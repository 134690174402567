// Source : https://ant.design/components/layout/

const BREAKPOINTS = {
  xs: "480px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1600px",
};

export default BREAKPOINTS;
