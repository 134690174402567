export const translations = [
  {
    text: "Name",
    en: "Sai Ashish",
    pt: "Sai Ashish",
    hi: "साई आशीष",
    te: "సాయి ఆశిష్",
    de: "Sai Ashish",
    zh: "賽阿西什",
    fr: "Sai Ashish",
    ru: "Сай Ашиш",
  },
  {
    text: "Full Name",
    en: "D. D. V. Sai Ashish",
    pt: "D. D. V. Sai Ashish",
    hi: "डी. डी. वी. साई आशीष",
    te: "డి. డి. వి. సాయి ఆశిష్",
    de: "D. D. V. Sai Ashish",
    zh: "D. D. V. Sai Ashish",
    fr: "D. D. V. Sai Ashish",
    ru: "Д. Д. В. Сай Ашиш",
  },
  {
    text: "Short Name",
    en: "Sai",
    pt: "Sai",
    hi: "साई",
    te: "సాయి",
    de: "Sai",
    zh: "賽",
    fr: "Sai",
    ru: "Сай",
  },
  {
    text: "English",
    en: "English",
    pt: "inglês (en)",
    hi: "अंग्रेज़ी (en)",
    te: "ఆంగ్ల (en)",
    de: "Englisch (en)",
    zh: "英語 (en)",
    fr: "Anglaise (en)",
    ru: "английский (en)",
  },
  {
    text: "Hindi",
    en: "Hindi",
    pt: "hindi",
    hi: "हिंदी",
    te: "హిందీ",
    de: "Hindi",
    zh: "印地語",
    fr: "hindi",
    ru: "хинди",
  },
  {
    text: "Telugu",
    en: "Telugu",
    pt: "Telugu",
    hi: "तेलुगू",
    te: "తెలుగు",
    de: "Telugu",
    zh: "泰盧固語",
    fr: "Telugu",
    ru: "телугу",
  },
  {
    text: "German",
    en: "German",
    pt: "Alemã",
    hi: "जर्मन",
    te: "జర్మన్",
    de: "Deutsche",
    zh: "德語",
    fr: "Allemande",
    ru: "Немецкий",
  },
  {
    text: "Russian",
    en: "Russian",
    pt: "Russa",
    hi: "रूसी",
    te: "రష్యన్",
    de: "Russisch",
    zh: "俄語",
    fr: "russe",
    ru: "русский",
  },
  {
    text: "French",
    en: "French",
    pt: "Francesa",
    hi: "फ्रांसेसा",
    te: "ఫ్రెంచ్",
    de: "Französisch",
    zh: "法語",
    fr: "français",
    ru: "французский язык",
  },
  {
    text: "Chinese",
    en: "Chinese",
    pt: "chinês",
    hi: "चीनी",
    te: "చైనీస్",
    de: "Chinesisch",
    zh: "中國人",
    fr: "Chinoise",
    ru: "китайский язык",
  },
  {
    text: "Portuguese",
    en: "Portuguese",
    pt: "português",
    hi: "पुर्तगाली",
    te: "పోర్చుగీస్",
    de: "Portugiesisch",
    zh: "葡萄牙語",
    fr: "Portugais",
    ru: "португальский",
  },
  {
    text: "Portuguese",
    en: "Portuguese",
    pt: "português",
    hi: "पुर्तगाली",
    te: "పోర్చుగీస్",
    de: "Portugiesisch",
    zh: "葡萄牙語",
    fr: "Portugais",
    ru: "португальский",
  },
  {
    text: "neverSayNever",
    en: "Never Say Never",
    pt: "Nunca diga nunca",
    hi: "नेवर से नेवर",
    te: "నెవర్ సే నెవర్",
    de: "Sag niemals nie",
    zh: "永不說永不",
    fr: "Ne jamais dire jamais",
    ru: "Никогда не говори никогда",
  },
  {
    text: "devaShreeGanesha",
    en: "Deva Shree Ganesha",
    pt: "Deva Shree Ganesha",
    hi: "देवा श्री गणेश",
    te: "దేవ శ్రీ గణేశుడు",
    de: "Deva Shree Ganesha",
    zh: "德瓦·史里甘尼薩",
    fr: "Deva Shree Ganesh",
    ru: "Дева Шри Ганеша",
  },
  {
    text: "Despacito",
    en: "Despacito",
    pt: "Despacito",
    hi: "डेस्पासिटो",
    te: "డెస్పాసిటో",
    de: "Despacito",
    zh: "德帕西托",
    fr: "Despacito",
    ru: "Despacito",
  },
  {
    text: "Shirdi Sai",
    en: "Shirdi Sai",
    pt: "Shirdi Sai",
    hi: "शिरडी साईं",
    te: "షిర్డీ సాయి",
    de: "Shirdi Sai",
    zh: "捨地賽",
    fr: "Shirdi Sai",
    ru: "Ширди Сай",
  },
  {
    text: "Achyutam Keshavam",
    en: "Achyutam Keshavam",
    pt: "Shirdi Sai",
    hi: "अच्युतम केशवमी",
    te: "అచ్యుతం కేశవం",
    de: "Achyutam Keshavam",
    zh: "阿丘塔姆·克沙瓦姆",
    fr: "Achyutam Keshavam",
    ru: "Ачьютам Кешавам",
  },
  {
    text: "Satisfya",
    en: "Satisfya",
    pt: "Satisfya",
    hi: "तृप्ति",
    te: "సతీస్ఫ్యా",
    de: "Zufrieden",
    zh: "滿意",
    fr: "Satisfya",
    ru: "Сатисфья",
  },
  {
    text: "Baby",
    en: "Baby",
    pt: "Bebê",
    hi: "शिशु",
    te: "బేబీ",
    de: "Baby",
    zh: "嬰兒",
    fr: "Bébé",
    ru: "малыш",
  },
  {
    text: "Enna Sona",
    en: "Enna Sona",
    pt: "Enna Sona",
    hi: "एना सोना",
    te: "ఎన్నా సోనా",
    de: "Enna Sona",
    zh: "恩娜·索納",
    fr: "Enna Sona",
    ru: "Энна Сона",
  },
  {
    text: "Why This Kolavari Di ?",
    en: "Why This Kolavari Di ?",
    pt: "Por que este Kolavari Di?",
    hi: "यह कोलावरी दी क्यों?",
    te: "ఈ కోలావరి డి ఎందుకు?",
    de: "Warum dieses Kolavari Di?",
    zh: "為什麼是這個 科拉維里迪？",
    fr: "Pourquoi ce Kolavari Di ?",
    ru: "Почему этот Колавари Ди?",
  },
  {
    text: "viewersCount",
    en: "Viewer's Count",
    pt: "Contagem de visualizadores",
    hi: "दर्शकों की संख्या",
    te: "వీక్షకుల సంఖ్య",
    de: "Zuschaueranzahl",
    zh: "觀眾人數",
    fr: "Nombre de spectateurs",
    ru: "Количество зрителей",
  },
  {
    text: "introDesc1",
    en: "Full stack web, native / cross-platform",
    pt: "Web de pilha completa, nativa / plataforma cruzada",
    hi: "पूर्ण स्टैक वेब, देशी / क्रॉस-प्लेटफ़ॉर्म",
    te: "పూర్తి స్టాక్ వెబ్, స్థానిక / క్రాస్-ప్లాట్‌ఫాం",
    de: "Full-Stack-Web, nativ / plattformübergreifend",
    zh: "全棧網絡，原生/跨平台",
    fr: "Web full stack, natif / multiplateforme",
    ru: "Полный стек веб, нативный / кроссплатформенный",
  },
  {
    text: "introDesc2",
    en: "mobile app developer",
    pt: "desenvolvedor de aplicativos móveis",
    hi: "मोबाइल ऐप डेवलपर",
    te: "మొబైల్ అనువర్తన డెవలపర్",
    de: "Entwickler von mobilen Apps",
    zh: "移動應用開發者",
    fr: "développeur d'applications mobiles",
    ru: "разработчик мобильных приложений",
  },
  {
    text: "introDesc3",
    en: "Computer Science BTECH 2022 Graduate At MSIT , GGSIPU (21 yrs)",
    pt: "Graduação em Ciência da Computação BTECH 2022 na MSIT, GGSIPU (21 anos)",
    hi: "कंप्यूटर विज्ञान BTECH 2022 MSIT, GGSIPU में स्नातक (21 वर्ष)",
    te: "MSIT, GGSIPUలో కంప్యూటర్ సైన్స్ BTECH 2022 గ్రాడ్యుయేట్ (21 సంవత్సరాలు)",
    de: "Informatik BTECH 2022 Absolvent am MSIT, GGSIPU (21 Jahre)",
    zh: "計算機科學 BTECH 2022 畢業生在 MSIT，GGSIPU（21 歲",
    fr: "Diplômé en informatique BTECH 2022 au MSIT , GGSIPU (21 ans)",
    ru: "Информатика Выпускник BTECH 2022 в MSIT, GGSIPU (21 год)",
  },
  {
    text: "location",
    en: "New Delhi, India",
    pt: "Nova Deli, India",
    hi: "नई दिल्ली, भारत",
    te: "న్యూఢిల్లీ, భారతదేశం",
    de: "Neu Delhi, Indien",
    zh: "印度新德里",
    fr: "New Delhi, Inde",
    ru: "Нью-Дели, Индия",
  },
  {
    text: "coverLetter",
    en: "Cover Letter",
    pt: "Carta de apresentação",
    hi: "कवर लेटर",
    te: "కవర్ లెటర్",
    de: "Motivationsschreiben",
    zh: "求職信",
    fr: "Lettre de motivation",
    ru: "Сопроводительное письмо",
  },
  {
    text: "resume",
    en: "Resume",
    pt: "Retomar",
    hi: "रिज्यूमे",
    te: "పునఃప్రారంభం",
    de: "Weitermachen",
    zh: "恢復",
    fr: "Reprendre",
    ru: "Резюме",
  },
  {
    text: "bTech",
    en: "BTech Performance",
    pt: "BTech Performance",
    hi: "बीटेक प्रदर्शन",
    te: "బిటెక్ పనితీరు",
    de: "BTech-Leistung",
    zh: "科技表現",
    fr: "Performance BTech",
    ru: "Производительность BTech",
  },
  {
    text: "cgpa",
    en: "CGPA: 9.575",
    pt: "CGPA: 9.575",
    hi: "सीजीपीए: 9.575",
    te: "సిజిపిఎ: 9.575",
    de: "CGPA：9.575",
    zh: "CGPA：9.575",
    fr: "MPC : 9.575",
    ru: "CGPA: 9.575",
  },
  {
    text: "github",
    en: "Github Profile",
    pt: "Perfil Github",
    hi: "जीथब प्रोफाइल",
    te: "గితుబ్ ప్రొఫైల్",
    de: "Github-Profil",
    zh: "Github 簡介",
    fr: "Profil Github",
    ru: "Профиль Github",
  },
  {
    text: "repos",
    en: "470+ repo's",
    pt: "Mais de 470 repo's",
    hi: "470+ रेपो",
    te: "470+ రెపోలు",
    de: "470+ Repos",
    zh: "470 多個回購",
    fr: "470+ pensions",
    ru: "470+ репо",
  },
  {
    text: "linkedIn",
    en: "LinkedIn Profile",
    pt: "Perfil do linkedIn",
    hi: "लिंक्डइन प्रोफ़ाइल",
    te: "లింక్డ్ఇన్ ప్రొఫైల్",
    de: "LinkedIn Profil",
    zh: "領英簡介",
    fr: "profil LinkedIn",
    ru: "LinkedIn профиль",
  },
  {
    text: "medium",
    en: "Medium Profile",
    pt: "Perfil Médio",
    hi: "मध्यम प्रोफ़ाइल",
    te: "మధ్యస్థ ప్రొఫైల్",
    de: "Mittleres Profil",
    zh: "中型簡介",
    fr: "Profil moyen",
    ru: "Средний профиль",
  },
  {
    text: "youtube",
    en: "Youtube Channel",
    pt: "Canal do Youtube",
    hi: "यूट्यूब चैनल",
    te: "యూట్యూబ్ ఛానల్",
    de: "Youtube Kanal",
    zh: "優酷頻道",
    fr: "Chaîne Youtube",
    ru: "YouTube канал",
  },
  {
    text: "nightCodingSetup",
    en: "Night Coding Setup",
    pt: "Configuração de Codificação Noturna",
    hi: "नाइट कोडिंग सेटअप",
    te: "నైట్ కోడింగ్ సెటప్",
    de: "Nachtcodierung einrichten",
    zh: "夜間編碼設置",
    fr: "Configuration du codage de nuit",
    ru: "Настройка ночного кодирования",
  },
  {
    text: "about",
    en: "About",
    pt: "Cerca de",
    hi: "के बारे में",
    te: "గురించి",
    de: "Über",
    zh: "關於",
    fr: "À propos de",
    ru: "О",
  },
  {
    text: "tel",
    en: "+91 8920125544",
    pt: "+91 8920125544",
    hi: "+९१ ८९२०१२५५४४",
    te: "+91 8920125544",
    de: "+91 8920125544",
    zh: "+91 8920125544",
    fr: "+91 8920125544",
    ru: "+91 8920125544",
  },
  {
    text: "instaClone",
    en: "Instagram Clone",
    pt: "Clone Instagram",
    hi: "इंस्टाग्राम क्लोन",
    te: "ఇన్స్టాగ్రామ్ క్లోన్",
    de: "Instagram-Klon",
    zh: "Instagram 克隆",
    fr: "Cloner Instagram",
    ru: "Клон Instagram",
  },
  {
    text: "amazonClone",
    en: "Amazon Clone",
    pt: "Amazon Clone",
    hi: "अमेज़न क्लोन",
    te: "అమెజాన్ క్లోన్",
    de: "Amazon Klon",
    zh: "亞馬遜克隆",
    fr: "Clone d'Amazon",
    ru: "Клон Амазонки",
  },
  {
    text: "linkedInClone",
    en: "LinkedIn Clone",
    pt: "Clone do LinkedIn",
    hi: "लिंक्डइन क्लोन",
    te: "లింక్డ్ఇన్ క్లోన్",
    de: "LinkedIn Klon",
    zh: "領英克隆",
    fr: "Cloner LinkedIn",
    ru: "LinkedIn Clone",
  },
  {
    text: "feelAtHome",
    en: "Feel At Home",
    pt: "Sinta-se em casa",
    hi: "घर में होने जैसा",
    te: "ఇంట్లో అనుభూతి",
    de: "Zuhause fühlen",
    zh: "有家的感覺",
    fr: "Se sentir à la maison",
    ru: "Чувствовать себя как дома",
  },
  {
    text: "appTown",
    en: "App Town Technologies",
    pt: "App Town Technologies",
    hi: "ऐप टाउन टेक्नोलॉजीज",
    te: "యాప్ టౌన్ టెక్నాలజీస్",
    de: "App Town-Technologien",
    zh: "應用城科技",
    fr: "App Town Technologies",
    ru: "App Town Technologies",
  },
  {
    text: "paytm",
    en: "Paytm",
    pt: "Paytm",
    hi: "Paytm",
    te: "Paytm",
    de: "Paytm",
    zh: "支付寶",
    fr: "Paiement",
    ru: "Paytm",
  },
  {
    text: "searchVaccines",
    en: "SearchVaccines",
    pt: "SearchVaccines",
    hi: "टीके खोजें",
    te: "శోధన టీకాలు",
    de: "SucheImpfstoffe",
    zh: "搜索疫苗",
    fr: "RechercherVaccins",
    ru: "Поиск вакцины",
  },
  {
    text: "pracify",
    en: "Pracify",
    pt: "Pracify",
    hi: "स्तुति",
    te: "ప్రసిఫై",
    de: "Üben",
    zh: "修行",
    fr: "Pratiquer",
    ru: "Практиковать",
  },
  {
    text: "inuvest",
    en: "Inuvest Technologies",
    pt: "Inuvest Technologies",
    hi: "इनवेस्ट टेक्नोलॉजीज",
    te: "ఇన్వెస్ట్ టెక్నాలజీస్",
    de: "Invest-Technologien",
    zh: "英威思科技",
    fr: "Investir Technologies",
    ru: "Инувест Технологии",
  },
  {
    text: "velvet",
    en: "Velvet Video",
    pt: "Velvet Video",
    hi: "मखमली वीडियो",
    te: "వెల్వెట్ వీడియో",
    de: "Samt-Video",
    zh: "絲絨視頻",
    fr: "Velours Vidéo",
    ru: "Бархат видео",
  },
  {
    text: "nex2Me",
    en: "Nex2Me",
    pt: "Nex2Me",
    hi: "नेक्स२मी",
    te: "నెక్స్2మి",
    de: "Nex2Me",
    zh: "Nex2Me",
    fr: "Nex2Me",
    ru: "nex2меня",
  },
  {
    text: "uvrobots",
    en: "UV Robots",
    pt: "Robôs UV",
    hi: "यूवी रोबोट",
    te: "UV రోబోట్లు",
    de: "UV-Roboter",
    zh: "紫外線機器人",
    fr: "Robots UV",
    ru: "УФ-роботы",
  },
  {
    text: "winner",
    en: "Winner 🏆",
    pt: "Vencedora 🏆",
    hi: "विजेता 🏆",
    te: "విజేత 🏆",
    de: "Gewinnerin 🏆",
    zh: "優勝者 🏆",
    fr: "Gagnante 🏆",
    ru: "Победитель 🏆",
  },
  {
    text: "awardWinner",
    en: "Award Winner 🏆",
    pt: "Vencedor do Prêmio 🏆",
    hi: "पुरस्कार विजेता 🏆",
    te: "అవార్డు విజేత 🏆",
    de: "Preisträger 🏆",
    zh: "獲獎者 🏆",
    fr: "Gagnant du prix 🏆",
    ru: "Лауреат премии 🏆",
  },
  {
    text: "topper",
    en: "Topper 🥇",
    pt: "Топпер 🥇",
    hi: "टोपर 🥇",
    te: "టాపర్ 🥇",
    de: "Topper 🥇",
    zh: "禮帽 🥇",
    fr: "Topper 🥇",
    ru: "Топпер 🥇",
  },
  {
    text: "publication",
    en: "Publication 😎",
    pt: "Publicação 😎",
    hi: "प्रकाशन 😎",
    te: "ప్రచురణ 😎",
    de: "Veröffentlichung 😎",
    zh: "發表 😎",
    fr: "Publication 😎",
    ru: "Публикация 😎",
  },
  {
    text: "girlscript",
    en: "Girlscript Hackathon'19",
    pt: "Girlscript Hackathon'19",
    hi: "गर्ल्सस्क्रिप्ट हैकथॉन'19",
    te: "గర్ల్స్క్రిప్ట్ హాకథాన్'19",
    de: "Girlscript Hackathon'19",
    zh: "Girlscript Hackathon'19",
    fr: "Girlscript Hackathon'19",
    ru: "Girlscript Хакатон'19",
  },
  {
    text: "avensis",
    en: "Avensis, HackMSIT'19",
    pt: "Avensis, HackMSIT'19",
    hi: "एवेन्सिस, HackMSIT'19",
    te: "అవెన్సిస్, హాక్ ఎంఎస్ఐటి'19",
    de: "Avensis, HackMSIT'19",
    zh: "Avensis，HackMSIT'19",
    fr: "Avensis, HackMSIT'19",
    ru: "Авенсис, HackMSIT'19",
  },
  {
    text: "hackVSIT",
    en: "HackVSIT",
    pt: "HackVSIT",
    hi: "हैकवीएसआईटी",
    te: "VSIT ను హాక్ చేయండి",
    de: "HackVSIT",
    zh: "黑客VSIT",
    fr: "HackVSIT",
    ru: "HackVSIT",
  },
  {
    text: "class11",
    en: "Class 11th",
    pt: "11ª classe",
    hi: "कक्षा ११वीं",
    te: "11 వ తరగతి",
    de: "11. Klasse",
    zh: "11年級",
    fr: "Classe 11e",
    ru: "11 класс",
  },
  {
    text: "iemis",
    en: "IEMIS",
    pt: "IEMIS",
    hi: "आईईएमआईएस",
    te: "IEMIS",
    de: "IEMIS",
    zh: "信息管理系統",
    fr: "IEMIS",
    ru: "ИЭМИС",
  },
  {
    text: "selectLanguage",
    en: "Select Language",
    pt: "Selecione o idioma",
    hi: "भाषा का चयन करें",
    te: "భాషను ఎంచుకోండి",
    de: "Sprache auswählen",
    zh: "選擇語言",
    fr: "Choisir la langue",
    ru: "Выберите язык",
  },
  {
    text: "selectSong",
    en: "Select Song",
    pt: "Selecione a música",
    hi: "गीत चुनें",
    te: "పాటను ఎంచుకోండి",
    de: "Lied auswählen",
    zh: "選擇歌曲",
    fr: "Sélectionnez la chanson",
    ru: "Выбрать композицию",
  },
  {
    text: "hackBVP",
    en: "Hack BVP",
    pt: "Hack BVP",
    hi: "हैक बीवीपी",
    te: "BVP ని హ్యాక్ చేయండి",
    de: "BVP hacken",
    zh: "黑客 BVP",
    fr: "Pirater BVP",
    ru: "Взломать BVP",
  },
  {
    text: "memories",
    en: "Memories ✨",
    pt: "Memórias ✨",
    hi: "यादें ✨",
    te: "జ్ఞాపకాలు ✨",
    de: "Erinnerungen ✨",
    zh: "回憶✨",
    fr: "Souvenirs",
    ru: "Воспоминания ✨",
  },
  {
    text: "month's",
    en: "month's",
    pt: "meses",
    hi: "महीने",
    te: "నెలల",
    de: "Monats",
    zh: "一個月的",
    fr: "mois",
    ru: "месяцы",
  },
  {
    text: "month",
    en: "month",
    pt: "mês",
    hi: "महीना",
    te: "నెల",
    de: "Monat",
    zh: "月",
    fr: "mois",
    ru: "месяц",
  },
  {
    text: "reactIntern",
    en: "React (JS + Native) Intern",
    pt: "Estagiário React (JS + Native)",
    hi: "रिएक्ट (जेएस + नेटिव) इंटर्न",
    te: "రియాక్ట్ (JS + నేటివ్) ఇంటర్న్",
    de: "React (JS + Native) Praktikantin",
    zh: "React (JS + Native) 實習生",
    fr: "Stagiaire React (JS + Native)",
    ru: "React (JS + Native) Стажер",
  },
  {
    text: "mernIntern",
    en: "MERN Stack Intern",
    pt: "Estagiário MERN Stack",
    hi: "MERN स्टैक इंटर्न",
    te: "మెర్న్ స్టాక్ ఇంటర్న్",
    de: "MERN Stack Praktikant",
    zh: "MERN 堆棧實習生",
    fr: "Stagiaire MERN Stack",
    ru: "MERN Stack Стажер",
  },
  {
    text: "sde-intern",
    en: "SDE Intern",
    pt: "Estagiário SDE",
    hi: "एसडीई इंटर्न",
    te: "SDE ఇంటర్న్",
    de: "SDE-Intern",
    zh: "SDE實習生",
    fr: "Stagiaire SDE",
    ru: "Стажер СДЕ",
  },
  {
    text: "fullStackPyIntern",
    en: "Full Stack (Angular + Django) Intern",
    pt: "Estagiário Full Stack (Angular + Django)",
    hi: "फुल स्टैक (कोणीय + Django) इंटर्न",
    te: "పూర్తి స్టాక్ (కోణీయ + జంగో) ఇంటర్న్",
    de: "Full Stack (Angular + Django) Intern",
    zh: "全棧（Angular + Django）實習生",
    fr: "Stagiaire Full Stack (Angular + Django)",
    ru: "Полный стек (Angular + Django) Стажер",
  },
  {
    text: "applicationDevIntern",
    en: "Application Development Intern",
    pt: "Estagiário de desenvolvimento de aplicativos",
    hi: "एप्लीकेशन डेवलपमेंट इंटर्न",
    te: "అప్లికేషన్ డెవలప్‌మెంట్ ఇంటర్న్",
    de: "Praktikantin in der Anwendungsentwicklung",
    zh: "應用開發實習生",
    fr: "Stagiaire en développement d'applications",
    ru: "Стажер по разработке приложений",
  },
  {
    text: "javaIntern",
    en: "Java Development Intern",
    pt: "Estagiário de Desenvolvimento Java",
    hi: "जावा डेवलपमेंट इंटर्न",
    te: "జావా డెవలప్మెంట్ ఇంటర్న్",
    de: "Praktikantin in der Java-Entwicklung",
    zh: "Java開發實習生",
    fr: "Stagiaire en développement Java",
    ru: "Стажер по Java-разработке",
  },
  {
    text: "images",
    en: "Images",
    pt: "Imagens",
    hi: "इमेजिस",
    te: "చిత్రాలు",
    de: "Bilder",
    zh: "圖片",
    fr: "Images",
    ru: "Картинки",
  },
  {
    text: "visitSite",
    en: "Visit Site",
    pt: "Visite o Site",
    hi: "यात्रा साइट",
    te: "సైట్ సందర్శించండి",
    de: "Besucherseite",
    zh: "訪問網站",
    fr: "Visitez le site",
    ru: "Посетите сайт",
  },
  {
    text: "teams",
    en: "Microsoft Teams",
    pt: "Microsoft Teams",
    hi: "माइक्रोसॉफ्ट टीम",
    te: "మైక్రోసాఫ్ట్ టీమ్స్",
    de: "Microsoft Teams",
    zh: "微軟團隊",
    fr: "Équipes Microsoft",
    ru: "Команды Microsoft",
  },
  {
    text: "messages",
    en: "Got messages from talent and acquisition team of:",
    pt: "Recebi mensagens da equipe de talentos e aquisição de:",
    hi: "की प्रतिभा और अधिग्रहण टीम से संदेश मिला:",
    te: "ప్రతిభ మరియు సముపార్జన బృందం నుండి సందేశాలు వచ్చాయి:",
    de: "Erhaltene Nachrichten vom Talent- und Akquisitionsteam von:",
    zh: "收到來自人才和採購團隊的消息：",
    fr: "J'ai reçu des messages de l'équipe de talent et d'acquisition de :",
    ru: "Получил сообщения от команды специалистов и специалистов по привлечению:",
  },
  {
    text: "invite",
    en: "For my loved one's, waiting for the call from talent team of:",
    pt: "Para a minha amada. aguardando a ligação da equipe de talentos de:",
    hi: "मेरे प्रियजन के लिए। की प्रतिभा टीम से कॉल की प्रतीक्षा कर रहा है:",
    te: "నా ప్రియమైన వారి కోసం. టాలెంట్ టీమ్ నుండి కాల్ కోసం వేచి ఉంది:",
    de: "Für meine Liebsten. Warten auf den Anruf vom Talentteam von:",
    zh: "為了我愛的人。等待人才團隊的電話：",
    fr: "Pour ceux de mon proche. en attente de l'appel de l'équipe talent de :",
    ru: "Для моего любимого. жду звонка от команды талантов в составе:",
  },
  {
    text: "class12",
    en: "Class 12th",
    pt: "12ª classe",
    hi: "कक्षा 12वीं",
    te: "12 వ తరగతి",
    de: "12. Klasse",
    zh: "11年級",
    fr: "Classe 12e",
    ru: "12 класс",
  },
  {
    text: "class8",
    en: "Class 8th",
    pt: "8ª classe",
    hi: "कक्षा 8वीं",
    te: "8 వ తరగతి",
    de: "12. Klasse",
    zh: "8年級",
    fr: "Classe 8e",
    ru: "8 класс",
  },
  {
    text: "dsa",
    en: "Data Structures & Algorithms",
    pt: "Estruturas de dados e algoritmos",
    hi: "डेटा संरचनाएं और एल्गोरिदम",
    te: "డేటా నిర్మాణాలు & అల్గోరిథంలు",
    de: "Datenstrukturen & Algorithmen",
    zh: "數據結構和算法",
    fr: "Structures de données et algorithmes",
    ru: "Структуры данных и алгоритмы",
  },
  {
    text: "wip",
    en: "[ Work In Progress ]",
    pt: "[ Trabalho em progresso ]",
    hi: "[ कार्य प्रगति पर है ]",
    te: "[ పని జరుగుచున్నది ]",
    de: "[ In Arbeit ]",
    zh: "[ 工作正在進行中 ]",
    fr: "[ Travaux en cours ]",
    ru: "[ Работа в процессе ]",
  },
  {
    text: "dsaTarget",
    en: "1008 Leetcode Questions + Notes + Interview Questions + System Design + Audio Clips + Animated Graphical Representation",
    pt: "1008 Questões Leetcode + Notas + Questões de Entrevista + Design de Sistema + Clipes de Áudio + Representação Gráfica Animada",
    hi: "1008 लेटकोड प्रश्न + नोट्स + साक्षात्कार प्रश्न + सिस्टम डिज़ाइन + ऑडियो क्लिप्स + एनिमेटेड ग्राफिकल प्रतिनिधित्व",
    te: "1008 లీట్‌కోడ్ ప్రశ్నలు + గమనికలు + ఇంటర్వ్యూ ప్రశ్నలు + సిస్టమ్ డిజైన్ + ఆడియో క్లిప్‌లు + యానిమేటెడ్ గ్రాఫికల్ రిప్రజెంటేషన్",
    de: "1008 Leetcode-Fragen + Notizen + Interviewfragen + Systemdesign + Audioclips + animierte grafische Darstellung",
    zh: "Leetcode 1008題+筆記+面試題+系統設計+音頻剪輯+動畫圖解",
    fr: "1008 Questions Leetcode + Notes + Questions d'entrevue + Conception du système + Clips audio + Représentation graphique animée",
    ru: "1008 вопросов по Leetcode + примечания + вопросы для интервью + дизайн системы + аудиоклипы + анимированное графическое представление",
  },
  {
    text: "netskope",
    en: "Netskope",
    pt: "Netskope",
    hi: "नेटस्कोप",
    te: "నెట్స్కోప్",
    de: "Netskope",
    zh: "網絡視鏡",
    fr: "Netskope",
    ru: "Netskope",
  },
  {
    text: "netskopeDesc",
    en: "Recognition ❤️",
    pt: "Reconhecimento ❤️",
    hi: "मान्यता ❤️",
    te: "గుర్తింపు ❤️",
    de: "Anerkennung ❤️",
    zh: "認可❤️",
    fr: "Reconnaissance ❤️",
    ru: "Признание ❤️",
  },
  {
    text: "amazonSDE2",
    en: "Amazon SDE 2 Opportunities (thrice)",
    pt: "Oportunidades do Amazon SDE-2 (três vezes)",
    hi: "अमेज़न एसडीई 2 अवसर (तीन बार)",
    te: "Amazon SDE 2 అవకాశాలు (మూడుసార్లు)",
    de: "Amazon SDE 2-Möglichkeiten (dreimal)",
    zh: "Amazon SDE 2 機會（三次）",
    fr: "Opportunités Amazon SDE 2 (trois fois)",
    ru: "Возможности Amazon SDE 2 (трижды)",
  },
  {
    text: "amazonSDE2Desc",
    en: "In a month (Age: 20) ☺️",
    pt: "Em um mês (Idade: 20) ☺️",
    hi: "एक महीने में (आयु: 20) ☺️",
    te: "ఒక నెలలో (వయస్సు: 20) ☺️",
    de: "In einem Monat (Alter: 20) ☺️",
    zh: "一個月後（年齡：20）☺️",
    fr: "Dans un mois (Âge : 20) ☺️",
    ru: "Через месяц (Возраст: 20) ☺️",
  },
  {
    text: "srm",
    en: "SRM Chennai MLSA",
    pt: "SRM Chennai MLSA",
    hi: "एसआरएम चेन्नई एमएलएसए",
    te: "SRM చెన్నై MLSA",
    de: "SRM Chennai MLSA",
    zh: "SRM 欽奈 MLSA",
    fr: "SRM Chennai MLSA",
    ru: "SRM Ченнаи MLSA",
  },
  {
    text: "srmDesc",
    en: "Invitations (Age: 19) 😇",
    pt: "Convites (idade: 19) 😇",
    hi: "निमंत्रण (आयु: 19)",
    te: "ఆహ్వానాలు (వయస్సు: 19) 😇",
    de: "Einladungen (Alter: 19) 😇",
    zh: "邀請函 (年齡: 19) 😇",
    fr: "Invitations (Âge : 19) 😇",
    ru: "Приглашения (Возраст: 19) 😇",
  },
  {
    text: "tier1",
    en: "Tier1 Students",
    pt: "Alunos Tier1",
    hi: "टियर 1 छात्र",
    te: "టైర్ 1 విద్యార్థులు",
    de: "Tier-1-Studenten",
    zh: "一級學生",
    fr: "Étudiants de niveau 1",
    ru: "Студенты Tier1",
  },
  {
    text: "tier1Desc",
    en: "Work Appreciation ☺️",
    pt: "Valorização do Trabalho ☺️",
    hi: "काम की तारीफ ️",
    te: "పని ప్రశంసలు ☺️",
    de: "Wertschätzung der Arbeit ☺️",
    zh: "工作欣賞☺️",
    fr: "Appréciation du travail ☺️",
    ru: "Признательность за работу ☺️",
  },
  {
    text: "100xvc",
    en: "100x.VC + Inc42",
    pt: "100x.VC + Inc42",
    hi: "100x.VC + Inc42",
    te: "100x.VC + Inc42",
    de: "100x.VC + Inc42",
    zh: "100x.VC + Inc42",
    fr: "100x.VC + Inc42",
    ru: "100x.VC + Inc42",
  },
  {
    text: "collaboration",
    en: "Collaboration",
    pt: "Colaboração",
    hi: "सहयोग",
    te: "సహకారం",
    de: "Zusammenarbeit",
    zh: "合作",
    fr: "Collaboration",
    ru: "Сотрудничество",
  },
  {
    text: "educative",
    en: "Educative, U.S.A. (GraphQL)",
    pt: "Educative, E.U.A. (GraphQL)",
    hi: "शिक्षाप्रद, यू.एस.ए. (ग्राफक्यूएल)",
    te: "విద్యావంతుడు, U.S.A. (గ్రాఫ్‌క్యూఎల్)",
    de: "Pädagogisch, USA (GraphQL)",
    zh: "教育, 美國(GraphQL)",
    fr: "Éducatif, États-Unis (GraphQL)",
    ru: "Образовательный, США (GraphQL)",
  },
  {
    text: "authorInvi",
    en: "Author Invitation ✨",
    pt: "Convite autor ✨",
    hi: "लेखक आमंत्रण",
    te: "రచయిత ఆహ్వానం ✨",
    de: "Autoreneinladung ✨",
    zh: "作者邀請✨",
    fr: "Invitation auteur ✨",
    ru: "Приглашение автора ✨",
  },
  {
    text: "bugs",
    en: "Found bugs at:",
    pt: "Erros encontrados em:",
    hi: "पर बग मिले:",
    te: "ఇక్కడ బగ్‌లు కనుగొనబడ్డాయి:",
    de: "Fehler gefunden bei:",
    zh: "在以下位置發現錯誤：",
    fr: "Bugs trouvés sur :",
    ru: "Найдены ошибки по адресу:",
  },
  {
    text: "care-medico",
    en: "Care Medico",
    pt: "Cuidados Médicos",
    hi: "केयर मेडिको",
    te: "కేర్ మెడికో",
    de: "Pflege Medizin",
    zh: "醫療保健",
    fr: "Soins Médico",
    ru: "Уход Медико",
  },
  {
    text: "lambda-test",
    en: "Lambda Test",
    pt: "Teste lambda",
    hi: "लैम्ब्डा टेस्ट",
    te: "లాంబ్డా టెస్ట్",
    de: "Lambda-Test",
    zh: "拉姆達測試",
    fr: "Test lambda",
    ru: "Лямбда-тест",
  },
  {
    text: "year",
    en: "year",
    pt: "ano",
    hi: "वर्ष",
    te: "సంవత్సరం",
    de: "Jahr",
    zh: "年",
    fr: "an",
    ru: "год",
  },
  {
    text: "whatsappBusiness",
    en: "Whatsapp Business Clone",
    pt: "WhatsApp Business Clone",
    hi: "व्हाट्सएप बिजनेस क्लोन",
    te: "Whatsapp బిజినెస్ క్లోన్",
    de: "WhatsApp Business-Klon",
    zh: "Whatsapp 商業克隆",
    fr: "Clone d'entreprise Whatsapp",
    ru: "Клон WhatsApp для бизнеса",
  },
  {
    text: "razorpay",
    en: "Razorpay",
    pt: "Razorpay",
    hi: "रेज़रपे",
    te: "రేజర్పే",
    de: "Razorpay",
    zh: "剃刀支付",
    fr: "Razorpay",
    ru: "Razorpay",
  },
  {
    text: "msTeamsChallenge",
    en: "MS Teams Challenge'22",
    pt: "MS Teams Challenge'22",
    hi: "एमएस टीम चैलेंज'22",
    te: "MS టీమ్స్ ఛాలెంజ్'22",
    de: "MS-Teams-Challenge'22",
    zh: "MS 團隊挑戰賽'22",
    fr: "Challenge Equipes MS'22",
    ru: "Вызов команд MS'22",
  },
  {
    text: "outstandingProject",
    en: "Outstanding Project ✨",
    pt: "Projeto pendente ✨",
    hi: "बकाया परियोजना ✨",
    te: "అత్యుత్తమ ప్రాజెక్ట్ ✨",
    de: "Hervorragendes Projekt ✨",
    zh: "優秀項目 ✨",
    fr: "Projet exceptionnel ✨",
    ru: "Выдающийся проект ✨",
  },
  {
    text: "buildable",
    en: "Buildable",
    pt: "Edificável",
    hi: "बनाने योग्य",
    te: "నిర్మించదగినది",
    de: "Baubar",
    zh: "可建造",
    fr: "Constructible",
    ru: "Сборный",
  },
  {
    text: "swiftRobotics",
    en: "Swift Robotics",
    pt: "Robótica Swift",
    hi: "स्विफ्ट रोबोटिक्स",
    te: "స్విఫ్ట్ రోబోటిక్స్",
    de: "Schnelle Robotik",
    zh: "迅捷機器人",
    fr: "Robotique rapide",
    ru: "Свифт Робототехника",
  },
  {
    text: "swiftShops",
    en: "Swift Shops",
    pt: "Lojas rápidas",
    hi: "स्विफ्ट की दुकानें",
    te: "స్విఫ్ట్ దుకాణాలు",
    de: "Schnelle Geschäfte",
    zh: "斯威夫特商店",
    fr: "Magasins rapides",
    ru: "Свифт Магазины",
  },
  {
    text: "tekion",
    en: "Tekion Corp",
    pt: "Tekion Corp",
    hi: "टेकियन कॉर्प",
    te: "టెకియోన్ కార్పొరేషన్",
    de: "Tekion Corp",
    zh: "泰肯公司",
    fr: "Tekion Corp",
    ru: "Текион Корп",
  },
  {
    text: "backend",
    en: "Backend Engineer",
    pt: "Engenheiro de back-end",
    hi: "बैकएंड इंजीनियर",
    te: "బ్యాకెండ్ ఇంజనీర్",
    de: "Backend-Ingenieur",
    zh: "後端工程師",
    fr: "Ingénieur back-end",
    ru: "Бэкенд-инженер",
  },
  {
    text: "rgsoc",
    en: "RGSOC",
    pt: "RGSOC",
    hi: "आरजीएसओसी",
    te: "RGSOC",
    de: "RGSOC",
    zh: "RGSOC",
    fr: "RGSOC",
    ru: "РГСОК",
  },
  {
    text: "mentor",
    en: "Mentor",
    pt: "Mentora",
    hi: "पथप्रदर्शक",
    te: "గురువు",
    de: "Mentorin",
    zh: "導師",
    fr: "Mentor",
    ru: "Наставник",
  },
  {
    text: "contributor",
    en: "Contributor",
    pt: "Contribuinte",
    hi: "योगदान देने वाला",
    te: "కంట్రిబ్యూటర్",
    de: "Mitwirkende",
    zh: "貢獻者",
    fr: "Donateur",
    ru: "Автор",
  },
  {
    text: "teamsDevC",
    en: "Teams Dev Challenge",
    pt: "Desafio de desenvolvimento do Teams",
    hi: "टीम देव चैलेंज",
    te: "టీమ్స్ దేవ్ ఛాలెంజ్",
    de: "Teams Dev Challenge",
    zh: "團隊開發挑戰",
    fr: "de développement des équipes",
    ru: "Соревнование разработчиков Teams",
  },
];
