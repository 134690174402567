import { CodeEditor, Span, Img } from "./components";
import { PlayerContainer } from "screens/home/components/projects/styles";
import ReactPlayer from "react-player";
import BigOChart from "assets/home/complexity-chart.jpeg";
import GraphImg from "assets/home/graphColoring.png";
import TSPDPImg from "assets/home/tsp_dp.png";
import TSPBacktrack from "assets/home/tsp-backtrack.png";
import SudokuImg from "assets/home/sudoku.png";
import KnightImg from "assets/home/knight-tour.png";
import NQueensImg from "assets/home/nqueens.png";
import NQueensImg2 from "assets/home/nqueens2.png";
import MazeImg from "assets/home/maze.png";
import PermutationsImg from "assets/home/pnc.png";
import WordBreakImg from "assets/home/wordbreak.png";
import SubsetsImg from "assets/home/subsets.png";
import LeftViewBT from "assets/home/leftView.png";
import RightViewBT from "assets/home/rightView.png";
import LeetCodeQ2 from "assets/leetcode/leetcodeQ2.png";
import LeetCodeQ11 from "assets/leetcode/q11.png";
import LeetcodeQ17 from "assets/leetcode/q17.png";
import LeetcodeQ19 from "assets/leetcode/q19.png";
import LeetcodeQ21 from "assets/leetcode/q21.png";
import LeetcodeQ24 from "assets/leetcode/q24.png";
import LeetcodeQ25a from "assets/leetcode/q25a.png";
import LeetcodeQ25b from "assets/leetcode/q25b.png";
import LeetcodeQ38 from "assets/leetcode/q38.png";
import LeetcodeQ42 from "assets/leetcode/q42.png";
import LeetcodeQ48 from "assets/leetcode/q48.png";
import LeetcodeQ48b from "assets/leetcode/q48b.png";
import LeetcodeQ51 from "assets/leetcode/q51.png";
import LeetcodeQ52 from "assets/leetcode/q52.png";
import LeetcodeQ54a from "assets/leetcode/54a.png";
import LeetcodeQ54b from "assets/leetcode/54b.png";
import Amazon1Img from "assets/interview/amazon1.jpeg";
import Amazon2Img from "assets/interview/amazon2.png";
import Amazon3Img from "assets/interview/amazon3.png";
import Amazon4Img from "assets/interview/amazon4.png";
import Amazon5Img from "assets/interview/amazon5.png";
import Amazon6Img from "assets/interview/amazon6.png";
import Amazon7Img from "assets/interview/amazon7.png";
import Amazon8Img from "assets/interview/amazon8.png";
import Amazon9Img from "assets/interview/amazon9.png";
import Amazon10Img from "assets/interview/amazon10.png";
import Amazon11Img from "assets/interview/amazon11.png";
import Amazon12Img from "assets/interview/amazon12.png";
import Amazon13Img from "assets/interview/amazon13.png";
import Amazon14Img from "assets/interview/amazon14.png";
import Vmware1Img from "assets/interview/vmware-q1.png";
import Vmware2Img from "assets/interview/vmware-q2.png";
import Vmware3Img from "assets/interview/vmware-q3.png";
import Vmware4Img from "assets/interview/vmware-q4.png";
import Vmware5Img from "assets/interview/vmware-q5.png";
import Vmware6Img from "assets/interview/vmware-q6.png";
import Vmware7Img from "assets/interview/vmware-q7.png";
import Vmware8Img from "assets/interview/vmware-q8.png";
import Vmware9Img from "assets/interview/vmware-q9.png";
import Vmware10Img from "assets/interview/vmware-q10.png";
import Vmware11Img from "assets/interview/vmware-q11.png";
import Vmware12Img from "assets/interview/vmware-q12.png";
import Pracify1Img from "assets/interview/pracify-1.png";
import Pracify2Img from "assets/interview/pracify-2.png";
import Pracify3Img from "assets/interview/pracify-3.png";
import Pracify4Img from "assets/interview/pracify-4.png";
import Pracify5Img from "assets/interview/pracify5.png";
import Pracify6Img from "assets/interview/pracify6.png";
import AppTown1 from "assets/interview/appTown1.png";
import AppTown2 from "assets/interview/appTown2.png";
import AppTown3 from "assets/interview/appTown3.png";
import AppTown4 from "assets/interview/appTown4.png";
import AppTown5 from "assets/interview/appTown5.png";
import AppTown6 from "assets/interview/appTown6.png";
import AppTown7 from "assets/interview/appTown7.png";
import AppTown8 from "assets/interview/appTown8.png";
import AppTown9 from "assets/interview/appTown9.png";
import Nex2Me1 from "assets/interview/nex2me1.png";
import Nex2Me2 from "assets/interview/nex2me2.png";
import Nex2Me3 from "assets/interview/nex2me3.png";
import Nex2Me4 from "assets/interview/nex2me4.png";
import Nex2Me5 from "assets/interview/nex2me5.png";
import Nex2Me6 from "assets/interview/nex2me6.png";
import Nex2Me7 from "assets/interview/nex2me7.png";
import Nex2Me8 from "assets/interview/nex2me8.png";
import Nex2Me9 from "assets/interview/nex2me9.png";
import Nex2Me10 from "assets/interview/nex2me10.png";
import Inuvest1 from "assets/interview/inuvest1.png";
import Inuvest2 from "assets/interview/inuvest2.png";
import Inuvest3 from "assets/interview/inuvest3.png";
import Inuvest4 from "assets/interview/inuvest4.png";
import Inuvest5 from "assets/interview/inuvest5.png";
import Inuvest6 from "assets/interview/inuvest6.png";
import Inuvest7 from "assets/interview/inuvest7.png";
import Inuvest8 from "assets/interview/inuvest8.png";
import Stewards1 from "assets/interview/stewards1.png";
import Stewards2 from "assets/interview/stewards2.png";
import Stewards3 from "assets/interview/stewards3.png";
import Stewards4 from "assets/interview/stewards4.png";
import Turing1 from "assets/interview/turing1.png";
import Turing2 from "assets/interview/turing2.png";
import Turing3 from "assets/interview/turing3.png";
import Turing4 from "assets/interview/turing4.png";
import CRED1 from "assets/interview/cred1.png";
import CRED2 from "assets/interview/cred2.png";
import CRED3 from "assets/interview/cred3.png";
import CRED4 from "assets/interview/cred4.png";
import CRED5 from "assets/interview/cred5.png";
import CRED6 from "assets/interview/cred6.png";
import Paytm1 from "assets/interview/paytm1.png";
import Paytm2 from "assets/interview/paytm2.png";
import Paytm3 from "assets/interview/paytm3.png";
import Paytm4 from "assets/interview/paytm4.png";
import Paytm5 from "assets/interview/paytm5.png";
import Paytm6 from "assets/interview/paytm6.png";
import Paytm7 from "assets/interview/paytm7.png";
import Paytm8 from "assets/interview/paytm8.png";
import Paytm9 from "assets/interview/paytm9.png";
import Uber1 from "assets/interview/uber/uber1.png";
import Uber2 from "assets/interview/uber/uber2.png";
import Uber3 from "assets/interview/uber/uber3.png";
import Uber4 from "assets/interview/uber/uber4.png";
import Uber5 from "assets/interview/uber/uber5.png";
import Uber6 from "assets/interview/uber/uber6.png";
import Uber7 from "assets/interview/uber/uber7.png";
import Uber8 from "assets/interview/uber/uber8.png";
import Uber9 from "assets/interview/uber/uber9.png";
import Uber10 from "assets/interview/uber/uber10.png";
import Uber11 from "assets/interview/uber/uber11.png";
import Uber12 from "assets/interview/uber/uber12.png";
import Uber13 from "assets/interview/uber/uber13.png";
import Uber14 from "assets/interview/uber/uber14.png";
import Uber15 from "assets/interview/uber/uber15.png";
import Uber16 from "assets/interview/uber/uber16.png";
import Uber17 from "assets/interview/uber/uber17.png";
import Uber18 from "assets/interview/uber/uber18.png";
import Uber19 from "assets/interview/uber/uber19.png";
import Uber20 from "assets/interview/uber/uber20.png";
import Oh1 from "assets/interview/oh1.png";
import Oh2 from "assets/interview/oh2.png";
import Oh3 from "assets/interview/oh3.png";
import LeetcodeQ59 from "assets/leetcode/q59.png";
import LeetcodeQ61a from "assets/leetcode/61a.png";
import LeetcodeQ61b from "assets/leetcode/61b.png";
import LeetcodeQ62 from "assets/leetcode/62.png";
import LeetcodeQ63a from "assets/leetcode/63a.png";
import LeetcodeQ63b from "assets/leetcode/63b.png";
import LeetcodeQ64 from "assets/leetcode/64.png";
import Leetcode73a from "assets/leetcode/73a.png";
import Leetcode73b from "assets/leetcode/73b.png";
import Leetcode74a from "assets/leetcode/74a.png";
import Leetcode74b from "assets/leetcode/74b.png";
import Leetcode79a from "assets/leetcode/79a.png";
import Leetcode79b from "assets/leetcode/79b.png";
import Leetcode79c from "assets/leetcode/79c.png";
import Leetcode82a from "assets/leetcode/q82a.png";
import Leetcode82b from "assets/leetcode/q82b.png";
import Leetcode83a from "assets/leetcode/83a.png";
import Leetcode83b from "assets/leetcode/83b.png";
import Leetcode84a from "assets/leetcode/84a.png";
import Leetcode84b from "assets/leetcode/84b.png";
import Leetcode85 from "assets/leetcode/85.png";
import Leetcode86 from "assets/leetcode/86.png";
import Leetcode92 from "assets/leetcode/92.png";
import Leetcode94 from "assets/leetcode/94.png";
import Leetcode95 from "assets/leetcode/95.png";
import Leetcode96 from "assets/leetcode/96.png";
import Leetcode97 from "assets/leetcode/97.png";
import Leetcode98a from "assets/leetcode/98a.png";
import Leetcode98b from "assets/leetcode/98b.png";
import Leetcode99a from "assets/leetcode/99a.png";
import Leetcode99b from "assets/leetcode/99b.png";
import Leetcode100 from "assets/leetcode/100.png";
import Leetcode101a from "assets/leetcode/101.png";
import Leetcode101b from "assets/leetcode/101b.png";
import Leetcode102 from "assets/leetcode/102.png";
import Leetcode103 from "assets/leetcode/103.png";
import Leetcode104 from "assets/leetcode/104.png";
import Leetcode105 from "assets/leetcode/105.png";
import Leetcode106 from "assets/leetcode/106.png";
import Leetcode107 from "assets/leetcode/107.png";
import Leetcode108 from "assets/leetcode/108.png";
import Leetcode109 from "assets/leetcode/109.png";
import Leetcode110 from "assets/leetcode/110.png";
import Leetcode111 from "assets/leetcode/111.png";
import Leetcode112 from "assets/leetcode/112.png";
import Leetcode113 from "assets/leetcode/113.png";
import Leetcode114 from "assets/leetcode/114.png";
import Leetcode116 from "assets/leetcode/116.png";
import Leetcode117 from "assets/leetcode/117.png";
import Leetcode118 from "assets/leetcode/118.png";
import Leetcode124a from "assets/leetcode/124a.png";
import Leetcode124b from "assets/leetcode/124b.png";
import Leetcode129 from "assets/leetcode/129.png";
import Leetcode130 from "assets/leetcode/130.png";
import Leetcode133 from "assets/leetcode/133.png";
import Leetcode138 from "assets/leetcode/138.png";
import Leetcode141 from "assets/leetcode/141.png";
import Leetcode143 from "assets/leetcode/143.png";
import Leetcode144 from "assets/leetcode/144.png";
import Leetcode147 from "assets/leetcode/147.png";
import Leetcode149 from "assets/leetcode/149.png";
import Leetcode160 from "assets/leetcode/160.png";
import Leetcode173 from "assets/leetcode/173.png";
import Leetcode174 from "assets/leetcode/174.png";
import Leetcode199 from "assets/leetcode/199.png";
import Leetcode203 from "assets/leetcode/203.png";
import Leetcode212 from "assets/leetcode/212.png";
import Leetcode218 from "assets/leetcode/q218.png";
import Leetcode221 from "assets/leetcode/221.png";
import Leetcode222 from "assets/leetcode/222.png";
import Leetcode223 from "assets/leetcode/223.png";
import Leetcode226 from "assets/leetcode/226.png";
import Leetcode230 from "assets/leetcode/230.png";
import Leetcode235 from "assets/leetcode/235.png";
import Leetcode236 from "assets/leetcode/236.png";
import Leetcode240 from "assets/leetcode/240.png";
import Leetcode257 from "assets/leetcode/257.png";
import Leetcode289 from "assets/leetcode/289.png";
import Leetcode297 from "assets/leetcode/297.png";
import Leetcode304 from "assets/leetcode/304.png";
import Leetcode310 from "assets/leetcode/310.png";
import Leetcode319 from "assets/leetcode/319.png";
import Leetcode329 from "assets/leetcode/329.png";
import Leetcode331 from "assets/leetcode/331.png";
import Leetcode332 from "assets/leetcode/332.png";
import Leetcode335 from "assets/leetcode/335.png";
import Leetcode337 from "assets/leetcode/337.png";
import Leetcode363 from "assets/leetcode/363.png";
import Leetcode375 from "assets/leetcode/375.png";
import Leetcode388 from "assets/leetcode/388.png";
import Leetcode391 from "assets/leetcode/391.png";
import Leetcode401 from "assets/leetcode/401.png";
import Leetcode404 from "assets/leetcode/404.png";
import Leetcode407 from "assets/leetcode/407.png";
import Leetcode417 from "assets/leetcode/417.png";
import Leetcode419 from "assets/leetcode/419.png";
import Leetcode427 from "assets/leetcode/427.png";
import Leetcode429 from "assets/leetcode/429.png";
import Leetcode430 from "assets/leetcode/430.png";
import Leetcode437 from "assets/leetcode/437.png";
import Leetcode441 from "assets/leetcode/441.png";
import Leetcode450 from "assets/leetcode/450.png";
import Leetcode463 from "assets/leetcode/463.png";
import Leetcode473 from "assets/leetcode/473.png";
import Leetcode497 from "assets/leetcode/497.png";
import Leetcode498 from "assets/leetcode/498.png";
import Leetcode500 from "assets/leetcode/500.png";
import Leetcode508 from "assets/leetcode/508.png";
import Leetcode513 from "assets/leetcode/513.png";
import Leetcode515 from "assets/leetcode/515.png";
import Leetcode529 from "assets/leetcode/529.png";
import Leetcode530 from "assets/leetcode/530.png";
import Leetcode538 from "assets/leetcode/538.png";
import Leetcode543 from "assets/leetcode/543.png";
import Leetcode547 from "assets/leetcode/547.png";
import Leetcode554 from "assets/leetcode/554.png";
import Leetcode558 from "assets/leetcode/558.png";
import Leetcode559 from "assets/leetcode/559.png";
import Leetcode563 from "assets/leetcode/563.png";
import Leetcode572 from "assets/leetcode/572.png";
import Leetcode576 from "assets/leetcode/576.png";
import Leetcode587 from "assets/leetcode/587.png";
import Leetcode598 from "assets/leetcode/598.png";
import Leetcode606 from "assets/leetcode/606.png";
import Leetcode617 from "assets/leetcode/617.png";
import Leetcode623 from "assets/leetcode/623.png";
import Leetcode637 from "assets/leetcode/637.png";
import Leetcode652 from "assets/leetcode/652.png";
import Leetcode653 from "assets/leetcode/653.png";
import Leetcode654 from "assets/leetcode/654.png";
import Leetcode655 from "assets/leetcode/655.png";
import Leetcode661 from "assets/leetcode/661.png";
import Leetcode662 from "assets/leetcode/662.png";
import Leetcode668 from "assets/leetcode/668.png";
import Leetcode669 from "assets/leetcode/669.png";
import Leetcode671 from "assets/leetcode/671.png";
import Leetcode684 from "assets/leetcode/684.png";
import Leetcode685 from "assets/leetcode/685.png";
import Leetcode687 from "assets/leetcode/687.png";
import Leetcode688 from "assets/leetcode/688.png";
import Leetcode690 from "assets/leetcode/690.png";
import Leetcode695 from "assets/leetcode/695.png";
import Leetcode699 from "assets/leetcode/699.png";
import Leetcode700 from "assets/leetcode/700.png";
import Leetcode701 from "assets/leetcode/701.png";
import Leetcode733 from "assets/leetcode/q733.png";
import Leetcode741 from "assets/leetcode/741.png";
import Leetcode743 from "assets/leetcode/743.png";
import Leetcode749 from "assets/leetcode/749.png";
import Leetcode756 from "assets/leetcode/q756.png";
import Leetcode764a from "assets/leetcode/764a.png";
import Leetcode764b from "assets/leetcode/764b.png";
import Leetcode782 from "assets/leetcode/782.png";
import Leetcode783 from "assets/leetcode/783.png";
import Leetcode785 from "assets/leetcode/785.png";
import Leetcode790a from "assets/leetcode/790a.png";
import Leetcode790b from "assets/leetcode/790b.png";
import Leetcode797a from "assets/leetcode/797a.png";
import Leetcode797b from "assets/leetcode/797b.png";
import Leetcode799 from "assets/leetcode/799.png";
import Leetcode802 from "assets/leetcode/802.png";
import Leetcode807 from "assets/leetcode/807.png";
import Leetcode812 from "assets/leetcode/812.png";
import Leetcode814 from "assets/leetcode/814.png";
import Leetcode833 from "assets/leetcode/833.png";
import Leetcode834 from "assets/leetcode/834.png";
import Leetcode838 from "assets/leetcode/838.png";
import Leetcode847 from "assets/leetcode/847.png";
import Leetcode849 from "assets/leetcode/849.png";
import Leetcode858 from "assets/leetcode/858.png";
import Leetcode863 from "assets/leetcode/863.png";
import Leetcode864 from "assets/leetcode/864.png";
import Leetcode872 from "assets/leetcode/872.png";
import Leetcode872a from "assets/leetcode/872a.png";
import Leetcode882 from "assets/leetcode/882.png";
import Leetcode883 from "assets/leetcode/883.png";
import Leetcode885 from "assets/leetcode/885.png";
import Leetcode889 from "assets/leetcode/889.png";
import Leetcode897 from "assets/leetcode/897.png";
import Leetcode909 from "assets/leetcode/909.png";
import Leetcode913 from "assets/leetcode/913.png";
import Leetcode919 from "assets/leetcode/919.png";
import Leetcode931 from "assets/leetcode/931.png";
import Leetcode935 from "assets/leetcode/935.png";
import Leetcode938 from "assets/leetcode/938.png";
import Leetcode939 from "assets/leetcode/939.png";
import Leetcode941 from "assets/leetcode/941.png";
import Leetcode951 from "assets/leetcode/951.png";
import NotesImg from "assets/notes.png";
import WebRTCImg from "assets/webrtc-go.png";
import WebRTCImg1 from "assets/webrtc1.png";
import WebRTCImg2 from "assets/webrtc2.png";
import WebRTCImg3 from "assets/webrtc3.png";
import WebRTCImg4 from "assets/webrtc4.png";
import WebRTCImg5 from "assets/webrtc5.png";
import WebRTCImg6 from "assets/webrtc6.png";
import GoImg from "assets/go.png";
import Comp from "./comp";

export const DATA = {
  ds: {
    title: "Data Structures",
    content: (
      <>
        <Span>
          A <b>data structure</b> is a way of organising data so that it can be
          used efficiently from temporary or main memory of the computer and
          various operations can be performed on it. It is a collection of data
          types
        </Span>
        <Span>
          There are two types of data structures: <b>primitive</b> and{" "}
          <b>non-primitive.</b>
        </Span>
        <Span>
          <b>Data Type:</b>
        </Span>
        <Span>
          Data types are used to identify the type of data a memory location can
          hold and the associated operations of handling it. Types: primitive
          and non-primitive
        </Span>
        <Span>
          <b>Database:</b>
        </Span>
        <Span>
          A database is an organized collection of data to access and manage
          data easily from permanent memory (files, HDD ,etc) . Relational
          database ( tables ) ( Mysql, Postgres ) (ACID (Atomicity Consistency
          Isolation Durability) property) and non-relational database ( json,xml
          files,etc ) ( MongoDB, Redis, Cassandra DB ) (BASE (Basically
          Available, Soft state, Eventually consistent) property) are it's
          types.
        </Span>
        <Span>
          <b>Data Warehouse:</b>
        </Span>
        <Span>
          It's the collection of data from various data sources which forms the
          main component of business.It makes use of sql queries and application
          log files.It is also used to perform queries on large amounts of data.
          It involves the process of extraction, loading, and transformation for
          providing the data for analysis. #collection
        </Span>
        <Span>
          <b>Data Mining:</b>
        </Span>
        <Span>
          Data mining is considered as a process of extracting data from large
          data sets with the help of patterns. #extraction
        </Span>
        <Span>
          <b>Big Data:</b>
        </Span>
        Big Data basically refers to the data which is in large volume and has
        complex data sets(Structured, semi-structured, or non-structured) which
        cannot be processed by traditional data processing software and
        databases. Various operations like analysis, manipulation, changes, etc
        are performed on data and then it is used by companies for intelligent
        decision making. e.g.Apache Hadoop.
      </>
    ),
    types: {
      primitive: {
        title: "Primitive",
        content: (
          <>
            <Span>
              It is a kind of data structure that stores the data of only one
              type (non nullable). There are the four primitive data structures:
              Integer, Float, Boolean and Character.
            </Span>
            <Span>
              <b>Primitive Data Type Vs Primitive Data Structure:</b>
            </Span>
            <Span>
              A primitive data type (
              int,byte,short,char,long,float,double,boolean ) identifies the
              reference variable whereas a primitive data structure is a
              location in memory (a piece of data) that has pointers to other
              locations on memory
            </Span>
          </>
        ),
        types: [
          {
            title: "Integer",
            content:
              "It contains the whole numbers that can be either negative or positive. (int, byte, short, char)",
          },
          {
            title: "Float",
            content:
              "The float is a data type that can hold decimal values. When the precision of decimal value increases then the Double data type is used. (folat,double) ",
          },
          {
            title: "Boolean",
            content:
              "It is a data type that can hold either a True or a False value. It is mainly used for checking the condition. (bool)",
          },
          {
            title: "Character",
            content:
              "It is a data type that can hold a single character value both uppercase and lowercase such as 'A' or 'a' (char) ",
          },
        ],
      },
      "non-primitive": {
        title: "Non Primitive",
        types: [
          {
            title: "Linear",
            content: (
              <>
                <Span>
                  In <b>linear data structure</b>, elements are arranged in a
                  linear or sequential way such that all of them can be
                  traversed in a single run and each element is connected to
                  previous and next adjacent.
                </Span>
                <Span>
                  e.g. : Array (static), Linked List (dynamic), Stack (dynamic),
                  Queue (dynamic), etc.
                </Span>
                <Span>
                  <b>Array (Homogeneous): </b>
                </Span>
                <Span>
                  It consists of a fixed number of elements of same type.
                  Operations: Insertion , Deletion , Traversal, Searching,
                  Modification And Sorting
                </Span>
                <b>Implementation</b>
                <CodeEditor
                  options={{
                    title: "Array (Homogeneous)",
                    output: "Output Test",
                    codes: {
                      Javascript: {
                        code: `// class Array will override default Array class
                        class CustomArray {
                            constructor() {
                                this.length = 0;
                                this.data = {};
                            }
                            len(){
                              return this.length;
                            }
                            print(){
                              console.log(Object.values(this.data))
                            }
                            get(index) {
                                return this.data[index];
                            }
                            push(element) {
                                this.data[this.length] = element;
                                this.length++;
                                return this.length;
                            }
                            pop() {
                                const item = this.data[this.length - 1];
                                delete this.data[this.length - 1];
                                this.length--;
                                return this.data;
                            }
                            enqueue(element){
                             return this.insert(element,0);
                            }
                            dequeue(){
                             return this.delete(0);
                            }
                            delete(index) {
                                for (let i = index; i < this.length - 1; i++) {
                                    this.data[i] = this.data[i + 1];
                                }
                                delete this.data[this.length - 1];
                                this.length--;
                                return this.data;
                            }
                            insert(item, index) {
                                for (let i = this.length; i >= index; i--) {
                                    this.data[i] = this.data[i - 1];
                                }
                                this.data[index] = item;
                                this.length++;
                                return this.data;
                            }
                        }
                        const array = new CustomArray();
                        
                        console.log("Custom Array Operations:")
                        
                        array.push(12);
                        array.push(10);
                        array.push(9);
                        array.push(14);
                        array.push(10);
                        array.push(989);
                        
                        console.log("Push: ")
                        array.print()
                        console.log("Length: " + array.len())
                        // array.length gives the same output
                        // array.length() will thrown an error 
                        console.log("Get (index: 1 ): " + array.get(1))
                        console.log("Pop: ")
                        const data = array.pop()
                        let res = []
                        for(let key in data){
                          res.push(data[key])
                        }
                        console.log(res)
                        console.log("Delete: " + array.delete(1))
                        array.print()
                        console.log("Insert: " + array.insert(2))
                        array.print()
                        console.log("Enqueue: " + array.insert(2))
                        array.print()
                        console.log("Dequeue: " + array.insert(2))
                        array.print()
                        
                        console.log("Javascript Array Operations:")
                        
                        let j_arr = [1,2,3]
                        
                        console.log("1. push: " + j_arr.push(5))
                        console.log(j_arr)
                        console.log("2. pop: " + j_arr.pop())
                        console.log(j_arr)
                        console.log("3. length: " + j_arr.length)
                        console.log(j_arr)
                        console.log("4. unshift (enqueue): " + j_arr.unshift(7,8))
                        console.log(j_arr)
                        console.log("5. shift (dequeue): " + j_arr.shift(2))
                        console.log(j_arr)
                        let j = j_arr.reduce((acc,curr)=>{
                        acc[curr] = (acc[curr] || 0) +1
                        return acc
                        },{})
                        console.log("6. reduce: " + JSON.stringify(j)) 
                        j = j_arr.reduceRight((acc,curr)=>{
                        acc[curr] = (acc[curr] || 0) +1
                        return acc
                        },{})
                        // traverse from right
                        console.log("7. reduceRight: " + JSON.stringify(j)) 
                        console.log("8. forEach: ")
                        j_arr.forEach((x,_)=>console.log(x))
                        console.log("9. map: " + j_arr.map(x=>x*2))
                        console.log("10. filter: " + j_arr.filter(x=>x*2))
                        console.log("11a. slice: " + j_arr.slice(2))
                        console.log("11b. slice: " + j_arr.slice(-1))
                        console.log(j_arr)
                        console.log("12. splice: " + j_arr.splice(2))
                        // overwrites the original array
                        // add 1 remove 0 "a" (1,0,"a")
                        // splice(2) remove elements from 2
                        // splice(2,2) remove 2 elements starting from index 2
                        // splice() adds and/or removes array elements.
                        console.log(j_arr)
                        console.log("13. sort: " + j_arr.sort())
                        // sort((a,b)=>a-b)
                        console.log(j_arr)
                        console.log("14. reverse: " + j_arr.reverse())
                        console.log(j_arr)
                        console.log("15. toString: " + j_arr.toString())
                        console.log(j_arr)
                        console.log("16. indexOf: " + j_arr.indexOf(9))
                        console.log(j_arr)
                        console.log("17. lastIndexOf: " + j_arr.lastIndexOf(1))
                        console.log(j_arr)
                        console.log("18. every: " + j_arr.every(x=>x===1))
                        console.log(j_arr)
                        console.log("19. some: " + j_arr.some(x=>x===1))
                        console.log(j_arr)
                        console.log("20 join: " + j_arr.join(","))
                        console.log(j_arr)
                        console.log("21. find: " + j_arr.find(x=>x===2))
                        console.log(j_arr)
                        console.log("22. findIndex: " + j_arr.find(x=>x===2))
                        console.log(j_arr)
                        console.log("23. fill: " + j_arr.fill(2))
                        console.log(j_arr)
                        console.log("24. includes: " + j_arr.includes(2))
                        console.log(j_arr)
                        console.log("25. concat: " + j_arr.concat([1,2]))
                        j_arr.push([3,4])
                        console.log(j_arr)
                        console.log("25. flat: " + j_arr.flat())
                        console.log(j_arr)
                        j_arr.fill(2)
                        console.log("26. flatMap: " + j_arr.flatMap(x=>[x,x*2]))
                        console.log(j_arr)
                        console.log("27. entries iterator")
                        for (const [index, element] of j_arr.entries())
                          console.log(index, element);
                        // keys values at(0)
                        
                        console.log("static methods: ")
                        let numbers = new Array(2)
                        console.log("Array(): " + numbers)
                        console.log("Array(): " + numbers.fill(2))
                        console.log("Array(): " + numbers.fill(new Array(2).fill(false)))
                        // If you execute the JavaScript in the environment that doesn’t support
                        // the  Array.of() method, you can use the following polyfill:
                        // if (!Array.of) {
                        //     Array.of = function() {
                        //         return Array.prototype.slice.call(arguments);
                        //     };
                        // }
                        numbers = Array.of(3);
                        console.log("Array.of(): " + numbers)
                        console.log("Array.from(): "+ Array.from("anc"))
                        console.log("Array.from(): "+ Array.from({length: 5}, (v, i) => i))
                        console.log("Array.from(): "+ Array.from([1, 2, 3], x => x + x))
                        console.log("Array.from(): "+ Array.from({length: 1}, () => Array(2).fill(false)))
                        console.log("Array.isArray(): " + Array.isArray(j_arr))
                        console.log(j_arr instanceof Array)
                        
                        const myRe = /d(b+)(d)/i
                        // 1d 1 or more b d ignore case
                        const myArray = myRe.exec('cdbBdbsbz')
                        console.log("myRe: " + myArray)
                        
                        
                        console.log("array destructuring: ")
                        let x,y
                        [x=10,y=20] = j_arr
                        console.log(x,y)
                        
                        // bind, apply, call ->
                        // https://medium.com/@omergoldberg/javascript-call-apply-and-bind-e5c27301f7bb
                        // var pokemon = {
                        //     firstname: 'Pika',
                        //     lastname: 'Chu ',
                        //     getPokeName: function() {
                        //         var fullname = this.firstname + ' ' + this.lastname;
                        //         return fullname;
                        //     }
                        // };
                        var pokemonName = function(snack, hobby) {
                          console.log(this.getPokeName() + ' loves ' + snack + ' and ' + hobby);
                        }                      
                        // var logPokemon = pokemonName.bind(pokemon); // creates new object and binds pokemon.
                        // 'this' of pokemon === pokemon now
                        // logPokemon('sushi', 'algorithms'); // Pika Chu  loves sushi and algorithms
                        // pokemonName.call(pokemon,'sushi', 'algorithms');
                        // Pika Chu  loves sushi and algorithms
                        // pokemonName.apply(pokemon,['sushi', 'algorithms']) 
                        // Pika Chu  loves sushi and algorithms
                        // logPokemon();
                        
                        
                        // Object.keys Object.entries Object.values
                        
                        // const {a :{ b } } = {a :{ b: 10 } } => { b :10 }`,
                        output: `
                        Custom Array Operations:
                        Push: 
                        [ 12, 10, 9, 14, 10, 989 ]
                        Length: 6
                        Get (index: 1 ): 10
                        Pop: 
                        [ 12, 10, 9, 14, 10 ]
                        Delete: [object Object]
                        [ 12, 9, 14, 10 ]
                        Insert: [object Object]
                        [ 12, 9, 14, 10, 2 ]
                        Enqueue: [object Object]
                        [ 12, 9, 14, 10, 2 ]
                        Dequeue: [object Object]
                        [ 12, 9, 14, 10, 2 ]
                        Javascript Array Operations:
                        1. push: 4
                        [ 1, 2, 3, 5 ]
                        2. pop: 5
                        [ 1, 2, 3 ]
                        3. length: 3
                        [ 1, 2, 3 ]
                        4. unshift (enqueue): 5
                        [ 7, 8, 1, 2, 3 ]
                        5. unshift (dequeue): 7
                        [ 8, 1, 2, 3 ]
                        6. reduce: {"1":1,"2":1,"3":1,"8":1}
                        7. reduceRight: {"1":1,"2":1,"3":1,"8":1}
                        8. forEach: 
                        8
                        1
                        2
                        3
                        9. map: 16,2,4,6
                        10. filter: 8,1,2,3
                        11a. slice: 2,3
                        11b. slice: 3
                        [ 8, 1, 2, 3 ]
                        12. splice: 2,3
                        [ 8, 1 ]
                        13. sort: 1,8
                        [ 1, 8 ]
                        14. reverse: 8,1
                        [ 8, 1 ]
                        15. toString: 8,1
                        [ 8, 1 ]
                        16. indexOf: -1
                        [ 8, 1 ]
                        17. lastIndexOf: 1
                        [ 8, 1 ]
                        18. every: false
                        [ 8, 1 ]
                        19. some: true
                        [ 8, 1 ]
                        20 join: 8,1
                        [ 8, 1 ]
                        21. find: undefined
                        [ 8, 1 ]
                        22. findIndex: undefined
                        [ 8, 1 ]
                        23. fill: 2,2
                        [ 2, 2 ]
                        24. includes: true
                        [ 2, 2 ]
                        25. concat: 2,2,1,2
                        [ 2, 2, [ 3, 4 ] ]
                        25. flat: 2,2,3,4
                        [ 2, 2, [ 3, 4 ] ]
                        26. flatMap: 2,4,2,4,2,4
                        [ 2, 2, 2 ]
                        27. entries iterator
                        0 2
                        1 2
                        2 2
                        static methods: 
                        Array(): ,
                        Array(): 2,2
                        Array(): false,false,false,false
                        Array.of(): 3
                        Array.from(): a,n,c
                        Array.from(): 0,1,2,3,4
                        Array.from(): 2,4,6
                        Array.from(): false,false
                        Array.isArray(): true
                        true
                        myRe: dbBd,bB,d
                        array destructuring: 
                        2 2`,
                      },
                      Java: {
                        code: `import java.util.*;
                        import java.io.*;
                        import java.util.stream.*;
                        
                        class Main {
                        
                          static PrintStream ps = System.out;
                        
                          static class CustomArray {
                        
                            private HashMap<Integer,Integer> data;
                            private int capacity;
                            private int length;
                        
                            CustomArray() {
                                data = new HashMap<Integer,Integer>();
                                capacity = 1;
                                length = 0;
                            }
                            int len(){
                              return length;
                            }
                        
                            @Override
                            public String toString(){
                              return data.toString();
                            }
                            int get(int index) {
                                // return data.get(index).intValue();
                                return Integer.valueOf(data.get(index));
                            }
                            int push(int element) {
                                data.put(this.length, element);
                                length++;
                                return length;
                            }
                            HashMap<Integer,Integer> pop() {
                                int item = data.get(this.length - 1).intValue();
                                data.remove(length-1);
                                length--;
                                return data;
                            }
                            int enqueue(int element) {
                                return insert(element, 0);
                            }
                            HashMap<Integer,Integer> dequeue() {
                                return delete(0);
                            }
                            HashMap<Integer,Integer> delete(int index) {
                                for (int i = index; i < this.length - 1; i++) {
                                    data.put(i,data.get(i + 1));
                                }
                                data.remove(length-1);
                                this.length--;
                                return this.data;
                            }
                            int insert(int item, int index) {
                                for (int i = this.length; i >= index; i--) {
                                    data.put(i, data.get(i - 1));
                                }
                                data.put(index, item);
                                length++;
                                return length;
                            }
                            }
                            public static void main(String args[]) {
                              CustomArray arr = new CustomArray();
                              ps.println("Custom Array Operations:");
                              ps.println("Push: ");
                              arr.push(9);
                              arr.push(8);
                              arr.push(7);
                              ps.println(arr);
                              ps.println("Length: " + arr.len());
                              ps.println("Get (index: 1): "+ arr.get(1));
                              ps.println("Pop: " + arr.pop());
                              ps.println("Delete: " + arr.delete(1));
                              ps.println("Insert: " + arr.insert(9,1));
                              ps.println(arr);
                              ps.println("Enqueue: " + arr.enqueue(10));
                              ps.println(arr);
                              ps.println("Dequeue: " + arr.dequeue());
                              ps.println("");
                              ps.println("Java Array Operations:");
                        
                              int j_arr[];
                              j_arr = new int[3];
                              ps.println(j_arr.getClass().getSuperclass());
                              j_arr[0] = 1;
                              int j_arr1[] = j_arr.clone(); 
                              ps.println(j_arr==j_arr1);
                              ps.println(Arrays.toString(j_arr1));
                              
                         // Collections API: https://www.geeksforgeeks.org/collections-in-java-2/
                             
                            List<Integer> al = Arrays.stream(j_arr1).boxed().collect(Collectors.toList());
                            //  List al = Arrays.asList(j_arr1);
                            //  Collections.addAll(al,j_arr1);
                            ps.println(Arrays.toString(al.stream().mapToInt(i -> i).toArray()));
                            //  non synchronized dynamic array
                            al.add(10);
                            ps.println(Arrays.toString(al.stream().mapToInt(i -> i).toArray()));
                            al.remove(2);
                            ps.println(al.get(0));
                        
                        //  synchronized dynamic array
                            Vector<Integer> v = new Vector();
                            v.addElement(9);
                            ps.println(v.toString());
                            Enumeration enu = v.elements();
                            while (enu.hasMoreElements()) {
                              ps.println(enu.nextElement());
                            }
                            }
                        }
                          `,
                        output: `
                        Custom Array Operations:
                        Push: 
                        {0=9, 1=8, 2=7}
                        Length: 3
                        Get (index: 1): 8
                        Pop: {0=9, 1=8}
                        Delete: {0=9}
                        Insert: 2
                        {0=9, 1=9}
                        Enqueue: 3
                        {0=10, 1=9, 2=9}
                        Dequeue: {0=9, 1=9}
                        
                        Java Array Operations:
                        class java.lang.Object
                        false
                        [1, 0, 0]
                        [1, 0, 0]
                        [1, 0, 0, 10]
                        1
                        [9]
                        9
                        `,
                      },
                      Python: {
                        output: `Custom Array Operations:
                        Append:
                        {0: 1, 1: 9}
                        Length: 2
                        Get (index: 1): 9
                        Pop:
                        {0: 1}
                        Delete: None
                        {}
                        please enter appropriate index..
                        Insert: None
                        {}
                        Enqueue: None
                        {0: 9}
                        Dequeue: None
                        {}
                        
                        List operations:
                        p_arr = [9, 8, 1]
                        1. reverse: [1, 8, 9]
                        2. sort: None
                        3. sorted: [9, 8, 1]
                        4. min: 1
                        5. max: 9
                        6. sum: 18
                        7. index: 0
                        8. map: [2, 16, 18]
                        9. filter: [1]
                        10. extend: [1, 8, 9, 9, 8]
                        11. pop: [1, 8, 9, 9]
                        12. dequeue: [8, 9, 9]
                        13. enqueue: [8, 8, 9, 9]
                        14. append: [8, 8, 9, 9, 8]
                        15. insert: [8, 9, 8, 9, 9, 8]
                        16. faltten using list comprehension[1, 2, 2, 4]
                        p_arr = [9, 8, 9]
                        17a. reduce:26
                        17b. reduce:26
                        18. slice:[9, 8]
                        19. find:False
                        20. all(js-> every):False
                        21. any(js -> some):True
                        `,
                        code: `import ctypes
class CustomArray():
  def __init__(self):
    self.length = 0
    self.capacity = 1 
    self.data = {}
    # self.A = self.make_array(self.capacity)
    # creates object of specified capacity
    
  def __len__(self):
    return self.length

  def __repr__(self):
    return str(self.data)
  # or __str__
  # __str__() is more human friendly whereas __repr__() is more information rich and machine friendly and can be used to reconstruct the object. In fact, we can use repr() function with eval() to construct the object.
  # x == eval(repr(x))

  def __getitem__(self, k):
    if not 0 <= k <self.length:
      return IndexError('K is out of bounds !')
    return self.data[k]
    
  def append(self, ele):
    self.data[self.length] = ele
    self.length += 1

  def pop(self):
    if self.length==0:
      print("Array is empty deletion not Possible")
      return
    del self.data[self.length-1]
    self.length-=1
    return self.data
  
  def enqueue(self,element):
    self.insert(element,0)

  def dequeue(self):
    self.delete(0)    

  def delete(self,index):
    if self.length==0:
      print("Array is empty deletion not Possible")
      return
    if index<0 or index>=self.length:
      return IndexError("Index out of bound....deletion not possible")		
    if index==self.length-1:
      del self.data[index]
      self.length-=1
      return		
    for i in range(index,self.length-1):
      self.data[i]=self.data[i+1]			
    del self.data[self.length-1]
    self.length-=1
    return self.data

  def insert(self,item,index):
    if index < 0 or index > self.length:
      print("please enter appropriate index..")
      return
    for i in range(self.length-1,index-1,-1):
      self.data[i+1]=self.data[i]
    self.data[index]=item
    self.length+=1
    return self.data
    
  # def _resize(self, new_cap):
  #   B = self.make_array(new_cap) 
  #   for k in range(self.length): 
  #     B[k] = self.data[k]
  #   self.data = B 
  #   self.capacity = new_cap 
    
  # def make_array(self, new_cap):
  #   return (new_cap * ctypes.py_object)()

arr = CustomArray()
print('Custom Array Operations:')
print('Append:')
arr.append(1)
arr.append(9)
print(arr)
print(f'Length: {len(arr)}')
# print(arr.__dict__)
print(f'Get (index: 1): {arr[1]}')
print(f'Pop:')
arr.pop()
print(arr)
print(f'Delete: {arr.delete(0)}')
print(arr)
print(f'Insert: {arr.insert(1,1)}')
print(arr)
print(f'Enqueue: {arr.enqueue(9)}')
print(arr)
print(f'Dequeue: {arr.dequeue()}')
print(arr)
print('')
print('List operations:')
p_arr = [9,8,1]
print(f'p_arr = {p_arr}')
print('1. reverse: ' + str(p_arr[::-1]))
print('2. sort: ' + str(p_arr.sort()))
print('3. sorted: ' + str(sorted(p_arr,reverse=True)))
print('4. min: ' + str(min(p_arr)))
print('5. max: ' + str(max(p_arr)))
print('6. sum: ' + str(sum(p_arr)))
print('7. index: ' + str(p_arr.index(1)))
print('8. map: ' + str(list(map(lambda x:x*2,p_arr))))
print('9. filter: ' + str(list(filter(lambda x:x==1,p_arr))))
p_arr.extend([9,8])
print('10. extend: ' + str(p_arr))
p_arr.pop()
print('11. pop: ' + str(p_arr))
p_arr.pop(0)
print('12. dequeue: ' + str(p_arr))
p_arr.insert(0,8)
print('13. enqueue: ' + str(p_arr))
p_arr.append(8)
print('14. append: ' + str(p_arr))
p_arr.insert(1,9)
print('15. insert: ' + str(p_arr))
p_arr = [[1,2],[2,4]]
print('16. faltten using list comprehension'+ str([y for x in p_arr for y in x ]))
import functools
import operator
p_arr = [*[9,8],9] # unpacking
print(f'p_arr = {p_arr}')
print('17a. reduce:' + str(functools.reduce(operator.add,p_arr)))
print('17b. reduce:' + str(functools.reduce(lambda acc,curr: acc+curr,p_arr)))
print('18. slice:' + str(p_arr[:2]))
print('19. find:' + str(2 in  p_arr))
print('20. all(js-> every):' + str(all([True,False])))
print('21. any(js -> some):' + str(any([True,False])))

# def names_tuple(*args):
#     return args
# names_tuple('Michael', 'John', 'Nancy')
# # ('Michael', 'John', 'Nancy')

# def names_dict(**kwargs):
#     return kwargs
# names_dict(Jane = 'Doe')
# {'Jane': 'Doe'}                        
                        `,
                      },
                      "C++": {
                        code: `#include <iostream>
                      #include <bits/stdc++.h>
                      #include <array>
                      
                      using namespace std;
                      
                      class CustomArray{
                        private:
                        int length;
                        map<int,int> data;
                      
                        public:
                        int len(){
                          return this->length;
                        }
                      
                        void print(){
                          string s;
                          for(auto i=data.begin();i != data.end();i++){
                            s+= to_string(i -> second) + " ";
                           }
                          cout<< s << endl;
                        }
                      
                        int get(int index){
                         return this -> data[index];
                        }
                      
                        int push(int element) {
                          this -> data[this -> length] = element;
                          this -> length+=1;
                          return this -> length;
                        }
                        
                        map<int,int> pop() {
                          int item = data[length - 1];
                          auto it = data.end();
                          it--;
                          data.erase(it);
                          length-=1;
                          return data;
                        }
                      
                        int insert(int ele,int index) {
                          if(ele==length-1){
                            push(ele);
                          }else{
                          for(auto i = length-1; i > index; i--) {
                            data[i+1] = data[i];
                          }
                          data[index] = ele;
                          length+=1;
                          }
                          return length;
                        }
                      
                        map<int,int> remove(int index) {
                          for(auto i = index; i < length-1; i++) {
                            data[i] = data[i + 1];
                          }
                          auto it = data.end();
                          it--;
                          data.erase(it);    length-=1;
                          return data;
                        }
                      
                        map<int,int> dequeue() {
                           return this -> remove(0);
                        }
                      
                        int enqueue(int ele) {
                          return insert(ele,0);
                        }
                      
                        CustomArray(){
                          length = 0;
                          data = {}; 
                        };
                      
                      };
                      
                      int main(){
                        CustomArray arr;
                        cout << "Custom Array Operations:" << endl;
                      
                        cout << "Push: " << endl;
                        arr.push(9);
                        arr.push(8);
                        arr.push(1);
                        arr.push(7);
                        arr.print();
                        cout << "Length: " << arr.len() << endl;
                        cout << "Get (index: 1 ): " << arr.get(1) << endl;
                        cout << "Pop: " << endl;
                        arr.pop();
                        arr.print();
                        cout << "Length: " << arr.len() << endl;
                        cout << "Delete: " << endl;
                        arr.remove(1);
                        arr.print();
                        cout << "Length: " << arr.len() << endl;
                        cout << "Insert: " << endl;
                        arr.insert(7,0);
                        arr.print();
                        cout << "Length: " << arr.len() << endl;
                        cout << "Enqueue: " << endl;
                        arr.enqueue(10);
                        arr.print();
                        cout << "Length: " << arr.len() << endl;
                        cout << "Dequeue: " << endl;
                        arr.dequeue();
                        arr.print();
                        cout << "Length: " << arr.len() << endl;
                      
                        cout << "C++ Array Operations: "<< endl;
                      
                        array<int,6> ar = {1, 2, 3, 4, 5, 6};
                      
                        cout << "1. get: " << get<0>(ar) << " , " << ar[0] << endl;
                        cout << "2. size: " << ar.size() << endl;
                        cout << "3. maxsize: " << ar.max_size() << endl;
                        cout << "4. size: " << ar.size() << endl;
                        cout << "5. front: " << ar.front() << endl;
                        cout << "6. back: " << ar.back() << endl;
                        ar.fill(9);
                        cout << "7. fill: " << ar[0] << endl;
                      
                        // stl -> algorithms , functions , containers , iterators
                        // https://www.geeksforgeeks.org/the-c-standard-template-library-stl/
                      
                        return 0;
                      }
                      `,
                        output: `
                      Custom Array Operations:
Push: 
9 8 1 7 
Length: 4
Get (index: 1 ): 8
Pop: 
9 8 1 
Length: 3
Delete: 
9 1 
Length: 2
Insert: 
7 1 1 
Length: 3
Enqueue: 
10 1 1 1 
Length: 4
Dequeue: 
1 1 1 
Length: 3
C++ Array Operations: 
1. get: 1 , 1
2. size: 6
3. maxsize: 6
4. size: 6
5. front: 1
6. back: 6
7. fill: 9
                      `,
                      },
                      Kotlin: {
                        code: `import java.io.PrintStream

                        internal object Main {
                            var ps: PrintStream = System.out
                            @JvmStatic
                            fun main(args: Array<String>) {
                                val arr = CustomArray()
                                ps.println("Custom Array Operations:")
                                ps.println("Push: ")
                                arr.push(9)
                                arr.push(8)
                                arr.push(7)
                                ps.println(arr)
                                ps.println("Length: " + arr.len())
                                ps.println("Get (index: 1): " + arr[1])
                                ps.println("Pop: " + arr.pop())
                                ps.println("Delete: " + arr.delete(1))
                                ps.println("Insert: " + arr.insert(9, 1))
                                ps.println(arr)
                                ps.println("Enqueue: " + arr.enqueue(10))
                                ps.println(arr)
                                ps.println("Dequeue: " + arr.dequeue())
                            }
                        
                            internal class CustomArray {
                                private val data: HashMap<Int, Int?> = HashMap()
                                private val capacity: Int = 1
                                private var length: Int = 0
                        
                        //        init {
                        //            length = 0
                        //        }
                        
                                fun len(): Int {
                                    return length
                                }
                        
                                override fun toString(): String {
                                    return data.toString()
                                }
                        
                                operator fun get(index: Int): Int {
                                    // return data.get(index).intValue();
                                    return Integer.valueOf(data[index]!!)
                                }
                        
                                fun push(element: Int): Int {
                                    data[length] = element
                                    length++
                                    return length
                                }
                        
                                fun pop(): HashMap<Int, Int?> {
                                    val item = data[length - 1]!!.toInt()
                                    data.remove(length - 1)
                                    length--
                                    return data
                                }
                        
                                fun enqueue(element: Int): Int {
                                    return insert(element, 0)
                                }
                        
                                fun dequeue(): HashMap<Int, Int?> {
                                    return delete(0)
                                }
                        
                                fun delete(index: Int): HashMap<Int, Int?> {
                                    for (i in index until length - 1) {
                                        data[i] = data[i + 1]
                                    }
                                    data.remove(length - 1)
                                    length--
                                    return data
                                }
                        
                                fun insert(item: Int, index: Int): Int {
                                    for (i in length downTo index) {
                                        data[i] = data[i - 1]F
                                    }
                                    data[index] = item
                                    length++
                                    return length
                                }
                            }
                        }`,
                        output: `
                        Custom Array Operations:
                        Push: 
                        {0=9, 1=8, 2=7}
                        Length: 3
                        Get (index: 1): 8
                        Pop: {0=9, 1=8}
                        Delete: {0=9}
                        Insert: 2
                        {0=9, 1=9}
                        Enqueue: 3
                        {0=10, 1=9, 2=9}
                        Dequeue: {0=9, 1=9}
                                                `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Linked List:</b>
                </Span>
                <Span>
                  It consists of nodes which are stored in non contiguos memory
                  locations. It's size is dynamic. Node consists of a data field
                  and links to adjacent neighbours. These links are also known
                  as self referential pointers as they are referring to other
                  nodes of same type.
                </Span>
                <Span>
                  Types : Singly Linked List, Doubly Linked List , Circular
                  Linked List ( Singly + Doubly ) And Header Linked List
                </Span>
                <b>Singly Linked List Implementation :</b>
                <CodeEditor
                  options={{
                    title: "Singly Linked List Implementation",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        class Node {
                          constructor(data) {
                              this.data = data
                              this.next = null
                          }
                      }
                      class LinkedList {
                          constructor() {
                              this.head = null
                          }
                          push(data) {
                              let node = new Node(data)
                              if (this.head == null) {
                                  this.head = node
                              } else {
                                  let temp = this.head
                                  while (temp.next) {
                                      temp = temp.next
                                  }
                                  temp.next = node
                              }
                          }
                          pop(){
                            if (this.head == null) {
                                return
                            }else if(this.head.next==null){
                              this.head=null
                              return
                            } 
                            else {
                                let temp = this.head
                                while (temp.next.next) {
                                  temp = temp.next
                                }
                                const last = temp.next 
                                temp.next = null
                      // delete temp doesn't work , we can delete object attributes but
                      //  not object itself in javascript
                              }
                          }
                          count() {
                              var count = 0
                              let node = this.head;
                              while (node != null) {
                                  count += 1
                                  node = node.next
                              }
                              return count
                          }
                          get(index){
                            if(this.head == null) return
                            else if(index>=this.count()) return
                            else {
                               let i=0
                               let temp = this.head
                               while(i < index){
                                  temp=temp.next
                                  i++
                               }
                               return temp.data
                            }
                          }
                          print() {
                              let temp = this.head
                              let output = ""
                              while (temp != null) {
                                  output+=temp.data + " "
                                  temp = temp.next
                              }
                              console.log(output)
                          }
                          insert(ele,index){
                            if(this.head==null || index > this.count()) return
                            let node = new Node(ele)
                              if (index == 0) {
                              node.next = this.head;
                              this.head = node;
                              } else {
                                  let it = 0,prev,curr;
                                  curr = this.head;
                                  prev = curr;
                                  while (it < index) {
                                      it++;
                                      prev = curr;
                                      curr = curr.next;
                                  }
                                  node.next = curr;
                                  prev.next = node;
                              }
                            return this.count()
                          }
                          delete(index){
                            if(this.head==null || index >= this.count()) return
                            else {
                              let curr,prev,it = 0
                              curr = this.head
                              prev = curr
                              if(index===0){
                                this.head = curr.next
                              }else{
                                while(it<index){
                                  it++
                                  prev = curr
                                  curr = curr.next
                                }  
                                prev.next = curr.next
                              }
                              return curr.data
                            }
                          }
                          enqueue(ele){
                             this.insert(ele,0)
                          }
                          dequeue(){
                             this.delete(0)
                          }
                      }
                      
                      const l = new LinkedList();
                      
                      console.log("Singly Linked List Operations:")
                      console.log("Push:")
                      l.push(9);
                      l.push(8);
                      l.push(10);
                      l.print()
                      console.log("Pop:")
                      l.pop()
                      l.print()
                      console.log("Count: " + l.count())
                      console.log("Get (Index 1): " + l.get(1))
                      console.log("Insert:")
                      l.insert(7,1)
                      l.print()
                      console.log("Delete:")
                      l.delete(1)
                      l.print()
                      console.log("Enqueue:")
                      l.enqueue(5)
                      l.print()
                      console.log("Dequeue:")
                      l.dequeue()
                      l.print()
                      `,
                        output: `
                        Singly Linked List Operations:
                        Push:
                        9 8 10 
                        Pop:
                        9 8 
                        Count: 2
                        Get (Index 1): 8
                        Insert:
                        9 7 8 
                        Delete:
                        9 8 
                        Enqueue:
                        5 9 8 
                        Dequeue:
                        9 8 
                        `,
                      },
                      Java: {
                        code: `
                      import java.io.*;

class Node{
int data;
Node next;
Node(int d){
data = d;
next = null;
}
}

class Main {

static PrintStream ps = System.out;

Node head;

public void push(int data){
Node node = new Node(data);
if(head==null){
head = node;
}else{
Node q = head;
while(q.next != null){
q=q.next;
}
q.next = node;
}
}

void pop(){
  if (head == null) {
      return;
  } else if (head.next == null) {
      head = null;
      return;
   } else {
      Node temp = head;
      while (temp.next.next != null) {
        temp = temp.next;
      }
      Node last = temp.next;
      temp.next = null;
  }
}

public int count(){
int count = 0;
Node p = head;
while(p!=null){
count++;
p = p.next;
}
return count;
}

int get(int index) {
  if (head == null) return -1;
  else if (index >= count()) return -1;
  else {
    int i = 0;
    Node temp = head;
    while (i < index) {
      temp = temp.next;
      i++;
    }
  return temp.data;
  }
  }
  
  int insert(int ele,int index){
    if (this.head == null || index >  this.count()) return -1;
    Node node = new Node(ele);
    if (index == 0) {
      node.next = this.head;
      this.head = node;
    } else {
      Node curr,prev;
      curr = this.head;
      prev = curr; 
      int it = 0;
      while (it < index) {
        it++;
        prev = curr;
        curr = curr.next;
      }
      node.next = curr;
      prev.next = node;
    }
    return this.count();
  }

  int delete(int index){
     if (this.head == null || index >= this.count()) return -1;
        else {
            Node curr, prev;
            int it = 0;
            curr = this.head;
            prev = curr;
            if (index == 0) {
                this.head = curr.next;
            } else {
                while (it < index) {
                    it++;
                    prev = curr;
                    curr = curr.next;
                }
                prev.next = curr.next;
            }
            return curr.data;
        }
  }

  int enqueue(int ele)
  {
    return this.insert(ele,0);
  } 

  int dequeue(){
    return this.delete(0);
  }

public void print(){
Node temp = head;
StringBuilder sb = new StringBuilder();
while(temp!=null){
sb.append(temp.data + " ");
temp=temp.next;
}
ps.println(sb.toString());
}

public static void main(String[] args) {
Main l = new Main();
l.push(2);
l.push(4);
l.push(3);
l.push(5);
l.push(6);
ps.println("Singly Linked List Operations:");
ps.println("Push:");
l.push(4);
l.print();
ps.println("Pop: ");
l.pop();
l.print();
ps.println("Count: "+ l.count());
ps.println("Get (Index 1): "+ l.get(1));
ps.println("Delete: ");
l.delete(2);
l.print();
ps.println("Enqueue: ");
l.enqueue(7);
l.print();
ps.println("Dequeue: ");
l.dequeue();
l.print();
}
}
                      `,
                        output: `
                      Singly Linked List Operations:
Push:
2 4 3 5 6 4 
Pop: 
2 4 3 5 6 
Count: 5
Get (Index 1): 4
Delete: 
2 4 5 6 
Enqueue: 
7 2 4 5 6 
Dequeue: 
2 4 5 6 
                      `,
                      },
                      Python: {
                        code: `class Node:
  def __init__(self,data):
    self.data=data
    self.next=None

class LinkedList:

  def __init__(self):
    self.head = None

  def append(self,data): 
    node = Node(data)
    if self.head is None:
      self.head = node
    else:
      temp = self.head
      while temp.next is not None:
        temp= temp.next
      temp.next = node

  def pop(self):
    if self.head is None:
      return
    elif self.head.next is None:
      self.head = None
      del self.head
      return
    else:
      temp = self.head
      while temp.next.next is not None:
        temp = temp.next
      last = temp.next
      temp.next = None
      del last

  def display(self):
    temp = self.head
    while temp is not None:
      print(temp.data,end =' ')
      temp = temp.next
    print('')

  def get(self,index):
    if self.head is None:
      return
    elif index >= self.count():                  
      return
    else:
      i = 0
      temp = self.head
      while i < index:
        temp = temp.next
        i+=1
    return temp.data
            
  def count(self):
    x=0
    temp=self.head
    while temp is not None:
      x+=1
      temp=temp.next
    return x

  def insert(self,ele,index):
    if self.head is None or index > self.count  (): 
      return
    node = Node(ele)
    if index == 0:
      node.next = self.head;
      self.head = node;
    else:
      it = 0
      prev = curr = None
      curr = self.head;
      prev = curr
      while it < index:
        it+=1
        prev = curr
        curr = curr.next
      node.next = curr
      prev.next = node
    return self.count()

  def delete(self,index):
    if self.head is None or index >= self.count():
      return
    else:
      curr = prev = it = 0
      curr = self.head
      prev = curr
      if index == 0:
        self.head = curr.next
      else:
        while it < index:
          it+=1
          prev = curr
          curr = curr.next
        prev.next = curr.next
    return curr.data

  def enqueue(self,ele):
    return self.insert(ele,0)

  def dequeue(self):
    return self.delete(0)

l = LinkedList()
print("Singly Linked List Operations:")
print("Append:")
l.append(2)
l.append(4)
l.append(3)
l.display()
print("Pop:")
l.pop()
l.display()
print("Count: " + str(l.count()))
print("Get (Index 1): " + str(l.get(2)))
print("Insert: ")
l.insert(7,1)
l.display()
print("Enqueue: ")
l.enqueue(9)
l.display()
print("Delete: ")
l.delete(1)
l.display()
print("Dequeue: ")
l.dequeue()
l.display()                      
                        `,
                        output: `
                        Singly Linked List Operations:
                        Append:
                        2 4 3 
                        Pop:
                        2 4 
                        Count: 2
                        Get (Index 1): None
                        Insert: 
                        2 7 4 
                        Enqueue: 
                        9 2 7 4 
                        Delete: 
                        9 7 4 
                        Dequeue: 
                        7 4  
                      `,
                      },
                      "C++": {
                        code: `
                      #include<iostream>
using namespace std;

struct Node {
int data;
Node *next; // self referential pointer
Node() : data(0), next(nullptr) {}
Node(int x) : data(x), next(nullptr) {}
Node(int x, Node *next) : data(x), next(next) {}
};

// class Node {
// public:
//     int data;
//     Node* next;
// };

class LinkedList {
 
private:
Node* head;

public:

LinkedList(){
  this -> head = NULL;
}

void push(int data){
  Node* node = new Node(data);
  if (this -> head == nullptr) {
    this -> head = node;
  } else {
    Node* temp = this->head;
    while (temp -> next) {
      temp = temp -> next;
    }
    temp->next = node;
  }
}

void pop(){
  if (this->head == nullptr) {
    return;
   } else if(this->head->next == nullptr){
    this->head = nullptr;
    return;
   } else {
    Node* temp = this->head;
    while (temp->next->next) {
      temp = temp->next;
    }
    Node* last = temp->next;
    temp->next = nullptr;
    delete last;
  }
}

int count(){
  int len = 0;
  Node* node = this -> head;
  while (node != nullptr) {
    len += 1;
    node = node -> next;
  }
  return len;
}

void display(){
   Node* temp = head;
   string output;
   while(temp != nullptr){
    output += to_string(temp->data) + " ";
    temp = temp -> next;
   } 
   cout << output << endl;
}

int get(int index){
   if(this->head == nullptr) return -1;
   else if(index >= this->count()){
     return -1;
   }else{
     int i = 0;
     Node* temp = this -> head;
     while(i < index){
       temp = temp -> next;
       i++;
     } 
     return temp->data;
   }
}

int insert(int ele,int index){
  if(this->head == nullptr || index > this->count()) return -1;
    Node* node = new Node(ele);
    if (index == 0) {
      node->next = this->head;
      this->head = node;
    } else {
      int it = 0;
      Node *prev, *curr;
      curr = this->head;
      prev = curr;
      while (it < index) {
        it++;
        prev = curr;
        curr = curr->next;
      }
      node->next = curr;
      prev->next = node;
    }
    return this->count();
}

int remove(int index){
  if (this->head == nullptr || index >= this->count()) 
  return -1;
  else {
    Node *curr, *prev;
    int it = 0;
    curr = this->head;
    prev = curr;
    if (index == 0) {
      this->head = curr->next;
    } else {
    while (it < index) {
      it++;
      prev = curr;
      curr = curr->next;
    }
    prev->next = curr->next;
    }
    return curr->data;
  }
  return -1;
}

int enqueue(int data){
   return this->insert(data,0);
}

int dequeue(){
  return this -> remove(0);
}

};

int main(){
  LinkedList l;
  cout << "Singly Linked List Operations:" << endl;
  cout << "Push:" << endl;
  l.push(9);
  l.push(8);
  l.push(10);
  l.display();
  cout << "Pop:" << endl;
  l.pop();
  l.display();
  cout << "Count: " << l.count() << endl;
  cout << "Get (Index 1): " <<
  l.get(1) << endl;
  cout << "Insert: " << endl;
  l.insert(9,2);
  l.display();
  cout << "Delete: " << endl;
  l.remove(2);
  l.display();
  cout << "Enqueue: " << endl;
  l.enqueue(8);
  l.display();
  cout << "Dequeue: " << endl;
  l.dequeue();
  l.display();
  return 0;
}
                      `,
                        output: `
                        Singly Linked List Operations:
                        Push:
                        9 8 10 
                        Pop:
                        9 8 
                        Count: 2
                        Get (Index 1): 8
                        Insert: 
                        9 8 9 
                        Delete: 
                        9 8 
                        Enqueue: 
                        8 9 8 
                        Dequeue: 
                        9 8 
                      `,
                      },
                      Kotlin: {
                        code: `
                      internal class Node(var data: Int) {
                        var next: Node? = null
                    }
                    
                    internal class Main {
                        var head: Node? = null
                        fun push(data: Int) {
                            val node = Node(data)
                            if (head == null) {
                                head = node
                            } else {
                                var q = head
                                while (q!!.next != null) {
                                    q = q.next
                                }
                                q.next = node
                            }
                        }
                    
                        fun pop() {
                            if (head == null) {
                                return
                            } else if (head!!.next == null) {
                                head = null
                                return
                            } else {
                                var temp = head
                                while (temp!!.next!!.next != null) {
                                    temp = temp.next
                                }
                                val last = temp.next
                                temp.next = null
                            }
                        }
                    
                        fun count(): Int {
                            var count = 0
                            var p = head
                            while (p != null) {
                                count++
                                p = p.next
                            }
                            return count
                        }
                    
                        operator fun get(index: Int): Int {
                            return if (head == null) -1 else if (index >= count()) -1 else {
                                var i = 0
                                var temp = head
                                while (i < index) {
                                    temp = temp!!.next
                                    i++
                                }
                                temp!!.data
                            }
                        }
                    
                        fun insert(ele: Int, index: Int): Int {
                            if (head == null || index > this.count()) return -1
                            val node = Node(ele)
                            if (index == 0) {
                                node.next = head
                                head = node
                            } else {
                                var curr: Node?
                                var prev: Node?
                                curr = head
                                prev = curr
                                var it = 0
                                while (it < index) {
                                    it++
                                    prev = curr
                                    curr = curr!!.next
                                }
                                node.next = curr
                                prev!!.next = node
                            }
                            return this.count()
                        }
                    
                        fun delete(index: Int): Int {
                            return if (head == null || index >= this.count()) -1 else {
                                var curr: Node?
                                var prev: Node?
                                var it = 0
                                curr = head
                                prev = curr
                                if (index == 0) {
                                    head = curr!!.next
                                } else {
                                    while (it < index) {
                                        it++
                                        prev = curr
                                        curr = curr!!.next
                                    }
                                    prev!!.next = curr!!.next
                                }
                                curr.data
                            }
                        }
                    
                        fun enqueue(ele: Int): Int {
                            return insert(ele, 0)
                        }
                    
                        fun dequeue(): Int {
                            return delete(0)
                        }
                    
                        fun print() {
                            var temp = head
                            val sb = StringBuilder()
                            while (temp != null) {
                                sb.append(temp.data.toString() + " ")
                                temp = temp.next
                            }
                            ps.println(sb.toString())
                        }
                    
                        companion object {
                            var ps = System.out
                            @JvmStatic
                            fun main(args: Array<String>) {
                                val l = Main()
                                l.push(2)
                                l.push(4)
                                l.push(3)
                                l.push(5)
                                l.push(6)
                                ps.println("Singly Linked List Operations:")
                                ps.println("Push:")
                                l.push(4)
                                l.print()
                                ps.println("Pop: ")
                                l.pop()
                                l.print()
                                ps.println("Count: " + l.count())
                                ps.println("Get (Index 1): " + l[1])
                                ps.println("Delete: ")
                                l.delete(2)
                                l.print()
                                ps.println("Enqueue: ")
                                l.enqueue(7)
                                l.print()
                                ps.println("Dequeue: ")
                                l.dequeue()
                                l.print()
                            }
                        }
                    }
                      `,
                        output: `
                      Singly Linked List Operations:
                      Push:
                      2 4 3 5 6 4 
                      Pop: 
                      2 4 3 5 6 
                      Count: 5
                      Get (Index 1): 4
                      Delete: 
                      2 4 5 6 
                      Enqueue: 
                      7 2 4 5 6 
                      Dequeue: 
                      2 4 5 6 
                      `,
                      },
                    },
                  }}
                />
                <b>Doubly Linked List Implementation :</b>
                <CodeEditor
                  options={{
                    title: "Doubly Linked List Implementation",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        class Node {
                          constructor(data) {
                              this.data = data
                              this.next = null
                              this.prev = null
                          }
                      }
                      class LinkedList {
                          constructor() {
                              this.head = null
                          }
                          push(data) {
                              let node = new Node(data)
                              if (this.head == null) {
                                  this.head = node
                              } else {
                                  let temp = this.head
                                  while (temp.next) {
                                      temp = temp.next
                                  }
                                  temp.next = node
                                  node.prev = temp
                              }
                          }
                          pop() {
                              if (this.head == null) {
                                  return
                              } else if (this.head.next == null) {
                                  this.head = null
                                  return
                              } else {
                                  let temp = this.head
                                  while (temp.next.next) {
                                      temp = temp.next
                                  }
                                  const last = temp.next
                                  temp.next = null
                                  // delete temp doesn't work , we can delete object attributes but
                                  //  not object itself in javascript
                              }
                          }
                          count() {
                              var count = 0
                              let node = this.head;
                              while (node != null) {
                                  count += 1
                                  node = node.next
                              }
                              return count
                          }
                          get(index) {
                              if (this.head == null) return
                              else if (index >= this.count()) return
                              else {
                                  let i = 0
                                  let temp = this.head
                                  while (i < index) {
                                      temp = temp.next
                                      i++
                                  }
                                  return temp.data
                              }
                          }
                          print() {
                              let temp = this.head
                              let output = ""
                              while (temp != null) {
                                  output += temp.data + " "
                                  temp = temp.next
                              }
                              console.log(output)
                          }
                          insert(ele, index) {
                              if (this.head == null || index > this.count()) return
                              let node = new Node(ele)
                              if (index == 0) {
                                  node.next = this.head;
                                  this.head.prev = node
                                  this.head = node;
                              } else {
                                  let it = 0,
                                      prev, curr;
                                  curr = this.head;
                                  prev = curr;
                                  while (it < index) {
                                      it++;
                                      prev = curr;
                                      curr = curr.next;
                                  }
                                  node.next = curr;
                                  curr.prev = node;
                                  prev.next = node;
                                  node.prev = prev
                              }
                              return this.count()
                          }
                          delete(index) {
                              if (this.head == null || index >= this.count()) return
                              else {
                                  let curr, prev, it = 0
                                  curr = this.head
                                  prev = curr
                                  if (index === 0) {
                                      this.head = curr.next
                                      curr.next.prev = null
                                  } else {
                                      while (it < index) {
                                          it++
                                          prev = curr
                                          curr = curr.next
                                      }
                                      prev.next = curr.next
                                      curr.next.prev = prev
                                  }
                                  return curr.data
                              }
                          }
                          enqueue(ele) {
                              this.insert(ele, 0)
                          }
                          dequeue() {
                              this.delete(0)
                          }
                      }
                      
                      const l = new LinkedList();
                      
                      console.log("Doubly Linked List Operations:")
                      console.log("Push:")
                      l.push(9);
                      l.push(8);
                      l.push(10);
                      l.print()
                      console.log("Pop:")
                      l.pop()
                      l.print()
                      console.log("Count: " + l.count())
                      console.log("Get (Index 1): " + l.get(1))
                      console.log("Insert:")
                      l.insert(7, 1)
                      l.print()
                      console.log("Delete:")
                      l.delete(1)
                      l.print()
                      console.log("Enqueue:")
                      l.enqueue(5)
                      l.print()
                      console.log("Dequeue:")
                      l.dequeue()
                      l.print()                      
                        `,
                        output: `Doubly Linked List Operations:
                        Push:
                        9 8 10 
                        Pop:
                        9 8 
                        Count: 2
                        Get (Index 1): 8
                        Insert:
                        9 7 8 
                        Delete:
                        9 8 
                        Enqueue:
                        5 9 8 
                        Dequeue:
                        9 8 `,
                      },
                    },
                  }}
                />
                <b>Circular Doubly Linked List Implementation :</b>
                <CodeEditor
                  options={{
                    title: "Circular Doubly Linked List Implementation",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `class Node {
                          constructor(data) {
                              this.data = data
                              this.next = null
                              this.prev = null
                          }
                      }
                      class LinkedList {
                          constructor() {
                              this.head = null
                          }
                          push(data) {
                              let node = new Node(data)
                              if (this.head == null) {
                                  this.head = node
                                  this.head.prev = this.head
                                  this.head.next = this.head
                              } else {
                                  let temp = this.head
                                  if(temp.next === this.head){
                                    temp.next = node
                                    node.prev = temp
                                    node.next = this.head 
                                  }else{
                                  while (temp.next !== this.head) {
                                      if(temp)
                                      temp = temp.next
                                  }
                                  temp.next = node
                                  node.prev = temp
                                  node.next= this.head
                                  }
                      
                              }
                          }
                          pop() {
                              if (this.head == null) {
                                  return
                              } else if (this.head.next == null) {
                                  this.head = null
                                  return
                              } else {
                                  let temp = this.head
                                  while (temp.next.next !== this.head) {
                                      temp = temp.next
                                  }
                                  const last = temp.next
                                  temp.next = this.head
                                  // delete temp doesn't work , we can delete object attributes but
                                  //  not object itself in javascript
                              }
                          }
                          count() {
                              var count = 0
                              let node = this.head;
                              if(this.head == null) return count
                              count = 1
                              while (node.next != this.head) {
                                  count += 1
                                  node = node.next
                              }
                              return count
                          }
                          get(index) {
                              if (this.head == null) return
                              else if (index >= this.count()) return
                              else {
                                  let i = 0
                                  let temp = this.head
                                  while (i < index) {
                                      temp = temp.next
                                      i++
                                  }
                                  return temp.data
                              }
                          }
                          print() {
                              let temp = this.head
                              let output = ""
                              while (temp.next != this.head) {
                                  output += temp.data + " "
                                  temp = temp.next
                              }
                              if(temp)
                              output += temp.data + " "
                              console.log(output)
                          }
                          insert(ele, index) {
                              if (this.head == null || index > this.count()) return
                              let node = new Node(ele)
                              if (index == 0) {
                                  node.next = this.head;
                                  this.head.prev = node
                                  let temp = this.head
                                  while(temp.next !== this.head) temp = temp.next
                                  temp.next = node
                                  this.head= node
                              } else {
                                  let it = 0,
                                      prev, curr;
                                  curr = this.head;
                                  prev = curr;
                                  while (it < index) {
                                      it++;
                                      prev = curr;
                                      curr = curr.next;
                                  }
                                  node.next = curr;
                                  curr.prev = node;
                                  prev.next = node;
                                  node.prev = prev
                              }
                              return this.count()
                          }
                          delete(index) {
                              if (this.head == null || index >= this.count()) return
                              else {
                                  let curr, prev, it = 0
                                  curr = this.head
                                  prev = curr
                                  if (index === 0) {
                                      let temp = this.head
                                      while(temp.next !== this.head) temp = temp.next
                                      temp.next = this.head.next
                                      this.head = curr.next
                                      curr.next.prev = this.head
                                  } else {
                                      while (it < index) {
                                          it++
                                          prev = curr
                                          curr = curr.next
                                      }
                                      prev.next = curr.next
                                      curr.next.prev = prev
                                  }
                                  return curr.data
                              }
                          }
                          enqueue(ele) {
                              this.insert(ele, 0)
                          }
                          dequeue() {
                              this.delete(0)
                          }
                      }
                      
                      const l = new LinkedList();
                      
                      console.log("Circular Doubly Linked List Operations:")
                      console.log("Push:")
                      l.push(9);
                      l.push(8);
                      l.push(10);
                      l.print()
                      console.log("Pop:")
                      l.pop()
                      l.print()
                      console.log("Count: " + l.count())
                      console.log("Get (Index 1): " + l.get(1))
                      console.log("Insert:")
                      l.insert(7, 1)
                      l.print()
                      console.log("Delete:")
                      l.delete(1)
                      l.print()
                      console.log("Enqueue:")
                      l.enqueue(5)
                      l.print()
                      console.log("Dequeue:")
                      l.dequeue()
                      l.print()
                      `,
                        output: `Circular Doubly Linked List Operations:
                        Push:
                        9 8 10 
                        Pop:
                        9 8 
                        Count: 2
                        Get (Index 1): 8
                        Insert:
                        9 7 8 
                        Delete:
                        9 8 
                        Enqueue:
                        5 9 8 
                        Dequeue:
                        9 8 
                        Count: 2`,
                      },
                    },
                  }}
                />
                <Span>Header Linked List</Span>
                <Span>
                  A header node is a special node that is found at the beginning
                  of the list. A list that contains this type of node, is called
                  the header-linked list. This type of list is useful when
                  information ( more oftenly length of list ) other than that
                  found in each node is needed. It's a modified version of
                  singly linked list.
                </Span>
                <Span>Types:</Span>
                <Span>
                  <b>Grounded Header Linked List</b>
                </Span>
                <Span>
                  It is a list whose last node contains the NULL pointer. In the
                  header linked list the start pointer always points to the
                  header node. start -&lt; next = NULL indicates that the
                  grounded header linked list is empty.
                </Span>
                <Span>
                  <b>Circular Header Linked List</b>
                </Span>
                <Span>
                  A list in which last node points back to the header node is
                  called circular linked list. The chains do not indicate first
                  or last nodes. In this case, external pointers provide a frame
                  of reference because last node of a circular linked list does
                  not contain the NULL pointer.
                </Span>
                <b>Grounded Header Linked List Implementation :</b>
                <CodeEditor
                  options={{
                    title: "Grounded Header Linked List Implementation",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `class Node {
                          constructor(data) {
                              this.data = data
                              this.next = null
                          }
                      }
                      class LinkedList {
                          constructor() {
                              this.head = new Node(0)
                          }
                          push(data) {
                              let node = new Node(data)
                              if (this.head.next == null) {
                                  this.head.next = node
                              } else {
                                  let temp = this.head.next
                                  while (temp.next) {
                                      temp = temp.next
                                  }
                                  temp.next = node
                              }
                              this.head.data = this.count()
                          }
                          pop() {
                              if (this.head.next == null) {
                                  return
                              } else if (this.head.next.next == null) {
                                  this.head.next = null
                                  return
                              } else {
                                  let temp = this.head.next
                                  while (temp.next.next) {
                                      temp = temp.next
                                  }
                                  const last = temp.next
                                  temp.next = null
                                  this.head.data = this.count()
                                  // delete temp doesn't work , we can delete object attributes but
                                  //  not object itself in javascript
                              }
                          }
                          count() {
                              var count = 0
                              let node = this.head.next;
                              while (node != null) {
                                  count += 1
                                  node = node.next
                              }
                              return count
                          }
                          get(index) {
                              if (this.head.next == null) return
                              else if (index >= this.count()) return
                              else {
                                  let i = 0
                                  let temp = this.head.next
                                  while (i < index) {
                                      temp = temp.next
                                      i++
                                  }
                                  return temp.data
                              }
                          }
                          print() {
                              let temp = this.head.next
                              let output = ""
                              while (temp != null) {
                                  output += temp.data + " "
                                  temp = temp.next
                              }
                              console.log(output)
                          }
                          insert(ele, index) {
                              if (this.head.next == null || index > this.count()) return
                              let node = new Node(ele)
                              if (index == 0) {
                                  node.next = this.head.next;
                                  this.head.next = node;
                              } else {
                                  let it = 0,
                                      prev, curr;
                                  curr = this.head.next;
                                  prev = curr;
                                  while (it < index) {
                                      it++;
                                      prev = curr;
                                      curr = curr.next;
                                  }
                                  node.next = curr;
                                  prev.next = node;
                              }
                              this.head.data = this.count()
                              return this.count()
                          }
                          delete(index) {
                              if (this.head.next == null || index >= this.count()) return
                              else {
                                  let curr, prev, it = 0
                                  curr = this.head.next
                                  prev = curr
                                  if (index === 0) {
                                      this.head.next = curr.next
                                  } else {
                                      while (it < index) {
                                          it++
                                          prev = curr
                                          curr = curr.next
                                      }
                                      prev.next = curr.next
                                  }
                                  this.head.data = this.count()
                                  return curr.data
                              }
                          }
                          enqueue(ele) {
                              this.insert(ele, 0)
                          }
                          dequeue() {
                              this.delete(0)
                          }
                      }
                      
                      const l = new LinkedList();
                      
                      console.log("Grounded Header Linked List Operations:")
                      console.log("Push:")
                      l.push(9);
                      l.push(8);
                      l.push(10);
                      l.print()
                      console.log("Pop:")
                      l.pop()
                      l.print()
                      console.log("Count: " + l.count())
                      console.log("Get (Index 1): " + l.get(1))
                      console.log("Insert:")
                      l.insert(7, 1)
                      l.print()
                      console.log("Delete:")
                      l.delete(1)
                      l.print()
                      console.log("Enqueue:")
                      l.enqueue(5)
                      l.print()
                      console.log("Dequeue:")
                      l.dequeue()
                      l.print()
                      console.log("Header Node:")
                      console.log(l.head)`,
                        output: `Grounded Header Linked List Operations:
                        Push:
                        9 8 10 
                        Pop:
                        9 8 
                        Count: 2
                        Get (Index 1): 8
                        Insert:
                        9 7 8 
                        Delete:
                        9 8 
                        Enqueue:
                        5 9 8 
                        Dequeue:
                        9 8 
                        Header Node:
                        Node {
                          data: 2,
                          next: Node { data: 9, next: Node { data: 8, next: null } }
                        }`,
                      },
                    },
                  }}
                />
                <p>
                  <b>Reverse a linked list :</b>
                </p>
                <CodeEditor
                  options={{
                    title: "Reverse a linked list",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `class Node {
                          constructor(data) {
                              this.data = data
                              this.next = null
                          }
                      }
                      class LinkedList {
                          constructor() {
                              this.head = null
                          }
                          push(data) {
                              let node = new Node(data)
                              if (this.head == null) {
                                  this.head = node
                              } else {
                                  let temp = this.head
                                  while (temp.next) {
                                      temp = temp.next
                                  }
                                  temp.next = node
                              }
                          }
                          print() {
                              let temp = this.head
                              let output = ""
                              while (temp != null) {
                                  output += temp.data + " "
                                  temp = temp.next
                              }
                              console.log(output)
                          }
                          reverse(){
                          let prev = null;
                          let current = this.head;
                          let next = null;
                          while (current != null) {
                            next = current.next;
                            current.next = prev;
                            prev = current;
                            current = next;
                          }
                          this.head = prev;
                          }
                          // TC: O(n)
                          // SC: O(1)
                      }
                      
                      const l = new LinkedList();
                      
                      console.log("Push:")
                      l.push(9);
                      l.push(8);
                      l.push(10);
                      l.print()
                      console.log("Reverse")
                      l.reverse()
                      l.print()
                      `,
                        output: `Push:
                        9 8 10 
                        Reverse
                        10 8 9 `,
                      },
                    },
                  }}
                />
                <p>
                  <b>Detect loop in a linked list:</b>
                </p>
                <CodeEditor
                  options={{
                    title: "Detect loop in a linked list",
                    codes: {
                      Javascript: {
                        code: `class Node {
                          constructor(data) {
                              this.data = data
                              this.next = null
                          }
                      }
                      class LinkedList {
                          constructor() {
                              this.head = null
                          }
                          push(data) {
                              let node = new Node(data)
                              if (this.head == null) {
                                  this.head = node
                              } else {
                                  let temp = this.head
                                  while (temp.next) {
                                      temp = temp.next
                                  }
                                  temp.next = node
                              }
                          }
                          print() {
                              let temp = this.head
                              let output = ""
                              while (temp != null) {
                                  output += temp.data + " "
                                  temp = temp.next
                              }
                              console.log(output)
                          }
                      
                          loop(){
                           let temp = this.head
                           let s = new Set()
                           while(temp !== null){
                             if(s.has(temp))
                             return true
                             s.add(temp)
                             temp = temp.next
                           }
                           return false
                          }
                      
                      }
                      
                      const l = new LinkedList();
                      
                      console.log("Push:")
                      l.push(9);
                      l.push(8);
                      l.push(10);
                      l.print()
                      l.head.next = l.head
                      console.log(l.loop())
                      `,
                        output: `Push:
                        9 8 10 
                        true`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Stack (LIFO/FILO) :</b>
                </Span>
                <Span>
                  Various operations are performed in the order of Last In Fisrt
                  Out (LIFO). It's used in depth first search which is used for
                  backtracking algorithm.
                </Span>
                <Span>
                  Example: Combination of plates placed on top of each other
                </Span>
                <b>Implementation</b>
                <CodeEditor
                  options={{
                    title: "Stack (LIFO/FILO)",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        class Node{
                          constructor(data){
                            this.data = data
                            this.next = null
                          }
                        }
                        
                        class CustomStack{
                          
                          constructor(){
                            this.top = null
                          } 
                        
                          isEmpty(){
                            return this.top === null
                          }
                        
                          push(element){
                          let temp = new Node(element)
                          if(temp == null){
                            console.log("Stack Overflow")
                            return
                          }
                          temp.next = this.top
                          this.top = temp
                          }
                        
                          peek(){
                            if(this.isEmpty()){
                              console.log("Stack Underflow");
                             return -1
                            }else{
                              return this.top.data       
                            }
                          }
                        
                          pop(){
                            if(this.isEmpty()){
                              console.log("Stack Underflow");
                              return;
                            }
                            this.top = this.top.next;
                          }
                        
                          display(){
                             if(this.isEmpty()){
                               console.log("Stack Underflow")
                             }else{
                               let temp = this.top
                               let output = ""
                               while(temp !== null){
                                 output += temp.data + " "
                                 temp = temp.next
                               }
                               console.log(output)
                             }
                          }
                        
                        }
                        
                        const cs = new CustomStack()
                        console.log("Custom Stack Operations:")
                        
                        console.log("1. Push: ")
                        cs.push(10)
                        cs.push(9)
                        cs.display()
                        console.log("2. Pop: ")
                        cs.pop()
                        cs.display()
                        console.log("3. Peek: " + cs.peek())
                        console.log("4. IsEmpty: " + cs.isEmpty())                        
                      `,
                        output: `
                        Custom Stack Operations:
                        1. Push: 
                        9 10 
                        2. Pop: 
                        10 
                        3. Peek: 10
                        4. IsEmpty: false
                      `,
                      },
                    },
                  }}
                />
                <b>Utilization:</b>
                <CodeEditor
                  options={{
                    title: "Stack (LIFO/FILO)(Utilization)",
                    output: null,
                    codes: {
                      Java: {
                        code: `
                      import java.util.*;
import java.io.*;
 
public class Main {
    public static void main(String args[])
    {
        Stack<Integer> stack = new Stack<Integer>();
        stack.push(10);
        stack.push(15);
        stack.push(30);
        stack.push(20);
        stack.push(5);
        System.out.println("Initial Stack: " + stack);
        System.out.println("Pop: "
                           + stack.pop());
        System.out.println("Peek: "
                           + stack.peek());
        System.out.println("Search: "
                           + stack.search(30));
        System.out.println("Stack after pop operation "
                           + stack);
    }
}                     
                      `,
                        output: `Initial Stack: [10, 15, 30, 20, 5]
                      Pop: 5
                      Peek: 20
                      Search: 2
                      Stack after pop operation [10, 15, 30, 20]`,
                      },
                      Python: {
                        code: `from queue import LifoQueue

stack = LifoQueue(maxsize=3)
print(stack.qsize())
stack.put('a')
stack.put('b')
stack.put('c')
print("Full: ", stack.full())
print("Size: ", stack.qsize())
print('Elements popped from the stack')
print(stack.get())
print(stack.get())
print(stack.get())
print("Empty: ", stack.empty())
                      `,
                        output: `
                      0
Full:  True
Size:  3
Elements popped from the stack
c
b
a
Empty:  True
                      `,
                      },
                      "C++": {
                        code: `
                      #include <iostream>
#include <stack>
using namespace std;
int main() {
    stack<int> stack;
    stack.push(21);
    stack.push(22);
    stack.push(24);
    stack.push(25);
     
    stack.pop();
    stack.pop();

    while (!stack.empty()) {
        cout << stack.top() << " ";
        stack.pop();
    }
    cout << endl;
    return 0;
}                      `,
                        output: `22 21`,
                      },
                      Kotlin: {
                        code: `
                        import java.util.*

object Main {
    @JvmStatic
    fun main(args: Array<String>) {
        val stack = Stack<Int>()
        stack.push(10)
        stack.push(15)
        stack.push(30)
        stack.push(20)
        stack.push(5)
        println("Initial Stack: $stack")
        println(
            "Pop: " +
                    stack.pop()
        )
        println(
            "Peek: " +
                    stack.peek()
        )
        println(
            "Search: " +
                    stack.search(30)
        )
        println(
            "Stack after pop operation " +
                    stack
        )
    }
}
                        `,
                        output: `Initial Stack: [10, 15, 30, 20, 5]
                      Pop: 5
                      Peek: 20
                      Search: 2
                      Stack after pop operation [10, 15, 30, 20]`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Queue (FIFO) :</b>
                </Span>
                <Span>
                  Various operations are performed in the order of First In
                  Fisrt Out (FIFO). It's used in breadth first search which is
                  used for branch and bound algorithm.
                </Span>
                <Span>
                  Example: people organised in the form of line for passport
                  verification.
                </Span>
                <b>Implementation</b>
                <CodeEditor
                  options={{
                    title: "Queue (FIFO)",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                      class Node{
                        constructor(data){
                          this.data = data
                          this.next = null
                        }
                      }
                      
                      // const MAX_SIZE = 1000
                      // SIZE === MAX_SIZE - 1
                      // -> stack overflow 
                      
                      class CustomQueue{
                        
                        constructor(){
                          this.front = null
                          this.rear = null
                        } 
                      
                        isEmpty(){
                          return this.front === null
                        }
                      
                        enqueue(data){
                          let temp = new Node(data);
                            
                          if(temp === null){
                            console.log("Stack Overflow")
                            return
                          }
                      
                          if (this.isEmpty()) {
                            this.front = this.rear = temp;
                            return;
                          }
                          this.rear.next = temp;
                          this.rear = temp;
                        }
                      
                       dequeue(){
                           if(this.isEmpty()){
                             console.log("Stack Underflow")
                             return
                           }
                           let temp = new Node()
                           temp = this.front;
                           this.front = this.front.next;
                           if (this.front == null)
                            this.rear = null;
                       }
                      
                       display(){
                        let temp = this.front
                        let output = ""
                        if(this.isEmpty()){
                             console.log("Stack Underflow")
                             return
                        }
                        while(temp !== null){
                         output += temp.data + " "
                         temp = temp.next
                        }
                        console.log(output)
                       }
                      
                      }
                      
                      const cq = new CustomQueue()
                      console.log("Custom Queue Operations:")
                      
                      console.log("1. Enqueue: ")
                      cq.enqueue(9)
                      cq.enqueue(7)
                      cq.display()
                      console.log("2. Dequeue: ")
                      cq.dequeue()
                      cq.display()
                      console.log("3. IsEmpty: " + cq.isEmpty())                      
                      `,
                        output: `
                      Custom Queue Operations:
1. Enqueue: 
9 7 
2. Dequeue: 
7 
3. IsEmpty: false
                      `,
                      },
                    },
                  }}
                />
                <b>Utilization:</b>
                <CodeEditor
                  options={{
                    title: "Queue (FIFO)(Utilization)",
                    output: null,
                    codes: {
                      Java: {
                        code: `
                      import java.util.*;
 
public class Main {
 
    public static void main(String[] args)
    {
        Queue<Integer> q
            = new LinkedList<>();

        Queue<Integer> q1
            = new PriorityQueue<>();
        
        for (int i = 0; i < 5; i++)
            q.add(i);
 
        System.out.println("Elements of queue "
                           + q);
        int removedele = q.remove();
        System.out.println("removed element-"
                           + removedele);
 
        System.out.println(q);
        int head = q.peek();
        System.out.println("head of queue-"
                           + head);

        int size = q.size();
        System.out.println("Size of queue-"
                           + size);
    }
}
                      `,
                        output: `
                      Elements of queue [0, 1, 2, 3, 4]
removed element-0
[1, 2, 3, 4]
head of queue-1
Size of queue-4
                      `,
                      },
                      Python: {
                        code: `from collections import deque
 
q = deque()
 
q.append('a')
q.append('b')
q.append('c')
 
print(q)
print(q.popleft())
print(q.popleft())
print(q.popleft())
 
print(q)
                      `,
                        output: `
                      deque(['a', 'b', 'c'])
a
b
c
deque([])
                      `,
                      },
                      "C++": {
                        code: `
                      #include <iostream>
#include <queue>
  
using namespace std;
  
void display(queue<int> q)
{
    queue<int> g = q;
    while (!g.empty()) {
        cout << g.front() << ' ';
        g.pop();
    }
    cout << endl;
}
  
int main()
{
    queue<int> q;
    q.push(10);
    q.push(20);
    q.push(30);
  
    cout << "The queue gquiz is : ";
    display(q);
  
    cout << "size() : " << q.size() << endl;
    cout << "front() : " << q.front() << endl;
    cout << "back() : " << q.back() << endl;
    cout << "pop()";
    q.pop();
    cout << endl;
    display(q);
  
    return 0;
}
                     `,
                        output: `
                     The queue gquiz is : 10 20 30 
size() : 3
front() : 10
back() : 30
pop()
20 30 
                     `,
                      },
                      Kotlin: {
                        code: `import java.util.*

                        object Main {
                            @JvmStatic
                            fun main(args: Array<String>) {
                                val q: Queue<Int> = LinkedList()
                                val q1: Queue<Int> = PriorityQueue()
                                for (i in 0..4) q.add(i)
                                println(
                                    "Elements of queue " +
                                            q
                                )
                                val removedele = q.remove()
                                println(
                                    "removed element-" +
                                            removedele
                                )
                                println(q)
                                val head = q.peek()
                                println(
                                    "head of queue-" +
                                            head
                                )
                                val size = q.size
                                println(
                                    "Size of queue-" +
                                            size
                                )
                            }
                        }`,
                        output: `
                      Elements of queue [0, 1, 2, 3, 4]
removed element-0
[1, 2, 3, 4]
head of queue-1
Size of queue-4
                      `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Priority Queue:</b>
                </Span>
                <Span>
                  In this type of queue, every item has a associated priority
                  and an element with high priority will be dequeued first. If
                  they both have the same priority then order will be
                  considered. *Priority will be considered during insertion
                  which helps to easily remove a element from the queue.
                </Span>
                <Span>Applications: Dijkstra, Prim's, etc.</Span>
                <b>Utilization:</b>
                <CodeEditor
                  options={{
                    title: "Priority Queue",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                      class Element{
                        constructor(ele,priority){
                          this.data = ele
                          this.priority = priority
                        }
                      }
                      
                      class PriorityQueue{
                         constructor()
                          {
                              this.items = [];
                          }
                      
                          enqueue(ele,priority){
                          var qe = new Element(ele, priority);
                          var contain = false;
                          for (var i = 0; i < this.items.length; i++) {
                              if (this.items[i].priority > qe.priority) {
                                  this.items.splice(i, 0, qe);
                                  // insertion
                                  contain = true;
                                  break;
                              }
                          }
                          if (!contain) {
                              this.items.push(qe);
                          }
                          }
                      
                          dequeue(){
                          if (this.isEmpty())
                              return "Underflow";
                          // deletion from front
                          return this.items.shift();
                          }
                           
                          display(){
                          var str = "";
                          for (var i = 0; i < this.items.length; i++)
                              str += this.items[i].data + " ";
                          // reduce 
                          console.log(str);
                          }
                      
                          isEmpty(){
                          return this.items.length == 0;
                          }
                      
                          front(){
                          if (this.isEmpty())
                              return "No elements in Queue";
                          return JSON.stringify(this.items[0]);
                          }
                      
                          rear(){
                          if (this.isEmpty())
                              return "No elements in Queue";
                          return JSON.stringify(this.items[this.items.length - 1]);
                          }
                      
                      }
                      
                      const pq = new PriorityQueue()
                      console.log("Priority Queue Operations: ")
                      console.log("Enqueue: ")
                      pq.enqueue(9)
                      pq.enqueue(8)
                      pq.display()
                      console.log("Dequeue: ")
                      pq.dequeue()
                      pq.display()
                      console.log("Front: " + pq.front())
                      console.log("Rear: " + pq.rear())
                      console.log("isEmpty: " + pq.isEmpty())                      
                      `,
                        output: `
                      Priority Queue Operations: 
Enqueue: 
9 8 
Dequeue: 
8 
Front: {"data":8}
Rear: {"data":8}
isEmpty: false
                      `,
                      },
                      Java: {
                        code: `
                      import java.util.*;
                      public class Main {
                        public static void main(String[] args)
                        {
                              PriorityQueue<Integer> pq = new PriorityQueue<Integer>();
                              pq.add(10);
                              pq.add(20);
                              pq.add(15);
                              System.out.print(pq.peek() + " ");
                              System.out.print(pq.poll() + " ");
                              System.out.print(pq.peek()+ " ");
                              pq.remove(20);
                              Iterator it = pq.iterator();
                        
                              while (it.hasNext()) {
                                  System.out.print(it.next() + " ");
                              }
                      
                              PriorityQueue<Integer> maxPQ = new PriorityQueue<>(Collections.reverseOrder()); 
                              PriorityQueue<Integer> maxPQ1 = new PriorityQueue<>((a,b) -> b - a); 
                              PriorityQueue<Integer> maxPQ2 = new PriorityQueue<>((a,b) -> b.compareTo(a)); 
                      
                              PriorityQueue<Integer> pq1 = new PriorityQueue<Integer> (
                              new Comparator<Integer> () {
                              public int compare(Integer a, Integer b) {
                             return b - a;
                             }
                        }
                      ); 
                            pq1.add(20);
                            pq1.add(30);
                            Iterator it1 = pq1.iterator();
                                System.out.println();
                              while (it1.hasNext()) {
                                  System.out.print(it1.next() + " ");
                              }
                        }
                      }                      
                      `,
                        output: `
                      10 10 15 15 
30 20
                      `,
                      },
                      Python: {
                        code: `from queue import PriorityQueue
pq = PriorityQueue()
pq.put((2, "A"))
pq.put((3, "B"))
pq.put((1, "C"))
pq.put((4, "D"))
while pq:
     print(pq.get())
pq.pop()

                      `,
                        output: `
(1, 'C')
(2, 'A')
(3, 'B')
(4, 'D')
                      `,
                      },
                      "C++": {
                        code: `
                      #include <iostream>
#include <queue>
#include <bits/stdc++.h>

using namespace std;

template<class T>
void display(T& q)
{
    while (!q.empty())
    {
        cout << q.top() << endl;
        q.pop();
    }
}
 
struct CustomCompare
{
    bool operator()(const int& lhs, const int& rhs)
    {
        return lhs < rhs;
    }
};

int main()
{
	priority_queue<int> pq;
  priority_queue<int,vector<int>,greater<int>> pq1;                      
  priority_queue<int,vector<int>, CustomCompare> pq2;

	pq.push(10);
	pq.push(30);
	pq.push(20);
	pq1.push(5);
	pq1.push(1);
	cout << "size() : " << pq.size() << endl;
	cout << "top() : " << pq.top() << endl;
	cout << "pop() : " << endl;
  pq.pop();
  display(pq);
  display(pq1);
  pq2.push(9);
  pq2.push(8);
  display(pq2);
	return 0;
}
                      `,
                        output: `
                      size() : 3
top() : 30
pop() : 
20
10
1
5
9
8
                      `,
                      },
                      Kotlin: {
                        code: `import java.util.PriorityQueue

                        fun main(args: Array<String>) {
                            val nums: PriorityQueue<Int> = PriorityQueue<Int>()
                        
                            // Add items (enqueue)
                            nums.add(800)
                            nums.add(50)
                            nums.add(200)
                            nums.add(550)
                        
                            println("peek: " + nums.peek())
                        
                            // Remove items (dequeue)
                            while (!nums.isEmpty()) {
                                println(nums.remove())
                            }
                        }`,
                        output: `peek: 50
                        50
                        200
                        550
                        800
                        `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Circular Queue:</b>
                </Span>
                <Span>
                  It's similar to a circular linked list which is used to avoid
                  memory wastage. We make of two pointers: front and rear
                  instead of one which is used at circular singly linked list
                </Span>
                <Span>
                  <b>Implementation</b>
                </Span>
                <span>(a) Without using mod operator:</span>
                <CodeEditor
                  options={{
                    title: "Circular Queue(Without using mod operator)",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        class Node {
                          constructor(data) {
                              this.data = data
                              this.next = null
                          }
                      }
                      
                      class CircularQueue {
                      
                          constructor() {
                              this.front = null
                              this.rear = null
                          }
                      
                          isEmpty() {
                              return this.front === null
                          }
                      
                          enqueue(data) {
                              let temp = new Node(data);
                      
                              if (temp === null) {
                                  console.log("Stack Overflow")
                                  return
                              }
                      
                              if (this.isEmpty()) {
                                  this.front = temp;
                              } else {
                                  this.rear.next = temp
                              }
                              this.rear = temp;
                              this.rear.next = this.front;
                          }
                      
                          dequeue() {
                              if (this.isEmpty()) {
                                  console.log("Stack Underflow")
                                  return
                              }
                              let value = null
                              if (this.front === this.rear) {
                                  value = this.front.data
                                  this.front = null
                                  this.rear = null
                              } else {
                                  let temp = this.front
                                  value = temp.data
                                  this.front = this.front.next
                                  this.rear.next = this.front
                              }
                              return value
                          }
                      
                          display() {
                              let temp = this.front
                              let output = ""
                              if (this.isEmpty()) {
                                  console.log("Stack Underflow")
                                  return
                              }
                              if (temp.next == temp) {
                                  output = temp.data
                              } else {
                                  while (temp.next != this.front) {
                                      output += temp.data + " "
                                      temp = temp.next
                                  }
                                  output += temp.data
                              }
                              console.log(output)
                          }
                      
                      }
                      
                      const cq = new CircularQueue()
                      console.log("Circular Queue Operations:")
                      
                      console.log("1. Enqueue: ")
                      cq.enqueue(9)
                      cq.enqueue(7)
                      cq.display()
                      console.log("2. Dequeue: ")
                      cq.dequeue()
                      cq.display()
                      console.log("3. IsEmpty: " + cq.isEmpty())                    
                      `,
                        output: `
                        Circular Queue Operations:
                        1. Enqueue: 
                        9 7 
                        2. Dequeue: 
                        7 
                        3. IsEmpty: false
                      `,
                      },
                    },
                  }}
                />
                <span>(b) Using mod operator:</span>
                <CodeEditor
                  options={{
                    title: "Circular Queue(Using mod operator)",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        class CustomQueue{
    
                          constructor(max_size){
                             this.max_size = max_size
                             this.queue = new Array(max_size).fill(null)
                             this.front = this.rear = -1       
                          }
                        
                          isEmpty(){
                            return this.front === -1
                          }
                        
                          enqueue(data){
                            if ((this.rear + 1) % this.max_size === this.front){
                              console.log("The circular queue is full\n")
                            }else if(this.isEmpty()){
                              this.front = 0
                              this.rear = 0
                              this.queue[this.rear] = data
                            }else{
                              this.rear = (this.rear + 1) % this.max_size
                              this.queue[this.rear] = data
                            }
                          }
                        
                        
                          dequeue(){
                            let temp = null
                            if (this.isEmpty()){
                              console.log("The circular queue is empty")
                            }
                            else if(this.front === this.rear){
                              temp = this.queue[this.front]
                              this.front = -1
                              this.rear = -1
                              return temp
                            }
                            else{
                              temp = this.queue[this.front]
                              this.front = (this.front + 1) % this.max_size
                              return temp
                            }
                          }
                        
                          display(){
                             let output = ""
                             if(this.isEmpty()){
                              console.log("No element in the circular queue")
                             }else if(this.rear >= this.front){
                              for(let i=this.front;i<=this.rear;i++){
                                 output += this.queue[i] + " "
                              }
                             }else{
                              for(let i=this.front;i<this.max_size;i++){
                                 output += this.queue[i] + " "
                              }   
                              for(let i=0;i<=this.rear;i++){
                                 output += this.queue[i] + " "
                              }   
                             }
                             console.log(output)
                          }
                        
                        }
                        
                        const cq = new CustomQueue(5); 
                        console.log("Circular Queue Operations:")
                        console.log("1. Enqueue: ")
                        cq.enqueue(9)
                        cq.enqueue(7)
                        cq.display()
                        console.log("2. Dequeue: ")
                        cq.dequeue()
                        cq.display()
                        console.log("3. IsEmpty: " + cq.isEmpty())  
                        `,
                        output: `
                        Circular Queue Operations:
                        1. Enqueue: 
                        9 7 
                        2. Dequeue: 
                        7 
                        3. IsEmpty: false
                      `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Deque:</b>
                </Span>
                <Span>
                  It allows insertion and deletion at both ends of the data
                  structure.
                </Span>
                <b>Utilization:</b>
                <CodeEditor
                  options={{
                    title: "Deque",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                      function Deque()
{
 this.stack=new Array();
 this.popback=function(){
  return this.stack.pop();
 }
 this.pushback=function(item){
  this.stack.push(item);
 }
 this.popfront=function(){
  return this.stack.shift();
 }
 this.pushfront=function(item){
  this.stack.unshift(item);
 }
 this.display=function(){
   console.log(this.stack)
 }
}

var deque= new Deque();

deque.pushfront("A");
deque.pushfront("B");
deque.display()
deque.pushback("C");
deque.display()
deque.popfront();
deque.display()
deque.popback();
deque.display()
                      `,
                        output: `
                      [ 'B', 'A' ]
[ 'B', 'A', 'C' ]
[ 'A', 'C' ]
[ 'A' ]
                      `,
                      },
                      Java: {
                        code: `
                      import java.util.*;
public class Main {
	public static void main(String[] args)
	{
		Deque<String> dq
			= new ArrayDeque<String>();
// new LinkedList<String>()

		dq.add("A");
		dq.addFirst("B");
		dq.addLast("C");
    for (Iterator it = dq.iterator();
         it.hasNext();) {
        System.out.print(it.next() + " ");
    }
    System.out.println();
    for (Iterator it = dq.descendingIterator();
         it.hasNext();) {
        System.out.print(it.next() + " ");
    }
    System.out.println();
    System.out.println(dq);
    System.out.println(dq.pop());
    System.out.println(dq.poll());
    System.out.println(dq);
    System.out.println(dq.pollFirst());
    System.out.println(dq.pollLast());
    dq.clear();
	  System.out.println(dq);
// Those methods retrieve the first element 
// and remove it from the list. The difference 
// between poll() and pop() is that pop will
// throw NoSuchElementException() on empty list, 
// whereas poll returns null.

	}
}
                      `,
                        output: `
                      B A C 
C A B 
[B, A, C]
B
A
[C]
C
null
[]
                      `,
                      },
                      Python: {
                        code: `import collections
de = collections.deque([9,7,8,4])
de.append(4)
print(de)
de.appendleft(6)
print(de)
de.pop()
print(de)
de.popleft()
print(de)
print(de.index(4,2,5))
de.insert(4,3)
print (de)
print (de.count(3))
de.remove(3)
de.extend([4,5,6])
print(de)
de.extendleft([7,8,9])
print(de)
de.rotate(-3)
print(de)
de.reverse()
print (de)
                      `,
                        output: `
                      deque([9, 7, 8, 4, 4])
deque([6, 9, 7, 8, 4, 4])
deque([6, 9, 7, 8, 4])
deque([9, 7, 8, 4])
3
deque([9, 7, 8, 4, 3])
1
deque([9, 7, 8, 4, 4, 5, 6])
deque([9, 8, 7, 9, 7, 8, 4, 4, 5, 6])
deque([9, 7, 8, 4, 4, 5, 6, 9, 8, 7])
deque([7, 8, 9, 6, 5, 4, 4, 8, 7, 9])
                      `,
                      },
                      "C++": {
                        code: `
                      #include <iostream>
#include <deque>

using namespace std;

void display(deque <int> q)
{
	deque <int> :: iterator it;
	for (it = q.begin(); it != q.end(); ++it)
		cout << *it << '\t';
	cout << '\n';
}

int main()
{
	deque <int> dq;
	dq.push_back(9);
	dq.push_front(8);
	dq.push_back(6);
	dq.push_front(7);
	display(dq);

  cout << "Deque Operations: " << endl;
	cout << "size() : " << dq.size()<< endl;
	cout << "max_size() : " << dq.max_size()<< endl;
	cout << "at(2) : " << dq.at(2)<< endl;
	cout << "front() : " << dq.front()<< endl;
	cout << "back() : " << dq.back()<< endl;
	cout << "pop_front() : "<< endl;
	dq.pop_front();
	display(dq);
	cout << "pop_back() : "<< endl;
	dq.pop_back();
	display(dq);

	return 0;
}
                      `,
                        output: `
                      7   8   9   6
Deque Operations: 
size() : 4
max_size() : 4611686018427387903
at(2) : 9
front() : 7
back() : 6
pop_front() : 
8   9   6
pop_back() : 
8   9
                      `,
                      },
                      Kotlin: {
                        code: `import java.util.*

                        object Main {
                            @JvmStatic
                            fun main(args: Array<String>) {
                                val dq: Deque<String> = ArrayDeque()
                                // new LinkedList<String>()
                                dq.add("A")
                                dq.addFirst("B")
                                dq.addLast("C")
                                run {
                                    val it: Iterator<*> = dq.iterator()
                                    while (it.hasNext()) {
                                        print(it.next().toString() + " ")
                                    }
                                }
                                println()
                                val it: Iterator<*> = dq.descendingIterator()
                                while (it.hasNext()) {
                                    print(it.next().toString() + " ")
                                }
                                println()
                                println(dq)
                                println(dq.pop())
                                println(dq.poll())
                                println(dq)
                                println(dq.pollFirst())
                                println(dq.pollLast())
                                dq.clear()
                                println(dq)
                                // Those methods retrieve the first element 
                                // and remove it from the list. The difference 
                                // between poll() and pop() is that pop will
                                // throw NoSuchElementException() on empty list, 
                                // whereas poll returns null.
                            }
                        }`,
                        output: `
                      B A C 
                      C A B 
                      [B, A, C]
                      B
                      A
                      [C]
                      C
                      null
                      []
                      `,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Non Linear",
            content: (
              <>
                <Span>
                  In <b>non linear data structure</b>, elements are not arranged
                  in a sequential way hence they cannot be traveresed in a
                  single run as multiple levels are involved.
                </Span>
                <Span>Applications: ML, AI , etc.</Span>
                <Span>e.g. Trees, Graph and Tries etc.</Span>
                <Span>
                  <b>Tree</b>
                </Span>
                <Span>
                  It consists of elements organised in a hierarchical way such
                  that there can be only one connection in between two elements.
                </Span>
                <Span>Let n =&gt; number of nodes</Span>
                <Span>
                  <b>Catalian Number:</b> 2nCn/(n+1) or (2n)!/(n+1)
                </Span>
                <Span>
                  <b>Terminology:</b>
                </Span>
                <Span>
                  <b>Root </b>: The first single node from where the tree
                  originates.
                </Span>
                <Span>
                  <b>Edge </b>: The connecting link between any two nodes is
                  called as an edge. NUmber of edges = n-1
                </Span>
                <Span>
                  <b>Parent </b>: The node which has a branch from it to any
                  other node
                </Span>
                <Span>
                  <b>Child </b>: The node which is a descendant of some node
                </Span>
                <Span>
                  <b>Siblings: </b>Nodes which belong to the same parent
                </Span>
                <Span>
                  <b>Degree of a node: </b>total number of children of that
                  node.
                </Span>
                <Span>
                  <b>Degree of a tree: </b>highest degree of a node among all
                  the nodes in the tree.
                </Span>
                <Span>
                  <b>Internal (non-terminal / non-leaf) node: </b>node which has
                  at least one child.
                </Span>
                <Span>
                  <b>External (terminal / leaf) node: </b>the node which does
                  not have any child
                </Span>
                <Span>
                  <b>Level: </b>each step from top to bottom ( 0 to height of
                  tree )
                </Span>
                <Span>
                  <b>Height of a node: </b>Total number of edges that lies on
                  the longest path from deepest leaf node to a particular node
                </Span>
                <Span>
                  <b>Height of a tree: </b>height of root node.
                </Span>
                <Span>
                  <b>Depth of a tree: </b> It's same as level of the tree and is
                  computed with the help of edges that lie between root node to
                  that particular node.
                </Span>
                <Span>
                  The root node has depth zero, leaf nodes have height zero, and
                  a tree with only a single node (hence both a root and leaf)
                  has depth and height zero.
                </Span>
                <Span>
                  <b>Subtree: </b>each child from a node forms another tree
                  recursively.
                </Span>
                <Span>
                  <b>Forest: </b>collection of trees obtained from omitting the
                  root.
                </Span>
                <Span>
                  <b>Types:</b>
                </Span>
                {/* https://www.educba.com/types-of-trees-in-data-structure/ */}
                <Span>
                  <b>1. General Tree</b>
                </Span>
                <p>
                  If no constraint is placed on the tree’s hierarchy, a tree is
                  called a general tree
                </p>
                <CodeEditor
                  options={{
                    title: "General Tree",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        class Node {
                          constructor(val) {
                            this.data = val;
                            this.left = null;
                            this.right = null;
                            // left1 
                            // right1
                            // we can add more links as
                            // well in case of general tree
                          }
                        }
                      
                      function inorder(root){
                        if(root!=null){
                          inorder(root.left)
                          console.log(root.data)
                          inorder(root.right)
                        }
                      }
                      
                      let root = null;
                      root = new Node(1);
                      root.left = new Node(2);
                      root.right = new Node(3);
                      root.left.left = new Node(4);
                      inorder(root)                      
                        `,
                        output: `
                        4
2
1
3
                        `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>2. Binary Tree</b>
                </Span>
                <Span>
                  The binary tree is the kind of tree in which most two children
                  can be found for each parent and no constraints are applied at
                  simple binary tree.
                </Span>
                <Span>
                  <b>Types:</b>
                </Span>
                <Span>a. Extended Binary Tree</Span>
                <p>
                  Extended binary tree is a type of binary tree in which all the
                  null sub tree of the original tree are replaced with special
                  nodes called external nodes whereas other nodes are called
                  internal nodes
                </p>
                <CodeEditor
                  options={{
                    title: "Extended Binary Tree",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `class Node
                        {
                            constructor()
                            {
                                this.key = 0;
                                this.left = null;
                                this.right = null;
                            }
                        };
                         
                        function createNode(key)
                        {
                            var temp = new Node();
                            temp.key = key;
                            temp.left = temp.right = null;
                            return (temp);
                        }
                        
                        function inorder(root){
                          traverse(root.left);
                          console.log(root.key + " ");
                          traverse(root.right);
                        }
                         
                        function traverse(root)
                        {
                            if (root != null)
                              inorder(root)
                            else
                            {
                                root = createNode(-1);
                                console.log(root.key + " ");
                            }
                        }
                         
                        const root = createNode(1);
                        root.left = createNode(2);
                        root.right = createNode(3);
                        root.left.left = createNode(5);
                        root.right.right = createNode(4);
                        traverse(root);`,
                        output: `-1 
                        5 
                        -1 
                        2 
                        -1 
                        1 
                        -1 
                        3 
                        -1 
                        4 
                        -1 `,
                      },
                    },
                  }}
                />
                <Span>b. Full Binary Tree</Span>
                <Span>
                  In this type of tree, all internal nodes will have two
                  children
                </Span>
                <Span>c. Complete Binary Tree</Span>
                <Span>
                  Binary Tree is a Complete Binary Tree if all the levels are
                  completely filled except possibly the last level and the last
                  level has all keys as left as possible{" "}
                </Span>
                <Span>d. Perfect Binary Tree</Span>
                <Span>
                  A Binary tree is a Perfect Binary Tree in which all the
                  internal nodes have two children and all leaf nodes are at the
                  same level.{" "}
                </Span>
                <Span>No. of leaf nodes = No. of internal nodes + 1</Span>
                <Span>e. Balanced Binary Tree</Span>
                <Span>
                  A binary tree is balanced if the height of the tree is O(Log
                  n) where n is the number of nodes. Height of left and right
                  subtree should't differ by 1. e.g. AVL, Red black.They provide
                  O(log n) for insert,search and delete.
                </Span>
                <Span>f. Degenerate(Pathological) Binary Tree</Span>
                <Span>
                  A Tree where every internal node has one child. Such trees are
                  performance-wise same as linked list.
                </Span>
                <p>
                  <b>Mirror (Invert) of binary tree</b>
                </p>
                <CodeEditor
                  options={{
                    title: "Mirror (Invert) of binary tree",
                    codes: {
                      Javascript: {
                        code: `class Node {
                          constructor(val) {
                              this.data = val;
                              this.left = null;
                              this.right = null;
                          }
                      }
                      
                      class BinaryTree {
                        constructor(){
                          this.root = null
                        }
                      
                        inorder(root=this.root){
                          if (root != null) {
                              this.inorder(root.left)
                              console.log(root.data)
                              this.inorder(root.right)
                          }
                        }
                      
                        // while doing bfs keep swapping
                        // left and right
                      
                        mirror(){
                          let queue = []
                          queue.push(this.root)
                          while (queue.length > 0)
                          {
                          let curr = queue.shift();;
                          let temp = curr.left;
                          curr.left = curr.right;
                          curr.right = temp;;
                          if (curr.left)
                            queue.push(curr.left);
                          if (curr.right)
                            queue.push(curr.right);
                          }
                        }

                        // mirror(){
                        //   let queue = []
                        //   let height = 0
                        //   queue.push(this.root)
                        //   while(queue.length){
                        //     let curr = queue.shift()
                        //     // console.log(curr.data)
                        //     if(curr.left)
                        //     [curr.left,curr.right] = [curr.right,curr.left]
                        //     height++
                        //     if(curr.left) queue.push(curr.left)
                        //     if(curr.right) queue.push(curr.right)
                        //   }
                        //   console.log({ height })
                        // }
                      
                      }
                      
                      let tree = new BinaryTree();
                      tree.root = new Node(1);
                      tree.root.left = new Node(2);
                      tree.root.right = new Node(3);
                      tree.root.left.left = new Node(4);
                      console.log("Inorder Traversals: ")
                      console.log("Before Mirroring:")
                      tree.inorder()
                      console.log("After Mirroring:")
                      tree.mirror()
                      tree.inorder()
                      `,
                        output: `4
                        2
                        1
                        3
                        Mirror
                        3
                        1
                        2
                        4`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Left View Of Binary Tree</b>
                </Span>
                <Span>
                  Left view of a Binary Tree is set of nodes visible when tree
                  is visited from left side.
                </Span>
                <Img left src={LeftViewBT} reduceH />
                <CodeEditor
                  options={{
                    title: "Left View Of Binary Tree",
                    codes: {
                      Javascript: {
                        code: `class Node {
                          constructor(val) {
                              this.data = val;
                              this.left = null;
                              this.right = null;
                          }
                      }
                      
                      class BinaryTree {
                          constructor() {
                              this.root = null
                          }
                      
                          inorder(root = this.root) {
                              if (root != null) {
                                  this.inorder(root.left)
                                  console.log(root.data)
                                  this.inorder(root.right)
                              }
                          }
                      
                          // keep track of left most node
                          // at each level of bfs ( level order 
                          // traversal ) with the help of a queue
                      
                          leftView() {
                              let queue = []
                              queue.push(this.root)
                              while (queue.length > 0) {
                                const n = queue.length
                                for(let i=0;i<n;i++){
                                  let curr = queue.shift();
                                  if(i==0)
                                  console.log(curr.data)
                                  if (curr.left)
                                    queue.push(curr.left);
                                  if (curr.right)
                                    queue.push(curr.right);
                                 }
                              }
                          }
                      
                      }
                      
                      let tree = new BinaryTree();
                      tree.root = new Node(1);
                      tree.root.left = new Node(2);
                      tree.root.right = new Node(3);
                      tree.root.right.left = new Node(4);
                      console.log("Inorder Traversal: ")
                      tree.inorder()
                      console.log("Left View Of Binary Tree:")
                      tree.leftView()`,
                        output: `Inorder Traversal: 
                        2
                        1
                        4
                        3
                        Left View Of Binary Tree:
                        1
                        2
                        4`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Right View Of Binary Tree</b>
                </Span>
                <Span>
                  Right view of a Binary Tree is set of nodes visible when tree
                  is visited from Right side.
                </Span>
                <Img left src={RightViewBT} reduceH />
                <CodeEditor
                  options={{
                    title: "Right View Of Binary Tree",
                    codes: {
                      Javascript: {
                        code: `class Node {
                          constructor(val) {
                              this.data = val;
                              this.left = null;
                              this.right = null;
                          }
                      }
                      
                      class BinaryTree {
                          constructor() {
                              this.root = null
                          }
                      
                          inorder(root = this.root) {
                              if (root != null) {
                                  this.inorder(root.left)
                                  console.log(root.data)
                                  this.inorder(root.right)
                              }
                          }
                      
                          // keep track of right most node
                          // at each level of bfs ( level order 
                          // traversal ) with the help of a queue
                      
                          rightView() {
                              let queue = []
                              queue.push(this.root)
                              while (queue.length > 0) {
                                const n = queue.length
                                for(let i=0;i<n;i++){
                                  let curr = queue.shift();
                                  if(i==n-1)
                                  console.log(curr.data)
                                  if (curr.left)
                                    queue.push(curr.left);
                                  if (curr.right)
                                    queue.push(curr.right);
                                 }
                              }
                          }
                      
                      }
                      
                      let tree = new BinaryTree();
                      tree.root = new Node(1);
                      tree.root.left = new Node(2);
                      tree.root.right = new Node(3);
                      tree.root.right.left = new Node(4);
                      console.log("Inorder Traversal: ")
                      tree.inorder()
                      console.log("Right View Of Binary Tree:")
                      tree.rightView()`,
                        output: `Inorder Traversal: 
                        2
                        1
                        4
                        3
                        Right View Of Binary Tree:
                        1
                        3
                        4`,
                      },
                    },
                  }}
                />
                <p>
                  <b>Print all paths from root to leaf</b>
                </p>
                <CodeEditor
                  options={{
                    title: "Print all paths from root to leaf",
                    codes: {
                      Javascript: {
                        code: `function dfs(root, result, curr = [], pathLen = 0) {
                          if (root) {
                            curr[pathLen] = root.data;
                            pathLen++;
                            if (!root.left && !root.right) {
                              result.push(curr.slice());
                              return;
                            }
                            dfs(root.left, result, curr, pathLen);
                            dfs(root.right, result, curr, pathLen);
                          }
                        }
                        function paths(t) {
                          const result = [];
                          dfs(t, result);
                          console.log(result);
                        }
                        const t = new TreeNode(2);
                        t.left = new TreeNode(1);
                        t.right = new TreeNode(3);
                        paths(t);
                        `,
                        output: `[ [ 2, 1 ], [ 2, 3 ] ]`,
                      },
                    },
                  }}
                />
                <p>
                  <b>Sum of all tree nodes</b>
                </p>
                <CodeEditor
                  options={{
                    title: "Print all paths from root to leaf",
                    codes: {
                      Javascript: {
                        code: `function dfs(root, result, sum = 0) {
                          if (root) {
                            sum += root.data;
                            if (!root.left && !root.right) {
                              result.push(sum);
                              return;
                            }
                            dfs(root.left, result, sum);
                            dfs(root.right, result, sum);
                          }
                        }
                        function sum(t) {
                          const result = [];
                          dfs(t, result);
                          console.log(result);
                        }
                        `,
                        output: `[ 3, 5 ]`,
                      },
                    },
                  }}
                />
                <p>
                  <b>delete node when sum &lt;=k</b>
                </p>
                <CodeEditor
                  options={{
                    title: "delete nodes when sum &lt;=k",
                    codes: {
                      Javascript: {
                        code: `function dfsH(root, k, sum = 0) {
                          if (root) {
                            let lsum = sum + root.data;
                            let rsum = lsum;
                            root.left = dfsH(root.left, k, lsum);
                            root.right = dfsH(root.right, k, rsum);
                            sum = Math.max(lsum, rsum);
                            if (sum < k) root = null;
                            return root;
                          }
                        }
                        function sumLK(t) {
                          const k = 4;
                          dfsH(t, k);
                          console.log(t);
                        }
                        const t = new TreeNode(2);
                        t.left = new TreeNode(1);
                        t.right = new TreeNode(3);
                        sumLK(t);
                        `,
                        output: `TreeNode {
                          data: 2,
                          left: null,
                          right: TreeNode { data: 3, left: undefined, right: undefined }
                        }`,
                      },
                    },
                  }}
                />
                <p>
                  <b>A program to check if a binary tree is BST or not</b>
                </p>
                <CodeEditor
                  options={{
                    title: "A program to check if a binary tree is BST or not",
                    codes: {
                      Javascript: {
                        code: `class Node
                        {
                            constructor(data)
                            {
                               this.left = null;
                               this.right = null;
                               this.data = data;
                            }
                        }
                        let prev;
                        function isBSTUtil(root)
                        {
                            if (root != null){
                                if (!isBSTUtil(root.left))
                                    return false;
                                if (prev && root.data <= prev.data)
                                    return false;
                                prev = root;                     
                                return isBSTUtil(root.right);
                            }
                            return true;
                        }
                        function isBST(root)
                        {
                            return isBSTUtil(root);
                        }
                        let root = new Node(3);
                        root.left = new Node(2);
                        root.right = new Node(5);
                        root.left.left = new Node(1);
                        root.left.right = new Node(4);
                        if (isBST(root))
                            console.log("Is BST");
                        else
                            console.log("Not a BST");`,
                        output: `Not a BST`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Flip binary tree</b>
                </Span>
                <Img
                  left
                  src="https://media.geeksforgeeks.org/wp-content/cdn-uploads/tree42-1024x381.png"
                />
                <p>
                  In the flip operation, the leftmost node becomes the root of
                  the flipped tree and its parent becomes its right child and
                  the right sibling becomes its left child and the same should
                  be done for all left most nodes recursively.
                </p>
                <CodeEditor
                  options={{
                    title: "Flip binary tree",
                    codes: {
                      Javascript: {
                        code: `class Node {
                          constructor(val) {
                              this.data = val;
                              this.left = null;
                              this.right = null;
                          }
                      }
                      
                      class BinaryTree {
                        constructor(){
                          this.root = null
                        }
                      
                        flip(){
                          this.root = this.flipBinaryTree(this.root)
                        }
                      
                       flipBinaryTree(root)
                          {
                               if (root == null)
                                  return root;
                              if (root.left == null && root.right ==null)
                                  return root;
                                let flippedRoot=this.flipBinaryTree(root.left);
                              root.left.left=root.right;
                              root.left.right=root;
                              root.left=root.right=null;
                              return flippedRoot;
                          }
                      
                      level(root = this.root) {
                              if (root == null)
                                  return;
                      
                              let q = [];
                              q.push(root);
                              while (true) {
                                  let nodeCount = q.length;
                                  if (nodeCount == 0) {
                                      break;
                                  }
                                  let output = ""
                                  while (nodeCount > 0) {
                                      let node =  q.shift();;
                                      output += node.data + " ";
                                      if (node.left)
                                          q.push(node.left);
                                      if (node.right)
                                          q.push(node.right);
                                      nodeCount--;
                                  }
                                  console.log(output)
                              }
                          }
                      
                      
                      }
                      
                      let tree = new BinaryTree();
                      tree.root = new Node(1);
                      tree.root.left = new Node(2);
                      tree.root.right = new Node(3);
                      tree.root.right.left = new Node(4);
                      tree.root.right.right = new Node(5);
                      console.log("Level Order Traversals: ")
                      console.log("Before Flip:")
                      tree.level()
                      console.log("After Flip:")
                      tree.flip()
                      tree.level()
                      `,
                        output: `Level Order Traversals: 
                        Before Flip:
                        1 
                        2 3 
                        4 5 
                        After Flip:
                        2 
                        3 1 
                        4 5 `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>3. Binary Search Tree</b>
                </Span>
                <Span>
                  Complexity for insertion, deletion and search : O(h) , h :
                  height of bst
                </Span>
                <Span>
                  It's basically a binary tree (degree : 2) with certain
                  constraints. There should be no duplicate nodes present in the
                  tree and also left subtree should always be smaller than right
                  subtree or we can say left child of any parent should be
                  lesser than it and the right child should be greater than it.
                </Span>
                <Span>
                  inorder, preorder, postorder =&lt; depth first traversal
                  <br />
                  level order =&lt; breadth first traversal
                  <br />
                  Min height =&lt; n-1 <br />
                  Max height =&lt; ceil(log2(n)) <br />
                  Min nodes -&lt; h+1 <br />
                  Max nodes -&lt; 2^h + 1 <br />
                  Insert, delete, search -&lt; complexity : O(1)
                </Span>
                <Span>
                  <b>Implementation:</b>
                </Span>
                <p>(a) using recursion:</p>
                <CodeEditor
                  options={{
                    title: "Binary Search Tree(Recursion)",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        class Node {
                          constructor(val) {
                            this.key = val;
                            this.left = null;
                            this.right = null;
                          }
                      }
                                            
                      class BinarySearchTree {
                        constructor(){
                          this.root = null
                        }
                      
                      // we'll make use of binary search till that
                      // desired element is found . After every
                      // iteration, n/2 nodes will be reduced from
                      // search space due to the elimination of desired
                      // subtree ( left or right ). That's why its
                      // called binary search tree.
                      
                        search(key,temp=this.root)
                        {
                          if (temp === null || temp.key === key)
                              return temp;
                          if (temp.key < key)
                             return this.search(key,temp.right);
                          return this.search(key,temp.left);
                        }
                      
                      // we'll find the desired leaf node for 
                      // insertion
                        insert(key)
                        {
                          this.root = this.insertRec(this.root, key);
                        }
                        
                        insertRec(temp, key)
                        {
                          if (temp == null)
                          {
                            temp = new Node(key);
                            return temp;
                          }
                          if (key < temp.key)
                            temp.left = this.insertRec(temp.left, key);
                          else if (key > temp.key)
                            temp.right = this.insertRec(temp.right, key);
                          return temp;
                        }
                        
                        inorder()
                        {
                          this.inorderRec(this.root);
                        }
                      
                        inorderRec(temp)
                        {
                          if (temp != null) {
                            this.inorderRec(temp.left);
                            console.log(temp.key);
                            this.inorderRec(temp.right);
                          }
                        }
                      
                        remove(key){
                          this.root = this.removeRec(this.root,key)
                        }
                      
                      // Deletion can be classified into 3 types depending upon
                      // whether node is present at:
                      // 1. leaf -> simply delete the node
                      // 2. parent with 1 child -> delete & replace it with the child
                      // 3. parent with 2 child -> delete & replace it with inorder 
                      // successor or predecessor
                      
                        removeRec(temp,key)
                        {
                        
                        if (temp == null)
                            return temp;
                      
                        if (key < temp.key)
                            temp.left = this.removeRec(temp.left, key);
                        else if (key > temp.key)
                            temp.right = this.removeRec(temp.right, key);
                        else {
                          if (temp.left == null)
                            return temp.right;
                          else if (temp.right == null)
                            return temp.left;
                          temp.key = this.minValue(temp.right);
                          // inorder successor
                          temp.right = this.removeRec(temp.right, temp.key);
                        }
                        return temp;
                        }
                       
                        minValue(root)
                        {
                          let minv = root.key;
                              while (root.left != null)
                              {
                                  minv = root.left.key;
                                  root = root.left;
                              }
                              return minv;
                        }
                      
                        maxDepth(node=this.root)
                          {
                              if (node == null)
                                  return -1;
                              else
                              {
                                  var lDepth = this.maxDepth(node.left);
                                  var rDepth = this.maxDepth(node.right);
                                  if (lDepth > rDepth)
                                      return (lDepth + 1);
                                   else
                                      return (rDepth + 1);
                              }
                              // O(n^2)
                        }
                        
                        // using recursion, level order 
                        // traversal occupies O(n*n) . That's
                        // why let's solve it ib O(n) using 
                        // queue
                      
                        levelOrderAndMaxDepth(root = this.root)
                        {
                          if(root == null)
                            return;
                          
                          let q = [];
                          let height = -1;
                          q.push(root);
                          while(true)
                          {
                            let nodeCount = q.length;
                            if(nodeCount == 0){
                              console.log({height})
                              break;
                            }
                            height++;
                            let output = ""
                            while(nodeCount > 0)
                            {
                              let node = q[0];
                              output += node.key + " ";
                              q.shift();
                              if(node.left != null)
                                q.push(node.left);
                              if(node.right != null)
                                q.push(node.right);
                              nodeCount--;
                            }
                            console.log(output)
                          }
                        }
                      
                      }
                      
                      const tree = new BinarySearchTree();
                      
                      console.log("Binary Search Tree Operations:")
                      
                      console.log("Insertion: 6, 5, 4, 3, 9, 8, 7")
                      tree.insert(6);
                      tree.insert(5);
                      tree.insert(4);
                      tree.insert(3);
                      tree.insert(9);
                      tree.insert(8);
                      tree.insert(7);
                      console.log("Level Order Traversal (Breadth First Traversal):")
                      tree.levelOrderAndMaxDepth()
                      console.log("Maximum Depth: " + tree.maxDepth())
                      console.log("In-Order Traversal (Depth First Traversal):")
                      tree.inorder();
                      console.log("Search (8):")
                      console.log(tree.search(8))
                      tree.levelOrderAndMaxDepth()
                      console.log("Delete (8): ")
                      tree.remove(8)
                      tree.levelOrderAndMaxDepth()
                      console.log("Maximum Depth: " + tree.maxDepth())
                      tree.inorder();                          
                        `,
                        output: `
                        Binary Search Tree Operations:
                        Insertion: 6, 5, 4, 3, 9, 8, 7
                        Level Order Traversal:
                        6 
                        5 9 
                        4 8 
                        3 7 
                        { height: 3 }
                        Maximum Depth: 3
                        In-Order Traversal:
                        3
                        Binary Search Tree Operations:
                        Insertion: 6, 5, 4, 3, 9, 8, 7
                        Level Order Traversal:
                        6 
                        5 9 
                        4 8 
                        3 7 
                        { height: 3 }
                        Maximum Depth: 3
                        In-Order Traversal:
                        3
                        4
                        Binary Search Tree Operations:
                        Insertion: 6, 5, 4, 3, 9, 8, 7
                        Binary Search Tree Operations:
                        Insertion: 6, 5, 4, 3, 9, 8, 7
                        Level Order Traversal (Breadth First Traversal):
                        6 
                        5 9 
                        4 8 
                        3 7 
                        { height: 3 }
                        Maximum Depth: 3
                        In-Order Traversal (Depth First Traversal):
                        3
                        4
                        5
                        6
                        7
                        8
                        9
                        Search (8):
                        Node {
                          key: 8,
                          left: Node { key: 7, left: null, right: null },
                          right: null
                        }
                        6 
                        5 9 
                        4 8 
                        3 7 
                        { height: 3 }
                        Delete (8): 
                        6 
                        5 9 
                        4 7 
                        3 
                        { height: 3 }
                        Maximum Depth: 3
                        3
                        4
                        5
                        6
                        7
                        9
                        `,
                      },
                    },
                  }}
                />
                <p>(b) inorder traversal using stack and loop:</p>
                <CodeEditor
                  options={{
                    title:
                      "Binary Search Tree(inorder traversal using stack and loop)",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        class Node {
                          constructor(val) {
                            this.val = val;
                            this.left = null;
                            this.right = null;
                          }
                      }
                      
                      class BinarySearchTree {
                        constructor(){
                          this.root = null
                        }
                        
                        insert(key)
                        {
                          const node = new Node(key);
                          if(this.root === null){
                            this.root = node;
                            return;
                          }
                          let prev=null;
                          let temp=this.root;
                          while (temp!=null){
                            if(temp.val > key){
                              prev=temp;
                              temp=temp.left;
                            }
                            else if(temp.val < key){
                              prev=temp;
                              temp=temp.right;
                            }
                          }
                          if(prev.val>key)
                            prev.left=node;
                          else prev.right=node;
                        }
                      
                        // depth first traversal
                        
                        inorder(){
                          let temp=this.root;
                          let stack=[];
                          while (temp!=null||stack.length!==0){
                            if(temp!=null){
                              stack.push(temp);
                              temp=temp.left;
                            }
                          else {
                              temp = stack.pop();
                              console.log(temp.val+" ");
                              temp = temp.right;
                          }
                          }
                        }
                      
                      }
                      
                      const tree = new BinarySearchTree();
                      console.log("Binary Search Tree Operations:")
                      tree.insert(9)
                      tree.insert(8)
                      tree.insert(7)
                      tree.inorder()                      
                        `,
                        output: `
                        Binary Search Tree Operations:
7 
8 
9
                        `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>4. Binary Heap</b>
                </Span>
                <Span>
                  It is a complete binary tree. It can be either min heap or max
                  heap. In min heap, key value of all parent nodes should be
                  lesser than their children. And they should be greater then
                  their children in case of max heap.
                </Span>
                <Span>
                  The traversal method use to achieve Array representation is
                  Level Order
                </Span>
                <Span>It's represented as an array( let's say Arr) where</Span>
                <Span>
                  Arr[(i-1)/2] Returns the parent node <br />
                  Arr[(2*i)+1] Returns the left child node <br />
                  Arr[(2*i)+2] Returns the right child node
                </Span>
                <Span>Algorithim === Avg. Case === Worst case</Span>
                <Span>
                  Search === O(n) === O(n)
                  <br />
                  Insert === O(1) === O(log n)
                  <br />
                  Find-min === O(1) === O(1)
                  <br />
                  Delete-min === O(log n) === O(log n)
                </Span>
                <Span>
                  <b>Heapify</b>
                </Span>
                <p>
                  The process of reshaping a binary tree into a Heap data
                  structure is known as "heapify".{" "}
                </p>
                <CodeEditor
                  options={{
                    title: "Heapify",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `class BH {
                          constructor() {
                            this.values = [];
                          }
                          add(element) {
                            this.values.push(element);
                            let index = this.values.length - 1;
                            const current = this.values[index];
                        
                            while (index > 0) {
                              let parentIndex = Math.floor((index - 1) / 2);
                              let parent = this.values[parentIndex];
                        
                              if (parent <= current) {
                                this.values[parentIndex] = current;
                                this.values[index] = parent;
                                index = parentIndex;
                              } else break;
                            }
                          }
                          extractMax() {
                            const max = this.values[0];
                            const end = this.values.pop();
                            this.values[0] = end;
                        
                            let index = 0;
                            const length = this.values.length;
                            const current = this.values[0];
                            while (true) {
                              let leftChildIndex = 2 * index + 1;
                              let rightChildIndex = 2 * index + 2;
                              let leftChild, rightChild;
                              let swap = null;
                        
                              if (leftChildIndex < length) {
                                leftChild = this.values[leftChildIndex];
                                if (leftChild > current) swap = leftChildIndex;
                              }
                              if (rightChildIndex < length) {
                                rightChild = this.values[rightChildIndex];
                                if (
                                  (swap === null && rightChild > current) ||
                                  (swap !== null && rightChild > leftChild)
                                )
                                  swap = rightChildIndex;
                              }
                        
                              if (swap === null) break;
                              this.values[index] = this.values[swap];
                              this.values[swap] = current;
                              index = swap;
                            }
                        
                            return max;
                          }
                        }
                        
                        const tree = new BH();
                        tree.add(3);
                        tree.add(4);
                        tree.add(31);
                        tree.add(6);
                        console.log(tree); 
                        console.log(tree.extractMax()); 
                        console.log(tree); 
                        // max heap
                        `,
                        output: `BH { values: [ 31, 6, 4, 3 ] }
                        31
                        BH { values: [ 6, 3, 4 ] }`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>5. AVL (Adelson Velsky and Landis) Tree</b>
                </Span>
                <Span>
                  It's a self balancing binary search tree where difference b/w
                  left and right subtress for every node cannot be more than 1.
                  They are used to improve the cost of operations of skewed bst
                  from O(n) to O(log n)
                </Span>
                <Span>The height of an AVL tree is always O(log n)</Span>
                Balance factor of a node: (height of left subtree) - (height of
                right tree). It should be between -1 and 1. If it's greater than
                1 then we should consider LL (Left Left) or LR case. Otherwise
                RR or RL case.
                <CodeEditor
                  options={{
                    title: "AVL (Adelson Velsky and Landis) Tree",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        class Node {
                          constructor(d) {
                          this.key = d;
                          this.height = 1;
                          this.left = null;
                          this.right = null;
                          }
                        }
                      
                        // Insertion->
                      
                        // level order traversals
                      
                        // let T1,T2,T3 and T4 be subtrees 
                      
                        // LL case -> right rotate z
                      
                        // before rotation:
                        // z (2)
                        // y--------T4 
                        // x T3
                        // T1 T2 
                      
                        // after rotating z clockwise (right)
                        // y
                        // x--------z
                        // T1 T2  T3 T4
                      
                        // RR case -> left rotate z
                        
                        // before rotation
                        // z (-2)
                        // T1 y
                        // ---T2 x
                        // ---T3 T4
                      
                        // after rotating z anti-clockwise (left)
                        // y 
                        // z      x
                        // T1 T2. T3 T4
                      
                        // LR case -> Left rotate y then right rotate z
                        
                        // before rotation
                        // z
                        // y T4
                        // T1 x
                        // -- T2 T3
                      
                        // after rotating y anti-clockwise (left)
                        // z
                        // x ----T4
                        // y T3
                        // T1 T2
                        // after rotating z clockwise (right)
                        // x
                        // y z
                        // T1 T2 T3 T4
                        
                        // RL case -> Right rotate y then left rotate z
                        // z
                        // T1 -- y
                        //      x T4
                        //      T2 t3
                      
                        // after rotating y clockwise (right)
                        // z
                        // T1 ----x
                        // T2 y
                        // T3 T4
                        // after rotating z anti-clockwise (left)
                        // x
                        // y z
                        // T1 T2 T3 T4
                      
                        // Deletion ->
                        // L1 -> LL        
                        // L0 -> LL or LR 
                        // L-1 -> LR 
                      
                        // R1 -> RL
                        // R0 -> RR or RL
                        // R-1 -> RR
                      
                        class AVLTree {
                          constructor() {
                          this.root = null;
                          }
                      
                          height(N) {
                          if (N == null) return 0;
                          return N.height;
                          }
                      
                          max(a, b) {
                          return a > b ? a : b;
                          }
                      
                          rightRotate(y) {
                          var x = y.left;
                          var T2 = x.right;
                          x.right = y;
                          y.left = T2;
                          y.height = this.max(this.height(y.left),
                          this.height(y.right)) + 1;
                          x.height = this.max(this.height(x.left),
                          this.height(x.right)) + 1;
                          return x;
                          }
                      
                          leftRotate(x) {
                          var y = x.right;
                          var T2 = y.left;
                          y.left = x;
                          x.right = T2;
                          x.height = this.max(this.height(x.left),
                          this.height(x.right)) + 1;
                          y.height = this.max(this.height(y.left),
                          this.height(y.right)) + 1;
                      
                          return y;
                          }
                      
                          getBalance(N) {
                          if (N == null) return 0;
                      
                          return this.height(N.left) - this.height(N.right);
                          }
                      
                          insert(key){
                           this.root = this.insertNode(this.root,key);
                          }
                      
                          insertNode(node, key) {
                          if (node == null) return new Node(key);
                      
                          if (key < node.key)
                          node.left = this.insertNode(node.left, key);
                          else if (key > node.key)
                          node.right = this.insertNode(node.right, key);
                          else return node;
                      
                          node.height =
                            1 + this.max(this.height(node.left),
                              this.height(node.right));
                          var balance = this.getBalance(node);
                      
                      // LL
                          if (balance > 1 && key < node.left.key)
                          return this.rightRotate(node);
                      
                      // RR
                          if (balance < -1 && key > node.right.key)
                            return this.leftRotate(node);
                      
                      // LR
                          if (balance > 1 && key > node.left.key) {
                            node.left = this.leftRotate(node.left);
                            return this.rightRotate(node);
                          }
                      
                      // RL
                          if (balance < -1 && key < node.right.key) {
                            node.right = this.rightRotate(node.right);
                            return this.leftRotate(node);
                          }
                      
                          return node;
                          }
                      
                          preOrder(node=this.root) {
                          if (node != null) {
                            console.log(node.key + " ");
                            this.preOrder(node.left);
                            this.preOrder(node.right);
                          }
                          }
                      
                      // for deletion simple perform bst deletion and perform 
                      // rotation accordingly
                      
                        remove(key){
                         this.root = this.removeNode(this.root,key)
                        }
                      
                        removeNode(head, key)
                          {
                              if (head == null)
                                  return head;
                              if (key < head.key)
                                  head.left = this.removeNode(head.left, key);
                              else if (key > head.key)
                                  head.right = this.removeNode(head.right, key);
                              else
                              {
                                  if ((head.left == null) || (head.right == null))
                                  {
                                      let temp = null;
                                      if (temp == head.left)
                                          temp = head.right;
                                      else
                                          temp = head.left;
                                      if (temp == null)
                                      {
                                          temp = head;
                                          head = null;
                                      }
                                      else 
                                          head = temp; 
                                  }
                                  else
                                  {
                                      let temp = this.minValueNode(head.right);
                                      head.key = temp.key;
                                      // inorder successor
                                      head.right = this.removeNode(head.right, temp.key);
                                  }
                              }
                              if (head == null)
                                  return head;
                              head.height = Math.max(this.height(head.left), this.height(head.right)) + 1;
                              let balance = this.getBalance(head);
                              // LL
                              if (balance > 1 && this.getBalance(head.left) >= 0)
                                  return this.rightRotate(head);
                              // LR
                              if (balance > 1 && this.getBalance(head.left) < 0)
                              {
                                  head.left = this.leftRotate(head.left);
                                  return this.rightRotate(head);
                              }
                              // RR
                              if (balance < -1 && this.getBalance(head.right) <= 0)
                                  return this.leftRotate(head);
                              // RL
                              if (balance < -1 && this.getBalance(head.right) > 0)
                              {
                                  head.right = this.rightRotate(head.right);
                                  return this.leftRotate(head);
                              }
                              return head;
                          }
                      }
                      
                      var tree = new AVLTree();
                      tree.insert(10);
                      tree.insert(20);
                      tree.insert(30);
                      tree.insert(40);
                      tree.insert(50);
                      tree.insert(25);
                      tree.preOrder()
                      console.log("#####")
                      tree.remove(40)
                      tree.preOrder()                      
                        `,
                        output: `
                        30 
                        20 
                        10 
                        25 
                        40 
                        50 
                        #####
                        30 
                        20 
                        10 
                        25 
                        50 
                        `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>6. Red-Black Tree</b>
                </Span>
                <Span>
                  It's a kind of self balancing binary search tree which uses
                  one extra bit for the color of each node instead of its
                  balancing height to ensure that tree remains balanced during
                  insertion and deletion. We make uses of various flags for
                  rotation.
                </Span>
                <Span>
                  Rules: Root will always be black.There are no two adjacent red
                  nodes. Number of black nodes from root to the leaf node will
                  always be the same through any path. Newly inserted node will
                  always be given red.
                </Span>
                <Span>
                  We need to perform rotation when the parent of current node is
                  red.
                </Span>
                <Span>
                  In the insert operation, we check the color of the uncle to
                  decide the appropriate case. In the delete operation, we check
                  the color of the sibling to decide the appropriate case after
                  bst deletion.
                </Span>
                <p>
                  Red-Black Tree of height h has black-height greater than or
                  equal to h/2. Height less than or equal to 2log2(n+1) <br />
                  Cost Of Operations: <br />
                  Insertion: O(log n) <br />
                  Search: O(log n) <br />
                  Deletion: O(log n)
                </p>
                <CodeEditor
                  options={{
                    title: "Red-Black Tree",
                    output: null,
                    codes: {
                      Java: {
                        code: `
                        import java.io.*;

                        public class Main
                        {
                          public Node root;
                          public Main()
                          {
                            super();
                            root = null;
                          }
                          class Node
                          {
                            int data;
                            Node left;
                            Node right;
                            char colour;
                            Node parent;
                        
                            Node(int data)
                            {
                              super();
                              this.data = data; 
                              this.left = null; 
                              this.right = null; 
                              this.colour = 'R'; 
                              this.parent = null; 
                            }
                          }
                          Node rotateLeft(Node node)
                          {
                            Node x = node.right;
                            Node y = x.left;
                            x.left = node;
                            node.right = y;
                            node.parent = x; 
                            if(y!=null)
                              y.parent = node;
                            return(x);
                          }
                        
                          Node rotateRight(Node node)
                          {
                            Node x = node.left;
                            Node y = x.right;
                            x.right = node;
                            node.left = y;
                            node.parent = x;
                            if(y!=null)
                              y.parent = node;
                            return(x);
                          }
                        
                        
                          boolean ll = false;
                          boolean rr = false;
                          boolean lr = false;
                          boolean rl = false;
                        
                        
                          Node insertHelp(Node root, int data)
                          {
                            // f is true when RED RED conflict is there.
                            boolean f=false;
                            
                        
                            if(root==null)
                              return(new Node(data));
                            else if(data<root.data)
                            {
                              // find the location and mark f 
                              // true if desired condition 
                              // satisfies
                              // root.left -> root is parent at last
                              root.left = insertHelp(root.left, data);
                              root.left.parent = root;
                              if(root!=this.root)
                              {
                                if(root.colour=='R' && root.left.colour=='R')
                                  f = true;
                              }
                            }
                            else
                            {
                              root.right = insertHelp(root.right,data);
                              root.right.parent = root;
                              if(root!=this.root)
                              {
                                if(root.colour=='R' && root.right.colour=='R')
                                  f = true;
                              }
                            }
                        
                            if(this.ll) 
                            {
                              root = rotateLeft(root);
                              root.colour = 'B';
                              root.left.colour = 'R';
                              this.ll = false;
                            }
                            else if(this.rr) 
                            {
                              root = rotateRight(root);
                              root.colour = 'B';
                              root.right.colour = 'R';
                              this.rr = false;
                            }
                            else if(this.rl) 
                            {
                              root.right = rotateRight(root.right);
                              root.right.parent = root;
                              root = rotateLeft(root);
                              root.colour = 'B';
                              root.left.colour = 'R';
                        
                              this.rl = false;
                            }
                            else if(this.lr) 
                            {
                              root.left = rotateLeft(root.left);
                              root.left.parent = root;
                              root = rotateRight(root);
                              root.colour = 'B';
                              root.right.colour = 'R';
                              this.lr = false;
                            }
                        
                        // red red case
                        // is uncle is black,perform rotation
                            if(f)
                            {
                              if(root.parent.right == root) 
                              {
                                //  uncle -> root.parent.left
                                if(root.parent.left==null || root.parent.left.colour=='B') 
                                {
                                  if(root.left!=null && root.left.colour=='R')
                                    this.rl = true;
                                  else if(root.right!=null && root.right.colour=='R')
                                    this.ll = true;
                                }
                                else 
                                {
                                  root.parent.left.colour = 'B';
                                  root.colour = 'B';
                                  // root cannot be red
                                  if(root.parent!=this.root)
                                    root.parent.colour = 'R';
                                }
                              }
                              else
                              {
                                if(root.parent.right==null || root.parent.right.colour=='B')
                                {
                                  if(root.left!=null && root.left.colour=='R')
                                    this.rr = true;
                                  else if(root.right!=null && root.right.colour=='R')
                                    this.lr = true;
                                }
                                else
                                {
                                  root.parent.right.colour = 'B';
                                  root.colour = 'B';
                                  if(root.parent!=this.root)
                                    root.parent.colour = 'R';
                                }
                              }
                              f = false;
                            }
                            return(root);
                          }
                        
                          public void insert(int data)
                          {
                            if(this.root==null)
                            {
                              this.root = new Node(data);
                              this.root.colour = 'B';
                            }
                            else
                              this.root = insertHelp(this.root,data);
                          }
                          
                          public void inorderTraversal(Node node)
                          {
                            if(node!=null)
                            {
                              inorderTraversal(node.left);
                              System.out.printf("%d ", node.data);
                              inorderTraversal(node.right);
                            }	}
                        
                          public static void main(String[] args)
                          {
                            Main t = new Main();
                            int[] arr = {1,4,6,3,5,7,8,2,9};
                            for(int i=0;i<9;i++)
                            {
                              t.insert(arr[i]);
                          }
                            t.inorderTraversal(t.root);
                        }
                        }
                        Deletion -> https://www.geeksforgeeks.org/red-black-tree-set-3-delete-2/ ( for now )
                        `,
                        output: `
 1 2 3 4 5 6 7 8 9
 `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>7. N-ary (M-way) Tree</b>
                </Span>
                <Span>
                  Multi way trees are used to generate trees with order m, i.e.
                  each tree can contain nodes with maximum m-1 keys and m
                  children.
                </Span>
                <Span>
                  Number of elements = h to (m^h)-1 <br />
                  Height: log_m(n+1) to n
                </Span>
                <b>Basic Structure (Without Driver Code) :</b>
                <CodeEditor
                  options={{
                    title: "N-ary (M-way) Tree",
                    output: null,
                    codes: {
                      Python: {
                        code: `class node:
    def __init__(self):
        self.count = -1
        self.value = [-1]*(MAX)
        self.child = [None]*(MAX + 1)

def search(val, root, pos):

	if (root == None):
		return None
	else :
		if (searchnode(val, root, pos)):
			return root
		else:
			return search(val, root.child[pos], pos)
	

def searchnode(val, n, pos):
	if (val < n.value[1]):
		pos = 0
		return 0
	
  else :
		pos = n.count

		while ((val < n.value[pos]) and pos > 1):
			pos-=1
		if (val == n.value[pos]):
			return 1
		else:
			return 0
	

                        `,
                      },
                    },
                  }}
                />
                <Span>Examples: B Tree and B+ Tree</Span>
                <Span>
                  <b>B Tree</b>
                </Span>
                <Span>
                  They are perfectly balanced m way search trees where every
                  leaf node is at the same depth.Every node except root is
                  atleast half full (m/2).
                </Span>
                <Span>
                  Most of the tree operations require O(h) disk accesses , with
                  the help of fat b tree we can perform these operations with
                  O(log n). The height of B-Trees is kept low by putting maximum
                  possible keys in a B-Tree node. <br />
                  Minimum degree = t<br />
                  Nodes = 2*t -1
                </Span>
                <p>Basic Structure (Without Driver Code) :</p>
                <CodeEditor
                  options={{
                    title: "B Tree",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        
class Btree
{

	constructor(t)
	{
		this.root = null; 
    // BTreeNode
		this.t = t;
	}
	
	traverse()
	{
		if (this.root != null)
			this.root.traverse();
	}

	search(k)
	{
		if (this.root == null)
			return null;
		else
			return this.root.search(k);
	}
	
}

class BTreeNode
{
	constructor(t,leaf)
	{
		this.t = t;
    // degree
		this.leaf = leaf;
		this.keys = new Array(2 * t - 1);
		this.C = new Array(2 * t);
		this.n = 0;
	}

	traverse()
	{

		let i = 0;
		for (i = 0; i < this.n; i++) {

			if (this.leaf == false) {
				C[i].traverse();
			}
			console.log(keys[i] + " ");
		}

		if (leaf == false)
			C[i].traverse();
	}
	
	search(k) 
	{
		let i = 0;
		while (i < n && k > keys[i])
			i++;

		if (keys[i] == k)
			return this;

		if (leaf == true)
			return null;
		return C[i].search(k);
	}
}
                        `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>B+ Tree</b>
                </Span>
                <Span>
                  This tree eliminates the indexing problem of B tree by storing
                  data pointers only at the leaf nodes whereas data pointers are
                  present at both internal and leaf nodes in case of b tree
                </Span>
                <Span>
                  Leaf nodes are present as structural linked list which makes
                  it easier for search and other operations
                </Span>
                <p>
                  Duplicates nodes may present in this tree unlike b tree , as
                  copy of desired node will be used during certain operations.
                </p>
                <p>Basic Structure (Without Driver Code) :</p>
                <CodeEditor
                  options={{
                    title: "B+ Tree",
                    output: null,
                    codes: {
                      Python: {
                        code: `MAX = 3
                        
class Node :
  def __init__(self):
    self.IS_LEAF=False
    self.key, self.size=[None]*MAX,0
    self.ptr=[None]*(MAX+1)


class BPTree :

  def __init__(self):
    self.root = None

  def search(self,x):

    if (self.root == None) :
      cout << "Tree is empty\n"
        else :

      cursor = self.root

      while (not cursor.IS_LEAF) :

        for i in range(cursor.size) :
          if (x < cursor.key[i]) :
            cursor = cursor.ptr[i]
            break
          if (i == cursor.size - 1) :
            cursor = cursor.ptr[i + 1]
            break
      for i in range(cursor.size):
        if (cursor.key[i] == x) :
          print("Found")
          return				
      print("Not found")
  
  def insert(self, x):

    if (self.root == None) :
      self.root = Node()
      self.root.key[0] = x
      self.root.IS_LEAF = True
      self.root.size = 1
    
    else :
      cursor = self.root
      parent=None
      while (not cursor.IS_LEAF) :

        parent = cursor

        for i in range(cursor.size) :
          if (x < cursor.key[i]) :
            cursor = cursor.ptr[i]
            break
          if (i == cursor.size - 1) :
            cursor = cursor.ptr[i + 1]
            break
          
        
      if (cursor.size < MAX) :
        i = 0
        while (i < cursor.size and x > cursor.key[i]):
          i+=1
        

        for j in range(cursor.size,i,-1):
          cursor.key[j] = cursor.key[j - 1]
        

        cursor.key[i] = x
        cursor.size+=1

        cursor.ptr[cursor.size] = cursor.ptr[cursor.size - 1]
        cursor.ptr[cursor.size - 1] = None
      

      else :

        newLeaf = Node()

        virtualNode=[None]*(MAX + 1)

        for i in range(MAX):
          virtualNode[i] = cursor.key[i]
        
        i = 0


        while (i < MAX and x > virtualNode[i]) :
          i+=1

        for j in range(MAX,i,-1) :
          virtualNode[j] = virtualNode[j - 1]
        

        virtualNode[i] = x
        newLeaf.IS_LEAF = True

        cursor.size = int((MAX + 1) / 2)
        newLeaf.size = int(MAX + 1 - (MAX + 1) / 2)

        cursor.ptr[cursor.size] = newLeaf

        newLeaf.ptr[newLeaf.size] = cursor.ptr[MAX]

        cursor.ptr[MAX] = None

        for i in range(cursor.size):
          cursor.key[i] = virtualNode[i]
      
        j=cursor.size
        for i in range(newLeaf.size):
          newLeaf.key[i] = virtualNode[j]
          j+=1
        if (cursor == self.root) :

          newRoot = Node()
          newRoot.key[0] = newLeaf.key[0]
          newRoot.ptr[0] = cursor
          newRoot.ptr[1] = newLeaf
          newRoot.IS_LEAF = False
          newRoot.size = 1
          root = newRoot
        
        else :


          insertInternal(newLeaf.key[0],
                parent,
                newLeaf)
      
    

def insertInternal(x, cursor, child):

  if (cursor.size < MAX) :
    i = 0

    while (x > cursor.key[i] and i < cursor.size) :
      i+=1
    for j in range(cursor.size,i,-1) :

      cursor.key[j] = cursor.key[j - 1]

    for j in range(cursor.size + 1, i + 1,-1):
      cursor.ptr[j] = cursor.ptr[j - 1]
    

    cursor.key[i] = x
    cursor.size+=1
    cursor.ptr[i + 1] = child
  
  else :

    newInternal = Node()
    virtualKey=[None]*(MAX + 1)
    virtualPtr=[None]*(MAX + 2)
    for i in range(MAX) :
      virtualKey[i] = cursor.key[i]
    

    for i in range(MAX + 1):
      virtualPtr[i] = cursor.ptr[i]
    i = 0

    while (x > virtualKey[i] and i < MAX) :
      i+=1
    for j in range(MAX + 1,i,-1):

      virtualKey[j] = virtualKey[j - 1]
    

    virtualKey[i] = x
    for j in range(MAX + 2, i + 1,-1) :
      virtualPtr[j] = virtualPtr[j - 1]
    

    virtualPtr[i + 1] = child
    newInternal.IS_LEAF = false

    cursor.size = (MAX + 1) / 2

    newInternal.size = MAX - (MAX + 1) / 2
    j = cursor.size + 1
    for i in range(newInternal.size):
      newInternal.key[i] = virtualKey[j]
      j+=1
    

    j = cursor.size + 1
    for i in range(newInternal.size):
      newInternal.ptr[i] = virtualKey[j]
      j+=1
    

    if (cursor == self.root) :

      newRoot = self.root
      newRoot.key[0] = cursor.key[cursor.size]
      newRoot.ptr[0] = cursor
      newRoot.ptr[1] = newInternal
      newRoot.IS_LEAF = false
      newRoot.size = 1
      root = newRoot
    else :
      insertInternal(cursor.key[cursor.size],
            findParent(root,
                  cursor),
            newInternal)

  def findParent(self, cursor, child):

    if (cursor.IS_LEAF or (cursor.ptr[0]).IS_LEAF) :
      return None
    
    for i in range(cursor.size + 1) :

      if (cursor.ptr[i] == child) :
        parent = cursor
        return parent
      else :
        parent = findParent(cursor.ptr[i], child)

        if (parent != None):
          return parent
    return parent


  def getRoot(self):
    return self.root


if __name__=='__main__':
  node=BPTree()
  node.insert(6)
  node.insert(16)
  node.insert(26)
  node.insert(36)
  node.insert(46)
  node.search(16)
                        `,
                        output: `Found`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Graph</b>
                </Span>
                <Span>
                  It consists of finite number of nodes (vertices) and edges
                  which connect two nodes.
                </Span>
                <Span>In facebook, each person is represented by a node.</Span>
                <Span>
                  <b>BFS & DFS</b>
                </Span>
                <Span>
                  BFS (level order traversal)(queue) &
                  DFS(preorder,postorder,inorder tree trvaersal)(stack) for
                  graph is similar to that of a graph, but unlike trees it may
                  contain cycles as well. A node may be visited twice that's why
                  we make use of boolean array.
                </Span>
                <Span>Representations:</Span>
                <Span>
                  <b>Adjacency List</b>
                </Span>
                <p>
                  It's a array (array[]) of lists where earch vertex each
                  element array[i] represents all the vertices adjacent to ith
                  vertex.
                </p>
                <CodeEditor
                  options={{
                    title: "Adjacency List",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        Map.prototype.inspect = function() {
                          return 'Map('+ mapEntriesToString(this.entries()) +')'
                        }
                                                
                        function mapEntriesToString(entries) {
                          return Array
                          .from(entries, ([k, v]) => k+':'+ v)
                          .join("");
                        }                       
                                                
                        class Graph {
                          constructor(noOfVertices)
                          {
                                this.noOfVertices = noOfVertices;
                                this.adjList = new Map();
                          }
                                                  
                          addVertex(v){
                            this.adjList.set(v, [])
                          }
                         
                          addEdge(v, w)
                          {
                            this.adjList.get(v).push(w);
                            this.adjList.get(w).push(v);
                          }
                           
                          printAdjList(){
                            console.log(this.adjList)
                            // because of custom prototype
                            var get_keys = this.adjList.keys();
                            for (var i of get_keys)
                            {
                                var get_values = this.adjList.get(i);
                                var conc = "";
                                for (var j of get_values)
                                    conc += j + " ";
                                console.log(i + " -> " + conc);
                            }
                        }
                        
                        bfs(startingNode)
                        {
                            var visited = {};
                            var q = [];
                            visited[startingNode] = true;
                            q.push(startingNode);
                            while (q.length!=0) {
                                var getQueueElement = q.shift();
                                console.log(getQueueElement);
                                var get_List = this.adjList.get(getQueueElement);
                                for (var i in get_List) {
                                    var neighbour = get_List[i];
                                    if (!visited[neighbour]) {
                                        visited[neighbour] = true;
                                        q.push(neighbour);
                                    }
                                }
                            }
                        }
                        
                        dfs(start){
                          this.dfsHelper(start,{})
                        }
                        
                        dfsHelper(vert,visited){
                          visited[vert] = true;
                          console.log(vert);
                          var get_neighbours = this.adjList.get(vert);
                          for (let neighbour of get_neighbours) {
                            if (!visited[neighbour])
                              this.dfsHelper(neighbour, visited);
                          }
                        }
                        }
                         
                          
                        const g = new Graph(6)
                        const v = ['A','B','C','D','E','F']
                        for(let x of v){
                          g.addVertex(x)
                        }
                        
                        g.addEdge('A', 'B');
                        g.addEdge('A', 'D');
                        g.addEdge('A', 'E');
                        g.addEdge('B', 'C');
                        g.addEdge('D', 'E');
                        g.addEdge('E', 'F');
                        g.addEdge('E', 'C');
                        g.addEdge('C', 'F');
                        g.printAdjList();
                        console.log("BFS");
                        g.bfs(v[0]);        
                        console.log("DFS");
                        g.dfs(v[0]);    
                        
                        // function addEdge(adj,u,v)
                        // {
                        //     adj[u].push(v);
                        //     adj[v].push(u);
                        // }
                        // function printGraph(adj)
                        // {
                        //     for (let i = 0; i < adj.length; i++) {
                        //     console.log("Adjacency list of vertex" + i);
                        //     console.log("head");
                        //     for (let j = 0; j < adj[i].length; j++) {
                        //      console.log(" -> "+adj[i][j]);
                        //     }
                        //    }
                        // }
                        // let V = 5;
                        // let adj= [];        
                        // for (let i = 0; i < V; i++)
                        //     adj.push([]);
                        //  addEdge(adj, 0, 1);
                        //  addEdge(adj, 0, 4);
                        //  addEdge(adj, 1, 2);
                        //  addEdge(adj, 1, 3);
                        //  addEdge(adj, 1, 4);
                        //  addEdge(adj, 2, 3);
                        //  addEdge(adj, 3, 4);                    
                        `,
                      },
                    },
                    output: `
                    Map {
                      'A' => [ 'B', 'D', 'E' ],
                      'B' => [ 'A', 'C' ],
                      'C' => [ 'B', 'E', 'F' ],
                      'D' => [ 'A', 'E' ],
                      'E' => [ 'A', 'D', 'F', 'C' ],
                      'F' => [ 'E', 'C' ]
                    }
                    A -> B D E 
                    B -> A C 
                    C -> B E F 
                    D -> A E 
                    E -> A D F C 
                    F -> E C 
                    BFS
                    A
                    B
                    D
                    E
                    C
                    F
                    DFS
                    A
                    B
                    C
                    E
                    D
                    F`,
                  }}
                />
                <Img src={GraphImg} alt="img" left />
                <p>
                  <b>Practice</b>
                </p>
                <CodeEditor
                  options={{
                    title: "Adjacency List(Practice)",
                    codes: {
                      Javascript: {
                        code: `class Graph{

                          constructor(n,v){
                            this.n = n
                            this.g = {}
                            this.v = v 
                          }
                          
                          addVertex(i){
                            this.g[i] = []
                          }
                        
                          removeVertex(i){
                            delete this.g[i]
                            for(let k in this.g){
                              this.g[k] = this.g[k].filter(x=>x!==i)
                            }
                          }
                        
                          addEdge(u,v){
                           this.g[u].push(v)
                           this.g[v].push(u)
                          }
                        
                          print(){
                            let res = {}
                            Object.entries(this.g).forEach((i,k)=>{
                              res[this.v[i[0]]] = i[1].map(x=>this.v[x])  
                            })
                            console.log(res)
                          }
                           
                          bfs(v = 0){
                            let q = []
                            let visited = {}
                            q.push(v)
                            visited[v] = true
                            while(q.length){
                              const ele = q.shift()
                              console.log(this.v[ele])
                              const list = this.g[ele]
                              for(let i of list){
                                if(!visited[i]){
                                  visited[i] = true
                                  q.push(i)
                                }
                              } 
                            }
                          }
                        
                          dfs(v=0,visited={}){
                            visited[v] = true
                            console.log(this.v[v])
                            const list = this.g[v]
                            for(let i of list){
                               if(!visited[i]) this.dfs(i,visited)
                            }
                          }
                        
                        }
                        
                        const v = ['A','B','C','D','E','F']
                        const g = new Graph(6,v)
                        for(let i in v)
                        g.addVertex(i)
                        g.addEdge(0, 1);
                        g.addEdge(0, 3);
                        g.addEdge(0, 4);
                        g.addEdge(1, 2);
                        g.addEdge(3, 4);
                        g.addEdge(4, 5);
                        g.addEdge(4, 2);
                        g.addEdge(2, 5);
                        // g.removeVertex(1)
                        g.print()
                        g.bfs()
                        g.dfs()`,
                        output: `{
                          A: [ 'B', 'D', 'E' ],
                          B: [ 'A', 'C' ],
                          C: [ 'B', 'E', 'F' ],
                          D: [ 'A', 'E' ],
                          E: [ 'A', 'D', 'F', 'C' ],
                          F: [ 'E', 'C' ]
                        }
                        A
                        B
                        D
                        E
                        C
                        F
                        A
                        B
                        C
                        E
                        D
                        F`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Adjacency Matrix</b>
                </Span>
                <Span>
                  It's a 2D array(adj[][]) of size V * V, where V is the number
                  of vertices in the graph. adj[u][v]= 1 for unweighted graph
                  means there is a edge between u and v.
                </Span>
                <p>
                  In case of weighted graphs , if adj[i][j] = w, then w will be
                  the weight of the edge between vertices u and v.
                  <br />
                  Adding a vertex: O(V^2)
                  <br />
                  Removing a vertex: O(1)
                </p>
                <CodeEditor
                  options={{
                    title: "Adjacency Matrix",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        class Graph
                        {
                          constructor(x)
                          {
                            this.n=x;
                            // numOfVertices
                            // this.g = new Array(10);
                            
                            // this.g = Array(10).fill(null).map(()=>Array(10).fill(0));
                            // ineffient and Array(10).fill(Array(10)) is bad as rows will be copied by reference
                            // var arr = Array(2).fill(Array(4))  
                            // arr[0][0] =1
                            // arr , arr[0][0] , arr1[0][1]
                            
                            this.g = Array.from(Array(10),()=>Array(10).fill(0))
                            // more efficient
                            
                            
                        
                            // for(let i=0;i<10;i++)
                            // {
                            // 	this.g[i]=new Array(10);
                            // 	for(let j=0;j<10;j++)
                            // 	{
                            // 		this.g[i][j]=0;
                            // 	}
                            // }
                            // adjMatrix
                          }
                          
                          displayAdjacencyMatrix()
                          {
                            console.log("Adjacency Matrix:");
                            for (let i = 0; i < this.n; ++i)
                            {
                              let output=""
                              for (let j = 0; j < this.n; ++j)
                              {
                                output += " " + this.g[i][j];
                              }
                              console.log(output)
                            }
                          }
                          
                          addEdge(x,y)
                          {
                            if ((x >= this.n) || (y > this.n))
                            {
                              console.log("Vertex does not exists!");
                            }
                        
                            if (x == y)
                            {
                              console.log("Same Vertex!");
                            }
                            else
                            {
                              this.g[y][x] = 1;
                              this.g[x][y] = 1;
                            }
                          }
                          
                          addVertex()
                          {
                            this.n++;
                            for (let i = 0; i < this.n; ++i)
                            {
                              this.g[i][this.n - 1] = 0;
                              this.g[this.n - 1][i] = 0;
                            }
                          }
                          
                          removeVertex(x)
                          {
                            if (x > this.n)
                            {
                              console.log("Vertex not present!");
                              return;
                            }
                            else
                            {
                              let i;
                              while (x < this.n)
                              {
                                for (i = 0; i < this.n; ++i)
                                {
                                  this.g[i][x] = this.g[i][x + 1];
                                }
                        
                                for (i = 0; i < this.n; ++i)
                                {
                                  this.g[x][i] = this.g[x + 1][i];
                                }
                                x++;
                              }
                              this.n--;
                            }
                          }
                          
                        }
                        
                        let obj = new Graph(4);
                        // 0 1 2 3 -> vertices
                        obj.addEdge(0, 1);
                        obj.addEdge(0, 2);
                        obj.addEdge(1, 2);
                        obj.addEdge(2, 3);
                        obj.displayAdjacencyMatrix();
                        obj.addVertex();
                        obj.addEdge(4, 1);
                        obj.addEdge(4, 3);
                        obj.displayAdjacencyMatrix();
                        obj.removeVertex(1);
                        obj.displayAdjacencyMatrix();                                              
                        `,
                      },
                    },
                    output: `
                    Adjacency Matrix:
                    0 1 1 0
                    1 0 1 0
                    1 1 0 1
                    0 0 1 0
                   Adjacency Matrix:
                    0 1 1 0 0
                    1 0 1 0 1
                    1 1 0 1 0
                    0 0 1 0 1
                    0 1 0 1 0
                   Adjacency Matrix:
                    0 1 0 0
                    1 0 1 0
                    0 1 0 1
                    0 0 1 0
                    `,
                  }}
                />
                <Img src={GraphImg} alt="img" left />
                <p>
                  <b>Practice:</b>
                </p>
                <CodeEditor
                  options={{
                    title: "Adjacency Matrix(Practice)",
                    codes: {
                      Javascript: {
                        code: `class Graph{

                          constructor(n,v){
                            this.n = n
                            this.g = Array.from(Array(n*2),()=>Array(n*2).fill(0))
                            this.v = v 
                          }
                          
                          addVertex() {
                            this.n++;    
                            for (let i = 0; i < this.n; ++i) {
                              this.g[i][this.n - 1] = 0;
                              this.g[this.n - 1][i] = 0;
                            }
                          }
                        
                            removeVertex(x) {
                                if (x > this.n) {
                                    console.log("Vertex not present!");
                                    return;
                                } else {
                                    let i;
                                    while (x < this.n) {
                                        for (i = 0; i < this.n; ++i) {
                                            this.g[i][x] = this.g[i][x + 1];
                                        }
                        
                                        for (i = 0; i < this.n; ++i) {
                                            this.g[x][i] = this.g[x + 1][i];
                                        }
                                        x++;
                                    }
                                    this.n--;
                                }
                            }
                        
                          addEdge(u,v){
                           this.g[u][v] = 1
                           this.g[v][u] = 1
                          }
                        
                          print(){
                            this.g.slice(0,this.n).forEach((x,i)=>console.log(x.slice(0,this.n)))
                          }
                           
                          bfs(v = 0){
                            let q = []
                            let visited = {}
                            q.push(v)
                            visited[v] = true
                            while(q.length){
                              const ele = q.shift()
                              console.log(this.v[ele])
                              for(let i=0;i<this.n;i++){
                                if(!visited[i] && this.g[ele][i]){
                                  visited[i] = true
                                  q.push(i)
                                }
                              }
                            }
                          }
                        
                          dfs(v=0,visited={}){
                            visited[v] = true
                            console.log(this.v[v])
                            const list = this.g[v]
                            for(let i =0;i<this.n;i++){
                               if(!visited[i] && list[i]) this.dfs(i,visited)
                            }
                          }
                        
                        }
                        
                        const v = ['A','B','C','D','E','F']
                        const g = new Graph(5,v)
                        g.addEdge(0, 1);
                        g.addEdge(0, 3);
                        g.addEdge(0, 4);
                        g.addEdge(1, 2);
                        g.addEdge(3, 4);
                        g.addEdge(4, 2);
                        g.addVertex()
                        g.addEdge(4, 5);
                        g.addEdge(2, 5);
                        // g.removeVertex(1)
                        g.print()
                        g.bfs()
                        g.dfs()`,
                        output: `[ 0, 1, 0, 1, 1, 0 ]
                        [ 1, 0, 1, 0, 0, 0 ]
                        [ 0, 1, 0, 0, 1, 1 ]
                        [ 1, 0, 0, 0, 1, 0 ]
                        [ 1, 0, 1, 1, 0, 1 ]
                        [ 0, 0, 1, 0, 1, 0 ]
                        A
                        B
                        D
                        E
                        C
                        F
                        A
                        B
                        C
                        E
                        D
                        F`,
                      },
                    },
                  }}
                />
                <p>
                  <b>
                    Print All possible paths from source to destination ( using
                    dfs):
                  </b>
                </p>
                <CodeEditor
                  options={{
                    title: "AllPathsDFS",
                    codes: {
                      Javascript: {
                        code: `class Graph {

                          constructor(n, v) {
                              this.n = n
                              this.g = {}
                              this.v = v
                          }
                      
                          addVertex(i) {
                              this.g[i] = []
                          }
                      
                          removeVertex(i) {
                              delete this.g[i]
                              for (let k in this.g) {
                                  this.g[k] = this.g[k].filter(x => x !== i)
                              }
                          }
                      
                          addEdge(u, v) {
                              this.g[u].push(v)
                              this.g[v].push(u)
                          }
                      
                          print() {
                              let res = {}
                              Object.entries(this.g).forEach((i, k) => {
                                  res[this.v[i[0]]] = i[1].map(x => this.v[x])
                              })
                              console.log(res)
                          }
                      
                          bfs(v = 0) {
                              let q = []
                              let visited = {}
                              q.push(v)
                              visited[v] = true
                              while (q.length) {
                                  const ele = q.shift()
                                  console.log(this.v[ele])
                                  const list = this.g[ele]
                                  for (let i of list) {
                                      if (!visited[i]) {
                                          visited[i] = true
                                          q.push(i)
                                      }
                                  }
                              }
                          }
                      
                          dfs(v = 0, visited = {}) {
                              visited[v] = true
                              console.log(this.v[v])
                              const list = this.g[v]
                              for (let i of list) {
                                  if (!visited[i]) this.dfs(i, visited)
                              }
                          }
                      
                          paths(source,destination){
                             const result = []     
                             const curr = []
                             curr.push(source)
                             this.pathsUsingDfs(source,destination,result,curr)
                             console.log(result.map(x=>x.map(y=>this.v[y])))
                          }
                      
                          pathsUsingDfs(s,d,result,curr=[],visited={}){
                            if(s === d){
                              result.push(curr.slice())
                              return
                            }
                            visited[s] = true
                            const list = this.g[s]
                            for (let i of list) {
                              if (!visited[i]){
                                curr.push(i)
                                this.pathsUsingDfs(i,d,result,curr,visited)
                                curr.splice(curr.indexOf(i),1)
                              }
                            }
                            visited[s] = false
                          }
                      
                      }
                      
                      const v = ['A', 'B', 'C', 'D', 'E', 'F']
                      const g = new Graph(6, v)
                      for (let i in v)
                          g.addVertex(i)
                      g.addEdge(0, 1);
                      g.addEdge(0, 3);
                      g.addEdge(0, 4);
                      g.addEdge(1, 2);
                      g.addEdge(3, 4);
                      g.addEdge(4, 5);
                      g.addEdge(4, 2);
                      g.addEdge(2, 5);
                      // g.removeVertex(1)
                      console.log("Graph:")
                      g.print()
                      console.log("BFS:")
                      g.bfs()
                      console.log("DFS:")
                      g.dfs()
                      console.log("All possible paths from A to F:")
                      g.paths(0,5)`,
                        output: `Graph:
                        {
                          A: [ 'B', 'D', 'E' ],
                          B: [ 'A', 'C' ],
                          C: [ 'B', 'E', 'F' ],
                          D: [ 'A', 'E' ],
                          E: [ 'A', 'D', 'F', 'C' ],
                          F: [ 'E', 'C' ]
                        }
                        BFS:
                        Graph:
                        {
                          A: [ 'B', 'D', 'E' ],
                          B: [ 'A', 'C' ],
                          C: [ 'B', 'E', 'F' ],
                          D: [ 'A', 'E' ],
                          E: [ 'A', 'D', 'F', 'C' ],
                          F: [ 'E', 'C' ]
                        }
                        BFS:
                        A
                        B
                        D
                        E
                        C
                        F
                        DFS:
                        A
                        B
                        C
                        E
                        D
                        F
                        All possible paths from A to F:
                        [
                          [ 'A', 'B', 'C', 'E', 'F' ],
                          [ 'A', 'B', 'C', 'F' ],
                          [ 'A', 'D', 'E', 'F' ],
                          [ 'A', 'D', 'E', 'C', 'F' ],
                          [ 'A', 'E', 'F' ],
                          [ 'A', 'E', 'C', 'F' ]
                        ]`,
                      },
                    },
                  }}
                />
                <p>
                  <b>
                    Print All possible paths from source to destination ( using
                    bfs):
                  </b>
                </p>
                <CodeEditor
                  options={{
                    title: "AllPathsBFS",
                    codes: {
                      Javascript: {
                        code: `class Graph {

                          constructor(n, v) {
                              this.n = n
                              this.g = {}
                              this.v = v
                          }
                      
                          addVertex(i) {
                              this.g[i] = []
                          }
                      
                          removeVertex(i) {
                              delete this.g[i]
                              for (let k in this.g) {
                                  this.g[k] = this.g[k].filter(x => x !== i)
                              }
                          }
                      
                          addEdge(u, v) {
                              this.g[u].push(v)
                              this.g[v].push(u)
                          }
                      
                          print() {
                              let res = {}
                              Object.entries(this.g).forEach((i, k) => {
                                  res[this.v[i[0]]] = i[1].map(x => this.v[x])
                              })
                              console.log(res)
                          }
                      
                          bfs(v = 0, d = 5) {
                              let q = []
                              let path = []
                              path.push(v)
                              q.push(path)
                              while (q.length) {
                                  path= q.shift()
                                  const last = path[path.length - 1]
                      
                                  if(last == d){
                                    console.log(path.map(x=>this.v[x]))
                                  }
                      
                                  const list = this.g[last]
                      
                                  for (let i of list) {
                                      if (!path.includes(i)) {
                                          q.push([...path,i])
                                      }
                                  }
                              }
                          }
                      
                      }
                      
                      const v = ['A', 'B', 'C', 'D', 'E', 'F']
                      const g = new Graph(6, v)
                      for (let i in v)
                          g.addVertex(i)
                      g.addEdge(0, 1);
                      g.addEdge(0, 3);
                      g.addEdge(0, 4);
                      g.addEdge(1, 2);
                      g.addEdge(3, 4);
                      g.addEdge(4, 5);
                      g.addEdge(4, 2);
                      g.addEdge(2, 5);
                      // g.removeVertex(1)
                      console.log("Graph:")
                      g.print()
                      console.log("All possible paths from A to F (bfs):")
                      g.bfs()
                      `,
                        output: `Graph:
                        {
                          A: [ 'B', 'D', 'E' ],
                          B: [ 'A', 'C' ],
                          C: [ 'B', 'E', 'F' ],
                          D: [ 'A', 'E' ],
                          E: [ 'A', 'D', 'F', 'C' ],
                          F: [ 'E', 'C' ]
                        }
                        All possible paths from A to F (bfs):
                        [ 'A', 'E', 'F' ]
                        [ 'A', 'B', 'C', 'F' ]
                        [ 'A', 'D', 'E', 'F' ]
                        [ 'A', 'E', 'C', 'F' ]
                        [ 'A', 'B', 'C', 'E', 'F' ]
                        [ 'A', 'D', 'E', 'C', 'F' ]`,
                      },
                    },
                  }}
                />
                <p>
                  <b>Array Permutations Using Dfs</b>
                </p>
                <CodeEditor
                  options={{
                    title: "Array Permutations",
                    codes: {
                      Javascript: {
                        output: `[
                      [ 1, 2, 3 ],
                      [ 1, 3, 2 ],
                      [ 2, 1, 3 ],
                      [ 2, 3, 1 ],
                      [ 3, 1, 2 ],
                      [ 3, 2, 1 ]
                    ]`,
                        code: `/**
                    * @param {number[]} nums
                    * @return {number[][]}
                    */
                   var permute = function (nums) {
                     let res = [];
                     let visited = Array(nums.length).fill(false);
                     dfs(nums, res, [], visited);
                     return res;
                   };
                   
                   var dfs = function (nums, res = [], curr = [], visited = []) {
                     if (curr.length == nums.length) {
                       res.push(curr.slice());
                       return;
                     }
                     for (let i in nums) {
                       if (visited[i] === false) {
                         visited[i] = true;
                         curr.push(nums[i]);
                         dfs(nums, res, curr, visited);
                         curr.pop();
                         visited[i] = false;
                       }
                     }
                   };
                   
                   console.log(permute([1, 2, 3]));
                   
                   `,
                      },
                    },
                  }}
                />
                <p>
                  <b>Detect Cycle in a Directed Graph</b>
                </p>
                <CodeEditor
                  options={{
                    title: "Detect Cycle in a Directed Graph",
                    codes: {
                      Javascript: {
                        code: `class Graph {
                          constructor(n, v) {
                            this.n = n;
                            this.g = {};
                            this.v = v;
                          }
                        
                          addVertex(i) {
                            this.g[i] = [];
                          }
                        
                          addEdge(u, v) {
                            this.g[u].push(v);
                          }
                        
                          hasCycleUtil(v, visited, stack) {
                            if (stack[v]) return true;
                            if (visited[v]) return false;
                            visited[v] = true;
                            stack[v] = true;
                            let list = this.g[v];
                            for (let i = 0; i < list.length; i++)
                              if (this.hasCycleUtil(i, visited, stack)) return true;
                            stack[v] = false;
                            return false;
                          }
                        
                          hasCycle() {
                            const visited = {};
                            const stack = {};
                            for (let i = 0; i < this.n; i++)
                              if (this.hasCycleUtil(i, visited, stack)) return true;
                            return false;
                          }
                        }
                        
                        const v = ["A", "B", "C", "D", "E", "F"];
                        const g = new Graph(6, v);
                        for (let i in v) g.addVertex(i);
                        g.addEdge(0, 1);
                        g.addEdge(0, 3);
                        g.addEdge(0, 4);
                        g.addEdge(1, 2);
                        g.addEdge(3, 4);
                        g.addEdge(4, 5);
                        g.addEdge(4, 2);
                        g.addEdge(2, 5);
                        console.log(g.hasCycle())`,
                        output: `true`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Trie</b>
                </Span>
                <p>
                  Trie is a tree which stores strings that can be used for
                  efficient information re<b>trie</b>val. Using well balanced
                  bst we can perform insert, delete and search operations in M *
                  O(log n) where M is max length of string and n is number of
                  keys , but we can implement same set of operations in O(M) in
                  trie.
                </p>
                <CodeEditor
                  options={{
                    title: "Trie",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        let ALPHABET_SIZE = 26;

                        class TrieNode {
                            constructor() {
                                this.children = new Array(ALPHABET_SIZE);
                                this.isEndOfWord = false;
                            }
                        }
                        
                        let keys = ["the", "a", "there", "answer",
                                               "any", "by", "bye", "their" 
                        ];
                        
                        let n = keys.length;
                        
                        class Trie {
                            constructor() {
                                this.root = new TrieNode()
                            }
                        
                            insert(key) {
                                let level;
                                let length = key.length;
                                let index;
                               
                                let pCrawl = this.root;
                                // pointer pCrawl == this.root pCrawl === this.root
                        
                                for (level = 0; level < length; level++)
                                {
                                    index = key[level].charCodeAt(0) - 97;
                                    if (pCrawl.children[index] == null)
                                        pCrawl.children[index] = new TrieNode();
                               
                                    pCrawl = pCrawl.children[index];
                                }
                                pCrawl.isEndOfWord = true;
                            }
                        
                            search(key) {
                                let level;
                                let length = key.length;
                                let index;
                                let pCrawl = this.root;
                               
                                for (level = 0; level < length; level++)
                                {
                                    index = key[level].charCodeAt(0) - 'a'.charCodeAt(0);
                               
                                    if (!pCrawl.children[index])
                                        return false;
                               
                                    pCrawl = pCrawl.children[index];
                                }
                               
                                return (pCrawl.isEndOfWord);
                            }
                        
                            isEmpty(root=this.root) {
                                for (let i = 0; i < ALPHABET_SIZE; i++)
                                    if (root.children[i] != null)
                                        return false;
                                return true;
                            }
                        
                            remove(key,depth){
                             this.removeHelper(key,depth,this.root)
                            }
                        
                            removeHelper(key, depth,root) {
                                if (root == null)
                                    return null;
                                if (depth == key.length) {
                                    if (root.isEndOfWord)
                                        root.isEndOfWord = false;
                                    if (this.isEmpty(root)) {
                                        root = null;
                                    }
                                    return root;
                            }
                        
                            let index = key[depth].charCodeAt(0) - 'a'.charCodeAt(0);
                            root.children[index] =
                            this.removeHelper(key, depth + 1,root.children[index]);
                        
                            if (this.isEmpty(root) && root.isEndOfWord == false) {
                                    root = null;
                            }
                            return root
                            }
                        
                            isLeafNode(root)
                            {
                            return root.isEndOfWord !== false;
                            }
                          
                            print(){
                             this.printHelper(this.root,[],0)
                            }
                        
                            printHelper(root,str,level){
                        
                        
                            if (this.isLeafNode(root)) 
                            {
                                str[level] = '';
                                console.log(str.join('').substring(0,str.indexOf('')))
                            }
                            let i;
                            for (i = 0; i < ALPHABET_SIZE; i++) 
                            {
                        
                                if (root.children[i] !== undefined) 
                                {
                                    str[level] = String.fromCharCode(i+97);
                                    this.printHelper(root.children[i], str, level + 1);
                                }
                            }
                            }
                        
                        }
                        
                        const trie = new Trie();
                        
                        for (let i = 0; i < n; i++)
                            trie.insert(keys[i]);
                        
                        console.log(trie.search("the")) // true
                        console.log(trie.search("anyer")) // false
                        trie.print()
                        trie.remove("the", 0);
                        console.log(trie.search("the")) // false
                        trie.print()
                        
                        `,
                        output: `
                        true
                        false
                        a
                        answer
                        any
                        by
                        bye
                        the
                        their
                        there
                        false
                        a
                        answer
                        any
                        by
                        bye
                        their
                        there
                        `,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Tabular",
            content: (
              <>
                <Span>
                  <b>Hash Table</b>
                </Span>
                <Span>
                  A Hash Table transforms a key into an integer index using a
                  hash function, and the index will decide where to store the
                  key/value pair in memory:
                </Span>
                <b>Implementation</b>
                <CodeEditor
                  options={{
                    title: "Hash Table",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        class HashTable {
                          constructor() {
                            this.table = new Array(127);
                            this.size = 0;
                          }
                        
                          _hash(key) {
                            let hash = 0;
                            for (let i = 0; i < key.length; i++) {
                              hash += key.charCodeAt(i);
                            }
                            return hash % this.table.length;
                          }
                        
                          set(key, value) {
                            const index = this._hash(key);
                            this.table[index] = [key, value];
                            this.size++;
                          }
                        
                          get(key) {
                            const target = this._hash(key);
                            return this.table[target];
                          }
                        
                          remove(key) {
                            const index = this._hash(key);
                        
                            if (this.table[index] && this.table[index].length) {
                              this.table[index] = [];
                              this.size--;
                              return true;
                            } else {
                              return false;
                            }
                          }
                        }
                        
                        const ht = new HashTable();
                        
                        ht.set("a", 1);
                        ht.set("b", 2);
                        console.log(ht.get("a")); 
                        console.log(ht.get("b"));
                        `,
                        output: `
                        [ 'a', 1 ]
[ 'b', 2 ]
                        `,
                      },
                    },
                  }}
                />
                <Span>Map</Span>
                <p>
                  A map stores a collection of (key,value) pairs, such that each
                  possible key appears at most once in the collection.
                </p>
                <CodeEditor
                  options={{
                    title: "Map",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        const map = new Map();

map.set('a', 1);
map.set('b', 2);
map.set('c', 3);
console.log(map.get('a'));
map.set('a', 97);
console.log(map.get('a'));
console.log(map.size);
map.delete('b');
console.log(map.size);
console.log(map.has('a'))
for (const [key, value] of map) {
  console.log(key + ' = ' + value)
}
map.forEach(function(value, key) {
  console.log(key + ' = ' + value)
})
console.log([...map])
console.log(Array.from(map))
const original = new Map([
  [1, 'one']
])
const clonedMap = new Map(original)
console.log(Array.from(original))
console.log(map.entries())

                        `,
                        output: `
                        1
97
3
2
true
a = 97
c = 3
a = 97
c = 3
[ [ 'a', 97 ], [ 'c', 3 ] ]
[ [ 'a', 97 ], [ 'c', 3 ] ]
[ [ 1, 'one' ] ]
[Map Entries] { [ 'a', 97 ], [ 'c', 3 ] }
                        `,
                      },
                      Java: {
                        code: `
                        // All three classes HashMap, TreeMap and LinkedHashMap
// implements java.util.Map interface, and represents
// mapping from unique key to values.

import java.util.*;
import java.io.*;

class Main{

  static PrintStream ps = System.out;

  public static void main(String ...s){
  
  // HashMap is implemented by array of linked lists. It contains 
  // unique elements. It may have one null key and multiple null
  // values.
  // It maintains no order.
  // HashMap extends AbstractMap

  HashMap<Integer, String> map = new HashMap<Integer, String>();
  map.put(9,"b");
  map.put(8,"a");
  ps.println(map.get(9));
  ps.println(map.containsKey(8));
  ps.println(map.containsValue("b"));
  ps.println(map.keySet());
  map.entrySet().forEach(ps::println);
  
  // LinkedHashMap is same as hahmap but maintains insertion
  // order and is implemeted by doubly linked buckets.

  Map<Integer, String> map1 = new LinkedHashMap<Integer, String>();
  map1.put(8,"a");
  map1.put(9,"b");
  map1.entrySet().forEach(ps::println);

  // A TreeMap contains values based on the key
  // It is same as HashMap instead maintains ascending 
  // order(Sorted using the natural order of its key). 

  Map<Integer, String> map2 = new TreeMap<Integer, String>();
  map2.put(9,"a");
  map2.put(8,"b");
  map2.entrySet().forEach(ps::println);

  // A HashTable is an array of list (bucket). Position of
  // bucket is identified by calling hashcode()


  Hashtable<Integer, String> ht = new Hashtable<>(4);
  ht.put(1, "one");
  ht.put(2, "two");
  ht.put(3, "three");
  ps.println(ht);
  // It contains only unique elements . It is synchronized
  // and a legacy class.

  }

}
                        `,
                        output: `
                        b
true
true
[8, 9]
8=a
9=b
8=a
9=b
8=b
9=a
{3=three, 2=two, 1=one}
                        `,
                      },
                      Kotlin: {
                        code: `import java.util.*
                        import java.util.function.Consumer
                        
                        internal object Main {
                            private var ps = System.out
                            @JvmStatic
                            fun main(s: Array<String>) {
                                
                                val map = HashMap<Int, String>()
                                map[9] = "b"
                                map[8] = "a"
                                ps.println(map[9])
                                ps.println(map.containsKey(8))
                                ps.println(map.containsValue("b"))
                                ps.println(map.keys)
                                map.entries.forEach(Consumer<Map.Entry<Int, String>> { x: Map.Entry<Int, String>? ->
                                    ps.println(
                                        x
                                    )
                                })
                                val map1: MutableMap<Int, String> = LinkedHashMap()
                                map1[8] = "a"
                                map1[9] = "b"
                                map1.entries.forEach(Consumer<Map.Entry<Int, String>> { x: Map.Entry<Int, String>? ->
                                    ps.println(
                                        x
                                    )
                                })
                                val map2: MutableMap<Int, String> = TreeMap()
                                map2[9] = "a"
                                map2[8] = "b"
                                map2.entries.forEach(Consumer<Map.Entry<Int, String>> { x: Map.Entry<Int, String>? ->
                                    ps.println(
                                        x
                                    )
                                })
                                val ht = Hashtable<Int, String>(4)
                                ht[1] = "one"
                                ht[2] = "two"
                                ht[3] = "three"
                                ps.println(ht)
                            }
                        }`,
                        output: `
                        b
                        true
                        true
                        [8, 9]
                        8=a
                        9=b
                        8=a
                        9=b
                        8=b
                        9=a
                        {3=three, 2=two, 1=one}
                        `,
                      },
                      Python: {
                        code: `# Python Maps also called ChainMap is a type of data
# structure to manage multiple dictionaries together 
# as one unit. The combined dictionary contains the key
# and value pairs in a specific sequence eliminating 
# any duplicate keys. 

import collections

dict1 = {'day1': 'Mon', 'day2': 'Tue'}
dict2 = {'day3': 'Wed', 'day1': 'Thu'}

res = collections.ChainMap(dict1, dict2)

print(res.maps)

print('Keys = {}'.format(list(res.keys())))
print('Values = {}'.format(list(res.values())))
print()

print('elements:')
for key, val in res.items():
   print('{} = {}'.format(key, val))
print()

print('day3 in res: {}'.format(('day1' in res)))
print('day4 in res: {}'.format(('day4' in res)))

res2 = collections.ChainMap(dict2, dict1)
print(res2.maps)

dict2['day4'] = 'Fri'
print(res.maps)`,
                        output: `
                        [{'day1': 'Mon', 'day2': 'Tue'}, {'day3': 'Wed', 'day1': 'Thu'}]
Keys = ['day3', 'day1', 'day2']
Values = ['Wed', 'Mon', 'Tue']

elements:
day3 = Wed
day1 = Mon
day2 = Tue

day3 in res: True
day4 in res: False
[{'day3': 'Wed', 'day1': 'Thu'}, {'day1': 'Mon', 'day2': 'Tue'}]
[{'day1': 'Mon', 'day2': 'Tue'}, {'day3': 'Wed', 'day1': 'Thu', 'day4': 'Fri'}]
                        `,
                      },
                      "C++": {
                        code: `
                        // #include <iostream>
// #include <iterator>
// #include <map>
#include <bits/stdc++.h>

using namespace std;

// map vs unordered_map

// order is maintained in map and we need predecessor/successor 
// of elements whereas no order is required in case of
// unordered map, single element can be accessed w/o any 
// traversal and we need to keep count of some data.

int main()
{

	map<int, int> m;
  m[0] = 60;
	m.insert(pair<int, int>(1, 40));
	m.insert(pair<int, int>(2, 30));
	m.insert(pair<int, int>(3, 60));
	m.insert(pair<int, int>(4, 20));
	m.insert(pair<int, int>(5, 50));
	m.insert(pair<int, int>(6, 50));
	m.insert(pair<int, int>(7, 10));
	// m.erase(m.begin(), m.find(3));
  m.erase(m.find(3));
	map<int, int>::iterator itr;
	cout << "The map m is : "<< endl;
	for (itr = m.begin(); itr != m.end(); ++itr) {
		cout << itr->first << ':' << itr->second<< endl;
	}
	cout << endl;

  unordered_map<int, int> umap;

	umap[5] = 10;
	umap[3] = 5;
	umap[20] = 100;
	umap[1] = 1;
  umap.erase(1);

	for (auto i = umap.begin();
		i != umap.end(); i++)
	{
		cout << i->first
				<< " : "
				<< i->second << endl;
	}

  if(umap.find(9)==umap.end()){
    cout << "Not found"  << endl;
  }

	return 0;
}
                        `,
                        output: `
                        The map m is : 
0:60
1:40
2:30
4:20
5:50
6:50
7:10

20 : 100
5 : 10
3 : 5
Not found
                        `,
                      },
                    },
                  }}
                />
                <Span>Set</Span>
                <p>
                  A set is an abstract data type that can store unique values,
                  without any particular order.
                  {/* https://www.tutorialspoint.com/python_data_structure/python_maps.htm */}
                </p>
                <CodeEditor
                  options={{
                    title: "Set",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        const customSet = new Set()

                        customSet.add(1)          
                        customSet.add(5)           
                        customSet.add(5)    
                        customSet.add(true)           
                        customSet.add('abc') 
                        const o = {a: 1, b: 2}
                        customSet.add(o)
                        customSet.delete(1)
                        console.log(customSet)
                        console.log(customSet.has(o))
                        console.log(customSet.size)
                        const mySet1 = customSet
                        console.log(Array.from(new Set([1,2,3,3])))
                        for (let item of mySet1) console.log(item)
                        for (let item of mySet1.keys()) console.log(item)
                        for (let [key, value] of mySet1.entries()) console.log(key)
                        
                        `,
                        output: `
                        Set { 5, true, 'abc', { a: 1, b: 2 } }
                        true
                        4
                        [ 1, 2, 3 ]
                        5
                        true
                        abc
                        { a: 1, b: 2 }
                        5
                        true
                        abc
                        { a: 1, b: 2 }
                        5
                        true
                        abc
                        { a: 1, b: 2 }
                        `,
                      },
                      Java: {
                        code: `
                        // All three classes HashSet, TreeSet and LinkedHashSet
// implements java.util.Set interface, and represents
// mapping from unique key to values.

import java.util.*;
import java.io.*;

// HashSet: If you don't want to maintain insertion order but want  
// to store unique objects. (thread -safe)
// LinkedHashSet: If you want to maintain the insertion order of 
// elements then you can use LinkedHashSet. (not thread -safe)
// TreeSet: If you want to sort the elements according to some 
// Comparator then use TreeSet. (not thread -safe)

// Order of execution forinsertion and deletion operations:
// TreeSet > LinkedHashSet > HashSet

class Helper implements Comparator<String> {
  
    public int compare(String str1, String str2)
    {
  
        String first_Str;
        String second_Str;
  
        first_Str = str1;
        second_Str = str2;
  
        return second_Str.compareTo(first_Str);
    }
}


class Main{

  static PrintStream ps = System.out;

  public static void main(String ...s){

    LinkedHashSet<String> set1
            = new LinkedHashSet<>();
    TreeSet<String> set2 = new TreeSet<>();
    HashSet<String> set3 = new HashSet<String>();
    TreeSet<String> set4 = new TreeSet<String>(new Helper());

    for (String str : Arrays.asList("A", "B","C", "D")) {
      set1.add(str);
      set2.add(str);
      set3.add(str);
      set4.add(str);
    }

    set1.remove("D");

    ps.println(set1);
    ps.println(set2);
    ps.println(set3);
    ps.println(set4);
    ps.println(set1.contains("A"));

  }

}
                        `,
                        output: `
                        [A, B, C]
[A, B, C, D]
[A, B, C, D]
[D, C, B, A]
true
                        `,
                      },
                      Kotlin: {
                        code: `import java.io.PrintStream
                        import java.util.*
                        
                        internal class Helper : Comparator<String> {
                            override fun compare(str1: String, str2: String): Int {
                                val str1: String = str1
                                return str2.compareTo(str1)
                            }
                        }
                        
                        internal object Main {
                            private var ps: PrintStream = System.out
                            @JvmStatic
                            fun main(s: Array<String>) {
                                val set1 = LinkedHashSet<String>()
                                val set2 = TreeSet<String>()
                                val set3 = HashSet<String>()
                                val set4 = TreeSet(Helper())
                                for (str in listOf("A", "B", "C", "D")) {
                                    set1.add(str)
                                    set2.add(str)
                                    set3.add(str)
                                    set4.add(str)
                                }
                                set1.remove("D")
                                ps.println(set1)
                                ps.println(set2)
                                ps.println(set3)
                                ps.println(set4)
                                ps.println(set1.contains("A"))
                            }
                        }`,
                        output: `
                        [A, B, C]
                        [A, B, C, D]
                        [A, B, C, D]
                        [D, C, B, A]
                        true
                        `,
                      },
                      Python: {
                        code: `days=set(["Mon","Tue","Wed","Thu","Fri","Sat","Sun",1])

months={"Jan","Feb","Mar"}

dates={21,22,17}

days.add("Sun")

days.discard(1)

# set1 - set2 set1 & set2 

print(days)
print(months)
print(dates)
                        `,
                        output: `
                        {'Sat', 'Tue', 'Mon', 'Fri', 'Sun', 'Wed', 'Thu'}
{'Feb', 'Jan', 'Mar'}
{17, 21, 22}
                        `,
                      },
                      "C++": {
                        code: `
                        #include <bits/stdc++.h>
using namespace std;

set<int> s;

void inorderSuccessor(int key) {
	if (s.find(key) == s.end()) {
		cout << "Key doesn't exist"<<endl;
		return;
	}
	set<int>::iterator it;
	it = s.find(key);
	++it;
	if (it == s.end()) {
		cout << "No successor"<<endl;
		return;
	}
	cout << "successor of " << key << " is=";
	cout << *(it) << endl;
}

void inorderPredecessor(int key) {
	if (s.find(key) == s.end()) {
		cout << "Key doesn't exist"<<endl;
		return;
	}

	set<int>::iterator it;
	it = s.find(key);
	if (it == s.begin()) {
		cout << "No predecessor"<<endl;
		return;
	}
	--it;
	cout << "predecessor of " << key << " is=";
	cout << *(it) << endl;
}

int main() {
	s.insert(5);
	s.insert(1);
	s.insert(6);
	s.insert(3);
	s.insert(7);
	s.insert(2);
	s.erase(7);
	unordered_set<int> s1;
	s1.insert(5);
	s1.insert(1);
	s1.insert(6);
	s1.insert(3);
	s1.insert(7);
	s1.insert(2);
	s1.erase(7);

	cout << "Elements of set in sorted order:" << endl;
	for (auto it : s)
		cout << it << " ";
	cout << endl;

	cout << "Elements of unordered_set:" << endl;
	for (auto it1 : s1)
		cout << it1 << " ";
	cout << endl;
	inorderPredecessor(5);
	inorderPredecessor(1);
	inorderPredecessor(8);
	inorderSuccessor(5);
	inorderSuccessor(2);
	inorderSuccessor(9);

	return 0;
}
                        `,
                        output: `
                        Elements of set in sorted order:
1 2 3 5 6 
Elements of unordered_set:
2 3 6 5 1 
predecessor of 5 is=3
No predecessor
Key doesn't exist
successor of 5 is=6
successor of 2 is=3
Key doesn't exist
                        `,
                      },
                    },
                  }}
                />
              </>
            ),
          },
        ],
        content: (
          <>
            <Span>
              Non-primitive data structure can store the data of more than one
              type whose size is not fixed.
            </Span>
            <Span>
              <b>Physical Data Structure</b>
            </Span>
            <Span>
              A physical data structure refers to the actual organization of
              data on a storage device.
            </Span>
            <Span>
              <b>e.g. : </b> Arrays and Linked List
            </Span>
            <Span>
              <b>Logical Data Structure</b>
            </Span>
            <Span>
              The logical data structure refers to how the information appears
              to a program or user. For example, a data file is a collection of
              information stored together. This is its logical structure.
            </Span>
            <Span>
              <b>e.g. :</b> Stack, Queue, Graph. Tree , Trie , Hash Table
            </Span>
            <Span>
              <b>Tabular Data Structure (Hash Tables)</b>
            </Span>
            <Span>
              It stores data in an associative manner. It can be linear as well
              as non linear. Access of data becomes really easy if its unique
              index is known.
            </Span>
            <Span>
              <b>Hashing</b>
            </Span>
            <Span>
              It's a technique to convert a range of keys into range of indexes
              of an array.
            </Span>
            <Span>array index h(x) = key % constant</Span>
            <Span>
              <b>Collision Resolution:</b>
            </Span>
            <Span>
              It may happen that hashing technique results into a index which
              already exists.This is known as collision. We make use of
              collision resolution to assign indexes to keys in such kind of
              scenarios.
            </Span>
            <Span>
              <b>Types:</b>
            </Span>
            <Span>
              <b>Open Hashing (Separate Chaining) </b>
            </Span>
            <Span>
              We make each cell of point table point to a linked list of
              records.
            </Span>
            <Span>
              <b>Closed Hashing ( Open Addressing )</b>
            </Span>
            <Span>
              In Open Addressing, all elements are stored in the hash table
              itself. So at any point, the size of the table must be greater
              than or equal to the total number of keys
            </Span>
            <Span>
              <b>Types:</b>
            </Span>
            <Span>
              <b>Linear Probing</b>
            </Span>
            <Span>
              In this type, we simply make use of next slot in case of
              collision.
            </Span>
            <Span>h(x) = (h(x) + i)% constant , i= 0,1,2,3</Span>
            <Span>
              Primary clustering and secondary clustering are two challenges for
              linear probing.
              <br /> In primary clusetring, many consecutive elements form
              groups and it starts taking time to find a free slot or to search
              for an element.
              <br />
              Two records only have the same collision chain (Probe Sequence) if
              their initial position is the same. It's less severe.
            </Span>
            <Span>
              <b>Quadratic Probing</b>
            </Span>
            <Span>We look for i*ith slot in ith iteration.</Span>
            <Span>h(x) = (h(x) + i*2) % constant ,and i= 0,1,2,3</Span>
            <Span>
              <b>Double hashing</b>
            </Span>
            <Span>
              We use another hash function g(x) and look for i*g(x) slot in ith
              rotation.{" "}
            </Span>
            <Span>h(x) = (h(x) + i*g(x))% constant</Span>
            <Span>
              <b>Hash functions:</b>
            </Span>
            <Span>
              Mod ( h(x) = x % size + 1 ) , Mid Square ( h(x) = |x*x| ) and
              Folding ( add all elements and ignore carry's )
            </Span>
          </>
        ),
      },
    },
  },
  algo: {
    title: "Algorithms",
    content: (
      <>
        <Span>
          It's a step by step procedure to solve computational problem.
        </Span>
        <Span>
          <b>Algorithm:</b>
        </Span>
        <Span>
          1. It's written at design time.
          <br />
          2. Author should have domain knowledge.
          <br />
          3. It can written in any language.
          <br />
          4. It should be machine independent.
          <br />
          5. It can be analyzed for space/time complexity.
          <br />
          6. Priori analysis is associated with algorithm.
        </Span>
        <Span>
          <b>Program:</b>
        </Span>
        <Span>
          1. It's written at implementation time.
          <br />
          2. Should be written by programmer.
          <br />
          3. It can written in a programming language.
          <br />
          4. It should be machine dependent ( hardware and operating system ).
          <br />
          5. It can be tested (watch time and bytes).
          <br />
          6. Posteriori analysis is associated with program.
        </Span>
        <Span>
          <b>Charactersitic's of algorithm:</b>
        </Span>
        <p>
          <p>1. Input: 0 or more</p>
          <p>2. Output: atleast 1</p>
          <p>3. Definiteness: every stmt. should be clear</p>
          <p>4. Finiteness: algorithm should have finite steps</p>
          <p>5. Effectiveness: every step must be used</p>
        </p>
        <br />
        <Span>
          <b>Algorithms can be analysed using following parameters:</b>
        </Span>
        <Span>
          1. Time <br /> 2. Space <br /> 3. N/W Consumption <br /> 4. Power
          Consumption <br /> 5. CPU Registers Consumption
        </Span>

        <Span>
          <b>STAR Method</b>
        </Span>
        <Span>
          The STAR method is a technique used to answer behavioral interview
          questions in a structured and compelling way. Behavioral questions
          prompt job candidates to give specific examples of how they’ve handled
          past situations or challenges.
          <br />
          The acronym STAR stands for –– situation, task, action, result. Each
          pillar helps you tell a well-thought-out short story that has a
          beginning, middle, and end. Let’s take a closer look at each one:
          <br />
          Situation: Set the scene by briefly describing the situation,
          challenge, or event you faced
          <br />
          Task: Explain what your responsibilities were in that situation. What
          role did you play?
          <br />
          Action: Describe what steps you took to overcome the challenge or
          address the situation
          <br />
          Result: Share what you achieved through your actions
        </Span>
      </>
    ),
    types: {
      sort: {
        title: "Sorting",
        types: [
          {
            title: "Comparison Based",
            content: (
              <>
                <Span>Let n be number of elements.</Span>
                <Span>
                  <b>Bubble Sort</b>
                </Span>
                <Span>
                  We can visualize this sort with the help of act of throwing
                  stones into the sea. More heavier stone will settle at tha
                  bottom compared to lighter ones. It's adaptive as well as
                  stable. We can't perform this sort at linked list due to time
                  consumption. Each of k passes, provides us the kth largest
                  element.
                </Span>
                <p>
                  Best TC: O(n) ( already sorted ) <br />
                  Average TC: O(n^2) <br />
                  Worst TC: O(n^2) <br />
                  Worst Space Complexity: O(1) <br />
                  Number of passes: n-1 <br />
                  Number of comparisons : n(n-1)/2 <br />
                  Max. Number of swaps: n(n-1)/2
                </p>
                <CodeEditor
                  options={{
                    title: "Bubble Sort",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        function bubbleSort(arr, n)
{
	  let i, j, temp;
		let flag;
		for (i = 0; i < n - 1; i++)
		{
			flag = false;
			for (j = 0; j < n - i - 1; j++)
			{
				if (arr[j] > arr[j + 1])
				{
					temp = arr[j];
					arr[j] = arr[j + 1];
					arr[j + 1] = temp;
					flag = true;
				}
			}
      // optimized way to brak outer loop in b/w
      // if provided i/p is already sorted.
			if (flag == false)
				break;
		}
}

var arr = [ 64, 34, 25, 12, 22, 11, 90 ];
var n = arr.length;
bubbleSort(arr, n);
console.log(arr)
                        `,
                        output: `
                        [
                          11, 12, 22, 25,
                          34, 64, 90
                        ]
                        `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Insertion Sort</b>
                </Span>
                <Span>
                  It works similar to the way we sort playing cards after each
                  iteration. We split original array virtually into two parts:
                  sorted and unsorted. Values from the unsorted part are picked
                  and placed at the correct position in the sorted part. It's
                  adaptive (by nature) and stable. And can be used to sort the
                  linked list.
                </Span>
                <Span>
                  We can use binary search to reduce the number of comparisons
                  in normal insertion sort. Binary Insertion Sort uses binary
                  search to find the proper location to insert the selected item
                  at each iteration. In normal insertion, sorting takes O(i) (at
                  ith iteration) in worst case. We can reduce it to O(logi) by
                  using binary search. It makes use of incremental approach.
                </Span>
                <Span>
                  Insertion sort is used when number of elements is small. It
                  can also be useful when input array is almost sorted, only few
                  elements are misplaced in complete big array.
                </Span>
                <Span>
                  For very small n, Insertion Sort is faster than more efficient
                  algorithms such as Quicksort or Merge Sort.
                </Span>
                <p>
                  Best TC: O(n) ( already sorted ) <br />
                  Average TC: O(n^2) <br />
                  Worst TC: O(n^2) <br />
                  Worst Space Complexity: O(1) <br />
                  Number of passes: n-1 <br />
                  Number of comparisons : n(n-1)/2 <br />
                  Max. Number of swaps: n(n-1)/2 O(n*n)
                </p>
                <CodeEditor
                  options={{
                    title: "Insertion Sort",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        function insertionSort(arr, n)
{
	let i, key, j;
	for (i = 1; i < n; i++)
	{
		key = arr[i];
		j = i - 1;
		while (j >= 0 && arr[j] > key)
		{
			arr[j + 1] = arr[j];
			j = j - 1;
		}
		arr[j + 1] = key;
	}
}

let arr = [12, 11, 13, 5, 6 ]; 
let n = arr.length; 
insertionSort(arr, n); 
console.log(arr)
                        `,
                        output: `
                        [ 5, 6, 11, 12, 13 ]
                        `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Selection Sort</b>
                </Span>
                <Span>
                  This is used to place smallest element at the start after each
                  iteration. It's neither adaptive nor stable.Each of k passes,
                  provides us the kth smallest element. It can be made stable.
                  It's used when no. of swaps are limited.
                </Span>
                <Span>
                  Selection sort performs a smaller number of swaps compared to
                  bubble sort; therefore, even though both sorting methods are
                  of O(N2), selection sort performs faster and more efficiently!
                </Span>
                <p>
                  Best TC: O(n^2)
                  <br />
                  Average TC: O(n^2) <br />
                  Worst TC: O(n^2) <br />
                  Worst Space Complexity: O(1) <br />
                  Number of swap: n-1 ( minimum among all kinds of sorts ){" "}
                  <br />
                  No. of comparisons: n(n-1)/2, O(n*n)
                </p>
                <CodeEditor
                  options={{
                    title: "Selection Sort",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        function selectionSort(arr, n)
{
	let i, j, min, temp;
	for (i = 0; i < n-1; i++)
	{
    min = i;
    for(j = i+1; j<n; j++)
    if(arr[j]<arr[min]){
      min = j;
    }
    temp = arr[i]
    arr[i] = arr[min]
    arr[min] = temp
	}
}

let arr = [12, 11, 13, 5, 6 ]; 
let n = arr.length; 
selectionSort(arr, n); 
console.log(arr)
                        `,
                        output: `
                        [ 5, 6, 11, 12, 13 ]
                        `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Heap Sort</b>
                </Span>
                <Span>
                  It is based on binary heap. It's similar to selection sort
                  where place the minimum element at start after each iteration.
                </Span>
                <Span>
                  The time required to perform Heap sort increases
                  logarithmically while other algorithms may grow exponentially
                  slower as the number of items to sort increases. This sorting
                  algorithm is very efficient. Memory usage is minimal because
                  apart from what is necessary to hold the initial list of items
                  to be sorted, it needs no additional memory space to work
                </Span>
                <Span>
                  Best TC: O(nlog n)
                  <br />
                  Average TC: O(nlog n) <br />
                  Worst TC: O(nlog n) <br />
                  Space Complexity: O(n)
                </Span>
                <Span>
                  <b>Array representation for binary heap:</b>
                </Span>
                <Span>
                  Since , binary heap is a complete binary tree , if the parent
                  node is stored at index i, then left child can be calculated
                  using 2*i + 1 and right can be defined using 2*i +2
                </Span>
                <Span>
                  <b>Heapify</b>
                </Span>
                <p>
                  The process of reshaping a binary tree into a Heap data
                  structure is known as "heapify".{" "}
                </p>
                <CodeEditor
                  options={{
                    title: "Heap Sort",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        function sort(arr)
                        {
                          var n = arr.length;
                      
                          for (var i = Math.floor(n / 2) - 1; i >= 0; i--) // O(n)
                            heapify(arr, n, i); // O(log n)
                            // O(nlog n)
                      
                          for (var i = n - 1; i > 0; i--) {
                            var temp = arr[0];
                            arr[0] = arr[i];
                            arr[i] = temp;
                            heapify(arr, i, 0);
                          }
                        }
                      
                          function heapify(arr, n, i)
                          {
                              var largest = i; 
                              var l = 2 * i + 1; 
                              var r = 2 * i + 2; 
                      
                              if (l < n && arr[l] > arr[largest])
                                  largest = l;
                      
                               if (r < n && arr[r] > arr[largest])
                                  largest = r;
                      
                              if (largest != i) {
                                  var swap = arr[i];
                                  arr[i] = arr[largest];
                                  arr[largest] = swap;
                                  heapify(arr, n, largest);
                              }
                          }
                      
                      const arr = [9,2,7,1,0]
                      sort(arr)
                      console.log(arr)                      
                        `,
                        output: `[ 0, 1, 2, 7, 9 ]`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Merge Sort</b>
                </Span>
                <Span>
                  It is a divide and conquer algorithm. It divides the array
                  into two parts, calls itself for each half and merges two
                  halves.It's stable.
                </Span>
                <Span>
                  Unlike arrays, linked list nodes may not be adjacent in
                  memory. Unlike array, in linked list, we can insert items in
                  the middle in O(1) extra space and O(1) time. Therefore merge
                  operation of merge sort can be implemented without extra space
                  for linked lists.
                </Span>
                <Span>
                  Merge Sort is a recursive algorithm and time complexity can be
                  expressed as following recurrence relation.
                  <br /> T(n) = 2T(n/2) + θ(n)
                </Span>
                <Span>
                  It's used for sorting linked list in nlog(n) time.And it's
                  used in external sorting. It's slower than other algorithms
                  for smaller tasks. Merge sort algorithm requires an additional
                  memory space of 0(n) for the temporary array. It goes through
                  the whole process even if the array is sorted.
                </Span>
                <Span>
                  <b>External sorting</b>
                </Span>
                <Span>
                  External sorting is a class of sorting algorithms that can
                  handle massive amounts of data. External sorting is required
                  when the data being sorted do not fit into the main memory of
                  a computing device (usually RAM) and instead they must reside
                  in the slower external memory, usually a hard disk drive.
                  Thus, external sorting algorithms are external memory
                  algorithms and thus applicable in the external memory model of
                  computation. External sorting algorithms generally fall into
                  two types, distribution sorting, which resembles quicksort,
                  and external merge sort, which resembles merge sort.
                </Span>
                <Span>
                  merge sort always divides the array into two halves and takes
                  linear time to merge two halves.
                </Span>
                <Span>
                  Best TC: O(nlog n)
                  <br />
                  Average TC: O(nlog n) <br />
                  Worst TC: O(nlog n) <br />
                  Space Complexity: O(n) //depends
                </Span>
                <Span>
                  <b>Merge vs heap sort:</b>
                </Span>
                <p>
                  HeapSort is the slowest of the sorting algorithms but unlike
                  merge and quick sort it does not require massive recursion or
                  multiple arrays to work. The merge sort is slightly faster
                  than the heap sort for larger sets, but it requires twice the
                  memory of the heap sort because of the second array.
                </p>
                <CodeEditor
                  options={{
                    title: "Merge Sort",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        function merge(arr, l, m, r)
{
    var n1 = m - l + 1;
    var n2 = r - m;
    var L = Array(n1); 
    var R = Array(n2);
    for (var i = 0; i < n1; i++)
        L[i] = arr[l + i];
    for (var j = 0; j < n2; j++)
        R[j] = arr[m + 1 + j];
    var i = 0;
    var j = 0;
    var k = l;

    while (i < n1 && j < n2) {
        if (L[i] <= R[j]) {
            arr[k] = L[i];
            i++;
        }
        else {
            arr[k] = R[j];
            j++;
        }
        k++;
    }

    while (i < n1) {
        arr[k] = L[i];
        i++;
        k++;
    }
    while (j < n2) {
        arr[k] = R[j];
        j++;
        k++;
    }
}

function mergeSort(arr,l, r){
    if(l>=r){
        return;
    }
    var m =l+ parseInt((r-l)/2);
    mergeSort(arr,l,m);
    mergeSort(arr,m+1,r);
    merge(arr,l,m,r);
}


var arr = [ 12, 11, 13, 5, 6, 7 ];
var arr_size = arr.length;
mergeSort(arr, 0, arr_size - 1);
console.log(arr, arr_size);
                        `,
                        output: `
                        [ 5, 6, 7, 11, 12, 13 ] 6
                        `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Quick Sort (Partition/Selection exchange sort)</b>
                </Span>
                <Span>
                  It's also a divide and conquer algorithm. It's a internal
                  sorting algorithm. It picks an element as pivot and partitions
                  the given array around the picked pivot. There are many
                  different versions of quickSort that pick pivot in different
                  ways.
                </Span>
                <Span>
                  The key process in quickSort is partition(). Target of
                  partitions is, given an array and an element x of array as
                  pivot, put x at its correct position in sorted array and put
                  all smaller elements (smaller than x) before x, and put all
                  greater elements (greater than x) after x. All this should be
                  done in linear time
                </Span>
                <Span>
                  Best TC: O(nlog n)
                  <br />
                  Average TC: O(nlog n) <br />
                  Worst TC: O(n*n) <br />
                  Space Complexity: O(n)
                </Span>
                <Span>
                  Quick Sort is preferred over MergeSort for sorting Arrays. For
                  arrays, merge sort loses due to the use of extra O(N) storage
                  space. Quick Sort is also a cache friendly sorting algorithm
                  as it has good locality of reference when used for arrays.
                  Quick Sort is also tail recursive, therefore tail call
                  optimizations is done.
                </Span>
                <Span>
                  The time complexity of Quicksort is O(n log n) in the best
                  case, O(n log n) in the average case, and O(n^2) in the worst
                  case. But because it has the best performance in the average
                  case for most inputs, Quicksort is generally considered the
                  “fastest” sorting algorithm.
                </Span>
                <p>
                  Quick sort is not stable and merge sort is preferred over
                  quick sort for linked lists.
                </p>
                <CodeEditor
                  options={{
                    title: "Quick Sort",
                    output: null,
                    codes: {
                      Python: {
                        code: `def partition(start, end, array):
	
    pivot_index = start
    pivot = array[pivot_index]
    while start < end:
      while start < len(array) and array[start] <= pivot:
        start += 1
      while array[end] > pivot:
        end -= 1
      if(start < end):
        array[start], array[end] = array[end], array[start]
    array[end], array[pivot_index] = array[pivot_index], array[end]
    return end
    
def quick_sort(start, end, array):
    
    if (start < end):
      p = partition(start, end, array)
      quick_sort(start, p - 1, array)
      quick_sort(p + 1, end, array)
      
array = [ 10, 7, 8, 9, 1, 5 ]
quick_sort(0, len(array) - 1, array)  
print(f'Sorted array: {array}')
                        `,
                        output: `Sorted array: [1, 5, 7, 8, 9, 10]`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Shell Sort</b>
                </Span>
                <Span>
                  ShellSort is mainly a variation of Insertion Sort. In
                  insertion sort, we move elements only one position ahead. When
                  an element has to be moved far ahead, many movements are
                  involved.
                </Span>
                <Span>
                  Best TC: O(nlog n) // already sorted
                  <br />
                  For other cases it depends on gap sequence. Generally, Average
                  TC: O(nlog n) <br />
                  Worst TC: O(nlog^2n) <br />
                  Space Complexity: O(1)
                </Span>
                <Span>
                  Shell Sort improves its time complexity by taking the
                  advantage of the fact that using Insertion Sort on a partially
                  sorted array results in less number of moves.
                </Span>
                <p>
                  Time complexity of below implementation of shellsort is
                  O(n^2).
                </p>
                <CodeEditor
                  options={{
                    title: "Shell Sort",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        function sort(arr)
{
	let n = arr.length;

		for (let gap = Math.floor(n/2); gap > 0; gap = Math.floor(gap/2))
		{
			for (let i = gap; i < n; i += 1)
			{
				let temp = arr[i];
				let j;
				for (j = i; j >= gap && arr[j - gap] > temp; j -= gap)
					arr[j] = arr[j - gap];
				arr[j] = temp;
			}
		}
		return arr;
}

let arr = [12, 34, 54, 2, 3];
arr = sort(arr);
console.log(arr);

                        `,
                        output: `[ 2, 3, 12, 34, 54 ]`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Tree Sort</b>
                </Span>
                <Span>
                  Tree sort is a sorting algorithm that is based on Binary
                  Search Tree data structure. It first creates a binary search
                  tree from the elements of the input list or array and then
                  performs an in-order traversal on the created binary search
                  tree to get the elements in sorted order.
                </Span>
                <Span>
                  For implementation please refer to non linear data structures.
                </Span>
                <Span>
                  O(n log n) Adding one item to a Binary Search tree on average
                  takes O(log n) time. Therefore, adding n items will take O(n
                  log n) time.The worst case time complexity of Tree Sort can be
                  improved by using a self-balancing binary search tree like Red
                  Black Tree, AVL Tree. Using self-balancing binary tree Tree
                  Sort will take O(n log n) time to sort the array in worst
                  case.
                </Span>
                <Span>
                  tree sort algorithms require separate memory to be allocated
                  for the tree, as opposed to in-place algorithms such as
                  quicksort or heapsort. On most common platforms, this means
                  that heap memory has to be used, which is a significant
                  performance hit when compared to quicksort and heapsort. When
                  using a splay tree as the binary search tree, the resulting
                  algorithm (called splaysort) has the additional property that
                  it is an adaptive sort, meaning that its running time is
                  faster than O(n log n) for inputs that are nearly sorted.
                </Span>
                <Span>
                  Best TC: O(nlog n)
                  <br />
                  TC: O(nlog n) <br />
                  Worst TC: O(n^2) <br />
                  Space Complexity: O(n)
                </Span>
              </>
            ),
          },
          {
            title: "Index ( Non Comparison ) Based",
            content: (
              <>
                <Span>
                  <b>Count Sort</b>
                </Span>
                <Span>
                  Counting sort is a sorting technique based on keys between a
                  specific range. It works by counting the number of objects
                  having distinct key values (kind of hashing). Then doing some
                  arithmetic to calculate the position of each object in the
                  output sequence.
                </Span>
                <p>
                  Best TC: O(n+k) k is size of count array
                  <br />
                  TC: O(n+k) <br />
                  Worst TC: O(n+k) <br />
                  Space Complexity: O(n+k)
                </p>
                <CodeEditor
                  options={{
                    title: "Count Sort",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `
                        function sort(arr)
{
	var n = arr.length;

	var output = Array(n).fill(0)

	var count = Array.from({length: 256}, (_, i) => 0);

	for (var i = 0; i < n; ++i)
		++count[arr[i].charCodeAt(0)];

	for (var i = 1; i <= 255; ++i)
		count[i] += count[i - 1];

	for (var i = n - 1; i >= 0; i--) {
		output[count[arr[i].charCodeAt(0)] - 1] = arr[i];
		--count[arr[i].charCodeAt(0)];
	}
	for (var i = 0; i < n; ++i)
		arr[i] = output[i];
	return arr;
}

	var arr = ['s','a','i','a','s','h','i','s','h'];

	arr = sort(arr);
	for (var i = 0; i < arr.length; ++i)
		console.log(arr[i]);

                        `,
                        output: `
                        a
a
h
h
i
i
s
s
s
                        `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Bin / Bucket Sort</b>
                </Span>
                <Span>
                  Bucket sort is mainly useful when input is uniformly
                  distributed over a range. Counting sort can by used here
                  because of floating numbers
                </Span>
                <p>
                  Best TC: O(n+k) k is number of buckets
                  <br />
                  TC: O(n+k) <br />
                  Worst TC: O(n^2) <br />
                  Space Complexity: O(n+k)
                </p>
                <CodeEditor
                  options={{
                    title: "Bin / Bucket Sort",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `function bucketSort(arr,n)
                        {
                          if (n <= 0)
                              return;
                            let buckets = new Array(n);
                        
                            for (let i = 0; i < n; i++)
                            {
                              buckets[i] = [];
                            }
                            for (let i = 0; i < n; i++) {
                              let idx = arr[i] * n;
                              buckets[Math.floor(idx)].push(arr[i]);
                            }
                            for (let i = 0; i < n; i++) {
                              buckets[i].sort(function(a,b){return a-b;});
                            }
                            let index = 0;
                            for (let i = 0; i < n; i++) {
                              for (let j = 0; j < buckets[i].length; j++) {
                                arr[index++] = buckets[i][j];
                              }
                            }
                        }
                        
                        let arr = [0.897, 0.565,
                            0.656, 0.1234,
                            0.665, 0.3434];
                        let n = arr.length;
                        bucketSort(arr, n);
                        
                        for (let el of arr.values()) {
                          console.log(el + " ");
                        }
                        `,
                        output: `
                        .1234 
0.3434 
0.565 
0.656 
0.665 
0.897 
                        `,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Radix Sort</b>
                </Span>
                <Span>
                  We can't use counting sort when elements are range of 1 to n*n
                  because counting sort will take O(n^2) which is worse than
                  comparison-based sorting algorithms.
                </Span>
                <Span>
                  After every iteration, we make of corresponding number at
                  digit's place using % operator.
                </Span>
                <Span>
                  Radix Sort takes O(d*(n+b)) time where b is the base for
                  representing numbers, for example, for the decimal system, b
                  is 10. What is the value of d? If k is the maximum possible
                  value, then d would be O(logb(k)). So overall time complexity
                  is O((n+b) * logb(k))
                </Span>
                <p>
                  Best TC: O(d*(n+b))
                  <br />
                  TC: O(d*(n+b)) <br />
                  Worst TC: O(d*(n+b)) <br />
                  Space Complexity: O(n+2^d).
                </p>
                <CodeEditor
                  options={{
                    title: "Radix Sort",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `function getMax(arr,n)
                        {
                          let mx = arr[0];
                            for (let i = 1; i < n; i++)
                              if (arr[i] > mx)
                                mx = arr[i];
                            return mx;
                        }
                        
                        // can be solved using linked list as well
                        
                        function countSort(arr,n,exp)
                        {
                          let output = new Array(n); 
                            let i;
                            let count = new Array(10);
                            for(let i=0;i<10;i++)
                              count[i]=0;
                        
                            for (i = 0; i < n; i++)
                              count[Math.floor(arr[i] / exp) % 10]++;
                        
                            for (i = 1; i < 10; i++)
                              count[i] += count[i - 1];
                        
                            for (i = n - 1; i >= 0; i--) {
                              output[count[Math.floor(arr[i] / exp) % 10] - 1] = arr[i];
                              count[Math.floor(arr[i] / exp) % 10]--;
                            }
                        
                            for (i = 0; i < n; i++)
                              arr[i] = output[i];
                        }
                        
                        function radixsort(arr,n)
                        {
                            let m = getMax(arr, n);
                            for (let exp = 1; Math.floor(m / exp) > 0; exp *= 10)
                              countSort(arr, n, exp);
                        }
                        
                        
                        let arr=[170, 45, 75, 90, 802, 24, 2, 66];
                        let n = arr.length;
                        radixsort(arr, n);
                        console.log(arr)
                        `,
                        output: `
                        [
                          2, 24,  45,  66,
                         75, 90, 170, 802
                       ]
                        `,
                      },
                    },
                  }}
                />
              </>
            ),
          },
        ],
        content: (
          <>
            <Span>
              This algorithm is basically used to reorder elements in the
              respective data structure based on a comparison operator.
            </Span>
            <Span>
              <b>Criteria of analysis:</b>
            </Span>
            <p>
              1. No. of comparisons <br />
              2. No. of swaps <br />
              3. Adaptive ( we can detect already sorted lists using this
              feature ) <br />
              4. Stable ( order of duplicate elements should be maintained ){" "}
              <br />
              5. Extra Memory <br />
              <br />
            </p>
            <Span>Types of sorting:</Span>
            <Span>(a) Comparison based:</Span>
            <Span>
              Elements are compared with each other to get sorted array.
            </Span>
            <Span>Examples:</Span>
            <Span>
              Avg. Complexity (O(n*n)) : Bubble, Insertion and Selection. <br />
              Avg. Complexity (O(n*(log n))) : Heap, Merge, Quick, and Tree{" "}
              <br />
              Avg. Complexity (O(n^1.5)) : Shell{" "}
            </Span>
            <Span>(b) Index based:</Span>
            <Span>
              This method makes use of hashing and sort elements by creating
              index recursively.
            </Span>
            <Span>Examples:</Span>
            <p>Avg. Complexity (O(n)): Count, Bucket( Bin ) and Radix</p>
          </>
        ),
      },
      search: {
        title: "Searching",
        content: (
          <>
            <Span>
              It's used to check for an element or retrieve an element from any
              data structure where it is stored.
            </Span>
            <Span>
              <b>Types:</b>
            </Span>
            <Span>
              <b>1. Sequential Search:</b>
            </Span>
            <Span>
              In this type, data structure is traversed sequentially and every
              element is checked.
            </Span>
            <Span>
              <b>Example:</b>
            </Span>
            <Span>
              <b>Linear search:</b>
            </Span>
            <Span>
              It's a basic algorithm to find a element in the data structure.
            </Span>
            <Span>
              <b>Best case TC: </b> O(1) <br />
              <b>Worst case TC: </b> O(n) <br />
              <b>Space Complexity: </b> O(1)
            </Span>
            <p>
              <b>Implemtation (basic + optimized):</b>
            </p>
            <CodeEditor
              options={{
                title: "Linear Search",
                output: null,
                codes: {
                  Javascript: {
                    code: `function basicSearch(arr,ele){
                      let flag = false;
                      for(let index in arr){
                        if(arr[index] === ele)
                        {
                          flag = true
                          return Number(index) + 1 +"th"
                        }
                      }
                      if(!flag) return "Not found"
                    }
                    
                    function optimizedSearch(arr, ele)
                    {
                      let left = 0;
                      let length = arr.length;
                      let right = length - 1;
                      let position = -1;
                      for(left = 0; left <= right;)
                      {
                        if (arr[left] == ele)
                        {
                          position = left;
                          console.log(
                            "Element found in array at " +
                            (position+1) + "th position with " +
                              (left + 1) + " attempt");
                          break;
                        }
                    
                        if (arr[right] == ele)
                        {
                          position = right;
                          console.log(
                            "Element found in array at " +
                            (position + 1) + "th position with " +
                            (length - right) + " attempt");
                          break;
                        }
                        left++;
                        right--;
                      }
                    
                      if (position == -1)
                        console.log("Not found in Array with " +
                              left + " Attempt");
                    }
                      
                    let arr = [ 1, 2, 3, 4, 5 ];
                    let search_element = 5;
                    console.log(basicSearch(arr, search_element))
                    optimizedSearch(arr, search_element);
                    `,
                    output: `
                    5th
Element found in array at 5th position with 1 attempt
                    `,
                  },
                },
              }}
            />
            <Span>
              <b>2. Interval Search:</b>
            </Span>
            <Span>
              These algorithms are specifically designed for sorted data
              structures. These types of algorithms are much more efficient than
              linear search as they repeatedly target the center of the search
              structure and divide the search space in half.
            </Span>
            <Span>
              <b>Binary search:</b>
            </Span>
            <Span>
              The original Binary search algorithm can not be applied to linked
              list because linked list by nature is dynamic and it is not known
              where the middle element is actually allocated. So, some of the
              efforts are required to apply binary search over a linked list to
              obtain the advantages. The main problem that binary search takes
              O(n) time in Linked List due to fact that in linked list we are
              not able to do indexing which led traversing of each element in
              Linked list take O(n) time.
            </Span>
            <Span>Complexities</Span>
            <Span>
              Best TC: O(1) // central index would directly match the desired
              value. <br />
              Avg. TC: O(log n) <br />
              Worst TC: O(log n) <br />
              Space Complexity (Iteration): O(1) <br />
              Space Complexity (Recursion): O(log n)
            </Span>
            <Span>
              Here we are using int mid = low + (high - low)/2; instead of int
              mid = (low + high)/2;
            </Span>
            <Span>
              If we calculate the middle index using (low + high)/2 means our
              code is not 100% correct, it contains bugs. That is, it fails for
              larger values of int variables low and high. Specifically, it
              fails if the sum of low and high is greater than the maximum
              positive int value(2^31 - 1 ). The sum overflows to a negative
              value and the value stays negative when divided by 2. In java, it
              throws ArrayIndexOutOfBoundException. int mid = low + (high -
              low)/2; So it's better to use it like this. This bug applies
              equally to merge sort and other divide and conquer algorithms.
            </Span>
            <p>Implementation</p>
            <CodeEditor
              options={{
                title: "Binary Search",
                output: null,
                codes: {
                  Javascript: {
                    code: `function recBinarySearch(arr, l, r, x){
                      if (r >= l) {
                          let mid = l + Math.floor((r - l) / 2);
                          if (arr[mid] == x)
                              return mid;
                          if (arr[mid] > x)
                              return binarySearch(arr, l, mid - 1, x);
                          return binarySearch(arr, mid + 1, r, x);
                      }
                      return -1;
                  }
                  
                  // every number can be represented as the sum of powers of 2.
                  // Hence it can solved using bitwise approach
                  
                  function binarySearch(arr, x)
                  {
                    let l = 0;
                    let r = arr.length - 1;
                    let mid;
                    while (r >= l) {
                      mid = l + Math.floor((r - l) / 2);
                      if (arr[mid] == x)
                        return mid;
                      if (arr[mid] > x)
                        r = mid - 1;
                      else
                        l = mid + 1;
                    }
                    return -1;
                  }
                  
                  const	arr =new Array(2, 3, 4, 10, 40);
                  const	x = 10;
                  const	n = arr.length;
                  const	result = binarySearch(arr, x);
                  console.log(result)
                  `,
                    output: `3`,
                  },
                },
              }}
            />
            <Span>
              <b>Two pointers technique:</b>
            </Span>
            <Span>
              Two pointers is really an easy and effective technique that is
              typically used for searching pairs in a sorted array.
            </Span>

            <Span>
              <b>Time complexity: </b> O(n)
            </Span>
            <p>
              Pairs can also be searched in a sorted array in O(n) with the help
              of map ( / dictionary / object )
            </p>
            <CodeEditor
              options={{
                title: "Two pointers technique",
                output: null,
                codes: {
                  Javascript: {
                    code: `
                    function isPairSum(A, N, X)
{
	var i = 0;
	var j = N - 1;

	while (i < j) {

		if (A[i] + A[j] == X)
    {
      console.log({
        i,
        j,
        l: A[i],r:A[j] , X
      })
			return true;
    }
		else if (A[i] + A[j] < X)
			i++;
		else
			j--;
	}
	return false;
}

var arr = [ 3, 5, 9, 2, 8, 10, 11 ];
var val = 17;
var arrSize =7;

console.log(isPairSum(arr, arrSize, val));
                    `,
                    output: `{ i: 2, j: 4, l: 9, r: 8, X: 17 }
                    true`,
                  },
                },
              }}
            />
            <Span>
              <b>Window slicing method:</b>
            </Span>
            <Span>
              Window Sliding Technique is a computational technique which aims
              to reduce the use of nested loop and replace it with a single
              loop, thereby reducing the time complexity.
            </Span>
            <Span>
              Consider a long chain connected together. Suppose you want to
              apply oil in the complete chain with your hands, without pouring
              the oil from above.
              <br />
              One way to do so is to:
              <br />
              pick some oil, <br />
              apply onto a section of chain, <br />
              then again pick some oil
              <br />
              then apply it to the next section where oil is not applied yet
              <br />
              and so on till the complete chain is oiled.
              <br />
              Another way to do so, is to use a cloth, dip it in oil, and then
              hold onto one end of the chain with this cloth. Then instead of
              re-dipping it again and again, just slide the cloth with hand onto
              the next section, and next, and so on till the other end.
              <br />
              The second way is known as the Sliding window technique and the
              portion which is slided from one end to end, is known as Sliding
              Window.
              <br />
              The use of Sliding Window technique can be done in a very specific
              scenario, where the size of window for computation is fixed
              throughout the complete nested loop. Only then the time complexity
              can be reduced.
            </Span>
            <Span>
              Example: Given an array of integers of size ‘n’, Our aim is to
              calculate the maximum sum of ‘k’ consecutive elements in the
              array.
            </Span>
            <Span>
              Input : arr[] = {"{(100, 200, 300, 400)}"}, k = 2 <br />
              Output : 700
            </Span>
            <p>
              <b>Naive Method</b>
            </p>
            <CodeEditor
              options={{
                title: "Window slicing naive method",
                output: null,
                codes: {
                  Javascript: {
                    code: `
                    function maxSum( arr, n, k){
                      let max_sum = Number.MIN_VALUE;
                      for (let i = 0; i < n - k + 1; i++) {
                          let current_sum = 0;
                          for (let j = 0; j < k; j++)
                              current_sum = current_sum + arr[i + j];
                          max_sum = Math.max(current_sum, max_sum);
                      }
                      return max_sum;
                    }              
                                  `,
                  },
                },
              }}
            />
            <p>
              <b>Slicing Window Technique</b>
            </p>
            <CodeEditor
              options={{
                title: "Window slicing naive method",
                output: null,
                codes: {
                  Javascript: {
                    code: `
                    function maxSum(arr, n, k) {
                     let max = 0;
                     let sum = 0;
                     for (let i = 0; i < k; i++) {
                         sum += arr[i];
                         max = sum;
                     }
                     for (let i = k; i < n; i++) {
                         sum += arr[i] - arr[i - k];
                         if (sum > max) {
                             max = sum;
                         }
                     }
                     return max;
                 }
                   `,
                  },
                },
              }}
            />
            <Span>
              <b>BFS and DFS</b>
            </Span>
            <Span>
              For their implementations at trees and graphs , please refer to
              non-linear data structures.
            </Span>
            <Span>
              <b>Practice Questions:</b>
            </Span>
            <Span>
              https://medium.com/techie-delight/top-20-breadth-first-search-bfs-practice-problems-ac2812283ab1
            </Span>
          </>
        ),
      },
      Complexity: {
        title: "Algorithmic Analysis",
        content: (
          <>
            <Img src={BigOChart} />
            <Span>
              O(1) === constant <br />
              O(log n) === usually searching algorithms <br />
              O(n) === linear , single loop <br />
              O(n^2) === nested loops <br />
              O(2^n) === exponential === recursion <br />
              O(n!) === loop for every element.
            </Span>
            <Span>
              <b>Analysis of algorithms:</b>
            </Span>
            <Span>
              Similar to other factors such as user friendliness, modularity,
              security, maintainability, performance of algorithms is also
              important.The answer to this is simple, we can have all the above
              things only if we have performance. So performance is like
              currency through which we can buy all the above things. Another
              reason for studying performance is – speed is fun! To summarize,
              performance == scale. Imagine a text editor that can load 1000
              pages, but can spell check 1 page per minute OR an image editor
              that takes 1 hour to rotate your image 90 degrees left OR … you
              get it. If a software feature can not cope with the scale of tasks
              users need to perform – it is as good as dead.
            </Span>
            <Span>
              <b>Asymptotic Analysis:</b>
            </Span>
            <Span>
              In Asymptotic Analysis, we evaluate the performance of an
              algorithm in terms of input size (we don’t measure the actual
              running time). We calculate, how the time (or space) taken by an
              algorithm increases with the input size.
            </Span>
            <Span>
              Linear Search on a fast computer A and Binary Search on a slow
              computer B and we pick the constant values for the two computers
              so that it tells us exactly how long it takes for the given
              machine to perform the search in seconds. Let’s say the constant
              for A is 0.2 and the constant for B is 1000 which means that A is
              5000 times more powerful than B. For small values of input array
              size n, the fast computer may take less time. But, after a certain
              value of input array size, the Binary Search will definitely start
              taking less time compared to the Linear Search even though the
              Binary Search is being run on a slow machine. The reason is the
              order of growth of Binary Search with respect to input size is
              logarithmic while the order of growth of Linear Search is linear.
              So the machine dependent constants can always be ignored after a
              certain value of input size. Here are some running times for this
              example: Linear Search running time in seconds on A: 0.2 * n
              Binary Search running time in seconds on B: 1000*log(n)
            </Span>
            <Span>
              <b>Complexity</b>
            </Span>
            <Span>
              The complexity of an algorithm is a function describing the
              efficiency of the algorithm in terms of the amount of data the
              algorithm must process.
            </Span>
            <Span>
              <b>Time Complexity</b>
            </Span>
            <Span>
              Time Complexity of algorithm/code is not equal to the actual time
              required to execute a particular code but the number of times a
              statement executes. We can prove this by using time command.
            </Span>
            <Span>
              O(n^2): You go and ask the first person of the class, if he has
              the pen. Also, you ask this person about other 99 people in the
              classroom if they have that pen and so on, This is what we call
              O(n^2). O(n): Going and asking each student individually is O(N).
              O(log n): Now I divide the class into two groups, then ask: “Is it
              on the left side, or the right side of the classroom?” Then I take
              that group and divide it into two and ask again, and so on. Repeat
              the process till you are left with one student who has your pen.
              This is what you mean by O(log n).
            </Span>
            <Span>
              <b>Space Complexity</b>
            </Span>
            <Span>
              Space Complexity of an algorithm is the total space taken by the
              algorithm with respect to the input size. Space complexity
              includes both Auxiliary space and space used by input. If we want
              to compare standard sorting algorithms on the basis of space, then
              Auxiliary Space would be a better criterion than Space Complexity.
              Merge Sort uses O(n) auxiliary space, Insertion sort, and Heap
              Sort use O(1) auxiliary space. The space complexity of all these
              sorting algorithms is O(n) though.
            </Span>
            <Span>
              <b>Worst Case vs Average Case vs Best Case</b>
            </Span>
            <Span>
              <b>Worst Case ( Upper bound ) Analysis</b>
            </Span>
            <Span>
              This case computes maximum number of operations to be executed.
            </Span>
            <Span>
              <b>Average Case Analysis</b>
            </Span>
            <Span>
              In average case analysis, we take all possible inputs and
              calculate computing time for all of the inputs. Sum all the
              calculated values and divide the sum by the total number of
              inputs. We sum all the cases and divide the sum by (n+1)
            </Span>
            <Span>
              <b>Best Case ( Lower bound ) Analysis</b>
            </Span>
            <Span>
              This case computes minimum number of operations to be executed.
            </Span>
            <Span>
              <b>Space Time Trade Off</b>
            </Span>
            <Span>
              A tradeoff is a situation where one thing increases and another
              thing decreases. More time-efficient algorithms will be less space
              efficient and vice versa.
            </Span>
            <Span>
              <b>Asynmptotic Notations</b>
            </Span>
            <Span>
              These are the mathematical tools used to compute efficiency of the
              algorithm which doesn't require algorithms to be implemented.
            </Span>
            <Span>
              n refers to the size of the input, in your case it's the number of
              items in your list. O(n) means that your algorithm will take on
              the order of n operations to insert an item
            </Span>
            <Span>
              <b>Types:</b>
            </Span>
            <Span>1. Theta notation:</Span>
            <Span>
              The theta notation bounds a function from above and below, so it
              defines exact asymptotic behavior. A simple way to get the Theta
              notation of an expression is to drop low-order terms and ignore
              leading constants. For example, consider the following expression.
              3n3 + 6n2 + 6000 = Θ{"(n3)"} Dropping lower order terms is always
              fine because there will always be a number(n) after which Θ(n3)
              has higher values than Θ(n2) irrespective of the constants
              involved. For a given function g(n), we denote Θ(g(n)) is
              following set of functions. Θ{"(g(n))"} = {"[f(n):"} there exist
              positive constants c1, c2 and n0 such that 0 less than or equal to
              c1*g(n) less than or equal to f(n) less than or equal to c2*g(n)
              for all n greater than or equal to n0{"]"}
            </Span>
            <Span>2. Big O notation:</Span>
            <Span>
              The Big O notation defines an upper bound of an algorithm, it
              bounds a function only from above. The Big O notation is useful
              when we only have an upper bound on the time complexity of an
              algorithm. Many times we easily find an upper bound by simply
              looking at the algorithm.
            </Span>
            <Span>
              O(g(n)) = [ f(n): there exist positive constants c and n0 such
              that 0 less than or equal to f(n) less than or equal to c*g(n) for
              all n greater than or equal to n0]
            </Span>
            <Span>3. Omega notation:</Span>
            <Span>
              Just as Big O notation provides an asymptotic upper bound on a
              function, Ω notation provides an asymptotic lower bound.
            </Span>
            <Span>
              Ω (g(n)) = [f(n): there exist positive constants c and n0 such
              that 0 less than or equal to c*g(n) less than or equal to f(n) for
              all n greater than or equal to n0].
            </Span>
          </>
        ),
      },
      tradeOf: {
        title: "Master's Theorem",
        content: (
          <>
            <Span>
              In the analysis of algorithms, the master theorem for
              divide-and-conquer recurrences provides an asymptotic analysis
              (using Big O notation) for recurrence relations of types that
              occur in the analysis of many divide and conquer algorithms.
            </Span>

            <Span>
              for(i=0; i&gt;n; i++) <br />
              O(n) <br />
            </Span>
            <Span>
              for(i=0; i&gt;n; i+=2) <br />
              O(n/2) === O(n) <br />
            </Span>

            <Span>
              for(i=0; i&gt;n; i/=2) <br />
              O(log2 n) <br />
            </Span>
            <Span>
              for(i=0; i&gt;n; i*/2) <br />
              O(log2 n) <br />
            </Span>

            <Span>
              for(i=0; i&gt;n; i*=3) <br />
              O(log3 n) <br />
            </Span>

            <Span>
              <b>Decreasing functions</b>
            </Span>

            <Span>
              T(n) = aT(n-b) + f(n) , f(n) = O(n^k) , f(n) &lt;= c*g(n) ,
              k&gt;=0 , a&gt;0 , b&gt;0
            </Span>
            <Span>if a = 1,</Span>
            <Span>O(n^(k+1)) O(n*f(n))</Span>
            <Span>if a &gt; 1,</Span>
            <Span>O(a^(n/b) * n^k)</Span>
            <Span>if a &lt; 1,</Span>
            <Span>O(f(n))</Span>
            <Span>Examples:</Span>
            <Span>
              1. T(n) = T(n-1) + constant <br />
              O(n)
            </Span>
            <Span>
              2. T(n) = T(n-1) + n <br /> O(n^2)
            </Span>
            <Span>
              3. T(n) = T(n-1) + log n <br /> O(nlog n)
            </Span>
            <Span>
              <pre>
                void Test(int n){"{"} // T(n) <br />
                if(n&gt;gt) // 1 <br />
                for(int i=0;i&lt;n;i*=2) // log2 n <br />
                printf("%d",i); // 1 <br />
                Test(n-1); // T(n-1) <br />
                {"}"}
              </pre>
            </Span>
            <Span>
              4. T(n) = 2T(n-1) + 1 <br /> O(2^n)
            </Span>
            <Span>
              5. T(n) = 3T(n-1) + 1 <br /> O(3^n)
            </Span>
            <Span>
              6. T(n) = 2T(n-1) + n <br /> O(n2^n)
            </Span>

            <Span>
              <b>Dividing functions</b>
            </Span>

            <Span>
              T(n) = aT(n/b) + f(n) , f(n) = O(n^k * log^p n) , f(n) &lt;=
              c*g(n) , a&gt;=1 , b&gt;1
            </Span>
            <Span>
              Case 1: logb a &gt; k <br />
              O(n^logb a)
            </Span>

            <Span>Case 2: logb a = k</Span>
            <Span>a. p &gt; -1</Span>
            <Span>O(n^k * logp+1 n)</Span>
            <Span>b. p = -1</Span>
            <Span>O(n^k * log log n)</Span>
            <Span>a. p &lt; -1</Span>
            <Span>O(n^k)</Span>
            <Span>Case 3: if logb a &lt; k</Span>
            <Span>a. p &gt;= 0</Span>
            <Span>O(n^k * logp n)</Span>
            <Span>b. p &lt; 0</Span>
            <Span>O(n^k)</Span>

            <Span>There are following three cases:</Span>
            <Span>
              1. If f(n) = O(n^k) where k &lt; logb a then T(n) = Θ(n^logb a)
            </Span>
            <Span>
              2. If f(n) = Θ(n^k) where k = logb a then T(n) = Θ(n^k log n)
            </Span>
            <Span>
              3.If f(n) = Ω(n^k) where k &gt; logb a then T(n) = Θ(f(n))
            </Span>

            <Span>
              Binary search: T(n) = T(n/2) + Θ(1). log2 1 == 0 O(n^k * log n)
            </Span>
            <Span>T(n) = 2T(n/2) + Θ(n). log2 2 === 1, n^1 * log2 n</Span>
          </>
        ),
      },
      recursive: {
        title: "Recursion",
        content: (
          <>
            <Span>
              It is a process in which a fn ( recursive fn ) calls itself.
            </Span>
            <Span>
              Examples: TOH <br /> DFS Tree Traversals <br /> DFS of graph ,
            </Span>
            <Span>
              When the program executes,the main memory gets divided into three
              parts. One part for code section, the second one is heap memory
              and another one is stack memory.
            </Span>
            <Span>
              Heap memory is a Dynamic memory(its size changes as program run)
              used to store arrays, global variables(with global
              scope/accessible from any function) and any created class
              instances(objects) at runtime in Java which are referred by the
              reference variables from Stack memory.
            </Span>
            <Span>Types:</Span>
            <Span>
              <b>Direct Recursion:</b>
            </Span>
            <Span>
              <b>Types:</b>
            </Span>
            <Span>
              <b>(a) Tail Recursion:</b>
            </Span>
            <Span>
              If recursive call is the last statement in the fn then it's called
              recursive fn.
            </Span>
            <Img src="https://media.geeksforgeeks.org/wp-content/uploads/20190621015455/tail1.jpg" />
            <p>
              It occurs when a function calls itself. This results in a one-step
              recursive call: the function makes a recursive call inside its own
              function body.
            </p>
            <CodeEditor
              options={{
                title: "Tail Recursion",
                output: null,
                codes: {
                  Javascript: {
                    code: `
                    function fun(n)
                    {
                      if (n > 0)
                      {
                        console.log(n + " ");
                        fun(n - 1);
                      }
                    }
                      var x = 3;
                      fun(x);

                // Time Complexity : O(n)
                // Space Complexity: O(n) // n activation records are
                // created in main menory

                // Using simple for loop, SC will be O(1)
                // as we're making use of only one activation record 

                // Driver code
                  var x = 3;
                    fun(x);
                    `,
                    output: `3 2 1`,
                  },
                },
              }}
            />
            <Span>
              <b>(b) Head Recursion:</b>
            </Span>
            <Img src="https://media.geeksforgeeks.org/wp-content/uploads/20190621015721/head3.jpg" />
            <p>
              If recursive call is the first statement in the fn then it's
              called recursive fn.
            </p>
            <CodeEditor
              options={{
                title: "Head Recursion",
                output: null,
                codes: {
                  Javascript: {
                    code: `
                    function fun(n)
                    {
                      if (n > 0)
                      {
                        fun(n - 1);
                        console.log(n + " ");
                      }
                    }
                    // Time Complexity : O(n)
                    // Space Complexity: O(n) 
                      var x = 3;
                      fun(x);
                    `,
                    output: `1 2 3`,
                  },
                },
              }}
            />
            <Span>
              Note: Time & Space Complexity is given for this specific example.
              It may vary for another example. Note: Head recursion can’t easily
              convert into loop as Tail Recursion but it can. Let’s convert the
              above code into the loop.
            </Span>
            <Span>
              <b>(c) Tree Recursion:</b>
            </Span>
            <Span>
              If a recursive function calling itself for one time then it’s
              known as Linear Recursion. Otherwise if a recursive function
              calling itself for more than one time then it’s known as Tree
              Recursion.
            </Span>
            <Img src="https://media.geeksforgeeks.org/wp-content/uploads/20190621015814/tree4.jpg" />
            <p>tracing tree of recursive function.</p>
            <CodeEditor
              options={{
                title: "Tree Recursion",
                output: null,
                codes: {
                  Javascript: {
                    code: `
                    function fun(n)
                    {
                        if (n > 0) {
                            console.log(" "+ n);
                    
                            fun(n - 1);
                     
                            // Calling twice
                            fun(n - 1);
                        }
                    }
                        fun(3);                     
                    `,
                    output: `3 2 1 1 2 1 1`,
                  },
                },
              }}
            />
            <Span>
              <b>(d) Nested Recursion (recursion inside recursion):</b>
            </Span>
            <p>
              a recursive function will pass the parameter as a recursive call
            </p>
            <CodeEditor
              options={{
                title: "Nested Recursion (recursion inside recursion)",
                output: null,
                codes: {
                  Javascript: {
                    code: `
                    function fun( n)
                    {
                        if (n > 100)
                            return n - 10;
                        return fun(fun(n + 11));
                    }
                    
                    console.log(fun(95));                     
                    `,
                    output: `91`,
                  },
                },
              }}
            />

            <Span>
              <b>Indirect Recursion:</b>
            </Span>
            <Span>
              In this recursion, there may be more than one functions and they
              are calling one another in a circular manner.
            </Span>
            <CodeEditor
              options={{
                title: "Indirect Recursion",
                output: null,
                codes: {
                  Javascript: {
                    code: `
                    function funA(n)
                    {
                        if (n > 0) {
                            console.log(n.toFixed(0));
                            funB(n - 1);
                        }
                    }
                     
                    function funB(n)
                    {
                        if (n > 1) {
                            console.log(n.toFixed(0));
                            funA(n / 2);
                        }
                    }
                     
                    funA(20);                     
                    `,
                    output: `20 19 9 8 4 3 1`,
                  },
                },
              }}
            />
            <Span>
              <b>Tower of hanoi problem</b>
            </Span>
            <p>
              Tower of Hanoi is a mathematical puzzle where we have three rods
              and n disks. The objective of the puzzle is to move the entire
              stack to another rod, obeying the following simple rules:
              <br />
              1.Only one disk can be moved at a time.
              <br />
              2.Each move consists of taking the upper disk from one of the
              stacks and placing it on top of another stack i.e. a disk can only
              be moved if it is the uppermost disk on a stack.
              <br />
              3.No disk may be placed on top of a smaller disk.
            </p>

            <CodeEditor
              options={{
                title: "Tower of hanoi problem",
                output: ``,
                codes: {
                  Javascript: {
                    code: `function towerOfHanoi(n, from_rod,  to_rod,  aux_rod)
                  {
                    const A = from_rod
                    const B = to_rod
                    const C = aux_rod
                  
                    if (n == 1){
                    console.log("Move disk 1 from rod " + from_rod + " to rod " + to_rod);
                              return;
                    }
                          towerOfHanoi(n - 1, A, C, B);
                          // shift left
                          console.log("Move disk " + n + " from rod " + from_rod +
                          " to rod " + to_rod);
                          towerOfHanoi(n - 1, C, B, A);
                  }
                   
                  var n = 4; 
                  towerOfHanoi(n, 'A', 'C', 'B');
                  
                  // 3 -> 1
                  // T(n) = 2T(n-1) + 1 // 2^n`,
                    output: `Move disk 1 from rod A to rod B
                  Move disk 2 from rod A to rod C
                  Move disk 1 from rod B to rod C
                  Move disk 3 from rod A to rod B
                  Move disk 1 from rod C to rod A
                  Move disk 2 from rod C to rod B
                  Move disk 1 from rod A to rod B
                  Move disk 4 from rod A to rod C
                  Move disk 1 from rod B to rod C
                  Move disk 2 from rod B to rod A
                  Move disk 1 from rod C to rod A
                  Move disk 3 from rod B to rod C
                  Move disk 1 from rod A to rod B
                  Move disk 2 from rod A to rod C
                  Move disk 1 from rod B to rod C`,
                  },
                },
              }}
            />

            <Span>Recursion vs backtracking:</Span>
            <Span>
              In recursion, the function calls itself until it reaches a base
              case. In backtracking, we use recursion to explore all the
              possibilities until we get the best result for the problem.
            </Span>
            <Span>There are mainly three ways for solving recurrences.</Span>
            <Span>
              1) Substitution Method: We make a guess for the solution and then
              we use mathematical induction to prove the guess is correct or
              incorrect.
            </Span>
            <Span>
              For example consider the recurrence T(n) = 2T(n/2) + n We guess
              the solution as T(n) = O(nlog n). Now we use induction to prove
              our guess. We need to prove that T(n) &lt;= cnlog n. We can assume
              that it is true for values smaller than n.
            </Span>
            <pre>
              T(n) = 2T(n/2) + n <br /> &lt;= 2cn/2Log(n/2) + n <br /> = cnlog n
              - cnLog2 + n <br /> = cnlog n - cn + n <br /> &lt;= cnlog n <br />
            </pre>
            <br />
            <Span>
              2) Recurrence Tree Method: In this method, we draw a recurrence
              tree and calculate the time taken by every level of tree. Finally,
              we sum the work done at all levels. To draw the recurrence tree,
              we start from the given recurrence and keep drawing till we find a
              pattern among levels. The pattern is typically a arithmetic or
              geometric series.
            </Span>
            <Span>3. Master's method</Span>
            <Span>I created separate section for this.</Span>
          </>
        ),
      },
      "divide&Conquer": {
        title: "Divide & Conquer",
        content: (
          <>
            <Span>
              This technique is used to solve the given problem by dividing it
              into smaller sub problems , solving them recursively and combining
              the solutions.
            </Span>
            <Span>Examples: Merge sort, quick sort and toh</Span>

            <Span>
              <b>DAC ( Divide And Conquer ) Vs DP (Dynamic Programming)</b>
            </Span>
            <Span>
              Divide and Conquer should be used when the same subproblems are
              not evaluated many times. Otherwise Dynamic Programming or
              Memoization should be used. For example, Quicksort is a Divide and
              Conquer algorithm, we never evaluate the same subproblems again.
              On the other hand, for calculating the nth Fibonacci number,
              Dynamic Programming should be preferred
            </Span>
            <p>Function to find the maximum no. in a given array.</p>
            {/* Function to find the maximum no. */}
            {/* https://www.geeksforgeeks.org/divide-and-conquer-algorithm-introduction/ */}
            <CodeEditor
              options={{
                output: ``,
                title: "Divide & Conquer",
                codes: {
                  Javascript: {
                    code: `function DAC_Max(a,index,l)
                    {
                        let max;
                        if (index >= l - 2)
                        {
                          // only two elements are left
                            if (a[index] > a[index + 1])
                                return a[index];
                            else
                                return a[index + 1];
                        }
                        max = DAC_Max(a, index + 1, l);
                        // we need max here for comparison
                        if (a[index] > max)
                            return a[index];
                        else
                            return max;
                    }
                    
                    function DAC_Min(a,index,l)
                    {
                        let min;
                        if (index >= l - 2)
                        {
                            if (a[index] < a[index + 1])
                                return a[index];
                            else
                                return a[index + 1];
                        }
                        min = DAC_Min(a, index + 1, l);
                        if (a[index] < min)
                            return a[index];
                        else
                            return min;
                    }
                    
                    let min, max;
                    let a=[70, 250, 50, 80, 140, 12, 14];
                    max = DAC_Max(a, 0, 7);
                    min = DAC_Min(a, 0, 7);
                    console.log("The minimum number in " +
                                      "a given array is : ", min);
                    console.log("The maximum number in " +
                                      "a given array is : "+ max);
                     `,
                    output: `
                    The minimum number in a given array is :  12
The maximum number in a given array is : 250
                    `,
                  },
                },
              }}
            />
          </>
        ),
      },
      greedy: {
        title: "Greedy",
        content: (
          <>
            <Span>
              An algorithmic paradigm that follows the problem solving approach
              of making the locally optimal choice at each stage with the hope
              of finding a global optimum.
            </Span>
            <Span>
              <b>Pros: </b>
              simple,easy to implement, and runs fast
            </Span>
            <Span>
              <b>Cons: </b>
              very often they don't provide a globally optimim solution
            </Span>
            <Span>
              consider the below min heap , and let's select the largest number
              at each level starting from the root. <br /> 3 4 7 6 20 9 11
            </Span>
            <Span>
              total sum will be 21 ( 3 + 7 + 11 )
              <br />
              but the maximum possible sum is 27 ( 3 + 4 + 20 )
            </Span>

            <Span>
              <b>Naive vs Greedy vs Brute Force ( Enumeration ) </b>
            </Span>
            <Span>
              Naive algorithm is simply used to find a basic solution to a
              problem. Greedy algorithm at each step makes use of local optimum
              in order to find the global optimum but it doesn't guarantee that
              the last solution is an optimal solution. ( Example: Fractional
              knapsack ). Brute force provides the optimal solution
              everytime.The principle is simple, it is necessary to enumerate
              all the possible solutions of the problem and to choose the best
              solution.
            </Span>
            <Span>
              <b>Properties:</b>
            </Span>
            <Span>
              1 .<b>Greedy Choice Property: </b>
              According to this property, a global optimum can be arrived by
              selecting local optimum.
            </Span>

            <Span>
              2.
              <b>Optimal Substructure: </b> Acc. to this , an optimal solution
              to the problem contains optimal solution to subproblems.
            </Span>
            <Span>
              <b>Feasible Solution:</b>
            </Span>
            <Span>It satisfies given condition</Span>
            <Span>
              <b>Optimal Solution:</b>
            </Span>
            <Span>
              <b>Optimization Problems</b>
            </Span>
            <Span>They require either min or max results</Span>
            <Span>It's feasible and gives best result</Span>
            <Span>Various Applications are specified at the top section.</Span>
          </>
        ),
        types: [
          {
            title: "Activity Selection",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Find out maximum number activities that a person can perform
                  if starting and finishing time of each activity is provided.
                </Span>
                <Span>
                  <b>Greedy approach:</b>
                </Span>
                1. Sort the activities according to their finishing time.
                <br />
                2. Select the first activity from the sorted array and print it
                <br />
                3. For the remaining activities , if the start time of this
                activity is greater then or equal to the finish time of
                previously selected activity then select this activity and print
                it .<p>Implementation</p>
                <CodeEditor
                  options={{
                    title: "Activity Selection",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `function printMaxActivities(s,f){
                          let i,j;
                          console.log("Following activities are selected")
                          i=0;
                          console.log(i)
                          for (j = 1; j < f.length; j++)
                          {
                          if (s[j] >= f[i])
                          {
                          console.log(j);
                          i = j;
                          }}}
                          const s = [1,3,0,5,8,5]
                          const f = [9,4,1,7,9,9]
                          f.sort()
                          printMaxActivities(s,f)
                          `,
                        output: `Following activities are selected
                          0
                          1
                          3`,
                      },
                      Java: {
                        code: `import java.io.*;
                        import java.util.Arrays;
                        class Main{
                        static PrintStream ps = System.out;
                        static void printMaxActivities(int s[], int f[])
                        {
                        int i, j;
                        ps.println("Following activities are selected ");
                        i = 0;
                        ps.print(" "+i);
                        for (j = 1; j < f.length; j++)
                        {
                        if (s[j] >= f[i])
                        {
                        ps.print(" " + j);
                        i = j;
                        }
                        }
                        ps.println("");
                        }
                        public static void main(String ...st){
                        int s[] = {1, 3, 0, 5, 8, 5};
                        int f[] = {9, 4, 1, 7, 9, 9};
                        Arrays.sort(f);
                        printMaxActivities(s, f);
                        }
                        }`,
                        output: `Following activities are selected 
                        0 1 3`,
                      },
                      Python: {
                        code: `def printMaxActivities(s,f):
  print("Following activities are selected ")
  i = 0
  print(i)
  for j in range(1,len(f)):
    if s[j] >= f[i]:
      print(j)
      i=j


s = [1,3,0,5,8,5]
f = [9,4,1,7,9,9]
f.sort()
printMaxActivities(s,f)`,
                        output: `Following activities are selected 
                        0
                        1
                        3`,
                      },
                      "C++": {
                        code: `#include <iostream>
                        #include <bits/stdc++.h>
                        using namespace std;
                        void printMaxActivities(int s[], int f[], int n)
                        {
                        int i, j;
                        cout <<"Following activities are selected "<< endl;
                        i = 0;
                        cout <<" "<< i;
                        for (j = 1; j < n; j++)
                        {
                        if (s[j] >= f[i])
                        {
                        cout <<" " << j;
                        i = j;
                        }
                        }
                        cout << endl;
                        }
                        int main()
                        {
                        int s[] = {1, 3, 0, 5, 8, 5};
                        int f[] = {9, 4, 1, 7, 9, 9};
                        int len = sizeof(f)/sizeof(f[0]);
                        sort(f,f+len);
                        int n = sizeof(s)/sizeof(s[0]);
                        printMaxActivities(s, f, n);
                        return 0;
                        }
                        // 0 3 5 5 8 1
                        // 1 4 7 9 9 9`,
                        output: `Following activities are selected 
                        0 1 3`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Job Sequencing With Deadlines",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Given an array of jobs where every job has a deadline and
                  associated profit if the job is finished before the deadline.
                  It is also given that every job takes single unit of time, so
                  the minimum possible deadline of any job is 1. How to maximize
                  the total profit if only one job can be scheduled at a time
                </Span>
                <Span>
                  <b>Greedy Approach</b>
                </Span>
                <Span>
                  Sort all the jobs in decreasing order of their profit. And
                  then with the help of result boolean array , assign values to
                  jobs array with the help of corresponding deadlines.
                </Span>
                <Span>
                  <b>Example</b>
                </Span>
                <p>
                  JobId: A , B , C , D , E <br />
                  Deadline (Units/time): 2 , 1 , 2 ,1 ,3 <br />
                  Profit 100, 19, 27, 25, 15 <br />
                  answer (a to b units / time) will be : 0 C 1 A 2 E 3 <br />( E
                  can be completed within 3 units of time and both A and C with
                  comparatively larger profit than D and B can be completed
                  within 2 units of time )
                </p>
                <CodeEditor
                  options={{
                    title: "Job Sequencing With Deadlines",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `class Job {
                          constructor(j,d,p){
                            this.job = j
                            this.profit = p
                            this.deadline = d
                          }
                        }
                        
                        function print(jobs,t){
                        let result = Array(t).fill(false)
                        let answer = Array(t).fill(null)
                        jobs.sort((a,b)=>b.deadline-a.deadline)
                        for(let i in jobs)
                          for(let j=Math.min(t-1,jobs[i].deadline-1);j>=0;j--){
                                if(!result[j]){
                                  result[j] = true
                                  answer[j] = jobs[i].job
                                  break
                                }
                          }
                        console.log(answer)
                        }
                        
                        const jobs = [
                          new Job('A',2,100),
                          new Job('B',1,19),
                          new Job('C',2,27),
                          new Job('D',1,25),
                          new Job('E',3,15),
                        ]
                        
                        print(jobs,3)`,
                        output: `[ 'C', 'A', 'E' ]`,
                      },
                      Java: {
                        code: `import java.util.*;
 
                        class Main
                        {
                            char id;
                            int deadline, profit;
                         
                            public Main() {}
                         
                            public Main(char id, int deadline, int profit)
                            {
                                this.id = id;
                                this.deadline = deadline;
                                this.profit = profit;
                            }
                         
                            void printJobScheduling(ArrayList<Main> arr, int t)
                            {
                                int n = arr.size();
                        
                                Collections.sort(arr,
                                                 (a, b) -> b.profit - a.profit);
                         
                        // sort jobs on the basis of deadlines
                        
                                boolean result[] = new boolean[t];
                         
                                char job[] = new char[t];
                         
                                for (int i = 0; i < n; i++)
                                {
                                    for (int j
                                         = Math.min(t - 1, arr.get(i).deadline - 1);
                                         j >= 0; j--) {
                        
                                          // iterate from max dealine to 0
                                          // and choose appropriate jobs using their respective index
                                          // as we already sorted them  
                        
                                        if (result[j] == false)
                                        {
                                            result[j] = true;
                                            job[j] = arr.get(i).id;
                                            break;
                                        }
                                    }
                                }
                         
                                 for (char jb : job)
                                {
                                    System.out.print(jb + " ");
                                }
                                System.out.println();
                            }
                         
                            public static void main(String args[])
                            {
                                ArrayList<Main> arr = new ArrayList<Main>();
                         
                                arr.add(new Main('a', 2, 100));
                                arr.add(new Main('b', 1, 19));
                                arr.add(new Main('c', 2, 27));
                                arr.add(new Main('d', 1, 25));
                                arr.add(new Main('e', 3, 15));
                               
                                System.out.println("Following is maximum "
                                                   + "profit sequence of jobs");
                         
                                Main job = new Main();
                         
                                job.printJobScheduling(arr, 3);
                            }
                        }`,
                        output: `Following is maximum profit sequence of jobs
                        c a e `,
                      },
                      Python: {
                        code: `def printJobScheduling(arr, t):

  n = len(arr)

  for i in range(n):
    for j in range(n - 1 - i):
      if arr[j][2] < arr[j + 1][2]:
        arr[j], arr[j + 1] = arr[j + 1], arr[j]

  result = [False] * t

  job = ['-1'] * t

  for i in range(len(arr)):
    for j in range(min(t - 1, arr[i][1] - 1), -1, -1):
      if result[j] is False:
        result[j] = True
        job[j] = arr[i][0]
        break

  print(job)

arr = [['a', 2, 100], 
  ['b', 1, 19],
  ['c', 2, 27],
  ['d', 1, 25],
  ['e', 3, 15]]


print("Following is maximum profit sequence of jobs")
printJobScheduling(arr, 3)`,
                        output: `['c', 'a', 'e']`,
                      },
                      "C++": {
                        code: `#include<iostream>
                        #include<algorithm>
                        using namespace std;
                         
                        struct Job
                        {
                           char id;    
                           int dead;    
                           int profit;  
                        };
                         
                        bool comparison(Job a, Job b)
                        {
                             return (a.profit > b.profit);
                        }
                         
                        void printJobScheduling(Job arr[], int n)
                        {
                            sort(arr, arr+n, comparison);
                         
                            int result[n]; 
                            bool slot[n];  
                         
                            for (int i=0; i<n; i++)
                                slot[i] = false;
                         
                            for (int i=0; i<n; i++)
                            {
                               for (int j=min(n, arr[i].dead)-1; j>=0; j--)
                               {
                                  if (slot[j]==false)
                                  {
                                     result[j] = i; 
                                     slot[j] = true; 
                                     break;
                                  }
                               }
                            }
                             for (int i=0; i<n; i++)
                               if (slot[i])
                                 cout << arr[result[i]].id << " ";
                        }
                         
                        int main()
                        {
                            Job arr[] = { {'a', 2, 100}, {'b', 1, 19}, {'c', 2, 27},
                                           {'d', 1, 25}, {'e', 3, 15}};
                            int n = sizeof(arr)/sizeof(arr[0]);
                            cout << "Following is maximum profit sequence of jobs \n";
                               printJobScheduling(arr, n);
                            return 0;
                        }`,
                        output: `Following is maximum profit sequence of jobs 
                        c a e`,
                      },
                      Kotlin: {
                        code: `import java.util.*

                        internal class Main {
                            var id = 0.toChar()
                            var deadline = 0
                            var profit = 0
                        
                            constructor() {}
                            constructor(id: Char, deadline: Int, profit: Int) {
                                this.id = id
                                this.deadline = deadline
                                this.profit = profit
                            }
                        
                            fun printJobScheduling(arr: ArrayList<Main?>, t: Int) {
                                val n: Int = arr.size()
                                Collections.sort(
                                    arr
                                ) { a, b -> b.profit - a.profit }
                        
                        // sort jobs on the basis of deadlines
                                val result = BooleanArray(t)
                                val job = CharArray(t)
                                for (i in 0 until n) {
                                    for (j in Math.min(t - 1, arr.get(i).deadline - 1) downTo 0) {
                        
                                        // iterate from max dealine to 0
                                        // and choose appropriate jobs using their respective index
                                        // as we already sorted them  
                                        if (result[j] == false) {
                                            result[j] = true
                                            job[j] = arr.get(i).id
                                            break
                                        }
                                    }
                                }
                                for (jb: Char in job) {
                                    System.out.print("$jb ")
                                }
                                System.out.println()
                            }
                        
                            companion object {
                                fun main(args: Array<String?>?) {
                                    val arr: ArrayList<Main?> = ArrayList<Main>()
                                    arr.add(Main('a', 2, 100))
                                    arr.add(Main('b', 1, 19))
                                    arr.add(Main('c', 2, 27))
                                    arr.add(Main('d', 1, 25))
                                    arr.add(Main('e', 3, 15))
                                    System.out.println(
                                        "Following is maximum "
                                                + "profit sequence of jobs"
                                    )
                                    val job = Main()
                                    job.printJobScheduling(arr, 3)
                                }
                            }
                        }`,
                        output: `Following is maximum profit sequence of jobs
                        c a e `,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Fractional Knapsack",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Given weights and values of n items, we need to put these
                  items in a knapsack of capacity W to get the maximum total
                  value in the knapsack.
                </Span>
                <Span>
                  <b> Greedy Approach</b>
                </Span>
                <Span>
                  1. Calculate the ratio(value/weight) for each item
                  <br /> 2. Sort the items based on this ratio (desc)
                  <br /> 3. Take the item with highest ratio and add them until
                  we can't add the next item as whole. <br /> 4. At the end add
                  the next item as much(fraction) as we can.
                </Span>
                <Span>
                  <b>Example</b>
                </Span>
                <Span>
                  Label: A , B , C <br />
                  Weight : 10 , 20 , 30 <br />
                  Value: 60 , 100 , 120 <br />
                  Total Capacity =&lt; 50 <br />
                  0-1 Knapsack solution will be : B +C ( profit: 100 + 120 = 220
                  ) (wt. : 20 + 30 = 50) <br />
                  Fractional knapsack solution will be : A + B + (2/3) * C (
                  profit: 60+ 100 + 2* 120 / 3 = 240 ) (wt. : 10 + 20 + 2 * 30 /
                  3 = 50)
                </Span>
                <Span>
                  <b>
                    Drawbacks of using brute force approach for solving this
                    problem:
                  </b>
                </Span>
                <p>
                  A brute-force solution would be to try all possible subset
                  with all different fraction but that will be too much time
                  taking.
                </p>
                <CodeEditor
                  options={{
                    title: "Fractional Knapsack",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `class Item {
                          constructor(value,weight){
                            this.value = value
                            this.weight = weight
                          }
                        }
                        
                        function fractionalKnapsack(W,arr,n){
                        
                        arr.sort((a,b) => (a.value,a.weight) - (b.value/b.weight))
                        
                        let curWeight = 0
                        let finalvalue = 0.0
                        
                        for (let i = 0; i < n; i++) {
                          if (curWeight + arr[i].weight <= W) {
                            curWeight += arr[i].weight;
                            finalvalue += arr[i].value;
                          }
                          else {
                            let remain = W - curWeight;
                            finalvalue += arr[i].value * (remain/ arr[i].weight);
                            break;
                          }
                        }
                        return finalvalue;
                        }
                        
                        let W = 50; 
                        let arr = []
                        arr.push(new Item(60, 10 ), new Item(100, 20), new Item(120, 30))
                         
                        console.log(fractionalKnapsack(W, arr, arr.length))`,
                        output: `220`,
                      },
                      Java: {
                        code: `import java.util.Arrays;
                        import java.util.Comparator;
                        
                        
                        class Main {
                            private static double getMaxValue(int[] wt, int[] val,
                                                              int capacity)
                            {
                                ItemValue[] iVal = new ItemValue[wt.length];
                         
                                for (int i = 0; i < wt.length; i++) {
                                    iVal[i] = new ItemValue(wt[i], val[i], i);
                                }
                         
                                Arrays.sort(iVal, new Comparator<ItemValue>() {
                                    @Override
                                    public int compare(ItemValue o1, ItemValue o2)
                                    {
                                        return o2.cost.compareTo(o1.cost);
                                    }
                                });
                         
                                double totalValue = 0d;
                         
                                for (ItemValue i : iVal) {
                         
                                    int curWt = (int)i.wt;
                                    int curVal = (int)i.val;
                         
                                    if (capacity - curWt >= 0) {
                                        capacity = capacity - curWt;
                                        totalValue += curVal;
                                    }
                                    else {
                                        double fraction
                                            = ((double)capacity / (double)curWt);
                                        totalValue += (curVal * fraction);
                                        capacity
                                            = (int)(capacity - (curWt * fraction));
                                        break;
                                    }
                                }
                         
                                return totalValue;
                            }
                         
                            static class ItemValue {
                                Double cost;
                                double wt, val, ind;
                         
                                public ItemValue(int wt, int val, int ind)
                                {
                                    this.wt = wt;
                                    this.val = val;
                                    this.ind = ind;
                                    cost = new Double((double)val / (double)wt);
                                }
                            }
                         
                            public static void main(String[] args)
                            {
                                int[] wt = { 10, 40, 20, 30 };
                                int[] val = { 60, 40, 100, 120 };
                                int capacity = 50;
                         
                                double maxValue = getMaxValue(wt, val, capacity);
                                 System.out.println("Maximum value we can obtain = "
                                                   + maxValue);
                            }
                        }`,
                        output: `Maximum value we can obtain = 240.0`,
                      },
                      Python: {
                        code: `class ItemValue:
    def __init__(self, wt, val, ind):
        self.wt = wt
        self.val = val
        self.ind = ind
        self.cost = val // wt
    def __lt__(self, other):
        return self.cost < other.cost
  
class FractionalKnapSack:
    @staticmethod
    def getMaxValue(wt, val, capacity):
        iVal = []
        for i in range(len(wt)):
            iVal.append(ItemValue(wt[i], val[i], i))
        iVal.sort(reverse=True)
        totalValue = 0
        for i in iVal:
            curWt = int(i.wt)
            curVal = int(i.val)
            if capacity - curWt >= 0:
                capacity -= curWt
                totalValue += curVal
            else:
                fraction = capacity / curWt
                totalValue += curVal * fraction
                capacity = int(capacity - (curWt * fraction))
                break
        return totalValue

if __name__ == "__main__":
    wt = [10, 40, 20, 30]
    val = [60, 40, 100, 120]
    capacity = 50
    maxValue = FractionalKnapSack.getMaxValue(wt, val, capacity)
    print("Maximum value in Knapsack =", maxValue)`,
                        output: `Maximum value in Knapsack = 240.0`,
                      },
                      "C++": {
                        code: `#include <bits/stdc++.h>
 
                        using namespace std;
                         
                        struct Item {
                            int value, weight;
                             Item(int value, int weight)
                            {
                               this->value=value;
                               this->weight=weight;
                            }
                        };
                         
                        bool cmp(struct Item a, struct Item b)
                        {
                            double r1 = (double)a.value / (double)a.weight;
                            double r2 = (double)b.value / (double)b.weight;
                            return r1 > r2;
                        }
                         
                        double fractionalKnapsack(int W, struct Item arr[], int n)
                        {
                            sort(arr, arr + n, cmp);
                         
                            int curWeight = 0;
                            double finalvalue = 0.0;
                         
                            for (int i = 0; i < n; i++) {
                                if (curWeight + arr[i].weight <= W) {
                                    curWeight += arr[i].weight;
                                    finalvalue += arr[i].value;
                                }
                                else {
                                    int remain = W - curWeight;
                                    finalvalue += arr[i].value
                                                  * ((double)remain
                                                     / (double)arr[i].weight);
                                    break;
                                }
                            }
                             return finalvalue;
                        }
                         
                        int main()
                        {
                            int W = 50; 
                            Item arr[] = { { 60, 10 }, { 100, 20 }, { 120, 30 } };
                         
                            int n = sizeof(arr) / sizeof(arr[0]);
                             cout << "Maximum value we can obtain = "
                                 << fractionalKnapsack(W, arr, n) << endl;
                            return 0;
                        }`,
                        output: `Maximum value we can obtain = 240`,
                      },
                      Kotlin: {
                        code: `import java.util.Arrays
                        import java.util.Comparator
                        
                        object Main {
                            private fun getMaxValue(
                                wt: IntArray, 'val': IntArray,
                                capacity: Int
                            ): Double {
                                var capacity = capacity
                                val iVal: Array<ItemValue> = arrayOfNulls(wt.size)
                                for (i in wt.indices) {
                                    iVal[i] = ItemValue(wt[i], 'val'[i], i)
                                }
                                Arrays.sort(iVal, object : Comparator<ItemValue?>() {
                                    @Override
                                    fun compare(o1: ItemValue, o2: ItemValue): Int {
                                        return o2.cost.compareTo(o1.cost)
                                    }
                                })
                                var totalValue = 0.0
                                for (i: ItemValue in iVal) {
                                    val curWt = i.wt.toInt()
                                    val curVal = i.'val'.toInt()
                                    if (capacity - curWt >= 0) {
                                        capacity = capacity - curWt
                                        totalValue += curVal.toDouble()
                                    } else {
                                        val fraction = capacity.toDouble() / curWt.toDouble()
                                        totalValue += curVal * fraction
                                        capacity = (capacity - curWt * fraction).toInt()
                                        break
                                    }
                                }
                                return totalValue
                            }
                        
                            fun main(args: Array<String?>?) {
                                val wt = intArrayOf(10, 40, 20, 30)
                                val 'val' = intArrayOf(60, 40, 100, 120)
                                val capacity = 50
                                val maxValue = getMaxValue(wt, 'val', capacity)
                                System.out.println(
                                    "Maximum value we can obtain = "
                                            + maxValue
                                )
                            }
                        
                            internal class ItemValue(wt: Int, 'val': Int, ind: Int) {
                                var cost: Double
                                var wt: Double
                                var 'val': Double
                                var ind: Double
                        
                                init {
                                    this.wt = wt.toDouble()
                                    this.'val' = 'val'.toDouble()
                                    this.ind = ind.toDouble()
                                    cost = Double('val'.toDouble() / wt.toDouble())
                                }
                            }
                        }`,
                        output: `Maximum value we can obtain = 240.0`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Graph Coloring",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Color all the vertcies of the graph in such a way that no two
                  connected vertices will have the same color.
                </Span>
                <Span>
                  <b>Example</b>
                </Span>
                <Span>
                  Sudoku is an example of graph coloring problem where every
                  cell represents a vertex. There is an edge between two
                  vertices if they are in same row or same column or same block.
                </Span>
                <Span>
                  <b>Greedy Approach</b>
                </Span>
                <Img src={GraphImg} alt="img" />
                <p>
                  1. Color first vertex with first color.
                  <br /> 2. For the remaining V-1 vertices. , consider the
                  currently picked vertex and color it with the lowest numbered
                  color that has not been used on any previously colored
                  vertices adjacent to it. If all previously used colors appear
                  on vertices adjacent to v, assign a new color to it.
                </p>
                <CodeEditor
                  options={{
                    title: "Graph Coloring",
                    output: `Coloring of graph 1 
                    Vertex 0  ---> Color 0
                    Vertex 1  ---> Color 1
                    Vertex 2  ---> Color 2
                    Vertex 3  ---> Color 0
                    Vertex 4  ---> Color 1
                    
                    Coloring of graph 2
                    Vertex 0  ---> Color 0
                    Vertex 1  ---> Color 1
                    Vertex 2  ---> Color 2
                    Vertex 3  ---> Color 0
                    Vertex 4  ---> Color 3`,
                    codes: {
                      Javascript: {
                        code: `
                        class Graph {
                          constructor(noOfVertices,colors) {
                              this.noOfVertices = noOfVertices;
                              this.adjList = new Map();
                              this.colors = colors
                          }
                      
                          addVertex(v) {
                              this.adjList.set(v, [])
                          }
                      
                          addEdge(v, w) {
                              this.adjList.get(v).push(w);
                              this.adjList.get(w).push(v);
                          }
                          
                         graphColoring(){
                          let result = {}  
                          let available = {}
                          const keys = Array.from(this.adjList.keys())
                          for(let i in keys){  
                            result[keys[i]] = i == 0 ? this.colors[0] : null
                          }
                          for(let c of this.colors){  
                            available[c] = false
                          }
                           for(let u=1;u<keys.length;u++){
                             for(let i of this.adjList.get(keys[u])){
                               if(result[i]){
                                   available[result[i]] = true
                               }
                             }
                             let cr=0
                             while(cr < this.noOfVertices){
                                if(!available[this.colors[cr]])
                                break;
                                cr+=1
                             }
                             result[keys[u]] = this.colors[cr]
                             for(let i of this.adjList.get(keys[u])){
                               if(result[i]){
                                   available[result[i]] = false
                               }
                             }
                           }
                          for(let i of keys){  
                            console.log("Vertex : " + i + " ---> Color : " + result[i])
                          }
                          }
                      
                          printAdjList() {
                              var get_keys = this.adjList.keys();
                              for (var i of get_keys) {
                                  var get_values = this.adjList.get(i);
                                  var conc = "";
                                  for (var j of get_values)
                                      conc += j + " ";
                                  console.log(i + " -> " + conc);
                              }
                          }
                      }
                      
                      
                      const g = new Graph(6,['red','green','yellow','blue','black','orange'])
                      const v = ['A', 'B', 'C', 'D', 'E', 'F']
                      for (let x of v) {
                          g.addVertex(x)
                      }
                      
                      g.addEdge('A', 'B');
                      g.addEdge('A', 'D');
                      g.addEdge('A', 'E');
                      g.addEdge('B', 'C');
                      g.addEdge('D', 'E');
                      g.addEdge('E', 'F');
                      g.addEdge('E', 'C');
                      g.addEdge('C', 'F');
                      g.printAdjList();
                      console.log(Array.from(g.adjList.keys()))
                      g.graphColoring();                      
                        `,
                        output: `A -> B D E 
                        B -> A C 
                        C -> B E F 
                        D -> A E 
                        E -> A D F C 
                        F -> E C 
                        [ 'A', 'B', 'C', 'D', 'E', 'F' ]
                        Vertex : A ---> Color : red
                        Vertex : B ---> Color : green
                        Vertex : C ---> Color : red
                        Vertex : D ---> Color : green
                        Vertex : E ---> Color : yellow
                        Vertex : F ---> Color : green`,
                      },
                      Java: {
                        code: `import java.util.*;
                       import java.util.LinkedList;
                       
                       class Graph
                       {
                         private int V; 
                         private LinkedList<Integer> adj[]; 
                       
                         Graph(int v)
                         {
                           V = v;
                           adj = new LinkedList[v];
                           for (int i=0; i<v; ++i)
                             adj[i] = new LinkedList();
                         }
                       
                         void addEdge(int v,int w)
                         {
                           adj[v].add(w);
                           adj[w].add(v);
                         }
                       
                         void greedyColoring()
                         {
                           int result[] = new int[V];
                           Arrays.fill(result, -1);
                           result[0] = 0;
                           boolean available[] = new boolean[V];
                           Arrays.fill(available, true);
                           for (int u = 1; u < V; u++)
                           {
                             Iterator<Integer> it = adj[u].iterator() ;
                             while (it.hasNext())
                             {
                               int i = it.next();
                               if (result[i] != -1)
                                 available[result[i]] = false;
                             }
                             int cr;
                             for (cr = 0; cr < V; cr++){
                               if (available[cr])
                                 break;
                             }
                             result[u] = cr; 
                             Arrays.fill(available, true);
                           }
                           for (int u = 0; u < V; u++)
                             System.out.println("Vertex " + u + " ---> Color "
                                       + result[u]);
                         }
                       
                         public static void main(String args[])
                         {
                           Graph g1 = new Graph(5);
                           g1.addEdge(0, 1);
                           // if 0 is connected to 1 , 1 is also connected to 0
                           g1.addEdge(0, 2);
                           g1.addEdge(1, 2);
                           g1.addEdge(1, 3);
                           g1.addEdge(2, 3);
                           g1.addEdge(3, 4);
                           System.out.println("Coloring of graph 1");
                           g1.greedyColoring();
                           System.out.println();
                           Graph g2 = new Graph(5);
                           g2.addEdge(0, 1);
                           g2.addEdge(0, 2);
                           g2.addEdge(1, 2);
                           g2.addEdge(1, 4);
                           g2.addEdge(2, 4);
                           g2.addEdge(4, 3);
                           System.out.println("Coloring of graph 2 ");
                           g2.greedyColoring();
                         }
                       }`,
                      },
                      Python: {
                        code: `def addEdge(adj, v, w):
  adj[v].append(w)
  adj[w].append(v)
  return adj

def graphColoring(adj, V):
  
  result = [-1] * V

  result[0] = 0

  available = [False] * V

  for u in range(1, V):
    
    for i in adj[u]:
      if (result[i] != -1):
        available[result[i]] = True

    cr = 0
    while cr < V:
      if (available[cr] == False):
        break
      
      cr += 1
      
    result[u] = cr

    for i in adj[u]:
      if (result[i] != -1):
        available[result[i]] = False

  for u in range(V):
    print("Vertex", u, " ---> Color", result[u])

if __name__ == '__main__':
  
  g1 = [[] for i in range(5)]
  g1 = addEdge(g1, 0, 1)
  g1 = addEdge(g1, 0, 2)
  g1 = addEdge(g1, 1, 2)
  g1 = addEdge(g1, 1, 3)
  g1 = addEdge(g1, 2, 3)
  g1 = addEdge(g1, 3, 4)
  print("Coloring of graph 1 ")
  graphColoring(g1, 5)

  g2 = [[] for i in range(5)]
  g2 = addEdge(g2, 0, 1)
  g2 = addEdge(g2, 0, 2)
  g2 = addEdge(g2, 1, 2)
  g2 = addEdge(g2, 1, 4)
  g2 = addEdge(g2, 2, 4)
  g2 = addEdge(g2, 4, 3)
  print("\nColoring of graph 2")
  graphColoring(g2, 5)
                      `,
                      },
                      "C++": {
                        code: `#include <iostream>
                        #include <list>
                        using namespace std;
                        
                        class Graph
                        {
                          int V; 
                          list<int> *adj;
                        public:
                          Graph(int V) { this->V = V; adj = new list<int>[V]; }
                          ~Graph()	 { delete [] adj; }
                        
                          void addEdge(int v, int w);
                        
                          void greedyColoring();
                        };
                        
                        void Graph::addEdge(int v, int w)
                        {
                          adj[v].push_back(w);
                          adj[w].push_back(v); 
                        }
                        
                        void Graph::greedyColoring()
                        {
                          int result[V];
                        
                          result[0] = 0;
                        
                          for (int u = 1; u < V; u++)
                            result[u] = -1; 
                        
                          bool available[V];
                          for (int cr = 0; cr < V; cr++)
                            available[cr] = false;
                        
                          for (int u = 1; u < V; u++)
                          {
                            list<int>::iterator i;
                            for (i = adj[u].begin(); i != adj[u].end(); ++i)
                              if (result[*i] != -1)
                                available[result[*i]] = true;
                        
                            int cr;
                            for (cr = 0; cr < V; cr++)
                              if (available[cr] == false)
                                break;
                        
                            result[u] = cr;
                        
                            for (i = adj[u].begin(); i != adj[u].end(); ++i)
                              if (result[*i] != -1)
                                available[result[*i]] = false;
                          }
                        
                          for (int u = 0; u < V; u++)
                            cout << "Vertex " << u << " ---> Color "
                              << result[u] << endl;
                        }
                        
                        int main()
                        {
                          Graph g1(5);
                          g1.addEdge(0, 1);
                          g1.addEdge(0, 2);
                          g1.addEdge(1, 2);
                          g1.addEdge(1, 3);
                          g1.addEdge(2, 3);
                          g1.addEdge(3, 4);
                          cout << "Coloring of graph 1 \n";
                          g1.greedyColoring();
                        
                          Graph g2(5);
                          g2.addEdge(0, 1);
                          g2.addEdge(0, 2);
                          g2.addEdge(1, 2);
                          g2.addEdge(1, 4);
                          g2.addEdge(2, 4);
                          g2.addEdge(4, 3);
                          cout << "\nColoring of graph 2 \n";
                          g2.greedyColoring();
                        
                          return 0;
                        }
                        `,
                      },
                      Kotlin: {
                        code: `import java.io.*
                        import java.util.*
                        import java.util.LinkedList
                        
                        internal class Graph(private val V: Int) {
                            private val adj: Array<LinkedList<Integer>>
                            fun addEdge(v: Int, w: Int) {
                                adj[v].add(w)
                                adj[w].add(v)
                            }
                        
                            fun greedyColoring() {
                                val result = IntArray(V)
                                Arrays.fill(result, -1)
                                result[0] = 0
                                val available = BooleanArray(V)
                                Arrays.fill(available, true)
                                for (u in 1 until V) {
                                    val it: Iterator<Integer> = adj[u].iterator()
                                    while (it.hasNext()) {
                                        val i: Int = it.next()
                                        if (result[i] != -1) available[result[i]] = false
                                    }
                                    var cr: Int
                                    cr = 0
                                    while (cr < V) {
                                        if (available[cr]) break
                                        cr++
                                    }
                                    result[u] = cr
                                    Arrays.fill(available, true)
                                }
                                for (u in 0 until V) System.out.println(
                                    "Vertex " + u + " ---> Color "
                                            + result[u]
                                )
                            }
                        
                            companion object {
                                fun main(args: Array<String?>?) {
                                    val g1 = Graph(5)
                                    g1.addEdge(0, 1)
                                    g1.addEdge(0, 2)
                                    g1.addEdge(1, 2)
                                    g1.addEdge(1, 3)
                                    g1.addEdge(2, 3)
                                    g1.addEdge(3, 4)
                                    System.out.println("Coloring of graph 1")
                                    g1.greedyColoring()
                                    System.out.println()
                                    val g2 = Graph(5)
                                    g2.addEdge(0, 1)
                                    g2.addEdge(0, 2)
                                    g2.addEdge(1, 2)
                                    g2.addEdge(1, 4)
                                    g2.addEdge(2, 4)
                                    g2.addEdge(4, 3)
                                    System.out.println("Coloring of graph 2 ")
                                    g2.greedyColoring()
                                }
                            }
                        
                            init {
                                adj = arrayOfNulls<LinkedList>(V)
                                for (i in 0 until V) adj[i] = LinkedList()
                            }
                        }`,
                      },
                    },
                  }}
                />
                <Span>
                  <b>Practice</b>
                </Span>
                <Img src={GraphImg} alt="img" reduceH left />
                <CodeEditor
                  options={{
                    title: "Graph Coloring(BFS)",
                    codes: {
                      Javascript: {
                        code: `class Graph {
  
                          constructor(n,v,c){
                            this.n = n
                            this.g = {}
                            this.c = c
                            this.v = v
                          }
                        
                          addVertex(v){
                            this.g[v] = {
                              color:null,
                              list: []
                            }
                          }
                          
                          addEdge(u,v){
                           this.g[u].list.push(v)
                           this.g[v].list.push(u)
                          }
                        
                          print(){
                            console.log(this.g)
                          }
                        
                          bfs(){
                            let q = []
                            let visited = {}
                            visited[0] = true
                            q.push(0)
                            this.g[0].color = this.c[0]
                            while(q.length){
                              const v = q.shift()
                              const list = this.g[v].list
                              let c = 0
                              for(let i of list){
                                if(!visited[i]){
                                  visited[i] = true
                                  q.push(i)
                                }
                                if(this.g[i].color===c) c++
                              }
                              this.g[v].color = c
                              console.log(this.v[v] + " -> " + this.c[c])
                            }
                          }
                        
                        }
                        
                        const c = ['red', 'green', 'yellow', 'blue', 'black', 'orange']
                        const v = ['A', 'B', 'C', 'D', 'E', 'F']
                        const g = new Graph(6,v,c)
                        for (let x in v) {
                            g.addVertex(x)
                        }
                        g.addEdge(0, 1);
                        g.addEdge(0, 3);
                        g.addEdge(0, 4);
                        g.addEdge(1, 2);
                        g.addEdge(3, 4);
                        g.addEdge(4, 5);
                        g.addEdge(4, 2);
                        g.addEdge(2, 5);
                        g.bfs()
                        g.print()
                        `,
                        output: `A -> red
                        B -> green
                        D -> green
                        E -> yellow
                        C -> red
                        F -> green
                        {
                          '0': { color: 0, list: [ 1, 3, 4 ] },
                          '1': { color: 1, list: [ 0, 2 ] },
                          '2': { color: 0, list: [ 1, 4, 5 ] },
                          '3': { color: 1, list: [ 0, 4 ] },
                          '4': { color: 2, list: [ 0, 3, 5, 2 ] },
                          '5': { color: 1, list: [ 4, 2 ] }
                        }`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Huffman Coding",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Assign variable-length codes to input characters, lengths of
                  the assigned codes are based on the frequencies of
                  corresponding characters. The most frequent character gets the
                  smallest code and the least frequent character gets the
                  largest code.
                </Span>
                <Span>
                  <b>Note : </b>
                  Huffman coding is a lossless data compression algorithm.
                </Span>
                <Span>
                  <b>Example :</b>
                </Span>
                <Span>Character : a , b , c , d , e , f</Span>
                <Span>Frequency : 5 , 9 , 12 , 13 , 16 , 45</Span>
                <Span>Solution :</Span>

                <Span>Character : f , c , d , a , b , e</Span>

                <Span>Code Word : 0 , 100 , 101 , 1100 , 1101 , 101</Span>

                <Span>
                  <b>Greedy Approach :</b>
                </Span>
                <Span>
                  1 . Create a leaf node for each unique character and build a
                  min heap of all leaf nodes (Min Heap is used as a priority
                  queue. The value of frequency field is used to compare two
                  nodes in min heap. Initially, the least frequent character is
                  at root) <br />
                  2 . Extract two nodes with the minimum frequency from the min
                  heap. <br />
                  3 . Create a new internal node with a frequency equal to the
                  sum of the two nodes frequencies. Make the first extracted
                  node as its left child and the other extracted node as its
                  right child. Add this node to the min heap. <br />
                  4 . Repeat steps#2 and #3 until the heap contains only one
                  node. The remaining node is the root node and the tree is
                  complete. <br />
                </Span>
                <p>
                  As we can character with highest frequency i.e, f got smallest
                  character code
                </p>
                <CodeEditor
                  options={{
                    title: "Huffman Coding",
                    output: `f -> 0
                    c -> 100
                    d -> 101
                    a -> 1100
                    b -> 1101
                    e -> 111`,
                    codes: {
                      Javascript: {
                        code: `class node{
                          constructor(freq,symbol,left=null,right=null){
                             this.freq = freq
                             this.symbol = symbol
                             this.left = left
                             this.right= right
                             this.huff = ''
                          } 
                       }
                       
                       function printNodes(node, val=''){
                           const newVal = val + ""+ node.huff
                           if(node.left)
                               printNodes(node.left, newVal)
                           if(node.right)
                               printNodes(node.right, newVal)
                           if(!node.left && !node.right)
                               console.log(node.symbol + ' -> ' + newVal)
                       }
                       
                       const chars = ['a', 'b', 'c', 'd', 'e', 'f']
                       
                       const freq = [ 5, 9, 12, 13, 16, 45]
                       
                       let nodes = []
                       
                       for(let c in chars){
                         nodes.push(new node(freq[c],chars[c]))
                       }
                       
                       
                       try{
                       while(nodes.length > 1){
                           nodes = nodes.sort((a,b)=>a.freq-b.freq)
                           let left = nodes[0]
                           let right = nodes[1]
                           left.huff = 0
                           right.huff = 1
                           let newNode = new node(left.freq+right.freq, left.symbol+right.symbol, left, right)
                           nodes = nodes.filter(x=>x.symbol!==left.symbol)
                           nodes = nodes.filter(x=>x.symbol!==right.symbol)
                           nodes.push(newNode)
                       }
                       printNodes(nodes[0])
                       }catch(e){
                       console.log(e)
                       }
                       `,
                      },
                      Java: {
                        code: `
                       import java.util.PriorityQueue;
                       import java.util.Scanner;
                       import java.util.Comparator;
                        
                       class HuffmanNode {
                        
                           int data;
                           char c;
                        
                           HuffmanNode left;
                           HuffmanNode right;
                       }
                        
                       
                       class MyComparator implements Comparator<HuffmanNode> {
                           public int compare(HuffmanNode x, HuffmanNode y)
                           {
                        
                               return x.data - y.data;
                           }
                       }
                        
                       class Huffman {
                        
                           public static void printCode(HuffmanNode root, String s)
                           {
                        
                               if (root.left
                                       == null
                                   && root.right
                                          == null
                                   && Character.isLetter(root.c)) {
                        
                                   System.out.println(root.c + ":" + s);
                        
                                   return;
                               }
                       
                               printCode(root.left, s + "0");
                               printCode(root.right, s + "1");
                           }
                        
                           public static void main(String[] args)
                           {
                        
                               Scanner s = new Scanner(System.in);
                        
                               int n = 6;
                               char[] charArray = { 'a', 'b', 'c', 'd', 'e', 'f' };
                               int[] charfreq = { 5, 9, 12, 13, 16, 45 };
                        
                               PriorityQueue<HuffmanNode> q
                                   = new PriorityQueue<HuffmanNode>(n, new MyComparator());
                        
                               for (int i = 0; i < n; i++) {
                       
                                   HuffmanNode hn = new HuffmanNode();
                        
                                   hn.c = charArray[i];
                                   hn.data = charfreq[i];
                        
                                   hn.left = null;
                                   hn.right = null;
                        
                                   q.add(hn);
                               }
                        
                               HuffmanNode root = null;
                        
                               while (q.size() > 1) {
                       
                                   HuffmanNode x = q.peek();
                                   q.poll();
                        
                                   HuffmanNode y = q.peek();
                                   q.poll();
                       
                                   HuffmanNode f = new HuffmanNode();
                        
                                   f.data = x.data + y.data;
                                   f.c = '-';
                        
                                   f.left = x;
                        
                                   f.right = y;
                        
                                   root = f;
                                   q.add(f);
                               }
                                printCode(root, "");
                           }
                       }`,
                      },
                      Python: {
                        code: `class node:
  def __init__(self, freq, symbol, left=None, right=None):
    self.freq = freq

    self.symbol = symbol

    self.left = left

    self.right = right

    self.huff = ''


def printNodes(node, val=''):
  newVal = val + str(node.huff)

  if(node.left):
    printNodes(node.left, newVal)
  if(node.right):
    printNodes(node.right, newVal)

  if(not node.left and not node.right):
    print(f"{node.symbol} -> {newVal}")


chars = ['a', 'b', 'c', 'd', 'e', 'f']

freq = [ 5, 9, 12, 13, 16, 45]

nodes = []

for x in range(len(chars)):
  nodes.append(node(freq[x], chars[x]))

while len(nodes) > 1:
  nodes = sorted(nodes, key=lambda x: x.freq)

  left = nodes[0]
  right = nodes[1]

  left.huff = 0
  right.huff = 1

  newNode = node(left.freq+right.freq, left.symbol+right.symbol, left, right)

  nodes.remove(left)
  nodes.remove(right)
  nodes.append(newNode)

printNodes(nodes[0])
                      `,
                      },
                      "C++": {
                        code: `#include <bits/stdc++.h>
                        using namespace std;
                         
                        struct MinHeapNode {
                         
                            char data;
                            unsigned freq;
                         
                            MinHeapNode *left, *right;
                         
                            MinHeapNode(char data, unsigned freq)
                         
                            {
                                left = right = NULL;
                                this->data = data;
                                this->freq = freq;
                            }
                        };
                         
                        struct compare {
                         
                            bool operator()(MinHeapNode* l, MinHeapNode* r)
                         
                            {
                                return (l->freq > r->freq);
                            }
                        };
                         
                        void printCodes(struct MinHeapNode* root, string str)
                        {
                         
                            if (!root)
                                return;
                         
                            if (root->data != '$')
                                cout << root->data << ": " << str << "\n";
                         
                            printCodes(root->left, str + "0");
                            printCodes(root->right, str + "1");
                        }
                         
                        void HuffmanCodes(char data[], int freq[], int size)
                        {
                            struct MinHeapNode *left, *right, *top;
                         
                            priority_queue<MinHeapNode*, vector<MinHeapNode*>, compare> minHeap;
                         
                            for (int i = 0; i < size; ++i)
                                minHeap.push(new MinHeapNode(data[i], freq[i]));
                         
                            while (minHeap.size() != 1) {
                        
                                left = minHeap.top();
                                minHeap.pop();
                         
                                right = minHeap.top();
                                minHeap.pop();
                         
                                top = new MinHeapNode('$', left->freq + right->freq);
                         
                                top->left = left;
                                top->right = right;
                         
                                minHeap.push(top);
                            }
                         
                            printCodes(minHeap.top(), "");
                        }
                         
                        int main()
                        {
                         
                            char arr[] = { 'a', 'b', 'c', 'd', 'e', 'f' };
                            int freq[] = { 5, 9, 12, 13, 16, 45 };
                            int size = sizeof(arr) / sizeof(arr[0]); 
                            HuffmanCodes(arr, freq, size);
                         
                            return 0;
                        }
                         `,
                      },
                      Kotlin: {
                        code: `import java.util.Arrays
import java.util.Comparator

object Main {
    private fun getMaxValue(
        wt: IntArray, 'val': IntArray,
        capacity: Int
    ): Double {
        var capacity = capacity
        val iVal: Array<ItemValue> = arrayOfNulls(wt.size)
        for (i in wt.indices) {
            iVal[i] = ItemValue(wt[i], 'val'[i], i)
        }
        Arrays.sort(iVal, object : Comparator<ItemValue?>() {
            @Override
            fun compare(o1: ItemValue, o2: ItemValue): Int {
                return o2.cost.compareTo(o1.cost)
            }
        })
        var totalValue = 0.0
        for (i: ItemValue in iVal) {
            val curWt = i.wt.toInt()
            val curVal = i.'val'.toInt()
            if (capacity - curWt >= 0) {
                capacity = capacity - curWt
                totalValue += curVal.toDouble()
            } else {
                val fraction = capacity.toDouble() / curWt.toDouble()
                totalValue += curVal * fraction
                capacity = (capacity - curWt * fraction).toInt()
                break
            }
        }
        return totalValue
    }

    fun main(args: Array<String?>?) {
        val wt = intArrayOf(10, 40, 20, 30)
        val 'val' = intArrayOf(60, 40, 100, 120)
        val capacity = 50
        val maxValue = getMaxValue(wt, 'val', capacity)
        System.out.println(
            "Maximum value we can obtain = "
                    + maxValue
        )
    }

    internal class ItemValue(wt: Int, 'val': Int, ind: Int) {
        var cost: Double
        var wt: Double
        var 'val': Double
        var ind: Double

        init {
            this.wt = wt.toDouble()
            this.'val' = 'val'.toDouble()
            this.ind = ind.toDouble()
            cost = Double('val'.toDouble() / wt.toDouble())
        }
    }
}`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Minimum Cost Spanning Trees",
            content: (
              <>
                <Span>
                  It is a subset of the edges of a connected , edgeweighted
                  graph that connects all the vertices together, without any
                  cycles and with the minimum possible totol edge weight. |E| =
                  |V| -1
                </Span>
                <Span>
                  Examples: Kruskal and Prims algorithms are used to find such
                  trees.
                </Span>
                <Span>
                  <b>Applications</b>
                </Span>
                <Span>
                  Network Design: Telephone , Electrical , Hydraulic , TV Cable
                  , Computer , Road
                  <br />
                  Approximation Algorithms: Travelling Salesman Problem , NP
                  Hard
                  <br /> List of cities and distance b/w each city
                  <br /> shortest path which reach each city exactly once and
                  return to the original city
                  <br /> MST * 2
                  <br /> Stiener Tree
                  <br /> Used to connect vertices of subset (terminal vertices)
                  (A,D)
                  <br /> Indirect Use Of MST
                  <br /> Max Bottleneck problem : here we find the path b/w two
                  designated vertices in a weighted graph maximizing the weight
                  of its minimum weight edge in the path ,
                  <br /> LDPC low density parity check , Cluster Analysis ,
                  groups based on some similarities
                </Span>
              </>
            ),
          },
          {
            title: "Kruskal",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Construct an MST with the help of globally available edge with
                  minimum weight which is not picked yet.
                </Span>
                <Span>
                  <b>Note</b>
                </Span>
                <Span>
                  <b>Note</b>
                </Span>

                <Span>Kruskal is slower tham prim's.</Span>
                <Span>Time Complexity : O(ElogE) or (ElogV)</Span>
                <Span>
                  <b>Greedy Approach</b>
                </Span>

                <Span>
                  Sort all the edges in non-decreasing order of their weight.{" "}
                  <br />
                  Pick the smallest edge. Check if it forms a cycle with the
                  spanning tree formed so far. If cycle is not formed, include
                  this edge. Else, discard it.
                  <br />
                  Repeat step#2 until there are (V-1) edges in the spanning
                  tree. <br />
                  Note : Basically , we make use of rank of a nodes in order to
                  generate the tree. Node with higher rank will be closer to
                  root. <br />
                </Span>
                <p>
                  <b>Note :</b>
                  Basically , we make use of rank of a nodes in order to
                  generate the tree. Node with higher rank will be closer to
                  root.
                </p>
                <CodeEditor
                  options={{
                    title: "Kruskal",
                    output: `Following are the edges in the constructed MST
                    2 -- 3 == 4
                    0 -- 3 == 5
                    0 -- 1 == 10
                    Minimum Cost Of Spanning Tree 19`,
                    codes: {
                      Java: {
                        code: `import java.util.*;

                        class Graph {
                          class Edge implements Comparable<Edge>
                          {
                            int src, dest, weight;
                        
                            public int compareTo(Edge compareEdge)
                            {
                              return this.weight - compareEdge.weight;
                            }
                          };
                        
                          class subset
                          {
                            int parent, rank;
                          };
                        
                          int V, E; 
                          Edge edge[]; 
                        
                          Graph(int v, int e)
                          {
                            V = v;
                            E = e;
                            edge = new Edge[E];
                            for (int i = 0; i < e; ++i)
                              edge[i] = new Edge();
                          }
                        
                          int find(subset subsets[], int i)
                          {
                            if (subsets[i].parent != i)
                              subsets[i].parent
                                = find(subsets, subsets[i].parent);
                        
                            return subsets[i].parent;
                          }
                        
                          void Union(subset subsets[], int x, int y)
                          {
                            int xroot = find(subsets, x);
                            int yroot = find(subsets, y);
                        
                            if (subsets[xroot].rank
                              < subsets[yroot].rank)
                              subsets[xroot].parent = yroot;
                            else if (subsets[xroot].rank
                                > subsets[yroot].rank)
                              subsets[yroot].parent = xroot;
                        
                            else {
                              subsets[yroot].parent = xroot;
                              subsets[xroot].rank++;
                            }
                          }
                        
                          void KruskalMST()
                          {
                            Edge result[] = new Edge[V];
                          
                            int e = 0;
                          
                            int i = 0;
                            for (i = 0; i < V; ++i)
                              result[i] = new Edge();
                        
                            Arrays.sort(edge);
                        
                            subset subsets[] = new subset[V];
                            for (i = 0; i < V; ++i)
                              subsets[i] = new subset();
                        
                            for (int v = 0; v < V; ++v)
                            {
                              subsets[v].parent = v;
                              subsets[v].rank = 0;
                            }
                        
                            i = 0; 
                        
                            while (e < V - 1)
                            {
                              Edge next_edge = edge[i++];
                        
                              int x = find(subsets, next_edge.src);
                              int y = find(subsets, next_edge.dest);
                        
                              if (x != y) {
                                result[e++] = next_edge;
                                Union(subsets, x, y);
                              }
                            }
                        
                            System.out.println("Following are the edges in "
                                    + "the constructed MST");
                            int minimumCost = 0;
                            for (i = 0; i < e; ++i)
                            {
                              System.out.println(result[i].src + " -- "
                                      + result[i].dest
                                      + " == " + result[i].weight);
                              minimumCost += result[i].weight;
                            }
                            System.out.println("Minimum Cost Spanning Tree "
                                    + minimumCost);
                          }
                        
                          public static void main(String[] args)
                          {
                            int V = 4;
                            int E = 5;
                            Graph graph = new Graph(V, E);
                            graph.edge[0].src = 0;
                            graph.edge[0].dest = 1;
                            graph.edge[0].weight = 10;
                            graph.edge[1].src = 0;
                            graph.edge[1].dest = 2;
                            graph.edge[1].weight = 6;
                            graph.edge[2].src = 0;
                            graph.edge[2].dest = 3;
                            graph.edge[2].weight = 5;
                            graph.edge[3].src = 1;
                            graph.edge[3].dest = 3;
                            graph.edge[3].weight = 15;
                            graph.edge[4].src = 2;
                            graph.edge[4].dest = 3;
                            graph.edge[4].weight = 4;
                        
                            graph.KruskalMST();
                          }
                        }
                        `,
                      },
                      Python: {
                        code: `from collections import defaultdict

class Graph:

  def __init__(self, vertices):
    self.V = vertices 
    self.graph = [] 

  def addEdge(self, u, v, w):
    self.graph.append([u, v, w])

  def find(self, parent, i):
    if parent[i] == i:
      return i
    return self.find(parent, parent[i])

  def union(self, parent, rank, x, y):
    xroot = self.find(parent, x)
    yroot = self.find(parent, y)

    if rank[xroot] < rank[yroot]:
      parent[xroot] = yroot
    elif rank[xroot] > rank[yroot]:
      parent[yroot] = xroot

    else:
      parent[yroot] = xroot
      rank[xroot] += 1

  def KruskalMST(self):

    result = []
    
    i = 0
    
    e = 0

    self.graph = sorted(self.graph,
              key=lambda item: item[2])

    parent = []
    rank = []

    for node in range(self.V):
      parent.append(node)
      rank.append(0)

    while e < self.V - 1:

      u, v, w = self.graph[i]
      i = i + 1
      x = self.find(parent, u)
      y = self.find(parent, v)

      if x != y:
        e = e + 1
        result.append([u, v, w])
        self.union(parent, rank, x, y)

    minimumCost = 0
    print ("Edges in the constructed MST")
    for u, v, weight in result:
      minimumCost += weight
      print("%d -- %d == %d" % (u, v, weight))
    print("Minimum Spanning Tree" , minimumCost)

g = Graph(4)
g.addEdge(0, 1, 10)
g.addEdge(0, 2, 6)
g.addEdge(0, 3, 5)
g.addEdge(1, 3, 15)
g.addEdge(2, 3, 4)

g.KruskalMST()
                        
                        `,
                      },
                      "C++": {
                        code: `#include <bits/stdc++.h>
                        using namespace std;
                        class Edge {
                        public:
                        int src, dest, weight;
                        };
                        class Graph {
                        public:
                        int V, E;
                        Edge* edge;
                        };
                        Graph* createGraph(int V, int E)
                        {
                        Graph* graph = new Graph;
                        graph->V = V;
                        graph->E = E;
                        graph->edge = new Edge[E];
                        return graph;
                        }
                        class subset {
                        public:
                        int parent;
                        int rank;
                        };
                        int find(subset subsets[], int i)
                        {
                        if (subsets[i].parent!= i)
                        subsets[i].parent
                        = find(subsets, subsets[i].parent);
                        return subsets[i].parent;
                        }
                        void Union(subset subsets[], int x, int y)
                        {
                        int xroot = find(subsets, x);
                        int yroot = find(subsets, y);
                        if (subsets[xroot].rank < subsets[yroot].rank)
                        subsets[xroot].parent = yroot;
                        else if (subsets[xroot].rank > subsets[yroot].rank)
                        subsets[yroot].parent = xroot;
                        else {
                        subsets[yroot].parent = xroot;
                        subsets[xroot].rank++;
                        }
                        }
                        int myComp(const void* a, const void* b)
                        {
                        Edge* a1 = (Edge*)a;
                        Edge* b1 = (Edge*)b;
                        return a1->weight > b1->weight;
                        }
                        void KruskalMST(Graph* graph)
                        {
                        int V = graph->V;
                        Edge result[V];
                        int e = 0;
                        int i = 0;
                        qsort(graph->edge, graph->E, sizeof(graph->edge[0]),
                        myComp);
                        subset* subsets = new subset[(V * sizeof(subset))];
                        for (int v = 0; v < V; ++v)
                        {
                        subsets[v].parent = v;
                        subsets[v].rank = 0;
                        }
                        while (e < V-1 && i < graph->E)
                        {
                        Edge next_edge = graph->edge[i++];
                        int x = find(subsets, next_edge.src);
                        int y = find(subsets, next_edge.dest);
                        if (x!= y) {
                        result[e++] = next_edge;
                        Union(subsets, x, y);
                        }
                        }
                        cout << "Following are the edges in the constructed "
                        "MST\n";
                        int minimumCost = 0;
                        for (i = 0; i < e; ++i)
                        {
                        cout << result[i].src << "-" << result[i].dest
                        << " == " << result[i].weight << endl;
                        minimumCost = minimumCost + result[i].weight;
                        }
                        cout << "Minimum Cost Spanning Tree: " << minimumCost
                        << endl;
                        }
                        int main()
                        {
                        int V = 4;
                        int E = 5;
                        Graph* graph = createGraph(V, E);
                        graph->edge[0].src = 0;
                        graph->edge[0].dest = 1;
                        graph->edge[0].weight = 10;
                        graph->edge[1].src = 0;
                        graph->edge[1].dest = 2;
                        graph->edge[1].weight = 6;
                        graph->edge[2].src = 0;
                        graph->edge[2].dest = 3;
                        graph->edge[2].weight = 5;
                        graph->edge[3].src = 1;
                        graph->edge[3].dest = 3;
                        graph->edge[3].weight = 15;
                        graph->edge[4].src = 2;
                        graph->edge[4].dest = 3;
                        graph->edge[4].weight = 4;
                        KruskalMST(graph);
                        return 0;
                        }`,
                      },
                      Kotlin: {
                        code: `import java.util.*;
import java.lang.*;
import java.io.*;

class Graph {
	class Edge implements Comparable<Edge>
	{
		int src, dest, weight;

		public int compareTo(Edge compareEdge)
		{
			return this.weight - compareEdge.weight;
		}
	};

	class subset
	{
		int parent, rank;
	};

	int V, E; 
	Edge edge[]; 

	Graph(int v, int e)
	{
		V = v;
		E = e;
		edge = new Edge[E];
		for (int i = 0; i < e; ++i)
			edge[i] = new Edge();
	}

	int find(subset subsets[], int i)
	{
		if (subsets[i].parent != i)
			subsets[i].parent
				= find(subsets, subsets[i].parent);

		return subsets[i].parent;
	}

	void Union(subset subsets[], int x, int y)
	{
		int xroot = find(subsets, x);
		int yroot = find(subsets, y);

		if (subsets[xroot].rank
			< subsets[yroot].rank)
			subsets[xroot].parent = yroot;
		else if (subsets[xroot].rank
				> subsets[yroot].rank)
			subsets[yroot].parent = xroot;

		else {
			subsets[yroot].parent = xroot;
			subsets[xroot].rank++;
		}
	}

	void KruskalMST()
	{
		Edge result[] = new Edge[V];
	
		int e = 0;
	
		int i = 0;
		for (i = 0; i < V; ++i)
			result[i] = new Edge();

		Arrays.sort(edge);

		subset subsets[] = new subset[V];
		for (i = 0; i < V; ++i)
			subsets[i] = new subset();

		for (int v = 0; v < V; ++v)
		{
			subsets[v].parent = v;
			subsets[v].rank = 0;
		}

		i = 0; 

		while (e < V - 1)
		{
			Edge next_edge = edge[i++];

			int x = find(subsets, next_edge.src);
			int y = find(subsets, next_edge.dest);

			if (x != y) {
				result[e++] = next_edge;
				Union(subsets, x, y);
			}
		}

		System.out.println("Following are the edges in "
						+ "the constructed MST");
		int minimumCost = 0;
		for (i = 0; i < e; ++i)
		{
			System.out.println(result[i].src + " -- "
							+ result[i].dest
							+ " == " + result[i].weight);
			minimumCost += result[i].weight;
		}
		System.out.println("Minimum Cost Of Spanning Tree "
						+ minimumCost);
	}

	public static void main(String[] args)
	{
		int V = 4;
		int E = 5;
		Graph graph = new Graph(V, E);
		graph.edge[0].src = 0;
		graph.edge[0].dest = 1;
		graph.edge[0].weight = 10;
		graph.edge[1].src = 0;
		graph.edge[1].dest = 2;
		graph.edge[1].weight = 6;
		graph.edge[2].src = 0;
		graph.edge[2].dest = 3;
		graph.edge[2].weight = 5;
		graph.edge[3].src = 1;
		graph.edge[3].dest = 3;
		graph.edge[3].weight = 15;
		graph.edge[4].src = 2;
		graph.edge[4].dest = 3;
		graph.edge[4].weight = 4;

		graph.KruskalMST();
	}
}
`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Prim's",
            content: (
              <>
                <Span>
                  <b> Problem Statement :</b>
                </Span>
                <Span>
                  Construct an MST with the help of edge with minimum weight
                  among all adjacent edges which is not picked yet.
                </Span>
                <Span>
                  <b>Note :</b>
                  <br />
                  Prim's is slower tham kruskal. <br />
                  Time Complexity : O(ElogE) or (ElogV)
                </Span>
                <CodeEditor
                  options={{
                    title: "Prim's",
                    codes: {
                      // Javascript:{code:``},
                      Java: {
                        code: `import java.util.*;
                      import java.lang.*;
                      import java.io.*;
                      
                      class Main {
                      private static final int V = 5;
                      int minKey(int key[], Boolean mstSet[])
                      {
                      int min = Integer.MAX_VALUE, min_index = -1;
                      for (int v = 0; v < V; v++)
                      if (mstSet[v] == false && key[v] < min) {
                      min = key[v];
                      min_index = v;
                      }
                      return min_index;
                      }
                      
                      void printMST(int parent[], int graph[][])
                      {
                      System.out.println("Edge \tWeight");
                      for (int i = 1; i < V; i++)
                      System.out.println(parent[i] + " — " + i + "\t" + graph[i][parent[i]]);
                      }
                      
                      void primMST(int graph[][])
                      {
                      int parent[] = new int[V];
                      int key[] = new int[V];
                      Boolean mstSet[] = new Boolean[V];
                      for (int i = 0; i < V; i++) {
                      key[i] = Integer.MAX_VALUE;
                      mstSet[i] = false;
                      }
                      key[0] = 0;
                      parent[0] = -1;
                      for (int count = 0;count < V - 1;count++){
                      int u = minKey(key, mstSet);
                      mstSet[u] = true;
                      for (int v = 0; v < V; v++)
                      if (graph[u][v] != 0 && mstSet[v] == false && graph[u][v] < key[v]) {
                      parent[v] = u;
                      key[v] = graph[u][v];
                      }
                      
                      }
                      printMST(parent, graph);
                      }
                      public static void main(String[] args)
                      {
                      Main t = new Main();
                      int graph[][] = new int[][] { { 0, 2, 0, 6, 0 },
                      { 2, 0, 3, 8, 5 },
                      { 0, 3, 0, 0, 7 },
                      { 6, 8, 0, 0, 9 },
                      { 0, 5, 7, 9, 0 } };
                      t.primMST(graph);
                      }
                      }
                      `,
                      },
                      Python: {
                        code: `import sys 

class Graph():

  def __init__(self, vertices):
    self.V = vertices
    self.graph = [[0 for column in range(vertices)]
          for row in range(vertices)]

  def printMST(self, parent):
    print("Edge \tWeight")
    for i in range(1, self.V):
      print(parent[i], "-", i, "\t", self.graph[i][ parent[i] ])

  def minKey(self, key, mstSet):

    min = sys.maxint

    for v in range(self.V):
      if key[v] < min and mstSet[v] == False:
        min = key[v]
        min_index = v

    return min_index

  def primMST(self):
    key = [sys.maxint] * self.V
    parent = [None] * self.V 
    key[0] = 0
    mstSet = [False] * self.V

    parent[0] = -1 

    for cout in range(self.V):

      u = self.minKey(key, mstSet)
      mstSet[u] = True
      for v in range(self.V):
        if self.graph[u][v] > 0 and mstSet[v] == False and key[v] > self.graph[u][v]:
            key[v] = self.graph[u][v]
            parent[v] = u

    self.printMST(parent)

g = Graph(5)
g.graph = [ [0, 2, 0, 6, 0],
      [2, 0, 3, 8, 5],
      [0, 3, 0, 0, 7],
      [6, 8, 0, 0, 9],
      [0, 5, 7, 9, 0]]

g.primMST()`,
                      },
                      "C++": {
                        code: `#include <bits/stdc++.h>
                      using namespace std;
                      
                      #define V 5
                      
                      int minKey(int key[], bool mstSet[])
                      {
                        int min = INT_MAX, min_index;
                      
                        for (int v = 0; v < V; v++)
                          if (mstSet[v] == false && key[v] < min)
                            min = key[v], min_index = v;
                      
                        return min_index;
                      }
                      
                      void printMST(int parent[], int graph[V][V])
                      {
                        cout<<"Edge \tWeight\n";
                        for (int i = 1; i < V; i++)
                          cout<<parent[i]<<" - "<<i<<" \t"<<graph[i][parent[i]]<<" \n";
                      }
                      
                      
                      void primMST(int graph[V][V])
                      {
                        int parent[V];
                        
                        int key[V];
                        
                        bool mstSet[V];
                      
                        for (int i = 0; i < V; i++)
                          key[i] = INT_MAX, mstSet[i] = false;
                        key[0] = 0;
                        parent[0] = -1;
                      
                        for (int count = 0; count < V - 1; count++)
                        {
                          int u = minKey(key, mstSet);
                      
                          mstSet[u] = true;
                      
                          for (int v = 0; v < V; v++)
                      
                            if (graph[u][v] && mstSet[v] == false && graph[u][v] < key[v])
                              parent[v] = u, key[v] = graph[u][v];
                        }
                      
                        printMST(parent, graph);
                      }
                      
                      int main()
                      {
                        int graph[V][V] = { { 0, 2, 0, 6, 0 },
                                  { 2, 0, 3, 8, 5 },
                                  { 0, 3, 0, 0, 7 },
                                  { 6, 8, 0, 0, 9 },
                                  { 0, 5, 7, 9, 0 } };
                      
                        primMST(graph);
                      
                        return 0;
                      }
                      `,
                      },
                      Kotlin: {
                        code: `import java.util.*
                      import java.lang.*
                      import java.io.*
                      
                      internal class MST {
                      
                          fun minKey(key: IntArray, mstSet: Array<Boolean>): Int {
                              var min: Int = Integer.MAX_VALUE
                              var min_index = -1
                              for (v in 0 until V) if (mstSet[v] == false && key[v] < min) {
                                  min = key[v]
                                  min_index = v
                              }
                              return min_index
                          }
                      
                          fun printMST(parent: IntArray, graph: Array<IntArray>) {
                              System.out.println("Edge \tWeight")
                              for (i in 1 until V) System.out.println(parent[i].toString() + " - " + i + "\t" + graph[i][parent[i]])
                          }
                          
                          fun primMST(graph: Array<IntArray>) {
                              val parent = IntArray(V)
                      
                              val key = IntArray(V)
                      
                              val mstSet: Array<Boolean> = arrayOfNulls(V)
                      
                              for (i in 0 until V) {
                                  key[i] = Integer.MAX_VALUE
                                  mstSet[i] = false
                              }
                      
                              key[0] = 0 
                              parent[0] = -1 
                      
                              for (count in 0 until V - 1) {
                                  val u = minKey(key, mstSet)
                      
                                  mstSet[u] = true
                      
                                  for (v in 0 until V)  
                                      if (graph[u][v] != 0 && mstSet[v] == false && graph[u][v] < key[v]) {
                                          parent[v] = u
                                          key[v] = graph[u][v]
                                      }
                              }
                      
                              printMST(parent, graph)
                          }
                      
                          companion object {
                              private const val V = 5
                              fun main(args: Array<String?>?) {
                                  val t = MST()
                                  val graph = arrayOf(
                                      intArrayOf(0, 2, 0, 6, 0),
                                      intArrayOf(2, 0, 3, 8, 5),
                                      intArrayOf(0, 3, 0, 0, 7),
                                      intArrayOf(6, 8, 0, 0, 9),
                                      intArrayOf(0, 5, 7, 9, 0)
                                  )
                      
                                  t.primMST(graph)
                              }
                          }
                      }`,
                      },
                    },
                    output: `Edge    Weight
                    0 — 1   2
                    1 — 2   3
                    0 — 3   6
                    1 — 4   5`,
                  }}
                />
              </>
            ),
          },
          {
            title: "Dijkstra's",
            content: (
              <>
                <Span>
                  <b>Problem Statement:</b>
                </Span>
                <Span>
                  Dijkstra's algorithm is very similar to prim's algorithm. Like
                  Prim's MST, we generate a SPT (shortest path tree) with a
                  given source as a root. We maintain two sets, one set contains
                  vertices included in the shortest-path tree, other set
                  includes vertices not yet included in the shortest-path tree.
                  At every step of the algorithm, we find a vertex that is in
                  the other set (set of not yet included) and has a minimum
                  distance from the source.
                </Span>
                <CodeEditor
                  options={{
                    title: "Dijkstra's",
                    codes: {
                      Javascript: {
                        code: `function minDistance(dist,sptSet)
                        {
                          
                            let min = Number.MAX_VALUE;
                          let min_index = -1;
                          
                          for(let v = 0; v < V; v++)
                          {
                            if (sptSet[v] == false && dist[v] <= min)
                            {
                              min = dist[v];
                              min_index = v;
                            }
                          }
                          return min_index;
                        }
                        
                        function printSolution(dist)
                        {
                          document.write("Vertex \t\t Distance from Source<br>");
                          for(let i = 0; i < V; i++)
                          {
                            document.write(i + " \t\t " +
                                dist[i] + "<br>");
                          }
                        }
                        
                        function dijkstra(graph, src)
                        {
                          let dist = new Array(V);
                          let sptSet = new Array(V);
                          
                          for(let i = 0; i < V; i++)
                          {
                            dist[i] = Number.MAX_VALUE;
                            sptSet[i] = false;
                          }
                          
                          dist[src] = 0;
                          
                            for(let count = 0; count < V - 1; count++)
                          {
                            
                            let u = minDistance(dist, sptSet);
                            
                            sptSet[u] = true;
                            
                            for(let v = 0; v < V; v++)
                            {
                              
                              if (!sptSet[v] && graph[u][v] != 0 &&
                                dist[u] != Number.MAX_VALUE &&
                                dist[u] + graph[u][v] < dist[v])
                              {
                                dist[v] = dist[u] + graph[u][v];
                              }
                            }
                          }
                            printSolution(dist);
                        }
                        
                        let graph = [ [ 0, 4, 0, 0, 0, 0, 0, 8, 0 ],
                              [ 4, 0, 8, 0, 0, 0, 0, 11, 0 ],
                              [ 0, 8, 0, 7, 0, 4, 0, 0, 2 ],
                              [ 0, 0, 7, 0, 9, 14, 0, 0, 0],
                              [ 0, 0, 0, 9, 0, 10, 0, 0, 0 ],
                              [ 0, 0, 4, 14, 10, 0, 2, 0, 0],
                              [ 0, 0, 0, 0, 0, 2, 0, 1, 6 ],
                              [ 8, 11, 0, 0, 0, 0, 1, 0, 7 ],
                              [ 0, 0, 2, 0, 0, 0, 6, 7, 0 ] ]
                        dijkstra(graph, 0);
                        `,
                      },
                      Java: {
                        code: `import java.util.*;
                        import java.lang.*;
                        import java.io.*;
                        
                        class Main {
                        static final int V = 9;
                        int minDistance(int dist[], Boolean sptSet[])
                        {
                        int min = Integer.MAX_VALUE, min_index = -1;
                        for (int v = 0; v < V; v++)
                        if (sptSet[v] == false && dist[v] <= min) {
                        min = dist[v];
                        min_index = v;
                        }
                        return min_index;
                        }
                        void printSolution(int dist[])
                        {
                        System.out.println("Vertex \t\t Distance from Source");
                        for (int i = 0; i < V; i++)
                        System.out.println(i + " \t\t " + dist[i]);
                        }
                        void dijkstra(int graph[][], int src)
                        {
                        int dist[] = new int[V];
                        Boolean sptSet[] = new Boolean[V];
                        for (int i = 0; i < V; i++) {
                        dist[i] = Integer.MAX_VALUE;
                        sptSet[i] = false;
                        }
                        dist[src] = 0;
                        for (int count = 0; count < V - 1; count++) {
                        int u = minDistance(dist, sptSet);
                        sptSet[u] = true;
                        for (int v = 0; v < V; v++)
                        if (!sptSet[v] && graph[u][v] != 0 && dist[u] != Integer.MAX_VALUE && dist[u] + graph[u][v] < dist[v])
                        dist[v] = dist[u] + graph[u][v];
                        }
                        printSolution(dist);
                        }
                        public static void main(String[] args)
                        {
                        int graph[][] = new int[][] { { 0, 4, 0, 0, 0, 0, 0, 8, 0 },
                        { 4, 0, 8, 0, 0, 0, 0, 11, 0 },
                        { 0, 8, 0, 7, 0, 4, 0, 0, 2 },
                        { 0, 0, 7, 0, 9, 14, 0, 0, 0 },
                        { 0, 0, 0, 9, 0, 10, 0, 0, 0 },
                        { 0, 0, 4, 14, 10, 0, 2, 0, 0 },
                        { 0, 0, 0, 0, 0, 2, 0, 1, 6 },
                        { 8, 11, 0, 0, 0, 0, 1, 0, 7 },
                        { 0, 0, 2, 0, 0, 0, 6, 7, 0 } };
                        Main t = new Main();
                        t.dijkstra(graph, 0);
                        }
                        }`,
                      },
                      Python: {
                        code: `import sys

class Graph():

  def __init__(self, vertices):
    self.V = vertices
    self.graph = [[0 for column in range(vertices)]
          for row in range(vertices)]

  def printSolution(self, dist):
    print("Vertex \tDistance from Source")
    for node in range(self.V):
      print(node+ "\t"+ dist[node])

  def minDistance(self, dist, sptSet):

    min = sys.maxint

    for v in range(self.V):
      if dist[v] < min and sptSet[v] == False:
        min = dist[v]
        min_index = v

    return min_index

  def dijkstra(self, src):

    dist = [sys.maxint] * self.V
    dist[src] = 0
    sptSet = [False] * self.V

    for cout in range(self.V):
      u = self.minDistance(dist, sptSet)
      sptSet[u] = True

      for v in range(self.V):
        if self.graph[u][v] > 0 and sptSet[v] == False and \
        dist[v] > dist[u] + self.graph[u][v]:
            dist[v] = dist[u] + self.graph[u][v]

    self.printSolution(dist)

g = Graph(9)
g.graph = [[0, 4, 0, 0, 0, 0, 0, 8, 0],
    [4, 0, 8, 0, 0, 0, 0, 11, 0],
    [0, 8, 0, 7, 0, 4, 0, 0, 2],
    [0, 0, 7, 0, 9, 14, 0, 0, 0],
    [0, 0, 0, 9, 0, 10, 0, 0, 0],
    [0, 0, 4, 14, 10, 0, 2, 0, 0],
    [0, 0, 0, 0, 0, 2, 0, 1, 6],
    [8, 11, 0, 0, 0, 0, 1, 0, 7],
    [0, 0, 2, 0, 0, 0, 6, 7, 0]
    ]

g.dijkstra(0)`,
                      },
                      "C++": {
                        code: `#include <iostream>
                          using namespace std;
                          #include <limits.h>
                          #define V 9
                          
                          int minDistance(int dist[], bool sptSet[])
                          {
                            int min = INT_MAX, min_index;
                          
                            for (int v = 0; v < V; v++)
                              if (sptSet[v] == false && dist[v] <= min)
                                min = dist[v], min_index = v;
                          
                            return min_index;
                          }
                          
                          void printSolution(int dist[])
                          {
                            cout <<"Vertex \t Distance from Source" << endl;
                            for (int i = 0; i < V; i++)
                              cout << i << " \t\t"<<dist[i]<< endl;
                          }
                          
                          void dijkstra(int graph[V][V], int src)
                          {
                            int dist[V];
                          
                            bool sptSet[V]; 
                            for (int i = 0; i < V; i++)
                              dist[i] = INT_MAX, sptSet[i] = false;
                          
                            dist[src] = 0;
                            for (int count = 0; count < V - 1; count++) {
                              int u = minDistance(dist, sptSet);
                          
                              sptSet[u] = true;
                          
                              for (int v = 0; v < V; v++)
                          
                                if (!sptSet[v] && graph[u][v] && dist[u] != INT_MAX
                                  && dist[u] + graph[u][v] < dist[v])
                                  dist[v] = dist[u] + graph[u][v];
                            }
                          
                            printSolution(dist);
                          }
                          
                          int main()
                          {
                          
                            int graph[V][V] = { { 0, 4, 0, 0, 0, 0, 0, 8, 0 },
                                      { 4, 0, 8, 0, 0, 0, 0, 11, 0 },
                                      { 0, 8, 0, 7, 0, 4, 0, 0, 2 },
                                      { 0, 0, 7, 0, 9, 14, 0, 0, 0 },
                                      { 0, 0, 0, 9, 0, 10, 0, 0, 0 },
                                      { 0, 0, 4, 14, 10, 0, 2, 0, 0 },
                                      { 0, 0, 0, 0, 0, 2, 0, 1, 6 },
                                      { 8, 11, 0, 0, 0, 0, 1, 0, 7 },
                                      { 0, 0, 2, 0, 0, 0, 6, 7, 0 } };
                          
                            dijkstra(graph, 0);
                          
                            return 0;
                          }`,
                      },
                      Kotlin: {
                        code: `import java.util.*
                          import java.lang.*
                          import java.io.*
                          
                          internal class ShortestPath {
                              fun minDistance(dist: IntArray, sptSet: Array<Boolean>): Int {
                                  var min: Int = Integer.MAX_VALUE
                                  var min_index = -1
                                  for (v in 0 until V) if (sptSet[v] == false && dist[v] <= min) {
                                      min = dist[v]
                                      min_index = v
                                  }
                                  return min_index
                              }
                          
                              fun printSolution(dist: IntArray) {
                                  System.out.println("Vertex \t\t Distance from Source")
                                  for (i in 0 until V) System.out.println(i.toString() + " \t\t " + dist[i])
                              }
                          
                              fun dijkstra(graph: Array<IntArray>, src: Int) {
                                  val dist = IntArray(V)
                                  val sptSet: Array<Boolean> = arrayOfNulls(V)
                                  for (i in 0 until V) {
                                      dist[i] = Integer.MAX_VALUE
                                      sptSet[i] = false
                                  }
                                  dist[src] = 0
                                  for (count in 0 until V - 1) {
                                      val u = minDistance(dist, sptSet)
                                      sptSet[u] = true
                                      for (v in 0 until V) if (!sptSet[v] && graph[u][v] != 0 && dist[u] != Integer.MAX_VALUE && dist[u] + graph[u][v] < dist[v]) dist[v] =
                                          dist[u] + graph[u][v]
                                  }
                                  printSolution(dist)
                              }
                          
                              companion object {
                                  const val V = 9
                                  fun main(args: Array<String?>?) {
                                      val graph = arrayOf(
                                          intArrayOf(0, 4, 0, 0, 0, 0, 0, 8, 0),
                                          intArrayOf(4, 0, 8, 0, 0, 0, 0, 11, 0),
                                          intArrayOf(0, 8, 0, 7, 0, 4, 0, 0, 2),
                                          intArrayOf(0, 0, 7, 0, 9, 14, 0, 0, 0),
                                          intArrayOf(0, 0, 0, 9, 0, 10, 0, 0, 0),
                                          intArrayOf(0, 0, 4, 14, 10, 0, 2, 0, 0),
                                          intArrayOf(0, 0, 0, 0, 0, 2, 0, 1, 6),
                                          intArrayOf(8, 11, 0, 0, 0, 0, 1, 0, 7),
                                          intArrayOf(0, 0, 2, 0, 0, 0, 6, 7, 0)
                                      )
                                      val t = ShortestPath()
                                      t.dijkstra(graph, 0)
                                  }
                              }
                          }`,
                      },
                    },
                    output: `Vertex       Distance from Source
                    0        0
                    1        4
                    2        12
                    3        19
                    4        21
                    5        11
                    6        9
                    7        8
                    8        14`,
                  }}
                />
              </>
            ),
          },
          {
            title: "Dial's",
            content: (
              <>
                <Span>
                  Many a times the range of weights on edges in is in small
                  range (i.e. all edge weight can be mapped to 0, 1, 2.. w where
                  w is a small number).
                </Span>
                <Span>
                  In that case, Dijkstra's algorithm can be modified by using
                  different data structure, buckets, which is called dial
                  implementation of dijkstra's algorithm. time complexity is O(E
                  + WV) where W is maximum weight on any edge of graph, so we
                  can see that, if W is small then this implementation runs much
                  faster than traditional algorithm. Following are important
                  observations. Maximum distance between any two node can be at
                  max w(V — 1) (w is maximum edge weight and we can have at max
                  V-1 edges between two vertices). In Dijkstra algorithm,
                  distances are finalized in non-decreasing, i.e., distance of
                  the closer (to given source) vertices is finalized before the
                  distant vertices.
                </Span>
              </>
            ),
          },
          {
            title: "Minimum Number Of Coins",
            content: (
              <>
                <Span>
                  <b>Problem Statemnt</b>
                </Span>
                <Span>
                  Calculate the minimum number of coins required , whose
                  summation will be equal to the given input with the help of
                  sorted array provided.
                </Span>
                <Span>
                  <b> Example</b>
                </Span>
                <p>
                  [1,2,5,10,20,50,100,500,1000]
                  <br />
                  Input Value: 70
                  <br />
                  Output: 2<br />
                  (50 + 20)
                  <br />
                  Input value: 121
                  <br />
                  Output: 3<br />
                  (100 + 20 + 1)
                </p>
                <CodeEditor
                  options={{
                    title: "Minimum Number Of Coins",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `let deno=[1, 2, 5, 10, 20,
                          50, 100, 500, 1000];
                      let n = deno.length;
                       
                      function findMin(V)
                      {
                              let ans = [];
                        
                              for (let i = n - 1; i >= 0; i--)
                              {
                                  while (V >= deno[i])
                                  {
                                      V -= deno[i];
                                      ans.push(deno[i]);
                                  }
                              }
                        
                              for (let i = 0; i < ans.length; i++)
                              {
                                  console.log(
                                      " " + ans[i]);
                              }
                      }
                       
                      n = 93;
                      console.log(
                      "Following is minimal number "
                      +"of change for " + n + ": ");
                      findMin(n);`,
                        output: `Following is minimal number of change for 93: 
                        50
                        20
                        20
                        2
                        1`,
                      },
                      Java: {
                        code: `
                       
import java.util.Vector;

class Main
{

	static int deno[] = {1, 2, 5, 10, 20,
	50, 100, 500, 1000};
	static int n = deno.length;

	static void findMin(int V)
	{
		Vector<Integer> ans = new Vector<>();

		for (int i = n - 1; i >= 0; i--)
		{
			while (V >= deno[i])
			{
				V -= deno[i];
				ans.add(deno[i]);
			}
		}

		for (int i = 0; i < ans.size(); i++)
		{
			System.out.print(
				" " + ans.elementAt(i));
		}
	}
	public static void main(String[] args)
	{
		int n = 93;
		System.out.print(
			"Following is minimal number "
			+"of change for " + n + ": ");
		findMin(n);
	}
}
                        `,
                        output: `Following is minimal number of change for 93:  50 20 20 2 1`,
                      },
                      Python: {
                        code: `def findMin(V):
  deno = [1, 2, 5, 10, 20, 50,
      100, 500, 1000]
  n = len(deno)
  ans = []

  i = n - 1
  while(i >= 0):
    while (V >= deno[i]):
      V -= deno[i]
      ans.append(deno[i])

    i -= 1

  for i in range(len(ans)):
    print(ans[i], end = " ")

if __name__ == '__main__':
  n = 93
  print("Following is minimal number",
    "of change for", n, ": ", end = "")
  findMin(n)
                      `,
                        output: `Following is minimal number of change for 93 : 50 20 20 2 1`,
                      },
                      "C++": {
                        code: `#include <bits/stdc++.h>
                        using namespace std;
                         
                        int deno[] = { 1, 2, 5, 10, 20,
                                       50, 100, 500, 1000 };
                        int n = sizeof(deno) / sizeof(deno[0]);
                         
                        void findMin(int V)
                        {
                            sort(deno, deno + n);
                         
                            vector<int> ans;
                         
                            for (int i = n - 1; i >= 0; i--) {
                                 while (V >= deno[i]) {
                                    V -= deno[i];
                                    ans.push_back(deno[i]);
                                }
                            }
                         
                            for (int i = 0; i < ans.size(); i++)
                                cout << ans[i] << " ";
                        }
                         
                        int main()
                        {
                            int n = 93;
                            cout << "Following is minimal"
                                 << " number of change for " << n
                                 << ": ";
                            findMin(n);
                            return 0;
                        }`,
                        output: `Following is minimal number of change for 93: 50 20 20 2 1`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Maximum Equal Sum Possible With Three Stacks",
            content: (
              <>
                <Span>
                  <b>Problem Statement:</b>
                </Span>
                <Span>
                  Given three stacks of the positive numbers, the task is to
                  find the possible equal maximum sum of the stacks with the
                  removal of top elements allowed.
                </Span>
                <Span>
                  <b>Greedy Approach</b>
                </Span>
                <Span>
                  1. Find the sum of all elements of in individual stacks.
                  <br />
                  2. If the sum of all three stacks is the same, then this is
                  the maximum sum. <br />
                  3. Else remove the top element of the stack having the maximum
                  sum among three of stacks. Repeat step 1 and step 2.
                </Span>
                <Span>
                  <b>Example</b>
                </Span>
                <p>
                  i/p :
                  <br />
                  stack1 [2,3]
                  <br />
                  stack 2 [4,10]
                  <br />
                  stack 3 [1,9]
                  <br />
                  o/p : 0
                </p>
                <CodeEditor
                  options={{
                    title: "Maximum Equal Sum Possible With Three Stacks",
                    output: null,
                    codes: {
                      Javascript: {
                        code: `function maxSum(stack1, stack2,
                          stack3, n1, n2, n3)
                      {
                      let sum1 = 0, sum2 = 0, sum3 = 0;
                      
                      for(let i = 0; i < n1; i++)
                      sum1 += stack1[i];
                      
                      for(let i = 0; i < n2; i++)
                      sum2 += stack2[i];
                      
                      for(let i = 0; i < n3; i++)
                      sum3 += stack3[i];
                      
                      let top1 = 0, top2 = 0, top3 = 0;
                      let ans = 0;
                      
                      while (true)
                      {
                      if (top1 == n1 || top2 == n2 ||
                      top3 == n3)
                      return 0;
                       
                      if (sum1 == sum2 && sum2 == sum3)
                      return sum1;
                      
                      if (sum1 >= sum2 && sum1 >= sum3)
                      sum1 -= stack1[top1++];
                      else if (sum2 >= sum1 && sum2 >= sum3)
                      sum2 -= stack2[top2++];
                      else if (sum3 >= sum2 && sum3 >= sum1)
                      sum3 -= stack3[top3++];
                      }
                      }
                      
                      let stack1 = [ 3, 2, 1, 1, 1 ];
                      let stack2 = [ 4, 3, 2 ];
                      let stack3 = [ 1, 1, 4, 1 ];
                      
                      let n1 = stack1.length;
                      let n2 = stack2.length;
                      let n3 = stack3.length;
                      
                      console.log(maxSum(stack1, stack2,
                                stack3, n1, n2, n3));`,
                        output: `5`,
                      },
                      Java: {
                        code: `class Main {
	
                          public static int maxSum(int stack1[], int stack2[],
                                      int stack3[], int n1, int n2,
                                              int n3)
                          {
                          int sum1 = 0, sum2 = 0, sum3 = 0;
                          
                          for (int i=0; i < n1; i++)
                            sum1 += stack1[i];
                          
                          for (int i=0; i < n2; i++)
                            sum2 += stack2[i];
                          
                          for (int i=0; i < n3; i++)
                            sum3 += stack3[i];
                          
                          int top1 =0, top2 = 0, top3 = 0;
                          int ans = 0;
                          while (true)
                          {
                            if (top1 == n1 || top2 == n2 || top3 == n3)
                              return 0;
                          
                            if (sum1 == sum2 && sum2 == sum3)
                              return sum1;
                            
                            if (sum1 >= sum2 && sum1 >= sum3)
                              sum1 -= stack1[top1++];
                            else if (sum2 >= sum1 && sum2 >= sum3)
                              sum2 -= stack2[top2++];
                            else if (sum3 >= sum2 && sum3 >= sum1)
                              sum3 -= stack3[top3++];
                          }
                          }
                          
                          public static void main(String[] args)
                          {
                            int stack1[] = { 3, 2, 1, 1, 1 };
                            int stack2[] = { 4, 3, 2 };
                            int stack3[] = { 1, 1, 4, 1 };
                            
                            int n1 = stack1.length;
                            int n2 = stack2.length;
                            int n3 = stack3.length;
                            
                            System.out.println(maxSum(stack1, stack2,
                                      stack3, n1, n2, n3));
                          }
                        }`,
                        output: `5`,
                      },
                      Python: {
                        code: `def maxSum(stack1, stack2, stack3, n1, n2, n3):
          sum1, sum2, sum3 = 0, 0, 0
        
          for i in range(n1):
            sum1 += stack1[i]
        
          for i in range(n2):
            sum2 += stack2[i]
        
          for i in range(n3):
            sum3 += stack3[i]
        
          top1, top2, top3 = 0, 0, 0
          ans = 0
          while (1):
            if (top1 == n1 or top2 == n2 or top3 == n3):
              return 0
        
            if (sum1 == sum2 and sum2 == sum3):
              return sum1
          
            if (sum1 >= sum2 and sum1 >= sum3):
              sum1 -= stack1[top1]
              top1=top1+1
            elif (sum2 >= sum1 and sum2 >= sum3):
              sum2 -= stack2[top2]
              top2=top2+1
            elif (sum3 >= sum2 and sum3 >= sum1):
              sum3 -= stack3[top3]
              top3=top3+1
        
        stack1 = [ 3, 2, 1, 1, 1 ]
        stack2 = [ 4, 3, 2 ]
        stack3 = [ 1, 1, 4, 1 ]
        
        n1 = len(stack1)
        n2 = len(stack2)
        n3 = len(stack3)
        
        print(maxSum(stack1, stack2, stack3, n1, n2, n3))`,
                        output: `5`,
                      },
                      "C++": {
                        code: `#include <bits/stdc++.h>
                        using namespace std;
                        
                        int maxSum(int stack1[], int stack2[], int stack3[], int n1,
                            int n2, int n3)
                        {
                          int sum1 = 0, sum2 = 0, sum3 = 0;
                        
                          for (int i = 0; i < n1; i++)
                            sum1 += stack1[i];
                        
                          for (int i = 0; i < n2; i++)
                            sum2 += stack2[i];
                        
                          for (int i = 0; i < n3; i++)
                            sum3 += stack3[i];
                        
                          int top1 = 0, top2 = 0, top3 = 0;
                          while (1) {
                            if (top1 == n1 || top2 == n2 || top3 == n3)
                              return 0;
                        
                            if (sum1 == sum2 && sum2 == sum3)
                              return sum1;
                        
                            if (sum1 >= sum2 && sum1 >= sum3)
                              sum1 -= stack1[top1++];
                            else if (sum2 >= sum1 && sum2 >= sum3)
                              sum2 -= stack2[top2++];
                            else if (sum3 >= sum2 && sum3 >= sum1)
                              sum3 -= stack3[top3++];
                          }
                        }
                        
                        int main()
                        {
                          int stack1[] = { 3, 2, 1, 1, 1 };
                          int stack2[] = { 4, 3, 2 };
                          int stack3[] = { 1, 1, 4, 1 };
                        
                          int n1 = sizeof(stack1) / sizeof(stack1[0]);
                          int n2 = sizeof(stack2) / sizeof(stack2[0]);
                          int n3 = sizeof(stack3) / sizeof(stack3[0]);
                        
                          cout << maxSum(stack1, stack2, stack3, n1, n2, n3)
                            << endl;
                          return 0;
                        }
                        `,
                        output: `5`,
                      },
                      Kotlin: {
                        code: `class M {

                          public static int maxSum(int stack1[], int stack2[],
                          int stack3[], int n1, int n2,
                          int n3)
                          {
                              int sum1 = 0, sum2 = 0, sum3 = 0;
                      
                              for (int i=0; i < n1; i++)
                              sum1 += stack1[i];
                      
                              for (int i=0; i < n2; i++)
                              sum2 += stack2[i];
                      
                              for (int i=0; i < n3; i++)
                              sum3 += stack3[i];
                      
                              int top1 =0, top2 = 0, top3 = 0;
                              int ans = 0;
                              while (true)
                              {
                                  if (top1 == n1 || top2 == n2 || top3 == n3)
                                      return 0;
                      
                                  if (sum1 == sum2 && sum2 == sum3)
                                      return sum1;
                      
                                  if (sum1 >= sum2 && sum1 >= sum3)
                                      sum1 -= stack1[top1++];
                                  else if (sum2 >= sum1 && sum2 >= sum3)
                                      sum2 -= stack2[top2++];
                                  else if (sum3 >= sum2 && sum3 >= sum1)
                                      sum3 -= stack3[top3++];
                              }
                          }
                      
                          public static void main(String[] args)
                          {
                              int stack1[] = { 3, 2, 1, 1, 1 };
                              int stack2[] = { 4, 3, 2 };
                              int stack3[] = { 1, 1, 4, 1 };
                      
                              int n1 = stack1.length;
                              int n2 = stack2.length;
                              int n3 = stack3.length;
                      
                              System.out.println(maxSum(stack1, stack2,
                                  stack3, n1, n2, n3));
                          }
                      }`,
                        output: `5`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
        ],
      },
      dp: {
        title: "Dynamic Programming",
        content: (
          <>
            <Span>
              Dynamic Programming is an algorithmic paradigm that solves a given
              complex problem by breaking it into subproblems and stores the
              results of subproblems to avoid computing the same results again.
              Following are the two main properties of a problem that suggests
              that the given problem can be solved using Dynamic programming.
            </Span>
            <Span>
              As compared to recursion, It increases both, it increases the
              space complexity and decreases the time complexity.
            </Span>
            <Span>
              f(n) = f(n-1) + f(n-2), n&gt;= 2 <br /> f(1) = 1 , f(0) = 0 <br />{" "}
              f(n) = [0,1,1,2,3,5,8,13,21]
            </Span>
            <Span>
              <b>Optimal Substructure</b>
            </Span>
            <Span>
              It exists when optimal solution can be constructed from optimal
              solutions of its subproblems. Problems should have recursive
              definition.
            </Span>
            <Span>
              <b>Overlapping Subproblems</b>
            </Span>
            <Span>
              This property exists when solutions of subproblems are needed
              again and again. Like Divide and Conquer, Dynamic Programming
              combines solutions to sub-problems. Dynamic Programming is mainly
              used when solutions of the same subproblems are needed again and
              again. In dynamic programming, computed solutions to subproblems
              are stored in a table so that these don’t have to be recomputed.
              So Dynamic Programming is not useful when there are no common
              (overlapping) subproblems because there is no point storing the
              solutions if they are not needed again.
            </Span>
            <Span>
              It is guaranteed that Dynamic Programming will generate an optimal
              solution as it generally considers all possible cases and then
              choose the best.
            </Span>
            <Span>
              To avoid recomputations we simply recall results from memory
            </Span>
            <Span>
              Time taken for calculating the 40th fibonacci number (102334155):{" "}
              <br />
              1. Recursive : 14s <br />
              2. Memoization : 0.17s <br />
              3. Tabulation: 0.30s
            </Span>
            <Span>Two methods of storing the results in memory:</Span>
            <Span>
              <b>1. Memoization (Top-Down)</b>
            </Span>
            <Span>
              The memoized program for a problem is similar to the recursive
              version ( tracing tree ) with a small modification that it looks
              into a lookup table before computing solutions. We initialize a
              lookup array with all initial values as NIL. Whenever we need the
              solution to a subproblem, we first look into the lookup table. If
              the precomputed value is there then we return that value,
              otherwise, we calculate the value and put the result in the lookup
              table so that it can be reused later.
            </Span>
            <Span>
              <b>2. Tabulation (Bottom-Up)</b>
            </Span>
            <p>
              Built the lookup table in bottom up fashion <br />
              After the table is built, simply return table[n]
            </p>
            <CodeEditor
              options={{
                title: "Dynamic Programming",
                codes: {
                  Javascript: {
                    code: `let MAX = 100
                // using recursion,
                // tc -> O(2^n)
                // sc -> O(n * constant)
                // using dp
                // tc -> O(n) we iterate through the 
                // loop only once
                // sc -> O(n)
                // memoization top-down
                // we compute the solution of the 
                // subproblem if it's not present yet
                // and store that at the look-up table 
                // so that it can be re-used as and when 
                // required
                const lookupMem = Array(MAX).fill(-1)
                const lookupTab = Array(MAX).fill(-1)
                function fibMem(n){
                   if(lookupMem[n]===-1){
                     if(n <=1) lookupMem[n] =n
                     else lookupMem[n] = fibMem(n-1) + fibMem(n-2)
                   } 
                   return lookupMem[n]
                }
                // tabulation bottom-up
                // after the lookup table is built 
                // with the help of previous values
                // we simply return lookup[n]
                function fibTab(n){
                   lookupTab[0] = 0
                   lookupTab[1] = 1
                   for (let i = 2; i <= n; i++)
                   lookupTab[i] = lookupTab[i-1] + lookupTab[i-2];
                   return lookupTab[n]
                }
                const n = 4
                console.log("Memoization")
                console.log(fibMem(n))
                console.log("Max lookupMem length")
                console.log(lookupMem.length)
                console.log("Required lookupMem slice")
                console.log(lookupMem.slice(0,n+1))
                console.log("Tabulation")
                console.log(fibTab(n))
                console.log("Max lookupTab length")
                console.log(lookupTab.length)
                console.log("Required lookupTab slice")
                console.log(lookupTab.slice(0,n+1))
                `,
                    output: `Memoization
                  3
                  Max lookupMem length
                  100
                  Required lookupMem slice
                  [ 0, 1, 1, 2, 3 ]
                  Tabulation
                  3
                  Max lookupTab length
                  100
                  Required lookupTab slice
                  [ 0, 1, 1, 2, 3 ]`,
                  },
                },
              }}
            />
          </>
        ),
        types: [
          {
            title: "0-1 Knapsack",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Given a set of weights and values , pick the weights in such a
                  way that their sum will be nearest or equal to the given total
                  weight.
                </Span>
                <Span>
                  <b>Note :</b>
                </Span>
                <p>Time Complexity : O(N*W)</p>
                <CodeEditor
                  options={{
                    title: "0-1 Knapsack",
                    codes: {
                      Javascript: {
                        code: `/*
values = 10 , 15 , 40

lookupTab[1][1] = 10
lookupTab[2][2] = 15
lookupTab[3][3] = 40

elements = 1 , 2 , 3
capacity = 6

Tabulation lookup table[values.length+1][capacity+1] -> 

   0   1   2   3   4   5   6    (index) w

0  0   0   0   0   0   0   0

1  0 [10] 10 [10] 10  10  10

2  0  10 [15] 25  25  25  25   wt[1] < 3, 1 < 3 , Math.max(10,15 + lookupTab[1][3-2])

3  0  10  15  40  50  55 [65] -> Answer       
|
(index) i

Answer: 65

TC O(N*W)
SC O(N*W)

*/
                      
                      function knapSack(val, wt, W) {
                        const n = val.length;
                        const lookupTab = Array.from(Array(n + 1), () => Array(W + 1).fill(-1));
                        // Tab stands for Tabulation
                        for (let i = 0; i <= n; i++) {
                          for (let w = 0; w <= W; w++) {
                            if (i == 0 || w == 0) {
                              lookupTab[i][w] = 0;
                            } else if (wt[i - 1] <= w)
                              lookupTab[i][w] = Math.max(
                                val[i - 1] + lookupTab[i - 1][w - wt[i - 1]], // trick to memorize: i-1 w - wt[i-1]
                                lookupTab[i - 1][w]
                              );
                            else lookupTab[i][w] = lookupTab[i - 1][w];
                          }
                        }
                        console.log("lookup table:")
                        console.log(lookupTab)
                        return lookupTab[n][W];
                      }
                      
                      const val = [10, 15, 40];
                      const wt = [1, 2, 3];
                      const W = 6;
                      console.log(knapSack(val, wt, W));                      
                      `,
                        output: `lookup table:
                        [
                          [
                            0, 0, 0, 0,
                            0, 0, 0
                          ],
                          [
                             0, 10, 10, 10,
                            10, 10, 10
                          ],
                          [
                             0, 10, 15, 25,
                            25, 25, 25
                          ],
                          [
                             0, 10, 15, 40,
                            50, 55, 65
                          ]
                        ]
                        65`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Binomial Coefficients",
            content: (
              <>
                <Span>
                  <b>Problem Statement:</b>
                </Span>
                <Span>Obtain C(n,r) using dp.</Span>
                <p>
                  Formula <br />
                  (1+x)^n <br />
                  C(n,k) = C(n-1,k-1) + C(n-1,k) <br />
                  C(n,0) = C(n,n) = 1
                </p>
                <CodeEditor
                  options={{
                    title: "Binomial Coefficients",
                    codes: {
                      Javascript: {
                        code: `// TC : O(n*k)
                        // SC : O(n*k) 
                        
                        // -1 -> null
                        // [
                        //   [ 1, -1, -1 ],
                        //   [ 1, 1, -1 ],
                        //   [ *1, *2, 1 ], 1+1 =2
                        //   [ 1, *3, 3 ], 1+2 =3 2+1 =3
                        //   [ 1, 4, 6 ], 1+3 =4 3+3 =6
                        //   [ 1, 5, 10 ] 1+ 4 = 5 4+6 =10
                        // ]
                        
                        function bc_dp(n, k) {
                            const lookupTab = Array.from(Array(n + 1), () => Array(k + 1).fill(-1))
                            // fill(null)
                            for (let i = 0; i <= n; i++) {
                                for (let j = 0; j <= Math.min(i, k); j++) {
                                    if (j == 0 || j == i)
                                        lookupTab[i][j] = 1
                                    else
                                        lookupTab[i][j] = lookupTab[i - 1][j - 1] + lookupTab[i - 1][j]
                                }
                            }
                            console.log(lookupTab)
                            return lookupTab[n][k]
                        }
                        
                        // TC : O(n*k)
                        // SC : O(n) 
                        
                        
                        // pascal's triangle
                        // c[2] represents C[i][2]
                        
                        function space_optimized_bc_dp(n, k) {
                            const lookupTab = Array(k + 1).fill(0)
                            lookupTab[0] = 1
                            for (let i = 0; i <= n; i++) {
                                for (let j = Math.min(i, k); j > 0; j--) {
                                    lookupTab[j] += lookupTab[j - 1]
                                }
                            }
                            console.log(lookupTab)
                            return lookupTab[k]
                        }
                        
                        const n = 5,
                            k = 2;
                        console.log(bc_dp(n, k))
                        console.log("###############")
                        console.log(space_optimized_bc_dp(n, k))`,
                        output: `[
                          [ 1, -1, -1 ],
                          [ 1, 1, -1 ],
                          [ 1, 2, 1 ],
                          [ 1, 3, 3 ],
                          [ 1, 4, 6 ],
                          [ 1, 5, 10 ]
                        ]
                        10
                        ###############
                        [ 1, 5, 10 ]
                        10`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Travelling Salesman",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Given a set of cities and distance between every pair of
                  cities, the problem is to find the shortest possible route
                  that visits every city exactly once and returns to the
                  starting point.
                </Span>
                <Img left src={TSPDPImg} />
                <Span>
                  Note the difference between Hamiltonian Cycle and TSP. The
                  Hamiltonian cycle problem is to find if there exists a tour
                  that visits every city exactly once. Here we know that
                  Hamiltonian Tour exists (because the graph is complete) and in
                  fact, many such tours exist, the problem is to find a minimum
                  weight Hamiltonian Cycle.
                </Span>
                <Span>
                  The problem is a famous NP-hard problem. There is no
                  polynomial-time known solution for this problem.
                </Span>
                <p>
                  In this type of problem, a salesman loves to travel and he
                  should travel all destinations and return back to the arrival
                  point
                </p>
                <CodeEditor
                  options={{
                    title: "Travelling Salesman",
                    codes: {
                      Javascript: {
                        code: `// TSP ->
                        // Minimum weighted hamiltonian
                        // cycle(s)
                        
                        // cycling permutaions
                        // A B C D
                        // B C D A
                        
                        // using brute force 
                        // complexity will be O(n!)
                        // even after selecting the source
                        // complexity will be O(n-1)!
                        
                        
                        
                        class Graph {
                            constructor(noOfVertices) {
                                this.noOfVertices = noOfVertices;
                                this.adjMatrix = Array.from(Array(noOfVertices),()=>Array(noOfVertices).fill(0));
                            }
                        
                            addEdge(u, v, wt) {
                              if ((u >= this.noOfVertices) || (v > this.noOfVertices)) {
                                console.log("Vertex does not exists!");
                                return
                              }
                              if (u == v) {
                                console.log("Same Vertex!");
                                return
                              }
                              this.adjMatrix[u][v] = wt
                              this.adjMatrix[v][u] = wt
                            }
                        
                            print() {
                                console.log(this.adjMatrix)
                            }
                        
                            tsp(){
                              let allVisited = (1 << this.noOfVertices) - 1;
                              // we can make use of boolean array as well
                              //. ( 1 << 4) -1  => 16 -1 = 15
                              // or allVisited = Array(this.noOfVertices)
                              // .fill(false)
                              
                              // mask 1 (0001) represents person is at first 
                              // visits (obviously)
                        
                              // mask 15 (1111) represents person visits all cities 
                        
                              const lookupMem = Array.from(Array(1<<this.noOfVertices),()=> Array(this.noOfVertices).fill(-1))
                              const res = this.tspHelper(1,0,allVisited,lookupMem)
                              console.log("lookup")
                              console.log(lookupMem)
                              return res
                            }
                        
                            // TC -> n^2 * 2^n
                            // Each sub-problem (2^n) will take O (n) time to find a path to 
                            // remaining (n-1) cities.
                            // SC -> n2^n
                        
                            tspHelper(mask,pos,allVisited,dp){
                        
                              if(mask == allVisited){
                               return this.adjMatrix[pos][0]
                              }
                              // dist[pos][0] distance b/w last and first pos
                        
                              if(dp[mask][pos]!=-1)
                              return dp[mask][pos]
                              // avoid overlapping subproblems
                        
                              let ans = Number.MAX_SAFE_INTEGER
                              for(let city=0;city<this.noOfVertices;city++){
                                // check for non visited neighbor
                              if((mask&(1<<city))==0){
                                  let newAns = this.adjMatrix[pos][city] + this.tspHelper((mask|(1<<city)),city,allVisited,dp)
                                  // update city's bit from 0 to 1 for e.g. from 0001 to 0011
                                  ans = Math.min(ans,newAns)   
                                }
                              }
                              return dp[mask][pos] = ans
                        }
                      }
                        
                        const g = new Graph(4)
                        const v = ['A', 'B', 'C', 'D']
                        g.addEdge(0, 1, 20);
                        g.addEdge(0, 2, 42);
                        g.addEdge(0, 3, 25);
                        g.addEdge(1, 2, 30);
                        g.addEdge(1, 3, 34);
                        g.addEdge(3, 2, 10);
                        g.print();
                        console.log(g.tsp())
                        `,
                        output: `[
                          [ 0, 20, 42, 25 ],
                          [ 20, 0, 30, 34 ],
                          [ 42, 30, 0, 10 ],
                          [ 25, 34, 10, 0 ]
                        ]
                        lookup
                        [
                          [ -1, -1, -1, -1 ], [ 85, -1, -1, -1 ],
                          [ -1, -1, -1, -1 ], [ -1, 65, -1, -1 ],
                          [ -1, -1, -1, -1 ], [ -1, -1, 64, -1 ],
                          [ -1, -1, -1, -1 ], [ -1, 59, 35, -1 ],
                          [ -1, -1, -1, -1 ], [ -1, -1, -1, 60 ],
                          [ -1, -1, -1, -1 ], [ -1, 72, -1, 52 ],
                          [ -1, -1, -1, -1 ], [ -1, -1, 50, 54 ],
                          [ -1, -1, -1, -1 ], [ -1, -1, -1, -1 ]
                        ]
                        85`,
                      },
                    },
                  }}
                />
                <p>
                  <b>Practice</b>
                </p>
                <CodeEditor
                  options={{
                    title: "TSP Bitmasking",
                    codes: {
                      Javascript: {
                        code: `function dec2bin(dec){                      
                          return (dec >>> 0).toString(2);
                        }
                        
                        class Graph {
                            constructor(n) {
                                this.n = n;
                                this.g = Array.from(Array(n), () => Array(n).fill(0));
                            }
                        
                            addEdge(u, v, wt) {
                                this.g[u][v] = wt
                                this.g[v][u] = wt
                            }
                        
                            print() {
                                console.log(this.g)
                            }
                        
                        
                            tsp(dp,mask=1, pos=0) {
                              // 1 -> 0001
                        
                                if (mask == ((1<<this.n)-1)) {
                                    return this.g[pos][0]
                                }
                        
                                if (dp[mask][pos] != -1)
                                    return dp[mask][pos]
                        
                                let ans = Number.MAX_SAFE_INTEGER
                                // compute tsp using each vertex
                                for (let i = 0; i < this.n; i++) {
                                    // if not visited
                                    if ((mask & (1 << i)) == 0) {
                                        // mark as visited 
                                        let newAns = this.g[pos][i] + this.tsp(dp,(mask | (1 << i)), i)
                                        ans = Math.min(ans, newAns)
                                    }
                                }
                                return dp[mask][pos] = ans
                            }
                        }
                        
                        const n = 4
                        const g = new Graph(n)
                        const v = ['A', 'B', 'C', 'D']
                        const dp = Array.from(Array(1 << n), () => Array(n).fill(-1))
                        g.addEdge(0, 1, 20);
                        g.addEdge(0, 2, 42);
                        g.addEdge(0, 3, 25);
                        g.addEdge(1, 2, 30);
                        g.addEdge(1, 3, 34);
                        g.addEdge(3, 2, 10);
                        g.print();
                        console.log(g.tsp(dp))`,
                        output: `[
                          [ 0, 20, 42, 25 ],
                          [ 20, 0, 30, 34 ],
                          [ 42, 30, 0, 10 ],
                          [ 25, 34, 10, 0 ]
                        ]
                        85`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Longest Palindromic Substring",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Given a string s, return the longest palindromic substring in
                  s.
                </Span>

                <Span>
                  <b>Example 1:</b>
                </Span>

                <Span>
                  Input: s = "babad" <br />
                  Output: "bab" <br />
                  Explanation: "aba" is also a valid answer.
                </Span>

                <Span>
                  <b>Example 2:</b>
                </Span>

                <Span>
                  Input: s = "cbbd" <br />
                  Output: "bb"
                </Span>

                <Span>
                  <b>Constraints:</b>
                </Span>

                <Span>
                  1 &lt;= s.length &lt;= 1000 <br />s consist of only digits and
                  English letters.
                </Span>

                <CodeEditor
                  options={{
                    title: "Q5. Longest Palindromic Substring",
                    output: `bab`,
                    codes: {
                      Javascript: {
                        code: `/**
                          * @param {string} s
                          * @return {string}
                          */
                         
                         var longestPalindrome = function(s) {
                             let n = s.length;
                             let lookupTab = Array.from(Array(n),()=>Array(n).fill(false));
                             let maxLength = 1;
                             for (let i = 0; i < n; ++i)
                               lookupTab[i][i] = true;
                             let start = 0;
                             for (let i = 0; i < n - 1; ++i)
                             {
                               if (s[i] == s[i + 1])
                               {
                                 lookupTab[i][i + 1] = true;
                                 start = i;
                                 maxLength = 2;
                               }
                             }
                             for (let k = 3; k <= n; ++k) {
                               for (let i = 0; i < n - k + 1; ++i)
                               {
                                 let j = i + k - 1;
                                 if (lookupTab[i + 1][j - 1]
                                   && s[i] == s[j]) {
                                   lookupTab[i][j] = true;
                                   if (k > maxLength) {
                                     start = i;
                                     maxLength = k;
                                   }
                                 }
                               }
                             }
                             return s.substring(start, start+maxLength);
                         }
                           
                         console.log(longestPalindrome("babad"));
                         `,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Longest Common Substring",
            content: (
              <>
                <Span>
                  <b>Longest Common Substring Problem Statement:</b>
                </Span>
                <Span>
                  Given two strings ‘X’ and ‘Y’, find the length of the longest
                  common substring.
                </Span>
                <CodeEditor
                  options={{
                    title: "Longest Common Substring",
                    codes: {
                      Javascript: {
                        code: `function lcs(s1,s2){ 
  
                          const m = s1.length;
                          const n = s2.length;
                          let row=0 
                          let col=0;
                          let len=0;
                          const dp = Array.from(Array(m + 1), () => Array(n + 1).fill(0));
                          let result = 0
                          for (let i = 0; i <= m; i++) {
                            for (let j = 0; j <= n; j++) {
                              if (i == 0 || j == 0) dp[i][j] = 0;
                              else if (s1[i - 1] == s2[j - 1]){
                                dp[i][j] = 1 + dp[i - 1][j - 1];
                                result = Math.max(result , dp[i][j]) 
                                if (len < dp[i][j]) {
                                     len = dp[i][j];
                                     row = i;
                                     col = j;
                        }
                        }
                              else dp[i][j] = 0
                            }
                          }
                           if(len==0) {
                           console.log("no substring present")
                           return 0   
                           }
                             let resultStr = "";  
                                while (dp[row][col] != 0) {
                                    resultStr = s1[row-1] + resultStr; // or Y[col-1]
                                    --len;
                                    --row;
                                    --col;
                                } 
                        console.log(resultStr)
                           return result
                        }
                        
                        lcs("sai","sai9")`,
                        output: `sai
                        3`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "LCS",
            content: (
              <>
                <Span>
                  <b>Longest Common Subsequence Problem Statement:</b>
                </Span>
                <Span>
                  Given two sequences , find the length of longest subsequence
                  present in both of them
                </Span>
                <p>
                  A subsequence is a sequence that appears in the same relative
                  order, but not necessarily contiguous. Sequences = “abcdefg”,
                  “abxdfg”
                </p>
                <CodeEditor
                  options={{
                    title: "LCS",
                    codes: {
                      Javascript: {
                        code: `// A subsequence is a sequence that appears in the same 
                        // relative order, but not necessarily contiguous.
                        
                        // tabulation
                        
                        /*
                           
                        str1 = abcdefg
                        str2 = abxdfg
                            
                               a   b   x   d   f   g
                           0 , 0 , 0 , 0 , 0 , 0 , 0
                        a  0 , 1 , 1 , 1 , 1 , 1 , 1
                        b  0 , 1 , 2 , 2 , 2 , 2 , 2
                        c  0 , 1 , 2 , 2 , 2 , 2 , 2
                        d  0 , 1 , 2 , 2 , 3 , 3 , 3
                        e  0 , 1 , 2 , 2 , 3 , 3 , 3. e !== d, 3 > 2
                        f  0 , 1 , 2 , 2 , 3 , 4 , 4   
                        g  0 , 1 , 2 , 2 , 3 , 4 , 5
                        
                        */
                        
                        const lcs = function(str1,str2){
                          
                        const m = str1.length
                        const n = str2.length
                        
                        const lookupTab = Array.from(Array(m+1),() => Array(n+1).fill(0))
                        
                        for(let i=0;i<=m;i++){
                          for(let j=0;j<=n;j++){
                             if(i===0 || j===0) lookupTab[i][j] = 0
                             else if(str1[i-1]===str2[j-1]) 
                             lookupTab[i][j] = lookupTab[i-1][j-1] + 1
                             else
                             lookupTab[i][j] = Math.max(lookupTab[i-1][j],lookupTab[i][j-1])
                          }
                        }
                        
                        const result = lookupTab[m][n]
                        
                        console.log("[ Lookup Table ]")
                        lookupTab.forEach(x=>{
                          console.log(x.join(" , "))
                        })
                        
                        let subsequence = Array(result).fill(null)
                        let len = result
                        
                        let i=m,j=n;
                        while(i>0 && j>0){
                          if(str1[i-1]==str2[j-1]){
                            subsequence[len-1] = str1[i-1] 
                            i-=1
                            j-=1
                            len-=1
                          }
                          // move in the direction of larger 
                          // element
                          else if(lookupTab[i-1][j]>lookupTab[i][j-1]){
                            i-=1
                          }else{
                            j-=1
                          }
                        }
                        console.log("M : " + str1)
                        console.log("N : " + str2)
                        console.log({subsequence: typeof str1 === 'string'? subsequence.join(""): subsequence})
                        
                        return result
                        }
                        
                        lcs("abcdefg", "abxdfg")
                        console.log("")
                        lcs([1,2,0],[9,8,1,6,2])
                        `,
                        output: `[ Lookup Table ]
                        0 , 0 , 0 , 0 , 0 , 0 , 0
                        0 , 1 , 1 , 1 , 1 , 1 , 1
                        0 , 1 , 2 , 2 , 2 , 2 , 2
                        0 , 1 , 2 , 2 , 2 , 2 , 2
                        0 , 1 , 2 , 2 , 3 , 3 , 3
                        0 , 1 , 2 , 2 , 3 , 3 , 3
                        0 , 1 , 2 , 2 , 3 , 4 , 4
                        0 , 1 , 2 , 2 , 3 , 4 , 5
                        M : abcdefg
                        N : abxdfg
                        { subsequence: 'abdfg' }
                        
                        [ Lookup Table ]
                        0 , 0 , 0 , 0 , 0 , 0
                        0 , 0 , 0 , 1 , 1 , 1
                        0 , 0 , 0 , 1 , 1 , 2
                        0 , 0 , 0 , 1 , 1 , 2
                        M : 1,2,0
                        N : 9,8,1,6,2
                        { subsequence: [ 1, 2 ] }
                        2`,
                      },
                    },
                  }}
                />

                <p>
                  <b>LCS of 0s</b>
                </p>
                <CodeEditor
                  options={{
                    title: "LCS of 0s",
                    codes: {
                      Javascript: {
                        code: `// A subsequence is a sequence that appears in the same 
                      // relative order, but not necessarily contiguous.
                      
                      // tabulation
                      
                      /*
                         
                      str1 = abcdefg
                      str2 = abxdfg
                          
                             a   b   x   d   f   g
                         0 , 0 , 0 , 0 , 0 , 0 , 0
                      a  0 , 1 , 1 , 1 , 1 , 1 , 1
                      b  0 , 1 , 2 , 2 , 2 , 2 , 2
                      c  0 , 1 , 2 , 2 , 2 , 2 , 2
                      d  0 , 1 , 2 , 2 , 3 , 3 , 3
                      e  0 , 1 , 2 , 2 , 3 , 3 , 3. e !== d, 3 > 2
                      f  0 , 1 , 2 , 2 , 3 , 4 , 4   
                      g  0 , 1 , 2 , 2 , 3 , 4 , 5
                      
                      */
                      
                      const lcs = function(str1,str2,val){
                        
                      const m = str1.length
                      const n = str2.length
                      
                      const lookupTab = Array.from(Array(m+1),() => Array(n+1).fill(0))
                      
                      for(let i=0;i<=m;i++){
                        for(let j=0;j<=n;j++){
                           if(i===0 || j===0) lookupTab[i][j] = 0
                           else if(str1[i-1]===str2[j-1] && str2[j-1]===val) 
                           lookupTab[i][j] = lookupTab[i-1][j-1] + 1
                           else
                           lookupTab[i][j] = Math.max(lookupTab[i-1][j],lookupTab[i][j-1])
                        }
                      }
                      
                      const result = lookupTab[m][n]
                      
                      console.log("[ Lookup Table ]")
                      lookupTab.forEach(x=>{
                        console.log(x.join(" , "))
                      })
                      
                      let subsequence = Array(result).fill(null)
                      let len = result
                      
                      let i=m,j=n;
                      while(i>0 && j>0){
                        if(str1[i-1]==str2[j-1] && str2[j-1]===val){
                          subsequence[len-1] = str1[i-1] 
                          i-=1
                          j-=1
                          len-=1
                        }
                        // move in the direction of larger 
                        // element
                        else if(lookupTab[i-1][j]>lookupTab[i][j-1]){
                          i-=1
                        }else{
                          j-=1
                        }
                      }
                      console.log("M : " + str1)
                      console.log("N : " + str2)
                      console.log({subsequence: typeof str1 === 'string'? subsequence.join(""): subsequence})
                      
                      return result
                      }
                      
                      lcs([0,-1,0,0,-1],[0,-1,1,0,-1],0)
                      `,
                        output: `[ Lookup Table ]
                      0 , 0 , 0 , 0 , 0 , 0
                      0 , 1 , 1 , 1 , 1 , 1
                      0 , 1 , 1 , 1 , 1 , 1
                      0 , 1 , 1 , 1 , 2 , 2
                      0 , 1 , 1 , 1 , 2 , 2
                      0 , 1 , 1 , 1 , 2 , 2
                      M : 0,-1,0,0,-1
                      N : 0,-1,1,0,-1
                      { subsequence: [ 0, 0 ] }
                      2`,
                      },
                    },
                  }}
                />
                <p>Print All LCS</p>
                <CodeEditor
                  options={{
                    title: "Print All LCS",
                    codes: {
                      Javascript: {
                        code: `function fillLookUpTable(str1, str2,lookupTab) {
                  const m = str1.length
                  const n = str2.length
                  for (let i = 0; i <= m; i++) {
                      for (let j = 0; j <= n; j++) {
                          if (i === 0 || j === 0) lookupTab[i][j] = 0
                          else if (str1[i - 1] === str2[j - 1])
                              lookupTab[i][j] = lookupTab[i - 1][j - 1] + 1
                          else
                              lookupTab[i][j] = Math.max(lookupTab[i - 1][j], lookupTab[i][j - 1])
                      }
                  }
                  console.log("[ Lookup Table ]")
                  lookupTab.forEach(x => {
                      console.log(x.join(" , "))
                  })
              }
              
              function printAllLcs(str1,str2,m,n,lookupTab){
                if(m==0 || n==0)
                return [""]
                if(str1[m - 1] === str2[n - 1]){
                  let lcs = printAllLcs(str1,str2,m-1,n-1,lookupTab)
                  for(let i in lcs){
                    lcs[i] += str1[m-1]
                  }
                  return lcs
                }
                // start moving in desired direction otherwise
                if(lookupTab[m-1][n] > lookupTab[m][n-1])
                  return printAllLcs(str1,str2,m-1,n,lookupTab)
                else if(lookupTab[m-1][n] < lookupTab[m][n-1])
                  return printAllLcs(str1,str2,m,n-1,lookupTab)
                else {
                let top = printAllLcs(str1, str2, m - 1, n, lookupTab)
                let left = printAllLcs(str1, str2, m, n - 1, lookupTab)
                top = [...top, ...left];
                return top;
                }
              }
              
              const str1 = "ABCBDAB"
              const str2 = "BDCABA"
              const m = str1.length
              const n = str2.length
              const lookupTab = Array.from(Array(m + 1), () => Array(n + 1).fill(0))
              fillLookUpTable(str1,str2,lookupTab)
              const res = printAllLcs(str1,str2,str1.length,str2.length,lookupTab)
              console.log("M : " + str1)
              console.log("N : " + str2)
              console.log([...new Set(res)])`,
                        output: `[ Lookup Table ]
                0 , 0 , 0 , 0 , 0 , 0 , 0
                0 , 0 , 0 , 0 , 1 , 1 , 1
                0 , 1 , 1 , 1 , 1 , 2 , 2
                0 , 1 , 1 , 2 , 2 , 2 , 2
                0 , 1 , 1 , 2 , 2 , 3 , 3
                0 , 1 , 2 , 2 , 2 , 3 , 3
                0 , 1 , 2 , 2 , 3 , 3 , 4
                0 , 1 , 2 , 2 , 3 , 4 , 4
                M : ABCBDAB
                N : BDCABA
                [ 'BCBA', 'BCAB', 'BDAB' ]`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "LIS",
            content: (
              <>
                <Span>
                  <b>Longest Increasing Subsequence Problem Statement:</b>
                </Span>
                <p>
                  Find the length of the longest subsequence of a given sequence
                  such that all elements of the subsequence are sorted in
                  increasing order
                </p>
                <CodeEditor
                  options={{
                    title: "LIS",
                    codes: {
                      Javascript: {
                        code: `/*
                      arr[] 10 22 9 33 21 50 41 60
                      lis    1  2 1  3  2  4  4  5  
                      */
                      
                      function LIS(arr){
                      
                      const n = arr.length
                      // 8
                      
                      let lookupTab = Array(n).fill(1)
                      
                      for (i = 1; i < n; i++)
                      for (j = 0; j < i; j++)
                      if (arr[i] > arr[j] && lookupTab[i] < lookupTab[j] + 1)
                      lookupTab[i] = lookupTab[j] + 1
                      
                      const max = Math.max(...lookupTab)
                      
                      let index =[]
                      let tmp = max
                      
                      for(let i=n-1;i>=0;i--){
                      if(lookupTab[i]==tmp){
                      index.push(i)
                      tmp--
                      }
                      }
                      index.reverse()
                      index = index.map(x=>arr[x])
                      console.log(index)
                      
                      return max
                      }
                      
                      LIS([10, 22, 9, 33, 21, 50, 41, 60])`,
                        output: `[ 10, 22, 33, 41, 60 ]
                      5`,
                      },
                    },
                  }}
                />
                <p>
                  <b>Print All LIS</b>
                </p>
                <CodeEditor
                  options={{
                    title: "Print All LIS",
                    codes: {
                      Javascript: {
                        code: `// W.I.P.`,
                        output: ``,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "LPS",
            content: (
              <>
                <Span>
                  <b>Longest Palindromic Subsequence Problem Statement</b>
                </Span>
                <p>
                  Given a sequence, find the length of the longest palindromic
                  subsequence on it
                </p>
                <CodeEditor
                  options={{
                    title: "LPS",
                    codes: {
                      Javascript: {
                        code: `function lps(subsequence){
                        const len = subsequence.length
                        // 9
                        let lookupTab = Array.from(Array(len), () => Array(len).fill(1))
                        
                        for (let cl=2; cl<=len; cl++){
                          for (let i=0; i<len-cl+1; i++)
                          {
                            let j = i+cl-1;
                            if (subsequence[i] == subsequence[j] && cl == 2)
                            lookupTab[i][j] = 2;
                            else if (subsequence[i] == subsequence[j])
                            lookupTab[i][j] = lookupTab[i+1][j-1] + 2;
                            else
                            lookupTab[i][j] = Math.max(lookupTab[i][j-1], lookupTab[i+1][j]);
                          }
                        }
                        console.log("Lookup Table : ")
                        lookupTab.forEach(x=>console.log(x.join(" , ")))
                        return lookupTab[0][len-1]
                        }
                        
                        console.log(lps("abs123abs"))
                        `,
                        output: `Lookup Table : 
                      1 , 1 , 1 , 1 , 1 , 1 , 3 , 3 , 3
                      1 , 1 , 1 , 1 , 1 , 1 , 1 , 3 , 3
                      1 , 1 , 1 , 1 , 1 , 1 , 1 , 1 , 3
                      1 , 1 , 1 , 1 , 1 , 1 , 1 , 1 , 1
                      1 , 1 , 1 , 1 , 1 , 1 , 1 , 1 , 1
                      1 , 1 , 1 , 1 , 1 , 1 , 1 , 1 , 1
                      1 , 1 , 1 , 1 , 1 , 1 , 1 , 1 , 1
                      1 , 1 , 1 , 1 , 1 , 1 , 1 , 1 , 1
                      1 , 1 , 1 , 1 , 1 , 1 , 1 , 1 , 1
                      3`,
                      },
                    },
                  }}
                />
                <p>
                  <b>Program to print lps</b>
                </p>
                <CodeEditor
                  options={{
                    title: "Program to print lps",
                    codes: {
                      Javascript: {
                        code: `const lcs = function(str1, str2) {
                          const m = str1.length
                          const n = str2.length
                          const lookupTab = Array.from(Array(m + 1), () => Array(n + 1).fill(n + 1))
                          for (let i = 0; i <= m; i++) {
                              for (let j = 0; j <= n; j++) {
                                  if (i === 0 || j === 0) lookupTab[i][j] = 0
                                  else if (str1[i - 1] === str2[j - 1])
                                      lookupTab[i][j] = lookupTab[i - 1][j - 1] + 1
                                  else
                                      lookupTab[i][j] = Math.max(lookupTab[i - 1][j], lookupTab[i][j - 1])
                              }
                          }
                          const result = lookupTab[m][n]
                          let subsequence = Array(result).fill(null)
                          let len = result
                          let i = m,
                              j = n;
                          while (i > 0 && j > 0) {
                              if (str1[i - 1] == str2[j - 1]) {
                                  subsequence[len - 1] = str1[i - 1]
                                  i -= 1
                                  j -= 1
                                  len -= 1
                              }
                              else if (lookupTab[i - 1][j] > lookupTab[i][j - 1]) {
                                  i -= 1
                              } else {
                                  j -= 1
                              }
                          }
                          console.log(typeof str === "string"? subsequence.join("") : subsequence )
                          return result
                      }
                      
                        function lps(str) {
                          return lcs(str, typeof str === "string"? str.split("").reverse().join(): str.reverse());
                        }
                      
                        
                        let str = "abs123abs";
                        console.log(lps(str));
                      `,
                        output: `sbs
                      3`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "LCIS",
            content: (
              <>
                <Span>
                  <b>
                    Longest Common Increasing Subsequence Problem Statement (
                    LCS + LIS )
                  </b>
                </Span>
                <p>
                  Given two arrays, find length of the longest common increasing
                  subsequence [LCIS] and print one of such sequences (multiple
                  sequences may exist) Suppose we consider two arrays – arr1[] ={" "}
                  [3, 4, 9, 1] and arr2[] = [5, 3, 8, 9, 10, 2, 1] Our answer
                  would be 2 [3, 9] as this is the longest common subsequence
                  which is increasing also.
                </p>
                <CodeEditor
                  options={{
                    title: "LCIS",
                    codes: {
                      Javascript: {
                        code: `function lcis(arr1,arr2){
                          let m = arr1.length
                          let n = arr2.length
                          let lookupTab = Array(n).fill(0)
                          // tab represents table
                          let parent = Array(n).fill(0)
                          
                          for(let i=0;i<m;i++){
                            let current = 0, last = -1
                            for(let j=0;j<n;j++){
                              // if both elements are same
                              if (arr1[i] == arr2[j] && current + 1 > lookupTab[j]){
                                  lookupTab[j] = current + 1; 
                                  parent[j] = last
                              }  
                          
                              // if first element is greater than second
                              if (arr1[i] > arr2[j] && lookupTab[j] > current){
                                  current = lookupTab[j];
                                  last = j
                              } 
                            }
                          }
                          
                          const max = Math.max(...lookupTab)
                          let index = lookupTab.indexOf(max);
                          let result=Array(max).fill(null),i=0;
                          while(index!=-1){
                            result[i] = arr2[index]; 
                            index = parent[index]; 
                            i+=1
                          }
                          console.log("lookup table")
                          console.log(lookupTab)
                          result = result.reverse()
                          console.log(typeof arr1 !== "string" ? result : result.join(""))
                          return max
                          }
                          
                          const arr1 = [3, 4, 9, 1]
                          const arr2 = [5, 3, 8, 9, 10, 2, 1]
                          // 3, 9
                          const arr3 = "b3sak"
                          const arr4 = "baejkl"
                          // bk
                          console.log(lcis(arr1,arr2));
                          console.log("##############")
                          console.log(lcis(arr3,arr4));                                                                      
                          `,
                        output: `lookup table
                        [
                          0, 1, 0, 2,
                          0, 0, 1
                        ]
                        [ 3, 9 ]
                        2
                        ##############
                        lookup table
                        [ 1, 1, 0, 0, 2, 0 ]
                        bk
                        2`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "LBS + LDS",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Given an array[0..n-1] containing n positive integers, a
                  subsequence of arr[] is called Bitonic if it is first
                  increasing, then decreasing. <br />
                  [1,11,2,10,4,5,2,1]
                </Span>
                <p>
                  Longest Bitonic Subsequence of length 6 : 1 , 2, 10 , 4 , 2 ,1
                </p>
                <CodeEditor
                  options={{
                    title: "LBS + LDS",
                    codes: {
                      Javascript: {
                        code: `function print(list,n,arr,rev){
                  const tempList = rev ? list.slice().reverse() : list.slice()
                  const max = Math.max(...tempList)
                  let index = []
                  let tmp = max
                  for (let i = n - 1; i >= 0; i--) {
                      if (tempList[i] == tmp) {
                          index.push(i)
                          tmp--
                      }
                  }
                  index.reverse()
                  index = index.map(x => arr[x])
                  console.log("lookup")
                  console.log(list)
                  // console.log("subsequence")
                  // console.log(index)
                  // console.log(index.length)
                  console.log("######################")
              }
              
              function LBS(arr) {
                  const n = arr.length
                  let lis = Array(n).fill(1)
                  for (i = 1; i < n; i++)
                      for (j = 0; j < i; j++)
                          if (arr[i] > arr[j] && lis[i] < lis[j] + 1)
                              lis[i] = lis[j] + 1
                  let lds = Array(n).fill(1)
                  // for (i = 1; i < n; i++)
                  //     for (j = 0; j < i; j++)
                  //         if (arr[i] < arr[j] && lds[i] < lds[j] + 1)
                  //             lds[i] = lds[j] + 1
              
                  for (i = n-1; i >= 0; i--)
                      for (j = n-1; j > i; j--)
                          if (arr[i] > arr[j] && lds[i] < lds[j] + 1)
                              lds[i] = lds[j] + 1
                  
                  console.log("lis")
                  print(lis,n,arr)
                  console.log("lds")
                  print(lds,n,arr,true)
              
                  // let max = lis[0] + lds[0] - 1
                  // for(i=1;i<n;i++)
                  // if(lis[i] + lds[i] - 1>max)
                  // max = lis[i] + lds[i] - 1
                  // return max
              
                  // max value of lis[i] + lds[i] - 1
              
                  let maxVal = arr[0], inx = 0;
                  for(let i = 0; i < n; i++) {
                  if (maxVal < lis[i] + lds[i] - 1) {
                    maxVal = lis[i] + lds[i] - 1;
                    inx = i;
                  }
                  }
              
                  // store smaller elements at the start using lis
              
                  let ct1 = lis[inx];
                  let res = [];
                   for(let i = inx; i >= 0 && ct1 > 0; i--) {
                  if (lis[i] == ct1) {
                    res.push(arr[i]);
                    ct1--;
                  }
                 }
                res.reverse(); 
                let ct2 = lds[inx] - 1;
                for(let i = inx; i < n && ct2 > 0; i++) {
                  if (lds[i] == ct2) {
                    res.push(arr[i]);
                    ct2--;
                  }
                }
              
                console.log(res)
                console.log(res.length)
              }
              
              // LBS([80, 60, 30, 40, 20, 10])
              LBS([1,11,2,10,4,5,2,1])`,
                        output: `lis
              lookup
              [
                1, 2, 2, 3,
              lis
              lookup
              [
                1, 2, 2, 3,
                3, 4, 2, 1
              ]
              ######################
              lds
              lookup
              [
                1, 5, 2, 4,
                3, 3, 2, 1
              ]
              ######################
              [ 1, 11, 10, 4, 2, 1 ]
              6`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Russian Doll Envelope",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  You are given a 2D array of integers envelopes where
                  envelopes[i] = [wi, hi] represents the width and the height of
                  an envelope.
                  <br />
                  One envelope can fit into another if and only if both the
                  width and height of one envelope are greater than the other
                  envelope's width and height.
                  <br />
                  Return the maximum number of envelopes you can Russian doll
                  (i.e., put one inside the other).
                  <br />
                  Note: You cannot rotate an envelope.
                </Span>
                <p>
                  Example 1:
                  <br />
                  Input: envelopes = {`[[5,4],[6,4],[6,7],[2,3]]`} <br />
                  Output: 3 <br />
                  Explanation: The maximum number of envelopes you can Russian
                  doll is 3 {`([2,3] => [5,4] => [6,7])`}.{" "}
                </p>
                <CodeEditor
                  options={{
                    title: "Russian Doll Envelope",
                    codes: {
                      Javascript: {
                        code: `function maxEnvelopes(envelopes){
                          var n = envelopes.length;
                          if (n == 0)
                              return n;
                          envelopes.sort();
                          var dp = Array(n).fill(1);
                          for (var i = 1; i < n; ++i) {
                              for (var j = 0; j < i; ++j) {
                                  if (envelopes[i][0] > envelopes[j][0]
                                      && envelopes[i][1] > envelopes[j][1]
                                      && dp[i] < dp[j] + 1)
                                      dp[i] = dp[j] + 1;
                              }
                          }
                          // lookup dp table => [1, 2, 2, 3]
                          return Math.max(...dp);
                      }
                      var envelopes
                          = [ [ 4, 3 ], [ 5, 3 ], [ 5, 6 ], [ 1, 2 ] ];
                      console.log(maxEnvelopes(envelopes))`,
                        output: `3`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "(MCL (maxChainLength)) Maximum Length Chain Of Pairs",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  You are given n pairs of numbers . In every pair, the first
                  number is always smaller than the second number. A pair (a,b)
                  is followed by another pair (c,d) if b &lt; c. Find the
                  longest chain which can be formed from a given set of pairs
                </Span>
                <Span>Input [[5,24],[39,60],[15,28],[27,40],[50,90]]</Span>
                <Span>Output 3</Span>
                <p>Chain [[5,24],[27,40],[50,90]]</p>
                <CodeEditor
                  options={{
                    title: "MCL",
                    codes: {
                      Javascript: {
                        code: `// this problem is a variation of standard lis

                      class Pair {
                        constructor(a,b){
                          this.a = a
                          this.b = b
                        }
                      }
                      
                      function MCL(arr) {
                          const n = arr.length
                          let lookupTab = Array(n).fill(1)
                          for (i = 1; i < n; i++)
                              for (j = 0; j < i; j++)
                                  if (arr[i].a > arr[j].b && lookupTab[i] < lookupTab[j] + 1)
                                      lookupTab[i] = lookupTab[j] + 1
                          const max = Math.max(...lookupTab)
                          let index = []
                          let tmp = max
                          for (let i = n - 1; i >= 0; i--) {
                              if (lookupTab[i] == tmp) {
                                  index.push(i)
                                  tmp--
                              }
                          }
                          index.reverse()
                          index = index.map(x => arr[x])
                          console.log(index)
                          return max
                      }
                      
                      MCL([
                        new Pair(5,24),
                        new Pair(39, 60),
                        new Pair (27, 40), 
                        new Pair(50, 90)
                      ])`,
                        output: `[ Pair { a: 5, b: 24 }, Pair { a: 27, b: 40 }, Pair { a: 50, b: 90 } ]
                      3`,
                      },
                    },
                  }}
                />
                <p>
                  <b>Practice</b>
                </p>
                <CodeEditor
                  options={{
                    title: "lis+lcs+lps+mcl+lds+lbs+lcis",
                    codes: {
                      Javascript: {
                        code: `function lcs(str1,str2){
                        const m = str1.length
                        const n = str2.length
                        const dp = Array.from(Array(m+1),()=>Array(n+1).fill(0))
                        for(let i=0;i<=m;i++){
                          for(let j=0;j<=n;j++){
                            if(i==0 || j==0) dp[i][j] = 0
                            else if(str1[i-1]==str2[j-1]) dp[i][j] = 1 + dp[i-1][j-1]  
                            else dp[i][j] = Math.max(dp[i][j-1],dp[i-1][j])
                          }
                        }
                        let result = len = dp[m][n]
                        const str = Array(result).fill(null)
                        let i=m,j=n
                        while(i>0&&j>0){
                         if(str1[i-1]==str2[j-1]){
                           str[len-1]= str1[i-1]
                           i-=1
                           j-=1
                           len-=1
                         }
                         else if (dp[i - 1][j] > dp[i][j - 1])
                            i -= 1
                         else 
                            j -= 1
                        }
                        return str.join("")
                        }
                        console.log(lcs("abcdef","pqadef"))
                        
                        function lps(str){
                          return lcs(str,str.split("").reverse().join())
                        }
                        console.log(lps("abs123abs"))
                        
                        function lps1(str) {
                            const m = str.length
                            let dp = Array.from(Array(m), () => Array(m).fill(1))
                            for (let cl = 2; cl <= m; cl++) {
                                for (let i = 0; i < m - cl + 1; i++) {
                                    let j = i + cl - 1;
                                    if (str[i] == str[j] && cl == 2)
                                        dp[i][j] = 2;
                                    else if (str[i] == str[j])
                                        dp[i][j] = dp[i + 1][j - 1] + 2;
                                    else
                                        dp[i][j] = Math.max(dp[i][j - 1], dp[i + 1][j]);
                                }
                            }
                            return dp[0][m - 1]
                        }
                        console.log(lps1("abs123abs"))
                        
                        function lis(str){
                          const n = str.length
                          let i,j   
                          const dp = Array(n).fill(1)
                          for(i=1;i<n;i++){
                            for(j=0;j<i;j++){
                              if(str[i] > str[j] && dp[i]<dp[j]+1){
                               dp[i] = dp[j] + 1
                              }
                            }
                          }
                          const max = Math.max(...dp)
                          let tmp = max
                          const index = []
                          for(i=n-1;i>=0;i--){
                            if(dp[i]==tmp){
                              index.push(i)
                              tmp--
                            }
                          }
                          return index.map(x => str[x]).reverse()
                        }
                        
                        console.log(lis([10, 22, 9, 33, 21, 50, 41, 60]))
                        
                        class Pair {
                            constructor(a, b) {
                                this.a = a
                                this.b = b
                            }
                        }
                        function mcl(arr){
                        const n = arr.length
                        const dp = Array(n).fill(1)
                        let i,j
                        for(i=1;i<n;i++){
                          for(j=0;j<i;j++){
                             if(arr[i].a > arr[j].b && dp[i]<dp[j]+1){
                               dp[i] = dp[j] + 1
                             }
                          }
                        }
                        const max = Math.max(...dp)
                        let tmp = max
                        let index = []
                        for(i=n-1;i>=0;i--){
                          if(dp[i]==tmp){
                            index.push(i)
                            tmp--
                          }
                        }
                        console.log(index.map(x=>arr[x].a + " " + arr[x].b).reverse().join(' '))
                        }
                        
                        mcl([
                            new Pair(5, 24),
                            new Pair(39, 60),
                            new Pair(27, 40),
                            new Pair(50, 90)
                        ])
                        
                        
                        function lds(arr){
                        const n = arr.length
                        let dp = new Array(n).fill(1)
                        let i,j
                        for(i=n-1;i>=0;i--){
                          for(j=n-1;j>i;j--){
                            if(arr[i] > arr[j] && dp[i] < dp[j]+1){
                              dp[i] = dp[j] + 1
                            }
                          }
                        }
                        const max = Math.max(...dp)
                        console.log(max)
                        let temp = max
                        let index = []
                        for(i=0;i<n;i++){
                         if(dp[i]==temp){
                           index.push(i)
                           temp--
                         }
                        }
                        console.log(index.map(x=>arr[x]))
                        }
                        lds([10, 22, 9, 33, 21, 50, 41, 60])
                        
                        function lbs(arr){
                        const n = arr.length
                        let i,j
                        const lis = Array(n).fill(1)
                        const lds = Array(n).fill(1)
                        for(i=1;i<=n;i++){
                          for(j=0;j<i;j++){
                            if(arr[i]>arr[j] &&lis[i] < lis[j]+1)
                            lis[i] = lis[j] + 1 
                          }
                        }
                        for(i=n-1;i>-0;i--){
                          for(j=n-1;j>i;j--){
                            if(arr[i]>arr[j] && lds[i] < lds[j] +1)
                              lds[i] = lds[j] + 1
                          }
                        }
                        let max = arr[0], ind = 0
                        for(i=0;i<n;i++){
                          if(max<lis[i]+lds[i]-1){
                            max = lis[i]+lds[i]-1 
                            ind = i
                          }
                        }
                        console.log(max)
                        
                        let lis_index = lis[ind] 
                        let lds_index = lds[ind] - 1
                        const result = []
                        
                        for(i=ind;i>=0 && lis_index > 0;i--){
                          if(lis_index === lis[i]){
                            result.push(arr[i])
                            lis_index--
                          }
                        }
                        result.reverse()
                        for(i=ind;i<n && lds_index > 0;i++){
                          if(lds_index === lds[i]){
                            result.push(arr[i])
                            lds_index--
                          }
                        }
                        console.log(result)
                        }
                        
                        lbs([1, 11, 2, 10, 4, 5, 2, 1])
                        
                        function lcis(str1,str2){
                        let m = str1.length
                        let n = str2.length
                        let dp = Array(n).fill(0)
                        let parent = Array(n).fill(0)
                        for (let i = 0; i < m; i++) {
                          let current = 0, last = -1
                          for (let j = 0; j < n; j++) {
                              if (str1[i] == str2[j] && current + 1 > dp[j]) {
                                  dp[j] = current + 1;
                                  parent[j] = last
                              }
                              if (str1[i] > str2[j] && dp[j] > current) {
                                  current = dp[j];
                                  last = j
                              }
                          }
                        }
                        const max = Math.max(...dp)
                        console.log(max)
                        const result = Array(max).fill(null)
                        let index = dp.indexOf(max)
                        let i=0
                        while(index!=-1){
                        result[i] = str2[index]
                        index = parent[index]
                        i+=1
                        }
                        console.log(result.reverse().join(""))
                        }
                        
                        lcis("b3sak", "baejkl")
                        `,
                        output: `adef
                        sbs
                        3
                        [ 10, 22, 33, 41, 60 ]
                        5 24 27 40 50 90
                        2
                        adef
                        sbs
                        3
                        [ 10, 22, 33, 41, 60 ]
                        5 24 27 40 50 90
                        2
                        adef
                        sbs
                        3
                        [ 10, 22, 33, 41, 60 ]
                        5 24 27 40 50 90
                        2
                        [ 10, 9 ]
                        6
                        [ 1, 11, 10, 4, 2, 1 ]
                        2
                        bk`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Naive",
            content: (
              <>
                <Span>
                  String matching or searching algorithms try to find places
                  where one or several strings (also called patterns) are found
                  within a larger string (searched text):.
                </Span>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Using the given pattern , find out all the indexes at which
                  pattern is present at the given string.
                </Span>
                <Span>
                  <b>
                    Basically finding all the occurences of a particular string
                    say ‘pat’ in another string say ‘txt’ is called pattern
                    searching
                  </b>
                </Span>
                <Span>
                  The time complexity of Naïve Pattern Search method is O(m*n).
                  The m is the size of pattern and n is the size of the main
                  string.
                </Span>
                <p>
                  Input: txt[] = “THIS IS A TEST TEXT”
                  <br />
                  pat[] = “TEST”
                  <br />
                  Output: Pattern found at index 10
                </p>
                <CodeEditor
                  options={{
                    title: "Naive",
                    codes: {
                      Javascript: {
                        code: `function naive(txt,pat){
                          const m = pat.length
                          const n = txt.length
                          // console.log(pat,txt,m,n)
                          for(let i=0;i<=n-m;i++){
                            let j
                            for(j=0;j<m;j++){
                              if(txt[i+j]!=pat[j])
                              break;
                            }
                            if(j==m)
                              console.log("pattern found at:" + i)
                          }
                          }
                          
                          const txt = "AABAACAADAABAAABAA"
                          const pat = "AABA"
                          naive(txt,pat)
                          
                          // O(m*n)`,
                        output: `pattern found at:0
                        pattern found at:9
                        pattern found at:13`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Knuth Morris Pratt [ Degenerating Property ]",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Using the given pattern , find out all the indexes at which
                  pattern is present at the given string.
                </Span>
                <Span>
                  <b>Theory</b>
                </Span>
                <Span>
                  Knuth Morris Pratt Searching algorithm uses degenerating
                  property of the pattern and improves the worst case complexity
                  to O(n).
                </Span>
                <Span>
                  Degenerating property, means pattern having same sub-problems
                  appearing more than once in the pattern , are considered
                </Span>
                <Span>
                  Preprocessing involves constructing an lps array corresponding
                  to the pattern string of same size as the pattern string
                </Span>
                <Span>
                  lps indicates longest proper prefix which is also suffix.
                </Span>
                <Span>
                  proper prefixes of “ABC” are “”,”A”,”AB” and not “ABC”.Same
                  for suffix
                </Span>
                <Span>Examples</Span>
                <>
                  <p>1</p>
                  <p>pat[] A B X A B</p>
                  <p>lps[] 0 0 0 1 2</p>
                </>
                <>
                  <p>2</p>
                  <p>pat a b c d a b c a</p>
                  <p>lps 0 0 0 0 1 2 3 1</p>
                </>
                <br />
                <p>
                  Start from i=0 and j=0. if character matches increment i and j
                  , and update lps[j] by 1 else only update i by 1 .
                </p>
                <CodeEditor
                  options={{
                    title: "Knuth Morris Pratt [ Degenerating Property ]",
                    codes: {
                      Javascript: {
                        code: `/* 
                        Degenerating property, means pattern having same 
                        sub-problems appearing more than once in the pattern , 
                        are considered
                       
                        kmp uses degenerating property to reduce worst case complexity to O(n)
                       
                        lps (longest proper prefix) indicates longest
                        proper prefix which is also suffix.
                       
                        For e.g. proper prefixes of “ABC” are “”,”A”,”AB” and not “ABC”.
                       */
                       
                       function generateLps(pat,lps){
                       let j = 0
                       // j represents length of proper prefix
                       let i = 1
                       lps[0] = 0
                       const m = pat.length
                       
                       while(i<m){
                       if(pat[i]===pat[j]){
                         j++
                         lps[i] = j
                         i++
                         // if both elements matches
                         // increment i and j by 1 and update
                         // lps of i
                       }else {
                       if(j != 0)
                         j = lps[j-1]
                       else{
                         // if first two characters doesn't match 
                         // increment i by 1
                         lps[i] = j
                         i++
                       }
                       }
                       }
                       }
                       
                       function kmp(pat,txt){
                       
                       const m = pat.length
                       const n = txt.length
                       
                       let lps = Array(m).fill(0)
                       generateLps(pat,lps)
                       console.log("lps array:")
                       console.log(lps)
                       let i = j = 0
                       while(i<n){
                       if(pat[j]==txt[i]){
                         i++
                         j++
                       }
                       if(j==m){
                         console.log("pattern found at index: " + (i-j))
                         j = lps[j-1]
                       }
                       else if(i<n && pat[j]!=txt[i]){
                         if(j!=0) j=lps[j-1]
                         else i+=1
                       }
                       }
                       }
                       
                       /*
                       A B X A B
                       0 0 0 1 2
                       */
                       
                       kmp("ABXAB","ABXAB9ABXAB")
                       `,
                        output: `lps array:
                        [ 0, 0, 0, 1, 2 ]
                        pattern found at index: 0
                        pattern found at index: 6`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Rabin Karp",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Using the given pattern , find out all the indexes at which
                  pattern is present at the given string
                </Span>
                <Span>
                  Like the Naive Algorithm, Rabin-Karp algorithm also slides the
                  pattern one by one. But unlike the Naive algorithm, Rabin Karp
                  algorithm matches the hash value of the pattern with the hash
                  value of current substring of text, and if the hash values
                  match then only it starts matching individual characters.
                </Span>
                <Span>
                  <b>Time Complexity:</b>
                  <br />
                  The average and best-case running time of the Rabin-Karp
                  algorithm is O(n+m), but its worst-case time is O(nm). Worst
                  case of Rabin-Karp algorithm occurs when all characters of
                  pattern and text are same as the hash values of all the
                  substrings of txt[] match with hash value of pat[]. For
                  example pat[] = “AAA” and txt[] = “AAAAAAA”.
                </Span>
                <p>
                  The Rabin-Karp algorithm uses a rolling hash to detect the
                  presence of a desired substring. Because it's a hash function,
                  it maps many different strings to the same hash value If the
                  rolling hash produces a candidate match due to this hash
                  collision, which turns out not to be a string match, that is a
                  “spurious hit.”
                </p>
                <CodeEditor
                  options={{
                    title: "Rabin Karp",
                    codes: {
                      Javascript: {
                        code: `//  The Rabin-Karp algorithm uses a rolling hash to
                        //  detect the presence of a desired substring. 
                        //  Because it's a hash function, it maps many different 
                        //  strings to the same hash value. If the rolling 
                        //  hash produces a candidate match due to this
                        //  hash collision, which turns out not to be a string
                        //  match, that is a “spurious hit.”
                        
                        function rk(pat, txt, q)
                        {
                          let m = pat.length;
                          let n = txt.length;
                          let i, j;
                          
                          let p = 0;
                          let t = 0;
                          let h = 1;
                          let d = 256; 
                        
                          for(i = 0; i < m - 1; i++)
                            h = (h * d) % q;
                        
                          for(i = 0; i < m; i++)
                          {
                            p = (d * p + pat[i].charCodeAt()) % q;
                            t = (d * t + txt[i].charCodeAt()) % q;
                          }
                        
                          // p represents hash value of pattern
                          // t represents hash value of substring
                        
                          for(i = 0; i <= n - m; i++)
                          {
                            if (p == t)
                            {
                              for(j = 0; j < m; j++)
                              {
                                if (txt[i+j] != pat[j])
                                  break;
                              }
                            console.log("Pattern found at index " + i);
                            }
                        
                            // compute next hash
                            if (i < n - m)
                            {
                              t = (d * (t - txt[i].charCodeAt() * h) +
                                  txt[i + m].charCodeAt()) % q;
                        
                              if (t < 0)
                                t = (t + q);
                            }
                          }
                        }
                        
                        let txt = "ABC9ABC";
                        let pat = "ABC";
                        
                        let q = 101;
                        rk(pat, txt, q);
                        `,
                        output: `Pattern found at index 0
                        Pattern found at index 4`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Edit Distance",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Given two strings str1 and str2 and below operations that can
                  performed on str1. Find minimum number of edits (operations)
                  required to convert ‘str1’ into ‘str2’.
                </Span>
                <Span>Insert , Remove , Replace</Span>
                <Span>All of the above operations are of equal cost.</Span>
                <Span>Example</Span>
                <Span>Input: str1 = “geek”, str2 = “gesek”</Span>
                <Span>Output: 1</Span>
                <Span>We can convert str1 into str2 by inserting a ‘s’.</Span>
                <Span>Insert: Recur for m and n-1</Span>
                <Span>Remove: Recur for m-1 and n</Span>
                <p>Replace: Recur for m-1 and n-1</p>
                <CodeEditor
                  options={{
                    title: "Edit Distance",
                    codes: {
                      Javascript: {
                        code: `function min(x, y, z) {
                        if (x <= y && x <= z) return x;
                        if (y <= x && y <= z) return y;
                        else return z;
                        }
                        function editDist(str1, str2, m, n) {
                        if (m == 0) return n;
                        if (n == 0) return m;
                        if (str1[m - 1] == str2[n - 1]) return editDist(str1, str2, m - 1, n - 1);
                        return (
                        1 +
                        min(
                        editDist(str1, str2, m, n - 1), // Insert
                        editDist(str1, str2, m - 1, n), // Remove
                        editDist(str1, str2, m - 1, n - 1) // Replace
                        )
                        ); 
                        }
                        let str1 = "sunday";
                        let str2 = "saturday";
                        editDist(str1, str2, str1.length, str2.length)`,
                        output: `3`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Egg Dropping Problem",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Given n eggs and k floors, decide floors from which eggs
                  should be dropped such that total number of attempts are
                  minimized. Egg may or may not break at each attempt <br />
                  Example(s)
                  <br />
                  Example 1 : Given 1 egg and 10 floors, what is min attempt?
                  (worst case)
                  <br />
                  Ans 1 : 10
                  <br />
                  Example 2 : Given 10 eggs and 1 floor , what is min attempt?
                  (worst case)
                  <br />
                  Ans 2 : 1
                </Span>
                <p>
                  (1 + max( DP[i-1][j-1], DP[i][j-x] )) <br />
                  i =&gt; Number of eggs <br />j =&gt; Number of floors
                </p>
                <CodeEditor
                  options={{
                    title: "Egg Dropping Problem",
                    codes: {
                      Javascript: {
                        code: `function eggDrop(n,k){
                          const eggFloor = Array.from(Array(n+1),()=>Array(k+1)) 
                          let res,i,j,x;
                          // We need one trial for one floor and
                          // 0 trials for 0 floors
                          for (i = 1; i <= n; i++) {
                           eggFloor[i][1] = 1;
                           eggFloor[i][0] = 0;
                          }
                          // We always need j trials for one egg
                          // and j floors.
                          for (j = 1; j <= k; j++) 
                           eggFloor[1][j] = j;
                          for (i = 2; i <= n; i++) {
                          for (j = 2; j <= k; j++) {
                          eggFloor[i][j] = Number.MAX_SAFE_INTEGER;
                          for (x = 1; x <= j; x++) {
                          res = 1 + Math.max(eggFloor[i - 1][x - 1],eggFloor[i][j - x]);
                          if (res < eggFloor[i][j])
                          eggFloor[i][j] = res;
                          }
                          }
                          }
                           eggFloor.forEach(x=>console.log(x.join("  ")))
                           return eggFloor[n][k];
                         }
                         
                         let n = 2, k = 10;
                         
                         console.log("Minimum number of "  + "trials in worst case with "
                         + n + " eggs and " + k
                         + " floors is " + eggDrop(n, k));`,
                        output: `0  1  2  3  4  5  6  7  8  9  10
                        0  1  2  2  3  3  3  4  4  4  4
                        Minimum number of trials in worst case with 2 eggs and 10 floors is 4`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Friends Pairing Problem",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Given n friends, each one can remain single or can be paired
                  up with some other friend. <br />
                  Note : Each can be paired only once
                  <br />
                  Find out the total number of ways in which friends can remain
                  single or can be paired up
                </Span>
                <p>f(n) = f(n — 1) + (n — 1) * f(n — 2)</p>
                <CodeEditor
                  options={{
                    title: "Friends Pairing Problem",
                    codes: {
                      Javascript: {
                        code: `// 1) n-th person remains single, we recur
                      // for f(n — 1)
                      // 2) n-th person pairs up with any of the
                      // remaining n — 1 persons. We get (n — 1) 
                      // * f(n — 2)
                      // Therefore we can recursively write f(n) as:
                      // f(n) = f(n — 1) + (n — 1) * f(n — 2)
                      
                      function Sol(n){
                        let dp = Array(n+1).fill(null)
                        for (let i = 0; i <= n; i++) {
                        if (i <= 2)
                        dp[i] = i;
                        else
                        dp[i] = dp[i - 1] + (i - 1) * dp[i - 2];
                        }
                        return dp[n];
                      }
                      
                      console.log(Sol(4))`,
                        output: `10`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Maximum Size Square Sub Matrix With All 1s",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <p>
                  Given a binary matrix, find out the maximum size square
                  sub-matrix with all 1s
                  <br />
                  0 1 1 0 1 <br />
                  1 1 0 1 0 <br />
                  0 1 1 1 0<br />
                  1 1 1 1 0<br />
                  1 1 1 1 1<br />
                  0 0 0 0 0<br />
                </p>
                <CodeEditor
                  options={{
                    title: "Maximum Size Square Sub Matrix With All 1s",
                    codes: {
                      Javascript: {
                        code: `let R = 6;
                      let C = 5;
                      
                      function printMaxSubSquare(M) {
                        let i,j;
                        let S = [];
                      
                      for ( var y = 0; y < R; y++ ) {
                        S[ y ] = [];
                        for ( var x = 0; x < C; x++ ) {
                          S[ y ][ x ] = 0;
                        }
                      }
                        let max_of_s, max_i, max_j;
                        
                        for(i = 0; i < R; i++)
                          S[i][0] = M[i][0];
                        
                        for(j = 0; j < C; j++)
                          S[0][j] = M[0][j];
                          
                        for(i = 1; i < R; i++)
                        {
                          for(j = 1; j < C; j++)
                          {
                            if(M[i][j] == 1)
                              S[i][j] = Math.min(S[i][j-1],Math.min( S[i-1][j],
                                      S[i-1][j-1])) + 1;
                            else
                              S[i][j] = 0;
                          }
                        }
                      
                        max_of_s = S[0][0]; max_i = 0; max_j = 0;
                        for(i = 0; i < R; i++)
                        {
                          for(j = 0; j < C; j++)
                          {
                            if(max_of_s < S[i][j])
                            {
                              max_of_s = S[i][j];
                              max_i = i;
                              max_j = j;
                            }
                          }			
                        }
                      
                        console.log("Maximum size sub-matrix is:");
                        for(i = max_i; i > max_i - max_of_s; i--)
                        { 
                          let out = ""
                          for(j = max_j; j > max_j - max_of_s; j--)
                          {
                            out += M[i][j] + " "
                          }
                          console.log(out)
                        }
                      }
                      
                      
                      let M = [[0, 1, 1, 0, 1],
                          [1, 1, 0, 1, 0],
                          [0, 1, 1, 1, 0],
                          [1, 1, 1, 1, 0],
                          [1, 1, 1, 1, 1],
                          [0, 0, 0, 0, 0]];
                                
                      printMaxSubSquare(M);
                      `,
                        output: `Maximum size sub-matrix is:
                      1 1 1 
                      1 1 1 
                      1 1 1 `,
                      },
                    },
                  }}
                />
              </>
            ),
          },
        ],
      },
      bruteForce: {
        title: "Brute Force",
        content: (
          <>
            <Span>
              It's a direct method in which all possible methods are enumerated
              and it's guaranteed way to find the correct solution by listing
              all the possible candidate solutions for the problem.The brute
              force approach is inefficient. For real-time problems, algorithm
              analysis often goes above the O(N!) order of growth. It's
              execution time can quickly exceed the limits of computer in some
              cases.
            </Span>
            <Span>
              For Example: If there is a lock of 4-digit PIN. The digits to be
              chosen from 0-9 then the brute force will be trying all possible
              combinations one by one like 0001, 0002, 0003, 0004, and so on
              until we get the right PIN. In the worst case, it will take 10,000
              (10^4) tries to find the right combination.
            </Span>
            <Span>
              For Travelling salesman problem and Knapsack problem, there is no
              polynomial time solution. Hence they are classified as NP hard
              problem. But they can solved by using backtracking approach, that
              will increase the efficiency of the algorithm.
            </Span>
            <Span>
              Naive algorithm is simply a brute force aprroach but substring can
              be found in more efficient way using kmp and rabin karp
            </Span>
          </>
        ),
      },
      backtracking: {
        title: "Backtracking",
        types: [
          {
            title: "TSP",
            content: (
              <>
                <Span>
                  <b>Traveling Salesman Problem Statement</b>
                </Span>
                <Img left src={TSPBacktrack} />
                <p>
                  Given a set of cities and distance between every pair of
                  cities, the problem is to find the shortest possible route
                  that visits every city exactly once and returns back to the
                  starting point.
                </p>
                <CodeEditor
                  options={{
                    title: "TSP",
                    codes: {
                      Javascript: {
                        code: `
                        class Graph {
                          constructor(noOfVertices) {
                              this.noOfVertices = noOfVertices;
                              this.adjMatrix = Array.from(Array(noOfVertices), () => Array(noOfVertices).fill(0));
                              this.v = ['A', 'B', 'C', 'D']
                          }
                      
                          addEdge(u, v, wt) {
                              if ((u >= this.noOfVertices) || (v > this.noOfVertices)) {
                                  console.log("Vertex does not exists!");
                                  return
                              }
                              if (u == v) {
                                  console.log("Same Vertex!");
                                  return
                              }
                              this.adjMatrix[u][v] = wt
                              this.adjMatrix[v][u] = wt
                          }
                      
                          print() {
                              console.log(this.adjMatrix)
                          }
                      
                          bfs(start=this.adjMatrix[0][0]) {
                              let visited = {};
                              let q = [];
                              let output = ""
                              visited[start] = true;
                              q.push(start);
                              while (q.length != 0){
                                  let ele = q[0]
                                  q.shift();
                                  output += this.v[ele] + " ";
                                  for (let i=0;i<this.noOfVertices;i++) {
                                  if (this.adjMatrix[ele][i]&&!visited[i]){
                                    visited[i] = true;
                                    q.push(i)
                                  }
                                }
                              }
                              console.log(output)
                          }
                      
                          dfs(start=this.adjMatrix[0][0]) {
                              let output = { res: "" }
                              // strings are immutable in js and cannot be directly
                              // passed by reference
                              this.dfsHelper(start, {}, output)
                              console.log(output.res)
                          }
                      
                          dfsHelper(vert, visited, output) {
                              visited[vert] = true;
                              output.res += this.v[vert] + " "
                              for (let i=0;i<this.noOfVertices;i++) {
                                  // if some vertex is adjacent
                                  if (this.adjMatrix[vert][i]&&!visited[i])
                                      this.dfsHelper(i, visited, output);
                              }
                          }
                      
                          tsp(){
                           let result = []
                           let visited = Array(this.noOfVertices).fill(false)
                           this.tspHelper(0,1,0,result,visited)
                           console.log(Math.min(...result),result)
                          }
                      
                          tspHelper(curr,count,cost,result,visited){
                            // if exploration of all vertices is completed 
                            // ,simply backtrack to the initial vertex
                            if(count === this.noOfVertices && this.adjMatrix[curr][0]){
                              result.push(cost+this.adjMatrix[curr][0]) 
                              return
                            }
                            for(let i=0;i<this.noOfVertices;i++){
                              let curr_cost = this.adjMatrix[curr][i]
                              if(!visited[i]&&curr_cost){
                                visited[i] = true
                                this.tspHelper(i,count+1,cost+curr_cost,result,visited)
                                visited[i] = false
                              }
                            }
                          }
                      }
                      
                      const g = new Graph(4)
                      g.addEdge(0, 1, 20);
                      g.addEdge(0, 2, 42);
                      g.addEdge(0, 3, 25);
                      g.addEdge(1, 2, 30);
                      g.addEdge(1, 3, 34);
                      g.addEdge(3, 2, 10);
                      g.print();
                      console.log("DFS");
                      g.dfs();
                      console.log("BFS");
                      g.bfs();
                      g.tsp()                      
                      `,
                        output: `[
                          [ 0, 20, 42, 25 ],
                          [ 20, 0, 30, 34 ],
                          [ 42, 30, 0, 10 ],
                          [ 25, 34, 10, 0 ]
                        ]
                        DFS
                        A B C D 
                        BFS
                        A B C D 
                        85 [
                          124,  90,  85, 106, 124,
                          134, 131, 106,  90, 134,
                          131,  85
                        ]`,
                      },
                    },
                  }}
                />
                <p>
                  <b>Practice:</b>
                </p>
                <CodeEditor
                  options={{
                    title: "TSP(Backtracking)",
                    codes: {
                      Javascript: {
                        code: `class Graph {
  
                        constructor(n){
                         this.n = n
                         this.g = Array.from(Array(n),()=>Array(n).fill(0))
                        }
                        
                        addEdge(u,v,w){
                          this.g[u][v] = w
                          this.g[v][u] = w
                        }
                      
                        print(){
                          console.log(this.g)
                        }
                        
                        dfs(v=0,visited={}){
                          visited[v] = true
                          console.log(v)
                          for(let i=0;i<this.n;i++){
                            if(!visited[i] && this.g[v][i]){
                              this.dfs(i,visited)
                            }
                          }
                        }
                        
                        bfs(){
                         let q = []
                         let visited = []
                         visited[0] = true
                         q.push(0)
                         while(q.length){
                            const el = q.shift()
                            console.log(el)
                            for(let i=0;i<this.n;i++){
                               if(!visited[i] && this.g[el][i]){
                                 visited[i] = true
                                 q.push(i)
                               }
                            }
                         }
                        }
                        
                        tsp(){
                          let result = []
                          this.tspH(0,1,0,result)
                          console.log(Math.min(...result))
                        }
                      
                        tspH(curr,count,cost,result,visited=[]){
                          if (count === this.n && this.g[curr][0]) {
                              result.push(cost + this.g[curr][0])
                              return
                          }
                          for (let i = 0; i < this.n; i++) {
                              let curr_cost = this.g[curr][i]
                              if (!visited[i] && curr_cost) {
                                  visited[i] = true
                                  this.tspH(i, count + 1, cost + curr_cost, result, visited)
                                  visited[i] = false
                              }
                          }     
                        }
                       
                      }
                      
                      const g = new Graph(4)
                      g.addEdge(0, 1, 20);
                      g.addEdge(0, 2, 42);
                      g.addEdge(0, 3, 25);
                      g.addEdge(1, 2, 30);
                      g.addEdge(1, 3, 34);
                      g.addEdge(3, 2, 10);
                      g.print();
                      console.log("DFS");
                      g.dfs();
                      console.log("BFS");
                      g.bfs();
                      g.tsp()
                      
                      // class Graph {
                      //     constructor(noOfVertices) {
                      //         this.noOfVertices = noOfVertices;
                      //         this.adjMatrix = Array.from(Array(noOfVertices), () => Array(noOfVertices).fill(0));
                      //     }
                      //     addEdge(u, v, wt) {
                      //         if ((u >= this.noOfVertices) || (v > this.noOfVertices)) {
                      //             console.log("Vertex does not exists!");
                      //             return
                      //         }
                      //         if (u == v) {
                      //             console.log("Same Vertex!");
                      //             return
                      //         }
                      //         this.adjMatrix[u][v] = wt
                      //         this.adjMatrix[v][u] = wt
                      //     }
                      //     print() {
                      //         console.log(this.adjMatrix)
                      //     }
                      //     tsp() {
                      //         let allVisited = (1 << this.noOfVertices) - 1;
                      //         // we can make use of boolean array as well
                      //         //. ( 1 << 4) -1  => 16 -1 = 15
                      //         // or allVisited = Array(this.noOfVertices)
                      //         // .fill(false)
                      
                      //         // mask 1 (0001) represents person is at first 
                      //         // visits (obviously)
                      
                      //         // mask 15 (1111) represents person visits all cities 
                      
                      //         const lookupMem = Array.from(Array(1 << this.noOfVertices), () => Array(this.noOfVertices).fill(-1))
                      //         const res = this.tspHelper(1, 0, allVisited, lookupMem)
                      //         console.log("lookup")
                      //         console.log(lookupMem)
                      //         return res
                      //     }
                      
                      //     // TC -> n^2 * 2^n
                      //     // Each sub-problem (2^n) will take O (n) time to find a path to 
                      //     // remaining (n-1) cities.
                      //     // SC -> n2^n
                      
                      //     tspHelper(mask, pos, allVisited, dp) {
                      
                      //         if (mask == allVisited) {
                      //             return this.adjMatrix[pos][0]
                      //         }
                      //         // dist[pos][0] distance b/w last and first pos
                      
                      //         if (dp[mask][pos] != -1)
                      //             return dp[mask][pos]
                      //         // avoid overlapping subproblems
                      
                      //         let ans = Number.MAX_SAFE_INTEGER
                      //         for (let city = 0; city < this.noOfVertices; city++) {
                      //             // check for non visited neighbor
                      //             if ((mask & (1 << city)) == 0) {
                      //                 let newAns = this.adjMatrix[pos][city] + this.tspHelper((mask | (1 << city)), city, allVisited, dp)
                      //                 // update city's bit from 0 to 1 for e.g. from 0001 to 0011
                      //                 ans = Math.min(ans, newAns)
                      //             }
                      //         }
                      //         return dp[mask][pos] = ans
                      //     }
                      // }
                      
                      // const g = new Graph(4)
                      // const v = ['A', 'B', 'C', 'D']
                      // g.addEdge(0, 1, 20);
                      // g.addEdge(0, 2, 42);
                      // g.addEdge(0, 3, 25);
                      // g.addEdge(1, 2, 30);
                      // g.addEdge(1, 3, 34);
                      // g.addEdge(3, 2, 10);
                      // g.print();
                      // console.log(g.tsp())`,
                        output: `[
                        [ 0, 20, 42, 25 ],
                        [ 20, 0, 30, 34 ],
                        [ 42, 30, 0, 10 ],
                        [ 25, 34, 10, 0 ]
                      ]
                      DFS
                      0
                      1
                      2
                      3
                      BFS
                      0
                      1
                      2
                      3
                      85`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Sudoku",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Solve the given sudoku by replacing all the 0’s with
                  appropriate values ranging from 1 to 9.
                </Span>
                <Img left src={SudokuImg} />
                <CodeEditor
                  options={{
                    title: "Sudoku",
                    codes: {
                      Javascript: {
                        code: `function isSafe(grid, row, col, num)
                        {
                          // check horizontally
                          for(let j = 0; j < n; j++)
                            if (grid[row][j] == num)
                              return false;
                        
                          // check vertically
                          for(let i = 0; i < n; i++)
                            if (grid[i][col] == num)
                              return false;
                        
                         
                          let startRow = row - row % 3,  
                             startCol = col - col % 3; 
                        
                          // check within the section (3 * 3) (n = 3)
                          for(let i = 0; i < 3; i++)
                            for(let j = 0; j < 3; j++)
                              if (grid[i + startRow][j + startCol] == num)
                                return false;
                        
                          return true;
                        }
                        
                        
                        function solve(grid, row, col, n)
                        {
                          // avoid further tracking if all cells are explored 8 9
                          if (row == n - 1 && col == n)
                            return true;
                        
                          // move to next row
                          if (col == n){
                            row++; 
                            col = 0;
                          }
                        
                          // check for next element horizontally if element is already present 
                          if (grid[row][col] != 0)
                            return solve(grid, row, col + 1,n);
                        
                          // if present value is 0, replace it with values from 0-9
                          for(let num = 1; num <  n + 1; num++)
                          {
                            if (isSafe(grid, row, col, num))
                            {
                              grid[row][col] = num;
                              // check for next column
                              if (solve(grid, row, col + 1,n))
                                return true;
                            }
                            grid[row][col] = 0;
                          }
                          return false;
                        }
                        
                        let n = 9;
                        const sudoku = Array.from(Array(n),()=>Array(n).fill(0))
                        sudoku.forEach(x=>console.log(x.join(" ")))
                        const start = new Date().getTime();
                        console.log("#################")
                        solve(sudoku, 0, 0,n)
                        const end = new Date().getTime();
                        sudoku.forEach(x=>console.log(x.join(" ")))
                        console.log("#################")
                        console.log("Time required to solve sudoku: ")
                        console.log((end - start)/1000 + " s")
                        `,
                        output: `0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0
                        0 0 0 0 0 0 0 0 0
                        #################
                        1 2 3 4 5 6 7 8 9
                        4 5 6 7 8 9 1 2 3
                        7 8 9 1 2 3 4 5 6
                        2 1 4 3 6 5 8 9 7
                        3 6 5 8 9 7 2 1 4
                        8 9 7 2 1 4 3 6 5
                        5 3 1 6 4 2 9 7 8
                        6 4 2 9 7 8 5 3 1
                        9 7 8 5 3 1 6 4 2
                        #################
                        Time required to solve sudoku: 
                        0.001 s`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Knight Tour",
            content: (
              <>
                <Span>
                  <b> Problem Statement</b>
                </Span>
                <Span>
                  Given a N*N board with the Knight placed on the first block of
                  an empty board. Moving according to the rules of chess knight
                  must visit each square exactly once. Print the order of each
                  the cell in which they are visited. ( horse should visite all
                  the cells exactly once )
                </Span>
                <Img left src={KnightImg} alt="img" />
                <p>Note -&lt; board[7][4] will be 53 at above pic.</p>
                <CodeEditor
                  options={{
                    title: "Knight Tour",
                    codes: {
                      Javascript: {
                        code: `const print = b => b.forEach(x=>console.log(x.join(" ")))

                        function isSafe(x,y,board){
                          // coordinate should lie within the board
                           return x >=0 && x<n && y >= 0 && y<n && board[x][y] == -1
                        }
                        
                        function knightTour(board,xMove,yMove,n){
                          // place knight at (0,0) first
                          board[0][0] = 0
                          if(!knightTourGuide(0,0,1,board,xMove,yMove,n)){
                             return false
                          }
                          return true
                        }
                        
                        function knightTourGuide(x,y,move,board,xMove,yMove,n){
                          let next_x, next_y;
                          if(move == n*n) return true
                          for(let k=0;k<n;k++){
                            // find next move using array
                            next_x = x + xMove[k];
                            next_y = y + yMove[k];
                            if(isSafe(next_x,next_y,board)){
                              board[next_x][next_y] = move
                            // check for next move
                            if(knightTourGuide(next_x, next_y, move + 1, board, xMove, yMove,n)) 
                              return true
                            else
                              board[next_x][next_y] = -1 // backtrack
                          }
                          }
                          return false
                        }
                        
                        
                        const n = 8
                        // A Knight can make maximum eight moves
                        
                        console.log("Initial Board")
                        const board = Array.from(Array(n),()=>Array(n).fill(-1))
                        
                        // we need to memorize below two arrays:
                        const xMove = [ 2, 1, -1, -2, -2, -1, 1, 2 ];
                        // 2 -> move 2 cells right
                        const yMove = [ 1, 2, 2, 1, -1, -2, -2, -1 ];
                        // -1 -> move one cell downwards
                        
                        print(board)
                        console.log("Total No. Of Cells : " + n*n)
                        const start = new Date().getTime()
                        knightTour(board,xMove,yMove,n)
                        const end = new Date().getTime()
                        console.log("Solved Board")
                        print(board)
                        console.log("Time Required For Execution: "+ (end-start)/1000 + "s")`,
                        output: `Initial Board
                        -1 -1 -1 -1 -1 -1 -1 -1
                        -1 -1 -1 -1 -1 -1 -1 -1
                        -1 -1 -1 -1 -1 -1 -1 -1
                        -1 -1 -1 -1 -1 -1 -1 -1
                        -1 -1 -1 -1 -1 -1 -1 -1
                        -1 -1 -1 -1 -1 -1 -1 -1
                        -1 -1 -1 -1 -1 -1 -1 -1
                        -1 -1 -1 -1 -1 -1 -1 -1
                        Total No. Of Cells : 64
                        Solved Board
                        0 59 38 33 30 17 8 63
                        37 34 31 60 9 62 29 16
                        58 1 36 39 32 27 18 7
                        35 48 41 26 61 10 15 28
                        42 57 2 49 40 23 6 19
                        47 50 45 54 25 20 11 14
                        56 43 52 3 22 13 24 5
                        51 46 55 44 53 4 21 12
                        Time Required For Execution: 23.616s`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "N Queens",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  The N Queen is the problem of placing N chess queens on an N×N
                  chessboard so that no two queens attack each other
                </Span>
                <Img left src={NQueensImg} />
                <p>
                  4 Queen’s Solution : <br />[ 0, 1, 0, 0]
                  <br /> [ 0, 0, 0, 1]
                  <br /> [ 1, 0, 0, 0]
                  <br />[ 0, 0, 1, 0]
                </p>
                <CodeEditor
                  options={{
                    title: "N Queens",
                    codes: {
                      Javascript: {
                        code: `const print = b => b.forEach(x=>console.log(x.join(" ")))

                        function isSafe(board,row,col,n){
                           let i,j
                          
                           // left side
                           for(let i=0;i<col;i++)
                            if(board[row][i]==1) return false
                        
                          // upper diagonal
                          for (let i = row, j = col; i >= 0 && j >= 0; i--, j--)
                            if (board[i][j] == 1) return false;
                          
                          // lower diagnoal  
                          for (i = row, j = col; j >= 0 && i < n; i++, j--)
                            if (board[i][j] == 1) return false;
                        
                           return true
                        }
                        
                        function solve(board,n){
                           return placeQueens(board,0,n)
                        }
                        
                        function placeQueens(board,col,n){
                          // if all columns are explored, the board is solved
                          if (col >= n) return true;
                        
                          for (let i = 0; i < n; i++) {
                            if (isSafe(board, i, col)) {
                              board[i][col] = 1;
                              // check for next col 
                              if (placeQueens(board, col + 1,n)) return true;
                              board[i][col] = 0; // backtrack
                              }
                            }
                          return false;
                        }
                        
                        const n = 4
                        
                        console.log("Initial Board ( 4 Queen's )")
                        const board = Array.from(Array(n),()=>Array(n).fill(0))
                        print(board)
                        const start = new Date().getTime()
                        solve(board,n)
                        const end = new Date().getTime()
                        console.log("Solved Board ( 4 Queen's )")
                        print(board)
                        console.log("Time Required For Execution: " + (end - start) / 1000 + "s")`,
                        output: `Initial Board ( 4 Queen's )
                        0 0 0 0
                        0 0 0 0
                        0 0 0 0
                        0 0 0 0
                        Solved Board ( 4 Queen's )
                        0 0 1 0
                        1 0 0 0
                        0 0 0 1
                        0 1 0 0
                        Time Required For Execution: 0s`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "N Queens 2",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Img left src={NQueensImg2} />
                <p>
                  The n-queens puzzle is the problem of placing n queens on an n
                  x n chessboard such that no two queens attack each other.
                  Given an integer n, return the number of distinct solutions to
                  the n-queens puzzle.
                </p>
                <CodeEditor
                  options={{
                    title: "N Queens 2",
                    codes: {
                      Javascript: {
                        code: `const print = b => b.forEach(x=>console.log(x.join(" ")))

                        function isSafe(board,row,col,n){
                           let i,j
                          
                           // right side
                           for(let i=0;i<col;i++)
                            if(board[row][i]==1) return false
                        
                          // upper diagonal
                          for (let i = row, j = col; i >= 0 && j >= 0; i--, j--)
                            if (board[i][j] == 1) return false;
                          
                          // lower diagnoal  
                          for (i = row, j = col; j >= 0 && i < n; i++, j--)
                            if (board[i][j] == 1) return false;
                        
                           return true
                        }
                        
                        function solve(board,n,result){
                           return placeQueens(board,0,n,result)
                        }
                        
                        function placeQueens(board,col,n,result){
                          // if all columns are explored, the board is solved
                          if (col == n){
                            result.push(board.map((cell,i)=>cell.indexOf(1)))
                            return true
                          };
                          
                          let res = false
                          
                          for (let i = 0; i < n; i++) {
                            if (isSafe(board, i, col,n)) {
                              board[i][col] = 1;
                              // check for next col 
                              res = placeQueens(board, col + 1,n,result) || res;
                              board[i][col] = 0; // backtrack
                              }
                            }
                          return res;
                        }
                        
                        const n = 4
                        
                        console.log("Initial Board ( N = 4 )")
                        const board = Array.from(Array(n),()=>Array(n).fill(0))
                        print(board)
                        const result = []
                        const start = new Date().getTime()
                        solve(board,n,result)
                        const end = new Date().getTime()
                        console.log("Solved Board(s) ( 4 * 4 )")
                        result.forEach((r,k)=>{
                          let temp = Array.from(Array(n),()=>Array(n).fill(0))
                          for(let index in r) temp[index][r[index]] = 1
                          console.log("Board " + (k+1)  + " :")
                          print(temp)
                        })
                        console.log("Time Required For Execution: " + (end - start) / 1000 + "s")
                        console.log("No. of distinct solutions : " + result.length)
                        `,
                        output: `Initial Board ( N = 4 )
                        0 0 0 0
                        0 0 0 0
                        0 0 0 0
                        0 0 0 0
                        Solved Board(s) ( 4 * 4 )
                        Board 1 :
                        0 0 1 0
                        1 0 0 0
                        0 0 0 1
                        0 1 0 0
                        Board 2 :
                        0 1 0 0
                        0 0 0 1
                        1 0 0 0
                        0 0 1 0
                        Time Required For Execution: 0.001s
                        No. of distinct solutions : 2`,
                      },
                    },
                  }}
                />
                <p>
                  <b>More Efficient Backtracking Solution ( Bit Masking )</b>
                </p>
                <CodeEditor
                  options={{
                    title: "N Queens 2 (Bit Masking)",
                    codes: {
                      Javascript: {
                        code: `const print = b => b.forEach(x => console.log(x.join(" ")))

                        function dec2bin(dec){
                          // a >>> b shifts a to b places right and changes all right bits to 0
                          return (dec >>> 0).toString(2);
                        }
                        
                        function bin2dec(bin){
                          return parseInt(bin, 2).toString(10);
                        }
                        
                        function solve(board,row,row_mask,left_diag_mask,right_diag_mask,result,n){
                          
                          const all_rows_filled = (1<<n) - 1 // 2^4 - 1 = 16 - 1 = 15
                          // 1 1 1 1 = 1 + 2 + 4 + 8 = 15 
                          // 1111 & 0000 = 0000 all queens are places
                        
                          if (row_mask == all_rows_filled) {
                            result.push(board.map((cell, i) => cell.indexOf(1)))
                            return;
                          }
                        
                          let safe = all_rows_filled & (~(row_mask | left_diag_mask | right_diag_mask)); 
                          while(safe > 0){
                        
                            // extracts right-most set(1) bit 
                            let p = safe & (-safe);
                            let col = parseInt(Math.log(p) / Math.log(2));
                            board[row][col] = 1;
                            // move to next row
                            solve(board, row + 1, row_mask | p,
                                               (left_diag_mask | p) << 1,
                                               (right_diag_mask | p) >> 1,result,n);
                            // move to next col
                            safe = safe & (safe - 1);
                            board[row][col] = 0; // backtrack
                          }
                        }
                        
                        const n = 4
                        console.log("Initial Board ( N = 4 )")
                        const board = Array.from(Array(n), () => Array(n).fill(0))
                        print(board)
                        const row_mask = 0,left_diag_mask = 0,right_diag_mask =0,row =0 
                        const result = []
                        const start = new Date().getTime()
                        solve(board,row,row_mask,left_diag_mask,right_diag_mask,result,n)
                        const end = new Date().getTime()
                        console.log("Solved Board(s) ( 4 * 4 )")
                        result.forEach((r, k) => {
                            let temp = Array.from(Array(n), () => Array(n).fill(0))
                            for (let index in r) temp[index][r[index]] = 1
                            console.log("Board " + (k + 1) + " :")
                            print(temp)
                        })
                        console.log("Time Required For Execution: " + (end - start) / 1000 + "s")
                        console.log("No. of distinct solutions : " + result.length)`,
                        output: `Initial Board ( N = 4 )
                        0 0 0 0
                        0 0 0 0
                        0 0 0 0
                        0 0 0 0
                        Solved Board(s) ( 4 * 4 )
                        Board 1 :
                        0 1 0 0
                        0 0 0 1
                        1 0 0 0
                        0 0 1 0
                        Board 2 :
                        0 0 1 0
                        1 0 0 0
                        0 0 0 1
                        0 1 0 0
                        Time Required For Execution: 0.001s
                        No. of distinct solutions : 2`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Rate in a maze",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Img left src={MazeImg} />
                <p>
                  A Maze is given as N*N binary matrix of blocks where source
                  block is the upper left most block i.e., maze[0][0] and
                  destination block is lower rightmost block i.e.,
                  maze[N-1][N-1]. A rat starts from source and has to reach the
                  destination. The rat can move only in two directions: forward
                  and down.In the maze matrix, 0 means the block is a dead end
                  and 1 means the block can be used in the path from source to
                  destination.
                </p>
                <CodeEditor
                  options={{
                    title: "Rate in a maze",
                    codes: {
                      Javascript: {
                        code: `const print = b => b.forEach(x => console.log(x.join(" ")))

                        function isSafe(maze,x,y,n)
                        {
                            // coordinate should lie within the matrix
                            return (x >= 0 && x < n && y >= 0
                                && y < n && maze[x][y] == 1);
                        }
                        
                        function solve(maze,n,result)
                        {
                           return traverseMaze(maze, 0, 0, result,n)
                        }
                        
                        function traverseMaze(maze,x,y,result,n)
                        {
                            // if rate is at the last cell return true
                            if (x == n - 1 && y == n - 1
                              && maze[x][y] == 1) {
                              result[x][y] = 1;
                              return true;
                            }
                            if (isSafe(maze, x, y,n)) {
                              if (result[x][y] == 1)
                                return false;
                              result[x][y] = 1; 
                              // move right
                              if (traverseMaze(maze, x + 1, y, result,n))
                                return true;
                              // move down
                              if (traverseMaze(maze, x, y + 1, result,n))
                                return true;
                              // move left
                              if (traverseMaze(maze, x - 1, y, result,n))
                                return true;
                              // move upwards
                              if (traverseMaze(maze, x, y - 1, result,n))
                                return true;
                              result[x][y] = 0; // backtrack
                              return false;
                            }
                            return false;
                        }
                        
                        console.log("Maze: ")
                        const maze=[
                          [ 1, 0, 0, 0 ],
                          [ 1, 1, 0, 1 ],
                          [ 0, 1, 0, 0 ],
                          [ 1, 1, 1, 1 ] 
                          ];
                        print(maze)
                        const n = maze.length;
                        const result = Array.from(Array(n),()=>Array(n).fill(0))
                        const start = new Date().getTime()
                        solve(maze,n,result);
                        const end = new Date().getTime()
                        console.log("Result: ")
                        print(result)
                        console.log("Execution Time: " + (end - start)/1000 +"s")
                        `,
                        output: `Maze: 
                        1 0 0 0
                        1 1 0 1
                        0 1 0 0
                        1 1 1 1
                        Result: 
                        1 0 0 0
                        1 1 0 0
                        0 1 0 0
                        0 1 1 1
                        Execution Time: 0.001s`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "String Permutations",
            content: (
              <>
                <Span>
                  <b>Naive Method</b>
                </Span>
                <Img left src={PermutationsImg} />
                <p>
                  Note : I missed a link between root node and one of its child
                  at below pic 😂
                </p>
                <CodeEditor
                  options={{
                    title: "String Permutations",
                    codes: {
                      Javascript: {
                        code: `function permuteNaive(str,l,r){  // T(n)
                          if(l==r)
                            console.log(str.map(x=>String.fromCharCode(x)).join(""))
                          else{
                            for(let i=l;i<=r;i++){ // n + 1
                              if(str[l]) [str[l],str[i]] = [str[i],str[l]] // 1
                              permuteNaive(str,l+1,r) // n * T(n-1) 
                              if(str[l]) [str[l],str[i]] = [str[i],str[l]] // 1
                            }
                          }
                        }
                        
                        function permuteBacktrack(str,answer){
                          if(str.length === 0) {
                            console.log(answer)
                            return
                          }
                          
                          for(let i = 0 ;i < str.length; i++){
                            let ch = str[i];
                            let left = str.substring(0, i);
                            let right = str.substring(i + 1);
                            let rest = left + right;
                            permuteBacktrack(rest, answer + ch);
                          }
                        }
                        
                        // Recurrence Relation : T(n) = n * T(n-1) + O(1)
                        // Time Complexity : n! * n
                        // There are n! permutations and it requires O(n) time to print 
                        // a permutation.
                        
                        const str = "abc"
                        const n = str.length
                        const strArr = str.split("").map(x=>x.charCodeAt(0)) 
                        console.log("Naive")
                        permuteNaive(strArr,0,n-1)
                        // we can't pass string by reference as it's immutable in js
                        console.log("Backtracking")
                        permuteBacktrack(str,"")                        
                        `,
                        output: `Naive
                        abc
                        acb
                        bac
                        bca
                        cba
                        cab
                        Backtracking
                        abc
                        acb
                        bac
                        bca
                        cab
                        cba`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Array Permutations",
            content: (
              <>
                <p>
                  <b>Array Permutations Using Dfs</b>
                </p>
                <CodeEditor
                  options={{
                    title: "Array Permutations",
                    codes: {
                      Javascript: {
                        output: `[
                      [ 1, 2, 3 ],
                      [ 1, 3, 2 ],
                      [ 2, 1, 3 ],
                      [ 2, 3, 1 ],
                      [ 3, 1, 2 ],
                      [ 3, 2, 1 ]
                    ]`,
                        code: `/**
                    * @param {number[]} nums
                    * @return {number[][]}
                    */
                   var permute = function (nums) {
                     let res = [];
                     let visited = Array(nums.length).fill(false);
                     dfs(nums, res, [], visited);
                     return res;
                   };
                   
                   var dfs = function (nums, res = [], curr = [], visited = []) {
                     if (curr.length == nums.length) {
                       res.push(curr.slice());
                       return;
                     }
                     for (let i in nums) {
                       if (visited[i] === false) {
                         visited[i] = true;
                         curr.push(nums[i]);
                         dfs(nums, res, curr, visited);
                         curr.pop();
                         visited[i] = false;
                       }
                     }
                   };
                   
                   console.log(permute([1, 2, 3]));
                   
                   `,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Array Combinations",
            content: (
              <>
                <CodeEditor
                  options={{
                    title: "Array Combinations",
                    codes: {
                      Javascript: {
                        code: `/**
                    * @param {number} n
                    * @param {number} k
                    * @return {number[][]}
                    */
                   var dfs = function(n,k,s,res,curr=[]){
                     if(k==0){
                      res.push(curr.slice())
                      return
                     }
                     for(let i=s;i<=n;i++){
                     curr.push(i)
                     dfs(n,k-1,i+1,res,curr)
                     curr.pop()
                     }
                   }
                   
                   var combine = function(n, k) {
                     const res = [] 
                     dfs(n,k,1,res) 
                     return res
                   };
                   
                   combine(4,2)`,
                        output: `[ [ 1, 2 ], [ 1, 3 ], [ 1, 4 ], [ 2, 3 ], [ 2, 4 ], [ 3, 4 ] ]`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Word Break",
            content: (
              <>
                <Span>
                  <b>Problem Statement</b>
                </Span>
                <Span>
                  Given a valid sentence without any spaces between the words
                  and a dictionary of valid English words, find all possible
                  ways to break the sentence in individual dictionary words.
                </Span>
                <Img left src={WordBreakImg} reduceH />
                <CodeEditor
                  options={{
                    title: "Word Break",
                    codes: {
                      Javascript: {
                        code: `function breakWords(n,str,words,res=""){
                          for(let i=1;i<=n;i++){
                            const prefix = str.substring(0,i)
                            if(words.includes(prefix)){ // isSafe
                                if(i==n){
                                res += prefix
                                console.log(res)
                                return
                                }
                                breakWords(n-i,str.substring(i),words,res + prefix) // backtrack
                            }
                          }
                        }
                        
                        const words = ['I','Love','❤️','My','India']
                        const str = "I❤️India"
                        const n = str.length
                        breakWords(n,str,words)
                        `,
                        output: `I❤️India`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
          {
            title: "Subsets of a set",
            content: (
              <>
                <Span>
                  <b> Problem Statement</b>
                </Span>
                <Span>Find all possible subsets of the given set</Span>
                <Img left src={SubsetsImg} reduceH />
                <CodeEditor
                  options={{
                    title: "Subsets of a set",
                    codes: {
                      Javascript: {
                        code: `function subsets(i_set,result,subset=[],index=0){
                          result.push(subset.slice()) // subset.slice() is necessary
                          for(let i=index;i<i_set.length;i++){
                             subset.push(i_set[i])
                             subsets(i_set,result,subset,i+1)
                             subset.pop() // backtrack
                          }
                          return
                         }
                         
                         const i_set = [1,2,3]
                         const result = []
                         console.log("Initial Set : ")
                         console.log(new Set(i_set))
                         const start = new Date().getTime()
                         subsets(i_set,result)
                         const end = new Date().getTime()
                         console.log("Resultant Subsets : ")
                         console.log(new Set(result))
                         console.log("Execution Time: " + (end-start)/1000 + "s")
                         `,
                        output: `Initial Set : 
                        Set { 1, 2, 3 }
                        Subsets : 
                        Set {
                          [],
                          [ 1 ],
                          [ 1, 2 ],
                          [ 1, 2, 3 ],
                          [ 1, 3 ],
                          [ 2 ],
                          [ 2, 3 ],
                          [ 3 ]
                        }
                        Execution Time: 0s`,
                      },
                    },
                  }}
                />
              </>
            ),
          },
        ],
        content: (
          <>
            <Span>
              It's simply used to solve problems recursively by trying to build
              the solution incrementally, removing those solutions that fail to
              solve the problem incrementally
            </Span>
            <Span>
              By being greedy, the algorithm matches the longest possible part.
              DP guarantees optimal solution but not the greedy problems
            </Span>
            <Span>It makes use of depth first search (stack)</Span>
            <Span>
              Backtracking algorithms, upon failure, keep exploring other
              possibilities. Such algorithms begin afresh from where they had
              originally started, hence they backtrack (go back to the starting
              point).
            </Span>
            <Span>It's used to solve:</Span>
            <Span>
              Decision Problem — In this, we search for a feasible solution.
            </Span>
            <Span>
              Optimization Problem — In this, we search for the best solution.
            </Span>
            <Span>
              Enumeration Problem — In this, we find all feasible solutions.
            </Span>
            <Span>Examples:</Span>
            <Span>
              1. While solving sudoku, if we find that the current digit cannot
              provide us the solution. We simply backtrack and try next digit.
              This drops a set of permutations
            </Span>
            <Span>
              2. To get to an address, we go to a well-known landmark, then try
              the first lane, for example. If there is no success, we backtrack
              to the landmark again and try another lane (we may ask a passerby
              for help). We keep doing this until we get to the address or give
              up the search altogether.
            </Span>
            <Span>
              <b>Backtracking Vs Branch And Bound</b>
            </Span>
            <Span>
              1. Backtracking is used to find all possible solutions available
              to a problem.When it realises that it has made a bad choice, it
              undoes the last choice by backing it up. It searches the state
              space tree until it has found a solution for the
              problem.Branch-and-Bound is used to solve optimisation problems.It
              completely searches the state space tree to get optimal solution.
              It abondones pre-solution if it has a better optimal solution.
            </Span>
            <Span>
              2. Backtracking traverses the tree in dfs whereas branch and bound
              traverses the tree in any manner dfs or bfs
            </Span>
            <Span>
              3.Backtracking involves feasibility function. Branch-and-Bound
              involves a bounding function.
            </Span>
            <Span>4. Backtracking is more efficient.</Span>
            <Span>
              5. Backtracking is used in solving N-Queen Problem, Sum of subset.
              Branch and bound is used in solving Knapsack Problem, Travelling
              Salesman Problem.
            </Span>
          </>
        ),
      },
      branchAndBound: {
        title: "Branch And Bound",
        content: (
          <>
            <Span>
              Branch-and-Bound is used to solve optimisation problems.It
              completely searches the state space tree to get optimal solution.
              It abondones pre-solution if it has a better optimal solution..
              These problems are typically exponential in terms of time
              complexity and may require exploring all possible permutations in
              worst case
            </Span>
            <Span>
              The backtracking based solution works better than brute force by
              ignoring infeasible solutions. We can do better (than
              backtracking) if we know a bound on best possible solution subtree
              rooted with every node. If the best in subtree is worse than
              current best, we can simply ignore this node and its subtrees. So
              we compute bound (best solution) for every node and compare the
              bound with current best solution before exploring the node.
            </Span>
            <Span>
              Backtracking is used to find all possible solutions available to a
              problem.When it realises that it has made a bad choice, it undoes
              the last choice by backing it up. It searches the state space tree
              until it has found a solution for the problem.Branch-and-Bound is
              used to solve optimisation problems.It completely searches the
              state space tree to get optimal solution. It abondones
              pre-solution if it has a better optimal solution.
            </Span>
            <Span>Example:</Span>
            <p>
              <b>0-1 Knapsack</b>
            </p>
            <CodeEditor
              options={{
                title: "0-1 Knapsack",
                codes: {
                  "C++": {
                    code: `#include <bits/stdc++.h>
                    using namespace std;
                    struct Item
                    {
                      float weight;
                      int value;
                    };
                    
                    struct Node
                    {
                      int level, profit, bound;
                      float weight;
                    };
                    
                    bool cmp(Item a, Item b)
                    {
                      double r1 = (double)a.value / a.weight;
                      double r2 = (double)b.value / b.weight;
                      return r1 > r2;
                    }
                    
                    int bound(Node u, int n, int W, Item arr[])
                    {
                      if (u.weight >= W)
                        return 0;
                    
                      int profit_bound = u.profit;
                      int j = u.level + 1;
                      int totweight = u.weight;
                      while ((j < n) && (totweight + arr[j].weight <= W))
                      {
                        totweight += arr[j].weight;
                        profit_bound += arr[j].value;
                        j++;
                      }
                      if (j < n)
                        profit_bound += (W - totweight) * arr[j].value /
                                        arr[j].weight;
                    
                      return profit_bound;
                    }
                    
                    int knapsack(int W, Item arr[], int n)
                    {
                      sort(arr, arr + n, cmp);
                    
                      queue<Node> Q;
                      Node u, v;
                    
                      u.level = -1;
                      u.profit = u.weight = 0;
                      Q.push(u);
                      int maxProfit = 0;
                      while (!Q.empty())
                      {
                        u = Q.front();
                        Q.pop();
                    
                        if (u.level == -1)
                          v.level = 0;
                    
                        if (u.level == n-1)
                          continue;
                    
                        v.level = u.level + 1;
                        v.weight = u.weight + arr[v.level].weight;
                        v.profit = u.profit + arr[v.level].value;
                        if (v.weight <= W && v.profit > maxProfit)
                          maxProfit = v.profit;
                        v.bound = bound(v, n, W, arr);
                        if (v.bound > maxProfit)
                          Q.push(v);
                        v.weight = u.weight;
                        v.profit = u.profit;
                        v.bound = bound(v, n, W, arr);
                        if (v.bound > maxProfit)
                          Q.push(v);
                      }
                    
                      return maxProfit;
                    }
                    
                    int main()
                    {
                      int W = 10; 
                      Item arr[] = {{2, 40}, {3.14, 50}, {1.98, 100},
                            {5, 95}, {3, 30}};
                      int n = sizeof(arr) / sizeof(arr[0]);
                    
                      cout << "Maximum possible profit = "
                        << knapsack(W, arr, n);
                    
                      return 0;
                    }
                    `,
                    output: `Maximum possible profit = 235`,
                  },
                },
              }}
            />
          </>
        ),
      },
      randomized: {
        title: "Randomized",
        content: (
          <>
            <Span>
              An algorithm that uses random numbers to decide what to do next
              anywhere in its logic is called Randomized Algorithm. For example,
              in Randomized Quick Sort, we use random number to pick the next
              pivot (or we randomly shuffle the array). Typically, this
              randomness is used to reduce time complexity or space complexity
              in other standard algorithms.,
            </Span>
            <Span>
              Example: Generate integer from 1 to 7 with equal probability
            </Span>
            <Span>Formula R: 5*( random() -1 ) + random() </Span>
            <p>
              Suppose the specified function is random(), which generates random
              numbers from 1 to 5 with equal probability. The idea is to use the
              expression 5 × (random() - 1) + random() which uniformly produces
              random numbers in the range [1–25]. So if we exclude the
              possibility of the random number being one among [8–25] by
              repeating the procedure, we are left with numbers between 1 and 7
              having equivalent probability.
            </p>
            <CodeEditor
              options={{
                title: "Randomized",
                output: null,
                codes: {
                  C: {
                    code: `
                    #include <stdio.h>
#include <stdlib.h>
#include <time.h>
#include <string.h>
 
int random1() {
    return (rand() % 5) + 1;
}
int generate()
{
    int r;
    do {
        r = 5 * (random1() - 1) + random1();
    } while (r > 7);
 
    return r;
}

// To minimize the total number of calls to the random() function, stop the while loop at r <= 21 and use the modulo operator, as shown below:

int generate1()
{
    int r;
    do {
        r = 5 * (random1() - 1) + random1();
    } while (r > 21);
 
    return (r % 7) + 1;
}
 
int main(void)
{
    srand(time(NULL));
 
    int count[8];
    memset(count, 0, sizeof count);
    for (int i = 1; i <= 1000000; i++)
    {
        int val = generate();
        count[val]++;
    }
 
    for (int i = 1; i <= 7; i++) {
        printf("%d ~ %0.2f\n", i, count[i]/10000.0);
    }
 
    return 0;
}`,
                    output: `
                    1 ~ 14.23
2 ~ 14.34
3 ~ 14.27
4 ~ 14.28
5 ~ 14.32
6 ~ 14.28
7 ~ 14.28
                    `,
                  },
                  Python: {
                    code: `from random import randint
def generate():
    while True:
        x = randint(1, 5)
        y = randint(1, 5)
        r = 5 * (x - 1) + y
        if r <= 7:
            break
    return r
  
if __name__ == '__main__':
    count = [0] * 8
    for i in range(1000000):
        val = generate()
        count[val] = count[val] + 1
    for i in range(1, 8):
        print(f'{i}~{count[i]/10000}%')
                     `,
                    output: `
                    1~14.2294%
2~14.3086%
3~14.2761%
4~14.2849%
5~14.3211%
6~14.3153%
7~14.2646%
                    `,
                  },
                },
              }}
            />
          </>
        ),
      },
      "np-hard-vs-np-complete": {
        title: "Deterministic and Non Deterministic Algorithms",
        content: (
          <>
            <Span>
              <b>Decidable Problems</b>
            </Span>
            <Span>
              An undecidable problem is a problem for which there is no
              algorithm that can solve it.
            </Span>
            <Span>
              Example: The halting problem can be simply stated as follows -
              Given an input and a Turing machine, there is no algorithm to
              determine if the machine will eventually halt. (a suspension of
              movement or activity, typically a temporary one. )
            </Span>
            <Span>
              <b>Polynomial and Non - polynomial time algorithm</b>
            </Span>
            <Span>
              If the complexity of an algorithm is expressed as O (p(n)) where
              p(n) is some polynomial of n, then the algorithm is said to be a
              polynomial time algorithm. Generally, polynomial time algorithms
              are tractable. Any algorithm with a time complexity that cannot be
              bounded by such bound then this is known as non - polynomial
              algorithms
            </Span>
            <Span>
              <b>Deterministic and Non deterministic algorithms:</b>
            </Span>
            <Span>
              In deterministic algorithm, for a given particular input, the
              computer will always produce the same output going through the
              same states but in case of non-deterministic algorithm, for the
              same input, the compiler may produce different output in different
              runs. In fact non-deterministic algorithms can’t solve the problem
              in polynomial time and can’t determine what is the next step. The
              non-deterministic algorithms can show different behaviors for the
              same input on different execution and there is a degree of
              randomness to it and they cannot determine the next step of
              execution due to more than one path the algorithm can take.
            </Span>
            <Span>
              The algorithms in which the result of every algorithm is uniquely
              defined are known as the deterministic algorithm. In the
              theoretical framework, we can remove this restriction on the
              outcome of every operation. We can allow algorithms to contain
              operations whose outcomes are not uniquely defined but are limited
              to specified sets of possibilities. The machine executing each
              operation is allowed to choose any one of these outcomes subjects
              to a determination condition to be defined later. This leads to
              the concept of a Non-deterministic algorithm.
            </Span>
            <Span>
              There are three new functions which specify such types of
              algorithms are:
              <br />
              Choice(S) arbitrarily chooses one of the elements of the set S.
              <br />
              Failure() signals an unsuccessful completion.
              <br />
              Success() signals a successful completion.
            </Span>
            <Span>
              The assignments statement x: Choice (1, n) could result in x being
              assigned any one of the integers in the range [1, n]. There is no
              rule specifying how this choice is to be made. The Failure() and
              the Success() signals are used to define a computation of the
              algorithm. These statements cannot be used to effect a return.
              Whenever there is a set of the choices that lead to a successful
              completion, then one such set of the choices is always made and
              the algorithm terminates successfully. A non - deterministic
              algorithm terminates unsuccessfully if and only if there exists no
              set of the choices leading to a success signal. The computing
              times for the Choices, the Success, and the Failure are taken to
              be O (1). A machine capable of executing a non - deterministic
              algorithm in this way is called a non – deterministic machine.
            </Span>
            <Span>
              Problem Statement : Search an element x on A[1:n] where n&gt;=1,
              on successful search return j if a[j] is equals to x otherwise
              return 0.
              <br />
              Non-deterministic Algorithm for this problem :
              <br />
              j= choice(a, n)
              <br /> if(A[j]==x) <br />
              then [ write(j); success(); ]
              <br />
              write(0); failure();
            </Span>
            <Span>Deterministic vs Non Deterministic algorithms</Span>
            <Span>
              1. For a particular input the computer, deterministic algorithm
              will give always same output whereas for a particular input the
              computer, non deterministic algorithm will give different output
              on different execution.
            </Span>
            <Span>
              2. Deterministic algorithm can solve the problem in polynomial
              time whereas non-deterministic algorithm can’t solve the problem
              in polynomial time.
            </Span>
            <Span>
              3. Deterministic algorithm can determine the next step of
              execution whereas non-deterministic algorithm cannot determine the
              next step of execution due to more than one path the algorithm can
              take.
            </Span>
            <Span>
              The NP problems are set of problems whose solutions are hard to
              find but easy to verify and are solved by Non-Deterministic
              Machine in polynomial time.
            </Span>
            <Span>
              <b>NP-Hard Problem</b>
            </Span>
            <Span>
              A Problem X is NP-Hard if there is an NP-Complete problem Y, such
              that Y is reducible to X in polynomial time. NP-Hard problems are
              as hard as NP-Complete problems. NP-Hard Problem need not be in NP
              class.
            </Span>
            <Span>Example: Halting problem, Vertex cover problem, etc.</Span>
            <Span>
              <b>NP-Complete Problem</b>
            </Span>
            <Span>
              A problem X is NP-Complete if there is an NP problem Y, such that
              Y is reducible to X in polynomial time. NP-Complete problems are
              as hard as NP problems. A problem is NP-Complete if it is a part
              of both NP and NP-Hard Problem. A non-deterministic Turing machine
              can solve NP-Complete problem in polynomial time.
            </Span>
            <Span>
              Example: Determine whether a graph has a Hamiltonian cycle,
              Determine whether a Boolean formula is satisfiable or not,
              Circuit-satisfiability problem, etc.
            </Span>
            <Span>NP-Hard Vs NP-Complete Problem</Span>
            <Span>
              1. NP-Hard problems(say X) can be solved if and only if there is a
              NP-Complete problem(say Y) that can be reducible into X in
              polynomial time.NP-Complete problems can be solved by a
              non-deterministic Algorithm/Turing Machine in polynomial time.
            </Span>
            <Span>
              2. To solve NP-hard problem, it do not have to be in NP .To solve
              NP-Complete problem, it must be both NP and NP-hard problems.
            </Span>
            <Span>
              3.NP-Hard not have to be a Decision problem. problem. NP-Complete
              is exclusively a Decision problem.
            </Span>
          </>
        ),
      },
    },
  },
  interview: {
    title: "Interview Questions",
    types: {
      amazon: {
        title: "Amazon",
        content: (
          <>
            <Span>
              <b>Amazon Interview Experience</b>
            </Span>
            <Span>
              I was invited to complete an online assessment at hacker rank in 2
              hours ( 4 sections ) . There were a total of 2 questions ( 2
              sections ). I had to solve those two questions and explain my
              approach in two separate sections . They were easy. Video was kept
              off during the test. I don't exactly remember those questions but
              they were based on amazon internal tools. Maybe they were made by
              amazon employees itself.
            </Span>
            <Span>
              I was invited to complete an online assessment at hacker rank.
              There were a total of 2 questions. I had to solve those two
              questions and expalin my approach. They were easy.
            </Span>
            <Span>
              After the exam, I had to fill-up the survey form for about 15
              minutes. I applied for this exam when I was 20 ( 5th Semester ) .
            </Span>
            <Span>
              Note: While solving a question , don't move to the next question
              without submitting the previous question. Past changes can be
              modified.
            </Span>
            <Span>
              Screenshots of sample coding assessment are provided below.
            </Span>
            <Span>
              <b>Initial mail from hr</b>
            </Span>
            <Img src={Amazon1Img} large left />
            <Span>
              <b>Screenshots for online assessment:</b>
            </Span>
            <Img src={Amazon2Img} left large />
            <Img src={Amazon3Img} left large />
            <Img src={Amazon4Img} left large />
            <Span>
              <b>Coding Assessment Demo:</b>
            </Span>
            <Img src={Amazon5Img} left large />
            <Img src={Amazon6Img} left large />
            <Img src={Amazon7Img} left large />
            <Img src={Amazon8Img} left large />
            <Img src={Amazon9Img} left large />
            <Img src={Amazon10Img} left large />
            <Img src={Amazon11Img} left large />
            <Img src={Amazon12Img} left large />
            <Img src={Amazon13Img} left large />
            <Img src={Amazon14Img} left large />
          </>
        ),
      },
      paytm: {
        title: "Paytm",
        content: (
          <>
            <Span>
              <b>Paytm Interview Experience:</b>
            </Span>
            <Span>
              I applied for a software development internship with a referral.{" "}
            </Span>
            <Span>Note: It's always better to switch your video on.</Span>
            <Span>
              <b>Round 1 ( 45 minutes ): </b>
            </Span>
            <Span>
              Senior developer of paytm asked me a few questions based on my
              internship work such as ACID vs BASE, aws services , celery and he
              asked me about how I'm gonna implement a few things. He later
              asked me a few problem solving questions. At last , I asked him
              about the feedback and he said he's 95% sure that I'll receive the
              call for the next round soon.{" "}
            </Span>
            <Img src={Paytm1} left large />
            <p>
              <b>
                Problem Solving Q1: Search element in a sorted array using
                recursion ( Basic Test , obviously we should use binary search )
              </b>
            </p>
            <CodeEditor
              options={{
                title: "paytm-q1",
                output: null,
                codes: {
                  Javascript: {
                    code: `function recBinarySearch(arr, l, r, x){
                      if (r >= l) {
                          let mid = l + Math.floor((r - l) / 2);
                          if (arr[mid] == x)
                              return mid;
                          if (arr[mid] > x)
                              return binarySearch(arr, l, mid - 1, x);
                          return binarySearch(arr, mid + 1, r, x);
                      }
                      return -1;
                  }
                  
                  // every number can be represented as the sum of powers of 2.
                  // Hence it can solved using bitwise approach
                  
                  function binarySearch(arr, x)
                  {
                    let l = 0;
                    let r = arr.length - 1;
                    let mid;
                    while (r >= l) {
                      mid = l + Math.floor((r - l) / 2);
                      if (arr[mid] == x)
                        return mid;
                      if (arr[mid] > x)
                        r = mid - 1;
                      else
                        l = mid + 1;
                    }
                    return -1;
                  }
                  
                  const	arr =new Array(2, 3, 4, 10, 40);
                  const	x = 10;
                  const	n = arr.length;
                  const	result = binarySearch(arr, x);
                  console.log(result)
                  `,
                    output: `3`,
                  },
                },
              }}
            />
            <p>
              <b>
                Problem Solving Q2: Longest Common Increasing Subsequence Of Two
                Strings
              </b>
            </p>
            <CodeEditor
              options={{
                title: "LCIS",
                codes: {
                  Javascript: {
                    code: `function lcis(str11,str2){
                      let m = str1.length
                      let n = str2.length
                      let lookupTab = Array(n).fill(0)
                      // tab represents table
                      let parent = Array(n).fill(0)
                      
                      for(let i=0;i<m;i++){
                        let current = 0, last = -1
                        for(let j=0;j<n;j++){
                          // if both elements are same
                          if (str1[i] == str2[j] && current + 1 > lookupTab[j]){
                              lookupTab[j] = current + 1; 
                              parent[j] = last
                          }  
                      
                          // if first element is greater than second
                          if (str1[i] > str2[j] && lookupTab[j] > current){
                              current = lookupTab[j];
                              last = j
                          } 
                        }
                      }
                      
                      const max = Math.max(...lookupTab)
                      let index = lookupTab.indexOf(max);
                      let result=Array(max).fill(null),i=0;
                      while(index!=-1){
                        result[i] = str2[index]; 
                        index = parent[index]; 
                        i+=1
                      }
                      result = result.reverse()
                      console.log(result.join(""))
                      return max
                      }
                      
                      const str1 = "b3sak"
                      const str2 = "baejkl"
                      console.log(lcis(str1,str2));                                                                                     
                          `,
                    output: `bk
                    2`,
                  },
                },
              }}
            />
            <Span>
              <b>Round 2 ( 45 minutes ): </b>
            </Span>

            <Span>
              Senior developer of paytm ( who owns paytm.com ) asked me several
              questions. I showed him my code style. He asked me about my
              approach towards developing a few things.This round was purely
              based on development.
            </Span>

            <Img src={Paytm2} left large />
            <Span>
              <b>Round 3 ( 45 minutes ): </b>
            </Span>
            <Span>
              My manager asked me several questions based on development skills
              at this round.
            </Span>
            <Img src={Paytm3} left large />
            <Span>
              <b>Round 4 ( HR ): </b>
            </Span>
            <Span>
              I discussed with my hr regarding stipend and she later provided me
              with the onboarding guide.
            </Span>
            <Img src={Paytm4} left large />
            <Img src={Paytm5} left />
            <Img src={Paytm6} left />
            <Img src={Paytm7} left />
            <Img src={Paytm8} left />
            <Img src={Paytm9} left />
          </>
        ),
      },
      cred: {
        title: "CRED",
        content: (
          <>
            <Span>
              <b>CRED Interview Experience:</b>
            </Span>
            <Span>
              I applied for mobile development internship where the stack was:
              Flutter, Kotlin , Android and Swift
            </Span>
            <Span>
              During the first call from a member who belongs to the talent
              team, I was asked to complete the assignment within the first
              week.
            </Span>
            <Span>
              I completed the task in 4 hours and sent them an attached zip file
              as well as a drive link which consists of my resume , cover
              letter, source code, app screenshot and screen record.
            </Span>
            <Span>
              They were happy with my application and invited me for the second
              round. Two developers asked me several problem solving questions
              including a puzzle for about 2 hours, after proper introduction. I
              showed the interviewers my projects.
            </Span>
            <Span>
              <b>First Email Screenshot:</b>
            </Span>
            <Img src={CRED1} large />
            <Span>
              <b>Task Screenshots:</b>
            </Span>
            <Img src={CRED2} left />
            <Img src={CRED3} left />
            <Img src={CRED4} left large />
            <PlayerContainer
              style={{
                display: "flex",
                height: "auto",
                alignItems: "flex-start",
                width: "auto",
              }}
            >
              <ReactPlayer
                loop
                muted
                height="32rem"
                style={{
                  margin: "auto 0",
                }}
                width="auto"
                controls
                playing
                url="https://res.cloudinary.com/saiashish/video/upload/v1641297009/screen_record_krviny.mp4"
              />
            </PlayerContainer>
            <Span>
              <b>Interview Call Invitation:</b>
            </Span>
            <Img src={CRED5} left large />
            <p>
              <b>Problem Solving Q1: Valid Parenthesis</b>
            </p>
            <CodeEditor
              options={{
                title: "cred-q1",
                codes: {
                  Javascript: {
                    code: `var isValid = function(s) {
                      var match = {
                          ')': '(',
                          '}': '{',
                          ']': '['
                      }
                      var stack = []
                      for (let i in s) {
                          if (Object.values(match).includes(s[i])) {
                              stack.push(s[i])
                              continue
                          }
                          if (stack.length === 0 || match[s[i]] != stack.pop()) {
                              return false
                          }
                      }
                      return stack.length === 0
                  };
                  
                  console.log(isValid('()()'))`,
                    output: `true`,
                  },
                },
              }}
            />
            <Span>
              <b>
                Problem Solving Q2: What is n in O(n)? How time complexity and
                space complexity is related to n. At Q1, what will be complexity
                of Object.values(match).includes(s[i])?
              </b>
            </Span>
            <Span>n represents input size in O(n)</Span>
            <Span>
              In Asynmptotic analysis, we calculate, how the time (or space)
              taken by an algorithm increases with the input size.
              <br />
              The complexity of an algorithm is a function describing the
              efficiency of the algorithm in terms of the amount of data the
              algorithm must process.
              <br />
              Time Complexity of algorithm/code is not equal to the actual time
              required to execute a particular code but the number of times a
              statement executes.
              <br />
              Space Complexity of an algorithm is the total space taken by the
              algorithm with respect to the input size.
            </Span>
            <Span>
              Time Complexity of Object.values(match).includes(s[i]) will be
              O(1) since match is a constant. Object.values(match) should always
              be fixed.
            </Span>
            <p>
              <b>
                Problem Solving Q3: There are 5 given cities : Mumbai, Chennai,
                Delhi, Hyderabad, Bangalore and Kolkata. Start from any of the
                cities and traverse all cities without returning back to any
                city in between.
              </b>
            </p>
            <CodeEditor
              options={{
                title: "cred-q3",
                codes: {
                  Javascript: {
                    code: `class Graph {

                      constructor(n, v) {
                          this.n = n
                          this.g = {}
                          this.v = v
                      }
                  
                      addVertex(i) {
                          this.g[i] = []
                      }
                  
                      addEdge(u, v) {
                          this.g[u].push(v)
                          this.g[v].push(u)
                      }
                  
                      print() {
                          let res = {}
                          Object.entries(this.g).forEach((i, k) => {
                              res[this.v[i[0]]] = i[1].map(x => this.v[x])
                          })
                          console.log(res)
                      }
                  
                      dfs(v = 0, visited = {}) {
                          visited[v] = true
                          console.log(this.v[v])
                          const list = this.g[v]
                          for (let i of list) {
                              if (!visited[i]) this.dfs(i, visited)
                          }
                      }
                  
                  }
                  
                  const v = ['Mumbai', 'Chennai', 'Delhi', 'Hyderabad', 'Kolkata', 'Bangalore']
                  const g = new Graph(6, v)
                  for (let i in v)
                      g.addVertex(i)
                  g.addEdge(0, 1);
                  g.addEdge(1, 2);
                  g.addEdge(2, 3);
                  g.addEdge(3, 4);
                  g.addEdge(4, 5);
                  console.log("Graph ( with no cycles ):")
                  g.print()
                  console.log("DFS:")
                  g.dfs()
                      `,
                    output: `Graph ( with no cycles ):
                    {
                      Mumbai: [ 'Chennai' ],
                      Chennai: [ 'Mumbai', 'Delhi' ],
                      Delhi: [ 'Chennai', 'Hyderabad' ],
                      Hyderabad: [ 'Delhi', 'Kolkata' ],
                      Kolkata: [ 'Hyderabad', 'Bangalore' ],
                      Bangalore: [ 'Kolkata' ]
                    }
                    DFS:
                    Mumbai
                    Chennai
                    Delhi
                    Hyderabad
                    Kolkata
                    Bangalore`,
                  },
                },
              }}
            />
            <p>
              <b>
                Problem Solving Q4: Detection Of Loop In A Singly Linked List.
              </b>
            </p>
            <CodeEditor
              options={{
                title: "cred-q4",
                codes: {
                  Javascript: {
                    code: `class Node {
                          constructor(data) {
                              this.data = data
                              this.next = null
                          }
                      }
                      class LinkedList {
                          constructor() {
                              this.head = null
                          }
                          push(data) {
                              let node = new Node(data)
                              if (this.head == null) {
                                  this.head = node
                              } else {
                                  let temp = this.head
                                  while (temp.next) {
                                      temp = temp.next
                                  }
                                  temp.next = node
                              }
                          }
                          print() {
                              let temp = this.head
                              let output = ""
                              while (temp != null) {
                                  output += temp.data + " "
                                  temp = temp.next
                              }
                              console.log(output)
                          }
                      
                          loop(){
                           let temp = this.head
                           let s = new Set()
                           while(temp !== null){
                             if(s.has(temp))
                             return true
                             s.add(temp)
                             temp = temp.next
                           }
                           return false
                          }
                      
                      }
                      
                      const l = new LinkedList();
                      
                      console.log("Push:")
                      l.push(9);
                      l.push(8);
                      l.push(10);
                      l.print()
                      l.head.next = l.head
                      console.log(l.loop())
                      `,
                    output: `Push:
                        9 8 10 
                        true`,
                  },
                },
              }}
            />
            <p>
              <b>Problem Solving Q5: Two Pointers Technique.</b>
            </p>
            <CodeEditor
              options={{
                title: "cred-q5",
                output: null,
                codes: {
                  Javascript: {
                    code: `
                    function isPairSum(A, N, X)
{
	var i = 0;
	var j = N - 1;

	while (i < j) {

		if (A[i] + A[j] == X)
    {
      console.log({
        i,
        j,
        l: A[i],r:A[j] , X
      })
			return true;
    }
		else if (A[i] + A[j] < X)
			i++;
		else
			j--;
	}
	return false;
}

var arr = [ 3, 5, 9, 2, 8, 10, 11 ];
var val = 17;
var arrSize =7;

console.log(isPairSum(arr, arrSize, val));
                    `,
                    output: `{ i: 2, j: 4, l: 9, r: 8, X: 17 }
                    true`,
                  },
                },
              }}
            />
            <Span>
              <b>
                Problem Solving Q6: Implement your own custom data structure.
              </b>
            </Span>
            <Span>
              <b>Min Stack</b>
            </Span>
            <p>
              MinStack() initializes the stack object.
              <br />
              void push(int val) pushes the element val onto the stack.
              <br />
              void pop() removes the element on the top of the stack.
              <br />
              int top() gets the top element of the stack.
              <br />
              int getMin() retrieves the minimum element in the stack.
            </p>
            <CodeEditor
              options={{
                title: "cred-q6",
                codes: {
                  Javascript: {
                    code: `var MinStack = function() {
                      this.stack = []
                    };
                    
                    /** 
                     * @param {number} val
                     * @return {void}
                     */
                    MinStack.prototype.push = function(val) {
                      let min = val
                      if(this.stack.length) min = Math.min(this.stack.slice(-1)[0][1],val)
                      this.stack.push([val,min])
                    };
                    
                    /**
                     * @return {void}
                     */
                    MinStack.prototype.pop = function() {
                      this.stack.pop()  
                    };
                    
                    /**
                     * @return {number}
                     */
                    MinStack.prototype.top = function() {
                      return this.stack.slice(-1)[0][0] 
                    };
                    
                    /**
                     * @return {number}
                     */
                    MinStack.prototype.getMin = function() {
                      return this.stack.slice(-1)[0][1]
                    };
                    
                    var obj = new MinStack()
                    obj.push(1)
                    obj.push(2)
                    console.log(obj.top())
                    obj.pop()
                    console.log(obj.getMin())
                    `,
                    output: `2
                    1`,
                  },
                },
              }}
            />
            <Span>
              <b>
                Problem Solving Q7 ( Puzzle ): Divide a cake into 8 pieces using
                3 cuts.
              </b>
            </Span>
            <Img src={CRED6} left />
          </>
        ),
      },
      uber: {
        title: "Uber",
        content: (
          <>
            <Span>
              <b>Uber SDE Internship Interview Experience</b>
            </Span>
            <Span>
              I applied for the SDE off-campus internship opportunity via my
              linked in profile and their career's dashboard.
            </Span>
            <Span> Online test was conducted at codesignal. </Span>
            <Span>Total Number Of Questions Asked: 3</Span>
            <Span>Test link was active for 6 hours.</Span>
            <Span>Test Duration: 1hr</Span>
            <Span>
              <b>Q1. Base 2 To Base 6 Conversion.</b>
            </Span>
            <p>
              <b>1(a) Method 1:</b>
            </p>
            <CodeEditor
              options={{
                title: "uber-q1(a)",
                codes: {
                  Javascript: {
                    code: `function solution(base2){
                      return +parseInt(''+base2,2).toString(6)
                     }
                     
                     console.log(solution(1100)) // 12`,
                    output: `20`,
                  },
                },
              }}
            />
            <p>
              <b>1(b) Method 2:</b>
            </p>
            <CodeEditor
              options={{
                title: "uber-q1(a)",
                codes: {
                  Javascript: {
                    code: `function val(c)
                    {
                      if (c >= '0' && c <= '9')
                          return c.charCodeAt(0) - 48;
                      else
                          return c.charCodeAt(0) - 65 + 10;
                    }
                     
                    
                    function dec(s, base)
                    {
                      let len = s.length;
                      let power = 1;
                      let num = 0;
                      for (let i = len - 1; i >= 0; i--) {
                        num += val(s[i]) * power;
                        power = power * base;
                      }
                      return num;
                    }
                    
                    function reVal(num)
                    {
                      if (num >= 0 && num <= 9)
                        return String.fromCharCode(num + 48);
                      else
                        return String.fromCharCode(num - 10 + 65);
                    }
                    
                    function fromDec(base, inp)
                    {
                      let res = "";
                      while (inp > 0) {
                        res += reVal(inp % base);
                        inp = Math.floor(inp/base);
                      }
                      res = res.split("").reverse().join("");
                      return res;
                    }
                    
                    function convertBase(s, a, b)
                    {
                      let num = dec(s, a);
                      let res = fromDec(b, num);
                      return res
                    }
                    
                    function solution(base2){
                      return convertBase(""+base2,2,6)
                    }
                    
                    console.log(solution(1100)) // 12                    
                    `,
                    output: `20`,
                  },
                },
              }}
            />
            <p>
              <b>
                Q2. Given a set of time intervals and scores , pick the
                intervals in such a way that the score is maximum and their sum
                will be equal or nearest to total duration provided. ( I exactly
                don't remember this question )
              </b>
            </p>
            <CodeEditor
              options={{
                title: "uber-q2",
                codes: {
                  Javascript: {
                    code: `function weightedKnapsack(s, t, T) {
                      const n = s.length;
                      const dp = Array.from(Array(n + 1), () => Array(T + 1).fill(-1));
                      for (let i = 0; i <= n; i++) {
                          for (let w = 0; w <= T; w++) {
                              if (i == 0 || w == 0) {
                                  dp[i][w] = 0;
                              } else if (t[i - 1] <= w)
                                  dp[i][w] = Math.max(
                                      s[i - 1] + dp[i - 1][w - t[i - 1]], 
                                      dp[i - 1][w]
                                  );
                              else dp[i][w] = dp[i - 1][w];
                          }
                      }
                      return dp[n][T];
                    }
                    
                    function solution(score,intervals,duration){
                      return weightedKnapsack(score,intervals,duration)
                    }
                    
                    console.log(solution([10, 15, 40], [1, 2, 3], 6));`,
                    output: `65`,
                  },
                },
              }}
            />
            <Span>
              <b>
                Q3. A travel agent sends couple's from one city to another via
                the shortest path possible. If there are N cities and N - 1
                highways connecting them. Help him plan the trip for all the
                couples such that he maximizes the total distance travelled by
                all couples. You must return to the same node from where you've
                originated.
              </b>
            </Span>
            <Img left src={Uber20} reduceH />
            <CodeEditor
              options={{
                title: "uber-q3",
                codes: {
                  Javascript: {
                    code: `class Graph {
                      constructor(n, v) {
                          this.n = n
                          this.g = {}
                      }
                      addVertex(i) {
                          this.g[i] = {}
                      }
                      addEdge(u, v, w) {
                          this.g[u][v] = w
                          this.g[v][u] = w
                      }
                  
                      paths(source, destination) {
                        let result = []
                        const curr = []
                        curr.push(source)
                        this.pathsUsingDfs(source, destination, result, curr)
                        return Math.min(...result)
                      }
                  
                      pathsUsingDfs(s, d, result, curr = [], visited = {}) {
                          if (s === d) {
                            let path = curr.slice()
                            let max = 0
                            for(let i=0;i<path.length;i++){
                               if(this.g[path[i+1]]){
                                max+= this.g[path[i+1]][path[i]]
                               }
                            }
                            result.push(max)
                            return
                          }
                          visited[s] = true
                          const list = Object.keys(this.g[s]).map(x=>+x)
                          for (let i of list) {
                              if (!visited[i]) {
                                  curr.push(i)
                                  this.pathsUsingDfs(i, d, result, curr, visited)
                                  curr.splice(curr.indexOf(i), 1)
                              }
                          }
                          visited[s] = false
                      }
                      
                      solve(){
                       let entriesL = Object.entries(this.g).map(x=>[+x[0],Object.entries(x[1]).length])
                       const maxN = Math.max(...entriesL.map(x=>x[1]))
                       entriesL = entriesL.filter(e=>e[1]===maxN)[0]
                       const source = entriesL[0]
                       let max = 0
                       const neighbors = Object.entries(this.g[source])
                       const keys = Object.keys(this.g[source]).map(x=>+x)
                       for(let n of neighbors){
                        max+= n[1]
                       }
                       const others = [...Array(this.n).keys()].map(x=>+x+1).filter(x=>!keys.includes(x) && x !== source)
                       for(let i of others){
                         max += this.paths(source,i)
                       }
                       return max * 2
                      }
                  }
                  
                  function solution(n,distances){
                  const g = new Graph(n)
                  for(let i=1;i<=n;i++)
                  g.addVertex(i)
                  for(let d of distances){
                    g.addEdge(d[0],d[1],d[2])
                  }
                  console.log(g.g)
                  return g.solve()
                  }
                  
                  console.log(solution(4,[
                  [1,2,3],
                  [2,3,2],
                  [4,3,2],
                  ]));
                  
                  console.log(solution(5,[
                  [1,2,1],
                  [2,3,2],
                  [2,4,3],
                  [1,5,4]
                  ]));`,
                    output: `{
                      '1': { '2': 3 },
                      '2': { '1': 3, '3': 2 },
                      '3': { '2': 2, '4': 2 },
                      '4': { '3': 2 }
                    }
                    18
                    {
                      '1': { '2': 1, '5': 4 },
                      '2': { '1': 1, '3': 2, '4': 3 },
                      '3': { '2': 2 },
                      '4': { '2': 3 },
                      '5': { '1': 4 }
                    }
                    22`,
                  },
                },
              }}
            />
            <Span>
              <b>Screenshots:</b>
            </Span>
            {[
              Uber1,
              Uber2,
              Uber3,
              Uber4,
              Uber5,
              Uber6,
              Uber7,
              Uber8,
              Uber9,
              Uber10,
              Uber11,
              Uber12,
              Uber13,
              Uber14,
              Uber15,
              Uber16,
              Uber17,
              Uber18,
              Uber19,
            ].map((i, k) => (
              <Img key={k} src={i} large />
            ))}
          </>
        ),
      },
      vmware: {
        title: "VMWare",
        content: (
          <>
            <Span>
              <b>
                VMWare Member Of Technical Staff Internship Interview Experience
              </b>
            </Span>
            <Span>
              I received the mail from VMWare for an off campus{" "}
              <b>MTS internship</b> opportunity because of my professional
              network.
            </Span>
            <Span>
              I choosed a slot out of many using a form link provided by them.
            </Span>
            <Span>
              The Hackerrank test was mailed to me 30 min prior to the exam.
            </Span>
            <Span>
              There were a total of two questions and the video was kept on.
            </Span>
            <Span>
              <b>Test duration was 1 hour.</b>
            </Span>
            <Span>
              <b>
                Q1. Longest Increasing Common Subsequence Of Two Arrays Which
                Consists of 1, -1 and 0 Only.
              </b>
            </Span>
            <p>
              Given two arrays, find length of the longest common increasing
              subsequence [LCIS] and print one of such sequences (multiple
              sequences may exist). Suppose we consider two arrays – arr1[] =
              [-1, 0, 1, 0] and arr2[] = [1, -1, -1, 0, 1, 0, 1] Our answer
              would be 3 [-1,0,1] as this is the longest common subsequence
              which is increasing also.
            </p>
            <CodeEditor
              options={{
                title: `vmware-q1`,
                codes: {
                  Javascript: {
                    code: `function lcis(arr1, arr2) {
                      let m = arr1.length
                      let n = arr2.length
                      let lookupTab = Array(n).fill(0)
                      // tab represents table
                      let parent = Array(n).fill(0)
                  
                      for (let i = 0; i < m; i++) {
                          let current = 0,
                              last = -1
                          for (let j = 0; j < n; j++) {
                              // if both elements are same
                              if (arr1[i] == arr2[j] && current + 1 > lookupTab[j]) {
                                  lookupTab[j] = current + 1;
                                  parent[j] = last
                              }
                  
                              // if first element is greater than second
                              if (arr1[i] > arr2[j] && lookupTab[j] > current) {
                                  current = lookupTab[j];
                                  last = j
                              }
                          }
                      }
                  
                      const max = Math.max(...lookupTab)
                      let index = lookupTab.indexOf(max);
                      let result = Array(max).fill(null),
                          i = 0;
                      while (index != -1) {
                          result[i] = arr2[index];
                          index = parent[index];
                          i += 1
                      }
                      result = result.reverse()
                      console.log(result)
                      return max
                  }
                  
                  const arr1 = [-1,0, 1, 0]
                  const arr2 = [1, -1, -1, 0, 1, 0, 1]
                  console.log(lcis(arr1, arr2));                                                                                          
                      `,
                    output: `[ -1, 0, 1 ]
                    3`,
                  },
                },
              }}
            />
            <Span>
              <b>Q2. Knight Tour Problem (Backtracking)(dfs)(stack)</b>
            </Span>
            <Span>
              <b> Problem Statement</b>
            </Span>
            <Span>
              Given a N*N board with the Knight placed on the first block of an
              empty board. Moving according to the rules of chess knight must
              visit each square exactly once. Print the order of each the cell
              in which they are visited. ( horse should visite all the cells
              exactly once )
            </Span>
            <Img left src={KnightImg} alt="img" />
            <p>Note -&lt; board[7][4] will be 53 at above pic.</p>
            <CodeEditor
              options={{
                title: `vmware-q2`,
                codes: {
                  Javascript: {
                    code: `const print = b => b.forEach(x=>console.log(x.join(" ")))

                    function isSafe(x,y,board){
                      // coordinate should lie within the board
                       return x >=0 && x<n && y >= 0 && y<n && board[x][y] == -1
                    }
                    
                    function knightTour(board,xMove,yMove,n){
                      // place knight at (0,0) first
                      board[0][0] = 0
                      if(!knightTourGuide(0,0,1,board,xMove,yMove,n)){
                         return false
                      }
                      return true
                    }
                    
                    function knightTourGuide(x,y,move,board,xMove,yMove,n){
                      let next_x, next_y;
                      if(move == n*n) return true
                      for(let k=0;k<n;k++){
                        // find next move using array
                        next_x = x + xMove[k];
                        next_y = y + yMove[k];
                        if(isSafe(next_x,next_y,board)){
                          board[next_x][next_y] = move
                        // check for next move
                        if(knightTourGuide(next_x, next_y, move + 1, board, xMove, yMove,n)) 
                          return true
                        else
                          board[next_x][next_y] = -1 // backtrack
                      }
                      }
                      return false
                    }
                    
                    
                    const n = 8
                    // A Knight can make maximum eight moves
                    
                    console.log("Initial Board")
                    const board = Array.from(Array(n),()=>Array(n).fill(-1))
                    
                    // we need to memorize below two arrays:
                    const xMove = [ 2, 1, -1, -2, -2, -1, 1, 2 ];
                    // 2 -> move 2 cells right
                    const yMove = [ 1, 2, 2, 1, -1, -2, -2, -1 ];
                    // -1 -> move one cell downwards
                    
                    print(board)
                    console.log("Total No. Of Cells : " + n*n)
                    const start = new Date().getTime()
                    knightTour(board,xMove,yMove,n)
                    const end = new Date().getTime()
                    console.log("Solved Board")
                    print(board)
                    console.log("Time Required For Execution: "+ (end-start)/1000 + "s")`,
                    output: `Initial Board
                    -1 -1 -1 -1 -1 -1 -1 -1
                    -1 -1 -1 -1 -1 -1 -1 -1
                    -1 -1 -1 -1 -1 -1 -1 -1
                    -1 -1 -1 -1 -1 -1 -1 -1
                    -1 -1 -1 -1 -1 -1 -1 -1
                    -1 -1 -1 -1 -1 -1 -1 -1
                    -1 -1 -1 -1 -1 -1 -1 -1
                    -1 -1 -1 -1 -1 -1 -1 -1
                    Total No. Of Cells : 64
                    Solved Board
                    0 59 38 33 30 17 8 63
                    37 34 31 60 9 62 29 16
                    58 1 36 39 32 27 18 7
                    35 48 41 26 61 10 15 28
                    42 57 2 49 40 23 6 19
                    47 50 45 54 25 20 11 14
                    56 43 52 3 22 13 24 5
                    51 46 55 44 53 4 21 12
                    Time Required For Execution: 23.616s`,
                  },
                },
              }}
            />
            <Span>
              <b>Screenshots:</b>
            </Span>
            <Img src={Vmware1Img} large />
            <Img src={Vmware2Img} large />
            <Img src={Vmware3Img} large />
            <Img src={Vmware4Img} large />
            <Img src={Vmware5Img} large />
            <Img src={Vmware6Img} large />
            <Img src={Vmware7Img} large />
            <Img src={Vmware8Img} large />
            <Img src={Vmware9Img} large />
            <Img src={Vmware10Img} large />
            <Img src={Vmware11Img} large />
            <Img src={Vmware12Img} large />
          </>
        ),
      },
      razorpay: {
        title: "Razorpay",
        content: (
          <>
            <Span>
              <b>Razorpay Interview Experience (call) :</b>
            </Span>
            <Span>
              I got the call from razorpay . They asked me questions regarding
              ci/cd , graphql and various important libraries and
              frameworks,etc. I was able to answer all of them.
            </Span>
            <Span>They're ready to provide me full time employment.</Span>
            <Span>
              At the same time, I got the internship opportunity from paytm.{" "}
            </Span>
          </>
        ),
      },
      browserstack: {
        title: "Browserstack",
        content: (
          <>
            <Span>
              <b>Browserstack Interview Experience :</b>
            </Span>
            <Span>
              I got messages from their talent team for full time employment.
            </Span>
            <Span>
              My <b>friend</b> who earlier worked as a intern at CodeNation
              called me during the interview.
            </Span>
            <Span>
              He wanted me to explain how to continuously take input from a file
              and perform operations on it using javascript.
            </Span>
          </>
        ),
      },
      turing: {
        title: "Turing",
        content: (
          <>
            <Span>
              <b>Turing Interview Experience :</b>
            </Span>
            <Span>
              I updated my profile and passed multiple tests. Due to which I was
              able to attempt the live coding contest. My video was kept on.
            </Span>
            <Span>
              <b>Test duration was 30 minutes.</b>
            </Span>
            <Span>
              I was suggested to solve the first few ( ~20-30 ) leetcode
              questions before attempting the test.
            </Span>
            <Span>
              I received emails for remote jobs from the Turing afterwards.
            </Span>
            <Span>
              I don't remember second question but the first question was
              longest palindromic subsequence of two strings
            </Span>
            <p>
              <b>Q1. Longest Palindromic Subsequence Of Two Strings:</b>
            </p>
            <CodeEditor
              options={{
                title: "turing-q1",
                codes: {
                  Javascript: {
                    code: `const lcs = function(str1, str2) {
                      const m = str1.length
                      const n = str2.length
                      const lookupTab = Array.from(Array(m + 1), () => Array(n + 1).fill(n + 1))
                      for (let i = 0; i <= m; i++) {
                          for (let j = 0; j <= n; j++) {
                              if (i === 0 || j === 0) lookupTab[i][j] = 0
                              else if (str1[i - 1] === str2[j - 1])
                                  lookupTab[i][j] = lookupTab[i - 1][j - 1] + 1
                              else
                                  lookupTab[i][j] = Math.max(lookupTab[i - 1][j], lookupTab[i][j - 1])
                          }
                      }
                      const result = lookupTab[m][n]
                      let subsequence = Array(result).fill(null)
                      let len = result
                      let i = m,
                          j = n;
                      while (i > 0 && j > 0) {
                          if (str1[i - 1] == str2[j - 1]) {
                              subsequence[len - 1] = str1[i - 1]
                              i -= 1
                              j -= 1
                              len -= 1
                          }
                          else if (lookupTab[i - 1][j] > lookupTab[i][j - 1]) {
                              i -= 1
                          } else {
                              j -= 1
                          }
                      }
                      console.log(typeof str === "string"? subsequence.join("") : subsequence )
                      return result
                  }
                  
                    function lps(str) {
                      return lcs(str, typeof str === "string"? str.split("").reverse().join(): str.reverse());
                    }
                  
                    
                    let str = "abs123abs";
                    console.log(lps(str));
                  `,
                    output: `sbs
                  3`,
                  },
                },
              }}
            />
            <Span>
              <b>Screenshots:</b>
            </Span>
            <Img src={Turing1} left large />
            <Img src={Turing2} left />
            <Img src={Turing3} left large />
            <Img src={Turing4} left large />
          </>
        ),
      },
      stewards: {
        title: "Stewards ❤️",
        content: (
          <>
            <Span>
              <b>Stewards Internship Interview Experience :</b>
            </Span>
            <Span>This internship really changed my life.</Span>
            <Span>
              After completion of the first two tasks, I showed the CTO of the
              company ( Ukraine ) multiple projects during a interview call. He
              asked me questions about ACID and BASE , various concepts which I
              made use of in my projects, batch transactions, and recycler views
              in android,etc.
            </Span>
            <Span>
              Then I made a project with the CEO of the company ( London ), and
              someone who belongs to Brazil Government. My work was presented at
              Brazil media.
            </Span>
            <Span>
              My work can be found at the experience section of the home section
              by simply clicking at the desired logos. ( uvrobots + velvet.video
              + searchvaccines ) .
            </Span>
            <Span>
              <b>Interview Task 1:</b>
            </Span>
            <Span>
              <b>Input Formatter</b>
            </Span>
            <Span>
              const input1 = 12345.12 <br />
              const input2 = 3.12 <br />
              const input3 = 123456789
              <br />
              <br />
              function formatter(input){"{"}
              <br />
              <br />
              {"}"}
              <br />
              <br />
              // formatter(input1) === 12,345.12 <br />
              // formatter(input2) === 3.12 <br />
              // formatter(input3) === 123,456,789 //implement formatter. // Do
              not use Regex:
            </Span>
            <Img left large src={Stewards1} reduceH />
            <CodeEditor
              options={{
                title: "Stewards ❤️",
                codes: {
                  Javascript: {
                    code: `function convert(x){
                      const y=String(parseInt(x)).split("").reverse()
                      var z= []
                      for(let i=0;i<y.length;i+=3){
                         let u=y[i]
                         if(y[i+1])
                         u+= y[i+1]
                         if(y[i+2])
                         u+=y[i+2]
                         z.push(u)
                      }
                      var ans = z.join(",").split("").reverse().join("")
                      const diff = String(x-parseInt(x)).substring(2,String(x).length-String(parseInt(x)).length+1)
                      if(diff.length>0) ans+="."+ diff
                      return ans
                      }
                      console.log(convert(16.14))`,
                    output: `16.14`,
                  },
                },
              }}
            />
            <Span>
              <b>Interview Task 2:</b>
            </Span>
            <Span>
              <b>TikTok Clone ( Rs. 5000 for completion ): </b>
            </Span>
            <Img src={Stewards2} left large />
            <Span>
              <b>
                I showed the CEO, my instagram clone, he was impressed and I got
                selected after a final interview call, which I mentioned above
                with the CTO.
              </b>
            </Span>
            <Span>
              <b>Screenshots:</b>
            </Span>
            <Img src={Stewards3} left />
            <Img src={Stewards4} left />
          </>
        ),
      },
      inuvest: {
        title: "Inuvest",
        content: (
          <>
            <Span>
              <b>Inuvest Internship Interview Experience ( 3rd Semester ) :</b>
            </Span>
            <Span>
              I applied for a full stack python ( Django + Flask + Angular + ML
              + Postgres + AI + + AWS + Docker + Digital Ocean + Redis )
              internship via internshala.
            </Span>
            <Span>
              After completing the task, they called me . And three interviewers
              asked me questions on linked list operations, tricky questions on
              angular and various aws services. I still remember , the founder
              of this start-up was impressed with what I'm doing at this age.
            </Span>
            <Span>
              My work can be found at the experience section of the home section
              by simply clicking at the desired logo.
            </Span>
            <Span>Screenshots:</Span>
            <Img left src={Inuvest1} />
            <Img left src={Inuvest2} />
            <Span>Task Screenshots:</Span>
            <Img left src={Inuvest3} />
            <Img left src={Inuvest4} />
            <Img left src={Inuvest5} />
            <Img left src={Inuvest6} />
            <Img left src={Inuvest7} />
            <Img left src={Inuvest8} />
          </>
        ),
      },
      nex2Me: {
        title: "Nex2Me",
        content: (
          <>
            <Span>
              <b>Nex2Me Internship Interview Experience ( 3rd Semester ) :</b>
            </Span>
            <Span>
              I applied for a full stack java ( MongoDB + Express + React +
              Node.js + AWS + Docker ) internship via internshala. I showed them
              my amazon clone and I was given a task. They're impressed by it .
              Then,someone from NIT Raipur took my interview. After the
              interview, I was invited to be the interviewer of two candidates
              much older than me because of my knowledge.
            </Span>
            <Span>
              My work can be found at the experience section of the home section
              by simply clicking at the desired logo.
            </Span>
            <Span>
              <b>Screenshots:</b>
            </Span>
            <Span>
              <b>
                During the interview, I was asked one problem solving question:
              </b>
            </Span>
            <p>
              <b>
                Q. Return length of longest subarray of continuos 0s from an
                array which consists of 0s and 1s only
              </b>
            </p>
            <CodeEditor
              options={{
                title: "nex2me",
                codes: {
                  Java: {
                    code: `import java.io.*;
                    import java.util.*;
                    
                    class Main{
                      static PrintStream ps = System.out;
                      public static void main(String ...s){
                        int[] arr = {0,1,0,0};
                        // Map<Integer,Integer> map = new HashMap();
                        // for(int i:arr){
                        //   if(map.containsKey(i)){
                        //     map.put(i,map.get(i)+1);
                        //   }else{
                        //     map.put(i,1);
                        //   }
                        // }
                        // int max_count = map.get(0); 
                        int max = 0;
                        int count = 0;
                        for(int i=0;i<arr.length;i++){
                        // if(max_count == 0) break;    
                        if(arr[i]==1){
                          // max_count--;
                          count = 0;
                        }else {
                          count++;
                          max = Math.max(max,count);
                        }
                        }
                        ps.println(max);
                      }
                    }`,
                    output: `2`,
                  },
                },
              }}
            />
            <p>
              <b>Largest subarray with equal number of 0s and 1s</b>
            </p>
            <CodeEditor
              options={{
                title: "nex2me",
                codes: {
                  Java: {
                    code: `import java.util.HashMap;
                    import java.io.*;
                    
                    class Main {
                    
                      static PrintStream ps = System.out;
                    
                      int maxLen(int arr[], int n)
                      {
                    
                        HashMap<Integer, Integer> map
                          = new HashMap<Integer, Integer>();
                    
                        int sum = 0;
                    
                        int max_len = 0;
                        int ending_index = -1;
                        int start_index = 0;
                    
                        for (int i = 0; i < n; i++) {
                          arr[i] = (arr[i] == 0) ? -1 : 1;
                        }
                    
                        for (int i = 0; i < n; i++) {
                          sum += arr[i];
                          if (sum == 0) {
                            max_len = i + 1;
                            ending_index = i;
                          }
                          if (map.containsKey(sum)) {
                            if (max_len < i - map.get(sum)) {
                              max_len = i - map.get(sum);
                              ending_index = i;
                            }
                          }
                            map.put(sum, i);
                        }
                    
                        for (int i = 0; i < n; i++) {
                          arr[i] = (arr[i] == -1) ? 0 : 1;
                        }
                    
                        int end = ending_index - max_len + 1;
                        System.out.println(end + " to " + ending_index);
                        return max_len;
                      }
                    
                      public static void main(String[] args)
                      {
                        Main sub = new Main();
                        int arr[] = { 1, 0, 0, 1, 0, 1, 1 };
                        int n = arr.length;
                        ps.println(sub.maxLen(arr, n));
                      }
                    }
                    `,
                    output: `0 to 5
                    6`,
                  },
                },
              }}
            />
            <Img src={Nex2Me1} left />
            <Img src={Nex2Me2} left />
            <Img src={Nex2Me3} left />
            <Img src={Nex2Me4} left />
            <Img src={Nex2Me5} left />
            <Img src={Nex2Me6} left />
            <Img src={Nex2Me7} left />
            <Img src={Nex2Me8} left />
            <Img src={Nex2Me9} left />
            <Img src={Nex2Me10} left />
          </>
        ),
      },
      pracify: {
        title: "Pracify ( Mauka )",
        content: (
          <>
            <Span>
              <b>Pracify Internship Interview Experience ( 3rd Semester ) :</b>
            </Span>
            <Span>
              I applied for MERN ( MongoDB + Express + React + Node.js + AWS +
              Docker ) stack internship via internshala. I was invited to the
              office.
            </Span>
            <Span>
              This was later selected for 100x.vc and inc42 collaboration. My
              work can be found at the experience section of the home section by
              simply clicking at the desired logo.
            </Span>
            <Span>
              <b>Screenshots:</b>
            </Span>
            <Img src={Pracify5Img} left />
            <Img src={Pracify6Img} left />
            <Span>
              <b>Internship Task:</b>
            </Span>
            <Img src={Pracify1Img} left />
            <Img src={Pracify2Img} left />
            <Span>
              <b>Solution:</b>
            </Span>
            <Img src={Pracify3Img} left />
            <Img src={Pracify4Img} left />
          </>
        ),
      },
      appTown: {
        title: "App Town",
        content: (
          <>
            <Span>
              <b>App Town Technologies In-office Interview Experience:</b>
            </Span>
            <Span>
              I applied for a react and react native internship via internshala.
              I was invited to the office.And I was given a task to make a
              calculator with a provided UI. And picked on the basis of my
              resume.
            </Span>
            <Span>I worked on 5 react and react native projects.</Span>
            <Span>
              My work can be found at the experience section of the home section
              by simply clicking at the desired logo.
            </Span>
            <Span>
              <b>Screenshots:</b>
            </Span>
            <Img src={AppTown1} left />
            <Img src={AppTown2} left />
            <Img src={AppTown3} left />
            <Img src={AppTown4} left />
            <Img src={AppTown5} left />
            <Img src={AppTown6} left />
            <Img src={AppTown7} left />
            <Img src={AppTown8} left />
            <Img src={AppTown9} left />
          </>
        ),
      },
      openHouse: {
        title: "OpenHouse",
        content: (
          <>
            <Span>
              <b>OpenHouse Offer:</b>
            </Span>{" "}
            <Span>
              I got the invitation for a python / django developer opportunity
              along with several other offers.
            </Span>
            <Span>Screenshots:</Span>
            <Img src={Oh1} left />
            <Img src={Oh2} left />
            <Img src={Oh3} left />
          </>
        ),
      },
    },
    content: (
      <>
        <Span>
          I specified some of the start-ups and companies who took my interview
          above.
        </Span>
        <Span>
          Resume, projects, knowledge and quality of work matters a lot in order
          to get the call for round 1 from hr. Be confident, try to interact
          with interviewers friendly. Just don't lose your confidence in
          between.
        </Span>
        <Span>
          I insist you to go through data structures and algorithms sections
          before the interview. If you're smart enough, you can do that in a
          maximum of 2 days. And solve ( or understand ) ( with code complexity
          ) atleast first 50 leetcode questions using any language. You'll be
          able to crack problem solving rounds of any interview by solving first
          108 leetcode questions for beginner positions ( + internships ).
        </Span>
        <Span>Atmost there will be four rounds:</Span>
        <Span>
          <b>Round 1 ( Important ):</b>
        </Span>
        <Span>
          This round is mainly based on problem solving skills of the
          individual. There can be an online test of duration 30min - 1hr or
          online meeting with interviewers at zoom or google meet for the
          duration of 45min to 2hrs. In case of online meeting rounds, you'll be
          asked to pick your slot. At max 4 dsa ( Data Structures And Algorithms
          ) questions will be asked. At max. 2 senior employees will come in
          order to take your interview. They may or may not ask questions based
          on development skills. You can ask for feedback from the interviewers
          at the end.
        </Span>
        <Span>
          You'll be asked to write the code in a paper or in an online editor
          while sharing your screen. It's better to switch on your camera for a
          nice impression otherwise it's upon the interviewer. One puzzle can
          also be asked with the help of canvas tool.
        </Span>
        <Span>
          <b>Round 2 ( Very Important ) :</b>
        </Span>
        <Span>
          This will be based on your development skills and you might need to
          explain code of your previous projects. Interview will ask a few
          tricky questions based on development skills. They might even give a
          few tasks to implement. Duration will be between 45 min and 2 hours.
          You can ask the feedback from interviewers at the end.
        </Span>
        <Span>
          <b>Round 3 ( Congrats 🥳 you're selected ) :</b>
        </Span>
        <Span>
          This round may or may not happen. This will be similar to the second
          round. This round may be held with your manager.
        </Span>
        <Span>
          <b>Round 4:</b>
        </Span>
        <Span>
          This will be held with your hiring manager. You need to discuss the
          stipend or package with your hr. And you'll be provided with an
          instruction guide. In some cases, Hr will try to provide as less
          stipend as possible so beware of that.
        </Span>
        <Span>All the best ❤️. You'll make it 🤝.</Span>
      </>
    ),
  },
  leetcode: {
    title: "Leetcode Questions (1008+)",
    types: {
      q1: {
        title: "Q1. Two Sum",
        content: (
          <>
            <Span>
              <b>Q1. Two Sum</b>
            </Span>
            <Span>
              Given an array of integers nums and an integer target, return
              indices of the two numbers such that they add up to target.
              <br />
              You may assume that each input would have exactly one solution,
              and you may not use the same element twice.
              <br />
              You can return the answer in any order
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [2,7,11,15], target = 9 <br />
              Output: [0,1] <br />
              Output: Because nums[0] + nums[1] == 9, we return [0, 1].
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [3,2,4], target = 6 <br />
              Output: [1,2]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              2 &lt;= nums.length &lt;= 104 <br />
              -109 &lt;= nums[i] &lt;= 109 <br />
              -109 &lt;= target &lt;= 109 <br />
              Only one valid answer exists.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q1. Two Sum(HashMap)",
                output: `[ 0, 1 ]`,
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @param {number} target
                    * @return {number[]}
                    */
                   var twoSum = function(nums, target){
                   const map = {}
                   for(let i in nums){
                     let diff = target - nums[i]
                     if(map[diff])
                     return [+map[diff],+i]
                     else
                     map[nums[i]] = i
                   }
                   }
                   
                   twoSum([3,3],6)                   
                      `,
                  },
                  Java: {
                    code: `package com.programming;
                    import java.util.*;
                    
                    public class TwoSum {
                    
                            private static int[] twoSum(int[] nums, int target) {
                                Map<Integer, Integer> numMap = new HashMap<>();
                                for (int i = 0; i < nums.length; i++) {
                                    int complement = target - nums[i];
                                    if (numMap.containsKey(complement)) {
                                        return new int[] { numMap.get(complement), i };
                                    } else {
                                        numMap.put(nums[i], i);
                                    }
                                }
                                return new int[] {};
                            }
                    
                    
                        public static void main(String[] args) {
                            int ans[] = twoSum(new int[]{1,5,4}, 5);
                            Arrays.stream(ans).forEach(System.out::println);
                        }
                    
                    }
                    `,
                  },
                  "C++": {
                    code: `#include <bits/stdc++.h>
                    #include <iterator> 
                    using namespace std;
                    
                    class Solution
                    {
                    // O(n)
                    public:
                      static void twoSum(vector<int>& nums,int target){
                            unordered_map<int,int> m;
                            int arr[]={};
                            for(int i=0;i<nums.size();i++){
                                int temp  = target - nums.at(i);
                                if(m.find(temp) != m.end()){
                                  cout << "[ " << m[temp] << " , " << i << " ]";
                                }
                                m[nums[i]] = i;
                            }
                      };
                    };
                    
                    int main(int argc,char const *argv[]){
                    
                    #ifndef ONLINE_JUDGE
                        freopen("ip.in","r",stdin);
                        freopen("op.in","w",stdout);
                    #endif 
                       // string s;
                       // getline(cin,s);
                        std::vector<int> v {1,2,3};
                        Solution::twoSum(v,5);
                      return 0;
                    }
                    `,
                  },
                  Python: {
                    code: `def twoSum(a, b):
    x = {}
    i = 0
    while i < len(a):
        diff = b - a[i]
        if diff in x:
            return [x[diff],i]
        x[a[i]] = i
        i += 1


a = [3, 4, 5]

if __name__ == '__main__':
    print(twoSum(a, 8)) 
                `,
                  },
                  Kotlin: {
                    code: `fun twoSum(a: IntArray, b:Int): List<Int?>? {
                      var x = mutableMapOf<Int,Int>();
                      for((i,v) in a.withIndex()){
                          if(x.containsKey(b -a[i])){
                              return listOf(x[b -a[i]],i)
                          }
                          x[v] = i
                      }
                      return null
                  }
                  
                  fun main(){
                      val arr = intArrayOf(3,4,1);
                      val ans =twoSum(arr, 5)
                      print(ans)
                  }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q2: {
        title: "Q2. Add Two Numbers",
        content: (
          <>
            <Span>
              <b>Q2. Add Two Numbers</b>
            </Span>
            <Span>
              You are given two non-empty linked lists representing two
              non-negative integers. The digits are stored in reverse order, and
              each of their nodes contains a single digit. Add the two numbers
              and return the sum as a linked list. You may assume the two
              numbers do not contain any leading zero, except the number 0
              itself.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: l1 = [2,4,3], l2 = [5,6,4] <br />
              Output: [7,0,8] <br />
              Explanation: 342 + 465 = 807.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in each linked list is in the range [1, 100].{" "}
              <br />
              0 &lt;= Node.val &lt;= 9 <br />
              It is guaranteed that the list represents a number that does not
              have leading zeros.
            </Span>
            <Img src={LeetCodeQ2} left reduceH />
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q2. Add Two Numbers",
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                    }
                    
                    /**
                     * @param {ListNode} l1
                     * @param {ListNode} l2
                     * @return {ListNode}
                     */
                    
                    var addTwoNumbers = function(l1, l2) {
                      let carry = val1 = val2 = 0
                      console.log(l1,l2)
                      let curr = new ListNode()
                      let result = curr
                      while(l1!=null || l2!= null || carry != 0){
                         if(l1){
                           val1 = l1.val
                           l1=l1.next
                         }else{
                           val1 = 0
                         }  
                         if(l2){
                           val2 = l2.val
                           l2=l2.next
                         }else{
                           val2 = 0
                         }
                         carry += (val1 + val2)
                         curr.next = new ListNode(carry%10)
                         curr = curr.next
                         carry= parseInt(carry/10)   
                      }
                      return result.next
                    };
                    
                    // driver code
                    const num1 = [2,4,3]
                    const num2 = [5,6,4]
                    const l1 = new ListNode(2)
                    l1.next = new ListNode(4)
                    l1.next.next = new ListNode(3)
                    const l2 = new ListNode(5)
                    l2.next = new ListNode(6)
                    l2.next.next = new ListNode(4)
                    addTwoNumbers(l1,l2)`,
                    output: `ListNode {
                      val: 2,
                      next: ListNode { val: 4, next: ListNode { val: 3, next: null } }
                    } ListNode {
                      val: 5,
                      next: ListNode { val: 6, next: ListNode { val: 4, next: null } }
                    }
                    ListNode {
                      val: 7,
                      next: ListNode { val: 0, next: ListNode { val: 8, next: null } }
                    }`,
                  },
                  "C++": {
                    code: `#include<iostream>

                    using namespace std;
                    
                    struct ListNode {
                        int val;
                        ListNode *next;
                        ListNode() : val(0), next(nullptr) {}
                        ListNode(int x) : val(x), next(nullptr) {}
                        ListNode(int x, ListNode *next) : val(x), next(next) {}
                    };
                    
                    class Solution {
                    public:
                        ListNode* addTwoNumbers(ListNode* l1, ListNode* l2) {
                            int v1=0;
                            int v2 =0;
                            int carry=0;
                            
                            ListNode* current= new ListNode(0);
                            ListNode *ans= current;
                            while(l1!=nullptr || l2!=nullptr || carry!=0){
                                
                                if(l1!=nullptr){
                                    v1=l1->val;
                                    l1=l1->next;
                                }else{
                                    v1=0;
                                }
                                
                                if(l2!=nullptr){
                                    v2=l2->val;
                                    l2=l2->next;
                                }else{
                                    v2=0;
                                }
                                
                                carry+=(v1+v2);
                                
                                current->next= new ListNode (carry%10);
                                current=current->next;
                                carry/=10;
                                
                            }
                            return ans->next;
                        }
                    };
                    
                    int main(){
                     Solution s;
                     ListNode* l1= new ListNode(2);
                     l1->next = new ListNode(4);
                     l1->next->next = new ListNode(3);
                     ListNode* l2= new ListNode(5);
                     l2->next = new ListNode(6);
                     l2->next->next = new ListNode(4);
                     ListNode* result =  s.addTwoNumbers(l1,l2);
                     ListNode* temp;
                     temp = result;
                     while(temp!=nullptr){
                       cout << temp -> val << " ";
                       temp = temp -> next;
                     }
                     cout<< endl;
                     return 0;
                    }`,
                    output: `7 0 8 `,
                  },
                },
              }}
            />
          </>
        ),
      },
      q3: {
        title: "Q3. Longest Substring Without Repeating Characters",
        content: (
          <>
            <Span>
              <b>Q3. Longest Substring Without Repeating Characters</b>
            </Span>
            <Span>
              Given a string s, find the length of the longest substring without
              repeating characters.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "abcabcbb" <br />
              Output: 3 <br />
              Explanation: The answer is "abc", with the length of 3.
            </Span>
            <Span>
              <b> Example 2:</b>
            </Span>
            <Span>
              Input: s = "bbbbb" <br />
              Output: 1 <br />
              Explanation: The answer is "b", with the length of 1.
            </Span>
            <Span>
              <b> Example 3:</b>
            </Span>
            <Span>
              Input: s = "pwwkew" Output: 3 Explanation: The answer is "wke",
              with the length of 3. Notice that the answer must be a substring,
              "pwke" is a subsequence and not a substring.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= s.length &lt;= 5 * 104 <br />s consists of English
              letters, digits, symbols and spaces.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q3. Longest Substring Without Repeating Characters",
                output: `abcd`,
                codes: {
                  Javascript: {
                    output: `9`,
                    code: `/**
                    * @param {string} s
                    * @return {number}
                    */
                   
                  // slicing window technique
                  
                  function lengthOfLongestSubstring(s) {
                    const n = s.length;
                    let maxL = 0;
                    for (let i = 0; i < n; i++) {
                      const visited = Array(256);
                      for (let j = i; j < n; j++) {
                        if (visited[s.charCodeAt(j)]) break;
                        maxL = Math.max(maxL, j - i + 1);
                        visited[s.charCodeAt(j)] = true;
                      }
                    }
                    return maxL;
                  }                  
                  console.log(lengthOfLongestSubstring("abcbbcab"));
                  


                   // method -> a
                   
                   var lengthOfLongestSubstring = function(s) {
                     let ans = 0;
                     const count = Array(128).fill(0);
                     for (let l = 0, r = 0; r < s.length; ++r) {
                         ++count[s[r].charCodeAt(0)];
                         while (count[s[r].charCodeAt(0)] > 1)
                           --count[s[l++].charCodeAt(0)];
                         ans = Math.max(ans, r - l + 1);
                       }
                     return ans;
                   };
                   
                   const str = "abcdefhgjfghnbahggjhh";
                   lengthOfLongestSubstring(str)
                   
                   // method -> b
                   function convert(str) {
                       var current = 0,
                           st = 0,
                           max = 0,
                           start = 0;
                       let i;
                       var obj = {};
                       for (i = 0; i < str.length; i++) {
                           if (!obj[str[i]]) {
                               obj[str[i]] = i;
                           } else {
                               if (obj[str[i]] >= st) {
                                   current = i - st;
                                   if (max < current) {
                                       max = current;
                                       start = st;
                                   }
                                   st = obj[str[i]] + 1;
                               }
                               obj[str[i]] = i;
                           }
                       }
                       if (max < i - st) {
                           max = i - st;
                           start = st;
                       }
                   
                       return str.substring(start, start + max);
                   }
                   
                   // console.log(convert(str));
                    `,
                  },
                  Java: {
                    code: `import java.util.*;

                    class Main {
                    
                      public static String convert(String str){
                        int st=0,start=0,max=0,current=0;
                        HashMap<Character,Integer> pos = new HashMap<>();
                        int i;
                        for(i=0;i<str.length();i++){
                        if(!pos.containsKey(str.charAt(i))){
                           pos.put(str.charAt(i),i);
                        }else{
                            if(pos.get(str.charAt(i))>=st){
                              current = i - st;
                              if(max < current){
                                 max = current;
                                 start = st;
                              }
                              st = pos.get(str.charAt(i)) + 1;
                            }
                           pos.put(str.charAt(i),i);
                        }
                        }
                    if (max < i - st) {
                      max = i - st;
                      start = st;
                    }
                    return str.substring(start,start+max);
                      }
                    
                      public static void main(String[] args) {
                        String x ="abcderffaccfcvccf";
                        System.out.println(convert(x));
                      }
                    
                    }`,
                  },
                  Python: {
                    code: `
def convert(str):
  current=0
  max=0
  i=0
  start=0
  st=0
  obj={}
  for i in range(0,len(str)):
    if not str[i] in obj:
      obj[str[i]]=i
    else :
      if obj[str[i]] >= st:
        current = i - st
        if max < current:
          max= current
          start = st
        st = obj[str[i]]+1
      obj[str[i]]=i
    if max < i -st:
      max = i - st
      start = st
      
  return str[start:start+max]
   
str = "abcdgdkjhjacaiachius"
print(convert(str))`,
                  },
                  "C++": {
                    code: `#include <iostream>
                    #include <bits/stdc++.h>
                    using namespace std;
                    
                    string longest(string str){
                    
                    unordered_map<char,int> pos;
                    
                    int start,st=0,current,max=0;
                    
                    
                    // start represent's current location where new substring is to be inspected.
                    
                    // st represent's current location
                    
                    // current represent's present distance 
                    
                    int i;
                    
                    for(i=0;i<str.length();i++){
                       if(pos.find(str[i]) == pos.end()){
                          pos[str[i]]=i;
                          // if it's not existing
                       }else{
                          if(pos[str[i]] >= st){
                            current = i - st;
                            if(max<current){
                              max=current;
                              start=st;
                            }
                            st = pos[str[i]] + 1;   
                          }
                          pos[str[i]] = i;
                       }
                    }
                    if (max < i - st) {
                      max = i - st;
                      start = st;
                    }
                    return str.substr(start,start+max);
                    }
                    
                    int main() {
                      string str="abcdbabsbd";
                      cout<< longest(str)<<endl;
                      return 0;
                    }`,
                  },
                  Kotlin: {
                    code: `package com.programming

                    fun convert(str: String): String {
                        var st=0;
                        var start=0;
                        var max=0;
                        var current=0;
                        var pos = mutableMapOf<Char,Int>();
                        var i:Int=0;
                        for(i in 0 until str.length-1){
                            if(!pos.containsKey(str[i])){
                                pos[str[i]]=i;
                            }else{
                                if(pos[str[i]]!! >= st){
                                    current = i - st;
                                    if(current>max){
                                        max=current;
                                        start=st;
                                    }
                                    st = pos[str[i]]?.plus(1) ?: 0;
                                }
                                pos[str[i]]=i;
                            }
                        }
                        if(max<i-st){
                            max=i-st;
                            start=st;
                        }
                        return str.substring(start,start+max)
                    }
                    
                    fun main(){
                        val str:String = "abcderffaccfcvccf";
                        print(convert(str))
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q4: {
        title: "Q4. Median Of Two Sorted Arrays",
        content: (
          <>
            <Span>
              <b>Q4. Median of Two Sorted Arrays</b>
            </Span>
            <Span>
              Given two sorted arrays nums1 and nums2 of size m and n
              respectively, return the median of the two sorted arrays.
            </Span>
            <Span>The overall run time complexity should be O(log (m+n)).</Span>
            <Span>
              <b> Example 1:</b>
            </Span>
            <Span>
              Input: nums1 = [1,3], nums2 = [2] <br />
              Output: 2.00000 <br />
              Explanation: merged array = [1,2,3] and median is 2.
            </Span>
            <Span>
              <b> Example 2:</b>
            </Span>
            <Span>
              Input: nums1 = [1,2], nums2 = [3,4] <br />
              Output: 2.50000 <br />
              Explanation: merged array = [1,2,3,4] and median is (2 + 3) / 2 =
              2.5.
            </Span>
            <Span>
              <b>Constraints</b>
            </Span>
            <Span>
              nums1.length == m <br />
              nums2.length == n <br />
              0 &lt;= m &lt;= 1000 <br />
              0 &lt;= n &lt;= 1000 <br />
              1 &lt;= m + n &lt;= 2000 <br />
              -106 &lt;= nums1[i], nums2[i] &lt;= 106
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q4. Median of Two Sorted Arrays",
                output: "2.5",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums1
                    * @param {number[]} nums2
                    * @return {number}
                    */
                   
                   var findMedianSortedArrays = function(nums1, nums2) {
                   const list = [...nums1, ...nums2];
                   list.sort((a,b)=>a-b);
                   var median;
                   var mid;
                   mid = parseInt(list.length / 2);
                   if (list.length % 2 != 0) {
                     median = list[mid];
                   } else {
                     median = (list[mid - 1] + list[mid]) / 2;
                   }
                   return median;
                   };
                   
                   findMedianSortedArrays([3],[-2, -1])`,
                  },
                  "C++": {
                    code: `#include <iostream>
                    #include <vector>
                    #include <algorithm>
                    
                    using namespace std;
                    
                    class Solution {
                    public:
                        static double findMedianSortedArrays(vector<int>& nums1, vector<int>& nums2) {
                             vector<int> vec(nums1);
                             vec.insert(vec.begin(),nums2.begin(),nums2.end());
                             sort(vec.begin(),vec.end());
                             double median;
                             int mid; 
                             if(vec.size()==1){
                               return vec[0];
                             }
                             mid = (int) vec.size()/2.0;
                             if(vec.size()%2!=0){
                                median = vec[mid];
                             }else{
                                median = (vec[mid-1]+vec[mid])/2.0;
                             }
                             return median;
                        }
                    };
                    
                    int main() {
                      vector<int> v1{};
                      vector<int> v2{2,3};
                      cout<<Solution::findMedianSortedArrays(v1,v2)<<endl;
                      return 0;
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q5: {
        title: "Q5. Longest Palindromic Substring",
        content: (
          <>
            <Span>
              <b>Q5. Longest Palindromic Substring</b>
            </Span>
            <Span>
              Given a string s, return the longest palindromic substring in s.
            </Span>

            <Span>
              <b>Example 1:</b>
            </Span>

            <Span>
              Input: s = "babad" <br />
              Output: "bab" <br />
              Explanation: "aba" is also a valid answer.
            </Span>

            <Span>
              <b>Example 2:</b>
            </Span>

            <Span>
              Input: s = "cbbd" <br />
              Output: "bb"
            </Span>

            <Span>
              <b>Constraints:</b>
            </Span>

            <Span>
              1 &lt;= s.length &lt;= 1000 <br />s consist of only digits and
              English letters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q5. Longest Palindromic Substring",
                output: `bab`,
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {string}
                    */
                   
                   var longestPalindrome = function(s) {
                       let n = s.length;
                       let lookupTab = Array.from(Array(n),()=>Array(n).fill(false));
                       let maxLength = 1;
                       for (let i = 0; i < n; ++i)
                         lookupTab[i][i] = true;
                       let start = 0;
                       for (let i = 0; i < n - 1; ++i)
                       {
                         if (s[i] == s[i + 1])
                         {
                           lookupTab[i][i + 1] = true;
                           start = i;
                           maxLength = 2;
                         }
                       }
                       for (let k = 3; k <= n; ++k) {
                         for (let i = 0; i < n - k + 1; ++i)
                         {
                           let j = i + k - 1;
                           if (lookupTab[i + 1][j - 1]
                             && s[i] == s[j]) {
                             lookupTab[i][j] = true;
                             if (k > maxLength) {
                               start = i;
                               maxLength = k;
                             }
                           }
                         }
                       }
                       return s.substring(start, start+maxLength);
                   }
                     
                   console.log(longestPalindrome("babad"));
                   `,
                  },
                },
              }}
            />
          </>
        ),
      },
      q6: {
        title: "Q6. ZigZag Conversion",
        content: (
          <>
            <Span>
              <b>Q6. ZigZag Conversion</b>
            </Span>
            <>
              <Span>
                The string "PAYPALISHIRING" is written in a zigzag pattern on a
                given number of rows like this: (you may want to display this
                pattern in a fixed font for better legibility)
              </Span>
              <Span>
                P -- A -- H -- N <br />
                A P L S I I G <br />Y -- I -- R
              </Span>
              <Span>
                And then read line by line: "PAHNAPLSIIGYIR" <br />
                Write the code that will take a string and make this conversion
                given a number of rows: <br />
                string convert(string s, int numRows);
              </Span>
              <Span>
                <b>Example 1:</b>
              </Span>
              <Span>
                Input: s = "PAYPALISHIRING", numRows = 3 <br />
                Output: "PAHNAPLSIIGYIR"
              </Span>
              <Span>
                <b>Example 3:</b>
              </Span>
              <Span>
                Input: s = "A", numRows = 1 <br />
                Output: "A"
              </Span>
              <Span>
                <b>Constraints</b>
              </Span>
              <Span>
                1 &lt;= s.length &lt;= 1000 <br />
                s consists of English letters (lower-case and upper-case), ','
                and '.'. <br />1 &lt;= numRows &lt;= 1000
              </Span>
              <Span>
                <b>Complexity:</b>
              </Span>
              <p>
                Time: O(n)
                <br />
                Space: O(n)
              </p>
              <CodeEditor
                options={{
                  title: `Q6. ZigZag Conversion`,
                  output: `PAHNAPLSIIGYIR`,
                  codes: {
                    Javascript: {
                      code: `/**
                      * @param {string} s
                      * @param {number} numRows
                      * @return {string}
                      */
                     var convert = function(s, numRows) {
                       if (numRows === 1 || s.length < numRows) {
                         return s;
                       }
                       let rows = Array.from(Array(numRows), () => []);
                       let currentRow = 0;
                       let reverse = false;
                       let result = "";
                     
                       for (let i = 0; i < s.length; i++) {
                         rows[currentRow].push(s[i]);
                         if (reverse === false) {
                           currentRow++;
                         } else {
                           currentRow--;
                         }
                     
                         if (currentRow === numRows - 1 || currentRow === 0) {
                           reverse = !reverse;
                         }
                       }
                     
                       rows.forEach((row) => {
                         result += row.join("");
                       });
                     
                       return result;
                     }
                     
                     convert("PAYPALISHIRING", 3);
                     `,
                    },
                    Java: {
                      code: `import java.util.ArrayList;
                      import java.util.Collections;
                      import java.util.List;
                      
                      class Main {
                      
                          public static String convert(String s,int numRows){
                              if(numRows==0||s.length()<numRows){
                                  return s;
                              }
                              int currentRow=0;
                              boolean reverse=false;
                              String result="";
                              List<ArrayList<Character>> rows = new ArrayList<ArrayList<Character>>();
                              for(int i=0;i<numRows;i++){
                                 rows.addAll(Collections.singleton(new ArrayList<Character>()));
                              }
                              
                              for (int i=0;i<s.length();i++){
                                  rows.get(currentRow).add(s.charAt(i));
                                  if(!reverse){
                                      currentRow++;
                                  }else{
                                      currentRow--;
                                  }
                                  if(currentRow == numRows -1 || currentRow ==0 ){
                                      reverse = !reverse;
                                  }
                              }
                      
                              for(int i=0;i<rows.size();i++){
                              String str="";
                              for(int j=0;j<rows.get(i).size();j++){
                                str += rows.get(i).get(j);    
                              }
                              result+= str;
                              }
                              
                              return result;
                          }
                      
                          public static void main(String[] args) {
                              System.out.println(convert("PAYPALISHIRING", 3));
                          }
                          
                      }`,
                    },
                    Python: {
                      code: `
def convert(s, numRows):
    if numRows == 1 or len(s) < numRows:
        return s

    rows = []
    currentRow = 0
    reverse = False
    result = ""

    for i in range(0, numRows):
        rows.insert(i, [])

    for i in range(0, len(s)):
        rows[currentRow].append(s[i])

        if reverse == False:
            currentRow += 1
        else:
            currentRow -= 1

        if currentRow == numRows - 1 or currentRow == 0:
            reverse = not reverse

    for i in rows:
        result += "".join(i)

    return result


print(convert("PAYPALISHIRING", 3))
# PAHNAPLSIIGYIR`,
                    },
                    "C++": {
                      code: `#include <iostream>
                      #include <string>
                      #include <algorithm>
                      #include <vector>
                      #include <numeric>      
                      
                      
                      using namespace std;
                      
                      string convert(string s,int numRows){
                        if(numRows==1||s.length()<numRows){
                          return s;
                        }
                      
                        vector<vector<char>> rows;
                        int currentRow=0;
                        bool reverse = false;
                        string result="";
                      
                        for(int i=0;i<numRows;i++){
                          vector<char> s;
                          rows.push_back(s);
                        }
                      
                        for(int i=0;i<s.length();i++){
                          rows[currentRow].push_back(s[i]);
                      
                          if(!reverse){
                              currentRow++;
                          }else{
                              currentRow--;
                          }
                      
                          if(currentRow == numRows -1 || currentRow ==0 ){
                            reverse = !reverse;
                          }
                        }
                        
                        for(auto i:rows){
                         result+= accumulate(
                          i.begin(),i.end(),string("")
                        );
                        }
                      
                        return result;
                      }
                      
                      int main() {
                        cout << convert("PAYPALISHIRING", 3)<<endl;
                        return 0;
                      }
                      // PAHNAPLSIIGYIR`,
                    },
                    Kotlin: {
                      code: `import java.util.*

                      fun convert(s:String, numRows:Int):String{
                      
                          if(numRows==0 || s.length <numRows){
                              return s;
                          }
                          var currentRow:Int=0;
                          var reverse:Boolean=false;
                          var result:String="";
                      
                          var rows= mutableListOf<MutableList<Char>>()
                      
                          for(i  in 0..numRows){
                              rows.addAll(Collections.singleton(mutableListOf()))
                          }
                      
                          for (element in s){
                              rows[currentRow].add(element)
                              if(!reverse){
                                  currentRow++;
                              }else{
                                  currentRow--;
                              }
                              if(currentRow == numRows -1 || currentRow ==0 ){
                                  reverse = !reverse;
                              }
                          }
                      
                          for (i in 0 until rows.size){
                              var str=""
                              for(j in 0 until rows[i].size){
                              str += rows[i][j];
                          }
                              result+= str;
                          }
                      
                          return result;
                      }
                      
                      fun main(){
                          println(convert("PAYPALISHIRING",3))
                      }`,
                    },
                  },
                }}
              />
            </>
          </>
        ),
      },
      q7: {
        title: "Q7. Reverse Integer",
        content: (
          <>
            <Span>
              <b>Q7. Reverse Integer</b>
            </Span>
            <Span>
              Given a signed 32-bit integer x, return x with its digits
              reversed. If reversing x causes the value to go outside the signed
              32-bit integer range [-231, 231 - 1], then return 0.
            </Span>
            <Span>
              <b>
                Assume the environment does not allow you to store 64-bit
                integers (signed or unsigned).
              </b>
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: x = 123 <br />
              Output: 321
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: x = -123 <br />
              Output: -321
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: x = 120 <br />
              Output: 21
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>-231 &lt;= x &lt;= 231 - 1</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: `Q7. Reverse Integer`,
                output: `-21`,
                codes: {
                  Javascript: {
                    code: `
                   var reverse = function(x) {
                       // Number.MAX_SAFE_INTEGER -> 2^53 - 1
                       let sign = x>0 ? 1 : -1
                       let y = Math.abs(x)
                       // while(y>0){
                       // ans = ans*10 + n%10;
                       // n = parseInt(n/10);
                       // }
                       const ans = parseInt(String(y).split("").reverse().join(""))
                       if(ans > 0x7FFFFFFF)
                       return 0
                       return sign * ans
                   };
                   
                   console.log(reverse(-12))`,
                  },
                  Java: {
                    code: `class Main {
                      static int reverse(int x){
                       long n=x;
                        long ans=0;
                        int sign =1;
                        if(x<0) {
                          n*=-1;
                          sign = -1;
                          };
                        while(n>0){
                          ans= ans*10 + n%10;
                          n/=10;
                        }
                        if(ans > Integer.MAX_VALUE){
                            return 0;
                        }     
                        return (int)(sign*ans);  }
                    
                      public static void main(String[] args) {
                        System.out.println(reverse(-12));
                      }
                    }`,
                  },
                  "C++": {
                    code: `#include <iostream>
                    #include <bits/stdc++.h>
                    
                    using namespace std;
                    
                    class Solution{
                      public:
                      int static reverse(int x){
                       long n=x;
                        long ans=0;
                        int sign =1;
                        if(x<0) {
                          n*=-1;
                          sign = -1;
                          };
                        while(n>0){
                          ans= ans*10 + n%10;
                          n/=10;
                        }
                        if(ans > INT_MAX){
                            return 0;
                        }     
                        return (int)sign*ans;
                      }
                    };
                    
                    int main(){
                      cout<<Solution::reverse(-12)<<endl;
                      return 0;
                    }`,
                  },
                  Kotlin: {
                    code: `class A{
                      companion object{
                          fun reverse(x:Int):Int{
                              var n:Long = x.toLong();
                              var ans:Long=0;
                              var sign:Int=1;
                              if(x<0){
                                  n*=-1;
                                  sign*=-1;
                              }
                              while(n>0){
                                  ans= ans*10 + n%10;
                                  n/=10;
                              }
                              if(ans > Integer.MAX_VALUE){
                                  return 0;
                              }
                              return (sign*ans).toInt();
                          }
                      }
                  }
                  
                  
                  fun main(){
                      println(A.reverse(-12))
                  }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q8: {
        title: "Q8. String to Integer (atoi)",
        content: (
          <>
            <Span>
              <b>Q8. String to Integer (atoi)</b>
            </Span>
            <Span>
              Implement the myAtoi(string s) function, which converts a string
              to a 32-bit signed integer (similar to C/C++'s atoi function).
            </Span>
            <Span>The algorithm for myAtoi(string s) is as follows:</Span>
            <Span>
              1. Read in and ignore any leading whitespace. <br />
              Check if the next character (if not already at the end of the
              string) is '-' or '+'. Read this character in if it is either.
              This determines if the final result is negative or positive
              respectively. Assume the result is positive if neither is present.
              <br />
              2. Read in next the characters until the next non-digit character
              or the end of the input is reached. The rest of the string is
              ignored.
              <br />
              3. Convert these digits into an integer (i.e. "123" -&lt; 123,
              "0032" -&lt; 32). If no digits were read, then the integer is 0.
              Change the sign as necessary (from step 2). <br />
              4. If the integer is out of the 32-bit signed integer range [-231,
              231 - 1], then clamp the integer so that it remains in the range.
              Specifically, integers less than -231 should be clamped to -231,
              and integers greater than 231 - 1 should be clamped to 231 - 1.
              Return the integer as the final result.
            </Span>
            <Span>
              <b>Note:</b>
            </Span>
            <Span>
              1. Only the space character ' ' is considered a whitespace
              character. <br />
              2.Do not ignore any characters other than the leading whitespace
              or the rest of the string after the digits.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "42" <br />
              Output: 42 <br />
              Explanation: The underlined characters are what is read in, the
              caret is the current reader position. Step 1: "42" (no characters
              read because there is no leading whitespace) <br />
              ^ Step 2: "42" (no characters read because there is neither a '-'
              nor '+') <br />
              ^ Step 3: "42" ("42" is read in) <br />^ The parsed integer is 42.
              Since 42 is in the range [-231, 231 - 1], the final result is 42.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = " -42" <br />
              Output: -42 <br />
              Explanation: <br />
              Step 1: " -42" (leading whitespace is read and ignored) <br />^
              Step 2: " -42" ('-' is read, so the result should be negative){" "}
              <br />
              ^ Step 3: " -42" ("42" is read in) <br />^ The parsed integer is
              -42. Since -42 is in the range [-231, 231 - 1], the final result
              is -42.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "4193 with words" <br />
              Output: 4193 <br />
              Explanation: <br />
              Step 1: "4193 with words" (no characters read because there is no
              leading whitespace) <br />
              ^ Step 2: "4193 with words" (no characters read because there is
              neither a '-' nor '+') <br />
              ^ Step 3: "4193 with words" ("4193" is read in; reading stops
              because the next character is a non-digit) <br />^ The parsed
              integer is 4193. Since 4193 is in the range [-231, 231 - 1], the
              final result is 4193.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1. 0 &lt;= s.length &lt;= 200 <br />
              2. s consists of English letters (lower-case and upper-case),
              digits (0-9), ' ', '+', '-', and '.'.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q8. String to Integer (atoi)",
                output: `42`,
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {number}
                    */
                   var myAtoi = function(s){
                        let st = s.trim()
                        if(st == null || st.length ==0){
                          return 0
                        }
                        let res=0
                        let isNegative=false
                        let startIndex=0
                        
                        if(st[0]=='+' || st[0]=='-'){
                           startIndex+=1
                        }
                        if(st[0]=='-'){
                           isNegative=true
                        } 
                        for(let i=startIndex;i<st.length;i++){
                          if(st[i]<'0'||st[i]>'9'){
                            break;
                          }
                          let digit = parseInt(st[i])
                          res = res*10+digit
                        }
                        if(isNegative){
                          res= -1 * res
                        }
                        if(res > Math.pow(2,31) - 1)
                           return Math.pow(2,31)-1
                        if(res < Math.pow(-2, 31))
                           return Math.pow(-2,31)
                        return res
                   }
                   console.log(myAtoi(" 42"))`,
                  },
                  Java: {
                    code: `class ATOI {
                        public static int atoi(String str) {
                            str = str.trim();
                            if (str == null || str.length() == 0) {
                                return 0;
                            }
                            double result = 0;
                            boolean isNegative = false;
                            int startIndex = 0;
                            if (str.charAt(0) == '+' || str.charAt(0) == '-') {
                                startIndex++;
                            }
                            if (str.charAt(0) == '-') {
                                isNegative = true;
                            }
                            for (int i = startIndex; i < str.length(); i++) {
                                if (str.charAt(i) < '0' || str.charAt(i) > '9') {
                                    break;
                                }
                                int digitValue = (int) (str.charAt(i) - '0');
                                result = result * 10 + digitValue;
                            }
                    
                            if (isNegative) {
                                result = -result;
                            }
                            if (result > Integer.MAX_VALUE) {
                                return Integer.MAX_VALUE;
                            }
                            if (result < Integer.MIN_VALUE) {
                                return Integer.MIN_VALUE;
                            }
                    
                            return (int) result;
                        }
                    
                        public static void main(String... s) {
                            System.out.println(ATOI.atoi("42"));
                        }
                    
                    }
                    `,
                  },
                  Python: {
                    code: `
class ATOI:
    @staticmethod
    def atoi(s):
        st = s.strip()

        if (st is None or len(st) == 0):
            return 0

        res = 0
        isNegative = False
        startIndex = 0

        if st[0] == '+' or st[0] == '-':
            startIndex += 1
        if st[0] == '-':
            isNegative = True

        print(startIndex, isNegative)

        i = startIndex

        while i < len(st):
            if st[i] < '0' or st[i] > '9':
                break
            digit = (int)(st[i])
            res = res * 10 + digit
            i += 1

        if isNegative:
            res = -res

        if res > 2 ^ 31 - 1:
            return 2 ^ 31

        if res < -2 ^ 31 + 1:
            return -2 ^ 31

        return res


# import sys
# sys.maxsize

print(ATOI.atoi("-23"))`,
                  },
                  "C++": {
                    code: `#include <iostream>
                      #include <string>
                      #include <algorithm>
                      #include <bits/stdc++.h>
                      
                      using namespace std;
                      
                      class ATOI{
                      public:
                      
                        static string trim(const string &s){
                        auto start = s.begin();
                        while(start != s.end() && isspace(*start)){
                           start++;
                        }
                        auto end = s.end();
                        do{
                          end--;
                        }while(distance(start,end)>0 && isspace(*end));
                        return string(start,end+1);
                        }
                      
                        static int atoi(string s){
                              string str = trim(s);
                              double result = 0;
                              bool isNegative = false;
                              int startIndex = 0;
                              if (str[0] == '+' || str[0] == '-') {
                                  startIndex++;
                              }
                              if (str[0] == '-') {
                                  isNegative = true;
                              }
                              for (int i = startIndex; i < str.length(); i++) {
                                  if (str[i] < '0' || str[i] > '9') {
                                      break;
                                  }
                                  int digitValue = (int) (str[i] - '0');
                                  result = result * 10 + digitValue;
                              }
                      
                              if (isNegative) {
                                  result = -result;
                              }
                              if (result > INT_MAX) {
                                  return INT_MAX;
                              }
                              if (result < INT_MIN) {
                                  return INT_MIN;
                              }
                      
                              return (int) result;
                        }
                      
                      };
                      
                      int main() {
                        cout << ATOI::atoi(" 42 Hello World! ")<<endl;
                        return 0;
                      }`,
                  },
                  kotlin: {
                    code: `class ATOI{
                        companion object{
                            fun atoi(s:String):Int{
                                var res:Double=0.0
                                var str = s.trim()
                                var isNegative:Boolean = false
                                var startIndex:Int=0
                                if(str[0]=='+'||str[0]=='-'){
                                    startIndex++
                                }
                                if(str[0]=='-'){
                                    isNegative = true
                                }
                                for(i: Int in startIndex until str.length){
                                    if(str[i]<'0'||str[i]>'9'){
                                        break;
                                    }
                                    var digit:Int = (str[i] - '0').toInt()
                                    res = res *10 + digit
                                }
                                if(isNegative){
                                    res = -res
                                }
                                if(res > Int.MAX_VALUE){
                                    return Int.MAX_VALUE
                                }else if(res<Int.MIN_VALUE){
                                    return Int.MIN_VALUE
                                }
                                return res.toInt()
                            }
                        }
                    }
                    
                    fun main(){
                      print(ATOI.atoi(" 42"))
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q9: {
        title: "Q9. Palindrome Number",
        content: (
          <>
            <Span>
              <b>Q9. Palindrome Number</b>
            </Span>
            <Span>
              Given an integer x, return true if x is palindrome integer. An
              integer is a palindrome when it reads the same backward as
              forward. For example, 121 is a palindrome while 123 is not.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: x = 121 <br />
              Output: true <br />
              Explanation: 121 reads as 121 from left to right and from right to
              left. <br />
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: x = -121 <br />
              Output: false <br />
              Explanation: From left to right, it reads -121. From right to
              left, it becomes 121-. Therefore it is not a palindrome. <br />
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: x = 10 <br />
              Output: false
              <br />
              Explanation: Reads 01 from right to left. Therefore it is not a
              palindrome.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>-2^31 &lt;= x &lt;= 2^31 - 1</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n)
              <br />
              Space: O(log n)
            </p>
            <CodeEditor
              options={{
                output: `true`,
                title: "Q9. Palindrome Number",
                codes: {
                  Javascript: {
                    code: `/*
@param {number} x
@return {boolean}
*/

                   var isPalindrome = function(x){
                         var n=x
                         if(n<0){
                           return false
                         }
                         var rev=0
                         while(parseInt(n)>0){
                           rev=rev*10+parseInt(n)%10
                           n/=10
                         }
                         if(rev==x){
                           return true
                         }else{
                           return false
                         }
                       } 
                    
                   console.log(isPalindrome(121))`,
                  },
                  Java: {
                    code: `class p{
                      static boolean isPalindrome(int x){
                          int n=x;
                          if(x<0){
                              return false;
                          }
                          int rev=0;
                          while(n>0){
                              rev= rev*10 + n%10;
                              n/=10;
                          }
                          if(x==rev){
                              return true;
                          }else{
                              return false;
                          }
                      }
                  
                      public static void main(String[] args) {
                          System.out.println(isPalindrome(121));
                      }
                  }`,
                  },
                  Python: {
                    code: `
class Solution:
  @staticmethod
  def isPalindrome(x):
    n=x
    if x<0:
      return False
    rev=0
    while int(n)>0:
      rev = rev*10 + int(n)%10
      n/=10
    if x==rev:
      return True
    else:
      return False
  
print(Solution.isPalindrome(121))
                  `,
                  },
                  "C++": {
                    code: `#include <iostream>

                  using namespace std;
                  
                  class Solution {
                      public:
                       static bool isPalindrome(int x) {
                          int n=x;
                          if(x<0){
                            return false;
                          }
                          int rev=0;
                          while(n>0){
                            rev= rev*10 + n%10;
                            n/=10;
                          }
                          if(x==rev){
                            return true;
                          }else{
                            return false;
                          }
                      }
                  };
                  
                  int main() {
                    cout << boolalpha;
                    cout << Solution::isPalindrome(-121) <<endl;
                    return 0;
                  }`,
                  },
                  Kotlin: {
                    code: `class Solution{
                    companion object{
                        fun isPalindrome(x:Int):Boolean{
                            var n:Int=x
                            if(x<0)
                                return false
                            var rev:Int=0
                            while(n>0){
                                rev=rev*10+(n%10)
                                n/=10
                            }
                            return x==rev
                        }
                    }
                }
                
                fun main(){
                    print(Solution.isPalindrome(121))
                }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q10: {
        title: "Q10. Regular Expression Matching",
        content: (
          <>
            <Span>
              <b>Q10. Regular Expression Matching</b>
            </Span>
            <Span>
              Given an input string s and a pattern p, implement regular
              expression matching with support for '.' and '*' where:
              <br />
              '.' Matches any single character.​​​​ <br />
              '*' Matches zero or more of the preceding element. <br />
              The matching should cover the entire input string (not partial).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "aa", p = "a" Output: false Explanation: "a" does not
              match the entire string "aa".
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "aa", p = "a*" Output: true Explanation: '*' means zero
              or more of the preceding element, 'a'. Therefore, by repeating 'a'
              once, it becomes "aa".
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "ab", p = ".*" Output: true Explanation: ".*" means
              "zero or more (*) of any character (.)".
            </Span>
            <Span>
              <b>Constraints</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 20 <br />
              1 &lt;= p.length &lt;= 30 <br />
              s contains only lowercase English letters. <br />
              p contains only lowercase English letters, '.', and '*'. <br />
              It is guaranteed for each appearance of the character '*', there
              will be a previous valid character to match.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(mn)
            </p>
            <CodeEditor
              options={{
                title: "Q10. Regular Expression Matching",
                output: `true`,
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @param {string} p
                    * @return {boolean}
                    */
                   var isMatch = function(s, p) {
                      const rows = s.length;
                      const columns = p.length;
                       if (rows == 0 && columns == 0) {
                         return true;
                       }
                       if (columns == 0) {
                         return false;
                       }
                       const dp = Array.from({ length: s.length + 1 }, () => [false]);
                       dp[0][0] = true;
                       for (let i = 1; i < columns + 1; i++) {
                         if (p[i - 1] === "*") {
                           dp[0][i] = dp[0][i - 2];
                         } else {
                           dp[0][i] = false;
                         }
                       }
                       for (let i = 1; i < rows + 1; i++) {
                         for (let j = 1; j < columns + 1; j++) {
                           if (p[j - 1] === "*") {
                             if (p[j - 2] === s[i - 1] || p[j - 2] === ".") {
                               dp[i][j] = dp[i][j - 2] || dp[i - 1][j];
                             } else {
                               dp[i][j] = dp[i][j - 2];
                             }
                           } else if (p[j - 1] === s[i - 1] || p[j - 1] === ".") {
                             dp[i][j] = dp[i - 1][j - 1];
                           } else {
                             dp[i][j] = false;
                           }
                         }
                       }
                       return dp[rows][columns]; 
                   };
                   
                   console.log(isMatch("aab", "c*a*b"));
                   `,
                  },
                  Java: {
                    code: ` 
                    // c	*	a	*	b
                    // 0	1	2	3	4	5
                    // 0	TRUE	FALSE	TRUE	FALSE	TRUE	FALSE
                    // a	1	FALSE	FALSE	FALSE	TRUE	TRUE	FALSE
                    // a	2	FALSE	FALSE	FALSE	FALSE	TRUE	FALSE
                    // b	3	FALSE	FALSE	FALSE	FALSE	FALSE	TRUE                    
                    class Main {
                    
                        public static boolean isMatch(String s,String p){
                          int rows = s.length();
                          int columns = p.length();
                          
                          if(rows==0&&columns==0){
                            return true;
                          }
                          if(columns==0){
                            return false;
                          }
                          boolean[][] dp=new boolean[rows+1][columns+1];
                          
                          dp[0][0]=true;
                      
                          for (int i = 2; i < columns + 1; i++) {
                                  if (p.charAt(i - 1) == '*') {
                                      dp[0][i] = dp[0][i - 2];
                                  }
                          }
                          
                          for (int i = 1; i < rows + 1; i++) {
                                  for (int j = 1; j < columns + 1; j++) {
                                      if (s.charAt(i - 1) == p.charAt(j - 1) || p.charAt(j - 1) == '.') {
                                          dp[i][j] = dp[i - 1][j - 1];
                                      } else if (j > 1 && p.charAt(j - 1) == '*') {
                                          dp[i][j] = dp[i][j - 2];
                                          if (p.charAt(j - 2) == '.' || p.charAt(j - 2) == s.charAt(i - 1)) {
                                              dp[i][j] = dp[i][j] | dp[i - 1][j];
                                          }
                                      }
                            }
                          }
                              return dp[rows][columns];
                      
                        }
                      
                        public static void main(String[] args) {
                          System.out.println(isMatch("aab","c*a*b"));
                        }
                      }         
                    `,
                  },
                  Python: {
                    code: `
class Solution:
  @staticmethod
  def isMatch(s: str, p: str) -> bool:
    rows, columns = (len(s), len(p))
    if rows == 0 and columns == 0:
        return True
    if columns == 0:
        return False
    dp = [[False for j in range(columns + 1)] for i in range(rows + 1)]
    dp[0][0] = True
    for i in range(2, columns + 1):
        if p[i - 1] == '*':
            dp[0][i] = dp[0][i - 2]
    for i in range(1, rows + 1):
        for j in range(1, columns + 1):
            if s[i - 1] == p[j - 1] or p[j - 1] == '.':
                dp[i][j] = dp[i - 1][j - 1]
            elif j > 1 and p[j - 1] == '*':
                dp[i][j] = dp[i][j - 2]
                if p[j - 2] == '.' or p[j - 2] == s[i - 1]:
                    dp[i][j] = dp[i][j] or dp[i - 1][j]
    return dp[rows][columns]

print(Solution.isMatch("aab", "c*a*b"))`,
                  },
                  "C++": {
                    code: `#include <iostream>

                    using namespace std;
                    
                    class Solution
                    {
                    public:
                        static bool isMatch(string s, string p)
                        {
                            int rows = 0, columns = 0;
                            bool dp[rows][columns];
                            dp[0][0] = true;
                    
                            for (int i = 2; i < columns + 1; i++)
                            {
                                if (p[i - 1] == '*')
                                {
                                    dp[0][i] = dp[0][i - 2];
                                }
                            }
                    
                            for (int i = 1; i < rows + 1; i++)
                            {
                                for (int j = 1; j < columns + 1; j++)
                                {
                                    if (s[i - 1] == p[j - 1] || p[j - 1] == '.')
                                    {
                                        dp[i][j] = dp[i - 1][j - 1];
                                    }
                                    else if (j > 1 && p[j - 1] == '*')
                                    {
                                        dp[i][j] = dp[i][j - 2];
                                        if (p[j - 2] == '.' || p[j - 2] == s[i - 1])
                                        {
                                            dp[i][j] = dp[i][j] or dp[i - 1][j];
                                        }
                                    }
                                }
                            }
                    
                            return dp[rows][columns];
                        }
                    };
                    
                    int main()
                    {
                        cout << boolalpha;
                        cout << Solution::isMatch("aab", "c*a*b") << endl;
                    }`,
                  },
                  Kotlin: {
                    code: `class Solution{
                      companion object {
                          fun isMatch(s:String,p:String):Boolean{
                              val rows:Int=0
                              val columns:Int=0
                              val dp = Array(
                                  rows+1
                              ){
                                  BooleanArray(columns+1)
                              }
                              dp[0][0]=true
                              for(i in 2 until  columns+1){
                                  if(p[i-1]=='*'){
                                      dp[0][i]=dp[0][i-2]
                                  }
                              }
                              for(i in 1 until  rows+1){
                                  for(j in 1 until columns+1){
                                      if (s[i - 1] === p[j - 1] || p[j - 1] === '.') {
                                          dp[i][j] = dp[i - 1][j - 1]
                                      } else if (j > 1 && p[j - 1] === '*') {
                                          dp[i][j] = dp[i][j - 2]
                                          if (p[j - 2] === '.' || p[j - 2] === s[i - 1]) {
                                              dp[i][j] = dp[i][j] or dp[i - 1][j]
                                          }
                                      }
                                  }
                              }
                  
                              return dp[rows][columns]
                          }
                      }
                  }
                  
                  fun main(){
                    print(Solution.isMatch("aab","c*a*b"))
                  }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q11: {
        title: "Q11. Container With Most Medium Water",
        content: (
          <>
            <Span>
              <b>Q11. Container With Most Medium Water</b>
            </Span>
            <Span>
              You are given an integer array height of length n. There are n
              vertical lines drawn such that the two endpoints of the ith line
              are (i, 0) and (i, height[i]).
            </Span>
            <Span>
              Return the maximum amount of water a container can store. Notice
              that you may not slant the container.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={LeetCodeQ11} left />
            <Span>
              Input: height = [1,8,6,2,5,4,8,3,7] <br />
              Output: 49 <br />
              Explanation: The above vertical lines are represented by array
              [1,8,6,2,5,4,8,3,7]. In this case, the max area of water (blue
              section) the container can contain is 49.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: height = [1,1]
              <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints</b>
            </Span>
            <Span>
              n == height.length <br />
              2 &lt;= n &lt;= 105 <br />0 &lt;= height[i] &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q11. Container With Most Medium Water",
                output: `2`,
                codes: {
                  Javascript: {
                    code: `
                    /*
@param {number[]} height
@return {number}
*/
var maxArea = function(height){
let max=0,l=0;
let r=height.length-1
while(l<r){
max=Math.max(max,Math.min(height[l],height[r])*(r-l))
if(height[l]<height[r]){
  l++
}else{
  r--
}
}
return max
}       
console.log(maxArea([1,2,1]))`,
                  },
                  Java: {
                    code: `class Main {
 
                      public static int maxArea(int[] height) {
                            int left = 0, right = height.length -1;
                            int max = 0;
                            while (left < right) {
                                max = Math.max(max, Math.min(height[left], height[right]) * (right - left));
                                if (height[left] < height[right]) {
                                    left ++;
                                }
                                else {
                                    right --;
                                }
                            }
                            return max;
                      }
                    
                      public static void main(String[] args) {
                        System.out.println(maxArea(new int[]{1,2,1}));
                       }
                    
                    }`,
                  },
                  Python: {
                    code: `class Solution:

  @staticmethod
  def maxArea(height)->int:
    max1=0
    min1=0
    left=0
    right=len(height)-1
    while left<right:
      max1 = max(max1, min(height[left], height[right]) * (right - left))
      if height[left]<height[right]:
        left+=1
      else:
        right-=1
    return max1

print(Solution.maxArea([1,2,1]))

                    `,
                  },
                  "C++": {
                    code: `#include <iostream>
                    #include <algorithm> 
                    #include <vector>
                    
                    using namespace std;
                    
                    class Solution{
                      public:
                      static int maxArea(vector<int>& height){
                        int left{0};
                        int right= height.size()-1;
                        int max=0;
                        while (left < right) {
                          max = std::max(max,min(height[left], height[right]) * (right - left));
                          if (height[left] < height[right]) {
                            left ++;
                          }
                          else {
                            right --;
                          }
                        }
                        return max;
                      }
                    };
                    
                    int main(){
                      vector<int> arr{1,2,1};
                      cout<<Solution::maxArea(arr)<<endl;
                      return 0;
                    }`,
                  },
                  Kotlin: {
                    code: `import kotlin.math.min

                    class Solution{
                        companion object{
                            fun maxArea(height: List<Int>):Int{
                              var max:Int=0
                              var left:Int=0
                              var right=height.size-1
                              while(left<right){
                                  max = kotlin.math.max(max, min(height[left], height[right]) * (right - left));
                                  if (height[left] < height[right]) {
                                      left ++;
                                  }
                                  else {
                                      right --;
                                  }
                              }
                              return max
                            }
                        }
                    }
                    
                    fun main(){
                        val arr= listOf<Int>(1,2,1)
                        print(Solution.maxArea(arr))
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q12: {
        title: "Q12. Integer To Roman",
        content: (
          <>
            <Span>
              <b>Q12. Integer to Roman</b>
            </Span>
            <Span>
              Roman numerals are represented by seven different symbols: I, V,
              X, L, C, D and M.
            </Span>
            <Span>
              Symbol -&lt; Value <br />
              I -&gt; 1 <br />
              V -&gt; 5 <br />
              X -&gt; 10 <br />
              L -&gt; 50 <br />
              C -&gt; 100 <br />
              D -&gt; 500 <br />M -&gt; 1000
            </Span>
            <Span>
              For example, 2 is written as II in Roman numeral, just two one's
              added together. 12 is written as XII, which is simply X + II. The
              number 27 is written as XXVII, which is XX + V + II.
            </Span>
            <Span>
              Roman numerals are usually written largest to smallest from left
              to right. However, the numeral for four is not IIII. Instead, the
              number four is written as IV. Because the one is before the five
              we subtract it making four. The same principle applies to the
              number nine, which is written as IX. There are six instances where
              subtraction is used:
            </Span>
            <Span>
              I can be placed before V (5) and X (10) to make 4 and 9. <br />X
              can be placed before L (50) and C (100) to make 40 and 90. <br />C
              can be placed before D (500) and M (1000) to make 400 and 900.{" "}
              <br />
              Given an integer, convert it to a roman numeral.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: num = 3 <br />
              Output: "III" <br />
              Explanation: 3 is represented as 3 ones.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: num = 58 <br />
              Output: "LVIII" <br />
              Explanation: L = 50, V = 5, III = 3.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: num = 1994 <br />
              Output: "MCMXCIV" <br />
              Explanation: M = 1000, CM = 900, XC = 90 and IV = 4.
            </Span>
            <Span>
              <b>Constraints</b>
            </Span>
            <Span>1 &lt;= num &lt;= 3999</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q12. Integer To Roman",
                output: `XII`,
                codes: {
                  Javascript: {
                    code: `var intToRoman = function(n){
                      let  num=n
                      const base=[1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1]
                      const chars=["M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"]
                      let result=""
                      for(let i=0;i<base.length;i++){
                        if(parseInt(num/base[i])>0){
                         for(let j=0;j<parseInt(num/base[i]);j++){
                           result+=chars[i]
                         }
                         num-= parseInt((num/base[i]))*base[i]
                        }
                      }
                      return result
                    }
                 
                console.log(intToRoman(12))`,
                  },
                  Java: {
                    code: `class Solution {
                      public String intToRoman(int num) {
                          int[] base = new int[]{1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1};
                          String[] chars = new String[]{"M","CM","D","CD","C","XC","L","XL","X","IX", "V", "IV", "I"};
                          String result = "";
                          for (int i = 0; i < base.length; i++) {
                              if (num / base[i] > 0) {
                                  for (int j = 0; j < num/base[i]; j++) {
                                      result = result + chars[i];
                                  }
                                  num = num - (num / base[i]) * base[i];
                              }
                          }
                          return result;
                      }
                      
                  }`,
                  },
                  Python: {
                    code: `
class Solution:

  @staticmethod
  def maxArea(num:int)->str:
    base=[1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1]
    chars=["M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"]
    result = ""
    for i in range(len(base)):
      if int(num / base[i]) > 0 :
        for j in range(int(num/base[i])):
          result += chars[i]
        num -= int(num/base[i])*base[i]
    return result

print(Solution.maxArea(12))
                    `,
                  },
                  "C++": {
                    code: `#include <iostream>

                    using namespace std;
                    
                    class Solution{
                      public:
                      string intToRoman(int num){
                        int base[] = {1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1};
                        string chars[] = {"M","CM","D","CD","C","XC","L","XL","X","IX", "V", "IV", "I"};
                        string result="";
                        for (int i = 0; i < sizeof(base)/sizeof(base[0]) ; i ++) {
                                if (num / base[i] > 0) {
                                    for (int j = 0; j < num/base[i]; j ++) {
                                        result = result + chars[i];
                                    }
                                    num = num - (num / base[i]) * base[i];
                                }
                        }
                        return result;
                      }
                    };
                    
                    int main(){
                      Solution s1;
                      cout<<s1.intToRoman(44)<<endl;
                      return 0;
                    }`,
                  },
                  Kotlin: {
                    code: `internal class Solution {
                      fun intToRoman(num: Int): String {
                          var num = num
                          val base = intArrayOf(1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1)
                          val chars = arrayOf("M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I")
                          var result = ""
                          for (i in base.indices) {
                              if (num / base[i] > 0) {
                                  for (j in 0 until num / base[i]) {
                                      result += chars[i]
                                  }
                                  num -= num / base[i] * base[i]
                              }
                          }
                          return result
                      }
                  }
                  
                  fun main(){
                      var s:Solution=Solution()
                      print(s.intToRoman(8))
                  }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q13: {
        title: "Q13. Roman To Integer",
        content: (
          <>
            <Span>
              <b>Q13. Roman To Integer</b>
            </Span>
            <Span>
              Roman numerals are represented by seven different symbols: I, V,
              X, L, C, D and M.
            </Span>
            <Span>
              Symbol -&lt; Value <br />
              I -&gt; 1 <br />
              V -&gt; 5 <br />
              X -&gt; 10 <br />
              L -&gt; 50 <br />
              C -&gt; 100 <br />
              D -&gt; 500 <br />M -&gt; 1000
            </Span>
            <Span>
              For example, 2 is written as II in Roman numeral, just two one's
              added together. 12 is written as XII, which is simply X + II. The
              number 27 is written as XXVII, which is XX + V + II.
              <br />
              Roman numerals are usually written largest to smallest from left
              to right. However, the numeral for four is not IIII. Instead, the
              number four is written as IV. Because the one is before the five
              we subtract it making four. The same principle applies to the
              number nine, which is written as IX. There are six instances where
              subtraction is used:
              <br />
              I can be placed before V (5) and X (10) to make 4 and 9. <br />X
              can be placed before L (50) and C (100) to make 40 and 90. <br />C
              can be placed before D (500) and M (1000) to make 400 and 900.{" "}
              <br />
              Given a roman numeral, convert it to an integer.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>Input: s = "III" Output: 3 Explanation: III = 3.</Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "LVIII" Output: 58 Explanation: L = 50, V= 5, III = 3.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "MCMXCIV" Output: 1994 Explanation: M = 1000, CM = 900,
              XC = 90 and IV = 4.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 15 <br />
              s contains only the characters ('I', 'V', 'X', 'L', 'C', 'D',
              'M'). <br />
              It is guaranteed that s is a valid roman numeral in the range [1,
              3999].
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q13. Roman To Integer",
                output: `4`,
                codes: {
                  Javascript: {
                    code: `var romanToInt = function(s){
                    let helper = {
                      'M':1000,
                      'D':500,
                      'C':100,
                      'L':50,
                      'X':10,
                      'V':5,
                      'I':1
                    }
                    var result =0
                    for(let i=0;i<s.length;i++){
                       if(i>0 && helper[s[i]] > helper[s[i-1]]){
                          result -= 2 * helper[s[i-1]]
                       }
                       result += helper[s[i]]
                    }
                    return result
                  }
                  
                  console.log(romanToInt("IV"))`,
                  },
                  Java: {
                    code: `import java.util.*;

                  class Main{
                  
                  public int romanToInt(String s){ 
                    HashMap<Character,Integer> helper = new HashMap<Character,Integer>();
                    initHelper(helper);
                    int result = 0;
                    for (int i = 0; i < s.length(); i ++){
                      if ( i > 0 && helper.get(s.charAt(i)) > helper.get(s.charAt(i-1))) {
                        result = result - 2 * helper.get(s.charAt(i-1));
                      }
                      result = result + helper.get(s.charAt(i));
                    }
                    return result;
                  }
                  
                  public void initHelper(HashMap<Character,Integer> helper){
                     helper.put('M',1000);
                     helper.put('D',500);
                     helper.put('C',100);
                     helper.put('L',50);
                     helper.put('X',10);
                     helper.put('V',5);
                     helper.put('I',1);
                  }
                  public static void main(String ...s){
                      Main m = new Main();
                      System.out.println(m.romanToInt("IV"));
                  } 
                  };
                  `,
                  },
                  Python: {
                    code: `
class Main:
  def romanToInt(self,s):
    helper = {
      'M':1000,
      'D':500,
      'C':100,
      'L':50,
      'X':10,
      'V':5,
      'I':1
    }
    result = 0
    for i in range(0,len(s)):
      if i > 0 and helper[s[i]] > helper[s[i-1]]:
        result -= 2 * helper[s[i-1]]
      result += helper[s[i]]
    return result 
    
m = Main()
print(m.romanToInt("IV"))`,
                  },
                  "C++": {
                    code: `#include <iostream>
                  #include <bits/stdc++.h>
                  
                  using namespace std;
                  
                  class Main{
                  
                  public:
                    int romanToInt(string s){
                         unordered_map<char,int> helper({
                           {'M',1000},
                           {'D',500},
                           {'C',100},
                           {'L',50},
                           {'X',10},
                           {'V',5},
                           {'I',1}
                         });
                         int result = 0;
                         for (int i = 0; i < s.length(); i++){
                            if ( i > 0 && helper[s[i]] > helper[s[i-1]]) {
                            result -= 2 * helper[s[i-1]];
                         }
                        result += helper[s[i]];
                        }
                        return result;
                    }
                  
                  };
                  
                  
                  int main(){
                    Main m;
                    cout<< m.romanToInt("IV") <<endl;
                    return 0;
                  }`,
                  },
                  Kotlin: {
                    code: `internal class Main {
                    fun romanToInt(s: String): Int {
                        val helper = HashMap<Char, Int>()
                        initHelper(helper)
                        var result = 0
                        for (i in s.indices) {
                            if (i > 0 && helper[s[i]]!! > helper[s[i - 1]]!!) {
                                result -= 2 * helper[s[i - 1]]!!
                            }
                            result += helper[s[i]]!!
                        }
                        return result
                    }
                
                    private fun initHelper(helper: HashMap<Char, Int>) {
                        helper['M'] = 1000
                        helper['D'] = 500
                        helper['C'] = 100
                        helper['L'] = 50
                        helper['X'] = 10
                        helper['V'] = 5
                        helper['I'] = 1
                    }
                
                    companion object {
                        @JvmStatic
                        fun main(s: Array<String>) {
                            val m = Main()
                            println(m.romanToInt("IV"))
                        }
                    }
                }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q14: {
        title: "Q14. Longest Common Prefix",
        content: (
          <>
            <Span>
              <b>Q14. Longest Common Prefix</b>
            </Span>
            <Span>
              Write a function to find the longest common prefix string amongst
              an array of strings. If there is no common prefix, return an empty
              string "".
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: strs = ["flower","flow","flight"] <br />
              Output: "fl"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: strs = ["dog","racecar","car"] <br />
              Output: "" <br />
              Explanation: There is no common prefix among the input strings.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= strs.length &lt;= 200 <br />
              0 &lt;= strs[i].length &lt;= 200 <br />
              strs[i] consists of only lower-case English letters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n*n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q14. Longest Common Prefix",
                output: `fl`,
                codes: {
                  Javascript: {
                    code: `/**
                  * @param {string[]} strs
                  * @return {string}
                  */
                 var longestCommonPrefix = function(strs) {
                     if (strs.length == 0) {
                       return "";
                     }
                     var prefix = strs[0];
                     for (let i = 0; i < strs.length; i++) {
                       for (let j = 0; j < prefix.length; j++) {
                         if (j == strs[i].length || prefix[j] != strs[i][j]) {
                           prefix = prefix.substr(0, j);
                           break;
                         }
                       }
                     }
                     return prefix;    
                 };
                 
                 console.log(longestCommonPrefix(["flower", "flow", "flight"]));
                 `,
                  },
                  Java: {
                    code: `import java.util.*;

                    class Main{
                    
                    public static String lcp(List<String> s){
                      if(s.size()==0){
                        return "";
                      }
                      String prefix = s.get(0);
                      for (int i = 0; i < s.size(); i++)
                      {
                          for (int j = 0; j < prefix.length();j++)
                          {
                              if (j == s.get(i).length() || prefix.charAt(j)!= s.get(i).charAt(j))
                              {
                                  prefix = prefix.substring(0, j);
                                  break;
                              }
                          }
                      }
                      return prefix;
                    }
                    
                    public static void main(String ...s){
                      System.out.println(lcp(Arrays.asList("flower", "flow", "flight")));
                    }
                    
                    }
                    `,
                  },
                  "C++": {
                    code: `#include <iostream>
                    #include <vector>
                    
                    using namespace std;
                    
                    class Solution
                    {
                    public:
                        static string lcp(vector<string> s)
                        {
                            if (s.size() == 0)
                            {
                                return "";
                            }
                            string prefix = s[0];
                            for (int i = 0; i < s.size(); i++)
                            {
                                for (int j = 0; j < prefix.length(); j++)
                                {
                                    if (j == s[i].length() || prefix[j] != s[i][j])
                                    {
                                        prefix = prefix.substr(0, j);
                                        break;
                                    }
                                }
                            }
                            return prefix;
                        }
                    };
                    
                    int main()
                    {
                        Solution s;
                        vector<string> l{"flower", "flow", "flight"};
                        cout << s.lcp(l) << endl;
                        return 0;
                    }`,
                  },
                  Python: {
                    code: `
class Solution:

  @staticmethod
  def lcp(s):
    if len(s)==0:
      return ""
    prefix = s[0]
    for i in range(len(s)):
      for j in range(len(prefix)):
        if j == len(s[i]) or prefix[j] != s[i][j]:
          prefix = prefix[:j]
          break
    return prefix


print(Solution.lcp(["flower", "flow", "flight"]))
`,
                  },
                  Kotlin: {
                    code: `package com.programming

                    internal class Test{
                        companion object{
                            fun lcp(s: List<String>):String {
                                if(s.isEmpty()){
                                    return ""
                                }
                                var prefix:String = s[0]
                                for(i in s.indices){
                                    for(j in prefix.indices){
                                        if(j == s[i].length || prefix[j]!= s[i][j]){
                                            prefix = prefix.substring(0,j)
                                            break
                                        }
                                    }
                                }
                                return prefix
                            }
                        }
                    }
                    
                    fun main(){
                        print(Test.lcp(listOf<String>("flower", "flow", "flight")))
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q15: {
        title: "Q15. 3Sum",
        content: (
          <>
            <Span>
              <b>Q15. 3Sum</b>
            </Span>
            <Span>
              Given an integer array nums, return all the triplets [nums[i],
              nums[j], nums[k]] such that i != j, i != k, and j != k, and
              nums[i] + nums[j] + nums[k] == 0. Notice that the solution set
              must not contain duplicate triplets.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [-1,0,1,2,-1,-4] <br />
              Output: [[-1,-1,2],[-1,0,1]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [] <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [0] <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints</b>
            </Span>
            <Span>
              0 &lt;= nums.length &lt;= 3000 <br />
              -105 &lt;= nums[i] &lt;= 105
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q15. 3Sum",
                output: `[ [ -1, -1, 2 ], [ -1, 0, 1 ] ]`,
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number[][]}
                    */
                   var threeSum = function(nums) {
                       var result = []
                       nums.sort((a, b) => a - b)
                       for (let i = 0; i < nums.length; i++) {
                           if (i == 0 || nums[i] != nums[i - 1]) {
                               let l = i + 1
                               let r = nums.length - 1
                               while (l < r) {
                                   let s = nums[i] + nums[l] + nums[r]
                                   if (s == 0) {
                                       let temp = [nums[i], nums[l], nums[r]]
                                       result.push(temp)
                                       let startVal = nums[l]
                                       let endVal = nums[r]
                                       while (l < r && startVal == nums[l]) {
                                           l += 1
                                       }
                                       while (r > l && endVal == nums[r]) {
                                           r -= 1
                                       }
                                   } else if (s < 0) {
                                       l += 1
                                   } else {
                                       r -= 1
                                   }
                               }
                           }
                       }
                       return result
                   };
                   
                   console.log(threeSum([-1, 0, 1, 2, -1, -4]))
                   `,
                  },
                  Java: {
                    code: `import java.util.*;

                    class Main{
                      public static List<List<Integer>> threeSum(int[] nums) {
                            List<List<Integer>> result = new LinkedList<List<Integer>>();
                            Arrays.sort(nums);
                            for (int i = 0; i < nums.length; i++) {
                                if (i == 0 || nums[i] != nums[i-1]) {
                                    int start = i + 1, end = nums.length - 1;
                                    while (start < end) {
                                        int sum = nums[i] + nums[start] + nums[end];
                                        if (sum == 0){
                                            List<Integer> tmp = new LinkedList<Integer>();
                                            tmp.add(nums[i]);
                                            tmp.add(nums[start]);
                                            tmp.add(nums[end]);
                                            result.add(tmp);
                                            int startVal = nums[start];
                                            int endVal = nums[end];
                                            while (start < end && startVal == nums[start]) {
                                                start ++;
                                            }
                                            while (end > start && endVal == nums[end]) {
                                                end --;
                                            }
                                        }
                                        else if (sum < 0) {
                                            start ++;
                                        }
                                        else {
                                            end --;
                                        }
                                    }
                                }
                            }
                            return result;
                      }
                    
                      public static void main(String ...s){
                        System.out.println(threeSum(new int[]{-1,0,1,2,-1,-4}));
                      }
                    
                    }
                    `,
                  },
                  Python: {
                    code: `
class Test:

  @staticmethod
  def threeSum(nums):
    result = []
    nums.sort()
    for i in range(len(nums)):
      if i == 0 or nums[i] != nums[i-1]:
        start = i+1
        end = len(nums) - 1
        while start < end :
          s = nums[i] + nums[start] + nums[end]
          if s == 0:
            temp = [nums[i],nums[start],nums[end]]
            result.append(temp)
            startVal = nums[start]
            endVal = nums[end]
            while start < end and startVal == nums[start]:
              start+=1
            while end > start and endVal == nums[end]:
              end-=1
          elif s < 0:
            start+=1
          else:
            end-=1
    return result  
            
print(Test.threeSum([-1, 0, 1, 2, -1, -4]))`,
                  },
                  "C++": {
                    code: `#include <iostream>
                    #include <vector>
                    #include <algorithm>
                    
                    using namespace std;
                    
                    class Solution{
                      
                      public:
                      vector<vector<int>> threeSum(vector<int> nums){
                         vector<vector<int>> result;
                         sort(nums.begin(),nums.end());
                         for(int i=0;i<nums.size();i++){
                           if(i==0||nums[i]!=nums[i-1]){
                             int start = i+1;
                             int end = nums.size() - 1;
                             while(start<end){
                               int s = nums[i] + nums[start] + nums[end];
                               if(s==0){
                                 vector<int> temp;
                                 temp.insert(temp.end(),{nums[i],nums[start],nums[end]});
                                 result.push_back(temp);
                                 int startVal = nums[start];
                                 int endVal = nums[end];
                                 while(start < end && startVal == nums[start]){
                                       start++;   
                                 }
                                 while(end > start && endVal == nums[end]){
                                      end--;
                                 }
                               }else if(s<0){start++;}
                           else{end--;}
                             }
                           }
                         }
                         return result;
                      }
                    
                    };
                    
                    int main(){
                      Solution s;
                      vector<int> l{-1, 0, 1, 2, -1, -4};
                      for(auto v:s.threeSum(l)){
                        cout << "[ ";
                        for(auto i:v){
                          cout << i <<" , " ;
                        }
                        cout << " ]" << endl;
                      }
                      return 0;
                    }`,
                  },
                  Kotlin: {
                    code: `package com.programming

                    import java.util.LinkedList
                    
                    import java.util.Arrays
                    
                    internal class Test{
                       companion object {
                           fun threeSum(nums: IntArray): List<List<Int>>? {
                               val result: MutableList<List<Int>> = LinkedList()
                               Arrays.sort(nums)
                               for (i in nums.indices) {
                                   if (i == 0 || nums[i] != nums[i - 1]) {
                                       var start = i + 1
                                       var end = nums.size - 1
                                       while (start < end) {
                                           val sum = nums[i] + nums[start] + nums[end]
                                           if (sum == 0) {
                                               val tmp: MutableList<Int> = LinkedList()
                                               tmp.add(nums[i])
                                               tmp.add(nums[start])
                                               tmp.add(nums[end])
                                               result.add(tmp)
                                               val startVal = nums[start]
                                               val endVal = nums[end]
                                               while (start < end && startVal == nums[start]) {
                                                   start++
                                               }
                                               while (end > start && endVal == nums[end]) {
                                                   end--
                                               }
                                           } else if (sum < 0) {
                                               start++
                                           } else {
                                               end--
                                           }
                                       }
                                   }
                               }
                               return result
                           }
                       }
                    }
                    
                    fun main(){
                        print(Test.threeSum(intArrayOf(-1, 0, 1, 2, -1, -4)))
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q16: {
        title: "Q16. 3SumClosest",
        content: (
          <>
            <Span>
              <b>Q16. 3SumClosest</b>
            </Span>
            <Span>
              Given an integer array nums of length n and an integer target,
              find three integers in nums such that the sum is closest to
              target. Return the sum of the three integers. You may assume that
              each input would have exactly one solution.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [-1,2,1,-4], target = 1 <br />
              Output: 2 <br />
              Explanation: The sum that is closest to the target is 2. (-1 + 2 +
              1 = 2).
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0,0,0], target = 1 <br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              3 &lt;= nums.length &lt;= 1000 <br />
              -1000 &lt;= nums[i] &lt;= 1000 <br />
              -104 &lt;= target &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q16. 3SumClosest",
                output: `3`,
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @param {number} target
                    * @return {number}
                    */
                   var threeSumClosest = function(nums, target) {
                       if (nums.length == 0) {
                           return 0
                       }
                       nums.sort((a, b) => a - b)
                       let min = Number.MAX_VALUE
                       // Number.MIN_SAFE_INTEGER
                       var closest = 0
                       for (let i = 0; i < nums.length; i++) {
                           if (i == 0 || nums[i] != nums[i - 1]) {
                               let l = i + 1
                               let r = nums.length - 1
                               while (l < r) {
                                   let s = nums[i] + nums[l] + nums[r]
                                   if (Math.abs(s - target) < min) {
                                       min = Math.abs(s - target)
                                       closest = s
                                   }
                                   if (s < target) {
                                       l += 1
                                   } else {
                                       r -= 1
                                   }
                               }
                           }
                       }
                       return closest
                   };
                   
                   console.log(threeSumClosest([-1, 0, 1, 2, -1, -4], 8))`,
                    Java: {
                      code: `import java.util.*;

                      public class Main {
                          public static int threeSumClosest(int[] nums, int target) {
                              if (nums.length == 0){
                                  return 0;
                              }
                              Arrays.sort(nums);
                              int min = Integer.MAX_VALUE;
                              int closed = 0;
                              for (int i = 0; i < nums.length; i ++){
                                  if (i == 0 || nums[i] != nums[i-1]){
                                      int start = i + 1;
                                      int end = nums.length - 1;
                                      while (start < end){
                                          int sum = nums[i] + nums[start] + nums[end];
                                          if (Math.abs(sum - target) < min){
                                              min = Math.abs(sum - target);
                                              closed = sum;
                                          }
                                          if (sum < target){ 
                                              start ++; } 
                                          else{ 
                                              end --;
                                          }
                                      }
                                   }
                              }
                              return closed;
                          }
                      
                          public static void main(String ...s){
                            System.out.println(threeSumClosest(new int[]{-1,3,3},5));
                          }
                      
                      }`,
                    },
                    Python: {
                      code: `
import sys

class Test:

  @staticmethod
  def threeSumClosest(nums,target):
    if len(nums) == 0:
      return 0    
    nums.sort()
    # python 2 -> sys.maxint
    # python 3 > sys.maxsize
    minimum =  sys.maxsize
    closed = 0

    for i in range(len(nums)):
      if i == 0 or nums[i] != nums[i-1]:
        start = i+1
        end = len(nums) - 1
        while start < end :
          s = nums[i] + nums[start] + nums[end]
          if abs(s-target)<minimum:
            minimum = abs(s-target)
            closed = s
          if s < target:
            start+=1
          else:
            end-=1
    return closed
            
print(Test.threeSumClosest([-1, 0, 1, 2, -1, -4],8))`,
                    },
                    "C++": {
                      code: `#include <iostream>
                      #include <vector>
                      #include <algorithm>
                      #include <cstdlib>
                      #include <bits/stdc++.h>
                      
                      using namespace std;
                      
                      class Solution{
                        
                        public:
                        int threeSum(vector<int> nums,int target){
                           vector<vector<int>> result;
                           sort(nums.begin(),nums.end());
                           int closed = 0;
                           int minimum = INT_MAX;
                           if(nums.size()==0){
                             return 0;
                           }
                           for(int i=0;i<nums.size();i++){
                             if(i==0||nums[i]!=nums[i-1]){
                               int start = i+1;
                               int end = nums.size() - 1;
                               while(start<end){
                                 int s = nums[i] + nums[start] + nums[end];
                                 if(abs(s-target)<minimum){
                                   minimum = abs(s-target);
                                   closed = s;
                                 }
                             if(s<target){start++;}
                             else{end--;}
                               }
                             }
                           }
                      
                           return closed;
                        }
                      
                      };
                      
                      int main(){
                        Solution s;
                        vector<int> l{-1, 0, 1, 2, -1, -4};
                        cout << s.threeSum(l,8)<<endl;
                        return 0;
                      }`,
                    },
                    Kotlin: {
                      code: `import java.util.*
                      import kotlin.math.abs
                      
                      object Main {
                          fun threeSumClosest(nums: IntArray, target: Int): Int {
                              if (nums.isEmpty()) {
                                  return 0
                              }
                              Arrays.sort(nums)
                              var min = Int.MAX_VALUE
                              var closed = 0
                              for (i in nums.indices) {
                                  if (i == 0 || nums[i] != nums[i - 1]) {
                                      var start = i + 1
                                      var end = nums.size - 1
                                      while (start < end) {
                                          val sum = nums[i] + nums[start] + nums[end]
                                          if (abs(sum - target) < min) {
                                              min = abs(sum - target)
                                              closed = sum
                                          }
                                          if (sum < target) {
                                              start++
                                          } else {
                                              end--
                                          }
                                      }
                                  }
                              }
                              return closed
                          }
                      
                          @JvmStatic
                          fun main(s: Array<String>) {
                              println(threeSumClosest(intArrayOf(-1, 3, 3), 5))
                          }
                      }`,
                    },
                  },
                },
              }}
            />
          </>
        ),
      },
      q17: {
        title: "Q17. Letter Combinations Of A PhoneNumber",
        content: (
          <>
            <Span>
              <b>Q17. Letter Combinations of a Phone Number</b>
            </Span>
            <Span>
              Given a string containing digits from 2-9 inclusive, return all
              possible letter combinations that the number could represent.
              Return the answer in any order. A mapping of digit to letters
              (just like on the telephone buttons) is given below. Note that 1
              does not map to any letters.
            </Span>
            <Img left src={LeetcodeQ17} />
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: digits = "23" <br />
              Output: ["ad","ae","af","bd","be","bf","cd","ce","cf"]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: digits = "" <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= digits.length &lt;= 4 <br />
              digits[i] is a digit in the range ['2', '9'].
            </Span>
            <p>
              Time: O(n4^n)
              <br />
              Space: O(4^n)
            </p>
            <CodeEditor
              options={{
                title: "Q17. Letter Combinations Of A PhoneNumber",
                codes: {
                  Javascript: {
                    code: `/**
* @param {string} digits
* @return {string[]}
*/
const digitToLetters = ["","","abc","def","ghi","jkl","mno", "pqrs", "tuv", "wxyz"];

function dfs(digits , i, res, curr=[]) {
 if (i == digits.length) {
   res.push(curr.slice().join(""));
   return;
 }

 for (let c of digitToLetters[+digits[i]]) {
   curr.push(c);
   dfs(digits, i + 1, res, curr);
   curr.pop()
 }
}

var letterCombinations = function(digits) {
 if (!digits.length)
     return [];
 const res = [];
 dfs(digits, 0, res);
 return res;  
};

letterCombinations("2")`,
                    output: `[ 'a', 'b', 'c' ]`,
                  },
                  Java: {
                    code: `import java.util.*;
                    class Main {
                        public List<String> letterCombinations(String digits) {
                            HashMap<Character, String> keyboard = new HashMap<Character, String>();
                            initKeyboard(keyboard);
                            List<String> result = new LinkedList<String>();
                            if (digits.length() == 0) {
                                return result;
                            }
                            compile(digits, "", 0, keyboard, result); 
                            return result;
                        }
                    
                        // public LinkedList<String> letterCombinations(String digits) {
                        //     HashMap<Character, String> keyboard = new HashMap<Character, String>();
                        //     initKeyboard(keyboard);
                        //     LinkedList<String> result = new LinkedList<String>();
                        //     if (digits.length() == 0) {
                        //         return result;
                        //     }
                        //     compile(digits, "", 0, keyboard, result); 
                        //     return result;
                        // }
                    
                        private void compile(String digits, String prefix, int start, HashMap<Character, String> keyboard, List<String> result) {
                            if (start == digits.length()) {
                                result.add(prefix);
                                return;
                            }
                            String cand = keyboard.get(digits.charAt(start));
                            for (int j = 0; j < cand.length(); j++) {
                                compile(digits, prefix + cand.charAt(j), start + 1, keyboard, result);
                            }
                        }
                        private void initKeyboard(HashMap<Character, String> keyboard) {
                            keyboard.put('2', "abc");
                            keyboard.put('3', "def");
                            keyboard.put('4', "ghi");
                            keyboard.put('5', "jkl");
                            keyboard.put('6', "mno");
                            keyboard.put('7', "pqrs");
                            keyboard.put('8', "tuv");
                            keyboard.put('9', "wxyz");
                        }
                    
                       public static void main(String ...s){
                            Main m = new Main();
                            System.out.println(m.letterCombinations("234").toString());
                       }
                    
                    }`,
                  },
                  Python: {
                    code: `
class Main:

def __initKeyboard(self,keyboard):
  keyboard['2'] = "abc"
  keyboard['3'] = "def"
  keyboard['4'] = "ghi"
  keyboard['5'] = "jkl"
  keyboard['6'] = "mno"
  keyboard['7'] = "pqrs"
  keyboard['8'] = "tuv"
  keyboard['9'] = "wxyz"

def comp(self,digits:str,prefix:str,start:int,keyboard,result):
  if start == len(digits):
    result.append(prefix)
    return
  cand = keyboard[digits[start]]
  for e in cand:
    self.comp(digits,prefix+e,start+1,keyboard,result)

def letterCombinations(self,digits:str):
  keyboard = {}
  self.__initKeyboard(keyboard)
  res=[]
  if len(digits)==0:
    return res
  self.comp(digits,"",0,keyboard,res)
  return res

m = Main()
print(m.letterCombinations("234"))
                    `,
                  },
                  "C++": {
                    code: `#include <iostream>
                    #include <string>
                    #include <vector>
                    #include <bits/stdc++.h>
                    
                    using namespace std;
                    
                    class Main{
                      
                      public:
                      unordered_map<char,string> initKeyboard(unordered_map<char,string> keyboard){
                        keyboard['2'] = "abc";
                        keyboard['3'] = "def";
                        keyboard['4'] = "ghi";
                        keyboard['5'] = "jkl";
                        keyboard['6'] = "mno";
                        keyboard['7'] = "pqrs";
                        keyboard['8'] = "tuv";
                        keyboard['9'] = "wxyz";
                        return keyboard;
                      }
                    
                      void comp(string digits,string prefix,int start,unordered_map<char,string> keyboard,vector<string> 
                      *result){
                        if(start==digits.length()){
                          result->push_back(prefix);
                          return;
                        }
                        string cand = keyboard[digits[start]];
                        for(auto e:cand){
                          comp(digits,prefix+e,start+1,keyboard,result);
                        }
                      }
                      
                      vector<string> letterCombinations(string digits){
                        unordered_map<char,string> keyboard;
                        keyboard = initKeyboard(keyboard);
                        vector<string> res;
                        if(digits.length()==0){
                           return res;
                        }
                        this->comp(digits,"",0,keyboard,&res);
                        return res; 
                      }
                    
                    };
                    
                    int main(){
                      Main m;
                      for(auto i:m.letterCombinations("234")){
                        cout << i << endl;
                      }
                      return 0;
                    }`,
                  },
                  Kotlin: {
                    code: `import java.util.*
                    import kotlin.collections.HashMap
                    
                    internal class Main {
                        fun letterCombinations(digits: String): List<String> {
                            val keyboard = HashMap<Char, String>()
                            initKeyboard(keyboard)
                            val result: MutableList<String> = LinkedList()
                            if (digits.isEmpty()) {
                                return result
                            }
                            compile(digits, "", 0, keyboard, result)
                            return result
                        }
                    
                        private fun compile(
                            digits: String,
                            prefix: String,
                            start: Int,
                            keyboard: HashMap<Char, String>,
                            result: MutableList<String>
                        ) {
                            if (start == digits.length) {
                                result.add(prefix)
                                return
                            }
                            val cand = keyboard[digits[start]]
                            for (element in cand!!) {
                                compile(digits, prefix + element, start + 1, keyboard, result)
                            }
                        }
                    
                        private fun initKeyboard(keyboard: HashMap<Char, String>) {
                            keyboard['2'] = "abc"
                            keyboard['3'] = "def"
                            keyboard['4'] = "ghi"
                            keyboard['5'] = "jkl"
                            keyboard['6'] = "mno"
                            keyboard['7'] = "pqrs"
                            keyboard['8'] = "tuv"
                            keyboard['9'] = "wxyz"
                        }
                    
                        companion object {
                            @JvmStatic
                            fun main(s: Array<String>) {
                                val m = Main()
                                println(m.letterCombinations("234").toString())
                            }
                        }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q18: {
        title: "Q18. 4Sum",
        content: (
          <>
            <Span>
              <b>Q18. 4Sum</b>
            </Span>
            <Span>
              Given an array nums of n integers, return an array of all the
              unique quadruplets [nums[a], nums[b], nums[c], nums[d]] such that:
              <br />
              0 &lt;= a, b, c, d &lt; n <br />
              a, b, c, and d are distinct. <br />
              nums[a] + nums[b] + nums[c] + nums[d] == target <br />
              You may return the answer in any order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,0,-1,0,-2,2], target = 0 <br />
              Output: [[-2,-1,1,2],[-2,0,0,2],[-1,0,0,1]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [2,2,2,2,2], target = 8 <br />
              Output: [[2,2,2,2]]
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^3)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q18. 4Sum",
                output: `[[-2, -1, 1, 2], [-2, 0, 0, 2], [-1, 0, 0, 1]]`,
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @param {number} target
                    * @return {number[][]}
                    */
                   function nSum(nums, n, target, l, r, curr, res) {
                     if (r - l + 1 < n || target < nums[l] * n || target > nums[r] * n)
                       return;
                     if (n == 2) {
                       while (l < r) {
                         let sum = nums[l] + nums[r];
                         if (sum == target) {
                           curr.push(nums[l]);
                           curr.push(nums[r]);
                           res.push(curr.slice());
                           curr.pop()
                           curr.pop()
                           ++l;
                           --r;
                           while (l < r && nums[l] == nums[l - 1])
                             ++l;
                           while (l < r && nums[r] == nums[r + 1])
                             --r;
                         } else if (sum < target) {
                           ++l;
                         } else {
                           --r;
                         }
                       }
                       return;
                     }
                   
                     for (let i = l; i <= r; ++i) {
                       if (i > l && nums[i] == nums[i - 1])
                         continue;
                       curr.push(nums[i]);
                       nSum(nums, n - 1, target - nums[i], i + 1, r, curr, res);
                       curr.pop()
                     }
                   }
                   
                   var fourSum = function(nums, target) {
                     nums.sort((a,b)=>a-b)
                     const res = []
                     nSum(nums, 4, target, 0, nums.length - 1, [], res)
                     return res
                   };
                   
                   fourSum([1,0,-1,0,-2,2],0)`,
                    output: `[ [ -2, -1, 1, 2 ], [ -2, 0, 0, 2 ], [ -1, 0, 0, 1 ] ]`,
                  },
                  Java: {
                    code: `import java.util.*;

                    class Main {
                        public List<List<Integer>> fourSum(int[] nums, int target) {
                            List<List<Integer>> result = new LinkedList<List<Integer>>();
                            if (nums.length < 4) {
                                return result;
                            }
                            Arrays.sort(nums);
                            int max = nums[nums.length -1];
                            for (int i = 0; i < nums.length; i++) {
                                if (i > 0 && nums[i] == nums[i-1]) {
                                    continue;
                                }
                                if (nums[i] + 3 * max < target) {
                                    continue;
                                }
                                if (nums[i] * 4 > target) {
                                    break;
                                }
                                for (int j = i + 1; j < nums.length; j++) {
                                    if (j > i + 1 && nums[j] == nums[j-1]) {
                                        continue;
                                    }
                                    int start = j + 1;
                                    int end = nums.length - 1;
                                    while (start < end) {
                                        int sum = nums[i] + nums[j] + nums[start] + nums[end];
                                        if (sum == target) {
                                            List<Integer> tmp = new LinkedList<Integer>();
                                            tmp.add(nums[i]);
                                            tmp.add(nums[j]);
                                            tmp.add(nums[start]);
                                            tmp.add(nums[end]);
                                            result.add(tmp);
                                            int startVal = nums[start];
                                            int endVal = nums[end];
                                            while (start < end && startVal == nums[start]) {
                                                start ++;
                                            }
                                            while (start < end && endVal == nums[end]) {
                                                end --;
                                            }
                                        }
                                        else if (sum < target) {
                                            start ++;
                                        }       
                                        else {
                                            end --;
                                        }
                                    }
                                }
                            }
                            return result;
                        }
                    
                        public static void main(String ...s){
                          Main m = new Main();
                          System.out.println(m.fourSum(new int[]{1,0,-1,0,-2,2},0).toString());
                        }
                    
                    }`,
                  },
                  Python: {
                    code: `class Main:
  @staticmethod
  def fourSum(nums,target:int):
    result = []
    if len(nums)<4:
      return result
    nums.sort()
    maximum = nums[-1]
    for i in range(len(nums)):
      if i > 0 and nums[i]==nums[i-1]:
        continue
      if nums[i] + 3 * maximum < target:
        continue
      if nums[i]*4 > target:
        break
      for j in range(i+1,len(nums)):
        if j > i+1 and nums[j]==nums[j-1]:
          continue
        start = j+1
        end= len(nums)-1
        while start < end :
          total = nums[i]+nums[j]+nums[start]+nums[end]
          if total == target :
            tmp = []
            tmp.append(nums[i])
            tmp.append(nums[j])
            tmp.append(nums[start])
            tmp.append(nums[end])
            result.append(tmp)
            startVal = nums[start]
            endVal = nums[end]
            while start < end and startVal == nums[start]:
              start += 1
            while start < end and endVal == nums[end]:
              end -= 1
          elif total < target:
            start+=1
          else :
            end -=1
    return result
print(Main.fourSum([1,0,-1,0,-2,2],0))
                    `,
                  },
                  "C++": {
                    code: `#include <iostream>
                    #include <vector>
                    #include <algorithm>
                    
                    using namespace std;
                    
                    vector<vector<int>> fourSum(vector<int> nums, int target)
                    {
                        vector<vector<int>> result;
                        if (nums.size() == 0)
                        {
                            return result;
                        }
                        sort(nums.begin(), nums.end());
                        int max = nums[nums.size() - 1];
                        for (int i = 0; i < nums.size(); i++)
                        {
                            if (i > 0 && nums[i] == nums[i - 1])
                            {
                                continue;
                            }
                            if (nums[i] + 3 * max < target)
                            {
                                continue;
                            }
                            if (nums[i] * 4 > target)
                            {
                                break;
                            }
                            for (int j = i + 1; j < nums.size(); j++)
                            {
                                if (j > i + 1 && nums[j] == nums[j - 1])
                                {
                                    continue;
                                }
                                int start = j + 1;
                                int end = nums.size() - 1;
                                while (start < end)
                                {
                                    int sum = nums[i] + nums[j] + nums[start] + nums[end];
                                    if (sum == target)
                                    {
                                        vector<int> tmp;
                                        tmp.push_back(nums[i]);
                                        tmp.push_back(nums[j]);
                                        tmp.push_back(nums[start]);
                                        tmp.push_back(nums[end]);
                                        result.push_back(tmp);
                                        int startVal = nums[start];
                                        int endVal = nums[end];
                                        while (start < end && startVal == nums[start])
                                        {
                                            start++;
                                        }
                                        while (start < end && endVal == nums[end])
                                        {
                                            end--;
                                        }
                                    }
                                    else if (sum < target)
                                    {
                                        start++;
                                    }
                                    else
                                    {
                                        end--;
                                    }
                                }
                            }
                        }
                        return result;
                    }
                    
                    int main()
                    {
                        vector<int> v{1, 0, -1, 0, -2, 2};
                        for (auto i : fourSum(v, 0))
                        {
                            for (auto j : i)
                            {
                                cout << j << " ";
                            }
                            cout << endl;
                        };
                        return 0;
                    }`,
                  },
                  Kotlin: {
                    code: `import java.util.*

                    internal class Main {
                        fun fourSum(nums: IntArray, target: Int): List<List<Int>> {
                            val result: MutableList<List<Int>> = LinkedList()
                            if (nums.size < 4) {
                                return result
                            }
                            Arrays.sort(nums)
                            val max = nums[nums.size - 1]
                            for (i in nums.indices) {
                                if (i > 0 && nums[i] == nums[i - 1]) {
                                    continue
                                }
                                if (nums[i] + 3 * max < target) {
                                    continue
                                }
                                if (nums[i] * 4 > target) {
                                    break
                                }
                                for (j in i + 1 until nums.size) {
                                    if (j > i + 1 && nums[j] == nums[j - 1]) {
                                        continue
                                    }
                                    var start = j + 1
                                    var end = nums.size - 1
                                    while (start < end) {
                                        val sum = nums[i] + nums[j] + nums[start] + nums[end]
                                        if (sum == target) {
                                            val tmp: MutableList<Int> = LinkedList()
                                            tmp.add(nums[i])
                                            tmp.add(nums[j])
                                            tmp.add(nums[start])
                                            tmp.add(nums[end])
                                            result.add(tmp)
                                            val startVal = nums[start]
                                            val endVal = nums[end]
                                            while (start < end && startVal == nums[start]) {
                                                start++
                                            }
                                            while (start < end && endVal == nums[end]) {
                                                end--
                                            }
                                        } else if (sum < target) {
                                            start++
                                        } else {
                                            end--
                                        }
                                    }
                                }
                            }
                            return result
                        }
                    
                        companion object {
                            @JvmStatic
                            fun main(s: Array<String>) {
                                val m = Main()
                                println(m.fourSum(intArrayOf(1, 0, -1, 0, -2, 2), 0).toString())
                            }
                        }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q19: {
        title: "Q19. Remove Nth Node From End of List",
        content: (
          <>
            <Span>
              <b>Q19. Remove Nth Node From End of List</b>
            </Span>
            <Span>
              Given the head of a linked list, remove the nth node from the end
              of the list and return its head.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={LeetcodeQ19} alt="img" left />
            <Span>
              Input: head = [1,2,3,4,5], n = 2 <br />
              Output: [1,2,3,5]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [1], n = 1 <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: head = [1,2], n = 1 <br />
              Output: [1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is sz. <br />
              1 &lt;= sz &lt;= 30 <br />
              0 &lt;= Node.val &lt;= 100 <br />1 &lt;= n &lt;= sz
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q19. Remove Nth Node From End of List",
                output: `1 -> 2 -> 3 -> 4 -> 5
                1 -> 2 -> 3 -> 5`,
                code: `1 -> 2 -> 3 -> 4 -> 5
                1 -> 2 -> 3 -> 5`,
                title: "Q19. Remove Nth Node From End of List",
                codes: {
                  Javascript: {
                    code: `// Definition for singly-linked list.
                    function ListNode(val, next) {
                        this.val = (val === undefined ? 0 : val)
                        this.next = (next === undefined ? null : next)
                    }
                    /**
                     * @param {ListNode} head
                     * @param {number} n
                     * @return {ListNode}
                     */
                    var removeNthFromEnd = function(head, n) {
                      let slow = fast = head;
                      while (n-- > 0)
                        fast = fast.next;
                      if (fast == null)
                        return head.next;
                      while (fast.next) {
                        slow = slow.next;
                        fast = fast.next;
                      }
                      slow.next = slow.next.next;
                      return head;
                    };
                    
                    const head = new ListNode(1)
                    head.next = new ListNode(2)
                    head.next.next = new ListNode(3)
                    head.next.next.next = new ListNode(4)
                    head.next.next.next.next = new ListNode(5)
                    removeNthFromEnd(head, 2)
                    let temp = head
                    while (temp) {
                        console.log(temp.val)
                        temp = temp.next
                    }    
                    `,
                    output: `1
                    2
                    3
                    5
                    null`,
                  },
                  Java: {
                    code: `import java.util.List;
                    import java.util.stream.Collectors;
                    import java.util.*;
                    
                    class ListNode {
                         int val;
                         ListNode next;
                         ListNode() {}
                         ListNode(int val) { this.val = val; }
                         ListNode(int val, ListNode next) { this.val = val; this.next = next; }
                      }
                    
                    class Main {
                    
                        static ListNode root;
                    
                        public  static ListNode removeNthFromEnd(ListNode head, int n) {
                            int len = 0;
                            ListNode tmp = head;
                            while(tmp != null){
                                tmp = tmp.next;
                                len ++;
                            }
                            int toDe = len - n;
                            ListNode fake = new ListNode(-1);
                            ListNode parent = fake;
                            parent.next = head;
                            while (toDe > 0){
                                parent = parent.next;
                                toDe --;
                            }
                            parent.next = parent.next.next;
                            return fake.next;      
                        }
                    
                        public static void display(){
                          display(root);
                        }
                    
                        public static void display(ListNode temp){
                          while(temp.next != null){
                            System.out.print(String.valueOf(temp.val) + (temp.next != null ?" -> ":""));
                            temp =temp.next;
                          }
                          if(temp != root)
                          System.out.print(String.valueOf(temp.val));
                          System.out.print("\n");
                        }
                    
                        public static void main(String ...s){
                          int arr[] = {1,2,3,4,5};
                          List<Integer> list = Arrays.stream(arr).boxed().collect(Collectors.toList());
                          ListNode temp = new ListNode();
                            for(int i=0;i<list.size();i++){
                              if(list.size()==0){
                                 return;
                              }else if(i==0){
                                 root = new ListNode(list.get(i)); 
                              }else{
                                temp = root;
                                while(temp.next != null){
                                      temp = temp.next;      
                                }
                                temp.next = new ListNode(list.get(i));
                              } 
                            }
                          display(); 
                          ListNode res = removeNthFromEnd(root,2);
                          display(res);
                        }
                    }`,
                  },
                  Python: {
                    code: `class ListNode:
  
  def __init__(self,val=0):
    self.val=val
    self.next=None
  
class Main:

  def __init__(self):
    self.root = None

  def add(self,data):
    node = ListNode(data)
    if self.root is None:
      self.root = node
    else:
      temp = self.root
      while temp.next is not None:
        temp = temp.next
      temp.next = node
  
  def removeNthFromEnd(self,n):
    count = 0
    tmp = self.root
    while tmp is not None:
      tmp = tmp.next
      count+=1
    toDe = count - n
    parent = ListNode(0)
    parent.next = self.root
    second = parent
    while toDe > 0:
      second = second.next
      toDe -= 1
    second.next = second.next.next
    return parent.next
  
  def display(self):
    tmp = self.root
    while tmp is not None:
      print(tmp.val)
      tmp = tmp.next

arr = list(range(6))
m = Main()
for i in range(1,len(arr)):
  m.add(arr[i])
m.display()
y = m.removeNthFromEnd(2)
while y is not None:
  print(y.val)
  y = y.next
                    `,
                  },
                  "C++": {
                    code: `#include <iostream>
                    #include <bits/stdc++.h>
                    #include <vector>
                    
                    using namespace std;
                    
                    struct ListNode {
                       int val = 0;
                       ListNode *next;
                       ListNode() : val(0), next(nullptr) {}
                       ListNode(int x) : val(x), next(nullptr) {}
                       ListNode(int x, ListNode *next) : val(x), next(next) {}
                    };
                    
                    class Main{
                      
                      public:
                      ListNode *root;
                    
                    
                      void add(vector<int> v1){
                      vector<int> v(v1);
                      ListNode* temp= new ListNode();
                      for(auto i=0;i<v.size();i++){
                        if(v.size()==0)
                        return;
                        else if(i==0){
                         root = new ListNode(v[i]);
                        }else{
                         temp = root;
                         while(temp->next!=nullptr){
                          temp = temp -> next;
                         }
                         temp -> next = new ListNode(v[i]);
                        }
                      }
                      }
                    
                      ListNode* removeNthFromEnd(int n){
                        int len =0;
                        ListNode *tmp;
                        tmp = root;
                        while(tmp!=NULL){
                          tmp = tmp->next;
                          len++;
                        }
                        int toDe = len -n;
                        ListNode* fake = new ListNode(0); 
                        ListNode* parent = fake;
                        parent->next = root;
                        while(toDe>0){
                          parent = parent->next;
                          toDe--;
                        }
                        parent -> next = parent -> next -> next;
                        return fake->next;
                      }
                    
                      void display(){
                        ListNode *temp = new ListNode();
                        temp = root;
                        while(temp!= nullptr){
                          cout << temp -> val << endl;
                          temp = temp -> next;
                        }
                      }
                    
                    };
                    
                    
                    int main(){
                      vector<int> v(5);
                      iota(v.begin(),v.end(),1);
                      Main m;
                      m.add(v);
                      m.display();
                      cout << endl;
                      ListNode *m1=m.removeNthFromEnd(2);
                      while(m1!= nullptr){
                          cout << m1 -> val << endl;
                          m1 = m1 -> next;
                      }  
                      return 0;
                    }
                    `,
                  },
                  Kotlin: {
                    code: `import java.util.*
                    import java.util.stream.Collectors
                    
                    internal class ListNode {
                        var 'val' = 0
                        var next: ListNode? = null
                    
                        constructor() {}
                        constructor('val': Int) {
                            this.'val' = 'val'
                        }
                    
                        constructor('val': Int, next: ListNode?) {
                            this.'val' = 'val'
                            this.next = next
                        }
                    }
                    
                    internal object Main {
                        var root: ListNode? = null
                        fun removeNthFromEnd(head: ListNode?, n: Int): ListNode? {
                            var len = 0
                            var tmp = head
                            while (tmp != null) {
                                tmp = tmp.next
                                len++
                            }
                            var toDe = len - n
                            val fake = ListNode(-1)
                            var parent: ListNode? = fake
                            parent!!.next = head
                            while (toDe > 0) {
                                parent = parent!!.next
                                toDe--
                            }
                            parent!!.next = parent.next!!.next
                            return fake.next
                        }
                    
                        @JvmOverloads
                        fun display(temp: ListNode? = root) {
                            var temp = temp
                            while (temp!!.next != null) {
                                print(temp.'val'.toString() + if (temp.next != null) " -> " else "")
                                temp = temp.next
                            }
                            if (temp !== root) print(temp.'val'.toString())
                            print("\n")
                        }
                    
                        @JvmStatic
                        fun main(s: Array<String>) {
                            val arr = intArrayOf(1, 2, 3, 4, 5)
                            val list = Arrays.stream(arr).boxed().collect(Collectors.toList())
                            var temp: ListNode? = ListNode()
                            for (i in list.indices) {
                                if (list.size == 0) {
                                    return
                                } else if (i == 0) {
                                    root = ListNode(list[i])
                                } else {
                                    temp = root
                                    while (temp!!.next != null) {
                                        temp = temp.next
                                    }
                                    temp.next = ListNode(list[i])
                                }
                            }
                            display()
                            val res = removeNthFromEnd(root, 2)
                            display(res)
                        }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q20: {
        title: "Q20. Valid Parenthesis",
        content: (
          <>
            <Span>
              <b>Q20. Valid Parenthesis</b>
            </Span>
            <Span>
              Given a string s containing just the characters '(', ')', {"{"},{" "}
              {"}"}
              ', '[' and ']', determine if the input string is valid. An input
              string is valid if: Open brackets must be closed by the same type
              of brackets. Open brackets must be closed in the correct order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>Input: s = "()" Output: true</Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>Input: s = "()[]{"{}"}" Output: true</Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>Input: s = "(]" Output: false</Span>
            <Span>
              <b>Constraints</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 104 <br />s consists of parentheses only
              '()[]{"{}"}'.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q20. Valid Parenthesis",
                codes: {
                  Javascript: {
                    output: `true`,
                    code: `/**
                    * @param {string} s
                    * @return {boolean}
                    */
                   var isValid = function(s) {
                     var match={
                       ')':'(',
                       '}':'{',
                       ']':'['
                     }
                     var stack=[]
                     for(let i in s){
                       if(s[i]==='('||s[i]==='{'||s[i]==='['){
                         stack.push(s[i])
                         continue
                       }
                       if(stack.length === 0 || match[s[i]] != stack.pop()){
                         return false
                       }
                     }
                     return stack.length === 0
                   };
                   
                   console.log(isValid('()()'))`,
                  },
                  Java: {
                    code: `import java.util.*;

                    class Solution {
                        public boolean isValid(String s) {
                            HashMap<Character, Character> match = new HashMap<Character, Character>();
                            match.put(')', '(');
                            match.put('}', '{');
                            match.put(']', '[');
                            Stack<Character> stack = new Stack<Character>();
                            for (int i = 0; i < s.length(); i++) {
                                if (s.charAt(i) == '(' || s.charAt(i) == '{' || s.charAt(i) == '[') {
                                    stack.push(s.charAt(i));
                                    continue;
                                }
                                if (stack.size() == 0 || match.get(s.charAt(i)) != stack.pop()) {
                                    return false;
                                }
                            }
                            if (stack.size() == 0) {
                                return true;
                            }
                            return false;
                        }
                    }`,
                  },
                  "C++": {
                    code: `#include <iostream>
                    #include <stack> 
                    #include <bits/stdc++.h>
                    
                    using namespace std;
                      
                    class Solution{
                      public:
                      static bool isValid(string s){
                        unordered_map<char,char> match;
                        match[')'] = '(';
                        match['}'] = '{';
                        match[']'] = '[';
                        stack<char> st;
                        for(int i=0;i<s.length();i++){
                          if(s[i]=='('||s[i]=='{'||s[i]=='['){
                            st.push(s[i]);
                            continue;
                          }
                          if(st.size()==0||match[s[i]]!=st.top()){
                            return false;
                          }
                          st.pop();
                        }
                        if(st.size()==0){
                          return true;
                        }
                        return false;
                      }
                    };
                    
                    int main(){
                      cout<< boolalpha;
                      cout<< Solution::isValid("(())")<<endl;
                      return 0;
                    }`,
                  },
                  Python: {
                    code: `class Solution():
  @staticmethod
  def isValid(s):
    match={
      ')':'(',
      '}':'{',
      ']':'['
    }
    stack = []
    for i in range(len(s)):
      if s[i] == '(' or s[i] == '{' or s[i] == '[':
        stack.append(s[i])
        continue
      if len(stack) == 0 or match[s[i]] != stack.pop():
        return False
    return len(stack)==0
print(Solution.isValid('{{()}}'))`,
                  },
                  Kotlin: {
                    code: `import java.util.*
                    import kotlin.collections.HashMap
                    
                    internal class Solution {
                        fun isValid(s: String): Boolean {
                            val match = HashMap<Char, Char>()
                            match[')'] = '('
                            match['}'] = '{'
                            match[']'] = '['
                            val stack = Stack<Char>()
                            for (i in s.indices) {
                                if (s[i] == '(' || s[i] == '{' || s[i] == '[') {
                                    stack.push(s[i])
                                    continue
                                }
                                if (stack.size == 0 || match[s[i]] !== stack.pop()) {
                                    return false
                                }
                            }
                            return stack.size == 0
                        }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q21: {
        title: "Q21. Merge Two Sorted Lists",
        content: (
          <>
            <Span>
              <b>Q21. Merge Two Sorted Lists</b>
            </Span>
            <Span>
              You are given the heads of two sorted linked lists list1 and
              list2. Merge the two lists in a one sorted list. The list should
              be made by splicing together the nodes of the first two lists.
              Return the head of the merged linked list.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={LeetcodeQ21} left alt="img" />
            <Span>
              Input: list1 = [1,2,4], list2 = [1,3,4] <br />
              Output: [1,1,2,3,4,4]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: list1 = [], list2 = [] <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: list1 = [], list2 = [0] <br />
              Output: [0]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in both lists is in the range [0, 50]. -100
              &lt;= Node.val &lt;= 100 Both list1 and list2 are sorted in
              non-decreasing order.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q21. Merge Two Sorted Lists",
                output: `#############
                1
                2
                4
                #############
                #############
                1
                3
                4
                #############
                #############
                1
                1
                2
                3
                4
                4
                #############`,
                codes: {
                  Javascript: {
                    code: `// Definition for singly-linked list.
                    function ListNode(val, next) {
                        this.val = (val===undefined ? 0 : val)
                        this.next = (next===undefined ? null : next)
                    }
                    /**
                     * @param {ListNode} list1
                     * @param {ListNode} list2
                     * @return {ListNode}
                     */
                    var mergeTwoLists = function(list1, list2) {
                      let l3= new ListNode(-1);
                      let l1 = list1, l2 = list2
                      let node = l3;
                      while(l1 !=null && l2 != null){
                            if(l1.val<l2.val){
                              node.next = l1;
                              l1 = l1.next;
                              node = node.next;
                            }else{
                              node.next = l2;
                              l2 = l2.next;
                              node = node.next;
                            }
                      }
                      if(l1 != null){
                        node.next = l1;
                      }else if(l2!=null){
                        node.next = l2;
                      }
                      return l3.next;
                    };
                    
                    const l1 = new ListNode(1)
                    l1.next = new ListNode(2)
                    l1.next.next = new ListNode(4)
                    const l2 = new ListNode(1)
                    l2.next = new ListNode(3)
                    l2.next.next = new ListNode(4)
                    console.log(mergeTwoLists(l1,l2))`,
                  },
                  Java: {
                    code: `import java.util.*;
                    import java.util.stream.Collectors;
                    
                    class ListNode{
                      int val;
                      ListNode next;
                      ListNode(){}
                      ListNode(int val){
                        this.val = val;
                      }
                      ListNode(int val,ListNode next){
                        this.val=val;
                        this.next=next;
                      }
                    }
                    
                    class Main{
                      
                      public static ListNode mergeTwoLists(ListNode l1,ListNode l2){
                        ListNode l3= new ListNode(-1);
                        ListNode node = l3;
                        while(l1 !=null && l2 != null){
                              if(l1.val<l2.val){
                                node.next = l1;
                                l1 = l1.next;
                                node = node.next;
                              }else{
                                node.next = l2;
                                l2 = l2.next;
                                node = node.next;
                              }
                        }
                        if(l1 != null){
                          node.next = l1;
                        }else if(l2!=null){
                          node.next = l2;
                        }
                        return l3.next;
                      }
                    
                      public static ListNode insert(List<Integer> l){
                      ListNode root = new ListNode();
                      for(int i=0;i<l.size();i++){
                        ListNode data = new ListNode(l.get(i));
                        if(i==0){
                          root = data;
                        }else{
                          ListNode temp = new ListNode();
                          temp=root;
                          while(temp.next!=null){
                            temp=temp.next;
                          }
                          temp.next= data;
                        }
                      }
                      return root;
                      }
                    
                      public static void display(ListNode l){
                          ListNode temp = new ListNode();
                          temp = l;
                          System.out.println("#############");
                          while(temp!=null){
                            System.out.println(temp.val);
                            temp=temp.next;
                          }
                          System.out.println("#############");
                      }
                    
                      public static void main(String ...s){
                        int arr1[] = {1,2,4};
                        int arr2[] = {1,3,4};
                        List<Integer> list1 = Arrays.stream(arr1).boxed().collect(Collectors.toList());
                        List<Integer> list2 = Arrays.stream(arr2).boxed().collect(Collectors.toList());
                        ListNode l1 = insert(list1);
                        ListNode l2 = insert(list2);
                        display(l1);
                        display(l2);
                        ListNode res = mergeTwoLists(l1,l2);
                        display(res);
                      }
                    }`,
                  },
                  "C++": {
                    code: `#include <iostream>
                    #include <bits/stdc++.h>
                    #include <vector>
                    
                    using namespace std;
                    
                    struct ListNode {
                       int val = 0;
                       ListNode *next;
                       ListNode() : val(0), next(nullptr) {}
                       ListNode(int x) : val(x), next(nullptr) {}
                       ListNode(int x, ListNode *next) : val(x), next(next) {}
                    };
                    
                    class Main{
                      
                      public:
                      ListNode *root;
                    
                      void add(vector<int> v1){
                      vector<int> v(v1);
                      ListNode* temp= new ListNode();
                      for(auto i=0;i<v.size();i++){
                        if(v.size()==0)
                        return;
                        else if(i==0){
                         root = new ListNode(v[i]);
                        }else{
                         temp = root;
                         while(temp->next!=nullptr){
                          temp = temp -> next;
                         }
                         temp -> next = new ListNode(v[i]);
                        }
                      }
                      }
                    
                      static ListNode* mergeTwoLists(ListNode* l1,ListNode* l2){
                        ListNode* l3   = new ListNode(-1);
                        ListNode* node = l3;
                        while(l1 != nullptr && l2 != nullptr){
                           if(l1->val < l2->val){
                             node-> next = l1;
                             l1 = l1->next;
                             node = node->next;
                           }else{
                             node-> next = l2;
                             l2 = l2->next;
                             node = node->next; 
                           }
                        }
                        if(l1 != nullptr){
                          node->next = l1;
                        }else if(l2 != nullptr){
                          node->next = l2;
                        }
                        return l3->next;
                      }
                    
                      void display(){
                        ListNode *temp = new ListNode();
                        temp = root;
                        while(temp!= nullptr){
                          cout << temp -> val << endl;
                          temp = temp -> next;
                        }
                      }
                    
                    };
                    
                    
                    int main(){
                      vector<int> v(5);
                      iota(v.begin(),v.end(),1);
                      Main m;
                      m.add(v);
                      Main m1;
                      m1.add(v);
                      cout << endl;
                      ListNode* m2=Main::mergeTwoLists(m.root,m1.root);
                      while(m2!= nullptr){
                          cout << m2 -> val << endl;
                          m2 = m2 -> next;
                      }  
                      return 0;
                    }
                    `,
                  },
                  Python: {
                    code: `
class Node:
  def __init__(self,data):
    self.data=data
    self.next=None
  
class LinkedList:
  def __init__(self):
    self.root = None

  def add(self,data):
    node = Node(data)
    if self.root is None:
      self.root = node
    else:
      temp = self.root
      while temp.next is not None:
        temp= temp.next
      temp.next = node

  def display(self):
    temp = self.root
    while temp is not None:
      print(temp.data)
      temp = temp.next
    print("############")
  
  @staticmethod
  def mergeTwoLists(l1,l2):
    l3 = Node(-1)
    node = l3
    while l1 is not None and l2 is not None:
      if l1.data < l2.data:
        node.next = l1
        l1=l1.next
        node = node.next
      else:
        node.next = l2
        l2 = l2.next
        node = node.next
    if l1 is not None:
      node.next = l1
    else:
      node.next = l2
    return l3.next


l1 = LinkedList()
l2 = LinkedList()
l1.add(2)
l1.add(4)
l1.add(3)
l2.add(5)
l2.add(6)
l2.add(4)
l1.display()
l2.display()
ans = LinkedList.mergeTwoLists(l1.root,l2.root)
while ans is not None:
  print(ans.data)
  ans = ans.next`,
                  },
                  Kotlin: {
                    code: `import java.util.*
                    import java.util.stream.Collectors
                    
                    internal class ListNode {
                        var 'val' = 0
                        var next: ListNode? = null
                    
                        constructor() {}
                        constructor('val': Int) {
                            this.'val' = 'val'
                        }
                    
                        constructor('val': Int, next: ListNode?) {
                            this.'val' = 'val'
                            this.next = next
                        }
                    }
                    
                    internal object Main {
                        private fun mergeTwoLists(l1: ListNode?, l2: ListNode?): ListNode? {
                            var l1 = l1
                            var l2 = l2
                            val l3 = ListNode(-1)
                            var node: ListNode? = l3
                            while (l1 != null && l2 != null) {
                                if (l1.'val' < l2.'val') {
                                    node!!.next = l1
                                    l1 = l1.next
                                    node = node.next
                                } else {
                                    node!!.next = l2
                                    l2 = l2.next
                                    node = node.next
                                }
                            }
                            if (l1 != null) {
                                node!!.next = l1
                            } else if (l2 != null) {
                                node!!.next = l2
                            }
                            return l3.next
                        }
                    
                        private fun insert(l: List<Int>): ListNode? {
                            var root: ListNode? = ListNode()
                            for (i in l.indices) {
                                val data = ListNode(l[i])
                                if (i == 0) {
                                    root = data
                                } else {
                                    var temp: ListNode? = ListNode()
                                    temp = root
                                    while (temp!!.next != null) {
                                        temp = temp.next
                                    }
                                    temp.next = data
                                }
                            }
                            return root
                        }
                    
                        private fun display(l: ListNode?) {
                            var temp: ListNode? = ListNode()
                            temp = l
                            println("#############")
                            while (temp != null) {
                                println(temp.'val')
                                temp = temp.next
                            }
                            println("#############")
                        }
                    
                        @JvmStatic
                        fun main(s: Array<String>) {
                            val arr1 = intArrayOf(1, 2, 4)
                            val arr2 = intArrayOf(1, 3, 4)
                            val list1 = Arrays.stream(arr1).boxed().collect(Collectors.toList())
                            val list2 = Arrays.stream(arr2).boxed().collect(Collectors.toList())
                            val l1 = insert(list1)
                            val l2 = insert(list2)
                            display(l1)
                            display(l2)
                            val res = mergeTwoLists(l1, l2)
                            display(res)
                        }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q22: {
        title: "Q22. Generate Parentheses",
        content: (
          <>
            <Span>
              <b>Q22. Generate Parentheses</b>
            </Span>
            <Span>
              Given n pairs of parentheses, write a function to generate all
              combinations of well-formed parentheses
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 3 <br />
              Output: ["((()))","(()())","(())()","()(())","()()()"]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1 <br />
              Output: ["()"]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= n &lt;= 8</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(2^2n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q22. Generate Parentheses",
                output: `[((())), (()()), (())(), ()(()), ()()()]`,
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {string[]}
                    */
                   var generateParenthesis = function(n) {
                       var result = []
                       dfs("", n, n, result)
                       return result
                   };
                   
                   function dfs(prefix, l, r, result) {
                       if (l === 0 && r === 0) {
                           result.push(prefix)
                           return
                       }
                       if (l > 0) {
                           dfs(prefix + "(", l - 1, r, result)
                       }
                       if (l < r) {
                           dfs(prefix + ")", l, r - 1, result)
                       }
                   }
                   
                   console.log(generateParenthesis(3))`,
                  },
                  Java: {
                    code: `import java.util.*;

                    class Main {
                      public List<String> generateParenthesis(int n){
                            List<String> result = new LinkedList<String>();
                            process("", n, n, result);
                            return result;
                      }
                      private void process(String prefix, int left, int right, List<String> result) {
                            if (left == 0 && right == 0) {
                                result.add(prefix);
                                return;
                            }
                            if (left > 0) {
                                process(prefix + '(', left - 1, right, result);
                            }
                            if (left < right) {
                                process(prefix + ')', left, right - 1, result);
                            }
                      } 
                      public static void main(String ...s){
                        Main sol = new Main();
                        System.out.println(sol.generateParenthesis(3));
                      }
                    }`,
                  },
                  Python: {
                    code: `class Main:

  def generateParenthesis(self,n:int):
    result = []
    self.process("",n,n,result)
    return result
  
  def process(self,prefix,left,right,result):
    if left == 0 and right == 0:
      result.append(prefix)
      return
    if left > 0 :
      self.process(f"{prefix}(",left-1,right,result)
    if left < right:
      self.process(f"{prefix})",left,right-1,result)

sol = Main()
print(sol.generateParenthesis(3))`,
                  },
                  "C++": {
                    code: `#include<iostream>
                    #include<string>
                    #include<vector>
                    
                    using namespace std;
                    
                    class Main{
                       
                      public: 
                      vector<string> generateParenthesis(int n){
                         vector<string> result;
                         process("",n,n,&result);
                         return result;
                      }
                    
                      void process(string prefix,int left,int right,vector<string> *result)
                      {
                        if(left==0&&right==0){
                          result->push_back(prefix);
                          return; 
                        }
                        if(left>0){
                          process(prefix+"(",left-1,right,result);
                        }
                        if(left<right){
                          process(prefix+")",left,right-1,result);
                        }
                      }
                    };
                    
                    int main(){
                      Main m;
                      vector<string> vec;
                      vec = m.generateParenthesis(3);
                      for(auto i:vec){
                        cout << i << " ";
                      }
                      return 0;
                    }`,
                  },
                  Kotlin: {
                    code: `import java.util.*

                    internal class Main {
                        fun generateParenthesis(n: Int): List<String> {
                            val result: MutableList<String> = LinkedList()
                            process("", n, n, result)
                            return result
                        }
                    
                        private fun process(prefix: String, left: Int, right: Int, result: MutableList<String>) {
                            if (left == 0 && right == 0) {
                                result.add(prefix)
                                return
                            }
                            if (left > 0) {
                                process("$prefix(", left - 1, right, result)
                            }
                            if (left < right) {
                                process("$prefix)", left, right - 1, result)
                            }
                        }
                    
                        companion object {
                            @JvmStatic
                            fun main(s: Array<String>) {
                                val sol = Main()
                                println(sol.generateParenthesis(3))
                            }
                        }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q23: {
        title: "Q23. Merge K Sorted Lists",
        content: (
          <>
            <Span>
              <b>Q23. Merge K Sorted Lists</b>
            </Span>
            <Span>
              You are given an array of k linked-lists lists, each linked-list
              is sorted in ascending order. Merge all the linked-lists into one
              sorted linked-list and return it.
            </Span>
            <Span>
              <b>Example 1</b>
            </Span>
            <Span>
              Input: lists = [[1,4,5],[1,3,4],[2,6]] <br />
              Output: [1,1,2,3,4,4,5,6] <br />
              Explanation: The linked-lists are: <br />
              [ <br />
              1-&gt;4-&gt;5, <br />
              1-&gt;3-&gt;4, <br />
              2-&gt;6 <br />
              ] <br />
              ] <br />
              merging them into one sorted list: <br />
              1-&gt;1-&gt;2-&gt;3-&gt;4-&gt;4-&gt;5-&gt;6 <br />
            </Span>
            <Span>
              <b>Example 2</b>
            </Span>
            <Span>
              Input: lists = [] <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3</b>
            </Span>
            <Span>
              Input: lists = [] <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints</b>
            </Span>
            <Span>
              k == lists.length <br />
              0 &lt;= k &lt;= 10^4 <br />
              0 &lt;= lists[i].length &lt;= 500 <br />
              -10^4 &lt;= lists[i][j] &lt;= 10^4 <br />
              lists[i] is sorted in ascending order. <br />
              The sum of lists[i].length won't exceed 10^4. <br />
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(nlogk)
              <br />
              Space: O(k)
            </p>
            <CodeEditor
              options={{
                title: "Q23. Merge K Sorted Lists",
                output: `#####################
                1
                4
                5
                #####################
                1
                3
                4
                #####################
                1
                1
                3
                4
                4
                5
                #####################`,
                codes: {
                  Java: {
                    code: `import java.util.*;

                    class ListNode{
                      int val;
                      ListNode next;
                      ListNode(){}
                      ListNode(int val){
                        this.val = val;
                      }
                      ListNode(int val,ListNode next){
                        this.val = val;
                        this.next = next;
                      }
                    }
                    
                    class Main{
                      public static ListNode mergekLists(ListNode[] lists){
                            PriorityQueue<ListNode> q = new PriorityQueue<ListNode>(Math.max(1,lists.length), new Comparator<ListNode>() {
                                @Override
                                public int compare(ListNode e1, ListNode e2) {
                                    return e1.val - e2.val;
                                }
                            });
                            for (int i = 0; i < lists.length; i++) {
                                if (lists[i] != null) {
                                    q.add(lists[i]);
                                }
                            }
                            // Iterator i = q.iterator();
                            // while(i.hasNext()){
                            //   display((ListNode)i.next());
                            // }
                            ListNode fakeHead = new ListNode(-1);
                            ListNode p = fakeHead;
                            while (!q.isEmpty()) {
                                ListNode n = q.poll();
                                if (n.next != null) {
                                    q.add(n.next);
                                }
                                n.next = null;
                                p.next = n;
                                p = n;
                            }
                            return fakeHead.next;
                      }
                    
                      public static void lineBreak(){
                        System.out.println("#####################");
                      }
                    
                      public static void display(ListNode l){
                        ListNode temp = l;
                        while(temp!=null){
                          System.out.println(temp.val);
                          temp=temp.next;
                        }
                      }
                    
                      public static ListNode insert(List<Integer> l){
                      ListNode root = new ListNode();
                      for(int i=0;i<l.size();i++){
                        ListNode data = new ListNode(l.get(i));
                        if(i==0){
                          root = data;
                        }else{
                          ListNode temp = new ListNode();
                          temp=root;
                          while(temp.next!=null){
                            temp=temp.next;
                          }
                          temp.next= data;
                        }
                      }
                      return root;
                      }
                    
                      public static void main(String ...s){
                        ListNode[] lists = new ListNode[2];
                        List<Integer> list1 = Arrays.asList(1,4,5);
                        List<Integer> list2 = Arrays.asList(1,3,4);
                        lineBreak();
                        ListNode l1 = insert(list1);
                        ListNode l2 = insert(list2);
                        lists[0] = l1;
                        lists[1] = l2;
                        //  list1.stream().forEach(x->{
                        //  System.out.println(x);
                        //  });
                         display(l1);
                         lineBreak();
                         display(l2);
                         lineBreak();
                         ListNode res = mergekLists(lists);
                         display(res);
                         lineBreak();
                      }
                    }`,
                  },
                  Python: {
                    code: `
class PriorityQueue(object):

  def __init__(self):
    self.queue = []

  def __str__(self):
    return str(self.queue)

  def insert(self,data):
    d = []
    for i in data:
      d.extend(ListNode.fetchValues(i))
    # *ListNode.fetchValues(i)
    self.queue += d
    self.queue = sorted(self.queue)

class ListNode():
  def __init__(self,val):
    self.val=val
    self.next=None
  
  @staticmethod 
  def fetchValues(t):
    res=[]
    temp=t
    while temp is not None:
      res.append(temp.val)
      temp = temp.next
    return res

  @staticmethod
  def display(t):
    temp = t
    while temp is not None:
      print(temp.val)
      temp = temp.next
  
  @staticmethod
  def insert(list):
    root= None
    head= None
    for i in range(len(list)):
      if i==0:
        root = ListNode(list[i])
        head = root
      else:
        root.next = ListNode(list[i])
        root = root.next
    return head

def lineBreak():
  print("###############")

l1 = ListNode.insert([3,4,1])
l2 = ListNode.insert([5,6,1])
lineBreak()
ListNode.display(l1)
lineBreak()
ListNode.display(l2)
lineBreak()
q = PriorityQueue()
q.insert([l1,l2])
print(q)
                    `,
                  },
                  "C++": {
                    code: `#include<iostream>
                    #include<bits/stdc++.h>
                    #include<vector>
                    #include<queue>
                    
                    using namespace std;
                    
                    struct ListNode {
                       int val = 0;
                       ListNode *next;
                       ListNode() : val(0), next(nullptr) {}
                       ListNode(int x) : val(x), next(nullptr) {}
                       ListNode(int x, ListNode *next) : val(x), next(next) {}
                    };
                    
                    struct compare {
                        bool operator()(
                            struct ListNode* a, struct ListNode* b)
                        {
                            return a->val > b->val;
                        }
                    };
                    
                    ListNode* mergekLists(vector<ListNode*> lists){
                      priority_queue<ListNode*,vector<ListNode*>,compare> q;  
                      // greater<int>> q;
                      for(int i=0;i<lists.size();i++){
                        if(lists[i]!=nullptr){
                          q.push(lists[i]);
                        }
                      } 
                      ListNode* fakeHead;
                      ListNode* p;
                      p = fakeHead;
                      while(!q.empty()){
                        ListNode* n;
                        n= q.top();
                        q.pop();
                        if(n->next!=nullptr){
                          q.push(n->next);
                        }
                        n->next = nullptr;
                        p->next = n;
                        p=n;
                      }
                      return fakeHead->next;
                    }
                    
                    void linebreak(){
                      cout << "######################\n";
                    }
                    
                    void display(ListNode* l){
                      ListNode* temp;
                      temp=l;
                      while(temp!=nullptr){
                        cout<<temp->val<<endl;
                        temp = temp->next;
                      }
                    }
                    
                    ListNode* insert(vector<int> l){
                     ListNode* root;
                     for(int i=0;i<l.size();i++){
                       ListNode* data;
                       data = new ListNode(l[i]);
                       if(i==0){
                         root = data;
                       }else{
                         ListNode* temp;
                         temp = root;
                         while(temp->next!=nullptr){
                           temp = temp->next;
                         }
                         temp->next = data;
                       }
                     }
                     return root;
                    }
                    
                    int main(){
                      vector<ListNode*> lists;
                      vector<int> list1{1,4,5};
                      vector<int> list2{1,3,4};
                      linebreak();
                      ListNode* l1;
                      l1 = insert(list1);
                      ListNode* l2;
                      l2 = insert(list2);
                      lists.push_back(l1);
                      lists.push_back(l2);
                      display(l1);
                      linebreak();
                      display(l2);
                      linebreak();
                      ListNode* res;
                      res = mergekLists(lists);
                      display(res);
                      linebreak();
                      return 0;
                    }`,
                  },
                  Kotlin: {
                    code: `import java.util.*

                    internal class ListNode {
                        var 'val' = 0
                        var next: ListNode? = null
                    
                        constructor() {}
                        constructor('val': Int) {
                            this.'val' = 'val'
                        }
                    
                        constructor('val': Int, next: ListNode?) {
                            this.'val' = 'val'
                            this.next = next
                        }
                    }
                    
                    internal object Main {
                        private fun mergekLists(lists: Array<ListNode?>): ListNode? {
                            val q = PriorityQueue<ListNode?>(
                                1.coerceAtLeast(lists.size)
                            ) { e1, e2 -> e1!!.'val' - e2!!.'val' }
                            for (i in lists.indices) {
                                if (lists[i] != null) {
                                    q.add(lists[i])
                                }
                            }
                            // Iterator i = q.iterator();
                            // while(i.hasNext()){
                            //   display((ListNode)i.next());
                            // }
                            val fakeHead = ListNode(-1)
                            var p: ListNode? = fakeHead
                            while (!q.isEmpty()) {
                                val n = q.poll()
                                if (n!!.next != null) {
                                    q.add(n.next)
                                }
                                n.next = null
                                p!!.next = n
                                p = n
                            }
                            return fakeHead.next
                        }
                    
                        private fun lineBreak() {
                            println("#####################")
                        }
                    
                        private fun display(l: ListNode?) {
                            var temp = l
                            while (temp != null) {
                                println(temp.'val')
                                temp = temp.next
                            }
                        }
                    
                        private fun insert(l: List<Int>): ListNode? {
                            var root: ListNode? = ListNode()
                            for (i in l.indices) {
                                val data = ListNode(l[i])
                                if (i == 0) {
                                    root = data
                                } else {
                                    var temp: ListNode? = ListNode()
                                    temp = root
                                    while (temp!!.next != null) {
                                        temp = temp.next
                                    }
                                    temp.next = data
                                }
                            }
                            return root
                        }
                    
                        @JvmStatic
                        fun main(s: Array<String>) {
                            val lists = arrayOfNulls<ListNode>(2)
                            val list1 = listOf(1, 4, 5)
                            val list2 = listOf(1, 3, 4)
                            lineBreak()
                            val l1 = insert(list1)
                            val l2 = insert(list2)
                            lists[0] = l1
                            lists[1] = l2
                            //  list1.stream().forEach(x->{
                            //  System.out.println(x);
                            //  });
                            display(l1)
                            lineBreak()
                            display(l2)
                            lineBreak()
                            val res = mergekLists(lists)
                            display(res)
                            lineBreak()
                        }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q24: {
        title: "Q24. Swap Nodes In Pairs",
        content: (
          <>
            <Span>
              <b>Q24. Swap Nodes in Pairs</b>
            </Span>
            <Span>
              Given a linked list, swap every two adjacent nodes and return its
              head. You must solve the problem without modifying the values in
              the list's nodes (i.e., only nodes themselves may be changed.)
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={LeetcodeQ24} left />
            <Span>
              Input: head = [1,2,3,4] <br />
              Output: [2,1,4,3]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [] <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            q24.png
            <Span>
              Input: head = [1] <br />
              Output: [1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is in the range [0, 100]. <br />0
              &lt;= Node.val &lt;= 100
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q24. Swap Nodes In Pairs",
                output: `#############
                1
                2
                3
                4
                #############
                2
                1
                4
                3
                #############`,
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                    }
                    /**
                     * @param {ListNode} head
                     * @return {ListNode}
                     */
                    
                    var swapPairs = function(head) {
                        var temp = new ListNode(-1)
                        temp.next = head
                        var node = temp
                        while(node && node.next && node.next.next){
                         let first = node
                         let second = node.next
                         let third = node.next.next
                         first.next = third
                         second.next = third.next
                         third.next = second
                         node = second
                        }
                        return temp.next
                    };
                    
                    const head = new ListNode(1)
                    head.next = new ListNode(2)
                    head.next.next = new ListNode(3)
                    head.next.next.next = new ListNode(4)
                    console.log(swapPairs(head))                    
                    `,
                    output: `ListNode {
                      val: 2,
                      next: ListNode { val: 1, next: ListNode { val: 4, next: [ListNode] } }
                    }`,
                  },
                  Java: {
                    code: `import java.util.*;
                    import java.util.stream.*;
                    
                    class ListNode{
                      int val;
                      ListNode next;
                      ListNode(){}
                      ListNode(int val){this.val=val;}
                      ListNode(int val,ListNode next){
                        this.val=val;
                        this.next=next;
                      }
                    }
                    
                    class Main{
                      
                      public static ListNode swapPairs(ListNode head){
                            ListNode fakeHead = new ListNode(-1);
                            fakeHead.next = head;
                            ListNode node = fakeHead;
                            while (node!=null & node.next != null && node.next.next!= null){
                                ListNode first = node;
                                ListNode second = node.next;
                                ListNode third = node.next.next;
                                first.next = third;
                                second.next = third.next;
                                third.next = second;
                                node = second;
                            }
                            return fakeHead.next;
                      }
                    
                      public static void display(ListNode node){
                        ListNode temp = new ListNode();
                        temp = node;
                        while(temp!=null){
                          System.out.println(temp.val);
                          temp = temp.next;
                        }
                      }
                    
                      public static ListNode insert(List<Integer> l){
                      ListNode root = new ListNode();
                      for(int i=0;i<l.size();i++){
                        ListNode data = new ListNode(l.get(i));
                        if(i==0){
                          root = data;
                        }else{
                          ListNode temp = new ListNode();
                          temp=root;
                          while(temp.next!=null){
                            temp=temp.next;
                          }
                          temp.next= data;
                        }
                      }
                      return root;
                      }
                    
                      public static void linebreak(){
                        System.out.println("#############");
                      }
                    
                      public static void main(String ...s){
                        int[] arr = {1,2,3,4};
                        List<Integer> list= Arrays.stream(arr).boxed().collect(Collectors.toList());
                        ListNode l = insert(list);
                        linebreak();
                        display(l);
                        ListNode res = swapPairs(l);
                        linebreak();
                        display(res);
                        linebreak();
                      }
                    
                    }`,
                  },
                  Python: {
                    code: `
class ListNode:
  def __init__(self,val=0):
    self.val=val
    self.next=None

def linebreak():
  print("##############")

def swapPairs(head):
  fakeHead = ListNode(-1)
  fakeHead.next = head
  node = fakeHead
  while node.next is not None:
    first = node
    second = node.next
    third = node.next.next
    first.next = third
    second.next = third.next
    third.next = second
    node = second
  return fakeHead.next

def display(node):
  temp = ListNode()
  temp = node
  while temp is not None:
    print(temp.val)
    temp = temp.next

def insert(l):
  root = ListNode()
  for i in range(len(l)):
    data = ListNode(l[i])
    if i == 0:
      root =  data
    else:
      temp = ListNode()
      temp = root
      while temp.next is not None:
        temp = temp.next
      temp.next = data
  return root
 
arr = [1,2,3,4]
l = insert(arr)
linebreak()
display(l)
linebreak()
display(swapPairs(l))
linebreak()
                    `,
                  },
                  "C++": {
                    code: `#include<iostream>
                    #include<bits/stdc++.h>
                    #include<vector>
                    
                    using namespace std;
                    
                    struct ListNode {
                       int val = 0;
                       ListNode *next;
                       ListNode() : val(0), next(nullptr) {}
                       ListNode(int x) : val(x), next(nullptr) {}
                       ListNode(int x, ListNode *next) : val(x), next(next) {}
                    };
                    
                    
                    ListNode* swapPairs(ListNode* head){
                      ListNode* fakeHead = new ListNode();
                      fakeHead->next = head;
                      ListNode* node;
                      node = fakeHead;
                      while(node != nullptr && node->next != nullptr && node->next->next != nullptr){
                        ListNode* first = node;
                        ListNode* second = node->next;
                        ListNode* third = node->next->next;
                        first->next = third;
                        second->next = third->next;
                        third->next = second;
                        node = second;
                      }
                      return fakeHead->next;
                    }
                    
                    void linebreak(){
                      cout << "######################\n";
                    }
                    
                    void display(ListNode* l){
                      ListNode* temp;
                      temp=l;
                      while(temp!=nullptr){
                        cout<<temp->val<<endl;
                        temp = temp->next;
                      }
                    }
                    
                    ListNode* insert(vector<int> l){
                     ListNode* root;
                     for(int i=0;i<l.size();i++){
                       ListNode* data;
                       data = new ListNode(l[i]);
                       if(i==0){
                         root = data;
                       }else{
                         ListNode* temp;
                         temp = root;
                         while(temp->next!=nullptr){
                           temp = temp->next;
                         }
                         temp->next = data;
                       }
                     }
                     return root;
                    }
                    
                    int main(){
                      vector<ListNode*> lists;
                      vector<int> list1{1,2,3,4};
                      linebreak();
                      ListNode* l1;
                      l1 = insert(list1);
                      display(l1);
                      linebreak();
                      ListNode* res = swapPairs(l1);
                      display(res);
                      linebreak();
                      return 0;
                    }`,
                  },
                  Kotlin: {
                    code: `import java.util.*
                    import java.util.stream.Collectors
                    
                    internal class ListNode {
                        var 'val' = 0
                        var next: ListNode? = null
                    
                        constructor() {}
                        constructor('val': Int) {
                            this.'val' = 'val'
                        }
                    
                        constructor('val': Int, next: ListNode?) {
                            this.'val' = 'val'
                            this.next = next
                        }
                    }
                    
                    internal object Main {
                        private fun swapPairs(head: ListNode?): ListNode? {
                            val fakeHead = ListNode(-1)
                            fakeHead.next = head
                            var node: ListNode? = fakeHead
                            while (node?.next!!.next != null) {
                                val first = node
                                val second = node.next
                                val third = node.next!!.next
                                first.next = third
                                second!!.next = third!!.next
                                third.next = second
                                node = second
                            }
                            return fakeHead.next
                        }
                    
                        private fun display(node: ListNode?) {
                            var temp: ListNode? = ListNode()
                            temp = node
                            while (temp != null) {
                                println(temp.'val')
                                temp = temp.next
                            }
                        }
                    
                        private fun insert(l: List<Int>): ListNode? {
                            var root: ListNode? = ListNode()
                            for (i in l.indices) {
                                val data = ListNode(l[i])
                                if (i == 0) {
                                    root = data
                                } else {
                                    var temp: ListNode? = ListNode()
                                    temp = root
                                    while (temp!!.next != null) {
                                        temp = temp.next
                                    }
                                    temp.next = data
                                }
                            }
                            return root
                        }
                    
                        private fun linebreak() {
                            println("#############")
                        }
                    
                        @JvmStatic
                        fun main(s: Array<String>) {
                            val arr = intArrayOf(1, 2, 3, 4)
                            val list = Arrays.stream(arr).boxed().collect(Collectors.toList())
                            val l = insert(list)
                            linebreak()
                            display(l)
                            val res = swapPairs(l)
                            linebreak()
                            display(res)
                            linebreak()
                        }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q25: {
        title: "Q25. Reverse Nodes in k-Group",
        content: (
          <>
            <Span>
              <b>Q25. Reverse Nodes in k-Group</b>
            </Span>
            <Span>
              Given the head of a linked listq25a, reverse the nodes of the list
              k at a time, and return the modified list. k is a positive integer
              and is less than or equal to the length of the linked list. If the
              number of nodes is not a multiple of k then left-out nodes, in the
              end, should remain as it is. You may not alter the values in the
              list's nodes, only nodes themselves may be changed.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img left src={LeetcodeQ25a} />
            <Span>
              Input: head = [1,2,3,4,5], k = 2 <br />
              Output: [2,1,4,3,5]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img left src={LeetcodeQ25b} />
            <Span>
              Input: head = [1,2,3,4,5], k = 3 <br />
              Output: [3,2,1,4,5]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is n. <br />
              1 &lt;= k &lt;= n &lt;= 5000 <br />0 &lt;= Node.val &lt;= 1000
            </Span>
            <Span>
              <b>Follow-up</b>: Can you solve the problem in O(1) extra memory
              space?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q25. Reverse Nodes in k-Group",
                code: `#####################
                1
                2
                3
                4
                5
                #####################
                2
                1
                4
                3
                5
                #####################`,
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                    }
                    /**
                     * @param {ListNode} head
                     * @param {number} k
                     * @return {ListNode}
                     */
                    
                    function reverse(pre,next){
                      let last = pre.next
                      let curr = last.next
                      while(curr!=next){
                        last.next = curr.next
                        curr.next = pre.next
                        pre.next = curr
                        curr = last.next
                      }
                      return last
                    }
                    
                    var reverseKGroup = function(head,k){
                      if(head==null || k==1){
                         return head
                      }
                      let fake = new ListNode(0)
                      fake.next = head
                      let pre = fake
                      let i=0
                      let p = head
                      while(p!=null){
                        i+=1
                        if(i%k===0){
                          pre = reverse(pre,p.next)
                          p = pre.next
                        }else{
                          p = p.next
                        }
                      }
                      return fake.next
                    }
                    
                    const head = new ListNode(1)
                    head.next = new ListNode(2)
                    head.next.next = new ListNode(3)
                    head.next.next.next = new ListNode(4)
                    console.log(reverseKGroup(head,2))
                    `,
                    output: `ListNode {
                      val: 2,
                      next: ListNode { val: 1, next: ListNode { val: 4, next`,
                  },
                  Java: {
                    code: `import java.util.*;
                    import java.util.stream.*;
                    
                    class ListNode{
                      int val;
                      ListNode next;
                      ListNode(){}
                      ListNode(int x){
                       this.val=x;
                      }
                    }
                    
                    class Main{
                      
                      public static ListNode reverseKGroup(ListNode head, int k) 
                      {
                            if(head==null||k==1)
                                return head;
                            ListNode fake = new ListNode(0);
                            fake.next = head;
                            ListNode pre = fake;
                            int i=0;
                            ListNode p = head;
                            while(p!=null){
                                i++;
                                if(i%k==0){
                                    pre = reverse(pre, p.next);
                                    p = pre.next;
                                }else{
                                    p = p.next; 
                                }
                            }
                            return fake.next; 
                      }
                    
                      public static ListNode insert(List<Integer> l){
                      ListNode root = new ListNode();
                      for(int i=0;i<l.size();i++){
                        ListNode data = new ListNode(l.get(i));
                        if(i==0){
                          root = data;
                        }else{
                          ListNode temp = new ListNode();
                          temp=root;
                          while(temp.next!=null){
                            temp=temp.next;
                          }
                          temp.next= data;
                        }
                      }
                      return root;
                      }
                       
                      public static ListNode reverse(ListNode pre, ListNode next){
                            ListNode last = pre.next;
                            ListNode curr = last.next;
                            while(curr != next){
                                last.next = curr.next;
                                curr.next = pre.next;
                                pre.next = curr;
                                curr = last.next;
                            }
                            return last; 
                      }
                    
                      public static void display(ListNode root){
                        ListNode temp = new ListNode();
                        temp = root;
                        while(temp!=null){
                          System.out.println(temp.val);
                          temp=temp.next;
                        }
                      }
                    
                      public static void linebreak(){
                        System.out.println("#####################");
                      }
                    
                      public static void main(String ...s){
                         int[] arr = {1,2,3,4,5};
                         List<Integer> list = Arrays.stream(arr).boxed().collect(Collectors.toList());
                         ListNode l1= insert(list);
                         linebreak();
                         display(l1);
                         linebreak();
                         ListNode res = reverseKGroup(l1,2);
                         display(res);
                         linebreak();
                      }
                    }`,
                  },
                  Python: {
                    code: `
class ListNode:
  def __init__(self,val=0):
    self.val=val
    self.next=None

def linebreak():
  print("##############")

def reverse(pre,next):
  last = pre.next
  curr = last.next
  while curr != next:
    last.next = curr.next
    curr.next = pre.next
    pre.next = curr
    curr = last.next
  return last

def reverseKGroup(head,k):
  if head is None or k ==1:
    return head
  fake = ListNode(0)
  fake.next = head
  pre = fake
  i = 0
  p = head
  while p is not None:
    i+=1
    if i%k == 0:
      pre = reverse(pre,p.next)
      p = pre.next
    else:
      p = p.next
  return fake.next

def display(node):
  temp = ListNode()
  temp = node
  while temp is not None:
    print(temp.val)
    temp = temp.next

def insert(l):
  root = ListNode()
  for i in range(len(l)):
    data = ListNode(l[i])
    if i == 0:
      root =  data
    else:
      temp = ListNode()
      temp = root
      while temp.next is not None:
        temp = temp.next
      temp.next = data
  return root
 
arr = [1,2,3,4,5]
l = insert(arr)
linebreak()
display(l)
linebreak()
display(reverseKGroup(l,2))
linebreak()
                    `,
                  },
                  "C++": {
                    code: `#include<iostream>
                    #include<bits/stdc++.h>
                    #include<vector>
                    
                    using namespace std;
                    
                    struct ListNode {
                       int val = 0;
                       ListNode *next;
                       ListNode() : val(0), next(nullptr) {}
                       ListNode(int x) : val(x), next(nullptr) {}
                       ListNode(int x, ListNode *next) : val(x), next(next) {}
                    };
                    
                    
                    ListNode* reverse(ListNode* pre,ListNode* next){
                      ListNode* last;
                      ListNode* curr;
                      last = new ListNode(0);
                      curr = new ListNode(0);
                      last = pre->next;
                      curr = last->next;
                      while(curr!=next){
                        last->next = curr->next;
                        curr->next = pre->next;
                        pre->next= curr;
                        curr = last->next;
                      }
                      return last;
                    }
                    
                    ListNode* reverseKGroup(ListNode* head,int k){
                      if(head==nullptr||k==1){
                        return head;
                      }
                      ListNode* fake;
                      fake = new ListNode(0);
                      ListNode* pre;
                      pre = new ListNode(0);
                      fake->next = head;
                      pre = fake;
                      int i=0;
                      ListNode* p;
                      p = new ListNode(0);
                      p = head;
                      while(p!=nullptr){
                        i+=1;
                        if(i%k==0){
                        pre = reverse(pre,p->next);
                        p = pre->next;
                        }else{
                        p = p->next;
                        }
                      }
                      return fake->next;
                    }
                    
                    void linebreak(){
                      cout << "######################\n";
                    }
                    
                    void display(ListNode* l){
                      ListNode* temp;
                      temp=l;
                      while(temp!=nullptr){
                        cout<<temp->val<<endl;
                        temp = temp->next;
                      }
                    }
                    
                    ListNode* insert(vector<int> l){
                     ListNode* root;
                     for(int i=0;i<l.size();i++){
                       ListNode* data;
                       data = new ListNode(l[i]);
                       if(i==0){
                         root = data;
                       }else{
                         ListNode* temp;
                         temp = root;
                         while(temp->next!=nullptr){
                           temp = temp->next;
                         }
                         temp->next = data;
                       }
                     }
                     return root;
                    }
                    
                    int main(){
                      vector<ListNode*> lists;
                      vector<int> list1(5);
                      iota(list1.begin(),list1.end(),1);
                      linebreak();
                      ListNode* l1;
                      l1 = insert(list1);
                      display(l1);
                      linebreak();
                      ListNode* res = reverseKGroup(l1,2);
                      display(res);
                      linebreak();
                      return 0;
                    }`,
                  },
                  Kotlin: {
                    code: `import java.util.*
                    import java.util.stream.Collectors
                    
                    internal class ListNode {
                        var 'val' = 0
                        var next: ListNode? = null
                    
                        constructor() {}
                        constructor(x: Int) {
                            'val' = x
                        }
                    }
                    
                    internal object Main {
                        private fun reverseKGroup(head: ListNode?, k: Int): ListNode? {
                            if (head == null || k == 1) return head
                            val fake = ListNode(0)
                            fake.next = head
                            var pre: ListNode? = fake
                            var i = 0
                            var p = head
                            while (p != null) {
                                i++
                                if (i % k == 0) {
                                    pre = reverse(pre, p.next)
                                    p = pre!!.next
                                } else {
                                    p = p.next
                                }
                            }
                            return fake.next
                        }
                    
                        private fun insert(l: List<Int>): ListNode? {
                            var root: ListNode? = ListNode()
                            for (i in l.indices) {
                                val data = ListNode(l[i])
                                if (i == 0) {
                                    root = data
                                } else {
                                    var temp: ListNode? = ListNode()
                                    temp = root
                                    while (temp!!.next != null) {
                                        temp = temp.next
                                    }
                                    temp.next = data
                                }
                            }
                            return root
                        }
                    
                        private fun reverse(pre: ListNode?, next: ListNode?): ListNode? {
                            val last = pre!!.next
                            var curr = last!!.next
                            while (curr !== next) {
                                last.next = curr!!.next
                                curr.next = pre.next
                                pre.next = curr
                                curr = last.next
                            }
                            return last
                        }
                    
                        private fun display(root: ListNode?) {
                            var temp: ListNode? = ListNode()
                            temp = root
                            while (temp != null) {
                                println(temp.'val')
                                temp = temp.next
                            }
                        }
                    
                        private fun linebreak() {
                            println("#####################")
                        }
                    
                        @JvmStatic
                        fun main(s: Array<String>) {
                            val arr = intArrayOf(1, 2, 3, 4, 5)
                            val list = Arrays.stream(arr).boxed().collect(Collectors.toList())
                            val l1 = insert(list)
                            linebreak()
                            display(l1)
                            linebreak()
                            val res = reverseKGroup(l1, 2)
                            display(res)
                            linebreak()
                        }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q26: {
        title: "Q26. Remove Duplicates from Sorted Array.",
        content: (
          <>
            <Span>
              <b>Q26. Remove Duplicates from Sorted Array.</b>
            </Span>
            <Span>
              Given an integer array nums sorted in non-decreasing order, remove
              the duplicates in-place such that each unique element appears only
              once. The relative order of the elements should be kept the same.
              <br />
              Since it is impossible to change the length of the array in some
              languages, you must instead have the result be placed in the first
              part of the array nums. More formally, if there are k elements
              after removing the duplicates, then the first k elements of nums
              should hold the final result. It does not matter what you leave
              beyond the first k elements.
              <br />
              Return k after placing the final result in the first k slots of
              nums.
              <br />
              Do not allocate extra space for another array. You must do this by
              modifying the input array in-place with O(1) extra memory.
            </Span>
            <Span>
              Custom Judge: The judge will test your solution with the following
              code: int[] nums = [...]; // Input array int[] expectedNums =
              [...]; // The expected answer with correct length int k =
              removeDuplicates(nums); // Calls your implementation
              <br />
              assert k == expectedNums.length; for (int i = 0; i &lt; k; i++){" "}
              {"{"} <br />
              assert nums[i] == expectedNums[i];
              {"}"} <br />
              If all assertions pass, then your solution will be accepted.
            </Span>
            <Span>
              <b>Example 1</b>
            </Span>
            <Span>
              Input: nums = [1,1,2] <br />
              Output: 2, nums = [1,2,_] <br />
              Explanation: Your function should return k = 2, with the first two
              elements of nums being 1 and 2 respectively. It does not matter
              what you leave beyond the returned k (hence they are underscores).
            </Span>
            <Span>
              <b>Example 2</b>
            </Span>
            <Span>
              Input: nums = [0,0,1,1,1,2,2,3,3,4] <br />
              Output: 5, nums = [0,1,2,3,4,_,_,_,_,_] <br />
              Explanation: Your function should return k = 5, with the first
              five elements of nums being 0, 1, 2, 3, and 4 respectively. It
              does not matter what you leave beyond the returned k (hence they
              are underscores).
            </Span>
            <Span>
              <b>Constraints</b>
            </Span>
            <Span>
              0 &lt;= nums.length &lt;= 3 * 104 <br />
              -100 &lt;= nums[i] &lt;= 100 <br />
              nums is sorted in non-decreasing order.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q26. Remove Duplicates from Sorted Array.",
                output: `5`,
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var removeDuplicates = function(nums){
                     let i = 0;
                     for (let num of nums)
                       if (i < 1 || num > nums[i - 1])
                         nums[i++] = num;
                     return i;
                   //   nums = nums.reduce((accumulator,current)=>{
                   // accumulator[current] = (accumulator[current] || 0) + 1
                   // return accumulator
                   // },{})
                   //     console.log(Object.keys(nums))
                   //   return Object.keys(nums).length
                   };
                     
                   console.log(removeDuplicates([1,1,2]))`,
                    output: `2`,
                  },
                  Java: {
                    code: `import java.util.*;

                    class Solution {
                        public int removeDuplicates(int[] nums) {
                                TreeMap<Integer,Integer> map= new TreeMap<Integer,Integer>();
                        for(int i=0;i<nums.length;i++){
                          if(!map.containsValue(nums[i])){
                            map.put(i,nums[i]);
                          }
                        }
                        int i=0;
                        for(Map.Entry m:map.entrySet()){
                          nums[i]=(int)m.getValue();
                          i++;
                        }
                        return map.size();
                        }
                    }`,
                  },
                  Python: {
                    code: `
def removeDuplicates(nums):
  m = {}
  for i in range(len(nums)):
    if not nums[i] in m.values():
      m[i]=nums[i]
  for i in range(len(m.values())):
    nums[i] = list(m.values())[i]
  return len(m)

print(removeDuplicates([0,0,1,2,2,3,4]))
                    `,
                  },
                  "C++": {
                    code: `#include <iostream>
                    #include <bits/stdc++.h>
                    
                    using namespace std;
                    
                    int removeDuplicates(int nums[]){
                      map<int,int> m;
                      int len = *(&nums+1)-nums;
                      for(int i=0;i<len;i++){
                        if(m.find(nums[i])==m.end()){
                           m[nums[i]]=i;
                        }
                      }
                      for(int i=0;i<m.size();i++){
                        nums[i]=m[i];
                      }
                      return m.size();
                    }
                    
                    int main(){
                      int r[]={0,0,1,2,2,3,4};
                      cout << removeDuplicates(r) << endl;
                      return 0;
                    }`,
                  },
                  Kotlin: {
                    code: `import java.util.*

                    internal class Solution {
                        fun removeDuplicates(nums: IntArray): Int {
                            val map = TreeMap<Int, Int>()
                            for (i in nums.indices) {
                                if (!map.containsValue(nums[i])) {
                                    map[i] = nums[i]
                                }
                            }
                            var i = 0
                            for ((_, value) in map) {
                                nums[i] = value
                                i++
                            }
                            return map.size
                        }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q27: {
        title: "Q27. Remove Element",
        content: (
          <>
            <Span>
              <b>Q27. Remove Element</b>
            </Span>
            <Span>
              Given an integer array nums and an integer val, remove all
              occurrences of val in nums in-place. The relative order of the
              elements may be changed.
              <br />
              Since it is impossible to change the length of the array in some
              languages, you must instead have the result be placed in the first
              part of the array nums. More formally, if there are k elements
              after removing the duplicates, then the first k elements of nums
              should hold the final result. It does not matter what you leave
              beyond the first k elements.
              <br />
              Return k after placing the final result in the first k slots of
              nums.
              <br />
              Do not allocate extra space for another array. You must do this by
              modifying the input array in-place with O(1) extra memory.
            </Span>
            <Span>
              <b>Custom Judge:</b>
            </Span>
            <Span>
              The judge will test your solution with the following code: int[]
              nums = [...]; // Input array int val = ...; // Value to remove
              int[] expectedNums = [...]; // The expected answer with correct
              length. // It is sorted with no values equaling val. int k =
              removeElement(nums, val); // Calls your implementation assert k ==
              expectedNums.length; sort(nums, 0, k); // Sort the first k
              elements of nums <br />
              for (int i = 0; i &lt; actualLength; i++) {"{"} <br />
              assert nums[i] == expectedNums[i]; <br />
              {"}"} <br />
              If all assertions pass, then your solution will be accepted.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [3,2,2,3], val = 3 <br />
              Output: 2, nums = [2,2,_,_] <br />
              Explanation: Your function should return k = 2, with the first two
              elements of nums being 2. It does not matter what you leave beyond
              the returned k (hence they are underscores).
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0,1,2,2,3,0,4,2], val = 2 <br />
              Output: 5, nums = [0,1,4,0,3,_,_,_] <br />
              Explanation: Your function should return k = 5, with the first
              five elements of nums containing 0, 0, 1, 3, and 4. Note that the
              five elements can be returned in any order. <br />
              It does not matter what you leave beyond the returned k (hence
              they are underscores).
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= nums.length &lt;= 100 <br />
              0 &lt;= nums[i] &lt;= 50 <br />0 &lt;= val &lt;= 100
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q27. Remove Element",
                output: `5`,
                codes: {
                  Javascript: {
                    code: `/**
* @param {number[]} nums
* @param {number} val
* @return {number}
*/
var removeElement = function(nums, val) {
 let i = 0;
 for (let num of nums)
   if (num != val)
     nums[i++] = num;
 return i;  
};

removeElement([3,2,2,3],3)`,
                    output: `2`,
                  },
                  Java: {
                    code: `import java.io.*;

                    class Solution {
                      public int removeElement(int[] nums, int val) {
                          int start = 0, end = nums.length -1;
                          while (start <= end) {
                              if (nums[start] != val) {
                                  start ++;
                                  continue;
                              }
                              if (nums[end] == val) {
                                  end --;
                                  continue;
                              }
                              nums[start] = nums[end];
                              start ++;
                              end --;
                          }
                          return start;
                      }
                      public static void main(String ...s){
                        Solution m = new Solution();
                        PrintStream ps = System.out;
                        ps.println(m.removeElement(new int[]{
                          0,1,2,2,3,0,4,2
                        },2));
                      }
                    }
                    `,
                  },
                  "C++": {
                    code: `#include <iostream>
                  #include <bits/stdc++.h>
                  
                  using namespace std;
                  
                  int removeElement(int nums[], int val) {
                      int start = 0;
                      int end = *(&nums+1)-nums-2;
                      while (start <= end) {
                          if (nums[start] != val) {
                              start ++;
                              continue;
                          }
                          if (nums[end] == val) {
                              end --;
                              continue;
                          }
                          nums[start] = nums[end];
                          start ++;
                          end --;
                        }
                        return start;
                  }
                  
                  int main(){
                    int r[]={0,0,1,2,2,3,4};
                    cout << removeElement(r,0) << endl;
                    return 0;
                  }`,
                  },
                  Kotlin: {
                    code: `import java.util.*
                    import java.util.stream.Collectors
                    
                    internal object Main {
                        fun removeElement(nums: IntArray, 'val': Int): Int {
                            var nums = nums
                            var arr = Arrays.stream(nums).boxed().collect(Collectors.toList())
                            arr = arr.stream().filter { x: Int -> x != 'val' }
                                .collect(Collectors.toList())
                            // Integer[] x = arr.toArray();
                            nums = arr.stream().mapToInt { i: Int? -> i!! }.toArray()
                            println(nums[0].toString() + " " + nums[1])
                            return arr.size
                        }
                    
                        @JvmStatic
                        fun main(s: Array<String>) {
                            println(removeElement(intArrayOf(3, 2, 2, 3), 3))
                        }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q28: {
        title: "Q28. Implement strStr()",
        content: (
          <>
            <Span>
              <b>Q28. Implement strStr()</b>
            </Span>
            <Span>
              Implement strStr().
              <br />
              Return the index of the first occurrence of needle in haystack, or
              -1 if needle is not part of haystack.
            </Span>
            <Span>
              <b>Clarification:</b>
            </Span>
            <Span>
              What should we return when needle is an empty string? This is a
              great question to ask during an interview. For the purpose of this
              problem, we will return 0 when needle is an empty string. This is
              consistent to C's strstr() and Java's indexOf().
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: haystack = "hello", needle = "ll" <br />
              Output: 2
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: haystack = "aaaaa", needle = "bba" <br />
              Output: -1
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: haystack = "", needle = "" <br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= haystack.length, needle.length &lt;= 5 * 104 <br />
              haystack and needle consist of only lower-case English characters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O((m-n)n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q28. Implement strStr()",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} haystack
                    * @param {string} needle
                    * @return {number}
                    */
                   
                   var strStr = function(haystack, needle) {
                       const txt = haystack
                       const pat = needle
                       const m = pat.length
                       const n = txt.length
                       
                   
                       // console.log(pat,txt,m,n)
                       for (let i = 0; i <= n - m; i++) {
                           let j
                           for (j = 0; j < m; j++) {
                               if (txt[i + j] != pat[j])
                                   break;
                           }
                           if (j == m){
                            return i;
                           }
                       }
                       return -1
                   }
                   
                   const txt = "hello"
                   const pat = "ll"
                   strStr(txt, pat)
                   `,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q29: {
        title: "Q29. Divide Two Integers",
        content: (
          <>
            <Span>
              <b>Q29. Divide Two Integers</b>
            </Span>
            <Span>
              Given two integers dividend and divisor, divide two integers
              without using multiplication, division, and mod operator.
            </Span>
            <Span>
              The integer division should truncate toward zero, which means
              losing its fractional part. For example, 8.345 would be truncated
              to 8, and -2.7335 would be truncated to -2.
            </Span>
            <Span>Return the quotient after dividing dividend by divisor.</Span>
            <Span>
              {" "}
              Note: Assume we are dealing with an environment that could only
              store integers within the 32-bit signed integer range: [−231, 231
              − 1]. For this problem, if the quotient is strictly greater than
              231 - 1, then return 231 - 1, and if the quotient is strictly less
              than -231, then return -231.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: dividend = 10, divisor = 3 <br />
              Output: 3 <br />
              Explanation: 10/3 = 3.33333.. which is truncated to 3.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: dividend = 7, divisor = -3 <br />
              Output: -2 <br />
              Explanation: 7/-3 = -2.33333.. which is truncated to -2.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              -231 &lt;= dividend, divisor &lt;= 231 - 1 <br />
              divisor != 0
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log^2 n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q29. Divide Two Integers",
                codes: {
                  Javascript: {
                    output: `3`,
                    code: `/**
                    * @param {number} dividend
                    * @param {number} divisor
                    * @return {number}
                    */
                   var divide = function(dividend, divisor) {
                     if (dividend == Math.pow(-2,31) && divisor == -1)
                         return Math.pow(2,31)-1;
                     let sign = dividend > 0 ^ divisor > 0 ? -1 : 1;
                     let ans = 0;
                     let dvd = Math.abs(dividend);
                     let dvs = Math.abs(divisor);
                     while (dvd >= dvs) {
                       let k = 1;
                       while (k * 2 * dvs <= dvd)
                         k *= 2;
                       dvd -= k * dvs;
                       ans += k;
                     }
                     return sign * ans;
                   };
                   
                   divide(10,3)`,
                  },
                  Java: {
                    output: `3`,
                    code: `import java.io.*;

                    class Main {
                       
                       static PrintStream ps = System.out;
                       
                       public int divide(int dividend, int divisor) {
                            int dd = dividend;
                            int dr = divisor;
                            if(dr == 0){
                                return Integer.MAX_VALUE;
                            }
                            if(dr == -1 && dd == Integer.MIN_VALUE){
                                return Integer.MAX_VALUE;
                            }
                            int flag = 1;
                            if((dd < 0 && dr > 0) || (dd > 0 && dr < 0)){ 
                                flag = -1; 
                            } 
                            long ldd = Math.abs((long)dd); 
                            long ldr = Math.abs((long)dr); 
                            int result = 0; 
                            while(ldd >= ldr){
                                int shift = 0;
                                while(ldd >= (ldr << shift)){
                                    shift++;
                                }
                                shift --;
                                result += 1 << shift;
                                ldd -= ldr << shift;
                            }
                            return result*flag;
                        }
                    
                        public static void main(String ...s){
                        Main m = new Main();
                        ps.println(m.divide(10,3));   
                        }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q30: {
        title: "Q30. Substring with Concatenation of All Words",
        content: (
          <>
            <Span>
              <b>Q30. Substring with Concatenation of All Words</b>
            </Span>
            <Span>
              You are given a string s and an array of strings words of the same
              length. Return all starting indices of substring(s) in s that is a
              concatenation of each word in words exactly once, in any order,
              and without any intervening characters. You can return the answer
              in any order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "barfoothefoobarman", words = ["foo","bar"] <br />
              Output: [0,9] <br />
              Explanation: Substrings starting at index 0 and 9 are "barfoo" and
              "foobar" respectively. The output order does not matter, returning
              [9,0] is fine too.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "wordgoodgoodgoodbestword", words =
              ["word","good","best","word"]
              <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "barfoofoobarthefoobarman", words = ["bar","foo","the"]
              <br />
              Output: [6,9,12]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 104 <br />
              s consists of lower-case English letters. <br />
              1 &lt;= words.length &lt;= 5000 <br />
              1 &lt;= words[i].length &lt;= 30 <br />
              words[i] consists of lower-case English letters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q30. Substring with Concatenation of All Words",
                codes: {
                  Javascript: {
                    output: `[0,9]`,
                    code: `/**
                    * @param {string} s
                    * @param {string[]} words
                    * @return {number[]}
                    */
                   var findSubstring = function(s, words) {
                     if(!s.length || !words.length ) return [];
                     let m = words.length;
                     let n = words[0].length;
                     const res = [];
                     const count = {};
                     for (let word of words)
                       count[word] = (count[word] || 0 ) + 1;
                     for (let i = 0; i <= s.length - m * n; ++i) {
                       const seen = {};
                       let j = 0;
                       for (; j < m; ++j) {
                         let word = s.substring(i + j * n, i + j * n + n);
                         seen[word] = (seen[word] || 0) + 1;
                         if (seen[word] > (count[word] || 0) )
                           break;
                       }
                       if (j == m)
                         res.push(i);
                     }
                     return res;
                   };
                     
                   console.log(findSubstring("barfoothefoobarman",["foo","bar"]))
                   // var sLen = s.length;
                   // var wLen = words.length;
                   // var wordLen = (words[0] || '').length;
                   // if (!sLen || !wLen || !wordLen) return [];
                   // var count = 0;
                   // var tmp = '';
                   // var map1 = {};
                   // var map2 = {};
                   // var res = [];
                   // for (var i = 0; i < wLen; i++) {
                   //   map1[words[i]] = (map1[words[i]] || 0) + 1;
                   // }
                   // out: for (var j = 0; j <= sLen - (wLen * wordLen); j++) {
                   //   map2 = {};
                   //   count = 0;
                   //   while (count < wLen) {
                   //     tmp = s.substr(j + (count * wordLen), wordLen);
                   //     if (map1[tmp] === undefined || map1[tmp] === map2[tmp]) continue out;
                   //     map2[tmp] = (map2[tmp] || 0) + 1;
                   //     count++;
                   //   }
                   //   res.push(j);
                   // }
                   // return res;`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q31: {
        title: "Q31. Next Permutation",
        content: (
          <>
            <Span>
              <b>Q31. Next Permutation</b>
            </Span>
            <Span>
              Implement next permutation, which rearranges numbers into the
              lexicographically next greater permutation of numbers.
              <br />
              If such an arrangement is impossible, it must rearrange it to the
              lowest possible order (i.e., sorted in ascending order).
              <br />
              The replacement must be in place and use only constant extra
              memory.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3] <br />
              Output: [1,3,2]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [3,2,1] <br />
              Output: [1,2,3]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [1,1,5] <br />
              Output: [1,5,1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 100 <br />0 &lt;= nums[i] &lt;= 100
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q31. Next Permutation",
                codes: {
                  Javascript: {
                    code: `function permuteBacktrack(str, answer,result) {
                      if (str.length === 0) {
                          result.push(answer)
                          return
                      }
                  
                      for (let i = 0; i < str.length; i++) {
                          let ch = str[i];
                          let left = str.substring(0, i);
                          let right = str.substring(i + 1);
                          let rest = left + right;
                          if(result.length<2)
                          permuteBacktrack(rest, answer + ch,result);
                      }
                  }
                  
                  /**
                   * @param {number[]} nums
                   * @return {void} Do not return anything, modify nums in-place instead.
                   */
                  var nextPermutation = function(nums) {
                  const result = []
                  permuteBacktrack(nums.join(""), "",result)
                  nums = result[1].split("").map(x=>+x)
                  console.log(nums)
                  };
                  
                  
                  console.log(nextPermutation([1,2,3]))`,
                  },
                  Java: {
                    code: `import java.util.Arrays;
                  import java.util.*;
                  import java.util.stream.Collectors;
                  
                    // public void nextPermutation(int[] nums) {
                    //   final int n = nums.length;
                    //   int i;
                    //   for (i = n - 2; i >= 0; --i)
                    //     if (nums[i] < nums[i + 1])
                    //       break;
                    //   if (i >= 0)
                    //     for (int j = n - 1; j > i; --j)
                    //       if (nums[j] > nums[i]) {
                    //         swap(nums, i, j);
                    //         break;
                    //       }
                    //   reverse(nums, i + 1, n - 1);
                    // }
                    // private void reverse(int[] nums, int l, int r) {
                    //   while (l < r)
                    //     swap(nums, l++, r--);
                    // }
                    // private void swap(int[] nums, int i, int j) {
                    //   final int temp = nums[i];
                    //   nums[i] = nums[j];
                    //   nums[j] = temp;
                    // }

                  class Solution{
                    public void nextPermutation(int nums[]){
                      int paNumIndex = -1;
                          for (int i = nums.length - 1; i >0; i --){
                              if (nums[i-1] < nums[i]){ 
                                  paNumIndex = i-1; 
                                  break; 
                              } 
                          } 
                          if (paNumIndex != -1){ 
                              int chNumIndex = -1;
                              for (int i = nums.length - 1; i > paNumIndex; i --){
                                  if (nums[i] > nums[paNumIndex]){
                                      chNumIndex = i;
                                      break;
                                  }
                              }
                              int tmp = nums[paNumIndex];
                              nums[paNumIndex] = nums[chNumIndex];
                              nums[chNumIndex] = tmp;
                          }
                          int start = paNumIndex + 1, end = nums.length -1;
                          while (start < end) {
                              int tmp = nums[start];
                              nums[start] = nums[end];
                              nums[end] = tmp;
                              start ++;
                              end --;
                          }
                      List<Integer> x = Arrays.stream(nums).boxed().collect(Collectors.toList());
                      x.stream().forEach(System.out::print);
                    }
                    public static void main(String ...st){
                      Solution s = new Solution();
                      s.nextPermutation(new int[]{1,2,3});
                    }
                    
                  }`,
                    output: `[1,3,2]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q32: {
        title: "Q32. Longest Valid Parentheses",
        content: (
          <>
            <Span>
              <b>Q32. Longest Valid Parentheses</b>
            </Span>
            <Span>
              Given a string containing just the characters '(' and ')', find
              the length of the longest valid (well-formed) parentheses
              substring.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "(()" <br />
              Output: 2 <br />
              Explanation: The longest valid parentheses substring is "()".
            </Span>
            <Span>
              Input: s = "(()" <br />
              Output: 2 <br />
              Explanation: The longest valid parentheses substring is "()".
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = ")()())" <br />
              Output: 4 <br />
              Explanation: The longest valid parentheses substring is "()()".
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "" <br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= s.length &lt;= 3 * 104 <br />
              s[i] is '(', or ')'.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q32. Longest Valid Parentheses",
                output: `4
                [ 0, 0, 0, 2, 0, 4 ]
                4`,
                codes: {
                  Javascript: {
                    code: `
                    /**
                     * @param {string} s
                     * @return {number}
                     */
                    var longestValidParentheses = function(s) {
                        let maxans = 0;
                        let stack = [];
                        stack.push(-1);
                        for (let i = 0; i < s.length; i++) {
                            if (s[i] == "(") stack.push(i);
                            else {
                                stack.pop();
                                if (stack.length == 0) {
                                    stack.push(i);
                                } else {
                                    maxans = Math.max(maxans, i - stack.slice(-1)[0]);
                                }
                            }
                        }
                        return maxans;
                    };
                    
                    function lvp(s){
                      let s2 = ")" + s;
                      const dp = Array(s2.length).fill(0);
                      for (let i = 1; i < s2.length; ++i)
                      if (s2[i] == ')' && s2[i - dp[i - 1] - 1] == '(')
                      dp[i] = dp[i - 1] + dp[i - dp[i - 1] - 2] + 2;
                      console.log(dp)
                      return Math.max(...dp);
                    }
                    console.log(longestValidParentheses("(()()"));
                    console.log(lvp("(()()"));
                   `,
                  },
                },
              }}
            />
          </>
        ),
      },
      q33: {
        title: "Q33. Search in Rotated Sorted Array",
        content: (
          <>
            <Span>
              <b>Q33. Search in Rotated Sorted Array</b>
            </Span>
            <Span>
              There is an integer array nums sorted in ascending order (with
              distinct values).
              <br />
              Prior to being passed to your function, nums is possibly rotated
              at an unknown pivot index k (1 &lt;= k &lt; nums.length) such that
              the resulting array is [nums[k], nums[k+1], ..., nums[n-1],
              nums[0], nums[1], ..., nums[k-1]] (0-indexed). For example,
              [0,1,2,4,5,6,7] might be rotated at pivot index 3 and become
              [4,5,6,7,0,1,2].
              <br />
              Given the array nums after the possible rotation and an integer
              target, return the index of target if it is in nums, or -1 if it
              is not in nums.
              <br />
              You must write an algorithm with O(log n) runtime complexity.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [4,5,6,7,0,1,2], <br />
              target = 0 <br />
              Output: 4
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [4,5,6,7,0,1,2], target = 3 <br />
              Output: -1
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [1], target = 0 <br />
              Output: -1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 5000 <br />
              -104 &lt;= nums[i] &lt;= 104 <br />
              All values of nums are unique. <br />
              nums is an ascending array that is possibly rotated. <br />
              -104 &lt;= target &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q33. Search in Rotated Sorted Array",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @param {number} target
                    * @return {number}
                    */
                   const search = function(nums, target) {
                       let l = 0;
                       let r = nums.length - 1;
                       while (l <= r) {
                           let m = parseInt((l + r) / 2);
                           if (nums[m] == target) {
                               return m;
                           }
                           if (nums[l] <= nums[m]) {
                               if (nums[l] <= target && target < nums[m]) {
                                   r = m - 1;
                               } else {
                                   l = m + 1;
                               }
                           } else {
                               if (nums[m] < target && target <= nums[r]) {
                                   l = m + 1;
                               } else {
                                   r = m - 1;
                               }
                           }
                       }
                       return -1;
                   };
                   
                   console.log(search([4, 5, 6, 0, 1, 2], 0));`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q34: {
        title: "Q34. Find First and Last Position of Element in Sorted Array",
        content: (
          <>
            <Span>
              <b>
                Q34. Find First and Last Position of Element in Sorted Array
              </b>
            </Span>
            <Span>
              Given an array of integers nums sorted in non-decreasing order,
              find the starting and ending position of a given target value. If
              target is not found in the array, return [-1, -1]. You must write
              an algorithm with O(log n) runtime complexity.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [5,7,7,8,8,10], <br />
              target = 8 <br />
              Output: [3,4]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [5,7,7,8,8,10], <br />
              target = 6 <br />
              Output: [-1,-1]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [], <br />
              target = 0 <br />
              Output: [-1,-1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= nums.length &lt;= 105 <br />
              -109 &lt;= nums[i] &lt;= 109 <br />
              nums is a non-decreasing array. <br />
              -109 &lt;= target &lt;= 109
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title:
                  "Q34. Find First and Last Position of Element in Sorted Array",
                codes: {
                  Javascript: {
                    output: `[3,4]`,
                    code: `/**
                    * @param {number[]} nums
                    * @param {number} target
                    * @return {number[]}
                    */
                   var searchRange = function(nums, target) {
                       var l = 0
                       var r = nums.length - 1
                       var res = []
                       res[0] = -1
                       var has = false
                       while (l <= r) {
                           var m = l + parseInt((r - l) / 2)
                           if (nums[m] < target) {
                               l++
                           } else if (nums[m] == target) {
                               has = true
                               r--
                           } else {
                               r--
                           }
                       }
                       if (has) {
                           res[0] = l
                       }
                       l = 0
                       r = nums.length - 1
                       while (l <= r) {
                           let m = l + parseInt((r - l) / 2)
                           if (nums[m] <= target) {
                               l++
                           } else {
                               r--
                           }
                       }
                       if (has) {
                           res[1] = r
                       } else {
                           res[1] = -1
                       }
                       return res
                   }
                   
                   console.log(searchRange([5, 7, 7, 8, 8, 10], 8))`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q35: {
        title: "Q35. Search Insert Position",
        content: (
          <>
            <Span>
              <b>Q35. Search Insert Position</b>
            </Span>
            <Span>
              Given a sorted array of distinct integers and a target value,
              return the index if the target is found. If not, return the index
              where it would be if it were inserted in order. You must write an
              algorithm with O(log n) runtime complexity.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,3,5,6], <br />
              target = 5 <br />
              Output: 2
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1,3,5,6], <br /> target = 2 <br />
              Output: 1
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [1,3,5,6], <br /> target = 7 <br />
              Output: 4
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 104 <br />
              -104 &lt;= nums[i] &lt;= 104 <br />
              nums contains distinct values sorted in ascending order. <br />
              -104 &lt;= target &lt;= 104{" "}
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q35. Search Insert Position",
                codes: {
                  Javascript: {
                    code: `
                    /**
                     * @param {number[]} nums
                     * @param {number} target
                     * @return {number}
                     */
                    var searchInsert = function(nums, target) {
                        return bs(nums, 0, nums.length - 1, target);
                    };
                    
                    var bs = function(nums, start, end, target) {
                        if (start > end) return end + 1;
                        let mid = parseInt((start + end) / 2);
                        if (nums[mid] < target) return bs(nums, mid + 1, end, target);
                        else if (nums[mid] > target) return bs(nums, start, mid - 1, target);
                        return mid;
                    };
                    
                    var SI = function(nums,target){
                      let l = 0;
                      let r = nums.length;
                      while (l < r) {
                        const m = l + parseInt((r - l) / 2);
                        if (nums[m] == target)
                          return m;
                        if (nums[m] < target)
                          l = m + 1;
                        else
                          r = m;
                      }
                      return l;
                    }
                    
                    console.log(SI([2, 3, 4, 5, 6], 5))
                    console.log(searchInsert([2, 3, 4, 5, 6], 5));
                    `,
                    output: `3
                    3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q36: {
        title: "Q36. Valid Sudoku",
        content: (
          <>
            <Span>
              <b>Q36. Valid Sudoku</b>
            </Span>
            <Span>
              Determine if a 9 x 9 Sudoku board is valid. Only the filled cells
              need to be validated according to the following rules:
            </Span>
            <Span>
              1. Each row must contain the digits 1-9 without repetition. <br />
              2. Each column must contain the digits 1-9 without repetition.{" "}
              <br />
              3. Each of the nine 3 x 3 sub-boxes of the grid must contain the
              digits 1-9 without repetition.
            </Span>
            <Span>
              <b>Note:</b>
            </Span>
            <Span>
              A Sudoku board (partially filled) could be valid but is not
              necessarily solvable.
            </Span>
            <Span>
              Only the filled cells need to be validated according to the
              mentioned rules.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: board = <br />
              [["5","3",".",".","7",".",".",".","."] <br />
              ,["6",".",".","1","9","5",".",".","."] <br />
              ,[".","9","8",".",".",".",".","6","."]
              <br />
              ,["8",".",".",".","6",".",".",".","3"]
              <br />
              ,["4",".",".","8",".","3",".",".","1"]
              <br />
              ,["7",".",".",".","2",".",".",".","6"]
              <br />
              ,[".","6",".",".",".",".","2","8","."]
              <br />
              ,[".",".",".","4","1","9",".",".","5"]
              <br />
              ,[".",".",".",".","8",".",".","7","9"]]
              <br />
              Output: true
              <br />
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: board = [["8","3",".",".","7",".",".",".","."] <br />
              ,["6",".",".","1","9","5",".",".","."]
              <br />
              ,[".","9","8",".",".",".",".","6","."] <br />
              ,["8",".",".",".","6",".",".",".","3"]
              <br />
              ,["4",".",".","8",".","3",".",".","1"]
              <br />
              ,["7",".",".",".","2",".",".",".","6"]
              <br />
              ,[".","6",".",".",".",".","2","8","."]
              <br />
              ,[".",".",".","4","1","9",".",".","5"]
              <br />
              ,[".",".",".",".","8",".",".","7","9"]]
              <br />
              Output: false Explanation: Same as Example 1, except with the 5 in
              the top left corner being modified to 8. Since there are two 8's
              in the top left 3x3 sub-box, it is invalid.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              board.length == 9 <br />
              board[i].length == 9 <br />
              board[i][j] is a digit 1-9 or '.'.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(1)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q36. Valid Sudoku",
                codes: {
                  Javascript: {
                    output: `false`,
                    code: `
/**
* @param {character[][]} board
* @return {boolean}
*/
                    var isValidSudoku = function(board){
                    let helper = new Set()
                    for(let i in board){
                      helper.clear()
                      for(let j in board){
                        if(!check(helper,board[i][j])){
                          return false
                        }
                      }
                      console.log('\n')
                    }
                    for(let j in board){
                      helper.clear()
                      for(let i in board){
                        if(!check(helper,board[i][j])){
                          return false
                        }
                      }
                    }
                    for(let i=0;i<9;i+=3){
                      for(let j=0;j<9;j+=3){
                        helper.clear()
                        for(let k=0;k<9;k+=3){
                          if(!check(helper,board[parseInt(i+k/3)][parseInt(j+k%3)])){
                            return false
                          }
                        }
                      }
                    }
                    return true
                  }
                  
                  
                  var check = function(helper,c){
                    if(c=='.'){
                      return true
                    }
                    if(helper.has(c)){
                      return false
                    }
                    helper.add(c)
                    return true
                  }
                  
                  console.log(isValidSudoku([
                    ['5','3','.','.','7','.','7','.','.'],
                    ['6','.','.','1','9','5','.','.','.'],
                    ['.','9','8','.','.','.','.','6','.'],
                    ['8','.','.','.','6','.','.','.','3'],
                    ['4','.','.','8','.','3','.','.','1'],
                    ['7','.','.','.','2','.','.','.','6'],
                    ['.','6','.','.','.','.','2','8','.'],
                    ['.','.','.','4','1','9','.','.','5'],
                    ['.','.','.','.','8','.','.','7','9']
                  ]))`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q37: {
        title: "Q37. Sudoku Solver",
        content: (
          <>
            <Span>
              <b>Q37. Sudoku Solver</b>
            </Span>
            <Span>
              Write a program to solve a Sudoku puzzle by filling the empty
              cells. A sudoku solution must satisfy all of the following rules:
            </Span>
            <Span>
              1. Each of the digits 1-9 must occur exactly once in each row.{" "}
              <br />
              2. Each of the digits 1-9 must occur exactly once in each column.{" "}
              <br />
              3. Each of the digits 1-9 must occur exactly once in each of the 9
              3x3 sub-boxes of the grid.
            </Span>
            <Span>The '.' character indicates empty cells.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: board = <br />
              [["5","3",".",".","7",".",".",".","."], <br />
              ["6",".",".","1","9","5",".",".","."], <br />
              [".","9","8",".",".",".",".","6","."], <br />
              ["8",".",".",".","6",".",".",".","3"], <br />
              ["4",".",".","8",".","3",".",".","1"], <br />
              ["7",".",".",".","2",".",".",".","6"], <br />
              [".","6",".",".",".",".","2","8","."], <br />
              [".",".",".","4","1","9",".",".","5"], <br />
              [".",".",".",".","8",".",".","7","9"]]
              <br />
              Output: [["5","3","4","6","7","8","9","1","2"], <br />
              ["6","7","2","1","9","5","3","4","8"], <br />
              ["1","9","8","3","4","2","5","6","7"], <br />
              ["8","5","9","7","6","1","4","2","3"], <br />
              ["4","2","6","8","5","3","7","9","1"], <br />
              ["7","1","3","9","2","4","8","5","6"], <br />
              ["9","6","1","5","3","7","2","8","4"], <br />
              ["2","8","7","4","1","9","6","3","5"], <br />
              ["3","4","5","2","8","6","1","7","9"]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              board.length == 9 <br />
              board[i].length == 9 <br />
              board[i][j] is a digit or '.'. <br />
              It is guaranteed that the input board has only one solution.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: NP Complete
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q37. Sudoku Solver",
                codes: {
                  Javascript: {
                    code: `/**
                * @param {character[][]} board
                * @return {void} Do not return anything, modify board in-place instead.
                */
               function isSafe(grid, row, col, num) {
                   // check horizontally
                   for (let j = 0; j < n; j++)
                       if (grid[row][j] == num)
                           return false;
               
                   // check vertically
                   for (let i = 0; i < n; i++)
                       if (grid[i][col] == num)
                           return false;
               
               
                   let startRow = row - row % 3,
                       startCol = col - col % 3;
               
                   // check within the section (3 * 3) (n = 3)
                   for (let i = 0; i < 3; i++)
                       for (let j = 0; j < 3; j++)
                           if (grid[i + startRow][j + startCol] == num)
                               return false;
               
                   return true;
               }
               
               
               function solve(grid, row, col, n) {
                   // avoid further tracking if all cells are explored 8 9
                   if (row == n - 1 && col == n)
                       return true;
               
                   // move to next row
                   if (col == n) {
                       row++;
                       col = 0;
                   }
               
                   // check for next element horizontally if element is already present 
                   if (grid[row][col] != ".")
                       return solve(grid, row, col + 1, n);
               
                   // if present value is 0, replace it with values from 0-9
                   for (let num = 1; num < n + 1; num++) {
                       if (isSafe(grid, row, col, num)) {
                           grid[row][col] = "" + num;
                           // check for next column
                           if (solve(grid, row, col + 1, n))
                               return true;
                       }
                       grid[row][col] = ".";
                   }
                   return false;
               }
               
               var solveSudoku = function(board){
                 solve(board, 0, 0, n)
               }
               
               let n = 9;
               const sudoku = Array.from(Array(n), () => Array(n).fill("."))
               sudoku.forEach(x => console.log(x.join(" ")))
               solveSudoku(sudoku)
               sudoku.forEach(x => console.log(x.join(" ")))
               `,
                    output: `. . . . . . . . .
                . . . . . . . . .
                . . . . . . . . .
                . . . . . . . . .
                . . . . . . . . .
                . . . . . . . . .
                . . . . . . . . .
                . . . . . . . . .
                . . . . . . . . .
                1 2 3 4 5 6 7 8 9
                4 5 6 7 8 9 1 2 3
                7 8 9 1 2 3 4 5 6
                2 1 4 3 6 5 8 9 7
                3 6 5 8 9 7 2 1 4
                8 9 7 2 1 4 3 6 5
                5 3 1 6 4 2 9 7 8
                6 4 2 9 7 8 5 3 1
                9 7 8 5 3 1 6 4 2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q38: {
        title: "Q38: Count And Say",
        content: (
          <>
            <Span>
              <b>Q38. Count and Say</b>
            </Span>
            <Span>
              The count-and-say sequence is a sequence of digit strings defined
              by the recursive formula:
              <br />
              countAndSay(1) = "1"
              <br />
              countAndSay(n) is the way you would "say" the digit string from
              countAndSay(n-1), which is then converted into a different digit
              string. To determine how you "say" a digit string, split it into
              the minimal number of groups so that each group is a contiguous
              section all of the same character. Then for each group, say the
              number of characters, then say the character. To convert the
              saying into a digit string, replace the counts with a number and
              concatenate every saying.
              <br />
              For example, the saying and conversion for digit string "3322251":
            </Span>
            <Img src={LeetcodeQ38} reduceH left />
            <Span>
              Given a positive integer n, return the nth term of the
              count-and-say sequence.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 1 <br />
              Output: "1" <br />
              Explanation: This is the base case.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 4 <br />
              Output: "1211"
              <br />
              Explanation:
              <br />
              countAndSay(1) = "1"
              <br />
              countAndSay(2) = say "1" = one 1 = "11" <br />
              countAndSay(3) = say "11" = two 1's = "21" <br />
              countAndSay(4) = say "21" = one 2 + one 1 = "12" + "11" = "1211"
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= n &lt;= 30</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n*n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q38: Count And Say",
                output: `1211`,
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {string}
                    */
                   
                   var countAndSay = function(n){
                     let st = ["1"];
                     while (--n > 0) {
                       const next = [];
                       for (let i = 0; i < st.length; ++i) {
                         let count = 1;
                         while (i + 1 < st.length && st[i] == st[i + 1]) {
                           ++count;
                           ++i;
                         }
                         next.push(count,st[i])
                       }
                       st = next;
                     }
                     return st.join("");
                   }
                   
                   var countAndSay1 = function(n) {
                       let result = ""
                       for (let i = 0; i < n; i++) {
                           if (i == 0) {
                               result = "1"
                               continue
                           }
                           result = count(result)
                       }
                       return result
                   }
                   
                   function count(s) {
                       let result = ""
                       let curr = s[0]
                       let count = 1
                       for (let i = 1; i < s.length; i++) {
                           if (s[i] != curr) {
                               result += count + "" + curr
                               curr = s[i]
                               count = 1
                               continue
                           }
                           count += 1
                       }
                       result += count + "" + curr
                       return result
                   }
                   
                   console.log(countAndSay(4))`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q39: {
        title: "Q39. Combination Sum",
        content: (
          <>
            <Span>
              <b>Q39. Combination Sum</b>
            </Span>
            <Span>
              Given an array of distinct integers candidates and a target
              integer target, return a list of all unique combinations of
              candidates where the chosen numbers sum to target. You may return
              the combinations in any order.
              <br />
              The same number may be chosen from candidates an unlimited number
              of times. Two combinations are unique if the frequency of at least
              one of the chosen numbers is different.
              <br />
              It is guaranteed that the number of unique combinations that sum
              up to target is less than 150 combinations for the given input.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: candidates = [2,3,6,7], target = 7
              <br />
              Output: [[2,2,3],[7]]
              <br />
              Explanation:
              <br />
              2 and 3 are candidates, and 2 + 2 + 3 = 7. Note that 2 can be used
              multiple times.
              <br />
              7 is a candidate, and 7 = 7.
              <br />
              These are the only two combinations.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: candidates = [2,3,5], target = 8 <br />
              Output: [[2,2,2,2],[2,3,3],[3,5]]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: candidates = [2], target = 1 <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= candidates.length &lt;= 30 <br />
              1 &lt;= candidates[i] &lt;= 200 <br />
              All elements of candidates are distinct. <br />1 &lt;= target
              &lt;= 500
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(candidates^target)
              <br />
              Space: O(target)
            </p>
            <CodeEditor
              options={{
                title: "Q39. Combination Sum",
                codes: {
                  Javascript: {
                    output: `[ [ 2, 2, 3 ], [ 7 ] ]`,
                    code: `/**
                    * @param {number[]} candidates
                    * @param {number} target
                    * @return {number[][]}
                    */
                   
                   function dfs(s, candidates, target, res, curr = []) {
                     if (target < 0)
                       return;
                     if (target == 0) {
                       res.push(curr.slice());
                       return;
                     }
                     for (let i = s; i < candidates.length; ++i) {
                       curr.push(candidates[i]);
                       dfs(i, candidates, target - candidates[i], res, curr);
                       curr.pop();
                     }
                   }
                   
                   
                   var combinationSum = function(candidates, target){
                     const res = []
                     candidates.sort((a,b)=>a-b)
                     dfs(0, candidates, target, res)
                     return res  
                   };
                   
                   combinationSum([2,3,6,7],7)`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q40: {
        title: "Q40. Combination Sum 2",
        content: (
          <>
            <Span>
              <b>Q40. Combination Sum 2</b>
            </Span>
            <Span>
              Given a collection of candidate numbers (candidates) and a target
              number (target), find all unique combinations in candidates where
              the candidate numbers sum to target. Each number in candidates may
              only be used once in the combination. Note: The solution set must
              not contain duplicate combinations.
            </Span>
            <Span>
              Note: The solution set must not contain duplicate combinations.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: candidates = [10,1,2,7,6,1,5],
              <br /> target = 8 <br />
              Output: [ [1,1,6], [1,2,5], [1,7], [2,6] ]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: candidates = [2,5,2,1,2], <br /> target = 5 <br />
              Output: [ [1,2,2], [5] ]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= candidates.length &lt;= 100 <br />
              1 &lt;= candidates[i] &lt;= 50 <br />1 &lt;= target &lt;= 30
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n2^n)
              <br />
              Space: O(n.2^n)
            </p>
            <CodeEditor
              options={{
                title: "Q40. Combination Sum 2",
                output: `[[1, 1, 6], [1, 2, 5], [1, 7], [2, 6]]`,
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} candidates
                    * @param {number} target
                    * @return {number[][]}
                    */
                   
                   function dfs(s, candidates, target, res, curr = []) {
                     if (target < 0)
                       return;
                     if (target == 0) {
                       res.push(curr.slice());
                       return;
                     }
                     for (let i = s; i < candidates.length; ++i) {
                       if (i > s && candidates[i] == candidates[i - 1])
                           continue;
                       curr.push(candidates[i]);
                       dfs(i+1, candidates, target - candidates[i], res, curr);
                       curr.pop();
                     }
                   }
                   
                   
                   var combinationSum2 = function(candidates, target){
                     const res = []
                     candidates.sort((a,b)=>a-b)
                     dfs(0, candidates, target, res)
                     return res  
                   };
                   
                   combinationSum2([10,1,2,7,6,1,5],8)`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q41: {
        title: "Q41. Missing Positive",
        content: (
          <>
            <Span>
              <b>Q41. Missing Positive</b>
            </Span>
            <Span>
              Given an unsorted integer array nums, return the smallest missing
              positive integer. You must implement an algorithm that runs in
              O(n) time and uses constant extra space.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,0] <br />
              Output: 3
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [3,4,-1,1] <br />
              Output: 2
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [7,8,9,11,12] <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 5 * 105 <br />
              -231 &lt;= nums[i] &lt;= 231 - 1
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q41. Missing Positive",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   
                   var firstMissingPositive = function(nums){
                       let n = nums.length
                       let present = []
                       for(let i=0;i<n;i++){
                        if(nums[i]>0&&nums[i]<=n){
                          present[nums[i]] = true
                        }
                       }
                       for(let i=1;i<n+1;i++){
                        if(!present[i]){
                          return i
                        }
                       }
                   //  for (let i = 0; i < n; ++i)
                   //  while (nums[i] > 0 && nums[i] <= n && nums[i] != nums[nums[i] - 1])
                   //  swap(nums, i, nums[i] - 1);
                   //  for (let i = 0; i < n; ++i)
                   //   if (nums[i] != i + 1)
                   //   return i + 1;
                   
                       return n+1
                    }
                    
                   const nums = [0,-1,3,1]
                   console.log(firstMissingPositive(nums))`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q42: {
        title: "Q42. Trapping Rain Water",
        content: (
          <>
            <Span>
              <b>Q42. Trapping Rain Water</b>
            </Span>
            <Span>
              Given n non-negative integers representing an elevation map where
              the width of each bar is 1, compute how much water it can trap
              after raining.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={LeetcodeQ42} left />
            <Span>
              Input: height = [0,1,0,2,1,0,1,3,2,1,2,1]
              <br />
              Output: 6 <br />
              Explanation:
              <br />
              The above elevation map (black section) is represented by array
              [0,1,0,2,1,0,1,3,2,1,2,1]. In this case, 6 units of rain water
              (blue section) are being trapped.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: height = [4,2,0,3,2,5]
              <br />
              Output: 9
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              n == height.length <br />
              1 &lt;= n &lt;= 2 * 104 <br />0 &lt;= height[i] &lt;= 105
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q42. Trapping Rain Water",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} height
                    * @return {number}
                    */
                   
                   var trap = function(height) {
                     if (height.length == 0)
                         return 0;
                     let ans = 0;
                     let l = 0;
                     let r = height.length - 1;
                     let maxL = height[l];
                     let maxR = height[r];
                     while (l < r)
                       if (maxL < maxR) {
                         ans += maxL - height[l];
                         maxL = Math.max(maxL, height[++l]);
                       } else {
                         ans += maxR - height[r];
                         maxR = Math.max(maxR, height[--r]);
                       }
                     return ans;  
                   };
                   
                   console.log([4, 2, 0, 3, 2, 5]);                   
                   `,
                    output: `[ 4, 2, 0, 3, 2, 5 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q43: {
        title: "Q43. Multiply Strings",
        content: (
          <>
            <Span>
              <b>Q43. Multiply Strings</b>
            </Span>
            <Span>
              Given two non-negative integers num1 and num2 represented as
              strings, return the product of num1 and num2, also represented as
              a string. Note: You must not use any built-in BigInteger library
              or convert the inputs to integer directly.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: num1 = "2", num2 = "3" <br />
              Output: "6"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: num1 = "123", num2 = "456" <br />
              Output: "56088"
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= num1.length, num2.length &lt;= 200 <br />
              num1 and num2 consist of digits only.
              <br />
              Both num1 and num2 do not contain any leading zero, except the
              number 0 itself.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(∣num1∣∣num2∣)
              <br />
              Space: O(∣num1∣ + ∣num2∣)
            </p>
            <CodeEditor
              options={{
                title: "Q43. Multiply Strings",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} num1
                    * @param {string} num2
                    * @return {string}
                    */
                   var multiply = function(num1, num2) {
                     const m = num1.length;
                     const n = num2.length;
                     const st = [];
                     const pos = Array(m + n).fill(0);
                   
                     for (let i = m - 1; i >= 0; --i)
                       for (let j = n - 1; j >= 0; --j) {
                         let multiply = +num1[i] * +num2[j];
                         let sum = multiply + pos[i + j + 1];
                         pos[i + j] += parseInt(sum / 10);
                         pos[i + j + 1] = sum % 10;
                       }
                   
                     for (let p of pos)
                       if (p > 0 || sb.length > 0)
                         st.push(p);
                   
                     return st.length == 0 ? "0" : st.join("");
                   };
                   
                   console.log(multiply("7", "8"));
                   `,
                    output: `56`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q44: {
        title: "Q44. Wildcard Matching",
        content: (
          <>
            <Span>
              <b>Q44. Wildcard Matching</b>
            </Span>
            <Span>
              Given an input string (s) and a pattern (p), implement wildcard
              pattern matching with support for '?' and '*' where:
              <br />
              '?' Matches any single character. <br />
              '*' Matches any sequence of characters (including the empty
              sequence). <br />
              The matching should cover the entire input string (not partial).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "aa", p = "a" <br />
              Output: false <br />
              Explanation: "a" does not match the entire string "aa".
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "aa", p = "*" <br />
              Output: true <br />
              Explanation: '*' matches any sequence.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "cb", p = "?a" <br />
              Output: false <br />
              Explanation: '?' matches 'c', but the second letter is 'a', which
              does not match 'b'.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= s.length, p.length &lt;= 2000 <br />
              s contains only lowercase English letters. <br />p contains only
              lowercase English letters, '?' or '*'.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(sp)
              <br />
              Space: O(sp)
            </p>
            <CodeEditor
              options={{
                title: "Q44. Wildcard Matching",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @param {string} p
                    * @return {boolean}
                    */
                   var isMatch = function (s, p) {
                     let dp = Array.from(Array(s.length + 1),()=>Array(p.length + 1).fill(false));
                     dp[0][0] = true;
                     for (let j = 1; j <= p.length; j++) {
                       if (p[j - 1] == "*") {
                         dp[0][j] = dp[0][j - 1];
                       }
                     }
                     for (let i = 1; i < s.length + 1; i++) {
                       for (let j = 1; j < p.length + 1; j++) {
                         if (p[j - 1] == "?" || p[j - 1] == s[i - 1]) {
                           dp[i][j] = dp[i][j] || dp[i - 1][j - 1];
                         } else if (p[j - 1] == "*") {
                           dp[i][j] = dp[i - 1][j] || dp[i][j - 1];
                         }
                       }
                     }
                     return dp[s.length][p.length];
                   };
                   
                   console.log(isMatch("aa", "a"));
                   `,
                    output: `false`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q45: {
        title: "Q45. Jump Game 2",
        content: (
          <>
            <Span>
              <b>Q45. Jump Game 2</b>
            </Span>
            <Span>
              Given an array of non-negative integers nums, you are initially
              positioned at the first index of the array. Each element in the
              array represents your maximum jump length at that position. Your
              goal is to reach the last index in the minimum number of jumps.
              You can assume that you can always reach the last index.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [2,3,1,1,4] <br />
              Output: 2 <br />
              Explanation: The minimum number of jumps to reach the last index
              is 2. Jump 1 step from index 0 to 1, then 3 steps to the last
              index.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [2,3,0,1,4] <br />
              Output: 2
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 104 <br />0 &lt;= nums[i] &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q45. Jump Game 2",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var jump = function (n) {
                     let len = n.length - 1;
                     let curr = -1,
                       next = 0,
                       ans = 0;
                     for (let i = 0; next < len; i++) {
                       if (i > curr) {
                         ans++;
                         curr = next;
                       }
                       next = Math.max(next, n[i] + i);
                     }
                     return ans;
                   };
                   
                   console.log(jump([2, 3, 1, 1, 4]));
                   `,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q46: {
        title: "Q46. Permutations",
        content: (
          <>
            <Span>
              <b>Q46. Permutations</b>
            </Span>
            <Span>
              Given an array nums of distinct integers, return all the possible
              permutations. You can return the answer in any order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3] <br />
              Output: [[1,2,3],[1,3,2],[2,1,3],[2,3,1],[3,1,2],[3,2,1]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0,1] <br />
              Output: [[0,1],[1,0]]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [1] <br />
              Output: [[1]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 6 <br />
              -10 &lt;= nums[i] &lt;= 10 <br />
              All the integers of nums are unique.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.n!)
              <br />
              Space: O(n.n!)
            </p>
            <CodeEditor
              options={{
                title: "Q46. Permutations",
                codes: {
                  Javascript: {
                    output: `[
                      [ 1, 2, 3 ],
                      [ 1, 3, 2 ],
                      [ 2, 1, 3 ],
                      [ 2, 3, 1 ],
                      [ 3, 1, 2 ],
                      [ 3, 2, 1 ]
                    ]`,
                    code: `/**
                    * @param {number[]} nums
                    * @return {number[][]}
                    */
                   var permute = function (nums) {
                     let res = [];
                     let visited = Array(nums.length).fill(false);
                     dfs(nums, res, [], visited);
                     return res;
                   };
                   
                   var dfs = function (nums, res = [], curr = [], visited = []) {
                     if (curr.length == nums.length) {
                       res.push(curr.slice());
                       return;
                     }
                     for (let i in nums) {
                       if (visited[i] === false) {
                         visited[i] = true;
                         curr.push(nums[i]);
                         dfs(nums, res, curr, visited);
                         curr.pop();
                         visited[i] = false;
                       }
                     }
                   };
                   
                   console.log(permute([1, 2, 3]));
                   
                   `,
                  },
                },
              }}
            />
          </>
        ),
      },
      q47: {
        title: "Q47. Permutations 2",
        content: (
          <>
            <Span>
              <b>Q47. Permutations II</b>
            </Span>
            <Span>
              Given a collection of numbers, nums, that might contain
              duplicates, return all possible unique permutations in any order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,1,2] <br />
              Output: [[1,1,2], [1,2,1], [2,1,1]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3] <br />
              Output: [[1,2,3],[1,3,2],[2,1,3],[2,3,1],[3,1,2],[3,2,1]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 8 <br />
              -10 &lt;= nums[i] &lt;= 10
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.n!)
              <br />
              Space: O(n.n!)
            </p>
            <CodeEditor
              options={{
                title: "Q47. Permutations 2",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number[][]}
                    */
                   var permuteUnique = function(nums) {
                         let res = [];
                     let visited = Array(nums.length).fill(false);
                     if (nums === null || nums.length === 0) {
                       return res;
                     }
                     nums.sort();
                     dfs(nums, res, [], visited);
                     return res;
                   };
                   
                   var dfs = function (nums, res = [], curr = [], visited = []) {
                     if (curr.length == nums.length) {
                       res.push(curr.slice());
                       return;
                     }
                     for (let i in nums) {
                       if (visited[i] === true) {
                         continue;
                       }
                       if (
                         i === 0 ||
                         nums[i] !== nums[i - 1] ||
                         (nums[i] === nums[i - 1] && visited[i - 1] === true)
                       ) {
                         visited[i] = true;
                         curr.push(nums[i]);
                         dfs(nums, res, curr, visited);
                         curr.pop();
                         visited[i] = false;
                       }
                     }
                   };
                   
                   console.log(permuteUnique([1, 1, 2]));`,
                    output: `[ [ 1, 1, 2 ], [ 1, 2, 1 ], [ 2, 1, 1 ] ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q48: {
        title: "Q48. Rotate Image",
        content: (
          <>
            <Span>
              <b>Q48. Rotate Image</b>
            </Span>
            <Span>
              You are given an n x n 2D matrix representing an image, rotate the
              image by 90 degrees (clockwise). You have to rotate the image
              in-place, which means you have to modify the input 2D matrix
              directly. DO NOT allocate another 2D matrix and do the rotation.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={LeetcodeQ48} left />
            <Span>
              Input: matrix = [[1,2,3],[4,5,6],[7,8,9]] <br />
              Output: [[7,4,1],[8,5,2],[9,6,3]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img src={LeetcodeQ48b} left />
            <Span>
              Input: matrix = [[5,1,9,11],[2,4,8,10],[13,3,6,7],[15,14,12,16]]
              <br />
              Output: [[15,13,2,5],[14,3,4,1],[12,6,8,9],[16,7,10,11]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              n == matrix.length == matrix[i].length <br />
              1 &lt;= n &lt;= 20 <br />
              -1000 &lt;= matrix[i][j] &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q48. Rotate Image",
                codes: {
                  Javascript: {
                    output: `[ [ 7, 4, 1 ], [ 8, 5, 2 ], [ 9, 6, 3 ] ]`,
                    code: `/**
                    * @param {number[][]} matrix
                    * @return {void} Do not return anything, modify matrix in-place instead.
                    */
                   var rotate = function(matrix) {
                     const n = matrix.length;
                     for (let i = 0; i < parseInt(n / 2); i++) {
                       for (let j = 0; j < Math.ceil(n / 2); j++) {
                         let temp = matrix[i][j];
                         matrix[i][j] = matrix[n - 1 - j][i];
                         matrix[n - 1 - j][i] = matrix[n - 1 - i][n - 1 - j];
                         matrix[n - 1 - i][n - 1 - j] = matrix[j][n - 1 - i];
                         matrix[j][n - 1 - i] = temp;
                       }
                     }
                   };
                   
                   const arr = [
                     [1, 2, 3],
                     [4, 5, 6],
                     [7, 8, 9],
                   ];
                   
                   rotate(arr);
                   console.log(arr);
                   ;
                   `,
                  },
                },
              }}
            />
          </>
        ),
      },
      q49: {
        title: "Q49. Group Anagrams",
        content: (
          <>
            <Span>
              <b>Q49. Group Anagrams</b>
            </Span>
            <Span>
              Given an array of strings strs, group the anagrams together. You
              can return the answer in any order. An Anagram is a word or phrase
              formed by rearranging the letters of a different word or phrase,
              typically using all the original letters exactly once.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: strs = ["eat","tea","tan","ate","nat","bat"] <br />
              Output: [["bat"],["nat","tan"],["ate","eat","tea"]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: strs = [""] <br />
              Output: [[""]]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: strs = ["a"] ] <br />
              Output: [["a"]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= strs.length &lt;= 104 <br />
              0 &lt;= strs[i].length &lt;= 100 <br />
              strs[i] consists of lowercase English letters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(nklogk)
              <br />
              Space: O(nk)
            </p>
            <CodeEditor
              options={{
                title: "Q49. Group Anagrams",
                codes: {
                  Javascript: {
                    output: `[ [ 'eat', 'tea', 'ate' ], [ 'tan', 'nat' ], [ 'bat' ] ]`,
                    code: `/**
                    * @param {string[]} strs
                    * @return {string[][]}
                    */
                   var groupAnagrams1 = function(strs) {
                     let result = []
                     let map = {}
                     for(let s of strs){
                       let temp = s.split("")
                       temp.sort()
                       let tmp = temp.join("")
                       if(tmp in map){
                          result[map[tmp]].push(s)
                          continue;
                       }
                       let curr =[]
                       curr.push(s)
                       result.push(curr)
                       map[tmp] = result.length - 1 
                     }
                     return result 
                   };
                   
                   var groupAnagrams = function(strs) {
                     const d = {}
                     for(let s of strs){
                       let k = s.split('').sort((a,b)=>a.charCodeAt(0)-b.charCodeAt(0)).join('')
                       d[k] = d[k]? [...d[k],s]:[s]
                     }
                     return Object.values(d)
                   }
                   
                   console.log(groupAnagrams(["eat","tea","tan","ate","nat","bat"]))`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q50: {
        title: "Q50. Pow(x,n)",
        content: (
          <>
            <Span>
              <b>Q50. Pow(x, n)</b>
            </Span>
            <Span>
              Implement pow(x, n), which calculates x raised to the power n
              (i.e., xn).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: x = 2.00000, n = 10 <br />
              Output: 1024.00000
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: x = 2.10000, n = 3 <br />
              Output: 9.26100
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: x = 2.00000, n = -2 <br />
              Output: 0.25000 <br />
              Explanation: 2-2 = 1/22 = 1/4 = 0.25
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              -100.0 &lt; x &lt; 100.0 <br />
              -2^31 &lt;= n &lt;= 2^31-1 <br />
              -10^4 &lt;= xn &lt;= 10^4 <br />
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                codes: {
                  Javascript: {
                    output: `1024`,
                    code: `/**
                    * @param {number} x
                    * @param {number} n
                    * @return {number}
                    */
                   var myPow1 = function(x, n) {
                       if (n === 0) return 1.0
                       let half = myPow(x, parseInt(n / 2))
                       if (n % 2 === 0) return half * half
                       else if (n > 0) return half * half * x;
                       else return half * half / x;
                   };
                   
                   var myPow = function(x, n) {
                    if (n == 0)
                      return 1;
                    if (n < 0)
                      return 1 / myPow(x, -n);
                    if (n & 1)
                      return x * myPow(x, n - 1);
                    return myPow(x * x, parseInt(n / 2));
                   }
                   
                   console.log(myPow(2.00000, 10))
                   `,
                  },
                },
              }}
            />
          </>
        ),
      },
      q51: {
        title: "Q51. N-Queens",
        content: (
          <>
            <Span>
              <b>Q51. N-Queens</b>
            </Span>
            <Span>
              The n-queens puzzle is the problem of placing n queens on an n x n
              chessboard such that no two queens attack each other. Given an
              integer n, return all distinct solutions to the n-queens puzzle.
              You may return the answer in any order. Each solution contains a
              distinct board configuration of the n-queens' placement, where 'Q'
              and '.' both indicate a queen and an empty space, respectively.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={LeetcodeQ51} left />
            <Span>
              Input: n = 4 <br />
              Output:
              [[".Q..","...Q","Q...","..Q."],["..Q.","Q...","...Q",".Q.."]]
              <br />
              Explanation: There exist two distinct solutions to the 4-queens
              puzzle as shown above
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1 <br />
              Output: [["Q (Q)"]]
            </Span>
            <Span>
              <b>Constraints</b>
            </Span>
            <Span>1 &lt;= n &lt;= 9</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.n!)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q51. N-Queens",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {string[][]}
                    */
                   
                   function isSafe(board, row, col, n) {
                       let i, j
                   
                       // left side
                       for (let i = 0; i < col; i++)
                           if (board[row][i] == 1) return false
                   
                       // upper diagonal
                       for (let i = row, j = col; i >= 0 && j >= 0; i--, j--)
                           if (board[i][j] == 1) return false;
                   
                       // lower diagnoal  
                       for (i = row, j = col; j >= 0 && i < n; i++, j--)
                           if (board[i][j] == 1) return false;
                   
                       return true
                   }
                   
                   function placeQueens(board, col, n, result) {
                       // if all columns are explored, the board is solved
                       if (col == n) {
                           result.push(board.map((cell, i) => cell.indexOf(1)))
                           return true
                       };
                       let res = false
                       for (let i = 0; i < n; i++) {
                           if (isSafe(board, i, col, n)) {
                               board[i][col] = 1;
                               // check for next col 
                               res = placeQueens(board, col + 1, n, result) || res;
                               board[i][col] = 0; // backtrack
                           }
                       }
                       return res;
                   }
                   
                   var solveNQueens = function(n) {
                     const board = Array.from(Array(n), () => Array(n).fill(0))
                     const result = []
                     placeQueens(board, 0, n,result)
                     const answer = []
                     result.forEach((r, k) => {
                       let temp = Array.from(Array(n), () => Array(n).fill(0))
                       for (let index in r) temp[index][r[index]] = 1
                       answer.push(temp)
                     })
                     return answer.map(x=>x.map(y=>y.map(z=>z?'Q':'.').join('')))
                   };
                   
                   solveNQueens(4)`,
                    output: `[
                      [ '..Q.', 'Q...', '...Q', '.Q..' ],
                      [ '.Q..', '...Q', 'Q...', '..Q.' ]
                    ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q52: {
        title: "Q52. N-Queens II",
        content: (
          <>
            <Span>
              <b>Q52. N-Queens II</b>
            </Span>
            <Span>
              The n-queens puzzle is the problem of placing n queens on an n x n
              chessboard such that no two queens attack each other. Given an
              integer n, return the number of distinct solutions to the n-queens
              puzzle.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={LeetcodeQ52} left />
            <Span>
              Input: n = 4 <br />
              Output: 2 <br />
              Explanation: There are two distinct solutions to the 4-queens
              puzzle as shown.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1 <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints</b>
            </Span>
            <Span>1 &lt;= n &lt;= 9</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.n!)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q52. N-Queens II",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number}
                    */
                   function isSafe(board, row, col, n) {
                       let i, j
                   
                       // left side
                       for (let i = 0; i < col; i++)
                           if (board[row][i] == 1) return false
                   
                       // upper diagonal
                       for (let i = row, j = col; i >= 0 && j >= 0; i--, j--)
                           if (board[i][j] == 1) return false;
                   
                       // lower diagnoal  
                       for (i = row, j = col; j >= 0 && i < n; i++, j--)
                           if (board[i][j] == 1) return false;
                   
                       return true
                   }
                   
                   function placeQueens(board, col, n, result) {
                       // if all columns are explored, the board is solved
                       if (col == n) {
                           result.push(board.map((cell, i) => cell.indexOf(1)))
                           return true
                       };
                       let res = false
                       for (let i = 0; i < n; i++) {
                           if (isSafe(board, i, col, n)) {
                               board[i][col] = 1;
                               // check for next col 
                               res = placeQueens(board, col + 1, n, result) || res;
                               board[i][col] = 0; // backtrack
                           }
                       }
                       return res;
                   }
                   
                   var totalNQueens = function(n) {
                     const board = Array.from(Array(n), () => Array(n).fill(0))
                     const result = []
                     placeQueens(board, 0, n,result)
                     const answer = []
                     result.forEach((r, k) => {
                       let temp = Array.from(Array(n), () => Array(n).fill(0))
                       for (let index in r) temp[index][r[index]] = 1
                       answer.push(temp)
                     })
                     return answer.length
                   };
                   
                   totalNQueens(4)`,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q53: {
        title: "Q53. Maximum Subarray",
        content: (
          <>
            <Span>
              <b>Q53. Maximum Subarray</b>
            </Span>
            <Span>
              Given an integer array nums, find the contiguous subarray
              (containing at least one number) which has the largest sum and
              return its sum. A subarray is a contiguous part of an array.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [-2,1,-3,4,-1,2,1,-5,4] <br /> Output: 6 <br />{" "}
              Explanation: [4,-1,2,1] has the largest sum = 6.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1] <br />
              Output: 1 <br />
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [5,4,-1,7,8] <br />
              Output: 23
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 105 <br />
              -104 &lt;= nums[i] &lt;= 104
            </Span>
            <Span>
              <b>Follow up</b>: If you have figured out the O(n) solution, try
              coding another solution using the divide and conquer approach,
              which is more subtle.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q53. Maximum Subarray",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var maxSubArray = function(nums) {
                       let curr = 0;
                       let max = Number.MIN_SAFE_INTEGER
                       for (let i = 0; i < nums.length; i++) {
                           curr = Math.max(curr + nums[i], nums[i])
                           max = Math.max(curr, max)
                       }
                       return max
                   };
                   
                   console.log(maxSubArray([1, 2, 3]))`,
                    output: `6`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q54: {
        title: "Q54. Spiral Matrix",
        content: (
          <>
            <Span>
              <b>Q54. Spiral Matrix</b>
            </Span>
            <Span>
              Given an m x n matrix, return all elements of the matrix in spiral
              order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={LeetcodeQ54a} left />
            <Span>
              Input: matrix = [[1,2,3],[4,5,6],[7,8,9]] <br />
              Output: [1,2,3,6,9,8,7,4,5]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img src={LeetcodeQ54b} left />
            <Span>
              Input: matrix = [[1,2,3,4],[5,6,7,8],[9,10,11,12]] <br />
              Output: [1,2,3,4,8,12,11,10,9,5,6,7]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == matrix.length <br />
              n == matrix[i].length <br />
              1 &lt;= m, n &lt;= 10 <br />
              -100 &lt;= matrix[i][j] &lt;= 100
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q54. Spiral Matrix",
                codes: {
                  Javascript: {
                    code: `/**
                  * @param {number[][]} matrix
                  * @return {number[]}
                  */
                 var spiralOrder = function(matrix) {
                   const result = []
                   if(matrix == null || matrix.length == 0) return result;
                   let m = matrix.length;
                   let n = matrix[0].length;
                   let x=0;
                   let y=0;
                   while(m>0 && n>0){
                   if(m==1){
                       for(let i=0; i<n; i++){
                           result.push(matrix[x][y++]);
                       }
                       break;
                   }
                   else if(n==1){
                       for(let i=0; i<m; i++){
                           result.push(matrix[x++][y]);
                       }
                       break;
                   }
                   //below, process a circle
                   //top - move right
                   for(let i=0;i<n-1;i++){
                       result.push(matrix[x][y++]);
                   }
                   //right - move down
                   for(let i=0;i<m-1;i++){
                       result.push(matrix[x++][y]);
                   }
                   //bottom - move left
                   for(let i=0;i<n-1;i++){
                       result.push(matrix[x][y--]);
                   }
                   //left - move up
                   for(let i=0;i<m-1;i++){
                       result.push(matrix[x--][y]);
                   }
                   x++;
                   y++;
                   m=m-2;
                   n=n-2;
                   }
                   return result;  
                 };
                 
                 console.log(spiralOrder([[1,2,3],[4,5,6],[7,8,9]]))`,
                    output: `[
                  1, 2, 3, 6, 9,
                  8, 7, 4, 5
                ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q55: {
        title: "Q55. Jump Game",
        content: (
          <>
            <Span>
              <b>Q55. Jump Game</b>
            </Span>
            <Span>
              You are given an integer array nums. You are initially positioned
              at the array's first index, and each element in the array
              represents your maximum jump length at that position. Return true
              if you can reach the last index, or false otherwise.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [2,3,1,1,4] <br />
              Output: true <br />
              Explanation: Jump 1 step from index 0 to 1, then 3 steps to the
              last index.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [3,2,1,0,4] <br />
              Output: false <br />
              Explanation: You will always arrive at index 3 no matter what. Its
              maximum jump length is 0, which makes it impossible to reach the
              last index.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 104 <br />0 &lt;= nums[i] &lt;= 105
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q55. Jump Game",
                codes: {
                  Javascript: {
                    output: `false`,
                    code: `/**
                * @param {number[]} nums
                * @return {boolean}
                */
               var canJump = function(nums) {
                 let max = 0;
                 for (let i=0;i<nums.length;i++) {
                     if (i > max) {
                         return false;
                     }
                     max = Math.max(max, i + nums[i]);
                 }
                 return true;
               };
               
               console.log(canJump([3,2,1,0,4]))`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q56: {
        title: "Q56. Merge Intervals",
        content: (
          <>
            <Span>
              <b>Q56. Merge Intervals</b>
            </Span>
            <Span>
              Given an array of intervals where intervals[i] = [starti, endi],
              merge all overlapping intervals, and return an array of the
              non-overlapping intervals that cover all the intervals in the
              input.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: intervals = [[1,3],[2,6],[8,10],[15,18]] <br />
              Output: [[1,6],[8,10],[15,18]] <br />
              Explanation: Since intervals [1,3] and [2,6] overlaps, merge them
              into [1,6].
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: intervals = [[1,4],[4,5]] <br />
              Output: [[1,5]] <br />
              Explanation: Intervals [1,4] and [4,5] are considered overlapping.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= intervals.length &lt;= 104 <br />
              intervals[i].length == 2 <br />0 &lt;= starti &lt;= endi &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.log n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q56. Merge Intervals",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[][]} intervals
                    * @return {number[][]}
                    */
                   var merge = function(intervals) {
                      const result = []
                      intervals.sort((a,b) => a[0] - b[0])
                      for(let i of intervals){
                        if(!result.length || result.slice(-1)[0][1] < i[0])
                        result.push(i)
                        else
                        result[result.length-1][1] = Math.max(result.slice(-1)[0][1],i[1])
                      }
                     return result
                   };
                   
                   merge([[1,4],[4,5]])`,
                    output: `[ [ 1, 5 ] ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q57: {
        title: "Q57. Insert Interval",
        content: (
          <>
            <Span>
              <b>Q57. Insert Interval</b>
            </Span>
            <Span>
              You are given an array of non-overlapping intervals intervals
              where intervals[i] = [starti, endi] represent the start and the
              end of the ith interval and intervals is sorted in ascending order
              by starti. You are also given an interval newInterval = [start,
              end] that represents the start and end of another interval.
            </Span>
            <Span>
              Insert newInterval into intervals such that intervals is still
              sorted in ascending order by starti and intervals still does not
              have any overlapping intervals (merge overlapping intervals if
              necessary).
            </Span>
            <Span>Return intervals after the insertion.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: intervals = [[1,3],[6,9]], newInterval = [2,5] <br />
              Output: [[1,5],[6,9]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: intervals = [[1,2],[3,5],[6,7],[8,10],[12,16]], <br />
              newInterval = [4,8]
              <br />
              Output: [[1,2],[3,10],[12,16]]
              <br />
              Explanation: Because the new interval [4,8] overlaps with
              [3,5],[6,7],[8,10].
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= intervals.length &lt;= 10^4 <br />
              intervals[i].length == 2 <br />
              0 &lt;= starti &lt;= endi &lt;= 10^5 <br />
              intervals is sorted by starti in ascending order. <br />
              newInterval.length == 2 <br />0 &lt;= start &lt;= end &lt;= 10^5
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q57. Insert Interval",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[][]} intervals
                    * @param {number[]} newInterval
                    * @return {number[][]}
                    */
                   var insert = function(intervals, newInterval){
                     const result = []
                     const n = intervals.length
                     // intervals.sort((a, b) => a[0] - b[0])
                     let i = 0;
                     while(i<n && intervals[i][1]<newInterval[0])
                     result.push(intervals[i++])
                     while(i<n && intervals[i][0]<=newInterval[1]){
                     newInterval[0] = Math.min(newInterval[0], intervals[i][0]);
                     newInterval[1] = Math.max(newInterval[1], intervals[i][1]);
                     ++i;
                     }
                     result.push(newInterval)
                     while(i<n)
                     result.push(intervals[i++])
                     return result
                   };
                   
                   insert([
                       [1, 3],
                       [6, 9]
                   ],[2,5])`,
                    output: `[ [ 1, 5 ], [ 6, 9 ] ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q58: {
        title: "Q58. Length of Last Word",
        content: (
          <>
            <Span>
              <b>Q58. Length of Last Word</b>
            </Span>
            <Span>
              Given a string s consisting of some words separated by some number
              of spaces, return the length of the last word in the string.
            </Span>
            <Span>
              A word is a maximal substring consisting of non-space characters
              only.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "Hello World" <br />
              Output: 5 <br />
              Explanation: The last word is "World" with length 5.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = " fly me to the moon " <br />
              Output: 4 <br />
              Explanation: The last word is "moon" with length 4.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "luffy is still joyboy" <br />
              Output: 6 <br />
              Explanation: The last word is "joyboy" with length 6.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 104 <br />
              s consists of only English letters and spaces ' '. <br />
              There will be at least one word in s.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q58. Length of Last Word",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {number}
                    */
                   var lengthOfLastWord = function(s) {
                       const res = s.split(" ").filter(x=>x!=='')
                       if(res.length)
                       return res.slice(-1)[0].length
                       return 0
                   };
                   
                   lengthOfLastWord("   fly me   to   the moon  ")`,
                    output: `4`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q59: {
        title: "Q59. Spiral Matrix II",
        content: (
          <>
            <Span>
              <b>Q59. Spiral Matrix II</b>
            </Span>
            <Span>
              Given a positive integer n, generate an n x n matrix filled with
              elements from 1 to n2 in spiral order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={LeetcodeQ59} left />
            <Span>
              Input: n = 3 <br />
              Output: [[1,2,3],[8,9,4],[7,6,5]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1 <br />
              Output: [[1]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= n &lt;= 20</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(n^2)
            </p>
            <CodeEditor
              options={{
                title: "Q59. Spiral Matrix II",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number[][]}
                    */
                   var generateMatrix = function(n) {
                   
                     const result = Array.from(Array(n),()=>Array(n).fill(0))
                     let count = 1
                     
                     const x = parseInt(n/2);
                   
                     for (let min = 0; min < x; ++min) {
                       const max = n - min - 1;
                       for (let i = min; i < max; ++i)
                         result[min][i] = count++;
                       for (let i = min; i < max; ++i)
                         result[i][max] = count++;
                       for (let i = max; i > min; --i)
                         result[max][i] = count++;
                       for (let i = max; i > min; --i)
                         result[i][min] = count++;
                     }
                   
                     if(n % 2 == 1){
                       result[x][x] = count;
                     }
                     return result
                   };
                   
                   generateMatrix(3).forEach(x=>console.log(x.join(" ")))`,
                    output: `
                    1 2 3
8 9 4
7 6 5
                    `,
                  },
                },
              }}
            />
          </>
        ),
      },
      q60: {
        title: "Q60. Permutation Sequence",
        content: (
          <>
            <Span>
              <b>Q60. Permutation Sequence</b>
            </Span>
            <Span>
              The set [1, 2, 3, ..., n] contains a total of n! unique
              permutations.
            </Span>
            <Span>
              By listing and labeling all of the permutations in order, we get
              the following sequence for n = 3:
            </Span>
            <Span>
              "123" <br />
              "132" <br />
              "213" <br />
              "231" <br />
              "312" <br />
              "321"
            </Span>
            <Span>Given n and k, return the kth permutation sequence.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 3, k = 3 <br />
              Output: "213"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 4, k = 9 <br />
              Output: "2314"
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: n = 3, k = 1 <br />
              Output: "123"
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= n &lt;= 9 <br />1 &lt;= k &lt;= n!
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q60. Permutation Sequence",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @param {number} k
                    * @return {string}
                    */
                   
                   function permuteBacktrack(str, answer,result,k) {
                       if(result.length<k){
                       if (str.length === 0) {
                           result.push(answer.slice())
                           return
                       }
                       for (let i = 0; i < str.length; i++) {
                           let ch = str[i];
                           let left = str.substring(0, i);
                           let right = str.substring(i + 1);
                           let rest = left + right;
                           permuteBacktrack(rest, answer + ch,result,k);
                       }
                       }
                   }
                   
                   var getPermutation = function(n, k) {
                       const str = [...Array(n).keys()].map(x=>x+1).join("")
                       const result = []
                       permuteBacktrack(str,"",result,k)
                       return result[k-1]
                   };
                   
                   getPermutation(3,3)`,
                    output: `213`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q61: {
        title: "Q61. Rotate List",
        content: (
          <>
            <Span>
              <b>Q61. Rotate List</b>
            </Span>
            <Span>
              Given the head of a linked list, rotate the list to the right by k
              places.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: head = [1,2,3,4,5], k = 2<br />
              Output: [4,5,1,2,3]
            </Span>
            <Img src={LeetcodeQ61a} left />
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [0,1,2], k = 4 <br />
              Output: [2,0,1]
            </Span>
            <Img src={LeetcodeQ61b} left />
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is in the range [0, 500]. <br />
              -100 &lt;= Node.val &lt;= 100 <br />0 &lt;= k &lt;= 2 * 109
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q61. Rotate List",
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                      }
                      
                      /**
                       * @param {ListNode} head
                       * @param {number} k
                       * @return {ListNode}
                       */
                      var rotateRight = function(head, k) {
                        if(!head || !head.next || k==0)
                        return head
                        
                        let len = 1
                        let tail = head
                      
                        while(tail.next){
                          ++len
                          tail = tail.next
                        }
                        tail.next = head
                      
                        const t = len - k%len
                        for(let i=0;i<t;i++)
                        tail = tail.next
                        const newHead = tail.next
                        tail.next = null
                        return newHead  
                      };
                      
                      const head = new ListNode(1)
                      head.next = new ListNode(2)
                      head.next.next = new ListNode(3)
                      head.next.next.next = new ListNode(4)
                      head.next.next.next.next = new ListNode(5)
                      rotateRight(head,2)
                      `,
                    output: `ListNode {
                      val: 4,
                      next: ListNode { val: 5, next: ListNode { val: 1, next: [ListNode] } }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q62: {
        title: "Q62. Unique Paths",
        content: (
          <>
            <Span>
              <b>Q62. Unique Paths</b>
            </Span>
            <Span>
              There is a robot on an m x n grid. The robot is initially located
              at the top-left corner (i.e., grid[0][0]). The robot tries to move
              to the bottom-right corner (i.e., grid[m - 1][n - 1]). The robot
              can only move either down or right at any point in time.
            </Span>
            <Span>
              Given the two integers m and n, return the number of possible
              unique paths that the robot can take to reach the bottom-right
              corner.
            </Span>
            <Span>
              The test cases are generated so that the answer will be less than
              or equal to 2 * 10^9.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img left src={LeetcodeQ62} />
            <Span>
              Input: m = 3, n = 7 <br />
              Output: 28
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: m = 3, n = 2 <br />
              Output: 3 <br />
              Explanation: From the top-left corner, there are a total of 3 ways
              to reach the bottom-right corner:
              <br /> 1. Right -&gt; Down -&gt; Down <br />
              2. Down -&gt; Down -&gt; Right <br />
              3. Down -&gt; Right -&gt; Down
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= m, n &lt;= 6100</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q62. Unique Paths",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} m
                    * @param {number} n
                    * @return {number}
                    */
                   var uniquePaths1 = function(m, n) {
                     const dp = Array.from(Array(m),()=>Array(n).fill(1))
                     for(let i=1;i<m;i++)
                     for(let j=1;j<n;j++)
                     dp[i][j] = dp[i-1][j] + dp[i][j-1] 
                     dp.forEach(x=>console.log(x.join(" ")))
                     return dp[m-1][n-1]
                   };
                   
                   var uniquePaths = function(m, n) {
                     const dp = Array(n).fill(1)
                     for(let i=1;i<m;i++)
                     for(let j=1;j<n;j++)
                     dp[j] += dp[j-1] 
                     console.log(dp.join(" "))
                     return dp[n-1] 
                   };
                   
                   uniquePaths1(3,2)
                   console.log("###")
                   uniquePaths(3,2)`,
                    output: `1 1
                    1 2
                    1 3
                    ###
                    1 3
                    3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q63: {
        title: "Q63. Unique Paths II",
        content: (
          <>
            <Span>
              <b>Q63. Unique Paths II</b>
            </Span>
            <Span>
              A robot is located at the top-left corner of a m x n grid (marked
              'Start' in the diagram below).
            </Span>
            <Span>
              The robot can only move either down or right at any point in time.
              The robot is trying to reach the bottom-right corner of the grid
              (marked 'Finish' in the diagram below).
            </Span>
            <Span>
              Now consider if some obstacles are added to the grids. How many
              unique paths would there be?
            </Span>
            <Span>
              An obstacle and space is marked as 1 and 0 respectively in the
              grid.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={LeetcodeQ63a} left />
            <Span>
              Input: obstacleGrid = [[0,0,0],[0,1,0],[0,0,0]] <br />
              Output: 2 <br />
              Explanation: There is one obstacle in the middle of the 3x3 grid
              above. <br />
              There are two ways to reach the bottom-right corner: <br />
              1. Right -&gt; Right -&gt; Down -&gt; Down <br />
              2. Down -&gt; Down -&gt; Right -&gt; Right
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img src={LeetcodeQ63b} left />
            <Span>
              Input: obstacleGrid = [[0,1],[0,0]] <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == obstacleGrid.length <br />
              n == obstacleGrid[i].length <br />
              1 &lt;= m, n &lt;= 100 <br />
              obstacleGrid[i][j] is 0 or 1.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q63. Unique Paths II",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[][]} obstacleGrid
                    * @return {number}
                    */
                   var uniquePathsWithObstacles1 = function(obstacleGrid) {
                     const m = obstacleGrid.length
                     const n = obstacleGrid[0].length
                     const dp = Array.from(Array(m+1),()=>Array(n+1).fill(0))
                     dp[0][1] = 1 
                     // or dp[1][0] = 1
                     for (let i = 1; i <= m; ++i)
                     for (let j = 1; j <= n; ++j)
                     if (!obstacleGrid[i - 1][j - 1])
                       dp[i][j] = dp[i - 1][j] + dp[i][j - 1];
                     dp.forEach(x=>console.log(x.join(" ")))
                     return dp[m][n]
                   };
                   
                   var uniquePathsWithObstacles = function(obstacleGrid) {
                     const m = obstacleGrid.length
                     const n = obstacleGrid[0].length    
                     const dp = Array(n).fill(0)
                     dp[0] = 1
                     for (let i = 0; i < m; ++i)
                     for (let j = 0; j < n; ++j)
                     if (obstacleGrid[i][j])
                       dp[j] = 0;
                     else if (j > 0)
                       dp[j] += dp[j - 1];
                     console.log(dp.join(" "))
                     return dp[n-1]
                   };
                   
                   uniquePathsWithObstacles1([[0,0,0],[0,1,0],[0,0,0]])
                   console.log("####")
                   uniquePathsWithObstacles([[0,0,0],[0,1,0],[0,0,0]])`,
                    output: `0 1 0 0
                   0 1 1 1
                   0 1 0 1
                   0 1 1 2
                   ####
                   1 1 2
                   2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q64: {
        title: "Q64. Minimum Path Sum",
        content: (
          <>
            <Span>
              <b>Q64. Minimum Path Sum</b>
            </Span>
            <Span>
              Given a m x n grid filled with non-negative numbers, find a path
              from top left to bottom right, which minimizes the sum of all
              numbers along its path.
            </Span>
            <Span>
              Note: You can only move either down or right at any point in time.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={LeetcodeQ64} left />
            <Span>
              Input: grid = [[1,3,1],[1,5,1],[4,2,1]] <br />
              Output: 7 <br />
              Explanation: Because the path 1 → 3 → 1 → 1 → 1 minimizes the sum.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: grid = [[1,2,3],[4,5,6]] <br />
              Output: 12
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == grid.length <br />
              n == grid[i].length <br />
              1 &lt;= m, n &lt;= 200 <br />0 &lt;= grid[i][j] &lt;= 100
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[][]} grid
                    * @return {number}
                    */
                   var minPathSum = function(grid) {
                     const m = grid.length;
                     const n = grid[0].length;
                     grid.forEach(x=>console.log(x.join(" ")));
                     console.log("#####")
                     // at each cell place the minimum value
                     for (let i = 0; i < m; ++i)
                       for (let j = 0; j < n; ++j)
                         if (i > 0 && j > 0)
                           grid[i][j] += Math.min(grid[i - 1][j], grid[i][j - 1]);
                         else if (i > 0)
                           grid[i][0] += grid[i - 1][0];
                         else if (j > 0)
                           grid[0][j] += grid[0][j - 1];
                     grid.forEach(x=>console.log(x.join(" ")));
                     return grid[m - 1][n - 1];
                   };
                   
                   minPathSum([[1,3,1],[1,5,1],[4,2,1]])`,
                    output: `1 3 1
                    1 5 1
                    4 2 1
                    #####
                    1 4 5
                    2 7 6
                    6 8 7
                    7`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q65: {
        title: "Q65. Valid Number",
        content: (
          <>
            <Span>
              <b>Q65. Valid Number</b>
            </Span>
            <Span>
              A valid number can be split up into these components (in order):
            </Span>
            <Span>
              1. A decimal number or an integer. <br />
              (2. Optional) An 'e' or 'E', followed by an integer.
            </Span>
            <Span>
              A decimal number can be split up into these components (in order):
            </Span>
            <Span>
              1. (Optional) A sign character (either '+' or '-'). <br />
              2. One of the following formats:
              <br />
              1. One or more digits, followed by a dot '.'. <br />
              2. One or more digits, followed by a dot '.', followed by one or
              more digits.
              <br />
              3. A dot '.', followed by one or more digits.
            </Span>
            <Span>
              An integer can be split up into these components (in order):
            </Span>
            <Span>
              1. (Optional) A sign character (either '+' or '-'). <br />
              2. One or more digits.
            </Span>
            <Span>
              For example, all the following are valid numbers: ["2", "0089",
              "-0.1", "+3.14", "4.", "-.9", "2e10", "-90E3", "3e+7", "+6e-1",
              "53.5e93", "-123.456e789"], while the following are not valid
              numbers: ["abc", "1a", "1e", "e3", "99e2.5", "--6", "-+3",
              "95a54e53"].
            </Span>
            <Span>Given a string s, return true if s is a valid number.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "0" <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "e" <br />
              Output: false
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "." <br />
              Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 20 <br />s consists of only English letters
              (both uppercase and lowercase), digits (0-9), plus '+', minus '-',
              or dot '.'.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q65. Valid Number",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {boolean}
                    */
                   var isNumber = function(s) {
                     s = s.trim()
                     s = s.toLowerCase()
                     if(!s) return false
                     let seenNum = false;
                     let seenDot = false;
                     let seenE = false;
                     for (let i=0;i<s.length;i++) {
                       switch (s[i]) {
                         case '.':
                           if (seenDot || seenE)
                             return false;
                           seenDot = true;
                           break;
                         case 'e':
                           if (seenE || !seenNum)
                             return false;
                           seenE = true;
                           seenNum = false;
                           break;
                         case '+':
                         case '-':
                           if (i > 0 && s[i - 1] != 'e')
                             return false;
                           seenNum = false;
                           break;
                         default:
                           if (!Number.isInteger(+s[i]))
                             return false;
                           seenNum = true;
                       }
                     }
                     return seenNum;
                   };
                   
                   isNumber("1E9")`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q66: {
        title: "Q66. Plus One",
        content: (
          <>
            <Span>
              <b>Q66. Plus One</b>
            </Span>
            <Span>
              You are given a large integer represented as an integer array
              digits, where each digits[i] is the ith digit of the integer. The
              digits are ordered from most significant to least significant in
              left-to-right order. The large integer does not contain any
              leading 0's.
            </Span>
            <Span>
              Increment the large integer by one and return the resulting array
              of digits.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: digits = [1,2,3] <br />
              Output: [1,2,4] <br />
              Explanation: The array represents the integer 123. <br />
              Incrementing by one gives 123 + 1 = 124. <br />
              Thus, the result should be [1,2,4].
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: digits = [4,3,2,1] <br />
              Output: [4,3,2,2] <br />
              Explanation: The array represents the integer 4321. <br />
              Incrementing by one gives 4321 + 1 = 4322. <br />
              Thus, the result should be [4,3,2,2].
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: digits = [9] <br />
              Output: [1,0] <br />
              Explanation: The array represents the integer 9. <br />
              Incrementing by one gives 9 + 1 = 10. <br />
              Thus, the result should be [1,0].
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= digits.length &lt;= 100 <br />
              0 &lt;= digits[i] &lt;= 9 <br />
              digits does not contain any leading 0's.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q66. Plus One",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} digits
                    * @return {number[]}
                    */
                   var plusOne = function(digits) {
                     for (let i = digits.length - 1; i >= 0; i--) {
                       if (digits[i] < 9) {
                         ++digits[i];
                         return digits;
                       }
                       digits[i] = 0;
                     }
                     return [1,...digits]
                   };
                   
                   plusOne([6,1,4,5,3,9,0,1,9,5,1,8,6,7,0,5,5,4,3])`,
                    output: `[
                    6, 1, 4, 5, 3, 9, 0,
                    1, 9, 5, 1, 8, 6, 7,
                    0, `,
                  },
                },
              }}
            />
          </>
        ),
      },
      q67: {
        title: "Q67. Add Binary",
        content: (
          <>
            <Span>
              <b>Q67. Add Binary</b>
            </Span>
            <Span>
              Given two binary strings a and b, return their sum as a binary
              string.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: a = "11", b = "1" <br />
              Output: "100"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: a = "1010", b = "1011" <br />
              Output: "10101"
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= a.length, b.length &lt;= 104 <br />
              a and b consist only of '0' or '1' characters. <br />
              Each string does not contain leading zeros except for the zero
              itself.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q67. Add Binary",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} a
                    * @param {string} b
                    * @return {string}
                    */
                   
                   // function b2d(bin){
                   //   return parseInt(bin, 2).toString(10);
                   // }
                   
                   // function d2b(dec){
                   //   return (dec >>> 0).toString(2);
                   // }
                   
                   var addBinary = function(a, b) {
                     const s = []
                     let carry = 0
                     let i = a.length -1
                     let j = b.length -1
                     while(i >= 0 || j >= 0 || carry){
                     if(i >= 0){
                       carry += +a[i]
                       i -= 1
                     }
                     if(j >= 0){
                       carry += +b[j]
                       j -= 1
                     }
                     s.push(String(carry % 2))
                     carry = parseInt(carry/2)
                     }
                     s.reverse()
                     return s.join("")
                   };
                   
                   addBinary("10100000100100110110010000010101111011011001101110111111111101000000101111001110001111100001101",
                   "110101001011101110001111100110001010100001101011101010000011011011001011101111001100000011011110011")`,
                    output: `110111101100010011000101110110100000011101000101011001000011011000001100011110011010010011000000000`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q68: {
        title: "Q68. Text Justification",
        content: (
          <>
            <Span>
              <b>Q68. Text Justification</b>
            </Span>
            <Span>
              Given an array of strings words and a width maxWidth, format the
              text such that each line has exactly maxWidth characters and is
              fully (left and right) justified.
            </Span>
            <Span>
              You should pack your words in a greedy approach; that is, pack as
              many words as you can in each line. Pad extra spaces ' ' when
              necessary so that each line has exactly maxWidth characters.
            </Span>
            <Span>
              Extra spaces between words should be distributed as evenly as
              possible. If the number of spaces on a line does not divide evenly
              between words, the empty slots on the left will be assigned more
              spaces than the slots on the right.
            </Span>
            <Span>
              For the last line of text, it should be left-justified and no
              extra space is inserted between words.
            </Span>
            <Span>
              <b>Note:</b>
            </Span>
            <Span>
              A word is defined as a character sequence consisting of non-space
              characters only. <br />
              Each word's length is guaranteed to be greater than 0 and not
              exceed maxWidth. <br />
              The input array words contains at least one word.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: words = ["This", "is", "an", "example", "of", "text",
              "justification."],
              <br />
              maxWidth = 16
              <br />
              Output:
              <br />
              [
              <br />
              "This is an",
              <br />
              "example of text",
              <br />
              "justification. "
              <br />]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: words = ["What","must","be","acknowledgment","shall","be"],
              maxWidth = 16
              <br />
              Output:
              <br />
              [
              <br />
              "What must be",
              <br />
              "acknowledgment ",
              <br />
              "shall be "
              <br />
              ]
              <br />
              Explanation: Note that the last line is "shall be " instead of
              "shall be", because the last line must be left-justified instead
              of fully-justified.
              <br />
              Note that the second line is also left-justified becase it
              contains only one word.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: words =
              ["Science","is","what","we","understand","well","enough","to","explain","to","a","computer.","Art","is","everything","else","we","do"],
              maxWidth = 20
              <br />
              Output:
              <br />
              [
              <br />
              "Science is what we",
              <br />
              "understand well",
              <br />
              "enough to explain to",
              <br />
              "a computer. Art is",
              <br />
              "everything else we",
              <br />
              "do "
              <br />]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= words.length &lt;= 300 <br />
              1 &lt;= words[i].length &lt;= 20
              <br />
              words[i] consists of only English letters and symbols.
              <br />
              1 &lt;= maxWidth &lt;= 100
              <br />
              words[i].length &lt;= maxWidth
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q68. Text Justification",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string[]} words
                    * @param {number} maxWidth
                    * @return {string[]}
                    */
                   var fullJustify = function(words, maxWidth) {
                       let row = []
                       const ans = []
                       let rowLetters = 0
                       for(let w of words){
                        if(rowLetters + w.length + row.length > maxWidth){
                           for(let i=0;i<maxWidth-rowLetters;i++)
                           row[i % (row.length - 1 || 1)] += ' '
                           ans.push(row.join(''))
                           row = []
                           rowLetters = 0
                        }
                        row.push(w)
                        rowLetters += w.length
                       }
                       // padStart fill char's at start
                       return [...ans,row.join(' ').padEnd(maxWidth)]
                   };
                   
                   fullJustify(["What","must","be","acknowledgment","shall","be"]
                   ,16)`,
                    output: `[ 'What   must   be', 'acknowledgment  ', 'shall be        ' ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q69: {
        title: "Q69. Sqrt(x)",
        content: (
          <>
            <Span>
              <b>Q69. Sqrt(x)</b>
            </Span>
            <Span>
              Given a non-negative integer x, compute and return the square root
              of x.
            </Span>
            <Span>
              Since the return type is an integer, the decimal digits are
              truncated, and only the integer part of the result is returned.
            </Span>
            <Span>
              Note: You are not allowed to use any built-in exponent function or
              operator, such as pow(x, 0.5) or x ** 0.5.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: x = 4 <br />
              Output: 2
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: x = 8 <br />
              Output: 2 <br />
              Explanation: The square root of 8 is 2.82842..., and since the
              decimal part is truncated, 2 is returned.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>0 &lt;= x &lt;= 231 - 1</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q69. Sqrt(x)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} x
                    * @return {number}
                    */
                   var mySqrt = function(x) {
                     let l =1
                     let r = x+1
                     while (l < r) {
                     const m = l + parseInt((r - l) / 2);
                     if (m > parseInt(x / m))
                       r = m;
                     else
                       l = m + 1;
                     }
                     return l - 1;
                   };
                   
                   mySqrt(9)`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q70: {
        title: "Q70. Climbing Stairs",
        content: (
          <>
            <Span>
              <b>Q70. Climbing Stairs</b>
            </Span>
            <Span>
              You are climbing a staircase. It takes n steps to reach the top.
            </Span>
            <Span>
              Each time you can either climb 1 or 2 steps. In how many distinct
              ways can you climb to the top?
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 2 <br />
              Output: 2 <br />
              Explanation: There are two ways to climb to the top. <br />
              1. 1 step + 1 step <br />
              2. 2 steps
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 3<br />
              Output: 3<br />
              Explanation: There are three ways to climb to the top.
              <br />
              1. 1 step + 1 step + 1 step
              <br />
              2. 1 step + 2 steps
              <br />
              3. 2 steps + 1 step
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= n &lt;= 45</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q70. Climbing Stairs",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number}
                    */
                   var climbStairs = function(n) {
                     const dp = Array(n + 1).fill(0);
                     dp[0] = 1;
                     dp[1] = 1;
                     for (let i = 2; i <= n; ++i)
                       dp[i] = dp[i - 1] + dp[i - 2];
                     console.log(dp)
                     console.log("###")
                     return dp[n];    
                   };
                   
                   var climbStairs1 = function(n){
                       let prev1 = prev2 = 1;  
                       for (let i = 2; i <= n; ++i) {
                         const dp = prev1 + prev2;
                         prev2 = prev1;
                         prev1 = dp;
                       }
                       return prev1;
                   }
                   
                   climbStairs(2)
                   `,
                    output: `[ 1, 1, 2 ]
                   ###
                   2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q71: {
        title: "Q71. Simplify Path",
        content: (
          <>
            <Span>
              <b>Q71. Simplify Path</b>
            </Span>
            <Span>
              Given a string path, which is an absolute path (starting with a
              slash '/') to a file or directory in a Unix-style file system,
              convert it to the simplified canonical path.
            </Span>
            <Span>
              In a Unix-style file system, a period '.' refers to the current
              directory, a double period '..' refers to the directory up a
              level, and any multiple consecutive slashes (i.e. '//') are
              treated as a single slash '/'. For this problem, any other format
              of periods such as '...' are treated as file/directory names.
            </Span>
            <Span>The canonical path should have the following format:</Span>
            <Span>
              1. The path starts with a single slash '/'. <br />
              2. Any two directories are separated by a single slash '/'. <br />
              3. The path does not end with a trailing '/'.
              <br />
              4. The path only contains the directories on the path from the
              root directory to the target file or directory (i.e., no period
              '.' or double period '..')
            </Span>
            <Span>Return the simplified canonical path.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: path = "/home/"
              <br />
              Output: "/home"
              <br />
              Explanation: Note that there is no trailing slash after the last
              directory name.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: path = "/../"
              <br />
              Output: "/"
              <br />
              Explanation: Going one level up from the root directory is a
              no-op, as the root level is the highest level you can go.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: path = "/home//foo/"
              <br />
              Output: "/home/foo"
              <br />
              Explanation: In the canonical path, multiple consecutive slashes
              are replaced by a single one.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= path.length &lt;= 3000 <br />
              path consists of English letters, digits, period '.', slash '/' or
              '_'.
              <br />
              path is a valid absolute Unix path.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q71. Simplify Path",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} path
                    * @return {string}
                    */
                   var simplifyPath = function(path) {
                     const stack = []
                     const paths = path.split('/')
                     for(let s of paths){
                       if(['', '.'].includes(s)) continue
                       if(s === '..') 
                       {if(stack) stack.pop()}
                       else
                         stack.push(s)
                     }
                     return '/' + stack.join('/')
                   };
                   
                   simplifyPath("/home/")`,
                    output: `/home`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q72: {
        title: "Q72. Edit Distance",
        content: (
          <>
            <Span>
              <b>Q72. Edit Distance</b>
            </Span>
            <Span>
              Given two strings word1 and word2, return the minimum number of
              operations required to convert word1 to word2.
              <br />
              You have the following three operations permitted on a word:
              <br />
              Insert a character <br />
              Delete a character
              <br />
              Replace a character
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>{" "}
            <Span>
              Input: word1 = "horse", word2 = "ros" <br />
              Output: 3<br />
              Explanation: <br />
              horse -&gt; rorse (replace 'h' with 'r')
              <br />
              rorse -&gt; rose (remove 'r')
              <br />
              rose -&gt; ros (remove 'e')
              <br />
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: word1 = "intention", word2 = "execution"
              <br />
              Output: 5<br />
              Explanation: <br />
              intention -&gt; inention (remove 't')
              <br />
              inention -&gt; enention (replace 'i' with 'e')
              <br />
              enention -&gt; exention (replace 'n' with 'x')
              <br />
              exention -&gt; exection (replace 'n' with 'c')
              <br />
              exection -&gt; execution (insert 'u')
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= word1.length, word2.length &lt;= 500
              <br />
              word1 and word2 consist of lowercase English letters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(mn)
            </p>
            <CodeEditor
              options={{
                title: "Q72. Edit Distance",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} word1
                    * @param {string} word2
                    * @return {number}
                    */
                   var minDistance = function(word1, word2) {
                     const m = word1.length
                     const n = word2.length
                     if (m == 0) return n;
                     if (n == 0) return m;
                     const dp = Array.from(Array(m + 1),()=>Array(n + 1).fill(0));
                     for (let i = 1; i <= m; ++i)
                       dp[i][0] = i;
                     for (let j = 1; j <= n; ++j)
                       dp[0][j] = j;
                     for (let i = 1; i <= m; ++i)
                       for (let j = 1; j <= n; ++j)
                         if (word1[i - 1] == word2[j - 1])
                           dp[i][j] = dp[i - 1][j - 1];
                         else
                           dp[i][j] = Math.min(dp[i - 1][j - 1], Math.min(dp[i - 1][j], dp[i][j - 1])) + 1;
                     return dp[m][n];
                   };
                   
                   minDistance("horse","ros")`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q73: {
        title: "Q73. Set Matrix Zeroes",
        content: (
          <>
            <Span>
              <b>Q73. Set Matrix Zeroes</b>
            </Span>
            <Span>
              Given an m x n integer matrix matrix, if an element is 0, set its
              entire row and column to 0's, and return the matrix.
            </Span>
            <Span>You must do it in place.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode73a} left />
            <Span>
              Input: matrix = [[1,1,1],[1,0,1],[1,1,1]] <br />
              Output: [[1,0,1],[0,0,0],[1,0,1]]
            </Span>
            <Img src={Leetcode73b} left />
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: matrix = [[0,1,2,0],[3,4,5,2],[1,3,1,5]] <br />
              Output: [[0,0,0,0],[0,4,5,0],[0,3,1,0]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == matrix.length <br />
              n == matrix[0].length <br />
              1 &lt;= m, n &lt;= 200 <br />
              -231 &lt;= matrix[i][j] &lt;= 231 - 1
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q73. Set Matrix Zeroes",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[][]} matrix
                    * @return {void} Do not return anything, modify matrix in-place instead.
                    */
                   var setZeroes = function(matrix) {
                     const m = matrix.length;
                     const n = matrix[0].length;
                     let row = false;
                     let col = false;
                     for (let j = 0; j < n; ++j)
                       if (matrix[0][j] == 0) {
                         row = true;
                         break;
                       }
                     for (let i = 0; i < m; ++i)
                       if (matrix[i][0] == 0) {
                         col = true;
                         break;
                       }
                     for (let i = 1; i < m; ++i)
                       for (let j = 1; j < n; ++j)
                         if (matrix[i][j] == 0) {
                           matrix[i][0] = 0;
                           matrix[0][j] = 0;
                         }
                     for (let i = 1; i < m; ++i)
                       for (let j = 1; j < n; ++j)
                         if (matrix[i][0] == 0 || matrix[0][j] == 0)
                           matrix[i][j] = 0;
                     if (row)
                       for (let j = 0; j < n; ++j)
                         matrix[0][j] = 0;
                     if (col)
                       for (let i = 0; i < m; ++i)
                         matrix[i][0] = 0;
                     matrix.forEach(x=>console.log(x.join(" ")))
                   };
                   
                   setZeroes(
                     [[1,1,1],[1,0,1],[1,1,1]]
                   )`,
                    output: `1 0 1
                    0 0 0
                    1 0 1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q74: {
        title: "Q74. Search a 2D Matrix",
        content: (
          <>
            <Span>
              <b>Q74. Search a 2D Matrix</b>
            </Span>
            <Span>
              Write an efficient algorithm that searches for a value in an m x n
              matrix. This matrix has the following properties:
            </Span>
            <Span>
              1. Integers in each row are sorted from left to right. <br />
              2. The first integer of each row is greater than the last integer
              of the previous row.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode74a} left />
            <Span>
              Input: matrix = [[1,3,5,7],[10,11,16,20],[23,30,34,60]], target =
              3 <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img src={Leetcode74b} left />
            <Span>
              Input: matrix = [[1,3,5,7],[10,11,16,20],[23,30,34,60]], target =
              13 <br />
              Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == matrix.length <br />
              n == matrix[i].length <br />
              1 &lt;= m, n &lt;= 100 <br />
              -104 &lt;= matrix[i][j], target &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn log mn)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q74. Search a 2D Matrix",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[][]} matrix
                    * @param {number} target
                    * @return {boolean}
                    */
                   var searchMatrix = function(matrix, target) {
                     if (matrix.length == 0) return false;
                     const m = matrix.length;
                     const n = matrix[0].length;
                     let l = 0;
                     let r = m * n;
                     while (l < r) {
                       const mid = l + parseInt((r - l) / 2);
                       const i = parseInt(mid / n);
                       const j = mid % n;
                       if (matrix[i][j] == target)
                         return true;
                       if (matrix[i][j] < target)
                         l = mid + 1;
                       else
                         r = mid;
                     }
                     return false;    
                   };
                   
                   searchMatrix([[1,3,5,7],[10,11,16,20],[23,30,34,60]],3)`,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q75: {
        title: "Q75. Sort Colors",
        content: (
          <>
            <Span>
              <b>Q75. Sort Colors</b>
            </Span>
            <Span>
              Given an array nums with n objects colored red, white, or blue,
              sort them in-place so that objects of the same color are adjacent,
              with the colors in the order red, white, and blue.
            </Span>
            <Span>
              We will use the integers 0, 1, and 2 to represent the color red,
              white, and blue, respectively.
            </Span>
            <Span>
              You must solve this problem without using the library's sort
              function.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [2,0,2,1,1,0] <br />
              Output: [0,0,1,1,2,2]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [2,0,1] <br />
              Output: [0,1,2]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              n == nums.length <br />
              1 &lt;= n &lt;= 300 <br />
              nums[i] is either 0, 1, or 2.
            </Span>
            <Span>
              <b>Follow up:</b>
              Could you come up with a one-pass algorithm using only constant
              extra space?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q75. Sort Colors",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {void} Do not return anything, modify nums in-place instead.
                    */
                   function threePointers(nums){
                     let p = q = r = -1;
                     for (let n of nums)
                       if (n == 0) {
                         nums[++r] = 2;
                         nums[++q] = 1;
                         nums[++p] = 0;
                       } else if (n == 1) {
                         nums[++r] = 2;
                         nums[++q] = 1;
                       } else {
                         nums[++r] = 2;
                       }
                   }
                   // O(n) O(1)
                   
                   function twoPointers(nums){
                       let l = 0
                       let r = nums.length - 1
                       let i = 0
                       while(i<=r){
                         if(nums[i]==0){
                         [nums[i],nums[l]] = [nums[l],nums[i]]
                         i++
                         l++
                         }else if(nums[i]==1){
                         i++
                         }else{
                         [nums[i],nums[r]] = [nums[r],nums[i]]
                         r--
                         }
                       }
                   }
                   // O(n) O(1)
                   
                   var sortColors = function(nums) {
                     //  twoPointers(nums)
                      threePointers(nums)
                      console.log(nums)
                   };
                   
                   sortColors([2,0,2,1,1,0])`,
                    output: `[ 0, 0, 1, 1, 2, 2 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q76: {
        title: "Q76. Minimum Window Substring",
        content: (
          <>
            <Span>
              <b>Q76. Minimum Window Substring</b>
            </Span>
            <Span>
              Given two strings s and t of lengths m and n respectively, return
              the minimum window substring of s such that every character in t
              (including duplicates) is included in the window. If there is no
              such substring, return the empty string "".
            </Span>
            <Span>
              The testcases will be generated such that the answer is unique.
            </Span>
            <Span>
              A substring is a contiguous sequence of characters within the
              string.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "ADOBECODEBANC", t = "ABC" <br />
              Output: "BANC" <br />
              Explanation: The minimum window substring "BANC" includes 'A',
              'B', and 'C' from string t.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "a", t = "a" <br />
              Output: "a" <br />
              Explanation: The entire string s is the minimum window.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>{" "}
            <Span>
              Input: s = "a", t = "aa" <br />
              Output: "" <br />
              Explanation: Both 'a's from t must be included in the window.{" "}
              <br />
              Since the largest window of s only has one 'a', return empty
              string.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>{" "}
            <Span>
              m == s.length <br />
              n == t.length <br />
              1 &lt;= m, n &lt;= 105 <br />s and t consist of uppercase and
              lowercase English letters.
            </Span>
            <Span>
              <b>Follow up: </b>
              Could you find an algorithm that runs in O(m + n) time?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q76. Minimum Window Substring",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @param {string} t
                    * @return {string}
                    */
                   var minWindow = function(s, t) {
                     const count = Array(128).fill(0)
                     let required = t.length;
                     let bestLeft = -1;
                     let minLength = s.length + 1;
                     for (let c of t)
                       ++count[c.charCodeAt(0)];
                     for (let l = 0, r = 0; r < s.length; ++r) {
                       if (--count[s[r].charCodeAt(0)] >= 0)
                         --required;
                       while (required == 0) {
                         if (r - l + 1 < minLength) {
                           bestLeft = l;
                           minLength = r - l + 1;
                         }
                         if (++count[s[l++].charCodeAt(0)] > 0)
                           ++required;
                       }
                     }
                     return bestLeft == -1 ? "" : s.substring(bestLeft, bestLeft + minLength);
                   };
                   
                   minWindow("ADOBECODEBANC","ABC")`,
                    output: `BANC`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q77: {
        title: "Q77. Combinations",
        content: (
          <>
            <Span>
              <b>Q77. Combinations</b>
            </Span>
            <Span>
              Given two integers n and k, return all possible combinations of k
              numbers out of the range [1, n].
            </Span>
            <Span>You may return the answer in any order.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 4, k = 2 <br />
              Output: [ [2,4], [3,4], [2,3], [1,2], [1,3], [1,4], ]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1, k = 1 <br />
              Output: [[1]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= n &lt;= 20 <br />1 &lt;= k &lt;= n
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(nCk)
              <br />
              Space: O(k.nCk)
            </p>
            <CodeEditor
              options={{
                title: "Q77. Combinations",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @param {number} k
                    * @return {number[][]}
                    */
                   var dfs = function(n,k,s,res,curr=[]){
                     if(k==0){
                      res.push(curr.slice())
                      return
                     }
                     for(let i=s;i<=n;i++){
                     curr.push(i)
                     dfs(n,k-1,i+1,res,curr)
                     curr.pop()
                     }
                   }
                   
                   var combine = function(n, k) {
                     const res = [] 
                     dfs(n,k,1,res) 
                     return res
                   };
                   
                   combine(4,2)`,
                    output: `[ [ 1, 2 ], [ 1, 3 ], [ 1, 4 ], [ 2, 3 ], [ 2, 4 ], [ 3, 4 ] ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q78: {
        title: "Q78. Subsets",
        content: (
          <>
            <Span>
              <b>Q78. Subsets</b>
            </Span>
            <Span>
              Given an integer array nums of unique elements, return all
              possible subsets (the power set).
            </Span>
            <Span>
              The solution set must not contain duplicate subsets. Return the
              solution in any order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3] <br />
              Output: [[],[1],[2],[1,2],[3],[1,3],[2,3],[1,2,3]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0] <br />
              Output: [[],[0]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 10 <br />
              -10 &lt;= nums[i] &lt;= 10 <br />
              All the numbers of nums are unique.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(2^n)
              <br />
              Space: O(n2^n)
            </p>
            <CodeEditor
              options={{
                title: "Q78. Subsets",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number[][]}
                    */
                   function subsetsH(i_set, result, subset = [], index = 0) {
                       result.push(subset.slice()) 
                       for (let i = index; i < i_set.length; i++) {
                           subset.push(i_set[i])
                           subsetsH(i_set, result, subset, i + 1)
                           subset.pop()
                       }
                       return
                   }
                   
                   var subsets = function(nums){
                     const result = []
                     subsetsH(nums,result)
                     return result
                   };
                   
                   subsets([1,2,3])`,
                    output: `[
                      [],       [ 1 ],
                      [ 1, 2 ], [ 1, 2, 3 ],
                      [ 1, 3 ], [ 2 ],
                      [ 2, 3 ], [ 3 ]
                    ]
                    Hi`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q79: {
        title: "Q79. Word Search",
        content: (
          <>
            <Span>
              <b>Q79. Word Search</b>
            </Span>
            <Span>
              Given an m x n grid of characters board and a string word, return
              true if word exists in the grid.
            </Span>
            <Span>
              The word can be constructed from letters of sequentially adjacent
              cells, where adjacent cells are horizontally or vertically
              neighboring. The same letter cell may not be used more than once.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode79a} left />
            <Span>
              Input: board =
              [["A","B","C","E"],["S","F","C","S"],["A","D","E","E"]], <br />
              word = "ABCCED"
              <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img src={Leetcode79b} left />
            <Span>
              Input: board =
              [["A","B","C","E"],["S","F","C","S"],["A","D","E","E"]], <br />
              word = "SEE"
              <br />
              Output: true
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Img src={Leetcode79c} left />
            <Span>
              Input: board =
              [["A","B","C","E"],["S","F","C","S"],["A","D","E","E"]], <br />
              word = "ABCB" <br />
              Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == board.length <br />
              n = board[i].length <br />
              1 &lt;= m, n &lt;= 6 <br />
              1 &lt;= word.length &lt;= 15 <br />
              board and word consists of only lowercase and uppercase English
              letters.
            </Span>
            <Span>
              <b>Follow up: </b>
              Could you use search pruning to make your solution faster with a
              larger board?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(4^n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q79. Word Search",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {character[][]} board
                    * @param {string} word
                    * @return {boolean}
                    */
                   
                   var dfs = function(board,word,i,j,s){
                     const m = board.length
                     const n = board[0].length
                     if(i<0||i==m||j<0||j==n) return false
                     if (board[i][j] != word[s] || board[i][j] == '*')
                       return false;
                     if (s == word.length - 1)
                       return true;
                     const cache = board[i][j]
                     board[i][j]='*'
                     const isExist = dfs(board, word, i + 1, j, s + 1) 
                     || dfs(board, word, i - 1, j, s + 1) ||
                     dfs(board, word, i, j + 1, s + 1) ||
                     dfs(board, word, i, j - 1, s + 1);
                     board[i][j] = cache
                     return isExist
                   }
                   
                   var exist = function(board, word) {
                     const m = board.length
                     const n = board[0].length
                     for (let i = 0; i < m; ++i)
                     for (let j = 0; j < n; ++j)
                     if (dfs(board, word, i, j, 0)) return true;
                     return false;
                   };
                   
                   exist([["A","B","C","E"],["S","F","C","S"],["A","D","E","E"]],"ABCCED")`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q80: {
        title: "Q80. Remove Duplicates from Sorted Array",
        content: (
          <>
            <Span>
              <b>Q80. Remove Duplicates from Sorted Array II</b>
            </Span>
            <Span>
              Given an integer array nums sorted in non-decreasing order, remove
              some duplicates in-place such that each unique element appears at
              most twice. The relative order of the elements should be kept the
              same.
            </Span>
            <Span>
              Since it is impossible to change the length of the array in some
              languages, you must instead have the result be placed in the first
              part of the array nums. More formally, if there are k elements
              after removing the duplicates, then the first k elements of nums
              should hold the final result. It does not matter what you leave
              beyond the first k elements.
            </Span>
            <Span>
              Return k after placing the final result in the first k slots of
              nums.
            </Span>
            <Span>
              Do not allocate extra space for another array. You must do this by
              modifying the input array in-place with O(1) extra memory.
            </Span>
            <Span>
              <b>Custom Judge:</b>
            </Span>
            <Span>
              The judge will test your solution with the following code:
            </Span>
            <Span>
              int[] nums = [...]; // Input array <br />
              int[] expectedNums = [...]; // The expected answer with correct
              length
              <br />
              int k = removeDuplicates(nums); // Calls your implementation
              <br />
              assert k == expectedNums.length;
              <br />
              for (int i = 0; i &lt; k; i++) {"{"}
              <br />
              assert nums[i] == expectedNums[i];
              <br />
              {"}"}
            </Span>
            <Span>
              If all assertions pass, then your solution will be accepted.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,1,1,2,2,3]
              <br />
              Output: 5, nums = [1,1,2,2,3,_]
              <br />
              Explanation: Your function should return k = 5, with the first
              five elements of nums being 1, 1, 2, 2 and 3 respectively. It does
              not matter what you leave beyond the returned k (hence they are
              underscores).
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0,0,1,1,1,1,2,3,3]
              <br />
              Output: 7, nums = [0,0,1,1,2,3,3,_,_]
              <br />
              Explanation: Your function should return k = 7, with the first
              seven elements of nums being 0, 0, 1, 1, 2, 3 and 3 respectively.
              It does not matter what you leave beyond the returned k (hence
              they are underscores).
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 3 * 104 <br />
              -104 &lt;= nums[i] &lt;= 104 <br />
              nums is sorted in non-decreasing order.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q80. Remove Duplicates from Sorted Array",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var removeDuplicates = function(nums) {
                     let i = 0;
                     for (let n of nums)
                       if (i < 2 || n > nums[i - 2])
                         nums[i++] = n;
                     return i;
                   };
                   
                   removeDuplicates([0,0,1,1,1,1,2,3,3])`,
                    output: `7`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q81: {
        title: "Q81. Search in Rotated Sorted Array II",
        content: (
          <>
            <Span>
              <b>Q81. Search in Rotated Sorted Array II</b>
            </Span>
            <Span>
              There is an integer array nums sorted in non-decreasing order (not
              necessarily with distinct values).
            </Span>
            <Span>
              Before being passed to your function, nums is rotated at an
              unknown pivot index k (0 &lt;= k &lt; nums.length) such that the
              resulting array is [nums[k], nums[k+1], ..., nums[n-1], nums[0],
              nums[1], ..., nums[k-1]] (0-indexed). For example,
              [0,1,2,4,4,4,5,6,6,7] might be rotated at pivot index 5 and become
              [4,5,6,6,7,0,1,2,4,4].
            </Span>
            <Span>
              Given the array nums after the rotation and an integer target,
              return true if target is in nums, or false if it is not in nums.
            </Span>
            <Span>
              You must decrease the overall operation steps as much as possible.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [2,5,6,0,0,1,2], <br />
              target = 0 <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [2,5,6,0,0,1,2],
              <br /> target = 3<br />
              Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              <b>Follow up:</b>
              This problem is similar to Search in Rotated Sorted Array, but
              nums may contain duplicates. Would this affect the runtime
              complexity? How and why?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n -&gt; n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q81. Search in Rotated Sorted Array II",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var search = function(nums,target) {
                       let l = 0;
                       let r = nums.length - 1;
                       while (l <= r) {
                         const m = l + parseInt((r - l) / 2);
                         if (nums[m] == target)
                           return true;
                         if (nums[l] == nums[m] && nums[m] == nums[r]) {
                           ++l;
                           --r;
                         } else if (nums[l] <= nums[m]) {  
                           if (nums[l] <= target && target < nums[m])
                             r = m - 1;
                           else
                             l = m + 1;
                         } else {  
                           if (nums[m] < target && target <= nums[r])
                             l = m + 1;
                           else
                             r = m - 1;
                         }
                       }
                   
                       return false;
                   };
                   
                   search([2,5,6,0,0,1,2],0)`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q82: {
        title: "Q82. Remove Duplicates from Sorted List II",
        content: (
          <>
            <Span>
              <b>Q82. Remove Duplicates from Sorted List IIq82a</b>
            </Span>
            <Span>
              Given the head of a sorted linked list, delete all nodes that have
              duplicate numbers, leaving only distinct numbers from the original
              list. Return the linked list sorted as well.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode82a} left />
            <Span>
              Input: head = [1,2,3,3,4,4,5] <br />
              Output: [1,2,5]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img src={Leetcode82b} left />
            <Span>
              Input: head = [1,1,1,2,3] <br />
              Output: [2,3]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is in the range [0, 300]. <br />
              -100 &lt;= Node.val &lt;= 100 <br />
              The list is guaranteed to be sorted in ascending order.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q82. Remove Duplicates from Sorted List II",
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                    }
                    
                    /**
                     * @param {ListNode} head
                     * @return {ListNode}
                     */
                    var deleteDuplicates = function(head) {
                        const temp = new ListNode(0, head);
                        let prev = temp;
                        while (head) {
                          while (head.next && head.val == head.next.val)
                            head = head.next;
                          if (prev.next == head)
                            prev = prev.next;
                          else
                            prev.next = head.next;
                          head = head.next;
                        }
                        return temp.next;  
                    };
                    
                    const l = new ListNode(1)
                    l.next = new ListNode(2)
                    l.next.next = new ListNode(3)
                    l.next.next.next = new ListNode(3)
                    l.next.next.next.next = new ListNode(4)
                    l.next.next.next.next.next = new ListNode(4)
                    l.next.next.next.next.next.next = new ListNode(5)
                    deleteDuplicates(l)
                    `,
                    output: `ListNode {
                      val: 1,
                      next: ListNode { val: 2, next: ListNode { val: 5, next: null } }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q83: {
        title: "Q83. Remove Duplicates from Sorted List",
        content: (
          <>
            <Span>
              <b>Q83. Remove Duplicates from Sorted List</b>
            </Span>
            <Span>
              Given the head of a sorted linked list, delete all duplicates such
              that each element appears only once. Return the linked list sorted
              as well.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode83a} left />
            <Span>
              Input: head = [1,1,2] <br />
              Output: [1,2]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img src={Leetcode83b} left />
            <Span>
              Input: head = [1,1,2,3,3] <br />
              Output: [1,2,3]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is in the range [0, 300]. <br />
              -100 &lt;= Node.val &lt;= 100 <br />
              The list is guaranteed to be sorted in ascending order.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q83. Remove Duplicates from Sorted List",
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                    }
                    
                    /**
                     * @param {ListNode} head
                     * @return {ListNode}
                     */
                    var deleteDuplicates = function(head) {
                      let curr = head;
                      while (curr) {
                        while (curr.next && curr.val == curr.next.val)
                          curr.next = curr.next.next;
                        curr = curr.next;
                      }
                      return head;    
                    };
                    
                    const l = new ListNode(1)
                    l.next = new ListNode(2)
                    l.next.next = new ListNode(3)
                    l.next.next.next = new ListNode(3)
                    l.next.next.next.next = new ListNode(4)
                    l.next.next.next.next.next = new ListNode(4)
                    l.next.next.next.next.next.next = new ListNode(5)
                    deleteDuplicates(l)`,
                    output: `ListNode {
                      val: 1,
                      next: ListNode { val: 2, next: ListNode { val: 3, next: [ListNode] } }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q84: {
        title: "Q84. Largest Rectangle in Histogram",
        content: (
          <>
            <Span>
              <b>Q84. Largest Rectangle in Histogram</b>
            </Span>
            <Span>
              Given an array of integers heights representing the histogram's
              bar height where the width of each bar is 1, return the area of
              the largest rectangle in the histogram.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode84a} left />
            <Span>
              Input: heights = [2,1,5,6,2,3] <br />
              Output: 10 <br />
              Explanation: The above is a histogram where width of each bar is
              1. <br />
              The largest rectangle is shown in the red area, which has an area
              = 10 units.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img src={Leetcode84b} left />
            <Span>
              Input: heights = [2,4] <br />
              Output: 4
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= heights.length &lt;= 105 <br />0 &lt;= heights[i] &lt;=
              104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q84. Largest Rectangle in Histogram",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} heights
                    * @return {number}
                    */
                   var largestRectangleArea = function(heights) {
                     let ans = 0
                     const stack = []
                     for(let i=0;i<=heights.length;i++){
                       while(stack.length && (i == heights.length || heights[stack[stack.length-1]] > heights[i])){
                         let h = heights[stack.pop()]
                         let w;
                         if(stack.length)
                         w = i - stack[stack.length-1] - 1 
                         else 
                         w = i
                         ans = Math.max(ans, h * w)
                       }
                       stack.push(i)
                     }
                     return ans   
                   };
                   
                   largestRectangleArea([2,1,5,6,2,3])`,
                    output: `10`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q85: {
        title: "Q85. Maximal Rectangle",
        content: (
          <>
            <Span>
              <b>Q85. Maximal Rectangle</b>
            </Span>
            <Span>
              Given a rows x cols binary matrix filled with 0's and 1's, find
              the largest rectangle containing only 1's and return its area.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode85} left />
            <Span>
              Input: matrix = [["1","0","1","0","0"], <br />
              ["1","0","1","1","1"], <br />
              ["1","1","1","1","1"], <br />
              ["1","0","0","1","0"]]
              <br />
              Output: 6<br />
              Explanation: The maximal rectangle is shown in the above picture.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: matrix = [["0"]] <br />
              Output: 0
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: matrix = [["1"]] <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              rows == matrix.length <br />
              cols == matrix[i].length <br />
              1 &lt;= row, cols &lt;= 200 <br />
              matrix[i][j] is '0' or '1'.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q85. Maximal Rectangle",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {character[][]} matrix
                    * @return {number}
                    */
                   
                   var maximalRectangle = function(matrix) {
                     if (matrix.length == 0)
                       return 0;
                     let ans = 0;
                     const hist = Array(matrix[0].length).fill(0);
                     for (let row of matrix) {
                       for (let i = 0; i < row.length; ++i)
                         hist[i] = row[i] == '0' ? 0 : hist[i] + 1;
                       ans = Math.max(ans, largestRectangleArea(hist));
                     }
                     return ans;
                   }
                   var largestRectangleArea = function(heights) {
                     let ans = 0;
                     const stack = [];
                     for (let i = 0; i <= heights.length; ++i) {
                       while (stack.length && (i == heights.length || heights[stack.slice(-1)[0]] > heights[i])) {
                         let h = heights[stack.pop()];
                         let w = !stack.length ? i : i - stack.slice(-1)[0] - 1;
                         ans = Math.max(ans, h * w);
                       }
                       stack.push(i);
                     }
                     return ans;
                   }
                   
                   maximalRectangle([["0"]])`,
                    output: `0`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q86: {
        title: "Q86. Partition List",
        content: (
          <>
            <Span>
              <b>Q86. Partition List</b>
            </Span>
            <Span>
              Given the head of a linked list and a value x, partition it such
              that all nodes less than x come before nodes greater than or equal
              to x.
            </Span>
            <Span>
              You should preserve the original relative order of the nodes in
              each of the two partitions.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode86} left />
            <Span>
              Input: head = [1,4,3,2,5,2], x = 3 <br />
              Output: [1,2,2,4,3,5]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [2,1], x = 2 <br />
              Output: [1,2]
            </Span>
            <Span>
              <b>Constraints</b>
            </Span>
            <Span>
              The number of nodes in the list is in the range [0, 200]. <br />
              -100 &lt;= Node.val &lt;= 100
              <br />
              -200 &lt;= x &lt;= 200
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q86. Partition List",
                codes: {
                  Javascript: {
                    code: ` function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                }
                
                /** 
                 * @param {ListNode} head
                 * @param {number} x
                 * @return {ListNode}
                 */
                var partition = function(head, x) {
                  let beforeHead = new ListNode(0);
                  let afterHead = new ListNode(0);
                  let before = beforeHead;
                  let after = afterHead;
                  for (; head; head = head.next)
                    if (head.val < x) {
                      before.next = head;
                      before = head;
                    } else {
                      after.next = head;
                      after = head;
                    }
                  after.next = null;
                  before.next = afterHead.next;
                  return beforeHead.next;
                };
                
                const l = new ListNode(1)
                l.next = new ListNode(4)
                l.next.next = new ListNode(3)
                l.next.next.next = new ListNode(2)
                l.next.next.next.next = new ListNode(5)
                l.next.next.next.next.next = new ListNode(2)
                partition(l,3)`,
                    output: `ListNode {
                      val: 1,
                      next: ListNode { val: 2, next: ListNode { val: 2, next: [ListNode] } }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q87: {
        title: "Q87. Scramble String",
        content: (
          <>
            <Span>
              <b>Q87. Scramble String</b>
            </Span>
            <Span>
              We can scramble a string s to get a string t using the following
              algorithm:
            </Span>
            <Span>
              1. If the length of the string is 1, stop. <br />
              2. If the length of the string is &gt; 1, do the following:
            </Span>
            <Span>
              (a). Split the string into two non-empty substrings at a random
              index, i.e., if the string is s, divide it to x and y where s = x
              + y. <br />
              (b). Randomly decide to swap the two substrings or to keep them in
              the same order. i.e., after this step, s may become s = x + y or s
              = y + x. <br />
              (c). Apply step 1 recursively on each of the two substrings x and
              y.
            </Span>
            <Span>
              Given two strings s1 and s2 of the same length, return true if s2
              is a scrambled string of s1, otherwise, return false.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s1 = "great", s2 = "rgeat" <br />
              Output: true <br />
              Explanation: One possible scenario applied on s1 is: <br />
              "great" --&gt; "gr/eat" // divide at random index.
              <br />
              "gr/eat" --&gt; "gr/eat" // random decision is not to swap the two
              substrings and keep them in order. <br />
              "gr/eat" --&gt; "g/r / e/at" // apply the same algorithm
              recursively on both substrings. divide at ranom index each of
              them. <br />
              "g/r / e/at" --&gt; "r/g / e/at" // random decision was to swap
              the first substring and to keep the second substring in the same
              order. <br />
              "r/g / e/at" --&gt; "r/g / e/ a/t" // again apply the algorithm
              recursively, divide "at" to "a/t".
              <br />
              "r/g / e/ a/t" --&gt; "r/g / e/ a/t" // random decision is to keep
              both substrings in the same order. <br />
              The algorithm stops now and the result string is "rgeat" which is
              s2.
              <br />
              As there is one possible scenario that led s1 to be scrambled to
              s2, we return true.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s1 = "abcde", s2 = "caebd" <br />
              Output: false
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s1 = "a", s2 = "a" <br />
              Output: true
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              s1.length == s2.length <br />
              1 &lt;= s1.length &lt;= 30 <br />
              s1 and s2 consist of lower-case English letters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s1
                    * @param {string} s2
                    * @return {boolean}
                    */
                    
                    const memo = new Map()
                    
                    // function Counter(a){
                    //   let res = a.split("")
                    //   res = res.reduce((acc,curr)=>{
                    //     acc[curr] =  acc[curr] || 0  + 1
                    //     return acc
                    //   } ,{})
                    //   res = Object.keys(res).sort().reduce(
                    //   (obj, key) => { 
                    //     obj[key] = res[key]; 
                    //     return obj;
                    //   }, {})
                    //   return JSON.stringify(res)
                    // }
                    
                    
                    var isScramble = function(s1, s2) {
                      if(s1 == s2) return true
                      if(s1.length != s2.length) return false
                      const hashedKey = s1 + "+" + s2
                      if(memo.has(hashedKey))
                        return memo.get(hashedKey)
                      const count = Array(128).fill(0)
                      for (let i = 0; i < s1.length; ++i) {
                        ++count[s1[i].charCodeAt(0)];
                        --count[s2[i].charCodeAt(0)];
                      }
                    
                        for (let c of count)
                          if (c != 0) {
                            memo.set(hashedKey, false);
                            return false;
                          }
                    
                      for(let i=1;i<s1.length;i++){
                        if(isScramble(s1.substring(0,i), s2.substring(0,i)) && isScramble(s1.substring(i), s2.substring(i))){
                          memo.set(hashedKey, true);
                          return true
                        } 
                        if(isScramble(s1.substring(0,i), s2.substring(s2.length-i)) && isScramble(s1.substring(i), s2.substring(0,s2.length-i))){
                          memo.set(hashedKey, true);
                          return true
                        }
                      } 
                      memo.set(hashedKey, false);
                      return false   
                    };
                    
                    isScramble("great","rgeat")`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q88: {
        title: "Q88. Merge Sorted Array",
        content: (
          <>
            <Span>
              <b>Q88. Merge Sorted Array</b>
            </Span>
            <Span>
              You are given two integer arrays nums1 and nums2, sorted in
              non-decreasing order, and two integers m and n, representing the
              number of elements in nums1 and nums2 respectively.
            </Span>
            <Span>
              Merge nums1 and nums2 into a single array sorted in non-decreasing
              order.
            </Span>
            <Span>
              The final sorted array should not be returned by the function, but
              instead be stored inside the array nums1. To accommodate this,
              nums1 has a length of m + n, where the first m elements denote the
              elements that should be merged, and the last n elements are set to
              0 and should be ignored. nums2 has a length of n.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums1 = [1,2,3,0,0,0], m = 3, nums2 = [2,5,6], n = 3 <br />
              Output: [1,2,2,3,5,6]
              <br />
              Explanation: The arrays we are merging are [1,2,3] and [2,5,6].
              <br />
              The result of the merge is [1,2,2,3,5,6] with the underlined
              elements coming from nums1.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums1 = [1], m = 1, nums2 = [], n = 0 <br />
              Output: [1]
              <br />
              Explanation: The arrays we are merging are [1] and []. <br />
              The result of the merge is [1].
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums1 = [0], m = 0, nums2 = [1], n = 1 <br />
              Output: [1] <br />
              Explanation: The arrays we are merging are [] and [1]. <br />
              The result of the merge is [1]. <br />
              Note that because m = 0, there are no elements in nums1. The 0 is
              only there to ensure the merge result can fit in nums1.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              nums1.length == m + n <br />
              nums2.length == n <br />
              0 &lt;= m, n &lt;= 200 <br />
              1 &lt;= m + n &lt;= 200 <br />
              -109 &lt;= nums1[i], nums2[j] &lt;= 109
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(m + n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q88. Merge Sorted Array",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums1
                    * @param {number} m
                    * @param {number[]} nums2
                    * @param {number} n
                    * @return {void} Do not return anything, modify nums1 in-place instead.
                    */
                   var merge = function(nums1, m, nums2, n){
                     let i = m - 1;    
                     let j = n - 1;    
                     let k = m + n - 1; 
                     while (j >= 0)
                     if (i >= 0 && nums1[i] > nums2[j])
                       nums1[k--] = nums1[i--];
                     else
                       nums1[k--] = nums2[j--];
                     console.log(nums1)
                   }
                   merge([1,2,3,0,0,0],3,[2,5,6],3)`,
                    output: `[ 1, 2, 2, 3, 5, 6 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q89: {
        title: "Q89. Gray Code",
        content: (
          <>
            <Span>
              <b>Q89. Gray Code</b>
            </Span>
            <Span>
              An n-bit gray code sequence is a sequence of 2n integers where:
              <br />
              Every integer is in the inclusive range [0, 2n - 1],
              <br />
              The first integer is 0,
              <br />
              An integer appears no more than once in the sequence,
              <br />
              The binary representation of every pair of adjacent integers
              differs by exactly one bit, and
              <br />
              The binary representation of the first and last integers differs
              by exactly one bit.
              <br />
              Given an integer n, return any valid n-bit gray code sequence.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 2 <br />
              Output: [0,1,3,2] <br />
              Explanation: <br />
              The binary representation of [0,1,3,2] is [00,01,11,10]. <br />
              - 00 and 01 differ by one bit
              <br />
              - 01 and 11 differ by one bit
              <br />
              - 11 and 10 differ by one bit
              <br />
              - 10 and 00 differ by one bit
              <br />
              [0,2,3,1] is also a valid gray code sequence, whose binary
              representation is [00,10,11,01].
              <br />
              - 00 and 10 differ by one bit
              <br />
              - 10 and 11 differ by one bit
              <br />
              - 11 and 01 differ by one bit
              <br />- 01 and 00 differ by one bit
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1<br />
              Output: [0,1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= n &lt;= 16</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(2^n)
              <br />
              Space: O(2^n)
            </p>
            <CodeEditor
              options={{
                title: "Q89. Gray Code",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number[]}
                    */
                   var grayCode = function(n) {
                     const ans = [];
                     ans.push(0);
                     for (let i = 0; i < n; ++i)
                       for (let j = ans.length - 1; j >= 0; --j)
                         ans.push(ans[j] | 1 << i);
                     return ans;
                   };
                   
                   grayCode(2)`,
                    output: `[0,1,3,2]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q90: {
        title: "Q90. Subsets II",
        content: (
          <>
            <Span>
              <b>Q90. Subsets II</b>
            </Span>
            <Span>
              Given an integer array nums that may contain duplicates, return
              all possible subsets (the power set).
            </Span>
            <Span>
              The solution set must not contain duplicate subsets. Return the
              solution in any order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,2] <br />
              Output: [[],[1],[1,2],[1,2,2],[2],[2,2]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0] <br />
              Output: [[],[0]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 10 <br />
              -10 &lt;= nums[i] &lt;= 10
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.2^n)
              <br />
              Space: O(n.2^n)
            </p>
            <CodeEditor
              options={{
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number[][]}
                    */
                   
                   function subsetsH(i_set, result, subset = [], index = 0) {
                       result.push(subset.slice())
                       for (let i = index; i < i_set.length; i++) {
                           if(i>index && i_set[i]===i_set[i-1])
                           continue
                           subset.push(i_set[i])
                           subsetsH(i_set, result, subset, i + 1)
                           subset.pop()
                       }
                       return
                   }
                   
                   var subsetsWithDup = function(nums) {
                     const result = []
                     nums = nums.sort()
                     subsetsH(nums, result)
                     return result    
                   };
                   
                   subsetsWithDup([4,4,4,1,4])
                   `,
                    output: `[
                    [],
                    [ 1 ],
                    [ 1, 4 ],
                    [ 1, 4, 4 ],
                    [ 1, 4, 4, 4 ],
                    [ 1, 4, 4, 4, 4 ],
                    [ 4 ],
                    [ 4, 4 ],
                    [ 4, 4, 4 ],
                    [ 4, 4, 4, 4 ]
                  ]
                  Hin`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q91: {
        title: "Q91. Decode Ways",
        content: (
          <>
            <Span>
              <b>Q91. Decode Ways</b>
            </Span>
            <Span>
              A message containing letters from A-Z can be encoded into numbers
              using the following mapping:
            </Span>
            <Span>
              'A' -&gt; "1" <br />
              'B' -&gt; "2" <br />
              ... <br />
              'Z' -&gt; "26"
            </Span>
            <Span>
              To decode an encoded message, all the digits must be grouped then
              mapped back into letters using the reverse of the mapping above
              (there may be multiple ways). For example, "11106" can be mapped
              into:
              <br />
              "AAJF" with the grouping (1 1 10 6)
              <br />
              "KJF" with the grouping (11 10 6)
              <br />
              Note that the grouping (1 11 06) is invalid because "06" cannot be
              mapped into 'F' since "6" is different from "06".
              <br />
              <br />
              Given a string s containing only digits, return the number of ways
              to decode it.
              <br />
              The test cases are generated so that the answer fits in a 32-bit
              integer.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "12" <br />
              Output: 2<br />
              Explanation: "12" could be decoded as "AB" (1 2) or "L" (12).
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "226" <br />
              Output: 3 <br />
              Explanation: "226" could be decoded as "BZ" (2 26), "VF" (22 6),
              or "BBF" (2 2 6).
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "06" <br /> Output: 0 <br />
              Explanation: "06" cannot be mapped to "F" because of the leading
              zero ("6" is different from "06").
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 100 <br />s contains only digits and may
              contain leading zero(s).
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q91. Decode Ways",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {number}
                    */
                   
                   function isSafe(c1,c2=null){
                     if(!c2)
                     return c1 != '0';
                     return c1 == '1' || (c1 == '2' && c2 <= '6') 
                   }
                   
                   var numDecodings = function(s) {
                     const n = s.length
                     const dp = [...Array(n).fill(0),1]
                     if(isSafe(s[n-1])) dp[n-1] = 1
                     for (let i = n - 2; i >= 0; --i) {   
                     if(isSafe(s[i]))
                       dp[i] += dp[i + 1]
                     if(isSafe(s[i], s[i + 1]))
                       dp[i] += dp[i + 2]
                   }
                   console.log(dp)
                   return dp[0]
                   };
                   
                   numDecodings("12")`,
                    output: `[ 2, 1, 1 ]
                    2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q92: {
        title: "Q92. Reverse Linked List II",
        content: (
          <>
            <Span>
              <b>Q92. Reverse Linked List II</b>
            </Span>
            <Span>
              Given the head of a singly linked list and two integers left and
              right where left &lt;= right, reverse the nodes of the list from
              position left to position right, and return the reversed list.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode92} left />
            <Span>
              Input: head = [1,2,3,4,5], <br />
              left = 2,
              <br />
              right = 4<br />
              Output: [1,4,3,2,5]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [5], <br />
              left = 1,
              <br /> right = 1 <br />
              Output: [5]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is n. <br />
              1 &lt;= n &lt;= 500 <br />
              -500 &lt;= Node.val &lt;= 500 <br />1 &lt;= left &lt;= right &lt;=
              n
            </Span>
            <Span>
              <b>Follow up:</b> Could you do it in one pass?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q92. Reverse Linked List II",
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                    }
                    /**
                     * @param {ListNode} head
                     * @param {number} left
                     * @param {number} right
                     * @return {ListNode}
                     */
                    var reverseBetween = function(head, left, right) {
                      if (head == null || left == right) return head;
                      const temp = new ListNode(0, head);
                      let prev = temp;
                      for (let i = 0; i < left - 1; ++i)
                        prev = prev.next; 
                      const tail = prev.next; 
                      for (let i = 0; i < right - left; ++i) {
                        const cache = tail.next;
                        tail.next = cache.next;
                        cache.next = prev.next;
                        prev.next = cache;
                      }
                      return temp.next;
                    };
                    
                    const l = new ListNode(1)
                    l.next = new ListNode(2)
                    l.next.next = new ListNode(3)
                    l.next.next.next = new ListNode(4)
                    l.next.next.next.next = new ListNode(5)
                    reverseBetween(l,2,4)`,
                    output: `ListNode {
                      val: 1,
                      next: ListNode { val: 4, next: ListNode { val: 3, next: [ListNode] } }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q93: {
        title: "Q93. Restore IP Addresses",
        content: (
          <>
            <Span>
              <b>Q93. Restore IP Addresses</b>
            </Span>
            <Span>
              A valid IP address consists of exactly four integers separated by
              single dots. Each integer is between 0 and 255 (inclusive) and
              cannot have leading zeros.
              <br />
              For example, "0.1.2.201" and "192.168.1.1" are valid IP addresses,
              but "0.011.255.245", "192.168.1.312" and "192.168@1.1" are invalid
              IP addresses.
              <br />
              Given a string s containing only digits, return all possible valid
              IP addresses that can be formed by inserting dots into s. You are
              not allowed to reorder or remove any digits in s. You may return
              the valid IP addresses in any order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "25525511135" <br />
              Output: ["255.255.11.135","255.255.111.35"]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "0000" <br />
              Output: ["0.0.0.0"]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "101023" <br />
              Output:
              ["1.0.10.23","1.0.102.3","10.1.0.23","10.10.2.3","101.0.2.3"]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= s.length &lt;= 20 <br />s consists of digits only.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(3^4)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q93. Restore IP Addresses",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {string[]}
                    */
                   
                   function dfs(result,str,s=0,curr=[]){
                    if(curr.length == 4 && s == str.length){
                      result.push(curr.join("."))
                      return
                    }
                     if(curr.length == 4 || s == str.length) return
                     for(let i=1;i<=3;++i){
                       if(s + i > str.length) return
                       if(i > 1 && str[s] == '0') return
                       let num = str.substring(s,s+i)
                       if(+num>255) return
                       curr.push(num)
                       dfs(result,str,s+i,curr)
                       curr.pop()
                     }
                   }
                   
                   var restoreIpAddresses = function(s) {
                     const result = []
                     dfs(result,s)
                     return result
                   };
                   
                   restoreIpAddresses("25525511135")`,
                    output: `[ '255.255.11.135', '255.255.111.35' ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q94: {
        title: "Q94. Binary Tree Inorder Traversal",
        content: (
          <>
            <Span>
              <b>Q94. Binary Tree Inorder Traversal</b>
            </Span>
            <Span>
              Given the root of a binary tree, return the inorder traversal of
              its nodes' values.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode94} left />
            <Span>
              Input: root = [1,null,2,3] <br />
              Output: [1,3,2]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [] <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = [1] <br />
              Output: [1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 100].
              <br />
              -100 &lt;= Node.val &lt;= 100
            </Span>
            <Span>
              <b>Follow up: </b>
              Recursive solution is trivial, could you do it iteratively?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q94. Binary Tree Inorder Traversal",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    /**
                     * @param {TreeNode} root
                     * @return {number[]}
                     */
                    
                    function inorder1(r){
                      if(r){
                        inorder1(r.left)
                        console.log(r.val)
                        inorder1(r.right)
                      }
                    }
                    
                    function inorder(result,root,stack=[]){
                     while(root || stack.length){
                     while(root){
                     stack.push(root)
                     root = root.left
                     }
                     root = stack.pop()
                     result.push(root.val)
                     root = root.right
                    }
                    }
                    
                    var inorderTraversal = function(root) {
                      inorder1(root)
                      console.log("###")
                      const result = []
                      inorder(result,root)
                      return result
                    }
                    
                    const t = new TreeNode(1)
                    t.right = new TreeNode(2)
                    t.right.left = new TreeNode(3)
                    inorderTraversal(t)`,
                    output: `1
                    3
                    2
                    ###
                    [ 1, 3, 2 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q95: {
        title: "Q95. Unique Binary Search Trees II",
        content: (
          <>
            <Span>
              <b>Q95. Unique Binary Search Trees II</b>
            </Span>
            <Span>
              Given an integer n, return all the structurally unique BST's
              (binary search trees), which has exactly n nodes of unique values
              from 1 to n. Return the answer in any order.
            </Span>{" "}
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode95} left />
            <Span>
              Input: n = 3 <br />
              Output: [[1,null,2,null,3],
              <br />
              [1,null,3,2],
              <br />
              [2,1,3],
              <br />
              [3,1,null,null,2],
              <br />
              [3,2,null,1]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1<br />
              Output: [[1]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= n &lt;= 8</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(3^n)
              <br />
              Space: O(3^n)
            </p>
            <CodeEditor
              options={{
                title: "Q95. Unique Binary Search Trees II",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right){
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                      }
                      /**
                       * @param {number} n
                       * @return {TreeNode[]}
                       */
                      
                      function generate(start,end){
                        var list = [];
                        if (start > end){
                        list.push(null);
                        return list;
                        }
                        for (var i = start; i <= end; i++){
                        var leftSubtree = generate(start, i - 1);
                        var rightSubtree = generate(i + 1, end);
                        for (var j = 0; j < leftSubtree.length; j++){
                          var left = leftSubtree[j];
                          for (var k = 0; k < rightSubtree.length; k++){
                              var right = rightSubtree[k];
                              var node = new TreeNode(i);
                              node.left = left;
                              node.right = right;    
                              list.push(node);        
                          }}}
                        return list;
                      }
                      
                      var generateTrees = function(n) {
                      return generate(1,n)
                      };
                      
                      generateTrees(3)`,
                    output: `
                      TreeNode {
                        val: 1,
                        left: null,
                        right: TreeNode { val: 2, left: null, right: [TreeNode] }
                      },
                      TreeNode {
                        val: 1,
                        left: null,
                        right: TreeNode { val: 3, left: [TreeNode], right: null }
                      },
                      TreeNode {
                        val: 2,
                        left: TreeNode { val: 1, left: null, right: null },
                        right: TreeNode { val: 3, left: null, right: null }
                      },
                      TreeNode {
                        val: 3,
                        left: TreeNode { val: 1, left: null, right: [TreeNode] },
                        right: null
                      },
                      TreeNode {
                        val: 3,
                        left: TreeNode { val: 2, left: [TreeNode], right: null },
                        right: null
                      }
                    ]
                    Hint:`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q96: {
        title: "Q96. Unique Binary Search Trees",
        content: (
          <>
            <Span>
              <b>Q96. Unique Binary Search Trees</b>
            </Span>
            <Span>
              Given an integer n, return the number of structurally unique BST's
              (binary search trees) which has exactly n nodes of unique values
              from 1 to n.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode96} left />
            <Span>
              Input: n = 3 <br />
              Output: 5
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1 <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= n &lt;= 19</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q96. Unique Binary Search Trees",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number}
                    */
                   
                   // Total number of possible 
                   // Binary Trees with n different 
                   // keys = catalian number * n!
                   
                   // catalian number = 2nCn
                   
                   var numTrees = function(n) {
                     const dp = Array(n+1).fill(0)
                     dp[0] = 1
                     dp[1] = 1
                     for (let i = 2; i <= n; ++i)
                     for (let j = 0; j < i; ++j)
                     dp[i] += dp[j] * dp[i - j - 1];
                     console.log(dp)
                     return dp[n]
                   };
                   
                   numTrees(3)`,
                    output: `[ 1, 1, 2, 5 ]
                   5`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q97: {
        title: "Q97. Interleaving String",
        content: (
          <>
            <Span>
              <b>Q97. Interleaving String</b>
            </Span>
            <Span>
              Given strings s1, s2, and s3, find whether s3 is formed by an
              interleaving of s1 and s2.
            </Span>
            <Span>
              An interleaving of two strings s and t is a configuration where
              they are divided into non-empty substrings such that:
              <br />
              s = s1 + s2 + ... + sn
              <br />
              t = t1 + t2 + ... + tm
              <br />
              |n - m| &lt;= 1
              <br />
              The interleaving is s1 + t1 + s2 + t2 + s3 + t3 + ... or t1 + s1 +
              t2 + s2 + t3 + s3 + ...
              <br />
              Note: a + b is the concatenation of strings a and b.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode97} left />
            <Span>
              Input: s1 = "aabcc",
              <br /> s2 = "dbbca", <br />
              s3 = "aadbbcbcac" <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s1 = "aabcc",
              <br /> s2 = "dbbca",
              <br /> s3 = "aadbbbaccc"
              <br />
              Output: false
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s1 = "", <br /> s2 = "", <br /> s3 = "" <br />
              Output: true
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= s1.length, s2.length &lt;= 100 <br />
              0 &lt;= s3.length &lt;= 200
              <br />
              s1, s2, and s3 consist of lowercase English letters.
            </Span>
            <Span>
              <b>Follow up: </b>
              Could you solve it using only O(s2.length) additional memory
              space?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q97. Interleaving String",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s1
                    * @param {string} s2
                    * @param {string} s3
                    * @return {boolean}
                    */
                   var isInterleave = function(s1, s2, s3) {
                      const m = s1.length 
                      const n = s2.length
                      if(m+n!=s3.length) return false
                      const dp = Array(n+1).fill(false)
                      for (let i = 0; i <= m; ++i)
                         for (let j = 0; j <= n; ++j)
                           if (i == 0 && j == 0)
                             dp[j] = true;
                           else if (i == 0)
                             dp[j] = dp[j - 1] && s2[j - 1] == s3[j - 1];
                           else if (j == 0)
                             dp[j] = dp[j] && s1[i - 1] == s3[i - 1];
                           else
                             dp[j] = dp[j] && s1[i - 1] == s3[i + j - 1] ||
                                     dp[j - 1] && s2[j - 1] == s3[i + j - 1];
                      console.log(dp)
                      return dp[n]
                   };
                   
                   isInterleave( "aabcc","dbbca","aadbbcbcac")`,
                    output: `[ false, false, false, true, false, true ]
                   true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q98: {
        title: "Q98. Validate Binary Search Tree",
        content: (
          <>
            <Span>
              <b>Q98. Validate Binary Search Tree</b>
            </Span>
            <Span>
              Given the root of a binary tree, determine if it is a valid binary
              search tree (BST).
              <br />
              A valid BST is defined as follows:
              <br />
              The left subtree of a node contains only nodes with keys less than
              the node's key.
              <br />
              The right subtree of a node contains only nodes with keys greater
              than the node's key.
              <br />
              Both the left and right subtrees must also be binary search trees.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode98a} left />
            <Span>
              Input: root = [2,1,3] <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img src={Leetcode98b} left />
            <Span>
              Input: root = [5,1,4,null,null,3,6]] <br />
              Output: false] <br />
              Explanation: The root node's value is 5 but its right child's
              value is 4.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [1, 104]. <br />
              -2^31 &lt;= Node.val &lt;= 2^31 - 1
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q98. Validate Binary Search Tree",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    
                    /**
                     * @param {TreeNode} root
                     * @return {boolean}
                     */
                    var isValidBST = function(root) {
                      const stack = []
                      let pred = null
                    
                      while(root || stack.length){
                        while(root){
                          stack.push(root)
                          root = root.left
                        }
                        root = stack.pop()
                        if(pred && pred.val >= root.val) return false
                        pred = root
                        root = root.right
                        }
                      return true
                    };
                    
                    const t = new TreeNode(2)
                    t.left = new TreeNode(1)
                    t.right = new TreeNode(3)
                    isValidBST(t)`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q99: {
        title: "Q99. Recover Binary Search Tree",
        content: (
          <>
            <Span>
              <b>Q99. Recover Binary Search Tree</b>
            </Span>
            <Span>
              You are given the root of a binary search tree (BST), where the
              values of exactly two nodes of the tree were swapped by mistake.
              Recover the tree without changing its structure.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode99a} left />
            <Span>
              Input: root = [1,3,null,null,2] <br />
              Output: [3,1,null,null,2]
              <br />
              Explanation: 3 cannot be a left child of 1 because 3 &gt; 1.
              Swapping 1 and 3 makes the BST valid.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img src={Leetcode99b} left />
            <Span>
              Input: root = [3,1,4,null,null,2] <br />
              Output: [2,1,4,null,null,3]
              <br />
              Explanation: 2 cannot be in the right subtree of 3 because 2 &lt;
              3. Swapping 2 and 3 makes the BST valid.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [2, 1000]. <br />
              -231 &lt;= Node.val &lt;= 231 - 1
            </Span>
            <Span>
              <b>Follow up: </b>A solution using O(n) space is pretty
              straight-forward. Could you devise a constant O(1) space solution?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q99. Recover Binary Search Tree",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                     }
                     
                     
                     /**
                      * @param {TreeNode} root
                      * @return {void} Do not return anything, modify root in-place instead.
                      */
                     var recoverTree = function(root) {
                       let pred = x = y = null  
                       stack = []
                       while(root || stack.length){
                         while(root){
                           stack.push(root)
                           root = root.left
                         }
                         root = stack.pop()
                         if(pred && root.val < pred.val){
                           y = root
                           if(!x) x = pred
                         }
                         pred = root
                         root = root.right
                       }
                       if(x)
                       [x.val,y.val] = [y.val,x.val]
                     };
                     
                     const t = new TreeNode(2)
                     t.left = new TreeNode(1)
                     t.right = new TreeNode(3)
                     recoverTree(t)
                     console.log(t)`,
                    output: `TreeNode {
                      val: 2,
                      left: TreeNode { val: 1, left: null, right: null },
                      right: TreeNode { val: 3, left: null, right: null }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q100: {
        title: "Q100. Same Tree",
        content: (
          <>
            <Span>
              <b>Q100. Same Tree</b>
            </Span>
            <Span>
              Given the roots of two binary trees p and q, write a function to
              check if they are the same or not.
            </Span>
            <Span>
              Two binary trees are considered the same if they are structurally
              identical, and the nodes have the same value.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode100} left />
            <Span>
              Input: p = [1,2,3], q = [1,2,3] <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: p = [1,2], q = [1,null,2] <br />
              Output: false
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: p = [1,2,1], q = [1,1,2] <br />
              Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in both trees is in the range [0, 100]. <br />
              -104 &lt;= Node.val &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q100. Same Tree",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    /**
                     * @param {TreeNode} p
                     * @param {TreeNode} q
                     * @return {boolean}
                     */
                    var isSameTree = function(p, q) {
                      if (p == null || q == null) return p == q;
                      return p.val == q.val &&
                               isSameTree(p.left, q.left) &&
                               isSameTree(p.right, q.right);
                    };
                    
                    const t1= new TreeNode(1)
                    t1.left = new TreeNode(2)
                    t1.right = new TreeNode(3)
                    const t2 = t1
                    isSameTree(t1,t2)`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q101: {
        title: "Q101. Symmetric Tree",
        content: (
          <>
            <Span>
              <b>Q101. Symmetric Tre</b>
            </Span>
            <Span>
              Given the root of a binary tree, check whether it is a mirror of
              itself (i.e., symmetric around its center).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode101a} left />
            <Span>
              Input: root = [1,2,2,3,4,4,3] <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img src={Leetcode101b} left />
            <Span>
              Input: root = [1,2,2,null,3,null,3] <br />
              Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              <b>Follow up: </b>
              Could you solve it both recursively and iteratively?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q101. Symmetric Tree",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    /**
                     * @param {TreeNode} p
                     * @param {TreeNode} q
                     * @return {boolean}
                     */
                    var isSameTree = function(p, q) {
                      if (!p || !q) return p == q;
                      return p.val === q.val &&
                               isSameTree(p.left, q.right) &&
                               isSameTree(p.right, q.left);
                    };
                    
                    var isSymmetric = function(root) {
                      return isSameTree(root,root)
                    };
                    
                    const t1= new TreeNode(1)
                    t1.left = new TreeNode(2)
                    t1.left.right = new TreeNode(3)
                    t1.right = new TreeNode(2)
                    t1.right.right = new TreeNode(3)
                    isSymmetric(t1)`,
                    output: `false`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q102: {
        title: "Q102. Binary Tree Level Order Traversal",
        content: (
          <>
            <Span>
              <b>Q102. Binary Tree Level Order Traversal</b>
            </Span>
            <Span>
              Given the root of a binary tree, return the level order traversal
              of its nodes' values. (i.e., from left to right, level by level).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode102} left />
            <Span>
              Input: root = [3,9,20,null,null,15,7] <br />
              Output: [[3],[9,20],[15,7]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [1] <br />
              Output: [[1]]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = [] <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 2000]. <br />
              -1000 &lt;= Node.val &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q102. Binary Tree Level Order Traversal",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    /**
                     * @param {TreeNode} root
                     * @return {number[][]}
                     */
                    var levelOrder = function(root) {
                      const q = []
                      const result = []
                      q.push(root)
                      while(q.length && root){
                       let curr = []
                       const n = q.length
                       for(let i=0;i<n;i++){
                        const ele = q.shift()
                        curr.push(ele.val)
                        if(ele.left) q.push(ele.left)
                        if(ele.right) q.push(ele.right)
                       }
                       result.push(curr)
                      }
                      return result
                    };
                    
                    const t= new TreeNode(3)
                    t.left = new TreeNode(9)
                    t.right = new TreeNode(20)
                    t.right.left = new TreeNode(15)
                    t.right.right = new TreeNode(7)
                    levelOrder(t)`,
                    output: `[ [ 3 ], [ 9, 20 ], [ 15, 7 ] ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q103: {
        title: "Q103. Binary Tree Zigzag Level Order Traversal",
        content: (
          <>
            <Span>
              <b>Q103. Binary Tree Zigzag Level Order Traversal</b>
            </Span>
            <Span>
              Given the root of a binary tree, return the zigzag level order
              traversal of its nodes' values. (i.e., from left to right, then
              right to left for the next level and alternate between).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode103} left />
            <Span>
              Input: root = [3,9,20,null,null,15,7] <br />
              Output: [[3],[20,9],[15,7]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [1] <br />
              Output: [[1]]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = [] <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 2000].
              <br />
              -100 &lt;= Node.val &lt;= 100
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q103. Binary Tree Zigzag Level Order Traversal",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    /**
                     * @param {TreeNode} root
                     * @return {number[][]}
                     */
                    var zigzagLevelOrder = function(root) {
                      const q = []
                      const result = []
                      q.push(root)
                      let ltr = true
                      while(q.length && root){
                       let curr = []
                       const n = q.length
                       for(let i=n;i>0;i--){
                        if(ltr){
                        const ele = q.shift()
                        curr.push(ele.val)
                        if(ele.left) q.push(ele.left)
                        if(ele.right) q.push(ele.right)
                        }else{
                        const ele = q.pop()
                        curr.push(ele.val)
                        if(ele.right) q.unshift(ele.right)
                        if(ele.left) q.unshift(ele.left)
                        }
                       }
                       result.push(curr)
                       ltr = !ltr
                      }
                      return result  
                    };
                    
                    const t= new TreeNode(3)
                    t.left = new TreeNode(9)
                    t.right = new TreeNode(20)
                    t.right.left = new TreeNode(15)
                    t.right.right = new TreeNode(7)
                    zigzagLevelOrder(t)`,
                    output: `[ [ 3 ], [ 20, 9 ], [ 15, 7 ] ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q104: {
        title: "Q104. Maximum Depth of Binary Tree",
        content: (
          <>
            <Span>
              <b>Q104. Maximum Depth of Binary Tree</b>
            </Span>
            <Span>
              Given the root of a binary tree, return its maximum depth.
              <br />A binary tree's maximum depth is the number of nodes along
              the longest path from the root node down to the farthest leaf
              node.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode104} left />
            <Span>
              Input: root = [3,9,20,null,null,15,7]
              <br />
              Output: 3
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [1,null,2]
              <br />
              Output: 2
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 104]. <br />
              -100 &lt;= Node.val &lt;= 100
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q104. Maximum Depth of Binary Tree",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    /**
                     * @param {TreeNode} root
                     * @return {number}
                     */
                    var maxDepth = function(root) {
                    const q = []
                    q.push(root)
                    let height=0;
                    while(q.length && root){
                     let curr = []
                     let n = q.length
                     if(n===0) break;
                     height++
                     while(n>0){
                     const ele = q.shift()
                     curr.push(ele.val)
                     if(ele.left) q.push(ele.left)
                     if(ele.right) q.push(ele.right)
                     n--
                     }
                    }
                    return height
                    };
                    
                    const t= new TreeNode(3)
                    t.left = new TreeNode(9)
                    t.right = new TreeNode(20)
                    t.right.left = new TreeNode(15)
                    t.right.right = new TreeNode(7)
                    maxDepth(t)`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q105: {
        title:
          "Q105. Construct Binary Tree from Preorder and Inorder Traversal",
        content: (
          <>
            <Span>
              <b>
                Q105. Construct Binary Tree from Preorder and Inorder Traversal
              </b>
            </Span>
            <Span>
              Given two integer arrays preorder and inorder where preorder is
              the preorder traversal of a binary tree and inorder is the inorder
              traversal of the same tree, construct and return the binary tree.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode105} left />
            <Span>
              Input: preorder = [3,9,20,15,7],
              <br /> inorder = [9,3,15,20,7] <br />
              Output: [3,9,20,null,null,15,7]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: preorder = [-1], <br />
              inorder = [-1] <br />
              Output: [-1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= preorder.length &lt;= 3000 <br />
              inorder.length == preorder.length <br />
              -3000 &lt;= preorder[i], inorder[i] &lt;= 3000
              <br />
              preorder and inorder consist of unique values.
              <br />
              Each value of inorder also appears in preorder.
              <br />
              preorder is guaranteed to be the preorder traversal of the tree.
              <br />
              inorder is guaranteed to be the inorder traversal of the tree.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title:
                  "Q105. Construct Binary Tree from Preorder and Inorder Traversal",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    
                    /**
                     * @param {number[]} preorder
                     * @param {number[]} inorder
                     * @return {TreeNode}
                     */
                    
                    function construct(preorder,pL,pR,inorder,iL,iR,map){
                      if (pL > pR) return null;
                      const pE = preorder[pL]
                      const i = map.get(pE)
                      const curr = new TreeNode(pE)
                      curr.left = construct(preorder, pL + 1, pL + i - iL, inorder, iL, i - 1,map);
                      curr.right = construct(preorder, pL + i - iL + 1, pR, inorder, i + 1, iR, map);
                      return curr
                    }
                    
                    var buildTree = function(preorder, inorder) {
                      const map = new Map()
                      for(let i in inorder){
                        map.set(inorder[i],+i)
                      }
                      return construct(preorder,0,preorder.length-1,inorder,0,inorder.length-1,map);
                    };
                    
                    buildTree([3,9,20,15,7],[9,3,15,20,7])`,
                    output: `TreeNode {
                      val: 3,
                      left: TreeNode { val: 9, left: null, right: null },
                      right: TreeNode {
                        val: 20,
                        left: TreeNode { val: 15, left: null, right: null },
                        right: TreeNode { val: 7, left: null, right: null }
                      }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q106: {
        title:
          "Q106. Construct Binary Tree from Inorder and Postorder Traversal",
        content: (
          <>
            <Span>
              <b>
                Q106. Construct Binary Tree from Inorder and Postorder Traversal
              </b>
            </Span>
            <Span>
              Given two integer arrays inorder and postorder where inorder is
              the inorder traversal of a binary tree and postorder is the
              postorder traversal of the same tree, construct and return the
              binary tree.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode106} left />
            <Span>
              Input: inorder = [9,3,15,20,7],
              <br /> postorder = [9,15,7,20,3]
              <br />
              Output: [3,9,20,null,null,15,7]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: inorder = [-1],
              <br /> postorder = [-1]
              <br />
              Output: [-1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= inorder.length &lt;= 3000 <br />
              postorder.length == inorder.length <br />
              -3000 &lt;= inorder[i], postorder[i] &lt;= 3000 <br />
              inorder and postorder consist of unique values. <br />
              Each value of postorder also appears in inorder. <br />
              inorder is guaranteed to be the inorder traversal of the tree.
              <br />
              postorder is guaranteed to be the postorder traversal of the tree.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q106. Construct Binary Tree from Inorder and Post",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    
                    /**
                     * @param {number[]} inorder
                     * @param {number[]} postorder
                     * @return {TreeNode}
                     */
                    
                    function construct(inorder,iL,iR,postorder,pL,pR,map){
                      if (iL > iR) return null;
                      const pE = postorder[pR]
                      const i = map.get(pE)
                      const curr = new TreeNode(pE)
                      curr.left = construct(inorder, iL, i - 1, postorder, pL, pL + i - iL - 1,map);
                      curr.right = construct(inorder, i + 1, iR, postorder, pL + i - iL, pR - 1, map);
                      return curr
                    }
                    
                    var buildTree = function(inorder, postorder) {
                      const map = new Map()
                      for(let i in inorder){
                        map.set(inorder[i],+i)
                      }
                      return construct(inorder,0,inorder.length-1,postorder,0,postorder.length-1,map);
                    };
                    
                    buildTree([9,3,15,20,7],[9,15,7,20,3])`,
                    output: `TreeNode {
                      val: 3,
                      left: TreeNode { val: 9, left: null, right: null },
                      right: TreeNode {
                        val: 20,
                        left: TreeNode { val: 15, left: null, right: null },
                        right: TreeNode { val: 7, left: null, right: null }
                      }
                    }
                    `,
                  },
                },
              }}
            />
          </>
        ),
      },
      q107: {
        title: "Q107. Binary Tree Level Order Traversal II",
        content: (
          <>
            <Span>
              <b>Q107. Binary Tree Level Order Traversal II</b>
            </Span>
            <Span>
              Given the root of a binary tree, return the bottom-up level order
              traversal of its nodes' values. (i.e., from left to right, level
              by level from leaf to root).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode107} left />
            <Span>
              Input: root = [3,9,20,null,null,15,7] <br />
              Output: [[15,7],[9,20],[3]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [1] <br />
              Output: [[1]]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = []
              <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 2000].
              <br />
              -1000 &lt;= Node.val &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q107. Binary Tree Level Order Traversal II",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    
                    /**
                     * @param {TreeNode} root
                     * @return {number[][]}
                     */
                    var levelOrderBottom = function(root) {
                      if (!root) return [];
                      let q = [];
                      let ans = []
                      q.push(root);
                      while (q.length && root) {
                          let n = q.length;
                          const curr = []
                          for(let i=n;i>0;i--) {
                              let node = q.shift();
                              curr.push(node.val)
                              if (node.left)
                                  q.push(node.left);
                              if (node.right)
                                  q.push(node.right);
                          }
                          ans.push(curr)
                      }
                      ans.reverse()
                      return ans
                    };
                    
                    const t = new TreeNode(1)
                    t.left = new TreeNode(3)
                    t.right = new TreeNode(20)
                    t.right.left = new TreeNode(15)
                    t.right.right = new TreeNode(7)
                    levelOrderBottom(t)
                    `,
                    output: `[ [ 15, 7 ], [ 3, 20 ], [ 1 ] ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q108: {
        title: "Q108. Convert Sorted Array to Binary Search Tree",
        content: (
          <>
            <Span>
              <b>Q108. Convert Sorted Array to Binary Search Tree</b>
            </Span>
            <Span>
              Given an integer array nums where the elements are sorted in
              ascending order, convert it to a height-balanced binary search
              tree.
            </Span>
            <Span>
              A height-balanced binary tree is a binary tree in which the depth
              of the two subtrees of every node never differs by more than one.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode108} left />
            <Span>
              Input: nums = [-10,-3,0,5,9]
              <br />
              Output: [0,-3,9,-10,null,5]
              <br />
              Explanation: [0,-10,5,null,-3,null,9] is also accepted:
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1,3]
              <br />
              Output: [3,1]
              <br />
              Explanation: [1,3] and [3,1] are both a height-balanced BSTs.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 104 <br />
              -104 &lt;= nums[i] &lt;= 104 <br />
              nums is sorted in a strictly increasing order.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(log n)
            </p>
            <CodeEditor
              options={{
                title: "Q108. Convert Sorted Array to Binary Search Tree",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                  }
                  
                  /**
                   * @param {number[]} nums
                   * @return {TreeNode}
                   */
                  
                  function helper(nums,l,r){
                    if (l > r) return null;
                    const m = l + parseInt((r - l) / 2);
                    const root = new TreeNode(nums[m]);
                    root.left = helper(nums, l, m - 1);
                    root.right = helper(nums, m + 1, r);
                    return root;
                  }
                  
                  var sortedArrayToBST = function(nums) {
                    return helper(nums, 0, nums.length - 1);
                  };
                  
                  sortedArrayToBST([-10,-3,0,5,9])
                  `,
                    output: `TreeNode {
                    val: 0,
                    left: TreeNode {
                      val: -10,
                      left: null,
                      right: TreeNode { val: -3, left: null, right: null }
                    },
                    right: TreeNode {
                      val: 5,
                      left: null,
                      right: TreeNode { val: 9, left: null, right: null }
                    }
                  }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q109: {
        title: "Q109. Convert Sorted List to Binary Search Tree",
        content: (
          <>
            <Span>
              <b>Q109. Convert Sorted List to Binary Search Tree</b>
            </Span>
            <Span>
              Given the head of a singly linked list where elements are sorted
              in ascending order, convert it to a height balanced BST.
            </Span>
            <Span>
              For this problem, a height-balanced binary tree is defined as a
              binary tree in which the depth of the two subtrees of every node
              never differ by more than 1.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode109} left />
            <Span>
              Input: head = [-10,-3,0,5,9] <br />
              Output: [0,-3,9,-10,null,5] <br />
              Explanation: One possible answer is [0,-3,9,-10,null,5], which
              represents the shown height balanced BST.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = []
              <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in head is in the range [0, 2 * 104]. <br />
              -105 &lt;= Node.val &lt;= 105
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q109. Convert Sorted List to Binary Search Tree",
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                    }
                    
                    function TreeNode(val, left, right) {
                        this.val = (val===undefined ? 0 : val)
                        this.left = (left===undefined ? null : left)
                        this.right = (right===undefined ? null : right)
                    }
                    
                    /**
                     * @param {ListNode} head
                     * @return {TreeNode}
                     */
                    // O(n) , O(n)
                    
                    function helper(list,l,r){
                      if(l>r) return null
                      const m = l + parseInt((r - l) / 2);
                      const root = new TreeNode(list[m]);
                      root.left = helper(list, l, m - 1);
                      root.right = helper(list, m + 1, r);
                      return root
                    }
                    
                    var sortedListToBST = function(head) {
                      const list = [];
                      for (let curr = head; curr; curr = curr.next)
                      list.push(curr.val);
                      return helper(list, 0, list.length - 1);
                    };
                    
                    const l = new ListNode(-10)
                    l.next = new ListNode(-3)
                    l.next.next = new ListNode(0)
                    l.next.next.next = new ListNode(5)
                    l.next.next.next.next = new ListNode(9)
                    sortedListToBST(l)`,
                    output: `TreeNode {
                      val: 0,
                      left: TreeNode {
                        val: -10,
                        left: null,
                        right: TreeNode { val: -3, left: null, right: null }
                      },
                      right: TreeNode {
                        val: 5,
                        left: null,
                        right: TreeNode { val: 9, left: null, right: null }
                      }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q110: {
        title: "Q110. Balanced Binary Tree",
        content: (
          <>
            <Span>
              <b>Q110. Balanced Binary Tree</b>
            </Span>
            <Span>
              Given a binary tree, determine if it is height-balanced.
            </Span>
            <Span>
              For this problem, a height-balanced binary tree is defined as:
            </Span>
            <Span>
              a binary tree in which the left and right subtrees of every node
              differ in height by no more than 1.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode110} left />
            <Span>
              Input: root = [3,9,20,null,null,15,7] <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [1,2,2,3,3,null,null,4,4]
              <br />
              Output: false
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = []
              <br />
              Output: true
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 5000]. <br />
              -104 &lt;= Node.val &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.log n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q110. Balanced Binary Tree",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    
                    /**
                     * @param {TreeNode} root
                     * @return {boolean}
                     */
                    // O(nlog n)
                    
                    function maxDepth(root){
                      if(!root) return 0
                      return 1 + Math.max(maxDepth(root.left),maxDepth(root.right))
                    }
                    
                    var isBalanced = function(root) {
                      if(!root) return true
                      return Math.abs(maxDepth(root.left) - maxDepth(root.right)) <= 1 && isBalanced(root.left) && isBalanced(root.right);
                    };
                    
                    const t = new TreeNode(3)
                    t.left = new TreeNode(9)
                    t.right = new TreeNode(20)
                    t.right.left = new TreeNode(16)
                    t.right.right = new TreeNode(7)
                    isBalanced(t)`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q111: {
        title: "Q111. Minimum Depth of Binary Tree",
        content: (
          <>
            <Span>
              <b>Q111. Minimum Depth of Binary Tree</b>
            </Span>
            <Span>Given a binary tree, find its minimum depth.</Span>
            <Span>
              The minimum depth is the number of nodes along the shortest path
              from the root node down to the nearest leaf node.
            </Span>
            <Span>
              <b>Note: </b>A leaf is a node with no children.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode111} left />
            <Span>
              Input: root = [3,9,20,null,null,15,7] <br />
              Output: 2
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [2,null,3,null,4,null,5,null,6] <br />
              Output: 5
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 105].
              <br />
              -1000 &lt;= Node.val &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q111. Minimum Depth of Binary Tree",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                  }
                  
                  /**
                   * @param {TreeNode} root
                   * @return {number}
                   */
                  
                  function topDownDFS(root){
                    if (!root) return 0;
                    if (!root.left)
                      return topDownDFS(root.right) + 1;
                    if (!root.right)
                      return topDownDFS(root.left) + 1;
                    return Math.min(topDownDFS(root.left), topDownDFS(root.right)) + 1;
                  }
                  
                  function bottomUpBFS(root){
                    if (!root) return 0;
                    let minDepth = 0
                    const q = []
                    q.push(root)
                    while(q.length && root){
                      ++minDepth
                      let n = q.length;
                      for(let i=n;i>0;i--){
                      const node = q.shift()
                      if(!node.left && !node.right) return minDepth
                      if(node.left) q.push(node.left)
                      if(node.right) q.push(node.right)
                      }
                    }
                  }
                  
                  var minDepth = function(root) {
                      return bottomUpBFS(root)
                  };
                  
                  const t = new TreeNode(3)
                  t.left = new TreeNode(9)
                  t.right= new TreeNode(20)
                  t.right.left = new TreeNode(15)
                  t.right.right = new TreeNode(7)
                  minDepth(t)
                  `,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q112: {
        title: "Q112. Path Sum",
        content: (
          <>
            <Span>
              <b>Q112. Path Sum</b>
            </Span>
            <Span>
              Given the root of a binary tree and an integer targetSum, return
              true if the tree has a root-to-leaf path such that adding up all
              the values along the path equals targetSum.
            </Span>
            <Span>A leaf is a node with no children.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode112} left />
            <Span>
              Input: root = [5,4,8,11,null,13,4,7,2,null,null,null,1], targetSum
              = 22 <br />
              Output: true <br />
              Explanation: The root-to-leaf path with the target sum is shown.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [1,2,3], targetSum = 5 <br />
              Output: false
              <br />
              Explanation: There two root-to-leaf paths in the tree:
              <br />
              (1 --&gt; 2): The sum is 3.
              <br />
              (1 --&gt; 3): The sum is 4.
              <br />
              There is no root-to-leaf path with sum = 5.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = [], targetSum = 0 <br />
              Output: false <br />
              Explanation: Since the tree is empty, there are no root-to-leaf
              paths.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 5000]. <br />
              -1000 &lt;= Node.val &lt;= 1000
              <br />
              -1000 &lt;= targetSum &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q112. Path Sum",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                  }
                  /**
                   * @param {TreeNode} root
                   * @param {number} targetSum
                   * @return {boolean}
                   */
                  var hasPathSum = function(root, targetSum) {
                    if (!root) return false;
                    if (root.val == targetSum && !root.left && !root.right)
                    return true;
                    return hasPathSum(root.left, targetSum - root.val) ||
                             hasPathSum(root.right, targetSum - root.val);
                  };
                  
                  const t = new TreeNode(1)
                  t.left = new TreeNode(2)
                  t.right = new TreeNode(3)
                  hasPathSum(t,5)`,
                    output: `false`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q113: {
        title: "Q113. Path Sum II",
        content: (
          <>
            <Span>
              <b>Q113. Path Sum II</b>
            </Span>
            <Span>
              Given the root of a binary tree and an integer targetSum, return
              all root-to-leaf paths where the sum of the node values in the
              path equals targetSum. Each path should be returned as a list of
              the node values, not node references.
            </Span>
            <Span>
              A root-to-leaf path is a path starting from the root and ending at
              any leaf node. A leaf is a node with no children.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode113} left />
            <Span>
              Input: root = [5,4,8,11,null,13,4,7,2,null,null,5,1], targetSum =
              22 <br />
              Output: [[5,4,11,2],[5,8,4,5]]
              <br />
              Explanation: There are two paths whose sum equals targetSum:
              <br />
              5 + 4 + 11 + 2 = 22
              <br />5 + 8 + 4 + 5 = 22
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [1,2,3], targetSum = 5 <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = [1,2], <br />
              targetSum = 0<br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 5000]. <br />
              -1000 &lt;= Node.val &lt;= 1000 <br />
              -1000 &lt;= targetSum &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.log n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q113. Path Sum II",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                  }
                  /**
                   * @param {TreeNode} root
                   * @param {number} targetSum
                   * @return {boolean}
                   */
                  
                  function dfs(root,sum,result,curr=[]){
                    if(!root) return
                    if (root.val == sum && !root.left && !root.right) {
                    curr.push(root.val);
                    console.log(curr)
                    result.push(curr.slice());
                    curr.pop();
                    return;
                    }
                    curr.push(root.val);
                    dfs(root.left, sum - root.val, result, curr);
                    dfs(root.right, sum - root.val, result, curr);
                    curr.pop();
                  }
                  
                  var pathSum = function(root, targetSum) {
                   const result = []
                   dfs(root,targetSum,result)
                   return result
                  };
                  
                  const t = new TreeNode(1)
                  t.left = new TreeNode(2)
                  t.right = new TreeNode(3)
                  pathSum(t,5)`,
                    output: `[]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q114: {
        title: "Q114. Flatten Binary Tree to Linked List",
        content: (
          <>
            <Span>
              <b>Q114. Flatten Binary Tree to Linked List</b>
            </Span>
            <Span>
              Given the root of a binary tree, flatten the tree into a "linked
              list":
            </Span>
            <Span>
              The "linked list" should use the same TreeNode class where the
              right child pointer points to the next node in the list and the
              left child pointer is always null.
            </Span>
            <Span>
              The "linked list" should be in the same order as a pre-order
              traversal of the binary tree.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode114} left />
            <Span>
              Input: root = [1,2,5,3,4,null,6] <br />
              Output: [1,null,2,null,3,null,4,null,5,null,6]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [] <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = [0] <br />
              Output: [0]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 2000]. <br />
              -100 &lt;= Node.val &lt;= 100
            </Span>
            <Span>
              <b>Follow up: </b>
              Can you flatten the tree in-place (with O(1) extra space)?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q114. Flatten Binary Tree to Linked List",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                  }
                  /**
                   * @param {TreeNode} root
                   * @return {void} Do not return anything, modify root in-place instead.
                   */
                  
                  var flatten = function(root) {
                     if(!root) return 
                     const stack = []
                     stack.push(root)
                     while(stack.length && root){
                      root = stack.pop()
                      if(root.right)
                      stack.push(root.right);
                      if (root.left)
                      stack.push(root.left);
                      if (stack.length)
                      root.right = stack[stack.length-1];
                      root.left = null;
                     }
                  };
                  
                  const t = new TreeNode(2)
                  t.left = new TreeNode(1)
                  t.right = new TreeNode(3)
                  flatten(t)
                  console.log(t)`,
                    output: `TreeNode {
                    val: 2,
                    left: null,
                    right: TreeNode {
                      val: 1,
                      left: null,
                      right: TreeNode { val: 3, left: null, right: null }
                    }
                  }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q115: {
        title: "Q115. Distinct Subsequences",
        content: (
          <>
            <Span>
              <b>Q115. Distinct Subsequences</b>
            </Span>
            <Span>
              Given two strings s and t, return the number of distinct
              subsequences of s which equals t.
            </Span>
            <Span>
              A string's subsequence is a new string formed from the original
              string by deleting some (can be none) of the characters without
              disturbing the remaining characters' relative positions. (i.e.,
              "ACE" is a subsequence of "ABCDE" while "AEC" is not).
            </Span>
            <Span>
              The test cases are generated so that the answer fits on a 32-bit
              signed integer.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "rabbbit", t = "rabbit" <br />
              Output: 3<br />
              Explanation:
              <br />
              As shown below, there are 3 ways you can generate "rabbit" from S.
              <br />
              [rabb]b[it]
              <br />
              [ra]b[bbit]
              <br />
              [rab]b[bit]
              <br />
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "babgbag", t = "bag"
              <br />
              Output: 5<br />
              Explanation:
              <br />
              As shown below, there are 5 ways you can generate "bag" from S.
              <br />
              babgbag
              <br />
              babgbag
              <br />
              babgbag
              <br />
              babgbag
              <br />
              babgbag
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length, t.length &lt;= 1000 s and t consist of English
              letters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q115. Distinct Subsequences",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @param {string} t
                    * @return {number}
                    */
                   var numDistinct = function(s, t) {
                     const m = s.length
                     const n = t.length
                     const dp = Array(n+1).fill(0)
                     dp[0] = 1
                     for (let i = 1; i <= m; ++i)
                     for (let j = n; j >= 1; --j)
                       if (s[i - 1] == t[j - 1])
                         dp[j] += dp[j - 1];
                     console.log(dp)
                     return dp[n];
                   };
                   
                   numDistinct("rabbbit", "rabbit")`,
                    output: `[
                    1, 1, 1, 3,
                    3, 3, 3
                  ]
                  3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q116: {
        title: "Q116. Populating Next Right Pointers in Each Node",
        content: (
          <>
            <Span>
              <b>Q116. Populating Next Right Pointers in Each Node</b>
            </Span>
            <Span>
              You are given a perfect binary tree where all leaves are on the
              same level, and every parent has two children. The binary tree has
              the following definition:
            </Span>
            <Span>
              struct Node {"{"}
              int val; <br />
              Node *left; <br />
              Node *right; <br />
              Node *next; <br />
              {"}"}
            </Span>
            <Span>
              Populate each next pointer to point to its next right node. If
              there is no next right node, the next pointer should be set to
              NULL.
            </Span>
            <Span>Initially, all next pointers are set to NULL.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode116} left />
            <Span>
              Input: root = [1,2,3,4,5,6,7] <br />
              Output: [1,#,2,3,#,4,5,6,7,#] <br />
              Explanation: Given the above perfect binary tree (Figure A), your
              function should populate each next pointer to point to its next
              right node, just like in Figure B. The serialized output is in
              level order as connected by the next pointers, with '#' signifying
              the end of each level.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [] <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 212 - 1].{" "}
              <br />
              -1000 &lt;= Node.val &lt;= 1000
            </Span>
            <Span>
              You may only use constant extra space.
              <br />
              The recursive approach is fine. You may assume implicit stack
              space does not count as extra space for this problem.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q116. Populating Next Right Pointers in Each Node",
                codes: {
                  Javascript: {
                    code: `function Node(val, left, right, next) {
                      this.val = val === undefined ? null : val;
                      this.left = left === undefined ? null : left;
                      this.right = right === undefined ? null : right;
                      this.next = next === undefined ? null : next;
                   };
                   
                   /**
                    * @param {Node} root
                    * @return {Node}
                    */
                   var connect = function(root) {
                     let node = root
                     while(node && node.left){
                      const temp = new Node()
                      for(let n = temp;node;node=node.next){
                        n.next = node.left
                        n = n.next
                        n.next = node.right
                        n = n.next
                      }
                      node = temp.next
                     }
                     return root
                   };
                   
                   const t = new Node(1)
                   t.left = new Node(2)
                   t.left.left = new Node(4)
                   t.left.right = new Node(5)
                   t.right = new Node(3)
                   t.right.left = new Node(6)
                   t.right.right = new Node(7)
                   connect(t)`,
                    output: `Node {
                    val: 1,
                    left: Node {
                      val: 2,
                      left: Node { val: 4, left: null, right: null, next: [Node] },
                      right: Node { val: 5, left: null, right: null, next: [Node] },
                      next: Node { val: 3, left: [Node], right: [Node], next: null }
                    },
                    right: Node {
                      val: 3,
                      left: Node { val: 6, left: null, right: null, next: [Node] },
                      right: Node { val: 7, left: null, right: null, next: null },
                      next: null
                    },
                    next: null
                  }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q117: {
        title: "Q117. Populating Next Right Pointers in Each Node II",
        content: (
          <>
            <Span>
              <b>Q117. Populating Next Right Pointers in Each Node II</b>
            </Span>
            <Span>Given a binary tree</Span>

            <Span>
              struct Node {"{"} <br />
              int val; <br />
              Node *left; <br />
              Node *right; <br />
              Node *next;
              {"}"}
            </Span>
            <Span>
              Populate each next pointer to point to its next right node. If
              there is no next right node, the next pointer should be set to
              NULL.
            </Span>
            <Span>Initially, all next pointers are set to NULL.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode117} left />
            <Span>
              Input: root = [1,2,3,4,5,null,7]
              <br />
              Output: [1,#,2,3,#,4,5,7,#] <br />
              Explanation: Given the above binary tree (Figure A), your function
              should populate each next pointer to point to its next right node,
              just like in Figure B. The serialized output is in level order as
              connected by the next pointers, with '#' signifying the end of
              each level.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [] <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 6000]. <br />
              -100 &lt;= Node.val &lt;= 100
            </Span>
            <Span>
              <b>Follow-up: </b>
              You may only use constant extra space. <br />
              The recursive approach is fine. You may assume implicit stack
              space does not count as extra space for this problem.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q117. Populating Next Right Pointers in Each Node II",
                codes: {
                  Javascript: {
                    code: `function Node(val, left, right, next) {
                      this.val = val === undefined ? null : val;
                      this.left = left === undefined ? null : left;
                      this.right = right === undefined ? null : right;
                      this.next = next === undefined ? null : next;
                   };
                   
                   /**
                    * @param {Node} root
                    * @return {Node}
                    */
                   var connect = function(root) {
                     let node = root
                     while(node){
                      const temp = new Node()
                      for(let n=temp;node;node=node.next){
                        if(node.left){
                           n.next = node.left
                           n = n.next
                        }
                        if(node.right){
                           n.next = node.right
                           n = n.next
                        }
                      }
                      node = temp.next
                     }
                     return root
                   };
                   
                   const t = new Node(1)
                   t.left = new Node(2)
                   t.left.left = new Node(4)
                   t.left.right = new Node(5)
                   t.right = new Node(3)
                   t.right.left = new Node(6)
                   t.right.right = new Node(7)
                   connect(t)`,
                    output: `Node {
                    val: 1,
                    left: Node {
                      val: 2,
                      left: Node { val: 4, left: null, right: null, next: [Node] },
                      right: Node { val: 5, left: null, right: null, next: [Node] },
                      next: Node { val: 3, left: [Node], right: [Node], next: null }
                    },
                    right: Node {
                      val: 3,
                      left: Node { val: 6, left: null, right: null, next: [Node] },
                      right: Node { val: 7, left: null, right: null, next: null },
                      next: null
                    },
                    next: null
                  }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q118: {
        title: "Q118. Pascal's Triangle",
        content: (
          <>
            <Span>
              <b>Q118. Pascal's Triangle</b>
            </Span>
            <Span>
              Given an integer numRows, return the first numRows of Pascal's
              triangle.
            </Span>
            <Span>
              In Pascal's triangle, each number is the sum of the two numbers
              directly above it as shown:
            </Span>
            <Img src={Leetcode118} left />
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: numRows = 5 <br />
              Output: [[1],[1,1],[1,2,1],[1,3,3,1],[1,4,6,4,1]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: numRows = 1 <br />
              Output: [[1]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= numRows &lt;= 30</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(n^2)
            </p>
            <CodeEditor
              options={{
                title: "Q118. Pascal's Triangle",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} numRows
                    * @return {number[][]}
                    */
                   var generate = function(numRows) {
                     const result = []
                     for(let i=0;i<numRows;i++)
                     result.push(Array(i+1).fill(1))
                     for (let i = 2; i < numRows; ++i)
                     for (let j = 1; j < result[i].length - 1; ++j)
                     result[i][j] = result[i - 1][j - 1] + result[i - 1][j]
                     return result  
                   };
                   
                   generate(9)`,
                    output: `[
                    [ 1 ],
                    [ 1, 1 ],
                    [ 1, 2, 1 ],
                    [ 1, 3, 3, 1 ],
                    [ 1, 4, 6, 4, 1 ],
                    [ 1, 5, 10, 10, 5, 1 ],
                    [
                       1, 6, 15, 20,
                      15, 6,  1
                    ],
                    [
                       1,  7, 21, 35,
                      35, 21,  7,  1
                    ],
                    [
                       1,  8, 28, 56, 70,
                      56, 28,  8,  1
                    ]
                  ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q119: {
        title: "Q119. Pascal's Triangle II",
        content: (
          <>
            <Span>
              <b>Q119. Pascal's Triangle II</b>
            </Span>
            <Span>
              Given an integer rowIndex, return the rowIndexth (0-indexed) row
              of the Pascal's triangle.
            </Span>
            <Span>
              In Pascal's triangle, each number is the sum of the two numbers
              directly above it as shown:
            </Span>
            <Img src={Leetcode118} left />
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: rowIndex = 3 <br />
              Output: [1,3,3,1]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: rowIndex = 0 <br />
              Output: [1]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: rowIndex = 1 <br />
              Output: [1,1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>0 &lt;= rowIndex &lt;= 33</Span>
            <Span>
              <b>Follow up: </b>
              Could you optimize your algorithm to use only O(rowIndex) extra
              space?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q119. Pascal's Triangle II",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} rowIndex
                    * @return {number[]}
                    */
                   var getRow = function(rowIndex) {
                     const result = Array(rowIndex+1).fill(1)    
                     for (let i = 2; i < rowIndex + 1; ++i)
                       for (let j = 1; j < i; ++j)
                         result[i - j] += result[i - j - 1];
                     return result  
                   };
                   
                   getRow(3)`,
                    output: `[1,3,3,1]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q120: {
        title: "Q120. Triangle",
        content: (
          <>
            <Span>
              <b>Q120. Triangle</b>
            </Span>
            <Span>
              Given a triangle array, return the minimum path sum from top to
              bottom.
            </Span>
            <Span>
              For each step, you may move to an adjacent number of the row
              below. More formally, if you are on index i on the current row,
              you may move to either index i or index i + 1 on the next row.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: triangle = [[2],[3,4],[6,5,7],[4,1,8,3]] <br />
              Output: 11 <br />
              Explanation: The triangle looks like: <br />
              2 <br />
              3 4<br />
              6 5 7<br />
              4 1 8 3<br />
              The minimum path sum from top to bottom is 2 + 3 + 5 + 1 = 11
              (underlined above).
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: triangle = [[-10]] <br />
              Output: -10
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= triangle.length &lt;= 200 <br />
              triangle[0].length == 1 <br />
              triangle[i].length == triangle[i - 1].length + 1 <br />
              -104 &lt;= triangle[i][j] &lt;= 104
            </Span>
            <Span>
              <b>Follow up: </b>
              Could you do this using only O(n) extra space, where n is the
              total number of rows in the triangle?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q120. Triangle",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[][]} triangle
                    * @return {number}
                    */
                   var minimumTotal = function(triangle) {
                     for (let i = triangle.length - 2; i >= 0; --i)
                     for (let j = 0; j <= i; ++j)
                     triangle[i][j] += Math.min(triangle[i + 1][j],
                                                 triangle[i + 1][j + 1])
                     return triangle[0][0];
                   };
                   
                   minimumTotal([[2],[3,4],[6,5,7],[4,1,8,3]])`,
                    output: `11`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q121: {
        title: "Q121. Best Time to Buy and Sell Stock",
        content: (
          <>
            <Span>
              <b>Q121. Best Time to Buy and Sell Stock</b>
            </Span>
            <Span>
              You are given an array prices where prices[i] is the price of a
              given stock on the ith day.
            </Span>
            <Span>
              You want to maximize your profit by choosing a single day to buy
              one stock and choosing a different day in the future to sell that
              stock.
            </Span>
            <Span>
              Return the maximum profit you can achieve from this transaction.
              If you cannot achieve any profit, return 0.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: prices = [7,1,5,3,6,4] <br />
              Output: 5 <br />
              Explanation: Buy on day 2 (price = 1) and sell on day 5 (price =
              6), profit = 6-1 = 5. Note that buying on day 2 and selling on day
              1 is not allowed because you must buy before you sell.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: prices = [7,6,4,3,1] <br />
              Output: 0 <br />
              Explanation: In this case, no transactions are done and the max
              profit = 0.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= prices.length &lt;= 105 <br />0 &lt;= prices[i] &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} prices
                    * @return {number}
                    */
                   var maxProfit = function(prices) {
                     let i = 0
                     let j = Number.MIN_SAFE_INTEGER
                     for(let p of prices){
                       i = Math.max(i,j+p)
                       j = Math.max(j,-p)
                     }
                     return i
                   };
                   maxProfit([7,1,5,3,6,4])`,
                    output: `5`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q122: {
        title: "Q122. Best Time to Buy and Sell Stock II",
        content: (
          <>
            <Span>
              <b>Q122. Best Time to Buy and Sell Stock II</b>
            </Span>
            <Span>
              You are given an integer array prices where prices[i] is the price
              of a given stock on the ith day.
            </Span>
            <Span>
              On each day, you may decide to buy and/or sell the stock. You can
              only hold at most one share of the stock at any time. However, you
              can buy it then immediately sell it on the same day
            </Span>
            <Span>Find and return the maximum profit you can achieve.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: prices = [7,1,5,3,6,4] <br />
              Output: 7 <br />
              Explanation: Buy on day 2 (price = 1) and sell on day 3 (price =
              5), profit = 5-1 = 4. Then buy on day 4 (price = 3) and sell on
              day 5 (price = 6), profit = 6-3 = 3. <br />
              Total profit is 4 + 3 = 7.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: prices = [1,2,3,4,5]
              <br />
              Output: 4<br />
              Explanation: Buy on day 1 (price = 1) and sell on day 5 (price =
              5), profit = 5-1 = 4.
              <br />
              Total profit is 4.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: prices = [7,6,4,3,1]
              <br /> Output: 0<br /> Explanation: There is no way to make a
              positive profit, so we never buy the stock to achieve the maximum
              profit of 0.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= prices.length &lt;= 3 * 104
              <br />0 &lt;= prices[i] &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q122. Best Time to Buy and Sell Stock II",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} prices
                    * @return {number}
                    */
                   var maxProfit = function(prices) {
                     let i = 0;
                     let j = Number.MIN_SAFE_INTEGER;
                     for (let p of prices) {
                       i = Math.max(i, j + p);
                       j = Math.max(j, i - p);
                     }
                     return i;    
                   };
                   
                   maxProfit([7,6,4,3,1])`,
                    output: `0`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q123: {
        title: "Q123. Best Time to Buy and Sell Stock III",
        content: (
          <>
            <Span>
              <b>Q123. Best Time to Buy and Sell Stock III</b>
            </Span>
            <Span>
              You are given an array prices where prices[i] is the price of a
              given stock on the ith day.
            </Span>
            <Span>
              Find the maximum profit you can achieve. You may complete at most
              two transactions.
            </Span>
            <Span>
              Note: You may not engage in multiple transactions simultaneously
              (i.e., you must sell the stock before you buy again).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: prices = [3,3,5,0,0,3,1,4] <br />
              Output: 6 <br />
              Explanation: Buy on day 4 (price = 0) and sell on day 6 (price =
              3), profit = 3-0 = 3. Then buy on day 7 (price = 1) and sell on
              day 8 (price = 4), profit = 4-1 = 3.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: prices = [1,2,3,4,5] <br />
              Output: 4<br />
              Explanation: Buy on day 1 (price = 1) and sell on day 5 (price =
              5), profit = 5-1 = 4.
              <br />
              Note that you cannot buy on day 1, buy on day 2 and sell them
              later, as you are engaging multiple transactions at the same time.
              You must sell before buying again.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: prices = [7,6,4,3,1] <br />
              Output: 0 <br />
              Explanation: In this case, no transaction is done, i.e. max profit
              = 0.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <p>
              1 &lt;= prices.length &lt;= 105 <br />0 &lt;= prices[i] &lt;= 105
            </p>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q123. Best Time to Buy and Sell Stock III",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} prices
                    * @return {number}
                    */
                   var maxProfit = function(prices) {
                     let sellTwo = 0;
                     let holdTwo = Number.MIN_SAFE_INTEGER;
                     let sellOne = 0;
                     let holdOne = Number.MIN_SAFE_INTEGER;
                     for (let price of prices) {
                       sellTwo = Math.max(sellTwo, holdTwo + price);
                       holdTwo = Math.max(holdTwo, sellOne - price);
                       sellOne = Math.max(sellOne, holdOne + price);
                       holdOne = Math.max(holdOne, -price);
                     }
                     return sellTwo;
                   };
                   
                   maxProfit([7,6,4,3,1])`,
                    output: `0`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q124: {
        title: "Q124. Binary Tree Maximum Path Sum",
        content: (
          <>
            <Span>
              <b>Q124. Binary Tree Maximum Path Sum</b>
            </Span>
            <Span>
              A path in a binary tree is a sequence of nodes where each pair of
              adjacent nodes in the sequence has an edge connecting them. A node
              can only appear in the sequence at most once. Note that the path
              does not need to pass through the root.
            </Span>
            <Span>
              The path sum of a path is the sum of the node's values in the
              path.
            </Span>
            <Span>
              Given the root of a binary tree, return the maximum path sum of
              any non-empty path.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode124a} left />
            <Span>
              Input: root = [1,2,3] <br />
              Output: 6 <br />
              Explanation: The optimal path is 2 -&gt; 1 -&gt; 3 with a path sum
              of 2 + 1 + 3 = 6.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Img src={Leetcode124b} left />
            <Span>
              Input: root = [-10,9,20,null,null,15,7] <br />
              Output: 42 <br />
              Explanation: The optimal path is 15 -&gt; 20 -&gt; 7 with a path
              sum of 15 + 20 + 7 = 42.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [1, 3 * 104].{" "}
              <br />
              -1000 &lt;= Node.val &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q124. Binary Tree Maximum Path Sum",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    
                    /**
                     * @param {TreeNode} root
                     * @return {number}
                     */
                    var maxPathSum = function(root) {
                      const res = { output : Number.MIN_SAFE_INTEGER }
                      maxPathSumDownFrom(root,res)
                      return res.output
                    };
                    
                    function maxPathSumDownFrom(root,res){
                      if (!root) return 0;
                      let l = Math.max(maxPathSumDownFrom(root.left,res), 0);
                      let r = Math.max(maxPathSumDownFrom(root.right,res), 0);
                      res.output = Math.max(res.output, root.val + l + r);
                      return root.val + Math.max(l, r);
                    };
                    
                    const t = new TreeNode(1)
                    t.left = new TreeNode(2)
                    t.right = new TreeNode(3)
                    maxPathSum(t)`,
                    output: `6`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q125: {
        title: "Q125. Valid Palindrome",
        content: (
          <>
            <Span>
              <b>Q125. Valid Palindrome</b>
            </Span>
            <Span>
              A phrase is a palindrome if, after converting all uppercase
              letters into lowercase letters and removing all non-alphanumeric
              characters, it reads the same forward and backward. Alphanumeric
              characters include letters and numbers.
            </Span>
            <Span>
              Given a string s, return true if it is a palindrome, or false
              otherwise.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "A man, a plan, a canal: Panama"
              <br />
              Output: true
              <br />
              Explanation: "amanaplanacanalpanama" is a palindrome.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "race a car" <br />
              Output: false <br />
              Explanation: "raceacar" is not a palindrome.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = " "<br />
              Output: true
              <br />
              Explanation: s is an empty string "" after removing
              non-alphanumeric characters. Since an empty string reads the same
              forward and backward, it is a palindrome.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 2 * 105 <br />s consists only of printable
              ASCII characters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {boolean}
                    */
                   
                   function isalnum(str) {
                     var code, i, len;
                   
                     for (i = 0, len = str.length; i < len; i++) {
                       code = str.charCodeAt(i);
                       if (!(code > 47 && code < 58) && //  (0-9)
                           !(code > 64 && code < 91) && //  (A-Z)
                           !(code > 96 && code < 123)) { // (a-z)
                         return false;
                       }
                     }
                     return true;
                   };
                   // string.match(/^[0-9a-z]+$/)
                   
                   var isPalindrome = function(s) {
                     let l = 0
                     let r = s.length - 1
                     while (l < r) {
                       while (l < r && !isalnum(s[l]))
                         ++l;
                       while (l < r && !isalnum(s[r]))
                         --r;
                       if (s[l].toLowerCase() != s[r].toLowerCase())
                         return false;
                       ++l;
                       --r;
                     }  
                     return true   
                   };
                   
                   isPalindrome("A man, a plan, a canal: Panama")`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q126: {
        title: "Q126. Word Ladder II",
        content: (
          <>
            <Span>
              <b>Q126. Word Ladder II</b>
            </Span>
            <Span>
              A transformation sequence from word beginWord to word endWord
              using a dictionary wordList is a sequence of words beginWord -&gt;
              s1 -&gt; s2 -&gt; ... -&gt; sk such that:
            </Span>
            <Span>
              Every adjacent pair of words differs by a single letter. <br />
              Every si for 1 &lt;= i &lt;= k is in wordList. Note that beginWord
              does not need to be in wordList. <br />
              sk == endWord
            </Span>
            <Span>
              Given two words, beginWord and endWord, and a dictionary wordList,
              return all the shortest transformation sequences from beginWord to
              endWord, or an empty list if no such sequence exists. Each
              sequence should be returned as a list -of the words [beginWord,
              s1, s2, ..., sk].
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: beginWord = "hit", <br />
              endWord = "cog", <br />
              wordList = ["hot","dot","dog","lot","log","cog"]
              <br />
              Output:
              [["hit","hot","dot","dog","cog"],["hit","hot","lot","log","cog"]]
              <br />
              Explanation: There are 2 shortest transformation sequences:
              <br />
              "hit" -&gt; "hot" -&gt; "dot" -&gt; "dog" -&gt; "cog"
              <br />
              "hit" -&gt; "hot" -&gt; "lot" -&gt; "log" -&gt; "cog"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: beginWord = "hit",
              <br /> endWord = "cog",
              <br /> wordList = ["hot","dot","dog","lot","log"]
              <br />
              Output: []
              <br />
              Explanation: The endWord "cog" is not in wordList, therefore there
              is no valid transformation sequence.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= beginWord.length &lt;= 5 <br />
              endWord.length == beginWord.length
              <br />
              1 &lt;= wordList.length &lt;= 1000
              <br />
              wordList[i].length == beginWord.length
              <br />
              beginWord, endWord, and wordList[i] consist of lowercase English
              letters. beginWord != endWord
              <br />
              All the words in wordList are unique.
              <br />
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.26^n)
              <br />
              Space: O(n^2)
            </p>
            <CodeEditor
              options={{
                codes: {
                  Javas: {
                    code: `import java.util.*;

                    class Main {
                      public List<List<String>> findLadders(String beginWord, String endWord, List<String> wordList) {
                        Set<String> wordSet = new HashSet<>(wordList);
                        if (!wordSet.contains(endWord))
                          return new ArrayList<>();
                    
                        Map<String, List<String>> parentToChildren = new HashMap<>();
                        Set<String> currentLevelWords = new HashSet<>();
                        currentLevelWords.add(beginWord);
                        boolean isFound = false;
                    
                        while (!currentLevelWords.isEmpty()) {
                          for (final String word : currentLevelWords)
                            wordSet.remove(word);
                          Set<String> nextLevelWords = new HashSet<>();
                          for (final String parent : currentLevelWords) {
                            StringBuilder sb = new StringBuilder(parent);
                            for (int i = 0; i < sb.length(); ++i) {
                              final char cache = sb.charAt(i);
                              for (char c = 'a'; c <= 'z'; ++c) {
                                sb.setCharAt(i, c);
                                final String child = sb.toString();
                                if (wordSet.contains(child)) {
                                  if (child.equals(endWord))
                                    isFound = true;
                                  nextLevelWords.add(child);
                                  parentToChildren.computeIfAbsent(parent, k -> new ArrayList<>()).add(child);
                                }
                              }
                              sb.setCharAt(i, cache);
                            }
                            currentLevelWords = nextLevelWords;
                          }
                          if (isFound)
                            break;
                        }
                    
                        if (!isFound)
                          return new ArrayList<>();
                    
                        List<List<String>> ans = new ArrayList<>();
                        List<String> path = new ArrayList<>(Arrays.asList(beginWord));
                    
                        dfs(parentToChildren, beginWord, endWord, path, ans);
                    
                        return ans;
                      }
                    
                      private void dfs(Map<String, List<String>> parentToChildren, final String word,
                                       final String endWord, List<String> path, List<List<String>> ans) {
                        if (word.equals(endWord)) {
                          ans.add(new ArrayList<>(path));
                          return;
                        }
                        if (!parentToChildren.containsKey(word))
                          return;
                    
                        for (final String child : parentToChildren.get(word)) {
                          path.add(child);
                          dfs(parentToChildren, child, endWord, path, ans);
                          path.remove(path.size() - 1);
                        }
                      }
                    
                      public static void main(String ...s){
                        Main m = new Main();
                        System.out.println(m.findLadders("hit","cog",Arrays.asList("hot","dot","dog","lot","log","cog")));
                      }
                    
                    }                    
                    `,
                    output: `[[hit, hot, dot, dog, cog], [hit, hot, lot, log, cog]]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q127: {
        title: "Q127. Word Ladder",
        content: (
          <>
            <Span>
              <b>Q127. Word Ladder</b>
            </Span>
            <Span>
              A transformation sequence from word beginWord to word endWord
              using a dictionary wordList is a sequence of words beginWord -&gt;
              s1 -&gt; s2 -&gt; ... -&gt; sk such that:
            </Span>
            <Span>
              Every adjacent pair of words differs by a single letter. <br />
              Every si for 1 &lt;= i &lt;= k is in wordList. Note that beginWord
              does not need to be in wordList. <br />
              sk == endWord
            </Span>
            <Span>
              Given two words, beginWord and endWord, and a dictionary wordList,
              return the number of words in the shortest transformation sequence
              from beginWord to endWord, or 0 if no such sequence exists.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: beginWord = "hit", endWord = "cog", wordList =
              ["hot","dot","dog","lot","log","cog"]
              <br />
              Output: 5<br />
              Explanation: One shortest transformation sequence is "hit" -&gt;
              "hot" -&gt; "dot" -&gt; "dog" -&gt; cog", which is 5 words long.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: beginWord = "hit", <br />
              endWord = "cog" <br /> wordList = ["hot","dot","dog","lot","log"]{" "}
              <br />
              Output: 0 <br />
              Explanation: The endWord "cog" is not in wordList, therefore there
              is no valid transformation sequence.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= beginWord.length &lt;= 10 <br />
              endWord.length == beginWord.length <br />
              1 &lt;= wordList.length &lt;= 5000 <br />
              wordList[i].length == beginWord.length <br />
              beginWord, endWord, and wordList[i] consist of lowercase English
              letters. beginWord != endWord <br />
              All the words in wordList are unique.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n26^n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q127. Word Ladder",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} beginWord
                    * @param {string} endWord
                    * @param {string[]} wordList
                    * @return {number}
                    */
                   var ladderLength = function(beginWord, endWord, wordList) {
                     const wordSet = new Set(wordList)
                     if(!wordSet.has(endWord))
                     return 0
                     let ans = 0
                     const q = []
                     q.push(beginWord)
                     while (q.length) {
                       ++ans;
                       for (let size = q.length; size > 0; --size) {
                         const sb = q.shift().split("")
                         for (let i = 0; i < sb.length; ++i) {
                           const cache = sb[i];
                           for (let c = 'a'.charCodeAt(0); c <= 'z'.charCodeAt(0); ++c) {
                             sb[i] = String.fromCharCode(c);
                             const word = sb.join("");
                             if (word == endWord)
                               return ans + 1;
                             if (wordSet.has(word)){
                               q.push(word);
                               wordSet.delete(word);
                             }
                           }
                           sb[i] = cache
                         }
                       }
                     }
                     return 0
                   };
                   
                   ladderLength("hit","cog",["hot","dot","dog","lot","log","cog"])`,
                    output: `5`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q128: {
        title: "Q128. Longest Consecutive Sequence",
        content: (
          <>
            <Span>
              <b>Q128. Longest Consecutive Sequence</b>
            </Span>
            <Span>
              Given an unsorted array of integers nums, return the length of the
              longest consecutive elements sequence.
            </Span>
            <Span>You must write an algorithm that runs in O(n) time.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [100,4,200,1,3,2] <br />
              Output: 4<br />
              Explanation: The longest consecutive elements sequence is [1, 2,
              3, 4]. Therefore its length is 4.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0,3,7,2,5,8,4,6,0,1]
              <br />
              Output: 9
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= nums.length &lt;= 105 <br />
              -109 &lt;= nums[i] &lt;= 109
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q128. Longest Consecutive Sequence",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var longestConsecutive = function(nums) {
                     let res = 0
                     const seen = new Set(nums)
                     for(let n of nums){
                     if(seen.has(n-1))
                     continue
                     let length = 1;
                     while (seen.has(++n))
                     ++length;
                     res = Math.max(res, length);
                     }
                     return res 
                   };
                   
                   longestConsecutive([100,4,200,1,3,2])`,
                    output: `4`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q129: {
        title: "Q129. Sum Root to Leaf Numbers",
        content: (
          <>
            <Span>
              <b>Q129. Sum Root to Leaf Numbers</b>
            </Span>
            <Span>
              You are given the root of a binary tree containing digits from 0
              to 9 only.
            </Span>
            <Span>Each root-to-leaf path in the tree represents a number.</Span>
            <Span>
              For example, the root-to-leaf path 1 -&gt; 2 -&gt; 3 represents
              the number 123.
            </Span>
            <Span>
              Return the total sum of all root-to-leaf numbers. Test cases are
              generated so that the answer will fit in a 32-bit integer.
            </Span>
            <Span>A leaf node is a node with no children.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode129} left />
            <Span>
              Input: root = [1,2,3] <br />
              Output: 25 <br />
              Explanation: <br />
              The root-to-leaf path 1-&gt;2 represents the number 12.
              <br />
              The root-to-leaf path 1-&gt;3 represents the number 13.
              <br />
              Therefore, sum = 12 + 13 = 25.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [4,9,0,5,1] <br />
              Output: 1026 <br />
              Explanation: The root-to-leaf path 4-&gt;9-&gt;5 represents the
              number 495. <br />
              The root-to-leaf path 4-&gt;9-&gt;1 represents the number 491.{" "}
              <br />
              The root-to-leaf path 4-&gt;0 represents the number 40. <br />
              Therefore, sum = 495 + 491 + 40 = 1026.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [1, 1000].
              <br />
              0 &lt;= Node.val &lt;= 9<br />
              The depth of the tree will not exceed 10.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q129. Sum Root to Leaf Numbers",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    
                    /**
                     * @param {TreeNode} root
                     * @return {number}
                     */
                    function dfs(root,res,path=0){
                      if (!root) return;
                      if (!root.left && !root.right) {
                          res.out += path * 10 + root.val;
                          return;
                      }
                      dfs(root.left,res, path * 10 + root.val);
                      dfs(root.right,res, path * 10 + root.val);
                    }
                    
                    var sumNumbers = function(root) {
                      const res = {out:0}
                      dfs(root,res)
                      return res.out    
                    };
                    
                    const t = new TreeNode(1)
                    t.left = new TreeNode(2)
                    t.right = new TreeNode(3)
                    sumNumbers(t)`,
                    output: `25`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q130: {
        title: "Q130. Surrounded Regions",
        content: (
          <>
            <Span>
              <b>Q130. Surrounded Regions</b>
            </Span>
            <Span>
              Given an m x n matrix board containing 'X' and 'O', capture all
              regions that are 4-directionally surrounded by 'X'.
            </Span>
            <Span>
              A region is captured by flipping all 'O's into 'X's in that
              surrounded region.
            </Span>{" "}
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode130} left />
            <Span>
              Input: board =
              [["X","X","X","X"],["X","O","O","X"],["X","X","O","X"],["X","O","X","X"]]{" "}
              <br />
              Output:
              [["X","X","X","X"],["X","X","X","X"],["X","X","X","X"],["X","O","X","X"]]{" "}
              <br />
              Explanation: Surrounded regions should not be on the border, which
              means that any 'O' on the border of the board are not flipped to
              'X'. Any 'O' that is not on the border and it is not connected to
              an 'O' on the border will be flipped to 'X'. Two cells are
              connected if they are adjacent cells connected horizontally or
              vertically.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: board = [["X"]] <br />
              Output: [["X"]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == board.length <br />
              n == board[i].length <br />
              1 &lt;= m, n &lt;= 200 <br />
              board[i][j] is 'X' or 'O'.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {character[][]} board
                    * @return {void} Do not return anything, modify board in-place instead.
                    */
                   var solve = function(board) {
                     if(board.length===0) return
                     const m = board.length
                     const n = board[0].length
                     const dirs = [0,1,0,-1,0]
                     const q = []
                     for (let i = 0; i < m; ++i)
                     for (let j = 0; j < n; ++j)
                     if (i * j == 0 || i == m - 1 || j == n - 1)
                     if (board[i][j] == 'O') {
                       q.push([i,j]);
                       board[i][j] = '*';
                     }
                     while(q.length){
                       const [i,j] = q.shift()
                       for (let k = 0; k < 4; ++k) {
                         let x = i + dirs[k];
                         let y = j + dirs[k + 1];
                         if (x < 0 || x == m || y < 0 || y == n)
                           continue;
                         if (board[x][y] != 'O')
                           continue;
                         q.push([x, y]);
                         board[x][y] = '*';
                       }
                       }
                     for(let row of board){
                       for(let i=0;i<row.length;i++){
                         if (row[i] == '*') row[i] = 'O';
                         else if (row[i] == 'O') row[i] = 'X';
                       }
                     }
                     console.log(board)
                   };
                   
                   solve([["X","X","X","X"],["X","O","O","X"],["X","X","O","X"],["X","O","X","X"]])`,
                    output: `[
                    [ 'X', 'X', 'X', 'X' ],
                    [ 'X', 'X', 'X', 'X' ],
                    [ 'X', 'X', 'X', 'X' ],
                    [ 'X', 'O', 'X', 'X' ]
                  ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q131: {
        title: "Q131. Palindrome Partitioning",
        content: (
          <>
            <Span>
              <b>Q131. Palindrome Partitioning</b>
            </Span>
            <Span>
              Given a string s, partition s such that every substring of the
              partition is a palindrome. Return all possible palindrome
              partitioning of s.
            </Span>
            <Span>
              A palindrome string is a string that reads the same backward as
              forward.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "aab" <br />
              Output: [["a","a","b"],["aa","b"]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "a" <br />
              Output: [["a"]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 16
              <br />s contains only lowercase English letters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n2^n)
              <br />
              Space: O(n2^n)
            </p>
            <CodeEditor
              options={{
                title: "Q131. Palindrome Partitioning",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {string[][]}
                    */
                   
                   function isPalindrome(s){
                     return s === s.split("").reverse().join("")
                   }
                   
                   function dfs(s,j,res,path=[]){
                     if(j===s.length){
                       res.push(path.slice())
                       return
                     }
                     for(let i=j;i<s.length;i++){
                       if(isPalindrome(s.substring(j,i+1))){
                         path.push(s.substring(j, i + 1));
                         dfs(s, i + 1, res,path);
                         path.pop()
                   // or
                   // dfs(s,i+1,res,[...path,s.substring(j,i+1)])
                       }
                     }
                   }
                   
                   var partition = function(s) {
                     const res = []
                     dfs(s,0,res)
                     return res  
                   };
                   
                   partition("aab")`,
                    output: `[ [ 'a', 'a', 'b' ], [ 'aa', 'b' ] ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q132: {
        title: "Q132. Palindrome Partitioning II",
        content: (
          <>
            <Span>
              <b>Q132. Palindrome Partitioning II</b>
            </Span>
            <Span>
              Given a string s, partition s such that every substring of the
              partition is a palindrome.
            </Span>
            <Span>
              Return the minimum cuts needed for a palindrome partitioning of s.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "aab" <br />
              Output: 1 <br />
              Explanation: The palindrome partitioning ["aa","b"] could be
              produced using 1 cut.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "a" <br />
              Output: 0
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "ab"
              <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 2000 <br />s consists of lowercase English
              letters only.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(mn)
            </p>
            <CodeEditor
              options={{
                title: "Q132. Palindrome Partitioning II",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {number}
                    */
                   var minCut = function(s) {
                     const n = s.length
                     const cut = Array(n).fill(0)
                     const dp = Array.from(Array(n),()=>Array(n).fill(false)) 
                     for(let i=0;i<n;i++){
                       let min = i
                       for(let j=0;j<i+1;j++){
                         if(s[j] == s[i] && (j + 1 > i - 1 || dp[j + 1][i - 1])){
                           dp[j][i] = true
                           if(j==0) min = 0
                           else min = Math.min(min, cut[j - 1] + 1)
                         } 
                       }
                       cut[i] = min
                     }
                     return cut[n-1]
                   };
                   
                   minCut("aab")`,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q133: {
        title: "Q133. Clone Graph",
        content: (
          <>
            <Span>
              <b>Q133. Clone Graph</b>
            </Span>
            <Span>
              Given a reference of a node in a connected undirected graph.
            </Span>
            <Span>Return a deep copy (clone) of the graph.</Span>
            <Span>
              Each node in the graph contains a value (int) and a list
              (List[Node]) of its neighbors.
            </Span>
            <Span>
              class Node {"{"}
              public int val; <br />
              public List{"<Node>"} neighbors; <br />
              {"}"}
            </Span>
            <Span>
              <b>Test case format:</b>
            </Span>
            <Span>
              For simplicity, each node's value is the same as the node's index
              (1-indexed). For example, the first node with val == 1, the second
              node with val == 2, and so on. The graph is represented in the
              test case using an adjacency list.
            </Span>
            <Span>
              An adjacency list is a collection of unordered lists used to
              represent a finite graph. Each list describes the set of neighbors
              of a node in the graph.
            </Span>
            <Span>
              The given node will always be the first node with val = 1. You
              must return the copy of the given node as a reference to the
              cloned graph.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode133} left />
            <Span>
              Input: adjList = [[2,4],[1,3],[2,4],[1,3]] <br />
              Output: [[2,4],[1,3],[2,4],[1,3]] <br />
              Explanation: There are 4 nodes in the graph. <br />
              1st node (val = 1)'s neighbors are 2nd node (val = 2) and 4th node
              (val = 4). <br />
              2nd node (val = 2)'s neighbors are 1st node (val = 1) and 3rd node
              (val = 3). <br />
              3rd node (val = 3)'s neighbors are 2nd node (val = 2) and 4th node
              (val = 4). <br />
              4th node (val = 4)'s neighbors are 1st node (val = 1) and 3rd node
              (val = 3).
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: adjList = [[]] <br />
              Output: [[]] <br />
              Explanation: Note that the input contains one empty list. The
              graph consists of only one node with val = 1 and it does not have
              any neighbors.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: adjList = []
              <br />
              Output: []
              <br />
              Explanation: This an empty graph, it does not have any nodes.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the graph is in the range [0, 100].
              <br />
              1 &lt;= Node.val &lt;= 100
              <br />
              Node.val is unique for each node.
              <br />
              There are no repeated edges and no self-loops in the graph.
              <br />
              The Graph is connected and all nodes can be visited starting from
              the given node.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(v+e)
              <br />
              Space: O(v+e)
            </p>
            <CodeEditor
              options={{
                title: "Q133. Clone Graph",
                codes: {
                  Python: {
                    code: `
class Node:
  def __init__(self, val = 0, neighbors = None):
    self.val = val
    self.neighbors = neighbors if neighbors is not None else []
class Solution:
    def cloneGraph(self, node: 'Node') -> 'Node':
        if not node:
            return None
        q = deque([node])
        map = {}
        map[node] = Node(node.val)
        while q:
            n = q.popleft()
            for neighbor in n.neighbors:
                if neighbor not in map:
                    map[neighbor] = Node(neighbor.val)
                    q.append(neighbor)
                map[n].neighbors.append(map[neighbor])
        return map[node]        
                `,
                  },
                },
              }}
            />
          </>
        ),
      },
      q134: {
        title: "Q134. Gas Station",
        content: (
          <>
            <Span>
              <b>Q134. Gas Station</b>
            </Span>
            <Span>
              There are n gas stations along a circular route, where the amount
              of gas at the ith station is gas[i].
            </Span>
            <Span>
              You have a car with an unlimited gas tank and it costs cost[i] of
              gas to travel from the ith station to its next (i + 1)th station.
              You begin the journey with an empty tank at one of the gas
              stations.
            </Span>
            <Span>
              Given two integer arrays gas and cost, return the starting gas
              station's index if you can travel around the circuit once in the
              clockwise direction, otherwise return -1. If there exists a
              solution, it is guaranteed to be unique
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: gas = [1,2,3,4,5], cost = [3,4,5,1,2] <br />
              Output: 3 <br />
              Explanation: <br />
              Start at station 3 (index 3) and fill up with 4 unit of gas. Your
              tank = 0 + 4 = 4 <br />
              Travel to station 4. Your tank = 4 - 1 + 5 = 8 <br />
              Travel to station 0. Your tank = 8 - 2 + 1 = 7 <br />
              Travel to station 1. Your tank = 7 - 3 + 2 = 6 <br />
              Travel to station 2. Your tank = 6 - 4 + 3 = 5 <br />
              Travel to station 3. The cost is 5. Your gas is just enough to
              travel back to station 3. Therefore, return 3 as the starting
              index.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: gas = [2,3,4], cost = [3,4,3]
              <br />
              Output: -1
              <br />
              Explanation:
              <br />
              You can't start at station 0 or 1, as there is not enough gas to
              travel to the next station.
              <br />
              Let's start at station 2 and fill up with 4 unit of gas. Your tank
              = 0 + 4 = 4<br />
              Travel to station 0. Your tank = 4 - 3 + 2 = 3<br />
              Travel to station 1. Your tank = 3 - 3 + 3 = 3<br />
              You cannot travel back to station 2, as it requires 4 unit of gas
              but you only have 3.
              <br />
              Therefore, you can't travel around the circuit once no matter
              where you start.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              gas.length == n<br />
              cost.length == n<br />
              1 &lt;= n &lt;= 105
              <br />0 &lt;= gas[i], cost[i] &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q134. Gas Station",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} gas
                    * @param {number[]} cost
                    * @return {number}
                    */
                   var canCompleteCircuit = function(gas, cost) {
                     let ans = net = sum = 0
                   
                     for(let i=0;i<gas.length;i++){
                       net += gas[i] - cost[i]
                       sum += gas[i] - cost[i]
                       if(sum < 0){
                         sum = 0
                         ans = i + 1
                       }
                     }
                     if(net < 0) return -1
                     return ans 
                   };
                   
                   canCompleteCircuit([2,3,4],[3,4,3])`,
                    output: `-1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q135: {
        title: "Q135. Candy",
        content: (
          <>
            <Span>
              <b>Q135. Candy</b>
            </Span>
            <Span>
              There are n children standing in a line. Each child is assigned a
              rating value given in the integer array ratings.
            </Span>
            <Span>
              You are giving candies to these children subjected to the
              following requirements:
            </Span>
            <Span>Each child must have at least one candy.</Span>
            <Span>
              Children with a higher rating get more candies than their
              neighbors.
            </Span>
            <Span>
              Return the minimum number of candies you need to have to
              distribute the candies to the children.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: ratings = [1,0,2] <br />
              Output: 5 <br />
              Explanation: You can allocate to the first, second and third child
              with 2, 1, 2 candies respectively.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: ratings = [1,2,2]
              <br />
              Output: 4<br />
              Explanation: You can allocate to the first, second and third child
              with 1, 2, 1 candies respectively. The third child gets 1 candy
              because it satisfies the above two conditions.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              n == ratings.length <br />
              1 &lt;= n &lt;= 2 * 104 <rb />0 &lt;= ratings[i] &lt;= 2 * 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q135. Candy",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} ratings
                    * @return {number}
                    */
                   var candy = function(ratings) {
                     const n = ratings.length;
                     let ans = 0;
                     let l = Array(n).fill(1);
                     let r = Array(n).fill(1);
                     for (let i = 1; i < n; ++i)
                       if (ratings[i] > ratings[i - 1])
                         l[i] = l[i - 1] + 1;
                     for (let i = n - 2; i >= 0; --i)
                       if (ratings[i] > ratings[i + 1])
                         r[i] = r[i + 1] + 1;
                     for (let i = 0; i < n; ++i)
                       ans += Math.max(l[i], r[i]);
                     return ans;  
                   };
                   
                   candy([1,0,2])
                   `,
                    output: `5`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q136: {
        title: "Q136. Single Number",
        content: (
          <>
            <Span>
              <b>Q136. Single Number</b>
            </Span>
            <Span>
              Given a non-empty array of integers nums, every element appears
              twice except for one. Find that single one.
            </Span>
            <Span>
              You must implement a solution with a linear runtime complexity and
              use only const1ant extra space.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [2,2,1] <br />
              Output: 1
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [4,1,2,1,2]
              <br />
              Output: 4
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [1]
              <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 3 * 104 <br />
              -3 * 104 &lt;= nums[i] &lt;= 3 * 104 <br />
              Each element in the array appears twice except for one element
              which appears only once.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q136. Single Number",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var singleNumber = function(nums) {
                       let ans = 0
                       for(let n of nums)
                       ans^=n
                       return ans
                   };
                   singleNumber([2,2,1])`,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q137: {
        title: "Q137. Single Number II",
        content: (
          <>
            <Span>
              <b>Q137. Single Number II</b>
            </Span>
            <Span>
              Given an integer array nums where every element appears three
              times except for one, which appears exactly once. Find the single
              element and return it.
            </Span>
            <Span>
              You must implement a solution with a linear runtime complexity and
              use only constant extra space.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [2,2,3,2] <br />
              Output: 3
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0,1,0,1,0,1,99] <br />
              Output: 99
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 3 * 104 <br />
              -231 &lt;= nums[i] &lt;= 231 - 1 <br />
              Each element in nums appears exactly three times except for one
              element which appears once.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q137. Single Number II",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   // O(32n) = )(n) O(1)
                   
                   function bit(nums){
                     let ans = 0;
                     for (let i = 0; i < 32; ++i) {
                       let sum = 0;
                       for (let n of nums)
                         sum += (n >> i) & 1;
                       sum %= 3;
                       ans |= (sum << i);
                     }
                     return ans;
                   }
                   
                   function magic(nums){
                     let ones = twos = 0;
                     for (let n of nums) {
                     ones ^= (num & ~twos);
                     twos ^= (num & ~ones);
                     }
                     return ones;
                   }
                   
                   var singleNumber = function(nums) {
                     return bit(nums)
                   };
                   
                   singleNumber([2,2,3,2])`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q138: {
        title: "Q138. Copy List with Random Pointer",
        content: (
          <>
            <Span>
              <b>Q138. Copy List with Random Pointer</b>
            </Span>
            <Span>
              A linked list of length n is given such that each node contains an
              additional random pointer, which could point to any node in the
              list, or null.
            </Span>
            <Span>
              Construct a deep copy of the list. The deep copy should consist of
              exactly n brand new nodes, where each new node has its value set
              to the value of its corresponding original node. Both the next and
              random pointer of the new nodes should point to new nodes in the
              copied list such that the pointers in the original list and copied
              list represent the same list state. None of the pointers in the
              new list should point to nodes in the original list.
            </Span>
            <Span>
              For example, if there are two nodes X and Y in the original list,
              where X.random --&gt; Y, then for the corresponding two nodes x
              and y in the copied list, x.random --&gt; y.
            </Span>
            <Span>Return the head of the copied linked list.</Span>
            <Span>
              The linked list is represented in the input/output as a list of n
              nodes. Each node is represented as a pair of [val, random_index]
              where:
            </Span>
            <Span>val: an integer representing Node.val</Span>
            <Span>
              random_index: the index of the node (range from 0 to n-1) that the
              random pointer points to, or null if it does not point to any
              node.
            </Span>
            <Span>
              Your code will only be given the head of the original linked list.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode138} left />
            <Span>
              Input: head = [[7,null],[13,0],[11,4],[10,2],[1,0]] <br />
              Output: [[7,null],[13,0],[11,4],[10,2],[1,0]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [[1,1],[2,1]]
              <br />
              Output: [[1,1],[2,1]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= n &lt;= 1000 <br />
              -104 &lt;= Node.val &lt;= 104 <br />
              Node.random is null or is pointing to some node in the linked
              list.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q138. Copy List with Random Pointer",
                codes: {
                  Python: {
                    code: `
class Node:
    def __init__(self, x: int, next: 'Node' = None, random: 'Node' = None):
        self.val = int(x)
        self.next = next
        self.random = random        
class Solution:
    def copyRandomList(self, head: 'Node') -> 'Node':
        if head is None:
            return None
        if head in self.dict:
            return self.dict[head]
        self.dict[head] = Node(head.val)
        self.dict[head].next = self.copyRandomList(head.next)
        self.dict[head].random = self.copyRandomList(head.random)
        return self.dict[head]
    dict = {}
                        `,
                  },
                },
              }}
            />
          </>
        ),
      },
      q139: {
        title: "Q139. Word Break",
        content: (
          <>
            <Span>
              <b>Q139. Word Break</b>
            </Span>
            <Span>
              Given a string s and a dictionary of strings wordDict, return true
              if s can be segmented into a space-separated sequence of one or
              more dictionary words.
            </Span>
            <Span>
              Note that the same word in the dictionary may be reused multiple
              times in the segmentation.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "leetcode", wordDict = ["leet","code"] <br />
              Output: true <br />
              Explanation: Return true because "leetcode" can be segmented as
              "leet code".
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "applepenapple", wordDict = ["apple","pen"] <br />
              Output: true <br />
              Explanation: Return true because "applepenapple" can be segmented
              as "apple pen apple". Note that you are allowed to reuse a
              dictionary word.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "catsandog", wordDict =
              ["cats","dog","sand","and","cat"] Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 300 <br />
              1 &lt;= wordDict.length &lt;= 1000 <br />
              1 &lt;= wordDict[i].length &lt;= 20 <br />s and wordDict[i]
              consist of only lowercase English letters. <br />
              All the strings of wordDict are unique.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^3)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q139. Word Break",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @param {string[]} wordDict
                    * @return {boolean}
                    */
                   
                   var wordBreak = function(s, wordDict) {
                     const n = s.length
                     const wordSet = new Set(wordDict)
                     const dp = Array(n+1).fill(false)
                     dp[0] = true
                     for (let i = 1; i <= n; ++i)
                     for (let j = 0; j < i; ++j)
                     if (dp[j] && wordSet.has(s.substring(j, i))) {
                       dp[i] = true;
                       break;
                     }
                     console.log(dp)
                     return dp[n];
                   };
                   
                   wordBreak("leetcode",["leet","code"])`,
                    output: `[
                      true,  false, false,
                      false, true,  false,
                      false, false, true
                    ]
                    true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q140: {
        title: "Q140. Word Break II",
        content: (
          <>
            <Span>
              <b>Q140. Word Break II</b>
            </Span>
            <Span>
              Given a string s and a dictionary of strings wordDict, add spaces
              in s to construct a sentence where each word is a valid dictionary
              word. Return all such possible sentences in any order.
            </Span>
            <Span>
              Note that the same word in the dictionary may be reused multiple
              times in the segmentation.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "catsanddog", <br /> wordDict =
              ["cat","cats","and","sand","dog"] <br />
              Output: ["cats and dog","cat sand dog"]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "pineapplepenapple", <br />
              wordDict = ["apple","pen","applepen","pine","pineapple"]
              <br />
              Output: ["pine apple pen apple","pineapple pen apple","pine
              applepen apple"]
              <br />
              Explanation: Note that you are allowed to reuse a dictionary word.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "catsandog", <br />
              wordDict = ["cats","dog","sand","and","cat"]
              <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 20 <br />
              1 &lt;= wordDict.length &lt;= 1000 <br />
              1 &lt;= wordDict[i].length &lt;= 10 <br />s and wordDict[i]
              consist of only lowercase English letters. <br />
              All the strings of wordDict are unique.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(2^n)
              <br />
              Space: O(2^n)
            </p>
            <CodeEditor
              options={{
                title: "Q140. Word Break II",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @param {string[]} wordDict
                    * @return {string[]}
                    */
                   function helper(s,wordSet,memo){
                   if(memo.has(s))
                   return memo.get(s)
                   const ans = []
                   
                   for (let i = 1; i < s.length; ++i) {
                     const prefix = s.substring(0, i);
                     const suffix = s.substring(i);
                     if (wordSet.has(prefix))
                       for (let word of helper(suffix, wordSet, memo))
                         ans.push(prefix + " " + word);
                   }
                   
                   if(wordSet.has(s))
                   ans.push(s)
                   memo.set(s,ans)
                   return ans
                   }
                   
                   var wordBreak = function(s, wordDict) {
                     const wordSet = new Set(wordDict)
                     const memo = new Map()
                     return helper(s,wordSet,memo)  
                   };
                   
                   wordBreak("catsanddog",["cat","cats","and","sand","dog"])`,
                    output: `[ 'cat sand dog', 'cats and dog' ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q141: {
        title: "Q141. Linked List Cycle",
        content: (
          <>
            <Span>
              <b>Q141. Linked List Cycle</b>
            </Span>
            <Span>
              Given head, the head of a linked list, determine if the linked
              list has a cycle in it.
            </Span>
            <Span>
              There is a cycle in a linked list if there is some node in the
              list that can be reached again by continuously following the next
              pointer. Internally, pos is used to denote the index of the node
              that tail's next pointer is connected to. Note that pos is not
              passed as a parameter.
            </Span>
            <Span>
              Return true if there is a cycle in the linked list. Otherwise,
              return false.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode141} left />
            <Span>
              Input: head = [3,2,0,-4], pos = 1 <br />
              Output: true <br />
              Explanation: There is a cycle in the linked list, where the tail
              connects to the 1st node (0-indexed)
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [1], pos = -1
              <br />
              Output: false
              <br />
              Explanation: There is no cycle in the linked list.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of the nodes in the list is in the range [0, 104].
              <br />
              -105 &lt;= Node.val &lt;= 105
              <br />
              pos is -1 or a valid index in the linked-list.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q141. Linked List Cycle",
                codes: {
                  Javascript: {
                    code: `function ListNode(val) {
                      this.val = val;
                      this.next = null;
                    }
                    
                    /**
                     * @param {ListNode} head
                     * @return {boolean}
                     */
                    
                    // let slow = fast = head;
                    // while (fast && fast.next) {
                    //   slow = slow.next;
                    //   fast = fast.next.next;
                    //   if (slow == fast)
                    //     return true;
                    // }
                    // return false;
                    
                    var hasCycle = function(head) {
                      let temp = head
                      let s = new Set()
                      while(temp !== null){
                        if(s.has(temp))
                        return true
                        s.add(temp)
                        temp = temp.next
                      }
                      return false
                    };
                    
                    const l = new ListNode(3)
                    l.next = new ListNode(2)
                    l.next.next = new ListNode(0)
                    l.next.next.next = new ListNode(-4)
                    l.next.next.next.next = l.next
                    hasCycle(l)`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q142: {
        title: "Q142. Linked List Cycle II",
        content: (
          <>
            <Span>
              <b>Q142. Linked List Cycle II</b>
            </Span>
            <Span>
              Given the head of a linked list, return the node where the cycle
              begins. If there is no cycle, return null.
            </Span>
            <Span>
              There is a cycle in a linked list if there is some node in the
              list that can be reached again by continuously following the next
              pointer. Internally, pos is used to denote the index of the node
              that tail's next pointer is connected to (0-indexed). It is -1 if
              there is no cycle. Note that pos is not passed as a parameter.
            </Span>
            <Span>Do not modify the linked list.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode141} left />
            <Span>
              Input: head = [3,2,0,-4], pos = 1 <br />
              Output: tail connects to node index 1 <br />
              Explanation: There is a cycle in the linked list, where tail
              connects to the second node.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [1,2], pos = 0<br />
              Output: tail connects to node index 0<br />
              Explanation: There is a cycle in the linked list, where tail
              connects to the first node.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: head = [1], pos = -1
              <br />
              Output: no cycle
              <br />
              Explanation: There is no cycle in the linked list.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of the nodes in the list is in the range [0, 104].
              <br />
              -105 &lt;= Node.val &lt;= 105
              <br />
              pos is -1 or a valid index in the linked-list.
            </Span>
            <Span>
              <b>Follow up: </b>
              Can you solve it using O(1) (i.e. constant) memory?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q142. Linked List Cycle II",
                codes: {
                  Javascript: {
                    code: `class ListNode {
                      int val;
                      ListNode next;
                      ListNode(int x) {
                          val = x;
                          next = null;
                      }
                  }
                  
                  // comment ListNode class before submission

                  public class Solution {
                    public ListNode detectCycle(ListNode head) {
                      ListNode slow = head;
                      ListNode fast = head;
                  
                      while (fast != null && fast.next != null) {
                        slow = slow.next;
                        fast = fast.next.next;
                        if (slow == fast) {
                          slow = head;
                          while (slow != fast) {
                            slow = slow.next;
                            fast = fast.next;
                          }
                          return slow;
                        }
                      }
                  
                      return null;
                    }
                    public static void Solution(String ...s){
                      ListNode l = new ListNode(3);
                      l.next = new ListNode(2);
                      l.next.next = new ListNode(0); 
                      l.next.next.next = new ListNode(-4);
                      l.next.next.next.next = l.next;
                      Solution m = new Solution();
                      System.out.println(m.detectCycle(l));
                    }
                  }
                  `,
                    output: `ListNode@2c7b84de`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q143: {
        title: "Q143. Reorder List",
        content: (
          <>
            <Span>
              <b>Q143. Reorder List</b>
            </Span>
            <Span>
              You are given the head of a singly linked-list. The list can be
              represented as:
            </Span>
            <Span>L0 → L1 → … → Ln - 1 → Ln</Span>
            <Span>Reorder the list to be on the following form:</Span>
            <Span>L0 → Ln → L1 → Ln - 1 → L2 → Ln - 2 → …</Span>
            <Span>
              You may not modify the values in the list's nodes. Only nodes
              themselves may be changed.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode143} left />
            <Span>
              Input: head = [1,2,3,4] <br />
              Output: [1,4,2,3]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [1,2,3,4,5] <br />
              Output: [1,5,2,4,3]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is in the range [1, 5 * 104].
              <br />1 &lt;= Node.val &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q143. Reorder List",
                codes: {
                  Javascript: {
                    code: `function ListNode(val) {
                      this.val = val;
                      this.next = null;
                    }
                    
                    /**
                     * @param {ListNode} head
                     * @return {void} Do not return anything, modify head in-place instead.
                     */
                    
                    function merge(l1,l2){
                    while(l2){
                      let next = l1.next
                      l1.next = l2;
                      l1 = l2;
                      l2 = next;
                    }
                    }
                    
                    function findMid(head){
                      let prev = null
                      let slow = fast = head
                      while(fast && fast.next){
                        prev = slow;
                        slow = slow.next;
                        fast = fast.next.next;
                      }
                      prev.next = null
                      return slow
                    }
                    
                    function reverse(head){
                    let prev = next = null
                    let curr = head
                    while(curr){
                    next = curr.next
                    curr.next = prev
                    prev = curr
                    curr = next
                    }
                    return prev
                    }
                    
                    var reorderList = function(head) {
                      if (!head|| !head.next)
                          return;
                      let mid = findMid(head);
                      let reversed = reverse(mid);
                      merge(head, reversed); 
                      console.log(head)
                    };
                    
                    const l = new ListNode(1)
                    l.next = new ListNode(2)
                    l.next.next = new ListNode(3)
                    l.next.next.next = new ListNode(4)
                    reorderList(l)
                    console.log(l)`,
                    output: `ListNode {
                      val: 1,
                      next: ListNode { val: 4, next: ListNode { val: 2, next: [ListNode] } }
                    }
                    ListNode {
                      val: 1,
                      next: ListNode { val: 4, next: ListNode { val: 2, next: [ListNode] } }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q144: {
        title: "Q144. Binary Tree Preorder Traversal",
        content: (
          <>
            <Span>
              <b>Q144. Binary Tree Preorder Traversal</b>
            </Span>
            <Span>
              Given the root of a binary tree, return the preorder traversal of
              its nodes' values.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode144} left />
            <Span>
              Input: root = [1,null,2,3] <br />
              Output: [1,2,3]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = []
              <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = [1]
              <br />
              Output: [1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 100]. <br />
              -100 &lt;= Node.val &lt;= 100
            </Span>
            <Span>
              <b>Follow up: </b>
              Recursive solution is trivial, could you do it iteratively?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                  }
                  
                  /**
                   * @param {TreeNode} root
                   * @return {number[]}
                   */
                  
                  function rec(head,res){
                  if(!head) return
                  res.push(head.val)
                  rec(head.left,res)
                  rec(head.right,res)
                  }
                  // O(n) O(h)
                  
                  function iter(head,res){
                  if(!head) return []
                  const stack = []
                  stack.push(head)
                  while (stack.length) {
                    let curr = stack.pop();
                    res.push(curr.val);
                    if (curr.right)
                      stack.push(curr.right);
                    if (curr.left)
                      stack.push(curr.left);
                  }
                  }
                  
                  var preorderTraversal = function(root) {
                    const res = []
                    iter(root,res)
                    return res
                  };
                  
                  const t = new TreeNode(1)
                  t.right = new TreeNode(2)
                  t.right.left = new TreeNode(3)
                  preorderTraversal(t)`,
                    output: `[ 1, 2, 3 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q145: {
        title: "Q145. Binary Tree Postorder Traversal",
        content: (
          <>
            <Span>
              <b>Q145. Binary Tree Postorder Traversal</b>
            </Span>
            <Span>
              Given the root of a binary tree, return the postorder traversal of
              its nodes' values.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode144} left />
            <Span>
              Input: root = [1,null,2,3] <br />
              Output: [3,2,1]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = []
              <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = [1]
              <br />
              Output: [1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of the nodes in the tree is in the range [0, 100].
              <br />
              -100 &lt;= Node.val &lt;= 100
            </Span>
            <Span>
              <b>Follow up: </b>
              Recursive solution is trivial, could you do it iteratively?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q145. Binary Tree Postorder Traversal",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                  }
                  
                  /**
                   * @param {TreeNode} root
                   * @return {number[]}
                   */
                  
                  function rec(head,res){
                  if(!head) return
                  rec(head.left,res)
                  rec(head.right,res)
                  res.push(head.val)
                  }
                  // O(n) O(h)
                  
                  function iter(head,res){
                  if(!head) return []
                  const stack = []
                  stack.push(head)
                  while (stack.length) {
                    let curr = stack.pop();
                    res.push(curr.val);
                    if (curr.left)
                      stack.push(curr.left);
                    if (curr.right)
                      stack.push(curr.right);
                  }
                  res.reverse()
                  }
                  
                  var postorderTraversal = function(root) {
                    const res = []
                    iter(root,res)
                    return res
                  };
                  
                  const t = new TreeNode(1)
                  t.right = new TreeNode(2)
                  t.right.left = new TreeNode(3)
                  postorderTraversal(t)`,
                    output: `[ 3, 2, 1 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q146: {
        title: "Q146. LRU Cache",
        content: (
          <>
            <Span>
              <b>Q146. LRU Cache</b>
            </Span>
            <Span>
              Design a data structure that follows the constraints of a Least
              Recently Used (LRU) cache.
            </Span>
            <Span>Implement the LRUCache class:</Span>
            <Span>
              LRUCache(int capacity) Initialize the LRU cache with positive size
              capacity.
            </Span>
            <Span>
              int get(int key) Return the value of the key if the key exists,
              otherwise return -1.
            </Span>
            <Span>
              void put(int key, int value) Update the value of the key if the
              key exists. Otherwise, add the key-value pair to the cache. If the
              number of keys exceeds the capacity from this operation, evict the
              least recently used key.
            </Span>
            <Span>
              The functions get and put must each run in O(1) average time
              complexity.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input ["LRUCache", "put", "put", "get", "put", "get", "put",
              "get", "get", "get"] <br />
              [[2], [1, 1], [2, 2], [1], [3, 3], [2], [4, 4], [1], [3], [4]]
              <br />
              Output
              <br />
              [null, null, null, 1, null, -1, null, -1, 3, 4]
              <br />
              Explanation
              <br />
              LRUCache lRUCache = new LRUCache(2);
              <br />
              lRUCache.put(1, 1); // cache is {"{"}1=1{"}"}
              <br />
              lRUCache.put(2, 2); // cache is {"{"}1=1, 2=2{"}"}
              <br />
              lRUCache.get(1); // return 1
              <br />
              lRUCache.put(3, 3); // LRU key was 2, evicts key 2, cache is {"{"}
              1=1, 3=3{"}"}
              <br />
              lRUCache.get(2); // returns -1 (not found)
              <br />
              lRUCache.put(4, 4); // LRU key was 1, evicts key 1, cache is {"{"}
              4=4, 3=3{"}"}
              <br />
              lRUCache.get(1); // return -1 (not found)
              <br />
              lRUCache.get(3); // return 3
              <br />
              lRUCache.get(4); // return 4
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= capacity &lt;= 3000
              <br />
              0 &lt;= key &lt;= 104
              <br />
              0 &lt;= value &lt;= 105
              <br />
              At most 2 * 105 calls will be made to get and put.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(capacity)
            </p>
            <CodeEditor
              options={{
                title: "Q146. LRU Cache",
                output: `-1
                1`,
                codes: {
                  Python: {
                    code: `class Node:
    def __init__(self, key: int, value: int):
        self.key = key
        self.value = value
        self.prev = None
        self.next = None


class LRUCache:
    def __init__(self, capacity: int):
        self.capacity = capacity
        self.dict = {}
        self.head = Node(-1, -1)
        self.tail = Node(-1, -1)
        self.join(self.head, self.tail)
    def join(self, node1: Node, node2: Node):
        node1.next = node2
        node2.prev = node1
    def remove(self, node: Node):
        self.join(node.prev, node.next)    
    def moveToHead(self, node: Node):
        self.join(node, self.head.next)
        self.join(self.head, node)        
    def get(self, key: int) -> int:
        if key not in self.dict:
            return -1
        node = self.dict[key]
        self.remove(node)
        self.moveToHead(node)
        return node.value
    def put(self, key: int, value: int) -> None:
        if key in self.dict:
            node = self.dict[key]
            node.value = value
            self.remove(node)
            self.moveToHead(node)
            return
        if len(self.dict) == self.capacity:
            lastNode = self.tail.prev
            del self.dict[lastNode.key]
            self.remove(lastNode)
        self.moveToHead(Node(key, value))
        self.dict[key] = self.head.next
`,
                  },
                  Javascript: {
                    code: `class Node {
                      constructor(k=-1,v=-1,next=null,prev=null){
                       this.key = k 
                       this.value = v
                       this.next = next
                       this.prev = prev
                      }
                    }
                    
                    /**
                     * @param {number} capacity
                     */
                    var LRUCache = function(capacity) {
                      this.capacity = capacity;
                      this.cache = {}
                      this.head = new Node();
                      this.tail = new Node();
                      this.join(this.head,this.tail)
                    };
                    
                    
                    LRUCache.prototype.join = function(n1,n2) {
                      n1.next = n2
                      n2.prev = n1
                    }
                    
                    LRUCache.prototype.remove = function(n) {
                      this.join(n.prev,n.next)
                    }
                    
                    LRUCache.prototype.moveToHead = function(n) {
                      this.join(n,this.head.next)
                      this.join(this.head, n)
                    }
                    
                    /** 
                     * @param {number} key
                     * @return {number}
                     */
                    LRUCache.prototype.get = function(key) {
                     if(!(key in this.cache)) return -1
                     let node = this.cache[key]
                     this.remove(node)
                     this.moveToHead(node)
                     return node.value
                    };
                    
                    /** 
                     * @param {number} key 
                     * @param {number} value
                     * @return {void}
                     */
                    LRUCache.prototype.put = function(key, value) {
                      if(key in this.cache){
                        let node = this.cache[key]
                        node.value = value
                        this.remove(node)
                        this.moveToHead(node)
                        return
                      }
                      if(Object.keys(this.cache).length == this.capacity){
                        let lastNode = this.tail.prev
                        delete this.cache[lastNode.key]
                        this.remove(lastNode)
                      }
                      this.moveToHead(new Node(key, value))
                      this.cache[key] = this.head.next
                    };
                    
                    
                    var obj = new LRUCache(2)
                    console.log(obj.get(0))
                    obj.put(0,1)
                    console.log(obj.get(0))`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q147: {
        title: "Q147. Insertion Sort List",
        content: (
          <>
            <Span>
              <b>Q147. Insertion Sort List</b>
            </Span>
            <Span>
              Given the head of a singly linked list, sort the list using
              insertion sort, and return the sorted list's head.
              <br />
              The steps of the insertion sort algorithm:
              <br />
              Insertion sort iterates, consuming one input element each
              repetition and growing a sorted output list.
              <br />
              At each iteration, insertion sort removes one element from the
              input data, finds the location it belongs within the sorted list
              and inserts it there.
              <br />
              It repeats until no input elements remain.
              <br />
              The following is a graphical example of the insertion sort
              algorithm. The partially sorted list (black) initially contains
              only the first element in the list. One element (red) is removed
              from the input data and inserted in-place into the sorted list
              with each iteration.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode147} left />
            <Span>
              Input: head = [4,2,1,3] <br />
              Output: [1,2,3,4]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [-1,5,3,4,0] <br />
              Output: [-1,0,3,4,5]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is in the range [1, 5000].
              <br />
              -5000 &lt;= Node.val &lt;= 5000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q147. Insertion Sort List",
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                    }
                    
                    /**
                     * @param {ListNode} head
                     * @return {ListNode}
                     */
                    var insertionSortList = function(head) {
                      let temp = new ListNode()
                      let curr = head
                      while(curr){
                        let prev = temp
                        while(prev.next && prev.next.val < curr.val)
                        prev = prev.next
                        next = curr.next
                        curr.next = prev.next
                        prev.next = curr
                        curr = next
                      } 
                      return temp.next    
                    };
                    
                    const l = new ListNode(4)
                    l.next = new ListNode(2)
                    l.next.next = new ListNode(1)
                    l.next.next.next = new ListNode(3)
                    insertionSortList(l)`,
                    output: `ListNode {
                      val: 1,
                      next: ListNode { val: 2, next: ListNode { val: 3, next: [ListNode] } }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q148: {
        title: "Q148. Sort List",
        content: (
          <>
            <Span>
              <b>Q148. Sort List</b>
            </Span>
            <Span>
              Given the head of a linked list, return the list after sorting it
              in ascending order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode147} left />
            <Span>
              Input: head = [4,2,1,3] <br />
              Output: [1,2,3,4]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [-1,5,3,4,0] <br />
              Output: [-1,0,3,4,5]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: head = []
              <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is in the range [0, 5 * 104].{" "}
              <br />
              -105 &lt;= Node.val &lt;= 105
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.log n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q148. Sort List",
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                    }
                    
                    /**
                     * @param {ListNode} head
                     * @return {ListNode}
                     */
                    
                    function count(h){
                      if(!h) return 0
                      if(!h.next) return 1
                      return 1 + count(h.next)
                    }
                    
                    function merge(l1,l2){
                      let temp = new ListNode();
                      let tail = temp;
                      while (l1 && l2) {
                        if (l1.val > l2.val) {
                          let temp = l1;
                          l1 = l2;
                          l2 = temp;
                        }
                        tail.next = l1;
                        l1 = l1.next;
                        tail = tail.next;
                      }
                      tail.next = l1 == null ? l2 : l1;
                      while (tail.next != null)
                        tail = tail.next;
                      return [temp.next, tail]
                    }
                    
                    function split(head,k){
                      while (--k > 0 && head) head = head.next;
                      let rest = head == null ? null : head.next;
                      if (head != null) head.next = null;
                      return rest;
                    }
                    
                    var sortList = function(head) {
                      let length = count(head) 
                      let temp = new ListNode(0,head)
                      for (let k = 1; k < length; k *= 2) {
                        let curr = temp.next;
                        let tail = temp;
                        while (curr) {
                          let l = curr;
                          let r = split(l, k);
                          curr = split(r, k);
                          const merged = merge(l, r);
                          tail.next = merged[0];
                          tail = merged[1];
                        }
                      }
                      return temp.next
                    };
                    
                    const l = new ListNode(4)
                    l.next = new ListNode(2)
                    l.next.next = new ListNode(1)
                    l.next.next.next = new ListNode(3)
                    sortList(l)`,
                    output: `ListNode {
                      val: 1,
                      next: ListNode { val: 2, next: ListNode { val: 3, next: [ListNode] } }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q149: {
        title: "Q149. Max Points on a Line",
        content: (
          <>
            <Span>
              <b>Q149. Max Points on a Line</b>
            </Span>
            <Span>
              Given an array of points where points[i] = [xi, yi] represents a
              point on the X-Y plane, return the maximum number of points that
              lie on the same straight line.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode149} left />
            <Span>
              Input: points = [[1,1],[2,2],[3,3]] <br />
              Output: 3
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: points = [[1,1],[3,2],[5,3],[4,1],[2,3],[1,4]]
              <br />
              Output: 4
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= points.length &lt;= 300 <br />
              points[i].length == 2 <br />
              -104 &lt;= xi, yi &lt;= 104 <br />
              All the points are unique.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q149. Max Points on a Line",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[][]} points
                    * @return {number}
                    */
                   
                   // hcf
                   function gcd(a,b){
                     if(b===0) return a
                     return gcd(b,a%b)
                   }
                   
                   function getSlope(p1,p2){
                     let dx = p2[0] - p1[0];
                     let dy = p2[1] - p1[1];
                     if (dx == 0) return [0, p1[0]]
                     if (dy == 0) return [p1[1], 0]
                     let d = gcd(dx, dy);
                     return [parseInt(dx / d), parseInt(dy / d)]
                   }
                   
                   var maxPoints = function(points) {
                     let ans = 0
                     for (let i = 0; i < points.length; ++i) {
                     let slopeCount = {};
                     let p1 = points[i];
                     let samePoints = 1;
                     let maxPoints = 0; 
                     for (let j = i + 1; j < points.length; ++j) {
                       let p2 = points[j];
                       if (p1[0] == p2[0] && p1[1] == p2[1])
                         ++samePoints;
                       else {
                         let slope = getSlope(p1, p2);
                         slopeCount[slope] = (slopeCount[slope] || 0) + 1
                         maxPoints = Math.max(maxPoints, slopeCount[slope]);
                       }
                     }
                     ans = Math.max(ans, samePoints + maxPoints);
                     }
                     return ans;
                   };
                   
                   maxPoints([[1,1],[2,2],[3,3]])`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q150: {
        title: "Q150. Evaluate Reverse Polish Notation",
        content: (
          <>
            <Span>
              <b>Q150. Evaluate Reverse Polish Notation</b>
            </Span>
            <Span>
              Evaluate the value of an arithmetic expression in Reverse Polish
              Notation.
            </Span>
            <Span>
              Valid operators are +, -, *, and /. Each operand may be an integer
              or another expression.
            </Span>
            <Span>
              Note that division between two integers should truncate toward
              zero.
            </Span>
            <Span>
              It is guaranteed that the given RPN expression is always valid.
              That means the expression would always evaluate to a result, and
              there will not be any division by zero operation.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: tokens = ["2","1","+","3","*"]
              <br />
              Output: 9 Explanation: ((2 + 1) * 3) = 9
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: tokens = ["4","13","5","/","+"] <br />
              Output: 6 Explanation: (4 + (13 / 5)) = 6
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: tokens =
              ["10","6","9","3","+","-11","*","/","*","17","+","5","+"] Output:
              22
              <br />
              <br />
              Explanation: ((10 * (6 / ((9 + 3) * -11))) + 17) + 5
              <br />= ((10 * (6 / (12 * -11))) + 17) + 5
              <br />= ((10 * (6 / -132)) + 17) + 5
              <br />= ((10 * 0) + 17) + 5
              <br />= (0 + 17) + 5
              <br />= 17 + 5
              <br />= 22
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= tokens.length &lt;= 104 <br />
              tokens[i] is either an operator: "+", "-", "*", or "/", or an
              integer in the range [-200, 200].
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q150. Evaluate Reverse Polish Notation",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string[]} tokens
                    * @return {number}
                    */
                   var evalRPN = function(tokens) {
                     const stack = []
                     const operators = {
                       '+': (a,b) => a + b,
                       '-': (a,b) => a - b,
                       '*': (a,b) => a * b,
                       '/': (a,b) => parseInt(a / b),
                     }
                     for(let token of tokens){
                       if(token in operators){
                         let b = stack.pop()
                         let a = stack.pop()
                         stack.push(operators[token](a, b))
                       }
                       else
                         stack.push(parseInt(token))
                     }
                     return stack[0]
                   };
                   
                   evalRPN(["4","13","5","/","+"])`,
                    output: `6`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q151: {
        title: "Q151. Reverse Words in a String",
        content: (
          <>
            <Span>
              <b>Q151. Reverse Words in a String</b>
            </Span>
            <Span>
              Given an input string s, reverse the order of the words.
              <br />
              A word is defined as a sequence of non-space characters. The words
              in s will be separated by at least one space.
              <br />
              Return a string of the words in reverse order concatenated by a
              single space.
              <br />
              Note that s may contain leading or trailing spaces or multiple
              spaces between two words. The returned string should only have a
              single space separating the words. Do not include any extra
              spaces.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "the sky is blue" <br />
              Output: "blue is sky the"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = " hello world <br />
              Output: "world hello"
              <br />
              Explanation: Your reversed string should not contain leading or
              trailing spaces.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "a good example"
              <br />
              Output: "example good a"
              <br />
              Explanation: You need to reduce multiple spaces between two words
              to a single space in the reversed string.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 104 <br />
              s contains English letters (upper-case and lower-case), digits,
              and spaces ' '. <br />
              There is at least one word in s.
            </Span>
            <Span>
              <b>Follow-up: </b>
              If the string data type is mutable in your language, can you solve
              it in-place with O(1) extra space?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {string}
                    */
                   var reverseWords = function(s) {
                     return s.split(' ').filter(x=> x!='').reverse().join(' ').trim()
                   };
                   
                   reverseWords("a good   example")`,
                    output: `example good a`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q152: {
        title: "Q152. Maximum Product Subarray",
        content: (
          <>
            <Span>
              <b>Q152. Maximum Product Subarray</b>
            </Span>
            <Span>
              Given an integer array nums, find a contiguous non-empty subarray
              within the array that has the largest product, and return the
              product.
              <br />
              The test cases are generated so that the answer will fit in a
              32-bit integer.
              <br />A subarray is a contiguous subsequence of the array.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [2,3,-2,4]
              <br />
              Output: 6<br />
              Explanation: [2,3] has the largest product 6.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [-2,0,-1]
              <br />
              Output: 0<br />
              Explanation: The result cannot be 2, because [-2,-1] is not a
              subarray.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 2 * 104 <br />
              -10 &lt;= nums[i] &lt;= 10 <br />
              The product of any prefix or suffix of nums is guaranteed to fit
              in a 32-bit integer.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q152. Maximum Product Subarray",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var maxProduct = function(nums) {
                     let ans = prevMin = prevMax = nums[0]
                     for(let i = 1;i<nums.length;i++){
                       let mini = prevMin * nums[i]
                       let maxi = prevMax * nums[i]
                       prevMin = Math.min(nums[i], mini, maxi)
                       prevMax = Math.max(nums[i], mini, maxi)
                       ans = Math.max(ans, prevMax)
                     }
                     return ans
                   };
                   
                   maxProduct([2,3,-2,4])`,
                    output: `6`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q153: {
        title: "Q153. Find Minimum in Rotated Sorted Array",
        content: (
          <>
            <Span>
              <b>Q153. Find Minimum in Rotated Sorted Array</b>
            </Span>
            <Span>
              Suppose an array of length n sorted in ascending order is rotated
              between 1 and n times. For example, the array nums =
              [0,1,2,4,5,6,7] might become:
            </Span>
            <Span>
              [4,5,6,7,0,1,2] if it was rotated 4 times. <br />
              [0,1,2,4,5,6,7] if it was rotated 7 times.
            </Span>
            <Span>
              Notice that rotating an array [a[0], a[1], a[2], ..., a[n-1]] 1
              time results in the array [a[n-1], a[0], a[1], a[2], ..., a[n-2]].
            </Span>
            <Span>
              Given the sorted rotated array nums of unique elements, return the
              minimum element of this array.
            </Span>
            <Span>You must write an algorithm that runs in O(log n) time.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [3,4,5,1,2]
              <br />
              Output: 1<br />
              Explanation: The original array was [1,2,3,4,5] rotated 3 times.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [4,5,6,7,0,1,2]
              <br />
              Output: 0<br />
              Explanation: The original array was [0,1,2,4,5,6,7] and it was
              rotated 4 times.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [11,13,15,17]
              <br />
              Output: 11
              <br />
              Explanation: The original array was [11,13,15,17] and it was
              rotated 4 times.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              n == nums.length <br />
              1 &lt;= n &lt;= 5000 <br />
              -5000 &lt;= nums[i] &lt;= 5000 <br />
              All the integers of nums are unique. <br />
              nums is sorted and rotated between 1 and n times.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q153. Find Minimum in Rotated Sorted Array",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var findMin = function(nums) {
                     let l = 0;
                     let r = nums.length - 1;
                     while (l < r) {
                       const m = l + parseInt((r - l) / 2);
                       if (nums[m] < nums[r])
                         r = m;
                       else
                         l = m + 1;
                     }
                     return nums[l];
                   };
                   
                   findMin([11,13,15,17])`,
                    output: `11`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q154: {
        title: "Q154. Find Minimum in Rotated Sorted Array II",
        content: (
          <>
            <Span>
              <b>Q154. Find Minimum in Rotated Sorted Array II</b>
            </Span>
            <Span>
              Suppose an array of length n sorted in ascending order is rotated
              between 1 and n times. For example, the array nums =
              [0,1,4,4,5,6,7] might become:
            </Span>
            <Span>
              [4,5,6,7,0,1,4] if it was rotated 4 times. <br />
              [0,1,4,4,5,6,7] if it was rotated 7 times.
            </Span>
            <Span>
              Notice that rotating an array [a[0], a[1], a[2], ..., a[n-1]] 1
              time results in the array [a[n-1], a[0], a[1], a[2], ..., a[n-2]].
            </Span>
            <Span>
              Given the sorted rotated array nums that may contain duplicates,
              return the minimum element of this array.
            </Span>
            <Span>
              You must decrease the overall operation steps as much as possible.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,3,5]
              <br />
              Output: 1
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [2,2,2,0,1]
              <br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              n == nums.length
              <br />
              1 &lt;= n &lt;= 5000
              <br />
              -5000 &lt;= nums[i] &lt;= 5000
              <br />
              nums is sorted and rotated between 1 and n times.
            </Span>
            <Span>
              <b>Follow up: </b>
              This problem is similar to Find Minimum in Rotated Sorted Array,
              but nums may contain duplicates. Would this affect the runtime
              complexity? How and why?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n -&gt; n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q154. Find Minimum in Rotated Sorted Array II",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var findMin = function(nums) {
                     let l = 0;
                     let r = nums.length - 1;
                     while (l < r) {
                       const m = l + parseInt((r - l) / 2);
                       if (nums[m] === nums[r])
                         r-=1
                       else if (nums[m] < nums[r])
                         r = m;
                       else
                         l = m + 1;
                     }
                     return nums[l];
                   };
                   
                   findMin([1,3,3])`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q155: {
        title: "Q155. Min Stack",
        content: (
          <>
            <Span>
              <b>Q155. Min Stack</b>
            </Span>
            <Span>
              Design a stack that supports push, pop, top, and retrieving the
              minimum element in constant time.
            </Span>
            <Span>Implement the MinStack class:</Span>
            <Span>
              MinStack() initializes the stack object.
              <br />
              void push(int val) pushes the element val onto the stack.
              <br />
              void pop() removes the element on the top of the stack.
              <br />
              int top() gets the top element of the stack.
              <br />
              int getMin() retrieves the minimum element in the stack.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input <br />
              ["MinStack","push","push","push","getMin","pop","top","getMin"]{" "}
              <br />
              [[],[-2],[0],[-3],[],[],[],[]] <br />
              <br />
              Output <br />
              [null,null,null,null,-3,null,0,-2]
              <br />
              Explanation
              <br />
              MinStack minStack = new MinStack();
              <br />
              minStack.push(-2);
              <br />
              minStack.push(0);
              <br />
              minStack.push(-3);
              <br />
              minStack.getMin(); // return -3
              <br />
              minStack.pop();
              <br />
              minStack.top(); // return 0 minStack.getMin(); // return -2
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              -231 &lt;= val &lt;= 231 - 1 <br />
              Methods pop, top and getMin operations will always be called on
              non-empty stacks. <br />
              At most 3 * 104 calls will be made to push, pop, top, and getMin.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q155. Min Stack",
                codes: {
                  Javascript: {
                    code: `var MinStack = function() {
                      this.stack = []
                    };
                    
                    /** 
                     * @param {number} val
                     * @return {void}
                     */
                    MinStack.prototype.push = function(val) {
                      let min = val
                      if(this.stack.length) min = Math.min(this.stack.slice(-1)[0][1],val)
                      this.stack.push([val,min])
                    };
                    
                    /**
                     * @return {void}
                     */
                    MinStack.prototype.pop = function() {
                      this.stack.pop()  
                    };
                    
                    /**
                     * @return {number}
                     */
                    MinStack.prototype.top = function() {
                      return this.stack.slice(-1)[0][0] 
                    };
                    
                    /**
                     * @return {number}
                     */
                    MinStack.prototype.getMin = function() {
                      return this.stack.slice(-1)[0][1]
                    };
                    
                    var obj = new MinStack()
                    obj.push(1)
                    obj.push(2)
                    console.log(obj.top())
                    obj.pop()
                    console.log(obj.getMin())
                    `,
                    output: `2
                    1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q156: {
        title: "Q160. Intersection of Two Linked Lists (Q156)",
        content: (
          <>
            <Span>
              <b>Q160. Intersection of Two Linked Lists (Q156)</b>
            </Span>
            <Span>
              Given the heads of two singly linked-lists headA and headB, return
              the node at which the two lists intersect. If the two linked lists
              have no intersection at all, return null.
            </Span>
            <Span>
              For example, the following two linked lists begin to intersect at
              node c1:
            </Span>
            <Span>
              The test cases are generated such that there are no cycles
              anywhere in the entire linked structure.
            </Span>
            <Span>
              Note that the linked lists must retain their original structure
              after the function returns.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode160} left />
            <Span>
              Input: intersectVal = 8, listA = [4,1,8,4,5], listB =
              [5,6,1,8,4,5], skipA = 2, skipB = 3
              <br />
              Output: Intersected at '8'
              <br />
              Explanation: The intersected node's value is 8 (note that this
              must not be 0 if the two lists intersect).
              <br />
              From the head of A, it reads as [4,1,8,4,5]. From the head of B,
              it reads as [5,6,1,8,4,5]. There are 2 nodes before the
              intersected node in A; There are 3 nodes before the intersected
              node in B.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: intersectVal = 2, listA = [1,9,1,2,4], listB = [3,2,4],
              skipA = 3, skipB = 1
              <br />
              Output: Intersected at '2'
              <br />
              Explanation: The intersected node's value is 2 (note that this
              must not be 0 if the two lists intersect).
              <br />
              From the head of A, it reads as [1,9,1,2,4]. From the head of B,
              it reads as [3,2,4]. There are 3 nodes before the intersected node
              in A; There are 1 node before the intersected node in B.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: intersectVal = 0, listA = [2,6,4], listB = [1,5], skipA =
              3, skipB = 2
              <br />
              Output: No intersection
              <br />
              Explanation: From the head of A, it reads as [2,6,4]. From the
              head of B, it reads as [1,5]. Since the two lists do not
              intersect, intersectVal must be 0, while skipA and skipB can be
              arbitrary values.
              <br />
              Explanation: The two lists do not intersect, so return null.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes of listA is in the m. <br />
              The number of nodes of listB is in the n. <br />
              1 &lt;= m, n &lt;= 3 * 104
              <br />
              1 &lt;= Node.val &lt;= 105
              <br />
              0 &lt;= skipA &lt; m<br />
              0 &lt;= skipB &lt; n<br />
              intersectVal is 0 if listA and listB do not intersect.
              <br />
              intersectVal == listA[skipA] == listB[skipB] if listA and listB
              intersect.
              <br />
            </Span>
            <Span>
              <b>Follow up: </b>
              Could you write a solution that runs in O(m + n) time and use only
              O(1) memory?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q160. Intersection of Two Linked Lists (Q156)",
                codes: {
                  Javascript: {
                    code: `function ListNode(val) {
                      this.val = val;
                      this.next = null;
                  }
                  
                  /**
                   * @param {ListNode} headA
                   * @param {ListNode} headB
                   * @return {ListNode}
                   */
                  var getIntersectionNode = function(headA, headB) {
                    let a = headA
                    let b = headB
                    while (a != b) {
                      a = a ? a.next : headB;
                      b = b ? b.next : headA;
                    }
                    return a
                  };
                  
                  const c = new ListNode(8)
                  c.next = new ListNode(4)
                  c.next.next = new ListNode(5)
                  const l1 = new ListNode(4)
                  l1.next = new ListNode(1)
                  l1.next.next = c
                  const l2 = new ListNode(5)
                  l2.next = new ListNode(6)
                  l2.next.next = new ListNode(1)
                  l2.next.next.next = c
                  
                  getIntersectionNode(l1,l2)`,
                    output: `ListNode {
                      val: 8,
                      next: ListNode { val: 4, next: ListNode { val: 5, next: null } }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q157: {
        title: "Q162. Find Peak Element (Q157)",
        content: (
          <>
            <Span>
              <b>Q162. Find Peak Element (Q157)</b>
            </Span>
            <Span>
              A peak element is an element that is strictly greater than its
              neighbors.
            </Span>
            <Span>
              Given an integer array nums, find a peak element, and return its
              index. If the array contains multiple peaks, return the index to
              any of the peaks.
            </Span>
            <Span>You may imagine that nums[-1] = nums[n] = -∞.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>You must write an algorithm that runs in O(log n) time.</Span>
            <Span>
              Input: nums = [1,2,3,1]
              <br />
              Output: 2<br />
              Explanation: 3 is a peak element and your function should return
              the index number 2.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              <br />
              Input: nums = [1,2,1,3,5,6,4] Output: 5<br /> Explanation: Your
              function can return either index number 1 where the peak element
              is 2, or index number 5 where the peak element is 6.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 1000 <br />
              -231 &lt;= nums[i] &lt;= 231 - 1 <br />
              nums[i] != nums[i + 1] for all valid i.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q162. Find Peak Element (Q157)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var findPeakElement = function(nums) {
                     let l = 0;
                     let r = nums.length - 1;
                     while (l < r) {
                       let m = l + parseInt((r - l) / 2);
                       if (nums[m] >= nums[m + 1])
                         r = m;
                       else
                         l = m + 1;
                     }
                     return l;
                   };
                   
                   findPeakElement([1,2,3,1])`,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q158: {
        title: "Q164. Maximum Gap (Q158)",
        content: (
          <>
            <Span>
              <b>Q164. Maximum Gap (Q158)</b>
            </Span>
            <Span>
              Given an integer array nums, return the maximum difference between
              two successive elements in its sorted form. If the array contains
              less than two elements, return 0.
            </Span>
            <Span>
              You must write an algorithm that runs in linear time and uses
              linear extra space.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [3,6,9,1]
              <br />
              Output: 3<br />
              Explanation: The sorted form of the array is [1,3,6,9], either
              (3,6) or (6,9) has the maximum difference 3.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [10] <br />
              Output: 0 <br />
              Explanation: The array contains less than 2 elements, therefore
              return 0.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 105 <bt />0 &lt;= nums[i] &lt;= 109
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   
                   class Bucket{
                     constructor(min=Number.MAX_SAFE_INTEGER,max=Number.MIN_SAFE_INTEGER){
                       this.min = min 
                       this.max = max
                     }
                   }
                   
                   var maximumGap = function(nums) {
                     if(nums.length < 2) return 0
                       let min = Math.min(...nums)
                       let max = Math.max(...nums)
                       if(min == max)
                         return 0
                       let gap = Math.ceil((max - min) / (nums.length - 1))
                       let bucketSize = parseInt((max - min) / gap)+1
                       const buckets = []
                       for(let i=0;i<bucketSize;i++)
                       buckets.push(new Bucket())
                       for(let num of nums){
                         let i = parseInt((num - min) / gap)
                         buckets[i].min = Math.min(buckets[i].min, num)
                         buckets[i].max = Math.max(buckets[i].max, num)
                       }
                       let ans = 0
                       let prevMax = min
                       for(let bucket of buckets){
                         if(bucket.min == Number.MAX_SAFE_INTEGER) continue  
                         ans = Math.max(ans, bucket.min - prevMax)
                         prevMax = bucket.max
                       }
                       return ans
                   };
                   
                   maximumGap([3,6,9,1])
                   `,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q159: {
        title: "Q165. Compare Version Numbers (Q159)",
        content: (
          <>
            <Span>
              <b>Q165. Compare Version Numbers (Q159)</b>
            </Span>
            <Span>
              Given two version numbers, version1 and version2, compare them.
            </Span>
            <Span>
              Version numbers consist of one or more revisions joined by a dot
              '.'. Each revision consists of digits and may contain leading
              zeros. Every revision contains at least one character. Revisions
              are 0-indexed from left to right, with the leftmost revision being
              revision 0, the next revision being revision 1, and so on. For
              example 2.5.33 and 0.1 are valid version numbers.
            </Span>
            <Span>
              To compare version numbers, compare their revisions in
              left-to-right order. Revisions are compared using their integer
              value ignoring any leading zeros. This means that revisions 1 and
              001 are considered equal. If a version number does not specify a
              revision at an index, then treat the revision as 0. For example,
              version 1.0 is less than version 1.1 because their revision 0s are
              the same, but their revision 1s are 0 and 1 respectively, and 0
              &lt; 1.
            </Span>
            <Span>
              Return the following:
              <br />
              If version1 &lt; version2, return -1.
              <br />
              If version1 &gt; version2, return 1.
              <br />
              Otherwise, return 0.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: version1 = "1.01", version2 = "1.001"
              <br />
              Output: 0<br />
              Explanation: Ignoring leading zeroes, both "01" and "001"
              represent the same integer "1".
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: version1 = "1.0", version2 = "1.0.0"
              <br />
              Output: 0<br />
              Explanation: version1 does not specify revision 2, which means it
              is treated as "0".
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: version1 = "0.1", version2 = "1.1" Output: -1 Explanation:
              version1's revision 0 is "0", while version2's revision 0 is "1".
              0 &lt; 1, so version1 &lt; version2.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= version1.length, version2.length &lt;= 500 <br />
              version1 and version2 only contain digits and '.'. <br />
              version1 and version2 are valid version numbers. <br />
              All the given revisions in version1 and version2 can be stored in
              a 32-bit integer.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q165. Compare Version Numbers (Q159)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} version1
                    * @param {string} version2
                    * @return {number}
                    */
                   var compareVersion = function(version1, version2) {
                     const l1 = version1.split('.')
                     const l2 = version2.split('.')
                     const len = Math.max(l1.length,l2.length)
                     for(let i=0;i<len;i++){
                       let v1 = v2 =0
                       if(i<l1.length) v1 = +l1[i]
                       if(i<l2.length) v2 = +l2[i]
                       if(v1 < v2) return -1
                       if(v1 > v2) return 1
                     }
                     return 0
                   };
                   
                   compareVersion("0.1","1.1")`,
                    output: `-1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q160: {
        title: "Q166. Fraction to Recurring Decimal (Q160)",
        content: (
          <>
            <Span>
              <b>Q166. Fraction to Recurring Decimal (Q160)</b>
            </Span>
            <Span>
              Given two integers representing the numerator and denominator of a
              fraction, return the fraction in string format.
            </Span>
            <Span>
              If the fractional part is repeating, enclose the repeating part in
              parentheses.
            </Span>
            <Span>If multiple answers are possible, return any of them.</Span>
            <Span>
              It is guaranteed that the length of the answer string is less than
              104 for all the given inputs.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: numerator = 1, <br />
              denominator = 2 <br />
              Output: "0.5"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: numerator = 2,
              <br /> denominator = 1<br />
              Output: "2"
            </Span>
            <Span>
              Input: numerator = 4,
              <br /> denominator = 333
              <br />
              Output: "0.(012)"
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              -231 &lt;= numerator, denominator &lt;= 231 - 1 <br />
              denominator != 0
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q166. Fraction to Recurring Decimal (Q160)",
                codes: {
                  Java: {
                    code: `import java.util.*;

                    class Solution {
                        public String fractionToDecimal(int numerator, int denominator) {
                       if (numerator == 0)
                          return "0";
                        StringBuilder sb = new StringBuilder();
                        if (numerator < 0 ^ denominator < 0)
                          sb.append("-");
                        long n = Math.abs((long) numerator);
                        long d = Math.abs((long) denominator);
                        sb.append(n / d);
                        if (n % d == 0)
                          return sb.toString();
                        sb.append(".");
                        Map<Long, Integer> seen = new HashMap<>();
                        for (long r = n % d; r > 0; r %= d) {
                          if (seen.containsKey(r)) {
                            sb.insert(seen.get(r), "(");
                            sb.append(")");
                            break;
                          }
                          seen.put(r, sb.length());
                          r *= 10;
                          sb.append(r / d);
                        }
                        return sb.toString();
                        }
                    
                        public static void Solution(String ...s){
                          System.out.println(new Solution().fractionToDecimal(2,1));
                        }
                    }`,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q161: {
        title: "Q167. Two Sum II - Input Array Is Sorted (Q161)",
        content: (
          <>
            <Span>
              <b>Q167. Two Sum II - Input Array Is Sorted (Q161)</b>
            </Span>
            <Span>
              Given a 1-indexed array of integers numbers that is already sorted
              in non-decreasing order, find two numbers such that they add up to
              a specific target number. Let these two numbers be numbers[index1]
              and numbers[index2] where 1 &lt;= index1 &lt; index2 &lt;=
              numbers.length.
            </Span>
            <Span>
              Return the indices of the two numbers, index1 and index2, added by
              one as an integer array [index1, index2] of length 2.
            </Span>
            <Span>
              The tests are generated such that there is exactly one solution.
              You may not use the same element twice.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: numbers = [2,7,11,15], target = 9<br />
              Output: [1,2]
              <br />
              Explanation: The sum of 2 and 7 is 9. Therefore, index1 = 1,
              index2 = 2. We return [1, 2].
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: numbers = [-1,0], target = -1
              <br />
              Output: [1,2]
              <br />
              Explanation: The sum of -1 and 0 is -1. Therefore index1 = 1,
              index2 = 2. We return [1, 2].
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              2 &lt;= numbers.length &lt;= 3 * 104
              <br />
              -1000 &lt;= numbers[i] &lt;= 1000
              <br />
              numbers is sorted in non-decreasing order.
              <br />
              -1000 &lt;= target &ly;= 1000
              <br />
              The tests are generated such that there is exactly one solution.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q167. Two Sum II - Input Array Is Sorted (Q161)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} numbers
                    * @param {number} target
                    * @return {number[]}
                    */
                   var twoSum = function(numbers, target) {
                     let l = 0;
                     let r = numbers.length - 1;
                     while (numbers[l] + numbers[r] != target)
                     if (numbers[l] + numbers[r] < target)
                       ++l;
                     else
                     --r;
                     return [l + 1, r + 1] 
                   };
                   
                   twoSum([2,3,4],6)`,
                    output: `[1,3]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q162: {
        title: "Q168. Excel Sheet Column Title (Q162)",
        content: (
          <>
            <Span>
              <b>Q168. Excel Sheet Column Title (Q162)</b>
            </Span>
            <Span>
              Given an integer columnNumber, return its corresponding column
              title as it appears in an Excel sheet.
            </Span>
            <Span>
              For example:
              <br />
              A -&gt; 1<br />
              B -&gt; 2<br />
              C -&gt; 3<br />
              ...
              <br />
              Z -&gt; 26
              <br />
              AA -&gt; 27
              <br />
              AB -&gt; 28 <br />
              ...
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: columnNumber = 1 <br />
              Output: "A"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: columnNumber = 28
              <br />
              Output: "AB"
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: columnNumber = 701 <br />
              Output: "ZY"
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= columnNumber &lt;= 2^31 - 1</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q168. Excel Sheet Column Title (Q162)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} columnNumber
                    * @return {string}
                    */
                   var convertToTitle = function(columnNumber) {
                     let n = columnNumber
                     return n == 0 ? "" : convertToTitle(parseInt((n - 1) / 26)) +String.fromCharCode(65+parseInt(((n - 1) % 26)))
                   };
                   
                   convertToTitle(28)`,
                    output: `AB`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q163: {
        title: "Q169. Majority Element (Q163)",
        content: (
          <>
            <Span>
              <b>Q169. Majority Element (Q163)</b>
            </Span>
            <Span>
              Given an array nums of size n, return the majority element.
            </Span>{" "}
            <Span>
              The majority element is the element that appears more than ⌊n / 2⌋
              times. You may assume that the majority element always exists in
              the array.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [3,2,3] <br />
              Output: 3
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [3,2,3] <br />
              Output: 3
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              n == nums.length <br />
              1 &lt;= n &lt;= 5 * 104
              <br />
              -231 &lt;= nums[i] &lt;= 231 - 1
            </Span>
            <Span>
              <b>Follow-up: </b>
              Could you solve the problem in linear time and in O(1) space?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q169. Majority Element (Q163)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var majorityElement = function(nums) {
                     let ans = null;
                     let count = 0;
                     for (let num of nums) {
                       if (count == 0)
                         ans = num;
                       count += num == ans ? 1 : -1;
                     }
                       return ans;
                   };
                   
                   majorityElement([3,2,3])`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q164: {
        title: "Q171. Excel Sheet Column Number (Q164)",
        content: (
          <>
            <Span>
              <b>Q171. Excel Sheet Column Number (Q164)</b>
            </Span>
            <Span>
              Given a string columnTitle that represents the column title as
              appear in an Excel sheet, return its corresponding column number.
            </Span>
            <Span>
              For example:
              <br />
              A -&gt; 1<br />
              B -&gt; 2<br />
              C -&gt; 3<br />
              ...
              <br />
              Z -&gt; 26
              <br />
              AA -&gt; 27
              <br />
              AB -&gt; 28 <br />
              ...
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: columnTitle = "A"
              <br />
              Output: 1
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: columnTitle = "AB" <br />
              Output: 28
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: columnTitle = "ZY"
              <br />
              Output: 701
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= columnTitle.length &lt;= 7<br />
              columnTitle consists only of uppercase English letters.
              <br />
              columnTitle is in the range ["A", "FXSHRXW"].
              <br />
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q171. Excel Sheet Column Number (Q164)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} columnTitle
                    * @return {number}
                    */
                   var titleToNumber = function(columnTitle) {
                     let ans = 0;
                     for (let c of columnTitle)
                     ans = ans * 26 + c.charCodeAt(0) - 64;
                     return ans;  
                   };
                   
                   titleToNumber("A")`,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q165: {
        title: "Q172. Factorial Trailing Zeroes (Q165)",
        content: (
          <>
            <Span>
              <b>Q172. Factorial Trailing Zeroes (Q165)</b>
            </Span>
            <Span>
              Given an integer n, return the number of trailing zeroes in n!.
            </Span>
            <Span>Note that n! = n * (n - 1) * (n - 2) * ... * 3 * 2 * 1.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 3 <br />
              Output: 0 <br />
              Explanation: 3! = 6, no trailing zero.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 5 <br />
              Output: 1 <br />
              Explanation: 5! = 120, one trailing zero.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: n = 0<br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>0 &lt;= n &lt;= 104</Span>
            <Span>
              <b>Follow up :</b>
              Could you write a solution that works in logarithmic time
              complexity?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log5 n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q172. Factorial Trailing Zeroes",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number}
                    */
                   var trailingZeroes = function(n) {
                     return n == 0 ? 0 : (parseInt(n / 5) + trailingZeroes(parseInt(n / 5)));
                   };
                   
                   trailingZeroes(5)`,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q166: {
        title: "Q173. Binary Search Tree Iterator (Q166)",
        content: (
          <>
            <Span>
              <b>Q173. Binary Search Tree Iterator (Q166)</b>
            </Span>
            <Span>
              Implement the BSTIterator class that represents an iterator over
              the in-order traversal of a binary search tree (BST):
            </Span>
            <Span>
              BSTIterator(TreeNode root) Initializes an object of the
              BSTIterator class. The root of the BST is given as part of the
              constructor. The pointer should be initialized to a non-existent
              number smaller than any element in the BST. <br />
              boolean hasNext() Returns true if there exists a number in the
              traversal to the right of the pointer, otherwise returns false.
              <br />
              int next() Moves the pointer to the right, then returns the number
              at the pointer.
            </Span>
            <Span>
              Notice that by initializing the pointer to a non-existent smallest
              number, the first call to next() will return the smallest element
              in the BST.
            </Span>
            <Span>
              You may assume that next() calls will always be valid. That is,
              there will be at least a next number in the in-order traversal
              when next() is called.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode173} left />
            <Span>
              Input
              <br />
              ["BSTIterator", "next", "next", "hasNext", "next", "hasNext",
              "next", "hasNext", "next", "hasNext"] [[[7, 3, 15, null, null, 9,
              20]], [], [], [], [], [], [], [], [], []]
              <br />
              Output <br />
              [null, 3, 7, true, 9, true, 15, true, 20, false]
              <br />
              Explanation <br />
              BSTIterator bSTIterator = new BSTIterator([7, 3, 15, null, null,
              9, 20]);
              <br />
              bSTIterator.next(); // return 3
              <br />
              bSTIterator.next(); // return 7
              <br />
              bSTIterator.hasNext(); // return True
              <br />
              bSTIterator.next(); // return 9
              <br />
              bSTIterator.hasNext(); // return True
              <br />
              bSTIterator.next(); // return 15
              <br />
              bSTIterator.hasNext(); // return True
              <br />
              bSTIterator.next(); // return 20 bSTIterator.hasNext(); // return
              False
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [1, 105]. <br />
              0 &lt;= Node.val &lt;= 106 <br />
              At most 105 calls will be made to hasNext, and next.
            </Span>
            <Span>
              Could you implement next() and hasNext() to run in average O(1)
              time and use O(h) memory, where h is the height of the tree?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(h)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q173. Binary Search Tree Iterator (Q166)",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    
                    /**
                     * @param {TreeNode} head
                     */
                    var BSTIterator = function(head) {
                      this.stack = []
                      this.unshift(head)
                    };
                    
                    /**
                     * @return {number}
                     */
                    BSTIterator.prototype.next = function() {
                      let head = this.stack.pop()
                      this.unshift(head.right)
                      return head.val
                    };
                    
                    /**
                     * @return {boolean}
                     */
                    BSTIterator.prototype.hasNext = function() {
                      return this.stack  
                    };
                    
                    BSTIterator.prototype.unshift = function(head) {
                      while(head){
                        this.stack.push(head)
                        head = head.left
                      }  
                    };
                    
                    
                    const head = new TreeNode(7)
                    head.left = new TreeNode(3)
                    head.right = new TreeNode(15)
                    head.right.left = new TreeNode(9)
                    head.right.right = new TreeNode(20)
                    
                    var obj = new BSTIterator(head)
                    var param_1 = obj.next()
                    var param_2 = obj.hasNext()
                    console.log({param_1,param_2})`,
                    output: `{
                      param_1: 3,
                      param_2: [ TreeNode { val: 7, left: [TreeNode], right: [TreeNode] } ]
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q167: {
        title: "Q174. Dungeon Game (Q167)",
        content: (
          <>
            <Span>
              <b>Q174. Dungeon Game (Q167)</b>
            </Span>
            <Span>
              The demons had captured the princess and imprisoned her in the
              bottom-right corner of a dungeon. The dungeon consists of m x n
              rooms laid out in a 2D grid. Our valiant knight was initially
              positioned in the top-left room and must fight his way through
              dungeon to rescue the princess.
            </Span>
            <Span>
              The knight has an initial health point represented by a positive
              integer. If at any point his health point drops to 0 or below, he
              dies immediately.
            </Span>
            <Span>
              Some of the rooms are guarded by demons (represented by negative
              integers), so the knight loses health upon entering these rooms;
              other rooms are either empty (represented as 0) or contain magic
              orbs that increase the knight's health (represented by positive
              integers).
            </Span>
            <Span>
              To reach the princess as quickly as possible, the knight decides
              to move only rightward or downward in each step.
            </Span>
            <Span>
              Return the knight's minimum initial health so that he can rescue
              the princess.
            </Span>
            <Span>
              Note that any room can contain threats or power-ups, even the
              first room the knight enters and the bottom-right room where the
              princess is imprisoned.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode174} left />
            <Span>
              Input: dungeon = [[-2,-3,3],[-5,-10,1],[10,30,-5]]
              <br />
              Output: 7<br />
              Explanation: The initial health of the knight must be at least 7
              if he follows the optimal path: RIGHT-&gt; RIGHT -&gt; DOWN -&gt;
              DOWN.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: dungeon = [[0]]
              <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == dungeon.length <br />
              n == dungeon[i].length <br />
              1 &lt;= m, n &lt;= 200 <br />
              -1000 &lt;= dungeon[i][j] &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(mn -&gt; n)
            </p>
            <CodeEditor
              options={{
                title: "Q174. Dungeon Game (Q167)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[][]} dungeon
                    * @return {number}
                    */
                   var calculateMinimumHP = function(dungeon){
                     const m = dungeon.length
                     const n = dungeon[0].length
                     const dp = Array(n+1).fill(Number.MAX_SAFE_INTEGER)
                     dp[n-1] = 1
                     for (let i = m - 1; i >= 0; --i)
                       for (let j = n - 1; j >= 0; --j) {
                       dp[j] = Math.min(dp[j], dp[j + 1]) - dungeon[i][j];
                       dp[j] = Math.max(dp[j], 1);
                     }
                     return dp[0]
                   };
                   calculateMinimumHP([[-2,-3,3],[-5,-10,1],[10,30,-5]])`,
                    output: `7`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q168: {
        title: "Q175. Combine Two Tables (Q168)",
        content: (
          <>
            <Span>
              <b>Q175. Combine Two Tables (Q168)</b>
            </Span>
            <p>Table: Person</p>
            <pre>
              {`
+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| personId    | int     |
| lastName    | varchar |
| firstName   | varchar |
+-------------+---------+
                `}
            </pre>
            <Span>
              personId is the primary key column for this table. This table
              contains information about the ID of some persons and their first
              and last names.
            </Span>
            <p>
              <b>Example 2:</b>
            </p>
            <pre>
              {`
+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| addressId   | int     |
| personId    | int     |
| city        | varchar |
| state       | varchar |
+-------------+---------+
                `}
            </pre>
            <Span>
              addressId is the primary key column for this table. Each row of
              this table contains information about the city and state of one
              person with ID = PersonId.
            </Span>
            <Span>
              Return the result table in any order.
              <br />
              The query result format is in the following example.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <p>
              Input: <br />
              Person table:
            </p>
            <pre>
              {`
+----------+----------+-----------+
| personId | lastName | firstName |
+----------+----------+-----------+
| 1        | Wang     | Allen     |
| 2        | Alice    | Bob       |
+----------+----------+-----------+
                `}
            </pre>
            <p>Address table:</p>
            <pre>
              {`
+-----------+----------+---------------+------------+
| addressId | personId | city          | state      |
+-----------+----------+---------------+------------+
| 1         | 2        | New York City | New York   |
| 2         | 3        | Leetcode      | California |
+-----------+----------+---------------+------------+  
  `}
            </pre>
            <p>Output</p>
            <pre>
              {`
+-----------+----------+---------------+----------+
| firstName | lastName | city          | state    |
+-----------+----------+---------------+----------+
| Allen     | Wang     | Null          | Null     |
| Bob       | Alice    | New York City | New York |
+-----------+----------+---------------+----------+ 
`}
            </pre>
            <br />
            <Span>Explanation:</Span>
            <Span>
              There is no address in the address table for the personId = 1 so
              we return null in their city and state. addressId = 1 contains
              information about the address of personId = 2.
            </Span>
            <CodeEditor
              options={{
                title: "Q175. Combine Two Tables (Q168)",
                codes: {
                  Mysql: {
                    code: `select FirstName, LastName, City, State
                    from Person left join Address
                    on Person.PersonId = Address.PersonId
                    ;`,
                    output: `{"headers": ["FirstName", "LastName", "City", "State"],
                     "values": [["Allen", "Wang", null, null], ["Bob", "Alice", "New York City", "New York"]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q169: {
        title: "Q176. Second Highest Salary (Q169)",
        content: (
          <>
            <Span>
              <b>Q176. Second Highest Salary (Q169)</b>
            </Span>

            <Span>
              Table: Employee
              <br />
              <pre>
                {`
+-------------+------+
| Column Name | Type |
+-------------+------+
| id          | int  |
| salary      | int  |
+-------------+------+ 
  `}
              </pre>
              <br />
              id is the primary key column for this table.
              <br />
              Each row of this table contains information about t
            </Span>
            <Span>
              {" "}
              Write an SQL query to report the second highest salary from the
              Employee table. If there is no second highest salary, the query
              should report null.
              <br />
              The query result format is in the following example.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <p>
              Input: <br />
              Employee table: <br />
              <pre>
                {`
+----+--------+
| id | salary |
+----+--------+
| 1  | 100    |
| 2  | 200    |
| 3  | 300    |
+----+--------+
  
  `}
              </pre>
              Output: <br />
              <pre>
                {`
+---------------------+
| SecondHighestSalary |
+---------------------+
| 200                 |
+---------------------+
  `}
              </pre>
            </p>
            <Span>
              <b>Example 2:</b>
            </Span>
            <p>
              Input: Employee table:
              <pre>
                {`
+----+--------+
| id | salary |
+----+--------+
| 1  | 100    |
+----+--------+ 
 `}{" "}
              </pre>
              <br />
              Output:
              <br />
              <pre>
                {`
+---------------------+
| SecondHighestSalary |
+---------------------+
| null                |
+---------------------+  
  `}{" "}
              </pre>
            </p>

            <CodeEditor
              options={{
                title: "Q176. Second Highest Salary (Q169)",
                codes: {
                  Mysql: {
                    code: `SELECT
                    (SELECT DISTINCT
                            Salary
                        FROM
                            Employee
                        ORDER BY Salary DESC
                        LIMIT 1 OFFSET 1) AS SecondHighestSalary
                ;`,
                    output: `
                    {"headers": ["SecondHighestSalary"], 
                    "values": [[200]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q170: {
        title: "Q177. Nth Highest Salary (Q170)",
        content: (
          <>
            <Span>
              <b>Q177. Nth Highest Salary (Q170)</b>
            </Span>
            <p>Table: Employee</p>
            <pre>{`
+-------------+------+
| Column Name | Type |
+-------------+------+
| id          | int  |
| salary      | int  |
+-------------+------+   
            `}</pre>
            <Span>
              id is the primary key column for this table.
              <br /> Each row of this table contains information about the
              salary of an employee.
            </Span>
            <Span>
              Write an SQL query to report the nth highest salary from the
              Employee table. If there is no nth highest salary, the query
              should report null.
              <br />
              The query result format is in the following example.
            </Span>
            <p>
              <b>Example 1:</b>
            </p>
            <p>
              Input: <br />
              Employee table: <br />
              <pre>
                {`
+----+--------+
| id | salary |
+----+--------+
| 1  | 100    |
| 2  | 200    |
| 3  | 300    |
+----+--------+    
    `}
              </pre>
              n = 2 <br />
              Output: <br />
              <pre>
                {`
+------------------------+
| getNthHighestSalary(2) |
+------------------------+
| 200                    |
+------------------------+
    `}
              </pre>
            </p>
            <p>
              <b>Example 2:</b>
            </p>
            <p>
              Input: <br />
              Employee table:
              <br />
              <pre>
                {`
+----+--------+
| id | salary |
+----+--------+
| 1  | 100    |
+----+--------+    
    `}
              </pre>
              n = 2<br />
              Output: <br />
              <pre>
                {`
+------------------------+
| getNthHighestSalary(2) |
+------------------------+
| null                   |
+------------------------+        
    `}
              </pre>
            </p>
            <CodeEditor
              options={{
                title: "Q177. Nth Highest Salary (Q170)",
                codes: {
                  Mysql: {
                    code: `CREATE FUNCTION getNthHighestSalary(N INT) RETURNS INT
                    BEGIN
                      SET N = N - 1;
                      RETURN (
                          select distinct e.Salary
                          from Employee e
                          order by e.Salary desc
                          limit N, 1
                      );
                    END`,
                    output: `{"headers": ["getNthHighestSalary(2)"],
                     "values": [[200]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q171: {
        title: "Q178. Rank Scores (Q171)",
        content: (
          <>
            <Span>
              <b>Q178. Rank Scores (Q171)</b>
            </Span>
            <p>Table: Scores</p>
            <pre>
              {`
+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| id          | int     |
| score       | decimal |
+-------------+---------+
                `}
            </pre>
            <Span>
              id is the primary key for this table. Each row of this table
              contains the score of a game. Score is a floating point value with
              two decimal places.
            </Span>
            <Span>
              Write an SQL query to rank the scores. The ranking should be
              calculated according to the following rules:
              <br />
              <br />
              The scores should be ranked from the highest to the lowest.
              <br />
              If there is a tie between two scores, both should have the same
              ranking.
              <br />
              After a tie, the next ranking number should be the next
              consecutive integer value. In other words, there should be no
              holes between ranks.
              <br />
              Return the result table ordered by score in descending order.
              <br />
              The query result format is in the following example.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <p>
              Input: <br />
              Scores table: <br />
              <pre>
                {`
+----+-------+
| id | score |
+----+-------+
| 1  | 3.50  |
| 2  | 3.65  |
| 3  | 4.00  |
| 4  | 3.85  |
| 5  | 4.00  |
| 6  | 3.65  |
+----+-------+  
  `}
              </pre>
              Output: <br />
              <pre>
                {`
+-------+------+
| score | rank |
+-------+------+
| 4.00  | 1    |
| 4.00  | 1    |
| 3.85  | 2    |
| 3.65  | 3    |
| 3.65  | 3    |
| 3.50  | 4    |
+-------+------+
`}
              </pre>
            </p>
            <CodeEditor
              options={{
                title: "Q178. Rank Scores (Q171)",
                codes: {
                  Mysql: {
                    code: `# Write your MySQL query statement below

                    SELECT s.score, (SELECT count(distinct(score)) from scores m where m.score >= s.score) as "Rank" FROM scores s order by s.score desc;
                    
                    # SELECT
                    #     score,
                    #     DENSE_RANK() OVER(ORDER BY score DESC) as "Rank"
                    # FROM scores`,
                    output: `{"headers": ["score", "Rank"],
                     "values": [[4.00, 1], [4.00, 1], [3.85, 2], [3.65, 3], [3.65, 3], [3.50, 4]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q172: {
        title: "Q179. Largest Number (Q172)",
        content: (
          <>
            <Span>
              <b>Q179. Largest Number (Q172)</b>
            </Span>
            <Span>
              Given a list of non-negative integers nums, arrange them such that
              they form the largest number and return it.
            </Span>
            <Span>
              Since the result may be very large, so you need to return a string
              instead of an integer.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [10,2] <br />
              Output: "210"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [3,30,34,5,9]
              <br />
              Output: "9534330"
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 100 <br />0 &lt;= nums[i] &lt;= 109
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.log n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q179. Largest Number (Q172)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {string}
                    */
                   var largestNumber = function(nums) {
                     nums = nums.sort((a,b)=>(String(b)+String(a))-(String(a)+String(b))).join('')
                     return nums[0] == '0'?'0':nums
                   };
                   
                   largestNumber([10,2])`,
                    output: `210`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q173: {
        title: "Q180. Consecutive Numbers (Q173)",
        content: (
          <>
            <Span>
              <b>Q180. Consecutive Numbers (Q173)</b>
            </Span>
            <p>Table: Logs</p>
            <pre>
              {`
+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| id          | int     |
| num         | varchar |
+-------------+---------+
                `}
            </pre>
            <p>id is the primary key for this table.</p>
            <Span>
              Write an SQL query to find all numbers that appear at least three
              times consecutively.
            </Span>
            <Span>Return the result table in any order.</Span>
            <Span>The query result format is in the following example.</Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <p>
              Input: <br />
              Logs table: <br />
              <pre>
                {`
+----+-----+
| id | num |
+----+-----+
| 1  | 1   |
| 2  | 1   |
| 3  | 1   |
| 4  | 2   |
| 5  | 1   |
| 6  | 2   |
| 7  | 2   |
+----+-----+
`}
              </pre>
              <br />
              Output: <br />
              <pre>
                {`
+-----------------+
| ConsecutiveNums |
+-----------------+
| 1               |
+-----------------+
`}
              </pre>
              <br />
              Explanation: 1 is the only number that appears consecutively for
              at least three times.
            </p>
            <CodeEditor
              options={{
                title: "Q180. Consecutive Numbers (Q173)",
                codes: {
                  Mysql: {
                    code: `select distinct l1.Num as ConsecutiveNums
                    from Logs l1, Logs l2, Logs l3
                    where l2.Id = l1.Id+1 and l3.Id = l2.Id + 1 and l1.Num = l2.Num and l2.Num = l3.Num`,
                    output: `{"headers": ["ConsecutiveNums"],
                     "values": [[1]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q174: {
        title: "Q181. Employees Earning More Than Their Managers (Q174)",
        content: (
          <>
            <Span>
              <b>Q181. Employees Earning More Than Their Managers (Q174)</b>
            </Span>
            <Span>Table: Employee</Span>
            <pre>
              {`                
+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| id          | int     |
| name        | varchar |
| salary      | int     |
| managerId   | int     |
+-------------+---------+
                `}
            </pre>
            <Span>
              id is the primary key column for this table. <br />
              Each row of this table indicates the ID of an employee, their
              name, salary, and the ID of their manager.
            </Span>
            <Span>
              Write an SQL query to find the employees who earn more than their
              managers.
              <br />
              Return the result table in any order.
              <rb />
              The query result format is in the following example.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <p>
              Input: <br />
              Employee table: <br />
              <pre>
                {`
+----+-------+--------+-----------+
| id | name  | salary | managerId |
+----+-------+--------+-----------+
| 1  | Joe   | 70000  | 3         |
| 2  | Henry | 80000  | 4         |
| 3  | Sam   | 60000  | Null      |
| 4  | Max   | 90000  | Null      |
+----+-------+--------+-----------+
`}
              </pre>
              Output: <br />
              <pre>
                {`
+----------+
| Employee |
+----------+
| Joe      |
+----------+
`}
              </pre>
              Explanation: Joe is the only employee who earns more than his
              manager.
            </p>
            <CodeEditor
              options={{
                title:
                  "Q181. Employees Earning More Than Their Managers (Q174)",
                codes: {
                  Mysql: {
                    code: `select e1.Name as Employee
                    from Employee e1, Employee e2
                    where e1.ManagerId = e2.Id and e1.Salary > e2.Salary`,
                    output: `{"headers": ["Employee"],
                     "values": [["Joe"]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q175: {
        title: "Q182. Duplicate Emails (Q175)",
        content: (
          <>
            <Span>
              <b>Q182. Duplicate Emails (Q175)</b>
            </Span>
            <p>Table: Person</p>
            <pre>
              {`
+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| id          | int     |
| email       | varchar |
+-------------+---------+
                `}
            </pre>
            <Span>
              id is the primary key column for this table. <br />
              Each row of this table contains an email. The emails will not
              contain uppercase letters.
            </Span>
            <Span>
              Write an SQL query to report all the duplicate emails.
              <br />
              Return the result table in any order.
              <br />
              The query result format is in the following example.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <p>
              Input: <br />
              Person table: <br />
              <pre>
                {`
+----+---------+
| id | email   |
+----+---------+
| 1  | a@b.com |
| 2  | c@d.com |
| 3  | a@b.com |
+----+---------+
`}
              </pre>
              <br />
              Output: <br />
              <pre>
                {`
+---------+
| Email   |
+---------+
| a@b.com |
+---------+
`}
              </pre>{" "}
              <br />
              Explanation: a@b.com is repeated two times.
            </p>
            <CodeEditor
              options={{
                title: "Q182. Duplicate Emails (Q175)",
                codes: {
                  Mysql: {
                    code: `select distinct p1.Email
                    from Person p1, Person p2
                    where p1.Id <> p2.Id and p1.Email = p2.Email`,
                    output: `{"headers": ["Email"],
                     "values": [["a@b.com"]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q176: {
        title: "Q183. Customers Who Never Order (Q176)",
        content: (
          <>
            <Span>
              <b>Q183. Customers Who Never Order (Q176)</b>
            </Span>
            <Span>Table: Customers</Span>
            <pre>
              {`+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| id          | int     |
| name        | varchar |
+-------------+---------+`}
            </pre>
            <p>
              id is the primary key column for this table. Each row of this
              table indicates the ID and name of a customer.
            </p>
            <Span>Table: Orders</Span>
            <pre>
              {`
+-------------+------+
| Column Name | Type |
+-------------+------+
| id          | int  |
| customerId  | int  |
+-------------+------+
              `}
            </pre>
            <Span>
              id is the primary key column for this table. customerId is a
              foreign key of the ID from the Customers table. Each row of this
              table indicates the ID of an order and the ID of the customer who
              ordered it.
            </Span>
            <Span>
              Write an SQL query to report all customers who never order
              anything.
              <br />
              Return the result table in any order.
              <br />
              The query result format is in the following example.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <p>
              Input: <br />
              Customers table: <br />
              <pre>
                {`
+----+-------+
| id | name  |
+----+-------+
| 1  | Joe   |
| 2  | Henry |
| 3  | Sam   |
| 4  | Max   |
+----+-------+
`}
              </pre>
              <br />
              Orders table:
              <br />
              <pre>
                {`
+----+------------+
| id | customerId |
+----+------------+
| 1  | 3          |
| 2  | 1          |
+----+------------+
`}
              </pre>
              <br />
              Output:
              <pre>
                {`
+-----------+
| Customers |
+-----------+
| Henry     |
| Max       |
+-----------+
`}
              </pre>
            </p>
            <CodeEditor
              options={{
                title: "Q183. Customers Who Never Order (Q176)",
                codes: {
                  Mysql: {
                    code: `select c2.Name as Customers
                    from Customers c2
                    where c2.Id 
                    not in 
                    (select o.CustomerId
                    from Customers c, Orders o
                    where c.Id = o.CustomerId)`,
                    output: `{"headers": ["Customers"], "values": [["Henry"], ["Max"]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q177: {
        title: "Q184. Department Highest Salary (Q177)",
        content: (
          <>
            <Span>
              <b>Q184. Department Highest Salary (Q177)</b>
            </Span>
            <Span>SQL Schema</Span>
            <p> Table: Employee</p>
            <pre>
              {`
+--------------+---------+
| Column Name  | Type    |
+--------------+---------+
| id           | int     |
| name         | varchar |
| salary       | int     |
| departmentId | int     |
+--------------+---------+
                `}
            </pre>
            <p>
              id is the primary key column for this table. departmentId is a
              foreign key of the ID from the Department table. Each row of this
              table indicates the ID, name, and salary of an employee. It also
              contains the ID of their department.
            </p>
            <p> Table: Department</p>
            <pre>
              {`
+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| id          | int     |
| name        | varchar |
+-------------+---------+
                `}
            </pre>
            <p>
              id is the primary key column for this table. Each row of this
              table indicates the ID of a department and its name.
            </p>
            <Span>
              A company's executives are interested in seeing who earns the most
              money in each of the company's departments. A high earner in a
              department is an employee who has a salary in the top three unique
              salaries for that department.
              <br />
              Write an SQL query to find the employees who are high earners in
              each of the departments.
              <br />
              Return the result table in any order.
              <br />
              The query result format is in the following example.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <p>
              <p>
                Input: <br />
                Employee table: <br />
              </p>
            </p>
            <pre>
              {`
+----+-------+--------+--------------+
| id | name  | salary | departmentId |
+----+-------+--------+--------------+
| 1  | Joe   | 70000  | 1            |
| 2  | Jim   | 90000  | 1            |
| 3  | Henry | 80000  | 2            |
| 4  | Sam   | 60000  | 2            |
| 5  | Max   | 90000  | 1            |
+----+-------+--------+--------------+
`}
            </pre>
            <br />
            <p>Department table:</p>
            <pre>
              {`
+----+-------+
| id | name  |
+----+-------+
| 1  | IT    |
| 2  | Sales |
+----+-------+
              `}
            </pre>
            <p>Output: </p>
            <pre>
              {`
+------------+----------+--------+
| Department | Employee | Salary |
+------------+----------+--------+
| IT         | Jim      | 90000  |
| Sales      | Henry    | 80000  |
| IT         | Max      | 90000  |
+------------+----------+--------+
                `}
            </pre>
            <p>
              <b>Explanation: </b>
              Max and Jim both have the highest salary in the IT department and
              Henry has the highest salary in the Sales department.
            </p>
            <CodeEditor
              options={{
                title: "Q184. Department Highest Salary (Q177)",
                codes: {
                  Mysql: {
                    code: `select d1.Name as Department, e1.Name as Employee,
                    e1.Salary as Salary
                    from Employee e1, Department d1, 
                    
                    (select d.Id as Id, e.Name as Employee, max(e.Salary) 
                    
                    as Salary
                    from Employee e, Department d
                    where e.DepartmentId = d.Id
                    group by d.Id) as a

                    where e1.DepartmentId = d1.Id and d1.Id = a.Id and
                     a.Salary = e1.Salary`,
                    output: `{"headers": ["Department", "Employee", "Salary"],
                     "values": [["IT", "Jim", 90000], ["Sales", "Henry", 80000], ["IT", "Max", 90000]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q178: {
        title: "Q185. Department Top Three Salaries (Q178)",
        content: (
          <>
            <Span>
              <b>Q185. Department Top Three Salaries (Q178)</b>
            </Span>
            <p>
              SQL Schema <br />
              Table: Employee
            </p>
            <pre>
              {`
+--------------+---------+
| Column Name  | Type    |
+--------------+---------+
| id           | int     |
| name         | varchar |
| salary       | int     |
| departmentId | int     |
+--------------+---------+
                `}
            </pre>
            <Span>
              id is the primary key column for this table. departmentId is a
              foreign key of the ID from the Department table. Each row of this
              table indicates the ID, name, and salary of an employee. It also
              contains the ID of their department.
            </Span>
            <p>Table: Department</p>
            <pre>
              {`
+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| id          | int     |
| name        | varchar |
+-------------+---------+
                `}
            </pre>
            <Span>
              id is the primary key column for this table. <br />
              Each row of this table indicates the ID of a department and its
              name.
            </Span>
            <Span>
              A company's executives are interested in seeing who earns the most
              money in each of the company's departments. A high earner in a
              department is an employee who has a salary in the top three unique
              salaries for that department.
              <br />
              Write an SQL query to find the employees who are high earners in
              each of the departments.
              <br />
              Return the result table in any order.
              <br />
              The query result format is in the following example.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <p>
              Input:
              <br />
              Employee table:
              <br />
              <pre>
                {`
+----+-------+--------+--------------+
| id | name  | salary | departmentId |
+----+-------+--------+--------------+
| 1  | Joe   | 85000  | 1            |
| 2  | Henry | 80000  | 2            |
| 3  | Sam   | 60000  | 2            |
| 4  | Max   | 90000  | 1            |
| 5  | Janet | 69000  | 1            |
| 6  | Randy | 85000  | 1            |
| 7  | Will  | 70000  | 1            |
+----+-------+--------+--------------+    
    `}
              </pre>
              Department table: <br />
              <pre>
                {`
+----+-------+
| id | name  |
+----+-------+
| 1  | IT    |
| 2  | Sales |
+----+-------+  
  `}
              </pre>
              Output: <br />
              <pre>
                {`
+------------+----------+--------+
| Department | Employee | Salary |
+------------+----------+--------+
| IT         | Max      | 90000  |
| IT         | Joe      | 85000  |
| IT         | Randy    | 85000  |
| IT         | Will     | 70000  |
| Sales      | Henry    | 80000  |
| Sales      | Sam      | 60000  |
+------------+----------+--------+  
  `}
              </pre>
              Explanation:
              <br />
              In the IT department:
              <br />- Max earns the highest unique salary
              <br />- Both Randy and Joe earn the second-highest unique salary
              <br />- Will earns the third-highest unique salary
              <br />
              In the Sales department:
              <br />- Henry earns the highest salary
              <br />- Sam earns the second-highest salary
              <br />- There is no third-highest salary as there are only two
              employees
            </p>
            <CodeEditor
              options={{
                title: "Q185. Department Top Three Salaries (Q178)",
                codes: {
                  Mysql: {
                    code: `select d1.Name as Department, e1.Name as
                    Employee, e1.Salary as Salary
                    from Employee e1, Department d1
                    where e1.DepartmentId  = d1.Id and 3 > 
                    (select count(distinct(e2.salary)) from Employee e2 where
                    e2.salary > e1.salary and e2.DepartmentId = e1.DepartmentId)`,
                    output: `{"headers": ["Department", "Employee", "Salary"], 
                    "values": [["IT", "Joe", 85000], ["Sales", "Henry", 80000],
                    ["Sales", "Sam", 60000], ["IT", "Max", 90000], 
                    ["IT", "Randy", 85000],
                    ["IT", "Will", 70000]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q179: {
        title: "Q187. Repeated DNA Sequences (Q179)",
        content: (
          <>
            <Span>
              <b>Q187. Repeated DNA Sequences (Q179)</b>
            </Span>
            <Span>
              The DNA sequence is composed of a series of nucleotides
              abbreviated as 'A', 'C', 'G', and 'T'.
              <br />
              For example, "ACGAATTCCG" is a DNA sequence.
              <br />
              When studying DNA, it is useful to identify repeated sequences
              within the DNA.
              <br />
              Given a string s that represents a DNA sequence, return all the
              10-letter-long sequences (substrings) that occur more than once in
              a DNA molecule. You may return the answer in any order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "AAAAACCCCCAAAAACCCCCCAAAAAGGGTTT" <br />
              Output: ["AAAAACCCCC","CCCCCAAAAA"]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "AAAAAAAAAAAAA"
              <br />
              Output: ["AAAAAAAAAA"]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 105 <br />
              s[i] is either 'A', 'C', 'G', or 'T'.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q187. Repeated DNA Sequences (Q179)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {string[]}
                    */
                   var findRepeatedDnaSequences = function(s) {
                     const ans = new Set()
                     const seen = new Set()
                     for (let i = 0; i + 10 <= s.length; ++i) {
                     const seq = s.substring(i, i + 10);
                     if (seen.has(seq))
                     ans.add(seq);
                     seen.add(seq);
                     }
                     return Array.from(ans)
                   };
                   
                   findRepeatedDnaSequences("AAAAACCCCCAAAAACCCCCCAAAAAGGGTTT")
                   `,
                    output: `["AAAAACCCCC","CCCCCAAAAA"]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q180: {
        title: "Q188. Best Time to Buy and Sell Stock IV (Q180)",
        content: (
          <>
            <Span>
              <b>Q188. Best Time to Buy and Sell Stock IV (Q180)</b>
            </Span>
            <Span>
              You are given an integer array prices where prices[i] is the price
              of a given stock on the ith day, and an integer k.
              <br />
              Find the maximum profit you can achieve. You may complete at most
              k transactions.
              <br />
              Note: You may not engage in multiple transactions simultaneously
              (i.e., you must sell the stock before you buy again).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: k = 2, prices = [2,4,1]
              <br />
              Output: 2
              <br />
              Explanation: Buy on day 1 (price = 2) and sell on day 2 (price =
              4), profit = 4-2 = 2.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: k = 2, prices = [3,2,6,5,0,3]
              <br />
              Output: 7
              <br />
              Explanation: Buy on day 2 (price = 2) and sell on day 3 (price =
              6), profit = 6-2 = 4. Then buy on day 5 (price = 0) and sell on
              day 6 (price = 3), profit = 3-0 = 3.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= k &lt;= 100
              <br />
              0 &lt;= prices.length &lt;= 1000
              <br />0 &lt;= prices[i] &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.k)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q188. Best Time to Buy and Sell Stock IV (Q180)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} k
                    * @param {number[]} prices
                    * @return {number}
                    */
                   var maxProfit = function(k, prices) {
                       if(k>=parseInt(prices.length/2)){
                         let sell = 0
                         let hold = Number.MIN_SAFE_INTEGER
                         for(let p of prices){
                           sell =  Math.max(sell, hold + p)
                           hold =  Math.max(hold, sell - p)
                         }
                         return sell
                       }
                       const sell = Array(k+1).fill(0)
                       const hold = Array(k+1).fill(Number.MIN_SAFE_INTEGER)
                       for (let price of prices)
                       for (let i = k; i > 0; --i) {
                         sell[i] = Math.max(sell[i], hold[i] + price);
                         hold[i] = Math.max(hold[i], sell[i - 1] - price);
                       }
                       return sell[k]
                   };
                   
                   maxProfit(2, [2,4,1])`,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q181: {
        title: "Q189. Rotate Array (Q181)",
        content: (
          <>
            <Span>
              <b>Q189. Rotate Array (Q181)</b>
            </Span>
            <Span>
              Given an array, rotate the array to the right by k steps, where k
              is non-negative.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3,4,5,6,7], k = 3 <br />
              Output: [5,6,7,1,2,3,4] <br />
              Explanation: <br />
              rotate 1 steps to the right: [7,1,2,3,4,5,6] <br />
              rotate 2 steps to the right: [6,7,1,2,3,4,5] <br />
              rotate 3 steps to the right: [5,6,7,1,2,3,4]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [-1,-100,3,99], k = 2<br />
              Output: [3,99,-1,-100]
              <br />
              Explanation: <br />
              rotate 1 steps to the right: [99,-1,-100,3]
              <br />
              rotate 2 steps to the right: [3,99,-1,-100]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 105
              <br />
              -231 &lt;= nums[i] &lt;= 231 - 1<br />0 &lt;= k &lt;= 105
            </Span>
            <Span>
              <b>Follow up: </b>
              <br />
              Try to come up with as many solutions as you can. There are at
              least three different ways to solve this problem.
              <br />
              Could you do it in-place with O(1) extra space?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q189. Rotate Array (Q181)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @param {number} k
                    * @return {void} Do not return anything, modify nums in-place instead.
                    */
                   
                   function reverse(nums,l,r){
                   while(l<r){
                     [nums[l],nums[r]] = [nums[r],nums[l]]
                     l++
                     r--
                   }
                   }
                   
                   var rotate = function(nums, k) {
                     k %= nums.length;
                     reverse(nums, 0, nums.length - 1);
                     reverse(nums, 0, k - 1);
                     reverse(nums, k, nums.length - 1);
                     console.log(nums)
                   };
                   
                   rotate([1,2,3,4,5,6,7],3)`,
                    output: `[
                      5, 6, 7, 1,
                      2, 3, 4
                    ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q182: {
        title: "Q190. Reverse Bits (Q182)",
        content: (
          <>
            <Span>
              <b>Q190. Reverse Bits (Q182)</b>
            </Span>
            <Span>
              Reverse bits of a given 32 bits unsigned integer.
              <br />
              Note:
              <br />
              Note that in some languages, such as Java, there is no unsigned
              integer type. In this case, both input and output will be given as
              a signed integer type. They should not affect your implementation,
              as the integer's internal binary representation is the same,
              whether it is signed or unsigned.
              <br />
              In Java, the compiler represents the signed integers using 2's
              complement notation. Therefore, in Example 2 above, the input
              represents the signed integer -3 and the output represents the
              signed integer -1073741825.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 00000010100101000001111010011100
              <br />
              Output: 964176192 (00111001011110000010100101000000)
              <br />
              Explanation: The input binary string
              00000010100101000001111010011100 represents the unsigned integer
              43261596, so return 964176192 which its binary representation is
              00111001011110000010100101000000.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 11111111111111111111111111111101
              <br />
              Output: 3221225471 (10111111111111111111111111111111)
              <br />
              Explanation: The input binary string
              11111111111111111111111111111101 represents the unsigned integer
              4294967293, so return 3221225471 which its binary representation
              is 10111111111111111111111111111111.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>The input must be a binary string of length 32</Span>
            <Span>
              <b>Follow up: </b>
              If this function is called many times, how would you optimize it?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q190. Reverse Bits (Q182)",
                codes: {
                  Java: {
                    code: `public class Solution {
                      public int reverseBits(int n) {
                        int ans = 0;
                    
                        for (int i = 0; i < 32; ++i)
                          if (((n >> i) & 1) == 1)
                            ans |= (1 << (31 - i));
                    
                        return ans;
                      }
                    }
                    `,
                    output: `11111111111111111111111111111101`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q183: {
        title: "Q191. Number of 1 Bits (Q183)",
        content: (
          <>
            <Span>
              <b>Q191. Number of 1 Bits (Q183)</b>
            </Span>
            <Span>
              Write a function that takes an unsigned integer and returns the
              number of '1' bits it has (also known as the Hamming weight).
            </Span>
            <Span>Note:</Span>
            <Span>
              Note that in some languages, such as Java, there is no unsigned
              integer type. In this case, the input will be given as a signed
              integer type. It should not affect your implementation, as the
              integer's internal binary representation is the same, whether it
              is signed or unsigned. <br />
              In Java, the compiler represents the signed integers using 2's
              complement notation. Therefore, in Example 3, the input represents
              the signed integer. -3.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 00000000000000000000000000001011
              <br />
              Output: 3<br />
              Explanation: The input binary string
              00000000000000000000000000001011 has a total of three '1' bits.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 00000000000000000000000010000000 <br />
              Output: 1 <br />
              Explanation: The input binary string
              00000000000000000000000010000000 has a total of one '1' bit.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: n = 11111111111111111111111111111101
              <br />
              Output: 31
              <br />
              Explanation: The input binary string
              11111111111111111111111111111101 has a total of thirty one '1'
              bits.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>The input must be a binary string of length 32.</Span>
            <Span>
              <b>Follow up: </b>
              If this function is called many times, how would you optimize it?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q191. Number of 1 Bits (Q183)",
                codes: {
                  Javascript: {
                    code: `public class Solution {
                      // you need to treat n as an unsigned value
                      public int hammingWeight(int n) {
                        int ans = 0;
                    
                        for (int i = 0; i < 32; ++i)
                          if (((n >> i) & 1) == 1)
                            ++ans;
                    
                        return ans;
                      }
                    }
                    //  00000000000000000000000000001011
                    `,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q184: {
        title: "Q192. Word Frequency (Q184)",
        content: (
          <>
            <Span>
              <b>Q192. Word Frequency (Q184)</b>
            </Span>
            <Span>
              Write a bash script to calculate the frequency of each word in a
              text file words.txt.
            </Span>
            <Span>
              For simplicity sake, you may assume: <br />
              words.txt contains only lowercase characters and space ' '
              characters. <br />
              Each word must consist of lowercase characters only. <br />
              Words are separated by one or more whitespace characters.
            </Span>
            <Span>
              <b>Example:</b>
            </Span>
            <Span>Assume that words.txt has the following content:</Span>
            <Span>
              the day is sunny the the <br />
              the sunny is is
            </Span>
            <Span>
              Your script should output the following, sorted by descending
              frequency:
            </Span>
            <Span>
              the 4<br />
              is 3<br />
              sunny 2<br />
              day 1
            </Span>
            <Span>Note:</Span>
            <p>
              Don't worry about handling ties, it is guaranteed that each word's
              frequency count is unique.
              <br />
              Could you write it in one-line using Unix pipes?
            </p>
            <CodeEditor
              options={{
                title: "Q192. Word Frequency (Q184)",
                codes: {
                  Shell: {
                    code: `cat words.txt | awk '{for(i=1;i<=NF;i++){printf "%s\\n", $i}}' | sort 
                    | uniq -c | sort -r | awk '{print $2" "$1}'`,
                    output: `the 4
                is 3
                sunny 2
                day 1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q185: {
        title: "Q193. Valid Phone Numbers (Q185)",
        content: (
          <>
            <Span>
              <b>Q193. Valid Phone Numbers (Q184) (Q185)</b>
            </Span>
            <Span>
              Given a text file file.txt that contains a list of phone numbers
              (one per line), write a one-liner bash script to print all valid
              phone numbers.
              <br />
              You may assume that a valid phone number must appear in one of the
              following two formats: (xxx) xxx-xxxx or xxx-xxx-xxxx. (x means a
              digit)
              <br />
              You may also assume each line in the text file must not contain
              leading or trailing white spaces.
            </Span>
            <Span>
              <b>Example :</b>
            </Span>
            <p>
              Assume that file.txt has the following content:
              <br />
              987-123-4567
              <br />
              123 456 7890
              <br />
              (123) 456-7890
              <br />
              Your script should output the following valid phone numbers:
              <br />
              987-123-4567
              <br />
              (123) 456-7890
            </p>
            <CodeEditor
              options={{
                title: "Q193. Valid Phone Numbers (Q185)",
                codes: {
                  Shell: {
                    code: `grep -Po '^(\(\\d{3}\) |\\d{3}-)\\d{3}-\\d{4}$' file.txt`,
                    output: `987-123-4567
                    (123) 456-7890`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q186: {
        title: "Q194. Transpose File (Q186)",
        content: (
          <>
            <Span>
              <b>Q194. Transpose File (Q186)</b>
            </Span>
            <Span>Given a text file file.txt, transpose its content.</Span>
            <Span>
              You may assume that each row has the same number of columns, and
              each field is separated by the ' ' character.
            </Span>
            <Span>
              <b>Example:</b>
            </Span>
            <p>
              If file.txt has the following content:
              <br />
              name age
              <br />
              alice 21
              <br />
              ryan 30
              <br />
              Output the following:
              <br />
              name alice ryan
              <br />
              age 21 30
            </p>
            <CodeEditor
              options={{
                title: "Q194. Transpose File (Q186)",
                codes: {
                  Shell: {
                    code: `#!/usr/bin/bash
                    while IFS=' ' read -a l; do
                            for i in ${"${"}!l[@]}; do
                                    a[i]="${"${"}a[i]} ${"${"}l[i]}"
                            done
                    done < file.txt
                    for i in ${"${"}!a[@]}; do
                            echo ${"${"}a[i]}
                    done`,
                    output: `name alice ryan
                    age 21 30`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q187: {
        title: "Q195. Tenth Line (Q187)",
        content: (
          <>
            <Span>
              <b>Q195. Tenth Line (Q187)</b>
            </Span>

            <Span>
              Given a text file file.txt, print just the 10th line of the file.
            </Span>
            <Span>
              <b>Example:</b>
              <br />
              Assume that file.txt has the following content:
              <br />
              Line 1<br />
              Line 2<br />
              Line 3<br />
              Line 4<br />
              Line 5<br />
              Line 6<br />
              Line 7<br />
              Line 8<br />
              Line 9<br />
              Line 10 Your script should output the tenth line, which is:
              <br />
              Line 10
              <br />
              Note:
              <br />
              1. If the file contains less than 10 lines, what should you
              output?
              <br />
              2. There's at least three different solutions. Try to explore all
              possibilities.
            </Span>
            <p></p>
            <CodeEditor
              options={{
                title: "Q195. Tenth Line (Q187)",
                codes: {
                  Shell: {
                    code: `i=0
                    while IFS= read -r row; do
                        rows[i++]="$row"
                    done < file.txt
                    echo "${"${"}rows[9]}"`,
                    output: `Line 10`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q188: {
        title: "Q196. Delete Duplicate Emails (Q188)",
        content: (
          <>
            <Span>
              <b>Q196. Delete Duplicate Emails (Q188)</b>
            </Span>
            <p>
              SQL Schema <br />
              Table: Person
            </p>
            <pre>
              {`
+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| id          | int     |
| email       | varchar |
+-------------+---------+  
  `}
            </pre>
            <Span>
              id is the primary key column for this table. <br />
              Each row of this table contains an email. The emails will not
              contain uppercase letters.
            </Span>
            <Span>
              Write an SQL query to delete all the duplicate emails, keeping
              only one unique email with the smallest id.
              <br />
              Return the result table in any order.
              <br />
              The query result format is in the following example.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>

            <p>
              Input: <br />
              Person table:
              <pre>
                {`
+----+------------------+
| id | email            |
+----+------------------+
| 1  | john@example.com |
| 2  | bob@example.com  |
| 3  | john@example.com |
+----+------------------+ 
 `}
              </pre>
              Output: <br />
              <pre>
                {`
+----+------------------+
| id | email            |
+----+------------------+
| 1  | john@example.com |
| 2  | bob@example.com  |
+----+------------------+
`}
              </pre>
              Explanation: john@example.com is repeated two times. We keep the
              row with the smallest Id = 1.
            </p>
            <CodeEditor
              options={{
                title: "Q196. Delete Duplicate Emails (Q188)",
                codes: {
                  Mysql: {
                    code: `delete p1
                    from Person p1, Person p2
                    where p1.Email = p2.Email and p1.Id > p2.Id`,
                    output: `{"headers": ["id", "email"], "values": [[1, "john@example.com"], [2, "bob@example.com"]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q189: {
        title: "Q197. Rising Temperature (Q189)",
        content: (
          <>
            <Span>
              <b>Q197. Rising Temperature (Q189)</b>
            </Span>
            <p>
              SQL Schema <br />
              Create table If Not Exists Weather (id int, recordDate date,
              temperature int)
              <br />
              Truncate table Weather
              <br />
              insert into Weather (id, recordDate, temperature) values ('1',
              '2015-01-01', '10')
              <br />
              insert into Weather (id, recordDate, temperature) values ('2',
              '2015-01-02', '25')
              <br />
              insert into Weather (id, recordDate, temperature) values ('3',
              '2015-01-03', '20')
              <br />
              insert into Weather (id, recordDate, temperature) values ('4',
              '2015-01-04', '30')
              <br />
              Table: Weather
            </p>
            <pre>
              {`
+---------------+---------+
| Column Name   | Type    |
+---------------+---------+
| id            | int     |
| recordDate    | date    |
| temperature   | int     |
+---------------+---------+
                `}
            </pre>
            <Span>
              id is the primary key for this table. <br />
              This table contains information about the temperature on a certain
              day.
            </Span>
            <Span>
              Write an SQL query to find all dates' Id with higher temperatures
              compared to its previous dates (yesterday).
              <br />
              Return the result table in any order.
              <br />
              The query result format is in the following example.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <p>
              Input: <br />
              Weather table: <br />
              <pre>
                {`
+----+------------+-------------+
| id | recordDate | temperature |
+----+------------+-------------+
| 1  | 2015-01-01 | 10          |
| 2  | 2015-01-02 | 25          |
| 3  | 2015-01-03 | 20          |
| 4  | 2015-01-04 | 30          |
+----+------------+-------------+        
    `}
              </pre>
            </p>
            <p>
              <b>Output:</b>
            </p>
            <pre>
              {`
+----+
| id |
+----+
| 2  |
| 4  |
+----+
                `}
            </pre>
            <p>
              Explanation: <br />
              In 2015-01-02, the temperature was higher than the previous day
              (10 -&gt; 25).
              <br />
              In 2015-01-04, the temperature was higher than the previous day
              (20 -&gt; 30).
              <br />
            </p>
            <CodeEditor
              options={{
                title: "Q197. Rising Temperature (Q189)",
                codes: {
                  Mysql: {
                    code: `WITH yesterday AS (SELECT id,recordDate,temperature,DATE_SUB(recordDate, INTERVAL 1 DAY) AS yesterday
                    FROM Weather)
                    
                    SELECT yesterday.id
                    FROM yesterday LEFT JOIN Weather on yesterday.yesterday = Weather.recordDate
                    WHERE Weather.temperature IS NOT NULL AND yesterday.temperature > Weather.temperature`,
                    output: `{"headers": ["id"], "values": [[2], [4]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q190: {
        title: "Q198. House Robber (Q190)",
        content: (
          <>
            <Span>
              <b>Q198. House Robber (Q190)</b>
            </Span>
            <Span>
              You are a professional robber planning to rob houses along a
              street. Each house has a certain amount of money stashed, the only
              constraint stopping you from robbing each of them is that adjacent
              houses have security systems connected and it will automatically
              contact the police if two adjacent houses were broken into on the
              same night.
            </Span>
            <Span>
              Given an integer array nums representing the amount of money of
              each house, return the maximum amount of money you can rob tonight
              without alerting the police.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3,1]
              <br />
              Output: 4
              <br />
              Explanation: Rob house 1 (money = 1) and then rob house 3 (money =
              3).
              <br />
              Total amount you can rob = 1 + 3 = 4.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [2,7,9,3,1]
              <br />
              Output: 12
              <br />
              Explanation: Rob house 1 (money = 2), rob house 3 (money = 9) and
              rob house 5 (money = 1).
              <br />
              Total amount you can rob = 2 + 9 + 1 = 12.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 100 <br />0 &lt;= nums[i] &lt;= 400
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q198. House Robber (Q190)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var rob = function(nums) {
                     let prev1 = 0; // dp[i - 1]
                     let prev2 = 0; // dp[i - 2]
                     for (let num of nums) {
                       let dp = Math.max(prev1, prev2 + num);
                       prev2 = prev1;
                       prev1 = dp;
                     }
                     return prev1;
                   };
                   rob([1,2,3,1])`,
                    output: `4`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q191: {
        title: "Q199. Binary Tree Right Side View (Q191)",
        content: (
          <>
            <Span>
              <b>Q199. Binary Tree Right Side View (Q191)</b>
            </Span>
            <Span>
              Given the root of a binary tree, imagine yourself standing on the
              right side of it, return the values of the nodes you can see
              ordered from top to bottom.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode199} left alt="img" />
            <Span>
              Input: root = [1,2,3,null,5,null,4] <br />
              Output: [1,3,4]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [1,null,3]
              <br />
              Output: [1,3]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = []
              <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 100].
              <br />
              -100 &lt;= Node.val &lt;= 100
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q199. Binary Tree Right Side View (Q191)",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                  }
                  
                  /**
                   * @param {TreeNode} root
                   * @return {number[]}
                   */
                  var rightSideView = function(root) {
                    let queue = []
                    queue.push(root)
                    const res = []
                    while (queue.length > 0&& root) {
                        const n = queue.length
                        for (let i = 0; i < n; i++) {
                            let curr = queue.shift();
                            if (i == n - 1 && curr)
                                res.push(curr.val)
                            if (curr.left != null)
                                queue.push(curr.left);
                            if (curr.right != null)
                                queue.push(curr.right);
                        }
                    }
                    return res
                  } 
                  
                  const t = new TreeNode(1)
                  t.right = new TreeNode(3)
                  rightSideView(t)                  
                  `,
                    output: `[1,3]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q192: {
        title: "Q200. Number of Islands (Q192)",
        content: (
          <>
            <Span>
              <b>Q200. Number of Islands (Q191)</b>
            </Span>
            <Span>
              Given an m x n 2D binary grid grid which represents a map of '1's
              (land) and '0's (water), return the number of islands.
              <br />
              An island is surrounded by water and is formed by connecting
              adjacent lands horizontally or vertically. You may assume all four
              edges of the grid are all surrounded by water.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: grid = [ ["1","1","1","1","0"]
              <br />
              , ["1","1","0","1","0"],
              <br />
              ["1","1","0","0","0"],
              <br /> ["0","0","0","0","0"] ]
              <br />
              Output: 1
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: grid = [
              <br />
              ["1","1","0","0","0"], <br />
              ["1","1","0","0","0"], <br />
              ["0","0","1","0","0"], <br />
              ["0","0","0","1","1"] <br />
              ] <br />
              Output: 3
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == grid.length <br />
              n == grid[i].length
              <br />
              1 &lt;= m, n &lt;= 300
              <br />
              grid[i][j] is '0' or '1'.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q200. Number of Islands (Q192)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {character[][]} grid
                    * @return {number}
                    */
                   
                   function dfs(grid,i,j){
                    const m = grid.length
                    const n = grid[0].length
                    if(i < 0 || i == m || j < 0 || j == n) return
                    if(grid[i][j] != '1') return
                    grid[i][j] = '2'  
                    dfs(grid,i + 1, j)
                    dfs(grid,i - 1, j)
                    dfs(grid,i, j + 1)
                    dfs(grid,i, j - 1)
                   }
                   
                   var numIslands = function(grid) {
                     const m = grid.length
                     const n = grid[0].length
                     let res = 0
                     for (let i = 0; i < m; ++i)
                      for (let j = 0; j < n; ++j)
                        if (grid[i][j] == '1') {
                          dfs(grid, i, j);
                          ++res;
                        }
                     return res
                   };
                   
                   numIslands([
                     ["1","1","1","1","0"],
                     ["1","1","0","1","0"],
                     ["1","1","0","0","0"],
                     ["0","0","0","0","0"]
                   ])`,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q193: {
        title: "Q201. Bitwise AND of Numbers Range (Q193)",
        content: (
          <>
            <Span>
              <b>Q201. Bitwise AND of Numbers Range (Q192)</b>
            </Span>
            <Span>
              Given two integers left and right that represent the range [left,
              right], return the bitwise AND of all numbers in this range,
              inclusive.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: left = 5, right = 7 <br />
              Output: 4
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: left = 0, right = 0 <br />
              Output: 0
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: left = 1, right = 2147483647
              <br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>0 &lt;= left &lt;= right &lt;= 231 - 1</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q201. Bitwise AND of Numbers Range (Q192)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} left
                    * @param {number} right
                    * @return {number}
                    */
                   var rangeBitwiseAnd = function(left, right) {
                     let shiftBits = 0;
                     let m = left
                     let n = right
                     while (m != n) {
                       m >>= 1;
                       n >>= 1;
                       ++shiftBits;
                     }
                     return m << shiftBits;  
                   };
                   
                   rangeBitwiseAnd(5,7)`,
                    output: `4`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q194: {
        title: "Q202. Happy Number (Q194)",
        content: (
          <>
            <Span>
              <b>Q202. Happy Number (Q194)</b>
            </Span>
            <Span>
              Write an algorithm to determine if a number n is happy.
              <br />
              A happy number is a number defined by the following process:
              <br />
              Starting with any positive integer, replace the number by the sum
              of the squares of its digits.
              <br />
              Repeat the process until the number equals 1 (where it will stay),
              or it loops endlessly in a cycle which does not include 1.
              <br />
              Those numbers for which this process ends in 1 are happy. Return
              true if n is a happy number, and false if not.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 19
              <br />
              Output: true
              <br />
              Explanation:
              <br />
              12 + 92 = 82
              <br />
              82 + 22 = 68
              <br />
              62 + 82 = 100
              <br />
              12 + 02 + 02 = 1
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 2 <br />
              Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= n &lt;= 231 - 1</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q202. Happy Number (Q194)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {boolean}
                    */
                   function helper(n) {
                   let sum = 0;
                   while (n > 0) {
                       sum += Math.pow(n % 10, 2);
                       n = parseInt(n/10);
                   }
                   return sum;  
                   }
                   
                   var isHappy = function(n) {
                     let slow = helper(n);
                     let fast = helper(helper(n));
                     while (slow != fast) {
                       slow = helper(slow);
                       fast = helper(helper(fast));
                     }
                     return slow == 1; 
                   };
                   
                   isHappy(19)`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q195: {
        title: "Q203. Remove Linked List Elements (Q195)",
        content: (
          <>
            <Span>
              <b>Q203. Remove Linked List Elements (Q195)</b>
            </Span>
            <Span>
              Given the head of a linked list and an integer val, remove all the
              nodes of the linked list that has Node.val == val, and return the
              new head.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode203} left alt="img" />
            <Span>
              Input: head = [1,2,6,3,4,5,6], val = 6 <br />
              Output: [1,2,3,4,5]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [], val = 1 <br />
              Output: []
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: head = [7,7,7,7], val = 7 <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is in the range [0, 104].
              <br />
              1 &lt;= Node.val &lt;= 50
              <br />0 &lt;= val &lt;= 50
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q203. Remove Linked List Elements (Q195)",
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                  }
                  
                  /**
                   * @param {ListNode} head
                   * @param {number} val
                   * @return {ListNode}
                   */
                  var removeElements = function(head, val){
                    let temp = new ListNode(0, head)
                    let prev = temp
                    while(head){
                      if(head.val != val){
                        prev.next = head
                        prev = prev.next
                      }
                      head = head.next
                    }
                    prev.next = null
                    return temp.next
                  };
                  
                  const l = new ListNode(1)
                  l.next = new ListNode(2)
                  l.next.next = new ListNode(6)
                  l.next.next.next = new ListNode(3)
                  l.next.next.next.next = new ListNode(4)
                  l.next.next.next.next.next = new ListNode(5)
                  l.next.next.next.next.next.next = new ListNode(6)
                  
                  removeElements(l,6)
                  `,
                    output: `ListNode {
                      val: 1,
                      next: ListNode { val: 2, next: ListNode { val: 3, next: [ListNode] } }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q196: {
        title: "Q204. Count Primes (Q196)",
        content: (
          <>
            <Span>
              <b>Q204. Count Primes (Q196)</b>
            </Span>
            <Span>
              Given an integer n, return the number of prime numbers that are
              strictly less than n.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 10 <br />
              Output: 4 <br />
              Explanation: There are 4 prime numbers less than 10, they are 2,
              3, 5, 7.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 0<br />
              Output: 0
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: n = 1 <br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>0 &lt;= n &lt;= 5 * 106</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(nlog log n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q204. Count Primes (Q196)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number}
                    */
                   var countPrimes = function(n) {
                     if (n <= 2) return 0;
                     let ans = 0;
                     const prime = Array(n).fill(true);
                     prime[0] = prime[1] = false
                     for (let i = 2; i < n**0.5; ++i){
                     if (prime[i])
                     for (let j = i * i; j < n; j += i)
                     prime[j] = false;
                     }
                     for (let p of prime)
                     if (p) ++ans;
                     return ans;  
                   };
                   
                   countPrimes(10)`,
                    output: `4`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q197: {
        title: "Q205. Isomorphic Strings (Q197)",
        content: (
          <>
            <Span>
              <b>Q205. Isomorphic Strings (Q197)</b>
            </Span>
            <Span>
              Given two strings s and t, determine if they are isomorphic.
              <br />
              Two strings s and t are isomorphic if the characters in s can be
              replaced to get t.
              <br />
              All occurrences of a character must be replaced with another
              character while preserving the order of characters. No two
              characters may map to the same character, but a character may map
              to itself.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "egg", t = "add" <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "foo", t = "bar"
              <br />
              Output: false
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "paper", t = "title"
              <br />
              Output: true
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 5 * 104 <br />
              t.length == s.length <br />s and t consist of any valid ascii
              character.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q205. Isomorphic Strings (Q197)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @param {string} t
                    * @return {boolean}
                    */
                   var isIsomorphic = function(s, t) {
                     const m1 = new Map();
                     const m2 = new Map();
                     for (let i = 0; i < s.length; ++i){
                       if (m1.get(s[i]) != m2.get(t[i]))
                         return false;
                       m1.set(s[i],i+1)
                       m2.set(t[i],i+1)
                     }
                     return true;
                   };
                   
                   isIsomorphic("foo","bar")`,
                    output: `false`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q198: {
        title: "Q206. Reverse Linked List (Q198)",
        content: (
          <>
            <Span>
              <b>Q206. Reverse Linked List (Q198)</b>
            </Span>
            <Span>
              Given the head of a singly linked list, reverse the list, and
              return the reversed list.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: head = [1,2,3,4,5] <br />
              Output: [5,4,3,2,1]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [1,2]
              <br />
              Output: [2,1]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: head = []
              <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is the range [0, 5000]. <br />
              -5000 &lt;= Node.val &lt;= 5000
            </Span>
            <Span>
              <b>Follow up: </b>A linked list can be reversed either iteratively
              or recursively. Could you implement both?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n (rec) / 1 (iter) )
            </p>
            <CodeEditor
              options={{
                title: "Q206. Reverse Linked List (Q198)",
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                  }
                  
                  /**
                   * @param {ListNode} head
                   * @return {ListNode}
                   */
                  function iter(head){
                  let prev = next = null
                  let curr = head
                  while(curr){
                  next = curr.next
                  curr.next = prev
                  prev = curr
                  curr = next
                  }
                  return prev
                  }
                  
                  function rec(head){
                    if(!head || !head.next) return head
                    let newHead = rec(head.next);
                    head.next.next = head;
                    head.next = null;
                    return newHead;
                  }
                  
                  var reverseList = function(head) {
                    return iter(head)   
                  };
                  
                  const l = new ListNode(1)
                  l.next = new ListNode(2)
                  reverseList(l)`,
                    output: `ListNode { val: 2, next: ListNode { val: 1, next: null } }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q199: {
        title: "Q207. Course Schedule (Q199)",
        content: (
          <>
            <Span>
              <b>Q207. Course Schedule (Q199)</b>
            </Span>
            <Span>
              There are a total of numCourses courses you have to take, labeled
              from 0 to numCourses - 1. You are given an array prerequisites
              where prerequisites[i] = [ai, bi] indicates that you must take
              course bi first if you want to take course ai.
              <br />
              For example, the pair [0, 1], indicates that to take course 0 you
              have to first take course 1.
              <br />
              Return true if you can finish all courses. Otherwise, return
              false.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: numCourses = 2, prerequisites = [[1,0]] <br />
              Output: true
              <br />
              Explanation: There are a total of 2 courses to take. <br />
              To take course 1 you should have finished course 0. So it is
              possible.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: numCourses = 2, prerequisites = [[1,0],[0,1]]
              <br />
              Output: false
              <br />
              Explanation: There are a total of 2 courses to take. <br />
              To take course 1 you should have finished course 0, and to take
              course 0 you should also have finished course 1. So it is
              impossible.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= numCourses &lt;= 105 <br />
              0 &lt;= prerequisites.length &lt;= 5000
              <br />
              prerequisites[i].length == 2<br />
              0 &lt;= ai, bi &lt; numCourses
              <br />
              All the pairs prerequisites[i] are unique.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(v+e)
              <br />
              Space: O(v+e)
            </p>
            <CodeEditor
              options={{
                title: "Q207. Course Schedule (Q199)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} numCourses
                    * @param {number[][]} prerequisites
                    * @return {boolean}
                    */
                   
                   const State = {
                     init: 0,
                     visiting : 1,
                     visited: 2
                   }
                   
                   var canFinish = function(numCourses, prerequisites) {
                     const graph = Array.from(Array(numCourses),()=>[]);
                     const state = Array.from(Array(numCourses),()=>[]);
                   
                     for (let p of prerequisites)
                       graph[p[1]].push(p[0]);
                     for (let i = 0; i < numCourses; ++i)
                       if (hasCycle(graph, i, state))
                         return false;
                   
                     return true;
                   };
                   
                     function hasCycle(graph, u, state) {
                       if (state[u] == State.visiting)
                         return true;
                       if (state[u] == State.visited)
                         return false;
                       state[u] = State.visiting;
                       for (let v of graph[u])
                         if (hasCycle(graph, v, state))
                           return true;
                       state[u] = State.visited;
                       return false;
                     }
                   
                     canFinish(2,[[1,0]])
                   `,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q200: {
        title: "Q208. Implement Trie (Prefix Tree) (Q200)",
        content: (
          <>
            <Span>
              <b>Q208. Implement Trie (Prefix Tree) (Q200)</b>
            </Span>
            <Span>
              A trie (pronounced as "try") or prefix tree is a tree data
              structure used to efficiently store and retrieve keys in a dataset
              of strings. There are various applications of this data structure,
              such as autocomplete and spellchecker
            </Span>
            <Span>
              Implement the Trie class:
              <br />
              Trie() Initializes the trie object.
              <br />
              void insert(String word) Inserts the string word into the trie.
              <br />
              boolean search(String word) Returns true if the string word is in
              the trie (i.e., was inserted before), and false otherwise.
              <br />
              boolean startsWith(String prefix) Returns true if there is a
              previously inserted string word that has the prefix prefix, and
              false otherwise.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input ["Trie", "insert", "search", "search", "startsWith",
              "insert", "search"]
              <br />
              [[], ["apple"], ["apple"], ["app"], ["app"], ["app"], ["app"]]
              Output
              <br />
              [null, null, true, false, true, null, true]
              <br />
              Explanation
              <br />
              Trie trie = new Trie();
              <br />
              trie.insert("apple");
              <br />
              trie.search("apple"); // return True
              <br />
              trie.search("app"); // return False
              <br />
              trie.startsWith("app"); // return True
              <br />
              trie.insert("app"); trie.search("app"); // return True
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= word.length, prefix.length &lt;= 2000 <br />
              word and prefix consist only of lowercase English letters. <br />
              At most 3 * 104 calls in total will be made to insert, search, and
              startsWith.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q208. Implement Trie (Prefix Tree) (Q200)",
                codes: {
                  Java: {
                    code: `class TrieNode {
                      public TrieNode[] children = new TrieNode[26];
                      public boolean isWord = false;
                    }
                    
                    class Trie {
                      public void insert(String word) {
                        TrieNode node = root;
                        for (final char c : word.toCharArray()) {
                          final int i = c - 'a';
                          if (node.children[i] == null)
                            node.children[i] = new TrieNode();
                          node = node.children[i];
                        }
                        node.isWord = true;
                      }
                    
                      public boolean search(String word) {
                        TrieNode node = find(word);
                        return node != null && node.isWord;
                      }
                    
                      public boolean startsWith(String prefix) {
                        return find(prefix) != null;
                      }
                    
                      private TrieNode root = new TrieNode();
                    
                      private TrieNode find(String prefix) {
                        TrieNode node = root;
                        for (final char c : prefix.toCharArray()) {
                          final int i = c - 'a';
                          if (node.children[i] == null)
                            return null;
                          node = node.children[i];
                        }
                        return node;
                      }
                    }
                    `,
                    output: `[null,null,true,false,true,null,true]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q201: {
        title: "Q209. Minimum Size Subarray Sum (Q201)",
        content: (
          <>
            <Span>
              <b>Q209. Minimum Size Subarray Sum (Q201)</b>
            </Span>
            <Span>
              Given an array of positive integers nums and a positive integer
              target, return the minimal length of a contiguous subarray [numsl,
              numsl+1, ..., numsr-1, numsr] of which the sum is greater than or
              equal to target. If there is no such subarray, return 0 instead.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: target = 7,
              <br /> nums = [2,3,1,2,4,3] <br />
              Output: 2<br />
              Explanation: The subarray [4,3] has the minimal length under the
              problem constraint.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: target = 4,
              <br /> nums = [1,4,4]
              <br />
              Output: 1
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: target = 11,
              <br /> nums = [1,1,1,1,1,1,1,1] Output: 0<br />
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= target &lt;= 109 <br />
              1 &lt;= nums.length &lt;= 105 <br />1 &lt;= nums[i] &lt;= 105
            </Span>
            <Span>
              Follow up: If you have figured out the O(n) solution, try coding
              another solution of which the time complexity is O(n log(n)).
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q209. Minimum Size Subarray Sum (Q201)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} target
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var minSubArrayLen = function(target, nums) {
                     let ans = Number.MAX_SAFE_INTEGER;
                     let sum = 0;
                     for (let l = 0, r = 0; r < nums.length; ++r) {
                       sum += nums[r];
                       while (sum >= target) {
                         ans = Math.min(ans, r - l + 1);
                         sum -= nums[l++];
                       }
                     }
                     return ans != Number.MAX_SAFE_INTEGER ? ans : 0;  
                   };
                   
                   minSubArrayLen(7,[2,3,1,2,4,3])`,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q202: {
        title: "Q210. Course Schedule II (Q202)",
        content: (
          <>
            <Span>
              <b>Q210. Course Schedule II (Q202)</b>
            </Span>
            <Span>
              There are a total of numCourses courses you have to take, labeled
              from 0 to numCourses - 1. You are given an array prerequisites
              where prerequisites[i] = [ai, bi] indicates that you must take
              course bi first if you want to take course ai.
              <br />
              For example, the pair [0, 1], indicates that to take course 0 you
              have to first take course 1.
              <br />
              Return the ordering of courses you should take to finish all
              courses. If there are many valid answers, return any of them. If
              it is impossible to finish all courses, return an empty array.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: numCourses = 2,
              <br />
              prerequisites = [[1,0]]
              <br />
              Output: [0,1]
              <br />
              Explanation: There are a total of 2 courses to take. To take
              course 1 you should have finished course 0. So the correct course
              order is [0,1].
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: numCourses = 4, <br />
              prerequisites = [[1,0],[2,0],[3,1],[3,2]]
              <br />
              Output: [0,2,1,3]
              <br />
              Explanation: There are a total of 4 courses to take. To take
              course 3 you should have finished both courses 1 and 2. Both
              courses 1 and 2 should be taken after you finished course 0. So
              one correct course order is [0,1,2,3]. Another correct ordering is
              [0,2,1,3].
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: numCourses = 1, <br />
              prerequisites = []
              <br />
              Output: [0]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= numCourses &lt;= 2000 <br />0 &lt;= prerequisites.length
              &lt;= numCourses * (numCourses - 1) <br />
              prerequisites[i].length == 2 <br />
              0 &lt;= ai, bi &lt; numCourses <br />
              ai != bi <br />
              All the pairs [ai, bi] are distinct.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(v+e)
              <br />
              Space: O(v+e)
            </p>
            <CodeEditor
              options={{
                title: "Q210. Course Schedule II (Q202)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} numCourses
                    * @param {number[][]} prerequisites
                    * @return {number[]}
                    */
                   
                   const State = {
                     init : 0,
                     visiting : 1,
                     visited : 2
                   }
                   
                   function hasCycle(graph, u, ans, state){
                     if(state[u] == State.visiting)
                       return true
                     if(state[u] == State.visited)
                       return false
                     state[u] = State.visiting
                     for(let v of graph[u]){
                       if(hasCycle(graph,v,ans,state)) return true
                     }
                     state[u] = State.visited
                     ans.push(u)
                     return false
                   }
                   
                   var findOrder = function(numCourses, prerequisites) {
                     const ans = [];
                     const state = []
                     const graph = Array.from(Array(numCourses),()=>[]);
                     for (let p of prerequisites)
                       graph[p[1]].push(p[0]);
                     for (let i = 0; i < numCourses; ++i)
                       if (hasCycle(graph, i, ans, state))
                         return [];
                     return ans.reverse();
                   };
                   
                   findOrder(2,[[1,0]])`,
                    output: `[0,1]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q203: {
        title: "Q211. Design Add and Search Words Data Structure (Q203)",
        content: (
          <>
            <Span>
              <b>Q211. Design Add and Search Words Data Structure (Q203)</b>
            </Span>
            <Span>
              Design a data structure that supports adding new words and finding
              if a string matches any previously added string.
              <br />
              Implement the WordDictionary class:
              <br />
              WordDictionary() Initializes the object.
              <br />
              void addWord(word) Adds word to the data structure, it can be
              matched later.
              <br />
              bool search(word) Returns true if there is any string in the data
              structure that matches word or false otherwise. word may contain
              dots '.' where dots can be matched with any letter.
            </Span>
            <Span>
              <b>Example:</b>
            </Span>
            <Span>
              Input
              <br />
              ["WordDictionary","addWord","addWord","addWord","search","search","search","search"]
              <br />
              [[],["bad"],["dad"],["mad"],["pad"],["bad"],[".ad"],["b.."]]
              <br />
              Output
              <br />
              [null,null,null,null,false,true,true,true]
            </Span>
            <Span>
              1 &lt;= word.length &lt;= 500 <br />
              word in addWord consists lower-case English letters. <br />
              word in search consist of '.' or lower-case English letters.{" "}
              <br />
              At most 50000 calls will be made to addWord and search.
              <br />
              Explanation
              <br />
              WordDictionary wordDictionary = new WordDictionary(); <br />
              wordDictionary.addWord("bad"); <br />
              wordDictionary.addWord("dad"); <br />
              wordDictionary.addWord("mad"); <br />
              wordDictionary.search("pad"); // return False <br />
              wordDictionary.search("bad"); // return True <br />
              wordDictionary.search(".ad"); // return True <br />
              wordDictionary.search("b.."); // return True
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= word.length &lt;= 500
              <br />
              word in addWord consists lower-case English letters.
              <br />
              word in search consist of '.' or lower-case English letters.
              <br />
              At most 50000 calls will be made to addWord and search.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time:
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title:
                  "Q211. Design Add and Search Words Data Structure (Q203)",
                codes: {
                  Java: {
                    code: `class TrieNode {
                      public TrieNode[] children = new TrieNode[26];
                      public boolean isWord = false;
                    }
                    
                    class WordDictionary {
                      public void addWord(String word) {
                        TrieNode node = root;
                        for (final char c : word.toCharArray()) {
                          final int i = c - 'a';
                          if (node.children[i] == null)
                            node.children[i] = new TrieNode();
                          node = node.children[i];
                        }
                        node.isWord = true;
                      }
                    
                      public boolean search(String word) {
                        return dfs(word, 0, root);
                      }
                    
                      private TrieNode root = new TrieNode();
                    
                      private boolean dfs(String word, int s, TrieNode node) {
                        if (s == word.length())
                          return node.isWord;
                        if (word.charAt(s) != '.') {
                          TrieNode next = node.children[word.charAt(s) - 'a'];
                          return next == null ? false : dfs(word, s + 1, next);
                        }
                    
                        // word.charAt(s) == '.' -> search all 26 children
                        for (int i = 0; i < 26; ++i)
                          if (node.children[i] != null && dfs(word, s + 1, node.children[i]))
                            return true;
                    
                        return false;
                      }
                    }
                    `,
                    output: `[null,null,null,null,false,true,true,true]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q204: {
        title: "Q212. Word Search II (Q204)",
        content: (
          <>
            <Span>
              <b>Q212. Word Search II (Q204)</b>
            </Span>
            <Span>
              Given an m x n board of characters and a list of strings words,
              return all words on the board.
            </Span>
            <Span>
              Each word must be constructed from letters of sequentially
              adjacent cells, where adjacent cells are horizontally or
              vertically neighboring. The same letter cell may not be used more
              than once in a word.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: board =
              [["o","a","a","n"],["e","t","a","e"],["i","h","k","r"],["i","f","l","v"]],{" "}
              <br />
              words = ["oath","pea","eat","rain"]
              <br />
              Output: ["eat","oath"]
            </Span>
            <Img src={Leetcode212} left />
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: board = [["a","b"],["c","d"]], <br /> words = ["abcb"]{" "}
              <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == board.length <br />
              n == board[i].length <br />
              1 &lt;= m, n &lt;= 12 <br />
              board[i][j] is a lowercase English letter. <br />
              1 &lt;= words.length &lt;= 3 * 104 <br />
              1 &lt;= words[i].length &lt;= 10 <br />
              words[i] consists of lowercase English letters. <br />
              All the strings of words are unique.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q212. Word Search II (Q204)",
                codes: {
                  Java: {
                    code: `
                    // [["o","a","a","n"],["e","t","a","e"],
                    // ["i","h","k","r"],["i","f","l","v"]]
                    // ["oath","pea","eat","rain"]
                    class TrieNode {
                      public TrieNode[] children = new TrieNode[26];
                      public String word;
                    }
                    
                    class Solution {
                      public List<String> findWords(char[][] board, String[] words) {
                        for (final String word : words)
                          insert(word);
                    
                        List<String> ans = new ArrayList<>();
                    
                        for (int i = 0; i < board.length; ++i)
                          for (int j = 0; j < board[0].length; ++j)
                            dfs(board, i, j, root, ans);
                    
                        return ans;
                      }
                    
                      private TrieNode root = new TrieNode();
                    
                      private void insert(final String word) {
                        TrieNode node = root;
                        for (final char c : word.toCharArray()) {
                          if (node.children[c - 'a'] == null)
                            node.children[c - 'a'] = new TrieNode();
                          node = node.children[c - 'a'];
                        }
                        node.word = word;
                      }
                    
                      private void dfs(char[][] board, int i, int j, TrieNode node, List<String> ans) {
                        if (i < 0 || i == board.length || j < 0 || j == board[0].length)
                          return;
                        if (board[i][j] == '*')
                          return;
                    
                        final char c = board[i][j];
                        TrieNode next = node.children[(int) (c - 'a')];
                        if (next == null)
                          return;
                        if (next.word != null) {
                          ans.add(next.word);
                          next.word = null;
                        }
                    
                        board[i][j] = '*';
                        dfs(board, i + 1, j, next, ans);
                        dfs(board, i - 1, j, next, ans);
                        dfs(board, i, j + 1, next, ans);
                        dfs(board, i, j - 1, next, ans);
                        board[i][j] = c;
                      }
                    }
                    `,
                    output: `["oath","eat"]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q205: {
        title: "Q213. House Robber II (Q205)",
        content: (
          <>
            <Span>
              <b>Q213. House Robber II (Q205)</b>
            </Span>
            <Span>
              You are a professional robber planning to rob houses along a
              street. Each house has a certain amount of money stashed. All
              houses at this place are arranged in a circle. That means the
              first house is the neighbor of the last one. Meanwhile, adjacent
              houses have a security system connected, and it will automatically
              contact the police if two adjacent houses were broken into on the
              same night.
            </Span>
            <Span>
              Given an integer array nums representing the amount of money of
              each house, return the maximum amount of money you can rob tonight
              without alerting the police.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [2,3,2] <br />
              Output: 3 <br />
              Explanation: You cannot rob house 1 (money = 2) and then rob house
              3 (money = 2), because they are adjacent houses.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3,1]
              <br />
              Output: 4<br />
              Explanation: Rob house 1 (money = 1) and then rob house 3 (money =
              3).
              <br />
              Total amount you can rob = 1 + 3 = 4.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 100 <br />0 &lt;= nums[i] &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q213. House Robber II (Q205)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   
                   function robH(nums,l,r){
                     let prev1 = 0; // dp[i - 1]
                     let prev2 = 0; // dp[i - 2]
                     for(let i = l; i <= r; ++i) {
                       const dp = Math.max(prev1, prev2 + nums[i]);
                       prev2 = prev1;
                       prev1 = dp;
                     }
                     return prev1;
                   }
                   
                   var rob = function(nums) {
                     if(!nums) return 0
                     if(nums.length < 2) return nums[0]
                     return Math.max(robH(nums,0, nums.length - 2), robH(nums,1, nums.length - 1))
                   };
                   
                   rob([1,2,1])`,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q206: {
        title: "Q214. Shortest Palindrome (Q206)",
        content: (
          <>
            <Span>
              <b>Q214. Shortest Palindrome (Q206)</b>
            </Span>
            <Span>
              You are given a string s. You can convert s to a palindrome by
              adding characters in front of it.
            </Span>
            <Span>
              Return the shortest palindrome you can find by performing this
              transformation.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "aacecaaa" <br />
              Output: "aaacecaaa"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "abcd"
              <br />
              Output: "dcbabcd"
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= s.length &lt;= 5 * 104 <br />s consists of lowercase
              English letters only.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q214. Shortest Palindrome (Q206)",
                codes: {
                  Javascript: {
                    code: `import java.io.*;

                    class Solution {
                      public String shortestPalindrome(String s) {
                        final String t = new StringBuilder(s).reverse().toString();
                    
                        for (int i = 0; i < t.length(); ++i)
                          if (s.startsWith(t.substring(i)))
                            return t.substring(0, i) + s;
                    
                        return t + s;
                      }
                    
                      public static void Solution(String ...s){
                        PrintStream ps = System.out;
                        ps.println(new Solution().shortestPalindrome("aacecaaa"));
                      }
                    
                    }
                    `,
                    output: `aaacecaaa`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q207: {
        title: "215. Kth Largest Element in an Array (Q207)",
        content: (
          <>
            <Span>
              <b>Q215. Kth Largest Element in an Array (Q207)</b>
            </Span>
            <Span>
              Given an integer array nums and an integer k, return the kth
              largest element in the array.
            </Span>
            <Span>
              Note that it is the kth largest element in the sorted order, not
              the kth distinct element.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [3,2,1,5,6,4],
              <br /> k = 2<br />
              Output: 5
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [3,2,3,1,2,4,5,5,6], <br />k = 4 <br />
              Output: 4
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= k &lt;= nums.length &lt;= 104 <br />
              -104 &lt;= nums[i] &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "215. Kth Largest Element in an Array (Q207)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @param {number} k
                    * @return {number}
                    */
                   
                   function swap(nums,l,r){
                     [nums[l],nums[r]] = [nums[r],nums[l]]
                   }
                   
                   function quickSelect(nums,l,r,k){
                     let randIndex = Math.floor((Math.random() * (r-l+1)) + l);
                     swap(nums, randIndex, r);
                     let pivot = nums[r];
                     let nextSwapped = l;
                     for(let i = l; i < r; ++i)
                       if (nums[i] >= pivot)
                         swap(nums, nextSwapped++, i);
                     swap(nums, nextSwapped, r);
                     let count = nextSwapped - l + 1; 
                     if (count == k)
                       return nums[nextSwapped];
                     if (count > k)
                       return quickSelect(nums, l, nextSwapped - 1, k);
                     return quickSelect(nums, nextSwapped + 1, r, k - count);
                   }
                   
                   var findKthLargest = function(nums, k) {
                     return quickSelect(nums, 0, nums.length - 1, k);
                   };
                   
                   findKthLargest([3,2,3,1,2,4,5,5,6],4)
                   `,
                    output: `4`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q208: {
        title: "Q216. Combination Sum III (Q208)",
        content: (
          <>
            <Span>
              <b>Q216. Combination Sum III (Q208)</b>
            </Span>
            <Span>
              Find all valid combinations of k numbers that sum up to n such
              that the following conditions are true:
              <br />
              Only numbers 1 through 9 are used.
              <br />
              Each number is used at most once.
              <br />
              Return a list of all possible valid combinations. The list must
              not contain the same combination twice, and the combinations may
              be returned in any order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: k = 3, n = 7 <br />
              Output: [[1,2,4]] <br />
              Explanation: <br />
              1 + 2 + 4 = 7 <br />
              There are no other valid combinations.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: k = 3, n = 9<br />
              Output: [[1,2,6],[1,3,5],[2,3,4]]
              <br />
              Explanation:
              <br />
              1 + 2 + 6 = 9<br />
              1 + 3 + 5 = 9<br />
              2 + 3 + 4 = 9<br />
              There are no other valid combinations.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: k = 4, n = 1 <br />
              Output: [] <br />
              Explanation: There are no valid combinations. <br />
              Using 4 different numbers in the range [1,9], the smallest sum we
              can get is 1+2+3+4 = 10 and since 10 &gt; 1, there are no valid
              combination.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              2 &lt;= k &lt;= 9 <br />1 &lt;= n &lt;= 60
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(9Ck -&gt; 9^k)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q216. Combination Sum III (Q208)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} k
                    * @param {number} n
                    * @return {number[][]}
                    */
                   
                   function dfs(k,n,s,res,curr=[]){
                     if (k == 0 && n == 0) {
                       res.push(curr.slice());
                       return;
                     }
                     if (k == 0 || n < 0)
                       return;
                     for (let i = s; i <= 9; ++i) {
                       curr.push(i);
                       dfs(k - 1, n - i, i + 1, res,curr);
                       curr.pop()
                     }
                   }
                   
                   var combinationSum3 = function(k, n) {
                     const res = []
                     dfs(k,n,1,res)
                     return res  
                   };
                   
                   combinationSum3(3,9)`,
                    output: `[ [ 1, 2, 6 ], [ 1, 3, 5 ], [ 2, 3, 4 ] ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q209: {
        title: "Q217. Contains Duplicate (Q209)",
        content: (
          <>
            <Span>
              <b>Q217. Contains Duplicate (Q208)</b>
            </Span>
            <Span>
              Given an integer array nums, return true if any value appears at
              least twice in the array, and return false if every element is
              distinct.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3,1]
              <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3,4]
              <br />
              Output: false
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [1,1,1,3,3,4,3,2,4,2]
              <br />
              Output: true
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 105 <br />
              -109 &lt;= nums[i] &lt;= 109
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q217. Contains Duplicate (Q209)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {boolean}
                    */
                   var containsDuplicate = function(nums) {
                     return Object.values(nums.reduce((acc,curr)=>{
                       acc[curr] = (acc[curr]||0) + 1
                       return acc
                     },{})).some(x=>x>1)
                   };
                   
                   containsDuplicate([1,2,3,1])`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q210: {
        title: "Q218. The Skyline Problem (Q210)",
        content: (
          <>
            <Span>
              <b>Q218. The Skyline Problem (Q210)</b>
            </Span>
            <Span>
              A city's skyline is the outer contour of the silhouette formed by
              all the buildings in that city when viewed from a distance. Given
              the locations and heights of all the buildings, return the skyline
              formed by these buildings collectively.
            </Span>
            <Span>
              The geometric information of each building is given in the array
              buildings where buildings[i] = [lefti, righti, heighti]:
            </Span>
            <Span>
              lefti is the x coordinate of the left edge of the ith building.
              <br />
              righti is the x coordinate of the right edge of the ith building.
              <br />
              heighti is the height of the ith building.
            </Span>
            <Span>
              You may assume all buildings are perfect rectangles grounded on an
              absolutely flat surface at height 0.
            </Span>
            <Span>
              The skyline should be represented as a list of "key points" sorted
              by their x-coordinate in the form [[x1,y1],[x2,y2],...]. Each key
              point is the left endpoint of some horizontal segment in the
              skyline except the last point in the list, which always has a
              y-coordinate 0 and is used to mark the skyline's termination where
              the rightmost building ends. Any ground between the leftmost and
              rightmost buildings should be part of the skyline's contour.
            </Span>
            <Span>
              Note: There must be no consecutive horizontal lines of equal
              height in the output skyline. For instance, [...,[2 3],[4 5],[7
              5],[11 5],[12 7],...] is not acceptable; the three lines of height
              5 should be merged into one in the final output as such: [...,[2
              3],[4 5],[12 7],...]
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode218} left />
            <Span>
              Input: buildings =
              [[2,9,10],[3,7,15],[5,12,12],[15,20,10],[19,24,8]]
              <br />
              Output: [[2,10],[3,15],[7,12],[12,0],[15,10],[20,8],[24,0]]
              <br />
              Explanation:
              <br />
              Figure A shows the buildings of the input.
              <br />
              Figure B shows the skyline formed by those buildings. The red
              points in figure B represent the key points in the output list.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: buildings = [[0,2,3],[2,5,3]] <br />
              Output: [[0,3],[5,0]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= buildings.length &lt;= 104 <br />
              0 &lt;= lefti &lt; righti &lt;= 231 - 1<br />
              1 &lt;= heighti &lt;= 231 - 1<br />
              buildings is sorted by lefti in non-decreasing order.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.log n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q218. The Skyline Problem (Q210)",
                codes: {
                  "C++": {
                    code: `#include <vector>
                    #include <bits/stdc++.h>
                    #include <cmath>
                    #include <iostream>
                    
                    using namespace std;
                    
                    class Solution {
                     public:
                      vector<vector<int>> getSkyline(vector<vector<int>>& buildings) {
                        vector<vector<int>> ans;
                        vector<vector<int>> events; 
                    
                        for (const auto& b : buildings) {
                          events.push_back({b[0], b[2]});
                          events.push_back({b[1], -b[2]});
                        }
                    
                        sort(begin(events), end(events), [](const auto& a, const auto& b) {
                          return a[0] == b[0] ? a[1] > b[1] : a[0] < b[0];
                        });
                    
                        for (const auto& event : events) {
                          const int x = event[0];
                          const int h = abs(event[1]);
                          const int isEntering = event[1] > 0;
                          if (isEntering) {
                            if (h > maxHeight())
                              ans.push_back({x, h});
                            set.insert(h);
                          } else {
                            set.erase(set.equal_range(h).first);
                            if (h > maxHeight())
                              ans.push_back({x, maxHeight()});
                          }
                        }
                    
                        return ans;
                      }
                    
                     private:
                      multiset<int> set;
                      int maxHeight() const {
                        return set.empty() ? 0 : *rbegin(set);
                      }
                    };
                    
                    // int main(){
                    //   Solution s;
                    //   vector<vector<int>> v{
                    //     {2,9,10},
                    //     {3,7,15},
                    //     {5,12,12},
                    //     {15,20,10},
                    //     {19,24,8}
                    //   };
                    //   for(auto v1 : s.getSkyline(v)){
                    //      for(auto v2 : v1){
                    //        cout << v2 << " ";
                    //      }
                    //      cout << endl;
                    //   }
                    //   return 0;
                    // }
                    `,
                    output: `2 10 
                    3 15 
                    7 12 
                    12 0 
                    15 10 
                    20 8 
                    24 0 `,
                  },
                },
              }}
            />
          </>
        ),
      },
      q211: {
        title: "Q219. Contains Duplicate II (Q211)",
        content: (
          <>
            <Span>
              <b>Q219. Contains Duplicate II (Q211)</b>
            </Span>
            <Span>
              Given an integer array nums and an integer k, return true if there
              are two distinct indices i and j in the array such that nums[i] ==
              nums[j] and abs(i - j) &lt;= k.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3,1], k = 3<br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1,0,1,1], k = 1<br />
              Output: true
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3,1,2,3], k = 2<br />
              Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 105 -109 &lt;= nums[i] &lt;= 109 0 &lt;=
              k &lt;= 105
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q219. Contains Duplicate II (Q211)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @param {number} k
                    * @return {boolean}
                    */
                   var containsNearbyDuplicate = function(nums, k) {
                     const seen = new Set();
                     for (let i = 0; i < nums.length; ++i){
                     if (i > k)
                       seen.delete(nums[i - k-1]);
                     if (seen.has(nums[i]))
                       return true;
                     seen.add(nums[i])
                     }
                     return false;
                   };
                   
                   containsNearbyDuplicate([1,2,3,1],3)`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q212: {
        title: "Q220. Contains Duplicate III (Q212)",
        content: (
          <>
            <Span>
              <b>Q220. Contains Duplicate III (Q212)</b>
            </Span>
            <Span>
              Given an integer array nums and two integers k and t, return true
              if there are two distinct indices i and j in the array such that
              abs(nums[i] - nums[j]) &lt;= t and abs(i - j) &lt;= k.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3,1],
              <br /> k = 3,
              <br /> t = 0 <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1,0,1,1], <br />k = 1,
              <br /> t = 2<br />
              Output: true
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [1,5,9,1,5,9],
              <br /> k = 2, <br />t = 3<br />
              Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 2 * 10^4 <br />
              -231 &lt;= nums[i] &lt;= 2^31 - 1 <br />
              0 &lt;= k &lt;= 104 <br />0 &lt;= t &lt;= 2^31 - 1
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.log k)
              <br />
              Space: O(k)
            </p>
            <CodeEditor
              options={{
                title: "Q220. Contains Duplicate III (Q212)",
                codes: {
                  Java: {
                    code: `import java.util.*;

                    class Solution {
                      public boolean containsNearbyAlmostDuplicate(int[] nums, int k, int t) {
                        TreeSet<Long> set = new TreeSet<>();
                    
                        for (int i = 0; i < nums.length; ++i) {
                          final long num = (long) nums[i];
                          final Long ceiling = set.ceiling(num); // the smallest num >= nums[i]
                          if (ceiling != null && ceiling - num <= t)
                            return true;
                          final Long floor = set.floor(num); // the largest num <= nums[i]
                          if (floor != null && num - floor <= t)
                            return true;
                          set.add(num);
                          if (i >= k)
                            set.remove((long) nums[i - k]);
                        }
                    
                        return false;
                      }
                      
                      public static void Solution(String ...s){
                        System.out.println(new Solution().containsNearbyAlmostDuplicate(new int[]{1,2,3,1},3,0));
                      }
                    
                    }
                    `,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q213: {
        title: "Q221. Maximal Square (Q213)",
        content: (
          <>
            <Span>
              <b>Q221. Maximal Square (Q213)</b>
            </Span>
            <Span>
              Given an m x n binary matrix filled with 0's and 1's, find the
              largest square containing only 1's and return its area.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode221} left />
            <Span>
              Input: <br />
              matrix = [["1","0","1","0","0"], <br />
              ["1","0","1","1","1"], <br />
              ["1","1","1","1","1"], <br />
              ["1","0","0","1","0"]] <br />
              Output: 4
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: <br />
              matrix = [["0","1"],["1","0"]] <br />
              Output: 1
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: matrix = [["0"]]
              <br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == matrix.length <br />
              n == matrix[i].length <br />
              1 &lt;= m, n &lt;= 300 <br />
              matrix[i][j] is '0' or '1'.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q221. Maximal Square (Q213)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {character[][]} matrix
                    * @return {number}
                    */
                   var maximalSquare = function(matrix) {
                     if (matrix.length == 0) return 0;
                     const m = matrix.length;
                     const n = matrix[0].length;
                     const dp = Array(n).fill(0);
                     let maxLength = 0;
                     let prev = 0; // dp[i - 1][j - 1]
                     for (let i = 0; i < m; ++i)
                       for (let j = 0; j < n; ++j) {
                         let cache = dp[j];
                         if (i == 0 || j == 0 || matrix[i][j] == '0')
                           dp[j] = matrix[i][j] == '1' ? 1 : 0;
                         else
                           dp[j] = Math.min(prev, Math.min(dp[j], dp[j - 1])) + 1;
                         maxLength = Math.max(maxLength, dp[j]);
                         prev = cache;
                       }
                     return maxLength * maxLength;
                   };
                   
                   maximalSquare([["1","0","1","0","0"],["1","0","1","1","1"],["1","1","1","1","1"],["1","0","0","1","0"]])`,
                    output: `4`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q214: {
        title: "Q222. Count Complete Tree Nodes (Q214)",
        content: (
          <>
            <Span>
              <b>Q222. Count Complete Tree Nodes (Q214)</b>
            </Span>
            <Span>
              Given the root of a complete binary tree, return the number of the
              nodes in the tree.
            </Span>
            <Span>
              According to Wikipedia, every level, except possibly the last, is
              completely filled in a complete binary tree, and all nodes in the
              last level are as far left as possible. It can have between 1 and
              2h nodes inclusive at the last level h.
            </Span>
            <Span>
              Design an algorithm that runs in less than O(n) time complexity.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode222} left />
            <Span>
              Input: root = [1,2,3,4,5,6] <br />
              Output: 6
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [] <br />
              Output: 0
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = [1]
              <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 5 * 104].{" "}
              <br />
              0 &lt;= Node.val &lt;= 5 * 104 <br />
              The tree is guaranteed to be complete.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log^2 n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q222. Count Complete Tree Nodes (Q214)",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                  }
                  
                  /**
                   * @param {TreeNode} root
                   * @return {number}
                   */
                  var countNodes = function(root) {
                    if (root == null) return 0;
                    let l = r = root;
                    let heightL = 0;
                    let heightR = 0;
                    while (l != null) {
                      ++heightL;
                      l = l.left;
                    }
                    while (r != null) {
                      ++heightR;
                      r = r.right;
                    }
                    if (heightL == heightR) 
                      return Math.pow(2, heightL) - 1;
                    return 1 + countNodes(root.left) + countNodes(root.right);
                  };
                  
                  const t = new TreeNode(1)
                  t.left = new TreeNode(2)
                  t.right = new TreeNode(3)
                  t.left.left = new TreeNode(4)
                  t.left.right = new TreeNode(5)
                  t.right.left = new TreeNode(6)
                  countNodes(t)`,
                    output: `4`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q215: {
        title: "Q223. Rectangle Area (Q215)",
        content: (
          <>
            <Span>
              <b>Q223. Rectangle Area (Q215)</b>
            </Span>
            <Span>
              Given the coordinates of two rectilinear rectangles in a 2D plane,
              return the total area covered by the two rectangles.
              <br />
              The first rectangle is defined by its bottom-left corner (ax1,
              ay1) and its top-right corner (ax2, ay2).
              <br />
              The second rectangle is defined by its bottom-left corner (bx1,
              by1) and its top-right corner (bx2, by2).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode223} left />
            <Span>
              Input: ax1 = -3, ay1 = 0, ax2 = 3, ay2 = 4, bx1 = 0, by1 = -1, bx2
              = 9, by2 = 2<br />
              Output: 45
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: ax1 = -2, ay1 = -2, <br />
              ax2 = 2, ay2 = 2, <br />
              bx1 = -2, by1 = -2,
              <br /> bx2 = 2, by2 = 2
              <br />
              Output: 16
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              -104 &lt;= ax1, ay1, ax2, ay2, bx1, by1, bx2, by2 &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q223. Rectangle Area (Q215)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} ax1
                    * @param {number} ay1
                    * @param {number} ax2
                    * @param {number} ay2
                    * @param {number} bx1
                    * @param {number} by1
                    * @param {number} bx2
                    * @param {number} by2
                    * @return {number}
                    */
                   var computeArea = function(ax1, ay1, ax2, ay2, bx1, by1, bx2, by2) {
                     let x = y = 0
                     if(Math.max(ax1, bx1) < Math.min(ax2, bx2))
                     x = Math.min(ax2, bx2) - Math.max(ax1, bx1) 
                     if(Math.max(ay1, by1) < Math.min(ay2, by2))
                     y = Math.min(ay2, by2) - Math.max(ay1, by1)
                     return (ax2 - ax1) * (ay2 - ay1) + (bx2 - bx1) * (by2 - by1) - x * y  
                   };
                   
                   computeArea(-3,0,3,2,0,-1,9,2)`,
                    output: `33`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q216: {
        title: "Q224. Basic Calculator (Q216)",
        content: (
          <>
            <Span>
              <b>Q224. Basic Calculator (Q216)</b>
            </Span>
            <Span>
              Given a string s representing a valid expression, implement a
              basic calculator to evaluate it, and return the result of the
              evaluation.
              <br />
              Note: You are not allowed to use any built-in function which
              evaluates strings as mathematical expressions, such as eval().
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "1 + 1"
              <br />
              Output: 2
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = " 2-1 + 2 "
              <br />
              Output: 3
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = "(1+(4+5+2)-3)+(6+8)"
              <br />
              Output: 23
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 3 * 105 s consists of digits, '+', '-',
              '(', ')', and ' '. <br />
              s represents a valid expression.
              <br />
              '+' is not used as a unary operation (i.e., "+1" and "+(2 + 3)" is
              invalid).
              <br />
              '-' could be used as a unary operation (i.e., "-1" and "-(2 + 3)"
              is valid).
              <br />
              There will be no two consecutive operators in the input.
              <br />
              Every number and running calculation will fit in a signed 32-bit
              integer.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q224. Basic Calculator (Q216)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {number}
                    */
                   var calculate = function(s) {
                     // return eval(s)
                     let ans = 0;
                     let num = 0;
                     let sign = 1;
                     const stack = [];
                     stack.push(sign);
                     for (let c of s)
                       if (Number.isInteger(parseInt(c))){
                         num = num * 10 + parseInt(c);
                       }
                       else if (c == '(')
                         stack.push(sign);
                       else if (c == ')')
                         stack.pop();
                       else if (c == '+' || c == '-') {
                         ans += sign * num;
                         sign = (c == '+' ? 1 : -1) * stack.slice(-1)[0];
                         num = 0;
                       }
                     return ans + sign * num;
                   };
                   
                   calculate("1 + 1")`,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q217: {
        title: "Q225. Implement Stack using Queues (Q217)",
        content: (
          <>
            <Span>
              <b>Q225. Implement Stack using Queues (Q217)</b>
            </Span>
            <Span>
              Implement a last-in-first-out (LIFO) stack using only two queues.
              The implemented stack should support all the functions of a normal
              stack (push, top, pop, and empty).
            </Span>
            <Span>
              Implement a last-in-first-out (LIFO) stack using only two queues.{" "}
              <br />
              void push(int x) Pushes element x to the top of the stack.
              <br />
              int pop() Removes the element on the top of the stack and returns
              it.
              <br />
              int top() Returns the element on the top of the stack.
              <br />
              boolean empty() Returns true if the stack is empty, false
              otherwise.
            </Span>
            <Span>
              Notes:
              <br />
              You must use only standard operations of a queue, which means that
              only push to back, peek/pop from front, size and is empty
              operations are valid.
              <br />
              Depending on your language, the queue may not be supported
              natively. You may simulate a queue using a list or deque
              (double-ended queue) as long as you use only a queue's standard
              operations.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input <br />
              ["MyStack", "push", "push", "top", "pop", "empty"] <br />
              [[], [1], [2], [], [], []] <br />
              Output <br />
              [null, null, null, 2, 2, false]
              <br />
              Explanation
              <br />
              MyStack myStack = new MyStack();
              <br />
              myStack.push(1);
              <br />
              myStack.push(2);
              <br />
              myStack.top(); // return 2
              <br />
              myStack.pop(); // return 2 myStack.empty(); // return False
            </Span>
            <Span>
              1 &lt;= x &lt;= 9 <br />
              At most 100 calls will be made to push, pop, top, and empty.{" "}
              <br />
              All the calls to pop and top are valid.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q225. Implement Stack using Queues (Q217)",
                codes: {
                  Javascript: {
                    code: `var MyStack = function() {
                      this.q = []
                    };
                    
                    /** 
                     * @param {number} x
                     * @return {void}
                     */
                    MyStack.prototype.push = function(x) {
                      this.q.push(x);
                      for (let i = 0; i < this.q.length - 1; ++i)
                      this.q.push(this.q.shift());
                    };
                    
                    /**
                     * @return {number}
                     */
                    MyStack.prototype.pop = function() {
                      return this.q.shift()
                    };
                    
                    /**
                     * @return {number}
                     */
                    MyStack.prototype.top = function() {
                       return this.q[0] 
                    };
                    
                    /**
                     * @return {boolean}
                     */
                    MyStack.prototype.empty = function() {
                      return !this.q.length
                    };
                    
                    
                    var obj = new MyStack()
                    obj.push(4)
                    obj.push(8)
                    obj.push(1)
                    var param_2 = obj.pop()
                    var param_3 = obj.top()
                    var param_4 = obj.empty()
                    console.log({ param_2, param_3, param_4 })`,
                    output: `{ param_2: 1, param_3: 8, param_4: false }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q218: {
        title: "Q226. Invert Binary Tree (Q218)",
        content: (
          <>
            <Span>
              <b>Q226. Invert Binary Tree (Q218)</b>
            </Span>
            <Span>
              Given the root of a binary tree, invert the tree, and return its
              root.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode226} left />
            <Span>
              Input: root = [4,2,7,1,3,6,9] <br />
              Output: [4,7,2,9,6,3,1]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [2,1,3]
              <br />
              Output: [2,3,1]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = []
              <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 100].
              <br />
              -100 &lt;= Node.val &lt;= 100
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q226. Invert Binary Tree (Q217)",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                    }
                    
                    /**
                     * @param {TreeNode} root
                     * @return {TreeNode}
                     */
                    var invertTree = function(root) {
                      let queue = []
                      queue.push(root)
                      while (queue.length && root)
                      {
                      let curr = queue.shift();
                      let temp = curr.left;
                      curr.left = curr.right;
                      curr.right = temp;
                      if (curr.left)
                        queue.push(curr.left);
                      if (curr.right)
                        queue.push(curr.right);
                      }
                      return root
                    };
                    
                    const t = new TreeNode(1)
                    t.left = new TreeNode(2)
                    t.right = new TreeNode(3)
                    invertTree(t)
                    `,
                    output: `TreeNode {
                      val: 1,
                      left: TreeNode { val: 3, left: null, right: null },
                      right: TreeNode { val: 2, left: null, right: null }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q219: {
        title: "Q227. Basic Calculator II (Q219)",
        content: (
          <>
            <Span>
              <b>Q227. Basic Calculator II (Q219)</b>
            </Span>
            <Span>
              Given a string s which represents an expression, evaluate this
              expression and return its value.
              <br />
              The integer division should truncate toward zero.
              <br />
              You may assume that the given expression is always valid. All
              intermediate results will be in the range of [-231, 231 - 1].
              <br />
              Note: You are not allowed to use any built-in function which
              evaluates strings as mathematical expressions, such as eval().
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "3+2*2" <br />
              Output: 7
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = " 3/2 " <br />
              Output: 1
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = " 3+5 / 2 "<br />
              Output: 5
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 3 * 105 <br />
              s consists of integers and operators ('+', '-', '*', '/')
              separated by some number of spaces. <br />
              s represents a valid expression. <br />
              All the integers in the expression are non-negative integers in
              the range [0, 231 - 1]. <br />
              The answer is guaranteed to fit in a 32-bit integer.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q227. Basic Calculator II (Q219)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {number}
                    */
                   var calculate = function(s) {
                     let ans = 0;
                     let currNum = 0;
                     let prevNum = 0;
                     let op = '+';
                     for (let i = 0; i < s.length; ++i) {
                       const c = s[i];
                       if (Number.isInteger(parseInt(c)))
                         currNum = currNum * 10 + parseInt(c);
                       if (!Number.isInteger(parseInt(c)) && c != ' ' || i == s.length - 1) {
                         if (op == '+' || op == '-') {
                           ans += prevNum;
                           prevNum = op == '+' ? currNum : -currNum;
                         } else if (op == '*') {
                           prevNum = prevNum * currNum;
                         } else if (op == '/') {
                           prevNum = parseInt(prevNum / currNum);
                         }
                         op = c;
                         currNum = 0;
                       }
                     }
                     return ans + prevNum; 
                   };
                   
                   calculate("3/2")`,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q220: {
        title: "Q228. Summary Ranges (Q220)",
        content: (
          <>
            <Span>
              <b>Q228. Summary Ranges (Q219)</b>
            </Span>
            <Span>
              You are given a sorted unique integer array nums.
              <br />
              Return the smallest sorted list of ranges that cover all the
              numbers in the array exactly. That is, each element of nums is
              covered by exactly one of the ranges, and there is no integer x
              such that x is in one of the ranges but not in nums.
              <br />
              Each range [a,b] in the list should be output as:
              <br />
              "a-&gt;b" if a != b<br />
              "a" if a == b
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [0,1,2,4,5,7]
              <br />
              Output: ["0-&gt;2","4-&gt;5","7"]
              <br />
              Explanation: The ranges are:
              <br />
              [0,2] --&gt; "0-&gt;2"
              <br />
              [4,5] --&gt; "4-&gt;5"
              <br />
              [7,7] --&gt; "7"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0,2,3,4,6,8,9] <br />
              Output: ["0","2-&gt;4","6","8-&gt;9"] <br />
              Explanation: The ranges are: <br />
              [0,0] --&gt; "0" <br />
              [2,4] --&gt; "2-&gt;4" <br />
              [6,6] --&gt; "6" <br />
              [8,9] --&gt; "8-&gt;9" <br />
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              0 &lt;= nums.length &lt;= 20
              <br />
              -231 &lt;= nums[i] &lt;= 231 - 1<br />
              All the values of nums are unique.
              <br />
              nums is sorted in ascending order.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q228. Summary Ranges (Q220)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {string[]}
                    */
                   var summaryRanges = function(nums) {
                     const ans = [];
                     for (let i = 0; i < nums.length; ++i) {
                       const begin = nums[i];
                       while (i + 1 < nums.length && nums[i] == nums[i + 1] - 1)
                         ++i;
                       const end = nums[i];
                       if (begin == end)
                         ans.push("" + begin);
                       else
                         ans.push("" + begin + "->" + end);
                     }
                     return ans;
                   };
                   
                   summaryRanges([0,2,3,4,6,8,9])`,
                    output: `[ '0', '2->4', '6', '8->9' ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q221: {
        title: "Q229. Majority Element II (Q221)",
        content: (
          <>
            <Span>
              <b>Q229. Majority Element II (Q221)</b>
            </Span>
            <Span>
              Given an integer array of size n, find all elements that appear
              more than ⌊ n/3 ⌋ times.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [3,2,3] <br />
              Output: [3]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1] <br />
              Output: [1]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [1,2]
              <br />
              Output: [1,2]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 5 * 104
              <br />
              -109 &lt;= nums[i] &lt;= 109
            </Span>
            <p>
              Follow up: Could you solve the problem in linear time and in O(1)
              space?
            </p>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q229. Majority Element II (Q221)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number[]}
                    */
                   var majorityElement = function(nums) {
                     const ans = [];
                     let candidate1 = 0;
                     let candidate2 = 1; 
                     let countSoFar1 = 0; 
                     let countSoFar2 = 0;
                     for (let num of nums)
                       if (num == candidate1) {
                         ++countSoFar1;
                       } else if (num == candidate2) {
                         ++countSoFar2;
                       } else if (countSoFar1 == 0) { 
                         candidate1 = num;
                         ++countSoFar1;
                       } else if (countSoFar2 == 0) { 
                         candidate2 = num;
                         ++countSoFar2;
                       } else { 
                         --countSoFar1;
                         --countSoFar2;
                       }
                     let count1 = 0;
                     let count2 = 0;
                     for (let num of nums)
                       if (num == candidate1)
                         ++count1;
                       else if (num == candidate2)
                         ++count2;
                     if (count1 > parseInt(nums.length / 3))
                       ans.push(candidate1);
                     if (count2 > parseInt(nums.length / 3))
                       ans.push(candidate2);
                     return ans; 
                   };
                   
                   majorityElement([3,2,3])`,
                    output: `[ 3 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q222: {
        title: "Q230. Kth Smallest Element in a BST (Q222)",
        content: (
          <>
            <Span>
              <b>Q230. Kth Smallest Element in a BST (Q222)</b>
            </Span>
            <Span>
              Given the root of a binary search tree, and an integer k, return
              the kth smallest value (1-indexed) of all the values of the nodes
              in the tree.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode230} left />
            <Span>
              Input: root = [3,1,4,null,2], k = 1<br />
              Output: 1
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [5,3,6,2,4,null,null,1], k = 3<br />
              Output: 3
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is n. <br />
              1 &lt;= k &lt;= n &lt;= 104 <br />0 &lt;= Node.val &lt;= 104
            </Span>
            <Span>
              Follow up: If the BST is modified often (i.e., we can do insert
              and delete operations) and you need to find the kth smallest
              frequently, how would you optimize?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q230. Kth Smallest Element in a BST (Q222)",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                  }
                  
                  /**
                   * @param {TreeNode} root
                   * @param {number} k
                   * @return {number}
                   */
                  var kthSmallest = function(root, k) {
                    const stack = [];
                    while (root) {
                      stack.push(root);
                      root = root.left;
                    }
                    for (let i = 0; i < k - 1; ++i) {
                      root = stack.pop();
                      root = root.right;
                      while (root) {
                        stack.push(root);
                        root = root.left;
                      }
                    }
                    return stack.slice(-1)[0].val;   
                  };
                  
                  const t = new TreeNode(3)
                  t.left = new TreeNode(1)
                  t.left.right = new TreeNode(2)
                  t.right = new TreeNode(4)
                  kthSmallest(t)`,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q223: {
        title: "Q231. Power of Two (Q223)",
        content: (
          <>
            <Span>
              <b>Q231. Power of Two (Q223)</b>
            </Span>
            <Span>
              Given an integer n, return true if it is a power of two.
              Otherwise, return false.
              <br />
              An integer n is a power of two, if there exists an integer x such
              that n == 2x.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 1 <br />
              Output: true <br />
              Explanation: 2^0 = 1
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 16
              <br />
              Output: true
              <br />
              Explanation: 2^4 = 16
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: n = 3<br />
              Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>-2^31 &lt;= n &lt;= 2^31 - 1</Span>
            <Span>Follow up: Could you solve it without loops/recursion?</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q231. Power of Two (Q223)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {boolean}
                    */
                   var isPowerOfTwo = function(n) {
                    if(typeof n !== 'number' || n>= Math.pow(2,31)-1 || n<=Math.pow(-2,31))
                     return false  
                     return n && (n & (n - 1)) === 0;
                     // 2 && 2 & 1 
                     // 0010 & 0001 -> 0000
                   };
                   
                   isPowerOfTwo(-2147483648)`,
                    output: `false`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q224: {
        title: "Q232. Implement Queue using Stacks (Q224)",
        content: (
          <>
            <Span>
              <b>Q232. Implement Queue using Stacks (Q224)</b>
            </Span>
            <Span>
              Implement a first in first out (FIFO) queue using only two stacks.
              The implemented queue should support all the functions of a normal
              queue (push, peek, pop, and empty).
            </Span>
            <Span>
              Implement the MyQueue class:
              <br />
              void push(int x) Pushes element x to the back of the queue. <br />
              int pop() Removes the element from the front of the queue and
              returns it.
              <br />
              int peek() Returns the element at the front of the queue. <br />
              boolean empty() Returns true if the queue is empty, false
              otherwise.
            </Span>
            <Span>
              Notes:
              <br />
              You must use only standard operations of a stack, which means only
              push to top, peek/pop from top, size, and is empty operations are
              valid.
              <br />
              Depending on your language, the stack may not be supported
              natively. You may simulate a stack using a list or deque
              (double-ended queue) as long as you use only a stack's standard
              operations.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input ["MyQueue", "push", "push", "peek", "pop", "empty"]
              <br />
              [[], [1], [2], [], [], []]
              <br />
              Output
              <br />
              [null, null, null, 1, 1, false]
              <br />
              Explanation MyQueue myQueue = new MyQueue();
              <br />
              myQueue.push(1); // queue is: [1]
              <br />
              myQueue.push(2); // queue is: [1, 2] (leftmost is front of the
              queue)
              <br />
              myQueue.peek(); // return 1<br />
              myQueue.pop(); // return 1, queue is [2]
              <br />
              myQueue.empty(); // return false
              <br />
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= x &lt;= 9 <br />
              At most 100 calls will be made to push, pop, peek, and empty.{" "}
              <br />
              All the calls to pop and peek are valid.
            </Span>
            <Span>
              Follow-up: Can you implement the queue such that each operation is
              amortized O(1) time complexity? In other words, performing n
              operations will take overall O(n) time even if one of those
              operations may take longer.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q232. Implement Queue using Stacks (Q224)",
                codes: {
                  Javascript: {
                    code: `
                    var MyQueue = function() {
                      this.input = []
                      this.output = []
                    };
                    
                    /** 
                     * @param {number} x
                     * @return {void}
                     */
                    MyQueue.prototype.push = function(x) {
                      this.input.push(x)  
                    };
                    
                    /**
                     * @return {number}
                     */
                    MyQueue.prototype.pop = function() {
                      this.peek() 
                      return this.output.pop()
                    };
                    
                    /**
                     * @return {number}
                     */
                    MyQueue.prototype.peek = function() {
                      if(!this.output.length)
                      while(this.input.length)
                      this.output.push(this.input.pop())
                      return this.output.slice(-1)[0]  
                    };
                    
                    /**
                     * @return {boolean}
                     */
                    MyQueue.prototype.empty = function() {
                      return !this.input.length && !this.output.length  
                    };
                    
                    
                    var obj = new MyQueue()
                    obj.push(1)
                    obj.push(2)
                    var param_2 = obj.pop()
                    var param_3 = obj.peek()
                    var param_4 = obj.empty()
                    console.log({param_2,param_3,param_4})`,
                    output: `{ param_2: 1, param_3: 2, param_4: false }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q225: {
        title: "Q233. Number of Digit One (Q225)",
        content: (
          <>
            <Span>
              <b>Q233. Number of Digit One (Q225)</b>
            </Span>
            <Span>
              Given an integer n, count the total number of digit 1 appearing in
              all non-negative integers less than or equal to n.
            </Span>

            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 13 <br />
              Output: 6
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 0<br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>0 &lt;= n &lt;= 109</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q233. Number of Digit One (Q225)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number}
                    */
                   var countDigitOne = function(n) {
                     
                     let ans = 0
                     let pow10 = 1
                     while(pow10 <= n){
                     let divisor = pow10 * 10
                     let quotient = parseInt(n/divisor) 
                     let remainder = n % divisor
                     if(quotient > 0)
                       ans += quotient * pow10
                     if(remainder >= pow10)
                       ans += Math.min(remainder - pow10 + 1, pow10)
                     pow10 *= 10
                     }
                     return ans
                   };
                   
                   countDigitOne(13)`,
                    output: `6`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q226: {
        title: "Q234. Palindrome Linked List (Q226)",
        content: (
          <>
            <Span>
              <b>Q234. Palindrome Linked List (Q226)</b>
            </Span>
            <Span>
              Given the head of a singly linked list, return true if it is a
              palindrome.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: head = [1,2,2,1] <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [1,2]
              <br />
              Output: false
            </Span>

            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the list is in the range [1, 105].
              <br />0 &lt;= Node.val &lt;= 9
            </Span>
            <Span>Follow up: Could you do it in O(n) time and O(1) space?</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n) <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q234. Palindrome Linked List (Q226)",
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                  }
                  
                  /**
                   * @param {ListNode} head
                   * @return {boolean}
                   */
                  
                  function reverse(head) {
                    let prev = null;
                    while (head) {
                    let next = head.next;
                    head.next = prev;
                    prev = head;
                    head = next;
                    }
                    return prev;
                  }
                  
                  var isPalindrome = function(head) {
                    let slow = head;
                    let fast = head;
                    while (fast && fast.next) {
                      slow = slow.next;
                      fast = fast.next.next;
                    }
                    if (fast)
                      slow = slow.next;
                    slow = reverse(slow);
                    while (slow) {
                      if (slow.val != head.val)
                        return false;
                      slow = slow.next;
                      head = head.next;
                    }
                    return true; 
                  };
                  
                  const l = new ListNode(1)
                  l.next = new ListNode(2)
                  l.next.next = new ListNode(2)
                  l.next.next.next = new ListNode(1)
                  isPalindrome(l)`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q227: {
        title: "Q235. Lowest Common Ancestor of a Binary Search Tree (Q227)",
        content: (
          <>
            <Span>
              <b>Q235. Lowest Common Ancestor of a Binary Search Tree (Q227)</b>
            </Span>
            <Span>
              Given a binary search tree (BST), find the lowest common ancestor
              (LCA) of two given nodes in the BST.
              <br />
              According to the definition of LCA on Wikipedia: “The lowest
              common ancestor is defined between two nodes p and q as the lowest
              node in T that has both p and q as descendants (where we allow a
              node to be a descendant of itself).”
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode235} left />
            <Span>
              Input: root = [6,2,8,0,4,7,9,null,null,3,5],
              <br /> p = 2, q = 8 <br />
              Output: 6 <br />
              Explanation: The LCA of nodes 2 and 8 is 6.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [6,2,8,0,4,7,9,null,null,3,5],
              <br /> p = 2, q = 4<br />
              Output: 2<br />
              Explanation: The LCA of nodes 2 and 4 is 2, since a node can be a
              descendant of itself according to the LCA definition.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = [2,1], <br />p = 2, q = 1<br />
              Output: 2
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [2, 105]. <br />
              -109 &lt;= Node.val &lt;= 109 <br />
              All Node.val are unique. <br />
              p != q <br />p and q will exist in the BST.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(h) <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title:
                  "Q235. Lowest Common Ancestor of a Binary Search Tree (Q227)",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val) {
                      this.val = val;
                      this.left = this.right = null;
                  }
                  
                  
                  /**
                   * @param {TreeNode} root
                   * @param {TreeNode} p
                   * @param {TreeNode} q
                   * @return {TreeNode}
                   */
                  var lowestCommonAncestor = function(root, p, q) {
                    if(root.val > Math.max(p.val, q.val))
                    return lowestCommonAncestor(root.left, p, q);
                    if(root.val < Math.min(p.val, q.val))
                    return lowestCommonAncestor(root.right, p, q);
                    console.log("Answer: " + root.val)
                    return root;   
                  };
                  
                  const t = new TreeNode(6)
                  t.left = new TreeNode(2)
                  t.left.left = new TreeNode(0)
                  t.left.right = new TreeNode(4)
                  t.left.right.left = new TreeNode(3)
                  t.left.right.right = new TreeNode(5)
                  t.right = new TreeNode(8)
                  t.right.left = new TreeNode(7)
                  t.right.right = new TreeNode(9)
                  lowestCommonAncestor(t,2,4)
                  `,
                    output: `Answer: 6
                    TreeNode {
                      val: 6,
                      right: TreeNode {
                        val: 8,
                        right: TreeNode { val: 9, right: null, left: null },
                        left: TreeNode { val: 7, right: null, left: null }
                      },
                      left: TreeNode {
                        val: 2,
                        right: TreeNode { val: 4, right: [TreeNode], left: [TreeNode] },
                        left: TreeNode { val: 0, right: null, left: null }
                      }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q228: {
        title: "Q236. Lowest Common Ancestor of a Binary Tree (Q228)",
        content: (
          <>
            <Span>
              <b>Q236. Lowest Common Ancestor of a Binary Tree (Q228)</b>
            </Span>
            <Span>
              Given a binary tree, find the lowest common ancestor (LCA) of two
              given nodes in the tree.
              <br />
              According to the definition of LCA on Wikipedia: “The lowest
              common ancestor is defined between two nodes p and q as the lowest
              node in T that has both p and q as descendants (where we allow a
              node to be a descendant of itself).”
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode236} left />
            <Span>
              Input: root = [3,5,1,6,2,0,8,null,null,7,4], <br /> p = 5, q = 1{" "}
              <br />
              Output: 3 <br />
              Explanation: The LCA of nodes 5 and 1 is 3.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [3,5,1,6,2,0,8,null,null,7,4], <br /> p = 5, q = 4{" "}
              <br />
              Output: 5 <br />
              Explanation: The LCA of nodes 5 and 4 is 5, since a node can be a
              descendant of itself according to the LCA definition.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: root = [1,2], <br />p = 1, q = 2 <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [2, 105]. <br />
              -109 &lt;= Node.val &lt;= 109 <br />
              All Node.val are unique. <br />
              p != q <br />p and q will exist in the tree.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(h) <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q236. Lowest Common Ancestor of a Binary Tree (Q228)",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val) {
                      this.val = val;
                      this.left = this.right = null;
                  }
                  
                  /**
                   * @param {TreeNode} root
                   * @param {TreeNode} p
                   * @param {TreeNode} q
                   * @return {TreeNode}
                   */
                  var lowestCommonAncestor = function(root, p, q) {
                    if (!root || root == p || root == q)
                      return root;
                    const l = lowestCommonAncestor(root.left, p, q);
                    const r = lowestCommonAncestor(root.right, p, q);
                    if (l && r) return root;
                    return l ? l : r;
                  };
                  
                  const t = new TreeNode(3)
                  t.left = new TreeNode(5)
                  t.left.left = new TreeNode(6)
                  t.left.right = new TreeNode(2)
                  t.left.right.left = new TreeNode(7)
                  t.left.right.right = new TreeNode(4)
                  t.right = new TreeNode(1)
                  t.right.left = new TreeNode(0)
                  t.right.right = new TreeNode(8)
                  lowestCommonAncestor(t,5,1)
                  console.log(t)
                  `,
                    output: `TreeNode {
                      val: 3,
                      right: TreeNode {
                        val: 1,
                        right: TreeNode { val: 8, right: null, left: null },
                        left: TreeNode { val: 0, right: null, left: null }
                      },
                      left: TreeNode {
                        val: 5,
                        right: TreeNode { val: 2, right: [TreeNode], left: [TreeNode] },
                        left: TreeNode { val: 6, right: null, left: null }
                      }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q229: {
        title: "Q237. Delete Node in a Linked List (Q229)",
        content: (
          <>
            <Span>
              <b>Q237. Delete Node in a Linked List (Q229)</b>
            </Span>
            <Span>
              Write a function to delete a node in a singly-linked list. You
              will not be given access to the head of the list, instead you will
              be given access to the node to be deleted directly.
              <br />
              It is guaranteed that the node to be deleted is not a tail node in
              the list.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: head = [4,5,1,9], node = 5 <br />
              Output: [4,1,9] <br />
              Explanation: You are given the second node with value 5, the
              linked list should become 4 -&gt; 1 -&gt; 9 after calling your
              function.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [4,5,1,9],
              <br /> node = 1<br />
              Output: [4,5,9]
              <br />
              Explanation: You are given the third node with value 1, the linked
              list should become 4 -&gt; 5 -&gt; 9 after calling your function.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of the nodes in the given list is in the range [2,
              1000]. <br />
              -1000 &lt;= Node.val &lt;= 1000 <br />
              The value of each node in the list is unique. <br />
              The node to be deleted is in the list and is not a tail node
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(h)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q237. Delete Node in a Linked List (Q229)",
                codes: {
                  Javascript: {
                    code: `function ListNode(val) {
                      this.val = val;
                      this.next = null;
                    }
                    
                    /**
                     * @param {ListNode} node
                     * @return {void} Do not return anything, modify node in-place instead.
                     */
                    var deleteNode = function(node) {
                      node.val = node.next.val
                      node.next = node.next.next    
                    };
                    
                    const l = new ListNode(4)
                    l.next = new ListNode(5)
                    l.next.next = new ListNode(1)
                    l.next.next.next = new ListNode(9)
                    deleteNode(l.next)
                    console.log(l)`,
                    output: `ListNode {
                      val: 4,
                      next: ListNode { val: 1, next: ListNode { val: 9, next: null } }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q230: {
        title: "Q238. Product of Array Except Self (Q230)",
        content: (
          <>
            <Span>
              <b>Q238. Product of Array Except Self (Q230)</b>
            </Span>
            <Span>
              Given an integer array nums, return an array answer such that
              answer[i] is equal to the product of all the elements of nums
              except nums[i].
              <br />
              The product of any prefix or suffix of nums is guaranteed to fit
              in a 32-bit integer.
              <br />
              You must write an algorithm that runs in O(n) time and without
              using the division operation.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3,4] <br />
              Output: [24,12,8,6]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [-1,1,0,-3,3]
              <br />
              Output: [0,0,9,0,0]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              2 &lt;= nums.length &lt;= 105 <br />
              -30 &lt;= nums[i] &lt;= 30 <br />
              The product of any prefix or suffix of nums is guaranteed to fit
              in a 32-bit integer.
            </Span>
            <Span>
              Follow up: Can you solve the problem in O(1) extra space
              complexity? (The output array does not count as extra space for
              space complexity analysis.)
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q238. Product of Array Except Self (Q230)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number[]}
                    */
                   var productExceptSelf = function(nums) {
                     const n = nums.length;
                     const ans = Array(n);
                     ans[0] = 1;
                     for (let i = 1; i < n; ++i)
                       ans[i] = ans[i - 1] * nums[i - 1];
                     let suffix = 1; 
                     for (let i = n - 1; i >= 0; --i) {
                       ans[i] *= suffix;
                       suffix *= nums[i];
                     }
                     return ans;   
                   };
                   
                   productExceptSelf([1,2,3,4])`,
                    output: `[ 24, 12, 8, 6 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q231: {
        title: "Q239. Sliding Window Maximum (Q231)",
        content: (
          <>
            <Span>
              <b>Q239. Sliding Window Maximum (Q231)</b>
            </Span>
            <Span>
              You are given an array of integers nums, there is a sliding window
              of size k which is moving from the very left of the array to the
              very right. You can only see the k numbers in the window. Each
              time the sliding window moves right by one position.
              <br />
              Return the max sliding window.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <p>
              Input: nums = [1,3,-1,-3,5,3,6,7], k = 3<br />
              Output: [3,3,5,5,6,7]
              <br />
              Explanation: <br />
              <pre>
                {`
Window position                Max
---------------               -----
[1  3  -1] -3  5  3  6  7       3
 1 [3  -1  -3] 5  3  6  7       3
 1  3 [-1  -3  5] 3  6  7       5
 1  3  -1 [-3  5  3] 6  7       5
 1  3  -1  -3 [5  3  6] 7       6
 1  3  -1  -3  5 [3  6  7]      7    
    `}
              </pre>
            </p>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1], k = 1<br />
              Output: [1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 105 <br />
              -104 &lt;= nums[i] &lt;= 104 <br />1 &lt;= k &lt;= nums.length
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n) <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q239. Sliding Window Maximum (Q231)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @param {number} k
                    * @return {number[]}
                    */
                   var maxSlidingWindow = function(nums, k) {
                     const ans = []
                     const q = []
                     for(let i in nums){
                     while(q.length && q.slice(-1)[0] < nums[i])
                     q.pop()
                     q.push(nums[i])
                     if(i >= k && nums[i - k] == q[0])
                     q.shift()
                     if(i >= k - 1)
                     ans.push(q[0])
                     }
                     return ans
                   };
                   
                   maxSlidingWindow([1,3,-1,-3,5,3,6,7],3)`,
                    output: `[ 3, 3, 5, 5, 6, 7 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q232: {
        title: "Q240. Search a 2D Matrix II (Q232)",
        content: (
          <>
            <Span>
              <b>Q240. Search a 2D Matrix II (Q232)</b>
            </Span>
            <Span>
              Write an efficient algorithm that searches for a target value in
              an m x n integer matrix. The matrix has the following properties:
              <br />
              Integers in each row are sorted in ascending from left to right.
              <br />
              Integers in each column are sorted in ascending from top to
              bottom.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode240} left />
            <Span>
              Input: matrix = [[1,4,7,11,15],[2,5,8,12,19], <br />
              [3,6,9,16,22], <br />
              [10,13,14,17,24], <br />
              [18,21,23,26,30]], <br />
              target = 5 <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: matrix = [[1,4,7,11,15],
              <br />
              [2,5,8,12,19],
              <br />
              [3,6,9,16,22],
              <br />
              [10,13,14,17,24],
              <br />
              [18,21,23,26,30]], target = 20 Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == matrix.length <br />
              n == matrix[i].length <br />
              1 &lt;= n, m &lt;= 300
              <br />
              -109 &lt;= matrix[i][j] &lt;= 109
              <br />
              All the integers in each row are sorted in ascending order.
              <br />
              All the integers in each column are sorted in ascending order.
              <br />
              -109 &lt;= target &lt;= 109
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(m + n) <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q240. Search a 2D Matrix II (Q232)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[][]} matrix
                    * @param {number} target
                    * @return {boolean}
                    */
                   var searchMatrix = function(matrix, target) {
                     if(!matrix.length) return false;
                     let r = 0;
                     let c = matrix[0].length - 1;
                     while (r < matrix.length && c >= 0) {
                       if (matrix[r][c] == target)
                       return true;
                       if (matrix[r][c] > target)
                       --c;
                       else
                       ++r;
                       }
                     return false;
                   };
                   
                   searchMatrix([[1,4,7,11,15],[2,5,8,12,19],[3,6,9,16,22],[10,13,14,17,24],[18,21,23,26,30]],20)`,
                    output: `false`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q233: {
        title: "Q241. Different Ways to Add Parentheses (Q233)",
        content: (
          <>
            <Span>
              <b>Q241. Different Ways to Add Parentheses (Q233)</b>
            </Span>
            <Span>
              Given a string expression of numbers and operators, return all
              possible results from computing all the different possible ways to
              group numbers and operators. You may return the answer in any
              order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: expression = "2-1-1" <br />
              Output: [0,2] <br />
              Explanation: <br />
              ((2-1)-1) = 0 <br />
              (2-(1-1)) = 2
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: expression = "2*3-4*5"
              <br />
              Output: [-34,-14,-10,-10,10]
              <br />
              Explanation:
              <br />
              (2*(3-(4*5))) = -34
              <br />
              ((2*3)-(4*5)) = -14 <br />
              ((2*(3-4))*5) = -10 <br />
              (2*((3-4)*5)) = -10 <br />
              (((2*3)-4)*5) = 10
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= expression.length &lt;= 20 expression consists of digits
              and the operator '+', '-', and '*'. All the integer values in the
              input expression are in the range [0, 99].
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(2^n -&gt; n) <br />
              Space: O(2^n) )
            </p>
            <CodeEditor
              options={{
                title: "Q241. Different Ways to Add Parentheses (Q233)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} expression
                    * @return {number[]}
                    */
                   var diffWaysToCompute = function(expression) {
                     const memo = new Map()
                     return ways(expression,memo)
                   };
                   
                   function ways(s,memo){
                     if (memo.has(s))
                         return memo.get(s);
                     const ans = [];
                     for (let i = 0; i < s.length; ++i)
                       if (!Number.isInteger(parseInt(s[i])))
                         for (let a of ways(s.substring(0, i), memo))
                           for (let b of ways(s.substring(i + 1), memo))
                             if (s[i] == '+')
                               ans.push(a + b);
                             else if (s[i] == '-')
                               ans.push(a - b);
                             else
                               ans.push(a * b);
                     if (!ans.length) {
                       memo.set(s, [parseInt(s)]);
                       return memo.get(s);
                     }
                     memo.set(s, ans);
                     return ans;
                   }
                   
                   diffWaysToCompute("2-1-1")`,
                    output: `[ 2, 0 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q234: {
        title: "Q242. Valid Anagram (Q234)",
        content: (
          <>
            <Span>
              <b>Q242. Valid Anagram (Q233)</b>
            </Span>
            <Span>
              Given two strings s and t, return true if t is an anagram of s,
              and false otherwise.
              <br />
              An Anagram is a word or phrase formed by rearranging the letters
              of a different word or phrase, typically using all the original
              letters exactly once.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "anagram", t = "nagaram"
              <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "rat", t = "car" <br />
              Output: false{" "}
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length, t.length &lt;= 5 * 104 <br />s and t consist of
              lowercase English letters.
            </Span>
            <Span>
              Follow up: What if the inputs contain Unicode characters? How
              would you adapt your solution to such a case?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n) <br />
              Space: O(128) = O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q242. Valid Anagram (Q234)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @param {string} t
                    * @return {boolean}
                    */
                   var isAnagram = function(s, t) {
                     if(s.length != t.length) return false;
                     const count = Array(128).fill(0);
                     for (let c of s)
                       ++count[c.charCodeAt(0)];
                     for (let c of t)
                       if (--count[c.charCodeAt(0)] < 0)
                       return false;
                       return true;
                   };
                   
                   isAnagram("rat", "car")`,
                    output: `false`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q235: {
        title: "Q257. Binary Tree Paths (Q235)",
        content: (
          <>
            <Span>
              <b>Q257. Binary Tree Paths (Q235)</b>
            </Span>
            <Span>
              Given the root of a binary tree, return all root-to-leaf paths in
              any order.
              <br />A leaf is a node with no children.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode257} left />
            <Span>
              Input: root = [1,2,3,null,5]
              <br />
              Output: ["1-&gt;2-&gt;5","1-&gt;3"]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [1] <br />
              Output: ["1"]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [1, 100].
              <br />
              -100 &lt;= Node.val &lt;= 100
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(h)
            </p>
            <CodeEditor
              options={{
                title: "Q257. Binary Tree Paths (Q235)",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                  }
                  
                  function dfs(root,res,st=""){
                    if(!root) return;
                    if(!root.left && !root.right) {
                      res.push(st + root.val);
                      return;
                    }
                    dfs(root.left, res, st + root.val + "->");
                    dfs(root.right, res, st + root.val + "->");
                  }
                  
                  /**
                   * @param {TreeNode} root
                   * @return {string[]}
                   */
                  var binaryTreePaths = function(root) {
                    const res = []
                    dfs(root,res)
                    return res
                  };
                  
                  const t = new TreeNode(1)
                  t.left = new TreeNode(2)
                  t.left.right = new TreeNode(5)
                  t.right = new TreeNode(3)
                  binaryTreePaths(t)`,
                    output: `[ '1->2->5', '1->3' ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q236: {
        title: "Q258. Add Digits (Q236)",
        content: (
          <>
            <Span>
              <b>Q258. Add Digits (Q236)</b>
            </Span>
            <Span>
              Given an integer num, repeatedly add all its digits until the
              result has only one digit, and return it.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: num = 38 <br />
              Output: 2<br />
              Explanation: The process is <br />
              38 --&gt; 3 + 8 --&gt; 11
              <br />
              11 --&gt; 1 + 1 --&gt; 2 <br />
              Since 2 has only one digit, return it.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: num = 0<br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>0 &lt;= num &lt;= 2^31 - 1</Span>
            <Span>
              Follow up: Could you do it without any loop/recursion in O(1)
              runtime?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q258. Add Digits (Q236)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} num
                    * @return {number}
                    */
                   var addDigits = function(num) {
                       return 1 + (num - 1) % 9;
                   };
                   
                   addDigits(38)`,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q237: {
        title: "Q260. Single Number III (Q237)",
        content: (
          <>
            <Span>
              <b>Q260. Single Number III (Q237)</b>
            </Span>
            <Span>
              Given an integer array nums, in which exactly two elements appear
              only once and all the other elements appear exactly twice. Find
              the two elements that appear only once. You can return the answer
              in any order.
              <br />
              You must write an algorithm that runs in linear runtime complexity
              and uses only constant extra space.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,1,3,2,5]
              <br />
              Output: [3,5]
              <br />
              Explanation: [5, 3] is also a valid answer.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [-1,0] <br />
              Output: [-1,0]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [0,1] <br />
              Output: [1,0]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              2 &lt;= nums.length &lt;= 3 * 10^4 <br />
              -2^31 &lt;= nums[i] &lt;= 2^31 - 1 <br />
              Each integer in nums will appear twice, only two integers will
              appear once.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q260. Single Number III (Q237)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number[]}
                    */
                   var singleNumber = function(nums) { 
                     const xors = nums.reduce((a,b)=>a^b)
                     const lowbit = xors & -xors;
                     const res = Array(2).fill(0)
                     for (let num of nums)
                       if ((num & lowbit) > 0)
                         res[0] ^= num;
                       else
                         res[1] ^= num;
                     return res
                   };
                   
                   singleNumber([1,2,1,3,2,5])`,
                    output: `[ 3, 5 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q238: {
        title: "Q262. Trips and Users (Q238)",
        content: (
          <>
            <Span>
              <b>Q262. Trips and Users (Q238)</b>
            </Span>
            <p>Table: Trips</p>
            <pre>
              {`
+-------------+----------+
| Column Name | Type     |
+-------------+----------+
| id          | int      |
| client_id   | int      |
| driver_id   | int      |
| city_id     | int      |
| status      | enum     |
| request_at  | date     |     
+-------------+----------+
                `}
            </pre>
            <Span>
              id is the primary key for this table.
              <br />
              The table holds all taxi trips. Each trip has a unique id, while
              client_id and driver_id are foreign keys to the users_id at the
              Users table.
              <br />
              Status is an ENUM type of ('completed', 'cancelled_by_driver',
              'cancelled_by_client').
            </Span>
            <p>Table: Users</p>
            <pre>
              {`
+-------------+----------+
| Column Name | Type     |
+-------------+----------+
| users_id    | int      |
| banned      | enum     |
| role        | enum     |
+-------------+----------+
              `}
            </pre>
            <Span>
              users_id is the primary key for this table. <br />
              The table holds all users. Each user has a unique users_id, and
              role is an ENUM type of ('client', 'driver', 'partner').
              <br />
              banned is an ENUM type of ('Yes', 'No').
            </Span>
            <Span>
              The cancellation rate is computed by dividing the number of
              canceled (by client or driver) requests with unbanned users by the
              total number of requests with unbanned users on that day.
              <br />
              Write a SQL query to find the cancellation rate of requests with
              unbanned users (both client and driver must not be banned) each
              day between "2013-10-01" and "2013-10-03". Round Cancellation Rate
              to two decimal points.
              <br />
              Return the result table in any order.
              <br />
              The query result format is in the following example.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <p>
              Input: <br />
              Trips table:
            </p>
            <pre>
              {`
+----+-----------+-----------+---------+---------------------+------------+
| id | client_id | driver_id | city_id | status              | request_at |
+----+-----------+-----------+---------+---------------------+------------+
| 1  | 1         | 10        | 1       | completed           | 2013-10-01 |
| 2  | 2         | 11        | 1       | cancelled_by_driver | 2013-10-01 |
| 3  | 3         | 12        | 6       | completed           | 2013-10-01 |
| 4  | 4         | 13        | 6       | cancelled_by_client | 2013-10-01 |
| 5  | 1         | 10        | 1       | completed           | 2013-10-02 |
| 6  | 2         | 11        | 6       | completed           | 2013-10-02 |
| 7  | 3         | 12        | 6       | completed           | 2013-10-02 |
| 8  | 2         | 12        | 12      | completed           | 2013-10-03 |
| 9  | 3         | 10        | 12      | completed           | 2013-10-03 |
| 10 | 4         | 13        | 12      | cancelled_by_driver | 2013-10-03 |
+----+-----------+-----------+---------+---------------------+------------+
                `}
            </pre>
            <p>Users table:</p>
            <pre>
              {`
+----------+--------+--------+
| users_id | banned | role   |
+----------+--------+--------+
| 1        | No     | client |
| 2        | Yes    | client |
| 3        | No     | client |
| 4        | No     | client |
| 10       | No     | driver |
| 11       | No     | driver |
| 12       | No     | driver |
| 13       | No     | driver |
+----------+--------+--------+
              `}
            </pre>
            <p>
              <b>Output:</b>
            </p>
            <pre>
              {`
+------------+-------------------+
| Day        | Cancellation Rate |
+------------+-------------------+
| 2013-10-01 | 0.33              |
| 2013-10-02 | 0.00              |
| 2013-10-03 | 0.50              |
+------------+-------------------+
                `}
            </pre>
            <p>
              Explanation: <br />
              On 2013-10-01: <br />
              <br /> - There were 4 requests in total, 2 of which were canceled.
              <br />- However, the request with Id=2 was made by a banned client
              (User_Id=2), so it is ignored in the calculation.
              <br />- Hence there are 3 unbanned requests in total, 1 of which
              was canceled.
              <br />- The Cancellation Rate is (1 / 3) = 0.33
              <br />
              On 2013-10-02:
              <br /> - There were 3 requests in total, 0 of which were canceled.
              <br /> - The request with Id=6 was made by a banned client, so it
              is ignored.
              <br /> - Hence there are 2 unbanned requests in total, 0 of which
              were canceled.
              <br /> - The Cancellation Rate is (0 / 2) = 0.00
              <br />
              On 2013-10-03:
              <br /> - There were 3 requests in total, 1 of which was canceled.
              <br /> - The request with Id=8 was made by a banned client, so it
              is ignored.
              <br /> - Hence there are 2 unbanned request in total, 1 of which
              were canceled.
              <br /> - The Cancellation Rate is (1 / 2) = 0.50
            </p>
            <CodeEditor
              options={{
                title: "Q262. Trips and Users (Q238)",
                codes: {
                  Mysql: {
                    code: `SELECT 
                    t1.Day
                    ,ROUND(SUM(t1.not_completed)/COUNT(*),2) AS "Cancellation Rate"
                    FROM
                    (SELECT 
                        t.request_at AS Day
                        ,(CASE
                            WHEN status<>'completed' THEN 1
                            ELSE 0 END) AS not_completed
                        
                     
                    FROM
                     Trips t
                    WHERE client_id IN (SELECT users_id FROM Users WHERE banned='No' AND role='client')
                          AND driver_id IN (SELECT users_id FROM Users WHERE banned='No' AND role='driver')
                            ) t1
                    WHERE (t1.Day BETWEEN '2013-10-01' AND '2013-10-03')
                    
                    GROUP BY t1.Day`,
                    output: `{"headers": ["Day", "Cancellation Rate"], "values": [["2013-10-01", 0.33], ["2013-10-02", 0.00], ["2013-10-03", 0.50]]}`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q239: {
        title: "Q263. Ugly Number (Q239)",
        content: (
          <>
            <Span>
              <b>Q263. Ugly Number (Q239)</b>
            </Span>
            <Span>
              An ugly number is a positive integer whose prime factors are
              limited to 2, 3, and 5.
              <br />
              Given an integer n, return true if n is an ugly number.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 6<br />
              Output: true
              <br />
              Explanation: 6 = 2 × 3
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1 <br />
              Output: true <br />
              Explanation: 1 has no prime factors, therefore all of its prime
              factors are limited to 2, 3, and 5.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: n = 14
              <br />
              Output: false
              <br />
              Explanation: 14 is not ugly since it includes the prime factor 7.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>-2^31 &lt;= n &lt;= 2^31 - 1</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n) <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q263. Ugly Number (Q239)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {boolean}
                    */
                   var isUgly = function(n) {
                     if (n == 0) return false;
                     for (let prime of [2, 3, 5])
                       while (n % prime == 0)
                         n = parseInt(n/prime);
                     return n == 1;
                   };
                   
                   isUgly(6)`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q240: {
        title: "Q264. Ugly Number II (Q240)",
        content: (
          <>
            <Span>
              <b>Q264. Ugly Number II (Q240)</b>
            </Span>
            <Span>
              An ugly number is a positive integer whose prime factors are
              limited to 2, 3, and 5.
              <br />
              Given an integer n, return the nth ugly number.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 10 <br />
              Output: 12 <br />
              Explanation: [1, 2, 3, 4, 5, 6, 8, 9, 10, 12] is the sequence of
              the first 10 ugly numbers.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1<br />
              Output: 1<br />
              Explanation: 1 has no prime factors, therefore all of its prime
              factors are limited to 2, 3, and 5.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= n &lt;= 1690</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n) <br />
              Space: O(n)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q264. Ugly Number II (Q240)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number}
                    */
                   var nthUglyNumber = function(n) {
                     const nums = [1]
                     let i2 = 0
                     let i3 = 0
                     let i5 = 0
                   
                     while(nums.length < n){
                     const next2 = nums[i2] * 2
                     const next3 = nums[i3] * 3
                     const next5 = nums[i5] * 5
                     const next = Math.min(next2, next3, next5)
                     if(next == next2)
                       i2 += 1
                     if(next == next3)
                       i3 += 1
                     if(next == next5)
                       i5 += 1
                     nums.push(next)
                     }
                     return nums.slice(-1)[0] 
                   };
                   nthUglyNumber(1)`,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q241: {
        title: "Q268. Missing Number (Q241)",
        content: (
          <>
            <Span>
              <b>Q268. Missing Number (Q241)</b>
            </Span>
            <Span>
              Given an array nums containing n distinct numbers in the range [0,
              n], return the only number in the range that is missing from the
              array.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [3,0,1]
              <br />
              Output: 2<br />
              Explanation: n = 3 since there are 3 numbers, so all numbers are
              in the range [0,3]. 2 is the missing number in the range since it
              does not appear in nums.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0,1]
              <br />
              Output: 2<br />
              Explanation: n = 2 since there are 2 numbers, so all numbers are
              in the range [0,2]. 2 is the missing number in the range since it
              does not appear in nums.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [9,6,4,2,3,5,7,0,1]
              <br />
              Output: 8<br />
              Explanation: n = 9 since there are 9 numbers, so all numbers are
              in the range [0,9]. 8 is the missing number in the range since it
              does not appear in nums.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              n == nums.length <br />
              1 &lt;= n &lt;= 104 <br />
              0 &lt;= nums[i] &lt;= n <br />
              All the numbers of nums are unique.
            </Span>
            <Span>
              Follow up: Could you implement a solution using only O(1) extra
              space complexity and O(n) runtime complexity?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n) <br />
              Space: O(1)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q268. Missing Number (Q241)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var missingNumber = function(nums) {
                     let ans = nums.length;
                     for (let i = 0; i < nums.length; ++i)
                       ans ^= i ^ nums[i];
                     return ans;
                   };
                   
                   missingNumber([3,0,1])`,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q242: {
        title: "Q273. Integer to English Words (Q242)",
        content: (
          <>
            <Span>
              <b>Q273. Integer to English Words (Q242)</b>
            </Span>
            <Span>
              Convert a non-negative integer num to its English words
              representation.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: num = 123 <br />
              Output: "One Hundred Twenty Three"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: num = 12345
              <br />
              Output: "Twelve Thousand Three Hundred Forty Five"
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: num = 1234567 <br />
              Output: "One Million Two Hundred Thirty Four Thousand Five Hundred
              Sixty Seven"
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>0 &lt;= num &lt;= 2^31 - 1</Span>
            <CodeEditor
              options={{
                title: "Q273. Integer to English Words (Q242)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} num
                    * @return {string}
                    */
                   const belowTwenty = ["",        "One",       "Two","Three",
                                      "Four",    "Five",      "Six",      "Seven",
                                      "Eight",   "Nine",      "Ten",      "Eleven",
                                      "Twelve",  "Thirteen",  "Fourteen", "Fifteen",
                                      "Sixteen", "Seventeen", "Eighteen", "Nineteen"]
                   const tens = ["",      "Ten",   "Twenty",  "Thirty", "Forty",
                               "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"]  
                   
                   var numberToWords = function(num) {
                     if(num == 0) return "Zero"
                     return helper(num)
                   };
                   
                   function helper(num){
                     if(num < 20)
                       s = belowTwenty[num]
                     else if(num < 100)
                       s = tens[parseInt(num / 10)] + " " + belowTwenty[num % 10]
                     else if(num < 1000)
                       s = helper(parseInt(num / 100)) + " Hundred " + helper(num % 100)
                     else if(num < 1000000)
                       s = helper(parseInt(num / 1000)) + " Thousand " + helper(num % 1000)
                     else if(num < 1000000000)
                       s = helper(parseInt(num / 1000000)) + " Million " + 
                               helper(num % 1000000)
                     else
                       s = helper(parseInt(num / 1000000000)) + " Billion " + 
                               helper(num % 1000000000)
                     return s.trim()
                   }
                      
                   numberToWords(123)`,
                    output: `One Hundred Twenty Three`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q243: {
        title: "Q274. H-Index (Q243)",
        content: (
          <>
            <Span>
              <b>Q274. H-Index (Q243)</b>
            </Span>
            <Span>
              Given an array of integers citations where citations[i] is the
              number of citations a researcher received for their ith paper,
              return compute the researcher's h-index.
              <br />
              According to the definition of h-index on Wikipedia: A scientist
              has an index h if h of their n papers have at least h citations
              each, and the other n − h papers have no more than h citations
              each.
              <br />
              If there are several possible values for h, the maximum one is
              taken as the h-index.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: citations = [3,0,6,1,5] <br />
              Output: 3<br />
              Explanation: [3,0,6,1,5] means the researcher has 5 papers in
              total and each of them had received 3, 0, 6, 1, 5 citations
              respectively.
              <br />
              Since the researcher has 3 papers with at least 3 citations each
              and the remaining two with no more than 3 citations each, their
              h-index is 3.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: citations = [1,3,1] <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              n == citations.length <br />
              1 &lt;= n &lt;= 5000
              <br />0 &lt;= citations[i] &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n) <br />
              Space: O(n)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q274. H-Index (Q243)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} citations
                    * @return {number}
                    */
                   var hIndex = function(citations) {
                     const n = citations.length;
                     let accumulate = 0;
                     const count = Array(n + 1).fill(0);
                     for (let citation of citations)
                       ++count[Math.min(citation, n)];
                     for (let i = n; i >= 0; --i) {
                       accumulate += count[i];
                       if (accumulate >= i)
                         return i;
                     }
                   };
                   
                   hIndex([3,0,6,1,5])`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q244: {
        title: "Q275. H-Index II (Q244)",
        content: (
          <>
            <Span>
              <b>Q275. H-Index II (Q244)</b>
            </Span>
            <Span>
              Given an array of integers citations where citations[i] is the
              number of citations a researcher received for their ith paper and
              citations is sorted in an ascending order, return compute the
              researcher's h-index.
              <br />
              According to the definition of h-index on Wikipedia: A scientist
              has an index h if h of their n papers have at least h citations
              each, and the other n − h papers have no more than h citations
              each.
              <br />
              If there are several possible values for h, the maximum one is
              taken as the h-index.
              <br />
              You must write an algorithm that runs in logarithmic time.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: citations = [0,1,3,5,6]
              <br />
              Output: 3<br />
              Explanation: [0,1,3,5,6] means the researcher has 5 papers in
              total and each of them had received 0, 1, 3, 5, 6 citations
              respectively.
              <br />
              Since the researcher has 3 papers with at least 3 citations each
              and the remaining two with no more than 3 citations each, their
              h-index is 3.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: citations = [1,2,100]
              <br />
              Output: 2
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              n == citations.length <br />
              1 &lt;= n &lt;= 10^5
              <br />0 &lt;= citations[i] &lt;= 1000
            </Span>{" "}
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n) <br />
              Space: O(n)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q275. H-Index II (Q244)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} citations
                    * @return {number}
                    */
                   var hIndex = function(citations) {
                     let l = 0;
                     let r = citations.length;
                     while (l < r) {
                       const m = l + parseInt((r - l) / 2);
                       if (citations[m] >= citations.length - m)
                         r = m;
                       else
                         l = m + 1;
                     }
                     return citations.length - l;
                   };
                   
                   hIndex([0,1,3,5,6])`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q245: {
        title: "Q278. First Bad Version (Q245)",
        content: (
          <>
            <Span>
              <b>Q278. First Bad Version (Q245)</b>
            </Span>
            <Span>
              You are a product manager and currently leading a team to develop
              a new product. Unfortunately, the latest version of your product
              fails the quality check. Since each version is developed based on
              the previous version, all the versions after a bad version are
              also bad.
              <br />
              Suppose you have n versions [1, 2, ..., n] and you want to find
              out the first bad one, which causes all the following ones to be
              bad.
              <br />
              You are given an API bool isBadVersion(version) which returns
              whether version is bad. Implement a function to find the first bad
              version. You should minimize the number of calls to the API.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 5, bad = 4 <br />
              Output: 4 <br />
              Explanation: <br />
              call isBadVersion(3) -&gt; false <br />
              call isBadVersion(5) -&gt; true <br />
              call isBadVersion(4) -&gt; true <br />
              Then 4 is the first bad version.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1, bad = 1<br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= bad &lt;= n &lt;= 2^31 - 1</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n) <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q278. First Bad Version (Q245)",
                codes: {
                  Javascript: {
                    code: `
                    /**
                    * Definition for isBadVersion()
                    * 
                    * @param {integer} version number
                    * @return {boolean} whether the version is bad
                    * isBadVersion = function(version) {
                    *     ...
                    * };
                    */
                   I/P: 5 4
                   /**
                    * @param {function} isBadVersion()
                    * @return {function}
                    */
                   var solution = function(isBadVersion) {
                       /**
                        * @param {integer} n Total versions
                        * @return {integer} The first bad version
                        */
                       return function(n) {
                       let l = 1;
                       let r = n;
                       while (l < r) {
                         const m = l + parseInt((r - l) / 2);
                         if (isBadVersion(m))
                           r = m;
                         else
                           l = m + 1;
                       }
                       return l;
                       };
                   };`,
                    output: `4`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q246: {
        title: "Q279. Perfect Squares (Q246)",
        content: (
          <>
            <Span>
              <b>Q279. Perfect Squares (Q246)</b>
            </Span>
            <Span>
              Given an integer n, return the least number of perfect square
              numbers that sum to n.
              <br />A perfect square is an integer that is the square of an
              integer; in other words, it is the product of some integer with
              itself. For example, 1, 4, 9, and 16 are perfect squares while 3
              and 11 are not.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 12 <br />
              Output: 3 <br />
              Explanation: 12 = 4 + 4 + 4.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 13
              <br />
              Output: 2<br />
              Explanation: 13 = 4 + 9.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= n &lt;= 10^4</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(log n) <br />
              Space: O(nlog n)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q279. Perfect Squares (Q246)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number}
                    */
                   var numSquares = function(n) {
                     const dp = Array(n + 1).fill(n); // 1^2 x n
                     dp[0] = 0; // no way
                     dp[1] = 1; // 1^2
                     for (let i = 2; i <= n; ++i)
                       for (let j = 1; j * j <= i; ++j)
                         dp[i] = Math.min(dp[i], dp[i - j * j] + 1);
                     return dp[n];  
                   };
                   
                   numSquares(12)`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q247: {
        title: "Q282. Expression Add Operators (Q247)",
        content: (
          <>
            <Span>
              <b>Q282. Expression Add Operators (Q247)</b>
            </Span>
            <Span>
              Given a string num that contains only digits and an integer
              target, return all possibilities to insert the binary operators
              '+', '-', and/or '*' between the digits of num so that the
              resultant expression evaluates to the target value.
              <br />
              Note that operands in the returned expressions should not contain
              leading zeros.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: num = "123", target = 6<br />
              Output: ["1*2*3","1+2+3"]
              <br />
              Explanation: Both "1*2*3" and "1+2+3" evaluate to 6.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: num = "232", target = 8<br />
              Output: ["2*3+2","2+3*2"]
              <br />
              Explanation: Both "2*3+2" and "2+3*2" evaluate to 8.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: num = "3456237490", target = 9191 <br />
              Output: [] <br />
              Explanation: There are no expressions that can be created from
              "3456237490" to evaluate to 9191.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= num.length &lt;= 10 <br />
              num consists of only digits. <br />
              -2^31 &lt;= target &lt;= 2^31 - 1
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n4^(n - 1)) <br />
              Space: O(n^2)
            </p>
            <CodeEditor
              options={{
                title: "Q282. Expression Add Operators (Q247)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} num
                    * @param {number} target
                    * @return {string[]}
                    */
                   function dfs(num,target,res,s=0,prev=0,ev=0,st=""){
                     if (s == num.length) {
                       if (ev == target)
                         res.push(st);
                       return;
                     }
                     for (let i = s; i < num.length; ++i) {
                       if (i > s && num[s] == '0')
                         return;
                       let curr = parseInt(num.substring(s, i + 1))
                       const length = st.length;
                       if (s == 0) {
                         dfs(num, target,res, i + 1, curr, curr, st + curr);
                       } else {
                         dfs(num, target, res , i + 1, curr, ev + curr, st + "+" + curr);
                         dfs(num, target, res , i + 1, -curr, ev - curr, st + "-" + curr);
                         dfs(num, target, res , i + 1, prev * curr, ev - prev + prev * curr, st + "*" + curr);
                       }
                     }
                   }
                   
                   var addOperators = function(num, target) {
                     const res = [] 
                     dfs(num,target,res)
                     return res
                   };
                   
                   addOperators("123",6)`,
                    output: `[ '1+2+3', '1*2*3' ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q248: {
        title: "Q283. Move Zeroes (Q248)",
        content: (
          <>
            <Span>
              <b>Q283. Move Zeroes (Q248)</b>
            </Span>
            <Span>
              Given an integer array nums, move all 0's to the end of it while
              maintaining the relative order of the non-zero elements.
              <br />
              Note that you must do this in-place without making a copy of the
              array.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [0,1,0,3,12] <br />
              Output: [1,3,12,0,0]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0]
              <br />
              Output: [0]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 10^4 <br />
              -2^31 &lt;= nums[i] &lt;= 2^31 - 1
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n) <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q283. Move Zeroes (Q248)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {void} Do not return anything, modify nums in-place instead.
                    */
                   var moveZeroes = function(nums) {
                     let i = 0;
                     for (let num of nums)
                       if (num != 0)
                         nums[i++] = num;
                     for (; i < nums.length; ++i)
                         nums[i] = 0;
                     console.log(nums)
                   };
                   
                   moveZeroes([0,1,0,3,12])`,
                    output: `[ 1, 3, 12, 0, 0 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q249: {
        title: "Q284. Peeking Iterator (Q249)",
        content: (
          <>
            <Span>
              <b>Q284. Peeking Iterator (Q249)</b>
            </Span>
            <Span>
              Design an iterator that supports the peek operation on an existing
              iterator in addition to the hasNext and the next operations.
              <br />
              Implement the PeekingIterator class:
              <br />
              PeekingIterator(Iterator{`<int>`} nums) Initializes the object
              with the given integer iterator iterator.
              <br />
              int next() Returns the next element in the array and moves the
              pointer to the next element.
              <br />
              boolean hasNext() Returns true if there are still elements in the
              array.
              <br />
              int peek() Returns the next element in the array without moving
              the pointer.
              <br />
              Note: Each language may have a different implementation of the
              constructor and Iterator, but they all support the int next() and
              boolean hasNext() functions.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input <br />
              ["PeekingIterator", "next", "peek", "next", "next", "hasNext"]{" "}
              <br />
              [[[1, 2, 3]], [], [], [], [], []] <br />
              Output <br />
              [null, 1, 2, 2, 3, false]
              <br />
              Explanation <br />
              PeekingIterator peekingIterator = new PeekingIterator([1, 2, 3]);
              // [1,2,3] <br />
              peekingIterator.next(); // return 1, the pointer moves to the next
              element [1,2,3].
              <br />
              peekingIterator.peek(); // return 2, the pointer does not move
              [1,2,3]. <br />
              peekingIterator.next(); // return 2, the pointer moves to the next
              element [1,2,3] <br />
              peekingIterator.next(); // return 3, the pointer moves to the next
              element [1,2,3] <br />
              peekingIterator.hasNext(); // return False
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 1000 <br />
              1 &lt;= nums[i] &lt;= 1000 <br />
              All the calls to next and peek are valid. <br />
              At most 1000 calls will be made to next, hasNext, and peek.
            </Span>
            <Span>
              Follow up: How would you extend your design to be generic and work
              with all types, not just integer?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1) <br />
              Space: O(n)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q284. Peeking Iterator (Q249)",
                codes: {
                  Javascript: {
                    code: `/**
                    * // This is the Iterator's API interface.
                    * // You should not implement it, or speculate about its implementation.
                    * function Iterator() {
                    *    @ return {number}
                    *    this.next = function() { // return the next number of the iterator
                    *       ...
                    *    }; 
                    *
                    *    @return {boolean}
                    *    this.hasNext = function() { // return true if it still has numbers
                    *       ...
                    *    };
                    * };
                    */
                   
                   /**
                    * @param {Iterator} iterator
                    */
                   var PeekingIterator = function(iterator) {
                     this.iterator = iterator
                     this.buffer = null
                     if(this.iterator.hasNext())
                     this.buffer = this.iterator.next()    
                   };
                   
                   /**
                    * @return {number}
                    */
                   PeekingIterator.prototype.peek = function() {
                     return this.buffer
                   };
                   
                   /**
                    * @return {number}
                    */
                   PeekingIterator.prototype.next = function() {
                     let next = this.buffer
                     if(this.iterator.hasNext())
                     this.buffer = this.iterator.next()
                     else
                     this.buffer = null
                     return next 
                   };
                   
                   /**
                    * @return {boolean}
                    */
                   PeekingIterator.prototype.hasNext = function() {
                     return this.buffer !== null
                   };
                   
                   // var obj = new PeekingIterator([1,2,3])
                   // var param_1 = obj.peek()
                   // var param_2 = obj.next()
                   // var param_3 = obj.hasNext()
                   // console.log({ param_1, param_2 , param_3 })`,
                    output: `[null,1,2,2,3,false]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q250: {
        title: "Q287. Find the Duplicate Number (Q250)",
        content: (
          <>
            <Span>
              <b>Q287. Find the Duplicate Number (Q250)</b>
            </Span>
            <Span>
              Given an array of integers nums containing n + 1 integers where
              each integer is in the range [1, n] inclusive.
              <br />
              There is only one repeated number in nums, return this repeated
              number.
              <br />
              You must solve the problem without modifying the array nums and
              uses only constant extra space.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,3,4,2,2]
              <br />
              Output: 2
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [3,1,3,4,2]
              <br />
              Output: 3
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= n &lt;= 105 <br />
              nums.length == n + 1 <br />
              1 &lt;= nums[i] &lt;= n <br />
              All the integers in nums appear only once except for precisely one
              integer which appears two or more times.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n) <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q287. Find the Duplicate Number (Q250)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var findDuplicate = function(nums) {
                     let slow = nums[nums[0]];
                     let fast = nums[nums[nums[0]]];
                     while (slow != fast) {
                       slow = nums[slow];
                       fast = nums[nums[fast]];
                     }
                     slow = nums[0];
                     while (slow != fast) {
                       slow = nums[slow];
                       fast = nums[fast];
                     }
                     return slow;
                   };
                   findDuplicate([1,3,4,2,2])`,
                    output: `2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q251: {
        title: "Q289. Game of Life (Q251)",
        content: (
          <>
            <Span>
              <b>Q289. Game of Life (Q251)</b>
            </Span>
            <Span>
              According to Wikipedia's article: "The Game of Life, also known
              simply as Life, is a cellular automaton devised by the British
              mathematician John Horton Conway in 1970."
              <br />
              The board is made up of an m x n grid of cells, where each cell
              has an initial state: live (represented by a 1) or dead
              (represented by a 0). Each cell interacts with its eight neighbors
              (horizontal, vertical, diagonal) using the following four rules
              (taken from the above Wikipedia article):
              <br />
              Any live cell with fewer than two live neighbors dies as if caused
              by under-population.
              <br />
              Any live cell with two or three live neighbors lives on to the
              next generation.
              <br />
              Any live cell with more than three live neighbors dies, as if by
              over-population.
              <br />
              Any dead cell with exactly three live neighbors becomes a live
              cell, as if by reproduction.
              <br />
              The next state is created by applying the above rules
              simultaneously to every cell in the current state, where births
              and deaths occur simultaneously. Given the current state of the m
              x n grid board, return the next state.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode289} left />
            <Span>
              Input: board = [[0,1,0],[0,0,1],[1,1,1],[0,0,0]]
              <br />
              Output: [[0,0,0],[1,0,1],[0,1,1],[0,1,0]]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: board = [[1,1],[1,0]]
              <br />
              Output: [[1,1],[1,1]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == board.length <br />
              n == board[i].length <br />
              1 &lt;= m, n &lt;= 25 <br />
              board[i][j] is 0 or 1.
            </Span>
            <Span>
              Follow up:
              <br />
              Could you solve it in-place? Remember that the board needs to be
              updated simultaneously: You cannot update some cells first and
              then use their updated values to update other cells.
              <br />
              In this question, we represent the board using a 2D array. In
              principle, the board is infinite, which would cause problems when
              the active area encroaches upon the border of the array (i.e.,
              live cells reach the border). How would you address these
              problems?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn) <br />
              Space: O(1)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q289. Game of Life (Q251)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[][]} board
                    * @return {void} Do not return anything, modify board in-place instead.
                    */
                   var gameOfLife = function(board) {
                     const m = board.length;
                     const n = board[0].length;
                     for (let i = 0; i < m; ++i)
                     for (let j = 0; j < n; ++j) {
                         let ones = 0;
                         for (let x = Math.max(0, i - 1); x < Math.min(m, i + 2); ++x)
                           for (let y = Math.max(0, j - 1); y < Math.min(n, j + 2); ++y)
                             ones += board[x][y] & 1;
                         if (board[i][j] == 1 && (ones == 3 || ones == 4))
                           board[i][j] |= 0b10;
                         if (board[i][j] == 0 && ones == 3)
                           board[i][j] |= 0b10;
                       }
                     for (let i = 0; i < m; ++i)
                       for (let j = 0; j < n; ++j)
                         board[i][j] >>= 1; 
                     console.log(board)
                   };
                   
                   gameOfLife([[0,1,0],[0,0,1],[1,1,1],[0,0,0]])`,
                    output: `[ [ 0, 0, 0 ], [ 1, 0, 1 ], [ 0, 1, 1 ], [ 0, 1, 0 ] ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q252: {
        title: "Q290. Word Pattern (Q252)",
        content: (
          <>
            <Span>
              <b>Q290. Word Pattern (Q252)</b>
            </Span>
            <Span>
              Given a pattern and a string s, find if s follows the same
              pattern.
              <br />
              Here follow means a full match, such that there is a bijection
              between a letter in pattern and a non-empty word in s.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: pattern = "abba", s = "dog cat cat dog"
              <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: pattern = "abba", s = "dog cat cat fish"
              <br />
              Output: false
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: pattern = "aaaa", s = "dog cat cat dog"
              <br />
              Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= pattern.length &lt;= 300 <br />
              pattern contains only lower-case English letters. <br />
              1 &lt;= s.length &lt;= 3000 <br />
              s contains only lowercase English letters and spaces ' '. <br />
              s does not contain any leading or trailing spaces. <br />
              All the words in s are separated by a single space.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n) <br />
              Space: O(n)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q290. Word Pattern (Q252)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} pattern
                    * @param {string} s
                    * @return {boolean}
                    */
                   var wordPattern = function(pattern, s) {
                     if (!pattern || !pattern.length) return false;
                     if (!s || !s.length) return false;
                     const map = new Map();
                     const map2 = new Map();
                   
                     const array = s.trim().split(" ");
                     if (array.length != pattern.length){
                       return false;
                     }
                     for (let i = 0; i < array.length; i++) {
                         const p = pattern[i]
                         const c = array[i];
                         if (map.has(p)) {
                             if (c != map.get(p)) {
                                 return false;
                             }
                         } else {
                             map.set(p, c);
                         }
                         if (map2.has(c)) {
                             if (p != map2.get(c)) {
                                 return false;
                             }
                         } else {
                             map2.set(c, p);
                         }
                     }
                     console.log(map,map2)
                     return true;
                   };
                   
                   wordPattern("abba","dog cat cat dog")`,
                    output: `Map { 'a' => 'dog', 'b' => 'cat' } Map { 'dog' => 'a', 'cat' => 'b' }
                    true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q253: {
        title: "Q292. Nim Game (Q253)",
        content: (
          <>
            <Span>
              <b>Q292. Nim Game (Q253)</b>
            </Span>
            <Span>
              You are playing the following Nim Game with your friend:
              <br />
              Initially, there is a heap of stones on the table.
              <br />
              You and your friend will alternate taking turns, and you go first.
              <br />
              On each turn, the person whose turn it is will remove 1 to 3
              stones from the heap.
              <br />
              The one who removes the last stone is the winner.
              <br />
              Given n, the number of stones in the heap, return true if you can
              win the game assuming both you and your friend play optimally,
              otherwise return false.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 4 <br />
              Output: false
              <br />
              Explanation: These are the possible outcomes:
              <br />
              1. You remove 1 stone. Your friend removes 3 stones, including the
              last stone. Your friend wins.
              <br />
              2. You remove 2 stones. Your friend removes 2 stones, including
              the last stone. Your friend wins.
              <br />
              3. You remove 3 stones. Your friend removes the last stone. Your
              friend wins.
              <br />
              In all outcomes, your friend wins.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1 <br />
              Output: true
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: n = 2 <br />
              Output: true
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>1 &lt;= n &lt;= 2^31 - 1</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1) <br />
              Space: O(1)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q292. Nim Game (Q253)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {boolean}
                    */
                   var canWinNim = function(n) {
                      return n % 4 != 0
                   };
                   
                   canWinNim(2)`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q254: {
        title: "Q295. Find Median from Data Stream (Q254)",
        content: (
          <>
            <Span>
              <b>Q295. Find Median from Data Stream (Q254)</b>
            </Span>
            <Span>
              The median is the middle value in an ordered integer list. If the
              size of the list is even, there is no middle value and the median
              is the mean of the two middle values.
            </Span>
            <Span>
              For example, for arr = [2,3,4], the median is 3.
              <br />
              For example, for arr = [2,3], the median is (2 + 3) / 2 = 2.5.
            </Span>
            <Span>
              Implement the MedianFinder class:
              <br />
              <br />
              MedianFinder() initializes the MedianFinder object.
              <br />
              void addNum(int num) adds the integer num from the data stream to
              the data structure.
              <br />
              double findMedian() returns the median of all elements so far.
              Answers within 10-5 of the actual answer will be accepted.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input
              <br />
              ["MedianFinder", "addNum", "addNum", "findMedian", "addNum",
              "findMedian"]
              <br />
              [[], [1], [2], [], [3], []] Output
              <br />
              [null, null, null, 1.5, null, 2.0]
              <br />
              Explanation
              <br />
              MedianFinder medianFinder = new MedianFinder();
              <br />
              medianFinder.addNum(1); // arr = [1]
              <br />
              medianFinder.addNum(2); // arr = [1, 2]
              <br />
              medianFinder.findMedian(); // return 1.5 (i.e., (1 + 2) / 2)
              <br />
              medianFinder.addNum(3); // arr[1, 2, 3]
              <br />
              medianFinder.findMedian(); // return 2.0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              -105 &lt;= num &lt;= 105 <br />
              <br />
              There will be at least one element in the data structure before
              calling findMedian. At most 5 * 104 calls will be made to addNum
              and findMedian.
            </Span>
            <Span>
              Follow up:
              <br />
              If all integer numbers from the stream are in the range [0, 100],
              how would you optimize your solution?
              <br />
              If 99% of all integer numbers from the stream are in the range [0,
              100], how would you optimize your solution?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(nlog n) <br />
              Space: O(n)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q295. Find Median from Data Stream (Q254)",
                codes: {
                  Javascript: {
                    code: `
                    // ["MedianFinder","addNum","addNum",
                    // "findMedian","addNum","findMedian"]
                    // [[],[1],[2],[],[3],[]]
                    class MedianFinder {
                      public void addNum(int num) {
                        if (l.isEmpty() || num <= l.peek())
                          l.offer(num);
                        else
                          r.offer(num);
                        if (l.size() < r.size())
                          l.offer(r.poll());
                        else if (l.size() - r.size() > 1)
                          r.offer(l.poll());
                      }
                    
                      public double findMedian() {
                        if (l.size() == r.size())
                          return (double) (l.peek() + r.peek()) / 2.0;
                        return (double) l.peek();
                      }
                    
                      private PriorityQueue<Integer> l = new PriorityQueue<>(Collections.reverseOrder());
                      private PriorityQueue<Integer> r = new PriorityQueue<>();                           
                    }
                    
                    
                    /**
                     * Your MedianFinder object will be instantiated and called as such:
                     * MedianFinder obj = new MedianFinder();
                     * obj.addNum(num);
                     * double param_2 = obj.findMedian();
                     */`,
                    output: `[null,null,null,1.50000,null,2.00000]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q255: {
        title: "Q297. Serialize and Deserialize Binary Tree (Q255)",
        content: (
          <>
            <Span>
              <b>Q297. Serialize and Deserialize Binary Tree (Q255)</b>
            </Span>
            <Span>
              Serialization is the process of converting a data structure or
              object into a sequence of bits so that it can be stored in a file
              or memory buffer, or transmitted across a network connection link
              to be reconstructed later in the same or another computer
              environment.
              <br />
              Design an algorithm to serialize and deserialize a binary tree.
              There is no restriction on how your serialization/deserialization
              algorithm should work. You just need to ensure that a binary tree
              can be serialized to a string and this string can be deserialized
              to the original tree structure.
              <br />
              Clarification: The input/output format is the same as how LeetCode
              serializes a binary tree. You do not necessarily need to follow
              this format, so please be creative and come up with different
              approaches yourself.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode297} left />
            <Span>
              Input: root = [1,2,3,null,null,4,5] <br />
              Output: [1,2,3,null,null,4,5]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = []
              <br />
              Output: []
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [0, 104]. <br />
              -1000 &lt;= Node.val &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n) <br />
              Space: O(n)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q297. Serialize and Deserialize Binary Tree (Q255)",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val) {
                      this.val = val;
                      this.left = this.right = null;
                    }
                    
                    /**
                     * Encodes a tree to a single string.
                     *
                     * @param {TreeNode} root
                     * @return {string}
                     */
                    
                    function preorderS(root,res){
                      if (!root) {
                        res.push("n ");
                        return;
                      }
                      res.push(root.val," ")
                      preorderS(root.left, res);
                      preorderS(root.right, res);
                    }
                    
                    var serialize = function(root) {
                      const res = []
                      preorderS(root, res);
                      return res.join("");
                    };
                    
                    /**
                     * Decodes your encoded data to tree.
                     *
                     * @param {string} data
                     * @return {TreeNode}
                     */
                    
                    function preorderD(q){
                      const s = q.shift();
                      if (s == "n")
                        return null;
                      const root = new TreeNode(parseInt(s));
                      root.left = preorderD(q);
                      root.right = preorderD(q);
                      return root;
                    }
                    
                    var deserialize = function(data) {
                      const vals = data.split(" ");
                      return preorderD(vals); 
                    };
                    
                    const t = new TreeNode(1)
                    t.left = new TreeNode(2)
                    t.right = new TreeNode(3)
                    t.right.left = new TreeNode(4)
                    t.right.right = new TreeNode(5)
                    deserialize(serialize(t));
                    `,
                    output: `TreeNode {
                      val: 1,
                      right: TreeNode {
                        val: 3,
                        right: TreeNode { val: 5, right: null, left: null },
                        left: TreeNode { val: 4, right: null, left: null }
                      },
                      left: TreeNode { val: 2, right: null, left: null }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q256: {
        title: "Q299. Bulls and Cows (Q256)",
        content: (
          <>
            <Span>
              <b>Q299. Bulls and Cows (Q256)</b>
            </Span>
            <Span>
              You are playing the Bulls and Cows game with your friend.
              <br />
              You write down a secret number and ask your friend to guess what
              the number is. When your friend makes a guess, you provide a hint
              with the following info:
              <br />
              <br />
              The number of "bulls", which are digits in the guess that are in
              the correct position.
              <br />
              The number of "cows", which are digits in the guess that are in
              your secret number but are located in the wrong position.
              Specifically, the non-bull digits in the guess that could be
              rearranged such that they become bulls.
              <br />
              Given the secret number secret and your friend's guess guess,
              return the hint for your friend's guess.
              <br />
              The hint should be formatted as "xAyB", where x is the number of
              bulls and y is the number of cows. Note that both secret and guess
              may contain duplicate digits.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: secret = "1807", guess = "7810"
              <br />
              Output: "1A3B"
              <br />
              Explanation: Bulls are connected with a '|' and cows are
              underlined:
              <br />
              <pre>{`
"1807"
|
"7810              
              `}</pre>
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: secret = "1123", guess = "0111" <br />
              Output: "1A1B" <br />
              Explanation: Bulls are connected with a '|' and cows are
              underlined: <br />
              <pre>
                {`
"1123"        "1123"
|      or     |
"0111"        "0111"
    `}
              </pre>
              Note that only one of the two unmatched 1s is counted as a cow
              since the non-bull digits can only be rearranged to allow one 1 to
              be a bull.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= secret.length, guess.length &lt;= 1000 <br />
              secret.length == guess.length <br />
              secret and guess consist of digits only.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n) <br />
              Space: O(10)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q299. Bulls and Cows (Q256)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} secret
                    * @param {string} guess
                    * @return {string}
                    */
                   var getHint = function(secret, guess) {
                     let A = 0;
                     let B = 0;
                     const count1 = Array(10).fill(0);
                     const count2 = Array(10).fill(0);
                     for (let i = 0; i < secret.length; ++i)
                       if(secret[i] == guess[i])
                           ++A;
                       else {
                         ++count1[parseInt(secret[i])];
                         ++count2[parseInt(guess[i])];
                       }
                       for (let i = 0; i < 10; ++i)
                         B += Math.min(count1[i], count2[i]);
                   
                       return A + "A" + B + "B";
                   };
                   
                   getHint("1807","7810")`,
                    output: `1A3B`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q257: {
        title: "Q300. Longest Increasing Subsequence (Q257)",
        content: (
          <>
            <Span>
              <b>Q300. Longest Increasing Subsequence (Q257)</b>
            </Span>
            <Span>
              Given an integer array nums, return the length of the longest
              strictly increasing subsequence.
              <br />A subsequence is a sequence that can be derived from an
              array by deleting some or no elements without changing the order
              of the remaining elements. For example, [3,6,2,7] is a subsequence
              of the array [0,3,1,6,2,2,7].
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [10,9,2,5,3,7,101,18]
              <br />
              Output: 4 <br />
              Explanation: The longest increasing subsequence is [2,3,7,101],
              therefore the length is 4.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0,1,0,3,2,3] <br />
              Output: 4
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [7,7,7,7,7,7,7] <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 2500 <br />
              -10^4 &lt;= nums[i] &lt;= 10^4
            </Span>
            <Span>
              Follow up: Can you come up with an algorithm that runs in O(n
              log(n)) time complexity?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2) <br />
              Space: O(n)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q300. Longest Increasing Subsequence (Q257)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var lengthOfLIS = function(nums) {
                     const n = nums.length
                     const dp = Array(n+1).fill(1)
                     for(let i=1;i<n;i++){
                     for(let j=0;j<=i;j++){
                       if(nums[i]>nums[j]&&dp[i]<dp[j]+1)
                       dp[i] = dp[j] + 1 
                     } 
                     } 
                     return Math.max(...dp) 
                   };
                   
                   lengthOfLIS([10,9,2,5,3,7,101,18])`,
                    output: `4`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q258: {
        title: "Q301. Remove Invalid Parentheses (Q258)",
        content: (
          <>
            <Span>
              <b>Q301. Remove Invalid Parentheses (Q258)</b>
            </Span>
            <Span>
              Given a string s that contains parentheses and letters, remove the
              minimum number of invalid parentheses to make the input string
              valid.
              <br />
              Return all the possible results. You may return the answer in any
              order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "()())()" <br />
              Output: ["(())()","()()()"]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "(a)())()" <br />
              Output: ["(a())()","(a)()()"]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: s = ")("
              <br />
              Output: [""]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 25
              <br />
              s consists of lowercase English letters and parentheses '(' and
              ')'.
              <br />
              There will be at most 20 parentheses in s.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(2^n)
              <br />
              Space: O(n+∣ans∣)
            </p>
            <CodeEditor
              options={{
                title: "Q301. Remove Invalid Parentheses (Q258)",
                codes: {
                  Javascript: {
                    code: `function getLeftAndRightCounts(s) {
                      let l = 0;
                      let r = 0;
                      for (let c of s)
                        if (c == '(')
                          ++l;
                        else if (c == ')') {
                          if (l == 0)
                            ++r;
                          else
                            --l;
                        }
                      return [l, r];
                    }
                    
                    function isValid(s) {
                      let count = 0; 
                      for (let c of s) {
                        if (c == '(')
                          ++count;
                        else if (c == ')')
                          --count;
                        if (count < 0)
                          return false;
                      }
                      return true; 
                    }
                    
                    function dfs(s,start,l,r,res) {
                      if (l == 0 && r == 0 && isValid(s)) {
                        res.push(s);
                        return;
                      }
                      for (let i = start; i < s.length; ++i) {
                        if (i > start && s[i] == s[i - 1])
                        continue;
                        if (l > 0 && s[i] == '(') 
                        dfs(s.substring(0, i) + s.substring(i + 1), i, l - 1, r, res);
                        else if (r > 0 && s.charAt(i) == ')') 
                        dfs(s.substring(0, i) + s.substring(i + 1), i, l, r - 1, res);
                      }
                    }
                    
                    /**
                     * @param {string} s
                     * @return {string[]}
                     */
                    var removeInvalidParentheses = function(s) {
                      const res = [];
                      const counts = getLeftAndRightCounts(s);
                      dfs(s, 0, counts[0], counts[1], res);
                      return res; 
                    };
                    
                    removeInvalidParentheses("()())()")`,
                    output: `[ '(())()', '()()()' ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q259: {
        title: "Q303. Range Sum Query - Immutable (Q259)",
        content: (
          <>
            <Span>
              <b>Q303. Range Sum Query - Immutable (Q259)</b>
            </Span>
            <Span>
              Given an integer array nums, handle multiple queries of the
              following type:
              <br />
              Calculate the sum of the elements of nums between indices left and
              right inclusive where left &lt;= right.
              <br />
              Implement the NumArray class:
              <br />
              NumArray(int[] nums) Initializes the object with the integer array
              nums.
              <br />
              int sumRange(int left, int right) Returns the sum of the elements
              of nums between indices left and right inclusive (i.e. nums[left]
              + nums[left + 1] + ... + nums[right]).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input
              <br />
              ["NumArray", "sumRange", "sumRange", "sumRange"]
              <br />
              [[[-2, 0, 3, -5, 2, -1]], [0, 2], [2, 5], [0, 5]]
              <br />
              Output
              <br />
              [null, 1, -1, -3]
              <br />
              Explanation
              <br />
              NumArray numArray = new NumArray([-2, 0, 3, -5, 2, -1]);
              <br />
              numArray.sumRange(0, 2); // return (-2) + 0 + 3 = 1<br />
              numArray.sumRange(2, 5); // return 3 + (-5) + 2 + (-1) = -1
              <br />
              numArray.sumRange(0, 5); // return (-2) + 0 + 3 + (-5) + 2 + (-1)
              = -3
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 104 <br />
              -105 &lt;= nums[i] &lt;= 105 <br />
              0 &lt;= left &lt;= right &lt; nums.length <br />
              At most 104 calls will be made to sumRange.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q303. Range Sum Query - Immutable (Q259)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    */
                   var NumArray = function(nums) {
                     this.prefix = Array(nums.length + 1).fill(0);
                     for(let i = 0; i < nums.length; ++i)
                     this.prefix[i + 1] = nums[i] + this.prefix[i]; 
                   };
                   
                   /** 
                    * @param {number} left 
                    * @param {number} right
                    * @return {number}
                    */
                   NumArray.prototype.sumRange = function(left, right) {
                     return this.prefix[right + 1] - this.prefix[left];
                   };
                   
                   
                   var obj = new NumArray([-2, 0, 3, -5, 2, -1])
                   var param_1 = obj.sumRange(0,4)
                   console.log(param_1)`,
                    output: `-2`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q260: {
        title: "Q304. Range Sum Query 2D - Immutable (Q260)",
        content: (
          <>
            <Span>
              <b>Q304. Range Sum Query 2D - Immutable (Q260)</b>
            </Span>
            <Span>
              Given a 2D matrix matrix, handle multiple queries of the following
              type:
              <br />
              Calculate the sum of the elements of matrix inside the rectangle
              defined by its upper left corner (row1, col1) and lower right
              corner (row2, col2).
              <br />
              Implement the NumMatrix class:
              <br />
              NumMatrix(int[][] matrix) Initializes the object with the integer
              matrix matrix.
              <br />
              int sumRegion(int row1, int col1, int row2, int col2) Returns the
              sum of the elements of matrix inside the rectangle defined by its
              upper left corner (row1, col1) and lower right corner (row2,
              col2).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode304} left />
            <Span>
              Input ["NumMatrix", "sumRegion", "sumRegion", "sumRegion"] <br />
              [[[[3, 0, 1, 4, 2], [5, 6, 3, 2, 1], [1, 2, 0, 1, 5], [4, 1, 0, 1,
              7], [1, 0, 3, 0, 5]]], [2, 1, 4, 3], [1, 1, 2, 2], [1, 2, 2, 4]]
              <br />
              Output
              <br />
              [null, 8, 11, 12]
              <br />
              Explanation
              <br />
              NumMatrix numMatrix = new NumMatrix([[3, 0, 1, 4, 2], [5, 6, 3, 2,
              1], [1, 2, 0, 1, 5], [4, 1, 0, 1, 7], [1, 0, 3, 0, 5]]);
              <br />
              numMatrix.sumRegion(2, 1, 4, 3); // return 8 (i.e sum of the red
              rectangle)
              <br />
              numMatrix.sumRegion(1, 1, 2, 2); // return 11 (i.e sum of the
              green rectangle)
              <br />
              numMatrix.sumRegion(1, 2, 2, 4); // return 12 (i.e sum of the blue
              rectangle)
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == matrix.length <br />
              n == matrix[i].length <br />
              1 &lt;= m, n &lt;= 200 <br />
              -105 &lt;= matrix[i][j] &lt;= 105 <br />
              0 &lt;= row1 &lt;= row2 &lt; m <br />
              0 &lt;= col1 &lt;= col2 &lt; n <br />
              At most 104 calls will be made to sumRegion.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(mn)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q304. Range Sum Query 2D - Immutable (Q260)",
                codes: {
                  Java: {
                    code: `class NumMatrix {
                      public NumMatrix(int[][] matrix) {
                        if (matrix.length == 0)
                          return;
                    
                        final int m = matrix.length;
                        final int n = matrix[0].length;
                    
                        prefix = new int[m + 1][n + 1];
                    
                        for (int i = 0; i < m; ++i)
                          for (int j = 0; j < n; ++j)
                            prefix[i + 1][j + 1] = matrix[i][j] + prefix[i][j + 1] + prefix[i + 1][j] - prefix[i][j];
                      }
                    
                      public int sumRegion(int row1, int col1, int row2, int col2) {
                        return prefix[row2 + 1][col2 + 1] - prefix[row1][col2 + 1]
                             - prefix[row2 + 1][col1] + prefix[row1][col1];
                      }
                    
                      private int[][] prefix;
                    }
                    `,
                    output: `[null,8,11,12]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q261: {
        title: "Q306. Additive Number (Q261)",
        content: (
          <>
            <Span>
              <b>Q306. Additive Number (Q261)</b>
            </Span>
            <Span>
              An additive number is a string whose digits can form an additive
              sequence.
              <br />
              A valid additive sequence should contain at least three numbers.
              Except for the first two numbers, each subsequent number in the
              sequence must be the sum of the preceding two.
              <br />
              Given a string containing only digits, return true if it is an
              additive number or false otherwise.
              <br />
              Note: Numbers in the additive sequence cannot have leading zeros,
              so sequence 1, 2, 03 or 1, 02, 3 is invalid.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: "112358"
              <br />
              Output: true
              <br />
              Explanation: <br />
              The digits can form an additive sequence: 1, 1, 2, 3, 5, 8. <br />
              1 + 1 = 2, 1 + 2 = 3, 2 + 3 = 5, 3 + 5 = 8
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: "199100199"
              <br />
              Output: true
              <br />
              Explanation: <br />
              The additive sequence is: 1, 99, 100, 199. <br />1 + 99 = 100, 99
              + 100 = 199
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= num.length &lt;= 35 <br />
              num consists only of digits.
            </Span>
            <Span>
              Follow up: How would you handle overflow for very large input
              integers?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(n^2)
            </p>{" "}
            <CodeEditor
              options={{
                title: "Q306. Additive Number (Q261)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} num
                    * @return {boolean}
                    */
                   
                   function dfs(num,n1,n2,s){
                     if(s===num.length) return true
                     const n3  = n1 + n2
                     const n3_s = String(n3)
                     return num.indexOf(n3_s, s) == s && dfs(num,n2, n3, s + n3_s.length)
                   }
                   
                   var isAdditiveNumber = function(num) {
                     const n = num.length;
                     for (let i = 0; i < parseInt(n / 2); ++i) {
                       if (i > 0 && num[0] == '0')
                           return false;
                       const firstNum = parseInt(num.substring(0, i + 1));
                         for (let j = i + 1; Math.max(i, j - i) < n - j; ++j) {
                           if (j > i + 1 && num[i + 1] == '0')
                             break;
                           const secondNum = parseInt(num.substring(i + 1, j + 1));
                           if (dfs(num, firstNum, secondNum, j + 1))
                             return true;
                         }
                       }
                       return false;
                   };
                   
                   isAdditiveNumber("120122436")`,
                    output: `false`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q262: {
        title: "Q307. Range Sum Query - Mutable (Q262)",
        content: (
          <>
            <Span>
              <b>Q307. Range Sum Query - Mutable (Q262)</b>
            </Span>
            <Span>
              Given an integer array nums, handle multiple queries of the
              following types:
              <br />
              Update the value of an element in nums.
              <br />
              Calculate the sum of the elements of nums between indices left and
              right inclusive where left &lt;= right.
              <br />
              Implement the NumArray class:
              <br />
              <br />
              NumArray(int[] nums) Initializes the object with the integer array
              nums.
              <br />
              void update(int index, int val) Updates the value of nums[index]
              to be val.
              <br />
              int sumRange(int left, int right) Returns the sum of the elements
              of nums between indices left and right inclusive (i.e. nums[left]
              + nums[left + 1] + ... + nums[right]).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input ["NumArray", "sumRange", "update", "sumRange"]
              <br />
              [[[1, 3, 5]], [0, 2], [1, 2], [0, 2]]
              <br />
              Output
              <br />
              [null, 9, null, 8]
              <br />
              Explanation
              <br />
              NumArray numArray = new NumArray([1, 3, 5]);
              <br />
              numArray.sumRange(0, 2); // return 1 + 3 + 5 = 9<br />
              numArray.update(1, 2); // nums = [1, 2, 5]
              <br />
              numArray.sumRange(0, 2); // return 1 + 2 + 5 = 8
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 3 * 104 <br />
              -100 &lt;= nums[i] &lt;= 100 <br />
              0 &lt;= index &lt; nums.length <br />
              -100 &lt;= val &lt;= 100 <br />
              0 &lt;= left &lt;= right &lt; nums.length <br />
              At most 3 * 104 calls will be made to update and sumRange.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: Constructor: O(nlog n), update(): O(log n),sumRange(): O(log
              n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q307. Range Sum Query - Mutable (Q262)",
                codes: {
                  Javascript: {
                    code: `class FenwickTree {
                      constructor(n){
                        this.sums = Array(n + 1).fill(0);
                      }
                    
                      update(i, delta) {
                        while (i < this.sums.length) {
                          this.sums[i] += delta;
                          i += i & -i;
                        }
                      }
                    
                      get(i) {
                        let sum = 0;
                        while (i > 0) {
                          sum += this.sums[i];
                          i -= i & -i;
                        }
                        return sum;
                      }
                    
                    }
                    
                    /**
                     * @param {number[]} nums
                     */
                    var NumArray = function(nums) {
                      this.nums = nums
                      this.tree = new FenwickTree(nums.length);
                        for (let i = 0; i < nums.length; ++i)
                          this.tree.update(i + 1, nums[i]);  
                    };
                    
                    /** 
                     * @param {number} index 
                     * @param {number} val
                     * @return {void}
                     */
                    NumArray.prototype.update = function(index, val) {
                      this.tree.update(index + 1, val - this.nums[index]);
                      this.nums[index] = val;  
                    };
                    
                    /** 
                     * @param {number} left 
                     * @param {number} right
                     * @return {number}
                     */
                    NumArray.prototype.sumRange = function(left, right) {
                      return this.tree.get(right + 1) - this.tree.get(left);
                    };
                    
                    /** 
                     * Your NumArray object will be instantiated and called as such:
                     * var obj = new NumArray(nums)
                     * obj.update(index,val)
                     * var param_2 = obj.sumRange(left,right)
                     */`,
                    output: `[null,9,null,8]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q263: {
        title: "Q309. Best Time to Buy and Sell Stock with Cooldown (Q263)",
        content: (
          <>
            <Span>
              <b>Q309. Best Time to Buy and Sell Stock with Cooldown (Q263)</b>
            </Span>
            <Span>
              You are given an array prices where prices[i] is the price of a
              given stock on the ith day.
              <br />
              Find the maximum profit you can achieve. You may complete as many
              transactions as you like (i.e., buy one and sell one share of the
              stock multiple times) with the following restrictions:
              <br />
              After you sell your stock, you cannot buy stock on the next day
              (i.e., cooldown one day).
              <br />
              Note: You may not engage in multiple transactions simultaneously
              (i.e., you must sell the stock before you buy again).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: prices = [1,2,3,0,2] <br />
              Output: 3 <br />
              Explanation: transactions = [buy, sell, cooldown, buy, sell]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: prices = [1]
              <br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= prices.length &lt;= 5000 <br />0 &lt;= prices[i] &lt;=
              1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title:
                  "Q309. Best Time to Buy and Sell Stock with Cooldown (Q263)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} prices
                    * @return {number}
                    */
                   var maxProfit = function(prices){
                     let sell = 0;
                     let hold = Number.MIN_SAFE_INTEGER;
                     let prev = 0;
                     for (let price of prices){
                     const cache = sell;
                     sell = Math.max(sell, hold + price);
                     hold = Math.max(hold, prev - price);
                     prev = cache;
                     }
                     return sell;
                   };
                   
                   maxProfit([1,2,3,0,2])`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q264: {
        title: "Q310. Minimum Height Trees (Q264)",
        content: (
          <>
            <Span>
              <b>Q310. Minimum Height Trees (Q264)</b>
            </Span>
            <Span>
              A tree is an undirected graph in which any two vertices are
              connected by exactly one path. In other words, any connected graph
              without simple cycles is a tree.
              <br />
              Given a tree of n nodes labelled from 0 to n - 1, and an array of
              n - 1 edges where edges[i] = [ai, bi] indicates that there is an
              undirected edge between the two nodes ai and bi in the tree, you
              can choose any node of the tree as the root. When you select a
              node x as the root, the result tree has height h. Among all
              possible rooted trees, those with minimum height (i.e. min(h)) are
              called minimum height trees (MHTs).
              <br />
              Return a list of all MHTs' root labels. You can return the answer
              in any order.
              <br />
              The height of a rooted tree is the number of edges on the longest
              downward path between the root and a leaf.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode310} left />
            <Span>
              Input: n = 4, <br />
              edges = [[1,0],[1,2],[1,3]] Output: [1] <br />
              Explanation: As shown, the height of the tree is 1 when the root
              is the node with label 1 which is the only MHT.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 6, <br />
              edges = [[3,0],[3,1],[3,2],[3,4],[5,4]]
              <br />
              Output: [3,4]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= n &lt;= 2 * 104
              <br />
              edges.length == n - 1<br />
              0 &lt;= ai, bi &lt; n<br />
              ai != bi
              <br />
              All the pairs (ai, bi) are distinct.
              <br />
              The given input is guaranteed to be a tree and there will be no
              repeated edges.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q310. Minimum Height Trees (Q264)",
                codes: {
                  "C++": {
                    code: `class Solution {
                      public:
                       vector<int> findMinHeightTrees(int n, vector<vector<int>>& edges) {
                         if (n == 1 || edges.empty())
                           return {0};
                     
                         vector<int> ans;
                         unordered_map<int, unordered_set<int>> graph;
                     
                         for (const auto& edge : edges) {
                           const int u = edge[0];
                           const int v = edge[1];
                           graph[u].insert(v);
                           graph[v].insert(u);
                         }
                     
                         for (const auto& [label, children] : graph)
                           if (children.size() == 1)
                             ans.push_back(label);
                     
                         while (n > 2) {
                           n -= ans.size();
                           vector<int> nextLeaves;
                           for (const int leaf : ans) {
                             const int u = *begin(graph[leaf]);
                             graph[u].erase(leaf);
                             if (graph[u].size() == 1)
                               nextLeaves.push_back(u);
                           }
                           ans = nextLeaves;
                         }
                         for(auto a: ans)
                         cout << a << " ";
                         return ans;
                       }
                     };
                     `,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q265: {
        title: "Q312. Burst Balloons (Q265)",
        content: (
          <>
            <Span>
              <b>Q312. Burst Balloons (Q265)</b>
            </Span>
            <Span>
              You are given n balloons, indexed from 0 to n - 1. Each balloon is
              painted with a number on it represented by an array nums. You are
              asked to burst all the balloons.
              <br />
              If you burst the ith balloon, you will get nums[i - 1] * nums[i] *
              nums[i + 1] coins. If i - 1 or i + 1 goes out of bounds of the
              array, then treat it as if there is a balloon with a 1 painted on
              it.
              <br />
              Return the maximum coins you can collect by bursting the balloons
              wisely.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [3,1,5,8]
              <br />
              Output: 167
              <br />
              Explanation:
              <br />
              nums = [3,1,5,8] --&gt; [3,5,8] --&gt; [3,8] --&gt; [8] --&gt; []
              <br />
              coins = 3*1*5 + 3*5*8 + 1*3*8 + 1*8*1 = 167
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1,5] <br />
              Output: 10
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              n == nums.length <br />
              1 &lt;= n &lt;= 300 <br />0 7lt;= nums[i] &lt;= 100
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(n^2)
            </p>
            <CodeEditor
              options={{
                title: "Q312. Burst Balloons (Q265)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number}
                    */
                   var maxCoins = function(nums) {
                     const n = nums.length;
                     const a = nums
                     a.unshift(1)
                     a.push(1)
                     const dp = Array.from(Array(n+2),()=>Array(n+2).fill(0))
                     for (let d = 0; d < n; ++d)
                     for (let i = 1; i < n - d + 1; ++i) {
                     const j = i + d;
                     for (let k = i; k <= j; ++k)
                     dp[i][j] = Math.max(dp[i][j],
                       dp[i][k - 1]+dp[k + 1][j]+ a[i - 1] * a[k] * a[j + 1]);
                     }
                     return dp[1][n];
                   };
                   
                   maxCoins([3,1,5,8])`,
                    output: `167`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q266: {
        title: "Q313. Super Ugly Number (Q266)",
        content: (
          <>
            <Span>
              <b>Q313. Super Ugly Number (Q266)</b>
            </Span>
            <Span>
              A super ugly number is a positive integer whose prime factors are
              in the array primes.
              <br />
              Given an integer n and an array of integers primes, return the nth
              super ugly number.
              <br />
              The nth super ugly number is guaranteed to fit in a 32-bit signed
              integer.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 12,
              <br /> primes = [2,7,13,19]
              <br />
              Output: 32
              <br />
              Explanation: [1,2,4,7,8,13,14,16,19,26,28,32] is the sequence of
              the first 12 super ugly numbers given primes = [2,7,13,19].
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 1, <br /> primes = [2,3,5] <br />
              Output: 1 <br />
              Explanation: 1 has no prime factors, therefore all of its prime
              factors are in the array primes = [2,3,5].
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= n &lt;= 106 <br />
              1 &lt;= primes.length &lt;= 100 <br />
              2 &lt;= primes[i] &lt;= 1000 <br />
              primes[i] is guaranteed to be a prime number. <br />
              All the values of primes are unique and sorted in ascending order.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(nk)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q313. Super Ugly Number (Q266)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @param {number[]} primes
                    * @return {number}
                    */
                   var nthSuperUglyNumber = function(n, primes) {
                     const k = primes.length;
                     const indices = Array(k).fill(0);
                     const uglyNums = Array(n).fill(0);
                     uglyNums[0] = 1;
                     for (let i = 1; i < n; ++i) {
                       const nexts = Array(k).fill(0);
                       for (let j = 0; j < k; ++j)
                         nexts[j] = uglyNums[indices[j]] * primes[j];
                       const next = Math.min(...nexts)
                       for (let j = 0; j < k; ++j)
                         if (next == nexts[j])
                           ++indices[j];
                       uglyNums[i] = next;
                     }
                     return uglyNums[n - 1];
                   };
                   
                   nthSuperUglyNumber(12,[2,7,13,19])`,
                    output: `32`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q267: {
        title: "Q315. Count of Smaller Numbers After Self (Q267)",
        content: (
          <>
            <Span>
              <b>Q315. Count of Smaller Numbers After Self (Q267)</b>
            </Span>
            <Span>
              You are given an integer array nums and you have to return a new
              counts array. The counts array has the property where counts[i] is
              the number of smaller elements to the right of nums[i].
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [5,2,6,1] <br />
              Output: [2,1,1,0] <br />
              Explanation: <br />
              To the right of 5 there are 2 smaller elements (2 and 1). <br />
              To the right of 2 there is only 1 smaller element (1). <br />
              To the right of 6 there is 1 smaller element (1). <br />
              To the right of 1 there is 0 smaller element.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [-1] <br />
              Output: [0]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [-1,-1]
              <br />
              Output: [0,0]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 10^5 <br />
              -10^4 &lt;= nums[i] &lt;= 10^4
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.log n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q315. Count of Smaller Numbers After Self (Q267)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {number[]}
                    */
                   
                   class FenwickTree {
                     constructor(n){
                      this.sums = Array(n+1).fill(0)
                     }
                     update(i, delta) {
                       while (i < this.sums.length) {
                         this.sums[i] += delta;
                         i += i & -i;
                       }
                     }
                     get(i) {
                       let sum = 0;
                       while (i > 0) {
                         sum += this.sums[i];
                         i -= i & -i;
                       }
                       return sum;
                     }
                   }
                   
                   var countSmaller = function(nums) {
                     const ans = []
                     const uniqueNums = new Set(nums)
                     const tree = new FenwickTree(uniqueNums.size)
                     const ranks = {}
                     let rank = 0
                     for(let num of [...Array.from(uniqueNums).sort((a,b)=>a-b)]){
                       ranks[num] = ++rank
                     }
                     for(let i = nums.length - 1; i >= 0; --i){
                       let num = nums[i]
                       ans.push(tree.get(ranks[num] - 1))
                       tree.update(ranks[+num], 1)
                     }
                     return ans.reverse()
                   };
                   
                   countSmaller([5,2,6,1])`,
                    output: `[ 2, 1, 1, 0 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q268: {
        title: "Q316. Remove Duplicate Letters (Q268)",
        content: (
          <>
            <Span>
              <b>Q316. Remove Duplicate Letters (Q268)</b>
            </Span>
            <Span>
              Given a string s, remove duplicate letters so that every letter
              appears once and only once. You must make sure your result is the
              smallest in lexicographical order among all possible results.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "bcabc" <br />
              Output: "abc"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "cbacdcbc" <br />
              Output: "acdb"
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 104 s consists of lowercase English
              letters.
            </Span>{" "}
            <Span>
              Note: This question is the same as 1081:
              https://leetcode.com/problems/smallest-subsequence-of-distinct-characters/
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q316. Remove Duplicate Letters (Q268)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {string}
                    */
                   var removeDuplicateLetters = function(s) {
                     const st = [];
                     const count = Array(128).fill(0);
                     const used = Array(128).fill(false);
                     for (let c of s)
                       ++count[c.charCodeAt(0)];
                   
                     for (let c of s) {
                     --count[c.charCodeAt(0)];
                     if (used[c.charCodeAt(0)]) continue;
                     while (st.length > 0 && st[st.length-1].charCodeAt(0) > c.charCodeAt(0) && count[st[st.length-1].charCodeAt(0)] > 0) {
                       used[st[st.length-1].charCodeAt(0)] = false;
                       st.pop()
                     }
                     used[c.charCodeAt(0)] = true;
                     st.push(c);
                     }
                     return st.join("");
                   }
                   
                   removeDuplicateLetters("bcabc")`,
                    output: `abc`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q269: {
        title: "Q318. Maximum Product of Word Lengths (Q269)",
        content: (
          <>
            <Span>
              <b>Q318. Maximum Product of Word Lengths (Q269)</b>
            </Span>
            <Span>
              Given a string array words, return the maximum value of
              length(word[i]) * length(word[j]) where the two words do not share
              common letters. If no such two words exist, return 0.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: words = ["abcw","baz","foo","bar","xtfn","abcdef"]
              <br />
              Output: 16
              <br />
              Explanation: The two words can be "abcw", "xtfn".
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: words = ["abcw","baz","foo","bar","xtfn","abcdef"]
              <br />
              Output: 16
              <br />
              Explanation: The two words can be "abcw", "xtfn".
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: words = ["abcw","baz","foo","bar","xtfn","abcdef"]
              <br />
              Output: 16
              <br />
              Explanation: The two words can be "abcw", "xtfn".
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              2 &lt;= words.length &lt;= 1000
              <br />
              1 &lt;= words[i].length &lt;= 1000
              <br />
              words[i] consists only of lowercase English letters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n^2)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q318. Maximum Product of Word Lengths (Q269)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string[]} words
                    * @return {number}
                    */
                   
                   function getMask(word) {
                     let mask = 0;
                     for (let c of word)
                       mask |= 1 << c.charCodeAt(0);
                     return mask;
                   }
                   
                   var maxProduct = function(words) {
                     let ans = 0;
                     const masks = Array(words.length).fill(0);
                     for (let i = 0; i < words.length; ++i)
                       masks[i] = getMask(words[i]);
                     for (let i = 0; i < masks.length; ++i)
                     for (let j = 0; j < i; ++j)
                       if((masks[i] & masks[j]) == 0)
                       ans = Math.max(ans, words[i].length * words[j].length);
                       return ans;
                   };
                   
                   maxProduct(["a","ab","abc","d","cd","bcd","abcd"])`,
                    output: `4`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q270: {
        title: "Q319. Bulb Switcher (Q270)",
        content: (
          <>
            <Span>
              <b>Q319. Bulb Switcher (Q270)</b>
            </Span>
            <Span>
              There are n bulbs that are initially off. You first turn on all
              the bulbs, then you turn off every second bulb.
              <br />
              On the third round, you toggle every third bulb (turning on if
              it's off or turning off if it's on). For the ith round, you toggle
              every i bulb. For the nth round, you only toggle the last bulb.
              <br />
              Return the number of bulbs that are on after n rounds.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode319} left />
            <Span>
              Input: n = 3 <br />
              Output: 1 <br />
              Explanation: At first, the three bulbs are [off, off, off]. <br />
              After the first round, the three bulbs are [on, on, on]. <br />
              After the second round, the three bulbs are [on, off, on]. <br />
              After the third round, the three bulbs are [on, off, off]. <br />
              So you should return 1 because there is only one bulb is on.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 0<br />
              Output: 0
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: n = 1<br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>0 &lt;= n &lt;= 109</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q319. Bulb Switcher (Q270)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number}
                    */
                   var bulbSwitch = function(n) {
                     return parseInt(Math.sqrt(n))
                   };
                   
                   bulbSwitch(1)`,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q271: {
        title: "Q321. Create Maximum Number (Q271)",
        content: (
          <>
            <Span>
              <b>Q321. Create Maximum Number (Q271)</b>
            </Span>
            <Span>
              You are given two integer arrays nums1 and nums2 of lengths m and
              n respectively. nums1 and nums2 represent the digits of two
              numbers. You are also given an integer k.
              <br />
              Create the maximum number of length k &lt;= m + n from digits of
              the two numbers. The relative order of the digits from the same
              array must be preserved.
              <br />
              Return an array of the k digits representing the answer.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums1 = [3,4,6,5],
              <br /> nums2 = [9,1,2,5,8,3], k = 5<br />
              Output: [9,8,6,5,3]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums1 = [6,7], <br />
              nums2 = [6,0,4], <br /> k = 5 <br />
              Output: [6,7,6,0,4]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums1 = [3,9], <br /> nums2 = [8,9], k = 3 <br />
              Output: [9,8,9]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == nums1.length <br />
              n == nums2.length <br />
              1 &lt;= m, n &lt;= 500 <br />
              0 &lt;= nums1[i], nums2[i] &lt;= 9 <br />1 &lt;= k &lt;= m + n
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(k(m+n)^2)
              <br />
              Space: O(m+n)
            </p>
            <CodeEditor
              options={{
                title: "Q321. Create Maximum Number (Q271)",
                codes: {
                  "C++": {
                    code: `class Solution {
                      public:
                       vector<int> maxNumber(vector<int>& nums1, vector<int>& nums2, int k) {
                         vector<int> ans;
                     
                         for (int k1 = 0; k1 <= k; ++k1) {
                           const int k2 = k - k1;
                           if (k1 > nums1.size() || k2 > nums2.size())
                             continue;
                           ans = max(ans, maxNumber(maxNumber(nums1, k1), maxNumber(nums2, k2)));
                         }
                     
                         return ans;
                       }
                     
                      private:
                       vector<int> maxNumber(const vector<int>& nums, int k) {
                         if (k == 0)
                           return {};
                     
                         vector<int> ans;
                         int toPop = nums.size() - k;
                     
                         for (const int num : nums) {
                           while (!ans.empty() && ans.back() < num && toPop-- > 0)
                             ans.pop_back();
                           ans.push_back(num);
                         }
                     
                         return {begin(ans), begin(ans) + k};
                       }
                     
                      private:
                       vector<int> maxNumber(const vector<int>& nums1, const vector<int>& nums2) {
                         vector<int> ans;
                     
                         auto s1 = cbegin(nums1);
                         auto s2 = cbegin(nums2);
                     
                         while (s1 != cend(nums1) || s2 != cend(nums2))
                           if (lexicographical_compare(s1, cend(nums1), s2, cend(nums2)))
                             ans.push_back(*s2++);
                           else
                             ans.push_back(*s1++);
                     
                         return ans;
                       }
                     };
                     `,
                    output: `[9,8,6,5,3]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q272: {
        title: "Q322. Coin Change (Q272)",
        content: (
          <>
            <Span>
              <b>Q322. Coin Change (Q272)</b>
            </Span>
            <Span>
              You are given an integer array coins representing coins of
              different denominations and an integer amount representing a total
              amount of money.
              <br />
              Return the fewest number of coins that you need to make up that
              amount. If that amount of money cannot be made up by any
              combination of the coins, return -1.
              <br />
              You may assume that you have an infinite number of each kind of
              coin.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: coins = [1,2,5], <br />
              amount = 11 <br />
              Output: 3 <br />
              Explanation: 11 = 5 + 5 + 1
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: coins = [2], <br />
              amount = 3<br />
              Output: -1
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: coins = [1],
              <br /> amount = 0<br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= coins.length &lt;= 12 <br />
              1 &lt;= coins[i] &lt;= 2^31 - 1 <br />0 &lt;= amount &lt;= 10^4
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(|coins|.|amount|)
              <br />
              Space: O(|amount|)
            </p>
            <CodeEditor
              options={{
                title: "Q322. Coin Change (Q272)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} coins
                    * @param {number} amount
                    * @return {number}
                    */
                   var coinChange = function(coins, amount) {
                     const dp = Array(amount + 1).fill(amount+1);
                     dp[0] = 0;
                     for (let coin of coins)
                       for (let i = coin; i <= amount; ++i)
                         dp[i] = Math.min(dp[i], dp[i - coin] + 1);
                       return dp[amount] == amount + 1 ? -1 : dp[amount];
                   };
                   
                   coinChange([1,2,5],11)`,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q273: {
        title: "Q324. Wiggle Sort II (Q273)0",
        content: (
          <>
            <Span>
              <b>Q324. Wiggle Sort II (Q273)</b>
            </Span>
            <Span>
              Given an integer array nums, reorder it such that nums[0] &lt;
              nums[1] &gt; nums[2] &lt; nums[3]....
              <br />
              You may assume the input array always has a valid answer.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,5,1,1,6,4]
              <br />
              Output: [1,6,1,5,1,4]
              <br />
              Explanation: [1,4,1,5,1,6] is also accepted.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1,3,2,2,3,1] <br />
              Output: [2,3,1,3,1,2]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              Follow Up: Can you do it in O(n) time and/or in-place with O(1)
              extra space?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n -&gt; n^2)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q324. Wiggle Sort II (Q273)",
                codes: {
                  Java: {
                    code: `public class Solution {
                      public void wiggleSort(int[] nums) {
                          if (nums == null || nums.length <= 1) return;
                  
                          int size = nums.length;
                          int medium = medium(nums);
                          int[] newArray = new int[size];
                          for (int i = 0; i < size; i++) {
                              newArray[i] = medium;
                          }
                  
                          if (size % 2 == 0) {
                              int l = nums.length - 2;
                              int r = 1;
                              for (int i = 0; i < nums.length; i++) {
                                  if (nums[i] < medium) {
                                      newArray[l] = nums[i];
                                      l -= 2;
                                  } else if (nums[i] > medium) {
                                      newArray[r] = nums[i];
                                      r += 2;
                                  }
                              }
                          } else {
                              int i = 0;
                              int j = size - 2;
                              for (int runner = 0; runner < size; runner++) {
                                  if (nums[runner] < medium) {
                                      newArray[i] = nums[runner];
                                      i += 2;
                                  } else if (nums[runner] > medium) {
                                      newArray[j] = nums[runner];
                                      j -= 2;
                                  }
                              }
                          }
                  
                          for (int i = 0; i < size; i++) {
                              nums[i] = newArray[i];
                          }
                      }
                  
                      private int medium(int[] nums) {
                          int size = nums.length;
                          return kTh(nums, size / 2);
                      }
                  
                      private int kTh(int[] nums, int k) {
                          int start = 0;
                          int end = nums.length - 1;
                  
                          while (start < end) {
                              int partition = partition(nums, start, end);
                              if (partition == k) {
                                  return nums[partition];
                              } else if (partition < k) {
                                  start = partition + 1;
                              } else {
                                  end = partition - 1;
                              }
                          }
                  
                          return nums[k];
                      }
                  
                      private int partition(int[] nums, int start, int end) {
                          if (start == end) return start;
                  
                          int pivot = nums[start];
                          int i = start + 1;
                          int j = end;
                  
                          while (true) {
                              while (i <= end && nums[i] <= pivot) {
                                  i++;
                              }
                  
                              while (j >= start + 1 && nums[j] > pivot) {
                                  j--;
                              }
                  
                              if (i > j) {
                                  break;
                              }
                  
                              swap(nums, i, j);
                          }
                  
                          swap(nums, start, j);
                          return j;
                      }
                  
                      private void swap(int[] nums, int start, int end) {
                          if (start < end) {
                              int temp = nums[start];
                              nums[start] = nums[end];
                              nums[end] = temp;
                          }
                      }
                  
                  
                  }`,
                    output: `[1,5,1,6,1,4]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q274: {
        title: "Q326. Power of Three (Q274)",
        content: (
          <>
            <Span>
              <b>Q326. Power of Three (Q274)</b>
            </Span>
            <Span>
              Given an integer n, return true if it is a power of three.
              Otherwise, return false.
              <br />
              An integer n is a power of three, if there exists an integer x
              such that n == 3^x.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 27 <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 0<br />
              Output: false
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: n = 9 <br />
              Output: true
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>-2^31 &lt;= n &lt;= 2^31 - 1</Span>{" "}
            <Span>Follow up: Could you solve it without loops/recursion?</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q326. Power of Three (Q274)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {boolean}
                    */
                   var isPowerOfThree = function(n) {
                     return n > 0 && Math.pow(3, 19) % n == 0;
                   };
                   
                   isPowerOfThree(3)`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q275: {
        title: "Q327. Count of Range Sum (Q275)",
        content: (
          <>
            <Span>
              <b>Q327. Count of Range Sum (Q275)</b>
            </Span>
            <Span>
              Given an integer array nums and two integers lower and upper,
              return the number of range sums that lie in [lower, upper]
              inclusive.
              <br />
              Range sum S(i, j) is defined as the sum of the elements in nums
              between indices i and j inclusive, where i &lt;= j.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [-2,5,-1], <br />
              lower = -2, <br /> upper = 2 <br />
              Output: 3 <br />
              Explanation: The three ranges are: [0,0], [2,2], and [0,2] and
              their respective sums are: -2, -1, 2.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [0],
              <br /> lower = 0, <br />
              upper = 0<br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 105 <br />
              -2^31 &lt;= nums[i] &lt;= 2^31 - 1 <br />
              -10^5 &lt;= lower &lt;= upper &lt;= 10^5 <br />
              The answer is guaranteed to fit in a 32-bit integer.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.log n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q327. Count of Range Sum (Q275)",
                codes: {
                  Java: {
                    code: `
                    // [-2,5,-1]
// -2
// 2
                    class Solution {
                      public int countRangeSum(int[] nums, int lower, int upper) {
                        final int n = nums.length;
                        long[] prefix = new long[n + 1];
                        for (int i = 0; i < n; ++i)
                          prefix[i + 1] = (long) nums[i] + prefix[i];
                        mergeSort(prefix, 0, n, lower, upper);
                        return count;
                      }
                    
                      private int count = 0;
                    
                      private void mergeSort(long[] prefix, int l, int r, int lower, int upper) {
                        if (l >= r)
                          return;
                        final int m = l + (r - l) / 2;
                        mergeSort(prefix, l, m, lower, upper);
                        mergeSort(prefix, m + 1, r, lower, upper);
                        merge(prefix, l, m, r, lower, upper);
                      }
                    
                      private void merge(long[] prefix, int l, int m, int r, int lower, int upper) {
                        int lo = m + 1; 
                        int hi = m + 1;
                        for (int i = l; i <= m; ++i) {
                          while (lo <= r && prefix[lo] - prefix[i] < lower)
                            ++lo;
                          while (hi <= r && prefix[hi] - prefix[i] <= upper)
                            ++hi;
                          count += hi - lo;
                        }
                    
                        long[] sorted = new long[r - l + 1];
                        int k = 0;     // sorted's index
                        int i = l;     // left's index
                        int j = m + 1; // right's index
                    
                        while (i <= m && j <= r)
                          if (prefix[i] < prefix[j])
                            sorted[k++] = prefix[i++];
                          else
                            sorted[k++] = prefix[j++];
                        while (i <= m)
                          sorted[k++] = prefix[i++];
                        while (j <= r)
                          sorted[k++] = prefix[j++];
                    
                        System.arraycopy(sorted, 0, prefix, l, sorted.length);
                      }
                    }
                    `,
                    output: `3`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q276: {
        title: "Q328. Odd Even Linked List (Q276)",
        content: (
          <>
            <Span>
              <b>Q328. Odd Even Linked List (Q276)</b>
            </Span>
            <Span>
              Given the head of a singly linked list, group all the nodes with
              odd indices together followed by the nodes with even indices, and
              return the reordered list.
              <b />
              The first node is considered odd, and the second node is even, and
              so on.
              <br />
              Note that the relative order inside both the even and odd groups
              should remain as it was in the input.
              <br />
              You must solve the problem in O(1) extra space complexity and O(n)
              time complexity.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: head = [1,2,3,4,5] <br />
              Output: [1,3,5,2,4]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: head = [2,1,3,5,6,4,7] <br />
              Output: [2,3,6,7,1,5,4]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              n == number of nodes in the linked list
              <br />
              0 &lt;= n &lt;= 104
              <br />
              -106 &lt;= Node.val &lt;= 106
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q328. Odd Even Linked List (Q276)",
                codes: {
                  Javascript: {
                    code: `function ListNode(val, next) {
                      this.val = (val===undefined ? 0 : val)
                      this.next = (next===undefined ? null : next)
                    }
                     
                    /**
                     * @param {ListNode} head
                     * @return {ListNode}
                     */
                    var oddEvenList = function(head) {
                      let oddHead = new ListNode(0);
                      let evenHead = new ListNode(0);
                      let odd = oddHead;
                      let even = evenHead;
                      for (let isOdd = 0; head; head = head.next)
                        if (isOdd ^= 1) {
                          odd.next = head;
                          odd = odd.next;
                        } else {
                          even.next = head;
                          even = even.next;
                        }
                      odd.next = evenHead.next;
                      even.next = null;
                      return oddHead.next;
                    };
                    
                    const l = new ListNode(1)
                    l.next = new ListNode(2)
                    l.next.next = new ListNode(3)
                    l.next.next.next = new ListNode(4)
                    l.next.next.next.next = new ListNode(5)
                    oddEvenList(l)`,
                    output: `ListNode {
                      val: 1,
                      next: ListNode { val: 3, next: ListNode { val: 5, next: [ListNode] } }
                    }`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q277: {
        title: "Q329. Longest Increasing Path in a Matrix (Q277)",
        content: (
          <>
            <Span>
              <b>Q329. Longest Increasing Path in a Matrix (Q276)</b>
            </Span>
            <Span>
              Given an m x n integers matrix, return the length of the longest
              increasing path in matrix.
              <br />
              From each cell, you can either move in four directions: left,
              right, up, or down. You may not move diagonally or move outside
              the boundary (i.e., wrap-around is not allowed).
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode329} left />
            <Span>
              Input: matrix = [[9,9,4],[6,6,8],[2,1,1]] <br />
              Output: 4 <br />
              Explanation: The longest increasing path is [1, 2, 6, 9].
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: matrix = [[3,4,5],[3,2,6],[2,2,1]]
              <br />
              Output: 4<br />
              Explanation: The longest increasing path is [3, 4, 5, 6]. Moving
              diagonally is not allowed.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: matrix = [[1]] <br />
              Output: 1
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              m == matrix.length <br />
              n == matrix[i].length <br />
              1 &lt;= m, n &lt;= 200 <br />0 &lt;= matrix[i][j] &lt;= 231 - 1
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(mn)
              <br />
              Space: O(mn)
            </p>
            <CodeEditor
              options={{
                title: "Q329. Longest Increasing Path in a Matrix (Q277)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[][]} matrix
                    * @return {number}
                    */
                   function dfs(matrix,i,j,prev,memo){
                     if (i < 0 || i == matrix.length || j < 0 || j == matrix[0].length)
                       return 0;
                     if (matrix[i][j] <= prev)
                       return 0;
                     if (memo[i][j] > 0)
                       return memo[i][j];
                     const curr = matrix[i][j];
                     const a = dfs(matrix, i + 1, j, curr, memo);
                     const b = dfs(matrix, i - 1, j, curr, memo);
                     const c = dfs(matrix, i, j + 1, curr, memo);
                     const d = dfs(matrix, i, j - 1, curr, memo);
                     return memo[i][j] = 1 + Math.max(Math.max(a, b), Math.max(c, d));
                   }
                   
                   var longestIncreasingPath = function(matrix) {
                     const m = matrix.length;
                     const n = matrix[0].length;
                     let ans = 0;
                     const memo = Array.from(Array(m),()=>Array(n).fill(0))
                     for (let i = 0; i < m; ++i)
                       for (let j = 0; j < n; ++j)
                         ans = Math.max(ans, dfs(matrix, i, j, Number.MIN_SAFE_INTEGER, memo));
                     return ans;
                   };
                   
                   longestIncreasingPath([[9,9,4],[6,6,8],[2,1,1]])`,
                    output: `4`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q278: {
        title: "Q330. Patching Array (Q278)",
        content: (
          <>
            <Span>
              <b>Q330. Patching Array (Q278)</b>
            </Span>
            <Span>
              Given a sorted integer array nums and an integer n, add/patch
              elements to the array such that any number in the range [1, n]
              inclusive can be formed by the sum of some elements in the array.
              <br />
              Return the minimum number of patches required.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,3], n = 6 <br />
              Output: 1 <br />
              Explanation: <br />
              Combinations of nums are [1], [3], [1,3], which form possible sums
              of: 1, 3, 4. <br />
              Now if we add/patch 2 to nums, the combinations are: [1], [2],
              [3], [1,3], [2,3], [1,2,3]. <br />
              Possible sums are 1, 2, 3, 4, 5, 6, which now covers the range [1,
              6]. <br />
              So we only need 1 patch.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1,5,10], n = 20
              <br />
              Output: 2<br />
              Explanation: The two patches can be [2, 4].
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [1,2,2], n = 5<br />
              Output: 0
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 1000 <br />
              1 &lt;= nums[i] &lt;= 10^4 <br />
              nums is sorted in ascending order. <br />1 &lt;= n &lt;= 2^31 - 1
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q330. Patching Array (Q278)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @param {number} n
                    * @return {number}
                    */
                   var minPatches = function(nums, n) {
                     let ans = 0;
                     let i = 0;   
                     let miss = 1; 
                     while (miss <= n)
                     if (i < nums.length && nums[i] <= miss) {
                       miss += nums[i++];
                     } else {
                       miss += miss;
                       ++ans;
                     }
                     return ans; 
                   };
                   
                   minPatches([1,3],6)`,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q279: {
        title: "Q331. Verify Preorder Serialization of a Binary Tree (Q279)",
        content: (
          <>
            <Span>
              <b>Q331. Verify Preorder Serialization of a Binary Tree (Q279)</b>
            </Span>
            <Span>
              One way to serialize a binary tree is to use preorder traversal.
              When we encounter a non-null node, we record the node's value. If
              it is a null node, we record using a sentinel value such as '#'.
            </Span>
            <Img src={Leetcode331} left />
            <Span>
              For example, the above binary tree can be serialized to the string
              "9,3,4,#,#,1,#,#,2,#,6,#,#", where '#' represents a null node.
              <br />
              Given a string of comma-separated values preorder, return true if
              it is a correct preorder traversal serialization of a binary tree.
              <br />
              It is guaranteed that each comma-separated value in the string
              must be either an integer or a character '#' representing null
              pointer.
              <br />
              You may assume that the input format is always valid.
              <br />
              For example, it could never contain two consecutive commas, such
              as "1,,3".
              <br />
              Note: You are not allowed to reconstruct the tree.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: preorder = "9,3,4,#,#,1,#,#,2,#,6,#,#"
              <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: preorder = "1,#"
              <br />
              Output: false
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: preorder = "9,#,#,1"
              <br />
              Output: false
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= preorder.length &lt;= 104 <br />
              preorder consist of integers in the range [0, 100] and '#'
              separated by commas ','.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title:
                  "Q331. Verify Preorder Serialization of a Binary Tree (Q279)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} preorder
                    * @return {boolean}
                    */
                   var isValidSerialization = function(preorder) {
                     let degree = 1; 
                     for (let node of preorder.split(",")) {
                       if (--degree < 0) return false;
                       if (node !== "#") degree += 2; 
                     }
                     return degree == 0;
                   };
                   
                   isValidSerialization("9,3,4,#,#,1,#,#,2,#,6,#,#")`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q280: {
        title: "Q332. Reconstruct Itinerary (Q280)",
        content: (
          <>
            <Span>
              <b>Q332. Reconstruct Itinerary (Q280)</b>
            </Span>
            <Span>
              You are given a list of airline tickets where tickets[i] = [fromi,
              toi] represent the departure and the arrival airports of one
              flight. Reconstruct the itinerary in order and return it.
              <br />
              All of the tickets belong to a man who departs from "JFK", thus,
              the itinerary must begin with "JFK". If there are multiple valid
              itineraries, you should return the itinerary that has the smallest
              lexical order when read as a single string.
              <br />
              For example, the itinerary ["JFK", "LGA"] has a smaller lexical
              order than ["JFK", "LGB"].
              <br />
              You may assume all tickets form at least one valid itinerary. You
              must use all the tickets once and only once.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode332} left />
            <Span>
              Input: tickets =
              [["MUC","LHR"],["JFK","MUC"],["SFO","SJC"],["LHR","SFO"]] <br />
              Output: ["JFK","MUC","LHR","SFO","SJC"]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: tickets =
              [["JFK","SFO"],["JFK","ATL"],["SFO","ATL"],["ATL","JFK"],["ATL","SFO"]]{" "}
              <br />
              Output: ["JFK","ATL","JFK","SFO","ATL","SFO"] <br />
              Explanation: Another possible reconstruction is
              ["JFK","SFO","ATL","JFK","ATL","SFO"] but it is larger in lexical
              order.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= tickets.length &lt;= 300
              <br />
              tickets[i].length == 2<br />
              fromi.length == 3<br />
              toi.length == 3<br />
              fromi and toi consist of uppercase English letters.
              <br />
              fromi != toi
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(|E| log |E|)
              <br />
              Space: O(|E|)
            </p>
            <CodeEditor
              options={{
                title: "Q332. Reconstruct Itinerary (Q280)",
                codes: {
                  Java: {
                    code: `
                    // [["MUC","LHR"],["JFK","MUC"],["SFO","SJC"],["LHR","SFO"]]
                    class Solution {
                      public List<String> findItinerary(List<List<String>> tickets) {
                        LinkedList<String> ans = new LinkedList<>();
                        Map<String, PriorityQueue<String>> graph = new HashMap<>();
                    
                        for (final List<String> ticket : tickets) {
                          graph.putIfAbsent(ticket.get(0), new PriorityQueue<>());
                          graph.get(ticket.get(0)).offer(ticket.get(1));
                        }
                    
                        dfs(graph, "JFK", ans);
                    
                        return ans;
                      }
                    
                      private void dfs(Map<String, PriorityQueue<String>> graph, final String u,
                                       LinkedList<String> ans) {
                        final PriorityQueue<String> arrivals = graph.get(u);
                        while (arrivals != null && !arrivals.isEmpty())
                          dfs(graph, arrivals.poll(), ans);
                        ans.addFirst(u);
                      }
                    }
                    `,
                    output: `["JFK","MUC","LHR","SFO","SJC"]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q281: {
        title: "Q334. Increasing Triplet Subsequence (Q281)",
        content: (
          <>
            <Span>
              <b>Q334. Increasing Triplet Subsequence (Q281)</b>
            </Span>
            <Span>
              Given an integer array nums, return true if there exists a triple
              of indices (i, j, k) such that i &lt; j &lt; k and nums[i] &lt;
              nums[j] &lt; nums[k]. If no such indices exists, return false.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,2,3,4,5] <br />
              Output: true <br />
              Explanation: Any triplet where i &lt; j &lt; k is valid.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [5,4,3,2,1]
              <br />
              Output: false
              <br />
              Explanation: No triplet exists.
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: nums = [2,1,5,0,4,6] <br />
              Output: true <br />
              Explanation: The triplet (3, 4, 5) is valid because nums[3] == 0
              &lt; nums[4] == 4 &lt; nums[5] == 6.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 5 * 10^5 <br />
              -2^31 &lt;= nums[i] &lt;= 2^31 - 1
            </Span>{" "}
            <Span>
              Follow up: Could you implement a solution that runs in O(n) time
              complexity and O(1) space complexity?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q334. Increasing Triplet Subsequence (Q281)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums
                    * @return {boolean}
                    */
                   var increasingTriplet = function(nums) {
                     let first = Number.MAX_SAFE_INTEGER;
                     let second = Number.MAX_SAFE_INTEGER;
                     for (let num of nums)
                       if (num <= first)
                         first = num;
                       else if (num <= second) 
                         second = num;
                       else 
                         return true;
                     return false;
                   };
                   
                   increasingTriplet([1,2,3,4,5])`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q282: {
        title: "Q335. Self Crossing (Q282)",
        content: (
          <>
            <Span>
              <b>Q335. Self Crossing (Q282)</b>
            </Span>
            <Span>
              You are given an array of integers distance.
              <br />
              You start at point (0,0) on an X-Y plane and you move distance[0]
              meters to the north, then distance[1] meters to the west,
              distance[2] meters to the south, distance[3] meters to the east,
              and so on. In other words, after each move, your direction changes
              counter-clockwise.
              <br />
              Return true if your path crosses itself, and false if it does not.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode335} left />
            <Span>
              Input: distance = [2,1,1,2] <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: distance = [1,2,3,4] <br />
              Output: false
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: distance = [1,1,1,1]
              <br />
              Output: true
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= distance.length &lt;= 105 <br />1 &lt;= distance[i] &lt;=
              10^5
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q335. Self Crossing (Q282)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} distance
                    * @return {boolean}
                    */
                   var isSelfCrossing = function(distance) {
                         if (distance.length <= 3)
                         return false;
                   
                       for (let i = 3; i < distance.length; ++i) {
                         if (distance[i - 2] <= distance[i] && distance[i - 1] <= distance[i - 3])
                           return true;
                         if (i >= 4 && distance[i - 1] == distance[i - 3] && distance[i - 2] <= distance[i] + distance[i - 4])
                           return true;
                         if (i >= 5 && distance[i - 4] <= distance[i - 2] && distance[i - 2] <= distance[i] + distance[i - 4] && distance[i - 1] <= distance[i - 3] &&
                             distance[i - 3] <= distance[i - 1] + distance[i - 5])
                           return true;
                       }
                   
                       return false;
                   };
                   
                   isSelfCrossing([1,2,3,4])`,
                    output: `false`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q283: {
        title: "Q336. Palindrome Pairs (Q283)",
        content: (
          <>
            <Span>
              <b>Q336. Palindrome Pairs (Q283)</b>
            </Span>
            <Span>
              Given a list of unique words, return all the pairs of the distinct
              indices (i, j) in the given list, so that the concatenation of the
              two words words[i] + words[j] is a palindrome.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: words = ["abcd","dcba","lls","s","sssll"]
              <br />
              Output: [[0,1],[1,0],[3,2],[2,4]]
              <br />
              Explanation: The palindromes are
              ["dcbaabcd","abcddcba","slls","llssssll"]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: words = ["bat","tab","cat"] <br />
              Output: [[0,1],[1,0]] <br />
              Explanation: The palindromes are ["battab","tabbat"]
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: words = ["a",""] <br />
              Output: [[0,1],[1,0]]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= words.length &lt;= 5000 <br />
              0 &lt;= words[i].length &lt;= 300 <br />
              words[i] consists of lower-case English letters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.k^2)
              <br />
              Space: O(nk)
            </p>
            <CodeEditor
              options={{
                title: "Q336. Palindrome Pairs (Q283)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string[]} words
                    * @return {number[][]}
                    */
                   
                   function isPalindrome(word) {
                     let l = 0;
                     let r = word.length - 1;
                       while (l < r)
                         if (word[l++] != word[r--])
                           return false;
                       return true;
                     }
                   
                   var palindromePairs = function(words) {
                     const ans = [];
                     const map = new Map(); 
                     for (let i = 0; i < words.length; ++i)
                       map.set(words[i].split("").reverse().join(""), i);
                     for (let i = 0; i < words.length; ++i) {
                       const word = words[i];
                       if (map.has("") && map.get("") != i && isPalindrome(word))
                         ans.push([i, map.get("")]);
                       for (let j = 1; j <= word.length; ++j) {
                         const l = word.substring(0, j);
                         const r = word.substring(j);
                         if (map.has(l) && map.get(l) != i && isPalindrome(r))
                           ans.push([i, map.get(l)]);
                         if (map.has(r) && map.get(r) != i && isPalindrome(l))
                           ans.push([map.get(r), i]);
                       }
                     }
                     return ans;
                   };
                   
                   palindromePairs(["bat","tab","cat"])`,
                    output: `[ [ 0, 1 ], [ 1, 0 ] ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q284: {
        title: "Q337. House Robber III (Q284)",
        content: (
          <>
            <Span>
              <b>Q337. House Robber III (Q284)</b>
            </Span>
            <Span>
              The thief has found himself a new place for his thievery again.
              There is only one entrance to this area, called root.
              <br />
              Besides the root, each house has one and only one parent house.
              After a tour, the smart thief realized that all houses in this
              place form a binary tree. It will automatically contact the police
              if two directly-linked houses were broken into on the same night.
              <br />
              Given the root of the binary tree, return the maximum amount of
              money the thief can rob without alerting the police.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Img src={Leetcode337} left />
            <Span>
              Input: root = [3,2,3,null,3,null,1] <br />
              Output: 7 <br />
              Explanation: Maximum amount of money the thief can rob = 3 + 3 + 1
              = 7.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: root = [3,4,5,1,3,null,1]
              <br />
              Output: 9<br />
              Explanation: Maximum amount of money the thief can rob = 4 + 5 =
              9.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              The number of nodes in the tree is in the range [1, 104]. <br />0
              &lt;= Node.val &lt;= 104
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q337. House Robber III (Q284)",
                codes: {
                  Javascript: {
                    code: `function TreeNode(val, left, right) {
                      this.val = (val===undefined ? 0 : val)
                      this.left = (left===undefined ? null : left)
                      this.right = (right===undefined ? null : right)
                  }
                  
                  
                  class T {
                    constructor(robRoot,notRobRoot) {
                      this.robRoot = robRoot;
                      this.notRobRoot = notRobRoot;
                    }
                  }
                  
                  function robOrNotRob(root){
                    if(!root) return new T(0, 0);
                    const l = robOrNotRob(root.left);
                    const r = robOrNotRob(root.right);
                    return new T(root.val + l.notRobRoot + r.notRobRoot,
                                   Math.max(l.robRoot, l.notRobRoot) + Math.max(r.robRoot, r.notRobRoot));
                  }
                  
                  /**
                   * @param {TreeNode} root
                   * @return {number}
                   */
                  var rob = function(root) {
                    const t = robOrNotRob(root);
                    return Math.max(t.robRoot, t.notRobRoot);
                  };
                  
                  const t = new TreeNode(3)
                  t.left = new TreeNode(2)
                  t.left.right = new TreeNode(3)
                  t.right = new TreeNode(3)
                  t.right.right = new TreeNode(1)
                  rob(t)`,
                    output: `7`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q285: {
        title: "Q338. Counting Bits (Q285)",
        content: (
          <>
            <Span>
              <b>Q338. Counting Bits (Q285)</b>
            </Span>
            <Span>
              Given an integer n, return an array ans of length n + 1 such that
              for each i (0 &lt;= i &lt;= n), ans[i] is the number of 1's in the
              binary representation of i.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 2 <br />
              Output: [0,1,1] <br />
              Explanation:
              <br />
              0 --&gt; 0<br />
              1 --&gt; 1<br />2 --&gt; 10
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 5<br />
              Output: [0,1,1,2,1,2]
              <br />
              Explanation:
              <br />
              0 --&gt; 0<br />
              1 --&gt; 1<br />
              2 --&gt; 10
              <br />
              3 --&gt; 11
              <br />
              4 --&gt; 100
              <br />5 --&gt; 101
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>0 &lt;= n &lt;= 105</Span>{" "}
            <Span>
              It is very easy to come up with a solution with a runtime of O(n
              log n). Can you do it in linear time O(n) and possibly in a single
              pass? Can you do it without using any built-in function (i.e.,
              like __builtin_popcount in C++)?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q338. Counting Bits (Q285)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number[]}
                    */
                   var countBits = function(n) {
                     const ans = Array(n + 1).fill(0);
                   
                     for (let i = 1; i <= n; ++i)
                       ans[i] = ans[parseInt(i / 2)] + (i % 2 == 0 ? 0 : 1);
                   
                     return ans;
                   };
                   
                   countBits(2)`,
                    output: `[ 0, 1, 1 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q286: {
        title: "Q341. Flatten Nested List Iterator (Q286)",
        content: (
          <>
            <Span>
              <b>Q341. Flatten Nested List Iterator (Q286)</b>
            </Span>
            <Span>
              You are given a nested list of integers nestedList. Each element
              is either an integer or a list whose elements may also be integers
              or other lists. Implement an iterator to flatten it.
              <br />
              Implement the NestedIterator class:
              <br />
              NestedIterator(List{`<NestedInteger>`} nestedList) Initializes the
              iterator with the nested list nestedList.
              <br />
              int next() Returns the next integer in the nested list.
              <br />
              boolean hasNext() Returns true if there are still some integers in
              the nested list and false otherwise.
              <br />
              Your code will be tested with the following pseudocode:
              <br />
              initialize iterator with nestedList
              <br />
              res = []
              <br />
              while iterator.hasNext()
              <br />
              append iterator.next() to the end of res
              <br />
              return res
              <br />
              If res matches the expected flattened list, then your code will be
              judged as correct.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nestedList = [[1,1],2,[1,1]]
              <br />
              Output: [1,1,2,1,1]
              <br />
              Explanation: By calling next repeatedly until hasNext returns
              false, the order of elements returned by next should be:
              [1,1,2,1,1].
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nestedList = [1,[4,[6]]] <br />
              Output: [1,4,6] <br />
              Explanation: By calling next repeatedly until hasNext returns
              false, the order of elements returned by next should be: [1,4,6].
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nestedList.length &lt;= 500 <br />
              The values of the integers in the nested list is in the range
              [-106, 106].
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q341. Flatten Nested List Iterator (Q286)",
                codes: {
                  Javascript: {
                    code: `public class NestedIterator implements Iterator<Integer> {
                      public NestedIterator(List<NestedInteger> nestedList) {
                        addInteger(nestedList);
                      }
                    
                      @Override
                      public Integer next() {
                        return stack.pop().getInteger();
                      }
                    
                      @Override
                      public boolean hasNext() {
                        while (!stack.isEmpty() && !stack.peek().isInteger()) {
                          final NestedInteger ni = stack.pop();
                          addInteger(ni.getList());
                        }
                        return !stack.isEmpty();
                      }
                    
                      private Stack<NestedInteger> stack = new Stack<>();
                    
                      private void addInteger(final List<NestedInteger> nestedList) {
                        for (int i = nestedList.size() - 1; i >= 0; --i)
                          stack.push(nestedList.get(i));
                      }
                    }
                    `,
                    output: `[1,1,2,1,1]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q287: {
        title: "Q342. Power of Four (Q287)",
        content: (
          <>
            <Span>
              <b>Q342. Power of Four (Q287)</b>
            </Span>
            <Span>
              Given an integer n, return true if it is a power of four.
              Otherwise, return false.
              <br />
              An integer n is a power of four, if there exists an integer x such
              that n == 4^x.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 16 <br />
              Output: true
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 5<br />
              Output: false
            </Span>
            <Span>
              <b>Example 3:</b>
            </Span>
            <Span>
              Input: n = 1 <br />
              Output: true
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>-2^31 &lt;= n &lt;= 2^31 - 1</Span>
            <Span>Follow up: Could you solve it without loops/recursion?</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(1)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q342. Power of Four (Q287)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {boolean}
                    */
                   
                   function countBit1(n){
                   return (n).toString(2).split('').filter(x=>+x===1).length
                   }
                   
                   var isPowerOfFour = function(n) {
                     return n > 0 && countBit1(n) == 1 && (n - 1) % 3 == 0;
                   };
                   
                   isPowerOfFour(4)
                   // 0100`,
                    output: `true`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q288: {
        title: "Q343. Integer Break (Q288)",
        content: (
          <>
            <Span>
              <b>Q343. Integer Break (Q288)</b>
            </Span>
            <Span>
              Given an integer n, break it into the sum of k positive integers,
              where k &gt;= 2, and maximize the product of those integers.
              <br />
              Return the maximum product you can get.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: n = 2 <br />
              Output: 1 <br />
              Explanation: 2 = 1 + 1, 1 × 1 = 1.
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: n = 10
              <br />
              Output: 36
              <br />
              Explanation: 10 = 3 + 3 + 4, 3 × 3 × 4 = 36.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>2 &lt;= n &lt;= 58</Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n/3)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q343. Integer Break (Q288)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number} n
                    * @return {number}
                    */
                   var integerBreak = function(n) {
                     if (n == 2)
                       return 1; 
                     if (n == 3)
                       return 2; 
                     let ans = 1;
                     while (n > 4) {
                       n -= 3;
                       ans *= 3;
                     }
                     ans *= n;
                     return ans;    
                   };
                   
                   integerBreak(2)`,
                    output: `1`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q289: {
        title: "Q344. Reverse String (Q289)",
        content: (
          <>
            <Span>
              <b>Q344. Reverse String (Q288)</b>
            </Span>
            <Span>
              Write a function that reverses a string. The input string is given
              as an array of characters s.
              <br />
              You must do this by modifying the input array in-place with O(1)
              extra memory.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = ["h","e","l","l","o"]
              <br />
              Output: ["o","l","l","e","h"]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = ["H","a","n","n","a","h"]
              <br />
              Output: ["h","a","n","n","a","H"]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 105 <br />
              s[i] is a printable ascii character.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q344. Reverse String (Q289)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {character[]} s
                    * @return {void} Do not return anything, modify s in-place instead.
                    */
                   var reverseString = function(s) {
                     let l = 0;
                     let r = s.length - 1;
                     while (l < r) {
                     const temp = s[l];
                     s[l++] = s[r];
                     s[r--] = temp;
                     } 
                     console.log(s)
                   };
                   
                   reverseString(["h","e","l","l","o"])`,
                    output: `[ 'o', 'l', 'l', 'e', 'h' ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q290: {
        title: "Q345. Reverse Vowels of a String (Q290)",
        content: (
          <>
            <Span>
              <b>Q345. Reverse Vowels of a String (Q290)</b>
            </Span>
            <Span>
              Given a string s, reverse only all the vowels in the string and
              return it.
              <br />
              The vowels are 'a', 'e', 'i', 'o', and 'u', and they can appear in
              both cases.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: s = "hello"
              <br />
              Output: "holle"
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: s = "leetcode" <br />
              Output: "leotcede"
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= s.length &lt;= 3 * 10^5 <br />s consist of printable ASCII
              characters.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(1)
            </p>
            <CodeEditor
              options={{
                title: "Q345. Reverse Vowels of a String (Q290)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {string} s
                    * @return {string}
                    */
                   var reverseVowels = function(s) {
                     const chars = s.split("")
                     const vowels = "aeiouAEIOU";
                     const st = vowels.split("");
                     let l = 0;
                     let r = s.length - 1;
                     while (l < r) {
                       while (l < r && !st.includes(chars[l]))
                         ++l;
                       while (l < r && !st.includes(chars[r]))
                         --r;
                     [ chars[l], chars[r] ] = [ chars[r], chars[l] ];
                     l++
                     r--
                     }
                     return chars.join("")
                   };
                   
                   reverseVowels("hello");`,
                    output: `holle`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q291: {
        title: "Q347. Top K Frequent Elements (Q291)",
        content: (
          <>
            <Span>
              <b>Q347. Top K Frequent Elements (Q291)</b>
            </Span>
            <Span>
              Given an integer array nums and an integer k, return the k most
              frequent elements. You may return the answer in any order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums = [1,1,1,2,2,3], k = 2<br />
              Output: [1,2]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums = [1], k = 1<br />
              Output: [1]
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums.length &lt;= 10^5 <br />k is in the range [1, the
              number of unique elements in the array]. <br />
              It is guaranteed that the answer is unique.
            </Span>{" "}
            <Span>
              Follow up: Your algorithm's time complexity must be better than
              O(n log n), where n is the array's size.
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n.log k)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q347. Top K Frequent Elements (Q291)",
                codes: {
                  Java: {
                    code: `
                    // [1,1,1,2,2,3]
// 2
                    class T {
                      public int num;
                      public int freq;
                      public T(int num, int freq) {
                        this.num = num;
                        this.freq = freq;
                      }
                    }
                    
                    class Solution {
                      public int[] topKFrequent(int[] nums, int k) {
                        final int n = nums.length;
                    
                        int[] ans = new int[k];
                        Map<Integer, Integer> count = new HashMap<>();
                        PriorityQueue<T> pq = new PriorityQueue<>((a, b) -> a.freq - b.freq);
                    
                        for (final int num : nums)
                          count.put(num, count.getOrDefault(num, 0) + 1);
                    
                        for (Map.Entry<Integer, Integer> entry : count.entrySet()) {
                          final int num = entry.getKey();
                          final int freq = entry.getValue();
                          pq.offer(new T(num, freq));
                          if (pq.size() > k)
                            pq.poll();
                        }
                    
                        for (int i = 0; i < k; ++i)
                          ans[i] = pq.poll().num;
                    
                        return ans;
                      }
                    }
                    `,
                    output: `[2,1]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q292: {
        title: "Q349. Intersection of Two Arrays (Q292)",
        content: (
          <>
            <Span>
              <b>Q349. Intersection of Two Arrays (Q292)</b>
            </Span>
            <Span>
              Given two integer arrays nums1 and nums2, return an array of their
              intersection. Each element in the result must be unique and you
              may return the result in any order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums1 = [1,2,2,1], nums2 = [2,2] <br />
              Output: [2]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums1 = [4,9,5], nums2 = [9,4,9,8,4] <br />
              Output: [9,4] <br />
              Explanation: [4,9] is also accepted.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums1.length, nums2.length &lt;= 1000
              <br />0 &lt;= nums1[i], nums2[i] &lt;= 1000
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(n)
              <br />
              Space: O(n)
            </p>
            <CodeEditor
              options={{
                title: "Q349. Intersection of Two Arrays (Q292)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums1
                    * @param {number[]} nums2
                    * @return {number[]}
                    */
                   var intersection = function(nums1, nums2) {
                     const ans = []
                     nums1 = new Set(nums1)
                   
                     for(let num of nums2){
                       if(nums1.has(num))
                       ans.push(num)
                       nums1.delete(num)
                     }
                     return ans
                   };
                   
                   intersection([1,2,2,1],[2,2])`,
                    output: `[ 2 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q293: {
        title: "Q350. Intersection of Two Arrays II (Q293)",
        content: (
          <>
            <Span>
              <b>Q350. Intersection of Two Arrays II (Q293)</b>
            </Span>
            <Span>
              Given two integer arrays nums1 and nums2, return an array of their
              intersection. Each element in the result must appear as many times
              as it shows in both arrays and you may return the result in any
              order.
            </Span>
            <Span>
              <b>Example 1:</b>
            </Span>
            <Span>
              Input: nums1 = [1,2,2,1], nums2 = [2,2] <br />
              Output: [2,2]
            </Span>
            <Span>
              <b>Example 2:</b>
            </Span>
            <Span>
              Input: nums1 = [4,9,5], nums2 = [9,4,9,8,4]
              <br />
              Output: [4,9]
              <br />
              Explanation: [9,4] is also accepted.
            </Span>
            <Span>
              <b>Constraints:</b>
            </Span>
            <Span>
              1 &lt;= nums1.length, <br />
              nums2.length &lt;= 1000 <br />0 &lt;= nums1[i], nums2[i] &lt;=
              1000
            </Span>
            <Span>
              Follow up:
              <br />
              What if the given array is already sorted? How would you optimize
              your algorithm?
              <br />
              What if nums1's size is small compared to nums2's size? Which
              algorithm is better?
              <br />
              What if elements of nums2 are stored on disk, and the memory is
              limited such that you cannot load all elements into the memory at
              once?
            </Span>
            <Span>
              <b>Complexity:</b>
            </Span>
            <p>
              Time: O(m.log n + n.log m)
              <br />
              Space: O(min(m , n))
            </p>
            <CodeEditor
              options={{
                title: "Q350. Intersection of Two Arrays II (Q293)",
                codes: {
                  Javascript: {
                    code: `/**
                    * @param {number[]} nums1
                    * @param {number[]} nums2
                    * @return {number[]}
                    */
                   var intersect = function(nums1, nums2) {
                     nums1.sort((a,b)=>a-b)
                     nums2.sort((a,b)=>a-b)
                     const ans= []; 
                     let i = 0; 
                     let j = 0;  
                     while (i < nums1.length && j < nums2.length)
                       if (nums1[i] < nums2[j]) {
                         ++i;
                       } else if (nums1[i] > nums2[j]) {
                         ++j;
                       } else {
                         ans.push(nums1[i]);
                         ++i;
                         ++j;
                       }
                     return ans;
                   };
                   
                   intersect([1,2,2,1],[2,2])`,
                    output: `[ 2, 2 ]`,
                  },
                },
              }}
            />
          </>
        ),
      },
      q294: {
        title: "Q352. Data Stream as Disjoint Intervals (Q294)",
        content: (
          <Comp
            title="Q352. Data Stream as Disjoint Intervals (Q294)"
            content1={
              <>
                Given a data stream input of non-negative integers a1, a2, ...,
                an, summarize the numbers seen so far as a list of disjoint
                intervals.
                <br />
                Implement the SummaryRanges class:
                <br />
                <br />
                SummaryRanges() Initializes the object with an empty stream.
                <br />
                void addNum(int val) Adds the integer val to the stream.
                <br />
                int[][] getIntervals() Returns a summary of the integers in the
                stream currently as a list of disjoint intervals [starti, endi].
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    <br /> ["SummaryRanges", "addNum", "getIntervals", "addNum",
                    "getIntervals", "addNum", "getIntervals", "addNum",
                    "getIntervals", "addNum", "getIntervals"]
                    <br />
                    [[], [1], [], [3], [], [7], [], [2], [], [6], []]
                    <br />
                    Output
                    <br />
                    [null, null, [[1, 1]], null, [[1, 1], [3, 3]], null, [[1,
                    1], [3, 3], [7, 7]], null, [[1, 3], [7, 7]], null, [[1, 3],
                    [6, 7]]]
                    <br />
                    Explanation
                    <br />
                    SummaryRanges summaryRanges = new SummaryRanges();
                    <br />
                    summaryRanges.addNum(1); // arr = [1]
                    <br />
                    summaryRanges.getIntervals(); // return [[1, 1]]
                    <br />
                    summaryRanges.addNum(3); // arr = [1, 3]
                    <br />
                    summaryRanges.getIntervals(); // return [[1, 1], [3, 3]]
                    <br />
                    summaryRanges.addNum(7); // arr = [1, 3, 7]
                    <br />
                    summaryRanges.getIntervals(); // return [[1, 1], [3, 3], [7,
                    7]]
                    <br />
                    summaryRanges.addNum(2); // arr = [1, 2, 3, 7]
                    <br />
                    summaryRanges.getIntervals(); // return [[1, 3], [7, 7]]
                    <br />
                    summaryRanges.addNum(6); // arr = [1, 2, 3, 6, 7]
                    summaryRanges.getIntervals(); // return [[1, 3], [6, 7]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= val &lt;= 10^4
                <br />
                At most 3 * 10^4 calls will be made to addNum and getIntervals.
              </>
            }
            fp={
              <>
                <b>Follow up:</b> What if there are lots of merges and the
                number of disjoint intervals is small compared to the size of
                the data stream?
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `class SummaryRanges {
              public void addNum(int val) {
                if (map.containsKey(val))
                  return;
                final Integer lo = map.lowerKey(val);  
                final Integer hi = map.higherKey(val);
                if (lo != null && hi != null && map.get(lo)[1] + 1 == val && val + 1 == hi) {
                  map.get(lo)[1] = map.get(hi)[1];
                  map.remove(hi);
                } else if (lo != null && map.get(lo)[1] + 1 >= val) {
                  map.get(lo)[1] = Math.max(map.get(lo)[1], val);
                } else if (hi != null && val + 1 == hi) {
                  map.put(val, new int[] {val, map.get(hi)[1]});
                  map.remove(hi);
                } else {
                  map.put(val, new int[] {val, val});
                }
              }
              public int[][] getIntervals() {
                List<int[]> intervals = new ArrayList<>(map.values());
                return intervals.toArray(new int[intervals.size()][]);
              }
              private TreeMap<Integer, int[]> map = new TreeMap<>();
            }
            `,
                output: `[null,null,[[1,1]],null,[[1,1],[3,3]],null,[[1,1],[3,3],[7,7]],null,[[1,3],[7,7]],null,[[1,3],[6,7]]]`,
              },
            }}
          />
        ),
      },
      q295: {
        title: "Q354. Russian Doll Envelopes (Q295)",
        content: (
          <Comp
            title="Q354. Russian Doll Envelopes (Q295)"
            content1={
              <>
                You are given a 2D array of integers envelopes where
                envelopes[i] = [wi, hi] represents the width and the height of
                an envelope.
                <br />
                One envelope can fit into another if and only if both the width
                and height of one envelope are greater than the other envelope's
                width and height.
                <br />
                Return the maximum number of envelopes you can Russian doll
                (i.e., put one inside the other).
                <br />
                Note: You cannot rotate an envelope.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: envelopes = [[5,4],[6,4],[6,7],[2,3]] <br />
                    Output: 3 <br />
                    Explanation: The maximum number of envelopes you can Russian
                    doll is 3 ([2,3] =&gt; [5,4] =&gt; [6,7]).
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: envelopes = [[1,1],[1,1],[1,1]]
                    <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= envelopes.length &lt;= 5000 <br />
                envelopes[i].length == 2 <br />1 &lt;= wi, hi &lt;= 10^4
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
            * @param {number[][]} envelopes
            * @return {number}
            */
           var maxEnvelopes = function(envelopes) {
             envelopes.sort((a, b) => a[0] == b[0] ? b[1] - a[1] : a[0] - b[0]);
             let ans = 0;
             const dp = Array(envelopes.length);
           
             for (let e of envelopes) {
               let l =0
               let r = ans
               while(l<r){
                 let m = parseInt((l+r)/2)
                 if(dp[m]>=e[1]){
                   r = m
                 }else{
                   l = m + 1
                 }
               }
               dp[l] = e[1]
               if(l===ans) ans+=1
             }
           
             return ans; 
           };
           
           maxEnvelopes([[5,4],[6,4],[6,7],[2,3]])`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q296: {
        title: "Q355. Design Twitter (Q296)",
        content: (
          <Comp
            title="Q355. Design Twitter (Q296)"
            content1={
              <>
                Design a simplified version of Twitter where users can post
                tweets, follow/unfollow another user, and is able to see the 10
                most recent tweets in the user's news feed.
                <br />
                Implement the Twitter class:
                <br />
                <br />
                Twitter() Initializes your twitter object.
                <br />
                void postTweet(int userId, int tweetId) Composes a new tweet
                with ID tweetId by the user userId. Each call to this function
                will be made with a unique tweetId.
                <br />
                List{"<Integer>"} getNewsFeed(int userId) Retrieves the 10 most
                recent tweet IDs in the user's news feed. Each item in the news
                feed must be posted by users who the user followed or by the
                user themself. Tweets must be ordered from most recent to least
                recent.
                <br />
                void follow(int followerId, int followeeId) The user with ID
                followerId started following the user with ID followeeId.
                <br />
                void unfollow(int followerId, int followeeId) The user with ID
                followerId started unfollowing the user with ID followeeId.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br /> ["Twitter", "postTweet", "getNewsFeed", "follow",
                    "postTweet", "getNewsFeed", "unfollow", "getNewsFeed"]
                    <br />
                    [[], [1, 5], [1], [1, 2], [2, 6], [1], [1, 2], [1]]
                    <br /> Output [null, null, [5], null, null, [6, 5], null,
                    [5]]
                    <br />
                    <br /> Explanation
                    <br />
                    Twitter twitter = new Twitter();
                    <br />
                    twitter.postTweet(1, 5); // User 1 posts a new tweet (id =
                    5).
                    <br />
                    twitter.getNewsFeed(1); // User 1's news feed should return
                    a list with 1 tweet id -&lt; [5]. return [5]
                    <br />
                    twitter.follow(1, 2); // User 1 follows user 2.
                    <br />
                    twitter.postTweet(2, 6); // User 2 posts a new tweet (id =
                    6).
                    <br />
                    twitter.getNewsFeed(1); // User 1's news feed should return
                    a list with 2 tweet ids -&lt; [6, 5]. Tweet id 6 should
                    precede tweet id 5 because it is posted after tweet id 5.
                    <br />
                    twitter.unfollow(1, 2); // User 1 unfollows user 2.
                    <br />
                    twitter.getNewsFeed(1); // User 1's news feed should return
                    a list with 1 tweet id -&lt; [5], since user 1 is no longer
                    following user 2.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= userId, followerId, followeeId &lt;= 500 <br />
                0 &lt;= tweetId &lt;= 10^4 <br />
                All the tweets have unique IDs. <br />
                At most 3 * 104 calls will be made to postTweet, getNewsFeed,
                follow, and unfollow.
              </>
            }
            tc="n + log k"
            sc="n"
            codes={{
              Java: {
                code: `class Tweet {
              public int id;
              public int time;
              public Tweet next = null;
              public Tweet(int id, int time) {
                this.id = id;
                this.time = time;
              }
            }
            
            class User {
              private int id;
              public Set<Integer> followeeIds = new HashSet<>();
              public Tweet tweetHead = null;
            
              public User(int id) {
                this.id = id;
                follow(id); 
              }
            
              public void follow(int followeeId) {
                followeeIds.add(followeeId);
              }
            
              public void unfollow(int followeeId) {
                followeeIds.remove(followeeId);
              }
            
              public void post(int tweetId, int time) {
                final Tweet oldTweetHead = tweetHead;
                tweetHead = new Tweet(tweetId, time);
                tweetHead.next = oldTweetHead;
              }
            }
            
            class Twitter {
              public void postTweet(int userId, int tweetId) {
                users.putIfAbsent(userId, new User(userId));
                users.get(userId).post(tweetId, time++);
              }
            
              public List<Integer> getNewsFeed(int userId) {
                if (!users.containsKey(userId))
                  return new ArrayList<>();
            
                List<Integer> newsFeed = new ArrayList<>();
                PriorityQueue<Tweet> pq = new PriorityQueue<>((a, b) -> b.time - a.time);
            
                for (final int followeeId : users.get(userId).followeeIds) {
                  Tweet tweetHead = users.get(followeeId).tweetHead;
                  if (tweetHead != null)
                    pq.offer(tweetHead);
                }
            
                int count = 0;
                while (!pq.isEmpty() && count++ < 10) {
                  Tweet tweet = pq.poll();
                  newsFeed.add(tweet.id);
                  if (tweet.next != null)
                    pq.offer(tweet.next);
                }
            
                return newsFeed;
              }
            
              public void follow(int followerId, int followeeId) {
                if (followerId == followeeId)
                  return;
                users.putIfAbsent(followerId, new User(followerId));
                users.putIfAbsent(followeeId, new User(followeeId));
                users.get(followerId).follow(followeeId);
              }
            
            
              public void unfollow(int followerId, int followeeId) {
                if (followerId == followeeId)
                  return;
                if (users.containsKey(followerId) && users.containsKey(followeeId))
                  users.get(followerId).unfollow(followeeId);
              }
            
              private int time = 0;
              private Map<Integer, User> users = new HashMap<>(); 
            }
            
            /**
             * Your Twitter object will be instantiated and called as such:
             * Twitter obj = new Twitter();
             * obj.postTweet(userId,tweetId);
             * List<Integer> param_2 = obj.getNewsFeed(userId);
             * obj.follow(followerId,followeeId);
             * obj.unfollow(followerId,followeeId);
             */`,
                output: `[null,null,[5],null,null,[6,5],null,[5]]`,
              },
            }}
          />
        ),
      },
      q297: {
        title: "Q357. Count Numbers with Unique Digits (Q297)",
        content: (
          <Comp
            title="Q357. Count Numbers with Unique Digits (Q297)"
            content1={
              <>
                Given an integer n, return the count of all numbers with unique
                digits, x, where 0 &lt;= x &lt; 10^n.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 2<br />
                    Output: 91
                    <br />
                    Explanation: The answer should be the total numbers in the
                    range of 0 ≤ x &lt; 100, excluding
                    11,22,33,44,55,66,77,88,99
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 0 <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={<>0 &lt;= n &lt;= 8</>}
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {number} n
            * @return {number}
            */
           var countNumbersWithUniqueDigits = function(n) {
               if (n == 0)
                 return 1;
               let ans = 10;
               let uniqueDigits = 9;
               for (let availableNum = 9; n > 1 && availableNum > 0; --n, --availableNum) {
                 uniqueDigits *= availableNum;
                 ans += uniqueDigits;
               }
               return ans;
           };
           
           countNumbersWithUniqueDigits(0)`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q298: {
        title: "Q363. Max Sum of Rectangle No Larger Than K (Q298)",
        content: (
          <Comp
            title="Q363. Max Sum of Rectangle No Larger Than K (Q298)"
            content1={
              <>
                Given an m x n matrix matrix and an integer k, return the max
                sum of a rectangle in the matrix such that its sum is no larger
                than k.
                <br />
                It is guaranteed that there will be a rectangle with a sum no
                larger than k.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode363,
                content: (
                  <>
                    Input: matrix = [[1,0,1],[0,-2,3]], k = 2<br />
                    Output: 2<br />
                    Explanation: Because the sum of the blue rectangle [[0, 1],
                    [-2, 3]] is 2, and 2 is the max number no larger than k (k =
                    2).
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: matrix = [[2,2,-1]], k = 3 <br />
                    Output: 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == matrix.length <br />
                n == matrix[i].length
                <br />
                1 &lt;= m, n &lt;= 100
                <br />
                -100 &lt;= matrix[i][j] &lt;= 100
                <br />
                -105 &lt;= k &lt;= 105
              </>
            }
            fp={
              <>
                <b>Follow up: </b>What if the number of rows is much larger than
                the number of columns?
              </>
            }
            tc="min(m,n)^2⋅max(m,n)⋅logmax(m,n)"
            sc="max(m,n)"
            codes={{
              Java: {
                code: `
            // [[1,0,1],[0,-2,3]], 2
            class Solution {
              public int maxSumSubmatrix(int[][] matrix, int k) {
                final int m = matrix.length;
                final int n = matrix[0].length;
            
                int ans = Integer.MIN_VALUE;
            
                for (int baseCol = 0; baseCol < n; ++baseCol) {
                  // sums[i] := sum(matrix[i][baseCol..j])
                  int[] sums = new int[m];
                  for (int j = baseCol; j < n; ++j) {
                    for (int i = 0; i < m; ++i)
                      sums[i] += matrix[i][j];
                    // find the max subarray no more than k
                    TreeSet<Integer> accumulate = new TreeSet<>(Arrays.asList(0));
                    int prefix = 0;
                    for (final int sum : sums) {
                      prefix += sum;
                      final Integer lo = accumulate.ceiling(prefix - k);
                      if (lo != null)
                        ans = Math.max(ans, prefix - lo);
                      accumulate.add(prefix);
                    }
                  }
                }
            
                return ans;
              }
            }
            `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q299: {
        title: "Q365. Water and Jug Problem (Q299)",
        content: (
          <Comp
            title="Q365. Water and Jug Problem (Q299)"
            content1={
              <>
                You are given two jugs with capacities jug1Capacity and
                jug2Capacity liters. There is an infinite amount of water supply
                available. Determine whether it is possible to measure exactly
                targetCapacity liters using these two jugs.
                <br />
                If targetCapacity liters of water are measurable, you must have
                targetCapacity liters of water contained within one or both
                buckets by the end.
                <br />
                Operations allowed:
                <br />
                Fill any of the jugs with water.
                <br />
                Empty any of the jugs.
                <br />
                Pour water from one jug into another till the other jug is
                completely full, or the first jug itself is empty.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: jug1Capacity = 3, <br />
                    jug2Capacity = 5,
                    <br /> targetCapacity = 4<br /> Output: true Explanation:
                    The famous Die Hard example
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: jug1Capacity = 2, <br /> jug2Capacity = 6, <br />{" "}
                    targetCapacity = 5 Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: jug1Capacity = 1, jug2Capacity = 2, <br />
                    targetCapacity = 3 <br /> Output: true
                  </>
                ),
              },
            ]}
            constraints={
              <>1 &lt;= jug1Capacity, jug2Capacity, targetCapacity &lt;= 106</>
            }
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {number} jug1Capacity
            * @param {number} jug2Capacity
            * @param {number} targetCapacity
            * @return {boolean}
            */
           
           function gcd(x, y) {
             return y == 0 ? x : gcd(y, x % y);
           }
           
           var canMeasureWater = function(jug1Capacity, jug2Capacity, targetCapacity) {
             const x = jug1Capacity
             const y = jug2Capacity
             const z = targetCapacity
             return z == 0 || x + y >= z && z % gcd(x, y) == 0;  
           };
           
           canMeasureWater(1,2,3)`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q300: {
        title: "Q367. Valid Perfect Square (Q300)",
        content: (
          <Comp
            title="Q367. Valid Perfect Square (Q300)"
            content1={
              <>
                Given a positive integer num, write a function which returns
                True if num is a perfect square else False.
                <br />
                <b>Follow up: </b>Do not use any built-in library function such
                as sqrt.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: num = 16 <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num = 14
                    <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= num &lt;= 2^31 - 1</>}
            tc="log num"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {number} num
            * @return {boolean}
            */
           var isPerfectSquare = function(num) {
             let l = 1;
             let r = num;
             while (l < r) {
               let m = l + parseInt((r - l) / 2);
               if (m >= num / m)
                 r = m;
               else
                 l = m + 1;
             }
             return l * l == num;   
           };
           
           isPerfectSquare(16)`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q301: {
        title: "Q368. Largest Divisible Subset (Q301)",
        content: (
          <Comp
            title="Q368. Largest Divisible Subset (Q301)"
            content1={
              <>
                Given a set of distinct positive integers nums, return the
                largest subset answer such that every pair (answer[i],
                answer[j]) of elements in this subset satisfies:
                <br />
                answer[i] % answer[j] == 0, or <br />
                answer[j] % answer[i] == 0 <br />
                If there are multiple solutions, return any of them.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,3] <br />
                    Output: [1,2] <br />
                    Explanation: [1,3] is also accepted.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,4,8]
                    <br />
                    Output: [1,2,4,8]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 1000 <br />
                1 &lt;= nums[i] &lt;= 2 * 10^9 <br />
                All the integers in nums are unique.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
            * @param {number[]} nums
            * @return {number[]}
            */
           var largestDivisibleSubset = function(nums) {
             const n = nums.length;
             const ans = [];
             const sizeEndsAt = Array(n).fill(1);
             const prevIndex = Array(n).fill(-1);
             let maxSize = 0;
             let index = -1; 
             nums = nums.sort((a,b)=>a-b)
             for (let i = 0; i < n; ++i) {
               for (let j = i - 1; j >= 0; --j)
                 if (nums[i] % nums[j] == 0 && sizeEndsAt[i] < sizeEndsAt[j] + 1) {
                   sizeEndsAt[i] = sizeEndsAt[j] + 1;
                   prevIndex[i] = j;
                 }
               if (maxSize < sizeEndsAt[i]) {
                 maxSize = sizeEndsAt[i];
                 index = i;
               }
             }
             while (index != -1) {
               ans.push(nums[index]);
               index = prevIndex[index];
             }
             return ans; 
           };
           
           largestDivisibleSubset([1,2,3])`,
                output: `[ 2, 1 ]`,
              },
            }}
          />
        ),
      },
      q302: {
        title: "Q371. Sum of Two Integers (Q302)",
        content: (
          <Comp
            title="Q371. Sum of Two Integers (Q302)"
            content1={
              <>
                Given two integers a and b, return the sum of the two integers
                without using the operators + and -.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: a = 1, b = 2 <br />
                    Output: 3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: a = 2, b = 3 <br />
                    Output: 5
                  </>
                ),
              },
            ]}
            constraints={<>-1000 &lt;= a, b &lt;= 1000</>}
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {number} a
            * @param {number} b
            * @return {number}
            */
           var getSum = function(a, b) {
            while (b != 0) {          
             const carry = a & b; 
             a ^= b;                  
             b = carry << 1;
             }
             return a; 
           };
           
           getSum(1, 2)`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q303: {
        title: "Q372. Super Pow (Q303)",
        content: (
          <Comp
            title="Q372. Super Pow (Q303)"
            content1={
              <>
                Your task is to calculate ab mod 1337 where a is a positive
                integer and b is an extremely large positive integer given in
                the form of an array.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: a = 2, b = [3] <br />
                    Output: 8
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: a = 2, b = [1,0]
                    <br />
                    Output: 1024
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: a = 1, b = [4,3,3,8,5,2]
                    <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= a &lt;= 2^31 - 1 <br />
                1 &lt;= b.length &lt;= 2000 <br />
                0 &lt;= b[i] &lt;= 9 <br />b does not contain leading zeros.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {number} a
            * @param {number[]} b
            * @return {number}
            */
           
           function powMod(x, y, k) {
             let pow = 1;
             while (y-- > 0)
               pow = (pow * x) % k;
             return pow;
           }
           
           var superPow = function(a, b) {
             let ans = 1;
             let k = 1337
             a %= k;
             for (let i of b)
               ans = powMod(ans, 10, k) * powMod(a, i, k) % k;
             return ans;    
           };
           
           superPow(2,[3])`,
                output: `8`,
              },
            }}
          />
        ),
      },
      q304: {
        title: "Q373. Find K Pairs with Smallest Sums (Q304)",
        content: (
          <Comp
            title="Q373. Find K Pairs with Smallest Sums (Q304)"
            content1={
              <>
                You are given two integer arrays nums1 and nums2 sorted in
                ascending order and an integer k.
                <br />
                Define a pair (u, v) which consists of one element from the
                first array and one element from the second array.
                <br />
                Return the k pairs (u1, v1), (u2, v2), ..., (uk, vk) with the
                smallest sums.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums1 = [1,7,11], nums2 = [2,4,6], k = 3<br />
                    Output: [[1,2],[1,4],[1,6]]
                    <br />
                    Explanation: The first 3 pairs are returned from the
                    sequence:
                    [1,2],[1,4],[1,6],[7,2],[7,4],[11,2],[7,6],[11,4],[11,6]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums1 = [1,1,2], <br /> nums2 = [1,2,3], k = 2 <br />
                    Output: [[1,1],[1,1]] <br />
                    Explanation: The first 2 pairs are returned from the
                    sequence:
                    [1,1],[1,1],[1,2],[2,1],[1,2],[2,2],[1,3],[1,3],[2,3]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums1 = [1,2], <br />
                    nums2 = [3], k = 3<br />
                    Output: [[1,3],[2,3]]
                    <br />
                    Explanation: All possible pairs are returned from the
                    sequence: [1,3],[2,3]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums1.length, nums2.length &lt;= 10^5 <br />
                -10^9 &lt;= nums1[i], nums2[i] &lt;= 10^9 <br />
                nums1 and nums2 both are sorted in ascending order. <br />1
                &lt;= k &lt;= 1000
              </>
            }
            tc="k.log k"
            sc="k"
            codes={{
              Java: {
                code: `class T {
              public int i;
              public int j;
              public int sum; // nums1[i] + nums2[j]
              public T(int i, int j, int sum) {
                this.i = i;
                this.j = j;
                this.sum = sum;
              }
            }
            
            class Solution {
              public List<List<Integer>> kSmallestPairs(int[] nums1, int[] nums2, int k) {
                if (nums1.length == 0 || nums2.length == 0 || k == 0)
                  return new ArrayList<>();
            
                List<List<Integer>> ans = new ArrayList<>();
                PriorityQueue<T> pq = new PriorityQueue<>((a, b) -> a.sum - b.sum);
            
                for (int i = 0; i < k && i < nums1.length; ++i)
                  pq.offer(new T(i, 0, nums1[i] + nums2[0]));
            
                while (!pq.isEmpty() && ans.size() < k) {
                  final int i = pq.peek().i;
                  final int j = pq.poll().j;
                  ans.add(Arrays.asList(nums1[i], nums2[j]));
                  if (j + 1 < nums2.length)
                    pq.offer(new T(i, j + 1, nums1[i] + nums2[j + 1]));
                }
            
                return ans;
              }
            }
            `,
                output: `[[1,2],[1,4],[1,6]]`,
              },
            }}
          />
        ),
      },
      q305: {
        title: "Q374. Guess Number Higher or Lower (Q305)",
        content: (
          <Comp
            title="Q374. Guess Number Higher or Lower (Q305)"
            content1={
              <>
                We are playing the Guess Game. The game is as follows:
                <br />
                I pick a number from 1 to n. You have to guess which number I
                picked.
                <br />
                Every time you guess wrong, I will tell you whether the number I
                picked is higher or lower than your guess.
                <br />
                You call a pre-defined API int guess(int num), which returns 3
                possible results:
                <br />
                <br />
                -1: The number I picked is lower than your guess (i.e. pick &lt;
                num).
                <br />
                1: The number I picked is higher than your guess (i.e. pick &gt;
                num).
                <br />
                0: The number I picked is equal to your guess (i.e. pick ==
                num).
                <br />
                Return the number that I picked.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 10, pick = 6 <br />
                    Output: 6
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1, pick = 1<br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2, pick = 1<br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 2^31 - 1 <br />1 &lt;= pick &lt;= n
              </>
            }
            tc="log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * Forward declaration of guess API.
            * (The problem description is not clear, so I translate it into follows.)
            *
            * @param traget num
            *        guess num
            *
            * @return -1 if guess num >  target num
            *          0 if guess num == target num
            *          1 if guess num <  target num
            */
           // I/P:  10 6
           public class Solution extends GuessGame {
             public int guessNumber(int n) {
               int l = 1;
               int r = n;
           
               // find the first guess num that >= target num
               while (l < r) {
                 final int m = l + (r - l) / 2;
                 if (guess(m) <= 0) // -1, 0
                   r = m;
                 else
                   l = m + 1;
               }
           
               return l;
             }
           }
           `,
                output: `6`,
              },
            }}
          />
        ),
      },
      q306: {
        title: "Q375. Guess Number Higher or Lower II (Q306)",
        content: (
          <Comp
            title="Q375. Guess Number Higher or Lower II (Q306)"
            content1={
              <>
                We are playing the Guessing Game. The game will work as follows:
                <br />
                I pick a number between 1 and n.
                <br />
                You guess a number.
                <br />
                If you guess the right number, you win the game.
                <br />
                If you guess the wrong number, then I will tell you whether the
                number I picked is higher or lower, and you will continue
                guessing.
                <br />
                Every time you guess a wrong number x, you will pay x dollars.
                If you run out of money, you lose the game.
                <br />
                Given a particular n, return the minimum amount of money you
                need to guarantee a win regardless of what number I pick.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                img: Leetcode375,
                content: (
                  <>
                    Input: n = 10 <br />
                    Output: 16
                    <br />
                    Explanation: The winning strategy is as follows:
                    <br />
                    - The range is [1,10]. Guess 7.
                    <br />
                    - If this is my number, your total is $0. Otherwise, you pay
                    $7.
                    <br />
                    - If my number is higher, the range is [8,10]. Guess 9.
                    <br />
                    - If this is my number, your total is $7. Otherwise, you pay
                    $9.
                    <br />
                    - If my number is higher, it must be 10. Guess 10. Your
                    total is $7 + $9 = $16.
                    <br />
                    - If my number is lower, it must be 8. Guess 8. Your total
                    is $7 + $9 = $16.
                    <br />
                    - If my number is lower, the range is [1,6]. Guess 3.
                    <br />
                    - If this is my number, your total is $7. Otherwise, you pay
                    $3.
                    <br />
                    - If my number is higher, the range is [4,6]. Guess 5.
                    <br />
                    - If this is my number, your total is $7 + $3 = $10.
                    Otherwise, you pay $5.
                    <br />
                    - If my number is higher, it must be 6. Guess 6. Your total
                    is $7 + $3 + $5 = $15.
                    <br />
                    - If my number is lower, it must be 4. Guess 4. Your total
                    is $7 + $3 + $5 = $15.
                    <br />
                    - If my number is lower, the range is [1,2]. Guess 1.
                    <br />
                    - If this is my number, your total is $7 + $3 = $10.
                    Otherwise, you pay $1.
                    <br />
                    - If my number is higher, it must be 2. Guess 2. Your total
                    is $7 + $3 + $1 = $11.
                    <br />
                    The worst case in all these scenarios is that you pay $16.
                    Hence, you only need $16 to guarantee a win.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1
                    <br />
                    Output: 0
                    <br />
                    Explanation: There is only one possible number, so you can
                    guess 1 and not have to pay anything.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2 Output: 1 Explanation: There are two possible
                    numbers, 1 and 2. - Guess 1. - If this is my number, your
                    total is $0. Otherwise, you pay $1. - If my number is
                    higher, it must be 2. Guess 2. Your total is $1. The worst
                    case is that you pay $1.
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 200</>}
            tc="n^3"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
            * @param {number} n
            * @return {number}
            */
           var getMoneyAmount = function(n) {
             const dp = Array.from(Array(n+2),()=>Array(n+2).fill(0))
             for (let d = 1; d < n; ++d)
               for (let i = 1; i + d <= n; ++i) {
                 const j = i + d;
                 dp[i][j] = Number.MAX_SAFE_INTEGER;
                 for (let k = i; k <= j; ++k)
                   dp[i][j] = Math.min(dp[i][j], Math.max(dp[i][k - 1], dp[k + 1][j]) + k);
               }
             return dp[1][n];   
           };
           
           getMoneyAmount(2)`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q307: {
        title: "Q376. Wiggle Subsequence (Q307)",
        content: (
          <Comp
            title="Q376. Wiggle Subsequence (Q307)"
            content1={
              <>
                A wiggle sequence is a sequence where the differences between
                successive numbers strictly alternate between positive and
                negative. The first difference (if one exists) may be either
                positive or negative. A sequence with one element and a sequence
                with two non-equal elements are trivially wiggle sequences.
                <br />
                For example, [1, 7, 4, 9, 2, 5] is a wiggle sequence because the
                differences (6, -3, 5, -7, 3) alternate between positive and
                negative.
                <br /> In contrast, [1, 4, 7, 2, 5] and [1, 7, 4, 5, 5] are not
                wiggle sequences. The first is not because its first two
                differences are positive, and the second is not because its last
                difference is zero.
                <br /> A subsequence is obtained by deleting some elements
                (possibly zero) from the original sequence, leaving the
                remaining elements in their original order.
                <br />
                Given an integer array nums, return the length of the longest
                wiggle subsequence of nums.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,7,4,9,2,5] <br />
                    Output: 6 <br />
                    Explanation: The entire sequence is a wiggle sequence with
                    differences (6, -3, 5, -7, 3).
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,17,5,10,13,15,10,5,16,8] <br />
                    Output: 7 <br />
                    Explanation: There are several subsequences that achieve
                    this length. <br />
                    One is [1, 17, 10, 13, 10, 16, 8] with differences (16, -7,
                    3, -3, 6, -8).
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4,5,6,7,8,9]
                    <br />
                    Output: 2
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 1000 <br />0 &lt;= nums[i] &lt;= 1000
              </>
            }
            fp={
              <>
                <b>Follow up: </b>
                Could you solve this in O(n) time?
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {number[]} nums
            * @return {number}
            */
           var wiggleMaxLength = function(nums) {
             if (nums.length == 0)
               return 0;
             let increasing = 1;
             let decreasing = 1;
             for (let i = 1; i < nums.length; ++i)
               if (nums[i] > nums[i - 1])
                 increasing = decreasing + 1;
               else if (nums[i] < nums[i - 1])
                 decreasing = increasing + 1;
             return Math.max(increasing, decreasing);
           };
           
           wiggleMaxLength([1,17,5,10,13,15,10,5,16,8])`,
                output: `7`,
              },
            }}
          />
        ),
      },
      q308: {
        title: "Q377. Combination Sum IV (Q308)",
        content: (
          <Comp
            title="Q377. Combination Sum IV (Q308)"
            content1={
              <>
                Given an array of distinct integers nums and a target integer
                target, return the number of possible combinations that add up
                to target.
                <br />
                The test cases are generated so that the answer can fit in a
                32-bit integer.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,3], target = 4<br />
                    Output: 7<br />
                    Explanation:
                    <br />
                    The possible combination ways are:
                    <br />
                    (1, 1, 1, 1)
                    <br />
                    (1, 1, 2)
                    <br />
                    (1, 2, 1)
                    <br />
                    (1, 3)
                    <br />
                    (2, 1, 1)
                    <br />
                    (2, 2)
                    <br />
                    (3, 1)
                    <br />
                    Note that different sequences are counted as different
                    combinations.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [9], <br />
                    target = 3<br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 200 <br />
                1 &lt;= nums[i] &lt;= 1000 <br />
                All the elements of nums are unique. <br />1 &lt;= target &lt;=
                1000
              </>
            }
            fp={
              <>
                <b>Follow up: </b> What if negative numbers are allowed in the
                given array? How does it change the problem? What limitation we
                need to add to the question to allow negative numbers?
              </>
            }
            tc="|coins|.target"
            sc="target"
            codes={{
              Javascript: {
                code: `/**
            * @param {number[]} nums
            * @param {number} target
            * @return {number}
            */
           var combinationSum4 = function(nums, target) {
             const dp = Array(target + 1).fill(0);
             dp[0] = 1;
             for (let i = 0; i <= target; ++i)
               for (let num of nums)
                 if (i >= num)
                   dp[i] += dp[i - num];
             return dp[target];
           };
           
           combinationSum4([1,2,3],4)`,
                output: `7`,
              },
            }}
          />
        ),
      },
      q309: {
        title: "Q378. Kth Smallest Element in a Sorted Matrix (Q309)",
        content: (
          <Comp
            title="Q378. Kth Smallest Element in a Sorted Matrix (Q309)"
            content1={
              <>
                Given an n x n matrix where each of the rows and columns is
                sorted in ascending order, return the kth smallest element in
                the matrix.
                <br />
                Note that it is the kth smallest element in the sorted order,
                not the kth distinct element.
                <br />
                You must find a solution with a memory complexity better than
                O(n2).
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: matrix = [[1,5,9],[10,11,13],[12,13,15]]
                    <br />, k = 8<br />
                    Output: 13
                    <br />
                    Explanation: The elements in the matrix are
                    [1,5,9,10,11,12,13,13,15], and the 8th smallest number is 13
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: matrix = [[-5]], k = 1<br />
                    Output: -5
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == matrix.length == matrix[i].length <br />
                1 &lt;= n&lt;= 300 <br />
                -109 &lt;= matrix[i][j] &lt;= 10^9 <br />
                All the rows and columns of matrix are guaranteed to be sorted
                in non-decreasing order.
                <br /> 1 &lt;= k &lt;= n^2
              </>
            }
            fp={
              <>
                <b>Follow up: </b>
                Could you solve the problem with a constant memory (i.e., O(1)
                memory complexity)?
                <br />
                Could you solve the problem in O(n) time complexity? The
                solution may be too advanced for an interview but you may find
                reading this paper fun.
              </>
            }
            tc="nlog(max−min)"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {number[][]} matrix
            * @param {number} k
            * @return {number}
            */
           function numsNoGreaterThan(matrix, m) {
             let count = 0;
             let j = matrix[0].length - 1;
             for (let row of matrix) {
               while (j >= 0 && row[j] > m)
                 --j;
               count += j + 1;
             }
             return count;
           }
           
           var kthSmallest = function(matrix, k) {
             let l = matrix[0][0];
             let r = matrix[matrix.length - 1][matrix.length - 1];
             while (l < r) {
               let m = l + parseInt((r - l) / 2);
               if (numsNoGreaterThan(matrix, m) >= k)
                 r = m;
               else
                 l = m + 1;
             }
             return l; 
           };
           
           kthSmallest([[1,5,9],[10,11,13],[12,13,15]],8)`,
                output: `13`,
              },
            }}
          />
        ),
      },
      q310: {
        title: "Q380. Insert Delete GetRandom O(1) (Q310)",
        content: (
          <Comp
            title="Q380. Insert Delete GetRandom O(1) (Q310)"
            content1={
              <>
                Implement the RandomizedSet class:
                <br />
                <br /> RandomizedSet() Initializes the RandomizedSet object.
                <br /> bool insert(int val) Inserts an item val into the set if
                not present. Returns true if the item was not present, false
                otherwise.
                <br /> bool remove(int val) Removes an item val from the set if
                present. Returns true if the item was present, false otherwise.
                <br /> int getRandom() Returns a random element from the current
                set of elements (it's guaranteed that at least one element
                exists when this method is called). Each element must have the
                same probability of being returned. You must implement the
                functions of the class such that each function works in average
                O(1) time complexity.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br /> ["RandomizedSet", "insert", "remove", "insert",
                    "getRandom", "remove", "insert", "getRandom"]
                    <br /> [[], [1], [2], [2], [], [1], [2], []]
                    <br /> Output
                    <br /> [null, true, false, true, 2, true, false, 2]
                    <br />
                    <br /> Explanation
                    <br /> RandomizedSet randomizedSet = new RandomizedSet();
                    <br /> randomizedSet.insert(1); // Inserts 1 to the set.
                    Returns true as 1 was inserted successfully.
                    <br /> randomizedSet.remove(2); // Returns false as 2 does
                    not exist in the set.
                    <br /> randomizedSet.insert(2); // Inserts 2 to the set,
                    returns true. Set now contains [1,2].
                    <br /> randomizedSet.getRandom(); // getRandom() should
                    return either 1 or 2 randomly.
                    <br /> randomizedSet.remove(1); // Removes 1 from the set,
                    returns true. Set now contains [2].
                    <br /> randomizedSet.insert(2); // 2 was already in the set,
                    so return false. randomizedSet.getRandom(); // Since 2 is
                    the only number in the set, getRandom() will always return
                    2.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                -2^31 &lt;= val &lt;= 2^31 - 1
                <br />
                At most 2 * 105 calls will be made to insert, remove, and
                getRandom.
                <br />
                There will be at least one element in the data structure when
                getRandom is called.
              </>
            }
            tc="1"
            sc="n"
            codes={{
              Java: {
                code: `class RandomizedSet {
              public boolean insert(int val) {
                if (valToIndex.containsKey(val))
                  return false;
            
                valToIndex.put(val, vals.size());
                vals.add(val);
            
                return true;
              }
            
              public boolean remove(int val) {
                if (!valToIndex.containsKey(val))
                  return false;
                final int index = valToIndex.get(val);
                valToIndex.put(last(vals), index);
                valToIndex.remove(val);
                vals.set(index, last(vals));
                vals.remove(vals.size() - 1);
                return true;
              }
            
              public int getRandom() {
                final int index = rand.nextInt(vals.size());
                return vals.get(index);
              }
            
              private Map<Integer, Integer> valToIndex = new HashMap<>();
              private List<Integer> vals = new ArrayList<>();
              private Random rand = new Random();
            
              private int last(List<Integer> vals) {
                return vals.get(vals.size() - 1);
              }
            }
            
            
            /**
             * Your RandomizedSet object will be instantiated and called as such:
             * RandomizedSet obj = new RandomizedSet();
             * boolean param_1 = obj.insert(val);
             * boolean param_2 = obj.remove(val);
             * int param_3 = obj.getRandom();
             */`,
                output: `[null,true,false,true,2,true,false,2]`,
              },
            }}
          />
        ),
      },
      q311: {
        title: "Q381. Insert Delete GetRandom O(1) - Duplicates allowed (Q312)",
        content: (
          <Comp
            title="Q381. Insert Delete GetRandom O(1) - Duplicates allowed (Q312)"
            content1={
              <>
                RandomizedCollection is a data structure that contains a
                collection of numbers, possibly duplicates (i.e., a multiset).
                It should support inserting and removing specific elements and
                also removing a random element.
                <br />
                Implement the RandomizedCollection class:
                <br />
                RandomizedCollection() Initializes the empty
                RandomizedCollection object.
                <br />
                bool insert(int val) Inserts an item val into the multiset, even
                if the item is already present. Returns true if the item is not
                present, false otherwise.
                <br />
                bool remove(int val) Removes an item val from the multiset if
                present. Returns true if the item is present, false otherwise.
                Note that if val has multiple occurrences in the multiset, we
                only remove one of them.
                <br />
                int getRandom() Returns a random element from the current
                multiset of elements. The probability of each element being
                returned is linearly related to the number of same values the
                multiset contains.
                <br />
                You must implement the functions of the class such that each
                function works on average O(1) time complexity.
                <br />
                Note: The test cases are generated such that getRandom will only
                be called if there is at least one item in the
                RandomizedCollection.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br /> ["RandomizedCollection", "insert", "insert",
                    "insert", "getRandom", "remove", "getRandom"]
                    <br /> [[], [1], [1], [2], [], [1], []]
                    <br /> Output
                    <br />
                    [null, true, false, true, 2, true, 1]
                    <br /> Explanation
                    <br /> RandomizedCollection randomizedCollection = new
                    RandomizedCollection();
                    <br /> randomizedCollection.insert(1); // return true since
                    the collection does not contain 1.
                    <br /> // Inserts 1 into the collection.
                    <br /> randomizedCollection.insert(1); // return false since
                    the collection contains 1.
                    <br /> // Inserts another 1 into the collection. Collection
                    now contains [1,1].
                    <br /> randomizedCollection.insert(2); // return true since
                    the collection does not contain 2.
                    <br /> // Inserts 2 into the collection. Collection now
                    contains [1,1,2].
                    <br /> randomizedCollection.getRandom(); // getRandom
                    should:
                    <br /> // - return 1 with probability 2/3, or
                    <br /> // - return 2 with probability 1/3.
                    <br /> randomizedCollection.remove(1); // return true since
                    the collection contains 1.
                    <br /> // Removes 1 from the collection. Collection now
                    contains [1,2].
                    <br /> randomizedCollection.getRandom(); // getRandom should
                    return 1 or 2, both equally likely.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                -2^31 &lt;= val &lt;= 2^31 - 1<br />
                <br /> At most 2 * 105 calls in total will be made to insert,
                remove, and getRandom. There will be at least one element in the
                data structure when getRandom is called.
              </>
            }
            tc="1"
            sc="n"
            codes={{
              Java: {
                code: `class Item {
              public int val;
              public int indexInMap;
              public Item(int val, int indexInMap) {
                this.val = val;
                this.indexInMap = indexInMap;
              }
            }
            
            class RandomizedCollection {
              public boolean insert(int val) {
                valToIndices.putIfAbsent(val, new ArrayList<>());
                valToIndices.get(val).add(items.size());
                items.add(new Item(val, valToIndices.get(val).size() - 1));
                return valToIndices.get(val).size() == 1;
              }
            
              public boolean remove(int val) {
                if (!valToIndices.containsKey(val))
                  return false;
            
                final int index = lastIndex(valToIndices.get(val));
                valToIndices.get(last(items).val).set(last(items).indexInMap, index);
                final int indicesSize = valToIndices.get(val).size();
                valToIndices.get(val).remove(indicesSize - 1);
                if (valToIndices.get(val).isEmpty())
                  valToIndices.remove(val);
                items.set(index, last(items));
                items.remove(items.size() - 1);
            
                return true;
              }
            
              public int getRandom() {
                final int index = rand.nextInt(items.size());
                return items.get(index).val;
              }
            
              private Map<Integer, List<Integer>> valToIndices = new HashMap<>();
              private List<Item> items = new ArrayList<>();
              private Random rand = new Random();
            
              private int lastIndex(List<Integer> indices) {
                return indices.get(indices.size() - 1);
              }
            
              private Item last(List<Item> items) {
                return items.get(items.size() - 1);
              }
            }
            
            
            /**
             * Your RandomizedCollection object will be instantiated and called as such:
             * RandomizedCollection obj = new RandomizedCollection();
             * boolean param_1 = obj.insert(val);
             * boolean param_2 = obj.remove(val);
             * int param_3 = obj.getRandom();
             */`,
                output: `[null,true,false,true,1,true,1]`,
              },
            }}
          />
        ),
      },
      q312: {
        title: "Q382. Linked List Random Node (Q311)",
        content: (
          <Comp
            title="Q382. Linked List Random Node (Q311)"
            content1={
              <>
                Given a singly linked list, return a random node's value from
                the linked list. Each node must have the same probability of
                being chosen.
                <br />
                Implement the Solution class:
                <br />
                Solution(ListNode head) Initializes the object with the head of
                the singly-linked list head.
                <br />
                int getRandom() Chooses a node randomly from the list and
                returns its value. All the nodes of the list should be equally
                likely to be chosen.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["Solution", "getRandom", "getRandom", "getRandom",
                    "getRandom", "getRandom"] <br />
                    [[[1, 2, 3]], [], [], [], [], []] <br />
                    Output <br />
                    [null, 1, 3, 2, 2, 3] <br />
                    <br />
                    Explanation <br />
                    Solution solution = new Solution([1, 2, 3]); <br />
                    solution.getRandom(); // return 1 <br />
                    solution.getRandom(); // return 3 <br />
                    solution.getRandom(); // return 2 <br />
                    solution.getRandom(); // return 2 <br />
                    solution.getRandom(); // return 3 <br />
                    // getRandom() should return either 1, 2, or 3 randomly.
                    Each element should have equal probability of returning.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the linked list will be in the range [1,
                104].
                <br /> -10^4 &lt;= Node.val &lt;= 10^4
                <br /> At most 10^4 calls will be made to getRandom.
              </>
            }
            fp={
              <>
                <b>Follow up: </b>What if the linked list is extremely large and
                its length is unknown to you?
                <br />
                Could you solve this efficiently without using extra space?
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `function ListNode(val, next) {
              this.val = (val===undefined ? 0 : val)
              this.next = (next===undefined ? null : next)
          }
          
          /**
           * @param {ListNode} head
           */
          var Solution = function(head) {
            this.head = head
          };
          
          /**
           * @return {number}
           */
          Solution.prototype.getRandom = function() {
            let ans = -1;
            let i = 1;
            for (let curr = this.head; curr != null; curr = curr.next, ++i)
              if (Math.floor(Math.random()*i) == i - 1)
                ans = curr.val;
            return ans;
          };
          
          const l = new ListNode(1) 
          l.next = new ListNode(2) 
          l.next.next = new ListNode(3) 
          var obj = new Solution(l)
          var param_1 = obj.getRandom()
          `,
                output: `ListNode { val: 3, next: null }`,
              },
            }}
          />
        ),
      },
      q313: {
        title: "Q383. Ransom Note (Q313)",
        content: (
          <Comp
            title="Q383. Ransom Note (Q313)"
            content1={
              <>
                Given two strings ransomNote and magazine, return true if
                ransomNote can be constructed from magazine and false otherwise.
                <br />
                Each letter in magazine can only be used once in ransomNote.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: ransomNote = "a",
                    <br /> magazine = "b"
                    <br />
                    Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: ransomNote = "aa",
                    <br /> magazine = "ab" <br />
                    Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: ransomNote = "aa",
                    <br /> magazine = "aab"
                    <br />
                    Output: true
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= ransomNote.length, magazine.length &lt;= 10^5 <br />
                ransomNote and magazine consist of lowercase English letters.
              </>
            }
            tc="∣ransomNote∣+∣magazine∣"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {string} ransomNote
            * @param {string} magazine
            * @return {boolean}
            */
           var canConstruct = function(ransomNote, magazine) {
             const count = Array(128).fill(0);
             for (let c of magazine)
               ++count[c.charCodeAt(0)];
             for (let c of ransomNote)
               if (--count[c.charCodeAt(0)] < 0)
                 return false;
             return true;
           };
           
           canConstruct("a","b")`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q314: {
        title: "Q384. Shuffle an Array (Q314)",
        content: (
          <Comp
            title="Q384. Shuffle an Array (Q314)"
            content1={
              <>
                Given an integer array nums, design an algorithm to randomly
                shuffle the array. All permutations of the array should be
                equally likely as a result of the shuffling.
                <br />
                Implement the Solution class:
                <br />
                <br />
                Solution(int[] nums) Initializes the object with the integer
                array nums.
                <br />
                int[] reset() Resets the array to its original configuration and
                returns it.
                <br />
                int[] shuffle() Returns a random shuffling of the array.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["Solution", "shuffle", "reset", "shuffle"] <br />
                    [[[1, 2, 3]], [], [], []] <br />
                    Output <br />
                    [null, [3, 1, 2], [1, 2, 3], [1, 3, 2]] <br />
                    Explanation <br />
                    Solution solution = new Solution([1, 2, 3]); <br />
                    solution.shuffle(); // Shuffle the array [1,2,3] and return
                    its result. <br />
                    // Any permutation of [1,2,3] must be equally likely to be
                    returned.
                    <br />
                    // Example: return [3, 1, 2] <br />
                    solution.reset(); // Resets the array back to its original
                    configuration [1,2,3]. Return [1, 2, 3]
                    <br />
                    solution.shuffle(); // Returns the random shuffling of array
                    [1,2,3]. Example: return [1, 3, 2]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 200 <br />
                -10^6 &lt;= nums[i] &lt;= 10^6 <br />
                All the elements of nums are unique. <br />
                At most 5 * 104 calls in total will be made to reset and
                shuffle.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
            * @param {number[]} nums
            */
           var Solution = function(nums) {
             this.nums = nums
           };
           
           /**
            * @return {number[]}
            */
           Solution.prototype.reset = function() {
             return this.nums  
           };
           
           /**
            * @return {number[]}
            */
           Solution.prototype.shuffle = function() {
             const a = this.nums.slice();
             for (let i = a.length - 1; i > 0; --i) {
               const j = Math.floor(Math.random()*(i+1));
               [ a[i], a[j] ] = [ a[j], a[i] ]
           };
             return a; 
           }
           
           /**
            *  
            * Your Solution object will be instantiated and called as such:
            * var obj = new Solution(nums)
            * var param_1 = obj.reset()
            * var param_2 = obj.shuffle()
            */`,
                output: `[null,[3,2,1],[1,2,3],[1,3,2]]`,
              },
            }}
          />
        ),
      },
      q315: {
        title: "Q385. Mini Parser (Q315)",
        content: (
          <Comp
            title="Q385. Mini Parser (Q315)"
            content1={
              <>
                Given a string s represents the serialization of a nested list,
                implement a parser to deserialize it and return the deserialized
                NestedInteger.
                <br />
                Each element is either an integer or a list whose elements may
                also be integers or other lists.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "324"
                    <br />
                    Output: 324
                    <br />
                    Explanation: You should return a NestedInteger object which
                    contains a single integer 324.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "[123,[456,[789]]]"
                    <br />
                    Output: [123,[456,[789]]]
                    <br />
                    Explanation: Return a NestedInteger object containing a
                    nested list with 2 elements:
                    <br /> 1. An integer containing value 123.
                    <br /> 2. A nested list containing two elements:
                    <br /> i. An integer containing value 456.
                    <br /> ii. A nested list with one element:
                    <br /> a. An integer containing value 789
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 5 * 10^4 <br />
                s consists of digits, square brackets "[]", negative sign '-',
                and commas ','. <br />
                s is the serialization of valid NestedInteger. <br />
                All the values in the input are in the range [-106, 106].
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `/**
            * // This is the interface that allows for creating nested lists.
            * // You should not implement it, or speculate about its implementation
            * public interface NestedInteger {
            *     // Constructor initializes an empty nested list.
            *     public NestedInteger();
            *
            *     // Constructor initializes a single integer.
            *     public NestedInteger(int value);
            *
            *     // @return true if this NestedInteger holds a single integer, rather than a nested list.
            *     public boolean isInteger();
            *
            *     // @return the single integer that this NestedInteger holds, if it holds a single integer
            *     // Return null if this NestedInteger holds a nested list
            *     public Integer getInteger();
            *
            *     // Set this NestedInteger to hold a single integer.
            *     public void setInteger(int value);
            *
            *     // Set this NestedInteger to hold a nested list and adds a nested integer to it.
            *     public void add(NestedInteger ni);
            *
            *     // @return the nested list that this NestedInteger holds, if it holds a nested list
            *     // Return empty list if this NestedInteger holds a single integer
            *     public List<NestedInteger> getList();
            * }
            */
           class Solution {
             public NestedInteger deserialize(String s) {
               if (s.charAt(0) != '[')
                 return new NestedInteger(Integer.parseInt(s));
           
               Stack<NestedInteger> stack = new Stack<>();
               int start = 1;
           
               for (int i = 0; i < s.length(); ++i)
                 switch (s.charAt(i)) {
                   case '[':
                     stack.push(new NestedInteger());
                     start = i + 1;
                     break;
                   case ',':
                     if (i > start) {
                       final int num = Integer.parseInt(s.substring(start, i));
                       stack.peek().add(new NestedInteger(num));
                     }
                     start = i + 1;
                     break;
                   case ']':
                     NestedInteger popped = stack.pop();
                     if (i > start) {
                       final int num = Integer.parseInt(s.substring(start, i));
                       popped.add(new NestedInteger(num));
                     }
                     if (!stack.isEmpty())
                       stack.peek().add(popped);
                     else
                       return popped;
                     start = i + 1;
                     break;
                 }
           
               throw new IllegalArgumentException();
             }
           }
           `,
                output: `324`,
              },
            }}
          />
        ),
      },
      q316: {
        title: "Q386. Lexicographical Numbers (Q316)",
        content: (
          <Comp
            title="Q386. Lexicographical Numbers (Q316)"
            content1={
              <>
                Given an integer n, return all the numbers in the range [1, n]
                sorted in lexicographical order.
                <br />
                You must write an algorithm that runs in O(n) time and uses O(1)
                extra space.{" "}
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 13
                    <br />
                    Output: [1,10,11,12,13,2,3,4,5,6,7,8,9]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2<br />
                    Output: [1,2]
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 5 * 104</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
            * @param {number} n
            * @return {number[]}
            */
           var lexicalOrder = function(n) {
             const ans = [];
             let curr = 1;
             while (ans.length < n) {
               ans.push(curr);
               if (curr * 10 <= n) {
                 curr *= 10;
               } else {
                 while (curr % 10 == 9 || curr == n)
                   curr = parseInt(curr / 10);
                 ++curr;
               }
             }
             return ans; 
           };
           
           lexicalOrder(13)`,
                output: `[
            1, 10, 11, 12, 13, 2,
            3,  4,  5,  6,  7, 8,
            9
          ]`,
              },
            }}
          />
        ),
      },
      q317: {
        title: "Q387. First Unique Character in a String (Q317)",
        content: (
          <Comp
            title="Q387. First Unique Character in a String (Q317)"
            content1={
              <>
                Given a string s, find the first non-repeating character in it
                and return its index. If it does not exist, return -1.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "leetcode"
                    <br />
                    Output: 0
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "loveleetcode"
                    <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "aabb"
                    <br />
                    Output: -1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^5 <br />s consists of only lowercase
                English letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {string} s
            * @return {number}
            */
           var firstUniqChar = function(s) {
             const count = Array(128).fill(0);
             for (let c of s)
               ++count[c.charCodeAt(0)];
             for (let i = 0; i < s.length; ++i)
               if (count[s[i].charCodeAt(0)] == 1)
                 return i;
             return -1;
           };
           
           firstUniqChar("leetcode")`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q318: {
        title: "Q388. Longest Absolute File Path (Q318)",
        content: (
          <Comp
            title="Q388. Longest Absolute File Path (Q318)"
            content1={
              <>
                Suppose we have a file system that stores both files and
                directories. An example of one system is represented in the
                following picture:
              </>
            }
            content2={
              <>
                Here, we have dir as the only directory in the root. dir
                contains two subdirectories, subdir1 and subdir2. subdir1
                contains a file file1.ext and subdirectory subsubdir1. subdir2
                contains a subdirectory subsubdir2, which contains a file
                file2.ext.
                <br />
                In text form, it looks like this (with ⟶ representing the tab
                character):
                <br />
                <pre>
                  {`
dir
⟶ subdir1
⟶ ⟶ file1.ext
⟶ ⟶ subsubdir1
⟶ subdir2
⟶ ⟶ subsubdir2
⟶ ⟶ ⟶ file2.ext
                  `}
                </pre>
                <br />
                If we were to write this representation in code, it will look
                like this:
                "dir\n\tsubdir1\n\t\tfile1.ext\n\t\tsubsubdir1\n\tsubdir2\n\t\tsubsubdir2\n\t\t\tfile2.ext".
                Note that the '\n' and '\t' are the new-line and tab characters.
                <br />
                Every file and directory has a unique absolute path in the file
                system, which is the order of directories that must be opened to
                reach the file/directory itself, all concatenated by '/'s. Using
                the above example, the absolute path to file2.ext is
                "dir/subdir2/subsubdir2/file2.ext". Each directory name consists
                of letters, digits, and/or spaces. Each file name is of the form
                name.extension, where name and extension consist of letters,
                digits, and/or spaces.
                <br />
                Given a string input representing the file system in the
                explained format, return the length of the longest absolute path
                to a file in the abstracted file system. If there is no file in
                the system, return 0.
              </>
            }
            img={Leetcode388}
            examples={[
              {
                content: (
                  <>
                    Input: input = "dir\n\tsubdir1\n\tsubdir2\n\t\tfile.ext"
                    <br /> Output: 20
                    <br /> Explanation: We have only one file, and the absolute
                    path is "dir/subdir2/file.ext" of length 20.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: input =
                    "dir\n\tsubdir1\n\t\tfile1.ext\n\t\tsubsubdir1\n\tsubdir2\n\t\tsubsubdir2\n\t\t\tfile2.ext"
                    <br />
                    Output: 32 Explanation: We have two files: <br />
                    "dir/subdir1/file1.ext" of length 21 <br />
                    "dir/subdir2/subsubdir2/file2.ext" of length 32. <br />
                    We return 32 since it is the longest absolute path to a
                    file.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: input = "a" <br />
                    Output: 0 <br />
                    Explanation: We do not have any files, just a single
                    directory named "a".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= input.length &lt;= 10^4 <br />
                input may contain lowercase or uppercase English letters, a new
                line character '\n', a tab character '\t', a dot '.', a space '
                ', and digits.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `class T {
              public int depth;
              public int length;
              public T(int depth, int length) {
                this.depth = depth;
                this.length = length;
              }
            }
            
            class Solution {
              public int lengthLongestPath(String input) {
                int ans = 0;
                Stack<T> stack = new Stack<>();
                stack.push(new T(-1, 0));
            
                for (String token : input.split("\\n")) {
                  final int depth = getDepth(token);
                  token = token.replace("\\t", "");
                  while (depth <= stack.peek().depth)
                    stack.pop();
                  if (token.contains(".")) // file
                    ans = Math.max(ans, stack.peek().length + token.length());
                  else // directory + '/'
                    stack.push(new T(depth, stack.peek().length + token.length() + 1));
                }
            
                return ans;
              }
            
              private int getDepth(final String token) {
                return (int) token.chars().filter(c -> c == '\\t').count();
              }
            }
            `,
                output: `20`,
              },
            }}
          />
        ),
      },
      q319: {
        title: "Q389. Find the Difference (Q319)",
        content: (
          <Comp
            title="Q389. Find the Difference (Q319)"
            content1={
              <>
                You are given two strings s and t.
                <br />
                String t is generated by random shuffling string s and then add
                one more letter at a random position.
                <br />
                Return the letter that was added to t.
              </>
            }
            examples={[
              {
                content: (
                  <>
                    Input: s = "abcd", t = "abcde" <br />
                    Output: "e" <br />
                    Explanation: 'e' is the letter that was added.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "", t = "y"
                    <br />
                    Output: "y"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= s.length &lt;= 1000 <br />
                t.length == s.length + 1 <br />s and t consist of lowercase
                English letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {string} s
            * @param {string} t
            * @return {character}
            */
           var findTheDifference = function(s, t) {
             let ans = 0;
             for (let c of s)
               ans ^= c.charCodeAt(0);
             for (let c of t)
                 ans ^= c.charCodeAt(0);
             return String.fromCharCode(ans);
           };
           
           findTheDifference("","y")`,
                output: `y`,
              },
            }}
          />
        ),
      },
      q320: {
        title: "Q390. Elimination Game (Q320)",
        content: (
          <Comp
            title="Q390. Elimination Game (Q320)"
            content1={
              <>
                You have a list arr of all integers in the range [1, n] sorted
                in a strictly increasing order. Apply the following algorithm on
                arr:
                <br />
                Starting from left to right, remove the first number and every
                other number afterward until you reach the end of the list.
                <br />
                Repeat the previous step again, but this time from right to
                left, remove the rightmost number and every other number from
                the remaining numbers.
                <br />
                Keep repeating the steps again, alternating left to right and
                right to left, until a single number remains.
                <br />
                Given the integer n, return the last number that remains in arr.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 9 <br />
                    Output: 6 <br />
                    Explanation: <br />
                    arr = [1, 2, 3, 4, 5, 6, 7, 8, 9] <br />
                    arr = [2, 4, 6, 8] <br />
                    arr = [2, 6] <br />
                    arr = [6]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1<br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 10^9</>}
            tc="log n"
            sc="log n"
            codes={{
              Javascript: {
                code: `/**
            * @param {number} n
            * @return {number}
            */
           var lastRemaining = function(n) {
             return n == 1 ? 1 : 2 * (1 + parseInt(n / 2) - lastRemaining(parseInt(n / 2)));
           };
           
           lastRemaining(9)`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q321: {
        title: "Q391. Perfect Rectangle (Q321)",
        content: (
          <Comp
            title="Q391. Perfect Rectangle (Q321)"
            content1={
              <>
                Given an array rectangles where rectangles[i] = [xi, yi, ai, bi]
                represents an axis-aligned rectangle. The bottom-left point of
                the rectangle is (xi, yi) and the top-right point of it is (ai,
                bi).
                <br />
                Return true if all the rectangles together form an exact cover
                of a rectangular region.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                img: Leetcode391,
                content: (
                  <>
                    Input: rectangles =
                    [[1,1,3,3],[3,1,4,2],[3,2,4,4],[1,3,2,4],[2,3,3,4]]
                    <br />
                    Output: true
                    <br />
                    Explanation: All 5 rectangles together form an exact cover
                    of a rectangular region.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: rectangles =
                    [[1,1,2,3],[1,3,2,4],[3,1,4,2],[3,2,4,4]]
                    <br />
                    Output: false
                    <br />
                    Explanation: Because there is a gap between the two
                    rectangular regions.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: rectangles =
                    [[1,1,3,3],[3,1,4,2],[1,3,2,4],[2,2,4,4]]
                    <br />
                    Output: false <br />
                    Explanation: Because two of the rectangles overlap with each
                    other.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= rectangles.length &lt;= 2 * 10^4 <br />
                rectangles[i].length == 4 <br />
                -10^5 &lt;= xi, yi, ai, bi &lt;= 105
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
            // [[1,1,3,3],[3,1,4,2],[1,3,2,4],[2,2,4,4]]
            class Solution {
              public boolean isRectangleCover(int[][] rectangles) {
                int area = 0;
                int x1 = Integer.MAX_VALUE;
                int y1 = Integer.MAX_VALUE;
                int x2 = Integer.MIN_VALUE;
                int y2 = Integer.MIN_VALUE;
                Set<String> corners = new HashSet<>();
            
                for (int[] r : rectangles) {
                  area += (r[2] - r[0]) * (r[3] - r[1]);
                  x1 = Math.min(x1, r[0]);
                  y1 = Math.min(y1, r[1]);
                  x2 = Math.max(x2, r[2]);
                  y2 = Math.max(y2, r[3]);
            
                  // four points of current rectangle
                  String[] points = new String[] {
                    r[0] + " " + r[1],
                    r[0] + " " + r[3],
                    r[2] + " " + r[1],
                    r[2] + " " + r[3]
                  };
                  for (final String point : points)
                    if (!corners.add(point))
                      corners.remove(point);
                }
            
                if (corners.size() != 4)
                  return false;
                if (!corners.contains(x1 + " " + y1) ||
                    !corners.contains(x1 + " " + y2) ||
                    !corners.contains(x2 + " " + y1) ||
                    !corners.contains(x2 + " " + y2))
                  return false;
            
                return area == (x2 - x1) * (y2 - y1);
              }
            }
            `,
                output: `false`,
              },
            }}
          />
        ),
      },
      q322: {
        title: "Q392. Is Subsequence (Q322)",
        content: (
          <Comp
            title="Q392. Is Subsequence (Q322)"
            content1={
              <>
                Given two strings s and t, return true if s is a subsequence of
                t, or false otherwise.
                <br />A subsequence of a string is a new string that is formed
                from the original string by deleting some (can be none) of the
                characters without disturbing the relative positions of the
                remaining characters. (i.e., "ace" is a subsequence of "abcde"
                while "aec" is not).
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "abc", t = "ahbgdc" <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "axc", t = "ahbgdc"
                    <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= s.length &lt;= 100 <br />
                0 &lt;= t.length &lt;= 104 <br />s and t consist only of
                lowercase English letters.
              </>
            }
            fp={
              <>
                <b>Follow up: </b> Suppose there are lots of incoming s, say s1,
                s2, ..., sk where k &gt;= 109, and you want to check one by one
                to see if t has its subsequence. In this scenario, how would you
                change your code?
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {string} s
            * @param {string} t
            * @return {boolean}
            */
           var isSubsequence = function(s, t) {
             if (!s.length) return true;
             let i = 0;
             for (let c of t)
               if (s[i] == c && ++i == s.length)
                 return true;
             return false;
           };
           
           isSubsequence("abc","ahbgdc")`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q333: {
        title: "Q393. UTF-8 Validation (Q333)",
        content: (
          <Comp
            title="Q393. UTF-8 Validation (Q333)"
            content1={
              <>
                Given an integer array data representing the data, return
                whether it is a valid UTF-8 encoding.
                <br />
                A character in UTF8 can be from 1 to 4 bytes long, subjected to
                the following rules:
                <br />
                <br />
                For a 1-byte character, the first bit is a 0, followed by its
                Unicode code.
                <br />
                For an n-bytes character, the first n bits are all one's, the n
                + 1 bit is 0, followed by n - 1 bytes with the most significant
                2 bits being 10.
                <br />
                This is how the UTF-8 encoding would work:
                <pre>
                  {`
   Char. number range  |        UTF-8 octet sequence
   (hexadecimal)    |              (binary)
--------------------+---------------------------------------------
0000 0000-0000 007F | 0xxxxxxx
0000 0080-0000 07FF | 110xxxxx 10xxxxxx
0000 0800-0000 FFFF | 1110xxxx 10xxxxxx 10xxxxxx
0001 0000-0010 FFFF | 11110xxx 10xxxxxx 10xxxxxx 10xxxxxx   
                  `}
                </pre>
                Note: The input is an array of integers. Only the least
                significant 8 bits of each integer is used to store the data.
                This means each integer represents only 1 byte of data.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: data = [197,130,1]
                    <br /> Output: true
                    <br /> Explanation: data represents the octet sequence:
                    11000101 10000010 00000001.
                    <br /> It is a valid utf-8 encoding for a 2-bytes character
                    followed by a 1-byte character.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: data = [235,140,4] <br />
                    Output: false
                    <br />
                    Explanation: data represented the octet sequence: 11101011
                    10001100 00000100.
                    <br />
                    The first 3 bits are all one's and the 4th bit is 0 means it
                    is a 3-bytes character.
                    <br />
                    The next byte is a continuation byte which starts with 10
                    and that's correct.
                    <br />
                    But the second continuation byte does not start with 10, so
                    it is invalid.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= data.length &lt;= 2 * 10^4 <br />0 &lt;= data[i] &lt;=
                255
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {number[]} data
            * @return {boolean}
            */
           var validUtf8 = function(data) {
             let leftToCheck = 0;
             for (let d of data)
               if (leftToCheck == 0) {
                 if ((d >> 3) == 0b11110)
                   leftToCheck = 3;
                 else if ((d >> 4) == 0b1110)
                   leftToCheck = 2;
                 else if ((d >> 5) == 0b110)
                   leftToCheck = 1;
                 else if ((d >> 7) == 0b0)
                   leftToCheck = 0;
                 else
                   return false;
               } else {
                 if ((d >> 6) != 0b10)
                   return false;
                 --leftToCheck;
               }
             return leftToCheck == 0;
           };
           
           validUtf8([197,130,1])`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q334: {
        title: "Q394. Decode String (Q334)",
        content: (
          <Comp
            title="Q394. Decode String (Q334)"
            content1={
              <>
                Given an encoded string, return its decoded string.
                <br />
                The encoding rule is: k[encoded_string], where the
                encoded_string inside the square brackets is being repeated
                exactly k times. Note that k is guaranteed to be a positive
                integer.
                <br />
                You may assume that the input string is always valid; there are
                no extra white spaces, square brackets are well-formed, etc.
                <br />
                Furthermore, you may assume that the original data does not
                contain any digits and that digits are only for those repeat
                numbers, k. For example, there will not be input like 3a or
                2[4].
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "3[a]2[bc]"
                    <br />
                    Output: "aaabcbc"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "3[a2[c]]" <br />
                    Output: "accaccacc"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "2[abc]3[cd]ef"
                    <br />
                    Output: "abcabccdcdcdef"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 30
                <br /> s consists of lowercase English letters, digits, and
                square brackets '[]'.
                <br />s is guaranteed to be a valid input. All the integers in s
                are in the range [1, 300].
              </>
            }
            tc="∣ans∣"
            sc="∣ans∣"
            codes={{
              Javascript: {
                code: `/**
            * @param {string} s
            * @return {string}
            */
           var decodeString = function(s) {
             const stack = [] 
             let currStr = ''
             let currNum = 0
           
             for(let c of s){
               if(Number.isInteger(+c))
               currNum = currNum * 10 + parseInt(c)
               else{
                 if(c == '['){
                 stack.push([currStr, currNum])
                 currStr = ''
                 currNum = 0
               } else if(c == ']'){
                 let [ prevStr, num ] = stack.pop()
                 currStr = prevStr + Array(num).fill(currStr).join('')
               }else
                   currStr += c
               } 
             }
             return currStr
           };
           decodeString("3[a]2[bc]")`,
                output: `aaabcbc`,
              },
            }}
          />
        ),
      },
      q335: {
        title:
          "Q395. Longest Substring with At Least K Repeating Characters (Q335)",
        content: (
          <Comp
            title="Q395. Longest Substring with At Least K Repeating Characters (Q335)"
            content1={
              <>
                Given a string s and an integer k, return the length of the
                longest substring of s such that the frequency of each character
                in this substring is greater than or equal to k.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "aaabb", k = 3 <br />
                    Output: 3 <br />
                    Explanation: The longest substring is "aaa", as 'a' is
                    repeated 3 times.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "ababbc", k = 2<br />
                    Output: 5<br />
                    Explanation: The longest substring is "ababb", as 'a' is
                    repeated 2 times and 'b' is repeated 3 times.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^4 <br />
                s consists of only lowercase English letters. <br />1 &lt;= k
                &lt;= 10^5
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {string} s
            * @param {number} k
            * @return {number}
            */
           function longestSubstringWithNUniqueCharacters(s, k, n) {
             let ans = 0;
             let uniqueChars = 0; 
             let noLessThanK = 0; 
             const count = Array(128).fill(0);
             for (let l = 0, r = 0; r < s.length; ++r) {
               if (count[s[r].charCodeAt(0)] == 0)
                 ++uniqueChars;
               if (++count[s[r].charCodeAt(0)] == k)
                 ++noLessThanK;
               while (uniqueChars > n) {
                 if (count[s[l].charCodeAt(0)] == k)
                   --noLessThanK;
                 if (--count[s[l].charCodeAt(0)] == 0)
                   --uniqueChars;
                 ++l;
               }
               if (noLessThanK == n) 
                 ans = Math.max(ans, r - l + 1);
             }
             return ans;
           }
           
           
           var longestSubstring = function(s, k) {
             let ans = 0;
             for (let n = 1; n <= 26; ++n)
               ans = Math.max(ans, longestSubstringWithNUniqueCharacters(s, k, n));
           
             return ans;
           };
           
           longestSubstring("aaabb",3)`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q336: {
        title: "Q396. Rotate Function (Q336)",
        content: (
          <Comp
            title="Q396. Rotate Function (Q336)"
            content1={
              <>
                You are given an integer array nums of length n.
                <br />
                Assume arrk to be an array obtained by rotating nums by k
                positions clock-wise. We define the rotation function F on nums
                as follow:
                <br />
                F(k) = 0 * arrk[0] + 1 * arrk[1] + ... + (n - 1) * arrk[n - 1].
                <br />
                Return the maximum value of F(0), F(1), ..., F(n-1).
                <br />
                The test cases are generated so that the answer fits in a 32-bit
                integer.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [4,3,2,6]
                    <br /> Output: 26
                    <br /> Explanation:
                    <br /> F(0) = (0 * 4) + (1 * 3) + (2 * 2) + (3 * 6) = 0 + 3
                    + 4 + 18 = 25
                    <br /> F(1) = (0 * 6) + (1 * 4) + (2 * 3) + (3 * 2) = 0 + 4
                    + 6 + 6 = 16
                    <br /> F(2) = (0 * 2) + (1 * 6) + (2 * 4) + (3 * 3) = 0 + 6
                    + 8 + 9 = 23
                    <br /> F(3) = (0 * 3) + (1 * 2) + (2 * 6) + (3 * 4) = 0 + 2
                    + 12 + 12 = 26
                    <br /> So the maximum value of F(0), F(1), F(2), F(3) is
                    F(3) = 26.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [100]
                    <br /> Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == nums.length <br />
                1 &lt;= n &lt;= 10^5 <br />
                -100 &lt;= nums[i] &lt;= 100
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {number[]} nums
            * @return {number}
            */
           var maxRotateFunction = function(nums) {
             const sum = nums.reduce((a,b)=>a+b,0);
             let f = 0;
             for (let i = 0; i < nums.length; ++i)
               f += i * nums[i];
             let ans = f;
             for (let i = nums.length - 1; i >= 0; --i) {
               f += sum - nums.length * nums[i];
               ans = Math.max(ans, f);
             }
             return ans;
           };
           
           maxRotateFunction([100])`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q337: {
        title: "Q397. Integer Replacement (Q337)",
        content: (
          <Comp
            title="Q397. Integer Replacement (Q337)"
            content1={
              <>
                Given a positive integer n, you can apply one of the following
                operations:
                <br />
                If n is even, replace n with n / 2.
                <br />
                If n is odd, replace n with either n + 1 or n - 1.
                <br />
                Return the minimum number of operations needed for n to become
                1.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 8<br />
                    Output: 3<br />
                    Explanation: 8 -&gt; 4 -&gt; 2 -&gt; 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 7<br />
                    Output: 4<br />
                    Explanation: 7 -&gt; 8 -&gt; 4 -&gt; 2 -&gt; 1<br />
                    or 7 -&gt; 6 -7gt; 3 -&gt; 2 -&gt; 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 4 <br />
                    Output: 2
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 2^31 - 1</>}
            tc="log n"
            sc="n"
            codes={{
              Java: {
                code: `
            // n = 8
            class Solution {
              public int integerReplacement(long n) {
                int ans = 0;
                for (; n > 1; ++ans)
                  if ((n & 1) == 0) 
                    n >>= 1;
                  else if (n == 3 || ((n >> 1) & 1) == 0) 
                    --n;
                  else
                    ++n;
                return ans;
              }
            }
            `,
                output: `3`,
              },
            }}
          />
        ),
      },
      q338: {
        title: "Q398. Random Pick Index (Q338)",
        content: (
          <Comp
            title="Q398. Random Pick Index (Q338)"
            content1={
              <>
                Given an integer array nums with possible duplicates, randomly
                output the index of a given target number. You can assume that
                the given target number must exist in the array.
                <br />
                Implement the Solution class:
                <br />
                Solution(int[] nums) Initializes the object with the array nums.
                <br />
                int pick(int target) Picks a random index i from nums where
                nums[i] == target. If there are multiple valid i's, then each
                index should have an equal probability of returning.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br />
                    ["Solution", "pick", "pick", "pick"]
                    <br />
                    [[[1, 2, 3, 3, 3]], [3], [1], [3]]
                    <br />
                    Output
                    <br />
                    [null, 4, 0, 2]
                    <br />
                    Explanation
                    <br />
                    <br /> Solution solution = new Solution([1, 2, 3, 3, 3]);
                    <br /> solution.pick(3); // It should return either index 2,
                    3, or 4 randomly. Each index should have equal probability
                    of returning.
                    <br /> solution.pick(1); // It should return 0. Since in the
                    array only nums[0] is equal to 1.
                    <br /> solution.pick(3); // It should return either index 2,
                    3, or 4 randomly. Each index should have equal probability
                    of returning.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 2 * 10^4 <br />
                -2^31 &lt;= nums[i] &lt;= 2^31 - 1 <br />
                target is an integer from nums. <br />
                At most 104 calls will be made to pick.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `class Solution {
              public Solution(int[] nums) {
                this.nums = nums;
              }
            
              public int pick(int target) {
                int ans = -1;
                int range = 0;
            
                for (int i = 0; i < nums.length; ++i)
                  if (nums[i] == target && rand.nextInt(++range) == 0)
                    ans = i;
            
                return ans;
              }
            
              private int[] nums;
              private Random rand = new Random();
            }
            
            /**
             * Your Solution object will be instantiated and called as such:
             * Solution obj = new Solution(nums);
             * int param_1 = obj.pick(target);
             */`,
                output: `[null,2,0,2]`,
              },
            }}
          />
        ),
      },
      q339: {
        title: "Q399. Evaluate Division (Q339)",
        content: (
          <Comp
            title="Q399. Evaluate Division (Q339)"
            content1={
              <>
                You are given an array of variable pairs equations and an array
                of real numbers values, where equations[i] = [Ai, Bi] and
                values[i] represent the equation Ai / Bi = values[i]. Each Ai or
                Bi is a string that represents a single variable.
                <br />
                You are also given some queries, where queries[j] = [Cj, Dj]
                represents the jth query where you must find the answer for Cj /
                Dj = ?.
                <br />
                Return the answers to all queries. If a single answer cannot be
                determined, return -1.0.
                <br />
                Note: The input is always valid. You may assume that evaluating
                the queries will not result in division by zero and that there
                is no contradiction.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: equations = [["a","b"],["b","c"]], values =
                    [2.0,3.0], queries =
                    [["a","c"],["b","a"],["a","e"],["a","a"],["x","x"]]
                    <br /> Output: [6.00000,0.50000,-1.00000,1.00000,-1.00000]
                    <br /> Explanation:
                    <br /> Given: a / b = 2.0, b / c = 3.0
                    <br /> queries are: a / c = ?, b / a = ?, a / e = ?, a / a =
                    ?, x / x = ?
                    <br /> return: [6.0, 0.5, -1.0, 1.0, -1.0 ]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: equations = [["a","b"],["b","c"],["bc","cd"]], values
                    = [1.5,2.5,5.0], queries =
                    [["a","c"],["c","b"],["bc","cd"],["cd","bc"]]
                    <br /> Output: [3.75000,0.40000,5.00000,0.20000]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: equations = [["a","b"]], values = [0.5], queries =
                    [["a","b"],["b","a"],["a","c"],["x","y"]]
                    <br /> Output: [0.50000,2.00000,-1.00000,-1.00000]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= equations.length &lt;= 20 <br />
                equations[i].length == 2<br />
                1 &lt;= Ai.length, Bi.length &lt;= 5<br />
                values.length == equations.length
                <br />
                0.0 &lt; values[i] &lt;= 20.0
                <br />
                1 &lt;= queries.length &lt;= 20
                <br />
                queries[i].length == 2<br />
                1 &lt;= Cj.length, Dj.length &lt;= 5<br />
                Ai, Bi, Cj, Dj consist of lower case English letters and digits.
              </>
            }
            tc="e + q"
            sc="e"
            codes={{
              Java: {
                code: `
              //  equations = [["a","b"],["b","c"]], values = [2.0,3.0], queries = [["a","c"],["b","a"],["a","e"],["a","a"],["x","x"]]
                class Solution {
              public double[] calcEquation(List<List<String>> equations, double[] values,
                                           List<List<String>> queries) {
                double[] ans = new double[queries.size()];
                Map<String, Map<String, Double>> graph = new HashMap<>();
            
                for (int i = 0; i < equations.size(); ++i) {
                  final String A = equations.get(i).get(0);
                  final String B = equations.get(i).get(1);
                  graph.putIfAbsent(A, new HashMap<>());
                  graph.putIfAbsent(B, new HashMap<>());
                  graph.get(A).put(B, values[i]);
                  graph.get(B).put(A, 1.0 / values[i]);
                }
            
                for (int i = 0; i < queries.size(); ++i) {
                  final String A = queries.get(i).get(0);
                  final String C = queries.get(i).get(1);
                  if (!graph.containsKey(A) || !graph.containsKey(C)) {
                    ans[i] = -1.0;
                    continue;
                  }
                  Set<String> seen = new HashSet<>();
                  ans[i] = divide(graph, A, C, seen);
                }
            
                return ans;
              }
            
              private double divide(Map<String, Map<String, Double>> graph, final String A, final String C,
                                    Set<String> seen) {
                if (A.equals(C))
                  return 1.0;
                seen.add(A);
                for (final String B : graph.get(A).keySet()) {
                  if (seen.contains(B))
                    continue;
                  final double res = divide(graph, B, C, seen); 
                  if (res > 0)                                  
                    return graph.get(A).get(B) * res;         
                }
            
                return -1.0;
              }
            }
            `,
                output: `[6.00000,0.50000,-1.00000,1.00000,-1.00000]`,
              },
            }}
          />
        ),
      },
      q340: {
        title: "Q400. Nth Digit (Q340)",
        content: (
          <Comp
            title="Q400. Nth Digit (Q340)"
            content1={
              <>
                Given an integer n, return the nth digit of the infinite integer
                sequence [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, ...].
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 3 <br />
                    Output: 3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 11 <br />
                    Output: 0 <br /> Explanation: The 11th digit of the sequence
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, ... is a 0, which is part
                    of the number 10.
                  </>
                ),
              },
            ]}
            constraints={<>1&lt;= n &lt;= 2^31 - 1</>}
            tc="log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
            * @param {number} n
            * @return {number}
            */
           var findNthDigit = function(n) {
             let digitSize = 1;
             let startNum = 1;
             let count = 9;
             while (digitSize * count < n) {
               n -= digitSize * count;
               ++digitSize;
               startNum *= 10;
               count *= 10;
             }
             let targetNum = startNum + parseInt((n - 1) / digitSize)
             let index = (n - 1) % digitSize;
             return +String(targetNum)[index] 
           };
           
           findNthDigit(3)`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q341: {
        title: "Q401. Binary Watch (Q341)",
        content: (
          <Comp
            title="Q401. Binary Watch (Q341)"
            content1={
              <>
                A binary watch has 4 LEDs on the top which represent the hours
                (0-11), and the 6 LEDs on the bottom represent the minutes
                (0-59). Each LED represents a zero or one, with the least
                significant bit on the right.
                <br />
                For example, the below binary watch reads "4:51".
                <br />
                Given an integer turnedOn which represents the number of LEDs
                that are currently on, return all possible times the watch could
                represent. You may return the answer in any order.
                <br />
                The hour must not contain a leading zero.
                <br />
                For example, "01:00" is not valid. It should be "1:00". <br />
                The minute must be consist of two digits and may contain a
                leading zero.
                <br />
                For example, "10:2" is not valid. It should be "10:02".
              </>
            }
            img={Leetcode401}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: turnedOn = 1<br />
                    Output:
                    ["0:01","0:02","0:04","0:08","0:16","0:32","1:00","2:00","4:00","8:00"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: turnedOn = 9<br />
                    Output: []
                  </>
                ),
              },
            ]}
            constraints={<>0 &lt;= turnedOn &lt;= 10</>}
            tc="2^n"
            sc="2^n"
            codes={{
              Javascript: {
                code: `/**
            * @param {number} turnedOn
            * @return {string[]}
            */
           
           const hours = [1, 2, 4, 8];
           const minutes = [1, 2, 4, 8, 16, 32];
           
           function dfs(n,s,h,m,ans){
             if (n == 0) {
             const time = String(h) + ":" + (m < 10 ? "0" : "") + String(m);
               ans.push(time);
               return;
             }
             for (let i = s; i < hours.length + minutes.length; ++i)
               if (i < 4 && h + hours[i] < 12)
                 dfs(n - 1, i + 1, h + hours[i], m, ans);
               else if (i >= 4 && m + minutes[i - 4] < 60)
                 dfs(n - 1, i + 1, h, m + minutes[i - 4], ans);
           }
           
           function readBinaryWatch(turnedOn) {
             const ans = [];
             dfs(turnedOn, 0, 0, 0, ans);
             return ans;
           }
           
           readBinaryWatch(9)`,
                output: `[]`,
              },
            }}
          />
        ),
      },
      q342: {
        title: "Q402. Remove K Digits (Q342)",
        content: (
          <Comp
            title="Q402. Remove K Digits (Q342)"
            content1={
              <>
                Given string num representing a non-negative integer num, and an
                integer k, return the smallest possible integer after removing k
                digits from num.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: num = "1432219", k = 3 <br />
                    Output: "1219" <br />
                    Explanation: Remove the three digits 4, 3, and 2 to form the
                    new number 1219 which is the smallest.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num = "10200", k = 1 <br />
                    Output: "200" <br />
                    Explanation: Remove the leading 1 and the number is 200.
                    Note that the output must not contain leading zeroes.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num = "10", k = 2<br />
                    Output: "0"
                    <br />
                    Explanation: Remove all the digits from the number and it is
                    left with nothing which is 0.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= k &lt;= num.length &lt;= 10^5
                <br />
                num consists of only digits.
                <br />
                num does not have any leading zeros except for the zero itself.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
            * @param {string} num
            * @param {number} k
            * @return {string}
            */
           var removeKdigits = function(num, k) {
             if (num.length == k)
               return "0";
           
             const sb = [];
             const stack = [];
           
             for (let i = 0; i < num.length; ++i) {
               while (k > 0 && stack.length && stack.slice(-1)[0] > num[i]) {
                 stack.pop();
                 --k;
               }
               stack.push(num[i]);
             }
           
             while (k-- > 0)
               stack.pop();
           
               for (let c of stack) {
                 if (c == '0' && sb.length == 0)
                   continue;
                 sb.push(c);
               }
             return sb.length == 0 ? "0" : sb.join(""); 
           };
           
           removeKdigits("10200",1)`,
                output: `200`,
              },
            }}
          />
        ),
      },
      q343: {
        title: "Q403. Frog Jump (Q343)",
        content: (
          <Comp
            title="Q403. Frog Jump (Q343)"
            content1={
              <>
                A frog is crossing a river. The river is divided into some
                number of units, and at each unit, there may or may not exist a
                stone. The frog can jump on a stone, but it must not jump into
                the water.
                <br />
                Given a list of stones' positions (in units) in sorted ascending
                order, determine if the frog can cross the river by landing on
                the last stone. Initially, the frog is on the first stone and
                assumes the first jump must be 1 unit.
                <br />
                If the frog's last jump was k units, its next jump must be
                either k - 1, k, or k + 1 units. The frog can only jump in the
                forward direction.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: stones = [0,1,3,5,6,8,12,17] <br />
                    Output: true
                    <br />
                    Explanation: The frog can jump to the last stone by jumping
                    1 unit to the 2nd stone, then 2 units to the 3rd stone, then
                    2 units to the 4th stone, then 3 units to the 6th stone, 4
                    units to the 7th stone, and 5 units to the 8th stone.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: stones = [0,1,2,3,4,8,9,11]
                    <br />
                    Output: false
                    <br />
                    Explanation: There is no way to jump to the last stone as
                    the gap between the 5th and 6th stone is too large.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= stones.length &lt;= 2000 <br />
                0 &lt;= stones[i] &lt;= 2^31 - 1 <br />
                stones[0] == 0 <br />
                stones is sorted in a strictly increasing order.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
            * @param {number[]} stones
            * @return {boolean}
            */
           var canCross = function(stones) {
             const n = stones.length
             const dp = Array.from(Array(n),() => Array(n + 1).fill(0));
             dp[0][1] = 1;
             for (let i = 1; i < n; ++i)
               for (let j = 0; j < i; ++j) {
                 const k = stones[i] - stones[j];
                 if (k <= n && dp[j][k] == 1) {
                   dp[i][k - 1] = 1;
                   dp[i][k] = 1;
                   dp[i][k + 1] = 1;
                 }
             }
             return dp[n - 1].some(a => a == 1);
           };
           
           canCross([0,1,2,3,4,8,9,11])`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q344: {
        title: "Q404. Sum of Left Leaves (Q344)",
        content: (
          <Comp
            title="Q404. Sum of Left Leaves (Q344)"
            content1={
              <>
                Given the root of a binary tree, return the sum of all left
                leaves.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                img: Leetcode404,
                content: (
                  <>
                    Input: root = [3,9,20,null,null,15,7]
                    <br />
                    Output: 24
                    <br />
                    Explanation: There are two left leaves in the binary tree,
                    with values 9 and 15 respectively.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1] <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 1000].
                <br /> -1000 &lt;= Node.val &lt;= 1000
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
              this.val = (val===undefined ? 0 : val)
              this.left = (left===undefined ? null : left)
              this.right = (right===undefined ? null : right)
          }
          
          /**
           * @param {TreeNode} root
           * @return {number}
           */
          var sumOfLeftLeaves = function(root) {
            if (!root) return 0;
            let ans = 0;
            const stack = [];
            stack.push(root);
            while (stack.length && root) {
              root = stack.pop();
              if (root.left) {
                if (!root.left.left && !root.left.right)
                  ans += root.left.val;
                else
                  stack.push(root.left);
              }
              if (root.right)
                stack.push(root.right);
            }
            return ans;  
          };
          
          const t = new TreeNode(3)
          t.left = new TreeNode(9)
          t.right = new TreeNode(20)
          t.right.left = new TreeNode(15)
          t.right.left = new TreeNode(7)
          sumOfLeftLeaves(t)`,
                output: `16`,
              },
            }}
          />
        ),
      },
      q345: {
        title: "Q405. Convert a Number to Hexadecimal (Q345)",
        content: (
          <Comp
            title="Q405. Convert a Number to Hexadecimal (Q345)"
            content1={
              <>
                Given an integer num, return a string representing its
                hexadecimal representation. For negative integers, two’s
                complement method is used.
                <br />
                All the letters in the answer string should be lowercase
                characters, and there should not be any leading zeros in the
                answer except for the zero itself.
                <br />
                Note: You are not allowed to use any built-in library method to
                directly solve this problem.
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: num = 26 <br />
                    Output: "1a"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num = -1
                    <br />
                    Output: "ffffffff"
                  </>
                ),
              },
            ]}
            constraints={<>-2^31 &lt;= num &lt;= 2^31 - 1</>}
            tc="log16 n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
            * @param {number} num
            * @return {string}
            */
           var toHex = function(num) {
             const hex = ['0', '1', '2', '3', '4', '5', '6', '7',
                                   '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
             
             const sb = [];
             while (num != 0) {
               sb.push(hex[num & 0xf]);
               num >>>= 4;
             }
             return sb.length == 0 ? "0" : sb.reverse().join("");  
           };
           
           toHex(26)`,
                output: `1a`,
              },
            }}
          />
        ),
      },
      q346: {
        title: "Q406. Queue Reconstruction by Height (Q346)",
        content: (
          <Comp
            title="Q406. Queue Reconstruction by Height (Q346)"
            content1={
              <>
                You are given an array of people, people, which are the
                attributes of some people in a queue (not necessarily in order).
                Each people[i] = [hi, ki] represents the ith person of height hi
                with exactly ki other people in front who have a height greater
                than or equal to hi.
                <br />
                Reconstruct and return the queue that is represented by the
                input array people. The returned queue should be formatted as an
                array queue, where queue[j] = [hj, kj] is the attributes of the
                jth person in the queue (queue[0] is the person at the front of
                the queue).
              </>
            }
            img={null}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: people = [[7,0],[4,4],[7,1],[5,0],[6,1],[5,2]]
                    <br /> Output: [[5,0],[7,0],[5,2],[6,1],[4,4],[7,1]]
                    <br /> Explanation:
                    <br /> Person 0 has height 5 with no other people taller or
                    the same height in front.
                    <br /> Person 1 has height 7 with no other people taller or
                    the same height in front.
                    <br /> Person 2 has height 5 with two persons taller or the
                    same height in front, which is person 0 and 1.
                    <br /> Person 3 has height 6 with one person taller or the
                    same height in front, which is person 1.
                    <br /> Person 4 has height 4 with four people taller or the
                    same height in front, which are people 0, 1, 2, and 3.
                    <br /> Person 5 has height 7 with one person taller or the
                    same height in front, which is person 1.
                    <br /> Hence [[5,0],[7,0],[5,2],[6,1],[4,4],[7,1]] is the
                    reconstructed queue.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: people = [[6,0],[5,0],[4,0],[3,2],[2,2],[1,4]]
                    <br /> Output: [[4,0],[5,0],[2,2],[3,2],[1,4],[6,0]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= people.length &lt;= 2000 <br />
                0 &lt;= hi &lt;= 106 <br />
                0 &lt;= ki &lt; people.length <br />
                It is guaranteed that the queue can be reconstructed.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `
            [[6,0],[5,0],[4,0],[3,2],[2,2],[1,4]]
class Solution {
  public int[][] reconstructQueue(int[][] people) {
    List<int[]> ans = new ArrayList<>();

    Arrays.sort(people, (a, b) -> a[0] == b[0] ? a[1] - b[1] : b[0] - a[0]);

    for (final int[] p : people)
      ans.add(p[1], p);

    return ans.toArray(new int[ans.size()][]);
  }
}
`,
                output: `[[4,0],[5,0],[2,2],[3,2],[1,4],[6,0]]`,
              },
            }}
          />
        ),
      },
      q347: {
        title: "Q407. Trapping Rain Water IIQ (Q347)",
        content: (
          <Comp
            title="Q407. Trapping Rain Water IIQ (Q347)"
            content1={
              <>
                Given an m x n integer matrix heightMap representing the height
                of each unit cell in a 2D elevation map, return the volume of
                water it can trap after raining.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode407,
                content: (
                  <>
                    Input: heightMap =
                    [[1,4,3,1,3,2],[3,2,1,3,2,4],[2,3,3,2,3,1]]
                    <br /> Output: 4
                    <br /> Explanation: After the rain, water is trapped between
                    the blocks.
                    <br /> We have two small ponds 1 and 3 units trapped. The
                    total volume of water trapped is 4.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: heightMap =
                    [[3,3,3,3,3],[3,2,2,2,3],[3,2,1,2,3],[3,2,2,2,3],[3,3,3,3,3]]
                    <br /> Output: 10
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == heightMap.length <br />
                n == heightMap[i].length <br />
                1 &lt;= m, n &lt;= 200 <br />0 &lt;= heightMap[i][j] &lt;= 2 *
                104
              </>
            }
            tc="mn log mn"
            sc="mn"
            codes={{
              Javascript: {
                code: `
            // [[1,4,3,1,3,2],[3,2,1,3,2,4],[2,3,3,2,3,1]]
            class T {
              public int i;
              public int j;
              public int h; 
              public T(int i, int j, int h) {
                this.i = i;
                this.j = j;
                this.h = h;
              }
            }
            
            class Solution {
              public int trapRainWater(int[][] heightMap) {
                final int m = heightMap.length;
                final int n = heightMap[0].length;
                final int[] dirs = {0, 1, 0, -1, 0};
                int ans = 0;
                PriorityQueue<T> pq = new PriorityQueue<>((a, b) -> a.h - b.h);
                boolean[][] seen = new boolean[m][n];
            
                for (int i = 0; i < m; ++i) {
                  pq.offer(new T(i, 0, heightMap[i][0]));
                  pq.offer(new T(i, n - 1, heightMap[i][n - 1]));
                  seen[i][0] = true;
                  seen[i][n - 1] = true;
                }
            
                for (int j = 1; j < n - 1; ++j) {
                  pq.offer(new T(0, j, heightMap[0][j]));
                  pq.offer(new T(m - 1, j, heightMap[m - 1][j]));
                  seen[0][j] = true;
                  seen[m - 1][j] = true;
                }
            
                while (!pq.isEmpty()) {
                  final int i = pq.peek().i;
                  final int j = pq.peek().j;
                  final int h = pq.poll().h;
                  for (int k = 0; k < 4; ++k) {
                    final int x = i + dirs[k];
                    final int y = j + dirs[k + 1];
                    if (x < 0 || x == m || y < 0 || y == n)
                      continue;
                    if (seen[x][y])
                      continue;
                    if (heightMap[x][y] < h) {
                      ans += h - heightMap[x][y];
                      pq.offer(new T(x, y, h)); 
                    } else {
                      pq.offer(new T(x, y, heightMap[x][y]));
                    }
                    seen[x][y] = true;
                  }
                }
            
                return ans;
              }
            }
            `,
                output: `4`,
              },
            }}
          />
        ),
      },
      q348: {
        title: "Q409. Longest Palindrome (Q348)",
        content: (
          <Comp
            title="Q409. Longest Palindrome (Q348)"
            content1={
              <>
                Given a string s which consists of lowercase or uppercase
                letters, return the length of the longest palindrome that can be
                built with those letters.
                <br />
                Letters are case sensitive, for example, "Aa" is not considered
                a palindrome here.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "abccccdd"
                    <br />
                    Output: 7<br />
                    Explanation:
                    <br />
                    One longest palindrome that can be built is "dccaccd", whose
                    length is 7.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "a"
                    <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "bb"
                    <br />
                    Output: 2
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 2000
                <br />s consists of lowercase and/or uppercase English letters
                only.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               var longestPalindrome = function(s) {
                 let ans = 0;
                 const count = Array(128).fill(0);
               
                 for (let c of s)
                   ++count[c.charCodeAt(0)];
               
                 for (let c of count)
                   ans += c % 2 == 0 ? c : c - 1;
               
                 const hasOddCount = count.some(c => c % 2 == 1);
               
                 return ans + (hasOddCount ? 1 : 0);
               };
               
               longestPalindrome("abccccdd")`,
                output: `7`,
              },
            }}
          />
        ),
      },
      q349: {
        title: "Q410. Split Array Largest Sum (Q349)",
        content: (
          <Comp
            title="Q410. Split Array Largest Sum (Q349)"
            content1={
              <>
                Given an array nums which consists of non-negative integers and
                an integer m, you can split the array into m non-empty
                continuous subarrays.
                <br />
                Write an algorithm to minimize the largest sum among these m
                subarrays.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [7,2,5,10,8],
                    <br /> m = 2 <br />
                    Output: 18 <br />
                    Explanation: <br />
                    There are four ways to split nums into two subarrays. <br />
                    The best way is to split it into [7,2,5] and [10,8], <br />
                    where the largest sum among the two subarrays is only 18.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4,5],
                    <br /> m = 2<br />
                    Output: 9
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,4,4],
                    <br /> m = 3<br />
                    Output: 4
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 1000
                <br />
                0 &lt;= nums[i] &lt;= 1^6
                <br />1 &lt;= m &lt;= min(50, nums.length)
              </>
            }
            tc="m.n^2"
            sc="mn"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} m
                * @return {number}
                */
               var splitArray = function(nums, m) {
                 const n = nums.length;
                 const dp = Array.from(Array(n + 1),()=>Array(m + 1).fill(Number.MAX_SAFE_INTEGER));
                 const prefix = Array(n + 1).fill(0);
                 for (let i = 1; i <= n; ++i) {
                   prefix[i] = prefix[i - 1] + nums[i - 1];
                   dp[i][1] = prefix[i];
                 }
                 for (let k = 2; k <= m; ++k)
                   for (let i = k; i <= n; ++i)
                     for (let j = k - 1; j < i; ++j)
                     dp[i][k] = Math.min(dp[i][k], Math.max(dp[j][k - 1], prefix[i] - prefix[j]));
                 return dp[n][m];    
               };
               
               splitArray([1,4,4],3)`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q350: {
        title: "Q412. Fizz Buzz (Q350)",
        content: (
          <Comp
            title="Q412. Fizz Buzz (Q350)"
            content1={
              <>
                Given an integer n, return a string array answer (1-indexed)
                where:
                <br />
                answer[i] == "FizzBuzz" if i is divisible by 3 and 5.
                <br />
                answer[i] == "Fizz" if i is divisible by 3.
                <br />
                answer[i] == "Buzz" if i is divisible by 5.
                <br />
                answer[i] == i (as a string) if none of the above conditions are
                true.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 3 <br />
                    Output: ["1","2","Fizz"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 5<br />
                    Output: ["1","2","Fizz","4","Buzz"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 15
                    <br />
                    Output:
                    ["1","2","Fizz","4","Buzz","Fizz","7","8","Fizz","Buzz","11","Fizz","13","14","FizzBuzz"]
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 104</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {string[]}
                */
               var fizzBuzz = function(n) {
                 const res = [];
                 for (let i = 1; i <= n; ++i) {
                   const s = [];
                   if (i % 3 == 0)
                     s.push("Fizz");
                   if (i % 5 == 0)
                     s.push("Buzz");
                   res.push(s.length == 0 ? String(i) : s.join(""));
                 }
                 return res;  
               };
               
               fizzBuzz(5)`,
                output: `[ '1', '2', 'Fizz', '4', 'Buzz' ]`,
              },
            }}
          />
        ),
      },
      q351: {
        title: "Q413. Arithmetic Slices (Q351)",
        content: (
          <Comp
            title="Q413. Arithmetic Slices (Q351)"
            content1={
              <>
                An integer array is called arithmetic if it consists of at least
                three elements and if the difference between any two consecutive
                elements is the same.
                <br />
                For example, [1,3,5,7,9], [7,7,7,7], and [3,-1,-5,-9] are
                arithmetic sequences.
                <br /> Given an integer array nums, return the number of
                arithmetic subarrays of nums.
                <br />A subarray is a contiguous subsequence of the array.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4]
                    <br />
                    Output: 3<br />
                    Explanation: We have 3 arithmetic slices in nums: [1, 2, 3],
                    [2, 3, 4] and [1,2,3,4] itself.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1] <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 5000 <br />
                -1000 &lt;= nums[i] &lt;= 1000
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var numberOfArithmeticSlices = function(nums) {
                 let ans = 0;
                 let dp = 0;
                 for (let i = 2; i < nums.length; ++i) {
                   if (nums[i] - nums[i - 1] == nums[i - 1] - nums[i - 2])
                     ans += ++dp;
                   else
                     dp = 0;
                 }
                 return ans;   
               };
               
               numberOfArithmeticSlices([1,2,3,4])`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q352: {
        title: "Q414. Third Maximum Number (Q352)",
        content: (
          <Comp
            title="Q414. Third Maximum Number (Q352)"
            content1={
              <>
                Given an integer array nums, return the third distinct maximum
                number in this array. If the third maximum does not exist,
                return the maximum number.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [3,2,1] <br />
                    Output: 1 <br />
                    Explanation: <br />
                    The first distinct maximum is 3. <br />
                    The second distinct maximum is 2. <br />
                    The third distinct maximum is 1.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2] <br />
                    Output: 2 <br />
                    Explanation: <br />
                    The first distinct maximum is 2. <br />
                    The second distinct maximum is 1. <br />
                    The third distinct maximum does not exist, so the maximum
                    (2) is returned instead.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2,2,3,1] <br />
                    Output: 1 <br />
                    Explanation: <br />
                    The first distinct maximum is 3. <br />
                    The second distinct maximum is 2 (both 2's are counted
                    together since they have the same value).
                    <br /> The third distinct maximum is 1.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4
                <br />
                -2^31 &lt;= nums[i] &lt;= 2^31 - 1
              </>
            }
            fp={
              <>
                <b>Follow up :</b> Can you find an O(n) solution?
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var thirdMax = function(nums) {
                 let max1 = Number.MIN_SAFE_INTEGER; 
                 let max2 = Number.MIN_SAFE_INTEGER;
                 let max3 = Number.MIN_SAFE_INTEGER; 
               
                 for (let num of nums)
                   if (num > max1) {
                     max3 = max2;
                     max2 = max1;
                     max1 = num;
                   } else if (max1 > num && num > max2) {
                     max3 = max2;
                     max2 = num;
                   } else if (max2 > num && num > max3) {
                     max3 = num;
                   }
               
                 return max3 == Number.MIN_SAFE_INTEGER ? max1 : max3;   
               };
               
               thirdMax([2,2,3,1])`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q353: {
        title: "Q415. Add Strings (Q353)",
        content: (
          <Comp
            title="Q415. Add Strings (Q353)"
            content1={
              <>
                Given two non-negative integers, num1 and num2 represented as
                string, return the sum of num1 and num2 as a string.
                <br />
                You must solve the problem without using any built-in library
                for handling large integers (such as BigInteger). You must also
                not convert the inputs to integers directly.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: num1 = "11",
                    <br /> num2 = "123"
                    <br />
                    Output: "134"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num1 = "456", <br /> num2 = "77" <br />
                    Output: "533"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num1 = "0",
                    <br /> num2 = "0"
                    <br />
                    Output: "0"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= num1.length, num2.length &lt;= 10^4 <br />
                num1 and num2 consist of only digits. <br />
                num1 and num2 don't have any leading zeros except for the zero
                itself.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} num1
                * @param {string} num2
                * @return {string}
                */
               var addStrings = function(num1, num2) {
                 let s = [];
                 let carry = 0;
                 let i = num1.length - 1;
                 let j = num2.length - 1;
               
                 while (i >= 0 || j >= 0 || carry > 0) {
                     if (i >= 0)
                       carry += num1[i--] - '0';
                     if (j >= 0)
                       carry += num2[j--] - '0';
                     s.push(carry % 10);
                     carry = parseInt(carry / 10);
                 }
                 
                 return s.reverse().join("");   
               };
               
               addStrings("11","123")`,
                output: `134`,
              },
            }}
          />
        ),
      },
      q354: {
        title: "Q416. Partition Equal Subset Sum (Q354)",
        content: (
          <Comp
            title="Q416. Partition Equal Subset Sum (Q354)"
            content1={
              <>
                Given a non-empty array nums containing only positive integers,
                find if the array can be partitioned into two subsets such that
                the sum of elements in both subsets is equal.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,5,11,5] <br />
                    Output: true
                    <br />
                    Explanation: The array can be partitioned as [1, 5, 5] and
                    [11].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,5]
                    <br />
                    Output: false
                    <br />
                    Explanation: The array cannot be partitioned into equal sum
                    subsets.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 200 <br />1 &lt;= nums[i] &lt;= 100
              </>
            }
            tc="n.k"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {boolean}
                */
               var canPartition = function(nums) {
                 const sum = nums.reduce((a,b)=>a+b,0);
                 if (sum % 2 == 1)
                   return false;
                 return knapsack(nums, parseInt(sum / 2));
               };
               
               function knapsack(nums, subsetSum) {
                 const dp = Array(subsetSum + 1).fill(false);
                 dp[0] = true;
                 for (let num of nums)
                   for (let i = subsetSum; i >= num; --i)
                     dp[i] = dp[i] || dp[i - num];
                 return dp[subsetSum];
               }
               
               canPartition([1,5,11,5])`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q355: {
        title: "Q417. Pacific Atlantic Water Flow (Q355)",
        content: (
          <Comp
            title="Q417. Pacific Atlantic Water Flow (Q355)"
            content1={
              <>
                There is an m x n rectangular island that borders both the
                Pacific Ocean and Atlantic Ocean. The Pacific Ocean touches the
                island's left and top edges, and the Atlantic Ocean touches the
                island's right and bottom edges.
                <br />
                The island is partitioned into a grid of square cells. You are
                given an m x n integer matrix heights where heights[r][c]
                represents the height above sea level of the cell at coordinate
                (r, c).
                <br />
                The island receives a lot of rain, and the rain water can flow
                to neighboring cells directly north, south, east, and west if
                the neighboring cell's height is less than or equal to the
                current cell's height. Water can flow from any cell adjacent to
                an ocean into the ocean.
                <br />
                Return a 2D list of grid coordinates result where result[i] =
                [ri, ci] denotes that rain water can flow from cell (ri, ci) to
                both the Pacific and Atlantic oceans.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode417,
                content: (
                  <>
                    Input: heights =
                    [[1,2,2,3,5],[3,2,3,4,4],[2,4,5,3,1],[6,7,1,4,5],[5,1,1,2,4]]
                    <br /> Output: [[0,4],[1,3],[1,4],[2,2],[3,0],[3,1],[4,0]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: heights = [[2,1],[1,2]] <br />
                    Output: [[0,0],[0,1],[1,0],[1,1]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == heights.length
                <br />
                n == heights[r].length
                <br />
                1 &lt;= m, n &lt;= 200
                <br />0 &lt;= heights[r][c] &lt;= 10^5
              </>
            }
            tc="m.n"
            sc="m.n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} heights
                * @return {number[][]}
                */
               
               function dfs(heights,i,j,h,seen){
                 if (i < 0 || i == heights.length || j < 0 || j == heights[0].length)
                   return;
                 if (seen[i][j] || heights[i][j] < h)
                   return;
                   seen[i][j] = true;
                 dfs(heights, i + 1, j, heights[i][j], seen);
                 dfs(heights, i - 1, j, heights[i][j], seen);
                 dfs(heights, i, j + 1, heights[i][j], seen);
                 dfs(heights, i, j - 1, heights[i][j], seen);
               }
               
               var pacificAtlantic = function(heights) {
                 const m = heights.length;
                 const n = heights[0].length;
                 const ans = [];
                 const seenP = Array.from(Array(m),() => Array(n).fill(false))
                 const seenA = Array.from(Array(m),() => Array(n).fill(false))
                 for (let i = 0; i < m; ++i) {
                   dfs(heights, i, 0, 0, seenP);
                   dfs(heights, i, n - 1, 0, seenA);
                 }
                 for (let j = 0; j < n; ++j) {
                   dfs(heights, 0, j, 0, seenP);
                   dfs(heights, m - 1, j, 0, seenA);
                 }
               for (let i = 0; i < m; ++i)
                 for (let j = 0; j < n; ++j)
                   if (seenP[i][j] && seenA[i][j])
                     ans.push([i, j]);
               return ans;    
               };
               
               pacificAtlantic([[2,1],[1,2]])`,
                output: `[ [ 0, 0 ], [ 0, 1 ], [ 1, 0 ], [ 1, 1 ] ]`,
              },
            }}
          />
        ),
      },
      q356: {
        title: "Q419. Battleships in a Board (Q356)",
        content: (
          <Comp
            title="Q419. Battleships in a Board (Q356)"
            content1={
              <>
                Given an m x n matrix board where each cell is a battleship 'X'
                or empty '.', return the number of the battleships on board.
                <br />
                Battleships can only be placed horizontally or vertically on
                board. In other words, they can only be made of the shape 1 x k
                (1 row, k columns) or k x 1 (k rows, 1 column), where k can be
                of any size. At least one horizontal or vertical cell separates
                between two battleships (i.e., there are no adjacent
                battleships).
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode419,
                content: (
                  <>
                    Input: board =
                    [["X",".",".","X"],[".",".",".","X"],[".",".",".","X"]]
                    <br /> Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: board = [["."]] <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == board.length
                <br />
                n == board[i].length
                <br />
                1 &lt;= m, n &lt;= 200
                <br />
                board[i][j] is either '.' or 'X'.
              </>
            }
            fp={
              <>
                <b>Follow up :</b> Could you do it in one-pass, using only O(1)
                extra memory and without modifying the values board?
              </>
            }
            tc="m.n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {character[][]} board
                * @return {number}
                */
               var countBattleships = function(board) {
                 let ans = 0;
                 const m = board.length
                 const n = board[0].length
                 for (let i = 0; i < m; ++i)
                   for (let j = 0; j < n; ++j) {
                     if (board[i][j] == '.')
                       continue;
                     if (i > 0 && board[i - 1][j] == 'X')
                       continue;
                     if (j > 0 && board[i][j - 1] == 'X')
                       continue;
                     ++ans;
                   }
                 return ans;   
               };
               
               countBattleships([["X",".",".","X"],[".",".",".","X"],[".",".",".","X"]])`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q357: {
        title: "Q420. Strong Password Checker (Q357)",
        content: (
          <Comp
            title="Q420. Strong Password Checker (Q357)"
            content1={
              <>
                A password is considered strong if the below conditions are all
                met:
                <br />
                It has at least 6 characters and at most 20 characters.
                <br />
                It contains at least one lowercase letter, at least one
                uppercase letter, and at least one digit.
                <br />
                It does not contain three repeating characters in a row (i.e.,
                "...aaa..." is weak, but "...aa...a..." is strong, assuming
                other conditions are met).
                <br />
                Given a string password, return the minimum number of steps
                required to make password strong. if password is already strong,
                return 0.
                <br />
                In one step, you can:
                <br />
                Insert one character to password,
                <br />
                Delete one character from password, or
                <br />
                Replace one character of password with another character.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: password = "a" <br />
                    Output: 5
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: password = "aA1" <br />
                    Output: 3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: password = "1337C0d3"
                    <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= password.length &lt;= 50 <br />
                password consists of letters, digits, dot '.' or exclamation
                mark '!'.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} password
                * @return {number}
                */
               var strongPasswordChecker = function(password) {
                 let n = password.length;
                 const chars = password.split("");
                 const missing = getMissing(chars);
                 let replaces = 0;
                 let oneSeq = 0;
                 let twoSeq = 0;
                 for (let i = 2; i < n;)
                  if (chars[i] == chars[i - 1] && chars[i - 1] == chars[i - 2]) {
                   let length = 2; 
                   while (i < n && chars[i] == chars[i - 1]) {
                     ++length;
                     ++i;
                   }
                   replaces += parseInt(length / 3); 
                   if (length % 3 == 0)
                     ++oneSeq;
                   if (length % 3 == 1)
                     ++twoSeq;
                   } else {
                     ++i;
                   }
                   if (n < 6)
                     return Math.max(6 - n, missing);
                   if (n <= 20)
                     return Math.max(replaces, missing);
                   let deletes = n - 20;
                   replaces -= Math.min(oneSeq, deletes);
                   replaces -= parseInt(Math.min(Math.max(deletes - oneSeq, 0), twoSeq * 2) / 2);
                   replaces -= parseInt(Math.max(deletes - oneSeq - twoSeq * 2, 0) / 3);
                   return deletes + Math.max(replaces, missing);
               };
               
               function getMissing(chars) {
                 let missing = 3;  
                 for (let c of chars)
                   if (c==c.toUpperCase() && !Number.isInteger(parseInt(c))) {
                     --missing;
                     break;
                   }
                 for (let c of chars)
                   if (c==c.toLowerCase() && !Number.isInteger(parseInt(c))) {
                     --missing;
                     break;
                   }
                 for (let c of chars)
                   if (Number.isInteger(parseInt(c))) {
                     --missing;
                     break;
                   }
                 return missing;
               }
               
               strongPasswordChecker("ABABABABABABABABABAB1")`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q358: {
        title: "Q421. Maximum XOR of Two Numbers in an Array (Q358)",
        content: (
          <Comp
            title="Q421. Maximum XOR of Two Numbers in an Array (Q358)"
            content1={
              <>
                Given an integer array nums, return the maximum result of
                nums[i] XOR nums[j], where 0 &lt;= i &lt;= j &lt; n.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [3,10,5,25,2,8] <br />
                    Output: 28
                    <br />
                    Explanation: The maximum result is 5 XOR 25 = 28.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [14,70,53,83,49,91,36,80,92,51,66,70]
                    <br />
                    Output: 127
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 2 * 10^5 <br />0 &lt;= nums[i] &lt;=
                2^31 - 1
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var findMaximumXOR = function(nums) {
                 let ans = 0;
                 let mask = 0;
                 for (let i = 31; i >= 0; --i) {
                   mask |= 1 << i;
                   const prefixes = new Set();
                   for (let num of nums)
                     prefixes.add(num & mask);
                   let candidate = ans | 1 << i;
                   for (let prefix of prefixes)
                     if (prefixes.has(prefix ^ candidate)) {
                       ans = candidate;
                       break;
                     }
                 }
                 return ans; 
               };
               
               findMaximumXOR([3,10,5,25,2,8])`,
                output: `28`,
              },
            }}
          />
        ),
      },
      q359: {
        title: "Q423. Reconstruct Original Digits from English (Q359)",
        content: (
          <Comp
            title="Q423. Reconstruct Original Digits from English (Q359)"
            content1={
              <>
                Given a string s containing an out-of-order English
                representation of digits 0-9, return the digits in ascending
                order.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "owoztneoer" <br />
                    Output: "012"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "fviefuro" <br />
                    Output: "45"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^5 <br />
                s[i] is one of the characters
                ["e","g","f","i","h","o","n","s","r","u","t","w","v","x","z"].
                <br /> s is guaranteed to be valid.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {string}
                */
               var originalDigits = function(s) {
                 const st = [];
                 const count = Array(10).fill(0);
                 for (let c of s) {
                   if (c == 'z')
                     ++count[0];
                   if (c == 'o')
                     ++count[1];
                   if (c == 'w')
                     ++count[2];
                   if (c == 'h')
                     ++count[3];
                   if (c == 'u')
                     ++count[4];
                   if (c == 'f')
                     ++count[5];
                   if (c == 'x')
                     ++count[6];
                   if (c == 's')
                     ++count[7];
                   if (c == 'g')
                     ++count[8];
                   if (c == 'i')
                     ++count[9];
                 }
                 count[1] -= count[0] + count[2] + count[4];
                 count[3] -= count[8];
                 count[5] -= count[4];
                 count[7] -= count[6];
                 count[9] -= count[5] + count[6] + count[8];
                 for (let i = 0; i < 10; ++i)
                   for (let j = 0; j < count[i]; ++j)
                     st.push(i);
                 return st.join("");
               };
               
               originalDigits("owoztneoer")`,
                output: `012`,
              },
            }}
          />
        ),
      },
      q360: {
        title: "Q424. Longest Repeating Character Replacement (Q360)",
        content: (
          <Comp
            title="Q424. Longest Repeating Character Replacement (Q360)"
            content1={
              <>
                You are given a string s and an integer k. You can choose any
                character of the string and change it to any other uppercase
                English character. You can perform this operation at most k
                times.
                <br />
                Return the length of the longest substring containing the same
                letter you can get after performing the above operations.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "ABAB", k = 2 <br />
                    Output: 4 <br />
                    Explanation: Replace the two 'A's with two 'B's or vice
                    versa.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "AABABBA", k = 1<br />
                    Output: 4<br />
                    Explanation: Replace the one 'A' in the middle with 'B' and
                    form "AABBBBA".
                    <br /> The substring "BBBB" has the longest repeating
                    letters, which is 4.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^5 <br />
                s consists of only uppercase English letters. <br />0 &lt;= k
                &lt;= s.length
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @param {number} k
                * @return {number}
                */
               var characterReplacement = function(s, k) {
                 let ans = 0;
                 let maxCount = 0;
                 const count = Array(128).fill(0);
                 for (let l = 0, r = 0; r < s.length; ++r) {
                   maxCount = Math.max(maxCount, ++count[s[r].charCodeAt(0)]);
                   while (maxCount + k < r - l + 1)
                     --count[s[l++].charCodeAt(0)];
                   ans = Math.max(ans, r - l + 1);
                 }
                 return ans;  
               };
               
               characterReplacement("ABAB",2)`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q361: {
        title: "Q427. Construct Quad Tree (Q361)",
        content: (
          <Comp
            title="Q427. Construct Quad Tree (Q361)"
            content1={
              <>
                Given a n * n matrix grid of 0's and 1's only. We want to
                represent the grid with a Quad-Tree.
                <br />
                Return the root of the Quad-Tree representing the grid.
                <br />
                Notice that you can assign the value of a node to True or False
                when isLeaf is False, and both are accepted in the answer.
                <br />
                A Quad-Tree is a tree data structure in which each internal node
                has exactly four children. Besides, each node has two
                attributes:
                <br />
                val: True if the node represents a grid of 1's or False if the
                node represents a grid of 0's.
                <br /> isLeaf: True if the node is leaf node on the tree or
                False if the node has the four children.
                <br />
                <pre>
                  {`
class Node {
  public boolean val;
  public boolean isLeaf;
  public Node topLeft;
  public Node topRight;
  public Node bottomLeft;
  public Node bottomRight;
}             
              `}
                </pre>
                We can construct a Quad-Tree from a two-dimensional area using
                the following steps:
                <br />
                If the current grid has the same value (i.e all 1's or all 0's)
                set isLeaf True and set val to the value of the grid and set the
                four children to Null and stop.
                <br /> If the current grid has different values, set isLeaf to
                False and set val to any value and divide the current grid into
                four sub-grids as shown in the photo.
                <br /> Recurse for each of the children with the proper
                sub-grid.
                <br />
                If you want to know more about the Quad-Tree, you can refer to
                the wiki.
                <br />
                Quad-Tree format:
                <br />
                The output represents the serialized format of a Quad-Tree using
                level order traversal, where null signifies a path terminator
                where no node exists below.
                <br />
                It is very similar to the serialization of the binary tree. The
                only difference is that the node is represented as a list
                [isLeaf, val].
                <br />
                If the value of isLeaf or val is True we represent it as 1 in
                the list [isLeaf, val] and if the value of isLeaf or val is
                False we represent it as 0.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode427,
                content: (
                  <>
                    Input: grid = [[0,1],[1,0]] <br />
                    Output: [[0,1],[1,0],[1,1],[1,1],[1,0]]
                    <br />
                    Explanation: The explanation of this example is shown below:
                    <br />
                    Notice that 0 represnts False and 1 represents True in the
                    photo representing the Quad-Tree.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid =
                    [[1,1,1,1,0,0,0,0],[1,1,1,1,0,0,0,0],[1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1],[1,1,1,1,0,0,0,0],[1,1,1,1,0,0,0,0],[1,1,1,1,0,0,0,0],[1,1,1,1,0,0,0,0]]
                    <br /> Output:
                    [[0,1],[1,1],[0,1],[1,1],[1,0],null,null,null,null,[1,0],[1,0],[1,1],[1,1]]
                    <br /> Explanation: All values in the grid are not the same.
                    We divide the grid into four sub-grids.
                    <br /> The topLeft, bottomLeft and bottomRight each has the
                    same value.
                    <br /> The topRight have different values so we divide it
                    into 4 sub-grids where each has the same value.
                    <br /> Explanation is shown in the photo below:
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == grid.length == grid[i].length <br />n == 2x where 0 &lt;= x
                &lt;= 6
              </>
            }
            tc="n^2. log4 n"
            sc="log4 n"
            codes={{
              Java: {
                code: `
                // [[0,1],[1,0]]
                class Solution {
                  public Node construct(int[][] grid) {
                    return helper(grid, 0, 0, grid.length);
                  }
                
                  private Node helper(int[][] grid, int i, int j, int w) {
                    if (allSame(grid, i, j, w))
                      return new Node(grid[i][j] == 1 ? true : false, true);
                
                    Node node = new Node(true, false);
                    node.topLeft = helper(grid, i, j, w / 2);
                    node.topRight = helper(grid, i, j + w / 2, w / 2);
                    node.bottomLeft = helper(grid, i + w / 2, j, w / 2);
                    node.bottomRight = helper(grid, i + w / 2, j + w / 2, w / 2);
                    return node;
                  }
                
                  private boolean allSame(int[][] grid, int i, int j, int w) {
                    for (int x = i; x < i + w; ++x)
                      for (int y = j; y < j + w; ++y)
                        if (grid[x][y] != grid[i][j])
                          return false;
                    return true;
                  }
                }
                `,
                output: `[[0,1],[1,0],[1,1],[1,1],[1,0]]`,
              },
            }}
          />
        ),
      },
      q362: {
        title: "Q429. N-ary Tree Level Order Traversal (Q362)",
        content: (
          <Comp
            title="Q429. N-ary Tree Level Order Traversal (Q362)"
            content1={
              <>
                Given an n-ary tree, return the level order traversal of its
                nodes' values.
                <br />
                Nary-Tree input serialization is represented in their level
                order traversal, each group of children is separated by the null
                value (See examples).
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode429,
                content: (
                  <>
                    Input: root = [1,null,3,2,4,null,5,6]
                    <br /> Output: [[1],[3,2,4],[5,6]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root =
                    [1,null,2,3,4,5,null,null,6,7,null,8,null,9,10,null,null,11,null,12,null,13,null,null,14]
                    <br /> Output: [[1],[2,3,4,5],[6,7,8,9,10],[11,12,13],[14]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The height of the n-ary tree is less than or equal to 1000
                <br /> The total number of nodes is between [0, 104]
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function Node(val,children) {
                  this.val = val;
                  this.children = children;
                };
                 
                
                /**
                 * @param {Node|null} root
                 * @return {number[][]}
                 */
                var levelOrder = function(root) {
                  if (!root)
                  return [];
                  const res = [];
                  const q = [root];
                  while (q.length) {
                    const curr = [];
                    for (let size = q.length; size > 0; --size) {
                      const node = q.shift();
                      curr.push(node.val);
                      for (let child of node.children)
                        q.push(child);
                    }
                    res.push(curr);
                  }
                  return res;
                };
                
                const n = new Node(1)
                const n1 = new Node(3,[])
                const n2 = new Node(2,[])
                const n3 = new Node(4,[])
                n.children = [n1,n2,n3]
                const n4 = new Node(5,[])
                const n5 = new Node(6,[])
                n3.children = [n4,n5]
                levelOrder(n)
                `,
                output: `[ [ 1 ], [ 3, 2, 4 ], [ 5, 6 ] ]`,
              },
            }}
          />
        ),
      },
      q363: {
        title: "Q430. Flatten a Multilevel Doubly Linked List (Q363)",
        content: (
          <Comp
            title="Q430. Flatten a Multilevel Doubly Linked List (Q363)"
            content1={
              <>
                You are given a doubly linked list, which contains nodes that
                have a next pointer, a previous pointer, and an additional child
                pointer. This child pointer may or may not point to a separate
                doubly linked list, also containing these special nodes. These
                child lists may have one or more children of their own, and so
                on, to produce a multilevel data structure as shown in the
                example below.
                <br />
                Given the head of the first level of the list, flatten the list
                so that all the nodes appear in a single-level, doubly linked
                list. Let curr be a node with a child list. The nodes in the
                child list should appear after curr and before curr.next in the
                flattened list.
                <nr />
                Return the head of the flattened list. The nodes in the list
                must have all of their child pointers set to null.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode430,
                content: (
                  <>
                    Input: head =
                    [1,2,3,4,5,6,null,null,null,7,8,9,10,null,null,11,12] <br />
                    Output: [1,2,3,7,8,11,12,9,10,4,5,6] <br />
                    Explanation: The multilevel linked list in the input is
                    shown.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: head = [1,2,null,3] <br />
                    Output: [1,3,2] <br />
                    Explanation: The multilevel linked list in the input is
                    shown.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: head = [] <br />
                    Output: [] <br />
                    Explanation: There could be empty list in the input.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of Nodes will not exceed 1000. <br />1 &lt;= Node.val
                &lt;= 10^5
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Java: {
                code: `/*
                // Definition for a Node.
                class Node {
                    public int val;
                    public Node prev;
                    public Node next;
                    public Node child;
                };
                */
                // [1,2,3,4,5,6,null,null,null,7,8,9,10,null,null,11,12]
                class Solution {
                  public Node flatten(Node head) {
                    for (Node curr = head; curr != null; curr = curr.next)
                      if (curr.child != null) {
                        Node cachedNext = curr.next;
                        curr.next = curr.child;
                        curr.child.prev = curr;
                        curr.child = null;
                        Node tail = curr.next;
                        while (tail.next != null)
                          tail = tail.next;
                        tail.next = cachedNext;
                        if (cachedNext != null)
                          cachedNext.prev = tail;
                      }
                
                    return head;
                  }
                }
                `,
                output: `[1,2,3,7,8,11,12,9,10,4,5,6]`,
              },
            }}
          />
        ),
      },
      q364: {
        title: "Q432. All O`one Data Structure (Q364)",
        content: (
          <Comp
            title="Q432. All O`one Data Structure (Q364)"
            content1={
              <>
                Design a data structure to store the strings' count with the
                ability to return the strings with minimum and maximum counts.
                <br />
                Implement the AllOne class:
                <br />
                AllOne() Initializes the object of the data structure.
                <br />
                inc(String key) Increments the count of the string key by 1. If
                key does not exist in the data structure, insert it with count
                1.
                <br />
                dec(String key) Decrements the count of the string key by 1. If
                the count of key is 0 after the decrement, remove it from the
                data structure. It is guaranteed that key exists in the data
                structure before the decrement.
                <br />
                getMaxKey() Returns one of the keys with the maximal count. If
                no element exists, return an empty string "".
                <br />
                getMinKey() Returns one of the keys with the minimum count. If
                no element exists, return an empty string "".
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input ["AllOne", "inc", "inc", "getMaxKey", "getMinKey",
                    "inc", "getMaxKey", "getMinKey"] [[], ["hello"], ["hello"],
                    [], [], ["leet"], [], []] <br />
                    Output <br />
                    [null, null, null, "hello", "hello", null, "hello", "leet"]{" "}
                    <br />
                    Explanation <br />
                    AllOne allOne = new AllOne(); <br />
                    allOne.inc("hello"); <br />
                    allOne.inc("hello"); <br />
                    allOne.getMaxKey(); // return "hello"
                    <br />
                    allOne.getMinKey(); // return "hello"
                    <br />
                    allOne.inc("leet");
                    <br />
                    allOne.getMaxKey(); // return "hello" <br />
                    allOne.getMinKey(); // return "leet"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= key.length &lt;= 10
                <br />
                key consists of lowercase English letters.
                <br />
                It is guaranteed that for each call to dec, key is existing in
                the data structure.
                <br /> At most 5 * 104 calls will be made to inc, dec,
                getMaxKey, and getMinKey.
              </>
            }
            tc="1"
            sc="n"
            codes={{
              Java: {
                code: `
                //  ["AllOne","inc","inc","getMaxKey","getMinKey","inc","getMaxKey","getMinKey"]
// [[],["hello"],["hello"],[],[],["leet"],[],[]]
                class AllOne {
                  private class BucketNode{
                      int val;
                      BucketNode count;
                      BucketNode pre;
                      BucketNode next;
                      Set<String> keySet;
                      private BucketNode(int val){
                          this.val = val;
                          keySet = new HashSet<>();
                      }
              
                  }
                  private BucketNode head;
                  private BucketNode tail;
                  private Map<String, Integer> keyValueMap;
                  private Map<Integer, BucketNode> valueBucketMap;
              
                  public AllOne() {
                       head = new BucketNode(Integer.MIN_VALUE);
                       tail = new BucketNode(Integer.MAX_VALUE);
                       keyValueMap  = new HashMap<>();
                       valueBucketMap = new HashMap<>();
                       head.next = tail;
                       tail.pre = head;
                  }
              
                  public void inc(String key) {
                      if(keyValueMap.containsKey(key)){
                          changeValue(key, 1);            
                      }
                      else{
              
                          if(head.next.val != 1){
                              addBucketAfter(new BucketNode(1),head);
                          }
              
                          head.next.keySet.add(key);
                          keyValueMap.put(key, 1);
                          valueBucketMap.put(1, head.next);
                      }
                  }
              
                  public void dec(String key) {
                      if(keyValueMap.containsKey(key)){
                          if(keyValueMap.get(key) == 1){
                              keyValueMap.remove(key);
                              removeKeyfromBucket(valueBucketMap.get(1), key);
                          }
                          else{
                              changeValue(key, -1);
                          }
                      }        
                  }
              
                  public String getMaxKey() {
                      return tail.pre == head? "": (String)tail.pre.keySet.iterator().next();
                  }
              
                  public String getMinKey() {
                      return head.next == tail? "": (String)head.next.keySet.iterator().next();
                  }
              
                  private void changeValue(String key, int offset){
                      int curVal = keyValueMap.get(key);
                      BucketNode curBucket = valueBucketMap.get(curVal);
                      BucketNode newBucket;
                      if(valueBucketMap.containsKey(curVal + offset)){
                          newBucket = valueBucketMap.get(curVal + offset);
                      }
                      else{
                          newBucket = new BucketNode(curVal + offset);
                          addBucketAfter(newBucket, offset == 1 ?curBucket: curBucket.pre);
                          valueBucketMap.put(curVal + offset, newBucket);
                      }
              
                      removeKeyfromBucket(curBucket, key);
              
                      newBucket.keySet.add(key);
                      keyValueMap.put(key, curVal + offset);
              
                  }
              
                  private void addBucketAfter(BucketNode newBucket, BucketNode pre){
                      newBucket.pre = pre;
                      newBucket.next = pre.next;
                      pre.next.pre = newBucket;
                      pre.next = newBucket;
              
                  }
              
                  private void removeKeyfromBucket(BucketNode bucket, String key){
                      bucket.keySet.remove(key);
                      if(bucket.keySet.size() == 0){
                          removeBucketfromBucketList(bucket);
                          valueBucketMap.remove(bucket.val);
                      }
                  }
              
                  private void removeBucketfromBucketList(BucketNode bucket){
                      bucket.pre.next = bucket.next;
                      bucket.next.pre = bucket.pre;
                      bucket.pre = null;
                      bucket.next = null;
                  }
              }
              
              /**
               * Your AllOne object will be instantiated and called as such:
               * AllOne obj = new AllOne();
               * obj.inc(key);
               * obj.dec(key);
               * String param_3 = obj.getMaxKey();
               * String param_4 = obj.getMinKey();
               */`,
                output: `[null,null,null,"hello","hello",null,"hello","leet"]`,
              },
            }}
          />
        ),
      },
      q365: {
        title: "Q433. Minimum Genetic Mutation (Q365)",
        content: (
          <Comp
            title="Q433. Minimum Genetic Mutation (Q365)"
            content1={
              <>
                A gene string can be represented by an 8-character long string,
                with choices from 'A', 'C', 'G', and 'T'.
                <br />
                Suppose we need to investigate a mutation from a gene string
                start to a gene string end where one mutation is defined as one
                single character changed in the gene string.
                <br />
                For example, "AACCGGTT" --&gt; "AACCGGTA" is one mutation.
                <br />
                There is also a gene bank bank that records all the valid gene
                mutations. A gene must be in bank to make it a valid gene
                string.
                <br />
                Given the two gene strings start and end and the gene bank bank,
                return the minimum number of mutations needed to mutate from
                start to end. If there is no such a mutation, return -1.
                <br />
                Note that the starting point is assumed to be valid, so it might
                not be included in the bank.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: start = "AACCGGTT", <br /> end = "AACCGGTA", <br />{" "}
                    bank = ["AACCGGTA"]
                    <br /> Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: start = "AACCGGTT", <br /> end = "AAACGGTA", <br />{" "}
                    bank = ["AACCGGTA","AACCGCTA","AAACGGTA"] Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: start = "AAAAACCC", <br /> end = "AACCCCCC", <br />
                    bank = ["AAAACCCC","AAACCCCC","AACCCCCC"] Output: 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                start.length == 8 <br />
                end.length == 8 <br />
                0 &lt;= bank.length &lt;= 10 <br />
                bank[i].length == 8 <br />
                start, end, and bank[i] consist of only the characters ['A',
                'C', 'G', 'T'].
              </>
            }
            tc="n.4^|word|"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} start
                * @param {string} end
                * @param {string[]} bank
                * @return {number}
                */
               var minMutation = function(start, end, bank) {
                 const bankSet = new Set(bank);
                 if (!bankSet.has(end))
                     return -1;
                 let ans = 0;
                 const q = [start];
                 while (q.length) {
                   ++ans;
                   for (let size = q.length; size > 0; --size) {
                     const s = q.shift().split("");
                     for (let j = 0; j < s.length; ++j) {
                       const cache = s[j];
                       for (let c of ['A', 'C', 'G', 'T']) {
                         s[j] =  c;
                         const word = s.join("");
                         if (word == end)
                           return ans;
                         if (bankSet.has(word)) {
                           bankSet.delete(word);
                           q.push(word);
                         }
                       }
                       s[j]  = cache;
                     }
                   }
                 }
                 return -1;
               };
               
               minMutation("AACCGGTT","AAACGGTA",["AACCGGTA","AACCGCTA","AAACGGTA"])`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q366: {
        title: "Q434. Number of Segments in a String (Q366)",
        content: (
          <Comp
            title="Q434. Number of Segments in a String (Q366)"
            content1={
              <>
                Given a string s, return the number of segments in the string.
                <br />A segment is defined to be a contiguous sequence of
                non-space characters.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "Hello, my name is John"
                    <br />
                    Output: 5 <br />
                    Explanation: The five segments are ["Hello,", "my", "name",
                    "is", "John"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "Hello"
                    <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= s.length &lt;= 300 <br />
                s consists of lowercase and uppercase English letters, digits,
                or one of the following characters "!@#$%^&*()_+-=',.:".
                <br /> The only space character in s is ' '.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               var countSegments = function(s) {
                 let ans = 0;
                 for (let i = 0; i < s.length; ++i)
                 if (s[i] != ' '&& (i == 0 || s[i - 1] == ' '))
                 ++ans;
                 return ans; 
               };
               
               countSegments("Hello, my name is John")`,
                output: `5`,
              },
            }}
          />
        ),
      },
      q367: {
        title: "Q435. Non-overlapping Intervals (Q367)",
        content: (
          <Comp
            title="Q435. Non-overlapping Intervals (Q367)"
            content1={
              <>
                Given an array of intervals intervals where intervals[i] =
                [starti, endi], return the minimum number of intervals you need
                to remove to make the rest of the intervals non-overlapping.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: intervals = [[1,2],[2,3],[3,4],[1,3]]
                    <br />
                    Output: 1<br />
                    Explanation: [1,3] can be removed and the rest of the
                    intervals are non-overlappin
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: intervals = [[1,2],[1,2],[1,2]] <br />
                    Output: 2 <br />
                    Explanation: You need to remove two [1,2] to make the rest
                    of the intervals non-overlapping.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: intervals = [[1,2],[2,3]] <br />
                    Output: 0 <br />
                    Explanation: You don't need to remove any of the intervals
                    since they're already non-overlapping.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= intervals.length &lt;= 105 <br />
                intervals[i].length == 2 <br />
                -5 * 104 &lt;= starti &lt; endi &lt;= 5 * 10^4 Accepted
              </>
            }
            tc="n.log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} intervals
                * @return {number}
                */
               var eraseOverlapIntervals = function(intervals) {
                 if (intervals.length == 0)
                     return 0;
                 intervals.sort((a, b) => a[1] - b[1]);
                 let ans = 0;
                 let currentEnd = intervals[0][1];
                 for (let i = 1; i < intervals.length; ++i)
                   if (intervals[i][0] >= currentEnd)
                     currentEnd = intervals[i][1];
                   else
                     ++ans;
                 return ans;    
               };
               
               eraseOverlapIntervals([[1,2],[1,2],[1,2]])`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q368: {
        title: "Q436. Find Right Interval (Q368)",
        content: (
          <Comp
            title="Q436. Find Right Interval (Q368)"
            content1={
              <>
                You are given an array of intervals, where intervals[i] =
                [starti, endi] and each starti is unique.
                <br />
                The right interval for an interval i is an interval j such that
                startj &gt;= endi and startj is minimized.
                <br />
                Return an array of right interval indices for each interval i.
                If no right interval exists for interval i, then put -1 at index
                i.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: intervals = [[1,2]] <br />
                    Output: [-1] <br />
                    Explanation: There is only one interval in the collection,
                    so it outputs -1.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: intervals = [[3,4],[2,3],[1,2]]
                    <br />
                    Output: [-1,0,1]
                    <br />
                    Explanation: There is no right interval for [3,4].
                    <br />
                    The right interval for [2,3] is [3,4] since start0 = 3 is
                    the smallest start that is &gt;= end1 = 3.
                    <br />
                    The right interval for [1,2] is [2,3] since start1 = 2 is
                    the smallest start that is &gt;= end2 = 2.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: intervals = [[1,4],[2,3],[3,4]]
                    <br />
                    Output: [-1,2,-1]
                    <br />
                    Explanation: There is no right interval for [1,4] and [3,4].
                    <br />
                    The right interval for [2,3] is [3,4] since start2 = 3 is
                    the smallest start that is &gt;= end1 = 3.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= intervals.length &lt;= 2 * 10^4 <br />
                intervals[i].length == 2 <br />
                -10^6 &lt;= starti &lt;= endi &lt;= 10^6 <br />
                The start point of each interval is unique.
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `
                // [[1,4],[2,3],[3,4]]
                import java.util.*;

                class Solution {
                  public int[] findRightInterval(int[][] intervals) {
                    final int n = intervals.length;
                
                    int[] ans = new int[n];
                    NavigableMap<Integer, Integer> startToIndex = new TreeMap<>();
                
                    for (int i = 0; i < n; ++i)
                      startToIndex.put(intervals[i][0], i);
                
                    for (int i = 0; i < n; ++i) {
                      Map.Entry<Integer, Integer> entry = startToIndex.ceilingEntry(intervals[i][1]);
                      if (entry == null)
                        ans[i] = -1;
                      else
                        ans[i] = entry.getValue();
                    }
                
                    return ans;
                  }
                }
                `,
                output: `[ -1, 0, 1 ]`,
              },
            }}
          />
        ),
      },
      q369: {
        title: "Q437. Path Sum III (Q369)",
        content: (
          <Comp
            title="Q437. Path Sum III (Q369)"
            content1={
              <>
                Given the root of a binary tree and an integer targetSum, return
                the number of paths where the sum of the values along the path
                equals targetSum.
                <br />
                The path does not need to start or end at the root or a leaf,
                but it must go downwards (i.e., traveling only from parent nodes
                to child nodes).
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode437,
                content: (
                  <>
                    Input: root = [10,5,-3,3,2,null,11,3,-2,null,1],
                    <br /> targetSum = 8<br />
                    Output: 3<br />
                    Explanation: The paths that sum to 8 are shown.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [5,4,8,11,null,13,4,7,2,null,null,5,1], <br />{" "}
                    targetSum = 22
                    <br /> Output: 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [0, 1000].{" "}
                <br />
                -109 &lt;= Node.val &lt;= 10^9 <br />
                -1000 &lt;= targetSum &lt;= 1000
              </>
            }
            tc="n.log n -> n^2"
            sc="log n -> n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root
                 * @param {number} targetSum
                 * @return {number}
                 */
                
                function dfs(root,targetSum){
                  if (!root) return 0;
                  return (targetSum == root.val ? 1 : 0) +
                        dfs(root.left, targetSum - root.val) +
                        dfs(root.right, targetSum - root.val);
                }
                
                var pathSum = function(root, targetSum) {
                  if (!root) return 0;
                  return dfs(root, targetSum) + pathSum(root.left, targetSum) + pathSum(root.right, targetSum);
                };
                
                const t = new TreeNode(10)
                t.left = new TreeNode(5)
                t.left.left = new TreeNode(3)
                t.left.left.left = new TreeNode(3)
                t.left.left.right = new TreeNode(-2)
                t.left.right = new TreeNode(2)
                t.left.right.right = new TreeNode(1)
                t.right = new TreeNode(-3)
                t.right.right = new TreeNode(11)
                pathSum(t,8)`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q370: {
        title: "Q438. Find All Anagrams in a String (Q370)",
        content: (
          <Comp
            title="Q438. Find All Anagrams in a String (Q370)"
            content1={
              <>
                Given two strings s and p, return an array of all the start
                indices of p's anagrams in s. You may return the answer in any
                order.
                <br />
                An Anagram is a word or phrase formed by rearranging the letters
                of a different word or phrase, typically using all the original
                letters exactly once.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "cbaebabacd", p = "abc"
                    <br />
                    Output: [0,6]
                    <br />
                    Explanation:
                    <br />
                    The substring with start index = 0 is "cba", which is an
                    anagram of "abc".
                    <br />
                    The substring with start index = 6 is "bac", which is an
                    anagram of "abc".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "abab", p = "ab"
                    <br />
                    Output: [0,1,2]
                    <br />
                    Explanation:
                    <br />
                    The substring with start index = 0 is "ab", which is an
                    anagram of "ab".
                    <br />
                    The substring with start index = 1 is "ba", which is an
                    anagram of "ab".
                    <br />
                    The substring with start index = 2 is "ab", which is an
                    anagram of "ab".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length, p.length &lt;= 3 * 10^4 <br />s and p consist
                of lowercase English letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @param {string} p
                * @return {number[]}
                */
               var findAnagrams = function(s, p) {
                 const res = [];
                 const count = Array(128).fill(false);
                 let required = p.length;
               
                 for (let c of p)
                   ++count[c.charCodeAt(0)];
               
                 for (let l = 0, r = 0; r < s.length; ++r) {
                   if (--count[s[r].charCodeAt(0)] >= 0)
                       --required;
                     while (required == 0) {
                       if (r - l + 1 == p.length)
                         res.push(l);
                       if (++count[s[l++].charCodeAt(0)] > 0)
                         ++required;
                     }
                 }
               
                 return res; 
               };
               
               findAnagrams("cbaebabacd","abc")`,
                output: `[ 0, 6 ]`,
              },
            }}
          />
        ),
      },
      q371: {
        title: "Q440. K-th Smallest in Lexicographical Order (Q371)",
        content: (
          <Comp
            title="Q440. K-th Smallest in Lexicographical Order (Q371)"
            content1={
              <>
                Given two integers n and k, return the kth lexicographically
                smallest integer in the range [1, n].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 13, k = 2 <br />
                    Output: 10 <br />
                    Explanation: The lexicographical order is [1, 10, 11, 12,
                    13, 2, 3, 4, 5, 6, 7, 8, 9], so the second smallest number
                    is 10.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1, k = 1<br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= k &lt;= n &lt;= 10^9</>}
            tc="log^3 n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @param {number} k
                * @return {number}
                */
               
               function getGap(a, b, n) {
                 let gap = 0;
                 while (a <= n) {
                   gap += Math.min(n + 1, b) - a;
                   a *= 10;
                   b *= 10;
                 }
                 return gap;
               }
               
               var findKthNumber = function(n, k) {
                 let curr = 1;
                 for (let i = 1; i < k;) {
                   let gap = getGap(curr, curr + 1, n);
                   if (i + gap <= k) {
                     i += gap;
                     ++curr;
                   } else {
                     ++i;
                     curr *= 10;
                   }
                 }
                 return curr; 
               };
               
               findKthNumber(13,2)`,
                output: `10`,
              },
            }}
          />
        ),
      },
      q372: {
        title: "Q441. Arranging Coins (Q372)",
        content: (
          <Comp
            title="Q441. Arranging Coins (Q372)"
            content1={
              <>
                You have n coins and you want to build a staircase with these
                coins. The staircase consists of k rows where the ith row has
                exactly i coins. The last row of the staircase may be
                incomplete.
                <br />
                Given the integer n, return the number of complete rows of the
                staircase you will build.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode441,
                content: (
                  <>
                    Input: n = 5 <br />
                    Output: 2 <br />
                    Explanation: Because the 3rd row is incomplete, we return 2.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 8<br />
                    Output: 3<br />
                    Explanation: Because the 4th row is incomplete, we return 3.
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 2^31 - 1</>}
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               var arrangeCoins = function(n) {
                 return parseInt((-1 + Math.sqrt(8 * n + 1)) / 2);
               };
               
               arrangeCoins(5)`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q373: {
        title: "Q442. Find All Duplicates in an Array (Q373)",
        content: (
          <Comp
            title="Q442. Find All Duplicates in an Array (Q373)"
            content1={
              <>
                Given an integer array nums of length n where all the integers
                of nums are in the range [1, n] and each integer appears once or
                twice, return an array of all the integers that appears twice.
                <br />
                You must write an algorithm that runs in O(n) time and uses only
                constant extra space.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [4,3,2,7,8,2,3,1] <br />
                    Output: [2,3]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,1,2] <rb />
                    Output: [1]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1] <br />
                    Output: []
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == nums.length
                <br />
                1 &lt;= n &lt;= 10^5
                <br />
                1 &lt;= nums[i] &lt;= n<br />
                Each element in nums appears once or twice.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number[]}
                */
               var findDuplicates = function(nums) {
                 const res = [];
                 for (let num of nums) {
                   nums[Math.abs(num) - 1] *= -1;
                   if (nums[Math.abs(num) - 1] > 0)
                   res.push(Math.abs(num));
                 }
                 return res;    
               };
               
               findDuplicates([1,1,2])`,
                output: `[ 1 ]`,
              },
            }}
          />
        ),
      },
      q375: {
        title: "Q443. String Compression (Q375)",
        content: (
          <Comp
            title="Q443. String Compression (Q375)"
            content1={
              <>
                Given an array of characters chars, compress it using the
                following algorithm:
                <br />
                Begin with an empty string s. For each group of consecutive
                repeating characters in chars:
                <br />
                If the group's length is 1, append the character to s.
                <br />
                Otherwise, append the character followed by the group's length.
                <br />
                The compressed string s should not be returned separately, but
                instead, be stored in the input character array chars. Note that
                group lengths that are 10 or longer will be split into multiple
                characters in chars.
                <br />
                After you are done modifying the input array, return the new
                length of the array.
                <br />
                You must write an algorithm that uses only constant extra space.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: chars = ["a","a","b","b","c","c","c"] <br />
                    Output: Return 6, and the first 6 characters of the input
                    array should be: ["a","2","b","2","c","3"]
                    <br /> Explanation: The groups are "aa", "bb", and "ccc".
                    This compresses to "a2b2c3".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: chars = ["a"] <br />
                    Output: Return 1, and the first character of the input array
                    should be: ["a"] <br />
                    Explanation: The only group is "a", which remains
                    uncompressed since it's a single character.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: chars =
                    ["a","b","b","b","b","b","b","b","b","b","b","b","b"] <br />
                    Output: Return 4, and the first 4 characters of the input
                    array should be: ["a","b","1","2"]. <br />
                    Explanation: The groups are "a" and "bbbbbbbbbbbb". This
                    compresses to "ab12".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= chars.length &lt;= 2000 <br />
                chars[i] is a lowercase English letter, uppercase English
                letter, digit, or symbol.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {character[]} chars
                * @return {number}
                */
               var compress = function(chars) {
                 let res = 0;
                 for (let i = 0; i < chars.length;) {
                   const letter = chars[i];
                   let count = 0;
                   while (i < chars.length && chars[i] == letter) {
                     ++count;
                     ++i;
                   }
                   chars[res++] = letter;
                   if (count > 1)
                   for (let c of String(count))
                   chars[res++] = c;
                 }
                 return res; 
               };
               
               compress(["a"])`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q376: {
        title: "Q445. Add Two Numbers II (Q376)",
        content: (
          <Comp
            title="Q445. Add Two Numbers II (Q376)"
            content1={
              <>
                You are given two non-empty linked lists representing two
                non-negative integers. The most significant digit comes first
                and each of their nodes contains a single digit. Add the two
                numbers and return the sum as a linked list.
                <br />
                You may assume the two numbers do not contain any leading zero,
                except the number 0 itself.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: l1 = [7,2,4,3], l2 = [5,6,4] <br />
                    Output: [7,8,0,7]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: l1 = [2,4,3], l2 = [5,6,4] <br />
                    Output: [8,0,7]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: l1 = [0], l2 = [0] <br />
                    Output: [0]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in each linked list is in the range [1,
                100]. <br />
                0 &lt;= Node.val &lt;= 9 <br />
                It is guaranteed that the list represents a number that does not
                have leading zeros.
              </>
            }
            fp={
              <>
                <b>Follow up :</b> Could you solve it without reversing the
                input lists?
              </>
            }
            tc="m + n"
            sc="m + n"
            codes={{
              Javascript: {
                code: `function ListNode(val, next) {
                  this.val = (val===undefined ? 0 : val)
                  this.next = (next===undefined ? null : next)
                }
                
                /**
                 * @param {ListNode} l1
                 * @param {ListNode} l2
                 * @return {ListNode}
                 */
                var addTwoNumbers = function(l1, l2) {
                  const stack1 = [];
                  const stack2 = [];
                  while (l1) {
                    stack1.push(l1);
                    l1 = l1.next;
                  }
                  while (l2) {
                    stack2.push(l2);
                    l2 = l2.next;
                  }
                  let head = null;
                  let carry = 0;
                  while (carry > 0 || stack1.length || stack2.length) {
                    if (stack1.length)
                      carry += stack1.pop().val;
                    if (stack2.length)
                      carry += stack2.pop().val;
                    let node = new ListNode(carry % 10);
                    node.next = head;
                    head = node;
                    carry = parseInt(carry/ 10);
                  }
                  return head;    
                };
                
                const l1 = new ListNode(7)
                l1.next = new ListNode(2)
                l1.next.next = new ListNode(4)
                l1.next.next.next = new ListNode(3)
                const l2 = new ListNode(5)
                l2.next = new ListNode(6)
                l2.next.next = new ListNode(4)
                addTwoNumbers(l1,l2)`,
                output: `ListNode {
                  val: 7,
                  next: ListNode { val: 8, next: ListNode { val: 0, next: [ListNode] } }
                }`,
              },
            }}
          />
        ),
      },
      q377: {
        title: "Q446. Arithmetic Slices II - Subsequence (Q377)",
        content: (
          <Comp
            title="Q446. Arithmetic Slices II - Subsequence (Q377)"
            content1={
              <>
                Given an integer array nums, return the number of all the
                arithmetic subsequences of nums.
                <br />
                A sequence of numbers is called arithmetic if it consists of at
                least three elements and if the difference between any two
                consecutive elements is the same.
                <br />
                For example, [1, 3, 5, 7, 9], [7, 7, 7, 7], and [3, -1, -5, -9]
                are arithmetic sequences.
                <br />
                For example, [1, 1, 2, 5, 7] is not an arithmetic sequence.
                <br />
                A subsequence of an array is a sequence that can be formed by
                removing some elements (possibly none) of the array.
                <br />
                For example, [2,5,10] is a subsequence of [1,2,1,2,4,1,5,10].
                <br />
                The test cases are generated so that the answer fits in 32-bit
                integer.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [2,4,6,8,10]
                    <br />
                    Output: 7<br />
                    Explanation: All arithmetic subsequence slices are:
                    <br />
                    [2,4,6]
                    <br />
                    [4,6,8]
                    <br />
                    [6,8,10]
                    <br />
                    [2,4,6,8]
                    <br />
                    [4,6,8,10]
                    <br />
                    [2,4,6,8,10]
                    <br />
                    [2,6,10]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [7,7,7,7,7]
                    <br />
                    Output: 16
                    <br />
                    Explanation: Any subsequence of this array is arithmetic.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 1000 <br />
                -2^31 &lt;= nums[i] &lt;= 2^31 - 1
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var numberOfArithmeticSlices = function(nums) {
                 const n = nums.length;
                 let res = 0;
                 const dp = Array.from(Array(n),()=>Array(n).fill(0))
                 const m = {};
                 for (let i = 0; i < n; ++i) {
                   m[nums[i]] = m[nums[i]] ? [...m[nums[i]],i] : [i]
                 }
               
                 for (let i = 0; i < n; ++i)
                   for (let j = 0; j < i; ++j) {
                     let target = nums[j] * 2 - nums[i];
                     if (target in m){
                      for (let k of m[target]){
                       if (k < j)
                           dp[i][j] += (dp[j][k] + 1);
                       }
                     }
                     res += dp[i][j];
                   }
                 return res;
               };
               
               numberOfArithmeticSlices([2,4,6,8,10])`,
                output: `7`,
              },
            }}
          />
        ),
      },
      q378: {
        title: "Q447. Number of Boomerangs (Q378)",
        content: (
          <Comp
            title="Q447. Number of Boomerangs (Q378)"
            content1={
              <>
                You are given n points in the plane that are all distinct, where
                points[i] = [xi, yi]. A boomerang is a tuple of points (i, j, k)
                such that the distance between i and j equals the distance
                between i and k (the order of the tuple matters).
                <br />
                Return the number of boomerangs.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: points = [[0,0],[1,0],[2,0]]
                    <br />
                    Output: 2<br />
                    Explanation: The two boomerangs are [[1,0],[0,0],[2,0]] and
                    [[1,0],[2,0],[0,0]].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: points = [[1,1],[2,2],[3,3]]
                    <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: points = [[1,1]] <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == points.length <br />
                1 &lt;= n &lt;= 500 <br />
                points[i].length == 2 <br />
                -10^4 &lt;= xi, yi &lt;= 10^4 <br />
                All the points are unique.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} points
                * @return {number}
                */
               
               function getDist(p, q) {
                 return Math.pow(p[0] - q[0], 2) + Math.pow(p[1] - q[1], 2);
               }
               
               var numberOfBoomerangs = function(points) {
                 let ans = 0;
                 for (let p of points) {
                   const count = {};
                   for (let q of points) {
                     const d = getDist(p, q);
                     count[d] = (count[d] || 0) + 1;
                   }
                   for (let freq of Object.values(count))
                     ans += freq * (freq - 1); 
                 }
                 return ans;
               }
               
               numberOfBoomerangs([[1,1],[2,2],[3,3]])`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q379: {
        title: "Q448. Find All Numbers Disappeared in an Array (Q379)",
        content: (
          <Comp
            title="Q448. Find All Numbers Disappeared in an Array (Q379)"
            content1={
              <>
                Given an array nums of n integers where nums[i] is in the range
                [1, n], return an array of all the integers in the range [1, n]
                that do not appear in nums.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [4,3,2,7,8,2,3,1]
                    <br />
                    Output: [5,6]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,1] <br />
                    Output: [2]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                {" "}
                n == nums.length <br />
                1 &lt;= n &lt;= 105
                <br />1 &lt;= nums[i] &lt;= n
              </>
            }
            fp={
              <>
                <b>Follow up :</b> Could you do it without extra space and in
                O(n) runtime? You may assume the returned list does not count as
                extra space.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number[]}
                */
               var findDisappearedNumbers = function(nums) {
                 const res = [];
                 for (let num of nums) {
                   let index = Math.abs(num) - 1;
                   nums[index] = -Math.abs(nums[index]);
                 }
                 for (let i = 0; i < nums.length; ++i)
                   if (nums[i] > 0)
                     res.push(i + 1);
                 return res;
               };
               
               findDisappearedNumbers([4,3,2,7,8,2,3,1])`,
                output: `[ 5, 6 ]`,
              },
            }}
          />
        ),
      },
      q380: {
        title: "Q449. Serialize and Deserialize BST (Q378)",
        content: (
          <Comp
            title="Q449. Serialize and Deserialize BST (Q378)"
            content1={
              <>
                Serialization is converting a data structure or object into a
                sequence of bits so that it can be stored in a file or memory
                buffer, or transmitted across a network connection link to be
                reconstructed later in the same or another computer environment.
                <br />
                Design an algorithm to serialize and deserialize a binary search
                tree. There is no restriction on how your
                serialization/deserialization algorithm should work. You need to
                ensure that a binary search tree can be serialized to a string,
                and this string can be deserialized to the original tree
                structure.
                <br />
                The encoded string should be as compact as possible.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: root = [2,1,3]
                    <br />
                    Output: [2,1,3]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = []
                    <br />
                    Output: []
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [0, 10^4].{" "}
                <br />
                0 &lt;= Node.val &lt;= 10^4 <br />
                The input tree is guaranteed to be a binary search tree.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode(int x) { val = x; }
                * }
                */
               // [2,1,3]
               public class Codec {
                 public String serialize(TreeNode root) {
                   if (root == null)
                     return "";
               
                   StringBuilder sb = new StringBuilder();
               
                   serialize(root, sb);
                   return sb.toString();
                 }
               
                   public TreeNode deserialize(String data) {
                   if (data.isEmpty())
                     return null;
                   final String[] vals = data.split(" ");
                   Queue<Integer> q = new LinkedList<>();
                   for (final String val : vals)
                     q.offer(Integer.parseInt(val));
                   return deserialize(Integer.MIN_VALUE, Integer.MAX_VALUE, q);
                 }
               
                 private void serialize(TreeNode root, StringBuilder sb) {
                   if (root == null)
                     return;
                   sb.append(root.val).append(" ");
                   serialize(root.left, sb);
                   serialize(root.right, sb);
                 }
               
                 private TreeNode deserialize(int min, int max, Queue<Integer> q) {
                   if (q.isEmpty())
                     return null;
                   final int val = q.peek();
                   if (val < min || val > max)
                     return null;
                   q.poll();
                   TreeNode root = new TreeNode(val);
                   root.left = deserialize(min, val, q);
                   root.right = deserialize(val, max, q);
                   return root;
                 }
               }
               
               // Your Codec object will be instantiated and called as such:
               // Codec ser = new Codec();
               // Codec deser = new Codec();
               // String tree = ser.serialize(root);
               // TreeNode ans = deser.deserialize(tree);
               // return ans;`,
                output: `[2,1,3]`,
              },
            }}
          />
        ),
      },
      q381: {
        title: "Q450. Delete Node in a BST (Q381)",
        content: (
          <Comp
            title="Q450. Delete Node in a BST (Q381)"
            content1={
              <>
                Given a root node reference of a BST and a key, delete the node
                with the given key in the BST. Return the root node reference
                (possibly updated) of the BST.
                <br />
                Basically, the deletion can be divided into two stages:
                <br />
                Search for a node to remove.
                <br />
                If the node is found, delete the node.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode450,
                content: (
                  <>
                    Input: root = [5,3,6,2,4,null,7], key = 3 <br />
                    Output: [5,4,6,2,null,null,7] <br />
                    Explanation: Given key to delete is 3. So we find the node
                    with value 3 and delete it. <br />
                    One valid answer is [5,4,6,2,null,null,7], shown in the
                    above BST. <br />
                    Please notice that another valid answer is
                    [5,2,6,null,4,null,7] and it's also accepted.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [5,3,6,2,4,null,7], key = 0<br />
                    Output: [5,3,6,2,4,null,7]
                    <br />
                    Explanation: The tree does not contain a node with value =
                    0.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [], key = 0 <br />
                    Output: []
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [0, 10^4].{" "}
                <br />
                -10^5 &lt;= Node.val &lt;= 10^5
                <br />
                Each node has a unique value.
                <br />
                root is a valid binary search tree.
                <br />
                -10^5 &lt;= key &lt;= 105
              </>
            }
            fp={
              <>
                <b>Follow up :</b> Could you solve it with time complexity
                O(height of tree)?
              </>
            }
            tc="h = log n"
            sc="h = log n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
              }
              
              /**
               * @param {TreeNode} root
               * @param {number} key
               * @return {TreeNode}
               */
              
              function getMin(node) {
                while (node.left)
                  node = node.left;
                return node;
              }
              
              var deleteNode = function(root, key) {
                if (!root)
                  return null;
                if (root.val == key) {
                  if (root.left == null)
                    return root.right;
                  if (root.right == null)
                    return root.left;
                  let minNode = getMin(root.right);
                  root.right = deleteNode(root.right, minNode.val);
                  minNode.left = root.left;
                  minNode.right = root.right;
                  root = minNode;
                } else if (root.val < key) {
                  root.right = deleteNode(root.right, key);
                } else { 
                  root.left = deleteNode(root.left, key);
                }
                return root;
              };
              
              const t = new TreeNode(5)
              t.left = new TreeNode(3)
              t.left.left =  new TreeNode(2)
              t.left.right = new TreeNode(4)
              t.right = new TreeNode(6)
              t.right.right =  new TreeNode(7)
              deleteNode(t,3)`,
                output: `TreeNode {
                  val: 5,
                  left: TreeNode {
                    val: 4,
                    left: TreeNode { val: 2, left: null, right: null },
                    right: null
                  },
                  right: TreeNode {
                    val: 6,
                    left: null,
                    right: TreeNode { val: 7, left: null, right: null }
                  }
                }`,
              },
            }}
          />
        ),
      },
      q382: {
        title: "Q451. Sort Characters By Frequency (Q382)",
        content: (
          <Comp
            title="Q451. Sort Characters By Frequency (Q382)"
            content1={
              <>
                Given a string s, sort it in decreasing order based on the
                frequency of the characters. The frequency of a character is the
                number of times it appears in the string.
                <br />
                Return the sorted string. If there are multiple answers, return
                any of them.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "tree" <br />
                    Output: "eert" <br />
                    Explanation: 'e' appears twice while 'r' and 't' both appear
                    once. <br />
                    So 'e' must appear before both 'r' and 't'. Therefore "eetr"
                    is also a valid answer.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "cccaaa"
                    <br />
                    Output: "aaaccc"
                    <br />
                    Explanation: Both 'c' and 'a' appear three times, so both
                    "cccaaa" and "aaaccc" are valid answers. Note that "cacaca"
                    is incorrect, as the same characters must be together.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "Aabb" <br />
                    Output: "bbAa" <br />
                    Explanation: "bbaA" is also a valid answer, but "Aabb" is
                    incorrect. Note that 'A' and 'a' are treated as two
                    different characters.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 5 * 10^5 <br />s consists of uppercase
                and lowercase English letters and digits.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {string}
                */
               var frequencySort = function(s) {
                 const n = s.length;
                 const st = [];
                 const count = Array(128).fill(0);
                 const bucket = Array(n + 1).fill([]);
                 
                 for (let c of s)
                   ++count[c.charCodeAt(0)];
                 for (let i = 0; i < 128; ++i) {
                   let freq = count[i];
                   if (freq > 0) {
                     bucket[freq] = [...bucket[freq],i]
                   }
                 }
                 for (let freq = n; freq > 0; --freq){
                   if (bucket[freq].length)
                     for (let c of bucket[freq]){
                       for (let i = 0; i < freq; ++i)
                         st.push(String.fromCharCode(c));
                     }
                 }
                 return st.join(""); 
               };
               
               console.log(frequencySort("Aabb"))`,
                output: `bbAa`,
              },
            }}
          />
        ),
      },
      q383: {
        title: "Q452. Minimum Number of Arrows to Burst Balloons (Q383)",
        content: (
          <Comp
            title="Q452. Minimum Number of Arrows to Burst Balloons (Q383)"
            content1={
              <>
                There are some spherical balloons taped onto a flat wall that
                represents the XY-plane. The balloons are represented as a 2D
                integer array points where points[i] = [xstart, xend] denotes a
                balloon whose horizontal diameter stretches between xstart and
                xend. You do not know the exact y-coordinates of the balloons.
                <br />
                Arrows can be shot up directly vertically (in the positive
                y-direction) from different points along the x-axis. A balloon
                with xstart and xend is burst by an arrow shot at x if xstart
                &lt;= x &lt;= xend. There is no limit to the number of arrows
                that can be shot. A shot arrow keeps traveling up infinitely,
                bursting any balloons in its path.
                <br />
                Given the array points, return the minimum number of arrows that
                must be shot to burst all balloons.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: points = [[10,16],[2,8],[1,6],[7,12]] <br />
                    Output: 2 <br />
                    Explanation: The balloons can be burst by 2 arrows: <br />
                    - Shoot an arrow at x = 6, bursting the balloons [2,8] and
                    [1,6]. <br />- Shoot an arrow at x = 11, bursting the
                    balloons [10,16] and [7,12].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: points = [[1,2],[3,4],[5,6],[7,8]] <br />
                    Output: 4 <br />
                    Explanation: One arrow needs to be shot for each balloon for
                    a total of 4 arrows.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: points = [[1,2],[2,3],[3,4],[4,5]] <br />
                    Output: 2 <br />
                    Explanation: The balloons can be burst by 2 arrows: <br />
                    - Shoot an arrow at x = 2, bursting the balloons [1,2] and
                    [2,3]. <br />- Shoot an arrow at x = 4, bursting the
                    balloons [3,4] and [4,5].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= points.length &lt;= 10^5 <br />
                points[i].length == 2 <br />
                -2^31 &lt;= xstart &lt; xend &lt;= 2^31 - 1
              </>
            }
            tc="n.log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} points
                * @return {number}
                */
               var findMinArrowShots = function(points) {
                 points.sort((a, b) => a[1] - b[1]);
                 let res = 1;
                 let arrowX = points[0][1];
                 for (let i = 1; i < points.length; ++i)
                   if (points[i][0] > arrowX) {
                     arrowX = points[i][1];
                     ++res;
                 }
                 return res;  
               };
               
               console.log(findMinArrowShots([[1,2],[3,4],[5,6],[7,8]]))`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q384: {
        title: "Q453. Minimum Moves to Equal Array Elements (Q384)",
        content: (
          <Comp
            title="Q453. Minimum Moves to Equal Array Elements (Q384)"
            content1={
              <>
                Given an integer array nums of size n, return the minimum number
                of moves required to make all array elements equal.
                <br />
                In one move, you can increment n - 1 elements of the array by 1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,3] <br />
                    Output: 3 <br />
                    Explanation: Only three moves are needed (remember each move
                    increments two elements): [1,2,3] =&gt; [2,3,3] =&gt;
                    [3,4,3] =&gt; [4,4,4]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,1,1] <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == nums.length <br />
                1 &lt;= nums.length &lt;= 10^5
                <br />
                -10^9 &lt;= nums[i] &lt;= 10^9
                <br />
                The answer is guaranteed to fit in a 32-bit integer.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var minMoves = function(nums) {
                 let sum = nums.reduce((a,b)=>a+b,0);
                 let min = Math.min(...nums);
                 return sum - min * nums.length;
               };
               
               console.log(minMoves([1,1,1]))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q385: {
        title: "Q454. 4Sum II (Q385)",
        content: (
          <Comp
            title="Q454. 4Sum II (Q385)"
            content1={
              <>
                Given four integer arrays nums1, nums2, nums3, and nums4 all of
                length n, return the number of tuples (i, j, k, l) such that:
                <br />
                0 &lt;= i, j, k, l &lt; n
                <br />
                nums1[i] + nums2[j] + nums3[k] + nums4[l] == 0
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums1 = [1,2], nums2 = [-2,-1], nums3 = [-1,2], nums4
                    = [0,2]
                    <br />
                    Output: 2<br />
                    Explanation:
                    <br />
                    The two tuples are:
                    <br />
                    1. (0, 0, 0, 1) -&gt; nums1[0] + nums2[0] + nums3[0] +
                    nums4[1] = 1 + (-2) + (-1) + 2 = 0<br />
                    2. (1, 1, 0, 0) -&gt; nums1[1] + nums2[1] + nums3[0] +
                    nums4[0] = 2 + (-1) + (-1) + 0 = 0
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums1 = [0], nums2 = [0], nums3 = [0], nums4 = [0]{" "}
                    <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == nums1.length <br />
                n == nums2.length <br />
                n == nums3.length <br />
                n == nums4.length <br />
                1 &lt;= n &lt;= 200 <br />
                -228 &lt;= nums1[i], nums2[i], nums3[i], nums4[i] &lt;= 228
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums1
                * @param {number[]} nums2
                * @param {number[]} nums3
                * @param {number[]} nums4
                * @return {number}
                */
               var fourSumCount = function(nums1, nums2, nums3, nums4) {
                 let res = 0;
                 const count = new Map();
               
                 for (let a of nums1)
                 for (let b of nums2)
                 count.set(a + b, (count.get(a + b) ? count.get(a + b):0) + 1);
               
                 for (let c of nums3)
                 for (let d of nums4)
                 if (count.has(-c - d))
                 res += count.get(-c - d);
               
                 return res;
               };
               
               console.log(fourSumCount([0],[0],[0],[0]))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q386: {
        title: "Q455. Assign Cookies (Q386)",
        content: (
          <Comp
            title="Q455. Assign Cookies (Q386)"
            content1={
              <>
                Assume you are an awesome parent and want to give your children
                some cookies. But, you should give each child at most one
                cookie.
                <br />
                Each child i has a greed factor g[i], which is the minimum size
                of a cookie that the child will be content with; and each cookie
                j has a size s[j]. If s[j] &gt;= g[i], we can assign the cookie
                j to the child i, and the child i will be content. Your goal is
                to maximize the number of your content children and output the
                maximum number.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: g = [1,2,3], s = [1,1] <br />
                    Output: 1 <br />
                    Explanation: You have 3 children and 2 cookies. The greed
                    factors of 3 children are 1, 2, 3. And even though you have
                    2 cookies, since their size is both 1, you could only make
                    the child whose greed factor is 1 content. You need to
                    output 1.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: g = [1,2], s = [1,2,3] <br />
                    Output: 2 <br />
                    Explanation: You have 2 children and 3 cookies. The greed
                    factors of 2 children are 1, 2. You have 3 cookies and their
                    sizes are big enough to gratify all of the children, You
                    need to output 2.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= g.length &lt;= 3 * 10^4
                <br />
                0 &lt;= s.length &lt;= 3 * 1^04
                <br />1 &lt;= g[i], s[j] &lt;= 2^31 - 1
              </>
            }
            tc="n.log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} g
                * @param {number[]} s
                * @return {number}
                */
               var findContentChildren = function(g, s) {
                 g.sort((a,b)=>a-b);
                 s.sort((a,b)=>a-b);
                 let i = 0;
                 for (let j = 0; i < g.length && j < s.length; ++j)
                   if (g[i] <= s[j])
                   ++i;
                 return i;  
               };
               
               console.log(findContentChildren([1,2],[1,2,3]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q387: {
        title: "Q456. 132 Pattern (Q387)",
        content: (
          <Comp
            title="Q456. 132 Pattern (Q387)"
            content1={
              <>
                Given an array of n integers nums, a 132 pattern is a
                subsequence of three integers nums[i], nums[j] and nums[k] such
                that i &lt; j &lt; k and nums[i] &lt; nums[k] &lt; nums[j].
                <br />
                Return true if there is a 132 pattern in nums, otherwise, return
                false.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4] <br />
                    Output: false <br />
                    Explanation: There is no 132 pattern in the sequence.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [3,1,4,2]
                    <br />
                    Output: true
                    <br />
                    Explanation: There is a 132 pattern in the sequence: [1, 4,
                    2].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [-1,3,2,0]
                    <br />
                    Output: true
                    <br />
                    Explanation: There are three 132 patterns in the sequence:
                    [-1, 3, 2], [-1, 3, 0] and [-1, 2, 0].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == nums.length <br />
                1 &lt;= n &lt;= 2 * 10^5 <br />
                -10^9 &lt;= nums[i] &lt;= 10^9
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {boolean}
                */
               var find132pattern = function(nums) {
                 const stack = []; 
                 let a = Number.MIN_SAFE_INTEGER;       
                 for (let i = nums.length - 1; i >= 0; --i) {
                   if (nums[i] < a) 
                     return true;
                   while (stack.length && stack.slice(-1)[0] < nums[i])
                     a = stack.pop();
                   stack.push(nums[i]);
                 }
               
                 return false;   
               };
               
               console.log(find132pattern([-1,3,2,0]))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q388: {
        title: "Q457. Circular Array Loop (Q388)",
        content: (
          <Comp
            title="Q457. Circular Array Loop (Q388)"
            content1={
              <>
                You are playing a game involving a circular array of non-zero
                integers nums. Each nums[i] denotes the number of indices
                forward/backward you must move if you are located at index i:
                <br />
                If nums[i] is positive, move nums[i] steps forward, and <br />
                If nums[i] is negative, move nums[i] steps backward. <br />
                Since the array is circular, you may assume that moving forward
                from the last element puts you on the first element, and moving
                backwards from the first element puts you on the last element.
                <br />
                A cycle in the array consists of a sequence of indices seq of
                length k where:
                <br />
                Following the movement rules above results in the repeating
                index sequence seq[0] -&gt; seq[1] -&gt; ... -&gt; seq[k - 1]
                -&gt; seq[0] -&gt; ...
                <br /> Every nums[seq[j]] is either all positive or all
                negative. k &gt; 1 <br />
                Return true if there is a cycle in nums, or false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [2,-1,1,2,2]
                    <br />
                    Output: true
                    <br />
                    Explanation: There is a cycle from index 0 -&gt; 2 -&gt; 3
                    -&gt; 0 -&gt; ... The cycle's length is 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [-1,2]
                    <br />
                    Output: false
                    <br />
                    Explanation:
                    <br />
                    The sequence from index 1 -&gt; 1 -&gt; 1 -&gt; ... is not a
                    cycle because the sequence's length is 1. By definition the
                    sequence's length must be strictly greater than 1 to be a
                    cycle.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [-2,1,-1,-2,-2]
                    <br />
                    Output: false
                    <br />
                    Explanation:
                    <br />
                    The sequence from index 1 -&lt; 2 -&lt; 1 -&lt; ... is not a
                    cycle because nums[1] is positive, but nums[2] is negative.
                    Every nums[seq[j]] must be either all positive or all
                    negative.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 5000 <br />
                -1000 &lt;= nums[i] &lt;= 1000 <br />
                nums[i] != 0
              </>
            }
            fp={
              <>
                <b>Follow up : </b>Could you solve it in O(n) time complexity
                and O(1) extra space complexity?
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {boolean}
                */
               function advance(nums,i){
                 let n = nums.length;
                 let val = (i + nums[i]) % n;
                 return i + nums[i] >= 0 ? val : n + val;
               }
               
               var circularArrayLoop = function(nums) {
                 if (nums.length < 2)
                     return false;
                 for (let i = 0; i < nums.length; ++i) {
                   if (nums[i] == 0)
                     continue;
                   let slow = i;
                   let fast = advance(nums, slow);
                     while (nums[i] * nums[fast] > 0 && nums[i] * nums[advance(nums, fast)] > 0) {
                       if (slow == fast) {
                         if (slow == advance(nums, slow))
                           break;
                         return true;
                       }
                       slow = advance(nums, slow);
                       fast = advance(nums, advance(nums, fast));
                     }
                     slow = i;
                     let sign = nums[i];
                     while (sign * nums[slow] > 0) {
                       let next = advance(nums, slow);
                       nums[slow] = 0;
                       slow = next;
                     }
                   }
                   return false;   
               };
               
               console.log(circularArrayLoop([2,-1,1,2,2]))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q389: {
        title: "Q458. Poor Pigs (Q389)",
        content: (
          <Comp
            title="Q458. Poor Pigs (Q389)"
            content1={
              <>
                There are buckets buckets of liquid, where exactly one of the
                buckets is poisonous. To figure out which one is poisonous, you
                feed some number of (poor) pigs the liquid to see whether they
                will die or not. Unfortunately, you only have minutesToTest
                minutes to determine which bucket is poisonous.
                <br />
                You can feed the pigs according to these steps:
                <br />
                Choose some live pigs to feed.
                <br />
                For each pig, choose which buckets to feed it. The pig will
                consume all the chosen buckets simultaneously and will take no
                time.
                <br />
                Wait for minutesToDie minutes. You may not feed any other pigs
                during this time.
                <br />
                After minutesToDie minutes have passed, any pigs that have been
                fed the poisonous bucket will die, and all others will survive.
                <br />
                Repeat this process until you run out of time.
                <br />
                Given buckets, minutesToDie, and minutesToTest, return the
                minimum number of pigs needed to figure out which bucket is
                poisonous within the allotted time.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: buckets = 4, minutesToDie = 15, minutesToTest = 15{" "}
                    <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: buckets = 4, minutesToDie = 15, minutesToTest = 15{" "}
                    <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: buckets = 4, minutesToDie = 15, minutesToTest = 30{" "}
                    <br />
                    Output: 2
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= buckets &lt;= 1000 <br />1 &lt;= minutesToDie &lt;=
                minutesToTest &lt;= 100
              </>
            }
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} buckets
                * @param {number} minutesToDie
                * @param {number} minutesToTest
                * @return {number}
                */
               var poorPigs = function(buckets, minutesToDie, minutesToTest) {
                 return parseInt(Math.ceil(Math.log(buckets) / Math.log(parseInt(minutesToTest / minutesToDie + 1))));
               };
               
               console.log(poorPigs(1000,15,60))`,
                output: `5`,
              },
            }}
          />
        ),
      },
      q390: {
        title: "Q459. Repeated Substring Pattern (Q390)",
        content: (
          <Comp
            title="Q459. Repeated Substring Pattern (Q390)"
            content1={
              <>
                Given a string s, check if it can be constructed by taking a
                substring of it and appending multiple copies of the substring
                together.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "abab"
                    <br />
                    Output: true
                    <br />
                    Explanation: It is the substring "ab" twice.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "aba"
                    <br />
                    Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "aba"
                    <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^4 <br />s consists of lowercase
                English letters.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {boolean}
                */
               var repeatedSubstringPattern = function(s) {
                 let st = s + s;
                 return st.substring(1, st.length - 1).includes(s);
               };
               
               console.log(repeatedSubstringPattern("abab"))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q391: {
        title: "Q460. LFU Cache (Q391)",
        content: (
          <Comp
            title="Q460. LFU Cache (Q391)"
            content1={
              <>
                Design and implement a data structure for a Least Frequently
                Used (LFU) cache.
                <br />
                Implement the LFUCache class:
                <br />
                LFUCache(int capacity) Initializes the object with the capacity
                of the data structure.
                <br /> int get(int key) Gets the value of the key if the key
                exists in the cache. Otherwise, returns -1.
                <br /> void put(int key, int value) Update the value of the key
                if present, or inserts the key if not already present. When the
                cache reaches its capacity, it should invalidate and remove the
                least frequently used key before inserting a new item. For this
                problem, when there is a tie (i.e., two or more keys with the
                same frequency), the least recently used key would be
                invalidated.
                <br /> To determine the least frequently used key, a use counter
                is maintained for each key in the cache. The key with the
                smallest use counter is the least frequently used key.
                <br /> When a key is first inserted into the cache, its use
                counter is set to 1 (due to the put operation). The use counter
                for a key in the cache is incremented either a get or put
                operation is called on it.
                <br /> The functions get and put must each run in O(1) average
                time complexity.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input ["LFUCache", "put", "put", "get", "put", "get", "get",
                    "put", "get", "get", "get"]
                    <br />
                    [[2], [1, 1], [2, 2], [1], [3, 3], [2], [3], [4, 4], [1],
                    [3], [4]]
                    <br />
                    Output
                    <br />
                    [null, null, null, 1, null, -1, 3, null, -1, 3, 4]
                    <br />
                    <br />
                    Explanation
                    <br />
                    // cnt(x) = the use counter for key x<br />
                    // cache=[] will show the last used order for tiebreakers
                    (leftmost element is most recent)
                    <br />
                    LFUCache lfu = new LFUCache(2);
                    <br />
                    lfu.put(1, 1); // cache=[1,_], cnt(1)=1
                    <br />
                    lfu.put(2, 2); // cache=[2,1], cnt(2)=1, cnt(1)=1
                    <br />
                    lfu.get(1); // return 1<br />
                    // cache=[1,2], cnt(2)=1, cnt(1)=2
                    <br />
                    lfu.put(3, 3); // 2 is the LFU key because cnt(2)=1 is the
                    smallest, invalidate 2.
                    <br />
                    // cache=[3,1], cnt(3)=1, cnt(1)=2
                    <br />
                    lfu.get(2); // return -1 (not found)
                    <br />
                    lfu.get(3); // return 3<br />
                    // cache=[3,1], cnt(3)=2, cnt(1)=2
                    <br />
                    lfu.put(4, 4); // Both 1 and 3 have the same cnt, but 1 is
                    LRU, invalidate 1.
                    <br />
                    // cache=[4,3], cnt(4)=1, cnt(3)=2
                    <br />
                    lfu.get(1); // return -1 (not found)
                    <br />
                    lfu.get(3); // return 3<br />
                    // cache=[3,4], cnt(4)=1, cnt(3)=3
                    <br />
                    lfu.get(4); // return 4<br />
                    // cache=[4,3], cnt(4)=2, cnt(3)=3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= capacity &lt;= 10^4 <br />
                0 &lt;= key &lt;= 10^5
                <br />
                0 &lt;= value &lt;= 10^9
                <br />
                At most 2 * 105 calls will be made to get and put.
              </>
            }
            tc="1"
            sc="n"
            codes={{
              Java: {
                code: `
                // ["LFUCache","put","put","get","put","get","get","put","get","get","get"]
// [[2],[1,1],[2,2],[1],[3,3],[2],[3],[4,4],[1],[3],[4]]
                class LFUCache {
                  public LFUCache(int capacity) {
                    this.capacity = capacity;
                  }
                
                  public int get(int key) {
                    if (!keyToVal.containsKey(key))
                      return -1;
                
                    final int freq = keyToFreq.get(key);
                    freqToLRUKeys.get(freq).remove(key);
                    if (freq == minFreq && freqToLRUKeys.get(freq).isEmpty()) {
                      freqToLRUKeys.remove(freq);
                      ++minFreq;
                    }
                    putFreq(key, freq + 1);
                    return keyToVal.get(key);
                  }
                
                  public void put(int key, int value) {
                    if (capacity == 0)
                      return;
                    if (keyToVal.containsKey(key)) {
                      keyToVal.put(key, value);
                      get(key); 
                      return;
                    }
                
                    if (keyToVal.size() == capacity) {
                      final int keyToEvict = freqToLRUKeys.get(minFreq).iterator().next();
                      freqToLRUKeys.get(minFreq).remove(keyToEvict);
                      keyToVal.remove(keyToEvict);
                    }
                
                    minFreq = 1;
                    putFreq(key, minFreq);   
                    keyToVal.put(key, value); 
                  }
                
                  private int capacity;
                  private int minFreq = 0;
                  private Map<Integer, Integer> keyToVal = new HashMap<>();
                  private Map<Integer, Integer> keyToFreq = new HashMap<>();
                  private Map<Integer, LinkedHashSet<Integer>> freqToLRUKeys = new HashMap<>();
                
                  private void putFreq(int key, int freq) {
                    keyToFreq.put(key, freq);
                    freqToLRUKeys.putIfAbsent(freq, new LinkedHashSet<>());
                    freqToLRUKeys.get(freq).add(key);
                  }
                }
                
                /**
                 * Your LFUCache object will be instantiated and called as such:
                 * LFUCache obj = new LFUCache(capacity);
                 * int param_1 = obj.get(key);
                 * obj.put(key,value);
                 */`,
                output: `[null,null,null,1,null,-1,3,null,-1,3,4]`,
              },
            }}
          />
        ),
      },
      q392: {
        title: "Q461. Hamming Distance (Q392)",
        content: (
          <Comp
            title="Q461. Hamming Distance (Q392)"
            content1={
              <>
                The Hamming distance between two integers is the number of
                positions at which the corresponding bits are different.
                <br />
                Given two integers x and y, return the Hamming distance between
                them.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: x = 1, y = 4 <br />
                    Output: 2 <br />
                    Explanation: <br />
                    <pre>
                      {`
1   (0 0 0 1)  
4   (0 1 0 0) 
       ↑   ↑  
                    `}
                    </pre>
                    The above arrows point to positions where the corresponding
                    bits are different.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: x = 3, y = 1 <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={<>0 &lt;= x, y &lt;= 2^31 - 1</>}
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} x
                * @param {number} y
                * @return {number}
                */
               var hammingDistance = function(x, y) {
                 let res = 0;
                 while (x > 0 || y > 0) {
                   res += (x & 1) ^ (y & 1);
                   x >>= 1;
                   y >>= 1;
                 }
                 return res;
               };
               
               console.log(hammingDistance(1,4))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q393: {
        title: "Q462. Minimum Moves to Equal Array Elements II (Q393)",
        content: (
          <Comp
            title="Q462. Minimum Moves to Equal Array Elements II (Q393)"
            content1={
              <>
                Given an integer array nums of size n, return the minimum number
                of moves required to make all array elements equal.
                <br />
                In one move, you can increment or decrement an element of the
                array by 1.
                <br />
                Test cases are designed so that the answer will fit in a 32-bit
                integer.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,3] <br />
                    Output: 2 <br />
                    Explanation: <br />
                    Only two moves are needed (remember each move increments or
                    decrements one element): <br />
                    [1,2,3] =&gt; [2,2,3] =&gt; [2,2,2]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,10,2,9] <br />
                    Output: 16
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == nums.length <br />
                1 &lt;= nums.length &lt;= 10^5 <br />
                -10^9 &lt;= nums[i] &lt;= 1^09
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Java: {
                code: `
                [1,10,2,9]
                class Solution {
                  public int minMoves2(int[] nums) {
                    final int n = nums.length;
                    final int median = quickSelect(nums, 0, n - 1, (n + 1) / 2);
                    int ans = 0;
                    for (final int num : nums)
                      ans += Math.abs(num - median);
                    return ans;
                  }
  
                  private int quickSelect(int[] nums, int l, int r, int k) {
                    final int randIndex = new Random().nextInt(r - l + 1) + l;
                    swap(nums, randIndex, r);
                    final int pivot = nums[r];
                    int nextSwapped = l;
                    for (int i = l; i < r; ++i)
                      if (nums[i] <= pivot)
                        swap(nums, nextSwapped++, i);
                    swap(nums, nextSwapped, r);
                    final int count = nextSwapped - l + 1;
                    if (count == k)
                      return nums[nextSwapped];
                    if (count > k)
                      return quickSelect(nums, l, nextSwapped - 1, k);
                    return quickSelect(nums, nextSwapped + 1, r, k - count);
                  }
                  private void swap(int[] nums, int i, int j) {
                    final int temp = nums[i];
                    nums[i] = nums[j];
                    nums[j] = temp;
                  }
                }
               `,
                output: `16`,
              },
            }}
          />
        ),
      },
      q394: {
        title: "Q463. Island Perimeter (Q394)",
        content: (
          <Comp
            title="Q463. Island Perimeter (Q394)"
            content1={
              <>
                You are given row x col grid representing a map where grid[i][j]
                = 1 represents land and grid[i][j] = 0 represents water.
                <br />
                Grid cells are connected horizontally/vertically (not
                diagonally). The grid is completely surrounded by water, and
                there is exactly one island (i.e., one or more connected land
                cells).
                <br />
                The island doesn't have "lakes", meaning the water inside isn't
                connected to the water around the island. One cell is a square
                with side length 1. The grid is rectangular, width and height
                don't exceed 100. Determine the perimeter of the island.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode463,
                content: (
                  <>
                    Input: grid = [[0,1,0,0],[1,1,1,0],[0,1,0,0],[1,1,0,0]]
                    <br /> Output: 16 <br />
                    Explanation: The perimeter is the 16 yellow stripes in the
                    image above.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[1]] <br />
                    Output: 4
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[1,0]] <br />
                    Output: 4
                  </>
                ),
              },
            ]}
            constraints={
              <>
                row == grid.length
                <br />
                col == grid[i].length
                <br />
                1 &lt;= row, col &lt;= 100
                <br />
                grid[i][j] is 0 or 1.
                <br />
                There is exactly one island in grid.
              </>
            }
            tc="m.n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} grid
                * @return {number}
                */
               var islandPerimeter = function(grid) {
                 let islands = 0;
                 let neighbors = 0;
                 const m = grid.length
                 const n = grid[0].length
                 for (let i = 0; i < m; ++i)
                   for (let j = 0; j < n; ++j)
                     if (grid[i][j]) {
                       ++islands;
                       if (i - 1 >= 0 && grid[i - 1][j])
                         ++neighbors;
                       if (j - 1 >= 0 && grid[i][j - 1])
                         ++neighbors;
                     }
                 return islands * 4 - neighbors * 2;
               };
               
               console.log(islandPerimeter([[0,1,0,0],[1,1,1,0],[0,1,0,0],[1,1,0,0]]))`,
                output: `16`,
              },
            }}
          />
        ),
      },
      q395: {
        title: "Q464. Can I Win (Q395)",
        content: (
          <Comp
            title="Q464. Can I Win (Q395)"
            content1={
              <>
                In the "100 game" two players take turns adding, to a running
                total, any integer from 1 to 10. The player who first causes the
                running total to reach or exceed 100 wins.
                <br />
                What if we change the game so that players cannot re-use
                integers?
                <br />
                For example, two players might take turns drawing from a common
                pool of numbers from 1 to 15 without replacement until they
                reach a total >= 100.
                <br />
                Given two integers maxChoosableInteger and desiredTotal, return
                true if the first player to move can force a win, otherwise,
                return false. Assume both players play optimally.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: maxChoosableInteger = 10, desiredTotal = 11
                    <br /> Output: false
                    <br /> Explanation:
                    <br /> No matter which integer the first player choose, the
                    first player will lose.
                    <br /> The first player can choose an integer from 1 up to
                    10.
                    <br /> If the first player choose 1, the second player can
                    only choose integers from 2 up to 10.
                    <br /> The second player will win by choosing 10 and get a
                    total = 11, which is >= desiredTotal.
                    <br /> Same with other integers chosen by the first player,
                    the second player will always win.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: maxChoosableInteger = 10, desiredTotal = 1
                    <br /> Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: maxChoosableInteger = 10, desiredTotal = 1
                    <br /> Output: true
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= maxChoosableInteger &lt;= 20 <br />0 &lt;= desiredTotal
                &lt;= 300
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// 10 , 11
                class Solution {
                  public int trav(int n,int target,int bit,int dp[])
                  {
                      if(target<=0)
                          return 1;
                      if(dp[bit]!=-1)
                          return dp[bit];
                      for(int i=1;i<=n;i++)
                      {
                          if((bit & (1<<i))==0)
                          {
                              if(trav(n,target-i,bit|(1<<i),dp)==1)
                              {
                                  return dp[bit]=0;
                              }
                          }
                      }
                      return dp[bit]=1;
                  }
                  public boolean canIWin(int maxChoosableInteger, int desiredTotal) {
                      int n=maxChoosableInteger;
                      int sum=((n)*(n+1))/2;
                      if(sum<desiredTotal)
                          return false;
                      if(desiredTotal==0)
                          return true;
                      int dp[]=new int[1<<(maxChoosableInteger+1)];
                      for(int i=0;i<dp.length;i++)
                          dp[i]=-1;
                      if(trav(maxChoosableInteger,desiredTotal,0,dp)==0)
                          return true;
                      return false;
                  }
              }
                `,
                output: `false`,
              },
            }}
          />
        ),
      },
      q396: {
        title: "Q466. Count The Repetitions (Q396)",
        content: (
          <Comp
            title="Q466. Count The Repetitions (Q396)"
            content1={
              <>
                We define str = [s, n] as the string str which consists of the
                string s concatenated n times.
                <br />
                For example, str == ["abc", 3] =="abcabcabc".
                <br />
                We define that string s1 can be obtained fro
                <br />
                m string s2 if we can remove some characters from s2 such that
                it becomes s1.
                <br />
                For example, s1 = "abc" can be obtained from s2 = "abdbec" based
                on our definition by removing the bolded underlined characters.
                <br />
                You are given two strings s1 and s2 and two integers n1 and n2.
                You have the two strings str1 = [s1, n1] and str2 = [s2, n2].
                <br />
                Return the maximum integer m such that str = [str2, m] can be
                obtained from str1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s1 = "acb", n1 = 4, s2 = "ab", n2 = 2 <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s1 = "acb", n1 = 1, s2 = "acb", n2 = 1<br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s1.length, s2.length &lt;= 100
                <br />
                s1 and s2 consist of lowercase English letters.
                <br />1 &lt;= n1, n2 &lt;= 106
              </>
            }
            tc="|s1||s2|"
            sc="|s2|"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s1
                * @param {number} n1
                * @param {string} s2
                * @param {number} n2
                * @return {number}
                */
               class Record {
                 constructor(count, nextIndex) {
                   this.count = count;
                   this.nextIndex = nextIndex;
                 }
               };
               
               var getMaxRepetitions = function(s1, n1, s2, n2) {
                 const records = []; 
                 for (let i = 0; i < s2.length; ++i) {
                   let count = 0;
                   let nextIndex = i;
                   for (let j = 0; j < s1.length; ++j)
                     if (s2[nextIndex] == s1[j])
                       if (++nextIndex == s2.length) { 
                         ++count;
                         nextIndex = 0;
                       }
                   records.push(new Record(count, nextIndex));
                 }
                 let matches = 0;
                 let index = 0;
                 while (n1-- > 0) {
                   matches += records[index].count;
                   index = records[index].nextIndex;
                 }
                 return parseInt(matches / n2);  
               };
               
               console.log(getMaxRepetitions("acb",1,"acb",1))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q397: {
        title: "Q467. Unique Substrings in Wraparound String (Q397)",
        content: (
          <Comp
            title="Q467. Unique Substrings in Wraparound String (Q397)"
            content1={
              <>
                We define the string s to be the infinite wraparound string of
                "abcdefghijklmnopqrstuvwxyz", so s will look like this:
                <br />
                "...zabcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcd....".
                <br />
                Given a string p, return the number of unique non-empty
                substrings of p are present in s.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: p = "a" <br />
                    Output: 1 <br />
                    Explanation: Only the substring "a" of p is in s.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: p = "cac" <br />
                    Output: 2 <br />
                    Explanation: There are two substrings ("a", "c") of p in s.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: p = "zab"
                    <br />
                    Output: 6<br />
                    Explanation: There are six substrings ("z", "a", "b", "za",
                    "ab", and "zab") of p in s.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= p.length &lt;= 10^5 <br />p consists of lowercase
                English letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} p
                * @return {number}
                */
               var findSubstringInWraproundString = function(p) {
                 let maxLength = 1;
                 const count = Array(26).fill(0); 
                 for (let i = 0; i < p.length; ++i) {
                   if (i > 0 && (p[i].charCodeAt(0) - p[i - 1].charCodeAt(0) == 1 || p[i - 1].charCodeAt(0) - p[i].charCodeAt(0) == 25))
                     ++maxLength;
                   else
                     maxLength = 1;
                   let index = p[i].charCodeAt(0) - 97;
                   count[index] = Math.max(count[index], maxLength);
                 }
                 return count.reduce((a,b)=>a+b,0);
               };
               
               console.log(findSubstringInWraproundString("zab"))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q398: {
        title: "Q468. Validate IP Address (Q398)",
        content: (
          <Comp
            title="Q468. Validate IP Address (Q398)"
            content1={
              <>
                Given a string queryIP, return "IPv4" if IP is a valid IPv4
                address, "IPv6" if IP is a valid IPv6 address or "Neither" if IP
                is not a correct IP of any type.
                <br />
                A valid IPv4 address is an IP in the form "x1.x2.x3.x4" where 0
                &lt;= xi &lt;= 255 and xi cannot contain leading zeros. For
                example, "192.168.1.1" and "192.168.1.0" are valid IPv4
                addresses but "192.168.01.1", while "192.168.1.00" and
                "192.168@1.1" are invalid IPv4 addresses.
                <br />
                A valid IPv6 address is an IP in the form
                "x1:x2:x3:x4:x5:x6:x7:x8" where:
                <br />
                1 &lt;= xi.length &lt;= 4<br />
                xi is a hexadecimal string which may contain digits, lower-case
                English letter ('a' to 'f') and upper-case English letters ('A'
                to 'F').
                <br />
                Leading zeros are allowed in xi.
                <br />
                For example, "2001:0db8:85a3:0000:0000:8a2e:0370:7334" and
                "2001:db8:85a3:0:0:8A2E:0370:7334" are valid IPv6 addresses,
                while "2001:0db8:85a3::8A2E:037j:7334" and
                "02001:0db8:85a3:0000:0000:8a2e:0370:7334" are invalid IPv6
                addresses.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: queryIP = "172.16.254.1" <br />
                    Output: "IPv4" <br />
                    Explanation: This is a valid IPv4 address, return "IPv4".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: queryIP = "2001:0db8:85a3:0:0:8A2E:0370:7334" <br />
                    Output: "IPv6" <br />
                    Explanation: This is a valid IPv6 address, return "IPv6".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: queryIP = "256.256.256.256" <br />
                    Output: "Neither" <br />
                    Explanation: This is neither a IPv4 address nor a IPv6
                    address.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                queryIP consists only of English letters, digits and the
                characters '.' and ':'.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} queryIP
                * @return {string}
                */
               var validIPAddress = function(queryIP) {
                 if (queryIP.split("").filter(c => c == '.').length == 3) {
                 for (let digit of queryIP.split("."))
                   if (!isIPv4(digit))
                     return "Neither";
                   return "IPv4";
                 }
                 if (queryIP.split("").filter(c => c == ':').length == 7) {
                 for (let digit of queryIP.split(":"))
                   if (!isIPv6(digit))
                     return "Neither";
                   return "IPv6";
                 }
                 return "Neither";   
               };
               
               function isIPv4(digit) {
                 if (!digit.length || digit.length > 3)
                   return false;
                 if (digit.length > 1 && digit[0] == '0')
                   return false;
                 for (let c of digit)
                   if (c.charCodeAt(0) < 48 || c.charCodeAt(0) > 57)
                     return false;
                 let num = parseInt(digit);
                 return 0 <= num && num <= 255;
               }
               
               function isIPv6(digit) {
                 const validIPv6Chars = "0123456789abcdefABCDEF";
                 if (!digit.length || digit.length > 4)
                   return false;
                 for (let c of digit)
                   if (!validIPv6Chars.includes(c))
                     return false;
                 return true;
               }
               
               console.log(validIPAddress("2001:0db8:85a3:0:0:8A2E:0370:7334"))`,
                output: `IPv6`,
              },
            }}
          />
        ),
      },
      q399: {
        title: "Q470. Implement Rand10() Using Rand7() (Q399)",
        content: (
          <Comp
            title="Q470. Implement Rand10() Using Rand7() (Q399)"
            content1={
              <>
                Given the API rand7() that generates a uniform random integer in
                the range [1, 7], write a function rand10() that generates a
                uniform random integer in the range [1, 10]. You can only call
                the API rand7(), and you shouldn't call any other API. Please do
                not use a language's built-in random API.
                <br />
                Each test case will have one internal argument n, the number of
                times that your implemented function rand10() will be called
                while testing. Note that this is not an argument passed to
                rand10().
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 1 <br />
                    Output: [2]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2<br />
                    Output: [2,8]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 3<br />
                    Output: [3,8,10]
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 10^5</>}
            fp={
              <>
                <b>Follow up :</b>What is the expected value for the number of
                calls to rand7() function?
                <br />
                Could you minimize the number of calls to rand7()?
              </>
            }
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * The rand7() API is already defined for you.
                * var rand7 = function() {}
                * @return {number} a random integer in the range 1 to 7
                */
               
               var rand7 = function() {
                 return Math.floor(Math.random() * 7) + 1
               }
               
               var rand10 = function() {
                 let num = 40;
                 while (num >= 40)
                   num = (rand7() - 1) * 7 + rand7() - 1;
                 return num % 10 + 1; 
               };
               
               console.log(rand10())`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q400: {
        title: "Q472. Concatenated Words (Q400)",
        content: (
          <Comp
            title="Q472. Concatenated Words (Q400)"
            content1={
              <>
                Given an array of strings words (without duplicates), return all
                the concatenated words in the given list of words.
                <br />A concatenated word is defined as a string that is
                comprised entirely of at least two shorter words in the given
                array.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: words =
                    ["cat","cats","catsdogcats","dog","dogcatsdog","hippopotamuses","rat","ratcatdogcat"]
                    <br /> Output: ["catsdogcats","dogcatsdog","ratcatdogcat"]
                    <br />
                    Explanation: "catsdogcats" can be concatenated by "cats",
                    "dog" and "cats";
                    <br /> "dogcatsdog" can be concatenated by "dog", "cats" and
                    "dog"; "ratcatdogcat" can be concatenated by "rat", "cat",
                    "dog" and "cat".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words = ["cat","dog","catdog"] <br />
                    Output: ["catdog"]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= words.length &lt;= 10^4 <br />
                0 &lt;= words[i].length &lt;= 1000 <br />
                words[i] consists of only lowercase English letters. <br />0
                &lt;= sum(words[i].length) &lt;= 105
              </>
            }
            tc="n.l^3"
            sc="n.l"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} words
                * @return {string[]}
                */
               var findAllConcatenatedWordsInADict = function(words) {
                 const res = [];
                 const wordSet = new Set(words);
                 const memo = new Map();
                 for (let word of words)
                   if (wordBreak(word, wordSet, memo))
                     res.push(word);
                 return res;
               };
               
               function wordBreak(word, wordSet, memo) {
                 if (memo.has(word))
                   return memo.get(word);
                 for (let i = 1; i < word.length; ++i) {
                   const prefix = word.substring(0, i);
                   const suffix = word.substring(i);
                   if (wordSet.has(prefix) &&
                       (wordSet.has(suffix) || wordBreak(suffix, wordSet, memo))) {
                     memo.set(word, true);
                     return true;
                   }
                 }
                 memo.set(word, false);
                 return false;
               }
               
               console.log(findAllConcatenatedWordsInADict(["cat","dog","catdog"]))`,
                output: `[ 'catdog' ]`,
              },
            }}
          />
        ),
      },
      q401: {
        title: "Q473. Matchsticks to Square (Q401)",
        content: (
          <Comp
            title="Q473. Matchsticks to Square (Q401)"
            content1={
              <>
                You are given an integer array matchsticks where matchsticks[i]
                is the length of the ith matchstick. You want to use all the
                matchsticks to make one square. You should not break any stick,
                but you can link them up, and each matchstick must be used
                exactly one time.
                <br />
                Return true if you can make this square and false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode473,
                content: (
                  <>
                    Input: matchsticks = [1,1,2,2,2] <br />
                    Output: true <br />
                    Explanation: You can form a square with length 2, one side
                    of the square came two sticks with length 1.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: matchsticks = [3,3,3,3,4] <br />
                    Output: false <br />
                    Explanation: You cannot find a way to form a square with all
                    the matchsticks.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= matchsticks.length &lt;= 15
                <br />1 &lt;= matchsticks[i] &lt;= 10^8
              </>
            }
            tc="2^n"
            sc="2^n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} matchsticks
                * @return {boolean}
                */
               var makesquare = function(matchsticks) {
                 if (matchsticks.length < 4)
                   return false;
                 let perimeter = matchsticks.reduce((a,b)=>a+b,0);
                 if (perimeter % 4 != 0)
                   return false;
                 const edges = Array(4).fill(parseInt(perimeter / 4));
                 edges.sort((a,b)=>a-b) 
                 return dfs(matchsticks, matchsticks.length - 1, edges);
               };
               
               function dfs(matchsticks, selected, edges) {
                 if (selected == -1)
                   return edges.every(edge => edge == 0);
                 for (let i = 0; i < 4; ++i) {
                   if (matchsticks[selected] > edges[i])
                     continue;
                   edges[i] -= matchsticks[selected];
                   if (dfs(matchsticks, selected - 1, edges))
                     return true;
                   edges[i] += matchsticks[selected];
                 }
                 return false;
               }
               
               console.log(makesquare([3,3,3,3,4]))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q402: {
        title: "Q474. Ones and Zeroes (Q402)",
        content: (
          <Comp
            title="Q474. Ones and Zeroes (Q402)"
            content1={
              <>
                You are given an array of binary strings strs and two integers m
                and n.
                <br />
                Return the size of the largest subset of strs such that there
                are at most m 0's and n 1's in the subset.
                <br />A set x is a subset of a set y if all elements of x are
                also elements of y.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: strs = ["10","0001","111001","1","0"], m = 5, n = 3{" "}
                    <br />
                    Output: 4 Explanation: The largest subset with at most 5 0's{" "}
                    <br />
                    and 3 1's is {'{("10", "0001", "1", "0")}'}, so the answer
                    is 4. <br />
                    Other valid but smaller subsets include {
                      '{("0001", "1")}'
                    }{" "}
                    and <br />
                    {'{("10", "1", "0")}.{"111001"}'} is an invalid subset
                    because <br />
                    it contains 4 1's, greater than the maximum of 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: strs = ["10","0","1"], m = 1, n = 1 Output: 2 <br />
                    Input: strs = ["10","0","1"], m = 1, n = 1 Output: 2 <br />
                    Explanation: The largest subset is {'{("0", "1")}'}, so the
                    answer is 2.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= strs.length &lt;= 600 <br />
                1 &lt;= strs[i].length &lt;= 100 <br />
                strs[i] consists only of digits '0' and '1'. <br />1 &lt;= m, n
                &lt;= 100
              </>
            }
            tc="k.l.m.n"
            sc="m.n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} strs
                * @param {number} m
                * @param {number} n
                * @return {number}
                */
               var findMaxForm = function(strs, m, n) {
                 const dp = Array.from(Array(m + 1),()=>Array(n + 1).fill(0))
                 for (let s of strs) {
                   const count0 = s.split("").filter(c => c == '0').length;
                   const count1 = s.length - count0;
                   for (let i = m; i >= count0; --i)
                     for (let j = n; j >= count1; --j)
                       dp[i][j] = Math.max(dp[i][j], dp[i - count0][j - count1] + 1);
                 }
                 return dp[m][n];
               }
               
               console.log(findMaxForm(["10","0","1"],1,1))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q403: {
        title: "Q475. Heaters (Q403)",
        content: (
          <Comp
            title="Q475. Heaters (Q403)"
            content1={
              <>
                Winter is coming! During the contest, your first job is to
                design a standard heater with a fixed warm radius to warm all
                the houses.
                <br />
                Every house can be warmed, as long as the house is within the
                heater's warm radius range.
                <br />
                Given the positions of houses and heaters on a horizontal line,
                return the minimum radius standard of heaters so that those
                heaters could cover all houses.
                <br />
                Notice that all the heaters follow your radius standard, and the
                warm radius will the same.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: houses = [1,2,3], heaters = [2] <br />
                    Output: 1 <br />
                    Explanation: The only heater was placed in the position 2,
                    and if we use the radius 1 standard, then all the houses can
                    be warmed.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: houses = [1,2,3,4], heaters = [1,4]
                    <br />
                    Output: 1<br />
                    Explanation: The two heater was placed in the position 1 and
                    4. We need to use radius 1 standard, then all the houses can
                    be warmed.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: houses = [1,5], heaters = [2] <br />
                    Output: 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= houses.length, heaters.length &lt;= 3 * 10^4 <br />1
                &lt;= houses[i], heaters[i] &lt;= 10^9
              </>
            }
            tc="n.log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} houses
                * @param {number[]} heaters
                * @return {number}
                */
               var findRadius = function(houses, heaters) {
                 houses = houses.sort((a,b)=>a-b);
                 heaters = heaters.sort((a,b)=>a-b);
                 let ans = 0;
                 let i = 0; 
                 for (let house of houses) {
                   while (i + 1 < heaters.length && house - heaters[i] > heaters[i + 1] - house)
                     ++i; 
                   ans = Math.max(ans, Math.abs(heaters[i] - house));
                 }
                 return ans;    
               };
               
               console.log(findRadius([1,5],[2]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q404: {
        title: "Q476. Number Complement (Q404)",
        content: (
          <Comp
            title="Q476. Number Complement (Q404)"
            content1={
              <>
                The complement of an integer is the integer you get when you
                flip all the 0's to 1's and all the 1's to 0's in its binary
                representation.
                <br />
                For example, The integer 5 is "101" in binary and its complement
                is "010" which is the integer 2.
                <br />
                Given an integer num, return its complement.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: num = 5<br />
                    Output: 2<br />
                    Explanation: The binary representation of 5 is 101 (no
                    leading zero bits), and its complement is 010. So you need
                    to output 2.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num = 1<br />
                    Output: 0<br />
                    Explanation: The binary representation of 1 is 1 (no leading
                    zero bits), and its complement is 0. So you need to output
                    0.
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= num &lt; 2^31</>}
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `// 5
                class Solution {
                  public int findComplement(int num) {
                    for (long i = 1; i <= num; i <<= 1)
                      num ^= i;
                    return num;
                  }
                }
                `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q405: {
        title: "Q477. Total Hamming Distance (Q405)",
        content: (
          <Comp
            title="Q477. Total Hamming Distance (Q405)"
            content1={
              <>
                The Hamming distance between two integers is the number of
                positions at which the corresponding bits are different.
                <br />
                Given an integer array nums, return the sum of Hamming distances
                between all the pairs of the integers in nums.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [4,14,2] <br />
                    Output: 6 <br />
                    Explanation: In binary representation, the 4 is 0100, 14 is
                    1110, and 2 is 0010 (just showing the four bits relevant in
                    this case).
                    <br />
                    The answer will be: HammingDistance(4, 14) +
                    HammingDistance(4, 2) + HammingDistance(14, 2) = 2 + 2 + 2 =
                    6.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [4,14,4] <br />
                    Output: 4
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The Hamming distance between two integers is the number of
                positions at which the corresponding bits are different.
                <br />
                Given an integer array nums, return the sum of Hamming distances
                between all the pairs of the integers in nums.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               function getCount(nums, mask) {
                 let count = 0;
                 for (let num of nums)
                   if ((num & mask) > 0)
                     ++count;
                 return count;
               }
               
               var totalHammingDistance = function(nums) {
                 let ans = 0;
                 let mask = 1;
                 for (let i = 0; i < 30; ++i) {
                   let onesCount = getCount(nums, mask);
                   let zerosCount = nums.length - onesCount;
                   ans += onesCount * zerosCount;
                   mask <<= 1;
                 }
                 return ans; 
               };
               
               console.log(totalHammingDistance([4,14,2]))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q406: {
        title: "Q478. Generate Random Point in a Circle (Q406)",
        content: (
          <Comp
            title="Q478. Generate Random Point in a Circle (Q406)"
            content1={
              <>
                Given the radius and the position of the center of a circle,
                implement the function randPoint which generates a uniform
                random point inside the circle.
                <br />
                Implement the Solution class:
                <br />
                Solution(double radius, double x_center, double y_center)
                initializes the object with the radius of the circle radius and
                the position of the center (x_center, y_center).
                <br />
                randPoint() returns a random point inside the circle. A point on
                the circumference of the circle is considered to be in the
                circle. The answer is returned as an array [x, y].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["Solution", "randPoint", "randPoint", "randPoint"] <br />
                    [[1.0, 0.0, 0.0], [], [], []] <br />
                    Output <br />
                    [null, [-0.02493, -0.38077], [0.82314, 0.38945], [0.36572,
                    0.17248]]
                    <br />
                    Explanation <br />
                    Solution solution = new Solution(1.0, 0.0, 0.0); <br />
                    solution.randPoint(); // return [-0.02493, -0.38077] <br />
                    solution.randPoint(); // return [0.82314, 0.38945] <br />
                    solution.randPoint(); // return [0.36572, 0.17248]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt; radius &lt;= 10^8 <br />
                -10^7 &lt;= x_center, y_center &lt;= 10^7 <br />
                At most 3 * 104 calls will be made to randPoint.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} radius
                * @param {number} x_center
                * @param {number} y_center
                */
               var Solution = function(radius, x_center, y_center) {
                 this.radius = radius;
                 this.x_center = x_center;
                 this.y_center = y_center;
               };
               
               /**
                * @return {number[]}
                */
               Solution.prototype.randPoint = function() {
                 let length = Math.sqrt(Math.random()) * this.radius;
                 let degree = Math.random() * 2 * Math.PI;
                 let x = this.x_center + length * Math.cos(degree);
                 let y = this.y_center + length * Math.sin(degree);
                 return [x, y];  
               };
               
               
               var obj = new Solution(1, 0, 0)
               var param_1 = obj.randPoint()
               console.log(param_1)`,
                output: `[ 0.17378474100349553, -0.3311756669145883 ]`,
              },
            }}
          />
        ),
      },
      q407: {
        title: "Q479. Largest Palindrome Product (Q407)",
        content: (
          <Comp
            title="Q479. Largest Palindrome Product (Q407)"
            content1={
              <>
                Given an integer n, return the largest palindromic integer that
                can be represented as the product of two n-digits integers.
                Since the answer can be very large, return it modulo 1337.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 2 <br />
                    Output: 987 <br />
                    Explanation: 99 x 91 = 9009, 9009 % 1337 = 987
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1 <br />
                    Output: 9
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 8</>}
            tc="10^n"
            sc="1"
            codes={{
              Java: {
                code: `
                // 2
                class Solution {
                  public int largestPalindrome(int n) {
                    if (n == 1)
                      return 9;
                
                    final int kMod = 1337;
                    final int upper = (int) Math.pow(10, n) - 1;
                    final int lower = (int) Math.pow(10, n - 1) - 1;
                
                    for (int i = upper; i > lower; --i) {
                      final long cand = getPalindromeCandidate(i);
                      for (long j = upper; j * j >= cand; --j)
                        if (cand % j == 0)
                          return (int) (cand % kMod);
                    }
                
                    throw new IllegalArgumentException();
                  }
                
                  private long getPalindromeCandidate(int i) {
                    final String reversed = new StringBuilder().append(i).reverse().toString();
                    return Long.valueOf(i + reversed);
                  }
                }
                `,
                output: `987`,
              },
            }}
          />
        ),
      },
      q408: {
        title: "Q480. Sliding Window Median (Q408)",
        content: (
          <Comp
            title="Q480. Sliding Window Median (Q408)"
            content1={
              <>
                The median is the middle value in an ordered integer list. If
                the size of the list is even, there is no middle value. So the
                median is the mean of the two middle values.
                <br />
                For examples, if arr = [2,3,4], the median is 3.
                <br />
                For examples, if arr = [1,2,3,4], the median is (2 + 3) / 2 =
                2.5.
                <br />
                You are given an integer array nums and an integer k. There is a
                sliding window of size k which is moving from the very left of
                the array to the very right. You can only see the k numbers in
                the window. Each time the sliding window moves right by one
                position.
                <br />
                Return the median array for each window in the original array.
                Answers within 10-5 of the actual value will be accepted.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,3,-1,-3,5,3,6,7],
                    <br /> k = 3<br />
                    Output: [1.00000,-1.00000,-1.00000,3.00000,5.00000,6.00000]
                    <br />
                    Explanation: <br />
                    <pre>
                      {`
Window position                Median
---------------                -----
[1  3  -1] -3  5  3  6  7        1
 1 [3  -1  -3] 5  3  6  7       -1
 1  3 [-1  -3  5] 3  6  7       -1
 1  3  -1 [-3  5  3] 6  7        3
 1  3  -1  -3 [5  3  6] 7        5
 1  3  -1  -3  5 [3  6  7]       6                  
                  `}
                    </pre>
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4,2,3,1,4,2],
                    <br /> k = 3<br />
                    Output:
                    [2.00000,3.00000,3.00000,3.00000,2.00000,3.00000,2.00000]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= k &lt;= nums.length &lt;= 10^5 <br />
                -2^31 &lt;= nums[i] &lt;= 2^31 - 1
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              "C++": {
                code: `
                // [1,3,-1,-3,5,3,6,7] , 3
                class Solution {
                  public:
                   vector<double> medianSlidingWindow(vector<int>& nums, int k) {
                     vector<double> ans;
                     multiset<double> window(begin(nums), begin(nums) + k);
                     auto it = next(begin(window), (k - 1) / 2);
                 
                     for (int i = k;; ++i) {
                       const double median = k & 1 ? *it : (*it + *next(it)) / 2.0;
                       ans.push_back(median);
                       if (i == nums.size())
                         break;
                       window.insert(nums[i]);
                       if (nums[i] < *it)
                         --it;
                       if (nums[i - k] <= *it)
                         ++it;
                       window.erase(window.lower_bound(nums[i - k]));
                     }
                 
                     return ans;
                   }
                 };
                 `,
                output: `[ 1, -1, -1, 3, 5, 6 ]`,
              },
            }}
          />
        ),
      },
      q409: {
        title: "Q481. Magical String (Q409)",
        content: (
          <Comp
            title="Q481. Magical String (Q409)"
            content1={
              <>
                A magical string s consists of only '1' and '2' and obeys the
                following rules:
                <br />
                The string s is magical because concatenating the number of
                contiguous occurrences of characters '1' and '2' generates the
                string s itself.
                <br /> The first few elements of s is s =
                "1221121221221121122……". If we group the consecutive 1's and 2's
                in s, it will be "1 22 11 2 1 22 1 22 11 2 11 22 ......" and the
                occurrences of 1's or 2's in each group are "1 2 2 1 1 2 1 2 2 1
                2 2 ......". You can see that the occurrence sequence is s
                itself.
                <br />
                Given an integer n, return the number of 1's in the first n
                number in the magical string s.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 6 <br />
                    Output: 3 <br />
                    Explanation: The first 6 elements of magical string s is
                    "122112" and it contains three 1's, so return 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1 <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 10^5</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               var magicalString = function(n) {
                   if (n === 1) return 1;
                   const arr = Array(n).fill(1);
                   arr[0] = 1;
                   arr[1] = 2;
                   let left = 1;
                   let right = 1;
                   let num = 2;
                   let ones = 1;
                   while (right < n) {
                       const count = arr[left];
                       for (let i = 0; i < count && right < n; i++, right++) {
                           arr[right] = num;
                           if (num === 1) ones++;
                       }
                       num ^= 3;
                       left++;
                   }
                   
                   return ones;
               };
               
               console.log(magicalString(6))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q410: {
        title: "Q482. License Key Formatting (Q410)",
        content: (
          <Comp
            title="Q482. License Key Formatting (Q410)"
            content1={
              <>
                You are given a license key represented as a string s that
                consists of only alphanumeric characters and dashes. The string
                is separated into n + 1 groups by n dashes. You are also given
                an integer k.
                <br />
                We want to reformat the string s such that each group contains
                exactly k characters, except for the first group, which could be
                shorter than k but still must contain at least one character.
                Furthermore, there must be a dash inserted between two groups,
                and you should convert all lowercase letters to uppercase.
                <br />
                Return the reformatted license key.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "5F3Z-2e-9-w", k = 4 <br />
                    Output: "5F3Z-2E9W" <br />
                    Explanation: The string s has been split into two parts,
                    each part has 4 characters. Note that the two extra dashes
                    are not needed and can be removed.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "2-5g-3-J", k = 2<br />
                    Output: "2-5G-3J"
                    <br />
                    Explanation: The string s has been split into three parts,
                    each part has 2 characters except the first part as it could
                    be shorter as mentioned above.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^5 <br />
                s consists of English letters, digits, and dashes '-'. <br />1
                &lt;= k &lt;= 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @param {number} k
                * @return {string}
                */
               var licenseKeyFormatting = function(s, k) {
                 let res = ""
                 let length = 0;
                 for (let i = s.length - 1; i >= 0; --i) {
                   if (s[i] == '-')
                     continue;
                   if (length > 0 && length % k == 0)
                     res += "-";
                   res += s[i].toUpperCase();
                   ++length;
                 }
                 return res.split("").reverse().join("");
               };
               
               console.log(licenseKeyFormatting("2-5g-3-J",2))`,
                output: `2-5G-3J`,
              },
            }}
          />
        ),
      },
      q411: {
        title: "Q483. Smallest Good Base (Q411)",
        content: (
          <Comp
            title="Q483. Smallest Good Base (Q411)"
            content1={
              <>
                Given an integer n represented as a string, return the smallest
                good base of n.
                <br />
                We call k &gt;= 2 a good base of n, if all digits of n base k
                are 1's.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = "13" <br />
                    Output: "3" <br />
                    Explanation: 13 base 3 is 111.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = "4681" <br />
                    Output: "8" <br />
                    Explanation: 4681 base 8 is 11111.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = "1000000000000000000" <br />
                    Output: "999999999999999999" <br />
                    Explanation: 1000000000000000000 base 999999999999999999 is
                    11.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n is an integer in the range [3, 1018]. <br />n does not contain
                any leading zeros.
              </>
            }
            tc="log^2 n"
            sc="n"
            codes={{
              Java: {
                code: `// 13
                class Solution {
                  public String smallestGoodBase(String n) {
                    final long num = Long.parseLong(n);
                    final int log2 = (int) (Math.log(num) / Math.log(2));
                
                    for (int m = log2; m >= 2; --m) {
                      int k = (int) Math.floor(Math.pow(num, 1.0 / m));
                      long sum = 1;
                      long prod = 1;
                      for (int i = 0; i < m; ++i) {
                        prod *= k;
                        sum += prod;
                      }
                      if (sum == num)
                        return String.valueOf(k);
                    }
                
                    return String.valueOf(num - 1);
                  }
                }
                `,
                output: `3`,
              },
            }}
          />
        ),
      },
      q412: {
        title: "Q485. Max Consecutive Ones (Q412)",
        content: (
          <Comp
            title="Q485. Max Consecutive Ones (Q412)"
            content1={
              <>
                Given a binary array nums, return the maximum number of
                consecutive 1's in the array.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,1,0,1,1,1] <br />
                    Output: 3 <br />
                    Explanation: The first two digits or the last three digits
                    are consecutive 1s. The maximum number of consecutive 1s is
                    3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,0,1,1,0,1] <br />
                    Output: 2
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 105 <br />
                nums[i] is either 0 or 1.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var findMaxConsecutiveOnes = function(nums) {
                 let ans = 0;
                 let sum = 0;
                 for (let num of nums)
                   if (num == 1)
                     ans = Math.max(ans, ++sum);
                   else
                     sum = 0;
                 return ans;
               };
               
               console.log(findMaxConsecutiveOnes([1,0,1,1,0,1]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q413: {
        title: "Q486. Predict the Winner (Q413)",
        content: (
          <Comp
            title="Q486. Predict the Winner (Q413)"
            content1={
              <>
                You are given an integer array nums. Two players are playing a
                game with this array: player 1 and player 2.
                <br />
                Player 1 and player 2 take turns, with player 1 starting first.
                Both players start the game with a score of 0. At each turn, the
                player takes one of the numbers from either end of the array
                (i.e., nums[0] or nums[nums.length - 1]) which reduces the size
                of the array by 1. The player adds the chosen number to their
                score. The game ends when there are no more elements in the
                array.
                <br />
                Return true if Player 1 can win the game. If the scores of both
                players are equal, then player 1 is still the winner, and you
                should also return true. You may assume that both players are
                playing optimally.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,5,2] <br />
                    Output: false <br />
                    Explanation: Initially, player 1 can choose between 1 and 2.
                    If he chooses 2 (or 1), then player 2 can choose from 1 (or
                    2) and 5. If player 2 chooses 5, then player 1 will be left
                    with 1 (or 2). So, final score of player 1 is 1 + 2 = 3, and
                    player 2 is 5. Hence, player 1 will never be the winner and
                    you need to return false.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,5,233,7] <br />
                    Output: true <br />
                    Explanation: Player 1 first chooses 1. Then player 2 has to
                    choose between 5 and 7. No matter which number player 2
                    choose, player 1 can choose 233. Finally, player 1 has more
                    score (234) than player 2 (12), so you need to return True
                    representing player1 can win.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 20 <br />0 &lt;= nums[i] &lt;= 10^7
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {boolean}
                */
               var PredictTheWinner = function(nums) {
                 const n = nums.length;
                 const dp = nums.slice();
               
                 for (let d = 1; d < n; ++d)
                   for (let j = n - 1; j - d >= 0; --j) {
                     const i = j - d;
                     dp[j] = Math.max(nums[i] - dp[j],      
                                        nums[j] - dp[j - 1]); 
                     }
                   return dp[n - 1] >= 0;
               };
               
               console.log(PredictTheWinner([1,5,233,7]))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q414: {
        title: "Q488. Zuma Game (Q414)",
        content: (
          <Comp
            title="Q488. Zuma Game (Q414)"
            content1={
              <>
                You are playing a variation of the game Zuma.
                <br />
                In this variation of Zuma, there is a single row of colored
                balls on a board, where each ball can be colored red 'R', yellow
                'Y', blue 'B', green 'G', or white 'W'. You also have several
                colored balls in your hand.
                <br />
                Your goal is to clear all of the balls from the board. On each
                turn:
                <br />
                Pick any ball from your hand and insert it in between two balls
                in the row or on either end of the row.
                <br />
                If there is a group of three or more consecutive balls of the
                same color, remove the group of balls from the board.
                <br />
                If this removal causes more groups of three or more of the same
                color to form, then continue removing each group until there are
                none left.
                <br />
                If there are no more balls on the board, then you win the game.
                <br />
                Repeat this process until you either win or do not have any more
                balls in your hand.
                <br />
                Given a string board, representing the row of balls on the
                board, and a string hand, representing the balls in your hand,
                return the minimum number of balls you have to insert to clear
                all the balls from the board. If you cannot clear all the balls
                from the board using the balls in your hand, return -1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: board = "WRRBBW", hand = "RB" <br />
                    Output: -1 <br />
                    Explanation: It is impossible to clear all the balls. The
                    best you can do is:
                    <br />
                    - Insert 'R' so the board becomes WRRRBBW. WRRRBBW -&gt;
                    WBBW.
                    <br />
                    - Insert 'B' so the board becomes WBBBW. WBBBW -&gt; WW.
                    <br />
                    There are still balls remaining on the board, and you are
                    out of balls to insert.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: board = "WWRRBBWW", hand = "WRBRW"
                    <br />
                    Output: 2
                    <br />
                    Explanation: To make the board empty:
                    <br />
                    - Insert 'R' so the board becomes WWRRRBBWW. WWRRRBBWW -&gt;
                    WWBBWW.
                    <br />
                    - Insert 'B' so the board becomes WWBBBWW. WWBBBWW -&gt;
                    WWWW -&gt; empty.
                    <br />2 balls from your hand were needed to clear the board.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: board = "WWRRBBWW", hand = "WRBRW"
                    <br />
                    Output: 2
                    <br />
                    Explanation: To make the board empty:
                    <br />
                    - Insert 'R' so the board becomes WWRRRBBWW. WWRRRBBWW -&gt;
                    WWBBWW.
                    <br />
                    - Insert 'B' so the board becomes WWBBBWW. WWBBBWW -&gt;
                    WWWW -&gt; empty.
                    <br />2 balls from your hand were needed to clear the board.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= board.length &lt;= 16 <br />
                1 &lt;= hand.length &lt;= 5<br />
                board and hand consist of the characters 'R', 'Y', 'B', 'G', and
                'W'. The initial row of balls on the board will not have any
                groups of three or more consecutive balls of the same color.
              </>
            }
            tc="m^2.n^2"
            sc="m.n"
            codes={{
              Java: {
                code: `
                //  board = "WWRRBBWW", hand = "WRBRW"
                class Solution {
                  public int findMinStep(String board, String hand) {
                      List<Character> boardList = new ArrayList<>();
                      for (char c : board.toCharArray()) {
                          boardList.add(c);
                      }
                      List<Character> handList = new ArrayList<>();
                      for (char c : hand.toCharArray()) {
                          handList.add(c);
                      }
                      
                      int res = findMinStep(boardList, handList);
                      return res;
                  }
                  
                  public int findMinStep(List<Character> boardList, List<Character> handList) {
                      if (boardList.isEmpty()) {
                          return 0;
                      }
                      if (!boardList.isEmpty() && handList.isEmpty()) {
                          return -1;
                      }
                      if (!isValid(boardList, handList)) {
                          return -1;
                      }
                      
                      int res = Integer.MAX_VALUE;
                      for (int boardListIndex=0; boardListIndex<=boardList.size(); boardListIndex++) {            
                          Set<Character> duplicate = new HashSet<>();
                          for (int handListIndex=0; handListIndex<handList.size(); handListIndex++) {
                              if (boardListIndex > 0 && boardList.get(boardListIndex-1) == handList.get(handListIndex)) {
                                  continue;
                              }
                              if (duplicate.contains(handList.get(handListIndex))) {
                                  continue;
                              } else {
                                  duplicate.add(handList.get(handListIndex));
                              }
              
                              boolean goodCase1 = (boardListIndex < boardList.size() && boardList.get(boardListIndex) == handList.get(handListIndex));
                              boolean goodCase2 = (boardListIndex > 0 && boardListIndex < boardList.size() && boardList.get(boardListIndex-1) == boardList.get(boardListIndex) && boardList.get(boardListIndex-1) != handList.get(handListIndex));
                              if (goodCase1 == false && goodCase2 == false) continue;
                              
                              List<Character> boardListClone = new ArrayList<>(boardList);
                              List<Character> handListClone = new ArrayList<>(handList);
                              boardListClone.add(boardListIndex, handListClone.remove(handListIndex));
                              cleanup(boardListClone);
                              int preRes = findMinStep(boardListClone, handListClone);
                              if (preRes != -1 && preRes < res) {
                                  res = preRes;
                              }
                          }
                      }
                      
                      if (res == Integer.MAX_VALUE) {
                          return -1;
                      }
                      return res + 1;
                  }
                  
                  public void cleanup(List<Character> boardList) {
                      Boolean isCleanup = false;
                      while (!isCleanup) {
                          isCleanup = true;
                          for (int i=0; i<boardList.size()-2; i++) {
                              int repeatLen = 1;
                              for (int j=i+1; j<boardList.size(); j++) {
                                  if (boardList.get(j) == boardList.get(j-1)) {
                                      repeatLen++;
                                  } else {
                                      break;
                                  }
                              }
                              if (repeatLen >= 3) {
                                  isCleanup = false;
                                  for (; repeatLen > 0; repeatLen--) {
                                      boardList.remove(i);
                                  }
                              }
                          }
                      }
                  }
                  
                  public boolean isValid(List<Character> boardList, List<Character> handList) {
                      int boardR = 0, boardY = 0, boardB = 0, boardG = 0, boardW = 0;
                      int handR = 0, handY = 0, handB = 0, handG = 0, handW = 0;
                      for (char c : boardList) {
                          if (c == 'R') boardR++;
                          if (c == 'Y') boardY++;
                          if (c == 'B') boardB++;
                          if (c == 'G') boardG++;
                          if (c == 'W') boardW++;
                      }
                      for (char c : handList) {
                          if (c == 'R') handR++;
                          if (c == 'Y') handY++;
                          if (c == 'B') handB++;
                          if (c == 'G') handG++;
                          if (c == 'W') handW++;
                      }
                      if (boardR < 3 && boardR > 0 && boardR + handR < 3) return false;
                      if (boardY < 3 && boardY > 0 && boardY + handY < 3) return false;
                      if (boardB < 3 && boardB > 0 && boardB + handB < 3) return false;
                      if (boardG < 3 && boardG > 0 && boardG + handG < 3) return false;
                      if (boardW < 3 && boardW > 0 && boardW + handW < 3) return false;
                      
                      return true;
                  }
              }
                `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q415: {
        title: "Q491. Increasing Subsequences (Q415)",
        content: (
          <Comp
            title="Q491. Increasing Subsequences (Q415)"
            content1={
              <>
                Given an integer array nums, return all the different possible
                increasing subsequences of the given array with at least two
                elements. You may return the answer in any order.
                <br />
                The given array may contain duplicates, and two equal integers
                should also be considered a special case of increasing sequence.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [4,6,7,7] <br />
                    Output:
                    [[4,6],[4,6,7],[4,6,7,7],[4,7],[4,7,7],[6,7],[6,7,7],[7,7]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [4,4,3,2,1]
                    <br />
                    Output: [[4,4]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    1 &lt;= nums.length &lt;= 15 <br />
                    -100 &lt;= nums[i] &lt;= 100
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 15 <br />
                -100 &lt;= nums[i] &lt;= 100
              </>
            }
            tc="n.2^n"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number[][]}
                */
               function dfs(nums,s,res,curr=[]){
                 if(curr.length > 1)
                   res.push(curr.slice());
               
                 const existing = new Set();
               
                 for (let i = s; i < nums.length; ++i) {
                   if (existing.has(nums[i]))
                     continue;
                   if (!curr.length || nums[i] >= curr.slice(-1)[0]) {
                     existing.add(nums[i]);
                     curr.push(nums[i]);
                     dfs(nums, i + 1, res, curr);
                     curr.pop();
                   }
                 }
               }
               
               var findSubsequences = function(nums) {
                 const res = [];
                 dfs(nums, 0, res);
                 return res;
               };
               
               console.log(findSubsequences([4,4,3,2,1]))`,
                output: `[ [ 4, 4 ] ]`,
              },
            }}
          />
        ),
      },
      q416: {
        title: "Q492. Construct the Rectangle (Q416)",
        content: (
          <Comp
            title="Q492. Construct the Rectangle (Q416)"
            content1={
              <>
                A web developer needs to know how to design a web page's size.
                So, given a specific rectangular web page’s area, your job by
                now is to design a rectangular web page, whose length L and
                width W satisfy the following requirements:
                <br />
                The area of the rectangular web page you designed must equal to
                the given target area.
                <br /> The width W should not be larger than the length L, which
                means L &gt;= W.
                <br /> The difference between length L and width W should be as
                small as possible.
                <br /> Return an array [L, W] where L and W are the length and
                width of the web page you designed in sequence.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: area = 4 <br />
                    Output: [2,2] <br />
                    Explanation: The target area is 4, and all the possible ways
                    to construct it are [1,4], [2,2], [4,1]. But according to
                    requirement 2, [1,4] is illegal; according to requirement 3,
                    [4,1] is not optimal compared to [2,2]. So the length L is
                    2, and the width W is 2.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: area = 37 <br />
                    Output: [37,1]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: area = 122122 <br />
                    Output: [427,286]
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= area &lt;= 10^7</>}
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} area
                * @return {number[]}
                */
               var constructRectangle = function(area){
                 let width = parseInt(Math.sqrt(area));
                 while (area % width > 0)
                   --width;
                 return [parseInt(area / width), width]; 
               };
               
               console.log(constructRectangle(4))`,
                output: `[ 2, 2 ]`,
              },
            }}
          />
        ),
      },
      q417: {
        title: "Q493. Reverse Pairs (Q417)",
        content: (
          <Comp
            title="Q493. Reverse Pairs (Q417)"
            content1={
              <>
                Given an integer array nums, return the number of reverse pairs
                in the array.
                <br />A reverse pair is a pair (i, j) where 0 &lt;= i &lt; j
                &lt; nums.length and nums[i] &gt; 2 * nums[j].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,3,2,3,1] <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,3,2,3,1] <br />
                    Output: 2
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 5 * 10^4 <br />
                -2^31 &lt;= nums[i] &lt;= 2^31 - 1
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              "C++": {
                code: `class FenwickTree {
                  public:
                   FenwickTree(int n) : sums(n + 1) {}
                 
                   void update(int i, int delta) {
                     while (i < sums.size()) {
                       sums[i] += delta;
                       i += lowbit(i);
                     }
                   }
                 
                   int get(int i) const {
                     int sum = 0;
                     while (i > 0) {
                       sum += sums[i];
                       i -= lowbit(i);
                     }
                     return sum;
                   }
                 
                  private:
                   vector<int> sums;
                 
                   static inline int lowbit(int i) {
                     return i & -i;
                   }
                 };
                 
                 class Solution {
                  public:
                   int reversePairs(vector<int>& nums) {
                     int ans = 0;
                     unordered_map<long, int> ranks;
                     getRanks(nums, ranks);
                     FenwickTree tree(ranks.size());
                 
                     for (int i = nums.size() - 1; i >= 0; --i) {
                       const long num = nums[i];
                       ans += tree.get(ranks[num] - 1);
                       tree.update(ranks[num * 2], 1);
                     }
                 
                     return ans;
                   }
                 
                  private:
                   void getRanks(const vector<int>& nums, unordered_map<long, int>& ranks) {
                     set<long> sorted(begin(nums), end(nums));
                     for (const long num : nums)
                       sorted.insert(num * 2);
                     int rank = 0;
                     for (const long num : sorted)
                       ranks[num] = ++rank;
                   }
                 };
                 `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q418: {
        title: "Q494. Target Sum (Q418)",
        content: (
          <Comp
            title="Q494. Target Sum (Q418)"
            content1={
              <>
                You are given an integer array nums and an integer target.
                <br />
                You want to build an expression out of nums by adding one of the
                symbols '+' and '-' before each integer in nums and then
                concatenate all the integers.
                <br />
                For example, if nums = [2, 1], you can add a '+' before 2 and a
                '-' before 1 and concatenate them to build the expression
                "+2-1". Return the number of different expressions that you can
                build, which evaluates to target.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,1,1,1,1], target = 3 <br />
                    Output: 5 <br />
                    Explanation: There are 5 ways to assign symbols to make the
                    sum of nums be target 3.
                    <pre>
                      {`
-1 + 1 + 1 + 1 + 1 = 3
+1 - 1 + 1 + 1 + 1 = 3
+1 + 1 - 1 + 1 + 1 = 3
+1 + 1 + 1 - 1 + 1 = 3
+1 + 1 + 1 + 1 - 1 = 3`}
                    </pre>
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1], target = 1 <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 20
                <br />
                0 &lt;= nums[i] &lt;= 1000
                <br />
                0 &lt;= sum(nums[i]) &lt;= 1000
                <br />
                -1000 v= target &lt;= 1000
              </>
            }
            tc="n.k"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} target
                * @return {number}
                */
               var findTargetSumWays = function(nums, target) {
                 const sum = nums.reduce((a,b)=>a+b,0);
                 if (sum < Math.abs(target) || (sum + target) % 2 == 1)
                   return 0;
                 return knapsack(nums, parseInt((sum + target) / 2));
               };
               
               function knapsack(nums, target) {
                 const dp = Array(Math.abs(target) + 1).fill(0);
                 dp[0] = 1;
               
                 for (let num of nums)
                   for (let i = target; i >= num; --i)
                     dp[i] += dp[i - num];
               
                 return dp[target];
               }
               
               console.log(findTargetSumWays([1],1))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q419: {
        title: "Q495. Teemo Attacking (Q419)",
        content: (
          <Comp
            title="Q495. Teemo Attacking (Q419)"
            content1={
              <>
                Our hero Teemo is attacking an enemy Ashe with poison attacks!
                When Teemo attacks Ashe, Ashe gets poisoned for a exactly
                duration seconds. More formally, an attack at second t will mean
                Ashe is poisoned during the inclusive time interval [t, t +
                duration - 1]. If Teemo attacks again before the poison effect
                ends, the timer for it is reset, and the poison effect will end
                duration seconds after the new attack.
                <br />
                You are given a non-decreasing integer array timeSeries, where
                timeSeries[i] denotes that Teemo attacks Ashe at second
                timeSeries[i], and an integer duration.
                <br />
                Return the total number of seconds that Ashe is poisoned.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: timeSeries = [1,4], duration = 2<br />
                    Output: 4<br />
                    Explanation: Teemo's attacks on Ashe go as follows:
                    <br />
                    - At second 1, Teemo attacks, and Ashe is poisoned for
                    seconds 1 and 2.
                    <br />
                    - At second 4, Teemo attacks, and Ashe is poisoned for
                    seconds 4 and 5.
                    <br />
                    Ashe is poisoned for seconds 1, 2, 4, and 5, which is 4
                    seconds in total.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: timeSeries = [1,4], duration = 2<br />
                    Output: 4<br />
                    Explanation: Teemo's attacks on Ashe go as follows:
                    <br />
                    - At second 1, Teemo attacks, and Ashe is poisoned for
                    seconds 1 and 2.
                    <br />
                    - At second 4, Teemo attacks, and Ashe is poisoned for
                    seconds 4 and 5.
                    <br />
                    Ashe is poisoned for seconds 1, 2, 4, and 5, which is 4
                    seconds in total.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= timeSeries.length &lt;= 10^4 <br />
                0 &lt;= timeSeries[i], duration &lt;= 10^7 <br />
                timeSeries is sorted in non-decreasing order.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} timeSeries
                * @param {number} duration
                * @return {number}
                */
               var findPoisonedDuration = function(timeSeries, duration) {
                 if (duration == 0) return 0;
                 let ans = 0;
                 for (let i = 0; i + 1 < timeSeries.length; ++i)
                   ans += Math.min(timeSeries[i + 1] - timeSeries[i], duration);
                 return ans + duration;
               };
               
               console.log(findPoisonedDuration([1,4],2))
               `,
                output: `4`,
              },
            }}
          />
        ),
      },
      q420: {
        title: "Q496. Next Greater Element I (Q420)",
        content: (
          <Comp
            title="Q496. Next Greater Element I (Q420)"
            content1={
              <>
                The next greater element of some element x in an array is the
                first greater element that is to the right of x in the same
                array.
                <br />
                You are given two distinct 0-indexed integer arrays nums1 and
                nums2, where nums1 is a subset of nums2.
                <br />
                For each 0 &lt;= i &lt; nums1.length, find the index j such that
                nums1[i] == nums2[j] and determine the next greater element of
                nums2[j] in nums2. If there is no next greater element, then the
                answer for this query is -1.
                <br />
                Return an array ans of length nums1.length such that ans[i] is
                the next greater element as described above.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums1 = [4,1,2], nums2 = [1,3,4,2] Output: [-1,3,-1]
                    Explanation: The next greater element for each value of
                    nums1 is as follows: - 4 is underlined in nums2 = [1,3,4,2].
                    There is no next greater element, so the answer is -1. - 1
                    is underlined in nums2 = [1,3,4,2]. The next greater element
                    is 3. - 2 is underlined in nums2 = [1,3,4,2]. There is no
                    next greater element, so the answer is -1.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums1 = [2,4], nums2 = [1,2,3,4] Output: [3,-1]
                    Explanation: The next greater element for each value of
                    nums1 is as follows: - 2 is underlined in nums2 = [1,2,3,4].
                    The next greater element is 3. - 4 is underlined in nums2 =
                    [1,2,3,4]. There is no next greater element, so the answer
                    is -1.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums1.length &lt;= nums2.length &lt;= 1000 <br />
                0 &lt;= nums1[i], nums2[i] &lt;= 10^4 <br />
                All integers in nums1 and nums2 are unique. <br />
                All the integers of nums1 also appear in nums2.
              </>
            }
            fp={
              <>
                <b>Follow up :</b>Could you find an O(nums1.length +
                nums2.length) solution?
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums1
                * @param {number[]} nums2
                * @return {number[]}
                */
               var nextGreaterElement = function(nums1, nums2) {
                 const res = [];
                 const m = new Map();
                 const stack = [];
                 for (let num of nums2) {
                   while (stack.length && stack.slice(-1)[0] < num)
                     m.set(stack.pop(), num);
                   stack.push(num);
                 }
                 for (let num of nums1)
                   if (m.has(num))
                     res.push(m.get(num));
                   else
                     res.push(-1);
                 return res.map(x=>+x)
               };
               
               console.log(nextGreaterElement([2,4],[1,2,3,4]))`,
                output: `[ 3, -1 ]`,
              },
            }}
          />
        ),
      },
      q421: {
        title: "Q497. Random Point in Non-overlapping Rectangles (Q421)",
        content: (
          <Comp
            title="Q497. Random Point in Non-overlapping Rectangles (Q421)"
            content1={
              <>
                You are given an array of non-overlapping axis-aligned
                rectangles rects where rects[i] = [ai, bi, xi, yi] indicates
                that (ai, bi) is the bottom-left corner point of the ith
                rectangle and (xi, yi) is the top-right corner point of the ith
                rectangle. Design an algorithm to pick a random integer point
                inside the space covered by one of the given rectangles. A point
                on the perimeter of a rectangle is included in the space covered
                by the rectangle.
                <br />
                Any integer point inside the space covered by one of the given
                rectangles should be equally likely to be returned.
                <br />
                Note that an integer point is a point that has integer
                coordinates. <br />
                Implement the Solution class:
                <br />
                Solution(int[][] rects) Initializes the object with the given
                rectangles rects. <br />
                int[] pick() Returns a random integer point [u, v] inside the
                space covered by one of the given rectangles.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode497,
                content: (
                  <>
                    Input <br />
                    ["Solution", "pick", "pick", "pick", "pick", "pick"] <br />
                    [[[[-2, -2, 1, 1], [2, 2, 4, 6]]], [], [], [], [], []]{" "}
                    <br />
                    Output <br />
                    [null, [1, -2], [1, -1], [-1, -2], [-2, -2], [0, 0]] <br />
                    Explanation <br />
                    Solution solution = new Solution([[-2, -2, 1, 1], [2, 2, 4,
                    6]]); <br />
                    solution.pick(); // return [1, -2] <br />
                    solution.pick(); // return [1, -1] <br />
                    solution.pick(); // return [-1, -2] <br />
                    solution.pick(); // return [-2, -2] <br />
                    solution.pick(); // return [0, 0]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= rects.length &lt;= 100 <br />
                rects[i].length == 4 <br />
                -10^9 &lt;= ai &lt; xi &lt;= 109 <br />
                -10^9 &lt;= bi &lt; yi &lt;= 109 <br />
                xi - ai &lt;= 2000 <br />
                yi - bi &lt;= 2000 <br />
                All the rectangles do not overlap. <br />
                At most 104 calls will be made to pick
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `class Solution {
                  public Solution(int[][] rects) {
                    this.rects = rects;
                    areas = new int[rects.length];
                    for (int i = 0; i < rects.length; ++i)
                      areas[i] = getArea(rects[i]) + (i > 0 ? areas[i - 1] : 0);
                  }
                
                  public int[] pick() {
                    final int target = rand.nextInt(areas[areas.length - 1]);
                    final int index = firstGreater(areas, target);
                    final int[] r = rects[index];
                    return new int[] {
                        rand.nextInt(r[2] - r[0] + 1) + r[0],
                        rand.nextInt(r[3] - r[1] + 1) + r[1],
                    };
                  }
                
                  private int[][] rects;
                  private int[] areas;
                  private Random rand = new Random();
                
                  private int getArea(int[] r) {
                    return (r[2] - r[0] + 1) * (r[3] - r[1] + 1);
                  }
                
                  private int firstGreater(int[] areas, int target) {
                    int l = 0;
                    int r = areas.length;
                    while (l < r) {
                      final int m = l + (r - l) / 2;
                      if (areas[m] > target)
                        r = m;
                      else
                        l = m + 1;
                    }
                    return l;
                  }
                }
                
                
                /**
                 * Your Solution object will be instantiated and called as such:
                 * Solution obj = new Solution(rects);
                 * int[] param_1 = obj.pick();
                 */`,
                output: `[null,[1,1],[4,6],[0,0],[4,6],[0,-1]]`,
              },
            }}
          />
        ),
      },
      q422: {
        title: "Q498. Diagonal Traverse (Q422)",
        content: (
          <Comp
            title="Q498. Diagonal Traverse (Q422)"
            content1={
              <>
                Given an m x n matrix mat, return an array of all the elements
                of the array in a diagonal order.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode498,
                content: (
                  <>
                    Input: mat = [[1,2,3],[4,5,6],[7,8,9]] <br />
                    Output: [1,2,4,7,5,3,6,8,9]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: mat = [[1,2],[3,4]]
                    <br />
                    Output: [1,2,3,4]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == mat.length <br />
                n == mat[i].length <br />
                1 &lt;= m, n &lt;= 10^4 <br />
                1 &lt;= m * n &lt;= 10^4 <br />
                -10^5 &lt;= mat[i][j] &lt;= 10^5
              </>
            }
            tc="m.n"
            sc="m.n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} mat
                * @return {number[]}
                */
               var findDiagonalOrder = function(mat) {
                 const m = mat.length;
                 const n = mat[0].length;
                 const res = Array(m * n).fill(0);
                 let d = 1; 
                 let row = 0;
                 let col = 0;
                 for (let i = 0; i < m * n; ++i) {
                   res[i] = mat[row][col];
                   row -= d;
                   col += d;
                   if (row == m) {
                     row = m - 1;
                     col += 2;
                     d = -d;
                   }
                   if (col == n) {
                     col = n - 1;
                     row += 2;
                     d = -d;
                   }
                   if (row < 0) {
                     row = 0;
                     d = -d;
                   }
                   if (col < 0) {
                     col = 0;
                     d = -d;
                   }
                 }
                 return res;
               };
               
               console.log(findDiagonalOrder([[1,2,3],[4,5,6],[7,8,9]]))`,
                output: `[
                  1, 2, 4, 7, 5,
                  3, 6, 8, 9
                ]`,
              },
            }}
          />
        ),
      },
      q423: {
        title: "Q500. Keyboard Row (Q423)",
        content: (
          <Comp
            title="Q500. Keyboard Row (Q423)"
            content1={
              <>
                Given an array of strings words, return the words that can be
                typed using letters of the alphabet on only one row of American
                keyboard like the image below.
                <br />
                In the American keyboard:
                <br />
                the first row consists of the characters "qwertyuiop",
                <br />
                the second row consists of the characters "asdfghjkl", and
                <br />
                the third row consists of the characters "zxcvbnm".
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode500,
                content: (
                  <>
                    Input: words = ["Hello","Alaska","Dad","Peace"] <br />
                    Output: ["Alaska","Dad"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words = ["omk"] <br />
                    Output: []
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words = ["omk"] <br />
                    Output: []
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= words.length &lt;= 20 <br />
                1 &lt;= words[i].length &lt;= 100 <br />
                words[i] consists of English letters (both lowercase and
                uppercase).{" "}
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} words
                * @return {string[]}
                */
               var findWords = function(words) {
                 const res = [];
                 const rows = [2, 3, 3, 2, 1, 2, 2, 2, 1, 2, 2, 2, 3,
                                       3, 1, 1, 1, 1, 2, 1, 1, 3, 1, 3, 1, 3];
               
                 for (let word of words) {
                   const lowerWord = word.toLowerCase();
                   const row = rows[lowerWord[0].charCodeAt(0)-97];
                   const isValid = lowerWord.split("").every(c => rows[c.charCodeAt(0)-97] == row);
                   if (isValid)
                     res.push(word);
                 }
                 return res
               };
               
               console.log(findWords(["Hello","Alaska","Dad","Peace"]))`,
                output: `[ 'Alaska', 'Dad' ]`,
              },
            }}
          />
        ),
      },
      q424: {
        title: "Q501. Find Mode in Binary Search Tree (Q424)",
        content: (
          <Comp
            title="Q501. Find Mode in Binary Search Tree (Q424)"
            content1={
              <>
                Given the root of a binary search tree (BST) with duplicates,
                return all the mode(s) (i.e., the most frequently occurred
                element) in it.
                <br />
                If the tree has more than one mode, return them in any order.
                <br />
                Assume a BST is defined as follows:
                <br />
                The left subtree of a node contains only nodes with keys less
                than or equal to the node's key.
                <br />
                The right subtree of a node contains only nodes with keys
                greater than or equal to the node's key.
                <br />
                Both the left and right subtrees must also be binary search
                trees.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: root = [0] <br />
                    Output: [0]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [0]
                    <br />
                    Output: [0]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                {" "}
                The number of nodes in the tree is in the range [1, 104]. <br />
                -10^5 &lt;= Node.val &lt;= 10^5
              </>
            }
            fp={
              <>
                <b>Follow up :</b> Could you do that without using any extra
                space? (Assume that the implicit stack space incurred due to
                recursion does not count).
              </>
            }
            tc="n"
            sc="n.log n"
            codes={{
              Java: {
                code: `/**
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode() {}
                *     TreeNode(int val) { this.val = val; }
                *     TreeNode(int val, TreeNode left, TreeNode right) {
                *         this.val = val;
                *         this.left = left;
                *         this.right = right;
                *     }
                * }
                */
               
               import java.util.*;
               
               class Solution {
                 public int[] findMode(TreeNode root) {
                   List<Integer> ans = new ArrayList<>();
                   int[] count = new int[2];
               
                   inorder(root, count, ans);
                   return ans.stream().mapToInt(i -> i).toArray();
                 }
               
                 private TreeNode pred = null;
               
                 private void inorder(TreeNode root, int[] count, List<Integer> ans) {
                   if (root == null)
                     return;
               
                   inorder(root.left, count, ans);
                   updateCount(root, count, ans);
                   inorder(root.right, count, ans);
                 }
               
                 private void updateCount(TreeNode root, int[] count, List<Integer> ans) {
                   if (pred != null && pred.val == root.val)
                     ++count[0];
                   else
                     count[0] = 1;
               
                   if (count[0] > count[1]) {
                     count[1] = count[0];
                     ans.clear();
                     ans.add(root.val);
                   } else if (count[0] == count[1]) {
                     ans.add(root.val);
                   }
               
                   pred = root;
                 }
               }
               
              `,
                output: `[2]`,
              },
            }}
          />
        ),
      },
      q425: {
        title: "Q502. IPO (Q425)",
        content: (
          <Comp
            title="Q502. IPO (Q425)"
            content1={
              <>
                Suppose LeetCode will start its IPO soon. In order to sell a
                good price of its shares to Venture Capital, LeetCode would like
                to work on some projects to increase its capital before the IPO.
                Since it has limited resources, it can only finish at most k
                distinct projects before the IPO. Help LeetCode design the best
                way to maximize its total capital after finishing at most k
                distinct projects. <br />
                You are given n projects where the ith project has a pure profit
                profits[i] and a minimum capital of capital[i] is needed to
                start it. <br />
                Initially, you have w capital. When you finish a project, you
                will obtain its pure profit and the profit will be added to your
                total capital. <br />
                Pick a list of at most k distinct projects from given projects
                to maximize your final capital, and return the final maximized
                capital. <br />
                The answer is guaranteed to fit in a 32-bit signed integer.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: k = 2, w = 0, profits = [1,2,3], capital = [0,1,1]
                    <br />
                    Output: 4
                    <br />
                    Explanation: Since your initial capital is 0, you can only
                    start the project indexed 0. After finishing it you will
                    obtain profit 1 and your capital becomes 1. With capital 1,
                    you can either start the project indexed 1 or the project
                    indexed 2. Since you can choose at most 2 projects, you need
                    to finish the project indexed 2 to get the maximum capital.
                    Therefore, output the final maximized capital, which is 0 +
                    1 + 3 = 4.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: k = 3, w = 0, <br />
                    profits = [1,2,3], <br />
                    capital = [0,1,2] <br />
                    Output: 6
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= k &lt;= 10^5
                <br />
                0 &lt;= w &lt;= 10^9
                <br />
                n == profits.length
                <br />
                n == capital.length
                <br />
                1 &lt;= n &lt;= 105
                <br />
                0 &lt;= profits[i] &lt;= 104
                <br />0 &lt;= capital[i] &lt;= 109
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Javascript: {
                code: `
                // 2 , 0 , [1,2,3] , [0,1,1]
                class T {
                  public int pro;
                  public int cap;
                  public T(int pro, int cap) {
                    this.pro = pro;
                    this.cap = cap;
                  }
                };
                
                class Solution {
                  public int findMaximizedCapital(int k, int W, int[] Profits, int[] Capital) {
                    PriorityQueue<T> pqCap = new PriorityQueue<>((a, b) -> a.cap - b.cap);
                    PriorityQueue<T> pqPro = new PriorityQueue<>((a, b) -> b.pro - a.pro);
                
                    for (int i = 0; i < Capital.length; ++i)
                      pqCap.offer(new T(Profits[i], Capital[i]));
                
                    while (k-- > 0) {
                      while (!pqCap.isEmpty() && pqCap.peek().cap <= W)
                        pqPro.offer(pqCap.poll());
                      if (pqPro.isEmpty())
                        break;
                      W += pqPro.poll().pro;
                    }
                
                    return W;
                  }
                }
                `,
                output: `4`,
              },
            }}
          />
        ),
      },
      q426: {
        title: "Q503. Next Greater Element II (Q426)",
        content: (
          <Comp
            title="Q503. Next Greater Element II (Q426)"
            content1={
              <>
                Given a circular integer array nums (i.e., the next element of
                nums[nums.length - 1] is nums[0]), return the next greater
                number for every element in nums.
                <br />
                The next greater number of a number x is the first greater
                number to its traversing-order next in the array, which means
                you could search circularly to find its next greater number. If
                it doesn't exist, return -1 for this number.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,1] <br />
                    Output: [2,-1,2] <br />
                    Explanation: The first 1's next greater number is 2; <br />
                    The number 2 can't find next greater number. <br />
                    The second 1's next greater number needs to search
                    circularly, which is also 2.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4,3] <br />
                    Output: [2,3,4,-1,4]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4 <br />
                -10^9 &lt;= nums[i] &lt;= 10^9
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number[]}
                */
               var nextGreaterElements = function(nums) {
                 const n = nums.length;
                 const res = Array(n).fill(-1);
                 const stack = []; 
                 for (let i = 0; i < n * 2; ++i) {
                   const num = nums[i % n];
                   while (stack.length && nums[stack.slice(-1)[0]] < num)
                     res[stack.pop()] = num;
                   if (i < n)
                     stack.push(i);
                 }
                 return res;
               };
               
               console.log(nextGreaterElements([1,2,1]))`,
                output: `[ 2, -1, 2 ]`,
              },
            }}
          />
        ),
      },
      q427: {
        title: "Q504. Base 7 (Q427)",
        content: (
          <Comp
            title="Q504. Base 7 (Q427)"
            content1={
              <>
                Given an integer num, return a string of its base 7
                representation.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: num = 100 <br />
                    Output: "202"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num = -7
                    <br />
                    Output: "-10"
                  </>
                ),
              },
            ]}
            constraints={<>-10^7 &lt;= num &lt;= 10^7</>}
            tc="log7 n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} num
                * @return {string}
                */
               var convertToBase7 = function(num) {
                 if (num < 0)
                   return "-" + convertToBase7(-num);
                 if (num < 7)
                   return String(num);
                 return convertToBase7(parseInt(num / 7)) + String(num % 7);    
               };
               
               console.log(convertToBase7(100))`,
                output: `202`,
              },
            }}
          />
        ),
      },
      q428: {
        title: "Q506. Relative Ranks (Q428)",
        content: (
          <Comp
            title="Q506. Relative Ranks (Q428)"
            content1={
              <>
                You are given an integer array score of size n, where score[i]
                is the score of the ith athlete in a competition. All the scores
                are guaranteed to be unique.
                <br />
                The athletes are placed based on their scores, where the 1st
                place athlete has the highest score, the 2nd place athlete has
                the 2nd highest score, and so on. The placement of each athlete
                determines their rank:
                <br />
                The 1st place athlete's rank is "Gold Medal".
                <br />
                The 2nd place athlete's rank is "Silver Medal".
                <br />
                The 3rd place athlete's rank is "Bronze Medal".
                <br />
                For the 4th place to the nth place athlete, their rank is their
                placement number (i.e., the xth place athlete's rank is "x").
                Return an array answer of size n where answer[i] is the rank of
                the ith athlete.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: score = [5,4,3,2,1]
                    <br />
                    Output: ["Gold Medal","Silver Medal","Bronze Medal","4","5"]
                    <br />
                    Explanation: The placements are [1st, 2nd, 3rd, 4th, 5th].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: score = [10,3,8,9,4]
                    <br />
                    Output: ["Gold Medal","5","Bronze Medal","Silver Medal","4"]
                    <br />
                    Explanation: The placements are [1st, 5th, 3rd, 2nd, 4th].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == score.length
                <br />
                1 &lt;= n &lt;= 10^4
                <br />
                0 &lt;= score[i] &lt;= 10^6
                <br />
                All the values in score are unique.
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} score
                * @return {string[]}
                */
               var findRelativeRanks = function(score) {
                 const n = score.length;
                 const res = Array(n).fill(null);
                 const indices = [];
                 for (let i = 0; i < n; ++i)
                   indices.push(i);
                 indices.sort((a, b) => score[b] - score[a]);
                 for (let i = 0; i < n; ++i)
                  if (i == 0)
                    res[indices[0]] = "Gold Medal";
                  else if (i == 1)
                    res[indices[1]] = "Silver Medal";
                  else if (i == 2)
                    res[indices[2]] = "Bronze Medal";
                  else
                    res[indices[i]] = String(i + 1);
                 return res; 
               };
               
               console.log(findRelativeRanks([5,4,3,2,1]))`,
                output: `[ 'Gold Medal', 'Silver Medal', 'Bronze Medal', '4', '5' ]`,
              },
            }}
          />
        ),
      },
      q429: {
        title: "Q507. Perfect Number (Q429)",
        content: (
          <Comp
            title="Q507. Perfect Number (Q429)"
            content1={
              <>
                A perfect number is a positive integer that is equal to the sum
                of its positive divisors, excluding the number itself. A divisor
                of an integer x is an integer that can divide x evenly.
                <br />
                Given an integer n, return true if n is a perfect number,
                otherwise return false.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: num = 28 <br />
                    Output: true <br />
                    Explanation: 28 = 1 + 2 + 4 + 7 + 14 <br />
                    1, 2, 4, 7, and 14 are all divisors of 28.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num = 7 <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= num &lt;= 108</>}
            tc="n^0.5 => 1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} num
                * @return {boolean}
                */
               var checkPerfectNumber = function(num) {
                 if (num == 1)
                   return false;
                 let sum = 1;
                 for (let i = 2; i <= Math.sqrt(num); ++i)
                   if (num % i == 0)
                     sum += i + parseInt(num / i);
                 return sum == num; 
               };
               
               console.log(checkPerfectNumber(28))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q430: {
        title: "Q508. Most Frequent Subtree Sum (Q430)",
        content: (
          <Comp
            title="Q508. Most Frequent Subtree Sum (Q430)"
            content1={
              <>
                Given the root of a binary tree, return the most frequent
                subtree sum. If there is a tie, return all the values with the
                highest frequency in any order.
                <br />
                The subtree sum of a node is defined as the sum of all the node
                values formed by the subtree rooted at that node (including the
                node itself).
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode508,
                content: (
                  <>
                    Input: root = [5,2,-3] <br />
                    Output: [2,-3,4]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [5,2,-5]
                    <br />
                    Output: [2]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 104]. <br />
                -10^5 &lt;= Node.val &lt;= 10^5
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
              }
              
              /**
               * @param {TreeNode} root
               * @return {number[]}
               */
              
              function dfs(root,count){
                if(!root) return 0
                let sum = root.val + dfs(root.left,count) + dfs(root.right,count)
                count[sum] = (count[sum] || 0) + 1
                return sum
              }
              
              var findFrequentTreeSum = function(root) {
                 if(!root) return []
                 const count = {}
                 dfs(root,count)
                 let maxFreq = Math.max(...Object.values(count))
                 const res = []
                 for(let s in count){
                   if(count[s]===maxFreq) res.push(+s)
                 }
                 return res
              };
              
              const t = new TreeNode(5)
              t.left = new TreeNode(2)
              t.right = new TreeNode(-5)
              console.log(findFrequentTreeSum(t))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q431: {
        title: "Q509. Fibonacci Number (Q431)",
        content: (
          <Comp
            title="Q509. Fibonacci Number (Q431)"
            content1={
              <>
                The Fibonacci numbers, commonly denoted F(n) form a sequence,
                called the Fibonacci sequence, such that each number is the sum
                of the two preceding ones, starting from 0 and 1. That is,
                <br />
                F(0) = 0, F(1) = 1<br />
                F(n) = F(n - 1) + F(n - 2), for n &gt; 1.
                <br />
                Given n, calculate F(n).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 2 <br />
                    Output: 1 <br />
                    Explanation: F(2) = F(1) + F(0) = 1 + 0 = 1.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 3 <br />
                    Output: 2 <br />
                    Explanation: F(3) = F(2) + F(1) = 1 + 1 = 2.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 4<br />
                    Output: 3<br />
                    Explanation: F(4) = F(3) + F(2) = 2 + 1 = 3.
                  </>
                ),
              },
            ]}
            constraints={<>0 &lt;= n &lt;= 30</>}
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               var fib = function(n) {
                 const dp = Array(30).fill(-1)
                 dp[0] = 0
                 dp[1] = 1
                 for (let i = 2; i <= n; i++)
                   dp[i] = dp[i - 1] + dp[i - 2];
                 return dp[n]
               };
               
               console.log(fib(2))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q432: {
        title: "Q513. Find Bottom Left Tree Value (Q432)",
        content: (
          <Comp
            title="Q513. Find Bottom Left Tree Value (Q432)"
            content1={
              <>
                Given the root of a binary tree, return the leftmost value in
                the last row of the tree.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode513,
                content: (
                  <>
                    Input: root = [2,1,3] <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1,2,3,4,null,5,6,null,null,7]
                    <br />
                    Output: 7
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 10^4].{" "}
                <br />
                -2^31 &lt;= Node.val &lt;= 2^31 - 1
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                
                /**
                 * @param {TreeNode} root
                 * @return {number}
                 */
                
                var findBottomLeftValue = function(root) {
                  const q = [root];
                  let node = null;
                  while (q.length) {
                    node = q.shift();
                    if (node.right)
                      q.push(node.right);
                    if (node.left)
                      q.push(node.left);
                  }
                  return node.val;
                };
                
                const t = new TreeNode(2)
                t.left = new TreeNode(1)
                t.right = new TreeNode(3)
                console.log(findBottomLeftValue(t))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q433: {
        title: "Q514. Freedom Trail (Q433)",
        content: (
          <Comp
            title="Q514. Freedom Trail (Q433)"
            content1={
              <>
                In the video game Fallout 4, the quest "Road to Freedom"
                requires players to reach a metal dial called the "Freedom Trail
                Ring" and use the dial to spell a specific keyword to open the
                door.
                <br />
                Given a string ring that represents the code engraved on the
                outer ring and another string key that represents the keyword
                that needs to be spelled, return the minimum number of steps to
                spell all the characters in the keyword.
                <br />
                Initially, the first character of the ring is aligned at the
                "12:00" direction. You should spell all the characters in key
                one by one by rotating ring clockwise or anticlockwise to make
                each character of the string key aligned at the "12:00"
                direction and then by pressing the center button.
                <br />
                At the stage of rotating the ring to spell the key character
                key[i]:
                <br />
                You can rotate the ring clockwise or anticlockwise by one place,
                which counts as one step. The final purpose of the rotation is
                to align one of ring's characters at the "12:00" direction,
                where this character must equal key[i]. If the character key[i]
                has been aligned at the "12:00" direction, press the center
                button to spell, which also counts as one step. After the
                pressing, you could begin to spell the next character in the key
                (next stage). Otherwise, you have finished all the spelling.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: ring = "godding", key = "gd" <br />
                    Output: 4 <br />
                    Explanation: <br />
                    For the first key character 'g', since it is already in
                    place, we just need 1 step to spell this character. For the
                    second key character 'd', we need to rotate the ring
                    "godding" anticlockwise by two steps to make it become
                    "ddinggo". Also, we need 1 more step for spelling. So the
                    final output is 4.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: ring = "godding", key = "godding"
                    <br />
                    Output: 13
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= ring.length, key.length &lt;= 100
                <br />
                ring and key consist of only lower case English letters.
                <br />
                It is guaranteed that key could always be spelled by rotating
                ring.
              </>
            }
            tc="k.r^3"
            sc="k"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} ring
                * @param {string} key
                * @return {number}
                */
               
               function dfs(ring, key, index, memo){
                 if (index == key.length)
                     return 0;
                 const hashKey = ring + index;
                 if (memo.has(hashKey))
                     return memo.get(hashKey);
                 let ans = Number.MAX_SAFE_INTEGER;
                 for (let i = 0; i < ring.length; ++i)
                   if (ring[i] == key[index]) {
                   let minRotates = Math.min(i, ring.length - i);
                   const newRing = ring.substring(i) + ring.substring(0, i);
                   let remainingRotates = dfs(newRing, key, index + 1, memo);
                   ans = Math.min(ans, minRotates + remainingRotates);
                   }
                 memo.set(hashKey, ans);
                 return ans;
               }
               
               var findRotateSteps = function(ring, key){
                 const memo = new Map();
                 return dfs(ring, key, 0, memo) + key.length;
               };
               
               console.log(findRotateSteps("godding","godding"))`,
                output: `13`,
              },
            }}
          />
        ),
      },
      q434: {
        title: "Q515. Find Largest Value in Each Tree Row (Q434)",
        content: (
          <Comp
            title="Q515. Find Largest Value in Each Tree Row (Q434)"
            content1={
              <>
                Given the root of a binary tree, return an array of the largest
                value in each row of the tree (0-indexed).
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode515,
                content: (
                  <>
                    Input: root = [1,3,2,5,3,null,9]
                    <br />
                    Output: [1,3,9]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1,2,3] <br />
                    Output: [1,3]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree will be in the range [0, 10^4].{" "}
                <br />
                -2^31 &lt;= Node.val &lt;= 2^31 - 1
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
              }
              
              /**
               * @param {TreeNode} root
               * @return {number[]}
               */
              var largestValues = function(root) {
                if (!root) return [];
                const res = [];
                const q = [root];
                while (q.length) {
                  let max = Number.MIN_SAFE_INTEGER;
                  for (let size = q.length; size > 0; --size) {
                    let curr = q.shift();
                    max = Math.max(max, curr.val);
                    if (curr.left)
                      q.push(curr.left);
                    if (curr.right)
                      q.push(curr.right);
                  }
                  res.push(max);
                }
                return res;    
              };
              
              const t = new TreeNode(1)
              t.left = new TreeNode(3)
              t.left.left = new TreeNode(5)
              t.left.right = new TreeNode(3)
              t.right = new TreeNode(2)
              t.right.right = new TreeNode(9)
              console.log(largestValues(t))`,
                output: `[ 1, 3, 9 ]`,
              },
            }}
          />
        ),
      },
      q435: {
        title: "Q516. Longest Palindromic Subsequence (Q435)",
        content: (
          <Comp
            title="Q516. Longest Palindromic Subsequence (Q435)"
            content1={
              <>
                Given a string s, find the longest palindromic subsequence's
                length in s.
                <br />A subsequence is a sequence that can be derived from
                another sequence by deleting some or no elements without
                changing the order of the remaining elements.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "bbbab" <br />
                    Output: 4 <br />
                    Explanation: One possible longest palindromic subsequence is
                    "bbbb".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "cbbd" <br />
                    Output: 2 <br />
                    Explanation: One possible longest palindromic subsequence is
                    "bb".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 1000 <br />s consists only of lowercase
                English letters.
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               const lcs = function(str1, str2) {
                 const m = str1.length
                 const n = str2.length
                 const dp = Array.from(Array(m + 1), () => Array(n + 1).fill(n + 1))
                 for (let i = 0; i <= m; i++) {
                     for (let j = 0; j <= n; j++) {
                         if (i === 0 || j === 0) dp[i][j] = 0
                         else if (str1[i - 1] === str2[j - 1])
                             dp[i][j] = dp[i - 1][j - 1] + 1
                         else
                             dp[i][j] = Math.max(dp[i - 1][j], dp[i][j - 1])
                     }
                 }
                 return dp[m][n]
               }
               
               var longestPalindromeSubseq = function(s) {
                 return lcs(s, s.split("").reverse().join());
               };
               
               console.log(longestPalindromeSubseq("bbbab"))`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q436: {
        title: "Q517. Super Washing Machines (Q436)",
        content: (
          <Comp
            title="Q517. Super Washing Machines (Q436)"
            content1={
              <>
                You have n super washing machines on a line. Initially, each
                washing machine has some dresses or is empty.
                <br />
                For each move, you could choose any m (1 &lt;= m &lt;= n)
                washing machines, and pass one dress of each washing machine to
                one of its adjacent washing machines at the same time.
                <br />
                Given an integer array machines representing the number of
                dresses in each washing machine from left to right on the line,
                return the minimum number of moves to make all the washing
                machines have the same number of dresses. If it is not possible
                to do it, return -1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: machines = [1,0,5] <br />
                    Output: 3 <br />
                    Explanation: <br />
                    <pre>
                      {`
1st move:    1     0 <-- 5    =>    1     1     4
2nd move:    1 <-- 1 <-- 4    =>    2     1     3
3rd move:    2     1 <-- 3    =>    2     2     2`}
                    </pre>
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: machines = [0,3,0]
                    <br />
                    Output: 2<br />
                    Explanation:
                    <br />
                    <pre>
                      {`
1st move:    0 <-- 3     0    =>    1     2     0
2nd move:    1     2 --> 0    =>    1     1     1  `}
                    </pre>
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: machines = [0,2,0] <br />
                    Output: -1 <br />
                    Explanation: It's impossible to make all three washing
                    machines have the same number of dresses.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == machines.length
                <br />
                1 &lt;= n &lt;= 10^4
                <br />0 &lt;= machines[i] &lt;= 10^5
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} machines
                * @return {number}
                */
               var findMinMoves = function(machines) {
                 let dresses = machines.reduce((a,b)=>a+b,0);
                 if (dresses % machines.length != 0)
                     return -1;
                 let ans = 0;
                 let inout = 0;
                 const average = parseInt(dresses / machines.length);
                 for (let dress of machines) {
                   inout += dress - average;
                   ans = Math.max(ans, Math.max(Math.abs(inout), dress - average));
                 }
                 return ans;    
               };
               
               console.log(findMinMoves([0,2,0]))`,
                output: `-1`,
              },
            }}
          />
        ),
      },
      q437: {
        title: "Q518. Coin Change 2 (Q437)",
        content: (
          <Comp
            title="Q518. Coin Change 2 (Q437)"
            content1={
              <>
                You are given an integer array coins representing coins of
                different denominations and an integer amount representing a
                total amount of money.
                <br />
                Return the number of combinations that make up that amount. If
                that amount of money cannot be made up by any combination of the
                coins, return 0.
                <br />
                You may assume that you have an infinite number of each kind of
                coin.
                <br />
                The answer is guaranteed to fit into a signed 32-bit integer.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: amount = 5, coins = [1,2,5] <br />
                    Output: 4 <br />
                    Explanation: there are four ways to make up the amount:{" "}
                    <br />
                    5=5 <br />
                    5=2+2+1 <br />
                    5=2+1+1+1 <br />
                    5=1+1+1+1+1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: amount = 3, coins = [2]
                    <br />
                    Output: 0<br />
                    Explanation: the amount of 3 cannot be made up just with
                    coins of 2.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: amount = 10, coins = [10] <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= coins.length &lt;= 300 <br />
                1 &lt;= coins[i] &lt;= 5000 <br />
                All the values of coins are unique. <br />0 &lt;= amount &lt;=
                5000
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} amount
                * @param {number[]} coins
                * @return {number}
                */
               var change = function(amount, coins) {
                 const dp = Array(amount + 1).fill(0);
                 dp[0] = 1;
                 for (let coin of coins)
                   for (let i = coin; i <= amount; ++i)
                     dp[i] += dp[i - coin];
                 return dp[amount];  
               };
               
               console.log(change(5,[1,2,5]))`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q438: {
        title: "Q519. Random Flip Matrix (Q438)",
        content: (
          <Comp
            title="Q519. Random Flip Matrix (Q438)"
            content1={
              <>
                There is an m x n binary grid matrix with all the values set 0
                initially. Design an algorithm to randomly pick an index (i, j)
                where matrix[i][j] == 0 and flips it to 1. All the indices (i,
                j) where matrix[i][j] == 0 should be equally likely to be
                returned.
                <br />
                Optimize your algorithm to minimize the number of calls made to
                the built-in random function of your language and optimize the
                time and space complexity.
                <br />
                Implement the Solution class:
                <br />
                Solution(int m, int n) Initializes the object with the size of
                the binary matrix m and n.
                <br /> int[] flip() Returns a random index [i, j] of the matrix
                where matrix[i][j] == 0 and flips it to 1.
                <br /> void reset() Resets all the values of the matrix to be 0.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br />
                    ["Solution", "flip", "flip", "flip", "reset", "flip"]
                    <br />
                    [[3, 1], [], [], [], [], []]
                    <br />
                    Output
                    <br />
                    [null, [1, 0], [2, 0], [0, 0], null, [2, 0]]
                    <br />
                    <br />
                    Explanation
                    <br />
                    Solution solution = new Solution(3, 1);
                    <br />
                    solution.flip(); // return [1, 0], [0,0], [1,0], and [2,0]
                    should be equally likely to be returned.
                    <br />
                    solution.flip(); // return [2, 0], Since [1,0] was returned,
                    [2,0] and [0,0]
                    <br />
                    solution.flip(); // return [0, 0], Based on the previously
                    returned indices, only [0,0] can be returned.
                    <br /> solution.reset(); // All the values are reset to 0
                    and can be returned.
                    <br />
                    solution.flip(); // return [2, 0], [0,0], [1,0], and [2,0]
                    should be equally likely to be returned.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= m, n &lt;= 10^4
                <br />
                There will be at least one free cell for each call to flip.
                <br />
                At most 1000 calls will be made to flip and reset.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} m
                * @param {number} n
                */
               var Solution = function(m, n) {
                 this.rows = m;
                 this.cols = n;
                 this.total = m * n;   
                 this.used = new Set();
               };
               
               /**
                * @return {number[]}
                */
               Solution.prototype.flip = function() {
                 if (this.used.size == this.total)
                   return [];
                 let index = Math.floor(Math.random() * this.total);
                 while (this.used.has(index))
                   index = ++index % this.total;
                 this.used.add(index);
                 return [parseInt(index / this.cols), index % this.cols];
               };
               
               /**
                * @return {void}
                */
               Solution.prototype.reset = function() {
                 this.used.clear();
               };
               
               /** 
                * Your Solution object will be instantiated and called as such:
                * var obj = new Solution(m, n)
                * var param_1 = obj.flip()
                * obj.reset()
                */`,
                output: `[null,[0,0],[1,0],[2,0],null,[2,0]]`,
              },
            }}
          />
        ),
      },
      q439: {
        title: "Q520. Detect Capital (Q439)",
        content: (
          <Comp
            title="Q520. Detect Capital (Q439)"
            content1={
              <>
                We define the usage of capitals in a word to be right when one
                of the following cases holds:
                <br />
                All letters in this word are capitals, like "USA". <br />
                All letters in this word are not capitals, like "leetcode".{" "}
                <br />
                Only the first letter in this word is capital, like "Google".{" "}
                <br />
                Given a string word, return true if the usage of capitals in it
                is right.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: word = "FlaG" <br />
                    Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: word = "FlaG" <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= word.length &lt;= 100 <br />
                word consists of lowercase and uppercase English letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} word
                * @return {boolean}
                */
               var detectCapitalUse = function(word) {
                 return word === word.toUpperCase() ||
                       word.substring(1) === word.substring(1).toLowerCase();
               };
               
               console.log(detectCapitalUse("USA"))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q440: {
        title: "Q521. Longest Uncommon Subsequence I (Q440)",
        content: (
          <Comp
            title="Q521. Longest Uncommon Subsequence I (Q440)"
            content1={
              <>
                Given two strings a and b, return the length of the longest
                uncommon subsequence between a and b. If the longest uncommon
                subsequence does not exist, return -1.
                <br />
                An uncommon subsequence between two strings is a string that is
                a subsequence of one but not the other.
                <br />
                A subsequence of a string s is a string that can be obtained
                after deleting any number of characters from s.
                <br />
                For example, "abc" is a subsequence of "aebdc" because you can
                delete the underlined characters in "aebdc" to get "abc". Other
                subsequences of "aebdc" include "aebdc", "aeb", and "" (empty
                string).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: a = "aaa", b = "aaa"
                    <br />
                    Output: -1
                    <br />
                    Explanation: Every subsequence of string a is also a
                    subsequence of string b. Similarly, every subsequence of
                    string b is also a subsequence of string a.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: a = "aaa", b = "aaa"
                    <br />
                    Output: -1
                    <br />
                    Explanation: Every subsequence of string a is also a
                    subsequence of string b. Similarly, every subsequence of
                    string b is also a subsequence of string a.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: a = "aaa", b = "aaa"
                    <br />
                    Output: -1
                    <br />
                    Explanation: Every subsequence of string a is also a
                    subsequence of string b. Similarly, every subsequence of
                    string b is also a subsequence of string a.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= a.length, b.length &lt;= 100 <br />a and b consist of
                lower-case English letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} a
                * @param {string} b
                * @return {number}
                */
               var findLUSlength = function(a, b) {
                 return a === b ? -1 : Math.max(a.length, b.length); 
               };
               
               console.log(findLUSlength("aaa","bbb"))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q441: {
        title: "Q522. Longest Uncommon Subsequence II (Q441)",
        content: (
          <Comp
            title="Q522. Longest Uncommon Subsequence II (Q441)"
            content1={
              <>
                Given an array of strings strs, return the length of the longest
                uncommon subsequence between them. If the longest uncommon
                subsequence does not exist, return -1.
                <br />
                An uncommon subsequence between an array of strings is a string
                that is a subsequence of one string but not the others.
                <br />
                A subsequence of a string s is a string that can be obtained
                after deleting any number of characters from s.
                <br />
                For example, "abc" is a subsequence of "aebdc" because you can
                delete the underlined characters in "aebdc" to get "abc". Other
                subsequences of "aebdc" include "aebdc", "aeb", and "" (empty
                string).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: strs = ["aba","cdc","eae"]
                    <br />
                    Output: 3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: strs = ["aaa","aaa","aa"]
                    <br />
                    Output: -1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= strs.length &lt;= 50 <br />
                1 &lt;= strs[i].length &lt;= 10 <br />
                strs[i] consists of lowercase English letters.
              </>
            }
            tc="n^3.l"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} a
                * @param {string} b
                * @return {number}
                */
               
               function isSubsequence(a, b) {
                 let i = 0;
                 for (let c of b)
                   if (i < a.length && c == a[i])
                     ++i;
                 return i == a.length;
               }
               
               var findLUSlength = function(strs) {
                 const seen = new Set();
                 const duplicates = new Set();
                 for (let str of strs)
                   if (seen.has(str))
                     duplicates.add(str);
                   else
                     seen.add(str);
                 strs.sort((a, b) => b.length - a.length);
                 for (let i = 0; i < strs.length; ++i) {
                   if (duplicates.has(strs[i]))
                     continue;
                   let isASubsequence = false;
                   for (let j = 0; j < i; ++j)
                     isASubsequence |= isSubsequence(strs[i], strs[j]);
                   if (!isASubsequence)
                     return strs[i].length;
                 }
                 return -1;
               };
               
               console.log(findLUSlength(["aba","cdc","eae"]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q442: {
        title: "Q523. Continuous Subarray Sum (Q442)",
        content: (
          <Comp
            title="Q523. Continuous Subarray Sum (Q442)"
            content1={
              <>
                Given an integer array nums and an integer k, return true if
                nums has a continuous subarray of size at least two whose
                elements sum up to a multiple of k, or false otherwise.
                <br />
                An integer x is a multiple of k if there exists an integer n
                such that x = n * k. 0 is always a multiple of k.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [23,2,4,6,7], k = 6<br />
                    Output: true
                    <br />
                    Explanation: [2, 4] is a continuous subarray of size 2 whose
                    elements sum up to 6.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [23,2,6,4,7], k = 6 <br />
                    Output: true <br />
                    Explanation: [23, 2, 6, 4, 7] is an continuous subarray of
                    size 5 whose elements sum up to 42. 42 is a multiple of 6
                    because 42 = 7 * 6 and 7 is an integer.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [23,2,6,4,7], k = 13 <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^5 <br />
                0 &lt;= nums[i] &lt;= 10^9 <br />
                0 &lt;= sum(nums[i]) &lt;= 2^31 - 1 <br />1 &lt;= k &lt;= 2^31 -
                1
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {boolean}
                */
               var checkSubarraySum = function(nums, k) {
                 let prefix = 0;
                 const m = new Map();
                 m.set(0, -1);
                 for (let i = 0; i < nums.length; ++i) {
                   prefix += nums[i];
                   if (k != 0)
                     prefix %= k;
                   if (m.has(prefix)) {
                     if (i - m.get(prefix) > 1)
                       return true;
                   } else {
                     m.set(prefix, i);
                   }
                 }
                 return false;   
               };
               
               console.log(checkSubarraySum([23,2,4,6,7],6))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q443: {
        title: "Q524. Longest Word in Dictionary through Deleting (Q443)",
        content: (
          <Comp
            title="Q524. Longest Word in Dictionary through Deleting (Q443)"
            content1={
              <>
                Given a string s and a string array dictionary, return the
                longest string in the dictionary that can be formed by deleting
                some of the given string characters. If there is more than one
                possible result, return the longest word with the smallest
                lexicographical order. If there is no possible result, return
                the empty string.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "abpcplea",
                    <br /> dictionary = ["ale","apple","monkey","plea"]
                    <br />
                    Output: "apple"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "abpcplea",
                    <br /> dictionary = ["a","b","c"]
                    <br />
                    Output: "a"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 1000 <br />
                1 &lt;= dictionary.length &lt;= 1000
                <br />
                1 &lt;= dictionary[i].length &lt;= 1000
                <br />s and dictionary[i] consist of lowercase English letters.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @param {string[]} dictionary
                * @return {string}
                */
               function isSubsequence(a, b) {
                 let i = 0;
                 for (let c of b)
                   if (i < a.length && c == a[i])
                     ++i;
                 return i == a.length;
               }
               
               var findLongestWord = function(s, dictionary) {
                 let res = "";
                 for (let word of dictionary)
                   if (isSubsequence(word, s))
                     if (word.length > res.length ||
                         word.length == res.length && word < res)
                       res = word;
                 return res;
               };
               
               console.log(findLongestWord("abpcplea",["ale","apple","monkey","plea"]))`,
                output: `apple`,
              },
            }}
          />
        ),
      },
      q444: {
        title: "Q525. Contiguous Array (Q444)",
        content: (
          <Comp
            title="Q525. Contiguous Array (Q444)"
            content1={
              <>
                Given a binary array nums, return the maximum length of a
                contiguous subarray with an equal number of 0 and 1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [0,1,0]
                    <br />
                    Output: 2<br />
                    Explanation: [0, 1] (or [1, 0]) is a longest contiguous
                    subarray with equal number of 0 and 1.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [0,1,0]
                    <br />
                    Output: 2<br />
                    Explanation: [0, 1] (or [1, 0]) is a longest contiguous
                    subarray with equal number of 0 and 1.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^5 <br />
                nums[i] is either 0 or 1.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var findMaxLength = function(nums) {
                 let res = 0;
                 let prefix = 0;
                 const m = new Map();
                 m.set(0, -1);
                 for (let i = 0; i < nums.length; ++i) {
                   prefix += nums[i] == 1 ? 1 : -1;
                   if (m.has(prefix))
                     res = Math.max(res, i - m.get(prefix));
                   else
                     m.set(prefix, i);
                 }
                 return res; 
               };
               
               console.log(findMaxLength([0,1]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q445: {
        title: "Q526. Beautiful Arrangement (Q445)",
        content: (
          <Comp
            title="Q526. Beautiful Arrangement (Q445)"
            content1={
              <>
                Suppose you have n integers labeled 1 through n. A permutation
                of those n integers perm (1-indexed) is considered a beautiful
                arrangement if for every i (1 &lt;= i &lt;= n), either of the
                following is true:
                <br />
                perm[i] is divisible by i.
                <br />
                i is divisible by perm[i].
                <br />
                Given an integer n, return the number of the beautiful
                arrangements that you can construct
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 2<br />
                    Output: 2<br />
                    Explanation: <br />
                    The first beautiful arrangement is [1,2]:
                    <br />
                    - perm[1] = 1 is divisible by i = 1<br />
                    - perm[2] = 2 is divisible by i = 2<br />
                    The second beautiful arrangement is [2,1]:
                    <br />
                    - perm[1] = 2 is divisible by i = 1<br />- i = 2 is
                    divisible by perm[2] = 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1 <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 15</>}
            tc="n.2^n"
            sc="2^n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               
               function dfs(n, num, st, memo) {
                 if (num == n + 1)
                   return 1;
                 let filled = st.join("");
                   if (memo.has(filled))
                     return memo.get(filled);
                   let count = 0;
                   for (let i = 1; i <= n; ++i)
                     if (st[i] == 'x' && (num % i == 0 || i % num == 0)) {
                       st[i] =  'o';
                       count += dfs(n, num + 1, st, memo);
                       st[i] = 'x';
                     }
                   memo.set(filled, count);
                   return count; 
               } 
               
               
               var countArrangement = function(n) {
                 const st = Array(n+1).fill("x");
                 const memo = new Map();
                 return dfs(n, 1, st, memo);
               };
               
               console.log(countArrangement(2))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q446: {
        title: "Q528. Random Pick with Weight (Q446)",
        content: (
          <Comp
            title="Q528. Random Pick with Weight (Q446)"
            content1={
              <>
                You are given a 0-indexed array of positive integers w where
                w[i] describes the weight of the ith index.
                <br />
                You need to implement the function pickIndex(), which randomly
                picks an index in the range [0, w.length - 1] (inclusive) and
                returns it. The probability of picking an index i is w[i] /
                sum(w).
                <br />
                For example, if w = [1, 3], the probability of picking index 0
                is 1 / (1 + 3) = 0.25 (i.e., 25%), and the probability of
                picking index 1 is 3 / (1 + 3) = 0.75 (i.e., 75%).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["Solution","pickIndex"] <br />
                    [[[1]],[]] Output [null,0] <br />
                    Explanation Solution solution = new Solution([1]);
                    solution.pickIndex(); // return 0. The only option is to
                    return 0 since there is only one element in w.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input
                    <br />
                    ["Solution","pickIndex","pickIndex","pickIndex","pickIndex","pickIndex"]
                    [[[1,3]],[],[],[],[],[]]
                    <br />
                    Output
                    <br />
                    [null,1,1,1,1,0]
                    <br />
                    <br />
                    Explanation Solution solution = new Solution([1, 3]);
                    <br />
                    solution.pickIndex(); // return 1. It is returning the
                    second element (index = 1) that has a probability of 3/4.
                    <br /> solution.pickIndex(); // return 1<br />
                    solution.pickIndex(); // return 1<br />
                    solution.pickIndex(); // return 1<br />
                    solution.pickIndex(); // return 0. It is returning the first
                    element (index = 0) that has a probability of 1/4.
                    <br />
                    Since this is a randomization problem, multiple answers are
                    allowed. All of the following outputs can be considered
                    correct: [null,1,1,1,1,0]
                    <br />
                    [null,1,1,1,1,1]
                    <br />
                    [null,1,1,1,0,0]
                    <br />
                    [null,1,1,1,0,1]
                    <br />
                    [null,1,0,1,0,0]
                    <br />
                    ......
                    <br />
                    and so on.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= w.length &lt;= 10^4 <br />
                1 &lt;= w[i] &lt;= 10^5 <br />
                pickIndex will be called at most 10^4 times
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} w
                */
               var Solution = function(w) {
                 this.prefix = w;
                 for (let i = 1; i < this.prefix.length; ++i)
                   this.prefix[i] += this.prefix[i - 1];
               };
               
               /**
                * @return {number}
                */
               Solution.prototype.pickIndex = function() {
                 let target = Math.floor(Math.random() * (this.prefix[this.prefix.length - 1]));
                 let l = 0;
                 let r = this.prefix.length;
                 while (l < r) {
                   let m = l + parseInt((r - l) / 2);
                   if (this.prefix[m] > target)
                     r = m;
                   else
                     l = m + 1;
                 }
                 return l;
               };
               
               /** 
                * Your Solution object will be instantiated and called as such:
                * var obj = new Solution(w)
                * var param_1 = obj.pickIndex()
                */`,
                output: `[null,0]`,
              },
            }}
          />
        ),
      },
      q447: {
        title: "Q529. Minesweeper (Q447)",
        content: (
          <Comp
            title="Q529. Minesweeper (Q447)"
            content1={
              <>
                Let's play the minesweeper game (Wikipedia, online game)!
                <br />
                You are given an m x n char matrix board representing the game
                board where:
                <br />
                'M' represents an unrevealed mine, <br />
                'E' represents an unrevealed empty square, <br />
                'B' represents a revealed blank square that has no adjacent
                mines (i.e., above, below, left, right, and all 4 diagonals),
                digit ('1' to '8') represents how many mines are adjacent to
                this revealed square, and
                <br />
                'X' represents a revealed mine.
                <br />
                You are also given an integer array click where click = [clickr,
                clickc] represents the next click position among all the
                unrevealed squares ('M' or 'E').
                <br />
                Return the board after revealing this position according to the
                following rules:
                <br />
                1. If a mine 'M' is revealed, then the game is over. You should
                change it to 'X'.
                <br />
                2. If an empty square 'E' with no adjacent mines is revealed,
                then change it to a revealed blank 'B' and all of its adjacent
                unrevealed squares should be revealed recursively.
                <br />
                3. If an empty square 'E' with at least one adjacent mine is
                revealed, then change it to a digit ('1' to '8') representing
                the number of adjacent mines.
                <br />
                4. Return the board when no more squares will be revealed.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: board = [["E","E","E","E","E"], <br />
                    ["E","E","M","E","E"], <br />
                    ["E","E","E","E","E"], <br />
                    ["E","E","E","E","E"]], click = [3,0]
                    <br />
                    Output: [["B","1","E","1","B"], <br />
                    ["B","1","M","1","B"], <br />
                    ["B","1","1","1","B"], <br />
                    ["B","B","B","B","B"]]
                  </>
                ),
              },
              {
                img: Leetcode529,
                content: (
                  <>
                    Input: board = [["B","1","E","1","B"],
                    <br />
                    ["B","1","M","1","B"],
                    <br />
                    ["B","1","1","1","B"],
                    <br />
                    ["B","B","B","B","B"]],
                    <br /> click = [1,2]
                    <br /> Output: [["B","1","E","1","B"],
                    <br />
                    ["B","1","X","1","B"],
                    <br />
                    ["B","1","1","1","B"],
                    <br />
                    ["B","B","B","B","B"]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == board.length
                <br />
                n == board[i].length
                <br />
                1 &lt;= m, n &lt;== 50
                <br />
                board[i][j] is either 'M', 'E', 'B', or a digit from '1' to '8'.
                <br />
                click.length == 2<br />
                0 &lt;== clickr &lt;= m<br />
                0 &lt;== clickc &lt;= n<br />
                board[clickr][clickc] is either 'M' or 'E'.
              </>
            }
            tc="m.n"
            sc="m.n"
            codes={{
              Javascript: {
                code: `/**
                * @param {character[][]} board
                * @param {number[]} click
                * @return {character[][]}
                */
               
                 const dirs = [[-1, -1], [-1, 0], [-1, 1], [0, -1],
                 [0, 1], [1, -1], [1, 0], [1, 1]];
               
               function dfs(board, i, j) {
                   if (i < 0 || i == board.length || j < 0 || j == board[0].length)
                     return;
                   if (board[i][j] != 'E')
                     return;
                   let minesCount = getMinesCount(board, i, j);
                   board[i][j] = minesCount == 0 ? 'B' : ''+minesCount;
                   if (minesCount == 0)
                     for (let dir of dirs)
                       dfs(board, i + dir[0], j + dir[1]);
                 }
               
                 function getMinesCount(board, i, j) {
                   let minesCount = 0;
                   for (let dir of dirs) {
                     let x = i + dir[0];
                     let y = j + dir[1];
                     if (x < 0 || x == board.length || y < 0 || y == board[0].length)
                       continue;
                     if (board[x][y] == 'M')
                       ++minesCount;
                   }
                   return minesCount;
                 }
               
               
               var updateBoard = function(board, click) {
                    if (board[click[0]][click[1]] == 'M') {
                     board[click[0]][click[1]] = 'X';
                     return board;
                   }
                   dfs(board, click[0], click[1]);
                   return board;
               };
               
               console.log(updateBoard([["E","E","E","E","E"],["E","E","M","E","E"],["E","E","E","E","E"],["E","E","E","E","E"]],[3,0]))`,
                output: `[
                  [ 'B', '1', 'E', '1', 'B' ],
                  [ 'B', '1', 'M', '1', 'B' ],
                  [ 'B', '1', '1', '1', 'B' ],
                  [ 'B', 'B', 'B', 'B', 'B' ]
                ]`,
              },
            }}
          />
        ),
      },
      q448: {
        title: "Q530. Minimum Absolute Difference in BST (Q448)",
        content: (
          <Comp
            title="Q530. Minimum Absolute Difference in BST (Q448)"
            content1={
              <>
                Given the root of a Binary Search Tree (BST), return the minimum
                absolute difference between the values of any two different
                nodes in the tree.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode530,
                content: (
                  <>
                    Input: root = [4,2,6,1,3] <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1,0,48,null,null,12,49] <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [2, 104].
                <br /> 0 &lt;= Node.val &lt;= 105
              </>
            }
            tc="n"
            sc="log n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
              }
              
              /**
               * @param {TreeNode} root
               * @return {number}
               */
              var getMinimumDifference = function(root) {
                let res = Number.MAX_SAFE_INTEGER;
                let prev = -1;
                const stack = [];
              
                while (root || stack.length) {
                  while (root) {
                    stack.push(root);
                    root = root.left;
                  }
                  root = stack.pop();
                  if (prev >= 0)
                    res = Math.min(res, root.val - prev);
                  prev = root.val;
                  root = root.right;
                }
                return res;
              };
              
              const t = new TreeNode(4)
              t.left = new TreeNode(2)
              t.left.left = new TreeNode(1)
              t.left.right = new TreeNode(3)
              t.right = new TreeNode(6)
              console.log(getMinimumDifference(t))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q449: {
        title: "Q532. K-diff Pairs in an Array (Q449)",
        content: (
          <Comp
            title="Q532. K-diff Pairs in an Array (Q449)"
            content1={
              <>
                Given an array of integers nums and an integer k, return the
                number of unique k-diff pairs in the array.
                <br />
                A k-diff pair is an integer pair (nums[i], nums[j]), where the
                following are true:
                <br />
                0 &lt;= i &lt; j &lt; nums.length <br />
                |nums[i] - nums[j]| == k <br />
                Notice that |val| denotes the absolute value of val.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [3,1,4,1,5], k = 2
                    <br /> Output: 2
                    <br /> Explanation: There are two 2-diff pairs in the array,
                    (1, 3) and (3, 5). Although we have two 1s in the input, we
                    should only return the number of unique pairs.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4,5], k = 1
                    <br /> Output: 4
                    <br /> Explanation: There are four 1-diff pairs in the
                    array, (1, 2), (2, 3), (3, 4) and (4, 5).
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,3,1,5,4], k = 0
                    <br /> Output: 1
                    <br /> Explanation: There is one 0-diff pair in the array,
                    (1, 1).
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4 <br />
                -107 &lt;= nums[i] &lt;= 10^7 <br />0 &lt;= k &lt;= 107
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var findPairs = function(nums, k) {
                 let res = 0;
                 const m = {};
                 for (let i = 0; i < nums.length; ++i)
                   m[nums[i]] = i;
                 for(let i = 0; i < nums.length; ++i) {
                   const target = nums[i] + k;
                   if (target in m && m[target] != i) {
                     ++res;
                     delete m[target]
                   }
                 }
                 return res;
               };
               
               console.log(findPairs([3,1,4,1,5],2))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q450: {
        title: "Q535. Encode and Decode TinyURL (Q450)",
        content: (
          <Comp
            title="Q535. Encode and Decode TinyURL (Q450)"
            content1={
              <>
                Note: This is a companion problem to the System Design problem:
                Design TinyURL.
                <br /> TinyURL is a URL shortening service where you enter a URL
                such as https://leetcode.com/problems/design-tinyurl and it
                returns a short URL such as http://tinyurl.com/4e9iAk. Design a
                class to encode a URL and decode a tiny URL.
                <br />
                There is no restriction on how your encode/decode algorithm
                should work. You just need to ensure that a URL can be encoded
                to a tiny URL and the tiny URL can be decoded to the original
                URL.
                <br />
                Implement the Solution class:
                <br />
                Solution() Initializes the object of the system.
                <br /> String encode(String longUrl) Returns a tiny URL for the
                given longUrl.
                <br /> String decode(String shortUrl) Returns the original long
                URL for the given shortUrl. It is guaranteed that the given
                shortUrl was encoded by the same object.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: url = "https://leetcode.com/problems/design-tinyurl"
                    <br /> Output:
                    "https://leetcode.com/problems/design-tinyurl"
                    <br />
                    Explanation:
                    <br /> Solution obj = new Solution();
                    <br /> string tiny = obj.encode(url); // returns the encoded
                    tiny url.
                    <br /> string ans = obj.decode(tiny); // returns the
                    original url after deconding it.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= url.length &lt;= 10^4 <br />
                url is guranteed to be a valid URL.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `public class Codec {
                  public String encode(String longUrl) {
                    while (!urlToCode.containsKey(longUrl)) {
                      StringBuilder sb = new StringBuilder();
                      for (int i = 0; i < 6; ++i) {
                        final char nextChar = alphabets.charAt(rand.nextInt(alphabets.length()));
                        sb.append(nextChar);
                      }
                      final String code = sb.toString();
                      if (!codeToUrl.containsKey(code)) {
                        codeToUrl.put(code, longUrl);
                        urlToCode.put(longUrl, code);
                        return "http://tinyurl.com/" + code;
                      }
                    }
                
                    throw new IllegalArgumentException();
                  }
                
                  public String decode(String shortUrl) {
                    return codeToUrl.get(shortUrl.substring(19));
                  }
                
                  private static final String alphabets =
                      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                  private Map<String, String> urlToCode = new HashMap<>();
                  private Map<String, String> codeToUrl = new HashMap<>();
                  private Random rand = new Random();
                }
                `,
                output: `"https://leetcode.com/problems/design-tinyurl"`,
              },
            }}
          />
        ),
      },
      q451: {
        title: "Q537. Complex Number Multiplication (Q451)",
        content: (
          <Comp
            title="Q537. Complex Number Multiplication (Q451)"
            content1={
              <>
                A complex number can be represented as a string on the form
                "real+imaginaryi" where:
                <br />
                real is the real part and is an integer in the range [-100,
                100].
                <br />
                imaginary is the imaginary part and is an integer in the range
                [-100, 100].
                <br />
                i2 == -1.
                <br />
                Given two complex numbers num1 and num2 as strings, return a
                string of the complex number that represents their
                multiplications.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: num1 = "1+1i", num2 = "1+1i"
                    <br />
                    Output: "0+2i"
                    <br />
                    Explanation: (1 + i) * (1 + i) = 1 + i2 + 2 * i = 2i, and
                    you need convert it to the form of 0+2i.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num1 = "1+-1i", num2 = "1+-1i"
                    <br />
                    Output: "0+-2i"
                    <br />
                    Explanation: (1 - i) * (1 - i) = 1 + i2 - 2 * i = -2i, and
                    you need convert it to the form of 0+-2i.
                  </>
                ),
              },
            ]}
            constraints={<>num1 and num2 are valid complex numbers.</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} num1
                * @param {string} num2
                * @return {string}
                */
               
               function getRealAndImag(s) {
                 let real = s.substring(0, s.indexOf('+'));
                 let imag = s.substring(s.indexOf('+') + 1, s.length - 1);
                 return [+real, +imag];
               }
               
               var complexNumberMultiply = function(num1, num2) {
                 let a = getRealAndImag(num1);
                 let b = getRealAndImag(num2);
                 return [a[0] * b[0] - a[1] * b[1]] + "+" +
                     [a[0] * b[1] + a[1] * b[0]] + "i";
               };
               
               console.log(complexNumberMultiply("1+1i","1+1i"))`,
                output: `0+2i`,
              },
            }}
          />
        ),
      },
      q452: {
        title: "Q538. Convert BST to Greater Tree (Q452)",
        content: (
          <Comp
            title="Q538. Convert BST to Greater Tree (Q452)"
            content1={
              <>
                Given the root of a Binary Search Tree (BST), convert it to a
                Greater Tree such that every key of the original BST is changed
                to the original key plus the sum of all keys greater than the
                original key in BST.
                <br />
                As a reminder, a binary search tree is a tree that satisfies
                these constraints:
                <br />
                The left subtree of a node contains only nodes with keys less
                than the node's key.
                <br />
                The right subtree of a node contains only nodes with keys
                greater than the node's key.
                <br />
                Both the left and right subtrees must also be binary search
                trees.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode538,
                content: (
                  <>
                    Input: root =
                    [4,1,6,0,2,5,7,null,null,null,3,null,null,null,8] <br />
                    Output:
                    [30,36,21,36,35,26,15,null,null,null,33,null,null,null,8]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [0,null,1] <br />
                    Output: [1,null,1]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [0, 10^4].{" "}
                <br />
                -10^4 &lt;= Node.val &lt;= 10^4 <br />
                All the values in the tree are unique. <br />
                root is guaranteed to be a valid binary search tree.
              </>
            }
            tc="n"
            sc="log n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root
                 * @return {TreeNode}
                 */
                var convertBST = function(root) {
                  var prefix = {val:0}
                  reversedInorder(root,prefix);
                  return root;
                };
                
                function reversedInorder(root,prefix) {
                  if (!root)
                    return;
                  reversedInorder(root.right,prefix);
                  prefix.val += root.val;
                  root.val = prefix.val;
                  reversedInorder(root.left,prefix);
                }
                
                const t = new TreeNode(0)
                t.right = new TreeNode(1)
                console.log(convertBST(t))`,
                output: `TreeNode {
                  val: 1,
                  left: null,
                  right: TreeNode { val: 1, left: null, right: null }
                }`,
              },
            }}
          />
        ),
      },
      q453: {
        title: "Q539. Minimum Time Difference (Q453)",
        content: (
          <Comp
            title="Q539. Minimum Time Difference (Q453)"
            content1={
              <>
                Given a list of 24-hour clock time points in "HH:MM" format,
                return the minimum minutes difference between any two
                time-points in the list.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: timePoints = ["23:59","00:00"]
                    <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: timePoints = ["00:00","23:59","00:00"]
                    <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= timePoints.length &lt;= 2 * 10^4 <br />
                timePoints[i] is in the format "HH:MM".
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} timePoints
                * @return {number}
                */
               var findMinDifference = function(timePoints) {
                 let res = 24 * 60;
                 let first = 24 * 60;
                 const bucket = Array(24 * 60).fill(false);
                 for (let timePoint of timePoints) {
                   const num =
                       +timePoint.substring(0, 2) * 60 + +timePoint.substring(3);
                   first = Math.min(first, num);
                   if (bucket[num])
                     return 0;
                   bucket[num] = true;
                 }
                 let prev = first;
                 for (let i = first + 1; i < bucket.length; ++i)
                   if (bucket[i]) {
                     res = Math.min(res, i - prev);
                     prev = i;
                   }
                 return Math.min(res, 24 * 60 - prev + first);    
               };
               
               console.log(findMinDifference(["23:59","00:00"]))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q454: {
        title: "Q540. Single Element in a Sorted Array (Q454)",
        content: (
          <Comp
            title="Q540. Single Element in a Sorted Array (Q454)"
            content1={
              <>
                You are given a sorted array consisting of only integers where
                every element appears exactly twice, except for one element
                which appears exactly once.
                <br />
                Return the single element that appears only once.
                <br />
                Your solution must run in O(log n) time and O(1) space.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,1,2,3,3,4,4,8,8] <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [3,3,7,7,10,11,11] <br />
                    Output: 10
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^5 &lt; <br />0 &lt;= nums[i] &lt;=
                105
              </>
            }
            tc="log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var singleNonDuplicate = function(nums) {
                 let l = 0;
                 let r = nums.length - 1;
                 while (l < r) {
                   let m = l + parseInt((r - l) / 2);
                   if (m % 2 == 1)
                     --m;
                   if (nums[m] == nums[m + 1])
                     l = m + 2;
                   else
                     r = m;
                 }
                 return nums[l]; 
               };
               
               console.log(singleNonDuplicate([3,3,7,7,10,11,11]))`,
                output: `10`,
              },
            }}
          />
        ),
      },
      q455: {
        title: "Q541. Reverse String II (Q455)",
        content: (
          <Comp
            title="Q541. Reverse String II (Q455)"
            content1={
              <>
                Given a string s and an integer k, reverse the first k
                characters for every 2k characters counting from the start of
                the string.
                <br />
                If there are fewer than k characters left, reverse all of them.
                If there are less than 2k but greater than or equal to k
                characters, then reverse the first k characters and leave the
                other as original.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "abcdefg", k = 2<br />
                    Output: "bacdfeg"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "abcd", k = 2<br />
                    Output: "bacd"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^4 <br />
                s consists of only lowercase English letters.
                <br />1 &lt;= k &lt;= 10^4
              </>
            }
            tc="n^2"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @param {number} k
                * @return {string}
                */
               var reverseStr = function(s, k) {
                 const st = s.split("")
                 for (let i = 0; i < st.length; i += 2 * k) {
                   let l = i;
                   let r = Math.min(i + k - 1, st.length - 1);
                   while (l < r) {
                     st[l] = s[r];
                     st[r] = s[l];
                     ++l;
                     --r;
                   }
                 }
                 return st.join("")
               };
               
               console.log(reverseStr("abcdefg",2))`,
                output: `bacdfeg`,
              },
            }}
          />
        ),
      },
      q456: {
        title: "Q542. 01 Matrix (Q456)",
        content: (
          <Comp
            title="Q542. 01 Matrix (Q456)"
            content1={
              <>
                Given an m x n binary matrix mat, return the distance of the
                nearest 0 for each cell.
                <br />
                The distance between two adjacent cells is 1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: mat = <br />
                    [[0,0,0],[ <br />
                    0,1,0], <br />
                    [0,0,0]] <br />
                    Output: <br /> [[0,0,0], <br />
                    [0,1,0], <br />
                    [0,0,0]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: mat = <br />
                    [[0,0,0], <br />
                    [0,1,0], <br />
                    [1,1,1]] <br />
                    Output: <br /> [[0,0,0], <br />
                    [0,1,0], <br />
                    [1,2,1]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == mat.length
                <br />
                n == mat[i].length
                <br />
                1 &lt;= m, n &lt;= 10^4
                <br />
                1 &lt;= m * n &lt;= 10^4
                <br />
                mat[i][j] is either 0 or 1.
                <br />
                There is at least one 0 in mat.
              </>
            }
            tc="m.n"
            sc="m.n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} mat
                * @return {number[][]}
                */
               var updateMatrix = function(mat) {
                 let m = mat.length;
                 let n = mat[0].length;
                 const dirs = [0, 1, 0, -1, 0];
                 const q = [];
                 const seen = Array.from(Array(m),()=>Array(n).fill(0));
                 for (let i = 0; i < m; ++i)
                   for (let j = 0; j < n; ++j)
                     if (mat[i][j] == 0) {
                       q.push([i, j]);
                       seen[i][j] = true;
                     }
                 while (q.length) {
                   const [ i, j ] = q.shift()
                   for (let k = 0; k < 4; ++k) {
                     let x = i + dirs[k];
                     let y = j + dirs[k + 1];
                     if (x < 0 || x == m || y < 0 || y == n)
                       continue;
                     if (seen[x][y])
                       continue;
                     mat[x][y] = mat[i][j] + 1;
                     q.push([x, y]);
                     seen[x][y] = true;
                   }
                 }
                 return mat;    
               };
               
               console.log(updateMatrix([[0,0,0],[0,1,0],[1,1,1]]))`,
                output: `[ [ 0, 0, 0 ], [ 0, 1, 0 ], [ 1, 2, 1 ] ]`,
              },
            }}
          />
        ),
      },
      q457: {
        title: "Q543. Diameter of Binary Tree (Q457)",
        content: (
          <Comp
            title="Q543. Diameter of Binary Tree (Q457)"
            content1={
              <>
                Given the root of a binary tree, return the length of the
                diameter of the tree.
                <br />
                The diameter of a binary tree is the length of the longest path
                between any two nodes in a tree. This path may or may not pass
                through the root.
                <br />
                The length of a path between two nodes is represented by the
                number of edges between them.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode543,
                content: (
                  <>
                    Input: root = [1,2,3,4,5]
                    <br />
                    Output: 3<br />
                    Explanation: 3 is the length of the path [4,2,1,3] or
                    [5,2,1,3].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1,2]
                    <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 104].
                <br />
                -100 &lt;= Node.val &lt;= 100
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root
                 * @return {number}
                 */
                var diameterOfBinaryTree = function(root) {
                  let res = {val:0}
                  maxDepth(root,res);
                  return res.val;
                }
                
                function maxDepth(root,res) {
                  if(!root) return 0;
                  let l = maxDepth(root.left,res);
                  let r = maxDepth(root.right,res);
                  res.val = Math.max(res.val, l + r);
                  return 1 + Math.max(l, r);
                }
                
                const t = new TreeNode(1)
                t.left = new TreeNode(2)
                t.left.left = new TreeNode(4)
                t.left.right = new TreeNode(5)
                t.right = new TreeNode(3)
                console.log(diameterOfBinaryTree(t))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q458: {
        title: "Q546. Remove Boxes (Q458)",
        content: (
          <Comp
            title="Q546. Remove Boxes (Q458)"
            content1={
              <>
                You are given several boxes with different colors represented by
                different positive numbers.
                <br />
                You may experience several rounds to remove boxes until there is
                no box left. Each time you can choose some continuous boxes with
                the same color (i.e., composed of k boxes, k &gt;= 1), remove
                them and get k * k points.
                <br />
                Return the maximum points you can get.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: boxes = [1,3,2,2,2,3,4,3,1]
                    <br />
                    Output: 23
                    <br />
                    Explanation:
                    <br />
                    [1, 3, 2, 2, 2, 3, 4, 3, 1] <br />
                    ----&gt; [1, 3, 3, 4, 3, 1] (3*3=9 points) <br />
                    ----&gt; [1, 3, 3, 3, 1] (1*1=1 points) <br />
                    ----&gt; [1, 1] (3*3=9 points) <br />
                    ----&gt; [] (2*2=4 points)
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: boxes = [1,1,1]
                    <br />
                    Output: 9
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: boxes = [1]
                    <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= boxes.length &lt;= 100 <br />1 &lt;= boxes[i] &lt;= 100
              </>
            }
            tc="n^4"
            sc="n^3"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} boxes
                * @return {number}
                */
               var removeBoxes = function(boxes) {
                 const n = boxes.length;
                 const dp = Array.from(Array(n),()=>
                 Array.from(Array(n),()=> Array(n).fill(0)
                 ));
                 return helper(boxes, 0, n - 1, 0,dp);
               };
               
               function helper(boxes,i, j, k, dp) {
                 if (i > j)
                   return 0;
                 if (dp[i][j][k] > 0)
                   return dp[i][j][k];
                 let r = j;
                 let sameBoxes = k + 1;
                 while (r > 0 && boxes[r - 1] == boxes[r]) {
                   --r;
                   ++sameBoxes;
                 }
                 dp[i][j][k] = helper(boxes, i, r - 1, 0,dp) + sameBoxes * sameBoxes;
                 for (let p = i; p < r; ++p)
                   if (boxes[p] == boxes[r])
                     dp[i][j][k] = Math.max(dp[i][j][k], helper(boxes, i, p, sameBoxes,dp) +
                                                             helper(boxes, p + 1, r - 1, 0,dp));
                 return dp[i][j][k];
               }
               
               console.log(removeBoxes([1,3,2,2,2,3,4,3,1]))`,
                output: `23`,
              },
            }}
          />
        ),
      },
      q459: {
        title: "Q547. Number of Provinces (Q459)",
        content: (
          <Comp
            title="Q547. Number of Provinces (Q459)"
            content1={
              <>
                There are n cities. Some of them are connected, while some are
                not. If city a is connected directly with city b, and city b is
                connected directly with city c, then city a is connected
                indirectly with city c.
                <br />
                A province is a group of directly or indirectly connected cities
                and no other cities outside of the group.
                <br />
                You are given an n x n matrix isConnected where
                isConnected[i][j] = 1 if the ith city and the jth city are
                directly connected, and isConnected[i][j] = 0 otherwise.
                <br />
                Return the total number of provinces.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode547,
                content: (
                  <>
                    Input: isConnected = [[1,1,0],[1,1,0],[0,0,1]]
                    <br /> Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: isConnected = [[1,0,0],[0,1,0],[0,0,1]]
                    <br /> Output: 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 200
                <br />
                n == isConnected.length
                <br />
                n == isConnected[i].length
                <br />
                isConnected[i][j] is 1 or 0.
                <br />
                isConnected[i][i] == 1<br />
                isConnected[i][j] == isConnected[j][i]
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `
                // [[1,1,0],[1,1,0],[0,0,1]]
                class UF {
                  public UF(int n) {
                    size = n;
                    id = new int[n];
                    for (int i = 0; i < n; ++i)
                      id[i] = i;
                  }
                
                  public void union(int u, int v) {
                    final int i = find(u);
                    final int j = find(v);
                    if (i == j)
                      return;
                    id[i] = j;
                    --size;
                  }
                
                  public int getSize() {
                    return size;
                  }
                
                  private int size;
                  private int[] id;
                
                  private int find(int u) {
                    return id[u] == u ? u : (id[u] = find(id[u]));
                  }
                }
                
                class Solution {
                  public int findCircleNum(int[][] M) {
                    final int n = M.length;
                    UF uf = new UF(n);
                
                    for (int i = 0; i < n; ++i)
                      for (int j = i; j < n; ++j)
                        if (M[i][j] == 1)
                          uf.union(i, j);
                
                    return uf.getSize();
                  }
                }
                `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q460: {
        title: "Q551. Student Attendance Record I (Q460)",
        content: (
          <Comp
            title="Q551. Student Attendance Record I (Q460)"
            content1={
              <>
                You are given a string s representing an attendance record for a
                student where each character signifies whether the student was
                absent, late, or present on that day. The record only contains
                the following three characters:
                <br />
                'A': Absent. <br />
                'L': Late. <br />
                'P': Present. <br />
                The student is eligible for an attendance award if they meet
                both of the following criteria:
                <br />
                The student was absent ('A') for strictly fewer than 2 days
                total. <br />
                The student was never late ('L') for 3 or more consecutive days.{" "}
                <br />
                Return true if the student is eligible for an attendance award,
                or false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "PPALLP"
                    <br />
                    Output: true
                    <br />
                    Explanation: The student has fewer than 2 absences and was
                    never late 3 or more consecutive days.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "PPALLL"
                    <br />
                    Output: false
                    <br />
                    Explanation: The student was late 3 consecutive days in the
                    last 3 days, so is not eligible for the award.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 1000 <br />
                s[i] is either 'A', 'L', or 'P'.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {boolean}
                */
               var checkRecord = function(s) {
                 return s.indexOf("A") == s.lastIndexOf("A") && !s.includes("LLL");  
               };
               
               console.log(checkRecord("PPALLP"))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q461: {
        title: "Q552. Student Attendance Record II (Q461)",
        content: (
          <Comp
            title="Q552. Student Attendance Record II (Q461)"
            content1={
              <>
                An attendance record for a student can be represented as a
                string where each character signifies whether the student was
                absent, late, or present on that day. The record only contains
                the following three characters:
                <br />
                'A': Absent.
                <br />
                'L': Late.
                <br />
                'P': Present.
                <br />
                Any student is eligible for an attendance award if they meet
                both of the following criteria:
                <br />
                The student was absent ('A') for strictly fewer than 2 days
                total.
                <br />
                The student was never late ('L') for 3 or more consecutive days.
                <br />
                Given an integer n, return the number of possible attendance
                records of length n that make a student eligible for an
                attendance award. The answer may be very large, so return it
                modulo 10^9 + 7.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 2<br />
                    Output: 8<br />
                    Explanation: There are 8 records with length 2 that are
                    eligible for an award:
                    <br />
                    "PP", "AP", "PA", "LP", "PL", "AL", "LA", "LL"
                    <br />
                    Only "AA" is not eligible because there are 2 absences
                    (there need to be fewer than 2).
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1<br />
                    Output: 3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 10101 <br />
                    Output: 183236316
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 10^5</>}
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `
                // 2
                class Solution {
                  public int checkRecord(int n) {
                    final long kMod = (long) 1e9 + 7;
                    long[][] dp = new long[2][3];
                    dp[0][0] = 1;
                
                    while (n-- > 0) {
                      long[][] prev = Arrays.stream(dp)
                                          .map((long[] A) -> A.clone())
                                          .toArray((int length) -> new long[length][]);
                
                      dp[0][0] = (prev[0][0] + prev[0][1] + prev[0][2]) % kMod;
                      dp[0][1] = prev[0][0];
                      dp[0][2] = prev[0][1];
                      dp[1][0] = (prev[0][0] + prev[0][1] + prev[0][2] +
                                  prev[1][0] + prev[1][1] + prev[1][2]) % kMod;
                      dp[1][1] = prev[1][0];
                      dp[1][2] = prev[1][1];
                    }
                
                    return (int) ((dp[0][0] + dp[0][1] + dp[0][2] + dp[1][0] + dp[1][1] + dp[1][2]) % kMod);
                  }
                }
                `,
                output: `8`,
              },
            }}
          />
        ),
      },
      q462: {
        title: "Q553. Optimal Division (Q462)",
        content: (
          <Comp
            title="Q553. Optimal Division (Q462)"
            content1={
              <>
                You are given an integer array nums. The adjacent integers in
                nums will perform the float division.
                <br />
                For example, for nums = [2,3,4], we will evaluate the expression
                "2/3/4".
                <br />
                However, you can add any number of parenthesis at any position
                to change the priority of operations. You want to add these
                parentheses such the value of the expression after the
                evaluation is maximum.
                <br />
                Return the corresponding expression that has the maximum value
                in string format.
                <br />
                Note: your expression should not contain redundant parenthesis.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1000,100,10,2]
                    <br />
                    Output: "1000/(100/10/2)"
                    <br />
                    Explanation:
                    <br />
                    1000/(100/10/2) = 1000/((100/10)/2) = 200
                    <br />
                    However, the bold parenthesis in "1000/((100/10)/2)" are
                    redundant, since they don't influence the operation
                    priority. So you should return "1000/(100/10/2)".
                    <br /> Other cases:
                    <br />
                    1000/(100/10)/2 = 50
                    <br />
                    1000/(100/(10/2)) = 50
                    <br />
                    1000/100/10/2 = 0.5
                    <br />
                    1000/100/(10/2) = 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2]
                    <br />
                    Output: "2"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2]
                    <br />
                    Output: "2"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10 <br />
                2 &lt;= nums[i] &lt;= 1000 <br />
                There is only one optimal division for the given iput.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {string}
                */
               var optimalDivision = function(nums) {
                 let res = nums[0]
                 if(nums.length == 1)
                   return String(res)
                 if(nums.length == 2)
                   return res + '/' + nums[1]
                 res += '/(' + nums[1]
                 for(let i=2;i<nums.length;i++)
                   res += '/' + nums[i]
                 res += ')'
                 return String(res)
               };
               
               console.log(optimalDivision([1000,100,10,2]))`,
                output: `1000/(100/10/2)`,
              },
            }}
          />
        ),
      },
      q463: {
        title: "Q554. Brick Wall (Q463)",
        content: (
          <Comp
            title="Q554. Brick Wall (Q463)"
            content1={
              <>
                There is a rectangular brick wall in front of you with n rows of
                bricks. The ith row has some number of bricks each of the same
                height (i.e., one unit) but they can be of different widths. The
                total width of each row is the same.
                <br />
                Draw a vertical line from the top to the bottom and cross the
                least bricks. If your line goes through the edge of a brick,
                then the brick is not considered as crossed. You cannot draw a
                line just along one of the two vertical edges of the wall, in
                which case the line will obviously cross no bricks.
                <br />
                Given the 2D array wall that contains the information about the
                wall, return the minimum number of crossed bricks after drawing
                such a vertical line.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode554,
                content: (
                  <>
                    Input: wall =
                    [[1,2,2,1],[3,1,2],[1,3,2],[2,4],[3,1,2],[1,3,1,1]]
                    <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: wall = [[1],[1],[1]]
                    <br />
                    Output: 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == wall.length
                <br />
                1 &lt;= n &lt;= 10^4
                <br />
                1 &lt;= wall[i].length &lt;= 10^4
                <br />
                1 &lt;= sum(wall[i].length) &lt;= 2 * 10^4
                <br />
                sum(wall[i]) is the same for each row i.
                <br />1 &lt;= wall[i][j] &lt;= 2^31 - 1
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} wall
                * @return {number}
                */
               var leastBricks = function(wall) {
                 let maxFreq = 0;
                 const count = new Map();
                 for (let row of wall) {
                   let prefix = 0;
                   for (let i = 0; i < row.length - 1; ++i) {
                     prefix += row[i];
                     count.set(prefix, (count.get(prefix) || 0) + 1);
                     maxFreq = Math.max(maxFreq, count.get(prefix));
                   }
                 }
                 return wall.length - maxFreq;
               };
               
               console.log(leastBricks([[1,2,2,1],[3,1,2],[1,3,2],[2,4],[3,1,2],[1,3,1,1]]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q464: {
        title: "Q556. Next Greater Element III (Q464)",
        content: (
          <Comp
            title="Q556. Next Greater Element III (Q464)"
            content1={
              <>
                Given a positive integer n, find the smallest integer which has
                exactly the same digits existing in the integer n and is greater
                in value than n. If no such positive integer exists, return -1.
                <br />
                ould fit in 32-bit integer, if there is a valid answer but it
                does not fit in 32-bit integer, return -1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 12 <br />
                    Output: 21
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 21 <br />
                    Output: -1
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 2^31 - 1</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               var nextGreaterElement = function(n) {
                 let s = nextPermutation(String(n).split(""));
                 let ans = +s.join("");
                 if(ans>Math.pow(2,31)-1||ans<=n) return -1
                 return ans;  
               };
               
               function nextPermutation(s) {
                 const n = s.length;
                 let i;
                 for (i = n - 2; i >= 0; --i)
                   if (s[i] < s[i + 1])
                     break;
                 if (i >= 0) {
                   for (let j = n - 1; j > i; --j)
                     if (s[j] > s[i]) {
                       swap(s,i,j)
                       break;
                     }
                 }
                 reverse(s, i + 1, n - 1);
                 return s;
               }
               
               function reverse(s, l, r) {
                 while (l < r){
                   swap(s,l++,r--)
                 }
               }
               
               function swap(s, i, j) {
                 let temp = s[i];
                 s[i] = s[j];
                 s[j] = temp;
               }
               
               console.log(nextGreaterElement(21))`,
                output: `-1`,
              },
            }}
          />
        ),
      },
      q465: {
        title: "Q557. Reverse Words in a String III (Q465)",
        content: (
          <Comp
            title="Q557. Reverse Words in a String III (Q465)"
            content1={
              <>
                Given a string s, reverse the order of characters in each word
                within a sentence while still preserving whitespace and initial
                word order.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "Let's take LeetCode contest"
                    <br />
                    Output: "s'teL ekat edoCteeL tsetnoc"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "Let's take LeetCode contest"
                    <br />
                    Output: "s'teL ekat edoCteeL tsetnoc"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 5 * 10^4
                <br />
                s contains printable ASCII characters. <br />
                s does not contain any leading or trailing spaces.
                <br />
                There is at least one word in s.
                <br />
                All the words in s are separated by a single space.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {string}
                */
               var reverseWords = function(s) {
                 const st = s.split("");
                 let i = 0;
                 let j = 0;
                 while (i < st.length) {
                   while (i < j || i < st.length && st[i] == ' ')
                     ++i;
                   while (j < i || j < st.length && st[j] != ' ')
                     ++j;
                   reverse(st, i, j - 1);
                 }
                 return st.join("");
               };
               
               function reverse(st, l, r) {
                 while (l < r) {
                 let temp = st[l];
                 st[l++] = st[r];
                 st[r--] = temp;
                 }
               }
               
               console.log(reverseWords("God Ding"))`,
                output: `doG gniD`,
              },
            }}
          />
        ),
      },
      q466: {
        title:
          "Q558. Logical OR of Two Binary Grids Represented as Quad-Trees (Q466)",
        content: (
          <Comp
            title="Q558. Logical OR of Two Binary Grids Represented as Quad-Trees (Q466)"
            content1={
              <>
                A Binary Matrix is a matrix in which all the elements are either
                0 or 1.
                <br />
                Given quadTree1 and quadTree2. quadTree1 represents a n * n
                binary matrix and quadTree2 represents another n * n binary
                matrix.
                <br />
                Return a Quad-Tree representing the n * n binary matrix which is
                the result of logical bitwise OR of the two binary matrixes
                represented by quadTree1 and quadTree2.
                <br />
                Notice that you can assign the value of a node to True or False
                when isLeaf is False, and both are accepted in the answer.
                <br />
                A Quad-Tree is a tree data structure in which each internal node
                has exactly four children. Besides, each node has two
                attributes:
                <br />
                val: True if the node represents a grid of 1's or False if the
                node represents a grid of 0's.
                <br />
                isLeaf: True if the node is leaf node on the tree or False if
                the node has the four children.
                <br />
                <pre>{`
class Node {
  public boolean val;
  public boolean isLeaf;
  public Node topLeft;
  public Node topRight;
  public Node bottomLeft;
  public Node bottomRight;
}
`}</pre>
                We can construct a Quad-Tree from a two-dimensional area using
                the following steps:
                <br />
                1. If the current grid has the same value (i.e all 1's or all
                0's) set isLeaf True and set val to the value of the grid and
                set the four children to Null and stop.
                <br />
                2. If the current grid has different values, set isLeaf to False
                and set val to any value and divide the current grid into four
                sub-grids as shown in the photo.
                <br />
                Recurse for each of the children with the proper sub-grid.
              </>
            }
            content2={
              <>
                If you want to know more about the Quad-Tree, you can refer to
                the wiki.
                <br />
                Quad-Tree format:
                <br />
                The input/output represents the serialized format of a Quad-Tree
                using level order traversal, where null signifies a path
                terminator where no node exists below.
                <br />
                It is very similar to the serialization of the binary tree. The
                only difference is that the node is represented as a list
                [isLeaf, val].
                <br />
                If the value of isLeaf or val is True we represent it as 1 in
                the list [isLeaf, val] and if the value of isLeaf or val is
                False we represent it as 0.
              </>
            }
            img={Leetcode558}
            examples={[
              {
                content: (
                  <>
                    Input: quadTree1 = [[0,1],[1,1],[1,1],[1,0],[1,0]] <br />
                    , quadTree2 =
                    [[0,1],[1,1],[0,1],[1,1],[1,0],null,null,null,null,[1,0],[1,0],[1,1],[1,1]]
                    <br /> Output: [[0,0],[1,1],[1,1],[1,1],[1,0]]
                    <br /> Explanation: quadTree1 and quadTree2 are shown above.
                    You can see the binary matrix which is represented by each
                    Quad-Tree. If we apply logical bitwise OR on the two binary
                    matrices we get the binary matrix below which is represented
                    by the result Quad-Tree. Notice that the binary matrices
                    shown are only for illustration, you don't have to construct
                    the binary matrix to get the result tree.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: quadTree1 = [[1,0]], quadTree2 = [[1,0]] <br />
                    Output: [[1,0]] <br />
                    Explanation: Each tree represents a binary matrix of size
                    1*1. Each matrix contains only zero. The resulting matrix is
                    of size 1*1 with also zero.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                quadTree1 and quadTree2 are both valid Quad-Trees each
                representing a n * n grid.
                <br /> n == 2x where 0 &lt;= x &lt;= 9.
              </>
            }
            tc="n^2.log4 n"
            sc="log4 n"
            codes={{
              Javas: {
                code: `// class Node {
                  //     public boolean val;
                  //     public boolean isLeaf;
                  //     public Node topLeft;
                  //     public Node topRight;
                  //     public Node bottomLeft;
                  //     public Node bottomRight;
                  //     public Node() {}
                  //     public Node(boolean _val,boolean _isLeaf,Node _topLeft,Node _topRight,Node _bottomLeft,Node _bottomRight) {
                  //         val = _val;
                  //         isLeaf = _isLeaf;
                  //         topLeft = _topLeft;
                  //         topRight = _topRight;
                  //         bottomLeft = _bottomLeft;
                  //         bottomRight = _bottomRight;
                  //     }
                  // };
                  
                  class Solution {
                    public Node intersect(Node quadTree1, Node quadTree2) {
                      if (quadTree1.isLeaf)
                        return quadTree1.val ? quadTree1 : quadTree2;
                      if (quadTree2.isLeaf)
                        return quadTree2.val ? quadTree2 : quadTree1;
                  
                      Node topLeft = intersect(quadTree1.topLeft, quadTree2.topLeft);
                      Node topRight = intersect(quadTree1.topRight, quadTree2.topRight);
                      Node bottomLeft = intersect(quadTree1.bottomLeft, quadTree2.bottomLeft);
                      Node bottomRight = intersect(quadTree1.bottomRight, quadTree2.bottomRight);
                  
                      if (topLeft.val == topRight.val &&
                          topLeft.val == bottomLeft.val &&
                          topLeft.val == bottomRight.val &&
                          topLeft.isLeaf && topRight.isLeaf &&
                          bottomLeft.isLeaf && bottomRight.isLeaf)
                        return new Node(topLeft.val, true);
                      return new Node(false, false, topLeft, topRight, bottomLeft, bottomRight);
                    }
                  }
                  `,
                output: `[[0,0],[1,1],[1,1],[1,1],[1,0]]`,
              },
            }}
          />
        ),
      },
      q467: {
        title: "Q559. Maximum Depth of N-ary Tree (Q467)",
        content: (
          <Comp
            title="Q559. Maximum Depth of N-ary Tree (Q467)"
            content1={
              <>
                Given a n-ary tree, find its maximum depth.
                <br />
                The maximum depth is the number of nodes along the longest path
                from the root node down to the farthest leaf node.
                <br />
                Nary-Tree input serialization is represented in their level
                order traversal, each group of children is separated by the null
                value (See examples).
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode559,
                content: (
                  <>
                    The total number of nodes is in the range [0, 104]. <br />
                    The depth of the n-ary tree is less than or equal to 1000.
                  </>
                ),
              },
              {
                content: (
                  <>
                    The total number of nodes is in the range [0, 104]. <br />
                    The depth of the n-ary tree is less than or equal to 1000.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The total number of nodes is in the range [0, 104]. <br />
                The depth of the n-ary tree is less than or equal to 1000.
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Javascript: {
                code: `function Node(val,children) {
                  this.val = val;
                  this.children = children;
                };
                 
                /**
                 * @param {Node|null} root
                 * @return {number}
                 */
                var maxDepth = function(root) {
                  if (!root) return 0;
                  let ans = 0;
                  for (let child of root.children)
                    ans = Math.max(ans, maxDepth(child));
                  return 1 + ans;
                };
                
                const n2 = new Node(2,[])
                const n4 = new Node(4,[])
                const n5 = new Node(5,[])
                const n6 = new Node(6,[])
                const n3 = new Node(3,[n5,n6])
                const n1 = new Node(1,[n3,n2,n4])
                console.log(maxDepth(n1))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q468: {
        title: "Q560. Subarray Sum Equals K (Q468)",
        content: (
          <Comp
            title="Q560. Subarray Sum Equals K (Q468)"
            content1={
              <>
                Given an array of integers nums and an integer k, return the
                total number of continuous subarrays whose sum equals to k.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,1,1], k = 2<br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3], k = 3<br />
                    Output: 2
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 2 * 104 -1000 &lt;= nums[i] &lt;= 1000
                -10^7 &lt;= k &lt;= 10^7
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var subarraySum = function(nums, k) {
                 let ans = 0;
                 let prefix = 0;
                 const count = {};
                 count[0] = 1;
                 for (let num of nums) {
                   prefix += num;
                   ans += (count[prefix - k] ||  0);
                   count[prefix] = (count[prefix] || 0) + 1;
                 }
                 return ans;
               };
               
               console.log(subarraySum([1,1,1],2))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q469: {
        title: "Q561. Array Partition I (Q469)",
        content: (
          <Comp
            title="Q561. Array Partition I (Q469)"
            content1={
              <>
                Given an integer array nums of 2n integers, group these integers
                into n pairs (a1, b1), (a2, b2), ..., (an, bn) such that the sum
                of min(ai, bi) for all i is maximized. Return the maximized sum.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,4,3,2]
                    <br />
                    Output: 4<br />
                    Explanation: All possible pairings (ignoring the ordering of
                    elements) are:
                    <br />
                    1. (1, 4), (2, 3) -&gt; min(1, 4) + min(2, 3) = 1 + 2 = 3
                    <br />
                    2. (1, 3), (2, 4) -&gt; min(1, 3) + min(2, 4) = 1 + 2 = 3
                    <br />
                    3. (1, 2), (3, 4) -&gt; min(1, 2) + min(3, 4) = 1 + 3 = 4
                    <br />
                    So the maximum possible sum is 4.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [6,2,6,5,1,2] <br />
                    Output: 9<br />
                    Explanation: The optimal pairing is (2, 1), (2, 5), (6, 6).
                    min(2, 1) + min(2, 5) + min(6, 6) = 1 + 2 + 6 = 9.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 10^4 <br />
                nums.length == 2 * n <br />
                -10^4 &lt;= nums[i] &lt;= 10^4
              </>
            }
            tc="n => n.log n"
            sc="n => 1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var arrayPairSum = function(nums) {
                 let ans = 0;
                 nums = nums.sort((a,b)=>a-b)
                 for (let i = 0; i < nums.length; i += 2)
                   ans += nums[i];
                 return ans;
               };
               
               console.log(arrayPairSum([1,4,3,2]))`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q470: {
        title: "Q563. Binary Tree Tilt (Q470)",
        content: (
          <Comp
            title="Q563. Binary Tree Tilt (Q470)"
            content1={
              <>
                Given the root of a binary tree, return the sum of every tree
                node's tilt.
                <br />
                The tilt of a tree node is the absolute difference between the
                sum of all left subtree node values and all right subtree node
                values. If a node does not have a left child, then the sum of
                the left subtree node values is treated as 0. The rule is
                similar if the node does not have a right child.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode563,
                content: (
                  <>
                    Input: root = [1,2,3] <br />
                    Output: 1 <br />
                    Explanation: <br />
                    Tilt of node 2 : |0-0| = 0 (no children) <br />
                    Tilt of node 3 : |0-0| = 0 (no children) <br />
                    Tilt of node 1 : |2-3| = 1 (left subtree is just left child,
                    so sum is 2; right subtree is just right child, so sum is 3)
                    <br />
                    Sum of every tilt : 0 + 0 + 1 = 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [4,2,9,3,5,null,7] <br />
                    Output: 15 <br />
                    Explanation: <br />
                    Tilt of node 3 : |0-0| = 0 (no children) <br />
                    Tilt of node 5 : |0-0| = 0 (no children) <br />
                    Tilt of node 7 : |0-0| = 0 (no children) <br />
                    Tilt of node 2 : |3-5| = 2 (left subtree is just left child,
                    so sum is 3; right subtree is just right child, so sum is 5)
                    <br />
                    Tilt of node 9 : |0-7| = 7 (no left child, so sum is 0;
                    right subtree is just right child, so sum is 7)
                    <br />
                    Tilt of node 4 : |(3+5+2)-(9+7)| = |10-16| = 6 (left subtree
                    values are 3, 5, and 2, which sums to 10; right subtree
                    values are 9 and 7, which sums to 16)
                    <br />
                    Sum of every tilt : 0 + 0 + 0 + 2 + 7 + 6 = 15
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [21,7,14,1,1,2,2,3,3] <br />
                    Output: 9
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [0, 10^4].
                <br />
                -1000 &lt;= Node.val &lt;= 1000
              </>
            }
            tc="n"
            sc="n.log n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root
                 * @return {number}
                 */
                var findTilt = function(root) {
                  const res = { val : 0 }
                  sum(root,res);
                  return res.val;
                }
                
                function sum(root,res) {
                  if (!root) return 0;
                  let l = sum(root.left,res);
                  let r = sum(root.right,res);
                  res.val += Math.abs(l - r);
                  return root.val + l + r;
                }  
                
                const t = new TreeNode(1)
                t.left = new TreeNode(2)
                t.right = new TreeNode(3)
                console.log(findTilt(t))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q471: {
        title: "Q564. Find the Closest Palindrome (Q471)",
        content: (
          <Comp
            title="Q564. Find the Closest Palindrome (Q471)"
            content1={
              <>
                Given a string n representing an integer, return the closest
                integer (not including itself), which is a palindrome. If there
                is a tie, return the smaller one.
                <br />
                The closest is defined as the absolute difference minimized
                between two integers.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = "123" <br />
                    Output: "121"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = "1"
                    <br />
                    Output: "0"
                    <br />
                    Explanation: 0 and 2 are the closest palindromes but we
                    return the smallest which is 0.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n.length &lt;= 18 <br />
                n consists of only digits. <br />
                n does not have leading zeros. <br />n is representing an
                integer in the range [1, 1018 - 1].
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `class Solution {
                  public String nearestPalindromic(String n) {
                    final long[] palindromes = getPalindromes(n);
                    return Math.abs(palindromes[0] - Long.parseLong(n)) <=
                            Math.abs(palindromes[1] - Long.parseLong(n))
                        ? String.valueOf(palindromes[0])
                        : String.valueOf(palindromes[1]);
                  }
                
                  private long[] getPalindromes(final String s) {
                    final long num = Long.parseLong(s);
                    final int n = s.length();
                    long[] palindromes = new long[2];
                    final String half = s.substring(0, (n + 1) / 2);
                    final String reversedHalf = new StringBuilder(half.substring(0, n / 2)).reverse().toString();
                    final long candidate = Long.parseLong(half + reversedHalf);
                
                    if (candidate < num)
                      palindromes[0] = candidate;
                    else {
                      final String prevHalf = String.valueOf(Long.parseLong(half) - 1);
                      final String reversedPrevHalf =
                          new StringBuilder(prevHalf.substring(0, Math.min(prevHalf.length(), n / 2)))
                              .reverse()
                              .toString();
                      if (n % 2 == 0 && Long.parseLong(prevHalf) == 0)
                        palindromes[0] = 9;
                      else if (n % 2 == 0 && (Long.parseLong(prevHalf) + 1) % 10 == 0)
                        palindromes[0] = Long.parseLong(prevHalf + '9' + reversedPrevHalf);
                      else
                        palindromes[0] = Long.parseLong(prevHalf + reversedPrevHalf);
                    }
                
                    if (candidate > num)
                      palindromes[1] = candidate;
                    else {
                      final String nextHalf = String.valueOf(Long.parseLong(half) + 1);
                      final String reversedNextHalf =
                          new StringBuilder(nextHalf.substring(0, n / 2)).reverse().toString();
                      palindromes[1] = Long.parseLong(nextHalf + reversedNextHalf);
                    }
                
                    return palindromes;
                  }
                }
                `,
                output: `121`,
              },
            }}
          />
        ),
      },
      q472: {
        title: "Q565. Array Nesting (Q472)",
        content: (
          <Comp
            title="Q565. Array Nesting (Q472)"
            content1={
              <>
                ou are given an integer array nums of length n where nums is a
                permutation of the numbers in the range [0, n - 1].
                <br />
                You should build a set s[k] ={" "}
                {"{nums[k], nums[nums[k]], nums[nums[nums[k]]], ... }"}{" "}
                subjected to the following rule:
                <br />
                The first element in s[k] starts with the selection of the
                element nums[k] of index = k.
                <br />
                The next element in s[k] should be nums[nums[k]], and then
                nums[nums[nums[k]]], and so on.
                <br />
                We stop adding right before a duplicate element occurs in s[k].
                <br />
                Return the longest length of a set s[k].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [5,4,0,3,1,6,2] <br />
                    Output: 4 <br />
                    Explanation: <br />
                    nums[0] = 5, nums[1] = 4, nums[2] = 0, nums[3] = 3, nums[4]
                    = 1, nums[5] = 6, nums[6] = 2.
                    <br /> One of the longest sets s[k]: <br />
                    s[0] ={" "}
                    {"{nums[0], nums[5], nums[6], nums[2]} = {5, 6, 2, 0}"}
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [0,1,2] <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^5 <br />
                0 &lt;= nums[i] &lt; nums.length <br />
                All the values of nums are unique.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var arrayNesting = function(nums) {
                 let res = 0;
                 for (let num of nums) {
                   if (num == -1)
                     continue;
                   let index = num;
                   let count = 0;
                   while (nums[index] != -1) { 
                     const cache = index;
                     index = nums[index];  
                     nums[cache] = -1;     
                     ++count;
                   }
                   res = Math.max(res, count);
                 }
                 return res;
               };
               
               console.log(arrayNesting([0,1,2]))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q473: {
        title: "Q566. Reshape the Matrix (Q473)",
        content: (
          <Comp
            title="Q566. Reshape the Matrix (Q473)"
            content1={
              <>
                In MATLAB, there is a handy function called reshape which can
                reshape an m x n matrix into a new one with a different size r x
                c keeping its original data.
                <br />
                You are given an m x n matrix mat and two integers r and c
                representing the number of rows and the number of columns of the
                wanted reshaped matrix.
                <br />
                The reshaped matrix should be filled with all the elements of
                the original matrix in the same row-traversing order as they
                were.
                <br />
                If the reshape operation with given parameters is possible and
                legal, output the new reshaped matrix; Otherwise, output the
                original matrix.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: mat = [[1,2],[3,4]],
                    <br /> r = 1, c = 4<br />
                    Output: [[1,2,3,4]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: mat = [[1,2],[3,4]],
                    <br /> r = 2, c = 4<br />
                    Output: [[1,2],[3,4]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == mat.length
                <br />
                n == mat[i].length
                <br />
                1 &lt;= m, n &lt;= 100
                <br />
                -1000 &lt;= mat[i][j] &lt;= 1000
                <br />1 &lt;= r, c &lt;= 300
              </>
            }
            tc="r.c"
            sc="r.c"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} mat
                * @param {number} r
                * @param {number} c
                * @return {number[][]}
                */
               var matrixReshape = function(mat, r, c) {
                 if (mat.length == 0 || r * c != mat.length * mat[0].length)
                    return mat;
                 const res = Array.from(Array(r),()=>Array(c).fill(0));
                 let k = 0;
                 for (let row of mat)
                   for (let num of row) {
                     res[parseInt(k / c)][k % c] = num;
                     ++k;
                   }
                 return res;  
               };
               
               console.log(matrixReshape([[1,2],[3,4]],2,4))`,
                output: `[ [ 1, 2 ], [ 3, 4 ] ]`,
              },
            }}
          />
        ),
      },
      q474: {
        title: "Q567. Permutation in String (Q474)",
        content: (
          <Comp
            title="Q567. Permutation in String (Q474)"
            content1={
              <>
                Given two strings s1 and s2, return true if s2 contains a
                permutation of s1, or false otherwise.
                <br />
                In other words, return true if one of s1's permutations is the
                substring of s2.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s1 = "ab", s2 = "eidbaooo" <br />
                    Output: true <br />
                    Explanation: s2 contains one permutation of s1 ("ba").
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s1 = "ab", s2 = "eidboaoo" <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s1.length, s2.length &lt;= 10^4 <br />
                s1 and s2 consist of lowercase English letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s1
                * @param {string} s2
                * @return {boolean}
                */
               var checkInclusion = function(s1, s2) {
                 const count = Array(128).fill(0);
                 let required = s1.length;
                 for (let c of s1)
                   ++count[c.charCodeAt(0)];
                 for (let l = 0, r = 0; r < s2.length; ++r) {
                   if (--count[s2[r].charCodeAt(0)] >= 0)
                     --required;
                   while (required == 0) {
                     if (r - l + 1 == s1.length)
                       return true;
                     if (++count[s2[l++].charCodeAt(0)] > 0)
                       ++required;
                   }
                 }
                 return false;
               };
               
               console.log(checkInclusion("ab","eidbaooo"))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q475: {
        title: "Q572. Subtree of Another Tree (Q475)",
        content: (
          <Comp
            title="Q572. Subtree of Another Tree (Q475)"
            content1={
              <>
                Given the roots of two binary trees root and subRoot, return
                true if there is a subtree of root with the same structure and
                node values of subRoot and false otherwise.
                <br />A subtree of a binary tree tree is a tree that consists of
                a node in tree and all of this node's descendants. The tree tree
                could also be considered as a subtree of itself.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode572,
                content: (
                  <>
                    Input: root = [3,4,5,1,2], subRoot = [4,1,2] <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [3,4,5,1,2,null,null,null,null,0],
                    <br /> subRoot = [4,1,2]
                    <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the root tree is in the range [1, 2000].
                <br />
                The number of nodes in the subRoot tree is in the range [1,
                1000].
                <br />
                -10^4 &lt;= root.val &lt;= 10^4
                <br />
                -10^4 &lt;= subRoot.val &lt;= 10^4
              </>
            }
            tc="m.n"
            sc="h"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
              }
              
              /**
               * @param {TreeNode} root
               * @param {TreeNode} subRoot
               * @return {boolean}
               */
              
              function isSameTree(p, q) {
                if (!p || !q)
                  return p == q;
                return p.val == q.val &&
                       isSameTree(p.left, q.left) &&
                       isSameTree(p.right, q.right);
              }
              
              var isSubtree = function(root, subRoot) {
                if (!root)
                  return false;
                if (isSameTree(root, subRoot))
                  return true;
                return isSubtree(root.left, subRoot) || isSubtree(root.right, subRoot); 
              };
              
              const t = new TreeNode(3)
              t.left = new TreeNode(4)
              t.left.left = new TreeNode(1)
              t.left.right = new TreeNode(2)
              t.left.right = new TreeNode(5) 
              console.log(isSubtree(t))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q476: {
        title: "Q575. Distribute Candies (Q476)",
        content: (
          <Comp
            title="Q575. Distribute Candies (Q476)"
            content1={
              <>
                Alice has n candies, where the ith candy is of type
                candyType[i]. Alice noticed that she started to gain weight, so
                she visited a doctor.
                <br />
                The doctor advised Alice to only eat n / 2 of the candies she
                has (n is always even). Alice likes her candies very much, and
                she wants to eat the maximum number of different types of
                candies while still following the doctor's advice.
                <br />
                Given the integer array candyType of length n, return the
                maximum number of different types of candies she can eat if she
                only eats n / 2 of them.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: candyType = [1,1,2,2,3,3] <br />
                    Output: 3 <br />
                    Explanation: Alice can only eat 6 / 2 = 3 candies. Since
                    there are only 3 types, she can eat one of each type.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: candyType = [1,1,2,3] <br />
                    Output: 2 <br />
                    Explanation: Alice can only eat 4 / 2 = 2 candies. Whether
                    she eats types [1,2], [1,3], or [2,3], she still can only
                    eat 2 different types.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: candyType = [6,6,6,6] <br />
                    Output: 1 <br />
                    Explanation: Alice can only eat 4 / 2 = 2 candies. Even
                    though she can eat 2 candies, she only has 1 type.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == candyType.length <br />
                2 &lt;= n &lt;= 10^4 <br />
                n is even. <br />
                -10^5 &lt;= candyType[i] &lt;= 10^5
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} candyType
                * @return {number}
                */
               var distributeCandies = function(candyType) {
                 return Math.min(parseInt(candyType.length / 2), new Set(candyType).size)  
               };
               
               console.log(distributeCandies([1,1,2,2,3,3]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q477: {
        title: "Q576. Out of Boundary Paths (Q477)",
        content: (
          <Comp
            title="Q576. Out of Boundary Paths (Q477)"
            content1={
              <>
                There is an m x n grid with a ball. The ball is initially at the
                position [startRow, startColumn]. You are allowed to move the
                ball to one of the four adjacent cells in the grid (possibly out
                of the grid crossing the grid boundary). You can apply at most
                maxMove moves to the ball.
                <br />
                Given the five integers m, n, maxMove, startRow, startColumn,
                return the number of paths to move the ball out of the grid
                boundary. Since the answer can be very large, return it modulo
                109 + 7.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode576,
                content: (
                  <>
                    Input: m = 2, n = 2,
                    <br /> maxMove = 2, <br />
                    startRow = 0,
                    <br /> startColumn = 0<br />
                    Output: 6
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: m = 1, n = 3,
                    <br /> maxMove = 3, <br />
                    startRow = 0,
                    <br /> startColumn = 1<br />
                    Output: 12
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= m, n &lt;= 50
                <br />
                0 &lt;= maxMove &lt;= 50
                <br />
                0 &lt;= startRow &lt; m<br />0 &lt;= startColumn &lt; n
              </>
            }
            tc="m.n.N"
            sc="m.n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} m
                * @param {number} n
                * @param {number} maxMove
                * @param {number} startRow
                * @param {number} startColumn
                * @return {number}
                */
               var findPaths = function(m, n, maxMove, startRow, startColumn) {
                 const kMod = 1e9 + 7;
                 const dirs = [0, 1, 0, -1, 0];
                 let res = 0;
                 let dp =Array.from(Array(m),()=>Array(n).fill(0));
                 dp[startRow][startColumn] = 1;
                 while (maxMove-- > 0) {
                   let newDp =Array.from(Array(m),()=>Array(n).fill(0));
                   for (let r = 0; r < m; ++r)
                     for (let c = 0; c < n; ++c)
                       if (dp[r][c] > 0)
                         for (let k = 0; k < 4; ++k) {
                           let x = r + dirs[k];
                           let y = c + dirs[k + 1];
                           if (x < 0 || x == m || y < 0 || y == n)
                             res = (res + dp[r][c]) % kMod;
                           else
                             newDp[x][y] = (newDp[x][y] + dp[r][c]) % kMod;
                         }
                   dp = newDp;
                 }
                 return res;   
               };
               
               console.log(findPaths(2,2,2,0,0))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q478: {
        title: "Q581. Shortest Unsorted Continuous Subarray (Q478)",
        content: (
          <Comp
            title="Q581. Shortest Unsorted Continuous Subarray (Q478)"
            content1={
              <>
                Given an integer array nums, you need to find one continuous
                subarray that if you only sort this subarray in ascending order,
                then the whole array will be sorted in ascending order.
                <br />
                Return the shortest such subarray and output its length.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [2,6,4,8,10,9,15]
                    <br /> Output: 5
                    <br /> Explanation: You need to sort [6, 4, 8, 10, 9] in
                    ascending order to make the whole array sorted in ascending
                    order.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4] <br />
                    Output: 0
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1] <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4 <br />
                -10^5 &lt;= nums[i] &lt;= 10^5
              </>
            }
            fp={
              <>
                <b>Follow up : </b>Can you solve it in O(n) time complexity?
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var findUnsortedSubarray = function(nums) {
                 const n = nums.length;
                 let min = Number.MAX_SAFE_INTEGER;
                 let max = Number.MIN_SAFE_INTEGER;
                 let meetDecrease = false;
                 let meetIncrease = false;
                 for (let i = 1; i < n; ++i) {
                   if (nums[i] < nums[i - 1])
                     meetDecrease = true;
                   if (meetDecrease)
                     min = Math.min(min, nums[i]);
                 }
                 for (let i = n - 2; i >= 0; --i) {
                   if (nums[i] > nums[i + 1])
                     meetIncrease = true;
                   if (meetIncrease)
                     max = Math.max(max, nums[i]);
                 }
                 let l = 0;
                 for (l = 0; l < n; ++l)
                   if (nums[l] > min)
                     break;
                 let r = 0;
                 for (r = n - 1; r >= 0; --r)
                   if (nums[r] < max)
                     break;
                 return l > r ? 0 : r - l + 1;
               };
               
               console.log(findUnsortedSubarray([2,6,4,8,10,9,15]))`,
                output: `5`,
              },
            }}
          />
        ),
      },
      q479: {
        title: "Q583. Delete Operation for Two Strings (Q479)",
        content: (
          <Comp
            title="Q583. Delete Operation for Two Strings (Q479)"
            content1={
              <>
                {" "}
                Given two strings word1 and word2, return the minimum number of
                steps required to make word1 and word2 the same.
                <br />
                In one step, you can delete exactly one character in either
                string.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: word1 = "sea", word2 = "eat"
                    <br />
                    Output: 2 <br />
                    Explanation: You need one step to make "sea" to "ea" and
                    another step to make "eat" to "ea".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: word1 = "leetcode", word2 = "etco""
                    <br />
                    Output: 4
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= word1.length, word2.length &lt;= 500
                <br />
                word1 and word2 consist of only lowercase English letters.
              </>
            }
            tc="m.n"
            sc="m.n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} word1
                * @param {string} word2
                * @return {number}
                */
               var minDistance = function(word1, word2) {
                 const k = lcs(word1, word2);
                 return (word1.length - k) + (word2.length - k); 
               };
               
               function lcs(a, b) {
                 const m = a.length;
                 const n = b.length;
                 const dp = Array.from(Array(m+1),()=>Array(n+1).fill(0))
                 for (let i = 1; i <= m; ++i)
                   for (let j = 1; j <= n; ++j)
                     if (a[i - 1] == b[j - 1])
                       dp[i][j] = 1 + dp[i - 1][j - 1];
                     else
                       dp[i][j] = Math.max(dp[i - 1][j], dp[i][j - 1]);
                 return dp[m][n];
               }
               
               console.log(minDistance("leetcode","etco"))`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q480: {
        title: "Q587. Erect the Fence (Q480)",
        content: (
          <Comp
            title="Q587. Erect the Fence (Q480)"
            content1={
              <>
                You are given an array trees where trees[i] = [xi, yi]
                represents the location of a tree in the garden.
                <br />
                You are asked to fence the entire garden using the minimum
                length of rope as it is expensive. The garden is well fenced
                only if all the trees are enclosed.
                <br />
                Return the coordinates of trees that are exactly located on the
                fence perimeter.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode587,
                content: (
                  <>
                    Input: points = [[1,1],[2,2],[2,0],[2,4],[3,3],[4,2]] <br />
                    Output: [[1,1],[2,0],[3,3],[2,4],[4,2]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: points = [[1,2],[2,2],[4,2]] <br />
                    Output: [[4,2],[2,2],[1,2]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= points.length &lt;= 3000 <br />
                points[i].length == 2 <br />
                0 &lt;= xi, yi &lt;= 100 <br />
                All the given points are unique.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `
                // [[1,1],[2,2],[2,0],[2,4],[3,3],[4,2]]
                class Solution {
                  public static class Pair {
                         int x;
                         int y;
                         Pair(int x, int y) {
                           this.x = x;
                           this.y = y;
                         }
                       }
                 public int[][] outerTrees(int[][] trees) {
                     List<Pair> points=new ArrayList<>();
                     for(int[] point:trees){
                        points.add(new Pair(point[0],point[1])); 
                     }
                     List<Pair> res=new ArrayList<>();
                     if(points.size()==1){
                         return trees;
                     }
                     int n=points.size();
                     Collections.sort(points,(a,b)->a.y==b.y?a.x-b.x:a.y-b.y);
                     HashSet<ArrayList<Integer>> dup=new HashSet<>();
                     Stack<Pair> hull=new Stack<>();
                     hull.push(points.get(0));
                     hull.push(points.get(1));
                     for(int i=2;i<n;i++){
                         Pair top=hull.pop();
                         while(!hull.isEmpty()&&ccw(hull.peek(),top,points.get(i))<0){
                             top=hull.pop();
                         }
                         hull.push(top);
                         hull.push(points.get(i));
                     }
                     for(int i=n-2;i>=0;i--){
                         Pair top=hull.pop();
                         while(!hull.isEmpty()&&ccw(hull.peek(),top,points.get(i))<0){
                             top=hull.pop();
                         }
                         hull.push(top);
                         hull.push(points.get(i));
                     }
                     for(Pair p:hull){
                         ArrayList<Integer> tmp=new ArrayList<>();
                         tmp.add(p.x);
                         tmp.add(p.y);
                         if(dup.contains(tmp))continue;
                         dup.add(tmp);
                         res.add(p);
                     }
             
                     int[][] ans=new int[res.size()][2];
                     int i=0;
                     for(Pair p:res){
                         ans[i][0]=p.x;
                         ans[i][1]=p.y;
                         i++;
                     }
                     return ans;
                     
                 }
             
                 public int ccw(Pair a,Pair b,Pair c){
                     double cp=(b.x-a.x)*(c.y-a.y)-(b.y-a.y)*(c.x-a.x);
                     if(cp<0)return -1;
                     else if(cp>0)return 1;
                     else return 0;
                 }
             }`,
                output: `[[2,0],[4,2],[3,3],[2,4],[1,1]]`,
              },
            }}
          />
        ),
      },
      q481: {
        title: "Q589. N-ary Tree Preorder Traversal (Q481)",
        content: (
          <Comp
            title="Q589. N-ary Tree Preorder Traversal (Q481)"
            content1={
              <>
                Given the root of an n-ary tree, return the preorder traversal
                of its nodes' values.
                <br />
                Nary-Tree input serialization is represented in their level
                order traversal. Each group of children is separated by the null
                value (See examples)
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode559,
                content: (
                  <>
                    Input: root = [1,null,3,2,4,null,5,6] <br />
                    Output: [1,3,5,6,2,4]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root =
                    [1,null,2,3,4,5,null,null,6,7,null,8,null,9,10,null,null,11,null,12,null,13,null,null,14]
                    <br /> Output: [1,2,3,6,7,11,14,4,8,12,5,9,13,10]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [0, 10^4].{" "}
                <br />
                0 &lt;= Node.val ^lt;= 104 <br />
                The height of the n-ary tree is less than or equal to 1000.
              </>
            }
            fp={
              <>
                <b>Follow up :</b>Recursive solution is trivial, could you do it
                iteratively?
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function Node(val, children) {
                  this.val = val;
                  this.children = children;
               };
               
               /**
                * @param {Node|null} root
                * @return {number[]}
                */
               var preorder = function(root) {
                 if (!root) return [];
                 const res = []
                 const stack = []
                 stack.push(root);
                 while (stack.length) {
                   root = stack.pop();
                   res.push(root.val);
                   for (let i = root.children.length - 1; i >= 0; --i)
                     stack.push(root.children[i]);
                 }
                 return res;   
               };
               
               const n2 = new Node(2,[])
               const n4 = new Node(4,[])
               const n5 = new Node(5,[])
               const n6 = new Node(6,[])
               const n3 = new Node(3,[n5,n6])
               const n1 = new Node(1,[n3,n2,n4])
               console.log(preorder(n1))`,
                output: `[ 1, 3, 5, 6, 2, 4 ]`,
              },
            }}
          />
        ),
      },
      q482: {
        title: "Q590. N-ary Tree Postorder Traversal (Q482)",
        content: (
          <Comp
            title="Q590. N-ary Tree Postorder Traversal (Q482)"
            content1={
              <>
                Given the root of an n-ary tree, return the postorder traversal
                of its nodes' values.
                <br />
                Nary-Tree input serialization is represented in their level
                order traversal. Each group of children is separated by the null
                value (See examples)
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode559,

                content: (
                  <>
                    Input: root = [1,null,3,2,4,null,5,6]
                    <br />
                    Output: [5,6,3,2,4,1]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root =
                    [1,null,2,3,4,5,null,null,6,7,null,8,null,9,10,null,null,11,null,12,null,13,null,null,14]
                    <br />
                    Output: [2,6,14,11,7,3,12,8,4,13,9,10,5,1]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [0, 10^4].
                <br />
                0 &lt;= Node.val &lt;= 10^4
                <br />
                The height of the n-ary tree is less than or equal to 1000.
              </>
            }
            fp={
              <>
                <b>Follow up :</b> Recursive solution is trivial, could you do
                it iteratively?
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function Node(val,children) {
                  this.val = val;
                  this.children = children;
               };
               
               
               /**
                * @param {Node|null} root
                * @return {number[]}
                */
               var postorder = function(root) {
                 if (!root) return [];
                 const res = []
                 const stack = []
                 stack.push(root);
                 while (stack.length) {
                     root = stack.pop();
                     res.push(root.val);
                     for (let i of root.children)
                         stack.push(i);
                 }
                 return res.reverse();    
               };
               
               const n2 = new Node(2, [])
               const n4 = new Node(4, [])
               const n5 = new Node(5, [])
               const n6 = new Node(6, [])
               const n3 = new Node(3, [n5, n6])
               const n1 = new Node(1, [n3, n2, n4])
               console.log(postorder(n1))`,
                output: `[ 5, 6, 3, 2, 4, 1 ]`,
              },
            }}
          />
        ),
      },
      q483: {
        title: "Q591. Tag Validator (Q483)",
        content: (
          <Comp
            title="Q591. Tag Validator (Q483)"
            content1={
              <>
                Given a string representing a code snippet, implement a tag
                validator to parse the code and return whether it is valid.
                <br />
                A code snippet is valid if all the following rules hold:
                <br />
                The code must be wrapped in a valid closed tag. Otherwise, the
                code is invalid.
                <br />A closed tag (not necessarily valid) has exactly the
                following format : {"<TAG_NAME>TAG_CONTENT</TAG_NAME>."} Among
                them, {"<TAG_NAME> is the start tag, and </TAG_NAME>"} is the
                end tag. The TAG_NAME in start and end tags should be the same.
                A closed tag is valid if and only if the TAG_NAME and
                TAG_CONTENT are valid.
                <br />
                A valid TAG_NAME only contain upper-case letters, and has length
                in range [1,9]. Otherwise, the TAG_NAME is invalid.
                <br />
                A valid TAG_CONTENT may contain other valid closed tags, cdata
                and any characters (see note1) EXCEPT unmatched &lt;, unmatched
                start and end tag, and unmatched or closed tags with invalid
                TAG_NAME. Otherwise, the TAG_CONTENT is invalid.
                <br />
                A start tag is unmatched if no end tag exists with the same
                TAG_NAME, and vice versa. However, you also need to consider the
                issue of unbalanced when tags are nested.
                <br />
                {
                  " A < is unmatched if you cannot find a subsequent >. And when you find a < or </, all the subsequent characters until the next > should be parsed as TAG_NAME (not necessarily valid)."
                }
                <br />
                The cdata has the following format :{" "}
                {
                  "<![CDATA[CDATA_CONTENT]]>. The range of CDATA_CONTENT is defined as the characters between <![CDATA[ and the first subsequent ]]>."
                }
                <br />
                CDATA_CONTENT may contain any characters. The function of cdata
                is to forbid the validator to parse CDATA_CONTENT, so even it
                has some characters that can be parsed as tag (no matter valid
                or invalid), you should treat it as regular characters.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: code ={" "}
                    {'"<DIV>This is the first line <![CDATA[<div>]]></DIV>"'}
                    <br /> Output: true <br />
                    Explanation: <br />
                    {
                      " The code is wrapped in a closed tag : <DIV> and </DIV>. "
                    }
                    <br /> The TAG_NAME is valid, the TAG_CONTENT consists of
                    some characters and cdata.
                    <br /> Although CDATA_CONTENT has an unmatched start tag
                    with invalid TAG_NAME, it should be considered as plain
                    text, not parsed as a tag.
                    <br /> So TAG_CONTENT is valid, and then the code is valid.
                    Thus return true.
                  </>
                ),
              },
              {
                content: (
                  <>
                    {
                      ' Input: code = "<DIV>>>  ![cdata[]] <![CDATA[<div>]>]]>]]>>]</DIV>"'
                    }
                    <br />
                    Output: true
                    <br />
                    Explanation:
                    <br />
                    We first separate the code into :
                    start_tag|tag_content|end_tag.
                    <br />
                    {'start_tag -> "<DIV>"'}
                    <br />
                    {'end_tag -> "</DIV>"'}
                    <br />
                    tag_content could also be separated into :
                    text1|cdata|text2.
                    <br />
                    {'text1 -> ">>  ![cdata[]] "'}
                    <br />
                    {
                      'cdata -> "<![CDATA[<div>]>]]>", where the CDATA_CONTENT is "<div>]>"'
                    }
                    <br />
                    {'text2 -> "]]>>]"'}
                    <br />
                    {
                      'The reason why start_tag is NOT "<DIV>>>" is because of the rule 6.'
                    }
                    <br />
                    {
                      'The reason why cdata is NOT "<![CDATA[<div>]>]]>]]>" is because of the rule 7.'
                    }
                  </>
                ),
              },
              {
                content: (
                  <>
                    {'Input: code = "<A>  <B> </A>   </B>"'} <br />
                    Output: false <br />
                    {
                      'Explanation: Unbalanced. If "<A>" is closed, then "<B>" must be unmatched, and vice versa.'
                    }
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= code.length &ly;= 500
                <br />
                code consists of English letters, digits, '&lt;', '&gt;', '/',
                '!', '[', ']', '.', and ' '.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `
                // "<DIV>This is the first line <![CDATA[<div>]]></DIV>"
                const isValid = function(code) {    
                  const stack = []
                  const openingTag = /^<[A-Z]{1,9}(>){1}/
                  const closingTag = /<\\/[A-Z]{1,9}(>){1}/
                  const cdata = /<\\!\\[CDATA\[.*?(\\]\\]>)+?/
                  const closingTagEndIndex = code.length < 13 ? 0 : code.length - 13
                  const openingTagEndIndex = code.length < 12 ? code.length : 12
                  if (!openingTag.test(code.slice(0, openingTagEndIndex))) {
                      return false
                  }
                  if (!closingTag.test(code.slice(closingTagEndIndex, code.length))) {
                      return false
                  }
                  
                  const lastIndex = code.length - 3
                  let index = 0
                  while (index < lastIndex) {
                      const char = code[index]
                      if (char === "<") {
                          const nextChar = code[index + 1]
                          const str = code.slice(index, code.length)
                          if (/[A-Z]/.test(nextChar) && openingTag.test(str)) {
                              const tag = str.match(openingTag)[0]
                              stack.push(tag)
                              index = index + tag.length - 1
                          }
                                      else if (nextChar === "/" && closingTag.test(str)) {
                              const tag = str.match(closingTag)[0]
                              const matchingOpeningTag = tag[0] + tag.slice(2)
                              if (stack.length && stack[stack.length - 1] === matchingOpeningTag) {
                                  stack.pop(tag)
                              }
                              else {
                                  return false
                              }
                              index = index + tag.length - 1
                              if (stack.length === 0 && index !== code.length - 1) {
                                  return false
                              }
                          }
                          else if (nextChar === "!" && cdata.test(code.slice(index))) {
                              const cdataTag = code.slice(index).match(cdata)[0]
                              index = index + cdataTag.length - 1
                          }
                           else {
                              return false
                          }
                      }
                      index++   
                  }
                  return stack.length === 0
              };`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q484: {
        title: "Q592. Fraction Addition and Subtraction (Q484)",
        content: (
          <Comp
            title="Q592. Fraction Addition and Subtraction (Q484)"
            content1={
              <>
                Given a string expression representing an expression of fraction
                addition and subtraction, return the calculation result in
                string format.
                <br />
                The final result should be an irreducible fraction. If your
                final result is an integer, change it to the format of a
                fraction that has a denominator 1. So in this case, 2 should be
                converted to 2/1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: expression = "-1/2+1/2" <br />
                    Output: "0/1"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: expression = "-1/2+1/2+1/3" <br />
                    Output: "1/3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: expression = "1/3-1/2" <br />
                    Output: "-1/6"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The input string only contains '0' to '9', '/', '+' and '-'. So
                does the output. <br />
                Each fraction (input and output) has the format
                ±numerator/denominator. If the first input fraction or the
                output is positive, then '+' will be omitted.
                <br />
                The input only contains valid irreducible fractions, where the
                numerator and denominator of each fraction will always be in the
                range [1, 10]. If the denominator is 1, it means this fraction
                is actually an integer in a fraction format defined above.
                <br />
                The number of given fractions will be in the range [1, 10].
                <br />
                The numerator and denominator of the final result are guaranteed
                to be valid and in the range of 32-bit int.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Java: {
                code: `
                // -1/2+1/2
                class Solution {
                  public String fractionAddition(String expression) {
                    Scanner sc = new Scanner(expression).useDelimiter("/|(?=[+-])");
                    int A = 0;
                    int B = 1;
                    while (sc.hasNext()) {
                      final int a = sc.nextInt();
                      final int b = sc.nextInt();
                      A = A * b + a * B;
                      B *= b;
                      final int g = gcd(A, B);
                      A /= g;
                      B /= g;
                    }
                
                    return A + "/" + B;
                  }
                
                  private int gcd(int a, int b) {
                    return a == 0 ? Math.abs(b) : gcd(b % a, a);
                  }
                }
                `,
                output: `0/1`,
              },
            }}
          />
        ),
      },
      q485: {
        title: "Q593. Valid Square (Q485)",
        content: (
          <Comp
            title="Q593. Valid Square (Q485)"
            content1={
              <>
                Given the coordinates of four points in 2D space p1, p2, p3 and
                p4, return true if the four points construct a square.
                <br />
                The coordinate of a point pi is represented as [xi, yi]. The
                input is not given in any order.
                <br />A valid square has four equal sides with positive length
                and four equal angles (90-degree angles).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: p1 = [0,0], p2 = [1,1], p3 = [1,0], p4 = [0,1]
                    <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: p1 = [0,0], p2 = [1,1], p3 = [1,0], p4 = [0,12]
                    <br />
                    Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: p1 = [1,0], p2 = [-1,0], p3 = [0,1], p4 = [0,-1]
                    <br />
                    Output: true
                  </>
                ),
              },
            ]}
            constraints={
              <>
                p1.length == p2.length == p3.length == p4.length == 2 <br />
                -10^4 &lt;= xi, yi &lt;= 10^4
              </>
            }
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} p1
                * @param {number[]} p2
                * @param {number[]} p3
                * @param {number[]} p4
                * @return {boolean}
                */
               var validSquare = function(p1, p2, p3, p4) {
                 const distSet = new Set();
                 const points = [p1, p2, p3, p4];
                 for (let i = 0; i < 4; ++i)
                   for (let j = i + 1; j < 4; ++j)
                     distSet.add(dist(points[i], points[j]));
                 return !distSet.has(0) && distSet.size == 2;  
               };
               
               function dist(p1, p2) {
                 return (p1[0] - p2[0]) * (p1[0] - p2[0]) + (p1[1] - p2[1]) * (p1[1] - p2[1]);
               }
               
               console.log(validSquare([0,0],[1,1],[1,0],[0,1]))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q486: {
        title: "Q594. Longest Harmonious Subsequence (Q486)",
        content: (
          <Comp
            title="Q594. Longest Harmonious Subsequence (Q486)"
            content1={
              <>
                We define a harmonious array as an array where the difference
                between its maximum value and its minimum value is exactly 1.
                <br />
                Given an integer array nums, return the length of its longest
                harmonious subsequence among all its possible subsequences.
                <br />A subsequence of array is a sequence that can be derived
                from the array by deleting some or no elements without changing
                the order of the remaining elements.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,3,2,2,5,2,3,7] <br />
                    Output: 5 <br />
                    Explanation: The longest harmonious subsequence is
                    [3,2,2,2,3].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4] <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,1,1,1] <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 2 * 10^4 <br />
                -10^9 &lt;= nums[i] &lt;= 10^9
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var findLHS = function(nums) {
                 let res = 0;
                 const count = {};
                 for (let num of nums)
                   count[num] = (count[num] || 0) + 1;
                 for (let num in count){
                   if ((+num + 1) in count)
                   res = Math.max(res, count[+num] + count[+num + 1]);
                 }
                 return res;   
               };
               
               console.log(findLHS([1,2,3,4]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q487: {
        title: "Q596. Classes More Than 5 Students (Q487)",
        content: (
          <Comp
            title="Q596. Classes More Than 5 Students (Q487)"
            content1={
              <>
                <b> SQL Schema</b>
                <br />
                <b>Table: World</b>
                <pre>
                  {`
+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| name        | varchar |
| continent   | varchar |
| area        | int     |
| population  | int     |
| gdp         | int     |
+-------------+---------+
  `}
                </pre>
                name is the primary key column for this table. Each row of this
                table gives information about the name of a country, the
                continent to which it belongs, its area, the population, and its
                GDP value.
                <br />
                A country is big if: it has an area of at l <br />
                east three million (i.e., 3000000 km2), or <br />
                it has a population of at least twenty-five million (i.e.,
                25000000). <br />
                Write an SQL query to report the name, population, and area of
                the big countries.
                <br />
                Return the result table in any order.
                <br />
                The query result format is in the following example.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: <br />
                    World table:
                    <pre>
                      {`
+-------------+-----------+---------+------------+--------------+
| name        | continent | area    | population | gdp          |
+-------------+-----------+---------+------------+--------------+
| Afghanistan | Asia      | 652230  | 25500100   | 20343000000  |
| Albania     | Europe    | 28748   | 2831741    | 12960000000  |
| Algeria     | Africa    | 2381741 | 37100000   | 188681000000 |
| Andorra     | Europe    | 468     | 78115      | 3712000000   |
| Angola      | Africa    | 1246700 | 20609294   | 100990000000 |
+-------------+-----------+---------+------------+--------------+  
  `}
                    </pre>
                    Output:
                    <pre>
                      {`
+-------------+------------+---------+
| name        | population | area    |
+-------------+------------+---------+
| Afghanistan | 25500100   | 652230  |
| Algeria     | 37100000   | 2381741 |
+-------------+------------+---------+  
  `}
                    </pre>
                  </>
                ),
              },
            ]}
            codes={{
              Mysql: {
                code: `
                select name, population, area
                from world
                where area >= 3000000 or population >= 25000000`,
                output: `{"headers": ["name", "population", "area"], "values": [["Afghanistan", 25500100, 652230], ["Algeria", 37100000, 2381741]]}`,
              },
            }}
          />
        ),
      },
      q488: {
        title: "Q596. Classes More Than 5 Students (Q488)",
        content: (
          <Comp
            title="Q596. Classes More Than 5 Students (Q488)"
            content1={
              <>
                <b> SQL Schema</b>
                <br />
                <b>Table: Courses</b>
                <pre>
                  {`
+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| student     | varchar |
| class       | varchar |
+-------------+---------+
`}
                  (student, class) is the primary key column for this table.
                  Each row of this table indicates the name of a student and the
                  class in which they are enrolled.
                  <br />
                  Write an SQL query to report all the classes that have at
                  least five students.
                  <br />
                  Return the result table in any order.
                  <br />
                  The query result format is in the following example.
                </pre>
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: <br />
                    Courses table:
                    <br />
                    <pre>
                      {`
+---------+----------+
| student | class    |
+---------+----------+
| A       | Math     |
| B       | English  |
| C       | Math     |
| D       | Biology  |
| E       | Math     |
| F       | Computer |
| G       | Math     |
| H       | Math     |
| I       | Math     |
+---------+----------+
                  `}
                    </pre>
                    Output:
                    <pre>
                      {`
+---------+
| class   |
+---------+
| Math    |
+---------+  
  `}
                    </pre>
                    <b>Explanation: </b>
                    <br />
                    - Math has 6 students, so we include it.
                    <br />
                    - English has 1 student, so we do not include it.
                    <br />
                    - Biology has 1 student, so we do not include it.
                    <br />- Computer has 1 student, so we do not include it.
                  </>
                ),
              },
            ]}
            codes={{
              Mysql: {
                code: `
                select class
                from courses
                group by class
                having count(student) >= 5`,
                output: `{"headers": ["class"], "values": [["Math"]]}`,
              },
            }}
          />
        ),
      },
      q489: {
        title: "Q598. Range Addition II (Q489)",
        content: (
          <Comp
            title="Q598. Range Addition II (Q489)"
            content1={
              <>
                You are given an m x n matrix M initialized with all 0's and an
                array of operations ops, where ops[i] = [ai, bi] means M[x][y]
                should be incremented by one for all 0 &lt;= x &lt; ai and 0
                &lt;= y &lt; bi.
                <br />
                Count and return the number of maximum integers in the matrix
                after performing all the operations.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode598,
                content: (
                  <>
                    Input: m = 3, n = 3,
                    <br /> ops = [[2,2],[3,3]]
                    <br />
                    Output: 4<br />
                    Explanation: The maximum integer in M is 2, and there are
                    four of it in M. So return 4.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: m = 3, n = 3, <br />
                    ops =
                    [[2,2],[3,3],[3,3],[3,3],[2,2],[3,3],[3,3],[3,3],[2,2],[3,3],[3,3],[3,3]]
                    Output: 4
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: m = 3, n = 3,
                    <br /> ops = [] Output: 9
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= m, n &lt;= 4 * 10^4 <br />
                0 &lt;= ops.length &lt;= 104 <br />
                ops[i].length == 2 <br />
                1 &lt;= ai &lt;= m <br />1 &lt;= bi &lt;= n
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} m
                * @param {number} n
                * @param {number[][]} ops
                * @return {number}
                */
               var maxCount = function(m, n, ops) {
                 let minY = m;
                 let minX = n;
                 for (let op of ops) {
                   minY = Math.min(minY, op[0]);
                   minX = Math.min(minX, op[1]);
                 }
                 return minX * minY;    
               };
               
               console.log(maxCount(3,3,[[2,2],[3,3]]))
               `,
                output: `4`,
              },
            }}
          />
        ),
      },
      q490: {
        title: "Q599. Minimum Index Sum of Two Lists (Q490)",
        content: (
          <Comp
            title="Q599. Minimum Index Sum of Two Lists (Q490)"
            content1={
              <>
                Suppose Andy and Doris want to choose a restaurant for dinner,
                and they both have a list of favorite restaurants represented by
                strings.
                <br />
                You need to help them find out their common interest with the
                least list index sum. If there is a choice tie between answers,
                output all of them with no order requirement. You could assume
                there always exists an answer.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: list1 = ["Shogun","Tapioca Express","Burger
                    King","KFC"], list2 = ["Piatti","The Grill at Torrey
                    Pines","Hungry Hunter Steakhouse","Shogun"]
                    <br /> Output: ["Shogun"]
                    <br />
                    Explanation: The only restaurant they both like is "Shogun".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: list1 = ["Shogun","Tapioca Express","Burger
                    King","KFC"], list2 = ["KFC","Shogun","Burger King"]
                    <br /> Output: ["Shogun"]
                    <br />
                    Explanation: The restaurant they both like and have the
                    least index sum is "Shogun" with index sum 1 (0+1).
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= list1.length, list2.length &lt;= 1000 <br />
                1 &lt;= list1[i].length, list2[i].length &lt;= 30
                <br />
                list1[i] and list2[i] consist of spaces ' ' and English letters.
                <br />
                All the stings of list1 are unique.
                <br />
                All the stings of list2 are unique.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} list1
                * @param {string[]} list2
                * @return {string[]}
                */
               var findRestaurant = function(list1, list2) {
                 let res = [];
                 const m = {};
                 let minSum = Number.MAX_SAFE_INTEGER;
                 for (let i = 0; i < list1.length; ++i)
                   m[list1[i]] = i;
                 for (let i = 0; i < list2.length; ++i) {
                   let restaurant = list2[i];
                   if (restaurant in m) {
                     let sum = m[restaurant] + i;
                     if (sum < minSum) {
                       minSum = sum;
                       res = [];
                       res.push(restaurant);
                     } else if (sum == minSum) {
                       res.push(restaurant);
                     }
                   }
                 }
                 return res;
                };
               
               console.log(findRestaurant(["Shogun","Tapioca Express","Burger King","KFC"],["Piatti","The Grill at Torrey Pines","Hungry Hunter Steakhouse","Shogun"]))`,
                output: `[ 'Shogun' ]`,
              },
            }}
          />
        ),
      },
      q491: {
        title: "Q600. Non-negative Integers without Consecutive Ones (Q491)",
        content: (
          <Comp
            title="Q600. Non-negative Integers without Consecutive Ones (Q491)"
            content1={
              <>
                Given a positive integer n, return the number of the integers in
                the range [0, n] whose binary representations do not contain
                consecutive ones.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 5 <br />
                    Output: 5 <br />
                    Explanation: <br />
                    Here are the non-negative integers &lt;= 5 with their
                    corresponding binary representations: 0 : 0 <br />
                    1 : 1 <br />
                    2 : 10 <br />
                    3 : 11 <br />
                    4 : 100 <br />
                    5 : 101 <br />
                    Among them, only integer 3 disobeys the rule (two
                    consecutive ones) and the other 5 satisfy the rule.{" "}
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1 <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2 <br />
                    Output: 3
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 10^9</>}
            tc="log n"
            sc="log n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} len
                * @return {number}
                */
               var findIntegers = function(n) {
                 const bits = [];
                 for (; n > 0; n >>= 1)
                   bits.push(n & 1);
                 let len = bits.length;
                 let zero = Array(len).fill(0);
                 let one = Array(len).fill(0);
                 zero[0] = 1;
                 one[0] = 1;
                 for (let i = 1; i < len; ++i) {
                   zero[i] = zero[i - 1] + one[i - 1];
                   one[i] = zero[i - 1];
                 }
                 let ans = zero[len - 1] + one[len - 1];
                 for (let i = len - 2; i >= 0; --i) {
                   if (bits[i] == '1' && bits[i + 1] == '1')
                     break;
                   if (bits[i] == '0' && bits[i + 1] == '0')
                     ans -= one[i];
                 }
                 return ans;    
               };
               
               console.log(findIntegers(5))`,
                output: `5`,
              },
            }}
          />
        ),
      },
      q492: {
        title: "Q601. Human Traffic of Stadium (Q492)",
        content: (
          <Comp
            title="Q601. Human Traffic of Stadium (Q492)"
            content1={
              <>
                <b>SQL Schema</b>
                <br />
                <b>Table: Stadium</b>
                <pre>
                  {`
+---------------+---------+
| Column Name   | Type    |
+---------------+---------+
| id            | int     |
| visit_date    | date    |
| people        | int     |
+---------------+---------+
              `}
                </pre>
                visit_date is the primary key for this table. <br />
                Each row of this table contains the visit date and visit id to
                the stadium with the number of people during the visit.
                <br />
                No two rows will have the same visit_date, and as the id
                increases, the dates increase as well.
                <br />
                Write an SQL query to display the records with three or more
                rows with consecutive id's, and the number of people is greater
                than or equal to 100 for each.
                <br />
                Return the result table ordered by visit_date in ascending
                order.
                <br />
                The query result format is in the following example.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: <br />
                    Stadium table:
                    <pre>
                      {`
+------+------------+-----------+
| id   | visit_date | people    |
+------+------------+-----------+
| 1    | 2017-01-01 | 10        |
| 2    | 2017-01-02 | 109       |
| 3    | 2017-01-03 | 150       |
| 4    | 2017-01-04 | 99        |
| 5    | 2017-01-05 | 145       |
| 6    | 2017-01-06 | 1455      |
| 7    | 2017-01-07 | 199       |
| 8    | 2017-01-09 | 188       |
+------+------------+-----------+                  
                  `}
                    </pre>
                    <br />
                    Output: <br />
                    <pre>{`
+------+------------+-----------+
| id   | visit_date | people    |
+------+------------+-----------+
| 5    | 2017-01-05 | 145       |
| 6    | 2017-01-06 | 1455      |
| 7    | 2017-01-07 | 199       |
| 8    | 2017-01-09 | 188       |
+------+------------+-----------+
`}</pre>
                    Explanation: <br />
                    The four rows with ids 5, 6, 7, and 8 have consecutive ids
                    and each of them has &gt;= 100 people attended. Note that
                    row 8 was included even though the visit_date was not the
                    next day after row 7.
                    <br />
                    The rows with ids 2 and 3 are not included because we need
                    at least three consecutive ids.
                  </>
                ),
              },
            ]}
            codes={{
              Mysql: {
                code: `with cte
                as
                (select id,visit_date, people, id - row_number() over (order by id ) diff
                 from stadium
                where people >=  100)
                
                
                select id, visit_date, people from cte
                where diff in (
                select diff from cte
                    group by diff
                    having count(*)>=3)
                    order by 2`,
                output: `{"headers": ["id", "visit_date", "people"], "values": [[5, "2017-01-05", 145], [6, "2017-01-06", 1455], [7, "2017-01-07", 199], [8, "2017-01-09", 188]]}`,
              },
            }}
          />
        ),
      },
      q493: {
        title: "Q605. Can Place Flowers (Q493)",
        content: (
          <Comp
            title="Q605. Can Place Flowers (Q493)"
            content1={
              <>
                You have a long flowerbed in which some of the plots are
                planted, and some are not. However, flowers cannot be planted in
                adjacent plots.
                <br />
                Given an integer array flowerbed containing 0's and 1's, where 0
                means empty and 1 means not empty, and an integer n, return if n
                new flowers can be planted in the flowerbed without violating
                the no-adjacent-flowers rule
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: flowerbed = [1,0,0,0,1], n = 1<br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: flowerbed = [1,0,0,0,1], n = 2<br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= flowerbed.length &lt;= 2 * 10^4 <br />
                flowerbed[i] is 0 or 1.
                <br />
                There are no two adjacent flowers in flowerbed.
                <br />0 &lt;= n &lt;= flowerbed.length
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} flowerbed
                * @param {number} n
                * @return {boolean}
                */
               var canPlaceFlowers = function(flowerbed, n) {
                 if (n == 0)
                     return true;
                 for (let i = 0; i < flowerbed.length; ++i)
                   if (flowerbed[i] == 0 && (i == 0 || flowerbed[i - 1] == 0) &&
                       (i == flowerbed.length - 1 || flowerbed[i + 1] == 0)) {
                     flowerbed[i] = 1;
                     if (--n == 0)
                       return true;
                   }
                 return false;
               };
               
               console.log(canPlaceFlowers([1,0,0,0,1],1))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q494: {
        title: "Q606. Construct String from Binary Tree (Q494)",
        content: (
          <Comp
            title="Q606. Construct String from Binary Tree (Q494)"
            content1={
              <>
                Given the root of a binary tree, construct a string consisting
                of parenthesis and integers from a binary tree with the preorder
                traversal way, and return it.
                <br />
                Omit all the empty parenthesis pairs that do not affect the
                one-to-one mapping relationship between the string and the
                original binary tree.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode606,
                content: (
                  <>
                    Input: root = [1,2,3,4]
                    <br />
                    Output: "1(2(4))(3)"
                    <br />
                    Explanation: Originally, it needs to be "1(2(4)())(3()())",
                    but you need to omit all the unnecessary empty parenthesis
                    pairs. And it will be "1(2(4))(3)"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1,2,3,null,4]
                    <br />
                    Output: "1(2()(4))(3)"
                    <br />
                    Explanation: Almost the same as the first example, except we
                    cannot omit the first parenthesis pair to break the
                    one-to-one mapping relationship between the input and the
                    output.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 10^4].
                <br />
                -1000 &lt;= Node.val &lt;= 1000
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
              }
              
              /**
               * @param {TreeNode} root
               * @return {string}
               */
              var tree2str = function(root) {
                return dfs(root);  
              };
              
              function dfs(root){
               if (!root)
                    return "";
                  if (root.right
                    return root.val + "(" + dfs(root.left) + ")(" + dfs(root.right) + ")";
                  if (root.left)
                    return root.val + "(" + dfs(root.left) + ")";
                  return root.val + "";
              }
              
const t = new TreeNode(1)
t.left = new TreeNode(2)
t.left.left = new TreeNode(4)
t.right = new TreeNode(3)
console.log(tree2str(t))`,
                output: `1(2(4))(3`,
              },
            }}
          />
        ),
      },
      q495: {
        title: "Q609. Find Duplicate File in System (Q495)",
        content: (
          <Comp
            title="Q609. Find Duplicate File in System (Q495)"
            content1={
              <>
                Given a list paths of directory info, including the directory
                path, and all the files with contents in this directory, return
                all the duplicate files in the file system in terms of their
                paths. You may return the answer in any order.
                <br />
                A group of duplicate files consists of at least two files that
                have the same content.
                <br />
                A single directory info string in the input list has the
                following format:
                <br />
                "root/d1/d2/.../dm f1.txt(f1_content) f2.txt(f2_content) ...
                fn.txt(fn_content)"
                <br />
                It means there are n files (f1.txt, f2.txt ... fn.txt) with
                content (f1_content, f2_content ... fn_content) respectively in
                the directory "root/d1/d2/.../dm". Note that n >= 1 and m >= 0.
                If m = 0, it means the directory is just the root directory.
                <br />
                The output is a list of groups of duplicate file paths. For each
                group, it contains all the file paths of the files that have the
                same content. A file path is a string that has the following
                format:
                <br />
                "directory_path/file_name.txt"
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: paths = ["root/a 1.txt(abcd) 2.txt(efgh)","root/c
                    3.txt(abcd)","root/c/d 4.txt(efgh)"]
                    <br /> Output:
                    [["root/a/2.txt","root/c/d/4.txt"],["root/a/1.txt","root/c/3.txt"]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: paths = ["root/a 1.txt(abcd) 2.txt(efgh)","root/c
                    3.txt(abcd)","root/c/d 4.txt(efgh)"]
                    <br /> Output:
                    [["root/a/2.txt","root/c/d/4.txt"],["root/a/1.txt","root/c/3.txt"]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= paths.length &lt;= 2 * 104
                <br /> 1 &lt;= paths[i].length &lt;= 3000
                <br /> 1 &lt;= sum(paths[i].length) &lt;= 5 * 10^5
                <br /> paths[i] consist of English letters, digits, '/', '.',
                '(', ')', and ' '.
                <br /> You may assume no files or directories share the same
                name in the same directory.
                <br /> You may assume each given directory info represents a
                unique directory. A single blank space separates the directory
                path and file info.
              </>
            }
            fp={
              <>
                <b>Follow up :</b>Imagine you are given a real file system, how
                will you search files? DFS or BFS?
                <br />
                If the file content is very large (GB level), how will you
                modify your solution?
                <br />
                If you can only read the file by 1kb each time, how will you
                modify your solution?
                <br />
                What is the time complexity of your modified solution? What is
                the most time-consuming part and memory-consuming part of it?
                How to optimize?
                <br />
                How to make sure the duplicated files you find are not false
                positive?
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} paths
                * @return {string[][]}
                */
               var findDuplicate = function(paths) {
                 const res = [];
                 const m = {}
               
                 for (let path of paths) {
                   const words = path.split(" ");
                   const rootPath = words[0]; 
                   for (let i = 1; i < words.length; ++i) {
                     const fileAndContent = words[i]; 
                     let l = fileAndContent.indexOf('(');
                     let r = fileAndContent.indexOf(')');
                     let file = fileAndContent.substring(0, l);
                     let content = fileAndContent.substring(l + 1, r);
                     let filePath = rootPath + '/' + file;
                     m[content] = !m[content] ? [filePath] : [...m[content],filePath]
                   }
                 }
                 for (let filePaths of Object.values(m))
                   if (filePaths.length > 1)
                     res.push(filePaths);
                 return res;
               };
               
               console.log(findDuplicate(["root/a 1.txt(abcd) 2.txt(efgh)","root/c 3.txt(abcd)","root/c/d 4.txt(efgh)"]))`,
                output: `[
                  [ 'root/a/1.txt', 'root/c/3.txt' ],
                  [ 'root/a/2.txt', 'root/c/d/4.txt' ]
                ]`,
              },
            }}
          />
        ),
      },
      q496: {
        title: "Q611. Valid Triangle Number (Q496)",
        content: (
          <Comp
            title="Q611. Valid Triangle Number (Q496)"
            content1={
              <>
                Given an integer array nums, return the number of triplets
                chosen from the array that can make triangles if we take them as
                side lengths of a triangle.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [2,2,3,4]
                    <br />
                    Output: 3<br />
                    Explanation: Valid combinations are: <br />
                    2,3,4 (using the first 2)
                    <br />
                    2,3,4 (using the second 2)
                    <br />
                    2,2,3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [4,2,3,4]
                    <br />
                    Output: 4
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 1000 <br />0 &lt;= nums[i] &lt;= 1000
              </>
            }
            tc="n^2"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var triangleNumber = function(nums) {
                if (nums.length < 3) return 0;
                let res = 0;
                nums = nums.sort((a,b)=>a-b)
                for (let k = nums.length - 1; k > 1; --k) {
                  let i = 0;
                  let j = k - 1;
                  while (i < j)
                    if (nums[i] + nums[j] > nums[k]) {
                      res += j - i;
                      --j;
                    } else {
                      ++i;
                    }
                }
                return res;   
               };
               
               console.log(triangleNumber([2,2,3,4]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q497: {
        title: "Q617. Merge Two Binary Trees (Q497)",
        content: (
          <Comp
            title="Q617. Merge Two Binary Trees (Q497)"
            content1={
              <>
                You are given two binary trees root1 and root2.
                <br />
                Imagine that when you put one of them to cover the other, some
                nodes of the two trees are overlapped while the others are not.
                You need to merge the two trees into a new binary tree. The
                merge rule is that if two nodes overlap, then sum node values up
                as the new value of the merged node. Otherwise, the NOT null
                node will be used as the node of the new tree.
                <br />
                Return the merged tree.
                <br />
                Note: The merging process must start from the root nodes of both
                trees.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode617,
                content: (
                  <>
                    Input: root1 = [1,3,2,5], root2 = [2,1,3,null,4,null,7]
                    <br /> Output: [3,4,5,5,4,null,7]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root1 = [1], root2 = [1,2]
                    <br /> Output: [2,2]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in both trees is in the range [0, 2000].
                <br /> -10^4 &lt;= Node.val &lt;= 10^4
              </>
            }
            tc="n"
            sc="log n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root1
                 * @param {TreeNode} root2
                 * @return {TreeNode}
                 */
                var mergeTrees = function(root1, root2) {
                  if (!root1 && !root2) return null;
                  const val = (!root1 ? 0 : root1.val) + (!root2 ? 0 : root2.val);
                  const root = new TreeNode(val);
                  root.left = mergeTrees(!root1 ? null : root1.left, !root2 ? null : root2.left);
                  root.right = mergeTrees(!root1 ? null : root1.right, !root2? null : root2.right);
                  return root;
                };
                
                const t1 = new TreeNode(1)
                t1.left = new TreeNode(3)
                t1.left.left = new TreeNode(5)
                t1.right = new TreeNode(2)
                const t2 = new TreeNode(2)
                t2.left = new TreeNode(1)
                t2.left.right = new TreeNode(4)
                t2.right = new TreeNode(3)
                t2.right.right = new TreeNode(7)
                console.log(mergeTrees(t1,t2))`,
                output: `TreeNode {
                  val: 3,
                  left: TreeNode {
                    val: 4,
                    left: TreeNode { val: 5, left: null, right: null },
                    right: TreeNode { val: 4, left: null, right: null }
                  },
                  right: TreeNode {
                    val: 5,
                    left: null,
                    right: TreeNode { val: 7, left: null, right: null }
                  }
                }`,
              },
            }}
          />
        ),
      },
      q498: {
        title: "Q620. Not Boring Movies (Q498)",
        content: (
          <Comp
            title="Q620. Not Boring Movies (Q498)"
            content1={
              <>
                <b>SQL Schema</b>
                <br />
                <b>Table: Cinema</b>
                <pre>
                  {`    
+----------------+----------+
| Column Name    | Type     |
+----------------+----------+
| id             | int      |
| movie          | varchar  |
| description    | varchar  |
| rating         | float    |
+----------------+----------+               
                  `}
                </pre>
                id is the primary key for this table.
                <br />
                Each row contains information about the name of a movie, its
                genre, and its rating.
                <br />
                rating is a 2 decimal places float in the range [0, 10]
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: <br />
                    Cinema table: <br />
                    <pre>
                      {`
+----+------------+-------------+--------+
| id | movie      | description | rating |
+----+------------+-------------+--------+
| 1  | War        | great 3D    | 8.9    |
| 2  | Science    | fiction     | 8.5    |
| 3  | irish      | boring      | 6.2    |
| 4  | Ice song   | Fantacy     | 8.6    |
| 5  | House card | Interesting | 9.1    |
+----+------------+-------------+--------+  
  `}
                    </pre>
                    Output: <br />
                    <pre>
                      {`
+----+------------+-------------+--------+
| id | movie      | description | rating |
+----+------------+-------------+--------+
| 5  | House card | Interesting | 9.1    |
| 1  | War        | great 3D    | 8.9    |
+----+------------+-------------+--------+  
  `}
                    </pre>
                    Explanation: <br />
                    We have three movies with odd-numbered IDs: 1, 3, and 5. The
                    movie with ID = 3 is boring so we do not include it in the
                    answer.
                  </>
                ),
              },
            ]}
            codes={{
              Mysql: {
                code: `SELECT * FROM Cinema 
                WHERE description NOT LIKE "%boring%" AND id % 2 = 1
                ORDER BY rating DESC;`,
                output: `{"headers": ["id", "movie", "description", "rating"], "values": [[5, "House card", "Interesting", 9.1], [1, "War", "great 3D", 8.9]]}`,
              },
            }}
          />
        ),
      },
      q499: {
        title: "Q621. Task Scheduler (Q499)",
        content: (
          <Comp
            title="Q621. Task Scheduler (Q499)"
            content1={
              <>
                Given a characters array tasks, representing the tasks a CPU
                needs to do, where each letter represents a different task.
                Tasks could be done in any order. Each task is done in one unit
                of time. For each unit of time, the CPU could complete either
                one task or just be idle.
                <br />
                However, there is a non-negative integer n that represents the
                cooldown period between two same tasks (the same letter in the
                array), that is that there must be at least n units of time
                between any two same tasks.
                <br />
                Return the least number of units of times that the CPU will take
                to finish all the given tasks.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: tasks = ["A","A","A","B","B","B"], n = 2
                    <br /> Output: 8<br />
                    Explanation: <br />
                    A -&gt; B -&gt; idle -&gt; A -&gt; B -&gt; idle -&gt; A
                    -&gt; B<br />
                    There is at least 2 units of time between any two same
                    tasks.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: tasks = ["A","A","A","B","B","B"], n = 2
                    <br /> Output: 8
                    <br /> Explanation:
                    <br /> A -&gt; B -&gt; idle -&gt; A -&gt; B -&gt; idle -&gt;
                    A -&gt; B
                    <br /> There is at least 2 units of time between any two
                    same tasks.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: tasks =
                    ["A","A","A","A","A","A","B","C","D","E","F","G"], n = 2
                    <br /> Output: 16
                    <br /> Explanation:
                    <br /> One possible solution is
                    <br /> A -&gt; B -&gt; C -&gt; A -&gt; D -&gt; E -&gt; A
                    -&gt; F -&gt; G -&gt; A -&gt; idle -&gt; idle -&gt; A -&gt;
                    idle -&gt; idle -&gt; A
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= task.length &lt;= 10^4 <br />
                tasks[i] is upper-case English letter. <br />
                The integer n is in the range [0, 100].
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {character[]} tasks
                * @param {number} n
                * @return {number}
                */
               var leastInterval = function(tasks, n) {
                 const count = Array(26).fill(0);
                 for (let task of tasks)
                   ++count[task.charCodeAt(0) - 65];
                 const maxFreq = Math.max(...count)
                 const maxFreqTaskOccupy = (maxFreq - 1) * (n + 1);
                 const nMaxFreq = count.filter(c => c == maxFreq).length;
                 return Math.max(maxFreqTaskOccupy + nMaxFreq, tasks.length);
               };
               console.log(leastInterval(["A","A","A","B","B","B"],2))`,
                output: `8`,
              },
            }}
          />
        ),
      },
      q500: {
        title: "Q622. Design Circular Queue (Q500)",
        content: (
          <Comp
            title="Q622. Design Circular Queue (Q500)"
            content1={
              <>
                Design your implementation of the circular queue. The circular
                queue is a linear data structure in which the operations are
                performed based on FIFO (First In First Out) principle and the
                last position is connected back to the first position to make a
                circle. It is also called "Ring Buffer".
                <br />
                One of the benefits of the circular queue is that we can make
                use of the spaces in front of the queue. In a normal queue, once
                the queue becomes full, we cannot insert the next element even
                if there is a space in front of the queue. But using the
                circular queue, we can use the space to store new values.
                <br />
                Implementation the MyCircularQueue class:
                <br />
                MyCircularQueue(k) Initializes the object with the size of the
                queue to be k.
                <br /> int Front() Gets the front item from the queue. If the
                queue is empty, return -1.
                <br /> int Rear() Gets the last item from the queue. If the
                queue is empty, return -1.
                <br /> boolean enQueue(int value) Inserts an element into the
                circular queue. Return true if the operation is successful.
                <br /> boolean deQueue() Deletes an element from the circular
                queue. Return true if the operation is successful.
                <br /> boolean isEmpty() Checks whether the circular queue is
                empty or not.
                <br /> boolean isFull() Checks whether the circular queue is
                full or not.
                <br /> You must solve the problem without using the built-in
                queue data structure in your programming language.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br />
                    ["MyCircularQueue", "enQueue", "enQueue", "enQueue",
                    "enQueue", "Rear", "isFull", "deQueue", "enQueue", "Rear"]
                    <br /> [[3], [1], [2], [3], [4], [], [], [], [4], []]
                    <br /> Output
                    <br /> [null, true, true, true, false, 3, true, true, true,
                    4]
                    <br /> Explanation
                    <br /> MyCircularQueue myCircularQueue = new
                    MyCircularQueue(3);
                    <br /> myCircularQueue.enQueue(1); // return True
                    <br /> myCircularQueue.enQueue(2); // return True
                    <br /> myCircularQueue.enQueue(3); // return True
                    <br /> myCircularQueue.enQueue(4); // return False
                    <br /> myCircularQueue.Rear(); // return 3
                    <br /> myCircularQueue.isFull(); // return True
                    <br /> myCircularQueue.deQueue(); // return True
                    <br /> myCircularQueue.enQueue(4); // return True
                    <br /> myCircularQueue.Rear(); // return 4
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= k &lt;= 1000 <br />
                0 &lt;= value &lt;= 1000 <br />
                At most 3000 calls will be made to enQueue, deQueue, Front,
                Rear, isEmpty, and isFull.
              </>
            }
            tc="1"
            sc="n"
            codes={{
              Java: {
                code: `
                // ["MyCircularQueue","enQueue","enQueue","enQueue","enQueue","Rear","isFull","deQueue","enQueue","Rear"]
                // [[3],[1],[2],[3],[4],[],[],[],[4],[]]
                class MyCircularQueue {
                  public MyCircularQueue(int k) {
                    this.k = k;
                    this.q = new int[k];
                    this.rear = k - 1;
                  }
                  public boolean enQueue(int value) {
                    if (isFull())
                      return false;
                
                    rear = ++rear % k;
                    q[rear] = value;
                    ++size;
                    return true;
                  }
                  public boolean deQueue() {
                    if (isEmpty())
                      return false;
                    front = ++front % k;
                    --size;
                    return true;
                  }
                  public int Front() {
                    return isEmpty() ? -1 : q[front];
                  }
                  public int Rear() {
                    return isEmpty() ? -1 : q[rear];
                  }
                  public boolean isEmpty() {
                    return size == 0;
                  }
                  public boolean isFull() {
                    return size == k;
                  }
                
                  private final int k;
                  private int[] q;
                  private int size = 0;
                  private int front = 0;
                  private int rear;
                }
                `,
                output: `[null,true,true,true,false,3,true,true,true,4]`,
              },
            }}
          />
        ),
      },
      q501: {
        title: "Q623. Add One Row to Tree (Q501)",
        content: (
          <Comp
            title="Q623. Add One Row to Tree (Q501)"
            content1={
              <>
                Given the root of a binary tree and two integers val and depth,
                add a row of nodes with value val at the given depth depth.
                <br />
                Note that the root node is at depth 1.
                <br />
                The adding rule is:
                <br />
                Given the integer depth, for each not null tree node cur at the
                depth depth - 1, create two tree nodes with value val as cur's
                left subtree root and right subtree root.
                <br /> cur's original left subtree should be the left subtree of
                the new left subtree root.
                <br /> cur's original right subtree should be the right subtree
                of the new right subtree root.
                <br /> If depth == 1 that means there is no depth depth - 1 at
                all, then create a tree node with value val as the new root of
                the whole original tree, and the original tree is the new root's
                left subtree.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode623,
                content: (
                  <>
                    Input: root = [4,2,6,3,1,5], val = 1,
                    <br /> depth = 2<br />
                    Output: [4,1,1,2,null,null,6,3,1,5]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [4,2,null,3,1], val = 1, <br />
                    depth = 3<br />
                    Output: [4,2,null,1,1,3,null,null,1]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 10^4].
                <br />
                The depth of the tree is in the range [1, 10^4].
                <br />
                -100 &lt;= Node.val &lt;= 100
                <br />
                -10^5 &lt;= val &lt;= 10^5
                <br />1 &lt;= depth &lt;= the depth of tree + 1
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root
                 * @param {number} val
                 * @param {number} depth
                 * @return {TreeNode}
                 */
                var addOneRow = function(root, val, depth) {
                  if (depth == 1) {
                    const newRoot = new TreeNode(val);
                    newRoot.left = root;
                    return newRoot;
                  }
                  let d = 0;
                  const q = [root];
                  while (q.length) {
                    ++d;
                    for (let size = q.length; size > 0; --size) {
                      let node = q.shift();
                      if (node.left != null)
                        q.push(node.left);
                      if (node.right != null)
                        q.push(node.right);
                      if (d == depth - 1) {
                        const cachedLeft = node.left;
                        const cachedRight = node.right;
                        node.left = new TreeNode(val);
                        node.right = new TreeNode(val);
                        node.left.left = cachedLeft;
                        node.right.right = cachedRight;
                      }
                    }
                    if (depth == d - 1)
                      break;
                  }
                  return root;  
                };
                
                const t = new TreeNode(4)
                t.left = new TreeNode(2)
                t.left.left = new TreeNode(3)
                t.left.right = new TreeNode(1)
                t.right = new TreeNode(6)
                t.right.right = new TreeNode(5)
                console.log(addOneRow(t,1,2))`,
                output: `TreeNode {
                  val: 4,
                  left: TreeNode {
                    val: 1,
                    left: TreeNode { val: 2, left: [TreeNode], right: [TreeNode] },
                    right: null
                  },
                  right: TreeNode {
                    val: 1,
                    left: null,
                    right: TreeNode { val: 6, left: null, right: [TreeNode] }
                  }
                }`,
              },
            }}
          />
        ),
      },
      q502: {
        title: "Q626. Exchange Seats (Q502)",
        content: (
          <Comp
            title="Q626. Exchange Seats (Q502)"
            content1={
              <>
                <b>SQL Schema</b>
                <br />
                <b>Table: Seat</b>
                <pre>{`
+-------------+---------+
| Column Name | Type    |
+-------------+---------+
| id          | int     |
| name        | varchar |
+-------------+---------+
                `}</pre>
                id is the primary key column for this table. <br />
                Each row of this table indicates the name and the ID of a
                student. <br />
                id is a continuous increment. <br />
                Write an SQL query to swap the seat id of every two consecutive
                students. If the number of students is odd, the id of the last
                student is not swapped.
                <br />
                Return the result table ordered by id in ascending order.
                <br />
                The query result format is in the following example.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: <br />
                    Seat table:
                    <pre>
                      {`
+----+---------+
| id | student |
+----+---------+
| 1  | Abbot   |
| 2  | Doris   |
| 3  | Emerson |
| 4  | Green   |
| 5  | Jeames  |
+----+---------+
                  `}
                    </pre>
                    Output:
                    <br />
                    <pre>
                      {`
+----+---------+
| id | student |
+----+---------+
| 1  | Doris   |
| 2  | Abbot   |
| 3  | Green   |
| 4  | Emerson |
| 5  | Jeames  |
+----+---------+  
  `}
                    </pre>
                    Explanation: <br />
                    Note that if the number of students is odd, there is no need
                    to change the last one's seat.
                  </>
                ),
              },
            ]}
            codes={{
              Mysql: {
                code: `SELECT
                s1.id, COALESCE(s2.student, s1.student) AS student
            FROM
                seat s1
                    LEFT JOIN
                seat s2 ON ((s1.id + 1) ^ 1) - 1 = s2.id
            ORDER BY s1.id;
      `,
                output: `{"headers": ["id", "student"], "values": [[1, "Doris"], [2, "Abbot"], [3, "Green"], [4, "Emerson"], [5, "Jeames"]]}`,
              },
            }}
          />
        ),
      },
      q503: {
        title: "Q627. Swap Salary (Q503)",
        content: (
          <Comp
            title="Q627. Swap Salary (Q503)"
            content1={
              <>
                <b>SQL Schema</b>
                <br />
                <b>Table: Salary</b>
                <pre>
                  {`
+-------------+----------+
| Column Name | Type     |
+-------------+----------+
| id          | int      |
| name        | varchar  |
| sex         | ENUM     |
| salary      | int      |
+-------------+----------+
                  `}
                </pre>
                id is the primary key for this table. <br />
                The sex column is ENUM value of type ('m', 'f'). <br />
                The table contains information about an employee. <br />
                Write an SQL query to swap all 'f' and 'm' values (i.e., change
                all 'f' values to 'm' and vice versa) with a single update
                statement and no intermediate temporary tables.
                <br />
                Note that you must write a single update statement, do not write
                any select statement for this problem.
                <br />
                The query result format is in the following example.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: <br />
                    Salary table: <br />
                    <pre>
                      {`
+----+------+-----+--------+
| id | name | sex | salary |
+----+------+-----+--------+
| 1  | A    | m   | 2500   |
| 2  | B    | f   | 1500   |
| 3  | C    | m   | 5500   |
| 4  | D    | f   | 500    |
+----+------+-----+--------+  
  `}
                    </pre>
                    Output: <br />
                    <pre>{`
+----+------+-----+--------+
| id | name | sex | salary |
+----+------+-----+--------+
| 1  | A    | f   | 2500   |
| 2  | B    | m   | 1500   |
| 3  | C    | f   | 5500   |
| 4  | D    | m   | 500    |
+----+------+-----+--------+
`}</pre>
                    Explanation: <br />
                    (1, A) and (3, C) were changed from 'm' to 'f'. <br />
                    (2, B) and (4, D) were changed from 'f' to 'm'.
                  </>
                ),
              },
            ]}
            codes={{
              Mysql: {
                code: `update Salary set sex = case when sex='m' then 'f' else 'm' end                `,
                output: `{"headers": ["id", "name", "sex", "salary"], "values": [[1, "A", "f", 2500], [2, "B", "m", 1500], [3, "C", "f", 5500], [4, "D", "m", 500]]}`,
              },
            }}
          />
        ),
      },
      q504: {
        title: "Q628. Maximum Product of Three Numbers (Q504)",
        content: (
          <Comp
            title="Q628. Maximum Product of Three Numbers (Q504)"
            content1={
              <>
                Given an integer array nums, find three numbers whose product is
                maximum and return the maximum product.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,3]
                    <br />
                    Output: 6
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4]]
                    <br />
                    Output: 24
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [-1,-2,-3]]
                    <br />
                    Output: -6
                  </>
                ),
              },
            ]}
            constraints={
              <>
                3 &lt;= nums.length &lt;= 10^4]
                <br />
                -1000 &lt;= nums[i] &lt;= 1000
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var maximumProduct = function(nums) {
                 let min1 = Number.MAX_SAFE_INTEGER; 
                 let min2 = Number.MAX_SAFE_INTEGER; 
                 let max1 = Number.MIN_SAFE_INTEGER; 
                 let max2 = Number.MIN_SAFE_INTEGER; 
                 let max3 = Number.MIN_SAFE_INTEGER; 
                 for (let num of nums) {
                   if (num <= min1) {
                     min2 = min1;
                     min1 = num;
                   } else if (num <= min2) {
                     min2 = num;
                   }
                   if (num >= max1) {
                     max3 = max2;
                     max2 = max1;
                     max1 = num;
                   } else if (num >= max2) {
                     max3 = max2;
                     max2 = num;
                   } else if (num >= max3) {
                     max3 = num;
                   }
                 }
                 return Math.max(min1 * min2 * max1, max1 * max2 * max3);
               };
               
               console.log(maximumProduct([1,2,3],6))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q505: {
        title: "Q629. K Inverse Pairs Array (Q505)",
        content: (
          <Comp
            title="Q629. K Inverse Pairs Array (Q505)"
            content1={
              <>
                For an integer array nums, an inverse pair is a pair of integers
                [i, j] where 0 &lt;= i &lt; j &lt; nums.length and nums[i] &gt;
                nums[j].
                <br />
                Given two integers n and k, return the number of different
                arrays consist of numbers from 1 to n such that there are
                exactly k inverse pairs. Since the answer can be huge, return it
                modulo 109 + 7.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 3, k = 0 <br />
                    Output: 1 <br />
                    Explanation: Only the array [1,2,3] which consists of
                    numbers from 1 to 3 has exactly 0 inverse pairs.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 3, k = 1<br />
                    Output: 2<br />
                    Explanation: The array [1,3,2] and [2,1,3] have exactly 1
                    inverse pair.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 1000 <br />0 &lt;= k &lt;= 1000
              </>
            }
            tc="m.n"
            sc="m.n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @param {number} k
                * @return {number}
                */
               var kInversePairs = function(n, k) {
                 const kMod = 1e9 + 7;
                 const dp = Array.from(Array(n + 1),()=>Array(k + 1).fill(0))
                 for (let i = 0; i <= n; ++i)
                 dp[i][0] = 1;
                 for (let i = 1; i <= n; ++i)
                   for (let j = 1; j <= k; ++j) {
                     dp[i][j] = (dp[i][j - 1] + dp[i - 1][j]) % kMod;
                     if (j - i >= 0)
                       dp[i][j] = (dp[i][j] - dp[i - 1][j - i] + kMod) % kMod;
                   }
                 return dp[n][k];
               };
               
               console.log(kInversePairs(3,0))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q506: {
        title: "Q630. Course Schedule III (Q506)",
        content: (
          <Comp
            title="Q630. Course Schedule III (Q506)"
            content1={
              <>
                There are n different online courses numbered from 1 to n. You
                are given an array courses where courses[i] = [durationi,
                lastDayi] indicate that the ith course should be taken
                continuously for durationi days and must be finished before or
                on lastDayi.
                <br />
                You will start on the 1st day and you cannot take two or more
                courses simultaneously.
                <br />
                Return the maximum number of courses that you can take.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: courses =
                    [[100,200],[200,1300],[1000,1250],[2000,3200]] Output: 3
                    <br />
                    Explanation: <br />
                    There are totally 4 courses, but you can take 3 courses at
                    most:
                    <br /> First, take the 1st course, it costs 100 days so you
                    will finish it on the 100th day, and ready to take the next
                    course on the 101st day.
                    <br />
                    Second, take the 3rd course, it costs 1000 days so you will
                    finish it on the 1100th day, and ready to take the next
                    course on the 1101st day.
                    <br />
                    Third, take the 2nd course, it costs 200 days so you will
                    finish it on the 1300th day.
                    <br />
                    The 4th course cannot be taken now, since you will finish it
                    on the 3300th day, which exceeds the closed date.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: courses = [[1,2]]
                    <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: courses = [[3,2],[4,3]]
                    <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= courses.length &lt;= 10^4 <br />1 &lt;= durationi,
                lastDayi &lt;= 10^4
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `class Solution {
                  // [[3,2],[4,3]]
                  public int scheduleCourse(int[][] courses) {
                    int time = 0;
                    Arrays.sort(courses, (a, b) -> (a[1] - b[1]));
                    PriorityQueue<Integer> pq = new PriorityQueue<>((a, b) -> b - a);
                    for (int[] c : courses) {
                      final int duration = c[0];
                      final int lastDay = c[1];
                      pq.offer(duration);
                      time += c[0];
                      if (time > lastDay)
                        time -= pq.poll();
                    }
                
                    return pq.size();
                  }
                }
                `,
                output: `0`,
              },
            }}
          />
        ),
      },
      q507: {
        title: "Q632. Smallest Range Covering Elements from K Lists (Q507)",
        content: (
          <Comp
            title="Q632. Smallest Range Covering Elements from K Lists (Q507)"
            content1={
              <>
                You have k lists of sorted integers in non-decreasing order.
                Find the smallest range that includes at least one number from
                each of the k lists.
                <br />
                We define the range [a, b] is smaller than range [c, d] if b - a
                &lt; d - c or a &lt; c if b &lt; a == d - c.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [[4,10,15,24,26],[0,9,12,20],[5,18,22,30]]
                    <br /> Output: [20,24] <br />
                    Explanation: <br />
                    List 1: [4, 10, 15, 24,26], 24 is in range [20,24]. <br />
                    List 2: [0, 9, 12, 20], 20 is in range [20,24]. <br />
                    List 3: [5, 18, 22, 30], 22 is in range [20,24].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [[1,2,3],[1,2,3],[1,2,3]] <br />
                    Output: [1,1]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                nums.length == k <br />
                1 &lt;= k &lt;= 3500 <br />
                1 &lt;= nums[i].length &lt;= 50 <br />
                -10^5 &lt;= nums[i][j] &lt;= 10^5 <br />
                nums[i] is sorted in non-decreasing order.
              </>
            }
            tc="n^2.log k"
            sc="k"
            codes={{
              Java: {
                code: `// [[1,2,3],[1,2,3],[1,2,3]]
                class T {
                  public int i;
                  public int j;
                  public int num; // nums[i][j]
                  public T(int i, int j, int num) {
                    this.i = i;
                    this.j = j;
                    this.num = num;
                  }
                }
                
                class Solution {
                  public int[] smallestRange(List<List<Integer>> nums) {
                    PriorityQueue<T> pq = new PriorityQueue<>((a, b) -> a.num - b.num);
                    int min = Integer.MAX_VALUE;
                    int max = Integer.MIN_VALUE;
                    for (int i = 0; i < nums.size(); ++i) {
                      final int num = nums.get(i).get(0);
                      pq.offer(new T(i, 0, num));
                      min = Math.min(min, num);
                      max = Math.max(max, num);
                    }
                    int minRange = min;
                    int maxRange = max;
                    while (pq.size() == nums.size()) {
                      final int i = pq.peek().i;
                      final int j = pq.poll().j;
                      if (j + 1 < nums.get(i).size()) {
                        pq.offer(new T(i, j + 1, nums.get(i).get(j + 1)));
                        max = Math.max(max, nums.get(i).get(j + 1));
                        min = pq.peek().num;
                      }
                      if (max - min < maxRange - minRange) {
                        minRange = min;
                        maxRange = max;
                      }
                    }
                
                    return new int[] {minRange, maxRange};
                  }
                }
                `,
                output: `[ 1, 1 ]`,
              },
            }}
          />
        ),
      },
      q508: {
        title: "Q633. Sum of Square Numbers (Q508)",
        content: (
          <Comp
            title="Q633. Sum of Square Numbers (Q508)"
            content1={
              <>
                Given a non-negative integer c, decide whether there're two
                integers a and b such that a^2 + b^2 = c.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: c = 5 <br />
                    Output: true <br />
                    Explanation: 1 * 1 + 2 * 2 = 5
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: c = 3 <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={<>0 &lt;= c &lt;= 2^31 - 1</>}
            tc="n^0.5"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} c
                * @return {boolean}
                */
               var judgeSquareSum = function(c) {
                 let l = 0;
                 let r = parseInt(Math.sqrt(c));
                 while (l <= r) {
                   const sum = l * l + r * r;
                   if (sum == c)
                     return true;
                   if (sum < c)
                     ++l;
                   else
                     --r;
                 }
                 return false;   
               };
               
               console.log(judgeSquareSum(5))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q509: {
        title: "Q636. Exclusive Time of Functions (Q509)",
        content: (
          <Comp
            title="Q636. Exclusive Time of Functions (Q509)"
            content1={
              <>
                On a single-threaded CPU, we execute a program containing n
                functions. Each function has a unique ID between 0 and n-1.
                <br />
                Function calls are stored in a call stack: when a function call
                starts, its ID is pushed onto the stack, and when a function
                call ends, its ID is popped off the stack. The function whose ID
                is at the top of the stack is the current function being
                executed. Each time a function starts or ends, we write a log
                with the ID, whether it started or ended, and the timestamp.
                <br />
                You are given a list logs, where logs[i] represents the ith log
                message formatted as a string{" "}
                {'"{function_id}:{"start" | "end"}:{timestamp}"'}. For example,
                "0:start:3" means a function call with function ID 0 started at
                the beginning of timestamp 3, and "1:end:2" means a function
                call with function ID 1 ended at the end of timestamp 2. Note
                that a function can be called multiple times, possibly
                recursively.
                <br />
                A function's exclusive time is the sum of execution times for
                all function calls in the program. For example, if a function is
                called twice, one call executing for 2 time units and another
                call executing for 1 time unit, the exclusive time is 2 + 1 = 3.
                <br />
                Return the exclusive time of each function in an array, where
                the value at the ith index represents the exclusive time for the
                function with ID i.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 2, logs =
                    ["0:start:0","1:start:2","1:end:5","0:end:6"]
                    <br /> Output: [3,4]
                    <br /> Explanation:
                    <br /> Function 0 starts at the beginning of time 0, then it
                    executes 2 for units of time and reaches the end of time 1.
                    <br /> Function 1 starts at the beginning of time 2,
                    executes for 4 units of time, and ends at the end of time 5.
                    <br /> Function 0 resumes execution at the beginning of time
                    6 and executes for 1 unit of time.
                    <br /> So function 0 spends 2 + 1 = 3 units of total time
                    executing, and function 1 spends 4 units of total time
                    executing.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1, logs =
                    ["0:start:0","0:start:2","0:end:5","0:start:6","0:end:6","0:end:7"]
                    <br /> Output: [8]
                    <br /> Explanation:
                    <br /> Function 0 starts at the beginning of time 0,
                    executes for 2 units of time, and recursively calls itself.
                    <br /> Function 0 (recursive call) starts at the beginning
                    of time 2 and executes for 4 units of time.
                    <br /> Function 0 (initial call) resumes execution then
                    immediately calls itself again.
                    <br /> Function 0 (2nd recursive call) starts at the
                    beginning of time 6 and executes for 1 unit of time.
                    <br /> Function 0 (initial call) resumes execution at the
                    beginning of time 7 and executes for 1 unit of time.
                    <br /> So function 0 spends 2 + 4 + 1 + 1 = 8 units of total
                    time executing.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2, logs =
                    ["0:start:0","0:start:2","0:end:5","1:start:6","1:end:6","0:end:7"]
                    <br /> Output: [7,1]
                    <br /> Explanation:
                    <br /> Function 0 starts at the beginning of time 0,
                    executes for 2 units of time, and recursively calls itself.
                    <br /> Function 0 (recursive call) starts at the beginning
                    of time 2 and executes for 4 units of time.
                    <br /> Function 0 (initial call) resumes execution then
                    immediately calls function 1.
                    <br /> Function 1 starts at the beginning of time 6,
                    executes 1 unit of time, and ends at the end of time 6.
                    <br /> Function 0 resumes execution at the beginning of time
                    6 and executes for 2 units of time.
                    <br /> So function 0 spends 2 + 4 + 1 = 7 units of total
                    time executing, and function 1 spends 1 unit of total time
                    executing.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 100 <br />
                1 &lt;= logs.length &lt;= 500 <br />
                0 &lt;= function_id &lt; n <br />
                0 &lt;= timestamp &lt;= 10^9 <br />
                No two start events will happen at the same timestamp. <br />
                No two end events will happen at the same timestamp. <br />
                Each function has an "end" log for each "start" log.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Python: {
                code: `// 2
class Solution:
  def exclusiveTime(self, n: int, logs: List[str]) -> List[int]:        
      def convertToArray(log: str):
          tmp = log.split(':')
          return [int(tmp[0]), tmp[1], int(tmp[2])]
      logs = list(map(convertToArray, logs))        
      stack = deque()
      functions = [0] * n
      for log in logs:
          if log[1] == "start":
              stack.append(log)
          else:
              startLog = stack.pop()
              execTime = log[2] - startLog[2] + 1
              functions[log[0]] += execTime
              if len(stack):
                  top = stack[-1]
                  functions[top[0]] -= execTime
      return functions`,
                output: `[3,4]`,
              },
            }}
          />
        ),
      },
      q510: {
        title: "Q637. Average of Levels in Binary Tree (Q510)",
        content: (
          <Comp
            title="Q637. Average of Levels in Binary Tree (Q510)"
            content1={
              <>
                Given the root of a binary tree, return the average value of the
                nodes on each level in the form of an array. Answers within 10-5
                of the actual answer will be accepted.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode637,
                content: (
                  <>
                    Input: root = [3,9,20,null,null,15,7]
                    <br /> Output: [3.00000,14.50000,11.00000]
                    <br /> Explanation: The average value of nodes on level 0 is
                    3, on level 1 is 14.5, and on level 2 is 11.
                    <br /> Hence return [3, 14.5, 11].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [3,9,20,null,null,15,7]
                    <br /> Output: [3.00000,14.50000,11.00000]
                    <br /> Explanation: The average value of nodes on level 0 is
                    3, on level 1 is 14.5, and on level 2 is 11. Hence return
                    [3, 14.5, 11].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 10^4].{" "}
                <br />
                -2^31 &lt;= Node.val &lt;= 2^31 - 1
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
              }
              
              /**
               * @param {TreeNode} root
               * @return {number[]}
               */
              var averageOfLevels = function(root) {
                const res = []
                const q = [root];
                while (q.length) {
                let sum = 0;
                let size = q.length;
                for (let i = 0; i < size; ++i) {
                  const node = q.shift();
                  sum += node.val;
                  if (node.left)
                    q.push(node.left);
                  if (node.right)
                    q.push(node.right);
                }
                res.push(sum /size);
                }
                return res;
              };
              
              const t = new TreeNode(3)
              t.left = new TreeNode(9)
              t.right = new TreeNode(20)
              t.right.left = new TreeNode(15)
              t.right.right = new TreeNode(7)
              console.log(averageOfLevels(t))`,
                output: `[ 3, 14.5, 11 ]`,
              },
            }}
          />
        ),
      },
      q511: {
        title: "Q638. Shopping Offers (Q511)",
        content: (
          <Comp
            title="Q638. Shopping Offers (Q511)"
            content1={
              <>
                In LeetCode Store, there are n items to sell. Each item has a
                price. However, there are some special offers, and a special
                offer consists of one or more different kinds of items with a
                sale price.
                <br />
                You are given an integer array price where price[i] is the price
                of the ith item, and an integer array needs where needs[i] is
                the number of pieces of the ith item you want to buy.
                <br />
                You are also given an array special where special[i] is of size
                n + 1 where special[i][j] is the number of pieces of the jth
                item in the ith offer and special[i][n] (i.e., the last integer
                in the array) is the price of the ith offer.
                <br />
                Return the lowest price you have to pay for exactly certain
                items as given, where you could make optimal use of the special
                offers. You are not allowed to buy more items than you want,
                even if that would lower the overall price. You could use any of
                the special offers as many times as you want.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: price = [2,5], special = [[3,0,5],[1,2,10]], needs =
                    [3,2]
                    <br /> Output: 14
                    <br /> Explanation: There are two kinds of items, A and B.
                    Their prices are $2 and $5 respectively.
                    <br /> In special offer 1, you can/ pay $5 for 3A and 0B
                    <br /> In special offer 2, you can pay $10 for 1A and 2B.
                    <br /> You need to buy 3A and 2B, so you may pay $10 for 1A
                    and 2B (special offer #2), and $4 for 2A.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: price = [2,5], special = [[3,0,5],[1,2,10]], needs =
                    [3,2] <br />
                    Output: 14 <br />
                    Explanation: There are two kinds of items, A and B. Their
                    prices are $2 and $5 respectively.
                    <br /> In special offer 1, you can pay $5 for 3A and 0B{" "}
                    <br />
                    In special offer 2, you can pay $10 for 1A and 2B. <br />
                    You need to buy 3A and 2B, so you may pay $10 for 1A and 2B
                    (special offer #2), and $4 for 2A.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == price.length <br />
                n == needs.length <br />
                1 &lt;= n &lt;= 6 <br />
                0 &lt;= price[i] &lt;= 10 <br />
                0 &lt;= needs[i] &lt;= 10 <br />
                1 &lt;= special.length &lt;= 100 <br />
                special[i].length == n + 1 <br />0 &lt;= special[i][j] &lt;= 50
              </>
            }
            tc="n^2"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} price
                * @param {number[][]} special
                * @param {number[]} needs
                * @return {number}
                */
               var shoppingOffers = function(price, special, needs) {
                 return dfs(price, special, needs, 0);
               };
               
               function dfs(price, special, needs, s) {
                 let ans = 0;
                 for (let i = 0; i < price.length; ++i)
                   ans += price[i] * needs[i];
                 for (let i = s; i < special.length; ++i)
                   if (isSafe(special[i], needs)) {
                     for (let j = 0; j < needs.length; ++j)
                       needs[j] -= special[i][j];
                     ans = Math.min(ans, special[i].slice(-1)[0] + dfs(price, special, needs, i));
                     for (let j = 0; j < needs.length; ++j)
                       needs[j] += special[i][j];
                   }
                 return ans;
               }
               
               function isSafe(offer, needs) {
                 for (let i = 0; i < needs.length; ++i)
                   if (needs[i] < offer[i])
                     return false;
                 return true;
               }
               
               console.log(shoppingOffers([2,5],[[3,0,5],[1,2,10]],[3,2]))`,
                output: `14`,
              },
            }}
          />
        ),
      },
      q512: {
        title: "Q639. Decode Ways II (Q512)",
        content: (
          <Comp
            title="Q639. Decode Ways II (Q512)"
            content1={
              <>
                A message containing letters from A-Z can be encoded into
                numbers using the following mapping:
                <br />
                'A' -&gt; "1" <br />
                'B' -&gt; "2" <br />
                ... 'Z' -&gt; "26" <br />
                To decode an encoded message, all the digits must be grouped
                then mapped back into letters using the reverse of the mapping
                above (there may be multiple ways). For example, "11106" can be
                mapped into:
                <br />
                "AAJF" with the grouping (1 1 10 6) <br />
                "KJF" with the grouping (11 10 6) <br />
                Note that the grouping (1 11 06) is invalid because "06" cannot
                be mapped into 'F' since "6" is different from "06".
                <br />
                In addition to the mapping above, an encoded message may contain
                the '*' character, which can represent any digit from '1' to '9'
                ('0' is excluded). For example, the encoded message "1*" may
                represent any of the encoded messages "11", "12", "13", "14",
                "15", "16", "17", "18", or "19". Decoding "1*" is equivalent to
                decoding any of the encoded messages it can represent.
                <br />
                Given a string s consisting of digits and '*' characters, return
                the number of ways to decode it.
                <br />
                Since the answer may be very large, return it modulo 109 + 7.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "*"
                    <br /> Output: 9
                    <br /> Explanation: The encoded message can represent any of
                    the encoded messages "1", "2", "3", "4", "5", "6", "7", "8",
                    or "9".
                    <br /> Each of these can be decoded to the strings "A", "B",
                    "C", "D", "E", "F", "G", "H", and "I" respectively.
                    <br /> Hence, there are a total of 9 ways to decode "*".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "1*"
                    <br /> Output: 18
                    <br />
                    Explanation: The encoded message can represent any of the
                    encoded messages "11", "12", "13", "14", "15", "16", "17",
                    "18", or "19".
                    <br /> Each of these encoded messages have 2 ways to be
                    decoded (e.g. "11" can be decoded to "AA" or "K").
                    <br /> Hence, there are a total of 9 * 2 = 18 ways to decode
                    "1*".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "2*" <br />
                    Output: 15 <br />
                    <br /> Explanation: The encoded message can represent any of
                    the encoded messages "21", "22", "23", "24", "25", "26",
                    "27", "28", or "29".
                    <br /> "21", "22", "23", "24", "25", and "26" have 2 ways of
                    being decoded, but "27", "28", and "29" only have 1 way.
                    <br /> Hence, there are a total of (6 * 2) + (3 * 1) = 12 +
                    3 = 15 ways to decode "2*".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^5
                <br />
                s[i] is a digit or '*'.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Java: {
                code: `// "*"
                class Solution {
                  public int numDecodings(String s) {
                    final int kMod = (int) 1e9 + 7;
                    final int n = s.length();
                    long prev2 = 1;
                    long prev1 = count(s.charAt(n - 1));
                    for (int i = n - 2; i >= 0; --i) {
                      long dp = count(s.charAt(i), s.charAt(i + 1)) * prev2 + count(s.charAt(i)) * prev1;
                      dp %= kMod;
                      prev2 = prev1;
                      prev1 = dp;
                    }
                    return (int) prev1;
                  }
                  private int count(char c) {
                    if (c == '*')
                      return 9;
                    return c == '0' ? 0 : 1;
                  }
                  private int count(char c1, char c2) {
                    if (c1 == '*' && c2 == '*')
                      return 15;
                    if (c1 == '*') {
                      if ('0' <= c2 && c2 <= '6')
                        return 2; 
                      else
                        return 1; 
                    }
                    if (c2 == '*') {
                      if (c1 == '1')
                        return 9; 
                      if (c1 == '2')
                        return 6; 
                      return 0;
                    }
                    return (c1 == '1' || (c1 == '2' && c2 <= '6')) ? 1 : 0;
                  }
                }
                `,
                output: `9`,
              },
            }}
          />
        ),
      },
      q513: {
        title: "Q640. Solve the Equation (Q513)",
        content: (
          <Comp
            title="Q640. Solve the Equation (Q513)"
            content1={
              <>
                Solve a given equation and return the value of 'x' in the form
                of a string "x=#value". The equation contains only '+', '-'
                operation, the variable 'x' and its coefficient. You should
                return "No solution" if there is no solution for the equation,
                or "Infinite solutions" if there are infinite solutions for the
                equation.
                <br />
                If there is exactly one solution for the equation, we ensure
                that the value of 'x' is an integer.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: equation = "x+5-3+x=6+x-2"
                    <br />
                    Output: "x=2"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: equation = "x=x"
                    <br />
                    Output: "Infinite solutions"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: equation = "2x=x"
                    <br />
                    Output: "x=0"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                3 &lt;= equation.length &lt;= 1000
                <br />
                equation has exactly one '='.
                <br />
                equation consists of integers with an absolute value in the
                range [0, 100] without any leading zeros, and the variable 'x'.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} equation
                * @return {string}
                */
               var solveEquation = function(equation) {
                 const equations = equation.split("=");
                 let lhs = calculate(equations[0]);
                 let rhs = calculate(equations[1]);
                 let coefficient = lhs[0] - rhs[0];
                 let constant = rhs[1] - lhs[1];
                 if (coefficient == 0 && constant == 0)
                   return "Infinite solutions";
                 if (coefficient == 0 && constant != 0)
                   return "No solution";
                 return "x=" + parseInt(constant / coefficient);
               };
               
               function calculate(s) {
                 let coefficient = 0;
                 let constant = 0;
                 let num = 0;
                 let sign = 1;
                 for (let i = 0; i < s.length; ++i) {
                   const c = s[i];
                   if (Number.isInteger(+c))
                     num = num * 10 + (c - '0');
                   else if (c == '+' || c == '-') {
                     constant += sign * num;
                     sign = c == '+' ? 1 : -1;
                     num = 0;
                   } else {
                     if (i > 0 && num == 0 && s[i - 1] == '0')
                       continue;
                     coefficient += num == 0 ? sign : sign * num;
                     num = 0;
                   }
                 }
                 return [coefficient, constant + sign * num];
               }
               
               console.log(solveEquation("x+5-3+x=6+x-2"))`,
                output: `x=2`,
              },
            }}
          />
        ),
      },
      q514: {
        title: "Q641. Design Circular Deque (Q514)",
        content: (
          <Comp
            title="Q641. Design Circular Deque (Q514)"
            content1={
              <>
                Design your implementation of the circular double-ended queue
                (deque).
                <br />
                Implement the MyCircularDeque class:
                <br />
                MyCircularDeque(int k) Initializes the deque with a maximum size
                of k.
                <br />
                boolean insertFront() Adds an item at the front of Deque.
                Returns true if the operation is successful, or false otherwise.
                <br />
                boolean insertLast() Adds an item at the rear of Deque. Returns
                true if the operation is successful, or false otherwise.
                <br />
                boolean deleteFront() Deletes an item from the front of Deque.
                Returns true if the operation is successful, or false otherwise.
                <br />
                boolean deleteLast() Deletes an item from the rear of Deque.
                Returns true if the operation is successful, or false otherwise.
                <br />
                int getFront() Returns the front item from the Deque. Returns -1
                if the deque is empty.
                <br />
                int getRear() Returns the last item from Deque. Returns -1 if
                the deque is empty.
                <br />
                boolean isEmpty() Returns true if the deque is empty, or false
                otherwise.
                <br />
                boolean isFull() Returns true if the deque is full, or false
                otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br /> ["MyCircularDeque", "insertLast", "insertLast",
                    "insertFront", "insertFront", "getRear", "isFull",
                    "deleteLast", "insertFront", "getFront"]
                    <br /> [[3], [1], [2], [3], [4], [], [], [], [4], []]
                    <br /> Output
                    <br /> [null, true, true, true, false, 2, true, true, true,
                    4]
                    <br /> Explanation
                    <br /> MyCircularDeque myCircularDeque = new
                    MyCircularDeque(3);
                    <br /> myCircularDeque.insertLast(1); // return True
                    <br /> myCircularDeque.insertLast(2); // return True
                    <br /> myCircularDeque.insertFront(3); // return True
                    <br /> myCircularDeque.insertFront(4); // return False, the
                    queue is full.
                    <br /> myCircularDeque.getRear(); // return 2
                    <br /> myCircularDeque.isFull(); // return True
                    <br /> myCircularDeque.deleteLast(); // return True
                    <br /> myCircularDeque.insertFront(4); // return True
                    <br /> myCircularDeque.getFront(); // return 4
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= k &lt;= 1000 <br />
                0 &lt;= value &lt;= 1000 <br />
                At most 2000 calls will be made to insertFront, insertLast,
                deleteFront, deleteLast, getFront, getRear, isEmpty, isFull.
              </>
            }
            tc="1"
            sc="n"
            codes={{
              Java: {
                code: `class MyCircularDeque {
                  public MyCircularDeque(int k) {
                    this.k = k;
                    this.q = new int[k];
                    this.rear = k - 1;
                  }
                
                  public boolean insertFront(int value) {
                    if (isFull())
                      return false;
                
                    front = (--front + k) % k;
                    q[front] = value;
                    ++size;
                    return true;
                  }
                
                  public boolean insertLast(int value) {
                    if (isFull())
                      return false;
                
                    rear = ++rear % k;
                    q[rear] = value;
                    ++size;
                    return true;
                  }
                    
                  public boolean deleteFront() {
                    if (isEmpty())
                      return false;
                
                    front = ++front % k;
                    --size;
                    return true;
                  }
                
                  public boolean deleteLast() {
                    if (isEmpty())
                      return false;
                
                    rear = (--rear + k) % k;
                    --size;
                    return true;
                  }
                
                  public int getFront() {
                    return isEmpty() ? -1 : q[front];
                  }
                
                  public int getRear() {
                    return isEmpty() ? -1 : q[rear];
                  }
                
                  public boolean isEmpty() {
                    return size == 0;
                  }
                
                  public boolean isFull() {
                    return size == k;
                  }
                
                  private final int k;
                  private int[] q;
                  private int size = 0;
                  private int front = 0;
                  private int rear;
                }
                `,
                output: `[null,true,true,true,false,2,true,true,true,4]`,
              },
            }}
          />
        ),
      },
      q515: {
        title: "Q643. Maximum Average Subarray I (Q515)",
        content: (
          <Comp
            title="Q643. Maximum Average Subarray I (Q515)"
            content1={
              <>
                You are given an integer array nums consisting of n elements,
                and an integer k.
                <br />
                Find a contiguous subarray whose length is equal to k that has
                the maximum average value and return this value. Any answer with
                a calculation error less than 10-5 will be accepted.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,12,-5,-6,50,3], k = 4<br />
                    Output: 12.75000
                    <br />
                    Explanation: Maximum average is (12 - 5 - 6 + 50) / 4 = 51 /
                    4 = 12.75
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [5], k = 1 <br />
                    Output: 5.00000
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == nums.length
                <br />
                1 &lt;= k &lt;= n &lt;= 105
                <br />
                -10^4 &lt;= nums[i] &lt;= 10^4
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var findMaxAverage = function(nums, k) {
                 let sum = 0;
                 for (let i = 0; i < k; ++i)
                   sum += nums[i];
                 let res = sum;
                 for (let i = k; i < nums.length; ++i) {
                   sum += nums[i] - nums[i - k];
                   res = Math.max(res, sum);
                 }
                 return res / k;
               };
               
               console.log(findMaxAverage([1,12,-5,-6,50,3],4))`,
                output: `12.75`,
              },
            }}
          />
        ),
      },
      q516: {
        title: "Q645. Set Mismatch (Q516)",
        content: (
          <Comp
            title="Q645. Set Mismatch (Q516)"
            content1={
              <>
                You have a set of integers s, which originally contains all the
                numbers from 1 to n. Unfortunately, due to some error, one of
                the numbers in s got duplicated to another number in the set,
                which results in repetition of one number and loss of another
                number.
                <br />
                You are given an integer array nums representing the data status
                of this set after the error.
                <br />
                Find the number that occurs twice and the number that is missing
                and return them in the form of an array.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,1] <br />
                    Output: [1,2]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,1] <br />
                    Output: [1,2]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= nums.length &lt;= 10^4 <br />1 &lt;= nums[i] &lt;= 10^4
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number[]}
                */
               var findErrorNums = function(nums) {
                 let duplicate = 0;
                 for(let num of nums)
                   if(nums[Math.abs(+num) - 1] < 0)
                     duplicate = Math.abs(+num)
                   else
                     nums[Math.abs(num) - 1] *= -1
                 for(let i in nums)
                   if(nums[+i] > 0)
                     return [duplicate, +i + 1]    
               };
               
               console.log(findErrorNums([1,1]))`,
                output: `[ 1, 2 ]`,
              },
            }}
          />
        ),
      },
      q517: {
        title: "Q646. Maximum Length of Pair Chain (Q517)",
        content: (
          <Comp
            title="Q646. Maximum Length of Pair Chain (Q517)"
            content1={
              <>
                You are given an array of n pairs pairs where pairs[i] = [lefti,
                righti] and lefti &lt; righti.
                <br />
                A pair p2 = [c, d] follows a pair p1 = [a, b] if b &lt; c. A
                chain of pairs can be formed in this fashion.
                <br />
                Return the length longest chain which can be formed.
                <br />
                You do not need to use up all the given intervals. You can
                select pairs in any order.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: pairs = [[1,2],[2,3],[3,4]]
                    <br />
                    Output: 2<br />
                    Explanation: The longest chain is [1,2] -&gt; [3,4].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: pairs = [[1,2],[2,3],[3,4]]
                    <br />
                    Output: 2<br />
                    Explanation: The longest chain is [1,2] -&gt; [3,4].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == pairs.length <br />
                1 &lt;= n &lt;= 1000 <br />
                -1000 &lt;= lefti &lt; righti &lt;= 1000
              </>
            }
            tc="n.log n"
            sc="1"
            codes={{
              Javascript: {
                code: `
                var findLongestChain = function(pairs) {
                  let res = 0;
                  let prevEnd = Number.MIN_SAFE_INTEGER;
                  pairs = pairs.sort((a,b)=> a[1] - b[1])
                  for (let pair of pairs)
                    if (pair[0] > prevEnd) {
                      ++res;
                      prevEnd = pair[1];
                    }
                  return res;
                };
                
                console.log(findLongestChain([[1,2],[2,3],[3,4]]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q518: {
        title: "Q647. Palindromic Substrings (Q518)",
        content: (
          <Comp
            title="Q647. Palindromic Substrings (Q518)"
            content1={
              <>
                Given a string s, return the number of palindromic substrings in
                it.
                <br />
                A string is a palindrome when it reads the same backward as
                forward.
                <br />A substring is a contiguous sequence of characters within
                the string.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "abc"
                    <br />
                    Output: 3<br />
                    Explanation: Three palindromic strings: "a", "b", "c".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "aaa"
                    <br />
                    Output: 6<br />
                    Explanation: Six palindromic strings: "a", "a", "a", "aa",
                    "aa", "aaa".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 1000Input: s = "abc"
                <br />
                Output: 3<br />
                Explanation: Three palindromic strings: "a", "b", "c".
                <br />s consists of lowercase English letters.
              </>
            }
            tc="n^2"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               var countSubstrings = function(s) {
                 let res = 0;
                 for (let i = 0; i < s.length; ++i) {
                   res += extendPalindromes(s, i, i);
                   res += extendPalindromes(s, i, i + 1);
                 }
                 return res;
               }
               
               function extendPalindromes(s, l, r) {
                 let count = 0;
                 while (l >= 0 && r < s.length && s[l] == s[r]) {
                   ++count;
                   --l;
                   ++r;
                 }
                 return count;
               };
               
               console.log(countSubstrings("abc"))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q519: {
        title: "Q648. Replace Words (Q519)",
        content: (
          <Comp
            title="Q648. Replace Words (Q519)"
            content1={
              <>
                In English, we have a concept called root, which can be followed
                by some other word to form another longer word - let's call this
                word successor. For example, when the root "an" is followed by
                the successor word "other", we can form a new word "another".
                <br />
                Given a dictionary consisting of many roots and a sentence
                consisting of words separated by spaces, replace all the
                successors in the sentence with the root forming it. If a
                successor can be replaced by more than one root, replace it with
                the root that has the shortest length.
                <br />
                Return the sentence after the replacement.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: dictionary = ["cat","bat","rat"], sentence = "the
                    cattle was rattled by the battery"
                    <br /> Output: "the cat was rat by the bat"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: dictionary = ["a","b","c"], sentence = "aadsfasf
                    absbs bbab cadsfafs"
                    <br /> Output: "a a b c"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= dictionary.length &lt;= 1000
                <br /> 1 &lt;= dictionary[i].length &lt;= 100
                <br /> dictionary[i] consists of only lower-case letters.
                <br /> 1 &lt;= sentence.length &lt;= 106
                <br /> sentence consists of only lower-case letters and spaces.
                <br /> The number of words in sentence is in the range [1, 1000]
                <br /> The length of each word in sentence is in the range [1,
                1000]
                <br /> Every two consecutive words in sentence will be separated
                by exactly one space.
                <br /> sentence does not have leading or trailing spaces.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // dictionary = ["a","b","c"], sentence = "aadsfasf absbs bbab cadsfafs"
                class Solution {
                  public String replaceWords(List<String> dict, String sentence) {
                    String ans = "";
                
                    for (final String word : dict)
                      insert(word);
                
                    final String[] words = sentence.split(" ");
                    for (final String word : words)
                      ans += ' ' + search(word);
                
                    return ans.substring(1);
                  }
                
                  private class TrieNode {
                    private TrieNode[] children = new TrieNode[26];
                    private String word;
                  }
                
                  private TrieNode root = new TrieNode();
                
                  private void insert(final String word) {
                    TrieNode node = root;
                    for (char c : word.toCharArray()) {
                      int index = c - 'a';
                      if (node.children[index] == null)
                        node.children[index] = new TrieNode();
                      node = node.children[index];
                    }
                    node.word = word;
                  }
                
                  private String search(final String word) {
                    TrieNode node = root;
                    for (char c : word.toCharArray()) {
                      if (node.word != null)
                        return node.word;
                      int index = c - 'a';
                      if (node.children[index] == null)
                        return word;
                      node = node.children[index];
                    }
                    return word;
                  }
                }
                `,
                output: `a a b c`,
              },
            }}
          />
        ),
      },
      q520: {
        title: "Q649. Dota2 Senate (Q520)",
        content: (
          <Comp
            title="Q649. Dota2 Senate (Q520)"
            content1={
              <>
                In the world of Dota2, there are two parties: the Radiant and
                the Dire.
                <br />
                The Dota2 senate consists of senators coming from two parties.
                Now the Senate wants to decide on a change in the Dota2 game.
                The voting for this change is a round-based procedure. In each
                round, each senator can exercise one of the two rights:
                <br />
                <br /> Ban one senator's right: A senator can make another
                senator lose all his rights in this and all the following
                rounds.
                <br /> Announce the victory: If this senator found the senators
                who still have rights to vote are all from the same party, he
                can announce the victory and decide on the change in the game.
                <br /> Given a string senate representing each senator's party
                belonging. The character 'R' and 'D' represent the Radiant party
                and the Dire party. Then if there are n senators, the size of
                the given string will be n.
                <br />
                The round-based procedure starts from the first senator to the
                last senator in the given order. This procedure will last until
                the end of voting. All the senators who have lost their rights
                will be skipped during the procedure.
                <br />
                Suppose every senator is smart enough and will play the best
                strategy for his own party. Predict which party will finally
                announce the victory and change the Dota2 game. The output
                should be "Radiant" or "Dire".
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: senate = "RD"
                    <br />
                    Output: "Radiant"
                    <br />
                    Explanation:
                    <br />
                    The first senator comes from Radiant and he can just ban the
                    next senator's right in round 1.
                    <br />
                    And the second senator can't exercise any rights anymore
                    since his right has been banned.
                    <br />
                    And in round 2, the first senator can just announce the
                    victory since he is the only guy in the senate who can vote.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: senate = "RDD"
                    <br />
                    Output: "Dire"
                    <br />
                    Explanation:
                    <br />
                    The first senator comes from Radiant and he can just ban the
                    next senator's right in round 1.
                    <br />
                    And the second senator can't exercise any rights anymore
                    since his right has been banned.
                    <br />
                    And the third senator comes from Dire and he can ban the
                    first senator's right in round 1.
                    <br /> And in round 2, the third senator can just announce
                    the victory since he is the only guy in the senate who can
                    vote.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == senate.length <br />
                1 &lt;= n &lt;= 10^4 <br />
                senate[i] is either 'R' or 'D'.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} senate
                * @return {string}
                */
               var predictPartyVictory = function(senate) {
                 const n = senate.length;
                 let qR = [];
                 let qD = [];
                 for (let i = 0; i < n; ++i)
                   if (senate[i] == 'R')
                     qR.push(i);
                   else
                     qD.push(i);
                 while (qR.length && qD.length) {
                   let indexR = qR.shift();
                   let indexD = qD.shift();
                   if (indexR < indexD)
                     qR.push(indexR + n);
                   else
                     qD.push(indexD + n);
                 }
                 return !qR.length ? "Dire" : "Radiant";
               };
               
               console.log(predictPartyVictory("RDD"))`,
                output: `Dire`,
              },
            }}
          />
        ),
      },
      q521: {
        title: "Q650. 2 Keys Keyboard (Q521)",
        content: (
          <Comp
            title="Q650. 2 Keys Keyboard (Q521)"
            content1={
              <>
                There is only one character 'A' on the screen of a notepad. You
                can perform two operations on this notepad for each step:
                <br />
                Copy All: You can copy all the characters present on the screen
                (a partial copy is not allowed).
                <br />
                Paste: You can paste the characters which are copied last time.
                <br />
                Given an integer n, return the minimum number of operations to
                get the character 'A' exactly n times on the screen.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 3 <br />
                    Output: 3 <br />
                    Explanation: Intitally, we have one character 'A'. <br />
                    In step 1, we use Copy All operation. <br />
                    In step 2, we use Paste operation to get 'AA'. <br />
                    In step 3, we use Paste operation to get 'AAA'.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1 <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 1000</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               var minSteps = function(n) {
                 const dp = Array(n + 1).fill(0);
                 for (let i = 2; i <= n; ++i) {
                   dp[i] = i; 
                   for (let j = parseInt(i / 2); j > 2; --j)
                     if (i % j == 0) {
                       dp[i] = dp[j] + parseInt(i / j); 
                       break;
                     }
                 }
                 return dp[n];
               };
               
               console.log(minSteps(1))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q522: {
        title: "Q652. Find Duplicate Subtrees (Q522)",
        content: (
          <Comp
            title="Q652. Find Duplicate Subtrees (Q522)"
            content1={
              <>
                Given the root of a binary tree, return all duplicate subtrees.
                <br />
                For each kind of duplicate subtrees, you only need to return the
                root node of any one of them.
                <br />
                Two trees are duplicate if they have the same structure with the
                same node values.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode652,
                content: (
                  <>
                    Input: root = [1,2,3,4,null,2,4,null,null,4] Output:
                    [[2,4],[4]]
                  </>
                ),
              },
              {
                content: <>Input: root = [2,1,1] Output: [[1]]</>,
              },
              {
                content: (
                  <>Input: root = [2,2,2,3,null,3,null] Output: [[2,3],[3]]</>
                ),
              },
            ]}
            constraints={
              <>
                The number of the nodes in the tree will be in the range [1,
                10^4]
                <br /> -200 &lt;= Node.val &lt;= 200
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
              }
              
              /**
               * @param {TreeNode} root
               * @return {TreeNode[]}
               */
              var findDuplicateSubtrees = function(root) {
                const res = [];
                const count = {};
                encode(root, count, res);
                return res;
              }
              
              function encode(root, count, res) {
                if (!root)
                  return "";
                let left = encode(root.left, count, res);
                let right = encode(root.right, count, res);
                let encoding = root.val + "#" + left + "#" + right;
                if ((count[encoding] || 0) == 1)
                  res.push(root);
                count[encoding] = (count[encoding] || 0) + 1 
                return encoding;
              }
              
              const t = new TreeNode(1)
              t.left = new TreeNode(2)
              t.left.left = new TreeNode(4)
              t.right = new TreeNode(3)
              t.right.left = new TreeNode(2)
              t.right.left.left = new TreeNode(4)
              t.right.left = new TreeNode(4)
              console.log(findDuplicateSubtrees(t))`,
                output: `[ TreeNode { val: 4, left: null, right: null } ]`,
              },
            }}
          />
        ),
      },
      q523: {
        title: "Q653. Two Sum IV - Input is a BST (Q523)",
        content: (
          <Comp
            title="Q653. Two Sum IV - Input is a BST (Q523)"
            content1={
              <>
                Given the root of a Binary Search Tree and a target number k,
                return true if there exist two elements in the BST such that
                their sum is equal to the given target.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode653,
                content: (
                  <>
                    Input: root = [5,3,6,2,4,null,7], k = 9 <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [5,3,6,2,4,null,7], k = 28
                    <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 10^4].
                <br />
                -10^4 &lt;= Node.val &lt;= 10^4
                <br />
                root is guaranteed to be a valid binary search tree.
                <br />
                -10^5 &lt;= k &lt;= 10^5
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // root = [5,3,6,2,4,null,7], k = 9
                /**
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode() {}
                *     TreeNode(int val) { this.val = val; }
                *     TreeNode(int val, TreeNode left, TreeNode right) {
                *         this.val = val;
                *         this.left = left;
                *         this.right = right;
                *     }
                * }
                */
               class BSTIterator {
                 public BSTIterator(TreeNode root, boolean leftToRight) {
                   this.leftToRight = leftToRight;
                   pushLeftsUntilNull(root);
                 }
               
                 public int next() {
                   TreeNode root = stack.pop();
                   pushLeftsUntilNull(leftToRight ? root.right : root.left);
                   return root.val;
                 }
               
                 public boolean hasNext() {
                   return !stack.isEmpty();
                 }
               
                 private Stack<TreeNode> stack = new Stack<>();
                 private boolean leftToRight;
               
                 private void pushLeftsUntilNull(TreeNode root) {
                   while (root != null) {
                     stack.push(root);
                     root = leftToRight ? root.left : root.right;
                   }
                 }
               }
               
               class Solution {
                 public boolean findTarget(TreeNode root, int k) {
                   if (root == null)
                     return false;
               
                   BSTIterator left = new BSTIterator(root, true);
                   BSTIterator right = new BSTIterator(root, false);
               
                   for (int l = left.next(), r = right.next(); l < r;) {
                     final int sum = l + r;
                     if (sum == k)
                       return true;
                     if (sum < k)
                       l = left.next();
                     else
                       r = right.next();
                   }
               
                   return false;
                 }
               }
               `,
                output: `9`,
              },
            }}
          />
        ),
      },
      q524: {
        title: "Q654. Maximum Binary Tree (Q524)",
        content: (
          <Comp
            title="Q654. Maximum Binary Tree (Q524)"
            content1={
              <>
                You are given an integer array nums with no duplicates. A
                maximum binary tree can be built recursively from nums using the
                following algorithm:
                <br />
                Create a root node whose value is the maximum value in nums.
                <br />
                Recursively build the left subtree on the subarray prefix to the
                left of the maximum value.
                <br />
                Recursively build the right subtree on the subarray suffix to
                the right of the maximum value.
                <br />
                Return the maximum binary tree built from nums.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode654,
                content: (
                  <>
                    Input: nums = [3,2,1,6,0,5] <br />
                    Output: [6,3,5,null,2,0,null,null,1] <br />
                    Explanation: The recursive calls are as follow: <br />
                    - The largest value in [3,2,1,6,0,5] is 6. Left prefix is
                    [3,2,1] and right suffix is [0,5].
                    <br />
                    - The largest value in [3,2,1] is 3. Left prefix is [] and
                    right suffix is [2,1].
                    <br />
                    - Empty array, so no child.
                    <br />
                    - The largest value in [2,1] is 2. Left prefix is [] and
                    right suffix is [1].
                    <br />
                    - Empty array, so no child.
                    <br />
                    - Only one element, so child is a node with value 1.
                    <br />
                    - The largest value in [0,5] is 5. Left prefix is [0] and
                    right suffix is [].
                    <br />
                    - Only one element, so child is a node with value 0.
                    <br />- Empty array, so no child.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [3,2,1] <br />
                    Output: [3,null,2,null,1]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 1000
                <br />
                0 &lt;= nums[i] &lt;= 1000
                <br />
                All integers in nums are uniqu
              </>
            }
            tc="n^3"
            sc="h"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {number[]} nums
                 * @return {TreeNode}
                 */
                var constructMaximumBinaryTree = function(nums) {
                  return build(nums, 0, nums.length - 1);
                };
                
                function build(nums, i, j) {
                  if (i > j) return null;
                  let maxIndex = i;
                  for (let k = i + 1; k <= j; ++k)
                      if (nums[k] > nums[maxIndex])
                        maxIndex = k;
                  const root = new TreeNode(nums[maxIndex]);
                  root.left = build(nums, i, maxIndex - 1);
                  root.right = build(nums, maxIndex + 1, j);
                  return root; 
                }
                
                console.log(constructMaximumBinaryTree([3,2,1,6,0,5]))`,
                output: `TreeNode {
                  val: 6,
                  left: TreeNode {
                    val: 3,
                    left: null,
                    right: TreeNode { val: 2, left: null, right: [TreeNode] }
                  },
                  right: TreeNode {
                    val: 5,
                    left: TreeNode { val: 0, left: null, right: null },
                    right: null
                  }
                }`,
              },
            }}
          />
        ),
      },
      q525: {
        title: "Q655. Print Binary Tree (Q525)",
        content: (
          <Comp
            title="Q655. Print Binary Tree (Q525)"
            content1={
              <>
                Given the root of a binary tree, construct a 0-indexed m x n
                string matrix res that represents a formatted layout of the
                tree. The formatted layout matrix should be constructed using
                the following rules:
                <br />
                The height of the tree is height and the number of rows m should
                be equal to height + 1.
                <br />
                The number of columns n should be equal to 2height+1 - 1.
                <br />
                Place the root node in the middle of the top row (more formally,
                at location res[0][(n-1)/2]).
                <br />
                For each node that has been placed in the matrix at position
                res[r][c], place its left child at res[r+1][c-2height-r-1] and
                its right child at res[r+1][c+2height-r-1].
                <br />
                Continue this process until all the nodes in the tree have been
                placed.
                <br />
                Any empty cells should contain the empty string "".
                <br />
                Return the constructed matrix res.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode655,
                content: (
                  <>
                    Input: root = [1,2] Output: <br />
                    [["","1",""], <br />
                    ["2","",""]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1,2,3,null,4] <br />
                    Output: <br />
                    [["","","","1","","",""], <br />
                    ["","2","","","","3",""], <br />
                    ["","","4","","","",""]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 210].
                <br />
                -99 &lt;= Node.val &lt;= 99
                <br />
                The depth of the tree will be in the range [1, 10].
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `/**
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode() {}
                *     TreeNode(int val) { this.val = val; }
                *     TreeNode(int val, TreeNode left, TreeNode right) {
                *         this.val = val;
                *         this.left = left;
                *         this.right = right;
                *     }
                * }
                */
               class Solution {
                   public List<List<String>> printTree(TreeNode root) {
                       int height = getHeight(root);
                       int rows = height + 1;
                       int cols = (int)Math.pow(2, height + 1) -1;
                       List<List<String>> result = new ArrayList<>();
                       for(int i = 0; i < rows; i++) {
                           result.add(new ArrayList<>());
                           for(int j = 0; j < cols; j++) 
                               result.get(i).add("");
                       }
                       
                       Queue<Pair> queue = new LinkedList<>();
                       queue.offer(new Pair(root, 0, 0, cols -1));
                       
                       while(!queue.isEmpty()) {
                           int size = queue.size();
                           for(int i = 0; i < size; i++) {
                               Pair pair = queue.poll();
                               TreeNode node = pair.node;
                       int middle = pair.colL + (pair.colR - pair.colL) /2;
                               if(node.left != null) {
                                   queue.offer(new Pair(node.left, pair.row + 1, pair.colL, middle -1));
                               }
                               if(node.right != null) {
                                   queue.offer(new Pair(node.right, pair.row + 1, middle + 1, pair.colR));
                               }
                               result.get(pair.row).set(middle, node.val + "");
                           }
                       }
                       return result;
                   }
                   
                   private int getHeight(TreeNode root) {
                       if(root == null || (root.left == null && root.right == null)) return 0;
                       return 1 + Math.max(getHeight(root.left), getHeight(root.right));
                   }
                   
                   class Pair {
                       TreeNode node;
                       int row;
                       int colL;
                       int colR;
                       
                       public Pair(TreeNode node, int row, int colL, int colR) {
                           this.node = node;
                           this.row = row;
                           this.colL = colL;
                           this.colR = colR;
                       }
                   }
               }`,
                output: `[["","1",""],["2","",""]]`,
              },
            }}
          />
        ),
      },
      q526: {
        title: "Q657. Robot Return to Origin (Q526)",
        content: (
          <Comp
            title="Q657. Robot Return to Origin (Q526)"
            content1={
              <>
                There is a robot starting at the position (0, 0), the origin, on
                a 2D plane. Given a sequence of its moves, judge if this robot
                ends up at (0, 0) after it completes its moves.
                <br />
                You are given a string moves that represents the move sequence
                of the robot where moves[i] represents its ith move. Valid moves
                are 'R' (right), 'L' (left), 'U' (up), and 'D' (down).
                <br />
                Return true if the robot returns to the origin after it finishes
                all of its moves, or false otherwise.
                <br />
                Note: The way that the robot is "facing" is irrelevant. 'R' will
                always make the robot move to the right once, 'L' will always
                make it move left, etc. Also, assume that the magnitude of the
                robot's movement is the same for each move.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: moves = "UD"
                    <br />
                    Output: true
                    <br />
                    Explanation: The robot moves up once, and then down once.
                    All moves have the same magnitude, so it ended up at the
                    origin where it started. Therefore, we return true.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: moves = "LL"
                    <br />
                    Output: false
                    <br />
                    Explanation: The robot moves left twice. It ends up two
                    "moves" to the left of the origin. We return false because
                    it is not at the origin at the end of its moves.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= moves.length &lt;= 2 * 10^4 <br />
                moves only contains the characters 'U', 'D', 'L' and 'R'.
              </>
            }
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} moves
                * @return {boolean}
                */
               var judgeCircle = function(moves) {
                 let right = 0;
                 let up = 0;
                 for (let move of moves) {
                   switch (move) {
                     case 'R':
                       ++right;
                       break;
                     case 'L':
                       --right;
                       break;
                     case 'U':
                       ++up;
                       break;
                     case 'D':
                       --up;
                       break;
                   }
                 }
                 return right == 0 && up == 0;
               };
               
               console.log(judgeCircle("LL"))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q527: {
        title: "Q658. Find K Closest Elements (Q527)",
        content: (
          <Comp
            title="Q658. Find K Closest Elements (Q527)"
            content1={
              <>
                Given a sorted integer array arr, two integers k and x, return
                the k closest integers to x in the array. The result should also
                be sorted in ascending order.
                <br />
                An integer a is closer to x than an integer b if:
                <br />
                |a - x| &lt; |b - x|, or
                <br />
                |a - x| == |b - x| and a &lt; b
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [1,2,3,4,5], k = 4, x = 3<br />
                    Output: [1,2,3,4]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [1,2,3,4,5], k = 4, x = -1
                    <br />
                    Output: [1,2,3,4]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= k &lt;= arr.length <br />
                1 &lt;= arr.length &lt;= 10^4 <br />
                arr is sorted in ascending order. <br />
                -10^4 &lt;= arr[i], x &lt;= 10^4
              </>
            }
            tc="k + log n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} arr
                * @param {number} k
                * @param {number} x
                * @return {number[]}
                */
               var findClosestElements = function(arr, k, x) {
                 let l = 0;
                 let r = arr.length - k;
                 while (l < r) {
                   const m = l + parseInt((r - l) / 2);
                   if (x - arr[m] <= arr[m + k] - x)
                     r = m;
                   else
                     l = m + 1;
                 }
                 return arr.slice(l,l+k)
               };
               
               console.log(findClosestElements([1,2,3,4,5],3,2))`,
                output: `[ 1, 2, 3 ]`,
              },
            }}
          />
        ),
      },
      q528: {
        title: "Q659. Split Array into Consecutive Subsequences (Q528)",
        content: (
          <Comp
            title="Q659. Split Array into Consecutive Subsequences (Q528)"
            content1={
              <>
                You are given an integer array nums that is sorted in
                non-decreasing order.
                <br />
                Determine if it is possible to split nums into one or more
                subsequences such that both of the following conditions are
                true:
                <br />
                Each subsequence is a consecutive increasing sequence (i.e. each
                integer is exactly one more than the previous integer).
                <br />
                All subsequences have a length of 3 or more.
                <br />
                Return true if you can split nums according to the above
                conditions, or false otherwise.
                <br />A subsequence of an array is a new array that is formed
                from the original array by deleting some (can be none) of the
                elements without disturbing the relative positions of the
                remaining elements. (i.e., [1,3,5] is a subsequence of
                [1,2,3,4,5] while [1,3,2] is not).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,3,3,4,5]
                    <br />
                    Output: true
                    <br />
                    Explanation: nums can be split into the following
                    subsequences:
                    <br />
                    [1,2,3,3,4,5] --&gt; 1, 2, 3<br />
                    [1,2,3,3,4,5] --&gt; 3, 4, 5
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,3,4,4,5,5]
                    <br />
                    Output: true
                    <br />
                    Explanation: nums can be split into the following
                    subsequences:
                    <br />
                    [1,2,3,3,4,4,5,5] --&gt; 1, 2, 3, 4, 5<br />
                    [1,2,3,3,4,4,5,5] --&gt; 3, 4, 5
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4,4,5]
                    <br />
                    Output: false
                    <br />
                    Explanation: It is impossible to split nums into consecutive
                    increasing subsequences of length 3 or more.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4
                <br />
                -1000 &lt;= nums[i] &lt;= 1000
                <br />
                nums is sorted in non-decreasing order.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {boolean}
                */
               var isPossible = function(nums) {
                 const count = {};
                 const starts = []; 
                 const ends = [];   
                 for (let num of nums)
                   count[num] = (count[num] || 0) + 1
                 for (let i = 0; i < nums.length; ++i) {
                   if (i > 0 && nums[i] == nums[i - 1])
                     continue;
                   let num = nums[i];
                   let currCount = count[num];
                   let prevCount = ((num-1) in count) ? count[num - 1] : 0;
                   let nextCount = ((num+1) in count)  ? count[num + 1] : 0;
                   for (let j = 0; j < currCount - prevCount; ++j)
                     starts.push(num);
                   for (let j = 0; j < currCount - nextCount; ++j)
                     ends.push(num);
                 }
                 for (let i = 0; i < starts.length; ++i)
                   if (ends[i] - starts[i] < 2)
                     return false;
                 return true;   
               };
               
               console.log(isPossible([1,2,3,3,4,4,5,5]))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q529: {
        title: "Q661. Image Smoother (Q529)",
        content: (
          <Comp
            title="Q661. Image Smoother (Q529)"
            content1={
              <>
                An image smoother is a filter of the size 3 x 3 that can be
                applied to each cell of an image by rounding down the average of
                the cell and the eight surrounding cells (i.e., the average of
                the nine cells in the blue smoother). If one or more of the
                surrounding cells of a cell is not present, we do not consider
                it in the average (i.e., the average of the four cells in the
                red smoother).
              </>
            }
            content2={
              <>
                Given an m x n integer matrix img representing the grayscale of
                an image, return the image after applying the smoother on each
                cell of it.
              </>
            }
            img={Leetcode661}
            examples={[
              {
                content: (
                  <>
                    Input: img = [[1,1,1],[1,0,1],[1,1,1]]
                    <br />
                    Output: [[0,0,0],[0,0,0],[0,0,0]]
                    <br />
                    Explanation:
                    <br />
                    For the points (0,0), (0,2), (2,0), (2,2): floor(3/4) =
                    floor(0.75) = 0<br />
                    For the points (0,1), (1,0), (1,2), (2,1): floor(5/6) =
                    floor(0.83333333) = 0
                    <br />
                    For the point (1,1): floor(8/9) = floor(0.88888889) = 0
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: img = [[100,200,100],[200,50,200],[100,200,100]]
                    <br />
                    Output: [[137,141,137],[141,138,141],[137,141,137]]
                    <br />
                    Explanation:
                    <br />
                    For the points (0,0), (0,2), (2,0), (2,2):
                    floor((100+200+200+50)/4) = floor(137.5) = 137
                    <br />
                    For the points (0,1), (1,0), (1,2), (2,1):
                    floor((200+200+50+200+100+100)/6) = floor(141.666667) = 141
                    <br />
                    For the point (1,1):
                    floor((50+200+200+200+200+100+100+100+100)/9) =
                    floor(138.888889) = 138
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == img.length <br />
                n == img[i].length <br />
                1 &lt;= m, n &lt;= 200 <br />0 &lt;= img[i][j] &lt;= 255
              </>
            }
            tc="m.n"
            sc="m.n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} img
                * @return {number[][]}
                */
               var imageSmoother = function(img) {
                 let m = img.length;
                 let n = img[0].length;
                 const res = Array.from(Array(m),()=>Array(n).fill(0));
                 for (let i = 0; i < m; ++i)
                   for (let j = 0; j < n; ++j) {
                     let ones = 0;
                     let count = 0;
                     for (let y = Math.max(0, i - 1); y < Math.min(m, i + 2); ++y)
                       for (let x = Math.max(0, j - 1); x < Math.min(n, j + 2); ++x) {
                         ones += img[y][x];
                         ++count;
                       }
                     res[i][j] = parseInt(ones / count);
                   }
                 return res;
               };
               
               console.log(imageSmoother([[1,1,1],[1,0,1],[1,1,1]]))`,
                output: `[ [ 0, 0, 0 ], [ 0, 0, 0 ], [ 0, 0, 0 ] ]`,
              },
            }}
          />
        ),
      },
      q530: {
        title: "Q665. Maximum Width of Binary Tree (Q530)",
        content: (
          <Comp
            title="Q665. Maximum Width of Binary Tree (Q530)"
            content1={
              <>
                Given the root of a binary tree, return the maximum width of the
                given tree.
                <br />
                The maximum width of a tree is the maximum width among all
                levels.
                <br />
                The width of one level is defined as the length between the
                end-nodes (the leftmost and rightmost non-null nodes), where the
                null nodes between the end-nodes are also counted into the
                length calculation.
                <br />
                It is guaranteed that the answer will in the range of 32-bit
                signed integer.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode662,
                content: (
                  <>
                    Input: root = [1,3,2,5,3,null,9] <br />
                    Output: 4 <br />
                    Explanation: The maximum width existing in the third level
                    with the length 4 (5,3,null,9).
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1,3,null,5,3] <br />
                    Output: 2 <br />
                    Explanation: The maximum width existing in the third level
                    with the length 2 (5,3).
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1,3,2,5]
                    <br />
                    Output: 2<br />
                    Explanation: The maximum width existing in the second level
                    with the length 2 (3,2).
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 3000].
                <br />
                -100 &lt;= Node.val &lt;= 100
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
              }
              
              /**
               * @param {TreeNode} root
               * @return {number}
               */
              var widthOfBinaryTree = function(root) {
                if(!root) return 0;
                let res = 0;
                const q = [];
                q.push([root, 1]);
                while (q.length) {
                  let offset = q[0][1] * 2;
                  res = Math.max(res, q[q.length-1][1] - q[0][1] + 1);
                  for (let size = q.length; size > 0; --size) {
                    let [curr,index] = q.shift()
                    if (curr.left)
                      q.push([curr.left, index * 2 - offset]);
                    if (curr.right)
                      q.push([curr.right, index * 2 + 1 - offset]);
                  }
                }
                return res;
              };
              
              const t = new TreeNode(1)
              t.left = new TreeNode(3)
              t.left.left = new TreeNode(5)
              t.left.right = new TreeNode(3)
              t.right = new TreeNode(2)
              t.right.right = new TreeNode(9)
              console.log(widthOfBinaryTree(t))`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q531: {
        title: "Q664. Strange Printer (Q531)",
        content: (
          <Comp
            title="Q664. Strange Printer (Q531)"
            content1={
              <>
                There is a strange printer with the following two special
                properties:
                <br />
                The printer can only print a sequence of the same character each
                time.
                <br />
                At each turn, the printer can print new characters starting from
                and ending at any place and will cover the original existing
                characters.
                <br />
                Given a string s, return the minimum number of turns the printer
                needed to print it.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "aaabbb"
                    <br />
                    Output: 2<br />
                    Explanation: Print "aaa" first and then print "bbb".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "aba"
                    <br />
                    Output: 2<br />
                    Explanation: Print "aaa" first and then print "b" from the
                    second place of the string, which will cover the existing
                    character 'a'.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 100 <br />s consists of lowercase English
                letters.
              </>
            }
            tc="n^3"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               var strangePrinter = function(s) {
                 if (!s) return 0;
                 const n = s.length;
                 const dp = Array.from(Array(n),()=>Array(n).fill(n));
                 for (let i = 0; i < n; ++i)
                   dp[i][i] = 1;
                 for (let j = 0; j < n; ++j)
                   for (let i = j; i >= 0; --i)
                     for (let k = i; k < j; ++k)
                       dp[i][j] =
                           Math.min(dp[i][j], dp[i][k] + dp[k + 1][j] - (s[k] == s[j] ? 1 : 0));
                 return dp[0][n - 1];
               };
               
               console.log(strangePrinter("aaabbb"))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q532: {
        title: "Q665. Non-decreasing Array (Q532)",
        content: (
          <Comp
            title="Q665. Non-decreasing Array (Q532)"
            content1={
              <>
                Given an array nums with n integers, your task is to check if it
                could become non-decreasing by modifying at most one element.
                <br />
                We define an array is non-decreasing if nums[i] &lt;= nums[i +
                1] holds for every i (0-based) such that (0 &lt;= i &lt;= n -
                2).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [4,2,3]
                    <br />
                    Output: true
                    <br />
                    Explanation: You could modify the first 4 to 1 to get a
                    non-decreasing array.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [4,2,1]
                    <br />
                    Output: false
                    <br />
                    Explanation: You can't get a non-decreasing array by modify
                    at most one element.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == nums.length
                <br />
                1 &lt;= n &lt;= 10^4
                <br />
                -10^5 &lt;= nums[i] &lt;= 10^5
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {boolean}
                */
               var checkPossibility = function(nums) {
                 let j = -1;
                 for (let i = 0; i + 1 < nums.length; ++i)
                   if (nums[i] > nums[i + 1]) {
                     if (j != -1)
                       return false;
                     j = i;
                   }
                 return j == -1 || j == 0 || j == nums.length - 2
                       || nums[j - 1] <= nums[j + 1]
                       || nums[j] <= nums[j + 2];   
               };
               
               console.log(checkPossibility([4,2,3]))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q533: {
        title: "Q667. Beautiful Arrangement II (Q533)",
        content: (
          <Comp
            title="Q667. Beautiful Arrangement II (Q533)"
            content1={
              <>
                Given two integers n and k, construct a list answer that
                contains n different positive integers ranging from 1 to n and
                obeys the following requirement:
                <br />
                Suppose this list is answer = [a1, a2, a3, ... , an], then the
                list [|a1 - a2|, |a2 - a3|, |a3 - a4|, ... , |an-1 - an|] has
                exactly k distinct integers.
                <br />
                Return the list answer. If there multiple valid answers, return
                any of them.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 3, k = 1 <br />
                    Output: [1,2,3] <br />
                    Explanation: The [1,2,3] has three different positive
                    integers ranging from 1 to 3, and the [1,1] has exactly 1
                    distinct integer: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 3, k = 1 <br />
                    Output: [1,2,3] <br />
                    Explanation: The [1,2,3] has three different positive
                    integers ranging from 1 to 3, and the [1,1] has exactly 1
                    distinct integer: 1
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= k &lt; n &lt;= 10^4</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @param {number} k
                * @return {number[]}
                */
               var constructArray = function(n, k) {
                 const ans = Array(n).fill(0);
                 for (let i = 0; i < n - k; ++i)
                   ans[i] = i + 1;
                 for (let i = 0; i < k; ++i) {
                   if (i % 2 == 0)
                     ans[n - k + i] = parseInt(n - i / 2);
                   else
                     ans[n - k + i] = n - k + parseInt((i + 1) / 2);
                 }
                 return ans;
               };
               
               console.log(constructArray(3,1))
               `,
                output: `[ 1, 2, 3 ]`,
              },
            }}
          />
        ),
      },
      q534: {
        title: "Q668. Kth Smallest Number in Multiplication Table (Q534)",
        content: (
          <Comp
            title="Q668. Kth Smallest Number in Multiplication Table (Q534)"
            content1={
              <>
                Nearly everyone has used the Multiplication Table. The
                multiplication table of size m x n is an integer matrix mat
                where mat[i][j] == i * j (1-indexed).
                <br />
                Given three integers m, n, and k, return the kth smallest
                element in the m x n multiplication table.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode668,
                content: (
                  <>
                    Input: m = 3, n = 3, k = 5 Output: 3 Explanation: The 5th
                    smallest number is 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: m = 2, n = 3, k = 6 Output: 6 Explanation: The 6th
                    smallest number is 6.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= m, n &lt;= 3 * 10^4 <br />1 &lt;= k &lt;= m * n
              </>
            }
            tc="m.n log m.n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} m
                * @param {number} n
                * @param {number} k
                * @return {number}
                */
               var findKthNumber = function(m, n, k) {
                 let l = 1;
                 let r = m * n;
                 while (l < r) {
                   const mid = l + parseInt((r - l) / 2);
                   if (numsNoGreaterThan(m, n, mid) >= k)
                     r = mid;
                   else
                     l = mid + 1;
                 }
                 return l; 
               };
               
                 function numsNoGreaterThan(m, n, target) {
                   let count = 0;
                   for (let i = 1; i <= m; ++i)
                     count += Math.min(parseInt(target / i), n);
                   return count;
                 }
               
               console.log(findKthNumber(2,3,6))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q535: {
        title: "Q669. Trim a Binary Search Tree (Q535)",
        content: (
          <Comp
            title="Q669. Trim a Binary Search Tree (Q535)"
            content1={
              <>
                Given the root of a binary search tree and the lowest and
                highest boundaries as low and high, trim the tree so that all
                its elements lies in [low, high]. Trimming the tree should not
                change the relative structure of the elements that will remain
                in the tree (i.e., any node's descendant should remain a
                descendant). It can be proven that there is a unique answer.
                <br />
                Return the root of the trimmed binary search tree. Note that the
                root may change depending on the given bounds.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode669,
                content: (
                  <>
                    Input: root = [1,0,2], low = 1, high = 2<br />
                    Output: [1,null,2]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [3,0,4,null,2,null,null,1], low = 1, high = 3
                    <br />
                    Output: [3,2,null,1]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree in the range [1, 10^4].
                <br />
                0 &lt;= Node.val &lt;= 10^4
                <br />
                The value of each node in the tree is unique.
                <br />
                root is guaranteed to be a valid binary search tree.
                <br />0 &lt;= low &lt;= high &lt;= 10^4
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root
                 * @param {number} low
                 * @param {number} high
                 * @return {TreeNode}
                 */
                var trimBST = function(root, low, high) {
                  if (!root)
                    return null;
                  if (root.val < low)
                    return trimBST(root.right, low, high);
                  if (root.val > high)
                    return trimBST(root.left, low, high);
                  root.left = trimBST(root.left, low, high);
                  root.right = trimBST(root.right, low, high);
                  return root;  
                };
                
                const t = new TreeNode(1)
                t.left = new TreeNode(0)
                t.right = new TreeNode(2)
                console.log(trimBST(t,1,2))`,
                output: `TreeNode {
                  val: 1,
                  left: null,
                  right: TreeNode { val: 2, left: null, right: null }
                }`,
              },
            }}
          />
        ),
      },
      q536: {
        title: "Q670. Maximum Swap (Q536)",
        content: (
          <Comp
            title="Q670. Maximum Swap (Q536)"
            content1={
              <>
                You are given an integer num. You can swap two digits at most
                once to get the maximum valued number.
                <br />
                Return the maximum valued number you can get.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: num = 2736 <br />
                    Output: 7236 <br />
                    Explanation: Swap the number 2 and the number 7.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num = 9973 <br />
                    Output: 9973 <br />
                    Explanation: No swap.
                  </>
                ),
              },
            ]}
            constraints={<>0 &lt;= num &lt;= 108</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} num
                * @return {number}
                */
               var maximumSwap = function(num) {
                 const s = String(num).split("");
                 let lastIndex = Array(10).fill(0)
                 for (let i = 0; i < s.length; ++i)
                 lastIndex[+s[i]] = i;
                 for (let i = 0; i < s.length; ++i)
                   for (let d = 9; d > +s[i]; --d)
                     if (lastIndex[d] > i) {
                       s[lastIndex[d]] = s[i];
                       s[i] = String(d);
                       return +s.join("")
                     }
                 return num;    
               };
               
               console.log(maximumSwap(2736))`,
                output: `7326`,
              },
            }}
          />
        ),
      },
      q537: {
        title: "Q671. Second Minimum Node In a Binary Tree (Q537)",
        content: (
          <Comp
            title="Q671. Second Minimum Node In a Binary Tree (Q537)"
            content1={
              <>
                Given a non-empty special binary tree consisting of nodes with
                the non-negative value, where each node in this tree has exactly
                two or zero sub-node. If the node has two sub-nodes, then this
                node's value is the smaller value among its two sub-nodes. More
                formally, the property root.val = min(root.left.val,
                root.right.val) always holds.
                <br />
                Given such a binary tree, you need to output the second minimum
                value in the set made of all the nodes' value in the whole tree.
                <br />
                If no such second minimum value exists, output -1 instead.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode671,
                content: (
                  <>
                    Input: root = [2,2,5,null,null,5,7]
                    <br />
                    Output: 5<br />
                    Explanation: The smallest value is 2, the second smallest
                    value is 5.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [2,2,2]
                    <br />
                    Output: -1
                    <br />
                    Explanation: The smallest value is 2, but there isn't any
                    second smallest value.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 25].
                <br />
                1 &lt;= Node.val &lt;= 231 - 1<br />
                root.val == min(root.left.val, root.right.val) for each internal
                node of the tree.
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root
                 * @return {number}
                 */
                var findSecondMinimumValue = function(root) {
                      if (!root)
                      return -1;
                    return helper(root, root.val); 
                };
                
                function helper(root, min) {
                  if (!root)
                    return -1;
                  if (root.val > min)
                    return root.val;
                  let leftMin = helper(root.left, min);
                  let rightMin = helper(root.right, min);
                  if (leftMin == -1 || rightMin == -1)
                    return Math.max(leftMin, rightMin);
                  return Math.min(leftMin, rightMin);
                }
                
                const t = new TreeNode(2)
                t.left = new TreeNode(2)
                t.right = new TreeNode(5)
                t.right.left = new TreeNode(5)
                t.right.right = new TreeNode(7)
                console.log(findSecondMinimumValue(t))`,
                output: `5`,
              },
            }}
          />
        ),
      },
      q538: {
        title: "Q672. Bulb Switcher II (Q538)",
        content: (
          <Comp
            title="Q672. Bulb Switcher II (Q538)"
            content1={
              <>
                There is a room with n bulbs labeled from 1 to n that all are
                turned on initially, and four buttons on the wall. Each of the
                four buttons has a different functionality where:
                <br />
                <br /> Button 1: Flips the status of all the bulbs.
                <br /> Button 2: Flips the status of all the bulbs with even
                labels (i.e., 2, 4, ...).
                <br /> Button 3: Flips the status of all the bulbs with odd
                labels (i.e., 1, 3, ...).
                <br /> Button 4: Flips the status of all the bulbs with a label
                j = 3k + 1 where k = 0, 1, 2, ... (i.e., 1, 4, 7, 10, ...).
                <br /> You must make exactly presses button presses in total.
                For each press, you may pick any of the four buttons to press.
                <br />
                Given the two integers n and presses, return the number of
                different possible statuses after performing all presses button
                presses.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 1, presses = 1<br />
                    Output: 2<br />
                    Explanation: Status can be:
                    <br />
                    - [off] by pressing button 1<br />- [on] by pressing button
                    2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2, presses = 1<br />
                    Output: 3<br />
                    Explanation: Status can be:
                    <br />
                    - [off, off] by pressing button 1<br />
                    - [on, off] by pressing button 2<br />- [off, on] by
                    pressing button 3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 3, presses = 1<br />
                    Output: 4<br />
                    Explanation: Status can be:
                    <br />
                    - [off, off, off] by pressing button 1<br />
                    - [off, on, off] by pressing button 2<br />
                    - [on, off, on] by pressing button 3<br />- [off, on, on] by
                    pressing button 4
                  </>
                ),
              },
            ]}
            constraints={
              <>
                {" "}
                1 &lt;= n &lt;= 1000 <br />0 &lt;= presses &lt;= 1000
              </>
            }
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @param {number} presses
                * @return {number}
                */
               var flipLights = function(n, presses) {
                 n = Math.min(n, 3);
                 let m = presses
                 if (m == 0)
                   return 1;
                 if (m == 1)
                   return [2, 3, 4][n - 1];
                 if (m == 2)
                   return [2, 4, 7][n - 1];
                 return Math.pow(2, n);   
               };
               
               console.log(flipLights(1,1))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q539: {
        title: "Q673. Number of Longest Increasing Subsequence (Q539)",
        content: (
          <Comp
            title="Q673. Number of Longest Increasing Subsequence (Q539)"
            content1={
              <>
                Given an integer array nums, return the number of longest
                increasing subsequences.
                <br />
                Notice that the sequence has to be strictly increasing.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,3,5,4,7]
                    <br />
                    Output: 2<br />
                    Explanation: The two longest increasing subsequences are [1,
                    3, 4, 7] and [1, 3, 5, 7].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2,2,2,2,2]]
                    <br />
                    Output: 5]
                    <br />
                    Explanation: The length of longest continuous increasing
                    subsequence is 1, and there are 5 subsequences' length is 1,
                    so output 5.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 2000 <br />
                -10^6 &lt;= nums[i] &lt;= 10^6
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var findNumberOfLIS = function(nums) {
                 const n = nums.length;
                 let res = 0;
                 let maxLength = 0;
                 const dp = Array(n).fill(1); 
                 const count =  Array(n).fill(1);
                 for (let i = 0; i < n; ++i)
                   for (let j = 0; j < i; ++j)
                     if (nums[j] < nums[i])
                       if (dp[i] < dp[j] + 1) {
                         dp[i] = dp[j] + 1;
                         count[i] = count[j];
                       } else if (dp[i] == dp[j] + 1) {
                         count[i] += count[j];
                       }
                 for (let i = 0; i < n; ++i)
                   if (dp[i] > maxLength) {
                     maxLength = dp[i];
                     res = count[i];
                   } else if (dp[i] == maxLength) {
                     res += count[i];
                   }
                 return res; 
               };
               
               console.log(findNumberOfLIS([1,3,5,4,7]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q540: {
        title: "Q674. Longest Continuous Increasing Subsequence (Q540)",
        content: (
          <Comp
            title="Q674. Longest Continuous Increasing Subsequence (Q540)"
            content1={
              <>
                Given an unsorted array of integers nums, return the length of
                the longest continuous increasing subsequence (i.e. subarray).
                The subsequence must be strictly increasing.
                <br />A continuous increasing subsequence is defined by two
                indices l and r (l &lt; r) such that it is [nums[l], nums[l +
                1], ..., nums[r - 1], nums[r]] and for each l &lt;= i &lt; r,
                nums[i] &lt; nums[i + 1].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,3,5,4,7] Output: 3<br />
                    <br /> Explanation: The longest continuous increasing
                    subsequence is [1,3,5] with length 3. Even though [1,3,5,7]
                    is an increasing subsequence, it is not continuous as
                    elements 5 and 7 are separated by element 4.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2,2,2,2,2]
                    <br />
                    Output: 1<br />
                    Explanation: The longest continuous increasing subsequence
                    is [2] with length 1. Note that it must be strictly
                    increasing.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4
                <br />
                -10^9 &lt;= nums[i] &lt;= 10^9
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var findLengthOfLCIS = function(nums) {
                 let res = 0;
                 for (let l = 0, r = 0; r < nums.length; ++r) {
                 if (r > 0 && nums[r] <= nums[r - 1])
                   l = r;
                   res = Math.max(res, r - l + 1);
                 }
                 return res; 
               };
               
               console.log(findLengthOfLCIS([1,3,5,4,7]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q541: {
        title: "Q675. Cut Off Trees for Golf Event (Q541)",
        content: (
          <Comp
            title="Q675. Cut Off Trees for Golf Event (Q541)"
            content1={
              <>
                You are asked to cut off all the trees in a forest for a golf
                event. The forest is represented as an m x n matrix. In this
                matrix:
                <br />
                0 means the cell cannot be walked through. <br />
                1 represents an empty cell that can be walked through. <br />
                A number greater than 1 represents a tree in a cell that can be
                walked through, and this number is the tree's height.
                <br />
                In one step, you can walk in any of the four directions: north,
                east, south, and west. If you are standing in a cell with a
                tree, you can choose whether to cut it off.
                <br />
                You must cut off the trees in order from shortest to tallest.
                When you cut off a tree, the value at its cell becomes 1 (an
                empty cell).
                <br />
                Starting from the point (0, 0), return the minimum steps you
                need to walk to cut off all the trees. If you cannot cut off all
                the trees, return -1.
                <br />
                You are guaranteed that no two trees have the same height, and
                there is at least one tree needs to be cut off.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: forest = [[1,2,3],[0,0,4],[7,6,5]] <br />
                    Output: 6 <br />
                    Explanation: Following the path above allows you to cut off
                    the trees from shortest to tallest in 6 steps.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: forest = [[1,2,3],[0,0,0],[7,6,5]] <br />
                    Output: -1 <br />
                    Explanation: The trees in the bottom row cannot be accessed
                    as the middle row is blocked.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: forest = [[2,3,4],[0,0,5],[8,7,6]] <br />
                    Output: 6 <br />
                    Explanation: You can follow the same path as Example 1 to
                    cut off all the trees. Note that you can cut off the first
                    tree at (0, 0) before making any steps.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == forest.length <br />
                n == forest[i].length <br />
                1 &lt;= m, n &lt;= 50 <br />0 &lt;= forest[i][j] &lt;= 109
              </>
            }
            tc="m^2.n^2"
            sc="m.n"
            codes={{
              Java: {
                code: `// [[2,3,4],[0,0,5],[8,7,6]]
                class T {
                  public int i;
                  public int j;
                  public int height;
                  public T(int i, int j, int height) {
                    this.i = i;
                    this.j = j;
                    this.height = height;
                  }
                }
                
                class Solution {
                  public int cutOffTree(List<List<Integer>> forest) {
                    PriorityQueue<T> pq = new PriorityQueue<>((a, b) -> a.height - b.height);
                
                    for (int i = 0; i < forest.size(); ++i)
                      for (int j = 0; j < forest.get(0).size(); ++j)
                        if (forest.get(i).get(j) > 1)
                          pq.offer(new T(i, j, forest.get(i).get(j)));
                
                    int ans = 0;
                    int x = 0;
                    int y = 0;
                
                    while (!pq.isEmpty()) {
                      final int i = pq.peek().i;
                      final int j = pq.poll().j;
                      // walk from (x, y) to (i, j)
                      final int steps = bfs(forest, x, y, i, j);
                      if (steps < 0)
                        return -1;
                      ans += steps;
                      x = i;
                      y = j;
                    }
                
                    return ans;
                  }
                
                  private static final int[] dirs = {0, 1, 0, -1, 0};
                
                  private int bfs(List<List<Integer>> forest, int si, int sj, int ei, int ej) {
                    final int m = forest.size();
                    final int n = forest.get(0).size();
                    int steps = 0;
                    Queue<int[]> q = new LinkedList<>(Arrays.asList(new int[] {si, sj}));
                    boolean[][] seen = new boolean[m][n];
                    seen[si][sj] = true;
                
                    while (!q.isEmpty()) {
                      for (int size = q.size(); size > 0; --size) {
                        final int i = q.peek()[0];
                        final int j = q.poll()[1];
                        if (i == ei && j == ej)
                          return steps;
                        for (int k = 0; k < 4; ++k) {
                          final int x = i + dirs[k];
                          final int y = j + dirs[k + 1];
                          if (x < 0 || x == m || y < 0 || y == n)
                            continue;
                          if (seen[x][y] || forest.get(x).get(y) == 0)
                            continue;
                          q.offer(new int[] {x, y});
                          seen[x][y] = true;
                        }
                      }
                      ++steps;
                    }
                
                    return -1;
                  };
                }
                `,
                output: `6`,
              },
            }}
          />
        ),
      },
      q542: {
        title: "Q676. Implement Magic Dictionary (Q542)",
        content: (
          <Comp
            title="Q676. Implement Magic Dictionary (Q542)"
            content1={
              <>
                Design a data structure that is initialized with a list of
                different words. Provided a string, you should determine if you
                can change exactly one character in this string to match any
                word in the data structure.
                <br />
                Implement the MagicDictionary class:
                <br />
                MagicDictionary() Initializes the object.
                <br />
                void buildDict(String[] dictionary) Sets the data structure with
                an array of distinct strings dictionary.
                <br /> bool search(String searchWord) Returns true if you can
                change exactly one character in searchWord to match any string
                in the data structure, otherwise returns false.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br />
                    ["MagicDictionary", "buildDict", "search", "search",
                    "search", "search"]
                    <br />
                    [[], [["hello", "leetcode"]], ["hello"], ["hhllo"],
                    ["hell"], ["leetcoded"]]
                    <br />
                    Output
                    <br />
                    [null, null, false, true, false, false]
                    <br />
                    Explanation
                    <br /> MagicDictionary magicDictionary = new
                    MagicDictionary();
                    <br /> magicDictionary.buildDict(["hello", "leetcode"]);
                    <br /> magicDictionary.search("hello"); // return False
                    <br /> magicDictionary.search("hhllo"); // We can change the
                    second 'h' to 'e' to match "hello" so we return True
                    <br /> magicDictionary.search("hell"); // return False
                    <br /> magicDictionary.search("leetcoded"); // return False
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= dictionary.length &lt;= 100
                <br /> 1 &lt;= dictionary[i].length &lt;= 100
                <br /> dictionary[i] consists of only lower-case English
                letters.
                <br /> All the strings in dictionary are distinct.
                <br /> 1 &lt;= searchWord.length &lt;= 100
                <br /> searchWord consists of only lower-case English letters.
                <br /> buildDict will be called only once before search.
                <br /> At most 100 calls will be made to search.
              </>
            }
            tc="1"
            sc="n"
            codes={{
              Java: {
                code: `class MagicDictionary {
                  public void buildDict(String[] dictionary) {
                    for (final String word : dictionary)
                      for (int i = 0; i < word.length(); ++i) {
                        final String replaced = getReplaced(word, i);
                        dict.put(replaced, dict.containsKey(replaced) ? '*' : word.charAt(i));
                      }
                  }
                
                  public boolean search(String searchWord) {
                    for (int i = 0; i < searchWord.length(); ++i) {
                      final String replaced = getReplaced(searchWord, i);
                      if (dict.getOrDefault(replaced, searchWord.charAt(i)) != searchWord.charAt(i))
                        return true;
                    }
                    return false;
                  }
                
                  private Map<String, Character> dict = new HashMap<>();
                
                  private String getReplaced(final String s, int i) {
                    return s.substring(0, i) + '*' + s.substring(i + 1);
                  }
                }
                
                
                /**
                 * Your MagicDictionary object will be instantiated and called as such:
                 * MagicDictionary obj = new MagicDictionary();
                 * obj.buildDict(dictionary);
                 * boolean param_2 = obj.search(searchWord);
                 */`,
                output: `[null,null,false,true,false,false]`,
              },
            }}
          />
        ),
      },
      q543: {
        title: "Q677. Map Sum Pairs (Q543)",
        content: (
          <Comp
            title="Q677. Map Sum Pairs (Q543)"
            content1={
              <>
                Design a map that allows you to do the following:
                <br />
                Maps a string key to a given value.
                <br />
                Returns the sum of the values that have a key with a prefix
                equal to a given string.
                <br />
                Implement the MapSum class:
                <br />
                MapSum() Initializes the MapSum object.
                <br />
                void insert(String key, int val) Inserts the key-val pair into
                the map. If the key already existed, the original key-value pair
                will be overridden to the new one.
                <br /> int sum(string prefix) Returns the sum of all the pairs'
                value whose key starts with the prefix.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br />
                    ["MapSum", "insert", "sum", "insert", "sum"] [[], ["apple",
                    3], ["ap"], ["app", 2], ["ap"]] Output
                    <br />
                    [null, null, 3, null, 5]
                    <br />
                    Explanation
                    <br />
                    MapSum mapSum = new MapSum();
                    <br />
                    mapSum.insert("apple", 3); <br />
                    mapSum.sum("ap"); // return 3 (apple = 3)
                    <br />
                    mapSum.insert("app", 2); <br />
                    mapSum.sum("ap"); // return 5 (apple + app = 3 + 2 = 5)
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= key.length, prefix.length &lt;= 50
                <br />
                key and prefix consist of only lowercase English letters.
                <br />
                1 &lt;= val &lt;= 1000
                <br />
                At most 50 calls will be made to insert and sum.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `class MapSum {

                  private class Node {
                    final Node[] trie = new Node[26];
                    int val = 0;
                    int sum = 0;
                    
                    void put(String key, int newValue, int oldValue) {
                      Node n = this;
                      n.sum += newValue - oldValue;
                      for (char c: key.toCharArray()) {
                        n = n.trie[c-'a'] == null ? n.trie[c-'a'] = new Node() : n.trie[c-'a'] ;
                        n.sum += newValue - oldValue;
                      }
                      n.val = newValue;
                    }
                    
                    int get(String key) {
                      Node n = search(key);       
                      return n != null ? n.val : 0;
                    }
                    
                    int sum(String prefix) {
                      Node n = search(prefix);      
                      return n != null ? n.sum : 0;      
                    }
                    
                    Node search(String key) {
                      Node n = this;
                      for (char c: key.toCharArray()) if ((n = n.trie[c-'a']) == null) return null;        
                      return n;
                    }
                        
                  }
                  
                  private final Node trie = new Node();
                
                  public void insert(String key, int val) {
                    trie.put(key, val, trie.get(key));
                  }
                    
                  public int sum(String prefix) {
                     return trie.sum(prefix);   
                  }
                }
                /**
                 * Your MapSum object will be instantiated and called as such:
                 * MapSum obj = new MapSum();
                 * obj.insert(key,val);
                 * int param_2 = obj.sum(prefix);
                 */`,
                output: `[null,null,3,null,5]`,
              },
            }}
          />
        ),
      },
      q544: {
        title: "Q678. Valid Parenthesis String (Q644)",
        content: (
          <Comp
            title="Q678. Valid Parenthesis String (Q644)"
            content1={
              <>
                Given a string s containing only three types of characters: '(',
                ')' and '*', return true if s is valid.
                <br />
                The following rules define a valid string:
                <br />
                Any left parenthesis '(' must have a corresponding right
                parenthesis ')'.
                <br />
                Any right parenthesis ')' must have a corresponding left
                parenthesis '('.
                <br />
                Left parenthesis '(' must go before the corresponding right
                parenthesis ')'.
                <br />
                '*' could be treated as a single right parenthesis ')' or a
                single left parenthesis '(' or an empty string "".
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "()" <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "(*)" <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "(*))" <br />
                    Output: true
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 100 <br />
                s[i] is '(', ')' or '*'.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {boolean}
                */
               var checkValidString = function(s) {
                 let low = 0;  
                 let high = 0;
               
                 for (let c of s) {
                     switch (c) {
                       case '(':
                         ++low;
                         ++high;
                         break;
                       case ')':
                         low = Math.max(0, --low);
                         --high;
                         break;
                       case '*':
                         low = Math.max(0, --low);
                         ++high;
                         break;
                     }
                     if (high < 0)
                       return false;
                   }
               
                   return low == 0;
               };
               
               console.log(checkValidString("()"))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q545: {
        title: "Q679. 24 Game (Q545)",
        content: (
          <Comp
            title="Q679. 24 Game (Q545)"
            content1={
              <>
                You are given an integer array cards of length 4. You have four
                cards, each containing a number in the range [1, 9]. You should
                arrange the numbers on these cards in a mathematical expression
                using the operators ['+', '-', '*', '/'] and the parentheses '('
                and ')' to get the value 24.
                <br />
                You are restricted with the following rules:
                <br />
                The division operator '/' represents real division, not integer
                division.
                <br /> For example, 4 / (1 - 2 / 3) = 4 / (1 / 3) = 12.
                <br /> Every operation done is between two numbers. In
                particular, we cannot use '-' as a unary operator.
                <br /> For example, if cards = [1, 1, 1, 1], the expression "-1
                - 1 - 1 - 1" is not allowed.
                <br /> You cannot concatenate numbers together
                <br /> For example, if cards = [1, 2, 1, 2], the expression "12
                + 12" is not valid.
                <br /> Return true if you can get such expression that evaluates
                to 24, and false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: cards = [4,1,8,7]
                    <br />
                    Output: true
                    <br />
                    Explanation: (8-4) * (7-1) = 24
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: cards = [1,2,1,2]
                    <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                cards.length == 4<br />1 &lt;= cards[i] &lt;= 9
              </>
            }
            tc="2^n"
            sc="2^n"
            codes={{
              Java: {
                code: `class Solution {
                  public boolean judgePoint24(int[] nums) {
                    List<Double> doubleNums = new ArrayList<>();
                
                    for (final int num : nums)
                      doubleNums.add((double) num);
                
                    return dfs(doubleNums);
                  }
                
                  private boolean dfs(List<Double> nums) {
                    if (nums.size() == 1)
                      return Math.abs(nums.get(0) - 24.0) < 0.001;
                
                    for (int i = 0; i < nums.size(); ++i)
                      for (int j = i + 1; j < nums.size(); ++j)
                        for (final double num : generate(nums.get(i), nums.get(j))) {
                          List<Double> nextRound = new ArrayList<>(Arrays.asList(num));
                          for (int k = 0; k < nums.size(); ++k) {
                            if (k == i || k == j)
                              continue;
                            nextRound.add(nums.get(k));
                          }
                          if (dfs(nextRound))
                            return true;
                        }
                
                    return false;
                  }
                
                  private double[] generate(double a, double b) {
                    return new double[] {a * b, a / b, b / a, a + b, a - b, b - a};
                  }
                }
                `,
                output: `true`,
              },
            }}
          />
        ),
      },
      q546: {
        title: "Q680. Valid Palindrome II  (Q546)",
        content: (
          <Comp
            title="Q680. Valid Palindrome II  (Q546)"
            content1={
              <>
                Given a string s, return true if the s can be palindrome after
                deleting at most one character from it.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "aba"
                    <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "abca""
                    <br />
                    Output: true"
                    <br />
                    Explanation: You could delete the character 'c'.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "abc""
                    <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^5
                <br />s consists of lowercase English letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {boolean}
                */
               var validPalindrome = function(s) {
                 for (let l = 0, r = s.length - 1; l < r; ++l, --r)
                 if(s[l] != s[r])
                 return helper(s, l + 1, r) || helper(s, l, r - 1);
                 return true;  
               };
               
               function helper(s, l, r){
                 while (l < r)
                   if (s[l++] != s[r--])
                     return false;
                 return true;
               }
               
               console.log(validPalindrome("abca"))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q547: {
        title: "Q682. Baseball Game (Q547)",
        content: (
          <Comp
            title="Q682. Baseball Game (Q547)"
            content1={
              <>
                You are keeping score for a baseball game with strange rules.
                The game consists of several rounds, where the scores of past
                rounds may affect future rounds' scores.
                <br />
                At the beginning of the game, you start with an empty record.
                You are given a list of strings ops, where ops[i] is the ith
                operation you must apply to the record and is one of the
                following:
                <br />
                An integer x - Record a new score of x.
                <br /> "+" - Record a new score that is the sum of the previous
                two scores. It is guaranteed there will always be two previous
                scores.
                <br /> "D" - Record a new score that is double the previous
                score. It is guaranteed there will always be a previous score.
                <br /> "C" - Invalidate the previous score, removing it from the
                record. It is guaranteed there will always be a previous score.
                <br /> Return the sum of all the scores on the record.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: ops = ["5","2","C","D","+"] <br />
                    Output: 30 <br />
                    Explanation: <br />
                    "5" - Add 5 to the record, record is now [5]. <br />
                    "2" - Add 2 to the record, record is now [5, 2]. <br />
                    "C" - Invalidate and remove the previous score, record is
                    now [5].
                    <br /> "D" - Add 2 * 5 = 10 to the record, record is now [5,
                    10].
                    <br /> "+" - Add 5 + 10 = 15 to the record, record is now
                    [5, 10, 15].
                    <br /> The total sum is 5 + 10 + 15 = 30.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: ops = ["5","-2","4","C","D","9","+","+"] <br />
                    Output: 27 <br />
                    Explanation: <br />
                    "5" - Add 5 to the record, record is now [5]. <br />
                    "-2" - Add -2 to the record, record is now [5, -2]. <br />
                    "4" - Add 4 to the record, record is now [5, -2, 4]. <br />
                    "C" - Invalidate and remove the previous score, record is
                    now [5, -2].
                    <br /> "D" - Add 2 * -2 = -4 to the record, record is now
                    [5, -2, -4].
                    <br /> "9" - Add 9 to the record, record is now [5, -2, -4,
                    9].
                    <br /> "+" - Add -4 + 9 = 5 to the record, record is now [5,
                    -2, -4, 9, 5].
                    <br /> "+" - Add 9 + 5 = 14 to the record, record is now [5,
                    -2, -4, 9, 5, 14].
                    <br /> The total sum is 5 + -2 + -4 + 9 + 5 + 14 = 27.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: ops = ["1"] <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= ops.length &lt;= 1000 <br />
                ops[i] is "C", "D", "+", or a string representing an integer in
                the range [-3 * 10^4, 3 * 10^4]. <br />
                For operation "+", there will always be at least two previous
                scores on the record. <br />
                For operations "C" and "D", there will always be at least one
                previous score on the record.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                class Solution {
                  // ["5","2","C","D","+"]
                  public int calPoints(String[] ops) {
                   int count=0;
                 ArrayList<Integer> res= new ArrayList<>();
                 int sum=0;
                 for(int i=0;i<ops.length;i++){
                     if(ops[i].charAt(0)=='x'){
                         int x=Integer.parseInt(ops[i]);
                         System.out.println(x);
                         res.add(x);
                         count++;
                     }else if(ops[i].charAt(0)=='+') {
                       int y=res.get(count-1)+res.get(count-2);
                       res.add(y);
                       count++;
                     }else if(ops[i].charAt(0)=='D') {
                       res.add(res.get(count-1)*2);
                       count++;
                       
                     }else if(ops[i].charAt(0)=='C') {
                       res.remove(count-1);
                       count--;
                       
                     }else {
                       int z=Integer.parseInt(ops[i]);
                       res.add(z);
                       count++;
                     }
                 }
                 
                 for(int i:res) {
                   sum=sum+i;
                 }
             return sum;   
              }
                  }`,
                output: `30`,
              },
            }}
          />
        ),
      },
      q548: {
        title: "Q684. Redundant Connection (Q548)",
        content: (
          <Comp
            title="Q684. Redundant Connection (Q548)"
            content1={
              <>
                In this problem, a tree is an undirected graph that is connected
                and has no cycles.
                <br />
                You are given a graph that started as a tree with n nodes
                labeled from 1 to n, with one additional edge added. The added
                edge has two different vertices chosen from 1 to n, and was not
                an edge that already existed. The graph is represented as an
                array edges of length n where edges[i] = [ai, bi] indicates that
                there is an edge between nodes ai and bi in the graph.
                <br />
                Return an edge that can be removed so that the resulting graph
                is a tree of n nodes. If there are multiple answers, return the
                answer that occurs last in the input.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode684,
                content: <>Input: edges = [[1,2],[1,3],[2,3]] Output: [2,3]</>,
              },
              {
                content: (
                  <>
                    Input: edges = [[1,2],[2,3],[3,4],[1,4],[1,5]] Output: [1,4]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == edges.length 3 &lt;= n &lt;= 1000 <br />
                edges[i].length == 2 <br />
                1 &lt;= ai &lt; bi &lt;= edges.length <br />
                ai != bi <br />
                There are no repeated edges. <br />
                The given graph is connected.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // [[1,2],[1,3],[2,3]]
                class UF {
                  public UF(int n) {
                    id = new int[n];
                    for (int i = 0; i < n; ++i)
                      id[i] = i;
                  }
                
                  public boolean union(int u, int v) {
                    final int i = find(u);
                    final int j = find(v);
                    if (i == j)
                      return false;
                    id[i] = j;
                    return true;
                  }
                
                  private int[] id;
                
                  private int find(int u) {
                    return id[u] == u ? u : (id[u] = find(id[u]));
                  }
                }
                
                class Solution {
                  public int[] findRedundantConnection(int[][] edges) {
                    UF uf = new UF(edges.length + 1);
                
                    for (int[] edge : edges)
                      if (!uf.union(edge[0], edge[1]))
                        return edge;
                
                    throw new IllegalArgumentException();
                  }
                }
                `,
                output: `[2,3]`,
              },
            }}
          />
        ),
      },
      q549: {
        title: "Q685. Redundant Connection II (Q549)",
        content: (
          <Comp
            title="Q685. Redundant Connection II (Q549)"
            content1={
              <>
                In this problem, a rooted tree is a directed graph such that,
                there is exactly one node (the root) for which all other nodes
                are descendants of this node, plus every node has exactly one
                parent, except for the root node which has no parents.
                <br />
                The given input is a directed graph that started as a rooted
                tree with n nodes (with distinct values from 1 to n), with one
                additional directed edge added. The added edge has two different
                vertices chosen from 1 to n, and was not an edge that already
                existed.
                <br />
                The resulting graph is given as a 2D-array of edges. Each
                element of edges is a pair [ui, vi] that represents a directed
                edge connecting nodes ui and vi, where ui is a parent of child
                vi.
                <br />
                Return an edge that can be removed so that the resulting graph
                is a rooted tree of n nodes. If there are multiple answers,
                return the answer that occurs last in the given 2D-array.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode685,
                content: (
                  <>
                    Input: edges = [[1,2],[1,3],[2,3]] <br />
                    Output: [2,3]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: edges = [[1,2],[2,3],[3,4],[4,1],[1,5]]
                    <br /> Output: [4,1]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == edges.length <br />
                3 &lt;= n &lt;= 1000 <br />
                edges[i].length == 2 <br />
                1 &lt;= ui, vi &lt;= n <br />
                ui != vi
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // [[1,2],[1,3],[2,3]]
                class UF {
                  public UF(int n) {
                    id = new int[n];
                    for (int i = 0; i < n; ++i)
                      id[i] = i;
                  }
                
                  public boolean union(int u, int v) {
                    final int i = find(u);
                    final int j = find(v);
                    if (i == j)
                      return false;
                    id[i] = j;
                    return true;
                  }
                
                  private int[] id;
                
                  private int find(int u) {
                    return id[u] == u ? u : (id[u] = find(id[u]));
                  }
                }
                
                class Solution {
                  public int[] findRedundantDirectedConnection(int[][] edges) {
                    int[] ids = new int[edges.length + 1];
                    int nodeWithTwoParents = 0;
                
                    for (int[] edge : edges) {
                      final int v = edge[1];
                      if (++ids[v] == 2) {
                        nodeWithTwoParents = v;
                        break;
                      }
                    }
                
                    if (nodeWithTwoParents == 0)
                      return findRedundantDirectedConnection(edges, -1);
                
                    for (int i = edges.length - 1; i >= 0; --i)
                      if (edges[i][1] == nodeWithTwoParents)
                        if (findRedundantDirectedConnection(edges, i).length == 0)
                          return edges[i];
                
                    throw new IllegalArgumentException();
                  }
                
                  private int[] findRedundantDirectedConnection(int[][] edges, int skippedEdgeIndex) {
                    UF uf = new UF(edges.length + 1);
                
                    for (int i = 0; i < edges.length; ++i) {
                      if (i == skippedEdgeIndex)
                        continue;
                      if (!uf.union(edges[i][0], edges[i][1]))
                        return edges[i];
                    }
                
                    return new int[] {};
                  }
                }
                `,
                output: `[2,3]`,
              },
            }}
          />
        ),
      },
      q550: {
        title: "Q686. Repeated String Match (Q550)",
        content: (
          <Comp
            title="Q686. Repeated String Match (Q550)"
            content1={
              <>
                Given two strings a and b, return the minimum number of times
                you should repeat string a so that string b is a substring of
                it. If it is impossible for b​​​​​​ to be a substring of a after
                repeating it, return -1.
                <br />
                Notice: string "abc" repeated 0 times is "", repeated 1 time is
                "abc" and repeated 2 times is "abcabc".
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: a = "abcd", b = "cdabcdab" <br />
                    Output: 3 <br />
                    Explanation: We return 3 because by repeating a three times
                    "abcdabcdabcd", b is a substring of it.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: a = "a", b = "aa" <br />
                    Output: 2
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= a.length, b.length &lt;= 10^4 <br />a and b consist of
                lowercase English letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} a
                * @param {string} b
                * @return {number}
                */
               var repeatedStringMatch = function(a, b) {
                 let n = Math.ceil(b.length / a.length)
                 let s = Array(n).fill(a).join("")
                 if(s.includes(b))
                     return n
                 if((s + a).includes(b))
                   return n + 1
                 return -1
               };
               
               console.log(repeatedStringMatch("a","aa"))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q551: {
        title: "Q687. Longest Univalue Path (Q551)",
        content: (
          <Comp
            title="Q687. Longest Univalue Path (Q551)"
            content1={
              <>
                Given the root of a binary tree, return the length of the
                longest path, where each node in the path has the same value.
                This path may or may not pass through the root.
                <br />
                The length of the path between two nodes is represented by the
                number of edges between them.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode687,
                content: (
                  <>
                    Input: root = [5,4,5,1,1,5] <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1,4,5,4,4,5] <br />
                    Output: 2
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [0, 104].
                <br /> -1000 &lt;= Node.val &lt;= 1000
                <br /> The depth of the tree will not exceed 1000.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
              }
              
              /**
               * @param {TreeNode} root
               * @return {number}
               */
              var longestUnivaluePath = function(root) {
                const res = {val:0};
                dfs(root,null,res);      
                return res.val;   
              };
              
              function dfs(node,parent,res){
               if (!node) return 0;
               let leftLen = dfs(node.left, node,res);
               let rightLen = dfs(node.right, node,res);
               let max1 = Math.max(leftLen, rightLen);
               let max2 = Math.max(max1, leftLen + rightLen);       
               res.val = Math.max(res.val, max2);       
               if (parent) {
                   if (parent.val == node.val) {
                       return max1 + 1;
                   }
                   return 0;
               }
               return res.val;
              }
              
              const t = new TreeNode(5)
              t.left = new TreeNode(4)
              t.left.left = new TreeNode(1)
              t.left.right = new TreeNode(1) 
              t.right = new TreeNode(5)
              t.right.right = new TreeNode(5)
              console.log(longestUnivaluePath(t))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q552: {
        title: "Q688. Knight Probability in Chessboard (Q552)",
        content: (
          <Comp
            title="Q688. Knight Probability in Chessboard (Q552)"
            content1={
              <>
                On an n x n chessboard, a knight starts at the cell (row,
                column) and attempts to make exactly k moves. The rows and
                columns are 0-indexed, so the top-left cell is (0, 0), and the
                bottom-right cell is (n - 1, n - 1).
                <br />A chess knight has eight possible moves it can make, as
                illustrated below. Each move is two cells in a cardinal
                direction, then one cell in an orthogonal direction.
              </>
            }
            img={Leetcode688}
            content2={
              <>
                Each time the knight is to move, it chooses one of eight
                possible moves uniformly at random (even if the piece would go
                off the chessboard) and moves there.
                <br />
                The knight continues moving until it has made exactly k moves or
                has moved off the chessboard.
                <br />
                Return the probability that the knight remains on the board
                after it has stopped moving.
              </>
            }
            examples={[
              {
                content: (
                  <>
                    Input: n = 3, k = 2, row = 0, column = 0<br />
                    Output: 0.06250
                    <br />
                    Explanation: There are two moves (to (1,2), (2,1)) that will
                    keep the knight on the board. From each of those positions,
                    there are also two moves that will keep the knight on the
                    board. The total probability the knight stays on the board
                    is 0.0625.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1, k = 0, row = 0, column = 0<br />
                    Output: 1.00000
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 25
                <br />
                0 &lt;= k &lt;= 100
                <br />0 &lt;= row, column &lt;= n
              </>
            }
            tc="k.n^2"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @param {number} k
                * @param {number} row
                * @param {number} column
                * @return {number}
                */
               var knightProbability = function(n, k, row, column) {
                 const kProb = 0.125;
                 const dirs = [[-2, 1], [-1, 2], [1, 2], [2, 1], [2, -1], [1, -2], [-1, -2], [-2, -1]];
                 let dp = Array.from(Array(n),()=>Array(n).fill(0));
                 dp[row][column] = 1.0;
                 for (let m = 0; m < k; ++m) {
                   let newDp =  Array.from(Array(n),()=>Array(n).fill(0));
                   for (let i = 0; i < n; ++i)
                     for (let j = 0; j < n; ++j)
                       if (dp[i][j] > 0.0) {
                         for (let dir of dirs) {
                           let x = i + dir[0];
                           let y = j + dir[1];
                           if (x < 0 || x >= n || y < 0 || y >= n)
                             continue;
                           newDp[x][y] += dp[i][j] * kProb;
                         }
                       }
                   dp = newDp;
                 }
                 let res = 0.0;
                 for (let row of dp)
                   res += row.reduce((a,b)=>a+b,0);
                 return res;   
               };
               
               console.log(knightProbability(3,2,0,0))`,
                output: `0.0625`,
              },
            }}
          />
        ),
      },
      q553: {
        title: "Q689. Maximum Sum of 3 Non-Overlapping Subarrays (Q553)",
        content: (
          <Comp
            title="Q689. Maximum Sum of 3 Non-Overlapping Subarrays (Q553)"
            content1={
              <>
                Given an integer array nums and an integer k, find three
                non-overlapping subarrays of length k with maximum sum and
                return them.
                <br />
                Return the result as a list of indices representing the starting
                position of each interval (0-indexed). If there are multiple
                answers, return the lexicographically smallest one.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,1,2,6,7,5,1], k = 2<br />
                    Output: [0,3,5]
                    <br />
                    Explanation: Subarrays [1, 2], [2, 6], [7, 5] correspond to
                    the starting indices [0, 3, 5]. We could have also taken [2,
                    1], but an answer of [1, 3, 5] would be lexicographically
                    larger.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,1,2,1,2,1,2,1], k = 2<br />
                    Output: [0,2,4]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 2 * 10^4
                <br />
                1 &lt;= nums[i] &lt; 2^16
                <br />1 &lt;= k &lt;= floor(nums.length / 3)
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number[]}
                */
               var maxSumOfThreeSubarrays = function(nums, k) {
                 const n = nums.length - k + 1;
                 let sums = Array(n).fill(0)
                 let l = Array(n).fill(0)
                 let r = Array(n).fill(0)
                 let sum = 0;
                 for (let i = 0; i < nums.length; ++i) {
                   sum += nums[i];
                   if (i >= k)
                     sum -= nums[i - k];
                   if (i >= k - 1)
                     sums[i - k + 1] = sum;
                 }
                 let maxIndex = 0;
                 for (let i = 0; i < n; ++i) {
                   if (sums[i] > sums[maxIndex])
                     maxIndex = i;
                   l[i] = maxIndex;
                 }
                 maxIndex = n - 1;
                 for (let i = n - 1; i >= 0; --i) {
                   if (sums[i] >= sums[maxIndex])
                     maxIndex = i;
                   r[i] = maxIndex;
                 }
                 let res = [-1, -1, -1];
                 for (let i = k; i + k < n; ++i)
                   if (res[0] == -1 ||
                       sums[res[0]] + sums[res[1]] + sums[res[2]] < sums[l[i - k]] + sums[i] + sums[r[i + k]]) {
                     res[0] = l[i - k];
                     res[1] = i;
                     res[2] = r[i + k];
                   }
                 return res;    
               };
               
               console.log(maxSumOfThreeSubarrays([1,2,1,2,1,2,1,2,1],2))`,
                output: `[ 0, 2, 4 ]`,
              },
            }}
          />
        ),
      },
      q554: {
        title: "Q690. Employee Importance (Q554)",
        content: (
          <Comp
            title="Q690. Employee Importance (Q554)"
            content1={
              <>
                You have a data structure of employee information, including the
                employee's unique ID, importance value, and direct subordinates'
                IDs.
                <br />
                You are given an array of employees employees where:
                <br />
                employees[i].id is the ID of the ith employee.
                <br />
                employees[i].importance is the importance value of the ith
                employee.
                <br />
                employees[i].subordinates is a list of the IDs of the direct
                subordinates of the ith employee.
                <br />
                Given an integer id that represents an employee's ID, return the
                total importance value of this employee and all their direct and
                indirect subordinates.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode690,
                content: (
                  <>
                    Input: employees = [[1,5,[2,3]],[2,3,[]],[3,3,[]]], id = 1
                    <br /> Output: 11 <br />
                    Explanation: Employee 1 has an importance value of 5 and has
                    two direct subordinates: employee 2 and employee 3. They
                    both have an importance value of 3. Thus, the total
                    importance value of employee 1 is 5 + 3 + 3 = 11.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: employees = [[1,2,[5]],[5,-3,[]]], id = 5
                    <br /> Output: -3 <br />
                    Explanation: Employee 5 has an importance value of -3 and
                    has no direct subordinates. Thus, the total importance value
                    of employee 5 is -3.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= employees.length &lt;= 2000 <br />
                1 &lt;= employees[i].id &lt;= 2000 <br />
                All employees[i].id are unique. <br />
                -100 &lt;= employees[i].importance &lt;= 100 <br />
                One employee has at most one direct leader and may have several
                subordinates.
                <br /> The IDs in employees[i].subordinates are valid IDs.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `/*
                // Definition for Employee.
                class Employee {
                    public int id;
                    public int importance;
                    public List<Integer> subordinates;
                };
                */
                
                class Solution {
                  public int getImportance(List<Employee> employees, int id) {
                    Map<Integer, Employee> idToEmployee = new HashMap<>();
                
                    for (Employee employee : employees)
                      idToEmployee.put(employee.id, employee);
                
                    return dfs(id, idToEmployee);
                  }
                
                  private int dfs(int id, Map<Integer, Employee> idToEmployee) {
                    int values = 0;
                
                    for (final int subId : idToEmployee.get(id).subordinates)
                      values += dfs(subId, idToEmployee);
                
                    return idToEmployee.get(id).importance + values;
                  }
                }
                `,
                output: `11`,
              },
            }}
          />
        ),
      },
      q555: {
        title: "Q691. Stickers to Spell Word (Q555)",
        content: (
          <Comp
            title="Q691. Stickers to Spell Word (Q555)"
            content1={
              <>
                We are given n different types of stickers. Each sticker has a
                lowercase English word on it.
                <br />
                You would like to spell out the given string target by cutting
                individual letters from your collection of stickers and
                rearranging them. You can use each sticker more than once if you
                want, and you have infinite quantities of each sticker.
                <br />
                Return the minimum number of stickers that you need to spell out
                target. If the task is impossible, return -1.
                <br />
                Note: In all test cases, all words were chosen randomly from the
                1000 most common US English words, and target was chosen as a
                concatenation of two random words.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: stickers = ["with","example","science"], target =
                    "thehat" Output: 3<br />
                    Explanation:
                    <br />
                    We can use 2 "with" stickers, and 1 "example" sticker. After
                    cutting and rearrange the letters of those stickers, we can
                    form the target "thehat". Also, this is the minimum number
                    of stickers necessary to form the target string.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: stickers = ["notice","possible"], target =
                    "basicbasic" Output: -1
                    <br />
                    Explanation:
                    <br />
                    We cannot form the target "basicbasic" from cutting letters
                    from the given stickers
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == stickers.length <br />
                1 &lt;= n &lt;= 50 <br />
                1 &lt;= stickers[i].length &lt;= 10 <br />
                1 &lt;= target &lt;= 15 <br />
                stickers[i] and target consist of lowercase English letters.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} stickers
                * @param {string} target
                * @return {number}
                */
               const MAX = Number.MAX_SAFE_INTEGER;
               const minStickers = (stickers, target) => {
                   let n = target.length;
                   let dp = Array(1 << n).fill(MAX);
                   dp[0] = 0;
                   for (let i = 0; i < 1 << n; i++) { 
                       if (dp[i] == MAX) continue;
                       for (const s of stickers) {
                           let cur = i;
                           for (const c of s) {
                               for (let j = 0; j < n; j++) {
                                   if (cur & (1 << j)) continue;  
                                   if (c == target[j]) {
                                       cur |= 1 << j;  
                                       break;
                                   }
                               }
                           }
                           dp[cur] = Math.min(dp[cur], dp[i] + 1);
                       }
                   }
                   return dp[(1 << n) - 1] == MAX ? -1 : dp[(1 << n) - 1];
               };
               
               console.log(minStickers(["with","example","science"],"thehat"))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q556: {
        title: "Q692. Top K Frequent Words (Q556)",
        content: (
          <Comp
            title="Q692. Top K Frequent Words (Q556)"
            content1={
              <>
                Given an array of strings words and an integer k, return the k
                most frequent strings.
                <br />
                Return the answer sorted by the frequency from highest to
                lowest. Sort the words with the same frequency by their
                lexicographical order.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: words = ["i","love","leetcode","i","love","coding"],
                    k = 2
                    <br />
                    Output: ["i","love"]
                    <br />
                    Explanation: "i" and "love" are the two most frequent words.
                    Note that "i" comes before "love" due to a lower
                    alphabetical order.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words =
                    ["the","day","is","sunny","the","the","the","sunny","is","is"],
                    k = 4
                    <br />
                    Output: ["the","is","sunny","day"]
                    <br />
                    Explanation: "the", "is", "sunny" and "day" are the four
                    most frequent words, with the number of occurrence being 4,
                    3, 2 and 1 respectively.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= words.length &lt;= 500 <br />
                1 &lt;= words[i] &lt;= 10 <br />
                words[i] consists of lowercase English letters. <br />k is in
                the range [1, The number of unique words[i]]
              </>
            }
            fp={
              <>
                <b>Follow up :</b> Could you solve it in O(n log(k)) time and
                O(n) extra space?
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} words
                * @param {number} k
                * @return {string[]}
                */
               var topKFrequent = function(words, k) {
                 const n = words.length;
                 const res = [];
                 const bucket = Array(n+1).fill(null);
                 const count = {};
                 for (let word of words)
                   count[word] =  (count[word] || 0) + 1;
                 for (let word of Object.keys(count)) {
                   const freq = count[word];
                   if (!bucket[freq])
                     bucket[freq] = [];
                   bucket[freq].push(word);
                 }
                 for (let freq = n; freq > 0; --freq)
                   if (bucket[freq]) {
                     bucket[freq] = bucket[freq].sort((a,b)=>a>b?1:-1)
                     for (let word of bucket[freq]) {
                       res.push(word);
                       if (res.length == k)
                         return res;
                     }
                   }    
               };
               
               console.log(topKFrequent(["i","love","leetcode","i","love","coding"],
               3))`,
                output: `[ 'i', 'love', 'coding' ]`,
              },
            }}
          />
        ),
      },
      q557: {
        title: "Q693. Binary Number with Alternating Bits (Q557)",
        content: (
          <Comp
            title="Q693. Binary Number with Alternating Bits (Q557)"
            content1={
              <>
                Given a positive integer, check whether it has alternating bits:
                namely, if two adjacent bits will always have different values.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 5<br />
                    Output: true
                    <br />
                    Explanation: The binary representation of 5 is: 101
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 7<br />
                    Output: false
                    <br />
                    Explanation: The binary representation of 7 is: 111.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 11
                    <br />
                    Output: false
                    <br />
                    Explanation: The binary representation of 11 is: 1011.
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 2^31 - 1</>}
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {boolean}
                */
               var hasAlternatingBits = function(n) {
               while(n !=0)
               {
               let temp1 = n&1;
               n = n >> 1;
               let temp2 = n&1;
               if(temp1 == temp2)
               return false;
               }
               return true;
               };
               
               console.log(hasAlternatingBits(5))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q558: {
        title: "Q695. Max Area of Island (Q558)",
        content: (
          <Comp
            title="Q695. Max Area of Island (Q558)"
            content1={
              <>
                You are given an m x n binary matrix grid. An island is a group
                of 1's (representing land) connected 4-directionally (horizontal
                or vertical.) You may assume all four edges of the grid are
                surrounded by water.
                <br />
                The area of an island is the number of cells with a value 1 in
                the island.
                <br />
                Return the maximum area of an island in grid. If there is no
                island, return 0.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode695,
                content: (
                  <>
                    Input: grid =
                    [[0,0,1,0,0,0,0,1,0,0,0,0,0],[0,0,0,0,0,0,0,1,1,1,0,0,0],[0,1,1,0,1,0,0,0,0,0,0,0,0],[0,1,0,0,1,1,0,0,1,0,1,0,0],[0,1,0,0,1,1,0,0,1,1,1,0,0],[0,0,0,0,0,0,0,0,0,0,1,0,0],[0,0,0,0,0,0,0,1,1,1,0,0,0],[0,0,0,0,0,0,0,1,1,0,0,0,0]]
                    <br /> Output: 6<br />
                    Explanation: The answer is not 11, because the island must
                    be connected 4-directionally.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[0,0,0,0,0,0,0,0]]
                    <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == grid.length
                <br />
                n == grid[i].length
                <br />
                1 &lt;= m, n &lt;= 50
                <br />
                grid[i][j] is either 0 or 1
              </>
            }
            tc="m.n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} grid
                * @return {number}
                */
               
               function dfs(grid, i, j) {
                   if (i < 0 || i == grid.length || j < 0 || j == grid[0].length)
                     return 0;
                   if (grid[i][j] != 1)
                     return 0;
                   grid[i][j] = 2;
                   return 1 + dfs(grid, i + 1, j) + dfs(grid, i - 1, j)
                            + dfs(grid, i, j + 1) + dfs(grid, i, j - 1);
                 }
               var maxAreaOfIsland = function(grid) {
                 let res = 0;
                 for (let i = 0; i < grid.length; ++i)
                   for (let j = 0; j < grid[0].length; ++j)
                     res = Math.max(res, dfs(grid, i, j));
                 return res;
               };
               
               console.log(maxAreaOfIsland([[0,0,1,0,0,0,0,1,0,0,0,0,0],[0,0,0,0,0,0,0,1,1,1,0,0,0],[0,1,1,0,1,0,0,0,0,0,0,0,0],[0,1,0,0,1,1,0,0,1,0,1,0,0],[0,1,0,0,1,1,0,0,1,1,1,0,0],[0,0,0,0,0,0,0,0,0,0,1,0,0],[0,0,0,0,0,0,0,1,1,1,0,0,0],[0,0,0,0,0,0,0,1,1,0,0,0,0]]))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q559: {
        title: "Q696. Count Binary Substrings (Q559)",
        content: (
          <Comp
            title="Q696. Count Binary Substrings (Q559)"
            content1={
              <>
                Give a binary string s, return the number of non-empty
                substrings that have the same number of 0's and 1's, and all the
                0's and all the 1's in these substrings are grouped
                consecutively.
                <br />
                Substrings that occur multiple times are counted the number of
                times they occur.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "00110011" <br />
                    Output: 6 <br />
                    Explanation: There are 6 substrings that have equal number
                    of consecutive 1's and 0's: "0011", "01", "1100", "10",
                    "0011", and "01". Notice that some of these substrings
                    repeat and are counted the number of times they occur. Also,
                    "00110011" is not a valid substring because all the 0's (and
                    1's) are not grouped together.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "10101"
                    <br />
                    Output: 4<br />
                    Explanation: There are 4 substrings: "10", "01", "10", "01"
                    that have equal number of consecutive 1's and 0's.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^5 <br />
                s[i] is either '0' or '1'.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               var countBinarySubstrings = function(s) {
                 let res = 0;
                 let prevEquals = 0;
                 let currEquals = 1;
                 for (let i = 0; i + 1 < s.length; ++i)
                   if (s[i] == s[i + 1])
                     ++currEquals;
                   else {
                     res += Math.min(prevEquals, currEquals);
                     prevEquals = currEquals;
                     currEquals = 1;
                   }
                 return res + Math.min(prevEquals, currEquals);
               };
               
               console.log(countBinarySubstrings("10101"))`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q560: {
        title: "Q697. Degree of an Array (Q560)",
        content: (
          <Comp
            title="Q697. Degree of an Array (Q560)"
            content1={
              <>
                Given a non-empty array of non-negative integers nums, the
                degree of this array is defined as the maximum frequency of any
                one of its elements.
                <br />
                Your task is to find the smallest possible length of a
                (contiguous) subarray of nums, that has the same degree as nums.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,2,3,1] Output: 2<br />
                    Explanation: <br />
                    The input array has a degree of 2 because both elements 1
                    and 2 appear twice. Of the subarrays that have the same
                    degree:
                    <br /> [1, 2, 2, 3, 1], [1, 2, 2, 3], [2, 2, 3, 1], [1, 2,
                    2], [2, 2, 3], [2, 2]
                    <br /> The shortest length is 2. So return 2.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,2,3,1,4,2]
                    <br />
                    Output: 6<br />
                    Explanation: <br />
                    The degree is 3 because the element 2 is repeated 3 times.
                    So [2,2,3,1,4,2] is the shortest subarray, therefore
                    returning 6.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                nums.length will be between 1 and 50,000.
                <br />
                nums[i] will be an integer between 0 and 49,999.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // [1,2,2,3,1,4,2]
                class Solution {
                  public int findShortestSubArray(int[] nums) {
                    int ans = 0;
                    int degree = 0;
                    Map<Integer, Integer> debut = new HashMap<>();
                    Map<Integer, Integer> count = new HashMap<>();
                
                    for (int i = 0; i < nums.length; ++i) {
                      final int num = nums[i];
                      debut.putIfAbsent(num, i);
                      count.put(num, count.getOrDefault(num, 0) + 1);
                      if (count.get(num) > degree) {
                        degree = count.get(num);
                        ans = i - debut.get(num) + 1;
                      } else if (count.get(num) == degree) {
                        ans = Math.min(ans, i - debut.get(num) + 1);
                      }
                    }
                
                    return ans;
                  }
                }
                `,
                output: `6`,
              },
            }}
          />
        ),
      },
      q561: {
        title: "Q698. Partition to K Equal Sum Subsets (Q561)",
        content: (
          <Comp
            title="Q698. Partition to K Equal Sum Subsets (Q561)"
            content1={
              <>
                Given an integer array nums and an integer k, return true if it
                is possible to divide this array into k non-empty subsets whose
                sums are all equal.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [4,3,2,3,5,2,1], k = 4<br />
                    Output: true
                    <br />
                    Explanation: It is possible to divide it into 4 subsets (5),
                    (1, 4), (2,3), (2,3) with equal sums.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4], k = 3<br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= k &lt;= nums.length &lt;= 16
                <br />
                1 &lt;= nums[i] &lt;= 10^4
                <br />
                The frequency of each element is in the range [1, 4
              </>
            }
            tc="2^n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {boolean}
                */
               var canPartitionKSubsets = function(nums, k) {
                 let sum = nums.reduce((a,b)=>a+b,0);
                 if (sum % k != 0)
                   return false;
                 const t = parseInt(sum / k); 
                 const seen = Array(nums.length).fill(0);
                 return dfs(nums, 0, k, t, t, seen);
               };
               
               function dfs(nums, s, k, target, subsetTargetSum, seen) {
                   if (k == 0)
                     return true;
                   if (target < 0)
                     return false;
                   if (target == 0)
                     return dfs(nums, 0, k - 1, subsetTargetSum, subsetTargetSum, seen);
               
                   for (let i = s; i < nums.length; ++i) {
                     if (seen[i])
                       continue;
                     seen[i] = true;
                     if (dfs(nums, i + 1, k, target - nums[i], subsetTargetSum, seen))
                       return true;
                     seen[i] = false;
                   }
               
                   return false;
                 }
               
               console.log(canPartitionKSubsets([1,2,3,4],3))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q562: {
        title: "Q699. Falling Squares (Q562)",
        content: (
          <Comp
            title="Q699. Falling Squares (Q562)"
            content1={
              <>
                There are several squares being dropped onto the X-axis of a 2D
                plane.
                <br />
                You are given a 2D integer array positions where positions[i] =
                [lefti, sideLengthi] represents the ith square with a side
                length of sideLengthi that is dropped with its left edge aligned
                with X-coordinate lefti.
                <br />
                Each square is dropped one at a time from a height above any
                landed squares. It then falls downward (negative Y direction)
                until it either lands on the top side of another square or on
                the X-axis. A square brushing the left/right side of another
                square does not count as landing on it. Once it lands, it
                freezes in place and cannot be moved.
                <br />
                After each square is dropped, you must record the height of the
                current tallest stack of squares.
                <br />
                Return an integer array ans where ans[i] represents the height
                described above after dropping the ith square.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode699,
                content: (
                  <>
                    Input: positions = [[1,2],[2,3],[6,1]]
                    <br /> Output: [2,5,5]
                    <br /> Explanation:
                    <br /> After the first drop, the tallest stack is square 1
                    with a height of 2.
                    <br /> After the second drop, the tallest stack is squares 1
                    and 2 with a height of 5.
                    <br /> After the third drop, the tallest stack is still
                    squares 1 and 2 with a height of 5.
                    <br /> Thus, we return an answer of [2, 5, 5].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: positions = [[100,100],[200,100]]
                    <br /> Output: [100,100]
                    <br /> Explanation:
                    <br /> After the first drop, the tallest stack is square 1
                    with a height of 100.
                    <br /> After the second drop, the tallest stack is either
                    square 1 or square 2, both with heights of 100.
                    <br /> Thus, we return an answer of [100, 100].
                    <br /> Note that square 2 only brushes the right side of
                    square 1, which does not count as landing on it.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= positions.length &lt;= 1000 <br />
                1 &lt;= lefti &lt;= 108 <br />1 &lt;= sideLengthi &lt;= 106
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `
                // [[1,2],[2,3],[6,1]]
                class Solution {
                  public List<Integer> fallingSquares(int[][] positions) {
                      Set<Integer> unique = new TreeSet<>();
              
                      for (int[] square : positions) {
                          unique.add(square[0]);
                          unique.add(square[0] + square[1] - 1);
                      }
              
                      List<Integer> sorted = new ArrayList<>(unique);   
                  
                      int[] heights = new int[sorted.size()];
                  
                      List<Integer> list = new ArrayList<>(positions.length); 
                  
                      int max = 0;
                      for (int[] square : positions) {
                          int x1 = Collections.binarySearch(sorted, square[0]);
                          int x2 = Collections.binarySearch(sorted, square[0] + square[1] - 1);
                          
                          int current = 0;
                          for (int i = x1; i <= x2; i++) {
                              current = Math.max(current, heights[i]);
                          }
                          current += square[1];
                          for (int i = x1; i <= x2; i++) {
                              heights[i] = current;
                          }        
                          max = Math.max(max, current);
                          list.add(max);
                      }
              
                      return list;
                  }   
              }`,
                output: `[2,5,5]`,
              },
            }}
          />
        ),
      },
      q563: {
        title: "Q700. Search in a Binary Search Tree (Q563)",
        content: (
          <Comp
            title="Q700. Search in a Binary Search Tree (Q563)"
            content1={
              <>
                You are given the root of a binary search tree (BST) and an
                integer val.
                <br />
                Find the node in the BST that the node's value equals val and
                return the subtree rooted with that node. If such a node does
                not exist, return null.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode700,
                content: (
                  <>
                    Input: root = [4,2,7,1,3], val = 2<br />
                    Output: [2,1,3]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [4,2,7,1,3], val = 5<br />
                    Output: []
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 5000].
                <br />
                1 &lt;= Node.val &lt;= 10^7
                <br />
                root is a binary search tree.
                <br />1 &lt;= val &lt;= 10^7
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root
                 * @param {number} val
                 * @return {TreeNode}
                 */
                var searchBST = function(root, val) {
                  if (!root)
                    return null;
                  if (root.val == val)
                    return root;
                  if (root.val > val)
                    return searchBST(root.left, val);
                  return searchBST(root.right, val);
                };
                
                const t = new TreeNode(4)
                t.left = new TreeNode(2)
                t.left.left = new TreeNode(1)
                t.left.right = new TreeNode(3)
                t.right  = new TreeNode(7)
                console.log(searchBST(t,4))`,
                output: `TreeNode {
                  val: 4,
                  left: TreeNode {
                    val: 2,
                    left: TreeNode { val: 1, left: null, right: null },
                    right: TreeNode { val: 3, left: null, right: null }
                  },
                  right: TreeNode { val: 7, left: null, right: null }
                }`,
              },
            }}
          />
        ),
      },
      q564: {
        title: "Q701. Insert into a Binary Search Tree (Q564)",
        content: (
          <Comp
            title="Q701. Insert into a Binary Search Tree (Q564)"
            content1={
              <>
                You are given the root node of a binary search tree (BST) and a
                value to insert into the tree. Return the root node of the BST
                after the insertion. It is guaranteed that the new value does
                not exist in the original BST.
                <br />
                Notice that there may exist multiple valid ways for the
                insertion, as long as the tree remains a BST after insertion.
                You can return any of them.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode701,
                content: (
                  <>
                    Input: root = [4,2,7,1,3], val = 5<br />
                    Output: [4,2,7,1,3,5]
                    <br />
                    Explanation: Another accepted tree is:
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [40,20,60,10,30,50,70], val = 25
                    <br />
                    Output: [40,20,60,10,30,50,70,null,null,25]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [4,2,7,1,3,null,null,null,null,null,null], val
                    = 5<br />
                    Output: [4,2,7,1,3,5]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree will be in the range [0, 10^4].
                <br />
                -10^8 &lt;= Node.val &lt;= 10^8
                <br />
                All the values Node.val are unique.
                <br />
                -10^8 &lt;= val &lt;= 10^8
                <br />
                It's guaranteed that val does not exist in the original BST.
              </>
            }
            tc="log n -> n"
            sc="log n -> n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root
                 * @param {number} val
                 * @return {TreeNode}
                 */
                var insertIntoBST = function(root, val) {
                  if (!root)
                      return new TreeNode(val);
                  if (root.val > val)
                    root.left = insertIntoBST(root.left, val);
                  else
                    root.right = insertIntoBST(root.right, val);
                  return root;
                };
                
                const t = new TreeNode(4)
                t.left = new TreeNode(2)
                t.left.left = new TreeNode(1)
                t.left.right = new TreeNode(3)
                t.right  = new TreeNode(7)
                console.log(insertIntoBST(t,5))`,
                output: `TreeNode {
                  val: 4,
                  left: TreeNode {
                    val: 2,
                    left: TreeNode { val: 1, left: null, right: null },
                    right: TreeNode { val: 3, left: null, right: null }
                  },
                  right: TreeNode {
                    val: 7,
                    left: TreeNode { val: 5, left: null, right: null },
                    right: null
                  }
                }`,
              },
            }}
          />
        ),
      },
      q565: {
        title: "Q703. Kth Largest Element in a Stream (Q565)",
        content: (
          <Comp
            title="Q703. Kth Largest Element in a Stream (Q565)"
            content1={
              <>
                Design a class to find the kth largest element in a stream. Note
                that it is the kth largest element in the sorted order, not the
                kth distinct element.
                <br />
                Implement KthLargest class:
                <br />
                KthLargest(int k, int[] nums) Initializes the object with the
                integer k and the stream of integers nums. int add(int val)
                Appends the integer val to the stream and returns the element
                representing the kth largest element in the stream.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["KthLargest", "add", "add", "add", "add", "add"] <br />
                    [[3, [4, 5, 8, 2]], [3], [5], [10], [9], [4]] <br />
                    Output <br />
                    [null, 4, 5, 5, 8, 8] <br />
                    Explanation <br />
                    KthLargest kthLargest = new KthLargest(3, [4, 5, 8, 2]);{" "}
                    <br />
                    kthLargest.add(3); // return 4 <br />
                    kthLargest.add(5); // return 5 <br />
                    kthLargest.add(10); // return 5 <br />
                    kthLargest.add(9); // return 8 <br />
                    kthLargest.add(4); // return 8
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= k &lt;= 10^4 <br />
                0 &lt;= nums.length &lt;= 10^4 <br />
                -10^4 &lt;= nums[i] &lt;= 10^4 <br />
                -10^4 &lt;= val &lt;= 10^4 <br />
                At most 104 calls will be made to add. <br />
                It is guaranteed that there will be at least k elements in the
                array when you search for the kth element.
              </>
            }
            tc="n.log k"
            sc="k"
            codes={{
              Java: {
                code: `class KthLargest {
                  public KthLargest(int k, int[] nums) {
                    this.k = k;
                    for (final int num : nums)
                      heapify(num);
                  }
                
                  public int add(int val) {
                    heapify(val);
                    return pq.peek();
                  }
                
                  private final int k;
                  private PriorityQueue<Integer> pq = new PriorityQueue<>();
                
                  private void heapify(int val) {
                    pq.offer(val);
                    if (pq.size() > k)
                      pq.poll();
                  }
                }
                
                
                /**
                 * Your KthLargest object will be instantiated and called as such:
                 * KthLargest obj = new KthLargest(k, nums);
                 * int param_1 = obj.add(val);
                 */`,
                output: `[null,4,5,5,8,8]`,
              },
            }}
          />
        ),
      },
      q566: {
        title: "Q704. Binary Search (Q566)",
        content: (
          <Comp
            title="Q704. Binary Search (Q566)"
            content1={
              <>
                Given an array of integers nums which is sorted in ascending
                order, and an integer target, write a function to search target
                in nums. If target exists, then return its index. Otherwise,
                return -1.
                <br />
                You must write an algorithm with O(log n) runtime complexity.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [-1,0,3,5,9,12], target = 9 <br />
                    Output: 4 <br />
                    Explanation: 9 exists in nums and its index is 4
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [-1,0,3,5,9,12], target = 2 <br />
                    Output: -1 <br />
                    Explanation: 2 does not exist in nums so return -1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4 <br />
                -10^4 &lt; nums[i], target &lt; 10^4 <br />
                All the integers in nums are unique. <br />
                nums is sorted in ascending order.
              </>
            }
            tc="log n -> 1"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} target
                * @return {number}
                */
               var search = function(nums, target) {
                  let l = 0;
                 let r = nums.length - 1;
                 let mid;
                 while (r >= l) {
                     mid = l + Math.floor((r - l) / 2);
                     if (nums[mid] == target)
                         return mid;
                     if (nums[mid] > target)
                         r = mid - 1;
                     else
                         l = mid + 1;
                 }
                 return -1;  
               };
               
               console.log(search([-1,0,3,5,9,12],9))`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q567: {
        title: "Q705. Design HashSet (Q567)",
        content: (
          <Comp
            title="Q705. Design HashSet (Q567)"
            content1={
              <>
                Design a HashSet without using any built-in hash table
                libraries.
                <br />
                Implement MyHashSet class:
                <br />
                void add(key) Inserts the value key into the HashSet. <br />
                bool contains(key) Returns whether the value key exists in the
                HashSet or not. <br />
                void remove(key) Removes the value key in the HashSet. If key
                does not exist in the HashSet, do nothing.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["MyHashSet", "add", "add", "contains", "contains", "add",
                    "contains", "remove", "contains"] <br />
                    [[], [1], [2], [1], [3], [2], [2], [2], [2]] <br />
                    Output <br />
                    [null, null, null, true, false, null, true, null, false]{" "}
                    <br />
                    Explanation <br />
                    MyHashSet myHashSet = new MyHashSet(); <br />
                    myHashSet.add(1); // set = [1] <br />
                    myHashSet.add(2); // set = [1, 2] <br />
                    myHashSet.contains(1); // return True <br />
                    myHashSet.contains(3); // return False, (not found) <br />
                    myHashSet.add(2); // set = [1, 2] <br />
                    myHashSet.contains(2); // return True <br />
                    myHashSet.remove(2); // set = [1] <br />
                    myHashSet.contains(2); // return False, (already removed)
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= key &lt;= 10^6 <br />
                At most 104 calls will be made to add, remove, and contains.
              </>
            }
            tc="1"
            sc="n"
            codes={{
              Javascript: {
                code: `
                var MyHashSet = function() {
                  this.set = Array(1000001).fill(false); 
                };
                
                /** 
                 * @param {number} key
                 * @return {void}
                 */
                MyHashSet.prototype.add = function(key) {
                  this.set[key] = true;
                };
                
                /** 
                 * @param {number} key
                 * @return {void}
                 */
                MyHashSet.prototype.remove = function(key) {
                  this.set[key] = false;
                };
                
                /** 
                 * @param {number} key
                 * @return {boolean}
                 */
                MyHashSet.prototype.contains = function(key) {
                  return this.set[key];
                };
                
                
                var obj = new MyHashSet()
                obj.add(1)
                obj.add(8)
                obj.remove(1)
                var param_3 = obj.contains(8)
                console.log(param_3)`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q568: {
        title: "Q706. Design HashMap (Q568)",
        content: (
          <Comp
            title="Q706. Design HashMap (Q568)"
            content1={
              <>
                Design a HashMap without using any built-in hash table
                libraries.
                <br />
                Implement the MyHashMap class:
                <br />
                MyHashMap() initializes the object with an empty map.
                <br />
                void put(int key, int value) inserts a (key, value) pair into
                the HashMap. If the key already exists in the map, update the
                corresponding value.
                <br /> int get(int key) returns the value to which the specified
                key is mapped, or -1 if this map contains no mapping for the
                key.
                <br /> void remove(key) removes the key and its corresponding
                value if the map contains the mapping for the key.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br />
                    ["MyHashMap", "put", "put", "get", "get", "put", "get",
                    "remove", "get"] [[], [1, 1], [2, 2], [1], [3], [2, 1], [2],
                    [2], [2]]
                    <br />
                    Output
                    <br />
                    [null, null, null, 1, -1, null, 1, null, -1]
                    <br />
                    Explanation MyHashMap myHashMap = new MyHashMap();
                    <br />
                    myHashMap.put(1, 1); // The map is now [[1,1]]
                    <br />
                    myHashMap.put(2, 2); // The map is now [[1,1], [2,2]]
                    <br />
                    myHashMap.get(1); // return 1, The map is now [[1,1], [2,2]]
                    <br />
                    <br /> myHashMap.get(3); // return -1 (i.e., not found), The
                    map is now [[1,1], [2,2]]
                    <br /> myHashMap.put(2, 1); // The map is now [[1,1], [2,1]]
                    (i.e., update the existing value)
                    <br /> myHashMap.get(2); // return 1, The map is now [[1,1],
                    [2,1]]
                    <br /> myHashMap.remove(2); // remove the mapping for 2, The
                    map is now [[1,1]]
                    <br /> myHashMap.get(2); // return -1 (i.e., not found), The
                    map is now [[1,1]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= key, value &lt;= 10^6 <br />
                At most 104 calls will be made to put, get, and remove.
              </>
            }
            tc="1"
            sc="n"
            codes={{
              Javascript: {
                code: `const kSize= 10000

                var MyHashMap = function() {
                  this.lists = Array(kSize).fill([]);
                };
                
                /** 
                 * @param {number} key 
                 * @param {number} value
                 * @return {void}
                 */
                MyHashMap.prototype.put = function(key, value) {
                  for (let pair of this.lists[key % kSize])
                    if (pair[0] == key) {
                      pair[1] = value;
                      return;
                    }
                  this.lists[key % kSize].push([key, value]);
                };
                
                /** 
                 * @param {number} key
                 * @return {number}
                 */
                MyHashMap.prototype.get = function(key) {
                 for (let pair of this.lists[key % kSize])
                      if (pair[0] == key)
                        return pair[1];
                    return -1;
                };
                
                /** 
                 * @param {number} key
                 * @return {void}
                 */
                MyHashMap.prototype.remove = function(key) {
                   for (let i = 0; i < this.lists[key % kSize].length; ++i)
                  if (this.lists[key % kSize][i][0] == key) {
                    this.lists[key % kSize].splice(i,1);
                    return;
                  }
                };
                
                
                var obj = new MyHashMap()
                obj.put(1,2)
                var param_2 = obj.get(1)
                obj.remove(1)
                console.log(param_2)`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q569: {
        title: "Q707. Design Linked List (Q569)",
        content: (
          <Comp
            title="Q707. Design Linked List (Q569)"
            content1={
              <>
                Design your implementation of the linked list. You can choose to
                use a singly or doubly linked list.
                <br /> A node in a singly linked list should have two
                attributes: val and next. val is the value of the current node,
                and next is a pointer/reference to the next node.
                <br /> If you want to use the doubly linked list, you will need
                one more attribute prev to indicate the previous node in the
                linked list. Assume all nodes in the linked list are 0-indexed.
                <br />
                Implement the MyLinkedList class:
                <br />
                MyLinkedList() Initializes the MyLinkedList object. <br />
                <br /> int get(int index) Get the value of the indexth node in
                the linked list. If the index is invalid, return -1.
                <br /> void addAtHead(int val) Add a node of value val before
                the first element of the linked list. After the insertion, the
                new node will be the first node of the linked list.
                <br /> void addAtTail(int val) Append a node of value val as the
                last element of the linked list.
                <br /> void addAtIndex(int index, int val) Add a node of value
                val before the indexth node in the linked list. If index equals
                the length of the linked list, the node will be appended to the
                end of the linked list. If index is greater than the length, the
                node will not be inserted.
                <br /> void deleteAtIndex(int index) Delete the indexth node in
                the linked list, if the index is valid.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input ["MyLinkedList", "addAtHead", "addAtTail",
                    "addAtIndex", "get", "deleteAtIndex", "get"] <br />
                    [[], [1], [3], [1, 2], [1], [1], [1]] <br />
                    Output <br />
                    [null, null, null, null, 2, null, 3] <br />
                    Explanation <br />
                    MyLinkedList myLinkedList = new MyLinkedList(); <br />
                    myLinkedList.addAtHead(1); <br />
                    myLinkedList.addAtTail(3); <br />
                    myLinkedList.addAtIndex(1, 2); // linked list becomes
                    1-&gt;2-&gt;3 <br />
                    myLinkedList.get(1); // return 2 <br />
                    myLinkedList.deleteAtIndex(1); // now the linked list is
                    1-&gt;3 <br />
                    myLinkedList.get(1); // return 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= index, val &lt;= 1000 <br />
                Please do not use the built-in LinkedList library. <br />
                At most 2000 calls will be made to get, addAtHead, addAtTail,
                addAtIndex and deleteAtIndex.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function ListNode(val,next){
                  this.val = val
                  this.next = next
                }
                
                var MyLinkedList = function() {
                  this.length = 0
                  this.temp = new ListNode(0)
                };
                
                /** 
                 * @param {number} index
                 * @return {number}
                 */
                MyLinkedList.prototype.get = function(index) {
                  if (index < 0 || index >= this.length)
                    return -1;
                  let curr = this.temp.next;
                  for (let i = 0; i < index; ++i)
                    curr = curr.next;
                  return curr.val;
                };
                
                /** 
                 * @param {number} val
                 * @return {void}
                 */
                MyLinkedList.prototype.addAtHead = function(val) {
                  let head = this.temp.next;
                  let node = new ListNode(val);
                  node.next = head;
                  this.temp.next = node;
                  ++this.length;
                };
                
                /** 
                 * @param {number} val
                 * @return {void}
                 */
                MyLinkedList.prototype.addAtTail = function(val) {
                  let curr = this.temp;
                  while (curr.next)
                    curr = curr.next;
                  curr.next = new ListNode(val);
                  ++this.length;
                };
                
                /** 
                 * @param {number} index 
                 * @param {number} val
                 * @return {void}
                 */
                MyLinkedList.prototype.addAtIndex = function(index, val) {
                  if (index > this.length)
                    return;
                  let curr = this.temp;
                  for (let i = 0; i < index; ++i)
                    curr = curr.next;
                  let cache = curr.next;
                  let node = new ListNode(val);
                  node.next = cache;
                  curr.next = node;
                  ++this.length;
                };
                
                /** 
                 * @param {number} index
                 * @return {void}
                 */
                MyLinkedList.prototype.deleteAtIndex = function(index) {
                  if (index < 0 || index >= this.length)
                    return;
                  let curr = this.temp;
                  for (let i = 0; i < index; ++i)
                    curr = curr.next;
                  let cache = curr.next;
                  curr.next = cache.next;
                  --this.length;
                };
                
                
                var obj = new MyLinkedList()
                obj.addAtHead(1)
                obj.addAtTail(2)
                obj.addAtIndex(2,1)
                obj.deleteAtIndex(2)
                var param_1 = obj.get(0)
                console.log(param_1)`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q570: {
        title: "Q709. To Lower Case (Q570)",
        content: (
          <Comp
            title="Q709. To Lower Case (Q570)"
            content1={
              <>
                Given a string s, return the string after replacing every
                uppercase letter with the same lowercase letter.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "Hello" <br />
                    Output: "hello"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "here"
                    <br />
                    Output: "here"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "LOVELY"
                    <br />
                    Output: "lovely"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 100
                <br />s consists of printable ASCII characters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {string}
                */
               var toLowerCase = function(s) {
                 let diff = 65 - 97;
                 const res = s.split("");
                 for (let i = 0; i < res.length; ++i)
                 if (res[i].charCodeAt(0) >= 65 && res[i].charCodeAt(0) <= 90)
                   res[i] = String.fromCharCode(res[i].charCodeAt(0) - diff);
                 return res.join("");
               };
               console.log(toLowerCase("Hello"))`,
                output: `hello`,
              },
            }}
          />
        ),
      },
      q571: {
        title: "Q710. Random Pick with Blacklist (Q571)",
        content: (
          <Comp
            title="Q710. Random Pick with Blacklist (Q571)"
            content1={
              <>
                You are given an integer n and an array of unique integers
                blacklist. Design an algorithm to pick a random integer in the
                range [0, n - 1] that is not in blacklist. Any integer that is
                in the mentioned range and not in blacklist should be equally
                likely to be returned.
                <br />
                Optimize your algorithm such that it minimizes the number of
                calls to the built-in random function of your language.
                <br />
                Implement the Solution class:
                <br />
                Solution(int n, int[] blacklist) Initializes the object with the
                integer n and the blacklisted integers blacklist. int pick()
                Returns a random integer in the range [0, n - 1] and not in
                blacklist.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input ["Solution", "pick", "pick", "pick", "pick", "pick",
                    "pick", "pick"]
                    <br /> [[7, [2, 3, 5]], [], [], [], [], [], [], []]
                    <br /> Output
                    <br /> [null, 0, 4, 1, 6, 1, 0, 4]
                    <br /> Explanation
                    <br /> Solution solution = new Solution(7, [2, 3, 5]);
                    <br /> solution.pick(); // return 0, any integer from
                    [0,1,4,6] should be ok. Note that for every call of pick,
                    <br /> // 0, 1, 4, and 6 must be equally likely to be
                    returned (i.e., with probability 1/4). solution.pick(); //
                    return 4 <br />
                    solution.pick(); // return 1 <br />
                    solution.pick(); // return 6 <br />
                    solution.pick(); // return 1 <br />
                    solution.pick(); // return 0 <br />
                    solution.pick(); // return 4
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 10^9 <br />
                0 &lt;= blacklist.length &lt;- min(105, n - 1) <br />
                0 &lt;= blacklist[i] &lt; n <br />
                All the values of blacklist are unique. <br />
                At most 2 * 104 calls will be made to pick.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `class Solution {
                  public Solution(int N, int[] blacklist) {
                    validRange = N - blacklist.length;
                
                    for (final int b : blacklist)
                      map.put(b, -1);
                
                    int maxAvailable = N - 1;
                
                    for (final int b : blacklist)
                      if (b < validRange) {
                        while (map.containsKey(maxAvailable))
                          --maxAvailable;
                        map.put(b, maxAvailable--);
                      }
                  }
                
                  public int pick() {
                    final int num = rand.nextInt(validRange);
                    return map.getOrDefault(num, num);
                  }
                
                  private int validRange;
                  private Map<Integer, Integer> map = new HashMap<>();
                  private Random rand = new Random();
                }
                
                
                /**
                 * Your Solution object will be instantiated and called as such:
                 * Solution obj = new Solution(n, blacklist);
                 * int param_1 = obj.pick();
                 */`,
                output: `[null,1,1,4,1,0,1,1]`,
              },
            }}
          />
        ),
      },
      q572: {
        title: "Q712. Minimum ASCII Delete Sum for Two Strings (Q572)",
        content: (
          <Comp
            title="Q712. Minimum ASCII Delete Sum for Two Strings (Q572)"
            content1={
              <>
                Given two strings s1 and s2, return the lowest ASCII sum of
                deleted characters to make two strings equal.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s1 = "sea", s2 = "eat" <br />
                    Output: 231 <br />
                    Explanation: Deleting "s" from "sea" adds the ASCII value of
                    "s" (115) to the sum. <br />
                    Deleting "t" from "eat" adds 116 to the sum. <br />
                    At the end, both strings are equal, and 115 + 116 = 231 is
                    the minimum sum possible to achieve this.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s1 = "delete", s2 = "leet" <br />
                    Output: 403 <br />
                    Explanation: Deleting "dee" from "delete" to turn the string
                    into "let", <br />
                    adds 100[d] + 101[e] + 101[e] to the sum. <br />
                    Deleting "e" from "leet" adds 101[e] to the sum. <br />
                    At the end, both strings are equal to "let", and the answer
                    is 100+101+101+101 = 403. <br />
                    If instead we turned both strings into "lee" or "eet", we
                    would get answers of 433 or 417, which are higher.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s1.length, s2.length &lt;= 1000 <br />
                s1 and s2 consist of lowercase English letters.
              </>
            }
            tc="m.n"
            sc="m.n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s1
                * @param {string} s2
                * @return {number}
                */
               var minimumDeleteSum = function(s1, s2) {
                 const m = s1.length;
                 const n = s2.length;
                 const dp = Array.from(Array(m + 1),()=>Array(n + 1).fill(0));
                 for (let i = 1; i <= m; ++i)
                   dp[i][0] = dp[i - 1][0] + s1[i - 1].charCodeAt(0);
                 for (let j = 1; j <= n; ++j)
                   dp[0][j] = dp[0][j - 1] + s2[j - 1].charCodeAt(0);
                 for (let i = 1; i <= m; ++i)
                   for (let j = 1; j <= n; ++j)
                     if (s1[i - 1].charCodeAt(0) == s2[j - 1].charCodeAt(0))
                       dp[i][j] = dp[i - 1][j - 1];
                     else
                       dp[i][j] = Math.min(dp[i - 1][j] + s1[i - 1].charCodeAt(0), dp[i][j - 1] + s2[j - 1].charCodeAt(0));
                 return dp[m][n];    
               };
               
               console.log(minimumDeleteSum("delete","leet"))`,
                output: `403`,
              },
            }}
          />
        ),
      },
      q573: {
        title: "Q713. Subarray Product Less Than K (Q573)",
        content: (
          <Comp
            title="Q713. Subarray Product Less Than K (Q573)"
            content1={
              <>
                Given an array of integers nums and an integer k, return the
                number of contiguous subarrays where the product of all the
                elements in the subarray is strictly less than k.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [10,5,2,6], k = 100 <br />
                    Output: 8 <br />
                    Explanation: The 8 subarrays that have product less than 100
                    are: [10], [5], [2], [6], [10, 5], [5, 2], [2, 6], [5, 2, 6]
                    Note that [10, 5, 2] is not included as the product of 100
                    is not strictly less than k.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3], k = 0 <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 3 * 10^4 <br />
                1 &lt;= nums[i] &lt;= 1000 <br />0 &lt;= k &lt;= 106
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var numSubarrayProductLessThanK = function(nums, k) {
                 if (k <= 1)
                     return 0;
                 let res = 0;
                 let prod = 1;
                 for (let l = 0, r = 0; r < nums.length; ++r) {
                   prod *= nums[r];
                   while (prod >= k)
                     prod /= nums[l++];
                   res += r - l + 1;
                 }
                 return res;    
               };
               
               console.log(numSubarrayProductLessThanK([1,2,3],0))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q574: {
        title:
          "Q714. Best Time to Buy and Sell Stock with Transaction Fee (Q574)",
        content: (
          <Comp
            title="Q714. Best Time to Buy and Sell Stock with Transaction Fee (Q574)"
            content1={
              <>
                You are given an array prices where prices[i] is the price of a
                given stock on the ith day, and an integer fee representing a
                transaction fee.
                <br />
                Find the maximum profit you can achieve. You may complete as
                many transactions as you like, but you need to pay the
                transaction fee for each transaction.
                <br />
                Note: You may not engage in multiple transactions simultaneously
                (i.e., you must sell the stock before you buy again).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: prices = [1,3,2,8,4,9], fee = 2 <br />
                    Output: 8 <br />
                    Explanation: The maximum profit can be achieved by: <br />
                    - Buying at prices[0] = 1 <br />
                    - Selling at prices[3] = 8 <br />
                    - Buying at prices[4] = 4 <br />
                    - Selling at prices[5] = 9 <br />
                    The total profit is ((8 - 1) - 2) + ((9 - 4) - 2) = 8.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: prices = [1,3,7,5,10,3], fee = 3 <br />
                    Output: 6
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= prices.length &lt;= 5 * 10^4
                <br />
                1 &lt;= prices[i] &lt; 5 * 10^4
                <br />0 &lt;= fee &lt; 5 * 10^4
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} prices
                * @param {number} fee
                * @return {number}
                */
               var maxProfit = function(prices, fee) {
                 let sell = 0;
                 let hold = Number.MIN_SAFE_INTEGER;
                 for (let price of prices) {
                   sell = Math.max(sell, hold + price);
                   hold = Math.max(hold, sell - price - fee);
                 }
                 return sell;
               };
               
               console.log(maxProfit([1,3,7,5,10,3],3))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q575: {
        title: "Q715. Range Module (Q575)",
        content: (
          <Comp
            title="Q715. Range Module (Q575)"
            content1={
              <>
                A Range Module is a module that tracks ranges of numbers. Design
                a data structure to track the ranges represented as half-open
                intervals and query about them.
                <br />
                A half-open interval [left, right) denotes all the real numbers
                x where left &lt;= x &lt; right.
                <br />
                Implement the RangeModule class:
                <br />
                RangeModule() Initializes the object of the data structure.
                <br />
                void addRange(int left, int right) Adds the half-open interval
                [left, right), tracking every real number in that interval.
                Adding an interval that partially overlaps with currently
                tracked numbers should add any numbers in the interval [left,
                right) that are not already tracked.
                <br />
                boolean queryRange(int left, int right) Returns true if every
                real number in the interval [left, right) is currently being
                tracked, and false otherwise.
                <br />
                void removeRange(int left, int right) Stops tracking every real
                number currently being tracked in the half-open interval [left,
                right).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input ["RangeModule", "addRange", "removeRange",
                    "queryRange", "queryRange", "queryRange"]
                    <br />
                    [[], [10, 20], [14, 16], [10, 14], [13, 15], [16, 17]]
                    <br />
                    Output
                    <br />
                    [null, null, null, true, false, true]
                    <br />
                    Explanation
                    <br />
                    RangeModule rangeModule = new RangeModule();
                    <br />
                    rangeModule.addRange(10, 20);
                    <br />
                    rangeModule.removeRange(14, 16);
                    <br />
                    rangeModule.queryRange(10, 14); // return True,(Every number
                    in [10, 14) is being tracked)
                    <br />
                    rangeModule.queryRange(13, 15); // return False,(Numbers
                    like 14, 14.03, 14.17 in [13, 15) are not being tracked)
                    <br /> rangeModule.queryRange(16, 17); // return True, (The
                    number 16 in [16, 17) is still being tracked, despite the
                    remove operation)
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= left &lt; right &lt;= 10^9 <br />
                At most 104 calls will be made to addRange, queryRange, and
                removeRange.
              </>
            }
            tc="1"
            sc="n"
            codes={{
              Java: {
                code: `class RangeModule {
                  public void addRange(int left, int right) {
                    Integer l = ranges.floorKey(left);
                    Integer r = ranges.floorKey(right);
                
                    if (l != null && ranges.get(l) >= left)
                      left = l;
                    if (r != null && ranges.get(r) > right)
                      right = ranges.get(r);
                
                    ranges.subMap(left, right).clear();
                    ranges.put(left, right);
                  }
                
                  public boolean queryRange(int left, int right) {
                    Integer l = ranges.floorKey(left);
                    return l == null ? false : ranges.get(l) >= right;
                  }
                
                  public void removeRange(int left, int right) {
                    Integer l = ranges.floorKey(left);
                    Integer r = ranges.floorKey(right);
                
                    if (r != null && ranges.get(r) > right)
                      ranges.put(right, ranges.get(r));
                    if (l != null && ranges.get(l) > left)
                      ranges.put(l, left);
                
                    ranges.subMap(left, right).clear();
                  }
                
                  private TreeMap<Integer, Integer> ranges = new TreeMap<>();
                }
                
                
                /**
                 * Your RangeModule object will be instantiated and called as such:
                 * RangeModule obj = new RangeModule();
                 * obj.addRange(left,right);
                 * boolean param_2 = obj.queryRange(left,right);
                 * obj.removeRange(left,right);
                 */`,
                output: `[null,null,null,true,false,true]`,
              },
            }}
          />
        ),
      },
      q576: {
        title: "Q717. 1-bit and 2-bit Characters (Q576)",
        content: (
          <Comp
            title="Q717. 1-bit and 2-bit Characters (Q576)"
            content1={
              <>
                We have two special characters:
                <br />
                The first character can be represented by one bit 0.
                <br />
                The second character can be represented by two bits (10 or 11).
                <br />
                Given a binary array bits that ends with 0, return true if the
                last character must be a one-bit character.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: bits = [1,0,0]
                    <br />
                    Output: true
                    <br />
                    Explanation: The only way to decode it is two-bit character
                    and one-bit character. So the last character is one-bit
                    character.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: bits = [1,1,1,0]
                    <br />
                    Output: false
                    <br />
                    Explanation: The only way to decode it is two-bit character
                    and two-bit character. So the last character is not one-bit
                    character.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= bits.length &lt;= 1000
                <br />
                bits[i] is either 0 or 1.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} bits
                * @return {boolean}
                */
               var isOneBitCharacter = function(bits) {
                 const n = bits.length;
                 let i = 0;
                 while (i < n - 1)
                   if (bits[i] == 0)
                     i += 1;
                   else
                     i += 2;
                 return i == n - 1;
               };
               
               console.log(isOneBitCharacter([1,1,1,0]))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q577: {
        title: "Q718. Maximum Length of Repeated Subarray (Q577)",
        content: (
          <Comp
            title="Q718. Maximum Length of Repeated Subarray (Q577)"
            content1={
              <>
                Given two integer arrays nums1 and nums2, return the maximum
                length of a subarray that appears in both arrays.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums1 = [1,2,3,2,1], nums2 = [3,2,1,4,7] <br />
                    Output: 3 <br />
                    Explanation: The repeated subarray with maximum length is
                    [3,2,1].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums1 = [0,0,0,0,0], nums2 = [0,0,0,0,0] <br />
                    Output: 5
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums1.length, nums2.length &lt;= 1000 <br />0 &lt;=
                nums1[i], nums2[i] &lt;= 100
              </>
            }
            tc="m.n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums1
                * @param {number[]} nums2
                * @return {number}
                */
               var findLength = function(nums1, nums2) {
                 const m = nums1.length;
                 const n = nums2.length;
                 let res = 0;
                 const dp = Array(n + 1).fill(0);
                 for (let i = m - 1; i >= 0; --i)
                   for (let j = 0; j < n; ++j) { 
                     dp[j] = nums1[i] == nums2[j] ? dp[j + 1] + 1 : 0;
                     res = Math.max(res, dp[j]);
                   }
                 return res;
               };
               
               console.log(findLength([1,2,3,2,1],[3,2,1,4,7]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q578: {
        title: "Q719. Find K-th Smallest Pair Distance (Q578)",
        content: (
          <Comp
            title="Q719. Find K-th Smallest Pair Distance (Q578)"
            content1={
              <>
                The distance of a pair of integers a and b is defined as the
                absolute difference between a and b.
                <br />
                Given an integer array nums and an integer k, return the kth
                smallest distance among all the pairs nums[i] and nums[j] where
                0 &lt;= i &lt; j &lt; nums.length.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,3,1], k = 1 Output: 0 Explanation: Here are
                    all the pairs: (1,3) -&gt; 2 (1,1) -&gt; 0 (3,1) -&gt; 2
                    Then the 1st smallest distance pair is (1,1), and its
                    distance is 0.
                  </>
                ),
              },
              {
                content: <>Input: nums = [1,1,1], k = 2 Output: 0</>,
              },
              {
                content: <>Input: nums = [1,6,1], k = 3 Output: 5</>,
              },
            ]}
            constraints={
              <>
                n == nums.length 2 &lt;= n &lt;= 104 0 &lt;= nums[i] &lt;= 10^6
                1 &lt;= k &lt;= n * (n - 1) / 2
              </>
            }
            tc="n.log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var smallestDistancePair = function(nums, k) {
                 nums = nums.sort((a,b)=>a-b)
                 let l = 0;
                 let r = nums[nums.length - 1] - nums[0];
                 while (l < r) {
                   let m = l + parseInt((r - l) / 2);
                   if (pairDistancesNoGreaterThan(nums, m) >= k)
                     r = m;
                   else
                     l = m + 1;
                 }
                 return l;
               };
               
               function pairDistancesNoGreaterThan(nums, m) {
                 let count = 0;
                 let j = 1;
                 for (let i = 0; i < nums.length; ++i) {
                   while (j < nums.length && nums[j] <= nums[i] + m)
                     ++j;
                   count += j - i - 1;
                 }
                 return count;
               }
               
               console.log(smallestDistancePair([1,3,1],1))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q579: {
        title: "Q720. Longest Word in Dictionary (Q579)",
        content: (
          <Comp
            title="Q720. Longest Word in Dictionary (Q579)"
            content1={
              <>
                Given an array of strings words representing an English
                Dictionary, return the longest word in words that can be built
                one character at a time by other words in words.
                <br />
                If there is more than one possible answer, return the longest
                word with the smallest lexicographical order. If there is no
                answer, return the empty string.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: words = ["w","wo","wor","worl","world"] <br />
                    Output: "world" <br />
                    Explanation: The word "world" can be built one character at
                    a time by "w", "wo", "wor", and "worl".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words =
                    ["a","banana","app","appl","ap","apply","apple"] <br />
                    Output: "apple" <br />
                    Explanation: Both "apply" and "apple" can be built from
                    other words in the dictionary. However, "apple" is
                    lexicographically smaller than "apply".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= words.length &lt;= 1000 <br />
                1 &lt;= words[i].length &lt;= 30
                <br />
                words[i] consists of lowercase English letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // ["w","wo","wor","worl","world"]
                class TrieNode {
                  public TrieNode[] children = new TrieNode[26];
                  public String word;
                }
                
                class Solution {
                  public String longestWord(String[] words) {
                    for (final String word : words)
                      insert(word);
                    return longestWordFrom(root);
                  }
                
                  private TrieNode root = new TrieNode();
                
                  private void insert(final String word) {
                    TrieNode node = root;
                    for (char c : word.toCharArray()) {
                      final int i = c - 'a';
                      if (node.children[i] == null)
                        node.children[i] = new TrieNode();
                      node = node.children[i];
                    }
                    node.word = word;
                  }
                
                  private String longestWordFrom(TrieNode node) {
                    String ans = node.word == null ? "" : node.word;
                
                    for (TrieNode child : node.children)
                      if (child != null && child.word != null) {
                        String childWord = longestWordFrom(child);
                        if (childWord.length() > ans.length())
                          ans = childWord;
                      }
                
                    return ans;
                  }
                }
                `,
                output: `world`,
              },
            }}
          />
        ),
      },
      q580: {
        title: "Q721. Accounts Merge (Q580)",
        content: (
          <Comp
            title="Q721. Accounts Merge (Q580)"
            content1={
              <>
                Given a list of accounts where each element accounts[i] is a
                list of strings, where the first element accounts[i][0] is a
                name, and the rest of the elements are emails representing
                emails of the account.
                <br />
                Now, we would like to merge these accounts. Two accounts
                definitely belong to the same person if there is some common
                email to both accounts. Note that even if two accounts have the
                same name, they may belong to different people as people could
                have the same name. A person can have any number of accounts
                initially, but all of their accounts definitely have the same
                name.
                <br />
                After merging the accounts, return the accounts in the following
                format: the first element of each account is the name, and the
                rest of the elements are emails in sorted order. The accounts
                themselves can be returned in any order.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: accounts =
                    [["John","johnsmith@mail.com","john_newyork@mail.com"],["John","johnsmith@mail.com","john00@mail.com"],["Mary","mary@mail.com"],["John","johnnybravo@mail.com"]]
                    <br /> Output:
                    [["John","john00@mail.com","john_newyork@mail.com","johnsmith@mail.com"],["Mary","mary@mail.com"],["John","johnnybravo@mail.com"]]
                    Explanation: <br />
                    The first and second John's are the same person as they have
                    the common email "johnsmith@mail.com". The third John and
                    Mary are different people as none of their email addresses
                    are used by other accounts. We could return these lists in
                    any order, for example the answer [['Mary',
                    'mary@mail.com'], ['John', 'johnnybravo@mail.com'], ['John',
                    'john00@mail.com', 'john_newyork@mail.com',
                    'johnsmith@mail.com']] would still be accepted.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: accounts =
                    [["Gabe","Gabe0@m.co","Gabe3@m.co","Gabe1@m.co"],["Kevin","Kevin3@m.co","Kevin5@m.co","Kevin0@m.co"],["Ethan","Ethan5@m.co","Ethan4@m.co","Ethan0@m.co"],["Hanzo","Hanzo3@m.co","Hanzo1@m.co","Hanzo0@m.co"],["Fern","Fern5@m.co","Fern1@m.co","Fern0@m.co"]]
                    <br /> Output:
                    [["Ethan","Ethan0@m.co","Ethan4@m.co","Ethan5@m.co"],["Gabe","Gabe0@m.co","Gabe1@m.co","Gabe3@m.co"],["Hanzo","Hanzo0@m.co","Hanzo1@m.co","Hanzo3@m.co"],["Kevin","Kevin0@m.co","Kevin3@m.co","Kevin5@m.co"],["Fern","Fern0@m.co","Fern1@m.co","Fern5@m.co"]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= accounts.length &lt;= 1000 <br />
                2 &lt;= accounts[i].length &lt;= 10 <br />
                1 &lt;= accounts[i][j] &lt;= 30 <br />
                accounts[i][0] consists of English letters. <br />
                accounts[i][j] (for j &gt; 0) is a valid email.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `class UF {
                  public UF(List<List<String>> accounts) {
                    for (List<String> account : accounts)
                      for (int i = 1; i < account.size(); ++i) {
                        final String email = account.get(i);
                        id.putIfAbsent(email, email);
                      }
                  }
                
                  public void union(final String u, final String v) {
                    id.put(find(u), find(v));
                  }
                
                  public String find(final String u) {
                    if (u != id.get(u))
                      id.put(u, find(id.get(u)));
                    return id.get(u);
                  }
                
                  private Map<String, String> id = new HashMap<>();
                }
                
                class Solution {
                  public List<List<String>> accountsMerge(List<List<String>> accounts) {
                    List<List<String>> ans = new ArrayList<>();
                    Map<String, String> emailToName = new HashMap<>();
                    Map<String, TreeSet<String>> idEmailToEmails = new HashMap<>();
                    UF uf = new UF(accounts);
                    for (final List<String> account : accounts)
                      for (int i = 1; i < account.size(); ++i)
                        emailToName.putIfAbsent(account.get(i), account.get(0));
                    for (final List<String> account : accounts)
                      for (int i = 2; i < account.size(); ++i)
                        uf.union(account.get(i), account.get(i - 1));
                
                    for (final List<String> account : accounts)
                      for (int i = 1; i < account.size(); ++i) {
                        final String id = uf.find(account.get(i));
                        idEmailToEmails.putIfAbsent(id, new TreeSet<>());
                        idEmailToEmails.get(id).add(account.get(i));
                      }
                
                    for (final String idEmail : idEmailToEmails.keySet()) {
                      List<String> emails = new ArrayList<>(idEmailToEmails.get(idEmail));
                      final String name = emailToName.get(idEmail);
                      emails.add(0, name);
                      ans.add(emails);
                    }
                
                    return ans;
                  }
                }
                `,
                output: `[["John","johnnybravo@mail.com"],["John","john00@mail.com","john_newyork@mail.com","johnsmith@mail.com"],["Mary","mary@mail.com"]]`,
              },
            }}
          />
        ),
      },
      q581: {
        title: "Q722. Remove Comments (Q581)",
        content: (
          <Comp
            title="Q722. Remove Comments (Q581)"
            content1={
              <>
                Given a C++ program, remove comments from it. The program source
                is an array of strings source where source[i] is the ith line of
                the source code. This represents the result of splitting the
                original source code string by the newline character '\n'.
                <br />
                In C++, there are two types of comments, line comments, and
                block comments.
                <br />
                The string "//" denotes a line comment, which represents that it
                and the rest of the characters to the right of it in the same
                line should be ignored.
                <br />
                The string "/*" denotes a block comment, which represents that
                all characters until the next (non-overlapping) occurrence of
                "*/" should be ignored. (Here, occurrences happen in reading
                order: line by line from left to right.) To be clear, the string
                "/*/" does not yet end the block comment, as the ending would be
                overlapping the beginning.
                <br />
                The first effective comment takes precedence over others.
                <br />
                For example, if the string "//" occurs in a block comment, it is
                ignored.
                <br />
                Similarly, if the string "/*" occurs in a line or block comment,
                it is also ignored.
                <br />
                If a certain line of code is empty after removing comments, you
                must not output that line: each string in the answer list will
                be non-empty.
                <br />
                There will be no control characters, single quote, or double
                quote characters.
                <br />
                For example, source = "string s = "/* Not a comment. */";" will
                not be a test case.
                <br />
                Also, nothing else such as defines or macros will interfere with
                the comments.
                <br />
                It is guaranteed that every open block comment will eventually
                be closed, so "/*" outside of a line or block comment always
                starts a new comment.
                <br />
                Finally, implicit newline characters can be deleted by block
                comments. Please see the examples below for details.
                <br />
                After removing the comments from the source code, return the
                source code in the same format.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    {`
Input: source = ["/*Test program */", "int main()", "{ ", "  // variable declaration ", "int a, b, c;", "/* This is a test", "   multiline  ", "   comment for ", "   testing */", "a = b + c;", "}"]
Output: ["int main()","{ ","  ","int a, b, c;","a = b + c;","}"]
Explanation: The line by line code is visualized as below:
/*Test program */
int main()
{ 
  // variable declaration 
int a, b, c;
/* This is a test
   multiline  
   comment for 
   testing */
a = b + c;
}
The string /* denotes a block comment, including line 1 and lines 6-9. The string // denotes line 4 as comments.
The line by line output code is visualized as below:
int main()
{ 
  
int a, b, c;
a = b + c;
}
                `}
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: source = ["a/*comment", "line", "more_comment*/b"]
                    <br /> Output: ["ab"]
                    <br /> Explanation: The original source string is
                    "a/*comment\nline\nmore_comment*/b", where we have bolded
                    the newline characters. After deletion, the implicit newline
                    characters are deleted, leaving the string "ab", which when
                    delimited by newline characters becomes ["ab"].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= source.length &lt;= 100 <br />
                0 &lt;= source[i].length &lt;= 80 <br />
                source[i] consists of printable ASCII characters. <br />
                Every open block comment is eventually closed. <br />
                There are no single-quote or double-quote in the input.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} source
                * @return {string[]}
                */
               var removeComments = function(source) {
                 const res = [];
                 let commenting = false;
                 let modified = "";
                 for (let line of source) {
                   for (let i = 0; i < line.length;) {
                     if (i + 1 == line.length) {
                       if (!commenting)
                         modified += line[i];
                       ++i;
                       break;
                     }
                     const twoChars = line.substring(i, i+2);
                     if (twoChars == "/*" && !commenting) {
                       commenting = true;
                       i += 2;
                     } else if (twoChars == "*/" && commenting) {
                       commenting = false;
                       i += 2;
                     } else if (twoChars == "//") {
                       if (!commenting)
                         break;
                       else
                         i += 2;
                     } else {
                       if (!commenting)
                         modified += line[i];
                       ++i;
                     }
                   }
                   if (modified.length > 0 && !commenting) {
                     res.push(modified);
                     modified = "";
                   }
                 }
                 return res;
               };
               
               console.log(removeComments(["a/*comment", "line", "more_comment*/b"]))`,
                output: `[ 'ab ]`,
              },
            }}
          />
        ),
      },
      q582: {
        title: "Q724. Find Pivot Index (Q582)",
        content: (
          <Comp
            title="Q724. Find Pivot Index (Q582)"
            content1={
              <>
                Given an array of integers nums, calculate the pivot index of
                this array.
                <br />
                The pivot index is the index where the sum of all the numbers
                strictly to the left of the index is equal to the sum of all the
                numbers strictly to the index's right.
                <br />
                If the index is on the left edge of the array, then the left sum
                is 0 because there are no elements to the left. This also
                applies to the right edge of the array.
                <br />
                Return the leftmost pivot index. If no such index exists, return
                -1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,7,3,6,5,6] <br />
                    Output: 3 <br />
                    Explanation: <br />
                    The pivot index is 3. <br />
                    Left sum = nums[0] + nums[1] + nums[2] = 1 + 7 + 3 = 11{" "}
                    <br />
                    Right sum = nums[4] + nums[5] = 5 + 6 = 11
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3] <br />
                    Output: -1 <br />
                    Explanation: <br />
                    There is no index that satisfies the conditions in the
                    problem statement.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2,1,-1] <br />
                    Output: 0 <br />
                    Explanation: <br />
                    The pivot index is 0. <br />
                    Left sum = 0 (no elements to the left of index 0) <br />
                    Right sum = nums[1] + nums[2] = 1 + -1 = 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4 <br />
                -1000 &lt;= nums[i] &lt;= 1000
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var pivotIndex = function(nums) {
                 const sum = nums.reduce((a,b)=>a+b,0)
                 let prefix = 0;
                 for (let i = 0; i < nums.length; ++i) {
                   if (prefix == sum - prefix - nums[i])
                     return i;
                   prefix += nums[i];
                 }
                 return -1;
               };
               
               console.log(pivotIndex([2,1,-1]))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q583: {
        title: "Q725. Split Linked List in Parts (Q583)",
        content: (
          <Comp
            title="Q725. Split Linked List in Parts (Q583)"
            content1={
              <>
                Given the head of a singly linked list and an integer k, split
                the linked list into k consecutive linked list parts.
                <br />
                The length of each part should be as equal as possible: no two
                parts should have a size differing by more than one. This may
                lead to some parts being null.
                <br />
                The parts should be in the order of occurrence in the input
                list, and parts occurring earlier should always have a size
                greater than or equal to parts occurring later.
                <br />
                Return an array of the k parts.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: head = [1,2,3], k = 5 <br />
                    Output: [[1],[2],[3],[],[]] <br />
                    Explanation: <br />
                    The first element output[0] has output[0].val = 1,
                    output[0].next = null.
                    <br /> The last element output[4] is null, but its string
                    representation as a ListNode is [].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: head = [1,2,3,4,5,6,7,8,9,10], k = 3 <br />
                    Output: [[1,2,3,4],[5,6,7],[8,9,10]] <br />
                    Explanation: <br />
                    The input has been split into consecutive parts with size
                    difference at most 1, and earlier parts are a larger size
                    than the later parts.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the list is in the range [0, 1000].
                <br /> 0 &lt;= Node.val &lt;= 1000
                <br />1 &lt;= k &lt;= 50
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `function ListNode(val, next) {
                  this.val = (val===undefined ? 0 : val)
                  this.next = (next===undefined ? null : next)
                }
                
                /**
                 * @param {ListNode} head
                 * @param {number} k
                 * @return {ListNode[]}
                 */
                var splitListToParts = function(head, k) {
                  const res = [];
                  const length = getLength(head);
                  let subLength = parseInt(length / k);
                  let remainder = length % k;
                  let prev = null;
                  let root = head;
                  for (let i = 0; i < k; ++i, --remainder) {
                    res[i] = root;
                    for (let j = 0; j < subLength + (remainder > 0 ? 1 : 0); ++j) {
                      prev = root;
                      root = root.next;
                    }
                    if (prev)
                      prev.next = null;
                  }
                  return res;
                }
                
                function getLength(root) {
                  let length = 0;
                  for (let curr = root; curr; curr = curr.next)
                    ++length;
                  return length;
                }
                
                const l = new ListNode(1)
                l.next = new ListNode(2)
                l.next.next = new ListNode(3) 
                console.log(splitListToParts(l,5))`,
                output: `[
                  ListNode { val: 1, next: null },
                  ListNode { val: 2, next: null },
                  ListNode { val: 3, next: null },
                  null,
                  null
                ]`,
              },
            }}
          />
        ),
      },
      q584: {
        title: "Q726. Number of Atoms (Q584)",
        content: (
          <Comp
            title="Q726. Number of Atoms (Q584)"
            content1={
              <>
                Given a string formula representing a chemical formula, return
                the count of each atom.
                <br />
                The atomic element always starts with an uppercase character,
                then zero or more lowercase letters, representing the name.
                <br />
                One or more digits representing that element's count may follow
                if the count is greater than 1. If the count is 1, no digits
                will follow.
                <br />
                For example, "H2O" and "H2O2" are possible, but "H1O2" is
                impossible. <br />
                Two formulas are concatenated together to produce another
                formula. <br />
                For example, "H2O2He3Mg4" is also a formula. <br />
                A formula placed in parentheses, and a count (optionally added)
                is also a formula. <br />
                For example, "(H2O2)" and "(H2O2)3" are formulas. <br />
                Return the count of all elements as a string in the following
                form: the first name (in sorted order), followed by its count
                (if that count is more than 1), followed by the second name (in
                sorted order), followed by its count (if that count is more than
                1), and so on.
                <br />
                The test cases are generated so that all the values in the
                output fit in a 32-bit integer.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: formula = "H2O" Output: "H2O" Explanation: The count
                    of elements are {"{'H': 2, 'O': 1}"}.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: formula = "Mg(OH)2" Output: "H2MgO2" Explanation: The
                    count of elements are {"{'H': 2, 'Mg': 1, 'O': 2}"}.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: formula = "K4(ON(SO3)2)2" Output: "K4N2O14S4"
                    Explanation: The count of elements are{" "}
                    {"{'K': 4, 'N': 2, 'O': 14, 'S': 4}"}.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= formula.length &lt;= 1000 <br />
                formula consists of English letters, digits, '(', and ')'.{" "}
                <br />
                formula is always valid.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // H2O
                class Solution {
                  public String countOfAtoms(String s) {
                    StringBuilder sb = new StringBuilder();
                    Map<String, Integer> count = parse(s);
                    for (final String elem : count.keySet())
                      sb.append(elem + (count.get(elem) == 1 ? "" : String.valueOf(count.get(elem))));
                    return sb.toString();
                  }                
                  private int i = 0;
                  private Map<String, Integer> parse(String s) {
                    Map<String, Integer> count = new TreeMap<>();
                    while (i < s.length())
                      if (s.charAt(i) == '(') {
                        ++i; // skip '('
                        for (Map.Entry<String, Integer> entry : parse(s).entrySet()) {
                          final String elem = entry.getKey();
                          final int freq = entry.getValue();
                          count.put(elem, count.getOrDefault(elem, 0) + freq);
                        }
                      } else if (s.charAt(i) == ')') {
                        ++i; // skip ')'
                        final int num = getNum(s);
                        for (final String elem : count.keySet()) {
                          final int freq = count.get(elem);
                          count.put(elem, freq * num);
                        }
                        return count;
                      } else {
                        final String elem = getElem(s);
                        final int num = getNum(s);
                        count.put(elem, count.getOrDefault(elem, 0) + num);
                      }
                
                    return count;
                  }
                  private String getElem(final String s) {
                    final int elemStart = i++;
                    while (i < s.length() && Character.isLowerCase(s.charAt(i)))
                      ++i;
                    return s.substring(elemStart, i);
                  }
                  private int getNum(final String s) {
                    final int numStart = i;
                    while (i < s.length() && Character.isDigit(s.charAt(i)))
                      ++i;
                    final String numString = s.substring(numStart, i);
                    return numString.isEmpty() ? 1 : Integer.parseInt(numString);
                  }
                }
                `,
                output: `H2O`,
              },
            }}
          />
        ),
      },
      q585: {
        title: "Q728. Self Dividing Numbers (Q585)",
        content: (
          <Comp
            title="Q728. Self Dividing Numbers (Q585)"
            content1={
              <>
                A self-dividing number is a number that is divisible by every
                digit it contains.
                <br />
                For example, 128 is a self-dividing number because 128 % 1 == 0,
                128 % 2 == 0, and 128 % 8 == 0.
                <br />
                A self-dividing number is not allowed to contain the digit zero.
                <br />
                Given two integers left and right, return a list of all the
                self-dividing numbers in the range [left, right].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: left = 1, <br /> right = 22 <br />
                    Output: [1,2,3,4,5,6,7,8,9,11,12,15,22]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: left = 47,
                    <br /> right = 85
                    <br />
                    Output: [48,55,66,77]
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= left &lt;= right &lt;= 10^4</>}
            tc="n.log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} left
                * @param {number} right
                * @return {number[]}
                */
               var selfDividingNumbers = function(left, right) {
                 const res = [];
                 for (let num = left; num <= right; ++num)
                   if (dividingNumber(num))
                     res.push(num);
                 return res;
               };
               
               function dividingNumber(num) {
                 for (let n = num; n > 0; n = parseInt(n/10))
                   if (n % 10 == 0 || num % (n % 10) != 0)
                     return false;
                 return true;
               }
               
               console.log(selfDividingNumbers(47,85))`,
                output: `[ 48, 55, 66, 77 ]`,
              },
            }}
          />
        ),
      },
      q586: {
        title: "Q729. My Calendar I (Q586)",
        content: (
          <Comp
            title="Q729. My Calendar I (Q586)"
            content1={
              <>
                You are implementing a program to use as your calendar. We can
                add a new event if adding the event will not cause a double
                booking.
                <br />
                A double booking happens when two events have some non-empty
                intersection (i.e., some moment is common to both events.).
                <br />
                The event can be represented as a pair of integers start and end
                that represents a booking on the half-open interval [start,
                end), the range of real numbers x such that start &lt;= x &lt;
                end.
                <br />
                Implement the MyCalendar class:
                <br />
                MyCalendar() Initializes the calendar object. <br />
                boolean book(int start, int end) Returns true if the event can
                be added to the calendar successfully without causing a double
                booking. Otherwise, return false and do not add the event to the
                calendar.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br />
                    ["MyCalendar", "book", "book", "book"]
                    <br />
                    [[], [10, 20], [15, 25], [20, 30]]
                    <br />
                    Output
                    <br />
                    [null, true, false, true]
                    <br />
                    Explanation
                    <br />
                    MyCalendar myCalendar = new MyCalendar();
                    <br />
                    myCalendar.book(10, 20); // return True
                    <br />
                    myCalendar.book(15, 25); // return False, It can not be
                    booked because time 15 is already booked by another event.
                    <br /> myCalendar.book(20, 30); // return True, The event
                    can be booked, as the first event takes every time less than
                    20, but not including 20.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= start &lt; end &lt;= 10^9
                <br />
                At most 1000 calls will be made to book.
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Javascript: {
                code: `var Node = function (start,end) {
                  this.left = null
                  this.right = null
                  this.start = start
                  this.end = end
                }
                class Tree {
                  constructor(){
                    this.root = null
                  }
                  insert(node,root=null){
                    if(!root){
                      if(!this.root){
                        this.root = node
                        return true
                      }else{
                        root = this.root
                      }
                    }
                    if(node.start >= root.end){
                      if(!root.right){
                        root.right = node
                        return true
                      }
                      return this.insert(node,root.right)
                    }
                    else if(node.end <= root.start){
                      if(!root.left){
                        root.left = node
                        return true
                      }
                      return this.insert(node,root.left)
                    }
                    else{
                      return false
                    }
                  }
                }
                var MyCalendar = function() {
                    this.tree = new Tree()
                };
                /** 
                 * @param {number} start 
                 * @param {number} end
                 * @return {boolean}
                 */
                MyCalendar.prototype.book = function(start, end) {
                    return this.tree.insert(new Node(start,end))
                };
                 var obj = new MyCalendar()
                 var param_1 = obj.book(1,5)
                console.log({param_1})`,
                output: `{ param_1: true }`,
              },
            }}
          />
        ),
      },
      q587: {
        title: "Q730. Count Different Palindromic Subsequences (Q587)",
        content: (
          <Comp
            title="Q730. Count Different Palindromic Subsequences (Q587)"
            content1={
              <>
                Given a string s, return the number of different non-empty
                palindromic subsequences in s. Since the answer may be very
                large, return it modulo 109 + 7.
                <br />
                A subsequence of a string is obtained by deleting zero or more
                characters from the string.
                <br />
                A sequence is palindromic if it is equal to the sequence
                reversed.
                <br />
                Two sequences a1, a2, ... and b1, b2, ... are different if there
                is some i for which ai != bi.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "bccb"
                    <br />
                    Output: 6<br />
                    Explanation: The 6 different non-empty palindromic
                    subsequences are 'b', 'c', 'bb', 'cc', 'bcb', 'bccb'. Note
                    that 'bcb' is counted only once, even though it occurs
                    twice.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s =
                    "abcdabcdabcdabcdabcdabcdabcdabcddcbadcbadcbadcbadcbadcbadcbadcba"
                    <br /> Output: 104860361
                    <br />
                    Explanation: There are 3104860382 different non-empty
                    palindromic subsequences, which is 104860361 modulo 109 + 7.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 1000 <br />
                s[i] is either 'a', 'b', 'c', or 'd
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               var countPalindromicSubsequences = function(s) {
                 const kMod = parseInt(1e9 + 7);
                 const n = s.length;
                 const dp = Array.from(Array(n),()=>Array(n).fill(0));
                   for (let i = 0; i < n; ++i)
                     dp[i][i] = 1;
                   for (let d = 1; d < n; ++d)
                     for (let i = 0; i + d < n; ++i) {
                       const j = i + d;
                       if (s[i] == s[j]) {
                         let lo = i + 1;
                         let hi = j - 1;
                         while (lo <= hi && s[lo] != s[i])
                           ++lo;
                         while (lo <= hi && s[hi] != s[i])
                           --hi;
                         if (lo > hi)
                           dp[i][j] = dp[i + 1][j - 1] * 2 + 2;
                         else if (lo == hi)
                           dp[i][j] = dp[i + 1][j - 1] * 2 + 1;
                         else
                           dp[i][j] = dp[i + 1][j - 1] * 2 - dp[lo + 1][hi - 1];
                       } else {
                         dp[i][j] = dp[i][j - 1] + dp[i + 1][j] - dp[i + 1][j - 1];
                       }
                       dp[i][j] = parseInt((dp[i][j] + kMod) % kMod);
                     }
                   return dp[0][n - 1];
               };
               console.log(countPalindromicSubsequences("bccb"))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q588: {
        title: "Q731. My Calendar II (Q588)",
        content: (
          <Comp
            title="Q731. My Calendar II (Q588)"
            content1={
              <>
                You are implementing a program to use as your calendar. We can
                add a new event if adding the event will not cause a triple
                booking.
                <br />
                A triple booking happens when three events have some non-empty
                intersection (i.e., some moment is common to all the three
                events.).
                <br />
                The event can be represented as a pair of integers start and end
                that represents a booking on the half-open interval [start,
                end), the range of real numbers x such that start &lt;= x &lt;
                end.
                <br />
                Implement the MyCalendarTwo class:
                <br />
                MyCalendarTwo() Initializes the calendar object. <br />
                boolean book(int start, int end) Returns true if the event can
                be added to the calendar successfully without causing a triple
                booking. Otherwise, return false and do not add the event to the
                calendar.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br />
                    ["MyCalendarTwo", "book", "book", "book", "book", "book",
                    "book"]
                    <br /> [[], [10, 20], [50, 60], [10, 40], [5, 15], [5, 10],
                    [25, 55]]
                    <br /> Output
                    <br /> [null, true, true, true, false, true, true]
                    <br /> Explanation
                    <br /> MyCalendarTwo myCalendarTwo = new MyCalendarTwo();
                    <br /> myCalendarTwo.book(10, 20); // return True, The event
                    can be booked.
                    <br /> myCalendarTwo.book(50, 60); // return True, The event
                    can be booked.
                    <br /> myCalendarTwo.book(10, 40); // return True, The event
                    can be double booked.
                    <br /> myCalendarTwo.book(5, 15); // return False, The event
                    cannot be booked, because it would result in a triple
                    booking.
                    <br /> myCalendarTwo.book(5, 10); // return True, The event
                    can be booked, as it does not use time 10 which is already
                    double booked.
                    <br /> myCalendarTwo.book(25, 55); // return True, The event
                    can be booked, as the time in [25, 40) will be double booked
                    with the third event, the time [40, 50) will be single
                    booked, and the time [50, 55) will be double booked with the
                    second event.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= start &lt; end &lt;= 10^9 <br />
                At most 1000 calls will be made to book
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `
                var MyCalendarTwo = function() {
                  this.ranges = [];
                  this.overlaps = [];
                };
                /** 
                 * @param {number} start 
                 * @param {number} end
                 * @return {boolean}
                 */
                MyCalendarTwo.prototype.book = function(start, end) {
                  for (let overlap of this.overlaps)
                    if (Math.max(start, overlap[0]) < Math.min(end, overlap[1]))
                      return false;
                  for (let range of this.ranges) {
                    const maxStart = Math.max(start, range[0]);
                    const minEnd = Math.min(end, range[1]);
                    if (maxStart < minEnd)
                      this.overlaps.push([maxStart, minEnd]);
                  }
                  this.ranges.push([start, end]);
                  return true;
                };
                var obj = new MyCalendarTwo()
                var param_1 = obj.book(1,5)
                console.log({param_1})`,
                output: `{ param_1: true }`,
              },
            }}
          />
        ),
      },
      q589: {
        title: "Q732. My Calendar III (Q589)",
        content: (
          <Comp
            title="Q732. My Calendar III (Q589)"
            content1={
              <>
                A k-booking happens when k events have some non-empty
                intersection (i.e., there is some time that is common to all k
                events.)
                <br />
                You are given some events [start, end), after each given event,
                return an integer k representing the maximum k-booking between
                all the previous events.
                <br />
                Implement the MyCalendarThree class:
                <br />
                MyCalendarThree() Initializes the object. <br />
                int book(int start, int end) Returns an integer k representing
                the largest integer such that there exists a k-booking in the
                calendar.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["MyCalendarThree", "book", "book", "book", "book", "book",
                    "book"]
                    <br /> [[], [10, 20], [50, 60], [10, 40], [5, 15], [5, 10],
                    [25, 55]] Output <br />
                    [null, 1, 1, 2, 3, 3, 3]
                    <br />
                    Explanation <br />
                    MyCalendarThree myCalendarThree = new MyCalendarThree();
                    <br /> myCalendarThree.book(10, 20); // return 1, The first
                    event can be booked and is disjoint, so the maximum
                    k-booking is a 1-booking.
                    <br /> myCalendarThree.book(50, 60); // return 1, The second
                    event can be booked and is disjoint, so the maximum
                    k-booking is a 1-booking.
                    <br /> myCalendarThree.book(10, 40); // return 2, The third
                    event [10, 40) intersects the first event, and the maximum
                    k-booking is a 2-booking.
                    <br /> myCalendarThree.book(5, 15); // return 3, The
                    remaining events cause the maximum K-booking to be only a
                    3-booking.
                    <br /> myCalendarThree.book(5, 10); // return 3
                    <br /> myCalendarThree.book(25, 55); // return 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= start &lt;end &lt;= 109
                <br />
                At most 400 calls will be made to book.
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Javascript: {
                code: `var MyCalendarThree = function() {
                  this.timeline = {};
                };
                
                /** 
                 * @param {number} start 
                 * @param {number} end
                 * @return {number}
                 */
                MyCalendarThree.prototype.book = function(start, end) {
                  this.timeline[start] = (this.timeline[start] || 0) + 1;
                  this.timeline[end] = (this.timeline[end] || 0) - 1;
                  let ans = 0;
                  let activeEvents = 0;
                  for (let count of Object.values(this.timeline)) {
                    activeEvents += +count;
                    ans = Math.max(ans, activeEvents);
                  }
                  return ans;
                };
                
                var obj = new MyCalendarThree()
                var param_1 = obj.book(1,5)
                console.log({ param_1 })`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q590: {
        title: "Q733. Flood Fill (Q590)",
        content: (
          <Comp
            title="Q733. Flood Fill (Q590)"
            content1={
              <>
                An image is represented by an m x n integer grid image where
                image[i][j] represents the pixel value of the image.
                <br />
                You are also given three integers sr, sc, and newColor. You
                should perform a flood fill on the image starting from the pixel
                image[sr][sc].
                <br />
                To perform a flood fill, consider the starting pixel, plus any
                pixels connected 4-directionally to the starting pixel of the
                same color as the starting pixel, plus any pixels connected
                4-directionally to those pixels (also with the same color), and
                so on. Replace the color of all of the aforementioned pixels
                with newColor.
                <br />
                Return the modified image after performing the flood fill.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode733,
                content: (
                  <>
                    Input: image = [[1,1,1],[1,1,0],[1,0,1]],
                    <br /> sr = 1, <br />
                    sc = 1, <br />
                    newColor = 2<br />
                    Output: [[2,2,2],[2,2,0],[2,0,1]]
                    <br />
                    Explanation: From the center of the image with position (sr,
                    sc) = (1, 1) (i.e., the red pixel), all pixels connected by
                    a path of the same color as the starting pixel (i.e., the
                    blue pixels) are colored with the new color.
                    <br />
                    Note the bottom corner is not colored 2, because it is not
                    4-directionally connected to the starting pixel.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: image = [[0,0,0],[0,0,0]],
                    <br /> sr = 0, <br />
                    sc = 0, <br />
                    newColor = 2<br />
                    Output: [[2,2,2],[2,2,2]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == image.length <br />
                n == image[i].length <br />
                1 &lt;= m, n &lt;= 50 <br />
                0 &lt;= image[i][j], newColor &lt; 216 <br />
                0 &lt;= sr &lt; m <br />0 &lt;= sc&lt; n
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} image
                * @param {number} sr
                * @param {number} sc
                * @param {number} newColor
                * @return {number[][]}
                */
               var floodFill = function(image, sr, sc, newColor) {
                 const seen = Array.from(Array(image.length),()=>Array(image[0].length).fill(0));
                 dfs(image, sr, sc, seen, image[sr][sc], newColor);
                 return image;
               };
               
               function dfs(image, i, j, seen, startColor, newColor){
                 if (i < 0 || i == image.length || j < 0 || j == image[0].length)
                   return;
                 if (image[i][j] != startColor || seen[i][j])
                   return;
                 image[i][j] = newColor;
                 seen[i][j] = true;
                 dfs(image, i + 1, j, seen, startColor, newColor);
                 dfs(image, i - 1, j, seen, startColor, newColor);
                 dfs(image, i, j + 1, seen, startColor, newColor);
                 dfs(image, i, j - 1, seen, startColor, newColor);
               }
               
               console.log(floodFill([[1,1,1],[1,1,0],[1,0,1]],1,1,2))`,
                output: `[ [ 2, 2, 2 ], [ 2, 2, 0 ], [ 2, 0, 1 ] ]`,
              },
            }}
          />
        ),
      },
      q591: {
        title: "Q735. Asteroid Collision (Q591)",
        content: (
          <Comp
            title="Q735. Asteroid Collision (Q591)"
            content1={
              <>
                We are given an array asteroids of integers representing
                asteroids in a row.
                <br />
                For each asteroid, the absolute value represents its size, and
                the sign represents its direction (positive meaning right,
                negative meaning left). Each asteroid moves at the same speed.
                <br />
                Find out the state of the asteroids after all collisions. If two
                asteroids meet, the smaller one will explode. If both are the
                same size, both will explode. Two asteroids moving in the same
                direction will never meet.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: asteroids = [5,10,-5] <br />
                    Output: [5,10] <br />
                    Explanation: The 10 and -5 collide resulting in 10. The 5
                    and 10 never collide.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: asteroids = [8,-8]
                    <br />
                    Output: []
                    <br />
                    Explanation: The 8 and -8 collide exploding each other.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: asteroids = [10,2,-5]
                    <br />
                    Output: [10]
                    <br />
                    Explanation: The 2 and -5 collide resulting in -5. The 10
                    and -5 collide resulting in 10.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= asteroids.length &lt;= 10^4 <br />
                -1000 &lt;= asteroids[i] &lt;= 1000 <br />
                asteroids[i] != 0
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// [5,10,-5]
                class Solution {
                  public int[] asteroidCollision(int[] asteroids) {
                    Stack<Integer> stack = new Stack<>();
                    for (final int a : asteroids)
                      if (a > 0) {
                        stack.push(a);
                      } else {
                        while (!stack.isEmpty() && stack.peek() > 0 && stack.peek() < -a)
                          stack.pop();
                        if (stack.isEmpty() || stack.peek() < 0)
                          stack.push(a);
                        else if (stack.peek() == -a)
                          stack.pop(); 
                        else ;            
                      }
                    int[] ans = new int[stack.size()];
                    for (int i = ans.length - 1; i >= 0; --i)
                      ans[i] = stack.pop();
                    return ans;
                  }
                }
                `,
                output: `[5,10]`,
              },
            }}
          />
        ),
      },
      q592: {
        title: "Q736. Parse Lisp Expression (Q592)",
        content: (
          <Comp
            title="Q736. Parse Lisp Expression (Q592)"
            content1={
              <>
                You are given a string expression representing a Lisp-like
                expression to return the integer value of.
                <br />
                The syntax for these expressions is given as follows.
                <br />
                An expression is either an integer, let expression, add
                expression, mult expression, or an assigned variable.
                Expressions always evaluate to a single integer.
                <br />
                (An integer could be positive or negative.)
                <br />
                A let expression takes the form "(let v1 e1 v2 e2 ... vn en
                expr)", where let is always the string "let", then there are one
                or more pairs of alternating variables and expressions, meaning
                that the first variable v1 is assigned the value of the
                expression e1, the second variable v2 is assigned the value of
                the expression e2, and so on sequentially; and then the value of
                this let expression is the value of the expression expr.
                <br />
                An add expression takes the form "(add e1 e2)" where add is
                always the string "add", there are always two expressions e1, e2
                and the result is the addition of the evaluation of e1 and the
                evaluation of e2.
                <br />
                A mult expression takes the form "(mult e1 e2)" where mult is
                always the string "mult", there are always two expressions e1,
                e2 and the result is the multiplication of the evaluation of e1
                and the evaluation of e2.
                <br />
                For this question, we will use a smaller subset of variable
                names. A variable starts with a lowercase letter, then zero or
                more lowercase letters or digits. Additionally, for your
                convenience, the names "add", "let", and "mult" are protected
                and will never be used as variable names.
                <br />
                Finally, there is the concept of scope. When an expression of a
                variable name is evaluated, within the context of that
                evaluation, the innermost scope (in terms of parentheses) is
                checked first for the value of that variable, and then outer
                scopes are checked sequentially. It is guaranteed that every
                expression is legal. Please see the examples for more details on
                the scope.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: expression = "(let x 2 (mult x (let x 3 y 4 (add x
                    y))))"
                    <br /> Output: 14 <br />
                    Explanation: In the expression (add x y), when checking for
                    the value of the variable x, we check from the innermost
                    scope to the outermost in the context of the variable we are
                    trying to evaluate. Since x = 3 is found first, the value of
                    x is 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: expression = "(let x 3 x 2 x)"
                    <br /> Output: 2 <br />
                    Explanation: Assignment in let statements is processed
                    sequentially.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: expression = "(let x 1 y 2 x (add x y) (add x y))"
                    <br /> Output: 5 <br />
                    Explanation: The first (add x y) evaluates as 3, and is
                    assigned to x. The second (add x y) evaluates as 3+2 = 5.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= expression.length &lt;= 2000 <br />
                There are no leading or trailing spaces in expression. <br />
                All tokens are separated by a single space in expression. <br />
                The answer and all intermediate calculations of that answer are
                guaranteed to fit in a 32-bit integer.
                <br />
                The expression is guaranteed to be legal and evaluate to an
                integer.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // "(let x 3 x 2 x)"
                class Solution {
                  public int evaluate(String expression) {
                    return evaluate(expression, new HashMap<>());
                  }
                  private int evaluate(final String e, Map<String, Integer> prevScope) {
                    if (Character.isDigit(e.charAt(0)) || e.charAt(0) == '-')
                      return Integer.parseInt(e);
                    if (prevScope.containsKey(e))
                      return prevScope.get(e);
                    Map<String, Integer> scope = new HashMap<>();
                    scope.putAll(prevScope);              
                    final int spaceIndex = e.indexOf(' ');
                    final String nextExpression = e.substring(spaceIndex + 1, e.length() - 1); // -2: "()"
                    List<String> tokens = split(nextExpression);                
                    if (e.startsWith("(m")) 
                      return evaluate(tokens.get(0), scope) * evaluate(tokens.get(1), scope);
                    if (e.startsWith("(a")) 
                      return evaluate(tokens.get(0), scope) + evaluate(tokens.get(1), scope);
                    for (int i = 0; i < tokens.size() - 2; i += 2)
                      scope.put(tokens.get(i), evaluate(tokens.get(i + 1), scope));
                    return evaluate(tokens.get(tokens.size() - 1), scope);
                  }       
                  private List<String> split(final String s) {
                    List<String> tokens = new ArrayList<>();
                    StringBuilder sb = new StringBuilder();
                    int parenthesis = 0;
                    for (char c : s.toCharArray()) {
                      if (c == '(')
                        ++parenthesis;
                      else if (c == ')')
                        --parenthesis;
                      if (parenthesis == 0 && c == ' ') {
                        tokens.add(sb.toString());
                        sb.setLength(0);
                      } else {
                        sb.append(c);
                      }
                    }
                    if (sb.length() > 0)
                      tokens.add(sb.toString());
                    return tokens;
                  }
                }
                `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q593: {
        title: "Q738. Monotone Increasing Digits (Q593)",
        content: (
          <Comp
            title="Q738. Monotone Increasing Digits (Q593)"
            content1={
              <>
                An integer has monotone increasing digits if and only if each
                pair of adjacent digits x and y satisfy x &lt;= y.
                <br />
                Given an integer n, return the largest number that is less than
                or equal to n with monotone increasing digits.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 10
                    <br />
                    Output: 9
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1234
                    <br />
                    Output: 1234
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 332
                    <br />
                    Output: 299
                  </>
                ),
              },
            ]}
            constraints={<>0 &lt;= n &lt;= 10^9</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               var monotoneIncreasingDigits = function(n) {
                 let s = String(n).split('');
                 let len = s.length;
                 let k = len; 
                 for (let i = len - 1; i > 0; --i)
                   if (+s[i] < +s[i - 1]) {
                     --s[i - 1]
                     k = i;
                   }
                 for (let i = k; i < len; ++i)
                   s[i] = '9';
                 return parseInt(s.join(""));    
               };
               
               console.log(monotoneIncreasingDigits(332))`,
                output: `299`,
              },
            }}
          />
        ),
      },
      q594: {
        title: "Q739. Daily Temperatures (Q594)",
        content: (
          <Comp
            title="Q739. Daily Temperatures (Q594)"
            content1={
              <>
                Given an array of integers temperatures represents the daily
                temperatures, return an array answer such that answer[i] is the
                number of days you have to wait after the ith day to get a
                warmer temperature. If there is no future day for which this is
                possible, keep answer[i] == 0 instead.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: temperatures = [73,74,75,71,69,72,76,73]
                    <br />
                    Output: [1,1,4,2,1,1,0,0]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: temperatures = [30,40,50,60]
                    <br />
                    Output: [1,1,1,0]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: temperatures = [30,60,90]
                    <br />
                    Output: [1,1,0]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= temperatures.length &lt;= 10^5 <br />
                30 &lt;= temperatures[i] &lt;= 100
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} temperatures
                * @return {number[]}
                */
               var dailyTemperatures = function(temperatures) {
                 const ans = Array(temperatures.length).fill(0);
                 const stack = []; 
                 for (let i = 0; i < temperatures.length; ++i) {
                 while (stack.length && temperatures[stack.slice(-1)[0]] < temperatures[i]) {
                   let index = stack.pop();
                   ans[index] = i - index;
                   }
                   stack.push(i);
                 }
                 return ans;  
               };
               
               console.log(dailyTemperatures([73,74,75,71,69,72,76,73]))`,
                output: `[
                  1, 1, 4, 2,
                  1, 1, 0, 0
                ]`,
              },
            }}
          />
        ),
      },
      q595: {
        title: "Q740. Delete and Earn (Q595)",
        content: (
          <Comp
            title="Q740. Delete and Earn (Q595)"
            content1={
              <>
                You are given an integer array nums. You want to maximize the
                number of points you get by performing the following operation
                any number of times:
                <br />
                Pick any nums[i] and delete it to earn nums[i] points.
                Afterwards, you must delete every element equal to nums[i] - 1
                and every element equal to nums[i] + 1.
                <br />
                Return the maximum number of points you can earn by applying the
                above operation some number of times.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [3,4,2] <br />
                    Output: 6 <br />
                    Explanation: You can perform the following operations:{" "}
                    <br />
                    - Delete 4 to earn 4 points. Consequently, 3 is also
                    deleted. nums = [2].
                    <br /> - Delete 2 to earn 2 points. nums = []. <br />
                    You earn a total of 6 points.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2,2,3,3,3,4]
                    <br />
                    Output: 9<br />
                    Explanation: You can perform the following operations:
                    <br />
                    - Delete a 3 to earn 3 points. All 2's and 4's are also
                    deleted. nums = [3,3].
                    <br /> - Delete a 3 again to earn 3 points. nums = [3].
                    <br /> - Delete a 3 once more to earn 3 points. nums = [].
                    <br />
                    You earn a total of 9 points.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 2 * 10^4
                <br />1 &lt;= nums[i] &lt;= 104
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var deleteAndEarn = function(nums) {
                 const bucket = Array(10001).fill(0);
                 for (let num of nums)
                   bucket[num] += num;
                 let prev1 = 0;
                 let prev2 = 0;
                   for (let num of bucket) {
                     const dp = Math.max(prev1, prev2 + num);
                     prev2 = prev1;
                     prev1 = dp;
                   }
                   return prev1;
               };
               console.log(deleteAndEarn([2,2,3,3,3,4]))`,
                output: `9`,
              },
            }}
          />
        ),
      },
      q596: {
        title: "Q741. Cherry Pickup (Q596)",
        content: (
          <Comp
            title="Q741. Cherry Pickup (Q596)"
            content1={
              <>
                You are given an n x n grid representing a field of cherries,
                each cell is one of three possible integers.
                <br />
                0 means the cell is empty, so you can pass through,
                <br />
                1 means the cell contains a cherry that you can pick up and pass
                through, or
                <br />
                -1 means the cell contains a thorn that blocks your way.
                <br />
                Return the maximum number of cherries you can collect by
                following the rules below:
                <br />
                Starting at the position (0, 0) and reaching (n - 1, n - 1) by
                moving right or down through valid path cells (cells with value
                0 or 1).
                <br />
                After reaching (n - 1, n - 1), returning to (0, 0) by moving
                left or up through valid path cells.
                <br />
                When passing through a path cell containing a cherry, you pick
                it up, and the cell becomes an empty cell 0.
                <br />
                If there is no valid path between (0, 0) and (n - 1, n - 1),
                then no cherries can be collected.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode741,
                content: (
                  <>
                    Input: grid = [[0,1,-1],[1,0,-1],[1,1,1]]
                    <br />
                    Output: 5<br />
                    Explanation: The player started at (0, 0) and went down,
                    down, right right to reach (2, 2).
                    <br /> 4 cherries were picked up during this single trip,
                    and the matrix becomes [[0,1,-1],[0,0,-1],[0,0,0]].
                    <br /> Then, the player went left, up, up, left to return
                    home, picking up one more cherry.
                    <br /> The total number of cherries picked up is 5, and this
                    is the maximum possible.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[1,1,-1],[1,-1,1],[-1,1,1]] <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == grid.length
                <br />
                n == grid[i].length
                <br />
                1 &lt;= n &lt;= 50
                <br />
                grid[i][j] is -1, 0, or 1.
                <br />
                grid[0][0] != -1
                <br />
                grid[n - 1][n - 1] != -1
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} grid
                * @return {number}
                */
               var cherryPickup = function(grid) {
                 const n = grid.length;
                 const dp = Array.from(Array(n + 1),()=>Array.from(Array(n + 1),()=>Array(n + 1).fill(-1)));
                 dp[1][1][1] = grid[0][0];
                 for (let x1 = 1; x1 <= n; ++x1)
                   for (let y1 = 1; y1 <= n; ++y1)
                     for (let x2 = 1; x2 <= n; ++x2) {
                       let y2 = x1 + y1 - x2;
                         if (y2 < 1 || y2 > n)
                           continue;
                         if (grid[x1 - 1][y1 - 1] == -1 || grid[x2 - 1][y2 - 1] == -1)
                           continue;
                         let ans = Math.max(Math.max(dp[x1 - 1][y1][x2], dp[x1 - 1][y1][x2 - 1]),
                                                  Math.max(dp[x1][y1 - 1][x2], dp[x1][y1 - 1][x2 - 1]));
                         if (ans < 0)
                           continue;
                         dp[x1][y1][x2] = ans + grid[x1 - 1][y1 - 1];
                         if (x1 != x2)
                           dp[x1][y1][x2] += grid[x2 - 1][y2 - 1];
                       }
                   return Math.max(0, dp[n][n][n]);
               };
               
               console.log(cherryPickup([[1,1,-1],[1,-1,1],[-1,1,1]]))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q597: {
        title: "Q743. Network Delay Time (Q597)",
        content: (
          <Comp
            title="Q743. Network Delay Time (Q597)"
            content1={
              <>
                You are given a network of n nodes, labeled from 1 to n. You are
                also given times, a list of travel times as directed edges
                times[i] = (ui, vi, wi), where ui is the source node, vi is the
                target node, and wi is the time it takes for a signal to travel
                from source to target.
                <br />
                We will send a signal from a given node k. Return the time it
                takes for all the n nodes to receive the signal. If it is
                impossible for all the n nodes to receive the signal, return -1.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode743,
                content: (
                  <>
                    Input: times = [[2,1,1],[2,3,1],[3,4,1]], <br /> n = 4, k =
                    2 <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: times = [[1,2,1]], <br /> n = 2, k = 1 <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: times = [[1,2,1]], <br />n = 2, k = 2 <br />
                    Output: -1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= k &lt;= n &lt;= 100
                <br />
                1 &lt;= times.length &lt;= 6000
                <br />
                times[i].length == 3<br />
                1 &lt;= ui, vi &lt;= n<br />
                ui != vi
                <br />
                0 &lt;= wi &lt;= 100
                <br />
                All the pairs (ui, vi) are unique. (i.e., no multiple edges.)
              </>
            }
            tc="e + log v"
            sc="v"
            codes={{
              Java: {
                code: `
                // times = [[2,1,1],[2,3,1],[3,4,1]], n = 4, k = 2
                class T {
                  public int u;
                  public int d;
                  public T(int u, int d) {
                    this.u = u;
                    this.d = d;
                  }
                }
                
                class Solution {
                  public int networkDelayTime(int[][] times, int n, int k) {
                    List<Pair<Integer, Integer>>[] graph = new List[n];
                    PriorityQueue<T> minHeap = new PriorityQueue<>((a, b) -> a.d - b.d);
                    boolean[] seen = new boolean[n];
                
                    for (int i = 0; i < n; ++i)
                      graph[i] = new ArrayList<>();
                
                    for (int[] t : times) {
                      final int u = t[0] - 1;
                      final int v = t[1] - 1;
                      final int w = t[2];
                      graph[u].add(new Pair<>(v, w));
                    }
                
                    minHeap.offer(new T(k - 1, 0));
                
                    while (!minHeap.isEmpty()) {
                      final int u = minHeap.peek().u;
                      final int d = minHeap.poll().d;
                      if (seen[u])
                        continue;
                      seen[u] = true;
                      if (--n == 0)
                        return d;
                      for (var node : graph[u]) {
                        final int v = node.getKey();
                        final int w = node.getValue();
                        minHeap.offer(new T(v, d + w));
                      }
                    }
                
                    return -1;
                  }
                }
                `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q598: {
        title: "Q744. Find Smallest Letter Greater Than Target (Q598)",
        content: (
          <Comp
            title="Q744. Find Smallest Letter Greater Than Target (Q598)"
            content1={
              <>
                Given a characters array letters that is sorted in
                non-decreasing order and a character target, return the smallest
                character in the array that is larger than target.
                <br />
                Note that the letters wrap around.
                <br />
                For example, if target == 'z' and letters == ['a', 'b'], the
                answer is 'a'.
                <br />
                <br />
                Constraints:
                <br />
                2 &lt;= letters.length &lt;= 104
                <br />
                letters[i] is a lowercase English letter.
                <br />
                letters is sorted in non-decreasing order.
                <br />
                letters contains at least two different characters.
                <br />
                target is a lowercase English letter.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    {" "}
                    Input: letters = ["c","f","j"],
                    <br /> target = "a" <br />
                    Output: "c"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: letters = ["c","f","j"], <br />
                    target = "c" <br />
                    Output: "f"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: letters = ["c","f","j"], <br />
                    target = "d" <br />
                    Output: "f"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= letters.length &lt;= 10^4 <br />
                letters[i] is a lowercase English letter. <br />
                letters is sorted in non-decreasing order. <br />
                letters contains at least two different characters. <br />
                target is a lowercase English letter.
              </>
            }
            tc="log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {character[]} letters
                * @param {character} target
                * @return {character}
                */
               var nextGreatestLetter = function(letters, target) {
                   let l = 0;
                   let r = letters.length;
                   while (l < r) {
                     const m = l + parseInt((r - l) / 2);
                     if (letters[m] > target)
                       r = m;
                     else
                       l = m + 1;
                   }
                   return letters[l % letters.length];
               };
               
               console.log(nextGreatestLetter(["c","f","j"],"a"))`,
                output: `c`,
              },
            }}
          />
        ),
      },
      q599: {
        title: "Q745. Prefix and Suffix Search (Q599)",
        content: (
          <Comp
            title="Q745. Prefix and Suffix Search (Q599)"
            content1={
              <>
                Design a special dictionary with some words that searchs the
                words in it by a prefix and a suffix.
                <br />
                Implement the WordFilter class:
                <br />
                WordFilter(string[] words) Initializes the object with the words
                in the dictionary. f(string prefix, string suffix) Returns the
                index of the word in the dictionary, which has the prefix prefix
                and the suffix suffix. If there is more than one valid index,
                return the largest of them. If there is no such word in the
                dictionary, return -1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["WordFilter", "f"] <br />
                    [[["apple"]], ["a", "e"]] <br />
                    Output <br />
                    [null, 0]
                    <br />
                    Explanation <br />
                    WordFilter wordFilter = new WordFilter(["apple"]); <br />
                    wordFilter.f("a", "e"); // return 0, because the word at
                    index 0 has prefix = "a" and suffix = 'e".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= words.length &lt;= 15000
                <br />
                1 &lt;= words[i].length &lt;= 10
                <br />
                1 &lt;= prefix.length, suffix.length &lt;= 10
                <br />
                words[i], prefix and suffix consist of lower-case English
                letters only.
                <br />
                At most 15000 calls will be made to the function f.
              </>
            }
            tc="n^4"
            sc="n^4"
            codes={{
              Java: {
                code: `
                // ["WordFilter","f"]
                // [[["apple"]],["a","e"]]
                class WordFilter {
                  public WordFilter(String[] words) {
                    for (int i = 0; i < words.length; ++i) {
                      final String word = words[i];
                      List<String> prefixes = new ArrayList<>();
                      List<String> suffixes = new ArrayList<>();
                      for (int j = 0; j <= word.length(); ++j) {
                        final String prefix = word.substring(0, j);
                        final String suffix = word.substring(j);
                        prefixes.add(prefix);
                        suffixes.add(suffix);
                      }
                      for (final String prefix : prefixes)
                        for (final String suffix : suffixes)
                          keyToIndex.put(prefix + '_' + suffix, i);
                    }
                  }
                
                  public int f(String prefix, String suffix) {
                    final String key = prefix + '_' + suffix;
                    if (keyToIndex.containsKey(key))
                      return keyToIndex.get(key);
                    return -1;
                  }
                
                  private Map<String, Integer> keyToIndex = new HashMap<>();
                }
                `,
                output: `[null,0]`,
              },
            }}
          />
        ),
      },
      q600: {
        title: "Q746. Min Cost Climbing Stairs (Q600)",
        content: (
          <Comp
            title="Q746. Min Cost Climbing Stairs (Q600)"
            content1={
              <>
                You are given an integer array cost where cost[i] is the cost of
                ith step on a staircase. Once you pay the cost, you can either
                climb one or two steps.
                <br />
                You can either start from the step with index 0, or the step
                with index 1.
                <br />
                Return the minimum cost to reach the top of the floor.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: cost = [10,15,20]
                    <br />
                    Output: 15
                    <br />
                    Explanation: You will start at index 1.
                    <br />
                    - Pay 15 and climb two steps to reach the top.
                    <br />
                    The total cost is 15.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: cost = [10,15,20]
                    <br />
                    Output: 15
                    <br />
                    Explanation: You will start at index 1.
                    <br />
                    - Pay 15 and climb two steps to reach the top.
                    <br />
                    The total cost is 15.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;== cost.length &lt;= 1000 <br />0 &lt;== cost[i] &lt;==
                999
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} cost
                * @return {number}
                */
               var minCostClimbingStairs = function(cost) {
                   const n = cost.length;
                   for (let i = 2; i < n; ++i)
                     cost[i] += Math.min(cost[i - 1], cost[i - 2]);
                   return Math.min(cost[n - 1], cost[n - 2]); 
               };
               
               console.log(minCostClimbingStairs([1,100,1,1,1,100,1,1,100,1]))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q601: {
        title: "Q747. Largest Number At Least Twice of Others (Q601)",
        content: (
          <Comp
            title="Q747. Largest Number At Least Twice of Others (Q601)"
            content1={
              <>
                You are given an integer array nums where the largest integer is
                unique.
                <br />
                Determine whether the largest element in the array is at least
                twice as much as every other number in the array. If it is,
                return the index of the largest element, or return -1 otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [3,6,1,0]
                    <br />
                    Output: 1<br />
                    Explanation: 6 is the largest integer.
                    <br />
                    For every other number in the array x, 6 is at least twice
                    as big as x.
                    <br /> The index of value 6 is 1, so we return 1.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4]
                    <br />
                    Output: -1
                    <br />
                    Explanation: 4 is less than twice the value of 3, so we
                    return -1.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1]
                    <br />
                    Output: 0<br />
                    Explanation: 1 is trivially at least twice the value as any
                    other number because there are no other numbers.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 50
                <br />
                0 &lt;= nums[i] &lt;= 100
                <br />
                The largest element in nums is unique.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var dominantIndex = function(nums) {
                 let ans = 0;
                 let max = 0;
                 let secondMax = 0;
                 for (let i = 0; i < nums.length; ++i)
                   if (nums[i] > max) {
                     secondMax = max;
                     max = nums[i];
                     ans = i;
                   } else if (nums[i] > secondMax) {
                     secondMax = nums[i];
                   }
                 return max >= 2 * secondMax ? ans : -1; 
               };
               
               console.log(dominantIndex([1]))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q602: {
        title: "Q748. Shortest Completing Word (Q602)",
        content: (
          <Comp
            title="Q748. Shortest Completing Word (Q602)"
            content1={
              <>
                Given a string licensePlate and an array of strings words, find
                the shortest completing word in words.
                <br />
                A completing word is a word that contains all the letters in
                licensePlate. Ignore numbers and spaces in licensePlate, and
                treat letters as case insensitive. If a letter appears more than
                once in licensePlate, then it must appear in the word the same
                number of times or more.
                <br />
                For example, if licensePlate = "aBc 12c", then it contains
                letters 'a', 'b' (ignoring case), and 'c' twice. Possible
                completing words are "abccdef", "caaacab", and "cbca".
                <br />
                Return the shortest completing word in words. It is guaranteed
                an answer exists. If there are multiple shortest completing
                words, return the first one that occurs in words.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: licensePlate = "1s3 PSt", <br /> words =
                    ["step","steps","stripe","stepple"]
                    <br /> Output: "steps" <br />
                    Explanation: licensePlate contains letters 's', 'p', 's'
                    (ignoring case), and 't'.
                    <br /> "step" contains 't' and 'p', but only contains 1 's'.
                    <br /> "steps" contains 't', 'p', and both 's' characters.
                    <br /> "stripe" is missing an 's'.
                    <br /> "stepple" is missing an 's'.
                    <br /> Since "steps" is the only word containing all the
                    letters, that is the answer.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: licensePlate = "1s3 456", <br /> words =
                    ["looks","pest","stew","show"]
                    <br /> Output: "pest"
                    <br /> Explanation: licensePlate only contains the letter
                    's'. All the words contain 's', but among these "pest",
                    "stew", and "show" are shortest. The answer is "pest"
                    because it is the word that appears earliest of the 3.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= licensePlate.length &lt;= 7<br />
                licensePlate contains digits, letters (uppercase or lowercase),
                or space ' '.
                <br /> 1 &lt;= words.length &lt;= 1000
                <br />
                1 &lt;= words[i].length &lt;= 15
                <br />
                words[i] consists of lower case English letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} licensePlate
                * @param {string[]} words
                * @return {string}
                */
               
               function isLetter(str) {
                 return str.length === 1 && str.match(/[a-z]/i);
               }
               
               var shortestCompletingWord = function(licensePlate, words) {
                 let ans = "****************";
                 const count = Array(26).fill(0);
                 for (let c of licensePlate.split(""))
                   if (isLetter(c))
                     ++count[c.toLowerCase().charCodeAt(0) - 97];
                 for (let word of words)
                   if (word.length < ans.length && isComplete(count, getCount(word)))
                   ans = word;
                 return ans;
               };
               
               function isComplete(c1, c2) {
                 for (let i = 0; i < 26; ++i)
                   if (c1[i] > c2[i])
                     return false;
                 return true;
               }
               
               function getCount(word) {
                 const count = Array(26).fill(0);
                 for (let c of word)
                   ++count[c.charCodeAt(0) - 97];
                 return count;
               }
               
               console.log(shortestCompletingWord("1s3 456",["looks","pest","stew","show"]))`,
                output: `pest`,
              },
            }}
          />
        ),
      },
      q603: {
        title: "Q749. Contain Virus (Q603)",
        content: (
          <Comp
            title="Q749. Contain Virus (Q603)"
            content1={
              <>
                A virus is spreading rapidly, and your task is to quarantine the
                infected area by installing walls.
                <br />
                The world is modeled as an m x n binary grid isInfected, where
                isInfected[i][j] == 0 represents uninfected cells, and
                isInfected[i][j] == 1 represents cells contaminated with the
                virus. A wall (and only one wall) can be installed between any
                two 4-directionally adjacent cells, on the shared boundary.
                <br />
                Every night, the virus spreads to all neighboring cells in all
                four directions unless blocked by a wall. Resources are limited.
                Each day, you can install walls around only one region (i.e.,
                the affected area (continuous block of infected cells) that
                threatens the most uninfected cells the following night). There
                will never be a tie.
                <br />
                Return the number of walls used to quarantine all the infected
                regions. If the world will become fully infected, return the
                number of walls used.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode749,
                content: (
                  <>
                    Input: isInfected =
                    [[0,1,0,0,0,0,0,1],[0,1,0,0,0,0,0,1],[0,0,0,0,0,0,0,1],[0,0,0,0,0,0,0,0]]
                    <br /> Output: 10 <br />
                    Explanation: There are 2 contaminated regions. <br />
                    On the first day, add 5 walls to quarantine the viral region
                    on the left. The board after the virus spreads is:
                    <br />
                    On the second day, add 5 walls to quarantine the viral
                    region on the right. The virus is fully contained.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: isInfected = [[1,1,1],[1,0,1],[1,1,1]]
                    <br />
                    Output: 4<br />
                    Explanation: Even though there is only one cell saved, there
                    are 4 walls built. Notice that walls are only built on the
                    shared boundary of two different cells.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: isInfected =
                    [[1,1,1,0,0,0,0,0,0],[1,0,1,0,1,1,1,1,1],[1,1,1,0,0,0,0,0,0]]
                    <br /> Output: 13
                    <br />
                    Explanation: The region on the left only builds two new
                    walls.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == isInfected.length <br />
                n == isInfected[i].length <br />
                1 &lt;= m, n &lt;= 50 <br />
                isInfected[i][j] is either 0 or 1. <br />
                There is always a contiguous viral region throughout the
                described process that will infect strictly more uncontaminated
                squares in the next round.
              </>
            }
            tc="n^4"
            sc="n^4"
            codes={{
              Java: {
                code: `
                // [[0,1,0,0,0,0,0,1],[0,1,0,0,0,0,0,1],[0,0,0,0,0,0,0,1],[0,0,0,0,0,0,0,0]]
                class Region {
                  public Set<Integer> infected = new HashSet<>();
                  public Set<Integer> noninfected = new HashSet<>();
                  public int wallsRequired = 0;
                };
                
                class Solution {
                  public int containVirus(int[][] grid) {
                    final int m = grid.length;
                    final int n = grid[0].length;
                    int ans = 0;
                    while (true) {
                      List<Region> regions = new ArrayList<>();
                      boolean[][] seen = new boolean[m][n];
                      for (int i = 0; i < m; ++i)
                        for (int j = 0; j < n; ++j)
                          if (grid[i][j] == 1 && !seen[i][j]) {
                            Region region = new Region();
                            dfs(grid, i, j, region, seen); 
                            if (!region.noninfected.isEmpty())
                              regions.add(region);
                          }
                      if (regions.isEmpty())
                        break; 
                      Collections.sort(regions, (a, b) -> a.noninfected.size() - b.noninfected.size());
                      Region mostInfectedRegion = regions.get(regions.size() - 1);
                      regions.remove(regions.size() - 1);
                      ans += mostInfectedRegion.wallsRequired;
                      for (final int neighbor : mostInfectedRegion.infected) {
                        final int i = neighbor / n;
                        final int j = neighbor % n;
                        grid[i][j] = 2;
                      }
                
                      for (final Region region : regions)
                        for (final int neighbor : region.noninfected) {
                          final int i = neighbor / n;
                          final int j = neighbor % n;
                          grid[i][j] = 1;
                        }
                    }
                    return ans;
                  }
                
                  private void dfs(int[][] grid, int i, int j, Region region, boolean[][] seen) {
                    if (i < 0 || i == grid.length || j < 0 || j == grid[0].length)
                      return;
                    if (seen[i][j] || grid[i][j] == 2)
                      return;
                    if (grid[i][j] == 0) {
                      region.noninfected.add(i * grid[0].length + j);
                      ++region.wallsRequired;
                      return;
                    }
                    seen[i][j] = true;
                    region.infected.add(i * grid[0].length + j);
                    dfs(grid, i + 1, j, region, seen);
                    dfs(grid, i - 1, j, region, seen);
                    dfs(grid, i, j + 1, region, seen);
                    dfs(grid, i, j - 1, region, seen);
                  }
                }
                `,
                output: `10`,
              },
            }}
          />
        ),
      },
      q604: {
        title: "Q752. Open the Lock (Q604)",
        content: (
          <Comp
            title="Q752. Open the Lock (Q604)"
            content1={
              <>
                You have a lock in front of you with 4 circular wheels. Each
                wheel has 10 slots: '0', '1', '2', '3', '4', '5', '6', '7', '8',
                '9'. The wheels can rotate freely and wrap around: for example
                we can turn '9' to be '0', or '0' to be '9'. Each move consists
                of turning one wheel one slot.
                <br />
                The lock initially starts at '0000', a string representing the
                state of the 4 wheels.
                <br />
                You are given a list of deadends dead ends, meaning if the lock
                displays any of these codes, the wheels of the lock will stop
                turning and you will be unable to open it.
                <br />
                Given a target representing the value of the wheels that will
                unlock the lock, return the minimum total number of turns
                required to open the lock, or -1 if it is impossible.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: deadends = ["0201","0101","0102","1212","2002"],
                    target = "0202"
                    <br /> Output: 6 Explanation: <br />
                    A sequence of valid moves would be "0000" -&gt; "1000" -&gt;
                    "1100" -&gt; "1200" -&gt; "1201" -&gt; "1202" -&gt; "0202".
                    <br /> Note that a sequence like "0000" -&gt; "0001" -&gt;
                    "0002" -&gt; "0102" -&gt; "0202" would be invalid,
                    <br /> because the wheels of the lock become stuck after the
                    display becomes the dead end "0102".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: deadends = ["8888"], target = "0009" <br />
                    Output: 1 <br />
                    Explanation: We can turn the last wheel in reverse to move
                    from "0000" -&gt; "0009".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: deadends =
                    ["8887","8889","8878","8898","8788","8988","7888","9888"],
                    target = "8888"
                    <br /> Output: -1 <br />
                    Explanation: We cannot reach the target without getting
                    stuck.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= deadends.length &lt;= 500 <br />
                deadends[i].length == 4 <br />
                target.length == 4 <br />
                target will not be in the list deadends. target and deadends[i]
                consist of digits only.
              </>
            }
            tc="1"
            sc="1"
            codes={{
              Java: {
                code: `// deadends = ["0201","0101","0102","1212","2002"], target = "0202"
                class Solution {
                  public int openLock(String[] deadends, String target) {
                    Set<String> seen = new HashSet<>(Arrays.asList(deadends));
                    if (seen.contains("0000"))
                      return -1;
                    if (target.equals("0000"))
                      return 0;
                    int ans = 0;
                    Queue<String> q = new LinkedList<>(Arrays.asList("0000"));
                
                    while (!q.isEmpty()) {
                      ++ans;
                      for (int sz = q.size(); sz > 0; --sz) {
                        StringBuilder sb = new StringBuilder(q.poll());
                        for (int i = 0; i < 4; ++i) {
                          final char cache = sb.charAt(i);
                          sb.setCharAt(i, sb.charAt(i) == '9' ? '0' : (char) (sb.charAt(i) + 1));
                          String word = sb.toString();
                          if (word.equals(target))
                            return ans;
                          if (!seen.contains(word)) {
                            q.offer(word);
                            seen.add(word);
                          }
                          sb.setCharAt(i, cache);
                          sb.setCharAt(i, sb.charAt(i) == '0' ? '9' : (char) (sb.charAt(i) - 1));
                          word = sb.toString();
                          if (word.equals(target))
                            return ans;
                          if (!seen.contains(word)) {
                            q.offer(word);
                            seen.add(word);
                          }
                          sb.setCharAt(i, cache);
                        }
                      }
                    }
                    return -1;
                  }
                }
                `,
                output: `6`,
              },
            }}
          />
        ),
      },
      q605: {
        title: "Q753. Cracking the Safe (Q605)",
        content: (
          <Comp
            title="Q753. Cracking the Safe (Q605)"
            content1={
              <>
                There is a safe protected by a password. The password is a
                sequence of n digits where each digit can be in the range [0, k
                - 1].
                <br />
                The safe has a peculiar way of checking the password. When you
                enter in a sequence, it checks the most recent n digits that
                were entered each time you type a digit.
                <br />
                For example, the correct password is "345" and you enter in
                "012345":
                <br />
                After typing 0, the most recent 3 digits is "0", which is
                incorrect.
                <br /> After typing 1, the most recent 3 digits is "01", which
                is incorrect.
                <br /> After typing 2, the most recent 3 digits is "012", which
                is incorrect.
                <br /> After typing 3, the most recent 3 digits is "123", which
                is incorrect.
                <br /> After typing 4, the most recent 3 digits is "234", which
                is incorrect.
                <br /> After typing 5, the most recent 3 digits is "345", which
                is correct and the safe unlocks.
                <br /> Return any string of minimum length that will unlock the
                safe at some point of entering it.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 1, k = 2 <br />
                    Output: "10" <br />
                    Explanation: The password is a single digit, so enter each
                    digit. "01" would also unlock the safe.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2, k = 2 <br />
                    Output: "01100" <br />
                    Explanation: For each possible password: <br />
                    - "00" is typed in starting from the 4th digit. <br />
                    - "01" is typed in starting from the 1st digit. <br />
                    - "10" is typed in starting from the 3rd digit. <br />
                    - "11" is typed in starting from the 2nd digit. <br />
                    Thus "01100" will unlock the safe. "01100", "10011", and
                    "11001" would also unlock the safe.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 4 <br />
                1 &lt;= k &lt;= 10
                <br />1 &lt;= k^n &lt;= 4096
              </>
            }
            tc="k^n"
            sc="k^n"
            codes={{
              Java: {
                code: `
                // n = 2, k = 2
                class Solution {
                  public String crackSafe(int n, int k) {
                    final String allZeros = "0".repeat(n);
                    StringBuilder sb = new StringBuilder(allZeros);
                    dfs((int) Math.pow(k, n), n, k, new HashSet<>(Arrays.asList(allZeros)), sb);
                    return sb.toString();
                  }
                  private boolean dfs(int passwordSize, int n, int k, Set<String> seen, StringBuilder path) {
                    if (seen.size() == passwordSize)
                      return true;
                    StringBuilder prefix = new StringBuilder(path.substring(path.length() - n + 1));
                    for (char c = '0'; c < '0' + k; ++c) {
                      prefix.append(c);
                      final String prefixStr = prefix.toString();
                      if (!seen.contains(prefixStr)) {
                        seen.add(prefixStr);
                        path.append(c);
                        if (dfs(passwordSize, n, k, seen, path))
                          return true;
                        path.deleteCharAt(path.length() - 1);
                        seen.remove(prefixStr);
                      }
                      prefix.deleteCharAt(prefix.length() - 1);
                    }
                    return false;
                  }
                }
                `,
                output: `01100`,
              },
            }}
          />
        ),
      },
      q606: {
        title: "Q754. Reach a Number (Q606)",
        content: (
          <Comp
            title="Q754. Reach a Number (Q606)"
            content1={
              <>
                You are standing at position 0 on an infinite number line. There
                is a destination at position target.
                <br />
                You can make some number of moves numMoves so that:
                <br />
                On each move, you can either go left or right. <br />
                During the ith move (starting from i == 1 to i == numMoves), you
                take i steps in the chosen direction.
                <br />
                Given the integer target, return the minimum number of moves
                required (i.e., the minimum numMoves) to reach the destination.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: target = 2<br />
                    Output: 3<br />
                    Explanation:
                    <br />
                    On the 1st move, we step from 0 to 1 (1 step).
                    <br />
                    On the 2nd move, we step from 1 to -1 (2 steps).
                    <br />
                    On the 3rd move, we step from -1 to 2 (3 steps).
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: target = 3<br />
                    Output: 2<br />
                    Explanation:
                    <br />
                    On the 1st move, we step from 0 to 1 (1 step).
                    <br />
                    On the 2nd move, we step from 1 to 3 (2 steps).
                    <br />
                  </>
                ),
              },
            ]}
            constraints={
              <>
                -10^9 &lt;= target &lt;= 10^9 <br />
                target != 0
              </>
            }
            tc="n^0.5"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} target
                * @return {number}
                */
               var reachNumber = function(target) {
                  const newTarget = Math.abs(target);
                  let ans = 0;
                  let pos = 0;
                  while (pos < newTarget)
                    pos += ++ans;
                  while ((pos - newTarget) % 2 == 1)
                    pos += ++ans;
                  return ans;
               };
               
               console.log(reachNumber(2))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q607: {
        title: "Q756. Pyramid Transition Matrix(Q607)",
        content: (
          <Comp
            title="Q756. Pyramid Transition Matrix(Q607)"
            content1={
              <>
                You are stacking blocks to form a pyramid. Each block has a
                color, which is represented by a single letter. Each row of
                blocks contains one less block than the row beneath it and is
                centered on top.
                <br />
                To make the pyramid aesthetically pleasing, there are only
                specific triangular patterns that are allowed. A triangular
                pattern consists of a single block stacked on top of two blocks.
                The patterns are given as a list of three-letter strings
                allowed, where the first two characters of a pattern represent
                the left and right bottom blocks respectively, and the third
                character is the top block.
                <br />
                For example, "ABC" represents a triangular pattern with a 'C'
                block stacked on top of an 'A' (left) and 'B' (right) block.
                Note that this is different from "BAC" where 'B' is on the left
                bottom and 'A' is on the right bottom.
                <br />
                You start with a bottom row of blocks bottom, given as a single
                string, that you must use as the base of the pyramid.
                <br />
                Given bottom and allowed, return true if you can build the
                pyramid all the way to the top such that every triangular
                pattern in the pyramid is in allowed, or false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode756,
                content: (
                  <>
                    Input: bottom = "BCD", allowed = ["BCC","CDE","CEA","FFF"]
                    Output: true <br />
                    <br />
                    Explanation: The allowed triangular patterns are shown on
                    the right.
                    <br />
                    Starting from the bottom (level 3), we can build "CE" on
                    level 2 and then build "E" on level 1.
                    <br />
                    There are three triangular patterns in the pyramid, which
                    are "BCC", "CDE", and "CEA". All are allowed.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: bottom = "AAAA", allowed =
                    ["AAB","AAC","BCD","BBE","DEF"] Output: false <br />
                    <br />
                    Explanation: The allowed triangular patterns are shown on
                    the right.
                    <br />
                    Starting from the bottom (level 4), there are multiple ways
                    to build level 3, but trying all the possibilites, you will
                    get always stuck before building level 1.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= bottom.length &lt;= 6 <br />
                0 &lt;= allowed.length &lt;= 216 <br />
                allowed[i].length == 3 <br />
                The letters in all input strings are from the set{" "}
                {("A", "B", "C", "D", "E", "F")}.
                <br />
                All the values of allowed are unique.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} bottom
                * @param {string[]} allowed
                * @return {boolean}
                */
              // bottom = "BCD", allowed = ["BCC","CDE","CEA","FFF"]
               var pyramidTransition = function(bottom, allowed) {
                   const set = new Set(allowed);
                   const memo = new Map();
                   const chars = ["A", "B", "C", "D", "E", "F"];                 
                   return topDown(bottom, bottom.length - 1);
                   function topDown(prev, row) {
                       const key = \`$\{prev}#$\{row}\`;                       
                       if (row === 0) return true;
                       if (memo.has(key)) return memo.get(key);
                       let pats = new Set();
                       pats.add("");
                       for (let i = 0; i < row; i++) {
                           const tmp = new Set();               
                           const leftBot = prev.charAt(i);
                           const rightBot = prev.charAt(i + 1);  
                           for (const char of chars) {
                               const triadStr = leftBot + rightBot + char;
                               if (set.has(triadStr)) {
                                   for (const pat of pats) {
                                       tmp.add(pat + char);
                                   }                 
                               }
                           }
                           pats = tmp;
                       }
                       for (const pat of pats) {
                           if (topDown(pat, row - 1)) return true;
                       }
                       memo.set(key, false);
                       return false;
                   }
               };
                `,
                output: `true`,
              },
            }}
          />
        ),
      },
      q608: {
        title: "Q757. Set Intersection Size At Least Two (Q608)",
        content: (
          <Comp
            title="Q757. Set Intersection Size At Least Two (Q608)"
            content1={
              <>
                An integer interval [a, b] (for integers a &lt; b) is a set of
                all consecutive integers from a to b, including a and b.
                <br />
                Find the minimum size of a set S such that for every integer
                interval A in intervals, the intersection of S with A has a size
                of at least two.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: intervals = [[1,3],[1,4],[2,5],[3,5]]
                    <br />
                    Output: 3<br />
                    Explanation: Consider the set S = {"{"}2, 3, 4{"}"}. For
                    each interval, there are at least 2 elements from S in the
                    interval.
                    <br />
                    Also, there isn't a smaller size set that fulfills the above
                    condition.
                    <br />
                    Thus, we output the size of this set, which is 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: intervals = [[1,2],[2,3],[2,4],[4,5]]
                    <br />
                    Output: 5<br />
                    Explanation: An example of a minimum sized set is {"{"}1, 2,
                    3, 4, 5{"}"}.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= intervals.length &lt;= 3000
                <br />
                intervals[i].length == 2<br />0 &lt;= ai &lt; bi &lt;= 108
              </>
            }
            tc="n.log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} intervals
                * @return {number}
                */
               var intersectionSizeTwo = function(intervals) { 
                 let ans = 0;
                 let max = -1;
                 let secondMax = -1;
               
                 intervals = intervals.sort((a, b) => a[1] == b[1] ? b[0] - a[0] : a[1] - b[1]);
                   for (let interval of intervals) {
                     let a = interval[0];
                     let b = interval[1];
                     if (max >= a && secondMax >= a)
                       continue;
                     if (max >= a) {
                       secondMax = max;
                       max = b; 
                       ans += 1;
                     } else {             
                       max = b;           
                       secondMax = b - 1; 
                       ans += 2;
                     }
                   }
                   return ans;    
               };
               
               console.log(intersectionSizeTwo([[1,2],[2,3],[2,4],[4,5]]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q609: {
        title: "Q761. Special Binary String (Q609)",
        content: (
          <Comp
            title="Q761. Special Binary String (Q609)"
            content1={
              <>
                {" "}
                Special binary strings are binary strings with the following two
                properties:
                <br />
                The number of 0's is equal to the number of 1's.
                <br />
                Every prefix of the binary string has at least as many 1's as
                0's.
                <br />
                You are given a special binary string s.
                <br />
                A move consists of choosing two consecutive, non-empty, special
                substrings of s, and swapping them. Two strings are consecutive
                if the last character of the first string is exactly one index
                before the first character of the second string.
                <br />
                Return the lexicographically largest resulting string possible
                after applying the mentioned operations on the string.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "11011000" <br />
                    Output: "11100100" <br />
                    Explanation: The strings "10" [occuring at s[1]] and "1100"
                    [at s[3]] are swapped. This is the lexicographically largest
                    string possible after some number of swaps.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "10" <br />
                    Output: "10"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 50 <br />
                s[i] is either '0' or '1'. <br />s is a special binary string.
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `class Solution {
                  public String makeLargestSpecial(String S) {
                    List<String> specials = new ArrayList<>();
                    int count = 0;
                    for (int i = 0, j = 0; j < S.length(); ++j) {
                      count += S.charAt(j) == '1' ? 1 : -1;
                      if (count == 0) {
                        specials.add("1" + makeLargestSpecial(S.substring(i + 1, j)) + "0");
                        i = j + 1;
                      }
                    }
                    Collections.sort(specials, Collections.reverseOrder());
                    return String.join("", specials);
                  }
                }
                `,
                output: `11100100`,
              },
            }}
          />
        ),
      },
      q610: {
        title: "Q762.Prime Number of Set Bits in Binary Representation  (Q610)",
        content: (
          <Comp
            title="Q762.Prime Number of Set Bits in Binary Representation  (Q610)"
            content1={
              <>
                Given two integers left and right, return the count of numbers
                in the inclusive range [left, right] having a prime number of
                set bits in their binary representation.
                <br />
                Recall that the number of set bits an integer has is the number
                of 1's present when written in binary.
                <br />
                For example, 21 written in binary is 10101, which has 3 set
                bits.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: left = 6, right = 10 <br />
                    Output: 4 <br />
                    Explanation: <br />
                    6 -&gt; 110 (2 set bits, 2 is prime) <br />
                    7 -&gt; 111 (3 set bits, 3 is prime) <br />
                    8 -&gt; 1000 (1 set bit, 1 is not prime) <br />
                    9 -&gt; 1001 (2 set bits, 2 is prime) <br />
                    10 -&gt; 1010 (2 set bits, 2 is prime) <br />4 numbers have
                    a prime number of set bits.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: left = 10, right = 15 <br />
                    Output: 5 <br />
                    Explanation: <br />
                    10 -&gt; 1010 (2 set bits, 2 is prime) <br />
                    11 -&gt; 1011 (3 set bits, 3 is prime) <br />
                    12 -&gt; 1100 (2 set bits, 2 is prime) <br />
                    13 -&gt; 1101 (3 set bits, 3 is prime) <br />
                    14 -&gt; 1110 (3 set bits, 3 is prime) <br />
                    15 -&gt; 1111 (4 set bits, 4 is not prime) <br />5 numbers
                    have a prime number of set bits.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= left &lt;= right &lt;= 10^6 <br />0 &lt;= right - left
                &lt;= 104
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} left
                * @param {number} right
                * @return {number}
                */
               function bitCount (n) {
                 n = n - ((n >> 1) & 0x55555555)
                 n = (n & 0x33333333) + ((n >> 2) & 0x33333333)
                 return ((n + (n >> 4) & 0xF0F0F0F) * 0x1010101) >> 24
               }
               var countPrimeSetBits = function(left, right) {
                 const magic = 665772;
                 let res = 0;
                 for (let n = left; n <= right; ++n)
                 if ((magic & 1 << bitCount(n)) > 0)
                       ++res;
                 return res;    
               };
               console.log(countPrimeSetBits(10,15))
               `,
                output: `5`,
              },
            }}
          />
        ),
      },
      q611: {
        title: "Q763. Partition Labels (Q611)",
        content: (
          <Comp
            title="Q763. Partition Labels (Q611)"
            content1={
              <>
                You are given a string s. We want to partition the string into
                as many parts as possible so that each letter appears in at most
                one part.
                <br />
                Note that the partition is done so that after concatenating all
                the parts in order, the resultant string should be s.
                <br />
                Return a list of integers representing the size of these parts.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "ababcbacadefegdehijhklij" <br />
                    Output: [9,7,8] <br />
                    Explanation: <br />
                    The partition is "ababcbaca", "defegde", "hijhklij". <br />
                    This is a partition so that each letter appears in at most
                    one part.
                    <br /> A partition like "ababcbacadefegde", "hijhklij" is
                    incorrect, because it splits s into less parts.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "eccbbbbdec" <br />
                    Output: [10]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 500
                <br />s consists of lowercase English letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number[]}
                */
               var partitionLabels = function(s) {
                 const res = [];
                 let rightmost = Array(128).fill(0);
                 for (let i = 0; i < s.length; ++i)
                 rightmost[s.charAt(i)] = i;
                 let l = 0; 
                 let r = 0; 
                 for (let i = 0; i < s.length; ++i) {
                 r = Math.max(r, rightmost[s[i]]);
                 if (r == i) {
                   res.push(i - l + 1);
                   l = i + 1;
                 }
                 }
                 return res;  
               };
               
               console.log(partitionLabels("ababcbacadefegdehijhklij"))
               `,
                output: `[ 9, 7, 8 ]`,
              },
            }}
          />
        ),
      },
      q612: {
        title: "Q764. Largest Plus Sign (Q612)",
        content: (
          <Comp
            title="Q764. Largest Plus Sign (Q612)"
            content1={
              <>
                You are given an integer n. You have an n x n binary grid grid
                with all values initially 1's except for some indices given in
                the array mines. The ith element of the array mines is defined
                as mines[i] = [xi, yi] where grid[xi][yi] == 0.
                <br />
                Return the order of the largest axis-aligned plus sign of 1's
                contained in grid. If there is none, return 0.
                <br />
                An axis-aligned plus sign of 1's of order k has some center
                grid[r][c] == 1 along with four arms of length k - 1 going up,
                down, left, and right, and made of 1's. Note that there could be
                0's or 1's beyond the arms of the plus sign, only the relevant
                area of the plus sign is checked for 1's.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode764a,
                content: (
                  <>
                    Input: n = 5, mines = [[4,2]] <br />
                    Output: 2 <br />
                    Explanation: In the above grid, the largest plus sign can
                    only be of order 2. One of them is shown.
                  </>
                ),
              },
              {
                img: Leetcode764b,
                content: (
                  <>
                    Input: n = 1, mines = [[0,0]] <br />
                    Output: 0 <br />
                    Explanation: There is no plus sign, so return 0.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 500 <br />
                1 &lt;= mines.length &lt;= 5000 <br />
                0 &lt;= xi, yi &lt; n <br />
                All the pairs (xi, yi) are unique.
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @param {number[][]} mines
                * @return {number}
                */
               var orderOfLargestPlusSign = function(n, mines) {
                 let grid = Array.from(Array(n),()=>Array(n).fill(n));
                 for (let mine of mines)
                     grid[mine[0]][mine[1]] = 0;
                   for (let i = 0; i < n; ++i) {
                     for (let j = 0, leftToRight = 0; j < n; ++j) {
                       leftToRight = (grid[i][j] == 0 ? 0 : leftToRight + 1);
                       grid[i][j] = Math.min(grid[i][j], leftToRight);
                     }
                     for (let j = n - 1, rightToLeft = 0; j >= 0; --j) {
                       rightToLeft = (grid[i][j] == 0 ? 0 : rightToLeft + 1);
                       grid[i][j] = Math.min(grid[i][j], rightToLeft);
                     }
                     for (let j = 0, upToDown = 0; j < n; ++j) {
                       upToDown = (grid[j][i] == 0 ? 0 : upToDown + 1);
                       grid[j][i] = Math.min(grid[j][i], upToDown);
                     }
                     for (let j = n - 1, downToUp = 0; j >= 0; --j) {
                       downToUp = (grid[j][i] == 0) ? 0 : downToUp + 1;
                       grid[j][i] = Math.min(grid[j][i], downToUp);
                     }
                   }
                   let res = 0;
                   for (let row of grid)
                     res = Math.max(res, Math.max(...row))
                   return res;  
               };
               
               console.log(orderOfLargestPlusSign(1,[[0,0]]))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q613: {
        title: "Q765. Couples Holding Hands  (Q613)",
        content: (
          <Comp
            title="Q765. Couples Holding Hands  (Q613)"
            content1={
              <>
                There are n couples sitting in 2n seats arranged in a row and
                want to hold hands.
                <br />
                The people and seats are represented by an integer array row
                where row[i] is the ID of the person sitting in the ith seat.
                The couples are numbered in order, the first couple being (0,
                1), the second couple being (2, 3), and so on with the last
                couple being (2n - 2, 2n - 1).
                <br />
                Return the minimum number of swaps so that every couple is
                sitting side by side. A swap consists of choosing any two
                people, then they stand up and switch seats.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: row = [0,2,1,3] <br />
                    Output: 1 <br />
                    Explanation: We only need to swap the second (row[1]) and
                    third (row[2]) person.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: row = [3,2,0,1] <br />
                    Output: 0 <br />
                    Explanation: All couples are already seated side by side.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2n == row.length
                <br />
                2 &lt;= n &lt;= 30
                <br />
                n is even.
                <br />
                0 &lt;= row[i] &lt; 2n
                <br />
                All the elements of row are unique.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // [0,2,1,3]
                class UF {
                  public UF(int n) {
                    count = n;
                    id = new int[n];
                    for (int i = 0; i < n; ++i)
                      id[i] = i;
                  }
                  public void union(int u, int v) {
                    final int i = find(u);
                    final int j = find(v);
                    if (i == j)
                      return;
                    id[i] = j;
                    --count;
                  }
                  public int getCount() {
                    return count;
                  }
                  private int count;
                  private int[] id;
                  private int find(int u) {
                    return id[u] == u ? u : (id[u] = find(id[u]));
                  }
                }
                class Solution {
                  public int minSwapsCouples(int[] row) {
                    final int n = row.length / 2;
                    UF uf = new UF(n);
                    for (int i = 0; i < n; ++i) {
                      final int a = row[2 * i];
                      final int b = row[2 * i + 1];
                      uf.union(a / 2, b / 2);
                    }
                    return n - uf.getCount();
                  }
                }`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q614: {
        title: "Q766. Toeplitz Matrix (Q614)",
        content: (
          <Comp
            title="Q766. Toeplitz Matrix (Q614)"
            content1={
              <>
                Given an m x n matrix, return true if the matrix is Toeplitz.
                Otherwise, return false.
                <br />A matrix is Toeplitz if every diagonal from top-left to
                bottom-right has the same elements.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: matrix = [[1,2,3,4],[5,1,2,3],[9,5,1,2]]
                    <br />
                    Output: true
                    <br />
                    Explanation:
                    <br />
                    In the above grid, the diagonals are:
                    <br />
                    "[9]", "[5, 5]", "[1, 1, 1]", "[2, 2, 2]", "[3, 3]", "[4]".
                    <br />
                    In each diagonal all elements are the same, so the answer is
                    True.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: matrix = [[1,2],[2,2]]
                    <br />
                    Output: false
                    <br />
                    Explanation:
                    <br />
                    The diagonal "[1, 2]" has different elements.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == matrix.length <br />
                n == matrix[i].length <br />
                1 &lt;= m, n &lt;= 20 <br />0 &lt;= matrix[i][j] &lt;= 99
              </>
            }
            fp={
              <>
                <b>Follow up:</b> <br />
                1. What if the matrix is stored on disk, and the memory is
                limited such that you can only load at most one row of the
                matrix into the memory at once?
                <br />
                2. What if the matrix is so large that you can only load up a
                partial row into the memory at once?
              </>
            }
            tc="m.n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} matrix
                * @return {boolean}
                */
               var isToeplitzMatrix = function(matrix) {
                 if (matrix.length == 0)
                     return true;
                   const m = matrix.length;
                   const n = matrix[0].length;
                   let buffer = Array(n).fill(0);
                   for (let j = 0; j < n; ++j)
                     buffer[j] = matrix[0][j];
                   for (let i = 1; i < m; ++i) {
                     for (let j = 0; j + 1 < n; ++j)
                       if (buffer[j] != matrix[i][j + 1])
                         return false;
                     buffer = matrix[i];
                   }
                   return true;
               };
               
               console.log(isToeplitzMatrix([[1,2],[2,2]]))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q615: {
        title: "Q767. Reorganize String (Q615)",
        content: (
          <Comp
            title="Q767. Reorganize String (Q615)"
            content1={
              <>
                Given a string s, rearrange the characters of s so that any two
                adjacent characters are not the same.
                <br />
                Return any possible rearrangement of s or return "" if not
                possible.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "aab"
                    <br />
                    Output: "aba"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "aaab"
                    <br />
                    Output: ""
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 500
                <br />s consists of lowercase English letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Java: {
                code: `
                // Input: s = "aab"
                class Solution {
                  public String reorganizeString(String S) {
                    final int n = S.length();
                    int[] count = new int[128];
                    int maxCount = 0;
                    char maxChar = '@';
                    for (final char c : S.toCharArray())
                      ++count[c];
                    for (char c = 'a'; c <= 'z'; ++c)
                      if (count[c] > maxCount) {
                        maxCount = count[c];
                        maxChar = c;
                      }
                
                    if (maxCount > (n + 1) / 2)
                      return "";
                    char[] ans = new char[n];
                    while (count[maxChar]-- > 0)
                      fillIn(ans, maxChar);
                    for (char c = 'a'; c <= 'z'; ++c)
                      while (count[c]-- > 0)
                        fillIn(ans, c);
                
                    return new String(ans);
                  }
                  private int i = 0;
                  private void fillIn(char[] ans, char c) {
                    ans[i] = c;
                    i += 2;
                    if (i >= ans.length) 
                      i = 1;
                  }
                }`,
                output: `aba`,
              },
            }}
          />
        ),
      },
      q616: {
        title: "Q768. Max Chunks To Make Sorted II (Q616)",
        content: (
          <Comp
            title="Q768. Max Chunks To Make Sorted II (Q616)"
            content1={
              <>
                You are given an integer array arr.
                <br />
                We split arr into some number of chunks (i.e., partitions), and
                individually sort each chunk. After concatenating them, the
                result should equal the sorted array.
                <br />
                Return the largest number of chunks we can make to sort the
                array.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [5,4,3,2,1] <br />
                    Output: 1 <br />
                    Explanation: <br />
                    Splitting into two or more chunks will not return the
                    required result. <br />
                    For example, splitting into [5, 4], [3, 2, 1] will result in
                    [4, 5, 1, 2, 3], which isn't sorted.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [2,1,3,4,4] <br />
                    Output: 4 <br />
                    Explanation: <br />
                    We can split into two chunks, such as [2, 1], [3, 4, 4].{" "}
                    <br />
                    However, splitting into [2, 1], [3], [4], [4] is the highest
                    number of chunks possible.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= arr.length &lt;= 2000 <br />0 &lt;= arr[i] &lt;= 108
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} arr
                * @return {number}
                */
               var maxChunksToSorted = function(arr) {
                const n = arr.length;
                let res = 0;
                const maxL = Array(n).fill(0); 
                const minR = Array(n).fill(0); 
                for (let i = 0; i < n; ++i)
                     maxL[i] = i == 0 ? arr[i] : Math.max(arr[i], maxL[i - 1]);
                for (let i = n - 1; i >= 0; --i)
                     minR[i] = i == n - 1 ? arr[i] : Math.min(arr[i], minR[i + 1]);
                for (let i = 0; i + 1 < n; ++i)
                 if (maxL[i] <= minR[i + 1])
                   ++res;
                return res + 1;    
               };
               
               console.log(maxChunksToSorted([5,4,3,2,1]))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q617: {
        title: "Q769. Max Chunks To Make Sorted  (Q617)",
        content: (
          <Comp
            title="Q769. Max Chunks To Make Sorted  (Q617)"
            content1={
              <>
                You are given an integer array arr of length n that represents a
                permutation of the integers in the range [0, n - 1].
                <br />
                We split arr into some number of chunks (i.e., partitions), and
                individually sort each chunk. After concatenating them, the
                result should equal the sorted array.
                <br />
                Return the largest number of chunks we can make to sort the
                array.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [4,3,2,1,0] <br />
                    Output: 1 <br />
                    Explanation: <br />
                    Splitting into two or more chunks will not return the
                    required result. <br />
                    For example, splitting into [4, 3], [2, 1, 0] will result in
                    [3, 4, 0, 1, 2], which isn't{" "}
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [1,0,2,3,4] <br />
                    Output: 4 <br />
                    Explanation: <br />
                    We can split into two chunks, such as [1, 0], [2, 3, 4].{" "}
                    <br />
                    However, splitting into [1, 0], [2], [3], [4] is the highest
                    number of chunks possible.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == arr.length <br />
                1 &lt;= n &lt;= 10 <br />
                0 &lt;= arr[i] &lt; n <br />
                All the elements of arr are unique.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} arr
                * @return {number}
                */
               var maxChunksToSorted = function(arr) {
                 let res = 0;
                 let max = Number.MIN_SAFE_INTEGER;
                 for (let i = 0; i < arr.length; ++i) {
                    max = Math.max(max, arr[i]);
                   if (max == i)
                     ++res;
                 }
                 return res;
               };
               console.log(maxChunksToSorted([5,4,3,2,1]))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q618: {
        title: "Q770. Basic Calculator IV (Q618)",
        content: (
          <Comp
            title="Q770. Basic Calculator IV (Q618)"
            content1={
              <>
                Given an expression such as expression = "e + 8 - a + 5" and an
                evaluation map such as {`{"e": 1}`} (given in terms of evalvars
                = ["e"] and evalints = [1]), return a list of tokens
                representing the simplified expression, such as ["-1*a","14"]
                <br /> <br />
                An expression alternates chunks and symbols, with a space
                separating each chunk and symbol. <br />
                <br /> A chunk is either an expression in parentheses, a
                variable, or a non-negative integer. <br />
                <br /> A variable is a string of lowercase letters (not
                including digits.) Note that variables can be multiple letters,
                and note that variables never have a leading coefficient or
                unary operator like "2x" or "-x". <br />
                <br /> Expressions are evaluated in the usual order: brackets
                first, then multiplication, then addition and subtraction.
                <br />
                For example, expression = "1 + 2 * 3" has an answer of ["7"].
                <br /> The format of the output is as follows:
                <br />
                For each term of free variables with a non-zero coefficient, we
                write the free variables within a term in sorted order
                lexicographically.
                <br /> For example, we would never write a term like "b*a*c",
                only "a*b*c".
                <br /> Terms have degrees equal to the number of free variables
                being multiplied, counting multiplicity. We write the largest
                degree terms of our answer first, breaking ties by lexicographic
                order ignoring the leading coefficient of the term.
                <br /> For example, "a*a*b*c" has degree 4.
                <br /> The leading coefficient of the term is placed directly to
                the left with an asterisk separating it from the variables (if
                they exist.) A leading coefficient of 1 is still printed.
                <br /> An example of a well-formatted answer is ["-2*a*a*a",
                "3*a*a*b", "3*b*b", "4*a", "5*c", "-6"].
                <br /> Terms (including constant terms) with coefficient 0 are
                not included.
                <br /> For example, an expression of "0" has an output of [].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: expression = "e + 8 - a + 5",
                    <br /> evalvars = ["e"], <br /> evalints = [1]
                    <br />
                    Output: ["-1*a","14"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: expression = "e - 8 + temperature - pressure",
                    <br /> evalvars = ["e", "temperature"], evalints = [1, 12]
                    Output: ["-1*pressure","5"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: expression = "(e + 8) * (e - 8)",
                    <br /> evalvars = [],
                    <br /> evalints = [] Output: ["1*e*e","-64"]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &tl;= expression.length &tl;= 250 <br />
                expression consists of lowercase English letters, digits, '+',
                '-', '*', '(', ')', ' '. <br />
                expression does not contain any leading or trailing spaces.{" "}
                <br />
                All the tokens in expression are separated by a single space.{" "}
                <br />
                0 &tl;= evalvars.length &tl;= 100 <br />
                1 &tl;= evalvars[i].length &tl;= 20 <br />
                evalvars[i] consists of lowercase English letters. <br />
                evalints.length == evalvars.length <br />
                -100 &tl;= evalints[i] &tl;= 100
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `
                // Input: expression = "e + 8 - a + 5", evalvars = ["e"], evalints = [1]
                class Poly {
                  public Poly add(Poly o) {
                    for (final String term : o.terms.keySet())
                      terms.merge(term, o.terms.get(term), Integer::sum);
                    return this;
                  }
                
                  public Poly minus(Poly o) {
                    for (final String term : o.terms.keySet())
                      terms.merge(term, -o.terms.get(term), Integer::sum);
                    return this;
                  }
                
                  public Poly mult(Poly o) {
                    Poly res = new Poly();
                    for (final String a : terms.keySet())
                      for (final String b : o.terms.keySet())
                        res.terms.merge(merge(a, b), terms.get(a) * o.terms.get(b), Integer::sum);
                    return res;
                  }
                
                  public List<String> toList() {
                    List<String> res = new ArrayList<>();
                    List<String> keys = new ArrayList<>(terms.keySet());
                    Collections.sort(keys, new Comparator<String>() {
                      @Override
                      public int compare(final String a, final String b) {
                        if (a.equals("1"))
                          return 1;
                        if (b.equals("1"))
                          return -1;
                        String[] as = a.split("\\*");
                        String[] bs = b.split("\\*");
                        return as.length == bs.length ? a.compareTo(b) : bs.length - as.length;
                      }
                    });
                    for (final String key : keys)
                      if (terms.get(key) != 0)
                        res.add(concat(key));
                    return res;
                  }
                
                  public Poly() {}
                  public Poly(final String term, int coef) {
                    terms.put(term, coef);
                  }
                
                  private Map<String, Integer> terms = new HashMap<>();
                
                  private static String merge(final String a, final String b) {
                    if (a.equals("1"))
                      return b;
                    if (b.equals("1"))
                      return a;
                    StringBuilder sb = new StringBuilder();
                    String[] A = a.split("\\*");
                    String[] B = b.split("\\*");
                    int i = 0; 
                    int j = 0;
                    while (i < A.length && j < B.length)
                      if (A[i].compareTo(B[j]) < 0)
                        sb.append("*").append(A[i++]);
                      else
                        sb.append("*").append(B[j++]);
                    while (i < A.length)
                      sb.append("*").append(A[i++]);
                    while (j < B.length)
                      sb.append("*").append(B[j++]);
                    return sb.substring(1).toString();
                  }
                  private String concat(final String term) {
                    if (term.equals("1"))
                      return String.valueOf(terms.get(term));
                    return new StringBuilder().append(terms.get(term)).append('*').append(term).toString();
                  }
                }
                class Solution {
                  public List<String> basicCalculatorIV(String expression, String[] evalvars, int[] evalints) {
                    List<String> tokens = getTokens(expression);
                    Map<String, Integer> evalMap = new HashMap<>();
                    for (int i = 0; i < evalvars.length; ++i)
                      evalMap.put(evalvars[i], evalints[i]);
                    for (int i = 0; i < tokens.size(); ++i)
                      if (evalMap.containsKey(tokens.get(i)))
                        tokens.set(i, String.valueOf(evalMap.get(tokens.get(i))));
                
                    List<String> postfix = infixToPostfix(tokens);
                    return evaluate(postfix).toList();
                  }
                
                  private List<String> getTokens(final String s) {
                    List<String> tokens = new ArrayList<>();
                    int i = 0;
                    for (int j = 0; j < s.length(); ++j)
                      if (s.charAt(j) == ' ') {
                        if (i < j)
                          tokens.add(s.substring(i, j));
                        i = j + 1;
                      } else if ("()+-*".contains(s.substring(j, j + 1))) {
                        if (i < j)
                          tokens.add(s.substring(i, j));
                        tokens.add(s.substring(j, j + 1));
                        i = j + 1;
                      }
                    if (i < s.length())
                      tokens.add(s.substring(i));
                    return tokens;
                  }
                
                  private boolean isOperator(final String token) {
                    return token.equals("+") || token.equals("-") || token.equals("*");
                  }
                
                  private boolean precedes(final String prevOp, final String currOp) {
                    if (prevOp.equals("("))
                      return false;
                    return prevOp.equals("*") || currOp.equals("+") || currOp.equals("-");
                  }
                
                  private List<String> infixToPostfix(List<String> tokens) {
                    List<String> postfix = new ArrayList<>();
                    Deque<String> ops = new ArrayDeque<>();
                
                    for (final String token : tokens)
                      if (token.equals("(")) {
                        ops.push(token);
                      } else if (token.equals(")")) {
                        while (!ops.peek().equals("("))
                          postfix.add(ops.pop());
                        ops.pop();
                      } else if (isOperator(token)) {
                        while (!ops.isEmpty() && precedes(ops.peek(), token))
                          postfix.add(ops.pop());
                        ops.push(token);
                      } else { // isOperand(token)
                        postfix.add(token);
                      }
                
                    while (!ops.isEmpty())
                      postfix.add(ops.pop());
                
                    return postfix;
                  }
                
                  private Poly evaluate(List<String> postfix) {
                    LinkedList<Poly> polys = new LinkedList<>();
                    for (final String token : postfix)
                      if (isOperator(token)) {
                        final Poly b = polys.removeLast();
                        final Poly a = polys.removeLast();
                        if (token.equals("+"))
                          polys.add(a.add(b));
                        else if (token.equals("-"))
                          polys.add(a.minus(b));
                        else 
                          polys.add(a.mult(b));
                      } else if (token.charAt(0) == '-' || token.chars().allMatch(c -> Character.isDigit(c))) {
                        polys.add(new Poly("1", Integer.parseInt(token)));
                      } else {
                        polys.add(new Poly(token, 1));
                      }
                    return polys.getFirst();
                  }
                }`,
                output: `["-1*a","14"]`,
              },
            }}
          />
        ),
      },
      q619: {
        title: "Q771. Jewels and Stones (Q619)",
        content: (
          <Comp
            title="Q771. Jewels and Stones (Q619)"
            content1={
              <>
                <Span>
                  You're given strings jewels representing the types of stones
                  that are jewels, and stones representing the stones you have.
                  Each character in stones is a type of stone you have. You want
                  to know how many of the stones you have are also jewels.
                </Span>
                Letters are case sensitive, so "a" is considered a different
                type of stone from "A".
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: jewels = "aA",
                    <br /> stones = "aAAbbbb"
                    <br />
                    Output: 3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: jewels = "z",
                    <br /> stones = "ZZ"
                    <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= jewels.length, stones.length &lt;= 50 <br />
                jewels and stones consist of only English letters. <br />
                All the characters of jewels are unique.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} jewels
                * @param {string} stones
                * @return {number}
                */
               var numJewelsInStones = function(jewels, stones) {
                 let res = 0;
                 const j = new Set(jewels);
                 for (let s of stones.split(""))
                   if (j.has(s))
                     ++res;
                 return res;    
               };
               console.log(numJewelsInStones("aA","aAAbbbb"))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q620: {
        title: "Q773. Sliding Puzzle (Q620)",
        content: (
          <Comp
            title="Q773. Sliding Puzzle (Q620)"
            content1={
              <>
                On an 2 x 3 board, there are five tiles labeled from 1 to 5, and
                an empty square represented by 0. A move consists of choosing 0
                and a 4-directionally adjacent number and swapping it.
                <br />
                The state of the board is solved if and only if the board is
                [[1,2,3],[4,5,0]].
                <br />
                Given the puzzle board board, return the least number of moves
                required so that the state of the board is solved. If it is
                impossible for the state of the board to be solved, return -1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: board = [[1,2,3],[4,0,5]] <br />
                    Output: 1 <br />
                    Explanation: Swap the 0 and the 5 in one move.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: board = [[1,2,3],[5,4,0]] <br />
                    Output: -1 <br />
                    Explanation: No number of moves will make the board solved.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: board = [[4,1,2],[5,0,3]] <br />
                    Output: 5 <br />
                    Explanation: 5 is the smallest number of moves that solves
                    the board. <br />
                    An example path: <br />
                    After move 0: [[4,1,2],[5,0,3]] <br />
                    After move 1: [[4,1,2],[0,5,3]] <br />
                    After move 2: [[0,1,2],[4,5,3]] <br />
                    After move 3: [[1,0,2],[4,5,3]] <br />
                    After move 4: [[1,2,0],[4,5,3]] <br />
                    After move 5: [[1,2,3],[4,5,0]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                board.length == 2<br />
                board[i].length == 3<br />
                0 &lt;= board[i][j] &lt;= 5<br />
                Each value board[i][j] is unique.
              </>
            }
            tc="(m.n)!"
            sc="(m.n)!"
            codes={{
              Java: {
                code: `
                // board = [[1,2,3],[4,0,5]]
                class Solution {
                  public int slidingPuzzle(int[][] board) {
                    final int m = 2;
                    final int n = 3;
                    final int[] dirs = {0, 1, 0, -1, 0};
                    final String goal = "123450";
                    int steps = 0;
                    StringBuilder startSb = new StringBuilder();
                
                    for (int i = 0; i < m; ++i)
                      for (int j = 0; j < n; ++j)
                        startSb.append((char) ('0' + board[i][j]));
                
                    final String start = startSb.toString();
                
                    if (start.equals(goal))
                      return 0;
                
                    Queue<String> q = new LinkedList<>(Arrays.asList(start));
                    Set<String> seen = new HashSet<>(Arrays.asList(start));
                
                    while (!q.isEmpty()) {
                      ++steps;
                      for (int sz = q.size(); sz > 0; --sz) {
                        final String s = q.poll();
                        final int zeroIndex = s.indexOf("0");
                        final int i = zeroIndex / n;
                        final int j = zeroIndex % n;
                        for (int k = 0; k < 4; ++k) {
                          final int x = i + dirs[k];
                          final int y = j + dirs[k + 1];
                          if (x < 0 || x == m || y < 0 || y == n)
                            continue;
                          final int swappedIndex = x * n + y;
                          StringBuilder sb = new StringBuilder(s);
                          sb.setCharAt(zeroIndex, s.charAt(swappedIndex));
                          sb.setCharAt(swappedIndex, s.charAt(zeroIndex));
                          final String t = sb.toString();
                          if (t.equals(goal))
                            return steps;
                          if (!seen.contains(t)) {
                            q.offer(t);
                            seen.add(t);
                          }
                        }
                      }
                    }
                
                    return -1;
                  }
                }`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q621: {
        title: "Q775. Global and Local Inversions (Q621)",
        content: (
          <Comp
            title="Q775. Global and Local Inversions (Q621)"
            content1={
              <>
                You are given an integer array nums of length n which represents
                a permutation of all the integers in the range [0, n - 1].
                <br />
                <br />
                The number of global inversions is the number of the different
                pairs (i, j) where:
                <br />
                <br />
                0 &lt;= i &lt; &lt; &lt; n<br />
                nums[i] &gt; nums[j]
                <br />
                The number of local inversions is the number of indices i where:
                <br />
                <br />
                0 &lt;= i &lt; n - 1
                <br />
                nums[i] &gt; nums[i + 1]
                <br />
                Return true if the number of global inversions is equal to the
                number of local inversions.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,0,2] <br />
                    Output: true <br />
                    Explanation: There is 1 global inversion and 1 local
                    inversion.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,0] <br /> Output: false <br />
                    Explanation: There are 2 global inversions and 1 local
                    inversion.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == nums.length
                <br />
                1 &lt;= n &lt;= 10^5
                <br />
                0 &lt;= nums[i] &lt; n<br />
                All the integers of nums are unique.
                <br />
                nums is a permutation of all the numbers in the range [0, n -
                1].
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {boolean}
                */
               var isIdealPermutation = function(nums) {
                 for (let i = 0; i < nums.length; ++i)
                   if (Math.abs(nums[i] - i) > 1)
                     return false;
                 return true;    
               };
               
               console.log(isIdealPermutation([1,2,0]))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q622: {
        title: "Q777. Swap Adjacent in LR String (Q622)",
        content: (
          <Comp
            title="Q777. Swap Adjacent in LR String (Q622)"
            content1={
              <>
                In a string composed of 'L', 'R', and 'X' characters, like
                "RXXLRXRXL", a move consists of either replacing one occurrence
                of "XL" with "LX", or replacing one occurrence of "RX" with
                "XR". Given the starting string start and the ending string end,
                return True if and only if there exists a sequence of moves to
                transform one string to the other.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: start = "RXXLRXRXL",
                    <br /> end = "XRLXXRRLX" <br />
                    Output: true
                    <br />
                    Explanation: We can transform start to end following these
                    steps:
                    <br />
                    RXXLRXRXL -&gt;
                    <br />
                    XRXLRXRXL -&gt;
                    <br />
                    XRLXRXRXL -&gt;
                    <br />
                    XRLXXRRXL -&gt;
                    <br />
                    XRLXXRRLX
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: start = "X", <br />
                    end = "L"
                    <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= start.length &lt;= 10^4
                <br />
                start.length == end.length
                <br />
                Both start and end will only consist of characters in 'L', 'R',
                and 'X'.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Java: {
                code: `
                // Input: start = "RXXLRXRXL", end = "XRLXXRRLX"
                class Solution {
                  public boolean canTransform(String start, String end) {
                    if (!start.replace("X", "").equals(end.replace("X", "")))
                      return false;
                    int i = 0; 
                    int j = 0; 
                    while (i < start.length() && j < end.length()) {
                      while (i < start.length() && start.charAt(i) == 'X')
                        ++i;
                      while (j < end.length() && end.charAt(j) == 'X')
                        ++j;
                      if (i == start.length() && j == end.length())
                        return true;
                      if (i == start.length() || j == end.length())
                        return false;
                      if (start.charAt(i) == 'L' && i < j)
                        return false;
                      if (start.charAt(i) == 'R' && i > j)
                        return false;
                      ++i;
                      ++j;
                    }
                    return true;
                  }
                }
                `,
                output: `true`,
              },
            }}
          />
        ),
      },
      q623: {
        title: "Q778. Swim in Rising Water (Q62)",
        content: (
          <Comp
            title="Q778. Swim in Rising Water (Q62)"
            content1={
              <>
                You are given an n x n integer matrix grid where each value
                grid[i][j] represents the elevation at that point (i, j).
                <br />
                The rain starts to fall. At time t, the depth of the water
                everywhere is t. You can swim from a square to another
                4-directionally adjacent square if and only if the elevation of
                both squares individually are at most t. You can swim infinite
                distances in zero time. Of course, you must stay within the
                boundaries of the grid during your swim.
                <br />
                Return the least time until you can reach the bottom right
                square (n - 1, n - 1) if you start at the top left square (0,
                0).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: grid = [[0,2],[1,3]] <br />
                    Output: 3<br />
                    Explanation:
                    <br />
                    At time 0, you are in grid location (0, 0).
                    <br />
                    You cannot go anywhere else because 4-directionally adjacent
                    neighbors have a higher elevation than t = 0.
                    <br />
                    You cannot reach point (1, 1) until time 3.
                    <br />
                    When the depth of water is 3, we can swim anywhere inside
                    the grid.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid =
                    [[0,1,2,3,4],[24,23,22,21,5],[12,13,14,15,16],[11,17,18,19,20],[10,9,8,7,6]]
                    <br /> Output: 16 <br />
                    Explanation: The final route is shown. <br />
                    We need to wait until time 16 so that (0, 0) and (4, 4) are
                    connected.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == grid.length <br />
                n == grid[i].length <br />
                1 &lt;= n &lt;= 50 <br />
                0 &lt;= grid[i][j] &lt; n2 <br />
                Each value grid[i][j] is unique.
              </>
            }
            tc="m.n log m.n"
            sc="m.n"
            codes={{
              Java: {
                code: `
                // grid = [[0,1,2,3,4],[24,23,22,21,5],[12,13,14,15,16],[11,17,18,19,20],[10,9,8,7,6]]
                class T {
                  public int i;
                  public int j;
                  public int height; // grid[i][j]
                  public T(int i, int j, int height) {
                    this.i = i;
                    this.j = j;
                    this.height = height;
                  }
                }
                class Solution {
                  public int swimInWater(int[][] grid) {
                    final int n = grid.length;
                    final int[] dirs = {0, 1, 0, -1, 0};
                    int ans = grid[0][0];
                    PriorityQueue<T> minHeap = new PriorityQueue<>((a, b) -> a.height - b.height);
                    boolean[][] seen = new boolean[n][n];
                    minHeap.offer(new T(0, 0, grid[0][0]));
                    seen[0][0] = true;
                    while (!minHeap.isEmpty()) {
                      final int i = minHeap.peek().i;
                      final int j = minHeap.peek().j;
                      final int height = minHeap.poll().height;
                      ans = Math.max(ans, height);
                      if (i == n - 1 && j == n - 1)
                        break;
                      for (int k = 0; k < 4; ++k) {
                        final int x = i + dirs[k];
                        final int y = j + dirs[k + 1];
                        if (x < 0 || x == n || y < 0 || y == n)
                          continue;
                        if (seen[x][y])
                          continue;
                        minHeap.offer(new T(x, y, grid[x][y]));
                        seen[x][y] = true;
                      }
                    }
                    return ans;
                  }
                }`,
                output: `16`,
              },
            }}
          />
        ),
      },
      q624: {
        title: "Q779. K-th Symbol in Grammar (Q624)",
        content: (
          <Comp
            title="Q779. K-th Symbol in Grammar (Q624)"
            content1={
              <>
                We build a table of n rows (1-indexed). We start by writing 0 in
                the 1st row. Now in every subsequent row, we look at the
                previous row and replace each occurrence of 0 with 01, and each
                occurrence of 1 with 10.
                <br />
                For example, for n = 3, the 1st row is 0, the 2nd row is 01, and
                the 3rd row is 0110.
                <br />
                Given two integer n and k, return the kth (1-indexed) symbol in
                the nth row of a table of n rows.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 1, k = 1 <br />
                    Output: 0 <br />
                    Explanation: row 1: 0
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2, k = 1 <br />
                    Output: 0 <br />
                    Explanation: <br />
                    row 1: 0 <br />
                    row 2: 01
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2, k = 2 <br />
                    Output: 1 <br />
                    Explanation: <br />
                    row 1: 0 <br />
                    row 2: 01
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 30 <br />1 &lt;= k &lt;= 2n - 1
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @param {number} k
                * @return {number}
                */
               var kthGrammar = function(n, k) {
                 if (n == 1)
                   return 0;
                 if (k % 2 == 1)
                   return kthGrammar(n - 1, parseInt(k + 1) / 2) == 0 ? 0 : 1; 
                   return kthGrammar(n - 1, parseInt(k / 2)) == 0 ? 1 : 0;        
               };
               console.log(kthGrammar(2,1))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q625: {
        title: "Q780. Reaching Points (Q625)",
        content: (
          <Comp
            title="Q780. Reaching Points (Q625)"
            content1={
              <>
                Given four integers sx, sy, tx, and ty, return true if it is
                possible to convert the point (sx, sy) to the point (tx, ty)
                through some operations, or false otherwise.
                <br />
                The allowed operation on some point (x, y) is to convert it to
                either (x, x + y) or (x + y, y).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: sx = 1, sy = 1, tx = 3, ty = 5 <br />
                    Output: true <br />
                    Explanation: <br />
                    One series of moves that transforms the starting point to
                    the target is: <br />
                    (1, 1) -&gt; (1, 2) <br />
                    (1, 2) -&gt; (3, 2) <br />
                    (3, 2) -&gt; (3, 5)
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: sx = 1, sy = 1, tx = 2, ty = 2 <br />
                    Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: sx = 1, sy = 1, tx = 1, ty = 1 <br />
                    Output: true
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= sx, sy, tx, ty &lt;= 10^9</>}
            tc="log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} sx
                * @param {number} sy
                * @param {number} tx
                * @param {number} ty
                * @return {boolean}
                */
               var reachingPoints = function(sx, sy, tx, ty) {
                  while (sx < tx && sy < ty)
                     if (tx > ty)
                       tx %= ty;
                     else
                       ty %= tx;
               
                   return sx == tx && sy <= ty && (ty - sy) % tx == 0 ||
                          sy == ty && sx <= tx && (tx - sx) % ty == 0;  
               };
               
               console.log(reachingPoints(1,1,2,2))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q626: {
        title: "Q781. Rabbits in Forest  (Q626)",
        content: (
          <Comp
            title="Q781. Rabbits in Forest  (Q626)"
            content1={
              <>
                There is a forest with an unknown number of rabbits. We asked n
                rabbits "How many rabbits have the same color as you?" and
                collected the answers in an integer array answers where
                answers[i] is the answer of the ith rabbit.
                <br />
                Given the array answers, return the minimum number of rabbits
                that could be in the forest.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: answers = [1,1,2] <br />
                    Output: 5 <br />
                    Explanation: <br />
                    The two rabbits that answered "1" could both be the same
                    color, say red. <br />
                    The rabbit that answered "2" can't be red or the answers
                    would be inconsistent. <br />
                    Say the rabbit that answered "2" was blue. <br />
                    Then there should be 2 other blue rabbits in the forest that
                    didn't answer into the array.
                    <br /> The smallest possible number of rabbits in the forest
                    is therefore 5: 3 that answered plus 2 that didn't.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: answers = [10,10,10] <br />
                    Output: 11
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= answers.length &lt;= 1000
                <br />0 &lt;= answers[i] &lt; 1000
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} answers
                * @return {number}
                */
               var numRabbits = function(answers) {
                 let res = 0;
                 const count = Array(1000).fill(0);
               
                 for (let answer of answers) {
                   if (count[answer] % (answer + 1) == 0)
                     res += answer + 1;
                     ++count[answer];
                   }
                 return res;    
               };
               
               console.log(numRabbits([10,10,10]))`,
                output: `11`,
              },
            }}
          />
        ),
      },
      q627: {
        title: "Q782. Transform to Chessboard  (Q627)",
        content: (
          <Comp
            title="Q782. Transform to Chessboard  (Q627)"
            content1={
              <>
                You are given an n x n binary grid board. In each move, you can
                swap any two rows with each other, or any two columns with each
                other.
                <br />
                Return the minimum number of moves to transform the board into a
                chessboard board. If the task is impossible, return -1.
                <br />A chessboard board is a board where no 0's and no 1's are
                4-directionally adjacent.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode782,
                content: (
                  <>
                    Input: board = [[0,1,1,0],[0,1,1,0],[1,0,0,1],[1,0,0,1]]
                    <br />
                    Output: 2<br />
                    Explanation: One potential sequence of moves is shown.
                    <br />
                    The first move swaps the first and second column.
                    <br />
                    The second move swaps the second and third row.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: board = [[0,1],[1,0]]
                    <br />
                    Output: 0<br />
                    Explanation: Also note that the board with 0 in the top left
                    corner, is also a valid chessboard.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: board = [[1,0],[1,0]]
                    <br />
                    Output: -1
                    <br />
                    Explanation: No matter what sequence of moves you make, you
                    cannot end with a valid chessboard.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == board.length
                <br />
                n == board[i].length
                <br />
                2 &lt;= n &lt;= 30
                <br />
                board[i][j] is either 0 or 1.
              </>
            }
            tc="n^2"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} board
                * @return {number}
                */
               var movesToChessboard = function(board) {
                   const n = board.length;
                   let rowSum = 0;
                   let colSum = 0;
                   let rowSwaps = 0;
                   let colSwaps = 0;
                   for (let i = 0; i < n; ++i)
                     for (let j = 0; j < n; ++j)
                       if ((board[0][0] ^ board[i][0] ^ board[0][j] ^ board[i][j]) == 1)
                         return -1;
                   for (let i = 0; i < n; ++i) {
                     rowSum += board[0][i];
                     colSum += board[i][0];
                   }
                   if (rowSum != parseInt(n / 2) && rowSum != parseInt((n + 1) / 2))
                     return -1;
                   if (colSum != parseInt(n / 2) && colSum != parseInt((n + 1) / 2))
                     return -1;
                   for (let i = 0; i < n; ++i) {
                     if (board[i][0] == (i & 1))
                       ++rowSwaps;
                     if (board[0][i] == (i & 1))
                       ++colSwaps;
                   }
                   if (n % 2 == 1) {
                     if (rowSwaps % 2 == 1)
                       rowSwaps = n - rowSwaps;
                     if (colSwaps % 2 == 1)
                       colSwaps = n - colSwaps;
                   } else {
                     rowSwaps = Math.min(rowSwaps, n - rowSwaps);
                     colSwaps = Math.min(colSwaps, n - colSwaps);
                   }
                   return parseInt((rowSwaps + colSwaps) / 2); 
               };
               
               console.log(movesToChessboard([[1,0],[1,0]]))`,
                output: `-1`,
              },
            }}
          />
        ),
      },
      q628: {
        title: "Q783. Minimum Distance Between BST Nodes (Q628)",
        content: (
          <Comp
            title="Q783. Minimum Distance Between BST Nodes (Q628)"
            content1={
              <>
                Given the root of a Binary Search Tree (BST), return the minimum
                difference between the values of any two different nodes in the
                tree.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode783,
                content: (
                  <>
                    Input: root = [4,2,6,1,3] <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1,0,48,null,null,12,49]
                    <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [2, 100].
                <br />0 &lt;= Node.val &lt;= 105
              </>
            }
            fp={
              <>
                <b>Note: </b>
                This question is the same as 530:
                https://leetcode.com/problems/minimum-absolute-difference-in-bst/
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // root = [4,2,6,1,3]
                /**
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode() {}
                *     TreeNode(int val) { this.val = val; }
                *     TreeNode(int val, TreeNode left, TreeNode right) {
                *         this.val = val;
                *         this.left = left;
                *         this.right = right;
                *     }
                * }
                */
               class Solution {
                 public int minDiffInBST(TreeNode root) {
                   inorder(root);
                   return ans;
                 }
               
                 private int ans = Integer.MAX_VALUE;
                 private Integer pred = null;
               
                 private void inorder(TreeNode root) {
                   if (root == null)
                     return;
               
                   inorder(root.left);
                   if (pred != null)
                     ans = Math.min(ans, root.val - pred);
                   pred = root.val;
                   inorder(root.right);
                 }
               }`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q629: {
        title: "Q784. Letter Case Permutation (Q629)",
        content: (
          <Comp
            title="Q784. Letter Case Permutation (Q629)"
            content1={
              <>
                Given a string s, you can transform every letter individually to
                be lowercase or uppercase to create another string.
                <br />
                Return a list of all possible strings we could create. Return
                the output in any order.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "a1b2" <br />
                    Output: ["a1b2","a1B2","A1b2","A1B2"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "3z4" <br />
                    Output: ["3z4","3Z4"]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 12 <br />s consists of lowercase English
                letters, uppercase English letters, and digits.
              </>
            }
            tc="2^n"
            sc="2^n"
            codes={{
              Java: {
                code: `
                // "a1b2"
                class Solution {
                  public List<String> letterCasePermutation(String S) {
                    List<String> ans = new ArrayList<>();
                    dfs(new StringBuilder(S), 0, ans);
                    return ans;
                  }
                  private void dfs(StringBuilder sb, int i, List<String> ans) {
                    if (i == sb.length()) {
                      ans.add(sb.toString());
                      return;
                    }
                    if (Character.isDigit(sb.charAt(i))) {
                      dfs(sb, i + 1, ans);
                      return;
                    }
                    sb.setCharAt(i, Character.toLowerCase(sb.charAt(i)));
                    dfs(sb, i + 1, ans);
                    sb.setCharAt(i, Character.toUpperCase(sb.charAt(i)));
                    dfs(sb, i + 1, ans);
                  }
                }
                `,
                output: `["a1b2","a1B2","A1b2","A1B2"]`,
              },
            }}
          />
        ),
      },
      q630: {
        title: "Q785. Is Graph Bipartite? (Q630)",
        content: (
          <Comp
            title="Q785. Is Graph Bipartite? (Q630)"
            content1={
              <>
                There is an undirected graph with n nodes, where each node is
                numbered between 0 and n - 1. You are given a 2D array graph,
                where graph[u] is an array of nodes that node u is adjacent to.
                More formally, for each v in graph[u], there is an undirected
                edge between node u and node v. The graph has the following
                properties:
                <br />
                There are no self-edges (graph[u] does not contain u).
                <br />
                There are no parallel edges (graph[u] does not contain duplicate
                values).
                <br />
                If v is in graph[u], then u is in graph[v] (the graph is
                undirected).
                <br />
                The graph may not be connected, meaning there may be two nodes u
                and v such that there is no path between them.
                <br />
                A graph is bipartite if the nodes can be partitioned into two
                independent sets A and B such that every edge in the graph
                connects a node in set A and a node in set B.
                <br />
                Return true if and only if it is bipartite.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode785,
                content: (
                  <>
                    Input: graph = [[1,2,3],[0,2],[0,1,3],[0,2]] <br />
                    Output: false <br />
                    Explanation: There is no way to partition the nodes into two
                    independent sets such that every edge connects a node in one
                    and a node in the other.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: graph = [[1,3],[0,2],[1,3],[0,2]] <br />
                    Output: true <br />
                    Explanation: We can partition the nodes into two sets: {"{"}
                    0, 2{"}"} and {"{"}1, 3{"}"}.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                graph.length == n <br />
                1 &lt;= n &lt;= 100 <br />
                0 &lt;= graph[u].length &lt; n <br />
                0 &lt;= graph[u][i] &lt;= n - 1 <br />
                graph[u] does not contain u. <br />
                All the values of graph[u] are unique. <br />
                If graph[u] contains v, then graph[v] contains u.
              </>
            }
            tc="v+e"
            sc="v"
            codes={{
              Java: {
                code: `enum Color { WHITE, RED, GREEN }

                class Solution {
                  public boolean isBipartite(int[][] graph) {
                    Color[] colors = new Color[graph.length];
                    Arrays.fill(colors, Color.WHITE);
                    for (int i = 0; i < graph.length; ++i)
                      if (colors[i] == Color.WHITE && !isValidColor(graph, i, colors, Color.RED))
                        return false;
                    return true;
                  }
                
                  private boolean isValidColor(int[][] graph, int u, Color[] colors, Color color) {
                    if (colors[u] != Color.WHITE)
                      return colors[u] == color;
                    colors[u] = color; 
                    for (final int v : graph[u])
                      if (!isValidColor(graph, v, colors, color == Color.RED ? Color.GREEN : Color.RED))
                        return false;
                    return true;
                  }
                }`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q631: {
        title: "Q786. K-th Smallest Prime Fraction (Q631)",
        content: (
          <Comp
            title="Q786. K-th Smallest Prime Fraction (Q631)"
            content1={
              <>
                You are given a sorted integer array arr containing 1 and prime
                numbers, where all the integers of arr are unique. You are also
                given an integer k.
                <br />
                For every i and j where 0 &lt;= i &lt; j &lt; arr.length, we
                consider the fraction arr[i] / arr[j].
                <br />
                Return the kth smallest fraction considered. Return your answer
                as an array of integers of size 2, where answer[0] == arr[i] and
                answer[1] == arr[j].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [1,2,3,5], k = 3 <br />
                    Output: [2,5] <br />
                    Explanation: The fractions to be considered in sorted order
                    are:
                    <br /> 1/5, 1/3, 2/5, 1/2, 3/5, and 2/3. <br />
                    The third fraction is 2/5.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [1,7], k = 1 <br />
                    Output: [1,7]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= arr.length &lt;= 1000 <br />
                1 &lt;= arr[i] &lt;= 3 * 104 <br />
                arr[0] == 1 <br />
                arr[i] is a prime number for i &gt; 0. <br />
                All the numbers of arr are unique and sorted in strictly
                increasing order.
                <br /> 1 &lt;= k &lt;= arr.length * (arr.length - 1) / 2
              </>
            }
            tc="n log max^2 arr"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} arr
                * @param {number} k
                * @return {number[]}
                */
               var kthSmallestPrimeFraction = function(arr, k) {
                   const n = arr.length;
                   let l = 0.0;
                   let r = 1.0;
                   while (l < r) {
                     const m = (l + r) / 2.0;
                     let fractionsNoGreaterThanM = 0;
                     let p = 0;
                     let q = 1;
                     for (let i = 0, j = 1; i < n; ++i) {
                       while (j < n && arr[i] > m * arr[j])
                         ++j;
                       if (j == n)
                         break;
                       fractionsNoGreaterThanM += n - j;
                       if (p * arr[j] < q * arr[i]) {
                         p = arr[i];
                         q = arr[j];
                       }
                     }
               
                     if (fractionsNoGreaterThanM == k)
                       return [p, q];
                     if (fractionsNoGreaterThanM > k)
                       r = m;
                     else
                       l = m;
                   }
                   
               };
               
               console.log(kthSmallestPrimeFraction([1,7],1))`,
                output: `[ 1, 7 ]`,
              },
            }}
          />
        ),
      },
      q632: {
        title: "Q787. Cheapest Flights Within K Stops (Q632)",
        content: (
          <Comp
            title="Q787. Cheapest Flights Within K Stops (Q632)"
            content1={
              <>
                There are n cities connected by some number of flights. You are
                given an array flights where flights[i] = [fromi, toi, pricei]
                indicates that there is a flight from city fromi to city toi
                with cost pricei.
                <br />
                You are also given three integers src, dst, and k, return the
                cheapest price from src to dst with at most k stops. If there is
                no such route, return -1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 3, flights = [[0,1,100],[1,2,100],[0,2,500]], src
                    = 0, dst = 2, k = 1
                    <br /> Output: 200 <br />
                    Explanation: The graph is shown. <br />
                    The cheapest price from city 0 to city 2 with at most 1 stop
                    costs 200, as marked red in the picture.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 3, flights = [[0,1,100],[1,2,100],[0,2,500]], src
                    = 0, dst = 2, k = 0<br />
                    Output: 500
                    <br />
                    Explanation: The graph is shown.
                    <br />
                    The cheapest price from city 0 to city 2 with at most 0 stop
                    costs 500, as marked blue in the picture.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 100 <br />
                0 &lt;= flights.length &lt;= (n * (n - 1) / 2) <br />
                flights[i].length == 3 <br />
                0 &lt;= fromi, toi &lt; n <br />
                fromi != toi <br />
                1 &lt;= pricei &lt;= 104 <br />
                There will not be any multiple flights between two cities.
                <br /> 0 &lt;= src, dst, k &lt; n <br />
                src != dst
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: n = 3, flights = [[0,1,100],[1,2,100],[0,2,500]], src = 0, dst = 2, k = 0
                class T {
                  public int u;
                  public int d;
                  public int stops;
                  public T(int u, int d, int stops) {
                    this.u = u;
                    this.d = d;
                    this.stops = stops;
                  }
                } 
                class Solution {
                  public int findCheapestPrice(int n, int[][] flights, int src, int dst, int k) {
                    List<Pair<Integer, Integer>>[] graph = new List[n];
                    PriorityQueue<T> minHeap = new PriorityQueue<>((a, b) -> a.d - b.d);
                    int[][] dist = new int[n][k + 2];
                    Arrays.stream(dist).forEach(A -> Arrays.fill(A, Integer.MAX_VALUE));
                    for (int i = 0; i < n; ++i)
                      graph[i] = new ArrayList<>();
                    for (int[] f : flights) {
                      final int u = f[0];
                      final int v = f[1];
                      final int w = f[2];
                      graph[u].add(new Pair<>(v, w));
                    }
                    minHeap.offer(new T(src, 0, k + 1));
                    dist[src][k + 1] = 0;
                    while (!minHeap.isEmpty()) {
                      final int u = minHeap.peek().u;
                      final int d = minHeap.peek().d;
                      final int stops = minHeap.poll().stops;
                      if (u == dst)
                        return d;
                      if (stops > 0)
                        for (var node : graph[u]) {
                          final int v = node.getKey();
                          final int w = node.getValue();
                          final int newDist = d + w;
                          if (newDist < dist[v][stops - 1]) {
                            dist[v][stops - 1] = newDist;
                            minHeap.offer(new T(v, d + w, stops - 1));
                          }
                        }
                    }
                    return -1;
                  }
                }`,
                output: `500`,
              },
            }}
          />
        ),
      },
      q633: {
        title: "Q788. Rotated Digits (Q633)",
        content: (
          <Comp
            title="Q788. Rotated Digits (Q633)"
            content1={
              <>
                An integer x is a good if after rotating each digit individually
                by 180 degrees, we get a valid number that is different from x.
                Each digit must be rotated - we cannot choose to leave it alone.
                <br />
                A number is valid if each digit remains a digit after rotation.
                For example:
                <br />
                0, 1, and 8 rotate to themselves,
                <br />
                2 and 5 rotate to each other (in this case they are rotated in a
                different direction, in other words, 2 or 5 gets mirrored),
                <br />
                6 and 9 rotate to each other, and
                <br />
                the rest of the numbers do not rotate to any other number and
                become invalid.
                <br />
                Given an integer n, return the number of good integers in the
                range [1, n].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 10 <br />
                    Output: 4 <br />
                    Explanation: There are four good numbers in the range [1,
                    10] : 2, 5, 6, 9. Note that 1 and 10 are not good numbers,
                    since they remain unchanged after rotating.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1 <br />
                    Output: 0
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2 <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 10^4</>}
            tc="n.log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               var rotatedDigits = function(n) {
                 let res = 0;
                 for (let i = 1; i <= n; ++i)
                   if (isGoodNumber(i))
                     ++res;
                 return res;
               };
               
               function isGoodNumber(i) {
                   let isRotated = false;
                   for (let c of String(i)) {
                     if (c == '0' || c == '1' || c == '8')
                       continue;
                     if (c == '2' || c == '5' || c == '6' || c == '9')
                       isRotated = true;
                     else
                       return false;
                   }
                   return isRotated;
               }
               
               console.log(rotatedDigits(1))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q634: {
        title: "Q789. Escape The Ghosts (Q634)",
        content: (
          <Comp
            title="Q789. Escape The Ghosts (Q634)"
            content1={
              <>
                You are playing a simplified PAC-MAN game on an infinite 2-D
                grid. You start at the point [0, 0], and you are given a
                destination point target = [xtarget, ytarget] that you are
                trying to get to. There are several ghosts on the map with their
                starting positions given as a 2D array ghosts, where ghosts[i] =
                [xi, yi] represents the starting position of the ith ghost. All
                inputs are integral coordinates.
                <br />
                Each turn, you and all the ghosts may independently choose to
                either move 1 unit in any of the four cardinal directions:
                north, east, south, or west, or stay still. All actions happen
                simultaneously.
                <br />
                You escape if and only if you can reach the target before any
                ghost reaches you. If you reach any square (including the
                target) at the same time as a ghost, it does not count as an
                escape.
                <br />
                Return true if it is possible to escape regardless of how the
                ghosts move, otherwise return false.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: ghosts = [[1,0],[0,3]], target = [0,1]
                    <br />
                    Output: true
                    <br />
                    Explanation: You can reach the destination (0, 1) after 1
                    turn, while the ghosts located at (1, 0) and (0, 3) cannot
                    catch up with you.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: ghosts = [[1,0]], target = [2,0]
                    <br />
                    Output: false
                    <br />
                    Explanation: You need to reach the destination (2, 0), but
                    the ghost at (1, 0) lies between you and the destination.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: ghosts = [[2,0]], target = [1,0]
                    <br />
                    Output: false
                    <br />
                    Explanation: The ghost can reach the target at the same time
                    as you.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= ghosts.length &lt;= 100 <br />
                ghosts[i].length == 2 <br />
                -10^4 &lt;= xi, yi &lt;= 10^4 <br />
                There can be multiple ghosts in the same location. <br />
                target.length == 2 <br />
                -10^4 &lt;= xtarget, ytarget &lt;= 10^4
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} ghosts
                * @param {number[]} target
                * @return {boolean}
                */
               var escapeGhosts = function(ghosts, target) {
                 const d = Math.abs(target[0]) + Math.abs(target[1]);
                 for (let ghost of ghosts)
                 if (d >= Math.abs(ghost[0] - target[0]) + Math.abs(ghost[1] - target[1]))
                     return false;
                 return true;
               };
               
               console.log(escapeGhosts([[2,0]],[1,0]))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q635: {
        title: "Q790. Domino and Tromino Tiling (Q635)",
        content: (
          <Comp
            title="Q790. Domino and Tromino Tiling (Q635)"
            content1={
              <>
                You have two types of tiles: a 2 x 1 domino shape and a tromino
                shape. You may rotate these shape
              </>
            }
            img={Leetcode790a}
            content2={
              <>
                Given an integer n, return the number of ways to tile an 2 x n
                board. Since the answer may be very large, return it modulo 109
                + 7.
                <br />
                In a tiling, every square must be covered by a tile. Two tilings
                are different if and only if there are two 4-directionally
                adjacent cells on the board such that exactly one of the tilings
                has both squares occupied by a tile.
              </>
            }
            examples={[
              {
                img: Leetcode790b,
                content: (
                  <>
                    Input: n = 3 <br />
                    Output: 5 <br />
                    Explanation: The five different ways are show abov
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1<br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 1000</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               var numTilings = function(n) {
                 const kMod = 1e9 + 7;
                 const dp = Array(1001).fill(0);
                 dp[1] = 1;
                 dp[2] = 2;
                 dp[3] = 5;
                 for (let i = 4; i <= n; ++i)
                   dp[i] = (2 * dp[i - 1] + dp[i - 3]) % kMod;
                 return dp[n];
               };
               
               console.log(numTilings(3))`,
                output: `5`,
              },
            }}
          />
        ),
      },
      q636: {
        title: "Q791. Custom Sort String (Q636)",
        content: (
          <Comp
            title="Q791. Custom Sort String (Q636)"
            content1={
              <>
                You are given two strings order and s. All the words of order
                are unique and were sorted in some custom order previously.
                <br />
                Permute the characters of s so that they match the order that
                order was sorted. More specifically, if a character x occurs
                before a character y in order, then x should occur before y in
                the permuted string.
                <br />
                Return any permutation of s that satisfies this property.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: order = "cba", s = "abcd" <br />
                    Output: "cbad" <br />
                    Explanation: <br />
                    "a", "b", "c" appear in order, so the order of "a", "b", "c"
                    should be "c", "b", and "a". Since "d" does not appear in
                    order, it can be at any position in the returned string.
                    "dcba", "cdba", "cbda" are also valid outputs.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: order = "cbafg", s = "abcd" <br />
                    Output: "cbad"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= order.length &lt;= 26 <br />
                1 &lt;= s.length &lt;= 200 <br />
                order and s consist of lowercase English letters. <br />
                All the characters of order are unique.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} order
                * @param {string} s
                * @return {string}
                */
               var customSortString = function(order, s) {
                 const sb = [];
                 const count = Array(128).fill(0);
                 for (let c of s)
                   ++count[c.charCodeAt(0)];
                 for (let c of order)
                   while (count[c.charCodeAt(0)]-- > 0)
                     sb.push(c);
                 for (let c = 'a'.charCodeAt(0); c <= 'z'.charCodeAt(0); ++c)
                   while (count[c]-- > 0)
                     sb.push(String.fromCharCode(c));
                 return sb.join("");    
               };
               
               console.log(customSortString("cba","abcd"))`,
                output: `cbad`,
              },
            }}
          />
        ),
      },
      q637: {
        title: "Q792. Number of Matching Subsequences (Q637)",
        content: (
          <Comp
            title="Q792. Number of Matching Subsequences (Q637)"
            content1={
              <>
                Given a string s and an array of strings words, return the
                number of words[i] that is a subsequence of s.
                <br />
                A subsequence of a string is a new string generated from the
                original string with some characters (can be none) deleted
                without changing the relative order of the remaining characters.
                <br />
                For example, "ace" is a subsequence of "abcde".
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "abcde", <br />
                    words = ["a","bb","acd","ace"]
                    <br />
                    Output: 3<br />
                    Explanation: There are three strings in words that are a
                    subsequence of s: "a", "acd", "ace".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "dsahjpjauf", <br />
                    words = ["ahjpjau","ja","ahbwzgqnuk","tnmlanowax"]
                    <br />
                    Output: 2
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 5 * 10^4 <br />
                1 &lt;= words.length &lt;= 5000 <br />
                1 &lt;= words[i].length &lt;= 50 <br />s and words[i] consist of
                only lowercase English letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // s = "abcde", words = ["a","bb","acd","ace"]
                class Solution {
                  public int numMatchingSubseq(String s, String[] words) {
                    int ans = 0;
                    List<Pair<Integer, Integer>>[] bucket = new List[26];
                    for (int i = 0; i < 26; ++i)
                      bucket[i] = new ArrayList<>();
                    for (int i = 0; i < words.length; ++i)
                      bucket[words[i].charAt(0) - 'a'].add(new Pair<>(i, 0));
                    for (final char c : s.toCharArray()) {
                      List<Pair<Integer, Integer>> prevBucket = bucket[c - 'a'];
                      bucket[c - 'a'] = new ArrayList<>();
                      for (var pair : prevBucket) {
                        final int i = pair.getKey();
                        final int j = pair.getValue() + 1;
                        if (j == words[i].length()) 
                          ++ans;
                        else
                          bucket[words[i].charAt(j) - 'a'].add(new Pair<>(i, j));
                      }
                    }
                    return ans;
                  }
                }`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q638: {
        title: "Q793. Preimage Size of Factorial Zeroes Function (Q638)",
        content: (
          <Comp
            title="Q793. Preimage Size of Factorial Zeroes Function (Q638)"
            content1={
              <>
                Let f(x) be the number of zeroes at the end of x!. Recall that
                x! = 1 * 2 * 3 * ... * x and by convention, 0! = 1.
                <br />
                For example, f(3) = 0 because 3! = 6 has no zeroes at the end,
                while f(11) = 2 because 11! = 39916800 has two zeroes at the
                end.
                <br />
                Given an integer k, return the number of non-negative integers x
                have the property that f(x) = k.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: k = 0 <br />
                    Output: 5 <br />
                    Explanation: 0!, 1!, 2!, 3!, and 4! end with k = 0 zeroes.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: k = 5 <br />
                    Output: 0 <br />
                    Explanation: There is no x such that x! ends in k = 5
                    zeroes.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: k = 3 <br />
                    Output: 5
                  </>
                ),
              },
            ]}
            constraints={<>0 &lt;= k &lt;= 109</>}
            tc="log n . log n"
            sc="2^n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} k
                * @return {number}
                */
               var preimageSizeFZF = function(k) {
                 let l = 0;
                 let r = 5 * k;
                 while (l < r) {
                   let m = l + parseInt((r - l) / 2);
                   if (trailingZeroes(m) >= k)
                     r = m;
                   else
                     l = m + 1;
                 }
                 return trailingZeroes(l) == k ? 5 : 0; 
               };
               
               function trailingZeroes(n) {
                 return n == 0 ? 0 : parseInt(parseInt(n / 5) + trailingZeroes(parseInt(n / 5)));
               }
               
               console.log(preimageSizeFZF(3))`,
                output: `5`,
              },
            }}
          />
        ),
      },
      q639: {
        title: "Q794. Valid Tic-Tac-Toe State (Q639)",
        content: (
          <Comp
            title="Q794. Valid Tic-Tac-Toe State (Q639)"
            content1={
              <>
                Given a Tic-Tac-Toe board as a string array board, return true
                if and only if it is possible to reach this board position
                during the course of a valid tic-tac-toe game.
                <br />
                The board is a 3 x 3 array that consists of characters ' ', 'X',
                and 'O'. The ' ' character represents an empty square.
                <br />
                Here are the rules of Tic-Tac-Toe:
                <br />
                Players take turns placing characters into empty squares ' '.
                <br />
                The first player always places 'X' characters, while the second
                player always places 'O' characters.
                <br /> 'X' and 'O' characters are always placed into empty
                squares, never filled ones.
                <br /> The game ends when there are three of the same
                (non-empty) character filling any row, column, or diagonal.
                <br /> The game also ends if all squares are non-empty. No more
                moves can be played if the game is over.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: board = ["O "," "," "] <br />
                    Output: false <br />
                    Explanation: The first player always plays "X".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: board = ["XOX"," X "," "] <br />
                    Output: false <br />
                    Explanation: Players take turns making moves.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: board = ["XOX","O O","XOX"] <br />
                    Output: true
                  </>
                ),
              },
            ]}
            constraints={
              <>
                board.length == 3 <br />
                board[i].length == 3 <br />
                board[i][j] is either 'X', 'O', or{" "}
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: board = ["O  ","   ","   "]
                class Solution {
                  public boolean validTicTacToe(String[] board) {
                    final int countX = sum(board, 'X');
                    final int countO = sum(board, 'O');
                
                    if (countX < countO || countX - countO > 1)
                      return false;
                    if (isWinned(board, 'X') && countX == countO ||
                        isWinned(board, 'O') && countX != countO)
                      return false;
                
                    return true;
                  }
                
                  private int sum(final String[] board, char c) {
                    int ans = 0;
                
                    for (final String row : board)
                      ans += row.chars().filter(i -> i == c).count();
                
                    return ans;
                  }
                
                  private boolean isWinned(final String[] board, char c) {
                    String[] rotated = rotate(board);
                
                    return Arrays.stream(board).anyMatch(row -> row.chars().filter(i -> i == c).count() == 3)
                        || Arrays.stream(rotated).anyMatch(row -> row.chars().filter(i -> i == c).count() == 3)
                        || board[0].charAt(0) == c && board[1].charAt(1) == c && board[2].charAt(2) == c
                        || board[0].charAt(2) == c && board[1].charAt(1) == c && board[2].charAt(0) == c;
                  }
                
                  private String[] rotate(final String[] board) {
                    String[] rotated = new String[3];
                
                    for (final String row : board)
                      for (int i = 0; i < 3; ++i)
                        rotated[i] += row.charAt(i);
                
                    return rotated;
                  }
                }`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q640: {
        title: "Q795. Number of Subarrays with Bounded Maximum (Q640)",
        content: (
          <Comp
            title="Q795. Number of Subarrays with Bounded Maximum (Q640)"
            content1={
              <>
                Given an integer array nums and two integers left and right,
                return the number of contiguous non-empty subarrays such that
                the value of the maximum array element in that subarray is in
                the range [left, right].
                <br />
                The test cases are generated so that the answer will fit in a
                32-bit integer.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [2,1,4,3], <br /> left = 2, right = 3 <br />
                    Output: 3 <br />
                    Explanation: There are three subarrays that meet the
                    requirements: [2], [2, 1], [3].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2,9,2,5,6], <br /> left = 2, right = 8 <br />
                    Output: 7
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^5 <br />
                0 &lt;= nums[i] &lt;= 10^9 <br />0 &lt;= left &lt;= right &lt;=
                10^9
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} left
                * @param {number} right
                * @return {number}
                */
               var numSubarrayBoundedMax = function(nums, left, right) {
                   let res = 0;
                   let l = -1;
                   let r = -1;
                   for (let i = 0; i < nums.length; ++i) {
                     if (nums[i] > right) 
                       l = i;
                     if (nums[i] >= left) 
                       r = i;
                     res += r - l;
                   }
                   return res;    
               };
               
               console.log(numSubarrayBoundedMax([2,9,2,5,6],2,8))`,
                output: `7`,
              },
            }}
          />
        ),
      },
      q641: {
        title: "Q796. Rotate String (Q641)",
        content: (
          <Comp
            title="Q796. Rotate String (Q641)"
            content1={
              <>
                Given two strings s and goal, return true if and only if s can
                become goal after some number of shifts on s.
                <br />
                A shift on s consists of moving the leftmost character of s to
                the rightmost position.
                <br />
                For example, if s = "abcde", then it will be "bcdea" after one
                shift.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "abcde", goal = "cdeab" <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "abcde", goal = "abced" <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length, goal.length &lt;= 100
                <br />s and goal consist of lowercase English letters.
              </>
            }
            tc="n^2"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @param {string} goal
                * @return {boolean}
                */
               var rotateString = function(s, goal) {
                    return s.length == goal.length && (s + s).includes(goal);
               };
               
               console.log(rotateString("abcde","cdeab"))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q642: {
        title: "Q797. All Paths From Source to Target (Q642)",
        content: (
          <Comp
            title="Q797. All Paths From Source to Target (Q642)"
            content1={
              <>
                Given a directed acyclic graph (DAG) of n nodes labeled from 0
                to n - 1, find all possible paths from node 0 to node n - 1 and
                return them in any order.
                <br />
                The graph is given as follows: graph[i] is a list of all nodes
                you can visit from node i (i.e., there is a directed edge from
                node i to node graph[i][j]).
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode797a,
                content: (
                  <>
                    Input: graph = [[1,2],[3],[3],[]] <br />
                    Output: [[0,1,3],[0,2,3]] <br />
                    Explanation: There are two paths: 0 -&gt; 1 -&gt; 3 and 0
                    -&gt; 2 -&gt; 3.
                  </>
                ),
              },
              {
                img: Leetcode797b,
                content: (
                  <>
                    Input: graph = [[4,3,1],[3,2,4],[3],[4],[]] <br />
                    Output: [[0,4],[0,3,4],[0,1,3,4],[0,1,2,3,4],[0,1,4]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == graph.length <br />
                2 &gt;= n &gt;= 15 <br />
                0 &gt;= graph[i][j] &gt; n <br />
                graph[i][j] != i (i.e., there will be no self-loops). <br />
                All the elements of graph[i] are unique. <br />
                The input graph is guaranteed to be a DAG.
              </>
            }
            tc="n.2^n"
            sc="n.2^n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} graph
                * @return {number[][]}
                */
               var allPathsSourceTarget = function(graph) {
                 const res = [];
                 dfs(graph, 0, [0], res);
                 return res;
               };
               
               function dfs(graph, u, curr, res) {
                   if (u == graph.length - 1) {
                     res.push(curr.slice());
                     return;
                   }
                   for (let v of graph[u]) {
                     curr.push(v);
                     dfs(graph, v, curr, res);
                     curr.pop()
                   }
                 }
               
               
               console.log(allPathsSourceTarget([[4,3,1],[3,2,4],[3],[4],[]]))`,
                output: `[
                  [ 0, 4 ],
                  [ 0, 3, 4 ],
                  [ 0, 1, 3, 4 ],
                  [ 0, 1, 2, 3, 4 ],
                  [ 0, 1, 4 ]
                ]`,
              },
            }}
          />
        ),
      },
      q643: {
        title: "Q798. Smallest Rotation with Highest Score (Q643)",
        content: (
          <Comp
            title="Q798. Smallest Rotation with Highest Score (Q643)"
            content1={
              <>
                You are given an array nums. You can rotate it by a non-negative
                integer k so that the array becomes [nums[k], nums[k + 1], ...
                nums[nums.length - 1], nums[0], nums[1], ..., nums[k-1]].
                Afterward, any entries that are less than or equal to their
                index are worth one point.
                <br />
                For example, if we have nums = [2,4,1,3,0], and we rotate by k =
                2, it becomes [1,3,0,2,4]. This is worth 3 points because 1 &gt;
                0 [no points], 3 &gt; 1 [no points], 0 &lt;= 2 [one point], 2
                &lt;= 3 [one point], 4 &lt;= 4 [one point].
                <br />
                Return the rotation index k that corresponds to the highest
                score we can achieve if we rotated nums by it. If there are
                multiple answers, return the smallest such index k.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [2,3,1,4,0] <br />
                    Output: 3 <br />
                    Explanation: Scores for each k are listed below: <br />
                    k = 0, nums = [2,3,1,4,0], score 2 <br />
                    k = 1, nums = [3,1,4,0,2], score 3 <br />
                    k = 2, nums = [1,4,0,2,3], score 3 <br />
                    k = 3, nums = [4,0,2,3,1], score 4 <br />
                    k = 4, nums = [0,2,3,1,4], score 3 <br />
                    So we should choose k = 3, which has the highest score.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,3,0,2,4] Output: 0 <br />
                    Explanation: nums will always have 3 points no matter how it
                    shifts. <br />
                    So we will choose the smallest k, which is 0.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^5 <br />0 &lt;= nums[i] &lt;
                nums.length
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var bestRotation = function(nums) {
                   const n = nums.length;
                   const rotate = Array(n).fill(0);
                   for (let i = 0; i < n; ++i)
                     --rotate[(i - nums[i] + 1 + n) % n];
                   for (let i = 1; i < n; ++i)
                     rotate[i] += rotate[i - 1] + 1;
                   let max = Number.MIN_SAFE_INTEGER;
                   let maxIndex = 0;
                   for (let i = 0; i < n; ++i)
                     if (rotate[i] > max) {
                       max = rotate[i];
                       maxIndex = i;
                     }
                   return maxIndex;
               };
               
               console.log(bestRotation([2,3,1,4,0]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q644: {
        title: "Q799. Champagne Tower (Q644)",
        content: (
          <Comp
            title="Q799. Champagne Tower (Q644)"
            content1={
              <>
                We stack glasses in a pyramid, where the first row has 1 glass,
                the second row has 2 glasses, and so on until the 100th row.
                Each glass holds one cup of champagne.
                <br />
                Then, some champagne is poured into the first glass at the top.
                When the topmost glass is full, any excess liquid poured will
                fall equally to the glass immediately to the left and right of
                it. When those glasses become full, any excess champagne will
                fall equally to the left and right of those glasses, and so on.
                (A glass at the bottom row has its excess champagne fall on the
                floor.)
                <br />
                For example, after one cup of champagne is poured, the top most
                glass is full. After two cups of champagne are poured, the two
                glasses on the second row are half full. After three cups of
                champagne are poured, those two cups become full - there are 3
                full glasses total now. After four cups of champagne are poured,
                the third row has the middle glass half full, and the two
                outside glasses are a quarter full, as pictured below.
              </>
            }
            content2={
              <>
                Now after pouring some non-negative integer cups of champagne,
                return how full the jth glass in the ith row is (both i and j
                are 0-indexed.)
              </>
            }
            img={Leetcode799}
            examples={[
              {
                content: (
                  <>
                    Input: poured = 1, query_row = 1, query_glass = 1<br />
                    Output: 0.00000
                    <br />
                    Explanation: We poured 1 cup of champange to the top glass
                    of the tower (which is indexed as (0, 0)). There will be no
                    excess liquid so all the glasses under the top glass will
                    remain empty.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: poured = 2, query_row = 1, query_glass = 1<br />
                    Output: 0.50000
                    <br />
                    Explanation: We poured 2 cups of champange to the top glass
                    of the tower (which is indexed as (0, 0)). There is one cup
                    of excess liquid. The glass indexed as (1, 0) and the glass
                    indexed as (1, 1) will share the excess liquid equally, and
                    each will get half cup of champange.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: poured = 100000009, query_row = 33, query_glass = 17
                    <br />
                    Output: 1.00000
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= poured &lt;= 109
                <br />0 &lt;= query_glass &lt;= query_row &lt; 100
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} poured
                * @param {number} query_row
                * @param {number} query_glass
                * @return {number}
                */
               var champagneTower = function(poured, query_row, query_glass) {
                  let dp = Array(query_row + 1).fill(0);
                  dp[0] = poured;
                   for (let i = 0; i < query_row; ++i) {
                     const newDp = Array(query_row + 1).fill(0);
                     for (let j = 0; j <= i; ++j)
                       if (dp[j] > 1) {
                         newDp[j] += (dp[j] - 1) / 2.0;
                         newDp[j + 1] += (dp[j] - 1) / 2.0;
                       }
                     dp = newDp;
                   }
                   return Math.min(1.0, dp[query_glass]);    
               };
               
               console.log(champagneTower(2,1,1))`,
                output: `0.5`,
              },
            }}
          />
        ),
      },
      q645: {
        title: "Q801. Minimum Swaps To Make Sequences Increasing (Q645)",
        content: (
          <Comp
            title="Q801. Minimum Swaps To Make Sequences Increasing (Q645)"
            content1={
              <>
                You are given two integer arrays of the same length nums1 and
                nums2. In one operation, you are allowed to swap nums1[i] with
                nums2[i].
                <br />
                For example, if nums1 = [1,2,3,8], and nums2 = [5,6,7,4], you
                can swap the element at i = 3 to obtain nums1 = [1,2,3,4] and
                nums2 = [5,6,7,8]. Return the minimum number of needed
                operations to make nums1 and nums2 strictly increasing. The test
                cases are generated so that the given input always makes it
                possible.
                <br />
                An array arr is strictly increasing if and only if arr[0] &lt;
                arr[1] &lt; arr[2] &lt; ... &lt; arr[arr.length - 1].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums1 = [1,3,5,4], nums2 = [1,2,3,7] <br />
                    Output: 1 <br />
                    Explanation: <br />
                    Swap nums1[3] and nums2[3]. Then the sequences are: <br />
                    nums1 = [1, 3, 5, 7] and nums2 = [1, 2, 3, 4] <br />
                    which are both strictly increasing.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums1 = [0,3,5,8,9], nums2 = [2,1,4,6,9] <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= nums1.length &lt;= 10^5 <br />
                nums2.length == nums1.length <br />0 &lt;= nums1[i], nums2[i]
                &lt;= 2 * 10^5
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums1
                * @param {number[]} nums2
                * @return {number}
                */
               var minSwap = function(nums1, nums2) {
                   let keepAt = 0;
                   let swapAt = 1;
                   let prevKeepAt = 0;
                   let prevSwapAt = 1;
               
                   for (let i = 1; i < nums1.length; ++i) {
                     keepAt = Number.MAX_SAFE_INTEGER;
                     swapAt = Number.MAX_SAFE_INTEGER;
                     if (nums1[i] > nums1[i - 1] && nums2[i] > nums2[i - 1]) {
                       keepAt = prevKeepAt;
                       swapAt = prevSwapAt + 1;
                     }
                     if (nums1[i] > nums2[i - 1] && nums2[i] > nums1[i - 1]) {
                       keepAt = Math.min(keepAt, prevSwapAt);
                       swapAt = Math.min(swapAt, prevKeepAt + 1);
                     }
                     prevKeepAt = keepAt;
                     prevSwapAt = swapAt;
                   }
               
                   return Math.min(keepAt, swapAt); 
               };
               
               console.log(minSwap([0,3,5,8,9],[2,1,4,6,9]))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q646: {
        title: "Q802. Find Eventual Safe States (Q646)",
        content: (
          <Comp
            title="Q802. Find Eventual Safe States (Q646)"
            content1={
              <>
                There is a directed graph of n nodes with each node labeled from
                0 to n - 1. The graph is represented by a 0-indexed 2D integer
                array graph where graph[i] is an integer array of nodes adjacent
                to node i, meaning there is an edge from node i to each node in
                graph[i].
                <br />
                A node is a terminal node if there are no outgoing edges. A node
                is a safe node if every possible path starting from that node
                leads to a terminal node.
                <br />
                Return an array containing all the safe nodes of the graph. The
                answer should be sorted in ascending order.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode802,
                content: (
                  <>
                    Input: graph = [[1,2],[2,3],[5],[0],[5],[],[]]
                    <br />
                    Output: [2,4,5,6]
                    <br />
                    Explanation: The given graph is shown above.
                    <br />
                    Nodes 5 and 6 are terminal nodes as there are no outgoing
                    edges from either of them.
                    <br /> Every path starting at nodes 2, 4, 5, and 6 all lead
                    to either node 5 or 6.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: graph = [[1,2,3,4],[1,2],[3,4],[0,4],[]]
                    <br />
                    Output: [4]
                    <br />
                    Explanation:
                    <br />
                    Only node 4 is a terminal node, and every path starting at
                    node 4 leads to node 4.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == graph.length
                <br />
                1 &lt;= n &lt;= 10^4
                <br />
                0 &lt;= graph[i].length &lt;= n<br />
                0 &lt;= graph[i][j] &lt;= n - 1<br />
                graph[i] is sorted in a strictly increasing order.
                <br />
                The graph may contain self-loops.
                <br />
                The number of edges in the graph will be in the range [1, 4 *
                104]
              </>
            }
            tc="v + e"
            sc="v + e"
            codes={{
              Java: {
                code: `
                // graph = [[1,2],[2,3],[5],[0],[5],[],[]]
                enum State { INIT, VISITING, VISITED }
                class Solution {
                  public List<Integer> eventualSafeNodes(int[][] graph) {
                    List<Integer> ans = new ArrayList<>();
                    State[] state = new State[graph.length];
                    for (int i = 0; i < graph.length; ++i)
                      if (!hasCycle(graph, i, state))
                        ans.add(i);
                    return ans;
                  }
                  private boolean hasCycle(int[][] graph, int u, State[] state) {
                    if (state[u] == State.VISITING)
                      return true;
                    if (state[u] == State.VISITED)
                      return false;                
                    state[u] = State.VISITING;
                    for (final int v : graph[u])
                      if (hasCycle(graph, v, state))
                        return true;
                    state[u] = State.VISITED;
                    return false;
                  }
                }
                `,
                output: `[2,4,5,6]`,
              },
            }}
          />
        ),
      },
      q647: {
        title: "Q803. Bricks Falling When Hit (Q647)",
        content: (
          <Comp
            title="Q803. Bricks Falling When Hit (Q647)"
            content1={
              <>
                You are given an m x n binary grid, where each 1 represents a
                brick and 0 represents an empty space. A brick is stable if:
                <br />
                It is directly connected to the top of the grid, or
                <br />
                At least one other brick in its four adjacent cells is stable.
                <br />
                You are also given an array hits, which is a sequence of
                erasures we want to apply. Each time we want to erase the brick
                at the location hits[i] = (rowi, coli). The brick on that
                location (if it exists) will disappear. Some other bricks may no
                longer be stable because of that erasure and will fall. Once a
                brick falls, it is immediately erased from the grid (i.e., it
                does not land on other stable bricks).
                <br />
                Return an array result, where each result[i] is the number of
                bricks that will fall after the ith erasure is applied.
                <br />
                Note that an erasure may refer to a location with no brick, and
                if it does, no bricks drop.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: grid = [[1,0,0,0],[1,1,1,0]], hits = [[1,0]]
                    <br /> Output: [2] <br />
                    Explanation: Starting with the grid: <br />
                    [[1,0,0,0], <br />
                    [1,1,1,0]] <br />
                    <br /> We erase the underlined brick at (1,0), resulting in
                    the grid: [[1,0,0,0], <br />
                    [0,1,1,0]] <br />
                    <br /> The two underlined bricks are no longer stable as
                    they are no longer connected to the top nor adjacent to
                    another stable brick, so they will fall. The resulting grid
                    is: [[1,0,0,0], <br />
                    [0,0,0,0]] <br />
                    Hence the result is [2].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[1,0,0,0],[1,1,0,0]], hits = [[1,1],[1,0]]
                    <br /> Output: [0,0] <br />
                    Explanation: Starting with the grid: <br />
                    [[1,0,0,0], <br />
                    [1,1,0,0]] <br />
                    We erase the underlined brick at (1,1), resulting in the
                    grid:
                    <br /> [[1,0,0,0], <br />
                    [1,0,0,0]] <br />
                    <br /> All remaining bricks are still stable, so no bricks
                    fall. The grid remains the same: [[1,0,0,0], <br />
                    [1,0,0,0]] <br />
                    <br /> Next, we erase the underlined brick at (1,0),
                    resulting in the grid: [[1,0,0,0], <br />
                    [0,0,0,0]] <br />
                    <br /> Once again, all remaining bricks are still stable, so
                    no bricks fall. Hence the result is [0,0].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == grid.length <br />
                n == grid[i].length <br />
                1 &lt;= m, n &lt;= 200 <br />
                grid[i][j] is 0 or 1. <br />
                1 &lt;= hits.length &lt;= 4 * 10^4 <br />
                hits[i].length == 2 <br />
                0 &lt;= xi &lt;= m - 1 <br />
                0 &lt;= yi &lt;= n - 1 <br />
                All (xi, yi) are unique.
              </>
            }
            tc="m.n.log m.n"
            sc="m.n"
            codes={{
              Java: {
                code: `
                // grid = [[1,0,0,0],[1,1,0,0]], hits = [[1,1],[1,0]]
                class UF {
                  public UF(int n) {
                    id = new int[n];
                    size = new int[n];
                    for (int i = 0; i < n; ++i)
                      id[i] = i;
                    Arrays.fill(size, 1);
                  }
                  public void union(int u, int v) {
                    final int i = find(u);
                    final int j = find(v);
                    if (i == j)
                      return;
                    id[i] = j;
                    size[j] += size[i];
                  }
                  public int getStableSize() {
                    return size[find(0)];
                  }
                  private int[] id;
                  private int[] size;
                  private int find(int u) {
                    return id[u] == u ? u : (id[u] = find(id[u]));
                  }
                }
                class Solution {
                  public int[] hitBricks(int[][] grid, int[][] hits) {
                    this.m = grid.length;
                    this.n = grid[0].length;
                    UF uf = new UF(m * n + 1); 
                    for (int[] hit : hits) {
                      final int i = hit[0];
                      final int j = hit[1];
                      if (grid[i][j] == 1)
                        grid[i][j] = 2;
                    }
                    for (int i = 0; i < m; ++i)
                      for (int j = 0; j < n; ++j)
                        if (grid[i][j] == 1)
                          unionNeighbors(grid, uf, i, j);
                    int[] ans = new int[hits.length];
                    int stableSize = uf.getStableSize();
                    for (int i = hits.length - 1; i >= 0; --i) {
                      final int x = hits[i][0];
                      final int y = hits[i][1];
                      if (grid[x][y] == 2) { 
                        grid[x][y] = 1;     
                        unionNeighbors(grid, uf, x, y);
                        final int newStableSize = uf.getStableSize();
                        if (newStableSize > stableSize)
                          ans[i] = newStableSize - stableSize - 1; 
                        stableSize = newStableSize;
                      }
                    }
                
                    return ans;
                  }
                  private int m;
                  private int n;
                  private static final int[] dirs = {0, 1, 0, -1, 0};
                  private void unionNeighbors(int[][] grid, UF uf, int i, int j) {
                    final int hashed = hash(i, j);
                    for (int k = 0; k < 4; ++k) {
                      final int x = i + dirs[k];
                      final int y = j + dirs[k + 1];
                      if (x < 0 || x == m || y < 0 || y == n)
                        continue;
                      if (grid[x][y] != 1)
                        continue;
                      uf.union(hashed, hash(x, y));
                    }
                    if (i == 0)
                      uf.union(hashed, 0);
                  }
                  private int hash(int i, int j) {
                    return i * n + j + 1;
                  }
                }`,
                output: `[0,0]`,
              },
            }}
          />
        ),
      },
      q648: {
        title: "Q804. Unique Morse Code Words (Q648)",
        content: (
          <Comp
            title="Q804. Unique Morse Code Words (Q648)"
            content1={
              <>
                International Morse Code defines a standard encoding where each
                letter is mapped to a series of dots and dashes, as follows:
                <br />
                'a' maps to ".-", <br />
                'b' maps to "-...", <br />
                'c' maps to "-.-.", and so on. <br />
                For convenience, the full table for the 26 letters of the
                English alphabet is given below:
                <br />
                <pre>
                  {`
[".-","-...","-.-.","-..",".","..-.","--."
,"....","..",".---","-.-",".-..","--","-."
,"---",".--.","--.-",".-.","...","-","..-"
,"...-",".--","-..-","-.--","--.."]                  
                  `}
                </pre>
                Given an array of strings words where each word can be written
                as a concatenation of the Morse code of each letter.
                <br />
                For example, "cab" can be written as "-.-..--...", which is the
                concatenation of "-.-.", ".-", and "-...". We will call such a
                concatenation the transformation of a word.
                <br />
                Return the number of different transformations among all words
                we have.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: words = ["gin","zen","gig","msg"]
                    <br />
                    Output: 2<br />
                    Explanation: The transformation of each word is:
                    <br />
                    "gin" -&gt; "--...-."
                    <br />
                    "zen" -&gt; "--...-."
                    <br />
                    "gig" -&gt; "--...--."
                    <br />
                    "msg" -&gt; "--...--."
                    <br />
                    <br /> There are 2 different transformations: "--...-." and
                    "--...--.".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words = ["a"]
                    <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= words.length &lt;= 100
                <br />
                1 &lt;= words[i].length &lt;= 12
                <br />
                words[i] consists of lowercase English letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} words
                * @return {number}
                */
               var uniqueMorseRepresentations = function(words) {
                 const morse = [
                       ".-",   "-...", "-.-.", "-..",  ".",   "..-.", "--.",  "....", "..",
                       ".---", "-.-",  ".-..", "--",   "-.",  "---",  ".--.", "--.-", ".-.",
                       "...",  "-",    "..-",  "...-", ".--", "-..-", "-.--", "--.."];
                 const transformations = new Set();
                 for (const word of words) {
                   let transformation = '';
                   for (const c of word)
                     transformation += morse[c.charCodeAt(0) - 97];
                   transformations.add(transformation);
                 }
                 return transformations.size;    
               };
               
               console.log(uniqueMorseRepresentations(["a"]))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q649: {
        title: "Q805. Split Array With Same Average (Q649)",
        content: (
          <Comp
            title="Q805. Split Array With Same Average (Q649)"
            content1={
              <>
                You are given an integer array nums.
                <br />
                You should move each element of nums into one of the two arrays
                A and B such that A and B are non-empty, and average(A) ==
                average(B).
                <br />
                Return true if it is possible to achieve that and false
                otherwise.
                <br />
                Note that for an array arr, average(arr) is the sum of all the
                elements of arr over the length of arr.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4,5,6,7,8] <br />
                    Output: true <br />
                    <br /> Explanation: We can split the array into [1,4,5,8]
                    and [2,3,6,7], and both of them have an average of 4.5.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [3,1] <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 30 <br />0 &lt;= nums[i] &lt;= 10^4
              </>
            }
            tc="2^(0.5*n)"
            sc="2^(0.5*n)"
            codes={{
              Java: {
                code: `
                // nums = [1,2,3,4,5,6,7,8]
                class Solution {
                  public boolean splitArraySameAverage(int[] A) {
                    final int n = A.length;
                    final int sum = Arrays.stream(A).sum();
                    if (!isPossible(sum, n))
                      return false;
                
                    List<Set<Integer>> sums = new ArrayList<>();
                
                    for (int i = 0; i < n / 2 + 1; ++i)
                      sums.add(new HashSet<>());
                    sums.get(0).add(0);
                
                    for (final int a : A)
                      for (int i = n / 2; i > 0; --i)
                        for (final int num : sums.get(i - 1))
                          sums.get(i).add(a + num);
                
                    for (int i = 1; i < n / 2 + 1; ++i)
                      if (i * sum % n == 0 && sums.get(i).contains(i * sum / n))
                        return true;
                
                    return false;
                  }
                
                  private boolean isPossible(int sum, int n) {
                    for (int i = 1; i < n / 2 + 1; ++i)
                      if (i * sum % n == 0)
                        return true;
                    return false;
                  }
                }
                `,
                output: `true`,
              },
            }}
          />
        ),
      },
      q650: {
        title: "Q806. Number of Lines To Write String (Q650)",
        content: (
          <Comp
            title="Q806. Number of Lines To Write String (Q650)"
            content1={
              <>
                You are given a string s of lowercase English letters and an
                array widths denoting how many pixels wide each lowercase
                English letter is. Specifically, widths[0] is the width of 'a',
                widths[1] is the width of 'b', and so on.
                <br />
                You are trying to write s across several lines, where each line
                is no longer than 100 pixels. Starting at the beginning of s,
                write as many letters on the first line such that the total
                width does not exceed 100 pixels. Then, from where you stopped
                in s, continue writing as many letters as you can on the second
                line. Continue this process until you have written all of s.
                <br />
                Return an array result of length 2 where:
                <br />
                result[0] is the total number of lines.
                <br />
                result[1] is the width of the last line in pixels.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: widths =
                    [10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10],
                    s = "abcdefghijklmnopqrstuvwxyz"
                    <br /> Output: [3,60] <br />
                    Explanation: You can write s as follows: <br />
                    abcdefghij // 100 pixels wide <br />
                    klmnopqrst // 100 pixels wide <br />
                    uvwxyz // 60 pixels wide <br />
                    There are a total of 3 lines, and the last line is 60 pixels
                    wide.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: widths =
                    [4,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10],
                    s = "bbbcccdddaaa"
                    <br /> Output: [2,4] <br />
                    Explanation: You can write s as follows: <br />
                    bbbcccdddaa // 98 pixels wide <br />
                    a // 4 pixels wide <br />
                    There are a total of 2 lines, and the last line is 4 pixels
                    wide.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                widths.length == 26
                <br />
                2 &lt;= widths[i] &lt;= 10
                <br />
                1 &lt;= s.length &lt;= 1000
                <br />s contains only lowercase English letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // widths = [10,10,10,10,10,10,10,10,10,10,10,10,10,
                // 10,10,10,10,10,10,10,10,10,10,10,10,10], 
                // s = "abcdefghijklmnopqrstuvwxyz"
                class Solution {
                  public int[] numberOfLines(int[] w, String s) {
                      int line=0,sum=0;
                      for(int i =0;i<s.length();i++){
                              int m = s.charAt(i) - 97;
                               System.out.println(m);
                              if(sum+w[m]>100){
                                  System.out.println(sum+w[m]);
                                  sum=0;
                                  line++;
                                  System.out.println(s.charAt(i));
                              }
                              sum+=w[m];
                          
                      }
                      int[] ans = new int[2];
                      ans[0]=line+1;
                      ans[1]=sum;
                      return ans;   
                  }
              }`,
                output: `[3,60]`,
              },
            }}
          />
        ),
      },
      q651: {
        title: "Q807. Max Increase to Keep City Skyline (Q651)",
        content: (
          <Comp
            title="Q807. Max Increase to Keep City Skyline (Q651)"
            content1={
              <>
                There is a city composed of n x n blocks, where each block
                contains a single building shaped like a vertical square prism.
                You are given a 0-indexed n x n integer matrix grid where
                grid[r][c] represents the height of the building located in the
                block at row r and column c.
                <br />
                A city's skyline is the the outer contour formed by all the
                building when viewing the side of the city from a distance. The
                skyline from each cardinal direction north, east, south, and
                west may be different.
                <br />
                We are allowed to increase the height of any number of buildings
                by any amount (the amount can be different per building). The
                height of a 0-height building can also be increased. However,
                increasing the height of a building should not affect the city's
                skyline from any cardinal direction.
                <br />
                Return the maximum total sum that the height of the buildings
                can be increased by without changing the city's skyline from any
                cardinal direction.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode807,
                content: (
                  <>
                    Input: grid = [[3,0,8,4],[2,4,5,7],[9,2,6,3],[0,3,1,0]]
                    <br /> Output: 35 <br />
                    Explanation: The building heights are shown in the center of
                    the above image.
                    <br /> The skylines when viewed from each cardinal direction
                    are drawn in red.
                    <br /> The grid after increasing the height of buildings
                    without affecting skylines is:
                    <br /> gridNew = [ [8, 4, 8, 7],
                    <br /> [7, 4, 7, 7], <br />
                    [9, 4, 8, 7], <br />
                    [3, 3, 3, 3] ]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[0,0,0],[0,0,0],[0,0,0]] <br />
                    Output: 0 <br />
                    Explanation: Increasing the height of any building will
                    result in the skyline changing.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == grid.length
                <br />
                n == grid[r].length
                <br />
                2 &lt;= n &lt;= 50
                <br />0 &lt;= grid[r][c] &lt;= 100
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} grid
                * @return {number}
                */
               var maxIncreaseKeepingSkyline = function(grid) {
                   const n = grid.length;
                   let ans = 0;
                   const rowMax = Array(n).fill(0);
                   const colMax = Array(n).fill(0);
                   for (let i = 0; i < n; ++i)
                     for (let j = 0; j < n; ++j) {
                       rowMax[i] = Math.max(rowMax[i], grid[i][j]);
                       colMax[j] = Math.max(colMax[j], grid[i][j]);
                     }
                   for (let i = 0; i < n; ++i)
                     for (let j = 0; j < n; ++j)
                       ans += Math.min(rowMax[i], colMax[j]) - grid[i][j];
                   return ans;    
               };
               
               console.log(maxIncreaseKeepingSkyline([[0,0,0],[0,0,0],[0,0,0]]))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q652: {
        title: "Q808. Soup Servings (Q652)",
        content: (
          <Comp
            title="Q808. Soup Servings (Q652)"
            content1={
              <>
                There are two types of soup: type A and type B. Initially, we
                have n ml of each type of soup. There are four kinds of
                operations:
                <br />
                Serve 100 ml of soup A and 0 ml of soup B,
                <br />
                Serve 75 ml of soup A and 25 ml of soup B,
                <br />
                Serve 50 ml of soup A and 50 ml of soup B, and
                <br />
                Serve 25 ml of soup A and 75 ml of soup B.
                <br />
                When we serve some soup, we give it to someone, and we no longer
                have it. Each turn, we will choose from the four operations with
                an equal probability 0.25. If the remaining volume of soup is
                not enough to complete the operation, we will serve as much as
                possible. We stop once we no longer have some quantity of both
                types of soup.
                <br />
                Note that we do not have an operation where all 100 ml's of soup
                B are used first.
                <br />
                Return the probability that soup A will be empty first, plus
                half the probability that A and B become empty at the same time.
                Answers within 10-5 of the actual answer will be accepted.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 50 <br />
                    Output: 0.62500 <br />
                    Explanation: If we choose the first two operations, A will
                    become empty first.
                    <br /> For the third operation, A and B will become empty at
                    the same time.
                    <br /> For the fourth operation, B will become empty first.
                    <br /> So the total probability of A becoming empty first
                    plus half the probability that A and B become empty at the
                    same time, is 0.25 * (1 + 1 + 0.5 + 0) = 0.625.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 100 <br />
                    Output: 0.71875
                  </>
                ),
              },
            ]}
            constraints={<>0 &lt;= n &lt;= 109</>}
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               
               const memo = Array.from(Array(4800 / 25),()=>Array(4800 / 25).fill(0));
               
               function dfs(a, b) {
                   if (a <= 0 && b <= 0)
                     return 0.5;
                   if (a <= 0)
                     return 1.0;
                   if (b <= 0)
                     return 0.0;
                   if (memo[a][b] > 0)
                     return memo[a][b];
                   return memo[a][b] = 0.25 * (dfs(a - 4, b) +
                                               dfs(a - 3, b - 1) +
                                               dfs(a - 2, b - 2) +
                                               dfs(a - 1, b - 3));
               }
               
               var soupServings = function(n) {
                   return n >= 4800 ? 1.0 : dfs(parseInt((n + 24) / 25), parseInt((n + 24) / 25));
               };
               
               console.log(soupServings(50))`,
                output: `0.625`,
              },
            }}
          />
        ),
      },
      q653: {
        title: "Q809. Expressive Words (Q653)",
        content: (
          <Comp
            title="Q809. Expressive Words (Q653)"
            content1={
              <>
                Sometimes people repeat letters to represent extra feeling. For
                example:
                <br /> "hello" -&gt; "heeellooo"
                <br /> "hi" -&gt; "hiiii" In these strings like "heeellooo", we
                have groups of adjacent letters that are all the same: "h",
                "eee", "ll", "ooo".
                <br />
                You are given a string s and an array of query strings words. A
                query word is stretchy if it can be made to be equal to s by any
                number of applications of the following extension operation:
                choose a group consisting of characters c, and add some number
                of characters c to the group so that the size of the group is
                three or more.
                <br />
                For example, starting with "hello", we could do an extension on
                the group "o" to get "hellooo", but we cannot get "helloo" since
                the group "oo" has a size less than three. Also, we could do
                another extension like "ll" -&gt; "lllll" to get "helllllooo".
                If s = "helllllooo", then the query word "hello" would be
                stretchy because of these two extension operations: query =
                "hello" -&gt; "hellooo" -&gt; "helllllooo" = s.
                <br /> Return the number of query strings that are stretchy.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "heeellooo",
                    <br /> words = ["hello", "hi", "helo"] Output: 1<br />
                    Explanation: <br />
                    <br /> We can extend "e" and "o" in the word "hello" to get
                    "heeellooo".
                    <br /> We can't extend "helo" to get "heeellooo" because the
                    group "ll" is not size 3 or more.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "zzzzzyyyyy", <br />
                    words = ["zzyy","zy","zyy"]
                    <br />
                    Output: 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length, words.length &lt;= 100
                <br />
                1 &lt;= words[i].length &lt;= 100
                <br />s and words[i] consist of lowercase letters
              </>
            }
            tc="n^2"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @param {string[]} words
                * @return {number}
                */
               var expressiveWords = function(s, words) {
                 let res = 0;
                 for (let word of words)
                   if (isStretchy(s, word))
                     ++res;
                 return res;    
               };
               function isStretchy(s,word){
                 let n = s.length;
                 let m = word.length;
                 let j = 0;
                   for (let i = 0; i < n; ++i)
                     if (j < m && s[i] == word[j])
                       ++j;
                     else if (i > 1 && s[i] == s[i - 1] && s[i - 1] == s[i - 2])
                       continue;
                     else if (0 < i && i + 1 < n &&
                         s[i - 1] == s[i] &&
                         s[i] == s[i + 1])
                       continue;
                     else
                       return false;
                   return j == m;  
               }     
               console.log(expressiveWords("heeellooo",["hello", "hi", "helo"]))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q654: {
        title: "Q810. Chalkboard XOR Game (Q654)",
        content: (
          <Comp
            title="Q810. Chalkboard XOR Game (Q654)"
            content1={
              <>
                You are given an array of integers nums represents the numbers
                written on a chalkboard.
                <br />
                Alice and Bob take turns erasing exactly one number from the
                chalkboard, with Alice starting first. If erasing a number
                causes the bitwise XOR of all the elements of the chalkboard to
                become 0, then that player loses. The bitwise XOR of one element
                is that element itself, and the bitwise XOR of no elements is 0.
                <br />
                Also, if any player starts their turn with the bitwise XOR of
                all the elements of the chalkboard equal to 0, then that player
                wins.
                <br />
                Return true if and only if Alice wins the game, assuming both
                players play optimally.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,1,2] <br />
                    Output: false <br />
                    Explanation: <br />
                    Alice has two choices: erase 1 or erase 2. <br />
                    If she erases 1, the nums array becomes [1, 2]. The bitwise
                    XOR of all the elements of the chalkboard is 1 XOR 2 = 3.
                    Now Bob can remove any element he wants, because Alice will
                    be the one to erase the last element and she will lose.
                    <br /> If Alice erases 2 first, now nums become [1, 1]. The
                    bitwise XOR of all the elements of the chalkboard is 1 XOR 1
                    = 0. Alice will lose.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [0,1] <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3] <br />
                    Output: true
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 1000 <br />0 &lt;= nums[i] &lt; 216
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {boolean}
                */
               var xorGame = function(nums) {
                 const xors = nums.reduce((a, b) => a ^ b,0)
                 return xors == 0 || nums.length % 2 == 0; 
               };
               
               console.log(xorGame([1,1,2]))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q655: {
        title: "Q811. Subdomain Visit Count (Q655)",
        content: (
          <Comp
            title="Q811. Subdomain Visit Count (Q655)"
            content1={
              <>
                A website domain "discuss.leetcode.com" consists of various
                subdomains. At the top level, we have "com", at the next level,
                we have "leetcode.com" and at the lowest level,
                "discuss.leetcode.com". When we visit a domain like
                "discuss.leetcode.com", we will also visit the parent domains
                "leetcode.com" and "com" implicitly.
                <br />
                A count-paired domain is a domain that has one of the two
                formats "rep d1.d2.d3" or "rep d1.d2" where rep is the number of
                visits to the domain and d1.d2.d3 is the domain itself.
                <br />
                For example, "9001 discuss.leetcode.com" is a count-paired
                domain that indicates that discuss.leetcode.com was visited 9001
                times.
                <br />
                Given an array of count-paired domains cpdomains, return an
                array of the count-paired domains of each subdomain in the
                input. You may return the answer in any order.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: cpdomains = ["9001 discuss.leetcode.com"]
                    <br /> Output: ["9001 leetcode.com","9001
                    discuss.leetcode.com","9001 com"]
                    <br /> Explanation: We only have one website domain:
                    "discuss.leetcode.com".
                    <br /> As discussed above, the subdomain "leetcode.com" and
                    "com" will also be visited. So they will all be visited 9001
                    times.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: cpdomains = ["900 google.mail.com", "50 yahoo.com",
                    "1 intel.mail.com", "5 wiki.org"]
                    <br /> Output: ["901 mail.com","50 yahoo.com","900
                    google.mail.com","5 wiki.org","5 org","1
                    intel.mail.com","951 com"]
                    <br /> Explanation: We will visit "google.mail.com" 900
                    times, "yahoo.com" 50 times, "intel.mail.com" once and
                    "wiki.org" 5 times.
                    <br /> For the subdomains, we will visit "mail.com" 900 + 1
                    = 901 times, "com" 900 + 50 + 1 = 951 times, and "org" 5
                    times.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= cpdomain.length &lt;= 100 <br />
                1 &lt;= cpdomain[i].length &lt;= 100 <br />
                cpdomain[i] follows either the "repi d1i.d2i.d3i" format or the
                "repi d1i.d2i" format.
                <br /> repi is an integer in the range [1, 104].
                <br /> d1i, d2i, and d3i consist of lowercase English letters.
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} cpdomains
                * @return {string[]}
                */
               var subdomainVisits = function(cpdomains) {
                   const res = [];
                   const count = [];
                   for (let cpdomain of cpdomains) {
                     let space = cpdomain.indexOf(' ');
                     let num = +cpdomain.substring(0, space);
                     let domain = cpdomain.substring(space + 1);
                     count[domain] = ( count[domain] || 0 ) + num;
                     for (let i = 0; i < domain.length; ++i)
                       if (domain[i] == '.') {
                         let subdomain = domain.substring(i + 1);
                         count[subdomain] = ( count[subdomain] || 0 ) + num;
                       }
                   }
                   for (let subdomain of Object.keys(count))
                     res.push(String(count[subdomain]) + ' ' + subdomain);
                   return res;    
               };
               
               console.log(subdomainVisits(["900 google.mail.com", "50 yahoo.com", "1 intel.mail.com", "5 wiki.org"]))`,
                output: `
                '900 google.mail.com',
                '901 mail.com',
                '951 com',
                '50 yahoo.com',
                '1 intel.mail.com',
                '5 wiki.org',
                '5 org'
              ]`,
              },
            }}
          />
        ),
      },
      q656: {
        title: "Q812. Largest Triangle Area (Q656)",
        content: (
          <Comp
            title="Q812. Largest Triangle Area (Q656)"
            content1={
              <>
                Given an array of points on the X-Y plane points where points[i]
                = [xi, yi], return the area of the largest triangle that can be
                formed by any three different points. Answers within 10-5 of the
                actual answer will be accepted.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode812,
                content: (
                  <>
                    Input: points = [[0,0],[0,1],[1,0],[0,2],[2,0]]
                    <br />
                    Output: 2.00000
                    <br />
                    Explanation: The five points are shown in the above figure.
                    The red triangle is the largest.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: points = [[1,0],[0,0],[0,1]] <br />
                    Output: 0.50000
                  </>
                ),
              },
            ]}
            constraints={
              <>
                3 &lt;= points.length &lt;= 50
                <br />
                -50 &lt;= xi, yi &lt;= 50
                <br />
                All the given points are unique.
              </>
            }
            tc="n^2"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} points
                * @return {number}
                */
               var largestTriangleArea = function(points) {
                 let res = 0;
                   for (let A of points)
                     for (let B of points)
                       for (let C of points)
                         res = Math.max(res, 0.5 * Math.abs(
                             (B[0] - A[0]) * (C[1] - A[1]) -
                             (C[0] - A[0]) * (B[1] - A[1])));
                 return res;    
               };
               
               console.log(largestTriangleArea([[1,0],[0,0],[0,1]]))`,
                output: `0.5`,
              },
            }}
          />
        ),
      },
      q657: {
        title: "Q813. Largest Sum of Averages (Q657)",
        content: (
          <Comp
            title="Q813. Largest Sum of Averages (Q657)"
            content1={
              <>
                You are given an integer array nums and an integer k. You can
                partition the array into at most k non-empty adjacent subarrays.
                The score of a partition is the sum of the averages of each
                subarray.
                <br />
                Note that the partition must use every integer in nums, and that
                the score is not necessarily an integer.
                <br />
                Return the maximum score you can achieve of all the possible
                partitions. Answers within 10-6 of the actual answer will be
                accepted.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [9,1,2,3,9],
                    <br /> k = 3<br />
                    Output: 20.00000
                    <br />
                    Explanation: <br />
                    The best choice is to partition nums into [9], [1, 2, 3],
                    [9]. The answer is 9 + (1 + 2 + 3) / 3 + 9 = 20.
                    <br /> We could have also partitioned nums into [9, 1], [2],
                    [3, 9], for example.
                    <br /> That partition would lead to a score of 5 + 2 + 6 =
                    13, which is worse.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4,5,6,7], <br />k = 4<br />
                    Output: 20.50000
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 100 <br />
                1 &lt;= nums[i] &lt;= 10^4 <br />1 &lt;= k &lt;= nums.length
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var largestSumOfAverages = function(nums, k) {
                 const n = nums.length
                 const dp = Array.from(Array(n + 1),()=>Array(k + 1).fill(0));
                 const prefix = Array(n + 1).fill(0);
                 for (let i = 1; i <= n; ++i) {
                   prefix[i] = nums[i - 1] + prefix[i - 1];
                   dp[i][1] = prefix[i] / i;
                 }
                 for (let p = 2; p <= k; ++p)
                   for (let i = p; i <= n; ++i)
                     for (let j = p - 1; j < i; ++j) {
                         let average = (prefix[i] - prefix[j]) / (i - j);
                         dp[i][p] = Math.max(dp[i][p], dp[j][p - 1] + average);
                       }
                 return dp[n][k];   
               };
               
               console.log(largestSumOfAverages([1,2,3,4,5,6,7],4))`,
                output: `20.5`,
              },
            }}
          />
        ),
      },
      q658: {
        title: "Q814. Binary Tree Pruning (Q658)",
        content: (
          <Comp
            title="Q814. Binary Tree Pruning (Q658)"
            content1={
              <>
                Given the root of a binary tree, return the same tree where
                every subtree (of the given tree) not containing a 1 has been
                removed.
                <br />A subtree of a node node is node plus every node that is a
                descendant of node.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode814,
                content: (
                  <>
                    Input: root = [1,null,0,0,1] <br />
                    Output: [1,null,0,null,1] <br />
                    Explanation: <br />
                    Only the red nodes satisfy the property "every subtree not
                    containing a 1".
                    <br /> The diagram on the right represents the answer.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1,0,1,0,0,0,1] <br />
                    Output: [1,null,1,null,1]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1,1,0,1,1,0,1,0]
                    <br />
                    Output: [1,1,0,1,1,null,1]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 200].
                <br />
                Node.val is either 0 or 1.
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root
                 * @return {TreeNode}
                 */
                var pruneTree = function(root) {
                    if (!root)
                      return null;
                    root.left = pruneTree(root.left);
                    root.right = pruneTree(root.right);
                    if (!root.left && !root.right && root.val == 0)
                      return null;
                    return root;
                }; 
                const t = new TreeNode(1)
                t.right = new TreeNode(0)
                t.right.left = new TreeNode(0)
                t.right.right = new TreeNode(1)
                console.log(pruneTree(t))`,
                output: `TreeNode {
                  val: 1,
                  left: null,
                  right: TreeNode {
                    val: 0,
                    left: null,
                    right: TreeNode { val: 1, left: null, right: null }
                  }
                }`,
              },
            }}
          />
        ),
      },
      q659: {
        title: "Q815. Bus Routes (Q659)",
        content: (
          <Comp
            title="Q815. Bus Routes (Q659)"
            content1={
              <>
                You are given an array routes representing bus routes where
                routes[i] is a bus route that the ith bus repeats forever.
                <br /> For example, if routes[0] = [1, 5, 7], this means that
                the 0th bus travels in the sequence 1 -&gt; 5 -&gt; 7 -&gt; 1
                -&gt; 5 -&gt; 7 -&gt; 1 -&gt; ... forever.
                <br /> You will start at the bus stop source (You are not on any
                bus initially), and you want to go to the bus stop target. You
                can travel between bus stops by buses only.
                <br />
                Return the least number of buses you must take to travel from
                source to target. Return -1 if it is not possible.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: routes = [[1,2,7],[3,6,7]],
                    <br /> source = 1, <br />
                    target = 6 Output: 2<br />
                    Explanation: The best strategy is take the first bus to the
                    bus stop 7, then take the second bus to the bus stop 6.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: routes = [[7,12],[4,5,15],[6],[15,19],[9,12,13]],
                    <br /> source = 15, <br />
                    target = 12
                    <br />
                    <br /> Output: -1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= routes.length &lt;= 500.
                <br />
                1 &lt;= routes[i].length &lt;= 10^5
                <br />
                All the values of routes[i] are unique.
                <br />
                sum(routes[i].length) &lt;= 10^5
                <br />
                0 &lt;= routes[i][j] &lt; 10^6
                <br />0 &lt;= source, target &lt; 10^6
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Java: {
                code: `
                // routes = [[1,2,7],[3,6,7]], source = 1, target = 6
                class Solution {
                  public int numBusesToDestination(int[][] routes, int S, int T) {
                    if (S == T)
                      return 0;
                    Map<Integer, List<Integer>> graph = new HashMap<>(); 
                    Set<Integer> usedBuses = new HashSet<>();
                    for (int i = 0; i < routes.length; ++i)
                      for (final int route : routes[i]) {
                        graph.putIfAbsent(route, new ArrayList<>());
                        graph.get(route).add(i);
                      }
                    int ans = 0;
                    Queue<Integer> q = new ArrayDeque<>(Arrays.asList(S));
                    while (!q.isEmpty()) {
                      ++ans;
                      for (int sz = q.size(); sz > 0; --sz) {
                        for (final int bus : graph.get(q.poll()))
                          if (usedBuses.add(bus))
                            for (final int nextRoute : routes[bus])
                              if (nextRoute == T)
                                return ans;
                              else
                                q.offer(nextRoute);
                      }
                    }
                    return -1;
                  }
                }
                `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q660: {
        title: "Q816. Ambiguous Coordinates (Q660)",
        content: (
          <Comp
            title="Q816. Ambiguous Coordinates (Q660)"
            content1={
              <>
                We had some 2-dimensional coordinates, like "(1, 3)" or "(2,
                0.5)". Then, we removed all commas, decimal points, and spaces
                and ended up with the string s.
                <br />
                For example, "(1, 3)" becomes s = "(13)" and "(2, 0.5)" becomes
                s = "(205)".
                <br />
                Return a list of strings representing all possibilities for what
                our original coordinates could have been.
                <br />
                Our original representation never had extraneous zeroes, so we
                never started with numbers like "00", "0.0", "0.00", "1.0",
                "001", "00.01", or any other number that can be represented with
                fewer digits. Also, a decimal point within a number never occurs
                without at least one digit occurring before it, so we never
                started with numbers like ".1".
                <br />
                The final answer list can be returned in any order. All
                coordinates in the final answer have exactly one space between
                them (occurring after the comma.)
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "(123)" <br />
                    Output: ["(1, 2.3)","(1, 23)","(1.2, 3)","(12, 3)"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "(0123)" <br />
                    Output: ["(0, 1.23)","(0, 12.3)","(0, 123)","(0.1,
                    2.3)","(0.1, 23)","(0.12, 3)"]
                    <br /> Explanation: 0.0, 00, 0001 or 00.01 are not allowed.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "(00011)" <br />
                    Output: ["(0, 0.011)","(0.001, 1)"]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                4 &lt;= s.length &lt;= 12 <br />
                s[0] == '(' and s[s.length - 1] == ')'. <br />
                The rest of s are digits.
              </>
            }
            tc="n^3"
            sc="n^3"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {string[]}
                */
               var ambiguousCoordinates = function(s) {
                 const res = [];
                 s = s.substring(1, s.length - 1);
                 for (let i = 1; i < s.length; ++i)
                   for (let x of splits(s.substring(0, i)))
                     for (let y of splits(s.substring(i)))
                       res.push("(" + x + ", " + y + ")");
                 return res;    
               };
               
               function splits(s){
                 if (!s || s.length > 1 && s.charAt(0) == '0' && s.charAt(s.length - 1) == '0')
                   return [];
                 if (s.charAt(s.length - 1) == '0')
                   return [s];
                 if (s.charAt(0) == '0')
                   return ["0." + s.substring(1)];
                 const res = [s]
                 for (let i = 1; i < s.length; ++i)
                   res.push(s.substring(0, i) + "." + s.substring(i));
                 return res; 
               }
               
               console.log(ambiguousCoordinates("(123)"))`,
                output: `[ '(1, 23)', '(1, 2.3)', '(12, 3)', '(1.2, 3)' ]`,
              },
            }}
          />
        ),
      },
      q661: {
        title: "Q817. Linked List Components (Q661)",
        content: (
          <Comp
            title="Q817. Linked List Components (Q661)"
            content1={
              <>
                You are given the head of a linked list containing unique
                integer values and an integer array nums that is a subset of the
                linked list values.
                <br />
                Return the number of connected components in nums where two
                values are connected if they appear consecutively in the linked
                list.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: head = [0,1,2,3], nums = [0,1,3] <br />
                    Output: 2 <br />
                    Explanation: 0 and 1 are connected, so [0, 1] and [3] are
                    the two connected components.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: head = [0,1,2,3,4], nums = [0,3,1,4]
                    <br />
                    Output: 2<br />
                    Explanation: 0 and 1 are connected, 3 and 4 are connected,
                    so [0, 1] and [3, 4] are the two connected components.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the linked list is n. <br />
                1 &lt;= n &lt;= 104 <br />
                0 &lt;= Node.val &lt; n <br />
                All the values Node.val are unique. <br />
                1 &lt;= nums.length &lt;= n <br />
                0 &lt;= nums[i] &lt; n <br />
                All the values of nums are unique.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function ListNode(val, next) {
                  this.val = (val===undefined ? 0 : val)
                  this.next = (next===undefined ? null : next)
                 }
                 
                 /**
                  * @param {ListNode} head
                  * @param {number[]} nums
                  * @return {number}
                  */
                 var numComponents = function(head, nums) {
                   let res = 0;
                   const setG = new Set();
                   for (let g of nums)
                     setG.add(g);
                   for (; head; head = head.next)
                     if (setG.has(head.val) && (!head.next || !setG.has(head.next.val)))
                     ++res;
                   return res;    
                 };
                 
                 const l = new ListNode(0)
                 l.next = new ListNode(1)
                 l.next.next = new ListNode(2)
                 l.next.next.next = new ListNode(3)
                 console.log(numComponents(l,[0,1,3]))
                 `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q662: {
        title: "Q818. Race Car (Q662)",
        content: (
          <Comp
            title="Q818. Race Car (Q662)"
            content1={
              <>
                Your car starts at position 0 and speed +1 on an infinite number
                line. Your car can go into negative positions. Your car drives
                automatically according to a sequence of instructions 'A'
                (accelerate) and 'R' (reverse):
                <br />
                When you get an instruction 'A', your car does the following:
                <br />
                position += speed
                <br />
                speed *= 2<br />
                When you get an instruction 'R', your car does the following:
                <br />
                If your speed is positive then speed = -1
                <br />
                otherwise speed = 1<br />
                Your position stays the same.
                <br />
                For example, after commands "AAR", your car goes to positions 0
                --&gt; 1 --&gt; 3 --&gt; 3, and your speed goes to 1 --&gt; 2
                --&gt; 4 --&gt; -1.
                <br />
                Given a target position target, return the length of the
                shortest sequence of instructions to get there.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: target = 3 <br />
                    Output: 2 <br />
                    Explanation: <br />
                    The shortest instruction sequence is "AA". <br />
                    Your position goes from 0 --&gt; 1 --&gt; 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: target = 6 <br />
                    Output: 5 <br />
                    Explanation: <br />
                    The shortest instruction sequence is "AAARA". <br />
                    Your position goes from 0 --&gt; 1 --&gt; 3 --&gt; 7 --&gt;
                    7 --&gt; 6.
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= target &lt;= 10^4</>}
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // target 3
                class Solution {
                  public int racecar(int target) {
                    dp = new int[target + 1];
                    Arrays.fill(dp, 1, dp.length, -1);
                    return rc(target);
                  }
                  private int[] dp;
                  private int rc(int i) {
                    if (dp[i] >= 0)
                      return dp[i];
                    int ans = Integer.MAX_VALUE;
                    int x = 1;          
                    int j = (1 << x) - 1; 
                    for (; j < i; j = (1 << ++x) - 1)
                      for (int y = 0, k = 0; k < j; k = (1 << ++y) - 1)
                        ans = Math.min(ans, (x + 1) + (y + 1) + rc(i - (j - k)));
                    ans = Math.min(ans, i == j ? x : x + 1 + rc(j - i));
                    return dp[i] = ans;
                  }
                }
                `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q663: {
        title: "Q819. Most Common Word (Q663)",
        content: (
          <Comp
            title="Q819. Most Common Word (Q663)"
            content1={
              <>
                Given a string paragraph and a string array of the banned words
                banned, return the most frequent word that is not banned. It is
                guaranteed there is at least one word that is not banned, and
                that the answer is unique.
                <br />
                The words in paragraph are case-insensitive and the answer
                should be returned in lowercase.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: paragraph = "Bob hit a ball, the hit BALL flew far
                    after it was hit.", banned = ["hit"]
                    <br /> Output: "ball"
                    <br />
                    Explanation: <br />
                    "hit" occurs 3 times, but it is a banned word.
                    <br />
                    "ball" occurs twice (and no other word does), so it is the
                    most frequent non-banned word in the paragraph.
                    <br /> Note that words in the paragraph are not case
                    sensitive,
                    <br /> that punctuation is ignored (even if adjacent to
                    words, such as "ball,"),
                    <br />
                    and that "hit" isn't the answer even though it occurs more
                    because it is banned.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: paragraph = "a.",
                    <br /> banned = []
                    <br />
                    Output: "a"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= paragraph.length &lt;= 1000
                <br />
                paragraph consists of English letters, space ' ', or one of the
                symbols: "!?',;.".
                <br />
                0 &lt;= banned.length &lt;= 100
                <br />
                1 &lt;= banned[i].length &lt;= 10
                <br />
                banned[i] consists of only lowercase English letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: paragraph = "a.", banned = []
                class Solution {
                  public String mostCommonWord(String paragraph, String[] banned) {
                    Pair<String, Integer> ans = new Pair<>("", 0);
                    Set<String> bannedSet = new HashSet<>(Arrays.asList(banned));
                    Map<String, Integer> count = new HashMap<>();
                    String[] words = paragraph.replaceAll("\\W+", " ").toLowerCase().split("\\s+");
                    for (final String word : words)
                      if (!bannedSet.contains(word)) {
                        count.put(word, count.getOrDefault(word, 0) + 1);
                        if (count.get(word) > ans.getValue())
                          ans = new Pair<>(word, count.get(word));
                      }
                
                    return ans.getKey();
                  }
                }
                `,
                output: `a`,
              },
            }}
          />
        ),
      },
      q664: {
        title: "Q820. Short Encoding of Words (Q664)",
        content: (
          <Comp
            title="Q820. Short Encoding of Words (Q664)"
            content1={
              <>
                A valid encoding of an array of words is any reference string s
                and array of indices indices such that:
                <br />
                words.length == indices.length <br />
                The reference string s ends with the '#' character. <br />
                For each index indices[i], the substring of s starting from
                indices[i] and up to (but not including) the next '#' character
                is equal to words[i].
                <br /> Given an array of words, return the length of the
                shortest reference string s possible of any valid encoding of
                words.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: words = ["time", "me", "bell"] <br />
                    Output: 10 <br />
                    Explanation: A valid encoding would be s = "time#bell#" and
                    indices = [0, 2, 5]. <br />
                    words[0] = "time", the substring of s starting from
                    indices[0] = 0 to the next '#' is underlined in "time#bell#"
                    <br /> words[1] = "me", the substring of s starting from
                    indices[1] = 2 to the next '#' is underlined in "time#bell#"
                    <br /> words[2] = "bell", the substring of s starting from
                    indices[2] = 5 to the next '#' is underlined in "time#bell#"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words = ["t"] <br />
                    Output: 2 <br />
                    Explanation: A valid encoding would be s = "t#" and indices
                    = [0].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= words.length &lt;= 2000
                <br />
                1 &lt;= words[i].length &lt;= 7<br />
                words[i] consists of only lowercase letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: words = ["t"]
                class TrieNode {
                  public TrieNode[] children = new TrieNode[26];
                  public int depth = 0;
                }
                class Solution {
                  public int minimumLengthEncoding(String[] words) {
                    int ans = 0;
                    TrieNode root = new TrieNode();
                    List<TrieNode> heads = new ArrayList<>();
                    for (final String word : new HashSet<>(Arrays.asList(words)))
                      heads.add(insert(root, word));
                    for (TrieNode head : heads)
                      if (Arrays.stream(head.children).allMatch(child -> child == null))
                        ans += head.depth + 1;
                    return ans;
                  }
                  private TrieNode insert(TrieNode root, final String word) {
                    TrieNode node = root;
                    for (final char c : new StringBuilder(word).reverse().toString().toCharArray()) {
                      if (node.children[c - 'a'] == null)
                        node.children[c - 'a'] = new TrieNode();
                      node = node.children[c - 'a'];
                    }
                    node.depth = word.length();
                    return node;
                  }
                }`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q665: {
        title: "Q821. Shortest Distance to a Character (Q665)",
        content: (
          <Comp
            title="Q821. 821. Shortest Distance to a Character (Q665)"
            content1={
              <>
                Given a string s and a character c that occurs in s, return an
                array of integers answer where answer.length == s.length and
                answer[i] is the distance from index i to the closest occurrence
                of character c in s.
                <br />
                The distance between two indices i and j is abs(i - j), where
                abs is the absolute value function.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "loveleetcode", c = "e" <br />
                    Output: [3,2,1,0,1,0,0,1,2,2,1,0] <br />
                    Explanation: The character 'e' appears at indices 3, 5, 6,
                    and 11 (0-indexed). <br />
                    The closest occurrence of 'e' for index 0 is at index 3, so
                    the distance is abs(0 - 3) = 3. <br />
                    The closest occurrence of 'e' for index 1 is at index 3, so
                    the distance is abs(1 - 3) = 2. <br />
                    For index 4, there is a tie between the 'e' at index 3 and
                    the 'e' at index 5, but the distance is still the same:
                    abs(4 - 3) == abs(4 - 5) = 1.
                    <br /> The closest occurrence of 'e' for index 8 is at index
                    6, so the distance is abs(8 - 6) = 2.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "aaab", c = "b" <br />
                    Output: [3,2,1,0]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^4
                <br />
                s[i] and c are lowercase English letters.
                <br />
                It is guaranteed that c occurs at least once in s.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `
                // Input: s = "loveleetcode", c = "e"
                /**
                * @param {string} s
                * @param {character} c
                * @return {number[]}
                */
               var shortestToChar = function(s, c) {
                  const arr = s.split("")
                  let count = 0;
                  const answer = []
                   const newArr = arr.map( (item, index) => {
                       if(item === c) { 
                           count = count + 1;
                          return arr[index] = count
                       }else { 
                           return arr[index] = item
                       }
                   })
                   for(let i=0; i<arr.length; i++) { 
                       let min = 0;
                       for(let j=1; j<=count;j++) {
                           const index = newArr.indexOf(j)
                           const distance = Math.abs(i - index);
                           if( arr[i] === j) { 
                               min = 0;
                               break;
                           }else if(distance < min || min ===0) { 
                               min = distance
                           }
                       }
                       answer.push(min)   
                   }
                   return answer  
               };`,
                output: `[3,2,1,0,1,0,0,1,2,2,1,0]`,
              },
            }}
          />
        ),
      },
      q666: {
        title: "Q822. Card Flipping Game (Q666)",
        content: (
          <Comp
            title="Q822. Card Flipping Game (Q666)"
            content1={
              <>
                You are given n cards, with a positive integer printed on the
                front and back of each card (possibly different). You can flip
                any number of cards (possibly zero).
                <br />
                After choosing the front and the back of each card, you will
                pick each card, and if the integer printed on the back of this
                card is not printed on the front of any other card, then this
                integer is good.
                <br />
                You are given two integer array fronts and backs where fronts[i]
                and backs[i] are the integers printer on the front and the back
                of the ith card respectively.
                <br />
                Return the smallest good and integer after flipping the cards.
                If there are no good integers, return 0.
                <br />
                Note that a flip swaps the front and back numbers, so the value
                on the front is now on the back and vice versa.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: fronts = [1,2,4,4,7], <br />
                    backs = [1,3,4,1,3]
                    <br />
                    Output: 2<br />
                    Explanation: If we flip the second card, the fronts are
                    [1,3,4,4,7] and the backs are [1,2,4,1,3].
                    <br /> We choose the second card, which has the number 2 on
                    the back, and it is not on the front of any card, so 2 is
                    good.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: fronts = [1],
                    <br /> backs = [1]
                    <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == fronts.length <br />
                n == backs.length <br />
                1 &lt;= n &lt;= 1000 <br />1 &lt;= fronts[i], backs[i] &lt;=
                2000
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} fronts
                * @param {number[]} backs
                * @return {number}
                */
               var flipgame = function(fronts, backs) {
                 let res = 2001;
                 const same = new Set();
                 for (let i = 0; i < fronts.length; ++i)
                     if (fronts[i] == backs[i])
                       same.add(fronts[i]);
                 for (let f of fronts)
                     if (!same.has(f))
                       res = Math.min(res, f);
                  for (let b of backs)
                     if (!same.has(b))
                       res = Math.min(res, b);
                   return res == 2001 ? 0 : res;  
               };
               console.log(flipgame([1,2,4,4,7],[1,3,4,1,3]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q667: {
        title: "Q823. Binary Trees With Factors (Q667)",
        content: (
          <Comp
            title="Q823. Binary Trees With Factors (Q667)"
            content1={
              <>
                Given an array of unique integers, arr, where each integer
                arr[i] is strictly greater than 1.
                <br />
                We make a binary tree using these integers, and each number may
                be used for any number of times. Each non-leaf node's value
                should be equal to the product of the values of its children.
                <br />
                Return the number of binary trees we can make. The answer may be
                too large so return the answer modulo 109 + 7.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [2,4] <br />
                    Output: 3 <br />
                    Explanation: We can make these trees: [2], [4], [4, 2, 2]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [2,4,5,10] <br />
                    Output: 7 <br />
                    Explanation: We can make these trees: [2], [4], [5], [10],
                    [4, 2, 2], [10, 2, 5], [10, 5, 2].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= arr.length &lt;= 1000
                <br />
                2 &lt;= arr[i] &lt;= 10^9
                <br />
                All the values of arr are unique.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: arr = [2,4,5,10]
                class Solution {
                  public int numFactoredBinaryTrees(int[] arr) {
                    final int kMod = (int) 1e9 + 7;
                    final int n = arr.length;
                    long[] dp = new long[n];
                    Map<Integer, Integer> m = new HashMap<>();
                    Arrays.sort(arr);
                    Arrays.fill(dp, 1);
                    for (int i = 0; i < n; ++i)
                      m.put(arr[i], i);
                    for (int i = 0; i < n; ++i) 
                      for (int j = 0; j < i; ++j)
                        if (arr[i] % arr[j] == 0) { 
                          final int right = arr[i] / arr[j];
                          if (m.containsKey(right)) {
                            dp[i] += dp[j] * dp[m.get(right)];
                            dp[i] %= kMod;
                          }
                        }
                    return (int) (Arrays.stream(dp).sum() % kMod);
                  }
                }
                `,
                output: `7`,
              },
            }}
          />
        ),
      },
      q668: {
        title: "Q824. Goat Latin (Q668)",
        content: (
          <Comp
            title="Q824. Goat Latin (Q668)"
            content1={
              <>
                You are given a string sentence that consist of words separated
                by spaces. Each word consists of lowercase and uppercase letters
                only.
                <br />
                We would like to convert the sentence to "Goat Latin" (a made-up
                language similar to Pig Latin.) The rules of Goat Latin are as
                follows:
                <br />
                If a word begins with a vowel ('a', 'e', 'i', 'o', or 'u'),
                append "ma" to the end of the word. For example, the word
                "apple" becomes "applema".
                <br />
                If a word begins with a consonant (i.e., not a vowel), remove
                the first letter and append it to the end, then add "ma".
                <br /> For example, the word "goat" becomes "oatgma".
                <br />
                <br /> Add one letter 'a' to the end of each word per its word
                index in the sentence, starting with 1.
                <br /> For example, the first word gets "a" added to the end,
                the second word gets "aa" added to the end, and so on.
                <br /> Return the final sentence representing the conversion
                from sentence to Goat Latin.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: sentence = "I speak Goat Latin" <br />
                    Output: "Imaa peaksmaaa oatGmaaaa atinLmaaaaa"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: sentence = "The quick brown fox jumped over the lazy
                    dog" <br />
                    Output: "heTmaa uickqmaaa rownbmaaaa oxfmaaaaa umpedjmaaaaaa
                    overmaaaaaaa hetmaaaaaaaa azylmaaaaaaaaa ogdmaaaaaaaaaa"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= sentence.length &lt;= 150 <br />
                sentence consists of English letters and spaces. <br />
                sentence has no leading or trailing spaces. <br />
                All the words in sentence are separated by a single space.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} sentence
                * @return {string}
                */
               var toGoatLatin = function(sentence) {
                   let res = "";
                   const vowels = "aeiouAEIOU";
                   const words = sentence.split(" ");
                   let i = 1;
                   for (let word of words) {
                     if (i > 1)
                       res += " ";
                     if (vowels.includes("" + word.charAt(0)))
                       res += word;
                     else
                       res += word.substring(1) + word.charAt(0);
                     res += "ma"
                            + "a".repeat(i++);
                   }
                   return res;
               };
               
               console.log(toGoatLatin("The quick brown fox jumped over the lazy dog"))`,
                output: `heTmaa uickqmaaa rownbmaaaa oxfmaaaaa umpedjmaaaaaa overmaaaaaaa hetmaaaaaaaa azylmaaaaaaaaa ogdmaaaaaaaaaa`,
              },
            }}
          />
        ),
      },
      q669: {
        title: "Q825. Friends Of Appropriate Ages (Q669)",
        content: (
          <Comp
            title="Q825. Friends Of Appropriate Ages (Q669)"
            content1={
              <>
                There are n persons on a social media website. You are given an
                integer array ages where ages[i] is the age of the ith person.
                <br />
                A Person x will not send a friend request to a person y (x != y)
                if any of the following conditions is true:
                <br />
                age[y] &lt;= 0.5 * age[x] + 7
                <br />
                age[y] &gt; age[x]
                <br />
                age[y] &gt; 100 && age[x] &lt; 100
                <br />
                Otherwise, x will send a friend request to y.
                <br />
                Note that if x sends a request to y, y will not necessarily send
                a request to x. Also, a person will not send a friend request to
                themself.
                <br />
                Return the total number of friend requests made.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: ages = [16,16] <br />
                    Output: 2 <br />
                    Explanation: 2 people friend request each other.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: ages = [16,17,18] <br />
                    Output: 2 <br />
                    Explanation: Friend requests are made 17 -&gt; 16, 18 -&gt;
                    17.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: ages = [20,30,100,110,120] <br />
                    Output: 3 <br />
                    Explanation: Friend requests are made 110 -&gt; 100, 120
                    -&gt; 110, 120 -&gt; 100.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == ages.length <br />
                1 &lt;= n &lt;= 2 * 10^4 <br />1 &lt;= ages[i] &lt;= 120
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: ages = [16,16]
                class Solution {
                  public int numFriendRequests(int[] ages) {
                    int ans = 0;
                    int[] count = new int[121];
                    for (final int age : ages)
                      ++count[age];
                    for (int ageA = 1; ageA <= 120; ++ageA)
                      for (int ageB = 1; ageB <= 120; ++ageB) {
                        final int countA = count[ageA];
                        final int countB = count[ageB];
                        if (countA > 0 && countB > 0 && request(ageA, ageB))
                          if (ageA == ageB)
                            ans += countA * (countB - 1);
                          else
                            ans += countA * countB;
                      }
                    return ans;
                  }
                  private boolean request(int ageA, int ageB) {
                    return !(ageB <= 0.5 * ageA + 7 || ageB > ageA || ageB > 100 && ageA < 100);
                  }
                }
                `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q670: {
        title: "Q826. Most Profit Assigning Work (Q670)",
        content: (
          <Comp
            title="Q826. 826. Most Profit Assigning Work (Q670)"
            content1={
              <>
                You have n jobs and m workers. You are given three arrays:
                difficulty, profit, and worker where:
                <br />
                difficulty[i] and profit[i] are the difficulty and the profit of
                the ith job, and
                <br /> worker[j] is the ability of jth worker (i.e., the jth
                worker can only complete a job with difficulty at most
                worker[j]).
                <br /> Every worker can be assigned at most one job, but one job
                can be completed multiple times.
                <br />
                For example, if three workers attempt the same job that pays $1,
                then the total profit will be $3. If a worker cannot complete
                any job, their profit is $0.
                <br /> Return the maximum profit we can achieve after assigning
                the workers to the jobs.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: difficulty = [2,4,6,8,10], <br />
                    profit = [10,20,30,40,50], worker = [4,5,6,7]
                    <br /> Output: 100 <br />
                    Explanation: Workers are assigned jobs of difficulty
                    [4,4,6,6] and they get a profit of [20,20,30,30] separately.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: difficulty = [85,47,57], <br />
                    profit = [24,66,99], worker = [40,25,25]
                    <br /> Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == difficulty.length <br />
                n == profit.length <br />
                m == worker.length <br />
                1 &lt;= n, m &lt;= 104 <br />1 &lt;= difficulty[i], profit[i],
                worker[i] &lt;= 105
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: difficulty = [2,4,6,8,10], profit = [10,20,30,40,50], worker = [4,5,6,7]
                class Solution {
                  public int maxProfitAssignment(int[] difficulty, int[] profit, int[] worker) {
                    int ans = 0;
                    List<Pair<Integer, Integer>> jobs = new ArrayList<>();
                    for (int i = 0; i < difficulty.length; ++i)
                      jobs.add(new Pair<>(difficulty[i], profit[i]));
                    Collections.sort(jobs, Comparator.comparing(Pair::getKey));
                    Arrays.sort(worker);
                    int i = 0;
                    int maxProfit = 0;
                    for (final int w : worker) {
                      for (; i < jobs.size() && w >= jobs.get(i).getKey(); ++i)
                        maxProfit = Math.max(maxProfit, jobs.get(i).getValue());
                      ans += maxProfit;
                    }
                    return ans;
                  }
                }
                `,
                output: `100`,
              },
            }}
          />
        ),
      },
      q671: {
        title: "Q827.  Making A Large Island (Q671)",
        content: (
          <Comp
            title="Q827. Making A Large Island (Q671)"
            content1={
              <>
                You are given an n x n binary matrix grid. You are allowed to
                change at most one 0 to be 1.
                <br />
                Return the size of the largest island in grid after applying
                this operation.
                <br />
                An island is a 4-directionally connected group of 1s.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: grid = [[1,0],[0,1]] <br />
                    Output: 3 <br />
                    Explanation: Change one 0 to 1 and connect two 1s, then we
                    get an island with area = 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[1,1],[1,0]] <br />
                    Output: 4 <br />
                    Explanation: Change the 0 to 1 and make the island bigger,
                    only one island with area = 4.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[1,1],[1,1]] <br />
                    Output: 4 <br />
                    Explanation: Can't change any 0 to 1, only one island with
                    area = 4.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == grid.length <br />
                n == grid[i].length <br />
                1 &lt;= n &lt;= 500 <br />
                grid[i][j] is either 0 or 1.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: grid = [[1,1],[1,1]]
                class Solution {
                  public int largestIsland(int[][] grid) {
                    final int m = grid.length;
                    final int n = grid[0].length;
                    int maxSize = 0;
                    List<Integer> sizes = new ArrayList<>(Arrays.asList(0, 0));
                    for (int i = 0; i < m; ++i)
                      for (int j = 0; j < n; ++j)
                        if (grid[i][j] == 1) {
                          sizes.add(paint(grid, i, j, sizes.size())); // paint 2, 3, ...
                        }
                    for (int i = 0; i < m; ++i)
                      for (int j = 0; j < n; ++j)
                        if (grid[i][j] == 0) {
                          Set<Integer> neighborIds =
                              new HashSet<>(Arrays.asList(getId(grid, i - 1, j), getId(grid, i + 1, j),
                                                          getId(grid, i, j + 1), getId(grid, i, j - 1)));
                          maxSize = Math.max(maxSize, 1 + getSize(grid, neighborIds, sizes));
                        }
                    return maxSize == 0 ? m * n : maxSize;
                  }
                  private int paint(int[][] grid, int i, int j, int id) {
                    if (i < 0 || i == grid.length || j < 0 || j == grid[0].length)
                      return 0;
                    if (grid[i][j] != 1)
                      return 0;
                    grid[i][j] = id; 
                    return 1 + paint(grid, i + 1, j, id) + paint(grid, i - 1, j, id) + paint(grid, i, j + 1, id) +
                        paint(grid, i, j - 1, id);
                  }
                  private int getId(int[][] grid, int i, int j) {
                    if (i < 0 || i == grid.length || j < 0 || j == grid[0].length)
                      return 0; 
                    return grid[i][j];
                  }
                  private int getSize(int[][] grid, Set<Integer> neighborIds, List<Integer> sizes) {
                    int size = 0;
                    for (final int neighborId : neighborIds)
                      size += sizes.get(neighborId);
                    return size;
                  }
                }`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q672: {
        title:
          "Q828. Count Unique Characters of All Substrings of a Given String (Q672)",
        content: (
          <Comp
            title="Q828. Count Unique Characters of All Substrings of a Given String (Q672)"
            content1={
              <>
                Let's define a function countUniqueChars(s) that returns the
                number of unique characters on s.
                <br />
                For example, calling countUniqueChars(s) if s = "LEETCODE" then
                "L", "T", "C", "O", "D" are the unique characters since they
                appear only once in s, therefore countUniqueChars(s) = 5.
                <br />
                Given a string s, return the sum of countUniqueChars(t) where t
                is a substring of s.
                <br />
                Notice that some substrings can be repeated so in this case you
                have to count the repeated ones too.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "ABC" <br />
                    Output: 10 <br />
                    Explanation: All possible substrings are:
                    "A","B","C","AB","BC" and "ABC".
                    <br /> Evey substring is composed with only unique letters.
                    <br /> Sum of lengths of all substring is 1 + 1 + 1 + 2 + 2
                    + 3 = 10
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "ABA" <br />
                    Output: 8 <br />
                    Explanation: The same as example 1, except
                    countUniqueChars("ABA") = 1.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "LEETCODE" <br />
                    Output: 92
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 105
                <br />s consists of uppercase English letters only.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               var uniqueLetterString = function(s) {
                 let ans = 0;
                 let count = 0;
                 const lastCount = Array(26).fill(0);
                 const lastSeen = Array(26).fill(-1);
                 for (let i = 0; i < s.length; ++i) {
                   let c = s[i].charCodeAt(0) - 65;
                   let currentCount = i - lastSeen[c];
                   count = count - lastCount[c] + currentCount;
                   lastCount[c] = currentCount;
                   lastSeen[c] = i;
                   ans += count;
                 }
                 return ans;  
               };
               console.log(uniqueLetterString("ABA"))`,
                output: `8`,
              },
            }}
          />
        ),
      },
      q673: {
        title: "Q829. Consecutive Numbers Sum (Q673)",
        content: (
          <Comp
            title="Q829. Consecutive Numbers Sum (Q673)"
            content1={
              <>
                Given an integer n, return the number of ways you can write n as
                the sum of consecutive positive integers.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 5<br />
                    Output: 2<br />
                    Explanation: 5 = 2 + 3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 9<br />
                    Output: 3<br />
                    Explanation: 9 = 4 + 5 = 2 + 3 + 4
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 15
                    <br />
                    Output: 4<br />
                    Explanation: 15 = 8 + 7 = 4 + 5 + 6 = 1 + 2 + 3 + 4 + 5
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 10^9</>}
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               var consecutiveNumbersSum = function(n) {
                 let res = 0;
                 for (let i = 1, triangleNum = i; triangleNum <= n; ++i, triangleNum += i)
                 if ((n - triangleNum) % i == 0)
                   ++res;
                 return res;
               };
               
               console.log(consecutiveNumbersSum(15))`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q674: {
        title: "Q830. Positions of Large Groups (Q674)",
        content: (
          <Comp
            title="Q830. Positions of Large Groups (Q674)"
            content1={
              <>
                In a string s of lowercase letters, these letters form
                consecutive groups of the same character.
                <br />
                For example, a string like s = "abbxxxxzyy" has the groups "a",
                "bb", "xxxx", "z", and "yy".
                <br />
                A group is identified by an interval [start, end], where start
                and end denote the start and end indices (inclusive) of the
                group. In the above example, "xxxx" has the interval [3,6].
                <br />
                A group is considered large if it has 3 or more characters.
                <br />
                Return the intervals of every large group sorted in increasing
                order by start index.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "abbxxxxzzy" <br />
                    Output: [[3,6]] <br />
                    Explanation: "xxxx" is the only large group with start index
                    3 and end index 6.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "abc" <br />
                    Output: [] <br />
                    Explanation: We have groups "a", "b", and "c", none of which
                    are large groups.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "abcdddeeeeaabbbcd" <br />
                    Output: [[3,5],[6,9],[12,14]] <br />
                    Explanation: The large groups are "ddd", "eeee", and "bbb".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 1000
                <br />s contains lowercase English letters only.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number[][]}
                */
               var largeGroupPositions = function(s) {
                 let n = s.length;
                 const res = [];
                   for (let i = 0, j = 0; i < n; i = j) {
                     while (j < n && s[j] == s[i])
                       ++j;
                     if (j - i >= 3)
                       res.push([i, j - 1]);
                   }
                 return res;   
               };
               console.log(largeGroupPositions("abcdddeeeeaabbbcd"))`,
                output: `[ [ 3, 5 ], [ 6, 9 ], [ 12, 14 ] ]`,
              },
            }}
          />
        ),
      },
      q675: {
        title: "Q831. Masking Personal Information (Q675)",
        content: (
          <Comp
            title="Q831. Masking Personal Information (Q675)"
            content1={
              <>
                You are given a personal information string s, representing
                either an email address or a phone number. Return the masked
                personal information using the below rules.
                <br />
                Email address:
                <br />
                An email address is:
                <br />
                A name consisting of uppercase and lowercase English letters,
                followed by
                <br /> The '@' symbol, followed by
                <br /> The domain consisting of uppercase and lowercase English
                letters with a dot '.' somewhere in the middle (not the first or
                last character).
                <br /> To mask an email:
                <br /> The uppercase letters in the name and domain must be
                converted to lowercase letters.
                <br /> The middle letters of the name (i.e., all but the first
                and last letters) must be replaced by 5 asterisks "*****".
                <br /> Phone number:
                <br /> A phone number is formatted as follows:
                <br /> The phone number contains 10-13 digits.
                <br /> The last 10 digits make up the local number.
                <br /> The remaining 0-3 digits, in the beginning, make up the
                country code.
                <br />
                Separation characters from the set {
                  "{'+', '-', '(', ')', ' '}"
                }{" "}
                separate the above digits in some way.
                <br />
                To mask a phone number:
                <br /> Remove all separation characters.
                <br /> The masked phone number should have the form:
                <br /> "***-***-XXXX" if the country code has 0 digits.
                <br /> "+*-***-***-XXXX" if the country code has 1 digit.
                <br /> "+**-***-***-XXXX" if the country code has 2 digits.
                <br /> "+***-***-***-XXXX" if the country code has 3 digits.
                <br /> "XXXX" is the last 4 digits of the local number.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "LeetCode@LeetCode.com"
                    <br />
                    Output: "l*****e@leetcode.com"
                    <br />
                    Explanation: s is an email address.
                    <br />
                    The name and domain are converted to lowercase, and the
                    middle of the name is replaced by 5 asterisks.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "AB@qq.com"
                    <br />
                    Output: "a*****b@qq.com"
                    <br />
                    Explanation: s is an email address.
                    <br />
                    The name and domain are converted to lowercase, and the
                    middle of the name is replaced by 5 asterisks.
                    <br /> Note that even though "ab" is 2 characters, it still
                    must have 5 asterisks in the middle.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "1(234)567-890"
                    <br />
                    Output: "***-***-7890"
                    <br />
                    Explanation: s is a phone number.
                    <br />
                    There are 10 digits, so the local number is 10 digits and
                    the country code is 0 digits.
                    <br /> Thus, the resulting masked number is "***-***-7890".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                s is either a valid email or a phone number. <br />
                If s is an email: <br />
                8 &lt;= s.length &lt;== 40 <br />
                s consists of uppercase and lowercase English letters and
                exactly one '@' symbol and '.' symbol.
                <br /> If s is a phone number: <br />
                10 &lt;== s.length &lt;== 20 <br />s consists of digits, spaces,
                and the symbols '(', ')', '-', and '+'.
              </>
            }
            tc="1"
            sc="1"
            codes={{
              Java: {
                code: `
                // Input: s = "1(234)567-890"
                class Solution {
                  public String maskPII(String S) {
                    final int atIndex = S.indexOf('@');
                    if (atIndex > 0) {
                      S = S.toLowerCase();
                      return S.charAt(0) + "*****" + S.substring(atIndex - 1);
                    }
                    StringBuilder sb = new StringBuilder();
                    for (final char c : S.toCharArray())
                      if (Character.isDigit(c))
                        sb.append(c);
                    if (sb.length() == 10)
                      return "***-***-" + sb.substring(sb.length() - 4).toString();
                    return '+' + "*".repeat(sb.length() - 10) + "-***-***-" +
                        sb.substring(sb.length() - 4).toString();
                  }
                }
                `,
                output: `"***-***-7890"`,
              },
            }}
          />
        ),
      },
      q676: {
        title: "Q832. Flipping an Image (Q676)",
        content: (
          <Comp
            title="Q832. Flipping an Image (Q676)"
            content1={
              <>
                Given an n x n binary matrix image, flip the image horizontally,
                then invert it, and return the resulting image.
                <br />
                To flip an image horizontally means that each row of the image
                is reversed.
                <br />
                For example, flipping [1,1,0] horizontally results in [0,1,1].
                <br /> To invert an image means that each 0 is replaced by 1,
                and each 1 is replaced by 0.
                <br />
                For example, inverting [0,1,1] results in [1,0,0].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: image = [[1,1,0],[1,0,1],[0,0,0]] <br />
                    Output: [[1,0,0],[0,1,0],[1,1,1]] <br />
                    Explanation: First reverse each row:
                    [[0,1,1],[1,0,1],[0,0,0]]. <br />
                    Then, invert the image: [[1,0,0],[0,1,0],[1,1,1]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: image = [[1,1,0,0],[1,0,0,1],[0,1,1,1],[1,0,1,0]]{" "}
                    <br />
                    Output: [[1,1,0,0],[0,1,1,0],[0,0,0,1],[1,0,1,0]] <br />
                    Explanation: First reverse each row:
                    [[0,0,1,1],[1,0,0,1],[1,1,1,0],[0,1,0,1]]. <br />
                    Then invert the image: <br />
                    [[1,1,0,0],[0,1,1,0],[0,0,0,1],[1,0,1,0]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == image.length
                <br />
                n == image[i].length
                <br />
                1 &lt;= n &lt;= 20
                <br />
                images[i][j] is either 0 or 1.
              </>
            }
            tc="n^2"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} image
                * @return {number[][]}
                */
               var flipAndInvertImage = function(image) {
                 const n = image.length;
                   for (let i = 0; i < n; ++i)
                     for (let j = 0; j < parseInt((n + 1) / 2); ++j) {
                       const temp = image[i][j];
                       image[i][j] = image[i][n - j - 1] ^ 1;
                       image[i][n - j - 1] = temp ^ 1;
                     }
                   return image;
               };
               console.log(flipAndInvertImage([[1,1,0,0],[1,0,0,1],[0,1,1,1],[1,0,1,0]]))`,
                output: `[ [ 1, 1, 0, 0 ], [ 0, 1, 1, 0 ], [ 0, 0, 0, 1 ], [ 1, 0, 1, 0 ] ]`,
              },
            }}
          />
        ),
      },
      q677: {
        title: "Q833. Find And Replace in String (Q677)",
        content: (
          <Comp
            title="Q833. Find And Replace in String (Q677)"
            content1={
              <>
                You are given a 0-indexed string s that you must perform k
                replacement operations on. The replacement operations are given
                as three 0-indexed parallel arrays, indices, sources, and
                targets, all of length k.
                <br />
                To complete the ith replacement operation:
                <br />
                Check if the substring sources[i] occurs at index indices[i] in
                the original string s.
                <br /> If it does not occur, do nothing.
                <br />
                Otherwise if it does occur, replace that substring with
                targets[i].
                <br />
                For example, if s = "abcd", indices[i] = 0, sources[i] = "ab",
                and targets[i] = "eee", then the result of this replacement will
                be "eeecd".
                <br />
                All replacement operations must occur simultaneously, meaning
                the replacement operations should not affect the indexing of
                each other. The testcases will be generated such that the
                replacements will not overlap.
                <br />
                For example, a testcase with s = "abc", indices = [0, 1], and
                sources = ["ab","bc"] will not be generated because the "ab" and
                "bc" replacements overlap.
                <br />
                Return the resulting string after performing all replacement
                operations on s.
                <br />A substring is a contiguous sequence of characters in a
                string.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode833,
                content: (
                  <>
                    Input: s = "abcd", indices = [0, 2], sources = ["a", "cd"],
                    targets = ["eee", "ffff"]
                    <br />
                    Output: "eeebffff" <br />
                    Explanation: <br />
                    "a" occurs at index 0 in s, so we replace it with "eee".{" "}
                    <br />
                    "cd" occurs at index 2 in s, so we replace it with "ffff".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "abcd", indices = [0, 2], sources = ["ab","ec"],
                    targets = ["eee","ffff"]
                    <br />
                    Output: "eeecd" <br />
                    Explanation: <br />
                    "ab" occurs at index 0 in s, so we replace it with "eee".{" "}
                    <br />
                    "ec" does not occur at index 2 in s, so we do nothing.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 1000 <br />
                k == indices.length == sources.length == targets.length <br />
                1 &lt;= k &lt;= 100 <br />
                0 &lt;= indexes[i] &lt; s.length <br />
                1 &lt;= sources[i].length, targets[i].length &lt;= 50 <br />
                s consists of only lowercase English letters. <br />
                sources[i] and targets[i] consist of only lowercase English
                letters.
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: s = "abcd", indices = [0, 2], sources = ["a", "cd"], targets = ["eee", "ffff"]
                class Solution {
                  public String findReplaceString(String S, int[] indexes, String[] sources, String[] targets) {
                    List<Pair<Integer, Integer>> sortedIndexes = new ArrayList<>();
                    for (int i = 0; i < indexes.length; ++i)
                      sortedIndexes.add(new Pair<>(indexes[i], i));
                    Collections.sort(sortedIndexes, (a, b) -> b.getKey() - a.getKey());
                    for (var sortedIndex : sortedIndexes) {
                      final int index = sortedIndex.getKey();
                      final int i = sortedIndex.getValue();
                      final String source = sources[i];
                      final String target = targets[i];
                      if (S.substring(index, index + source.length()).equals(source))
                        S = S.substring(0, index) + target + S.substring(index + source.length());
                    }
                    return S;
                  }
                }
                `,
                output: `eeebffff`,
              },
            }}
          />
        ),
      },
      q678: {
        title: "Q834. Sum of Distances in Tree (Q678)",
        content: (
          <Comp
            title="Q834. Sum of Distances in Tree (Q678)"
            content1={
              <>
                There is an undirected connected tree with n nodes labeled from
                0 to n - 1 and n - 1 edges.
                <br />
                You are given the integer n and the array edges where edges[i] =
                [ai, bi] indicates that there is an edge between nodes ai and bi
                in the tree.
                <br />
                Return an array answer of length n where answer[i] is the sum of
                the distances between the ith node in the tree and all other
                nodes.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode834,
                content: (
                  <>
                    Input: n = 6, edges = [[0,1],[0,2],[2,3],[2,4],[2,5]]
                    <br /> Output: [8,12,6,10,10,10]
                    <br />
                    Explanation: The tree is shown above.
                    <br />
                    <br /> We can see that dist(0,1) + dist(0,2) + dist(0,3) +
                    dist(0,4) + dist(0,5) equals 1 + 1 + 2 + 2 + 2 = 8.
                    <br />
                    Hence, answer[0] = 8, and so on.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 1, edges = []
                    <br />
                    Output: [0]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2, edges = [[1,0]]
                    <br />
                    Output: [1,1]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 3 * 104 <br />
                edges.length == n - 1 <br />
                edges[i].length == 2 <br />
                0 &lt;= ai, bi &lt; n <br />
                ai != bi <br />
                The given input represents a valid tree.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: n = 6, edges = [[0,1],[0,2],[2,3],[2,4],[2,5]]
                class Solution {
                  public int[] sumOfDistancesInTree(int N, int[][] edges) {
                    int[] ans = new int[N];
                    int[] count = new int[N];
                    Set<Integer>[] tree = new Set[N];
                    Arrays.fill(count, 1);
                    for (int i = 0; i < N; ++i)
                      tree[i] = new HashSet<>();
                    for (int[] e : edges) {
                      final int u = e[0];
                      final int v = e[1];
                      tree[u].add(v);
                      tree[v].add(u);
                    }
                    postorder(tree, 0, -1, count, ans);
                    preorder(tree, 0, -1, count, ans);
                    return ans;
                  }
                  private void postorder(Set<Integer>[] tree, int node, int parent, int[] count, int[] ans) {
                    for (final int child : tree[node]) {
                      if (child == parent)
                        continue;
                      postorder(tree, child, node, count, ans);
                      count[node] += count[child];
                      ans[node] += ans[child] + count[child];
                    }
                  }
                  private void preorder(Set<Integer>[] tree, int node, int parent, int[] count, int[] ans) {
                    for (final int child : tree[node]) {
                      if (child == parent)
                        continue;
                      ans[child] = ans[node] - count[child] + (tree.length - count[child]);
                      preorder(tree, child, node, count, ans);
                    }
                  }
                }`,
                output: `[8,12,6,10,10,10]`,
              },
            }}
          />
        ),
      },
      q679: {
        title: "Q835. Image Overlap (Q679)",
        content: (
          <Comp
            title="Q835. Image Overlap (Q679)"
            content1={
              <>
                You are given two images, img1 and img2, represented as binary,
                square matrices of size n x n. A binary matrix has only 0s and
                1s as values.
                <br />
                We translate one image however we choose by sliding all the 1
                bits left, right, up, and/or down any number of units. We then
                place it on top of the other image. We can then calculate the
                overlap by counting the number of positions that have a 1 in
                both images.
                <br />
                Note also that a translation does not include any kind of
                rotation. Any 1 bits that are translated outside of the matrix
                borders are erased.
                <br />
                Return the largest possible overlap.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: img1 = [[1,1,0],[0,1,0],[0,1,0]], img2 =
                    [[0,0,0],[0,1,1],[0,0,1]]
                    <br />
                    Output: 3<br />
                    Explanation: We translate img1 to right by 1 unit and down
                    by 1 unit.
                    <br />
                    The number of positions that have a 1 in both images is 3
                    (shown in red).
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: img1 = [[1]], <br />
                    img2 = [[1]]
                    <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: img1 = [[0]],
                    <br /> img2 = [[0]]
                    <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == img1.length == img1[i].length <br />
                n == img2.length == img2[i].length <br />
                1 &lt;= n &lt;= 30 <br />
                img1[i][j] is either 0 or 1. <br />
                img2[i][j] is either 0 or 1.
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Java: {
                code: `
                // Input: img1 = [[1,1,0],[0,1,0],[0,1,0]], img2 = [[0,0,0],[0,1,1],[0,0,1]]
                class Solution {
                  public int largestOverlap(int[][] A, int[][] B) {
                    final int n = A.length;
                    final int magic = 100;
                    int ans = 0;
                    List<int[]> onesA = new ArrayList<>();
                    List<int[]> onesB = new ArrayList<>();
                    Map<Integer, Integer> map = new HashMap<>();
                    for (int i = 0; i < n; ++i)
                      for (int j = 0; j < n; ++j) {
                        if (A[i][j] == 1)
                          onesA.add(new int[] {i, j});
                        if (B[i][j] == 1)
                          onesB.add(new int[] {i, j});
                      }  
                    for (int[] a : onesA)
                      for (int[] b : onesB) {
                        final int key = (a[0] - b[0]) * magic + a[1] - b[1];
                        map.put(key, map.getOrDefault(key, 0) + 1);
                      }
                    for (final int value : map.values())
                      ans = Math.max(ans, value);
                    return ans;
                  }
                }
                `,
                output: `3`,
              },
            }}
          />
        ),
      },
      q680: {
        title: "Q836. Rectangle Overlap (Q680)",
        content: (
          <Comp
            content1={
              <>
                An axis-aligned rectangle is represented as a list [x1, y1, x2,
                y2], where (x1, y1) is the coordinate of its bottom-left corner,
                and (x2, y2) is the coordinate of its top-right corner. Its top
                and bottom edges are parallel to the X-axis, and its left and
                right edges are parallel to the Y-axis.
                <br />
                Two rectangles overlap if the area of their intersection is
                positive. To be clear, two rectangles that only touch at the
                corner or edges do not overlap.
                <br />
                Given two axis-aligned rectangles rec1 and rec2, return true if
                they overlap, otherwise return false.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: rec1 = [0,0,2,2], <br /> rec2 = [1,1,3,3] <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: rec1 = [0,0,1,1], <br />
                    rec2 = [1,0,2,1] <br />
                    Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: rec1 = [0,0,1,1], <br />
                    rec2 = [2,2,3,3] Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                rect1.length == 4<br />
                rect2.length == 4<br />
                -10^9 &lt;= rec1[i], rec2[i] &lt;= 10^9
                <br />
                rec1 and rec2 represent a valid rectangle with a non-zero area.
              </>
            }
            tc="1"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} rec1
                * @param {number[]} rec2
                * @return {boolean}
                */
               var isRectangleOverlap = function(rec1, rec2) {
                 return rec1[0] < rec2[2] && rec2[0] < rec1[2] &&
                          rec1[1] < rec2[3] && rec2[1] < rec1[3];  
               };
               
               console.log(isRectangleOverlap([0,0,2,2],[1,1,3,3]))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q681: {
        title: "Q837. New 21 Game (Q681)",
        content: (
          <Comp
            content1={
              <>
                Alice plays the following game, loosely based on the card game
                "21".
                <br />
                Alice starts with 0 points and draws numbers while she has less
                than k points. During each draw, she gains an integer number of
                points randomly from the range [1, maxPts], where maxPts is an
                integer. Each draw is independent and the outcomes have equal
                probabilities.
                <br />
                Alice stops drawing numbers when she gets k or more points.
                <br />
                Return the probability that Alice has n or fewer points.
                <br />
                Answers within 10-5 of the actual answer are considered
                accepted.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 10, <br /> k = 1, <br /> maxPts = 10 <br />
                    Output: 1.00000 <br />
                    Explanation: Alice gets a single card, then stops.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 6, <br /> k = 1, <br /> maxPts = 10 <br />
                    Output: 0.60000 <br />
                    Explanation: Alice gets a single card, then stops. <br />
                    In 6 out of 10 possibilities, she is at or below 6 points.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 21, <br />k = 17, <br /> maxPts = 10 <br />
                    Output: 0.73278
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= k &lt;= n &lt;= 10^4 <br />1 &lt;= maxPts &lt;= 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @param {number} k
                * @param {number} maxPts
                * @return {number}
                */
               var new21Game = function(n, k, maxPts) {
                 if (k == 0 || n >= k - 1 + maxPts)
                     return 1.0;
                 let ans = 0.0;
                 const dp = Array(n + 1).fill(0); 
                 dp[0] = 1.0;
                 let windowSum = dp[0];
                 for (let i = 1; i <= n; ++i) {
                   dp[i] = windowSum / maxPts;
                   if (i < k)
                     windowSum += dp[i];
                   else 
                     ans += dp[i];
                   if (i - maxPts >= 0)
                     windowSum -= dp[i - maxPts];
                 }
                 return ans;  
               };
               
               console.log(new21Game(10,1,10))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q682: {
        title: "Q838. Push Dominoes (Q682)",
        content: (
          <Comp
            title="Q838. Push Dominoes (Q682)"
            content1={
              <>
                There are n dominoes in a line, and we place each domino
                vertically upright. In the beginning, we simultaneously push
                some of the dominoes either to the left or to the right.
                <br />
                After each second, each domino that is falling to the left
                pushes the adjacent domino on the left. Similarly, the dominoes
                falling to the right push their adjacent dominoes standing on
                the right.
                <br />
                When a vertical domino has dominoes falling on it from both
                sides, it stays still due to the balance of the forces.
                <br />
                For the purposes of this question, we will consider that a
                falling domino expends no additional force to a falling or
                already fallen domino.
                <br />
                You are given a string dominoes representing the initial state
                where:
                <br />
                dominoes[i] = 'L', if the ith domino has been pushed to the
                left,
                <br />
                dominoes[i] = 'R', if the ith domino has been pushed to the
                right, and
                <br />
                dominoes[i] = '.', if the ith domino has not been pushed.
                <br />
                Return a string representing the final state.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: dominoes = "RR.L" <br />
                    Output: "RR.L" <br />
                    Explanation: The first domino expends no additional force on
                    the second domino.
                  </>
                ),
              },
              {
                img: Leetcode838,
                content: (
                  <>
                    Input: dominoes = ".L.R...LR..L.." <br /> Output:
                    "LL.RR.LLRRLL.."
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == dominoes.length <br />
                1 &lt;= n &lt;= 10^5 <br />
                dominoes[i] is either 'L', 'R', or '.'
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} dominoes
                * @return {string}
                */
               var pushDominoes = function(dominoes) {
                   let s = dominoes.split("")
                   let L = -1;
                   let R = -1;
                   for (let i = 0; i <= dominoes.length; ++i)
                     if (i == dominoes.length || s[i] == 'R') {
                       if (L < R)
                         while (R < i)
                           s[R++] = 'R';
                       R = i;
                     } else if (s[i] == 'L') {
                       if (R < L || L == -1 && R == -1) {
                         if (L == -1 && R == -1)
                           ++L;
                         while (L < i)
                           s[L++] = 'L';
                       } else {
                         let l = R + 1;
                         let r = i - 1;
                         while (l < r) {
                           s[l++] = 'R';
                           s[r--] = 'L';
                         }
                       }
                       L = i;
                     }
               
                   return s.join("")
               };
               
               console.log(pushDominoes(".L.R...LR..L.."))`,
                output: `LL.RR.LLRRLL..`,
              },
            }}
          />
        ),
      },
      q683: {
        title: "Q839. Similar String Groups (Q683)",
        content: (
          <Comp
            title="Q839. Similar String Groups (Q683)"
            content1={
              <>
                Two strings X and Y are similar if we can swap two letters (in
                different positions) of X, so that it equals Y. Also two strings
                X and Y are similar if they are equal.
                <br />
                For example, "tars" and "rats" are similar (swapping at
                positions 0 and 2), and "rats" and "arts" are similar, but
                "star" is not similar to "tars", "rats", or "arts".
                <br />
                Together, these form two connected groups by similarity:{" "}
                {
                  '{"tars", "rats", "arts"} and {"star"}.  Notice that "tars" and "arts"'
                }{" "}
                are in the same group even though they are not similar.
                Formally, each group is such that a word is in the group if and
                only if it is similar to at least one other word in the group.
                <br />
                We are given a list strs of strings where every string in strs
                is an anagram of every other string in strs. How many groups are
                there?
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: strs = ["tars","rats","arts","star"] <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: strs = ["omv","ovm"]
                    <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= strs.length &lt;= 300
                <br />
                1 &lt;= strs[i].length &lt;= 300
                <br />
                strs[i] consists of lowercase letters only.
                <br />
                All words in strs have the same length and are anagrams of each
                other.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: strs = ["omv","ovm"]
                class Solution {
                  public int numSimilarGroups(String[] A) {
                    int ans = 0;
                    boolean[] seen = new boolean[A.length];
                    for (int i = 0; i < A.length; ++i)
                      if (!seen[i]) {
                        dfs(A, i, seen);
                        ++ans;
                      }
                    return ans;
                  }
                  private void dfs(final String[] A, int i, boolean[] seen) {
                    seen[i] = true;
                    for (int j = 0; j < A.length; ++j)
                      if (!seen[j] && isSimilar(A[i], A[j]))
                        dfs(A, j, seen);
                  }
                  private boolean isSimilar(final String X, final String Y) {
                    int diff = 0;
                    for (int i = 0; i < X.length(); ++i)
                      if (X.charAt(i) != Y.charAt(i) && ++diff > 2)
                        return false;
                    return true;
                  }
                }
                `,
                output: `1`,
              },
            }}
          />
        ),
      },
      q684: {
        title: "Q840. Magic Squares In Grid (Q684)",
        content: (
          <Comp
            title="Q840. Magic Squares In Grid (Q684)"
            content1={
              <>
                A 3 x 3 magic square is a 3 x 3 grid filled with distinct
                numbers from 1 to 9 such that each row, column, and both
                diagonals all have the same sum.
                <br />
                Given a row x col grid of integers, how many 3 x 3 "magic
                square" subgrids are there? (Each subgrid is contiguous).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: grid = [[4,3,8,4],[9,5,1,9],[2,7,6,2]]
                    <br />
                    Output: 1 :
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[8]]
                    <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                row == grid.length]
                <br />
                col == grid[i].length]
                <br />
                1 &lt;= row, col &lt;= 10]
                <br />0 &lt;= grid[i][j] &lt;= 15
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} grid
                * @return {number}
                */
               var numMagicSquaresInside = function(grid) {
                 let res = 0;
                 for (let i = 0; i + 2 < grid.length; ++i)
                     for (let j = 0; j + 2 < grid[0].length; ++j)
                       if (grid[i][j] % 2 == 0 && grid[i + 1][j + 1] == 5)
                         if (isMagic(grid, i, j))
                           ++res;
                 return res;
               };
               
               function isMagic(grid, i, j) {
                   let s = "";
                   for (let num of [0, 1, 2, 5, 8, 7, 6, 3])
                     s += String(grid[i + parseInt(num / 3)][parseInt(j + num % 3)]);
                   return new String("4381672943816729").includes(s) ||
                          new String("9276183492761834").includes(s);
                 }
               
               console.log(numMagicSquaresInside([[4,3,8,4],[9,5,1,9],[2,7,6,2]]))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q685: {
        title: "Q841. Keys and Rooms (Q685)",
        content: (
          <Comp
            title="Q841. Keys and Rooms (Q685)"
            content1={
              <>
                There are n rooms labeled from 0 to n - 1 and all the rooms are
                locked except for room 0. Your goal is to visit all the rooms.
                However, you cannot enter a locked room without having its key.
                <br />
                When you visit a room, you may find a set of distinct keys in
                it. Each key has a number on it, denoting which room it unlocks,
                and you can take all of them with you to unlock the other rooms.
                <br />
                Given an array rooms where rooms[i] is the set of keys that you
                can obtain if you visited room i, return true if you can visit
                all the rooms, or false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: rooms = [[1],[2],[3],[]] <br />
                    Output: true <br />
                    Explanation: <br />
                    We visit room 0 and pick up key 1. <br />
                    We then visit room 1 and pick up key 2. <br />
                    We then visit room 2 and pick up key 3. <br />
                    We then visit room 3. <br />
                    Since we were able to visit every room, we return true.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: rooms = [[1,3],[3,0,1],[2],[0]] <br />
                    Output: false <br />
                    Explanation: We can not enter room number 2 since the only
                    key that unlocks it is in that room.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == rooms.length
                <br />
                2 &lt;= n &lt;= 1000
                <br />
                0 &lt;= rooms[i].length &lt;= 1000
                <br />
                1 &lt;= sum(rooms[i].length) &lt;= 3000
                <br />
                0 &lt;= rooms[i][j] &lt; n<br />
                All the values of rooms[i] are unique.
              </>
            }
            tc="v + e"
            sc="v"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} rooms
                * @return {boolean}
                */
               var canVisitAllRooms = function(rooms) {
                 const seen = Array(rooms.length).fill(0);
                 dfs(rooms, 0, seen);
                 return seen.every(a => a == 1);
               };
               function dfs(rooms, node, seen) {
                 seen[node] = 1;
                 for (let child of rooms[node])
                 if (seen[child] == 0)
                 dfs(rooms, child, seen);
               }
               console.log(canVisitAllRooms([[1,3],[3,0,1],[2],[0]]))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q686: {
        title: "Q842. Split Array into Fibonacci Sequence (Q686)",
        content: (
          <Comp
            title="Q842. Split Array into Fibonacci Sequence (Q686)"
            content1={
              <>
                You are given a string of digits num, such as "123456579". We
                can split it into a Fibonacci-like sequence [123, 456, 579].
                <br />
                Formally, a Fibonacci-like sequence is a list f of non-negative
                integers such that:
                <br />
                0 &lt;= f[i] &lt; 2^31, (that is, each integer fits in a 32-bit
                signed integer type),
                <br />
                f.length &gt;= 3, and
                <br />
                f[i] + f[i + 1] == f[i + 2] for all 0 &lt;= i &lt; f.length - 2.
                <br />
                Note that when splitting the string into pieces, each piece must
                not have extra leading zeroes, except if the piece is the number
                0 itself.
                <br />
                Return any Fibonacci-like sequence split from num, or return []
                if it cannot be done.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: num = "1101111" <br />
                    Output: [11,0,11,11] <br />
                    Explanation: The output [110, 1, 111] would also be
                    accepted.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num = "112358130" <br />
                    Output: [] <br />
                    Explanation: The task is impossible.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num = "0123" <br />
                    Output: [] <br />
                    Explanation: Leading zeroes are not allowed, so "01", "2",
                    "3" is not val
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= num.length &lt;= 200 <br />
                num contains only digits.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `class Solution {
                  public List<Integer> splitIntoFibonacci(String num) {
                    List<Integer> ans = new ArrayList<>();
                    dfs(num, 0, ans);
                    return ans;
                  }
                  private boolean dfs(final String num, int s, List<Integer> ans) {
                    if (s == num.length() && ans.size() >= 3)
                      return true;
                    for (int i = s; i < num.length(); ++i) {
                      if (num.charAt(s) == '0' && i > s)
                        break;
                      final long val = Long.valueOf(num.substring(s, i + 1));
                      if (val > Integer.MAX_VALUE)
                        break;
                      if (ans.size() >= 2 && val > ans.get(ans.size() - 2) + ans.get(ans.size() - 1))
                        break;
                      if (ans.size() <= 1 || val == ans.get(ans.size() - 2) + ans.get(ans.size() - 1)) {
                        ans.add((int) val);
                        if (dfs(num, i + 1, ans))
                          return true;
                        ans.remove(ans.size() - 1);
                      }
                    }
                    return false;
                  }
                }`,
                output: `[]`,
              },
            }}
          />
        ),
      },
      q687: {
        title: "Q843. Guess the Word (Q687)",
        content: (
          <Comp
            title="Q843. Guess the Word (Q687)"
            content1={
              <>
                This is an interactive problem.
                <br />
                You are given an array of unique strings wordlist where
                wordlist[i] is 6 letters long, and one word in this list is
                chosen as secret.
                <br />
                You may call Master.guess(word) to guess a word. The guessed
                word should have type string and must be from the original list
                with 6 lowercase letters.
                <br />
                This function returns an integer type, representing the number
                of exact matches (value and position) of your guess to the
                secret word. Also, if your guess is not in the given wordlist,
                it will return -1 instead.
                <br />
                For each test case, you have exactly 10 guesses to guess the
                word. At the end of any number of calls, if you have made 10 or
                fewer calls to Master.guess and at least one of these guesses
                was secret, then you pass the test case.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: secret = "acckzz", wordlist =
                    ["acckzz","ccbazz","eiowzz","abcczz"], numguesses = 10
                    <br /> Output: You guessed the secret word correctly. <br />
                    Explanation: <br />
                    <br /> master.guess("aaaaaa") returns -1, because "aaaaaa"
                    is not in wordlist.
                    <br /> master.guess("acckzz") returns 6, because "acckzz" is
                    secret and has all 6 matches.
                    <br /> master.guess("ccbazz") returns 3, because "ccbazz"
                    has 3 matches.
                    <br /> master.guess("eiowzz") returns 2, because "eiowzz"
                    has 2 matches.
                    <br />
                    master.guess("abcczz") returns 4, because "abcczz" has 4
                    matches.
                    <br />
                    We made 5 calls to master.guess and one of them was the
                    secret, so we pass the test case.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: secret = "hamada", <br /> wordlist =
                    ["hamada","khaled"], numguesses = 10
                    <br /> Output: You guessed the secret word correctly
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= wordlist.length &lt;= 100
                <br />
                wordlist[i].length == 6<br />
                wordlist[i] consist of lowercase English letters.
                <br />
                All the strings of wordlist are unique.
                <br />
                secret exists in wordlist.
                <br />
                numguesses == 10
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: secret = "hamada", wordlist = ["hamada","khaled"], numguesses = 10
                /**
                * // This is the Master's API interface.
                * // You should not implement it, or speculate about its implementation
                * interface Master {
                *   public int guess(String word) {}
                * }
                */
               class Solution {
                 public void findSecretWord(String[] wordlist, Master master) {
                   Random rand = new Random();     
                   for (int i = 0; i < 10; ++i) {
                     final String guessedWord = wordlist[rand.nextInt(wordlist.length)];
                     final int matches = master.guess(guessedWord);
                     if (matches == 6)
                       break;
                     List<String> updated = new ArrayList<>();
                     for (final String word : wordlist)
                       if (getMatches(guessedWord, word) == matches)
                         updated.add(word);
                     wordlist = updated.toArray(new String[0]);
                   }
                 }
                 private int getMatches(final String s1, final String s2) {
                   int matches = 0;
                   for (int i = 0; i < s1.length(); ++i)
                     if (s1.charAt(i) == s2.charAt(i))
                       ++matches;
                   return matches;
                 }
               }`,
                output: `You guessed the secret word correctly.`,
              },
            }}
          />
        ),
      },
      q688: {
        title: "Q844. Backspace String Compare (Q688)",
        content: (
          <Comp
            title="Q844. Backspace String Compare (Q688)"
            content1={
              <>
                Given two strings s and t, return true if they are equal when
                both are typed into empty text editors. '#' means a backspace
                character.
                <br />
                Note that after backspacing an empty text, the text will
                continue empty.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "ab#c", t = "ad#c" <br />
                    Output: true <br />
                    Explanation: Both s and t become "ac".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "ab##", t = "c#d#" <br />
                    Output: true <br />
                    Explanation: Both s and t become "".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "a#c", t = "b" <br />
                    Output: false <br />
                    Explanation: s becomes "c" while t becomes "b".
                  </>
                ),
              },
            ]}
            fp={
              <>
                <b>Follow up: </b>
                Can you solve it in O(n) time and O(1) space?
              </>
            }
            constraints={
              <>
                1 &lt;= s.length, t.length &lt;= 200 <br />s and t only contain
                lowercase letters and '#' characters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @param {string} t
                * @return {boolean}
                */
               var backspaceCompare = function(s, t) {
                 let i = s.length - 1;  
                 let j = t.length - 1;
                 while (true) {
                     let back = 0;
                     while (i >= 0 && (s[i] == '#' || back > 0)) {
                       back += s[i] == '#' ? 1 : -1;
                       --i;
                     }
                     back = 0;
                     while (j >= 0 && (t[j] == '#' || back > 0)) {
                       back += t[j] == '#' ? 1 : -1;
                       --j;
                     }
                     if (i >= 0 && j >= 0 && s[i] == t[j]) {
                       --i;
                       --j;
                     } else {
                       break;
                     }
                   }
                   return i == -1 && j == -1;
               };
               
               console.log(backspaceCompare("a#c","b"))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q689: {
        title: "Q845. Longest Mountain in Array (Q689)",
        content: (
          <Comp
            title="Q845. Longest Mountain in Array (Q689)"
            content1={
              <>
                You may recall that an array arr is a mountain array if and only
                if:
                <br />
                arr.length &gt;= 3<br />
                There exists some index i (0-indexed) with 0 &lt; i &lt;
                arr.length - 1 such that:
                <br /> arr[0] &lt; arr[1] &lt; ... &lt; arr[i - 1] &lt; arr[i]
                <br /> arr[i] &gt; arr[i + 1] &gt; ... &gt; arr[arr.length - 1]
                <br /> Given an integer array arr, return the length of the
                longest subarray, which is a mountain. Return 0 if there is no
                mountain subarray.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [2,1,4,7,3,2,5]
                    <br />
                    Output: 5<br />
                    Explanation: The largest mountain is [1,4,7,3,2] which has
                    length 5.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [2,2,2]
                    <br />
                    Output: 0<br />
                    Explanation: There is no mountain.
                  </>
                ),
              },
            ]}
            fp={
              <>
                <b>Follow up: </b>
                Can you solve it using only one pass? <br />
                Can you solve it in O(1) space?
              </>
            }
            constraints={
              <>
                1 &lt;= arr.length &lt;= 10^4 <br />0 &lt;= arr[i] &lt;= 10^4
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} arr
                * @return {number}
                */
               var longestMountain = function(arr) {
                   let ans = 0;
                   for (let i = 0; i + 1 < arr.length;) {
                     while (i + 1 < arr.length && arr[i] == arr[i + 1])
                       ++i;
                     let increasing = 0;
                     let decreasing = 0;
                     while (i + 1 < arr.length && arr[i] < arr[i + 1]) {
                       ++increasing;
                       ++i;
                     }
                     while (i + 1 < arr.length && arr[i] > arr[i + 1]) {
                       ++decreasing;
                       ++i;
                     }
                     if (increasing > 0 && decreasing > 0)
                       ans = Math.max(ans, increasing + decreasing + 1);
                   }
                   return ans;    
               };
               
               console.log(longestMountain([2,1,4,7,3,2,5]))`,
                output: `5`,
              },
            }}
          />
        ),
      },
      q690: {
        title: "Q846. Hand of Straights (Q690)",
        content: (
          <Comp
            title="Q846. Hand of Straights (Q690)"
            content1={
              <>
                Alice has some number of cards and she wants to rearrange the
                cards into groups so that each group is of size groupSize, and
                consists of groupSize consecutive cards.
                <br />
                Given an integer array hand where hand[i] is the value written
                on the ith card and an integer groupSize, return true if she can
                rearrange the cards, or false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: hand = [1,2,3,6,2,3,4,7,8], groupSize = 3 <br />
                    Output: true <br />
                    Explanation: Alice's hand can be rearranged as
                    [1,2,3],[2,3,4],[6,7,8]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: hand = [1,2,3,4,5], groupSize = 4<br />
                    Output: false
                    <br />
                    Explanation: Alice's hand can not be rearranged into groups
                    of 4.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= hand.length &lt;= 104
                <br />
                0 &lt;= hand[i] &lt;= 109
                <br />1 &lt;= groupSize &lt;= hand.length
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: hand = [1,2,3,6,2,3,4,7,8], groupSize = 3
                class Solution {
                  public boolean isNStraightHand(int[] hand, int W) {
                    TreeMap<Integer, Integer> count = new TreeMap<>();
                    for (final int card : hand)
                      count.put(card, count.getOrDefault(card, 0) + 1);
                    for (final int start : count.keySet()) {
                      final int value = count.getOrDefault(start, 0);
                      if (value > 0)
                        for (int i = start; i < start + W; ++i) {
                          count.put(i, count.getOrDefault(i, 0) - value);
                          if (count.get(i) < 0)
                            return false;
                        }
                    }
                    return true;
                  }
                }
                `,
                output: `true`,
              },
            }}
          />
        ),
      },
      q691: {
        title: "Q847. Shortest Path Visiting All Nodes (Q691)",
        content: (
          <Comp
            title="Q847. Shortest Path Visiting All Nodes (Q691)"
            content1={
              <>
                You have an undirected, connected graph of n nodes labeled from
                0 to n - 1. You are given an array graph where graph[i] is a
                list of all the nodes connected with node i by an edge.
                <br />
                Return the length of the shortest path that visits every node.
                You may start and stop at any node, you may revisit nodes
                multiple times, and you may reuse edges.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode847,
                content: (
                  <>
                    Input: graph = [[1,2,3],[0],[0],[0]] <br />
                    Output: 4 <br />
                    Explanation: One possible path is [1,0,2,0,3]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: graph = [[1],[0,2,4],[1,3,4],[2],[1,2]] <br />
                    Output: 4 <br />
                    Explanation: One possible path is [0,1,4,2,3]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == graph.length <br />
                1 &lt;= n &lt;= 12 <br />
                0 &lt;= graph[i].length &lt; n <br />
                graph[i] does not contain i. <br />
                If graph[a] contains b, then graph[b] contains a. <br />
                The input graph is always connected.
              </>
            }
            tc="n.2^n"
            sc="n.2^n"
            codes={{
              Java: {
                code: `
                // Input: graph = [[1,2,3],[0],[0],[0]]
                class Solution {
                  public int shortestPathLength(int[][] graph) {
                    final int n = graph.length;
                    final int goal = (1 << n) - 1;
                    int ans = 0;
                    Queue<Pair<Integer, Integer>> q = new ArrayDeque<>(); // (u, state)
                    boolean[][] seen = new boolean[n][1 << n];
                    for (int i = 0; i < n; ++i)
                      q.offer(new Pair<>(i, 1 << i));
                    while (!q.isEmpty()) {
                      for (int sz = q.size(); sz > 0; --sz) {
                        final int u = q.peek().getKey();
                        final int state = q.poll().getValue();
                        if (state == goal)
                          return ans;
                        if (seen[u][state])
                          continue;
                        seen[u][state] = true;
                        for (final int v : graph[u])
                          q.offer(new Pair<>(v, state | (1 << v)));
                      }
                      ++ans;
                    }
                    return -1;
                  }
                }`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q692: {
        title: "Q848. Shifting Letters (Q692)",
        content: (
          <Comp
            title="Q848. Shifting Letters (Q692)"
            content1={
              <>
                You are given a string s of lowercase English letters and an
                integer array shifts of the same length.
                <br />
                Call the shift() of a letter, the next letter in the alphabet,
                (wrapping around so that 'z' becomes 'a').
                <br />
                For example, shift('a') = 'b', shift('t') = 'u', and shift('z')
                = 'a'.
                <br />
                Now for each shifts[i] = x, we want to shift the first i + 1
                letters of s, x times.
                <br />
                Return the final string after all such shifts to s are applied.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "abc", shifts = [3,5,9]
                    <br />
                    Output: "rpl"
                    <br />
                    Explanation: We start with "abc".
                    <br />
                    After shifting the first 1 letters of s by 3, we have "dbc".
                    <br />
                    After shifting the first 2 letters of s by 5, we have "igc".
                    <br />
                    After shifting the first 3 letters of s by 9, we have "rpl",
                    the answer.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "aaa", shifts = [1,2,3] <br />
                    Output: "gfd"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^5 <br />
                s consists of lowercase English letters. <br />
                shifts.length == s.length <br />0 &lt;= shifts[i] &lt;= 109
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: s = "aaa", shifts = [1,2,3]
                class Solution {
                  public String shiftingLetters(String s, int[] shifts) {
                    StringBuilder sb = new StringBuilder();
                    for (int i = shifts.length - 2; i >= 0; --i)
                      shifts[i] = (shifts[i] + shifts[i + 1]) % 26;
                    for (int i = 0; i < s.length(); ++i)
                      sb.append((char) ((s.charAt(i) - 'a' + shifts[i]) % 26 + 'a'));
                    return sb.toString();
                  }
                }
                `,
                output: `gfd`,
              },
            }}
          />
        ),
      },
      q693: {
        title: "Q849. Maximize Distance to Closest Person (Q693)",
        content: (
          <Comp
            title="Q849. Maximize Distance to Closest Person (Q693)"
            content1={
              <>
                You are given an array representing a row of seats where
                seats[i] = 1 represents a person sitting in the ith seat, and
                seats[i] = 0 represents that the ith seat is empty (0-indexed).
                <br />
                There is at least one empty seat, and at least one person
                sitting.
                <br />
                Alex wants to sit in the seat such that the distance between him
                and the closest person to him is maximized.
                <br />
                Return that maximum distance to the closest person.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode849,
                content: (
                  <>
                    Input: seats = [1,0,0,0,1,0,1] <br />
                    Output: 2 <br />
                    Explanation: <br />
                    If Alex sits in the second open seat (i.e. seats[2]), then
                    the closest person has distance 2.
                    <br />
                    If Alex sits in any other open seat, the closest person has
                    distance 1. Thus, the maximum distance to the closest person
                    is 2.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: seats = [1,0,0,0] <br />
                    Output: 3 <br />
                    Explanation: <br />
                    If Alex sits in the last seat (i.e. seats[3]), the closest
                    person is 3 seats away.
                    <br /> This is the maximum distance possible, so the answer
                    is 3.
                  </>
                ),
              },
              {
                content: <>Input: seats = [0,1] Output: 1</>,
              },
            ]}
            constraints={
              <>
                2 &lt;= seats.length &lt;= 2 * 10^4 <br />
                seats[i] is 0 or 1. <br />
                At least one seat is empty. <br />
                At least one seat is occupied.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} seats
                * @return {number}
                */
               var maxDistToClosest = function(seats) {
                 const n = seats.length;
                 let res = 0;
                 let j = -1;
                 for (let i = 0; i < n; ++i)
                   if (seats[i] == 1) {
                     res = j == -1 ? i : Math.max(res, parseInt((i - j) / 2));
                     j = i;
                 }
                 return Math.max(res, n - j - 1);
               };
               
               console.log(maxDistToClosest([1,0,0,0,1,0,1]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q694: {
        title: "Q850. Rectangle Area II (Q694)",
        content: (
          <Comp
            title="Q850. Rectangle Area II (Q694)"
            content1={
              <>
                You are given a 2D array of axis-aligned rectangles. Each
                rectangle[i] = [xi1, yi1, xi2, yi2] denotes the ith rectangle
                where (xi1, yi1) are the coordinates of the bottom-left corner,
                and (xi2, yi2) are the coordinates of the top-right corner.
                <br />
                Calculate the total area covered by all rectangles in the plane.
                Any area covered by two or more rectangles should only be
                counted once.
                <br />
                Return the total area. Since the answer may be too large, return
                it modulo 109 + 7.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: rectangles = [[0,0,2,2],[1,0,2,3],[1,0,3,1]]
                    <br /> Output: 6<br />
                    Explanation: A total area of 6 is covered by all three
                    rectangales, as illustrated in the picture.
                    <br /> From (1,1) to (2,2), the green and red rectangles
                    overlap.
                    <br /> From (1,0) to (2,3), all three rectangles overlap.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: rectangles = [[0,0,1000000000,1000000000]]
                    <br /> Output: 49 <br />
                    Explanation: The answer is 1018 modulo (109 + 7), which is
                    49.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= rectangles.length &lt;= 200 <br />
                rectanges[i].length == 4 <br />0 &lt;= xi1, yi1, xi2, yi2 &lt;=
                10^9
              </>
            }
            tc="n^2.log n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: rectangles = [[0,0,1000000000,1000000000]]
                class Event {
                  public int x;
                  public int y1;
                  public int y2;
                  public char type;
                  public Event(int x, int y1, int y2, char type) {
                    this.x = x;
                    this.y1 = y1;
                    this.y2 = y2;
                    this.type = type;
                  }
                }
                
                class Solution {
                  public int rectangleArea(int[][] rectangles) {
                    final int kMod = (int) 1e9 + 7;
                    List<Event> events = new ArrayList<>();
                
                    for (int[] r : rectangles) {
                      events.add(new Event(r[0], r[1], r[3], 's'));
                      events.add(new Event(r[2], r[1], r[3], 'e'));
                    }
                
                    Collections.sort(events, (a, b) -> a.x - b.x);
                
                    long ans = 0;
                    int prevX = 0;
                    List<Pair<Integer, Integer>> yPairs = new ArrayList<>();
                
                    for (Event e : events) {
                      if (e.x > prevX) {
                        final int width = e.x - prevX;
                        ans = (ans + width * getHeight(yPairs)) % kMod;
                        prevX = e.x;
                      }
                      if (e.type == 's') {
                        yPairs.add(new Pair<>(e.y1, e.y2));
                        Collections.sort(yPairs, Comparator.comparing(Pair::getKey));
                      } else { // type == 'e'
                        yPairs.remove(new Pair<>(e.y1, e.y2));
                      }
                    }
                    return (int) (ans % kMod);
                  }
                
                  private long getHeight(List<Pair<Integer, Integer>> yPairs) {
                    int height = 0;
                    int prevY = 0;
                    for (var pair : yPairs) {
                      final int y1 = pair.getKey();
                      final int y2 = pair.getValue();
                      prevY = Math.max(prevY, y1);
                      if (y2 > prevY) {
                        height += y2 - prevY;
                        prevY = y2;
                      }
                    }
                    return height;
                  }
                }`,
                output: `49`,
              },
            }}
          />
        ),
      },
      q695: {
        title: "Q851. Loud and Rich (Q695)",
        content: (
          <Comp
            title="Q851. Loud and Rich (Q695)"
            content1={
              <>
                There is a group of n people labeled from 0 to n - 1 where each
                person has a different amount of money and a different level of
                quietness.
                <br />
                You are given an array richer where richer[i] = [ai, bi]
                indicates that ai has more money than bi and an integer array
                quiet where quiet[i] is the quietness of the ith person. All the
                given data in richer are logically correct (i.e., the data will
                not lead you to a situation where x is richer than y and y is
                richer than x at the same time).
                <br />
                Return an integer array answer where answer[x] = y if y is the
                least quiet person (that is, the person y with the smallest
                value of quiet[y]) among all people who definitely have equal to
                or more money than the person x.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: richer = [[1,0],[2,1],[3,1],[3,7],[4,3],[5,3],[6,3]],
                    <br /> quiet = [3,2,5,4,6,1,7,0]
                    <br /> Output: [5,5,2,5,4,5,6,7]
                    <br /> Explanation:
                    <br /> answer[0] = 5.
                    <br /> Person 5 has more money than 3, which has more money
                    than 1, which has more money than 0.
                    <br /> The only person who is quieter (has lower quiet[x])
                    is person 7, but it is not clear if they have more money
                    than person 0.
                    <br /> answer[7] = 7.
                    <br /> Among all people that definitely have equal to or
                    more money than person 7 (which could be persons 3, 4, 5, 6,
                    or 7), the person who is the quietest (has lower quiet[x])
                    is person 7.
                    <br /> The other answers can be filled out with similar
                    reasoning.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: richer = [], <br />
                    quiet = [0]
                    <br />
                    Output: [0]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == quiet.length <br />
                1 &lt;= n &lt;= 500 <br />
                0 &lt;= quiet[i] &lt; n <br />
                All the values of quiet are unique. <br />
                0 &lt;= richer.length &lt;= n * (n - 1) / 2 <br />
                0 &lt;= ai, bi &lt; n <br />
                ai != bi <br />
                All the pairs of richer are unique. <br />
                The observations in richer are all logically consistent.
              </>
            }
            tc="v + e"
            sc="v + e"
            codes={{
              Java: {
                code: `
                // Input: richer = [[1,0],[2,1],[3,1],[3,7],[4,3],[5,3],[6,3]], quiet = [3,2,5,4,6,1,7,0]
                class Solution {
                  public int[] loudAndRich(int[][] richer, int[] quiet) {
                    int[] ans = new int[quiet.length];
                    List<Integer>[] graph = new List[quiet.length];
                    Arrays.fill(ans, -1);
                    for (int i = 0; i < graph.length; ++i)
                      graph[i] = new ArrayList<>();
                    for (int[] e : richer)
                      graph[e[1]].add(e[0]);
                    for (int i = 0; i < graph.length; ++i)
                      dfs(graph, i, quiet, ans);
                    return ans;
                  }
                  private int dfs(List<Integer>[] graph, int u, int[] quiet, int[] ans) {
                    if (ans[u] != -1)
                      return ans[u];
                    ans[u] = u;
                    for (final int v : graph[u]) {
                      final int res = dfs(graph, v, quiet, ans);
                      if (quiet[res] < quiet[ans[u]])
                        ans[u] = res;
                    }
                    return ans[u];
                  }
                }`,
                output: `[5,5,2,5,4,5,6,7]`,
              },
            }}
          />
        ),
      },
      q696: {
        title: "Q852. Peak Index in a Mountain Array (Q696)",
        content: (
          <Comp
            title="Q852. Peak Index in a Mountain Array (Q696)"
            content1={
              <>
                Let's call an array arr a mountain if the following properties
                hold:
                <br />
                arr.length &gt;= 3<br />
                There exists some i with 0 &lt; i &lt; arr.length &lt; 1 such
                that:
                <br />
                arr[0] &lt; arr[1] &lt; ... arr[i-1] &lt; arr[i]
                <br />
                arr[i] &gt; arr[i+1] &gt; ... &gt; arr[arr.length - 1]
                <br />
                Given an integer array arr that is guaranteed to be a mountain,
                return any i such that arr[0] &lt; arr[1] &lt; ... arr[i - 1]
                &lt; arr[i] &gt; arr[i + 1] &gt; ... &gt; arr[arr.length - 1].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [0,1,0] <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [0,2,1,0] <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [0,10,5,2] <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                3 &lt;= arr.length &lt;= 10^4 <br />
                0 &lt;= arr[i] &lt;= 106 <br />
                arr is guaranteed to be a mountain array.
              </>
            }
            fp={
              <>
                <b>Follow up: </b>: Finding the O(n) is straightforward, could
                you find an O(log(n)) solution?
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: arr = [0,1,0]
                class Solution {
                  public int peakIndexInMountainArray(int[] arr) {
                      int s=0;
                      int e=arr.length-1;
                      while(s<=e){
                          int m=(s+e)/2;
                          if(m>0 && arr[m-1]<arr[m] && arr[m+1]<arr[m]){
                              return m;
                          }else if(arr[m+1]>arr[m]){
                              s=m+1;
                          }else if(arr[m+1]<arr[m]){
                              e=m-1;
                          }
                      }
                      return -1;
                  }
                  }`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q697: {
        title: "Q853. Car Fleet (Q697)",
        content: (
          <Comp
            title="Q853. Car Fleet (Q697)"
            content1={
              <>
                There are n cars going to the same destination along a one-lane
                road. The destination is target miles away.
                <br />
                You are given two integer array position and speed, both of
                length n, where position[i] is the position of the ith car and
                speed[i] is the speed of the ith car (in miles per hour).
                <br />
                A car can never pass another car ahead of it, but it can catch
                up to it and drive bumper to bumper at the same speed. The
                faster car will slow down to match the slower car's speed. The
                distance between these two cars is ignored (i.e., they are
                assumed to have the same position).
                <br />
                A car fleet is some non-empty set of cars driving at the same
                position and same speed. Note that a single car is also a car
                fleet.
                <br />
                If a car catches up to a car fleet right at the destination
                point, it will still be considered as one car fleet.
                <br />
                Return the number of car fleets that will arrive at the
                destination.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: target = 12, <br /> position = [10,8,0,5,3], <br />{" "}
                    speed = [2,4,1,1,3]
                    <br />
                    Output: 3 <br />
                    Explanation: <br />
                    The cars starting at 10 (speed 2) and 8 (speed 4) become a
                    fleet, meeting each other at 12.
                    <br />
                    The car starting at 0 does not catch up to any other car, so
                    it is a fleet by itself.
                    <br />
                    The cars starting at 5 (speed 1) and 3 (speed 3) become a
                    fleet, meeting each other at 6. The fleet moves at speed 1
                    until it reaches target.
                    <br />
                    Note that no other cars meet these fleets before the
                    destination, so the answer is 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: target = 10, <br />
                    position = [3], <br />
                    speed = [3] <br />
                    Output: 1 <br />
                    Explanation: There is only one car, hence there is only one
                    fleet.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: target = 100,
                    <br /> position = [0,2,4],
                    <br /> speed = [4,2,1]
                    <br /> Output: 1<br />
                    Explanation:
                    <br />
                    The cars starting at 0 (speed 4) and 2 (speed 2) become a
                    fleet, meeting each other at 4. The fleet moves at speed 2.
                    <br />
                    Then, the fleet (speed 2) and the car starting at 4 (speed
                    1) become one fleet, meeting each other at 6. The fleet
                    moves at speed 1 until it reaches target.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == position.length == speed.length
                <br />
                1 &lt;= n &lt;= 10^5
                <br />
                0 &lt; target &lt;= 10^6
                <br />
                0 &lt;= position[i] &lt; target
                <br />
                All the values of position are unique.
                <br />0 &lt; speed[i] &lt;= 106
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: target = 100, position = [0,2,4], speed = [4,2,1]
                class Car {
                  public int pos;
                  public double time;
                  public Car(int pos, double time) {
                    this.pos = pos;
                    this.time = time;
                  }
                }
                class Solution {
                  public int carFleet(int target, int[] position, int[] speed) {
                    int ans = 0;
                    Car[] cars = new Car[position.length];
                    for (int i = 0; i < position.length; ++i)
                      cars[i] = new Car(position[i], (double) (target - position[i]) / speed[i]);
                    Arrays.sort(cars, (a, b) -> b.pos - a.pos);
                    double maxTime = 0;
                    for (Car car : cars)
                      if (car.time > maxTime) {
                        maxTime = car.time;
                        ++ans;
                      }
                    return ans;
                  }
                }
                `,
                output: `1`,
              },
            }}
          />
        ),
      },
      q698: {
        title: "Q854. K-Similar Strings (Q698)",
        content: (
          <Comp
            title="Q854. K-Similar Strings (Q698)"
            content1={
              <>
                Strings s1 and s2 are k-similar (for some non-negative integer
                k) if we can swap the positions of two letters in s1 exactly k
                times so that the resulting string equals s2.
                <br />
                Given two anagrams s1 and s2, return the smallest k for which s1
                and s2 are k-similar.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s1 = "ab", s2 = "ba" <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s1 = "abc", s2 = "bca"
                    <br />
                    Output: 2
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s1.length &lt;= 20
                <br />
                s2.length == s1.length
                <br />
                s1 and s2 contain only lowercase letters from the set{" "}
                {"{'a', 'b', 'c', 'd', 'e', 'f'}"}.
                <br /> s2 is an anagram of s1.
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Java: {
                code: `
                // Input: s1 = "abc", s2 = "bca"
                class Solution {
                  public int kSimilarity(String s1, String s2) {
                    int ans = 0;
                    Queue<String> q = new ArrayDeque<>(Arrays.asList(s1));
                    Set<String> seen = new HashSet<>(Arrays.asList(s1));
                    while (!q.isEmpty()) {
                      for (int sz = q.size(); sz > 0; --sz) {
                        final String curr = q.poll();
                        if (curr.equals(s2))
                          return ans;
                        for (final String child : getChildren(curr, s2)) {
                          if (seen.contains(child))
                            continue;
                          q.offer(child);
                          seen.add(child);
                        }
                      }
                      ++ans;
                    }
                    return -1;
                  }
                  private List<String> getChildren(final String curr, final String target) {
                    List<String> children = new ArrayList<>();
                    char[] charArray = curr.toCharArray();
                    int i = 0; 
                    while (curr.charAt(i) == target.charAt(i))
                      ++i;
                
                    for (int j = i + 1; j < charArray.length; ++j)
                      if (curr.charAt(j) == target.charAt(i)) {
                        swap(charArray, i, j);
                        children.add(String.valueOf(charArray));
                        swap(charArray, i, j);
                      }
                    return children;
                  }
                  private void swap(char[] charArray, int i, int j) {
                    final char temp = charArray[i];
                    charArray[i] = charArray[j];
                    charArray[j] = temp;
                  }
                }`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q699: {
        title: "Q855. Exam Room (Q699)",
        content: (
          <Comp
            title="Q855. Exam Room (Q699)"
            content1={
              <>
                There is an exam room with n seats in a single row labeled from
                0 to n - 1.
                <br />
                When a student enters the room, they must sit in the seat that
                maximizes the distance to the closest person. If there are
                multiple such seats, they sit in the seat with the lowest
                number. If no one is in the room, then the student sits at seat
                number 0.
                <br />
                Design a class that simulates the mentioned exam room.
                <br />
                Implement the ExamRoom class:
                <br />
                ExamRoom(int n) Initializes the object of the exam room with the
                number of the seats n.
                <br />
                int seat() Returns the label of the seat at which the next
                student will set.
                <br />
                void leave(int p) Indicates that the student sitting at seat p
                will leave the room. It is guaranteed that there will be a
                student sitting at seat p.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br />
                    ["ExamRoom", "seat", "seat", "seat", "seat", "leave",
                    "seat"]
                    <br />
                    [[10], [], [], [], [], [4], []]
                    <br />
                    Output
                    <br />
                    [null, 0, 9, 4, 2, null, 5]
                    <br />
                    Explanation
                    <br />
                    ExamRoom examRoom = new ExamRoom(10);
                    <br />
                    examRoom.seat(); // return 0, no one is in the room, then
                    the student sits at seat number 0.
                    <br />
                    examRoom.seat(); // return 9, the student sits at the last
                    seat number 9.
                    <br />
                    examRoom.seat(); // return 4, the student sits at the last
                    seat number 4.
                    <br />
                    examRoom.seat(); // return 2, the student sits at the last
                    seat number 2.
                    <br />
                    examRoom.leave(4);
                    <br />
                    examRoom.seat(); // return 5, the student sits at the last
                    seat number 5.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 10^9
                <br />
                It is guaranteed that there is a student sitting at seat p.
                <br />
                At most 104 calls will be made to seat and leave
              </>
            }
            tc="1"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input
// ["ExamRoom", "seat", "seat", "seat", "seat", "leave", "seat"]
// [[10], [], [], [], [], [4], []]
                class Node {
                  public Node prev;
                  public Node next;
                  public int value;
                
                  public Node(int value) {
                    this.value = value;
                  }
                }
                
                class ExamRoom {
                  public ExamRoom(int N) {
                    this.N = N;
                    join(head, tail);
                  }
                  public int seat() {
                    if (head.next == tail) {
                      Node node = new Node(0);
                      join(head, node);
                      join(node, tail);
                      map.put(0, node);
                      return 0;
                    }
                    int prevStudent = -1;
                    int maxDistToClosest = 0;
                    int val = 0;    
                    Node pos = null; 
                    for (Node node = head; node != tail; node = node.next) {
                      if (prevStudent == -1) {         
                        maxDistToClosest = node.value; 
                        pos = node;
                      } else if ((node.value - prevStudent) / 2 > maxDistToClosest) {
                        maxDistToClosest = (node.value - prevStudent) / 2;
                        val = (node.value + prevStudent) / 2;
                        pos = node;
                      }
                      prevStudent = node.value;
                    }
                    if (N - 1 - tail.prev.value > maxDistToClosest) {
                      pos = tail;
                      val = N - 1;
                    }
                    Node insertedNode = new Node(val);
                    join(pos.prev, insertedNode);
                    join(insertedNode, pos);
                    map.put(val, insertedNode);
                    return val;
                  }
                
                  public void leave(int p) {
                    Node removedNode = map.get(p);
                    join(removedNode.prev, removedNode.next);
                  }
                
                  private int N;
                  private Node head = new Node(-1);
                  private Node tail = new Node(-1);
                  private Map<Integer, Node> map = new HashMap<>(); 
                
                  private void join(Node node1, Node node2) {
                    node1.next = node2;
                    node2.prev = node1;
                  }
                
                  private void remove(Node node) {
                    join(node.prev, node.next);
                  }
                }`,
                output: `[null, 0, 9, 4, 2, null, 5]`,
              },
            }}
          />
        ),
      },
      q700: {
        title: "Q856. Score of Parentheses (Q700)",
        content: (
          <Comp
            title="Q856. Score of Parentheses (Q700)"
            content1={
              <>
                Given a balanced parentheses string s, return the score of the
                string.
                <br />
                The score of a balanced parentheses string is based on the
                following rule:
                <br />
                "()" has score 1.
                <br />
                AB has score A + B, where A and B are balanced parentheses
                strings.
                <br />
                (A) has score 2 * A, where A is a balanced parentheses string.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "()"
                    <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "(())"
                    <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "()()"
                    <br />
                    Output: 2
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= s.length &lt;= 50
                <br />
                s consists of only '(' and ')'.
                <br />s is a balanced parentheses string.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               var scoreOfParentheses = function(s) {
                 let res = 0;
                 let layer = 0;
                 for (let i = 0; i + 1 < s.length; ++i) {
                 const a = s.charAt(i);
                 const b = s.charAt(i + 1);
                 if (a == '(' && b == ')')
                 res += 1 << layer;
                 layer += a == '(' ? 1 : -1;
                 }
                 return res;
               };
               
               console.log("()")`,
                output: `()`,
              },
            }}
          />
        ),
      },
      q701: {
        title: "Q857. Minimum Cost to Hire K Workers (Q701)",
        content: (
          <Comp
            title="Q857. Minimum Cost to Hire K Workers (Q701)"
            content1={
              <>
                There are n workers. You are given two integer arrays quality
                and wage where quality[i] is the quality of the ith worker and
                wage[i] is the minimum wage expectation for the ith worker.
                <br />
                We want to hire exactly k workers to form a paid group. To hire
                a group of k workers, we must pay them according to the
                following rules:
                <br />
                Every worker in the paid group should be paid in the ratio of
                their quality compared to other workers in the paid group.
                <br />
                Every worker in the paid group must be paid at least their
                minimum wage expectation.
                <br />
                Given the integer k, return the least amount of money needed to
                form a paid group satisfying the above conditions. Answers
                within 10-5 of the actual answer will be accepted.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: quality = [10,20,5],
                    <br /> wage = [70,50,30], k = 2<br />
                    Output: 105.00000
                    <br />
                    Explanation: We pay 70 to 0th worker and 35 to 2nd worker.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: quality = [3,1,10,10,1], <br />
                    wage = [4,8,2,2,7], k = 3<br />
                    Output: 30.66667
                    <br />
                    Explanation: We pay 4 to 0th worker, 13.33333 to 2nd and 3rd
                    workers separately.
                  </>
                ),
              },
            ]}
            constraints={<></>}
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `class Solution {
                  public double mincostToHireWorkers(int[] quality, int[] wage, int k) {
                    double ans = Double.MAX_VALUE;
                    Pair<Double, Integer>[] workers = new Pair[quality.length];
                    PriorityQueue<Integer> maxHeap = new PriorityQueue<>((a, b) -> b - a);
                    int qualitySum = 0;
                    for (int i = 0; i < quality.length; ++i)
                      workers[i] = new Pair<>((double) wage[i] / quality[i], quality[i]);
                    Arrays.sort(workers, new Comparator<Pair<Double, Integer>>() {
                      @Override
                      public int compare(Pair<Double, Integer> a, Pair<Double, Integer> b) {
                        return Double.compare(a.getKey(), b.getKey());
                      }
                    });
                    for (var worker : workers) {
                      final double wagePerQuality = worker.getKey();
                      final int q = worker.getValue();
                      maxHeap.offer(q);
                      qualitySum += q;
                      if (maxHeap.size() > k)
                        qualitySum -= maxHeap.poll();
                      if (maxHeap.size() == k)
                        ans = Math.min(ans, qualitySum * wagePerQuality);
                    }
                    return ans;
                  }
                }`,
                output: `30.66667`,
              },
            }}
          />
        ),
      },
      q702: {
        title: "Q858. Mirror Reflection (Q702)",
        content: (
          <Comp
            title="Q858. Mirror Reflection (Q702)"
            content1={
              <>
                There is a special square room with mirrors on each of the four
                walls. Except for the southwest corner, there are receptors on
                each of the remaining corners, numbered 0, 1, and 2.
                <br />
                The square room has walls of length p and a laser ray from the
                southwest corner first meets the east wall at a distance q from
                the 0th receptor.
                <br />
                Given the two integers p and q, return the number of the
                receptor that the ray meets first.
                <br />
                The test cases are guaranteed so that the ray will meet a
                receptor eventually.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode858,
                content: (
                  <>
                    Input: p = 2, q = 1 <br />
                    Output: 2 <br />
                    Explanation: The ray meets receptor 2 the first time it gets
                    reflected back to the left wall.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: p = 3, q = 1 <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= q &lt;= p &lt;= 1000</>}
            tc="log n"
            sc="1"
            codes={{
              "C++": {
                code: `
                // Input: p = 2, q = 1
                class Solution {
                  public:
                   int mirrorReflection(int p, int q) {
                     int m = 1;  
                     int n = 1; 
                     while (m * p != n * q)
                       m = ++n * q / p;
                     if (n % 2 == 0)
                       return 2;
                     if (m % 2 == 0)
                       return 0;
                     if (m % 2 == 1)
                       return 1;
                     throw;
                   }
                 };
                 `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q703: {
        title: "Q859. Buddy Strings (Q703)",
        content: (
          <Comp
            title="Q859. Buddy Strings (Q703)"
            content1={
              <>
                Given two strings s and goal, return true if you can swap two
                letters in s so the result is equal to goal, otherwise, return
                false.
                <br />
                Swapping letters is defined as taking two indices i and j
                (0-indexed) such that i != j and swapping the characters at s[i]
                and s[j].
                <br />
                For example, swapping at indices 0 and 2 in "abcd" results in
                "cbad".
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "ab", goal = "ba" <br />
                    Output: true <br />
                    Explanation: You can swap s[0] = 'a' and s[1] = 'b' to get
                    "ba", which is equal to goal.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "ab", goal = "ab" <br />
                    Output: false <br />
                    Explanation: The only letters you can swap are s[0] = 'a'
                    and s[1] = 'b', which results in "ba" != goal.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "aa", goal = "aa" <br />
                    Output: true <br />
                    Explanation: You can swap s[0] = 'a' and s[1] = 'a' to get
                    "aa", which is equal to goal.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length, goal.length &lt;= 2 * 10^4 <br />s and goal
                consist of lowercase letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @param {string} goal
                * @return {boolean}
                */
               var buddyStrings = function(s, goal) {
                  if (s.length != goal.length)
                     return false;
                   if (s===goal) {
                   const set = new Set();
                   for (let c of s.split(""))
                       set.add(c);
                     return set.size < s.length;
                   }
                 const diff = [];
                   for (let i = 0; i < s.length; ++i)
                     if (s[i] != goal[i])
                       diff.push(i);
                   return diff.length == 2 &&
                          s[diff[0]] == goal[diff[1]] &&
                          s[diff[1]] == goal[diff[0]]
               };
               
               console.log(buddyStrings("ab","ba"))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q704: {
        title: "Q860. Lemonade Change (Q704)",
        content: (
          <Comp
            title="Q860. Lemonade Change (Q704)"
            content1={
              <>
                At a lemonade stand, each lemonade costs $5. Customers are
                standing in a queue to buy from you and order one at a time (in
                the order specified by bills). Each customer will only buy one
                lemonade and pay with either a $5, $10, or $20 bill. You must
                provide the correct change to each customer so that the net
                transaction is that the customer pays $5.
                <br />
                Note that you do not have any change in hand at first.
                <br />
                Given an integer array bills where bills[i] is the bill the ith
                customer pays, return true if you can provide every customer
                with the correct change, or false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: bills = [5,5,5,10,20] <br />
                    Output: true <br />
                    Explanation: <br />
                    From the first 3 customers, we collect three $5 bills in
                    order.
                    <br /> From the fourth customer, we collect a $10 bill and
                    give back a $5.
                    <br /> From the fifth customer, we give a $10 bill and a $5
                    bill.
                    <br /> Since all customers got correct change, we output
                    true.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: bills = [5,5,10,10,20]
                    <br />
                    Output: false
                    <br />
                    Explanation: <br />
                    From the first two customers in order, we collect two $5
                    bills.
                    <br />
                    For the next two customers in order, we collect a $10 bill
                    and give back a $5 bill.
                    <br /> For the last customer, we can not give the change of
                    $15 back because we only have two $10 bills.
                    <br /> Since not every customer received the correct change,
                    the answer is false.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= bills.length &lt;= 10^5
                <br />
                bills[i] is either 5, 10, or 20.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: bills = [5,5,5,10,20]
                class Solution {
                  public boolean lemonadeChange(int[] bills) {
                     int bill_5 = 0;
                     int bill_10 = 0;
                     for (int num : bills) {
                         if (num == 5) {
                             bill_5++;
                             continue;
                         } else if (num == 10) {
                             if (bill_5 > 0) {
                                 bill_5--;
                                 bill_10++;
                                 continue;
                             } else {
                                 return false;
                             }
                         } else if (num == 20) {
                             if (bill_10 > 0 && bill_5 > 0) {
                                 bill_10--;
                                 bill_5--;
                             } else if (bill_5 >= 3) {
                                 bill_5 = bill_5 - 3;
                             } else {
                                 return false;
                             }
                         }
                     }
                     return true;
                 }
             }`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q705: {
        title: "Q861. Score After Flipping Matrix (Q705)",
        content: (
          <Comp
            title="Q861. Score After Flipping Matrix (Q705)"
            content1={
              <>
                A move consists of choosing any row or column and toggling each
                value in that row or column (i.e., changing all 0's to 1's, and
                all 1's to 0's).
                <br />
                Every row of the matrix is interpreted as a binary number, and
                the score of the matrix is the sum of these numbers.
                <br />
                Return the highest possible score after making any number of
                moves (including zero moves).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: grid = [[0,0,1,1],[1,0,1,0],[1,1,0,0]]
                    <br /> Output: 39 <br />
                    <br /> Explanation: 0b1111 + 0b1001 + 0b1111 = 15 + 9 + 15 =
                    39
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[0]] <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == grid.length <br />
                n == grid[i].length <br />
                1 &lt;= m, n &lt;= 20 <br />
                grid[i][j] is either 0 or 1.
              </>
            }
            tc="m.n"
            sc="m.n"
            codes={{
              Java: {
                code: `/**
                * @param {number[][]} grid
                * @return {number}
                */
               var matrixScore = function(grid) {
                   const m = grid.length;
                   const n = grid[0].length;
                   let res = m;
                   for (let j = 1; j < n; ++j) {
                     let onesCount = 0;
                     for (let i = 0; i < m; ++i)
                       onesCount += grid[i][j] == grid[i][0] ? 1 : 0;
                     res = res * 2 + Math.max(onesCount, m - onesCount);
                   }
                   return res;
               };
               console.log(matrixScore([[0,0,1,1],[1,0,1,0],[1,1,0,0]]))`,
                output: `39`,
              },
            }}
          />
        ),
      },
      q706: {
        title: "Q862. Shortest Subarray with Sum at Least K (Q706)",
        content: (
          <Comp
            title="Q862. Shortest Subarray with Sum at Least K (Q706)"
            content1={
              <>
                Given an integer array nums and an integer k, return the length
                of the shortest non-empty subarray of nums with a sum of at
                least k. If there is no such subarray, return -1.
                <br />A subarray is a contiguous part of an array.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1], k = 1<br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2], k = 4<br />
                    Output: -1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2,-1,2], k = 3<br />
                    Output: 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^5 <br />
                -10^5 &lt;= nums[i] &lt;= 10^5
                <br />1 &lt;= k &lt;= 10^9
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // A = [1] , k = 1
                class Solution {
                  public int shortestSubarray(int[] A, int K) {
                    final int n = A.length;
                    int ans = n + 1;
                    Deque<Integer> q = new ArrayDeque<>();
                    long[] prefix = new long[n + 1];
                    for (int i = 0; i < n; ++i)
                      prefix[i + 1] = (long) A[i] + prefix[i];
                    for (int i = 0; i < n + 1; ++i) {
                      while (!q.isEmpty() && prefix[i] - prefix[q.getFirst()] >= K)
                        ans = Math.min(ans, i - q.pollFirst());
                      while (!q.isEmpty() && prefix[i] <= prefix[q.getLast()])
                        q.pollLast();
                      q.addLast(i);
                    }
                    return ans <= n ? ans : -1;
                  }
                }
                `,
                output: `1`,
              },
            }}
          />
        ),
      },
      q707: {
        title: "Q863. All Nodes Distance K in Binary Tree (Q707)",
        content: (
          <Comp
            title="Q863. All Nodes Distance K in Binary Tree (Q707)"
            content1={
              <>
                Given the root of a binary tree, the value of a target node
                target, and an integer k, return an array of the values of all
                nodes that have a distance k from the target node.
                <br />
                You can return the answer in any order.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode863,
                content: (
                  <>
                    Input: root = [3,5,1,6,2,0,8,null,null,7,4], target = 5, k =
                    2
                    <br /> Output: [7,4,1] <br />
                    Explanation: The nodes that are a distance 2 from the target
                    node (with value 5) have values 7, 4, and 1.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1], target = 1, k = 3 <br />
                    Output: []
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 500]. <br />
                0 &lt;= Node.val &lt;= 500 <br />
                All the values Node.val are unique. <br />
                target is the value of one of the nodes in the tree. <br />0
                &lt;= k &lt;= 1000
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: root = [3,5,1,6,2,0,8,null,null,7,4], target = 5, k = 2
                /**
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode(int x) { val = x; }
                * }
                */
               class Solution {
                 public List<Integer> distanceK(TreeNode root, TreeNode target, int K) {
                   List<Integer> ans = new ArrayList<>();
                   Map<TreeNode, Integer> nodeToDist = new HashMap<>(); // {node: distance to target}
               
                   getDists(root, target, nodeToDist);
                   dfs(root, K, 0, nodeToDist, ans);
               
                   return ans;
                 }
                 private void getDists(TreeNode root, TreeNode target, Map<TreeNode, Integer> nodeToDist) {
                   if (root == null)
                     return;
                   if (root == target) {
                     nodeToDist.put(root, 0);
                     return;
                   }
                   getDists(root.left, target, nodeToDist);
                   if (nodeToDist.containsKey(root.left)) {
                     nodeToDist.put(root, nodeToDist.get(root.left) + 1);
                     return;
                   }
                   getDists(root.right, target, nodeToDist);
                   if (nodeToDist.containsKey(root.right))
                     nodeToDist.put(root, nodeToDist.get(root.right) + 1);
                 }
                 private void dfs(TreeNode root, int K, int dist, Map<TreeNode, Integer> nodeToDist,
                                  List<Integer> ans) {
                   if (root == null)
                     return;
                   if (nodeToDist.containsKey(root))
                     dist = nodeToDist.get(root);
                   if (dist == K)
                     ans.add(root.val);
                   dfs(root.left, K, dist + 1, nodeToDist, ans);
                   dfs(root.right, K, dist + 1, nodeToDist, ans);
                 }
               }`,
                output: `[7,4,1]`,
              },
            }}
          />
        ),
      },
      q708: {
        title: "Q864. Shortest Path to Get All Keys (Q708)",
        content: (
          <Comp
            title="Q864. Shortest Path to Get All Keys (Q708)"
            content1={
              <>
                You are given an m x n grid grid where:
                <br />
                '.' is an empty cell.
                <br />
                '#' is a wall.
                <br />
                '@' is the starting point.
                <br />
                Lowercase letters represent keys.
                <br />
                Uppercase letters represent locks.
                <br />
                You start at the starting point and one move consists of walking
                one space in one of the four cardinal directions. You cannot
                walk outside the grid, or walk into a wall.
                <br />
                If you walk over a key, you can pick it up and you cannot walk
                over a lock unless you have its corresponding key.
                <br />
                For some 1 &lt;= k &lt;= 6, there is exactly one lowercase and
                one uppercase letter of the first k letters of the English
                alphabet in the grid. This means that there is exactly one key
                for each lock, and one lock for each key; and also that the
                letters used to represent the keys and locks were chosen in the
                same order as the English alphabet.
                <br />
                Return the lowest number of moves to acquire all keys. If it is
                impossible, return -1.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode864,
                content: (
                  <>
                    Input: grid = ["@.a.#","###.#","b.A.B"] <br />
                    Output: 8 <br />
                    Explanation: Note that the goal is to obtain all the keys
                    not to open all the locks.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = ["@..aA","..B#.","....b"]
                    <br /> Output: 6
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = ["@Aa"] <br />
                    Output: -1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == grid.length <br />
                n == grid[i].length <br />
                1 &lt;= m, n &lt;= 30 <br />
                grid[i][j] is either an English letter, '.', '#', or '@'.
                <br /> The number of keys in the grid is in the range [1, 6].
                <br /> Each key in the grid is unique.
                <br /> Each key in the grid has a matching loc
              </>
            }
            tc="mn.2^n"
            sc="mn.2^n"
            codes={{
              Java: {
                code: `
                // Input: grid = ["@Aa"]
                class T {
                  public int i;
                  public int j;
                  public int keys; // keys in bitmask
                  public T(int i, int j, int keys) {
                    this.i = i;
                    this.j = j;
                    this.keys = keys;
                  }
                }
                
                class Solution {
                  public int shortestPathAllKeys(String[] grid) {
                    final int m = grid.length;
                    final int n = grid[0].length();
                    final int keysCount = getKeysCount(grid);
                    final int kKeys = (1 << keysCount) - 1;
                    final int[] dirs = {0, 1, 0, -1, 0};
                    final int[] start = getStart(grid);
                    int ans = 0;
                    Queue<T> q = new ArrayDeque<>(Arrays.asList(new T(start[0], start[1], 0)));
                    boolean[][][] seen = new boolean[m][n][kKeys];
                    seen[start[0]][start[1]][0] = true;
                
                    while (!q.isEmpty()) {
                      ++ans;
                      for (int sz = q.size(); sz > 0; --sz) {
                        final int i = q.peek().i;
                        final int j = q.peek().j;
                        final int keys = q.poll().keys;
                        for (int k = 0; k < 4; ++k) {
                          final int x = i + dirs[k];
                          final int y = j + dirs[k + 1];
                          if (x < 0 || x == m || y < 0 || y == n)
                            continue;
                          final char c = grid[x].charAt(y);
                          if (c == '#')
                            continue;
                          final int newKeys = 'a' <= c && c <= 'f' ? keys | 1 << c - 'a' : keys;
                          if (newKeys == kKeys)
                            return ans;
                          if (seen[x][y][newKeys])
                            continue;
                          if ('A' <= c && c <= 'F' && (newKeys >> c - 'A' & 1) == 0)
                            continue;
                          q.offer(new T(x, y, newKeys));
                          seen[x][y][newKeys] = true;
                        }
                      }
                    }
                
                    return -1;
                  }
                  private int getKeysCount(String[] grid) {
                    int count = 0;
                    for (final String s : grid)
                      count += (int) s.chars().filter(c -> 'a' <= c && c <= 'f').count();
                    return count;
                  }
                  private int[] getStart(String[] grid) {
                    for (int i = 0; i < grid.length; ++i)
                      for (int j = 0; j < grid[0].length(); ++j)
                        if (grid[i].charAt(j) == '@')
                          return new int[] {i, j};
                    throw new IllegalArgumentException();
                  }
                }`,
                output: `-1`,
              },
            }}
          />
        ),
      },
      q709: {
        title: "Q865. Smallest Subtree with all the Deepest Nodes (Q709)",
        content: (
          <Comp
            title="Q865. Smallest Subtree with all the Deepest Nodes (Q709)"
            content1={
              <>
                Given the root of a binary tree, the depth of each node is the
                shortest distance to the root.
                <br />
                Return the smallest subtree such that it contains all the
                deepest nodes in the original tree.
                <br />
                A node is called the deepest if it has the largest depth
                possible among any node in the entire tree.
                <br />
                The subtree of a node is a tree consisting of that node, plus
                the set of all descendants of that node.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: root = [3,5,1,6,2,0,8,null,null,7,4]
                    <br /> Output: [2,7,4] <br />
                    Explanation: We return the node with value 2, colored in
                    yellow in the diagram.
                    <br /> The nodes coloured in blue are the deepest nodes of
                    the tree.
                    <br /> Notice that nodes 5, 3 and 2 contain the deepest
                    nodes in the tree but node 2 is the smallest subtree among
                    them, so we return it.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [1] <br />
                    Output: [1] <br />
                    Explanation: The root is the deepest node in the tree.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [0,1,3,null,2] <br />
                    Output: [2] <br />
                    Explanation: The deepest node in the tree is 2, the valid
                    subtrees are the subtrees of nodes 2, 1 and 0 but the
                    subtree of node 2 is the smallest.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree will be in the range [1, 500].
                <br /> 0 &lt;= Node.val &lt;= 500
                <br />
                The values of the nodes in the tree are unique.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: root = [0,1,3,null,2]
                /**
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode() {}
                *     TreeNode(int val) { this.val = val; }
                *     TreeNode(int val, TreeNode left, TreeNode right) {
                *         this.val = val;
                *         this.left = left;
                *         this.right = right;
                *     }
                * }
                */
               class T {
                 public TreeNode lca;
                 public int depth;              
                 public T(TreeNode lca, int depth) {
                   this.lca = lca;
                   this.depth = depth;
                 }
               };
               class Solution {
                 public TreeNode subtreeWithAllDeepest(TreeNode root) {
                   return dfs(root).lca;
                 }
                 private T dfs(TreeNode root) {
                   if (root == null)
                     return new T(null, 0);
                   T l = dfs(root.left);
                   T r = dfs(root.right);
                   if (l.depth > r.depth)
                     return new T(l.lca, l.depth + 1);
                   if (l.depth < r.depth)
                     return new T(r.lca, r.depth + 1);
                   return new T(root, l.depth + 1);
                 }
               }
               `,
                output: `[2]`,
              },
            }}
          />
        ),
      },
      q710: {
        title: "Q866. Prime Palindrome (Q710)",
        content: (
          <Comp
            title="Q866. Prime Palindrome (Q710)"
            content1={
              <>
                Given an integer n, return the smallest prime palindrome greater
                than or equal to n.
                <br />
                An integer is prime if it has exactly two divisors: 1 and
                itself. Note that 1 is not a prime number.
                <br />
                For example, 2, 3, 5, 7, 11, and 13 are all primes.
                <br />
                An integer is a palindrome if it reads the same from left to
                right as it does from right to left.
                <br />
                For example, 101 and 12321 are palindromes.
                <br />
                The test cases are generated so that the answer always exists
                and is in the range [2, 2 * 108].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 6 <br />
                    Output: 7
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 8 <br />
                    Output: 11
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 13 <br />
                    Output: 101
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 10^8</>}
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `
                // 8
                class Solution {
                  public int primePalindrome(int N) {
                    if (N <= 2)
                      return 2;
                    if (N == 3)
                      return 3;
                    if (N <= 5)
                      return 5;
                    if (N <= 7)
                      return 7;
                    if (N <= 11)
                      return 11;
                    int n = String.valueOf(N).length();
                    while (true) {
                      for (int num : getPalindromes(n))
                        if (num >= N && isPrime(num))
                          return num;
                      ++n;
                    }
                  }
                  private List<Integer> getPalindromes(int n) {
                    List<Integer> palindromes = new ArrayList<>();
                    int length = n / 2;
                    for (int i = (int) Math.pow(10, length - 1); i < (int) Math.pow(10, length); ++i) {
                      String s = String.valueOf(i);
                      String reversedS = new StringBuilder(s).reverse().toString();
                      for (int j = 0; j < 10; ++j)
                        palindromes.add(Integer.valueOf(s + String.valueOf(j) + reversedS));
                    }
                    return palindromes;
                  }
                  private boolean isPrime(int num) {
                    for (int i = 2; i < (int) Math.sqrt(num) + 1; ++i)
                      if (num % i == 0)
                        return false;
                    return true;
                  }
                }`,
                output: `11`,
              },
            }}
          />
        ),
      },
      q711: {
        title: "Q867. Transpose Matrix (Q711)",
        content: (
          <Comp
            title="Q867. Transpose Matrix (Q711)"
            content1={
              <>
                Given a 2D integer array matrix, return the transpose of matrix.
                <br />
                The transpose of a matrix is the matrix flipped over its main
                diagonal, switching the matrix's row and column indices.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: matrix = [[1,2,3],[4,5,6],[7,8,9]]
                    <br />
                    Output: [[1,4,7],[2,5,8],[3,6,9]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: matrix = [[1,2,3],[4,5,6]]
                    <br />
                    Output: [[1,4],[2,5],[3,6]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == matrix.length <br />
                n == matrix[i].length <br />
                1 &lt;= m, n&lt;= 1000 <br />
                1 &lt;= m * n &lt;= 10^5 <br />
                -10^9 &lt;= matrix[i][j] &lt;= 10^9 <br />
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} matrix
                * @return {number[][]}
                */
               var transpose = function(matrix) {
                 const res = Array.from(Array(matrix[0].length),() => Array(matrix.length).fill(0));
                 for (let i = 0; i < matrix.length; ++i)
                   for (let j = 0; j < matrix[0].length; ++j)
                     res[j][i] = matrix[i][j];
                 return res;
               };
               console.log(transpose([[1,2,3],[4,5,6],[7,8,9]]))`,
                output: `[ [ 1, 4, 7 ], [ 2, 5, 8 ], [ 3, 6, 9 ] ]`,
              },
            }}
          />
        ),
      },
      q712: {
        title: "Q868. Binary Gap (Q712)",
        content: (
          <Comp
            title="Q868. Binary Gap (Q712)"
            content1={
              <>
                Given a positive integer n, find and return the longest distance
                between any two adjacent 1's in the binary representation of n.
                If there are no two adjacent 1's, return 0.
                <br />
                Two 1's are adjacent if there are only 0's separating them
                (possibly no 0's). The distance between two 1's is the absolute
                difference between their bit positions. For example, the two 1's
                in "1001" have a distance of 3.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 22 <br />
                    Output: 2 <br />
                    Explanation: 22 in binary is "10110". <br />
                    The first adjacent pair of 1's is "10110" with a distance of
                    2.
                    <br /> The second adjacent pair of 1's is "10110" with a
                    distance of 1.
                    <br /> The answer is the largest of these two distances,
                    which is 2.
                    <br /> Note that "10110" is not a valid pair since there is
                    a 1 separating the two 1's underlined.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 8 <br />
                    Output: 0 <br />
                    Explanation: 8 in binary is "1000". <br />
                    There are not any adjacent pairs of 1's in the binary
                    representation of 8, so we return 0.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 5 <br />
                    Output: 2 <br />
                    Explanation: 5 in binary is "101".
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 10^9</>}
            tc="log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               var binaryGap = function(n) {
                   let res = 0;
                   for (let d = -32; n > 0; n = parseInt(n/2), ++d)
                     if ((n & 1) == 1) {
                       res = Math.max(res, d);
                       d = 0;
                     }
                   return res;  
               };
               console.log(binaryGap(22))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q713: {
        title: "Q869. Reordered Power of 2 (Q713)",
        content: (
          <Comp
            title="Q869. Reordered Power of 2 (Q713)"
            content1={
              <>
                You are given an integer n. We reorder the digits in any order
                (including the original order) such that the leading digit is
                not zero.
                <br />
                Return true if and only if we can do this so that the resulting
                number is a power of two.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 1 <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 10 <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 10^9</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {boolean}
                */
               var reorderedPowerOf2 = function(n) {
                   let count = counter(n);
                   for (let i = 0; i < 30; ++i)
                     if (counter(1 << i) == count)
                       return true;
                   return false;
               };
               function counter(n) {
                   let count = 0;
                   for (; n > 0; n = parseInt(n/10))
                     count += Math.pow(10, n % 10);
                   return count;
               }       
               console.log(reorderedPowerOf2(2))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q714: {
        title: "Q870. Advantage Shuffle (Q714)",
        content: (
          <Comp
            title="Q870. Advantage Shuffle (Q714)"
            content1={
              <>
                You are given two integer arrays nums1 and nums2 both of the
                same length. The advantage of nums1 with respect to nums2 is the
                number of indices i for which nums1[i] > nums2[i].
                <br />
                Return any permutation of nums1 that maximizes its advantage
                with respect to nums2.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums1 = [2,7,11,15], <br />
                    nums2 = [1,10,4,11]
                    <br /> Output: [2,11,7,15]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums1 = [12,24,8,32], <br /> nums2 = [13,25,32,11]
                    <br /> Output: [24,32,8,12]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums1.length &lt;= 10^5 <br />
                nums2.length == nums1.length <br />0 &lt;= nums1[i], nums2[i]
                &lt;= 10^9
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: nums1 = [12,24,8,32], nums2 = [13,25,32,11]
                class Solution {
                  public int[] advantageCount(int[] A, int[] B) {
                    TreeMap<Integer, Integer> map = new TreeMap<>();              
                    for (int a : A)
                      map.put(a, map.getOrDefault(a, 0) + 1);
                    for (int i = 0; i < B.length; i++) {
                      Integer key = map.higherKey(B[i]);
                      if (key == null)
                        key = map.firstKey();
                      map.put(key, map.get(key) - 1);
                      if (map.get(key) == 0)
                        map.remove(key);
                      A[i] = key;
                    }
                    return A;
                  }
                }
                `,
                output: `[24,32,8,12]`,
              },
            }}
          />
        ),
      },
      q715: {
        title: "Q871. Minimum Number of Refueling Stops  (Q715)",
        content: (
          <Comp
            title="Q871. Minimum Number of Refueling Stops  (Q715)"
            content1={
              <>
                A car travels from a starting position to a destination which is
                target miles east of the starting position. There are gas
                stations along the way. The gas stations are represented as an
                array stations where stations[i] = [positioni, fueli] indicates
                that the ith gas station is positioni miles east of the starting
                position and has fueli liters of gas.
                <br />
                The car starts with an infinite tank of gas, which initially has
                startFuel liters of fuel in it. It uses one liter of gas per one
                mile that it drives. When the car reaches a gas station, it may
                stop and refuel, transferring all the gas from the station into
                the car.
                <br />
                Return the minimum number of refueling stops the car must make
                in order to reach its destination. If it cannot reach the
                destination, return -1.
                <br />
                Note that if the car reaches a gas station with 0 fuel left, the
                car can still refuel there. If the car reaches the destination
                with 0 fuel left, it is still considered to have arrived.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: target = 1, startFuel = 1, stations = []
                    <br /> Output: 0 <br />
                    Explanation: We can reach the target without refueling.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: target = 100, startFuel = 1, stations = [[10,100]]
                    <br /> Output: -1 <br />
                    Explanation: We can not reach the target (or even the first
                    gas station).
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: target = 100, startFuel = 10, stations =
                    [[10,60],[20,30],[30,30],[60,40]]
                    <br /> Output: 2 <br />
                    Explanation: We start with 10 liters of fuel. <br />
                    We drive to position 10, expending 10 liters of fuel. We
                    refuel from 0 liters to 60 liters of gas.
                    <br /> Then, we drive from position 10 to position 60
                    (expending 50 liters of fuel),
                    <br /> and refuel from 10 liters to 50 liters of gas. We
                    then drive to and reach the target.
                    <br /> We made 2 refueling stops along the way, so we return
                    2.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= target, startFuel &lt;= 10^9 <br />
                0 &lt;= stations.length &lt;= 500 <br />
                0 &lt;= positioni &lt;= positioni+1 &lt; target <br />1 &lt;=
                fueli &lt; 10^9
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: target = 100, startFuel = 10, stations = [[10,60],[20,30],[30,30],[60,40]]
                class Solution {
                  public int minRefuelStops(int target, int startFuel, int[][] stations) {
                    int ans = 0;
                    int i = 0; 
                    int curr = startFuel;
                    PriorityQueue<Integer> maxHeap = new PriorityQueue<>(Collections.reverseOrder());
                    while (curr < target) {
                      while (i < stations.length && curr >= stations[i][0])
                        maxHeap.offer(stations[i++][1]);
                      if (maxHeap.isEmpty()) 
                        return -1;
                      curr += maxHeap.poll();
                      ++ans;                 
                    }
                    return ans;
                  }
                }
                 `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q716: {
        title: "Q872. Leaf-Similar Trees (Q716)",
        content: (
          <Comp
            title="Q872. Leaf-Similar Trees (Q716)"
            content1={
              <>
                Consider all the leaves of a binary tree, from left to right
                order, the values of those leaves form a leaf value sequence.
              </>
            }
            content2={
              <>
                For example, in the given tree above, the leaf value sequence is
                (6, 7, 4, 9, 8).
                <br />
                Two binary trees are considered leaf-similar if their leaf value
                sequence is the same.
                <br />
                Return true if and only if the two given trees with head nodes
                root1 and root2 are leaf-similar.
              </>
            }
            img={Leetcode872}
            examples={[
              {
                img: Leetcode872a,
                content: (
                  <>
                    Input: root1 = [3,5,1,6,2,9,8,null,null,7,4],
                    <br /> root2 =
                    [3,5,1,6,7,4,2,null,null,null,null,null,null,9,8]
                    <br /> Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root1 = [1,2,3], root2 = [1,3,2]
                    <br /> Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in each tree will be in the range [1, 200].
                <br /> Both of the given trees will have values in the range [0,
                200].
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: root1 = [1,2,3], root2 = [1,3,2]
                /**
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode() {}
                *     TreeNode(int val) { this.val = val; }
                *     TreeNode(int val, TreeNode left, TreeNode right) {
                *         this.val = val;
                *         this.left = left;
                *         this.right = right;
                *     }
                * }
                */
               class Solution {
                 public boolean leafSimilar(TreeNode root1, TreeNode root2) {
                   List<Integer> leaves1 = new ArrayList<>();
                   List<Integer> leaves2 = new ArrayList<>();
                   dfs(root1, leaves1);
                   dfs(root2, leaves2);
                   return leaves1.equals(leaves2);
                 }
                 public void dfs(TreeNode node, List<Integer> leaves) {
                   if (node == null)
                     return;
                   if (node.left == null && node.right == null) {
                     leaves.add(node.val);
                     return;
                   }
                   dfs(node.left, leaves);
                   dfs(node.right, leaves);
                 }
               }
               `,
                output: `false`,
              },
            }}
          />
        ),
      },
      q717: {
        title: "Q873. Length of Longest Fibonacci Subsequence (Q717)",
        content: (
          <Comp
            title="Q873. Length of Longest Fibonacci Subsequence (Q717)"
            content1={
              <>
                A sequence x1, x2, ..., xn is Fibonacci-like if:
                <br />
                n &gt;= 3<br />
                xi + xi+1 == xi+2 for all i + 2 &lt;= n<br />
                Given a strictly increasing array arr of positive integers
                forming a sequence, return the length of the longest
                Fibonacci-like subsequence of arr. If one does not exist, return
                0.
                <br />A subsequence is derived from another sequence arr by
                deleting any number of elements (including none) from arr,
                without changing the order of the remaining elements. For
                example, [3, 5, 8] is a subsequence of [3, 4, 5, 6, 7, 8].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [1,2,3,4,5,6,7,8] <br />
                    Output: 5 <br />
                    Explanation: The longest subsequence that is fibonacci-like:
                    [1,2,3,5,8].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [1,3,7,11,12,14,18] <br />
                    Output: 3 <br />
                    Explanation: The longest subsequence that is fibonacci-like:
                    [1,11,12], [3,11,14] or [7,11,18].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                3 &lt;= arr.length &lt;= 1000 <br />1 &lt;= arr[i] &lt; arr[i +
                1] &lt;= 109
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Java: {
                code: `
                // Input: arr = [1,2,3,4,5,6,7,8]
                class Solution {
                  public int lenLongestFibSubseq(int[] A) {
                    final int n = A.length;
                    int ans = 0;
                    int[][] dp = new int[n][n];
                    Arrays.stream(dp).forEach(row -> Arrays.fill(row, 2));
                    Map<Integer, Integer> m = new HashMap<>();
                    for (int i = 0; i < n; ++i)
                      m.put(A[i], i);
                    for (int j = 0; j < n; ++j)
                      for (int k = j + 1; k < n; ++k) {
                        final int ai = A[k] - A[j];
                        if (ai < A[j] && m.containsKey(ai)) {
                          final int i = m.get(ai);
                          dp[j][k] = dp[i][j] + 1;
                          ans = Math.max(ans, dp[j][k]);
                        }
                      }
                    return ans;
                  }
                }
                `,
                output: `5`,
              },
            }}
          />
        ),
      },
      q718: {
        title: "Q874. Walking Robot Simulation (Q718)",
        content: (
          <Comp
            title="Q874. Walking Robot Simulation (Q718)"
            content1={
              <>
                A robot on an infinite XY-plane starts at point (0, 0) facing
                north. The robot can receive a sequence of these three possible
                types of commands:
                <br />
                -2: Turn left 90 degrees.
                <br />
                -1: Turn right 90 degrees.
                <br />
                1 &lt;= k &lt;= 9: Move forward k units, one unit at a time.
                <br />
                Some of the grid squares are obstacles. The ith obstacle is at
                grid point obstacles[i] = (xi, yi). If the robot runs into an
                obstacle, then it will instead stay in its current location and
                move on to the next command.
                <br />
                Return the maximum Euclidean distance that the robot ever gets
                from the origin squared (i.e. if the distance is 5, return 25).
                <br />
                Note:
                <br />
                North means +Y direction. <br />
                East means +X direction. <br />
                South means -Y direction. <br />
                West means -X direction.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: commands = [4,-1,3], obstacles = []
                    <br /> Output: 25 <br />
                    Explanation: The robot starts at (0, 0): <br />
                    1. Move north 4 units to (0, 4). <br />
                    2. Turn right. <br />
                    3. Move east 3 units to (3, 4). <br />
                    The furthest point the robot ever gets from the origin is
                    (3, 4), which squared is 32 + 42 = 25 units away.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: commands = [4,-1,4,-2,4], obstacles = [[2,4]] <br />
                    Output: 65 <br />
                    Explanation: The robot starts at (0, 0): <br />
                    1. Move north 4 units to (0, 4). <br />
                    2. Turn right. <br />
                    3. Move east 1 unit and get blocked by the obstacle at (2,
                    4), robot is at (1, 4).
                    <br />
                    4. Turn left. <br />
                    5. Move north 4 units to (1, 8). <br />
                    The furthest point the robot ever gets from the origin is
                    (1, 8), which squared is 12 + 82 = 65 units away.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: commands = [6,-1,-1,6], obstacles = []
                    <br /> Output: 36
                    <br /> Explanation: The robot starts at (0, 0):
                    <br /> 1. Move north 6 units to (0, 6).
                    <br /> 2. Turn right.
                    <br /> 3. Turn right.
                    <br /> 4. Move south 6 units to (0, 0).
                    <br /> The furthest point the robot ever gets from the
                    origin is (0, 6), which squared is 62 = 36 units away.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= commands.length &lt;= 10^4 <br />
                commands[i] is either -2, -1, or an integer in the range [1, 9].
                <br /> 0 &lt;= obstacles.length &lt;= 10^4
                <br /> -3 * 10^4 &lt;= xi, yi &lt;= 3 * 10^4
                <br /> The answer is guaranteed to be less than 231.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code:
                  // Input: commands = [4,-1,4,-2,4], obstacles = [[2,4]]
                  `class Solution {
                  public int robotSim(int[] commands, int[][] obstacles) {
                    final int[] dirs = {0, 1, 0, -1, 0};
                    int ans = 0;
                    int d = 0; 
                    int x = 0;
                    int y = 0; 
                    Set<Pair<Integer, Integer>> set = new HashSet<>();
                    for (int[] o : obstacles)
                      set.add(new Pair<>(o[0], o[1]));
                    for (final int c : commands) {
                      if (c == -1) {
                        d = (d + 1) % 4;
                      } else if (c == -2) {
                        d = (d + 3) % 4;
                      } else {
                        for (int step = 0; step < c; ++step) {
                          if (set.contains(new Pair<>(x + dirs[d], y + dirs[d + 1])))
                            break;
                          x += dirs[d];
                          y += dirs[d + 1];
                        }
                      }
                      ans = Math.max(ans, x * x + y * y);
                    }
                    return ans;
                  }
                }
                `,
                output: `65`,
              },
            }}
          />
        ),
      },
      q719: {
        title: "Q875. Koko Eating Bananas (Q719)",
        content: (
          <Comp
            title="Q875. Koko Eating Bananas (Q719)"
            content1={
              <>
                Koko loves to eat bananas. There are n piles of bananas, the ith
                pile has piles[i] bananas. The guards have gone and will come
                back in h hours.
                <br />
                Koko can decide her bananas-per-hour eating speed of k. Each
                hour, she chooses some pile of bananas and eats k bananas from
                that pile. If the pile has less than k bananas, she eats all of
                them instead and will not eat any more bananas during this hour.
                <br />
                Koko likes to eat slowly but still wants to finish eating all
                the bananas before the guards return.
                <br />
                Return the minimum integer k such that she can eat all the
                bananas within h hours.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: piles = [3,6,7,11], h = 8<br />
                    Output: 4
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: piles = [30,11,23,4,20], h = 5<br />
                    Output: 30
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: piles = [30,11,23,4,20], h = 6<br />
                    Output: 23
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= piles.length &lt;= 10^4 <br />
                piles.length &lt;= h &lt;= 10^9
                <br />1 &lt;= piles[i] &lt;= 109
              </>
            }
            tc="n.log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} piles
                * @param {number} h
                * @return {number}
                */
               var minEatingSpeed = function(piles, h) {
                 let l = 1;
                 let r = Math.max(...piles)
                 while (l < r) {
                   let m = l + parseInt((r - l) / 2);
                   if (eatHours(piles, m) <= h)
                     r = m;
                   else
                     l = m + 1;
                 }
                 return l;  
               };            
               function eatHours(piles, k) {
                   let hours = 0;
                   for (let pile of piles)
                     hours += parseInt((pile - 1) / k) + 1; 
                   return hours;
                 }
               console.log(minEatingSpeed([3,6,7,11],8))`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q720: {
        title: "Q876. Middle of the Linked List (Q720)",
        content: (
          <Comp
            title="Q876. Middle of the Linked List (Q720)"
            content1={
              <>
                Given the head of a singly linked list, return the middle node
                of the linked list.
                <br />
                If there are two middle nodes, return the second middle node.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: head = [1,2,3,4,5]
                    <br />
                    Output: [3,4,5]
                    <br />
                    Explanation: The middle node of the list is node 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: head = [1,2,3,4,5,6]
                    <br />
                    Output: [4,5,6]
                    <br />
                    Explanation: Since the list has two middle nodes with values
                    3 and 4, we return the second one.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the list is in the range [1, 100].
                <br /> 1 &lt;= Node.val &lt;= 100
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: ` function ListNode(val, next) {
                  this.val = (val===undefined ? 0 : val)
                  this.next = (next===undefined ? null : next)
            }
             
            /**
             * @param {ListNode} head
             * @return {ListNode}
             */
            var middleNode = function(head) {
              let slow = head;
              let fast = head;
              while (fast && fast.next) {
                slow = slow.next;
                fast = fast.next.next;
              }
              return slow;
            };
            
            const l = new ListNode(1)
            l.next =  new ListNode(2)
            l.next.next =  new ListNode(3)
            l.next.next.next  =  new ListNode(4)
            l.next.next.next.next =    new ListNode(5)
            console.log(middleNode(l))`,
                output: `ListNode {
                  val: 3,
                  next: ListNode { val: 4, next: ListNode { val: 5, next: null } }
                }`,
              },
            }}
          />
        ),
      },
      q721: {
        title: "Q877. Stone Game (Q721)",
        content: (
          <Comp
            title="Q877. Stone Game (Q721)"
            content1={
              <>
                Alice and Bob play a game with piles of stones. There are an
                even number of piles arranged in a row, and each pile has a
                positive integer number of stones piles[i].
                <br />
                The objective of the game is to end with the most stones. The
                total number of stones across all the piles is odd, so there are
                no ties.
                <br />
                Alice and Bob take turns, with Alice starting first. Each turn,
                a player takes the entire pile of stones either from the
                beginning or from the end of the row. This continues until there
                are no more piles left, at which point the person with the most
                stones wins.
                <br />
                Assuming Alice and Bob play optimally, return true if Alice wins
                the game, or false if Bob wins.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: piles = [5,3,4,5] <br />
                    Output: true <br />
                    Explanation: <br />
                    Alice starts first, and can only take the first 5 or the
                    last 5. <br />
                    Say she takes the first 5, so that the row becomes [3, 4,
                    5]. <br />
                    If Bob takes 3, then the board is [4, 5], and Alice takes 5
                    to win with 10 points. <br />
                    If Bob takes the last 5, then the board is [3, 4], and Alice
                    takes 4 to win with 9 points. <br />
                    This demonstrated that taking the first 5 was a winning move
                    for Alice, so we return true.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: piles = [3,7,2,3] <br />
                    Output: true
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= piles.length &lt;= 500 <br />
                piles.length is even. <br />
                1 &lt;= piles[i] &lt;= 500 <br />
                sum(piles[i]) is odd.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} piles
                * @return {boolean}
                */
               var stoneGame = function(piles) {
                 const n = piles.length;
                 const dp = piles.slice();
                 for (let d = 1; d < n; ++d)
                   for (let j = n - 1; j - d >= 0; --j) {
                     const i = j - d;
                       dp[j] = Math.max(piles[i] - dp[j], piles[j] - dp[j - 1]);
                     }
                 return dp[n - 1] > 0;  
               };
               console.log(stoneGame([5,3,4,5]))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q722: {
        title: "Q878. Nth Magical Number (Q722)",
        content: (
          <Comp
            title="Q878. Nth Magical Number (Q722)"
            content1={
              <>
                A positive integer is magical if it is divisible by either a or
                b.
                <br />
                Given the three integers n, a, and b, return the nth magical
                number. Since the answer may be very large, return it modulo 109
                + 7.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 1, a = 2, b = 3 <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 4, a = 2, b = 3<br />
                    Output: 6
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 10^9 <br />2 &lt;= a, b &lt;= 4 * 10^4
              </>
            }
            tc="log n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @param {number} a
                * @param {number} b
                * @return {number}
                */
               var nthMagicalNumber = function(n, a, b) {
                 const kMod = 1e9 + 7;
                 let lcm = a * parseInt(b / gcd(a, b));
                 let l = Math.min(a, b);
                 let r = Math.min(a, b) * n;
                   while (l < r) {
                     let m = l + parseInt((r - l) / 2);
                     if (parseInt(m / a) +parseInt(m / b) - parseInt(m / lcm) >= n)
                       r = m;
                     else
                       l = m + 1;
                   }
                   return parseInt(l % kMod);
               }
               
               function gcd(a, b) {
                 return b == 0 ? a : gcd(b, a % b);
               }
               
               console.log(nthMagicalNumber(1,2,3))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q723: {
        title: "Q879. Profitable Schemes (Q723)",
        content: (
          <Comp
            title="Q879. Profitable Schemes (Q723)"
            content1={
              <>
                There is a group of n members, and a list of various crimes they
                could commit. The ith crime generates a profit[i] and requires
                group[i] members to participate in it. If a member participates
                in one crime, that member can't participate in another crime.
                <br />
                Let's call a profitable scheme any subset of these crimes that
                generates at least minProfit profit, and the total number of
                members participating in that subset of crimes is at most n.
                <br />
                Return the number of schemes that can be chosen. Since the
                answer may be very large, return it modulo 109 + 7.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 5, minProfit = 3, group = [2,2], profit = [2,3]
                    <br />
                    Output: 2<br />
                    Explanation: To make a profit of at least 3, the group could
                    either commit crimes 0 and 1, or just crime 1.
                    <br /> In total, there are 2 schemes.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 10, minProfit = 5, group = [2,3,5], profit =
                    [6,7,8]
                    <br />
                    Output: 7<br />
                    Explanation: To make a profit of at least 5, the group could
                    commit any crimes, as long as they commit one.
                    <br /> There are 7 possible schemes: (0), (1), (2), (0,1),
                    (0,2), (1,2), and (0,1,2).
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 100 <br />
                0 &lt;= minProfit &lt;= 100 <br />
                1 &lt;= group.length &lt;= 100 <br />
                1 &lt;= group[i] &lt;= 100 <br />
                profit.length == group.length <br />0 &lt;= profit[i] &lt;= 100
              </>
            }
            tc="n^3"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @param {number} minProfit
                * @param {number[]} group
                * @param {number[]} profit
                * @return {number}
                */
               var profitableSchemes = function(n, minProfit, group, profit) {
                 let kMod =  1e9 + 7;
                 const dp = Array.from(Array(n + 1),()=> Array(minProfit + 1).fill(0));
                   for (let i = 0; i <= n; ++i)
                     dp[i][0] = 1;
                   for (let k = 1; k <= group.length; ++k) {
                     const g = group[k - 1];
                     const p = profit[k - 1];
                     for (let i = n; i >= g; --i)
                       for (let j = minProfit; j >= 0; --j) {
                         dp[i][j] += dp[i - g][Math.max(0, j - p)];
                         dp[i][j] %= kMod;
                       }
                   }
                   return dp[n][minProfit];
               };
               console.log(profitableSchemes(10,5,[2,3,5],[6,7,8]))`,
                output: `7`,
              },
            }}
          />
        ),
      },
      q724: {
        title: "Q880. Decoded String at Index (Q724)",
        content: (
          <Comp
            title="Q880. Decoded String at Index (Q724)"
            content1={
              <>
                You are given an encoded string s. To decode the string to a
                tape, the encoded string is read one character at a time and the
                following steps are taken:
                <br />
                If the character read is a letter, that letter is written onto
                the tape.
                <br /> If the character read is a digit d, the entire current
                tape is repeatedly written d - 1 more times in total.
                <br /> Given an integer k, return the kth letter (1-indexed) in
                the decoded string.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "leet2code3", k = 10 <br />
                    Output: "o" <br />
                    Explanation: The decoded string is
                    "leetleetcodeleetleetcodeleetleetcode".
                    <br /> The 10th letter in the string is "o".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "ha22", k = 5 <br />
                    Output: "h" <br />
                    Explanation: The decoded string is "hahahaha". <br />
                    The 5th letter is "h".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "a2345678999999999999999", k = 1 <br />
                    Output: "a" <br />
                    Explanation: The decoded string is "a" repeated
                    8301530446056247680 times.
                    <br />
                    The 1st letter is "a".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= s.length &lt;= 100 <br />
                s consists of lowercase English letters and digits 2 through 9.
                <br />
                s starts with a letter.
                <br />
                1 &lt;= k &lt;= 109
                <br />
                It is guaranteed that k is less than or equal to the length of
                the decoded string.
                <br /> The decoded string is guaranteed to have less than 263
                letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Java: {
                code: `
                // Input: s = "ha22", k = 5
                class Solution {
                  public String decodeAtIndex(String s, int k) {
                    long size = 0; 
                    for (final char c : s.toCharArray())
                      if (Character.isDigit(c))
                        size *= c - '0';
                      else
                        ++size;
                    for (int i = s.length() - 1; i >= 0; --i) {
                      k %= size;
                      if (k == 0 && Character.isAlphabetic(s.charAt(i)))
                        return s.substring(i, i + 1);
                      if (Character.isDigit(s.charAt(i)))
                        size /= s.charAt(i) - '0';
                      else
                        --size;
                    }
                    throw new IllegalArgumentException();
                  }
                }
                `,
                output: `h`,
              },
            }}
          />
        ),
      },
      q725: {
        title: "Q881. Boats to Save People (Q725)",
        content: (
          <Comp
            title="Q881. Boats to Save People (Q725)"
            content1={
              <>
                You are given an array people where people[i] is the weight of
                the ith person, and an infinite number of boats where each boat
                can carry a maximum weight of limit. Each boat carries at most
                two people at the same time, provided the sum of the weight of
                those people is at most limit.
                <br />
                Return the minimum number of boats to carry every given person.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: people = [1,2], limit = 3 <br />
                    Output: 1 <br />
                    Explanation: 1 boat (1, 2)
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: people = [3,2,2,1], limit = 3 <br />
                    Output: 3 <br />
                    Explanation: 3 boats (1, 2), (2) and (3)
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: people = [3,5,3,4], limit = 5 <br />
                    Output: 4 <br />
                    Explanation: 4 boats (3), (3), (4), (5)
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= people.length &lt;= 5 * 10^4 <br />1 &lt;= people[i]
                &lt;= limit &lt;= 3 * 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `/**
                * @param {number[]} people
                * @param {number} limit
                * @return {number}
                */
               var numRescueBoats = function(people, limit) {
                 let res = 0;
                 people.sort((a,b)=>a-b);
                 for (let i = 0, j = people.length - 1; i <= j; ++res) {
                   let remain = limit - people[j--];
                   if (people[i] <= remain)
                     ++i;
                 }
                 return res;
               };
               
               console.log(numRescueBoats([1,2],3))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q726: {
        title: "Q882. Reachable Nodes In Subdivided Graph (Q726)",
        content: (
          <Comp
            title="Q882. Reachable Nodes In Subdivided Graph (Q726)"
            content1={
              <>
                You are given an undirected graph (the "original graph") with n
                nodes labeled from 0 to n - 1. You decide to subdivide each edge
                in the graph into a chain of nodes, with the number of new nodes
                varying between each edge.
                <br />
                The graph is given as a 2D array of edges where edges[i] = [ui,
                vi, cnti] indicates that there is an edge between nodes ui and
                vi in the original graph, and cnti is the total number of new
                nodes that you will subdivide the edge into. Note that cnti == 0
                means you will not subdivide the edge.
                <br />
                To subdivide the edge [ui, vi], replace it with (cnti + 1) new
                edges and cnti new nodes. The new nodes are x1, x2, ..., xcnti,
                and the new edges are [ui, x1], [x1, x2], [x2, x3], ...,
                [xcnti-1, xcnti], [xcnti, vi].
                <br />
                In this new graph, you want to know how many nodes are reachable
                from the node 0, where a node is reachable if the distance is
                maxMoves or less.
                <br />
                Given the original graph and maxMoves, return the number of
                nodes that are reachable from node 0 in the new graph.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode882,
                content: (
                  <>
                    Input: edges = [[0,1,10],[0,2,1],[1,2,2]], maxMoves = 6, n =
                    3
                    <br /> Output: 13
                    <br /> Explanation: The edge subdivisions are shown in the
                    image above.
                    <br /> The nodes that are reachable are highlighted in
                    yellow.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: edges = [[0,1,4],[1,2,6],[0,2,8],[1,3,1]], maxMoves =
                    10, n = 4
                    <br /> Output: 23
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: edges = [[1,2,4],[1,4,5],[1,3,1],[2,3,4],[3,4,5]],
                    maxMoves = 17, n = 5
                    <br /> Output: 1
                    <br /> Explanation: Node 0 is disconnected from the rest of
                    the graph, so only node 0 is reachable.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= edges.length &lt;= min(n * (n - 1) / 2, 104) <br />
                edges[i].length == 3 <br />
                0 &lt;= ui &lt; vi &lt; n <br />
                There are no multiple edges in the graph. <br />
                0 &lt;= cnti &lt;= 10^4 <br />
                0 &lt;= maxMoves &lt;= 10^9 <br />1 &lt;= n &lt;= 3000
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: edges = [[1,2,4],[1,4,5],[1,3,1],[2,3,4],[3,4,5]], maxMoves = 17, n = 5
                class Solution {
                  public int reachableNodes(int[][] edges, int maxMoves, int n) {
                    List<Pair<Integer, Integer>>[] graph = new List[n];
                    Queue<int[]> minHeap = new PriorityQueue<>((a, b) -> a[0] - b[0]); // (d, u)
                    int[] dist = new int[n];
                    Arrays.fill(dist, maxMoves + 1);
                    for (int i = 0; i < n; ++i)
                      graph[i] = new ArrayList<>();
                    for (int[] e : edges) {
                      final int u = e[0];
                      final int v = e[1];
                      final int cnt = e[2];
                      graph[u].add(new Pair<>(v, cnt));
                      graph[v].add(new Pair<>(u, cnt));
                    }
                    minHeap.offer(new int[] {0, 0});
                    dist[0] = 0;
                    while (!minHeap.isEmpty()) {
                      final int d = minHeap.peek()[0];
                      final int u = minHeap.poll()[1];
                      if (d >= maxMoves)
                        break;
                      for (var node : graph[u]) {
                        final int v = node.getKey();
                        final int w = node.getValue();
                        final int newDist = d + w + 1;
                        if (newDist < dist[v]) {
                          dist[v] = newDist;
                          minHeap.offer(new int[] {newDist, v});
                        }
                      }
                    }
                    final int reachableNodes = (int) Arrays.stream(dist).filter(d -> d <= maxMoves).count();
                    int reachableSubnodes = 0;
                    for (int[] e : edges) {
                      final int u = e[0];
                      final int v = e[1];
                      final int cnt = e[2];
                      final int a = dist[u] > maxMoves ? 0 : Math.min(maxMoves - dist[u], cnt);
                      final int b = dist[v] > maxMoves ? 0 : Math.min(maxMoves - dist[v], cnt);
                      reachableSubnodes += Math.min(a + b, cnt);
                    }
                    return reachableNodes + reachableSubnodes;
                  }
                }`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q727: {
        title: "Q883. Projection Area of 3D Shapes (Q727)",
        content: (
          <Comp
            title="Q883. Projection Area of 3D Shapes (Q727)"
            content1={
              <>
                You are given an n x n grid where we place some 1 x 1 x 1 cubes
                that are axis-aligned with the x, y, and z axes.
                <br />
                Each value v = grid[i][j] represents a tower of v cubes placed
                on top of the cell (i, j).
                <br />
                We view the projection of these cubes onto the xy, yz, and zx
                planes.
                <br />
                A projection is like a shadow, that maps our 3-dimensional
                figure to a 2-dimensional plane. We are viewing the "shadow"
                when looking at the cubes from the top, the front, and the side.
                <br />
                Return the total area of all three projections.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode883,
                content: (
                  <>
                    Input: grid = [[1,2],[3,4]] <br />
                    Output: 17 <br />
                    Explanation: Here are the three projections ("shadows") of
                    the shape made with each axis-aligned plane.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[2]]
                    <br />
                    Output: 5
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[1,0],[0,2]] <br />
                    Output: 8
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == grid.length == grid[i].length
                <br />
                1 &lt;= n &lt;= 50
                <br />0 &lt;= grid[i][j] &lt;= 50
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} grid
                * @return {number}
                */
               var projectionArea = function(grid) {
                 let res = 0;
                 for (let i = 0; i < grid.length; ++i) {
                   let maxOfRow = 0;
                   let maxOfCol = 0;
                   for (let j = 0; j < grid.length; ++j) {
                       maxOfRow = Math.max(maxOfRow, grid[i][j]);
                       maxOfCol = Math.max(maxOfCol, grid[j][i]);
                       if (grid[i][j] > 0)
                         ++res;
                     }
                     res += maxOfRow + maxOfCol;
                 }
                 return res;  
               };
               console.log(projectionArea([[1,2],[3,4]]))`,
                output: `17`,
              },
            }}
          />
        ),
      },
      q728: {
        title: "Q884. Uncommon Words from Two Sentences (Q728)",
        content: (
          <Comp
            title="Q884. Uncommon Words from Two Sentences (Q728)"
            content1={
              <>
                A sentence is a string of single-space separated words where
                each word consists only of lowercase letters.
                <br />
                A word is uncommon if it appears exactly once in one of the
                sentences, and does not appear in the other sentence.
                <br />
                Given two sentences s1 and s2, return a list of all the uncommon
                words. You may return the answer in any order.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode885,
                content: (
                  <>
                    Input: s1 = "this apple is sweet", <br /> s2 = "this apple
                    is sour"
                    <br /> Output: ["sweet","sour"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s1 = "apple apple", <br /> s2 = "banana" <br />
                    Output: ["banana"]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s1.length, s2.length &lt;= 200 <br />
                s1 and s2 consist of lowercase English letters and spaces.{" "}
                <br />
                s1 and s2 do not have leading or trailing spaces. <br />
                All the words in s1 and s2 are separated by a single space.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `/**
                * @param {string} s1
                * @param {string} s2
                * @return {string[]}
                */
               var uncommonFromSentences = function(s1, s2) {
                const res = [];
                const count = {}
                for (let word of (s1 + ' ' + s2).split(" "))
                  count[word] = (count[word] || 0) + 1;
                for (let word in count)
                  if (count[word] == 1)
                    res.push(word);
                return res;  
               };
               console.log(uncommonFromSentences("this apple is sweet","this apple is sour"))`,
                output: `[ 'sweet', 'sour' ]`,
              },
            }}
          />
        ),
      },
      q729: {
        title: "Q885. Spiral Matrix III (Q729)",
        content: (
          <Comp
            title="Q885. Spiral Matrix III (Q729)"
            content1={
              <>
                You start at the cell (rStart, cStart) of an rows x cols grid
                facing east. The northwest corner is at the first row and column
                in the grid, and the southeast corner is at the last row and
                column.
                <br />
                You will walk in a clockwise spiral shape to visit every
                position in this grid. Whenever you move outside the grid's
                boundary, we continue our walk outside the grid (but may return
                to the grid boundary later.). Eventually, we reach all rows *
                cols spaces of the grid.
                <br />
                Return an array of coordinates representing the positions of the
                grid in the order you visited them.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode885,
                content: (
                  <>
                    Input: rows = 1, <br /> cols = 4, <br /> rStart = 0, <br />{" "}
                    cStart = 0
                    <br /> Output: [[0,0],[0,1],[0,2],[0,3]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: rows = 5, <br /> cols = 6, <br /> rStart = 1, <br />{" "}
                    cStart = 4
                    <br /> Output:
                    [[1,4],[1,5],[2,5],[2,4],[2,3],[1,3],[0,3],[0,4],[0,5],[3,5],[3,4],[3,3],[3,2],[2,2],[1,2],[0,2],[4,5],[4,4],[4,3],[4,2],[4,1],[3,1],[2,1],[1,1],[0,1],[4,0],[3,0],[2,0],[1,0],[0,0]]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= rows, cols &lt;= 100 <br />
                0 &lt;= rStart &lt; rows <br />0 &lt;= cStart &lt; cols
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} R
                * @param {number} C
                * @param {number} r0
                * @param {number} c0
                * @return {number[][]}
                */
               var spiralMatrixIII = function(R, C, r0, c0){
                 const res = [];
                 const dx = [1, 0, -1, 0];
                 const dy = [0, 1, 0, -1];
                 res.push([r0, c0]);
                 for (let i = 0; res.length < R * C; ++i)
                     for (let step = 0; step < parseInt(i / 2) + 1; ++step) {
                       r0 += dy[i % 4];
                       c0 += dx[i % 4];
                       if (0 <= r0 && r0 < R && 0 <= c0 && c0 < C)
                            res.push([r0, c0]);
                 }
                 return res;
               };
               
               console.log(spiralMatrixIII(1,4,0,0))`,
                output: `[ [ 0, 0 ], [ 0, 1 ], [ 0, 2 ], [ 0, 3 ] ]`,
              },
            }}
          />
        ),
      },
      q730: {
        title: "Q886. Possible Bipartition (Q730)",
        content: (
          <Comp
            title="Q886. Possible Bipartition (Q730)"
            content1={
              <>
                We want to split a group of n people (labeled from 1 to n) into
                two groups of any size. Each person may dislike some other
                people, and they should not go into the same group.
                <br />
                Given the integer n and the array dislikes where dislikes[i] =
                [ai, bi] indicates that the person labeled ai does not like the
                person labeled bi, return true if it is possible to split
                everyone into two groups in this way.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 4, dislikes = [[1,2],[1,3],[2,4]]
                    <br />
                    Output: true
                    <br />
                    Explanation: group1 [1,4] and group2 [2,3].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 3, dislikes = [[1,2],[1,3],[2,3]]
                    <br />
                    Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 5, dislikes = [[1,2],[2,3],[3,4],[4,5],[1,5]]
                    <br /> Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 2000 <br />
                0 &lt;= dislikes.length &lt;= 104 <br />
                dislikes[i].length == 2 <br />
                1 &lt;= dislikes[i][j] &lt;= n <br />
                ai &lt; bi <br />
                All the pairs of dislikes are unique.
              </>
            }
            tc="v+e"
            sc="v"
            codes={{
              Java: {
                code: `
                // Input: n = 4, dislikes = [[1,2],[1,3],[2,4]]
                enum Color { WHITE, RED, GREEN }
                class Solution {
                  public boolean possibleBipartition(int n, int[][] dislikes) {
                    List<Integer>[] graph = new List[n + 1];
                    Color[] colors = new Color[n + 1];
                    Arrays.fill(colors, Color.WHITE);
                    for (int i = 1; i <= n; ++i)
                      graph[i] = new ArrayList<>();
                    for (int[] d : dislikes) {
                      final int u = d[0];
                      final int v = d[1];
                      graph[u].add(v);
                      graph[v].add(u);
                    }
                    for (int i = 1; i <= n; ++i)
                      if (colors[i] == Color.WHITE && !isValidColor(graph, i, colors, Color.RED))
                        return false;
                    return true;
                  }
                  private boolean isValidColor(List<Integer>[] graph, int u, Color[] colors, Color color) {
                    if (colors[u] != Color.WHITE)
                      return colors[u] == color;
                    colors[u] = color; 
                    for (final int v : graph[u])
                      if (!isValidColor(graph, v, colors, color == Color.RED ? Color.GREEN : Color.RED))
                        return false;
                    return true;
                  }
                }`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q731: {
        title: "Q887. Super Egg Drop (Q731)",
        content: (
          <Comp
            title="Q887. Super Egg Drop (Q731)"
            content1={
              <>
                You are given k identical eggs and you have access to a building
                with n floors labeled from 1 to n.
                <br />
                You know that there exists a floor f where 0 &lt;= f &lt;= n
                such that any egg dropped at a floor higher than f will break,
                and any egg dropped at or below floor f will not break.
                <br />
                Each move, you may take an unbroken egg and drop it from any
                floor x (where 1 &lt;= x &lt;= n). If the egg breaks, you can no
                longer use it. However, if the egg does not break, you may reuse
                it in future moves.
                <br />
                Return the minimum number of moves that you need to determine
                with certainty what the value of f is.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: k = 1, n = 2 <br />
                    Output: 2 <br />
                    Explanation: <br />
                    Drop the egg from floor 1. If it breaks, we know that f = 0.{" "}
                    <br />
                    Otherwise, drop the egg from floor 2. If it breaks, we know
                    that f = 1. <br />
                    If it does not break, then we know f = 2. <br />
                    Hence, we need at minimum 2 moves to determine with
                    certainty what the value of f is.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: k = 2, n = 6 <br />
                    Output: 3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: k = 3, n = 14 <br />
                    Output: 4
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= k &lt;= 100
                <br />1 &lt;= n &lt;= 10^4
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} k
                * @param {number} n
                * @return {number}
                */
               var superEggDrop = function(k, n) {
                 let moves = 0;
                 const dp = Array.from(Array(n+1),()=>Array(k+1).fill(0));
                   while (dp[moves][k] < n) {
                     ++moves;
                     for (let eggs = 1; eggs <= k; ++eggs)
                       dp[moves][eggs] = dp[moves - 1][eggs - 1] + dp[moves - 1][eggs] + 1;
                   }
                   return moves;
               };
               
               console.log(superEggDrop(3,14))`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q732: {
        title: "Q888. Fair Candy Swap (Q732)",
        content: (
          <Comp
            title="Q888. Fair Candy Swap (Q732)"
            content1={
              <>
                Alice and Bob have a different total number of candies. You are
                given two integer arrays aliceSizes and bobSizes where
                aliceSizes[i] is the number of candies of the ith box of candy
                that Alice has and bobSizes[j] is the number of candies of the
                jth box of candy that Bob has.
                <br />
                Since they are friends, they would like to exchange one candy
                box each so that after the exchange, they both have the same
                total amount of candy. The total amount of candy a person has is
                the sum of the number of candies in each box they have.
                <br />
                Return an integer array answer where answer[0] is the number of
                candies in the box that Alice must exchange, and answer[1] is
                the number of candies in the box that Bob must exchange. If
                there are multiple answers, you may return any one of them. It
                is guaranteed that at least one answer exists.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: aliceSizes = [1,1], bobSizes = [2,2]
                    <br />
                    Output: [1,2]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: aliceSizes = [1,2], bobSizes = [2,3]
                    <br />
                    Output: [1,2]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: aliceSizes = [2], bobSizes = [1,3]
                    <br />
                    Output: [2,3]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= aliceSizes.length, bobSizes.length &lt;= 104
                <br />
                1 &lt;= aliceSizes[i], bobSizes[j] &lt;= 10^5
                <br />
                Alice and Bob have a different total number of candies.
                <br />
                There will be at least one valid answer for the given input.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} aliceSizes
                * @param {number[]} bobSizes
                * @return {number[]}
                */
               var fairCandySwap = function(aliceSizes, bobSizes) {
                 const A = aliceSizes
                 const B = bobSizes
                 let diff = parseInt((A.reduce((a,b)=>a+b,0) - B.reduce((a,b)=>a+b,0)) / 2);
                 const set = new Set();
                 for (let b of B)
                   set.add(b);
                 for (let a of A)
                   if (set.has(a - diff))
                     return [a, a - diff];
               };
               
               console.log(fairCandySwap([1,2],[2,3]))`,
                output: `[ 1, 2 ]`,
              },
            }}
          />
        ),
      },
      q733: {
        title:
          "Q889. Construct Binary Tree from Preorder and Postorder Traversal (Q733)",
        content: (
          <Comp
            title="Q889. Construct Binary Tree from Preorder and Postorder Traversal (Q733)"
            content1={
              <>
                Given two integer arrays, preorder and postorder where preorder
                is the preorder traversal of a binary tree of distinct values
                and postorder is the postorder traversal of the same tree,
                reconstruct and return the binary tree.
                <br />
                If there exist multiple answers, you can return any of them.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode889,
                content: (
                  <>
                    Input: preorder = [1,2,4,5,3,6,7], <br /> postorder =
                    [4,5,2,6,7,3,1]
                    <br /> Output: [1,2,3,4,5,6,7]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: preorder = [1], <br />
                    postorder = [1] <br />
                    Output: [1]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= preorder.length &lt;== 30 <br />
                1 &lt;== preorder[i] &lt;== preorder.length <br />
                All the values of preorder are unique. <br />
                postorder.length == preorder.length <br />
                1 &lt;== postorder[i] &lt;== postorder.length <br />
                All the values of postorder are unique. <br />
                It is guaranteed that preorder and postorder are the preorder
                traversal and postorder traversal of the same binary tree.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `/**
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode() {}
                *     TreeNode(int val) { this.val = val; }
                *     TreeNode(int val, TreeNode left, TreeNode right) {
                *         this.val = val;
                *         this.left = left;
                *         this.right = right;
                *     }
                * }
                */
               class Solution {
                 public TreeNode constructFromPrePost(int[] pre, int[] post) {
                   Map<Integer, Integer> postToIndex = new HashMap<>();        
                   for (int i = 0; i < post.length; ++i)
                     postToIndex.put(post[i], i);
                   return build(pre, 0, pre.length - 1, post, 0, post.length - 1, postToIndex);
                 }
               
                 private TreeNode build(int[] pre, int preStart, int preEnd, int[] post, int postStart,
                                        int postEnd, Map<Integer, Integer> postToIndex) {
                   if (preStart > preEnd)
                     return null;
                   if (preStart == preEnd)
                     return new TreeNode(pre[preStart]);
                   final int rootVal = pre[preStart];
                   final int leftRootVal = pre[preStart + 1];
                   final int leftRootPostIndex = postToIndex.get(leftRootVal);
                   final int leftSize = leftRootPostIndex - postStart + 1;
                   TreeNode root = new TreeNode(rootVal);
                   root.left = build(pre, preStart + 1, preStart + leftSize, post, postStart, leftRootPostIndex,
                                     postToIndex);
                   root.right = build(pre, preStart + leftSize + 1, preEnd, post, leftRootPostIndex + 1,
                                      postEnd - 1, postToIndex);
                   return root;
                 }
               }
               `,
                output: `[ 1 ]`,
              },
            }}
          />
        ),
      },
      q734: {
        title: "Q890. Find and Replace Pattern (Q734)",
        content: (
          <Comp
            title="Q890. Find and Replace Pattern (Q734)"
            content1={
              <>
                Given a list of strings words and a string pattern, return a
                list of words[i] that match pattern. You may return the answer
                in any order.
                <br />
                A word matches the pattern if there exists a permutation of
                letters p so that after replacing every letter x in the pattern
                with p(x), we get the desired word.
                <br />
                Recall that a permutation of letters is a bijection from letters
                to letters: every letter maps to another letter, and no two
                letters map to the same letter.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: words = ["abc","deq","mee","aqq","dkd","ccc"],
                    pattern = "abb"
                    <br /> Output: ["mee","aqq"] <br />
                    <br /> Explanation: "mee" matches the pattern because there
                    is a permutation {"{a -> m, b -> e, ...}"}. "ccc" does not
                    match the pattern because {"{a -> c, b -> c, ...}"} is not a
                    permutation, since a and b map to the same letter.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words = ["a","b","c"], pattern = "a" <br />
                    Output: ["a","b","c"]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= pattern.length &lt;= 20
                <br />
                1 &lt;= words.length &lt;= 50
                <br />
                words[i].length == pattern.length
                <br />
                pattern and words[i] are lowercase English letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: words = ["abc","deq","mee","aqq","dkd","ccc"], pattern = "abb"
                class Solution {
                  public List<String> findAndReplacePattern(String[] words, String pattern) {
                    List<String> ans = new ArrayList<>();
                    for (final String word : words)
                      if (isIsomorphic(word, pattern))
                        ans.add(word);
                    return ans;
                  }
                
                  private boolean isIsomorphic(final String w, final String p) {
                    Map<Character, Integer> map_w = new HashMap<>();
                    Map<Character, Integer> map_p = new HashMap<>();
                    for (Integer i = 0; i < w.length(); ++i)
                      if (map_w.put(w.charAt(i), i) != map_p.put(p.charAt(i), i))
                        return false;
                    return true;
                  }
                }
                `,
                output: `["mee","aqq"]`,
              },
            }}
          />
        ),
      },
      q735: {
        title: "Q891. Sum of Subsequence Widths (Q735)",
        content: (
          <Comp
            title="Q891. Sum of Subsequence Widths (Q735)"
            content1={
              <>
                The width of a sequence is the difference between the maximum
                and minimum elements in the sequence.
                <br />
                Given an array of integers nums, return the sum of the widths of
                all the non-empty subsequences of nums. Since the answer may be
                very large, return it modulo 109 + 7.
                <br />A subsequence is a sequence that can be derived from an
                array by deleting some or no elements without changing the order
                of the remaining elements. For example, [3,6,2,7] is a
                subsequence of the array [0,3,1,6,2,2,7].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [2,1,3] <br />
                    Output: 6 <br />
                    Explanation: The subsequences are [1], [2], [3], [2,1],
                    [2,3], [1,3], [2,1,3].
                    <br /> The corresponding widths are 0, 0, 0, 1, 1, 2, 2.
                    <br /> The sum of these widths is 6.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2] <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^5
                <br />1 &lt;= nums[i] &lt;= 10^5
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var sumSubseqWidths = function(nums) {
                 const kMod = 1e9 + 7;
                 const n = nums.length;
                 let ans = 0;
                 let exp = 1;
                 nums = nums.sort((a,b)=>a-b);
                 for (let i = 0; i < n; ++i, exp = exp * 2 % kMod) {
                   ans += (nums[i] - nums[n - i - 1]) * exp;
                   ans %= kMod;
                 }
                 return parseInt(ans);
               };
               
               console.log(sumSubseqWidths([2,1,3]))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q736: {
        title: "Q892. Surface Area of 3D Shapes (Q736)",
        content: (
          <Comp
            title="Q892. Surface Area of 3D Shapes (Q736)"
            content1={
              <>
                You are given an n x n grid where you have placed some 1 x 1 x 1
                cubes. Each value v = grid[i][j] represents a tower of v cubes
                placed on top of cell (i, j).
                <br />
                After placing these cubes, you have decided to glue any directly
                adjacent cubes to each other, forming several irregular 3D
                shapes.
                <br />
                Return the total surface area of the resulting shapes.
                <br />
                Note: The bottom face of each shape counts toward its surface
                area.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: grid = [[1,2],[3,4]] <br />
                    Output: 34
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[1,1,1],[1,0,1],[1,1,1]] <br />
                    Output: 32
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[2,2,2],[2,1,2],[2,2,2]] <br />
                    Output: 46
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == grid.length == grid[i].length <br />
                1 &lt;= n &lt;= 50 <br />0 &lt;= grid[i][j] &lt;= 50
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} grid
                * @return {number}
                */
               var surfaceArea = function(grid) {
                 let ans = 0;
                 for (let i = 0; i < grid.length; ++i)
                     for (let j = 0; j < grid.length; ++j) {
                       if (grid[i][j] > 0)
                         ans += grid[i][j] * 4 + 2;
                       if (i > 0)
                         ans -= Math.min(grid[i][j], grid[i - 1][j]) * 2;
                       if (j > 0)
                         ans -= Math.min(grid[i][j], grid[i][j - 1]) * 2;
                     }
                   return ans;   
               };
               console.log(surfaceArea([[1,2],[3,4]]))`,
                output: `34`,
              },
            }}
          />
        ),
      },
      q737: {
        title: "Q893. Groups of Special-Equivalent Strings (Q737)",
        content: (
          <Comp
            title="Q893. Groups of Special-Equivalent Strings (Q737)"
            content1={
              <>
                You are given an array of strings of the same length words.
                <br />
                In one move, you can swap any two even indexed characters or any
                two odd indexed characters of a string words[i].
                <br />
                Two strings words[i] and words[j] are special-equivalent if
                after any number of moves, words[i] == words[j].
                <br />
                For example, words[i] = "zzxy" and words[j] = "xyzz" are
                special-equivalent because we may make the moves "zzxy" -&gt;
                "xzzy" -&gt; "xyzz". A group of special-equivalent strings from
                words is a non-empty subset of words such that:
                <br />
                Every pair of strings in the group are special equivalent, and
                The group is the largest size possible (i.e., there is not a
                string words[i] not in the group such that words[i] is
                special-equivalent to every string in the group). Return the
                number of groups of special-equivalent strings from words.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: words = ["abcd","cdab","cbad","xyzz","zzxy","zzyx"]
                    <br />
                    Output: 3 <br />
                    Explanation: <br />
                    One group is ["abcd", "cdab", "cbad"], since they are all
                    pairwise special equivalent, and none of the other strings
                    is all pairwise special equivalent to these.
                    <br /> The other two groups are ["xyzz", "zzxy"] and
                    ["zzyx"].
                    <br /> Note that in particular, "zzxy" is not special
                    equivalent to "zzyx".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words = ["abc","acb","bac","bca","cab","cba"]
                    <br /> Output: 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= words.length &lt;= 1000
                <br />
                1 &lt;= words[i].length &lt;= 20
                <br />
                words[i] consist of lowercase English letters.
                <br />
                All the strings are of the same length.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} words
                * @return {number}
                */
               var numSpecialEquivGroups = function(words) {
                 const set = new Set();
                 for (let s of words) {
                     let even = "";
                     let odd = "";
                     for (let i = 0; i < s.length; ++i)
                       if (i % 2 == 0)
                         even += s[i];
                       else
                         odd += s[i];
                     const evenCharArray = even.split("");
                     const oddCharArray = odd.split("");
                     evenCharArray.sort((a,b)=>a<b?1:-1)
                     oddCharArray.sort((a,b)=>a<b?1:-1)
                     set.add(evenCharArray.join("") + oddCharArray.join(""));
                   }
                   return set.size;   
               };
               
               console.log(numSpecialEquivGroups(["abc","acb","bac","bca","cab","cba"]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q738: {
        title: "Q894. All Possible Full Binary Trees (Q738)",
        content: (
          <Comp
            title="Q894. All Possible Full Binary Trees (Q738)"
            content1={
              <>
                Given an integer n, return a list of all possible full binary
                trees with n nodes. Each node of each tree in the answer must
                have Node.val == 0.
                <br />
                Each element of the answer is the root node of one possible
                tree. You may return the final list of trees in any order.
                <br />A full binary tree is a binary tree where each node has
                exactly 0 or 2 children.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 7 <br />
                    Output: [[0,0,0,null,null,0,0,null,null,0,0], <br />
                    [0,0,0,null,null,0,0,0,0], <br />
                    [0,0,0,0,0,0,0], <br />
                    [0,0,0,0,0,null,null,null,null,0,0], <br />
                    [0,0,0,0,0,null,null,0,0]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 3 <br />
                    Output: [[0,0,0]]
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 20</>}
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
                // n = 7
                /**
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode() {}
                *     TreeNode(int val) { this.val = val; }
                *     TreeNode(int val, TreeNode left, TreeNode right) {
                *         this.val = val;
                *         this.left = left;
                *         this.right = right;
                *     }
                * }
                */
               
               class Solution {
                 public List<TreeNode> allPossibleFBT(int n) {
                   if (n % 2 == 0)
                     return new ArrayList<>();
                   if (n == 1)
                     return Arrays.asList(new TreeNode(0));
                   if (memo.containsKey(n))
                     return memo.get(n);
               
                   List<TreeNode> ans = new ArrayList<>();
               
                   for (int leftCount = 0; leftCount < n; ++leftCount) {
                     final int rightCount = n - 1 - leftCount;
                     for (TreeNode left : allPossibleFBT(leftCount))
                       for (TreeNode right : allPossibleFBT(rightCount)) {
                         ans.add(new TreeNode(0));
                         ans.get(ans.size() - 1).left = left;
                         ans.get(ans.size() - 1).right = right;
                       }
                   }
               
                   memo.put(n, ans);
                   return ans;
                 }
                 private Map<Integer, List<TreeNode>> memo = new HashMap<>();
               }`,
                output: `[[0,0,0,null,null,0,0,null,null,0,0],[0,0,0,null,null,0,0,0,0],[0,0,0,0,0,0,0],[0,0,0,0,0,null,null,null,null,0,0],[0,0,0,0,0,null,null,0,0]]`,
              },
            }}
          />
        ),
      },
      q739: {
        title: "Q895. Maximum Frequency Stack (Q739)",
        content: (
          <Comp
            title="Q895. Maximum Frequency Stack (Q739)"
            content1={
              <>
                Design a stack-like data structure to push elements to the stack
                and pop the most frequent element from the stack.
                <br />
                Implement the FreqStack class:
                <br />
                FreqStack() constructs an empty frequency stack.
                <br />
                void push(int val) pushes an integer val onto the top of the
                stack.
                <br />
                int pop() removes and returns the most frequent element in the
                stack.
                <br />
                If there is a tie for the most frequent element, the element
                closest to the stack's top is removed and returned.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input
                    <br /> ["FreqStack", "push", "push", "push", "push", "push",
                    "push", "pop", "pop", "pop", "pop"] [[], [5], [7], [5], [7],
                    [4], [5], [], [], [], []] <br />
                    Output <br />
                    [null, null, null, null, null, null, null, 5, 7, 5, 4]{" "}
                    <br />
                    Explanation
                    <br /> FreqStack freqStack = new FreqStack();
                    <br /> freqStack.push(5); // The stack is [5]
                    <br /> freqStack.push(7); // The stack is [5,7]
                    <br /> freqStack.push(5); // The stack is [5,7,5]
                    <br /> freqStack.push(7); // The stack is [5,7,5,7]
                    <br /> freqStack.push(4); // The stack is [5,7,5,7,4]
                    <br /> freqStack.push(5); // The stack is [5,7,5,7,4,5]
                    <br /> freqStack.pop(); // return 5, as 5 is the most
                    frequent. The stack becomes [5,7,5,7,4].
                    <br /> freqStack.pop(); // return 7, as 5 and 7 is the most
                    frequent, but 7 is closest to the top. The stack becomes
                    [5,7,5,4].
                    <br /> freqStack.pop(); // return 5, as 5 is the most
                    frequent. The stack becomes [5,7,4].
                    <br /> freqStack.pop(); // return 4, as 4, 5 and 7 is the
                    most frequent, but 4 is closest to the top. The stack
                    becomes [5,7].
                    <br />{" "}
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= val &lt;= 10^9 <br />
                At most 2 * 10^4 calls will be made to push and pop. <br />
                It is guaranteed that there will be at least one element in the
                stack before calling pop.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `
  // Input
  // ["FreqStack", "push", "push", "push", "push", "push", "push", "pop", "pop", "pop", "pop"]
  // [[], [5], [7], [5], [7], [4], [5], [], [], [], []]
                class FreqStack {
                  public void push(int val) {
                    count.merge(val, 1, Integer::sum);
                    st.putIfAbsent(count.get(val), new ArrayDeque<>());
                    st.get(count.get(val)).push(val);
                    maxFreq = Math.max(maxFreq, count.get(val));
                  }
                
                  public int pop() {
                    final int val = st.get(maxFreq).pop();
                    count.merge(val, -1, Integer::sum);
                    if (st.get(maxFreq).isEmpty())
                      --maxFreq;
                    return val;
                  }
                
                  private int maxFreq = 0;
                  private Map<Integer, Integer> count = new HashMap<>();
                  private Map<Integer, Deque<Integer>> st = new HashMap<>();
                }
                
                /**
                 * Your FreqStack object will be instantiated and called as such:
                 * FreqStack obj = new FreqStack();
                 * obj.push(val);
                 * int param_2 = obj.pop();
                 */`,
                output: `[null, null, null, null, null, null, null, 5, 7, 5, 4]`,
              },
            }}
          />
        ),
      },
      q740: {
        title: "Q896. Monotonic Array (Q740)",
        content: (
          <Comp
            title="Q896. Monotonic Array (Q740)"
            content1={
              <>
                An array is monotonic if it is either monotone increasing or
                monotone decreasing.
                <br />
                An array nums is monotone increasing if for all i &lt;= j,
                nums[i] &lt;= nums[j]. An array nums is monotone decreasing if
                for all i &lt;= j, nums[i] &gt;= nums[j].
                <br />
                Given an integer array nums, return true if the given array is
                monotonic, or false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,2,3]
                    <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [6,5,4,4]
                    <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,3,2]
                    <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^5 <br />
                -10^5 &lt;= nums[i] &lt;= 10^5
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {boolean}
                */
               var isMonotonic = function(nums) {
                 let increasing = true;
                 let decreasing = true;
                 for (let i = 1; i < nums.length; ++i) {
                   increasing &= nums[i] >= nums[i - 1];
                   decreasing &= nums[i] <= nums[i - 1];
                 }
                 return increasing || decreasing;  
               };
               
               console.log(isMonotonic([1,2,2,3]))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q741: {
        title: "Q897. Increasing Order Search Tree (Q741)",
        content: (
          <Comp
            title="Q897. Increasing Order Search Tree (Q741)"
            content1={
              <>
                Given the root of a binary search tree, rearrange the tree in
                in-order so that the leftmost node in the tree is now the root
                of the tree, and every node has no left child and only one right
                child.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode897,
                content: (
                  <>
                    Input: root = [5,3,6,2,4,null,8,1,null,null,null,7,9]
                    <br />
                    Output: <br />
                    [1,null,2,null,3,null,4,null,5,null,6,null,7,null,8,null,9]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [5,1,7] <br />
                    Output: [1,null,5,null,7]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the given tree will be in the range [1,
                100].
                <br /> 0 &lt;= Node.val &lt;= 1000
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                /**
                 * @param {TreeNode} root
                 * @return {TreeNode}
                 */
                var increasingBST = function(root) {
                  return helper(root);
                };
                function helper(root, tail=null) {
                  if (!root) return tail;
                  let ans = helper(root.left, root);
                  root.left = null
                  root.right = helper(root.right, tail);
                  return ans;
                }
                const t = new TreeNode(5)
                t.left = new TreeNode(1)
                t.right = new TreeNode(7)
                console.log(increasingBST(t))`,
                output: `TreeNode {
                  val: 1,
                  left: null,
                  right: TreeNode {
                    val: 5,
                    left: null,
                    right: TreeNode { val: 7, left: null, right: null }
                  }
                }`,
              },
            }}
          />
        ),
      },
      q742: {
        title: "Q898. Bitwise ORs of Subarrays (Q742)",
        content: (
          <Comp
            title="Q898. Bitwise ORs of Subarrays (Q742)"
            content1={
              <>
                We have an array arr of non-negative integers.
                <br />
                For every (contiguous) subarray sub = [arr[i], arr[i + 1], ...,
                arr[j]] (with i &lt;= j), we take the bitwise OR of all the
                elements in sub, obtaining a result arr[i] | arr[i + 1] | ... |
                arr[j].
                <br />
                Return the number of possible results. Results that occur more
                than once are only counted once in the final answer
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [1,1,2] <br />
                    Output: 3 <br />
                    Explanation: The possible subarrays are [1], [1], [2], [1,
                    1], [1, 2], [1, 1, 2].
                    <br /> These yield the results 1, 1, 2, 1, 3, 3.
                    <br /> There are 3 unique values, so the answer is 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [1,1,2] <br />
                    Output: 3 <br />
                    Explanation: The possible subarrays are [1], [1], [2], [1,
                    1], [1, 2], [1, 1, 2]. These yield the results 1, 1, 2, 1,
                    3, 3. <br />
                    There are 3 unique values, so the answer is 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [1,2,4] <br />
                    Output: 6 <br />
                    Explanation: The possible results are 1, 2, 3, 4, 6, and 7.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 5 * 10^4 <br />0 &lt;= nums[i] &lt;=
                10^9
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} arr
                * @return {number}
                */
               var subarrayBitwiseORs = function(arr) {
                 const s = [];
                 let l = 0;
                 for (let a of arr) {
                   let r = s.length;
                   s.push(a);
                   for (let i = l; i < r; ++i)
                     if (s[s.length - 1] != (s[i] | a))
                         s.push(s[i] | a);
                   l = r;
                 }
                 return new Set(s).size;
               };
               
               console.log(subarrayBitwiseORs([1,2,4]))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q743: {
        title: "Q899. Orderly Queue (Q743)",
        content: (
          <Comp
            title="Q899. Orderly Queue (Q743)"
            content1={
              <>
                You are given a string s and an integer k. You can choose one of
                the first k letters of s and append it at the end of the
                string..
                <br />
                Return the lexicographically smallest string you could have
                after applying the mentioned step any number of moves.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "cba", k = 1 <br />
                    Output: "acb" <br />
                    Explanation: <br />
                    In the first move, we move the 1st character 'c' to the end,
                    obtaining the string "bac".
                    <br /> In the second move, we move the 1st character 'b' to
                    the end, obtaining the final result "acb".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "baaca", k = 3 <br />
                    Output: "aaabc" <br />
                    Explanation: <br />
                    In the first move, we move the 1st character 'b' to the end,
                    obtaining the string "aacab".
                    <br /> In the second move, we move the 3rd character 'c' to
                    the end, obtaining the final result "aaabc".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= k &lt;= s.length &lt;= 1000 <br />s consist of lowercase
                English letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @param {number} k
                * @return {string}
                */
               var orderlyQueue = function(s, k) {
                 if (k > 1) {
                   const chars = s.split("");
                   chars.sort((a,b)=>a>b?1:-1);
                   return chars.join("");
                 }
                 let ans = s;
                 for (let i = 1; i < s.length; ++i) {
                     let s2 = s.substring(i) + s.substring(0, i);
                     if (ans > s2)
                       ans = s2;
                   }
                 return ans;  
               };
               
               console.log(orderlyQueue("cba",1))`,
                output: `acb`,
              },
            }}
          />
        ),
      },
      q744: {
        title: "Q900. RLE Iterator (Q744)",
        content: (
          <Comp
            title="Q900. RLE Iterator (Q744)"
            content1={
              <>
                We can use run-length encoding (i.e., RLE) to encode a sequence
                of integers. In a run-length encoded array of even length
                encoding (0-indexed), for all even i, encoding[i] tells us the
                number of times that the non-negative integer value encoding[i +
                1] is repeated in the sequence.
                <br />
                For example, the sequence arr = [8,8,8,5,5] can be encoded to be
                encoding = [3,8,2,5]. encoding = [3,8,0,9,2,5] and encoding =
                [2,8,1,8,2,5] are also valid RLE of arr.
                <br />
                Given a run-length encoded array, design an iterator that
                iterates through it.
                <br />
                Implement the RLEIterator class:
                <br />
                RLEIterator(int[] encoded) Initializes the object with the
                encoded array encoded.
                <br />
                int next(int n) Exhausts the next n elements and returns the
                last element exhausted in this way. If there is no element left
                to exhaust, return -1 instead.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["RLEIterator", "next", "next", "next", "next"] <br />
                    [[[3, 8, 0, 9, 2, 5]], [2], [1], [1], [2]] <br />
                    Output <br />
                    [null, 8, 8, 5, -1] <br />
                    Explanation
                    <br /> RLEIterator rLEIterator = new RLEIterator([3, 8, 0,
                    9, 2, 5]); // This maps to the sequence [8,8,8,5,5].
                    <br /> rLEIterator.next(2); // exhausts 2 terms of the
                    sequence, returning 8. The remaining sequence is now [8, 5,
                    5].
                    <br /> rLEIterator.next(1); // exhausts 1 term of the
                    sequence, returning 8. The remaining sequence is now [5, 5].
                    <br /> rLEIterator.next(1); // exhausts 1 term of the
                    sequence, returning 5. The remaining sequence is now [5].
                    <br /> rLEIterator.next(2); // exhausts 2 terms, returning
                    -1. This is because the first term exhausted was 5,
                    <br /> but the second term did not exist. Since the last
                    term exhausted does not exist, we return -1.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= encoding.length &lt;= 1000 <br />
                encoding.length is even. <br />
                0 &lt;= encoding[i] &lt;= 109 <br />
                1 &lt;= n &lt;= 109 <br />
                At most 1000 calls will be made to next.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input
                // ["RLEIterator", "next", "next", "next", "next"]
                // [[[3, 8, 0, 9, 2, 5]], [2], [1], [1], [2]]
                class RLEIterator {
                  public RLEIterator(int[] A) {
                    this.A = A;
                  }    
                  public int next(int n) {
                    while (index < A.length && A[index] < n) {
                      n -= A[index];
                      index += 2;
                    }
                    if (index == A.length)
                      return -1;
                    A[index] -= n;
                    return A[index + 1];
                  }
                  private int index = 0;
                  private int[] A;
                }
                /**
                 * Your RLEIterator object will be instantiated and called as such:
                 * RLEIterator obj = new RLEIterator(encoding);
                 * int param_1 = obj.next(n);
                 */`,
                output: `[null, 8, 8, 5, -1]`,
              },
            }}
          />
        ),
      },
      q745: {
        title: "Q901. Online Stock Span (Q745)",
        content: (
          <Comp
            title="Q901. Online Stock Span (Q745)"
            content1={
              <>
                Design an algorithm that collects daily price quotes for some
                stock and returns the span of that stock's price for the current
                day.
                <br />
                The span of the stock's price today is defined as the maximum
                number of consecutive days (starting from today and going
                backward) for which the stock price was less than or equal to
                today's price.
                <br />
                For example, if the price of a stock over the next 7 days were
                [100,80,60,70,60,75,85], then the stock spans would be
                [1,1,1,2,1,4,6].
                <br />
                Implement the StockSpanner class:
                <br />
                StockSpanner() Initializes the object of the class.
                <br />
                int next(int price) Returns the span of the stock's price given
                that today's price is price.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["StockSpanner", "next", "next", "next", "next", "next",
                    "next", "next"] [[], [100], [80], [60], [70], [60], [75],
                    [85]] <br />
                    Output <br />
                    [null, 1, 1, 1, 2, 1, 4, 6] <br />
                    Explanation
                    <br /> StockSpanner stockSpanner = new StockSpanner();
                    <br /> stockSpanner.next(100); // return 1
                    <br /> stockSpanner.next(80); // return 1
                    <br /> stockSpanner.next(60); // return 1
                    <br /> stockSpanner.next(70); // return 2
                    <br /> stockSpanner.next(60); // return 1
                    <br /> stockSpanner.next(75); // return 4, because the last
                    4 prices (including today's price of 75) were less than or
                    equal to today's price.
                    <br /> stockSpanner.next(85); // return 6
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= price &lt;= 10^5
                <br />
                At most 10^4 calls will be made to next.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input
                // ["StockSpanner", "next", "next", "next", "next", "next", "next", "next"]
                // [[], [100], [80], [60], [70], [60], [75], [85]]
                class StockSpanner {
                  public int next(int price) {
                    int span = 1;
                    while (!stack.isEmpty() && stack.peek().getKey() <= price)
                      span += stack.pop().getValue();
                    stack.push(new Pair<>(price, span));
                    return span;
                  }
                  private Stack<Pair<Integer, Integer>> stack = new Stack<>();
                }
                /**
                 * Your StockSpanner object will be instantiated and called as such:
                 * StockSpanner obj = new StockSpanner();
                 * int param_1 = obj.next(price);
                 */`,
                output: `[null, 1, 1, 1, 2, 1, 4, 6]`,
              },
            }}
          />
        ),
      },
      q746: {
        title: "Q902. Numbers At Most N Given Digit Set (Q746)",
        content: (
          <Comp
            title="Q902. Numbers At Most N Given Digit Set (Q746)"
            content1={
              <>
                Given an array of digits which is sorted in non-decreasing
                order. You can write numbers using each digits[i] as many times
                as we want. For example, if digits = ['1','3','5'], we may write
                numbers such as '13', '551', and '1351315'.
                <br />
                Return the number of positive integers that can be generated
                that are less than or equal to a given integer n.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: digits = ["1","3","5","7"], n = 100
                    <br /> Output: 20
                    <br /> Explanation:
                    <br /> The 20 numbers that can be written are:
                    <br /> 1, 3, 5, 7, 11, 13, 15, 17, 31, 33, 35, 37, 51, 53,
                    55, 57, 71, 73, 75, 77.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: digits = ["1","4","9"], n = 1000000000
                    <br />
                    Output: 29523
                    <br /> Explanation:
                    <br /> We can write 3 one digit numbers, 9 two digit
                    numbers, 27 three digit numbers,
                    <br /> 81 four digit numbers, 243 five digit numbers, 729
                    six digit numbers,
                    <br /> 2187 seven digit numbers, 6561 eight digit numbers,
                    and 19683 nine digit numbers.
                    <br /> In total, this is 29523 integers that can be written
                    using the digits array.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: digits = ["7"], n = 8 <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= digits.length &lt;= 9 <br />
                digits[i].length == 1 <br />
                digits[i] is a digit from '1' to '9'. <br />
                All the values in digits are unique. <br />
                digits is sorted in non-decreasing order. <br />1 &lt;= n &lt;=
                109
              </>
            }
            tc="log n"
            sc="log n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} digits
                * @param {number} n
                * @return {number}
                */
               var atMostNGivenDigitSet = function(digits, n) {
                 let ans = 0;
                 let num = String(n);
               
                   for (let i = 1; i < num.length; ++i)
                     ans += Math.pow(digits.length, i);
               
                   for (let i = 0; i < num.length; ++i) {
                     let dHasSameNum = false;
                     for (let digit of digits) {
                       if (digit.charAt(0) < num.charAt(i))
                         ans += Math.pow(digits.length, num.length - i - 1);
                       else if (digit[0] == num.charAt(i))
                         dHasSameNum = true;
                     }
                     if (!dHasSameNum)
                       return ans;
                   }
               
                   return ans + 1;   
               };
               
               console.log(atMostNGivenDigitSet(["1","3","5","7"],100))`,
                output: `20`,
              },
            }}
          />
        ),
      },
      q747: {
        title: "Q903. Valid Permutations for DI Sequence (Q747)",
        content: (
          <Comp
            title="Q903. Valid Permutations for DI Sequence (Q747)"
            content1={
              <>
                You are given a string s of length n where s[i] is either:
                <br />
                'D' means decreasing, or
                <br />
                'I' means increasing.
                <br />
                A permutation perm of n + 1 integers of all the integers in the
                range [0, n] is called a valid permutation if for all valid i:
                <br />
                If s[i] == 'D', then perm[i] &gt; perm[i + 1], and
                <br />
                If s[i] == 'I', then perm[i] &lt; perm[i + 1]. Return the number
                of valid permutations perm. Since the answer may be large,
                return it modulo 109 + 7.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "DID" <br />
                    Output: 5 <br />
                    Explanation: The 5 valid permutations of (0, 1, 2, 3) are:{" "}
                    <br />
                    (1, 0, 3, 2) <br />
                    (2, 0, 3, 1) <br />
                    (2, 1, 3, 0) <br />
                    (3, 0, 2, 1) <br />
                    (3, 1, 2, 0)
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "D" <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == s.length <br />
                1 &lt;= n &lt;= 200 <br />
                s[i] is either 'I' or 'D'.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               var numPermsDISequence = function(s) {
                 const kMod = 1e9 + 7;
                 let n = s.length;
                 let dp = Array(n + 1).fill(0);
               
                 for (let j = 0; j <= n; ++j)
                     dp[j] = 1;
               
                 for (let i = 1; i <= n; ++i) {
                   const newDp = Array(n + 1).fill(0);
                     if (s[i - 1] == 'I') { 
                       let postfixsum = 0;
                       for (let j = n - i; j >= 0; --j) {
                         postfixsum = (postfixsum + dp[j + 1]) % kMod;
                         newDp[j] = postfixsum;
                       }
                     } else {
                       let prefix = 0;
                       for (let j = 0; j <= n - i; ++j) {
                         prefix = (prefix + dp[j]) % kMod;
                         newDp[j] = prefix;
                       }
                     }
                     dp = newDp;
                   }
               
                   return dp[0]; 
               };
               console.log(numPermsDISequence("DID"))`,
                output: `5`,
              },
            }}
          />
        ),
      },
      q748: {
        title: "Q904. Fruit Into Baskets (Q748)",
        content: (
          <Comp
            title="Q904. Fruit Into Baskets (Q748)"
            content1={
              <>
                You are visiting a farm that has a single row of fruit trees
                arranged from left to right. The trees are represented by an
                integer array fruits where fruits[i] is the type of fruit the
                ith tree produces.
                <br />
                You want to collect as much fruit as possible. However, the
                owner has some strict rules that you must follow:
                <br />
                You only have two baskets, and each basket can only hold a
                single type of fruit. There is no limit on the amount of fruit
                each basket can hold.
                <br />
                Starting from any tree of your choice, you must pick exactly one
                fruit from every tree (including the start tree) while moving to
                the right. The picked fruits must fit in one of your baskets.
                <br />
                Once you reach a tree with fruit that cannot fit in your
                baskets, you must stop.
                <br />
                Given the integer array fruits, return the maximum number of
                fruits you can pick.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: fruits = [1,2,1] <br />
                    Output: 3 <br />
                    Explanation: We can pick from all 3 trees.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: fruits = [0,1,2,2] <br />
                    Output: 3 <br />
                    Explanation: We can pick from trees [1,2,2]. <br />
                    If we had started at the first tree, we would only pick from
                    trees [0,1].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: fruits = [1,2,3,2,2] <br />
                    Output: 4 <br />
                    Explanation: We can pick from trees [2,3,2,2]. <br />
                    If we had started at the first tree, we would only pick from
                    trees [1,2].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= fruits.length &lt;= 10^5 <br />0 &lt;= fruits[i] &lt;
                fruits.length
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} fruits
                * @return {number}
                */
               var totalFruit = function(fruits) {
                 let ans = 0;
                 const count = {};
                 for (let l = 0, r = 0; r < fruits.length; ++r) {
                     count[fruits[r]] = (count[fruits[r]] || 0) + 1;
                     while (Object.keys(count).length > 2) {
                       count[fruits[l]] = count[fruits[l]] - 1;
                       if(count[fruits[l]]==0)
                       delete count[fruits[l]]
                       ++l;
                     }
                     ans = Math.max(ans, r - l + 1);
                   }
                   return ans;   
               };
               console.log(totalFruit([1,2,1]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q749: {
        title: "Q905. Sort Array By Parity (Q749)",
        content: (
          <Comp
            title="Q905. Sort Array By Parity (Q749)"
            content1={
              <>
                Given an integer array nums, move all the even integers at the
                beginning of the array followed by all the odd integers.
                <br />
                Return any array that satisfies this condition.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [3,1,2,4] <br />
                    Output: [2,4,3,1] <br />
                    Explanation: The outputs [4,2,3,1], [2,4,1,3], and [4,2,1,3]
                    would also be accepted.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [0] <br />
                    Output: [0]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 5000 <br />0 &lt;= nums[i] &lt;= 5000
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number[]}
                */
               var sortArrayByParity = function(nums) {
                 let l = 0;
                 let r = nums.length - 1;
                 while (l < r) {
                   if (nums[l] % 2 == 1 && nums[r] % 2 == 0) {
                     let temp = nums[l];
                     nums[l] = nums[r];
                     nums[r] = temp;
                   }
                   if (nums[l] % 2 == 0)
                     ++l;
                   if (nums[r] % 2 == 1)
                     --r;
                 }
                 return nums;
               };
               
               console.log(sortArrayByParity([3,1,2,4]))`,
                output: `[ 4, 2, 1, 3 `,
              },
            }}
          />
        ),
      },
      q750: {
        title: "Q906. Super Palindromes (Q750)",
        content: (
          <Comp
            title="Q906. Super Palindromes (Q750)"
            content1={
              <>
                Let's say a positive integer is a super-palindrome if it is a
                palindrome, and it is also the square of a palindrome.
                <br />
                Given two positive integers left and right represented as
                strings, return the number of super-palindromes integers in the
                inclusive range [left, right].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: left = "4", right = "1000"
                    <br />
                    Output: 4<br />
                    Explanation: 4, 9, 121, and 484 are superpalindromes.
                    <br />
                    Note that 676 is not a superpalindrome: 26 * 26 = 676, but
                    26 is not a palindrome.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: left = "1", right = "2"
                    <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= left.length, right.length &lt;= 18
                <br />
                left and right consist of only digits.
                <br />
                left and right cannot have leading zeros.
                <br />
                left and right represent integers in the range [1, 1018 - 1].
                <br />
                left is less than or equal to right.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `class Solution {
                  public int superpalindromesInRange(String left, String right) {
                    int ans = 0;
                    Long l = Long.valueOf(left);
                    Long r = Long.valueOf(right);
                    for (long i = (long) Math.sqrt(l); i * i <= r;) {
                      long palindrome = nextPalindrome(i);
                      long squared = palindrome * palindrome;
                      if (squared <= r && isPalindrome(squared))
                        ++ans;
                      i = palindrome + 1;
                    }
                
                    return ans;
                  }
                
                  private long nextPalindrome(long num) {
                    final String s = String.valueOf(num);
                    final int n = s.length();
                    String half = s.substring(0, (n + 1) / 2);
                    String reversedHalf = new StringBuilder(half.substring(0, n / 2)).reverse().toString();
                    final long candidate = Long.valueOf(half + reversedHalf);
                    if (candidate >= num)
                      return candidate;
                    half = String.valueOf(Long.valueOf(half) + 1);
                    reversedHalf = new StringBuilder(half.substring(0, n / 2)).reverse().toString();
                    return Long.valueOf(half + reversedHalf);
                  }
                
                  private boolean isPalindrome(long num) {
                    final String s = String.valueOf(num);
                    int l = 0;
                    int r = s.length() - 1;
                    while (l < r)
                      if (s.charAt(l++) != s.charAt(r--))
                        return false;
                    return true;
                  }
                }`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q751: {
        title: "Q907. Sum of Subarray Minimums (Q751)",
        content: (
          <Comp
            title="Q907. Sum of Subarray Minimums (Q751)"
            content1={
              <>
                3252 209 Add to List Share Given an array of integers arr, find
                the sum of min(b), where b ranges over every (contiguous)
                subarray of arr. Since the answer may be large, return the
                answer modulo 10^9 + 7.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [3,1,2,4]
                    <br />
                    Output: 17
                    <br />
                    Explanation: <br />
                    Subarrays are [3], [1], [2], [4], [3,1], [1,2], [2,4],
                    [3,1,2], [1,2,4], [3,1,2,4].
                    <br /> Minimums are 3, 1, 2, 4, 1, 1, 2, 1, 1, 1.
                    <br />
                    Sum is 17.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [11,81,94,43,3]
                    <br />
                    Output: 444
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= arr.length &lt;= 3 * 10^4
                <br />1 &lt;= arr[i] &lt;= 3 * 104
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} arr
                * @return {number}
                */
               var sumSubarrayMins = function(arr) {
                 const kMod = 1e9 + 7;
                 let n = arr.length;
                 let ans = 0;
                 const prev = Array(n).fill(-1);
                 const next = Array(n).fill(n);
                 const stack = [];
               
                 for (let i = 0; i < arr.length; ++i) {
                     while (stack.length && arr[stack[stack.length-1]] > arr[i]) {
                       const index = stack.pop();
                       next[index] = i;
                     }
                     if (stack.length)
                       prev[i] = stack[stack.length-1]
                     stack.push(i);
                   }
                   for (let i = 0; i < arr.length; ++i) {
                     ans += arr[i] * (i - prev[i]) * (next[i] - i);
                     ans %= kMod;
                   }
               
                   return parseInt(ans);  
               };
               
               console.log(sumSubarrayMins([3,1,2,4]))`,
                output: `17`,
              },
            }}
          />
        ),
      },
      q752: {
        title: "Q908. Smallest Range I (Q752)",
        content: (
          <Comp
            title="Q908. Smallest Range I (Q752)"
            content1={
              <>
                You are given an integer array nums and an integer k.
                <br />
                In one operation, you can choose any index i where 0 &lt; = i
                &lt; nums.length and change nums[i] to nums[i] + x where x is an
                integer from the range [-k, k]. You can apply this operation at
                most once for each index i.
                <br />
                The score of nums is the difference between the maximum and
                minimum elements in nums.
                <br />
                Return the minimum score of nums after applying the mentioned
                operation at most once for each index in it.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1], k = 0<br />
                    Output: 0<br />
                    Explanation: The score is max(nums) - min(nums) = 1 - 1 = 0.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [0,10], k = 2<br />
                    Output: 6<br />
                    Explanation: Change nums to be [2, 8]. The score is
                    max(nums) - min(nums) = 8 - 2 = 6.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,3,6], k = 3<br />
                    Output: 0<br />
                    Explanation: Change nums to be [4, 4, 4]. The score is
                    max(nums) - min(nums) = 4 - 4 = 0.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4
                <br />
                0 &lt;= nums[i] &lt;= 10^4
                <br />0 &lt;= k &lt;= 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var smallestRangeI = function(nums, k) {
                 let max = Math.max(...nums)
                 let min = Math.min(...nums)
                 return Math.max(0, max - min - 2 * k);
               };
               
               console.log(smallestRangeI([1],0))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q753: {
        title: "Q909. Snakes and Ladders (Q753)",
        content: (
          <Comp
            title="Q909. Snakes and Ladders (Q753)"
            content1={
              <>
                You are given an n x n integer matrix board where the cells are
                labeled from 1 to n2 in a Boustrophedon style starting from the
                bottom left of the board (i.e. board[n - 1][0]) and alternating
                direction each row.
                <br />
                You start on square 1 of the board. In each move, starting from
                square curr, do the following:
                <br />
                <br /> Choose a destination square next with a label in the
                range [curr + 1, min(curr + 6, n2)].
                <br /> This choice simulates the result of a standard 6-sided
                die roll: i.e., there are always at most 6 destinations,
                regardless of the size of the board.
                <br /> If next has a snake or ladder, you must move to the
                destination of that snake or ladder. Otherwise, you move to
                next.
                <br /> The game ends when you reach the square n2.
                <br /> A board square on row r and column c has a snake or
                ladder if board[r][c] != -1. The destination of that snake or
                ladder is board[r][c]. Squares 1 and n2 do not have a snake or
                ladder.
                <br />
                Note that you only take a snake or ladder at most once per move.
                If the destination to a snake or ladder is the start of another
                snake or ladder, you do not follow the subsequent snake or
                ladder.
                <br />
                For example, suppose the board is [[-1,4],[-1,3]], and on the
                first move, your destination square is 2. You follow the ladder
                to square 3, but do not follow the subsequent ladder to 4.
                <br /> Return the least number of moves required to reach the
                square n2. If it is not possible to reach the square, return -1.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode909,
                content: (
                  <>
                    Input: board =
                    [[-1,-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1,-1],[-1,35,-1,-1,13,-1],[-1,-1,-1,-1,-1,-1],[-1,15,-1,-1,-1,-1]]
                    <br /> Output: 4
                    <br /> Explanation:
                    <br /> In the beginning, you start at square 1 (at row 5,
                    column 0).
                    <br /> You decide to move to square 2 and must take the
                    ladder to square 15.
                    <br /> You then decide to move to square 17 and must take
                    the snake to square 13.
                    <br /> You then decide to move to square 14 and must take
                    the ladder to square 35.
                    <br /> You then decide to move to square 36, ending the
                    game.
                    <br /> This is the lowest possible number of moves to reach
                    the last square, so return 4.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: board = [[-1,-1],[-1,3]] <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == board.length == board[i].length <br />
                2 &lt;= n &lt;= 20 <br />
                grid[i][j] is either -1 or in the range [1, n2]. <br />
                The squares labeled 1 and n2 do not have any ladders or snakes.
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Java: {
                code: `// Input: board = [[-1,-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1,-1],[-1,35,-1,-1,13,-1],[-1,-1,-1,-1,-1,-1],[-1,15,-1,-1,-1,-1]]
                class Solution {
                  public int snakesAndLadders(int[][] board) {
                    final int n = board.length;
                    int ans = 0;
                    Queue<Integer> q = new ArrayDeque<>(Arrays.asList(1));
                    boolean[] seen = new boolean[1 + n * n];
                    int[] A = new int[1 + n * n];
                    for (int i = 0; i < n; ++i)
                      for (int j = 0; j < n; ++j)
                        A[(n - 1 - i) * n + ((n - i & 1) == 1 ? j + 1 : n - j)] = board[i][j];
                
                    while (!q.isEmpty()) {
                      ++ans;
                      for (int sz = q.size(); sz > 0; --sz) {
                        final int curr = q.poll();
                        for (int next = curr + 1; next <= Math.min(curr + 6, n * n); ++next) {
                          final int dest = A[next] > 0 ? A[next] : next;
                          if (dest == n * n)
                            return ans;
                          if (seen[dest])
                            continue;
                          q.offer(dest);
                          seen[dest] = true;
                        }
                      }
                    }
                
                    return -1;
                  }
                }
                `,
                output: `4`,
              },
            }}
          />
        ),
      },
      q754: {
        title: "Q910. Smallest Range II (Q754)",
        content: (
          <Comp
            title="Q910. Smallest Range II (Q754)"
            content1={
              <>
                You are given an integer array nums and an integer k.
                <br />
                For each index i where 0 &lt;= i &lt; nums.length, change
                nums[i] to be either nums[i] + k or nums[i] - k.
                <br />
                The score of nums is the difference between the maximum and
                minimum elements in nums.
                <br />
                Return the minimum score of nums after changing the values at
                each index.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1], k = 0 <br />
                    Output: 0 <br />
                    Explanation: The score is max(nums) - min(nums) = 1 - 1 = 0.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [0,10], k = 2 <br />
                    Output: 6 <br />
                    Explanation: Change nums to be [2, 8]. The score is
                    max(nums) - min(nums) = 8 - 2 = 6.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,3,6], k = 3 <br />
                    Output: 3 <br />
                    Explanation: Change nums to be [4, 6, 3]. The score is
                    max(nums) - min(nums) = 6 - 3 = 3.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4 <br />
                0 &lt;= nums[i] &lt;= 10^4 <br />0 &lt;= k &lt;= 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var smallestRangeII = function(nums, k) {
                 nums = nums.sort((a,b)=>a-b) 
                 let ans = nums[nums.length - 1] - nums[0];
                 let left = nums[0] + k;
                 let right = nums[nums.length - 1] - k;
                 for (let i = 0; i + 1 < nums.length; ++i) {
                   let min = Math.min(left, nums[i + 1] - k);
                   let max = Math.max(right, nums[i] + k);
                   ans = Math.min(ans, max - min);
                 }
                 return ans; 
               };
               
               console.log(smallestRangeII([1],0))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q755: {
        title: "Q911. Online Election (Q755)",
        content: (
          <Comp
            title="Q911. Online Election (Q755)"
            content1={
              <>
                You are given two integer arrays persons and times. In an
                election, the ith vote was cast for persons[i] at time times[i].
                <br />
                For each query at a time t, find the person that was leading the
                election at time t. Votes cast at time t will count towards our
                query. In the case of a tie, the most recent vote (among tied
                candidates) wins.
                <br />
                Implement the TopVotedCandidate class:
                <br />
                TopVotedCandidate(int[] persons, int[] times) Initializes the
                object with the persons and times arrays.
                <br />
                int q(int t) Returns the number of the person that was leading
                the election at time t according to the mentioned rules.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["TopVotedCandidate", "q", "q", "q", "q", "q", "q"] <br />
                    [[[0, 1, 1, 0, 0, 1, 0], [0, 5, 10, 15, 20, 25, 30]], [3],
                    [12], [25], [15], [24], [8]] Output <br />
                    [null, 0, 1, 1, 0, 0, 1]
                    <br />
                    Explanation
                    <br /> TopVotedCandidate topVotedCandidate = new
                    TopVotedCandidate([0, 1, 1, 0, 0, 1, 0], [0, 5, 10, 15, 20,
                    25, 30]);
                    <br /> topVotedCandidate.q(3); // return 0, At time 3, the
                    votes are [0], and 0 is leading.
                    <br /> topVotedCandidate.q(12); // return 1, At time 12, the
                    votes are [0,1,1], and 1 is leading.
                    <br /> topVotedCandidate.q(25); // return 1, At time 25, the
                    votes are [0,1,1,0,0,1], and 1 is leading (as ties go to the
                    most recent vote.)
                    <br /> topVotedCandidate.q(15); // return 0
                    <br /> topVotedCandidate.q(24); // return 0
                    <br /> topVotedCandidate.q(8); // return 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= persons.length &lt;= 5000 <br />
                times.length == persons.length <br />
                0 &lt;= persons[i] &lt; persons.length <br />
                0 &lt;= times[i] &lt;= 10^9 <br />
                times is sorted in a strictly increasing order. <br />
                times[0] &lt;= t &lt;= 10^9 <br />
                At most 104 calls will be made to q.
              </>
            }
            tc="log n"
            sc="n"
            codes={{
              Java: {
                code: `
  // Input
  // ["TopVotedCandidate", "q", "q", "q", "q", "q", "q"]
  // [[[0, 1, 1, 0, 0, 1, 0], [0, 5, 10, 15, 20, 25, 30]], [3], [12], [25], [15], [24], [8]]
                class TopVotedCandidate {
                  public TopVotedCandidate(int[] persons, int[] times) {
                    this.times = times;
                    int lead = -1;
                    Map<Integer, Integer> count = new HashMap<>(); 
                    for (int i = 0; i < persons.length; ++i) {
                      count.merge(persons[i], 1, Integer::sum);
                      if (count.get(persons[i]) >= count.getOrDefault(lead, 0))
                        lead = persons[i];
                      timeToLead.put(times[i], lead);
                    }
                  }
                  public int q(int t) {
                    final int i = Arrays.binarySearch(times, t);
                    return i < 0 ? timeToLead.get(times[-i - 2]) : timeToLead.get(times[i]);
                  }
                  private final int[] times;
                  private Map<Integer, Integer> timeToLead = new HashMap<>();
                }
                /**
                 * Your TopVotedCandidate object will be instantiated and called as such:
                 * TopVotedCandidate obj = new TopVotedCandidate(persons, times);
                 * int param_1 = obj.q(t);
                 */`,
                output: `[null, 0, 1, 1, 0, 0, 1]`,
              },
            }}
          />
        ),
      },
      q756: {
        title: "Q912. Sort an Array (Q756)",
        content: (
          <Comp
            title="Q912. Sort an Array (Q756)"
            content1={
              <>
                Given an array of integers nums, sort the array in ascending
                order.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [5,2,3,1]
                    <br />
                    Output: [1,2,3,5]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [5,1,1,2,0,0] <br />
                    Output: [0,0,1,1,2,5]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 5 * 10^4 -5 * 10^4 &lt;= nums[i] &lt;=
                5 * 10^4
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: nums = [5,2,3,1]
                class Solution {
                  public int[] sortArray(int[] nums) {
                    quickSort(nums, 0, nums.length - 1);
                    return nums;
                  }
                
                  private void quickSort(int[] A, int l, int r) {
                    if (l >= r)
                      return;
                
                    final int m = partition(A, l, r);
                    quickSort(A, l, m - 1);
                    quickSort(A, m + 1, r);
                  }
                
                  private int partition(int[] A, int l, int r) {
                    final int randIndex = new Random().nextInt(r - l + 1) + l;
                    swap(A, randIndex, r);
                    final int pivot = A[r];
                    int nextSwapped = l;
                    for (int i = l; i < r; ++i)
                      if (A[i] <= pivot)
                        swap(A, nextSwapped++, i);
                    swap(A, nextSwapped, r);
                    return nextSwapped;
                  }
                
                  private void swap(int[] A, int i, int j) {
                    final int temp = A[i];
                    A[i] = A[j];
                    A[j] = temp;
                  }
                }
                `,
                output: `[1,2,3,5]`,
              },
            }}
          />
        ),
      },
      q757: {
        title: "Q913. Cat and Mouse (Q757)",
        content: (
          <Comp
            title="Q913. Cat and Mouse (Q757)"
            content1={
              <>
                A game on an undirected graph is played by two players, Mouse
                and Cat, who alternate turns.
                <br />
                The graph is given as follows: graph[a] is a list of all nodes b
                such that ab is an edge of the graph.
                <br />
                The mouse starts at node 1 and goes first, the cat starts at
                node 2 and goes second, and there is a hole at node 0.
                <br />
                During each player's turn, they must travel along one edge of
                the graph that meets where they are. For example, if the Mouse
                is at node 1, it must travel to any node in graph[1].
                <br />
                Additionally, it is not allowed for the Cat to travel to the
                Hole (node 0.)
                <br />
                Then, the game can end in three ways:
                <br />
                If ever the Cat occupies the same node as the Mouse, the Cat
                wins.
                <br />
                If ever the Mouse reaches the Hole, the Mouse wins.
                <br />
                If ever a position is repeated (i.e., the players are in the
                same position as a previous turn, and it is the same player's
                turn to move), the game is a draw.
                <br />
                Given a graph, and assuming both players play optimally, return
                <br />
                1 if the mouse wins the game,
                <br />
                2 if the cat wins the game, or
                <br />0 if the game is a draw.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode913,
                content: (
                  <>
                    Input: graph = [[2,5],[3],[0,4,5],[1,4,5],[2,3],[0,2,3]]
                    <br /> Output: 0
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: graph = [[1,3],[0],[3],[0,2]] <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                3 &lt;= graph.length &lt;= 50
                <br />
                1 &lt;= graph[i].length &lt; graph.length
                <br />
                0 &lt;= graph[i][j] &lt; graph.length <br />
                graph[i][j] != i <br />
                graph[i] is unique. <br />
                The mouse and the cat can always move.{" "}
              </>
            }
            tc="n^3"
            sc="n^3"
            codes={{
              Java: {
                code: `// Input: graph = [[1,3],[0],[3],[0,2]]
                enum State { DRAW, MOUSE_WIN, CAT_WIN }
                
                class Solution {
                  public int catMouseGame(int[][] graph) {
                    final int n = graph.length;
                    int[][][] states = new int[n][n][2];
                    int[][][] outDegree = new int[n][n][2];
                    Queue<int[]> q = new ArrayDeque<>();
                
                    for (int cat = 0; cat < n; ++cat)
                      for (int mouse = 0; mouse < n; ++mouse) {
                        outDegree[cat][mouse][0] = graph[mouse].length;
                        outDegree[cat][mouse][1] =
                            graph[cat].length - (Arrays.stream(graph[cat]).anyMatch(v -> v == 0) ? 1 : 0);
                      }
                    for (int cat = 1; cat < n; ++cat)
                      for (int move = 0; move < 2; ++move) {
                        states[cat][0][move] = State.MOUSE_WIN.ordinal();
                        q.offer(new int[] {cat, 0, move, State.MOUSE_WIN.ordinal()});
                        states[cat][cat][move] = State.CAT_WIN.ordinal();
                        q.offer(new int[] {cat, cat, move, State.CAT_WIN.ordinal()});
                      }
                    while (!q.isEmpty()) {
                      final int cat = q.peek()[0];
                      final int mouse = q.peek()[1];
                      final int move = q.peek()[2];
                      final int state = q.poll()[3];
                      if (cat == 2 && mouse == 1 && move == 0)
                        return state;
                      final int prevMove = move ^ 1;
                      for (final int prev : graph[prevMove == 0 ? mouse : cat]) {
                        final int prevCat = prevMove == 0 ? cat : prev;
                        if (prevCat == 0) 
                          continue;
                        final int prevMouse = prevMove == 0 ? prev : mouse;
                        if (states[prevCat][prevMouse][prevMove] > 0)
                          continue;
                        if (prevMove == 0 && state == State.MOUSE_WIN.ordinal() ||
                            prevMove == 1 && state == State.CAT_WIN.ordinal() ||
                            --outDegree[prevCat][prevMouse][prevMove] == 0) {
                          states[prevCat][prevMouse][prevMove] = state;
                          q.offer(new int[] {prevCat, prevMouse, prevMove, state});
                        }
                      }
                    }
                    return states[2][1][0];
                  }
                }`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q758: {
        title: "Q914. X of a Kind in a Deck of Cards (Q758)",
        content: (
          <Comp
            title="Q914. X of a Kind in a Deck of Cards (Q758)"
            content1={
              <>
                In a deck of cards, each card has an integer written on it.
                <br />
                Return true if and only if you can choose X &gt;= 2 such that it
                is possible to split the entire deck into 1 or more groups of
                cards, where:
                <br />
                Each group has exactly X cards.
                <br />
                All the cards in each group have the same integer.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: deck = [1,1,1,2,2,2,3,3]
                    <br />
                    Output: false
                    <br />
                    Explanation: No possible partition.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: deck = [1,1,1,2,2,2,3,3]
                    <br />
                    Output: false
                    <br />
                    Explanation: No possible partition.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &gt;= deck.length &gt;= 10^4 <br />0 &gt;= deck[i] &gt; 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} deck
                * @return {boolean}
                */
               var hasGroupsSizeX = function(deck) {
                 const count = {};
                 let gcd = 0;
                 for (let d of deck)
                   count[d] = (count[d] || 0) + 1;
                 for (let value of Object.values(count))
                   gcd = __gcd(gcd, value);
                 return gcd >= 2; 
               };
               
               function __gcd(a, b) {
                 return b > 0 ? __gcd(b, a % b) : a;
               }
               
               console.log(hasGroupsSizeX([1,2,3,4,4,3,2,1]))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q759: {
        title: "Q915. Partition Array into Disjoint Intervals (Q759)",
        content: (
          <Comp
            title="Q915. Partition Array into Disjoint Intervals (Q759)"
            content1={
              <>
                Given an integer array nums, partition it into two (contiguous)
                subarrays left and right so that:
                <br />
                Every element in left is less than or equal to every element in
                right.
                <br />
                left and right are non-empty.
                <br />
                left has the smallest possible size.
                <br />
                Return the length of left after such a partitioning.
                <br />
                Test cases are generated such that partitioning exists.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [5,0,3,8,6]
                    <br />
                    Output: 3<br />
                    Explanation: left = [5,0,3], right = [8,6]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,1,1,0,6,12]
                    <br />
                    Output: 4<br />
                    Explanation: left = [1,1,1,0], right = [6,12]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= nums.length &lt;= 10^5
                <br />
                0 &lt;= nums[i] &lt;= 10^6
                <br />
                There is at least one valid answer for the given input.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var partitionDisjoint = function(nums) {
                 const n = nums.length;
                 const min = Array(n).fill(0)
                 min[n - 1] = nums[n - 1];
                 let max = Number.MIN_SAFE_INTEGER;
               
                 for (let i = n - 2; i >= 0; --i)
                   min[i] = Math.min(min[i + 1], nums[i]);
               
                 for (let i = 0; i < n; ++i) {
                   max = Math.max(max, nums[i]);
                   if (max <= min[i + 1])
                     return i + 1;
                 }
               };
               
               console.log(partitionDisjoint([5,0,3,8,6]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q760: {
        title: "Q916. Word Subsets (Q760)",
        content: (
          <Comp
            title="Q916. Word Subsets (Q760)"
            content1={
              <>
                You are given two string arrays words1 and words2.
                <br />
                A string b is a subset of string a if every letter in b occurs
                in a including multiplicity.
                <br />
                For example, "wrr" is a subset of "warrior" but is not a subset
                of "world".
                <br />
                A string a from words1 is universal if for every string b in
                words2, b is a subset of a.
                <br />
                Return an array of all the universal strings in words1. You may
                return the answer in any order.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: words1 =
                    ["amazon","apple","facebook","google","leetcode"], words2 =
                    ["e","o"]
                    <br />
                    Output: ["facebook","google","leetcode"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words1 =
                    ["amazon","apple","facebook","google","leetcode"], words2 =
                    ["l","e"]
                    <br /> Output: ["apple","google","leetcode"]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= words1.length, words2.length &lt;= 104
                <br /> 1 &lt;= words1[i].length, words2[i].length &lt;= 10
                <br /> words1[i] and words2[i] consist only of lowercase English
                letters.
                <br /> All the strings of words1 are unique.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} words1
                * @param {string[]} words2
                * @return {string[]}
                */
               var wordSubsets = function(words1, words2) {
                 const res = [];
                 const countB = Array(26).fill(0);
                 for (let b of words2) {
                   const temp = counter(b);
                   for (let i = 0; i < 26; ++i)
                     countB[i] = Math.max(countB[i], temp[i]);
                 }
                 for (let a of words1)
                   if (isUniversal(counter(a), countB))
                      res.push(a);
                  return res;   
               };
               
               function counter(s) {
                 const count = Array(26).fill(0);
                 for (let c of s)
                   ++count[c.charCodeAt(0) - 97];
                 return count;
               }
               
               function isUniversal(countA, countB) {
                 for (let i = 0; i < 26; ++i)
                   if (countA[i] < countB[i])
                     return false;
                 return true;
               }
               
               console.log(wordSubsets(["amazon","apple","facebook","google","leetcode"],["l","e"]))`,
                output: `[ 'apple', 'google', 'leetcode' ]`,
              },
            }}
          />
        ),
      },
      q761: {
        title: "Q917. Reverse Only Letters (Q761)",
        content: (
          <Comp
            title="Q917. Reverse Only Letters (Q761)"
            content1={
              <>
                Given a string s, reverse the string according to the following
                rules:
                <br />
                All the characters that are not English letters remain in the
                same position.
                <br />
                All the English letters (lowercase or uppercase) should be
                reversed.
                <br />
                Return s after reversing it.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "ab-cd" <br />
                    Output: "dc-ba"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "a-bC-dEf-ghIj" <br />
                    Output: "j-Ih-gfE-dCba"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "Test1ng-Leet=code-Q!" <br />
                    Output: "Qedo1ct-eeLg=ntse-T!"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 100 <br />
                s consists of characters with ASCII values in the range [33,
                122].
                <br /> s does not contain '\"' or '\\'.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: s = "ab-cd"
                class Solution {
                  public String reverseOnlyLetters(String S) {
                    StringBuilder sb = new StringBuilder(S);
                
                    for (int i = 0, j = S.length() - 1; i < j; ++i, --j) {
                      while (i < j && !Character.isLetter(S.charAt(i)))
                        ++i;
                      while (i < j && !Character.isLetter(S.charAt(j)))
                        --j;
                      sb.setCharAt(i, S.charAt(j));
                      sb.setCharAt(j, S.charAt(i));
                    }
                
                    return sb.toString();
                  }
                }
                `,
                output: `dc-ba`,
              },
            }}
          />
        ),
      },
      q762: {
        title: "Q918. Maximum Sum Circular Subarray (Q762)",
        content: (
          <Comp
            title="Q918. Maximum Sum Circular Subarray (Q762)"
            content1={
              <>
                Given a circular integer array nums of length n, return the
                maximum possible sum of a non-empty subarray of nums.
                <br />
                A circular array means the end of the array connects to the
                beginning of the array. Formally, the next element of nums[i] is
                nums[(i + 1) % n] and the previous element of nums[i] is nums[(i
                - 1 + n) % n].
                <br />A subarray may only include each element of the fixed
                buffer nums at most once. Formally, for a subarray nums[i],
                nums[i + 1], ..., nums[j], there does not exist i &lt;= k1, k2
                &lt;= j with k1 % n == k2 % n.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,-2,3,-2] <br />
                    Output: 3 <br />
                    Explanation: Subarray [3] has maximum sum 3.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [5,-3,5] <br />
                    Output: 10 <br />
                    Explanation: Subarray [5,5] has maximum sum 5 + 5 = 10.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [-3,-2,-3] <br />
                    Output: -2 <br />
                    Explanation: Subarray [-2] has maximum sum -2.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == nums.length <br />
                1 &lt;= n &lt;= 3 * 10^4 <br />
                -3 * 10^4 &lt;= nums[i] &lt;= 3 * 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var maxSubarraySumCircular = function(nums) {
                 let totalSum = 0;
                 let currMaxSum = 0;
                 let currMinSum = 0;
                 let maxSum = Number.MIN_SAFE_INTEGER;
                 let minSum = Number.MAX_SAFE_INTEGER;
                 for (let a of nums) {
                   totalSum += a;
                   currMaxSum = Math.max(currMaxSum + a, a);
                   currMinSum = Math.min(currMinSum + a, a);
                   maxSum = Math.max(maxSum, currMaxSum);
                   minSum = Math.min(minSum, currMinSum);
                 }
                 return maxSum < 0 ? maxSum : Math.max(maxSum, totalSum - minSum);  
               };
               
                console.log(maxSubarraySumCircular([1,-2,3,-2]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q763: {
        title: "Q919. Complete Binary Tree Inserter (Q763)",
        content: (
          <Comp
            title="Q919. Complete Binary Tree Inserter (Q763)"
            content1={
              <>
                A complete binary tree is a binary tree in which every level,
                except possibly the last, is completely filled, and all nodes
                are as far left as possible.
                <br />
                Design an algorithm to insert a new node to a complete binary
                tree keeping it complete after the insertion.
                <br />
                Implement the CBTInserter class:
                <br />
                CBTInserter(TreeNode root) Initializes the data structure with
                the root of the complete binary tree.
                <br />
                int insert(int v) Inserts a TreeNode into the tree with value
                Node.val == val so that the tree remains complete, and returns
                the value of the parent of the inserted TreeNode.
                <br />
                TreeNode get_root() Returns the root node of the tree.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode919,
                content: (
                  <>
                    Input <br />
                    ["CBTInserter", "insert", "insert", "get_root"] <br />
                    [[[1, 2]], [3], [4], []] <br />
                    Output <br />
                    [null, 1, 2, [1, 2, 3, 4]] <br />
                    Explanation <br />
                    CBTInserter cBTInserter = new CBTInserter([1, 2]); <br />
                    cBTInserter.insert(3); // return 1 <br />
                    cBTInserter.insert(4); // return 2 <br />
                    cBTInserter.get_root(); // return [1, 2, 3, 4]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree will be in the range [1, 1000].
                <br />
                0 &lt;= Node.val &lt;= 5000
                <br />
                root is a complete binary tree.
                <br />0 &lt;= val &lt;= 5000 At most 104 calls will be made to
                insert and get_root.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input
                // ["CBTInserter", "insert", "insert", "get_root"]
                // [[[1, 2]], [3], [4], []]
                /**
                 * Definition for a binary tree node.
                 * public class TreeNode {
                 *     int val;
                 *     TreeNode left;
                 *     TreeNode right;
                 *     TreeNode() {}
                 *     TreeNode(int val) { this.val = val; }
                 *     TreeNode(int val, TreeNode left, TreeNode right) {
                 *         this.val = val;
                 *         this.left = left;
                 *         this.right = right;
                 *     }
                 * }
                 */
                /**
                 * Definition for a binary tree node.
                 * public class TreeNode {
                 *     int val;
                 *     TreeNode left;
                 *     TreeNode right;
                 *     TreeNode() {}
                 *     TreeNode(int val) { this.val = val; }
                 *     TreeNode(int val, TreeNode left, TreeNode right) {
                 *         this.val = val;
                 *         this.left = left;
                 *         this.right = right;
                 *     }
                 * }
                 */
                class CBTInserter {
                  public CBTInserter(TreeNode root) {
                    tree.add(root);
                    for (int i = 0; i < tree.size(); ++i) {
                      TreeNode node = tree.get(i);
                      if (node.left != null)
                        tree.add(node.left);
                      if (node.right != null)
                        tree.add(node.right);
                    }
                  }
                
                  public int insert(int v) {
                    final int n = tree.size();
                    TreeNode node = new TreeNode(v);
                    TreeNode parent = tree.get((n - 1) / 2);
                    tree.add(node);
                    if (n % 2 == 1)
                      parent.left = node;
                    else
                      parent.right = node;
                    return parent.val;
                  }
                
                  public TreeNode get_root() {
                    return tree.get(0);
                  }
                
                  private List<TreeNode> tree = new ArrayList<>();
                }
                /**
                 * Your CBTInserter object will be instantiated and called as such:
                 * CBTInserter obj = new CBTInserter(root);
                 * int param_1 = obj.insert(val);
                 * TreeNode param_2 = obj.get_root();
                 */`,
                output: `[null, 1, 2, [1, 2, 3, 4]]`,
              },
            }}
          />
        ),
      },
      q764: {
        title: "Q920. Number of Music Playlists (Q764)",
        content: (
          <Comp
            title="Q920. Number of Music Playlists (Q764)"
            content1={
              <>
                Your music player contains n different songs. You want to listen
                to goal songs (not necessarily different) during your trip. To
                avoid boredom, you will create a playlist so that:
                <br />
                Every song is played at least once.
                <br />
                A song can only be played again only if k other songs have been
                played.
                <br />
                Given n, goal, and k, return the number of possible playlists
                that you can create. Since the answer can be very large, return
                it modulo 109 + 7.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 3, goal = 3, k = 1<br />
                    Output: 6<br />
                    Explanation: There are 6 possible playlists: [1, 2, 3], [1,
                    3, 2], [2, 1, 3], [2, 3, 1], [3, 1, 2], and [3, 2, 1].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2, goal = 3, k = 0<br />
                    Output: 6<br />
                    Explanation: There are 6 possible playlists: [1, 1, 2], [1,
                    2, 1], [2, 1, 1], [2, 2, 1], [2, 1, 2], and [1, 2, 2].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2, goal = 3, k = 1<br />
                    Output: 2<br />
                    Explanation: There are 2 possible playlists: [1, 2, 1] and
                    [2, 1, 2].
                  </>
                ),
              },
            ]}
            constraints={<>0 &lt;= k &lt; n &lt;= goal &lt;= 100</>}
            tc="n.l"
            sc="n.l"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @param {number} goal
                * @param {number} k
                * @return {number}
                */
               var numMusicPlaylists = function(n, goal, k) {
                   const kMod = 1e9 + 7;
                   const dp = Array.from(Array(goal+1),()=>Array(n+1).fill(0))
                   dp[0][0] = 1;
                   for (let i = 1; i <= goal; ++i)
                     for (let j = 1; j <= n; ++j) {
                       dp[i][j] += dp[i - 1][j - 1] * (n - (j - 1)); 
                       dp[i][j] += dp[i - 1][j] * Math.max(0, j - k); 
                       dp[i][j] %= kMod;
                     }
                   return parseInt(dp[goal][n])  
               };
               
               console.log(numMusicPlaylists(3,3,1))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q765: {
        title: "Q921. Minimum Add to Make Parentheses Valid (Q765)",
        content: (
          <Comp
            title="Q921. Minimum Add to Make Parentheses Valid (Q765)"
            content1={
              <>
                A parentheses string is valid if and only if:
                <br />
                It is the empty string,
                <br />
                It can be written as AB (A concatenated with B), where A and B
                are valid strings, or
                <br />
                It can be written as (A), where A is a valid string.
                <br />
                You are given a parentheses string s. In one move, you can
                insert a parenthesis at any position of the string.
                <br />
                For example, if s = "()))", you can insert an opening
                parenthesis to be "(()))" or a closing parenthesis to be
                "())))".
                <br />
                Return the minimum number of moves required to make s valid.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "())" <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "(((" <br />
                    Output: 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 1000 <br />
                s[i] is either '(' or ')'.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               var minAddToMakeValid = function(s) {
                 let l = 0;
                 let r = 0;
                 for (const c of s)
                   if (c == '(') {
                     ++l;
                   } else {
                     if (l == 0)
                       ++r;
                     else
                       --l;
                   }
                 return l + r;   
               };
               
               console.log(minAddToMakeValid("())"))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q766: {
        title: "Q922. Sort Array By Parity II (Q766)",
        content: (
          <Comp
            title="Q922. Sort Array By Parity II (Q766)"
            content1={
              <>
                Given an array of integers nums, half of the integers in nums
                are odd, and the other half are even.
                <br />
                Sort the array so that whenever nums[i] is odd, i is odd, and
                whenever nums[i] is even, i is even.
                <br />
                Return any answer array that satisfies this condition.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [4,2,5,7]
                    <br />
                    Output: [4,5,2,7]
                    <br />
                    Explanation: [4,7,2,5], [2,5,4,7], [2,7,4,5] would also have
                    been accepted.
                  </>
                ),
              },
              {
                content: <>Input: nums = [2,3] Output: [2,3]</>,
              },
            ]}
            constraints={
              <>
                2 &lt;= nums.length &lt;= 2 * 10^4
                <br />
                nums.length is even.
                <br />
                Half of the integers in nums are even.
                <br />0 &lt;= nums[i] &lt;= 1000
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number[]}
                */
               var sortArrayByParityII = function(nums) {
                 const n = nums.length;
                 for (let i = 0, j = 1; i < n; i += 2, j += 2) {
                   while (i < n && nums[i] % 2 == 0)
                     i += 2;
                   while (j < n && nums[j] % 2 == 1)
                     j += 2;
                   if (i < n) {
                     let temp = nums[i];
                     nums[i] = nums[j];
                     nums[j] = temp;
                   }
                 }
                 return nums;    
               };
               console.log(sortArrayByParityII([4,2,5,7]))`,
                output: `[ 4, 5, 2, 7 ]`,
              },
            }}
          />
        ),
      },
      q767: {
        title: "Q923. 3Sum With Multiplicity (Q767)",
        content: (
          <Comp
            title="Q923. 3Sum With Multiplicity (Q767)"
            content1={
              <>
                Given an integer array arr, and an integer target, return the
                number of tuples i, j, k such that i &lt; j &lt; k and arr[i] +
                arr[j] + arr[k] == target.
                <br />
                As the answer can be very large, return it modulo 10^9 + 7.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [1,1,2,2,3,3,4,4,5,5], target = 8 <br />
                    Output: 20 <br />
                    Explanation: <br />
                    Enumerating by the values (arr[i], arr[j], arr[k]): <br />
                    (1, 2, 5) occurs 8 times; <br />
                    (1, 3, 4) occurs 8 times; <br />
                    (2, 2, 4) occurs 2 times; <br />
                    (2, 3, 3) occurs 2 times.
                  </>
                ),
              },
              {
                content: (
                  <>
                    put: arr = [1,1,2,2,2,2], target = 5 <br />
                    Output: 12 <br />
                    Explanation: <br />
                    arr[i] = 1, arr[j] = arr[k] = 2 occurs 12 times: <br />
                    We choose one 1 from [1,1] in 2 ways, <br />
                    and two 2s from [2,2,2,2] in 6 ways.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                3 &lt;= arr.length &lt;= 3000 <br />
                0 &lt;= arr[i] &lt;= 100 <br />0 &lt;= target &lt;= 300
              </>
            }
            tc="n"
            sc="n"
            codes={{
              "C++": {
                code: `
                // Input: arr = [1,1,2,2,3,3,4,4,5,5], target = 8
                class Solution {
                  public:
                   int threeSumMulti(vector<int>& A, int target) {
                     constexpr int kMod = 1e9 + 7;
                     int ans = 0;
                     unordered_map<int, long> count;
                     for (const int a : A)
                       ++count[a];
                     for (const auto& [i, x] : count)
                       for (const auto& [j, y] : count) {
                         int k = target - i - j;
                         if (!count.count(k))
                           continue;
                         if (i == j && j == k)
                           ans = (ans + x * (x - 1) * (x - 2) / 6) % kMod;
                         else if (i == j && j != k)
                           ans = (ans + x * (x - 1) / 2 * count[k]) % kMod;
                         else if (i < j && j < k)
                           ans = (ans + x * y * count[k]) % kMod;
                       }
                     return ans;
                   }
                 };
                 `,
                output: `20`,
              },
            }}
          />
        ),
      },
      q768: {
        title: "Q924. Minimize Malware Spread (Q768)",
        content: (
          <Comp
            title="Q924. Minimize Malware Spread (Q768)"
            content1={
              <>
                You are given a network of n nodes represented as an n x n
                adjacency matrix graph, where the ith node is directly connected
                to the jth node if graph[i][j] == 1.
                <br />
                Some nodes initial are initially infected by malware. Whenever
                two nodes are directly connected, and at least one of those two
                nodes is infected by malware, both nodes will be infected by
                malware. This spread of malware will continue until no more
                nodes can be infected in this manner.
                <br />
                Suppose M(initial) is the final number of nodes infected with
                malware in the entire network after the spread of malware stops.
                We will remove exactly one node from initial.
                <br />
                Return the node that, if removed, would minimize M(initial). If
                multiple nodes could be removed to minimize M(initial), return
                such a node with the smallest index.
                <br />
                Note that if a node was removed from the initial list of
                infected nodes, it might still be infected later due to the
                malware spread.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: graph = [[1,1,0],[1,1,0],[0,0,1]], <br /> initial =
                    [0,1]
                    <br /> Output: 0
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: graph = [[1,0,0],[0,1,0],[0,0,1]], <br />
                    initial = [0,2]
                    <br /> Output: 0
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: graph = [[1,1,1],[1,1,1],[1,1,1]], <br />
                    initial = [1,2]
                    <br /> Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == graph.length <br />
                n == graph[i].length <br />
                2 &lt;= n &lt;= 300 <br />
                graph[i][j] is 0 or 1. <br />
                graph[i][j] == graph[j][i] <br />
                graph[i][i] == 1 <br />
                1 &lt;= initial.length &lt;= n <br />
                0 &lt;= initial[i] &lt;= n - 1 <br />
                All the integers in initial are unique
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `// Input: graph = [[1,1,0],[1,1,0],[0,0,1]], initial = [0,1]
                class UF {
                  public UF(int n) {
                    id = new int[n];
                    for (int i = 0; i < n; ++i)
                      id[i] = i;
                  }
                  public void union(int u, int v) {
                    id[find(u)] = find(v);
                  }
                  public int find(int u) {
                    return id[u] == u ? u : (id[u] = find(id[u]));
                  }
                  private int[] id;
                }
                
                class Solution {
                  public int minMalwareSpread(int[][] graph, int[] initial) {
                    final int n = graph.length;
                    UF uf = new UF(n);
                    int[] ufSize = new int[n];
                    int[] malwareCount = new int[n];
                    for (int i = 0; i < n; ++i)
                      for (int j = i + 1; j < n; ++j)
                        if (graph[i][j] == 1)
                          uf.union(i, j);
                    for (int i = 0; i < n; ++i)
                      ++ufSize[uf.find(i)];
                    for (final int i : initial)
                      ++malwareCount[uf.find(i)];
                    Arrays.sort(initial);
                    int ans = initial[0];
                    int maxUfSize = 0;
                    for (final int i : initial) {
                      final int id = uf.find(i);
                      if (ufSize[id] > maxUfSize && malwareCount[id] == 1) {
                        maxUfSize = ufSize[id];
                        ans = i;
                      }
                    }
                
                    return ans;
                  }
                }`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q769: {
        title: "Q925. Long Pressed Name (Q769)",
        content: (
          <Comp
            title="Q925. Long Pressed Name (Q769)"
            content1={
              <>
                Your friend is typing his name into a keyboard. Sometimes, when
                typing a character c, the key might get long pressed, and the
                character will be typed 1 or more times.
                <br />
                You examine the typed characters of the keyboard. Return True if
                it is possible that it was your friends name, with some
                characters (possibly none) being long pressed.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    nput: name = "alex", typed = "aaleex"
                    <br />
                    Output: true
                    <br />
                    Explanation: 'a' and 'e' in 'alex' were long pressed.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: name = "saeed", typed = "ssaaedd"
                    <br />
                    Output: false
                    <br />
                    Explanation: 'e' must have been pressed twice, but it was
                    not in the typed output.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= name.length, typed.length &lt;= 1000
                <br />
                name and typed consist of only lowercase English letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} name
                * @param {string} typed
                * @return {boolean}
                */
               var isLongPressedName = function(name, typed) {
                 let i = 0;
                 for (let j = 0; j < typed.length; ++j)
                   if (i < name.length && name[i] == typed[j])
                       ++i;
                   else if (j == 0 || typed[j] != typed[j - 1])
                     return false;
                 return i == name.length;    
               };
               
               console.log(isLongPressedName("alex","aaleex"))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q770: {
        title: "Q926. Flip String to Monotone Increasing (Q770)",
        content: (
          <Comp
            title="Q926. Flip String to Monotone Increasing (Q770)"
            content1={
              <>
                A binary string is monotone increasing if it consists of some
                number of 0's (possibly none), followed by some number of 1's
                (also possibly none).
                <br />
                You are given a binary string s. You can flip s[i] changing it
                from 0 to 1 or from 1 to 0.
                <br />
                Return the minimum number of flips to make s monotone
                increasing.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "00110"
                    <br />
                    Output: 1<br />
                    Explanation: We flip the last digit to get 00111.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "010110"
                    <br />
                    Output: 2<br />
                    Explanation: We flip to get 011111, or alternatively 000111.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "00011000"
                    <br />
                    Output: 2<br />
                    Explanation: We flip to get 00000000.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^5
                <br />
                s[i] is either '0' or '1'.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               var minFlipsMonoIncr = function(s) {
                 const dp = Array(2).fill(0);
                 for (let i = 0; i < s.length; ++i) {
                   let temp = dp[0] + (s[i] == '1' ? 1 : 0);
                   dp[1] = Math.min(dp[0], dp[1]) + (s[i] == '0' ? 1 : 0);
                   dp[0] = temp;
                 }
                 return Math.min(dp[0], dp[1])    
               };
               
               console.log(minFlipsMonoIncr("00110"))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q771: {
        title: "Q927. Three Equal Parts (Q771)",
        content: (
          <Comp
            title="Q927. Three Equal Parts (Q771)"
            content1={
              <>
                You are given an array arr which consists of only zeros and
                ones, divide the array into three non-empty parts such that all
                of these parts represent the same binary value.
                <br />
                If it is possible, return any [i, j] with i + 1 &lt; j, such
                that:
                <br />
                arr[0], arr[1], ..., arr[i] is the first part,
                <br />
                arr[i + 1], arr[i + 2], ..., arr[j - 1] is the second part, and
                <br />
                arr[j], arr[j + 1], ..., arr[arr.length - 1] is the third part.
                <br />
                All three parts have equal binary values.
                <br />
                If it is not possible, return [-1, -1].
                <br />
                Note that the entire part is used when considering what binary
                value it represents. For example, [1,1,0] represents 6 in
                decimal, not 3. Also, leading zeros are allowed, so [0,1,1] and
                [1,1] represent the same value.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [1,0,1,0,1] <br />
                    Output: [0,3]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [1,1,0,1,1] <br />
                    Output: [-1,-1]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [1,1,0,0,1] <br />
                    Output: [0,2]
                  </>
                ),
              },
            ]}
            constraints={<></>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} arr
                * @return {number[]}
                */
               var threeEqualParts = function(arr) {
                 let ones = 0;
                 for (let a of arr)
                     if (a == 1)
                       ++ones;
                   if (ones == 0)
                     return [0, arr.length - 1];
                   if (ones % 3 != 0)
                     return [-1, -1];
                   let k = parseInt(ones / 3);
                   let i = 0;
                   let j = 0;
                   let first = 0;
                   let second = 0;
                   let third = 0;
                   for (i = 0; i < arr.length; ++i)
                     if (arr[i] == 1) {
                       first = i;
                       break;
                     }
                   let gapOnes = k;
                   for (j = i + 1; j < arr.length; ++j)
                     if (arr[j] == 1 && --gapOnes == 0) {
                       second = j;
                       break;
                     }
                   gapOnes = k;
                   for (i = j + 1; i < arr.length; ++i)
                     if (arr[i] == 1 && --gapOnes == 0) {
                       third = i;
                       break;
                     }
                   while (third < arr.length && arr[first] == arr[second] && arr[second] == arr[third]) {
                     ++first;
                     ++second;
                     ++third;
                   }
                   if (third == arr.length)
                     return [first - 1, second];
                   return [-1, -1]; 
               };
               
               console.log(threeEqualParts([1,0,1,0,1]))`,
                output: `[ 0, 3 `,
              },
            }}
          />
        ),
      },
      q772: {
        title: "Q928. Minimize Malware Spread II (Q772)",
        content: (
          <Comp
            title="Q928. Minimize Malware Spread II (Q772)"
            content1={
              <>
                You are given a network of n nodes represented as an n x n
                adjacency matrix graph, where the ith node is directly connected
                to the jth node if graph[i][j] == 1.
                <br />
                Some nodes initial are initially infected by malware. Whenever
                two nodes are directly connected, and at least one of those two
                nodes is infected by malware, both nodes will be infected by
                malware. This spread of malware will continue until no more
                nodes can be infected in this manner.
                <br />
                Suppose M(initial) is the final number of nodes infected with
                malware in the entire network after the spread of malware stops.
                <br />
                We will remove exactly one node from initial, completely
                removing it and any connections from this node to any other
                node.
                <br />
                Return the node that, if removed, would minimize M(initial). If
                multiple nodes could be removed to minimize M(initial), return
                such a node with the smallest index.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: graph = [[1,1,0],[1,1,0],[0,0,1]], <br /> initial =
                    [0,1]
                    <br /> Output: 0
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: graph = [[1,1,0],[1,1,1],[0,1,1]], <br />
                    initial = [0,1]
                    <br /> Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: graph = [[1,1,0,0],[1,1,1,0],[0,1,1,1],[0,0,1,1]],{" "}
                    <br /> initial = [0,1]
                    <br /> Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == graph.length <br />
                n == graph[i].length <br />
                2 &lt;= n &lt;= 300 <br />
                graph[i][j] is 0 or 1. <br />
                graph[i][j] == graph[j][i] <br />
                graph[i][i] == 1 <br />
                1 &lt;= initial.length &lt; n <br />
                0 &lt;= initial[i] &lt;= n - 1 <br />
                All the integers in initial are unique.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} graph
                * @param {number[]} initial
                * @return {number}
                */
               var minMalwareSpread = function(graph, initial) {
                 let ans = 0;
                 let minCount = graph.length;
                 initial = initial.sort((a,b)=>a-b)
                 for (let i of initial) {
                   const count = bfs(graph, i, initial);
                   if (count < minCount) {
                     minCount = count;
                     ans = i;
                   }
                 }
                 return ans;   
               };
               
               function bfs(graph, removed, initial) {
                 const q = [];
                 const seen = Array(graph.length).fill(false);
                 seen[removed] = true;
                 let count = 0;
                 for (const i of initial)
                     if (i != removed) {
                       q.push(i);
                       seen[i] = true;
                     }
                   while (q.length) {
                     const u = q.shift();
                     ++count;
                     for (let i = 0; i < graph.length; ++i) {
                       if (seen[i])
                         continue;
                       if (i != u && graph[i][u] == 1) {
                         q.push(i);
                         seen[i] = true;
                       }
                     }
                   }
                   return count;
               }
               console.log(minMalwareSpread([[1,1,0],[1,1,0],[0,0,1]],[0,1]))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q773: {
        title: "Q929. Unique Email Addresses (Q773)",
        content: (
          <Comp
            title="Q929. Unique Email Addresses (Q773)"
            content1={
              <>
                Every valid email consists of a local name and a domain name,
                separated by the '@' sign. Besides lowercase letters, the email
                may contain one or more '.' or '+'.
                <br />
                For example, in "alice@leetcode.com", "alice" is the local name,
                and "leetcode.com" is the domain name.
                <br />
                If you add periods '.' between some characters in the local name
                part of an email address, mail sent there will be forwarded to
                the same address without dots in the local name. Note that this
                rule does not apply to domain names.
                <br />
                For example, "alice.z@leetcode.com" and "alicez@leetcode.com"
                forward to the same email address.
                <br />
                If you add a plus '+' in the local name, everything after the
                first plus sign will be ignored. This allows certain emails to
                be filtered. Note that this rule does not apply to domain names.
                <br />
                For example, "m.y+name@email.com" will be forwarded to
                "my@email.com".
                <br />
                It is possible to use both of these rules at the same time.
                <br />
                Given an array of strings emails where we send one email to each
                emails[i], return the number of different addresses that
                actually receive mails.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: emails =
                    ["test.email+alex@leetcode.com","test.e.mail+bob.cathy@leetcode.com","testemail+david@lee.tcode.com"]
                    <br />
                    Output: 2
                    <br />
                    Explanation: "testemail@leetcode.com" and
                    "testemail@lee.tcode.com" actually receive mails.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: emails =
                    ["a@leetcode.com","b@leetcode.com","c@leetcode.com"]
                    <br />
                    Output: 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= emails.length &lt;= 100
                <br />
                1 &lt;= emails[i].length &lt;= 100
                <br />
                emails[i] consist of lowercase English letters, '+', '.' and
                '@'.
                <br />
                Each emails[i] contains exactly one '@' character.
                <br />
                All local and domain names are non-empty.
                <br />
                Local names do not start with a '+' character.
                <br />
                Domain names end with the ".com" suffix.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} emails
                * @return {number}
                */
               var numUniqueEmails = function(emails) {
                 const seen = new Set();
                 for (let email of emails) {
                   let [local,domain] = email.split("@");
                   local = local.split('+')[0].split('.').join('')
                   seen.add(local + '@' + domain)
                 }
                 return seen.size;    
               };
               
               console.log(numUniqueEmails(["test.email+alex@leetcode.com","test.e.mail+bob.cathy@leetcode.com","testemail+david@lee.tcode.com"]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q774: {
        title: "Q930. Binary Subarrays With Sum (Q774)",
        content: (
          <Comp
            title="Q930. Binary Subarrays With Sum (Q774)"
            content1={
              <>
                Given a binary array nums and an integer goal, return the number
                of non-empty subarrays with a sum goal.
                <br />A subarray is a contiguous part of the array.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Given a binary array nums and an integer goal, return the
                    number of non-empty subarrays with a sum goal.
                    <br />A subarray is a contiguous part of the array.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [0,0,0,0,0], goal = 0<br />
                    Output: 15
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 3 * 10^4
                <br />
                nums[i] is either 0 or 1.
                <br />0 &lt;= goal &lt;= nums.length
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} goal
                * @return {number}
                */
               var numSubarraysWithSum = function(nums, goal) {
                 let ans = 0;
                 let prefix = 0;
                 const count = {}
                 count[0] = 1
               
                 for (let a of nums) {
                   prefix += a;
                   if ((prefix - goal) in count)
                     ans += count[prefix - goal];
                 count[prefix] = (count[prefix] || 0) + 1;
                 }
                 return ans;    
               };
               
               console.log(numSubarraysWithSum([1,0,1,0,1],2))`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q775: {
        title: "Q931. Minimum Falling Path Sum (Q775)",
        content: (
          <Comp
            title="Q931. Minimum Falling Path Sum (Q775)"
            content1={
              <>
                Given an n x n array of integers matrix, return the minimum sum
                of any falling path through matrix.
                <br />A falling path starts at any element in the first row and
                chooses the element in the next row that is either directly
                below or diagonally left/right. Specifically, the next element
                from position (row, col) will be (row + 1, col - 1), (row + 1,
                col), or (row + 1, col + 1).
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode931,
                content: (
                  <>
                    Input: matrix = [[2,1,3],[6,5,4],[7,8,9]] <br />
                    Output: 13 <br />
                    Explanation: There are two falling paths with a minimum sum
                    as shown.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: matrix = [[-19,57],[-40,-5]] <br />
                    Output: -59 <br />
                    Explanation: The falling path with a minimum sum is shown.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == matrix.length == matrix[i].length
                <br />
                1 &lt;= n &lt;= 100
                <br />
                -100 &lt;= matrix[i][j] &lt;= 100
              </>
            }
            tc="n^2"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} matrix
                * @return {number}
                */
               var minFallingPathSum = function(matrix) {
                 const n = matrix.length;
                   for (let i = 1; i < n; ++i)
                     for (let j = 0; j < n; ++j) {
                       let min = Number.MAX_SAFE_INTEGER;
                       for (let k = Math.max(0, j - 1); k < Math.min(n, j + 2); ++k)
                         min = Math.min(min, matrix[i - 1][k]);
                       matrix[i][j] += min;
                     }
                   return Math.min(...matrix[n-1])
               };
               console.log(minFallingPathSum([[2,1,3],[6,5,4],[7,8,9]]))`,
                output: `13`,
              },
            }}
          />
        ),
      },
      q776: {
        title: "Q932. Beautiful Array (Q776)",
        content: (
          <Comp
            title="Q932. Beautiful Array (Q776)"
            content1={
              <>
                An array nums of length n is beautiful if:
                <br />
                nums is a permutation of the integers in the range [1, n].
                <br />
                For every 0 &lt;= i &lt; j &lt; n, there is no index k with i
                &lt; k &lt; j where 2 * nums[k] == nums[i] + nums[j].
                <br />
                Given the integer n, return any beautiful array nums of length
                n. There will be at least one valid answer for the given n.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 4<br />
                    Output: [2,1,4,3]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 5<br />
                    Output: [3,1,2,5,4]
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 1000</>}
            tc="n.log n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number[]}
                */
               var beautifulArray = function(n) {
                 const A = Array(n).fill(0)
                 for (let i = 0; i < n; ++i)
                   A[i] = i + 1;
                 divide(A, 0, n - 1, 1);
                 return A;    
               };
               
               function divide(A, l, r, mask) {
                 if (l >= r) return;
                 const m = partition(A, l, r, mask);
                 divide(A, l, m, mask << 1);
                 divide(A, m + 1, r, mask << 1);
               }
               
               function partition(A, l, r, mask) {
                 let nextSwapped = l;
                 for (let i = l; i <= r; ++i)
                   if ((A[i] & mask) > 0)
                     swap(A, i, nextSwapped++);
                 return nextSwapped - 1;
               }
               
               function swap(A, i, j) {
                 const temp = A[i];
                 A[i] = A[j];
                 A[j] = temp;
               }
               
               console.log(beautifulArray(4))`,
                output: `[ 3, 1, 2, 4 ]`,
              },
            }}
          />
        ),
      },
      q777: {
        title: "Q933. Number of Recent Calls (Q777)",
        content: (
          <Comp
            title="Q933. Number of Recent Calls (Q777)"
            content1={
              <>
                You have a RecentCounter class which counts the number of recent
                requests within a certain time frame.
                <br />
                Implement the RecentCounter class:
                <br />
                RecentCounter() Initializes the counter with zero recent
                requests.
                <br />
                int ping(int t) Adds a new request at time t, where t represents
                some time in milliseconds, and returns the number of requests
                that has happened in the past 3000 milliseconds (including the
                new request). Specifically, return the number of requests that
                have happened in the inclusive range [t - 3000, t].
                <br />
                It is guaranteed that every call to ping uses a strictly larger
                value of t than the previous call.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["RecentCounter", "ping", "ping", "ping", "ping"] <br />
                    [[], [1], [100], [3001], [3002]] <br />
                    Output <br />
                    [null, 1, 2, 3, 3] <br />
                    Explanation <br />
                    RecentCounter recentCounter = new RecentCounter();
                    <br /> recentCounter.ping(1); // requests = [1], range is
                    [-2999,1], return 1
                    <br /> recentCounter.ping(100); // requests = [1, 100],
                    range is [-2900,100], return 2
                    <br /> recentCounter.ping(3001); // requests = [1, 100,
                    3001], range is [1,3001], return 3
                    <br /> recentCounter.ping(3002); // requests = [1, 100,
                    3001, 3002], range is [2,3002], return 3
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= t &lt;= 109 <br />
                Each test case will call ping with strictly increasing values of
                t.
                <br /> At most 104 calls will be made to ping.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input
                // ["RecentCounter", "ping", "ping", "ping", "ping"]
                // [[], [1], [100], [3001], [3002]]
                class RecentCounter {
                    List<Integer> list;
                    public RecentCounter() {
                        list = new LinkedList<>();
                    }
                    
                    public int ping(int t) {
                        list.add(t);
                        while(list.get(0) < t-3000) list.remove(0);
                        return list.size();
                    }
                }
                
                /**
                 * Your RecentCounter object will be instantiated and called as such:
                 * RecentCounter obj = new RecentCounter();
                 * int param_1 = obj.ping(t);
                 */`,
                output: `[null, 1, 2, 3, 3]`,
              },
            }}
          />
        ),
      },
      q778: {
        title: "Q934. Shortest Bridge (Q778)",
        content: (
          <Comp
            title="Q934. Shortest Bridge (Q778)"
            content1={
              <>
                You are given an n x n binary matrix grid where 1 represents
                land and 0 represents water.
                <br />
                An island is a 4-directionally connected group of 1's not
                connected to any other 1's. There are exactly two islands in
                grid.
                <br />
                You may change 0's to 1's to connect the two islands to form one
                island.
                <br />
                Return the smallest number of 0's you must flip to connect the
                two islands.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: grid = [[0,1],[1,0]] <br />
                    Output: 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[0,1,0],[0,0,0],[0,0,1]] <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid =
                    [[1,1,1,1,1],[1,0,0,0,1],[1,0,1,0,1],[1,0,0,0,1],[1,1,1,1,1]]
                    <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == grid.length == grid[i].length <br />
                2 &lt;= n &lt;= 100 <br />
                grid[i][j] is either 0 or 1. <br />
                There are exactly two islands in grid.
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Java: {
                code: `// Input: grid = [[0,1],[1,0]]
                class Solution {
                  public int shortestBridge(int[][] grid) {
                    markGridTwo(grid);
                    for (int color = 2;; ++color)
                      for (int i = 0; i < grid.length; ++i)
                        for (int j = 0; j < grid[0].length; ++j)
                          if (grid[i][j] == color)
                            if (expand(grid, i + 1, j, color) || expand(grid, i - 1, j, color) ||
                                expand(grid, i, j + 1, color) || expand(grid, i, j - 1, color))
                              return color - 2;
                  }
                  private void markGridTwo(int[][] grid) {
                    for (int i = 0; i < grid.length; ++i)
                      for (int j = 0; j < grid[0].length; ++j)
                        if (grid[i][j] == 1) {
                          markGridTwo(grid, i, j);
                          return;
                        }
                  }
                  private void markGridTwo(int[][] grid, int i, int j) {
                    if (i < 0 || i == grid.length || j < 0 || j == grid[0].length)
                      return;
                    if (grid[i][j] != 1)
                      return;
                    grid[i][j] = 2;
                    markGridTwo(grid, i + 1, j);
                    markGridTwo(grid, i - 1, j);
                    markGridTwo(grid, i, j + 1);
                    markGridTwo(grid, i, j - 1);
                  }
                  private boolean expand(int[][] grid, int i, int j, int color) {
                    if (i < 0 || i == grid.length || j < 0 || j == grid[0].length)
                      return false;
                    if (grid[i][j] == 0)
                      grid[i][j] = color + 1;
                    return grid[i][j] == 1; 
                  }
                }`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q779: {
        title: "Q935. Knight Dialer (Q779)",
        content: (
          <Comp
            title="Q935. Knight Dialer (Q779)"
            content1={
              <>
                The chess knight has a unique movement, it may move two squares
                vertically and one square horizontally, or two squares
                horizontally and one square vertically (with both forming the
                shape of an L). The possible movements of chess knight are shown
                in this diagaram:
                <br />A chess knight can move as indicated in the chess diagram
                below:
              </>
            }
            img={Leetcode935}
            content2={
              <>
                We have a chess knight and a phone pad as shown below, the
                knight can only stand on a numeric cell (i.e. blue cell).
                <br />
                Given an integer n, return how many distinct phone numbers of
                length n we can dial.
                <br />
                You are allowed to place the knight on any numeric cell
                initially and then you should perform n - 1 jumps to dial a
                number of length n. All jumps should be valid knight jumps.
                <br />
                As the answer may be very large, return the answer modulo 109 +
                7.
              </>
            }
            examples={[
              {
                content: (
                  <>
                    Input: n = 1 <br />
                    Output: 10 <br />
                    Explanation: We need to dial a number of length 1, so
                    placing the knight over any numeric cell of the 10 cells is
                    sufficient.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2 <br />
                    Output: 20 <br />
                    Explanation: All the valid number we can dial are [04, 06,
                    16, 18, 27, 29, 34, 38, 40, 43, 49, 60, 61, 67, 72, 76, 81,
                    83, 92, 94]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 3131 <br />
                    Output: 136006598 <br />
                    Explanation: Please take care of the mod.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 5000 <br />
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               var knightDialer = function(n) {
                 const kMod = 1e9 + 7;
                 const dirs = [[-2, 1], [-1, 2], [1, 2], [2, 1], [2, -1], [1, -2], [-1, -2], [-2, -1]];
                 let dp = Array.from(Array(4),()=>Array(3).fill(1));
                 dp[3][0] = dp[3][2] = 0;
                 for (let k = 0; k < n - 1; ++k) {
                     const newDp = Array.from(Array(4),()=>Array(3).fill(0));
                     for (let i = 0; i < 4; ++i)
                       for (let j = 0; j < 3; ++j) {
                         if (isNotNumericCell(i, j))
                           continue;
                         for (let dir of dirs) {
                           const x = i + dir[0];
                           const y = j + dir[1];
                           if (x < 0 || x >= 4 || y < 0 || y >= 3)
                             continue;
                           if (isNotNumericCell(x, y))
                             continue;
                           newDp[i][j] = (newDp[i][j] + dp[x][y]) % kMod;
                         }
                       }
                     dp = newDp;
                   }
                   let ans = 0;
                   for (let row of dp)
                     for (let a of row)
                       ans = (ans + a) % kMod;
                   return ans;    
               };
               
               function isNotNumericCell(i, j) {
                 return i == 3 && (j == 0 || j == 2);
               }
               
               console.log(knightDialer(1))`,
                output: `10`,
              },
            }}
          />
        ),
      },
      q780: {
        title: "Q936. Stamping The Sequence (Q780)",
        content: (
          <Comp
            title="Q936. Stamping The Sequence (Q780)"
            content1={
              <>
                You are given two strings stamp and target. Initially, there is
                a string s of length target.length with all s[i] == '?'.
                <br />
                In one turn, you can place stamp over s and replace every letter
                in the s with the corresponding letter from stamp.
                <br />
                For example, if stamp = "abc" and target = "abcba", then s is
                "?????" initially. In one turn you can:
                <br />
                place stamp at index 0 of s to obtain "abc??",
                <br />
                place stamp at index 1 of s to obtain "?abc?", or
                <br />
                place stamp at index 2 of s to obtain "??abc".
                <br />
                Note that stamp must be fully contained in the boundaries of s
                in order to stamp (i.e., you cannot place stamp at index 3 of
                s).
                <br />
                We want to convert s to target using at most 10 * target.length
                turns. Return an array of the index of the left-most letter
                being stamped at each turn. If we cannot obtain target from s
                within 10 * target.length turns, return an empty array.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: stamp = "abc", target = "ababc" <br />
                    Output: [0,2] <br />
                    Explanation: Initially s = "?????". <br />
                    - Place stamp at index 0 to get "abc??". <br />
                    - Place stamp at index 2 to get "ababc". <br />
                    [1,0,2] would also be accepted as an answer, as well as some
                    other answers.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: stamp = "abca", target = "aabcaca" <br />
                    Output: [3,0,1] <br />
                    Explanation: Initially s = "???????". <br />
                    - Place stamp at index 3 to get "???abca". <br />
                    - Place stamp at index 0 to get "abcabca". <br />- Place
                    stamp at index 1 to get "aabcaca".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= stamp.length &lt;= target.length &lt;= 1000 <br />
                stamp and target consist of lowercase English letters.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `// Input: stamp = "abc", target = "ababc"
                class Solution {
                  public int[] movesToStamp(String stamp, String target) {
                    List<Integer> ans = new ArrayList<>();
                    char[] T = target.toCharArray();
                    boolean[] stamped = new boolean[target.length()];
                    int stampedCount = 0;
                
                    while (stampedCount < T.length) {
                      boolean isStamped = false;
                      for (int i = 0; i <= T.length - stamp.length(); ++i) {
                        if (stamped[i])
                          continue;
                        final int stampified = stampify(stamp, T, i);
                        if (stampified == 0)
                          continue;
                        stampedCount += stampified;
                        isStamped = true;
                        stamped[i] = true;
                        ans.add(i);
                      }
                      if (!isStamped)
                        return new int[] {};
                    }
                    Collections.reverse(ans);
                    return ans.stream().mapToInt(i -> i).toArray();
                  }
                
                  private int stampify(final String stamp, char[] T, int s) {
                    int stampified = stamp.length();
                    for (int i = 0; i < stamp.length(); ++i)
                      if (T[s + i] == '*') 
                        --stampified;
                      else if (T[s + i] != stamp.charAt(i))
                        return 0; 
                    Arrays.fill(T, s, s + stamp.length(), '*');
                    return stampified;
                  }
                }`,
                output: `[0,2]`,
              },
            }}
          />
        ),
      },
      q781: {
        title: "Q937. Reorder Data in Log Files (Q781)",
        content: (
          <Comp
            title="Q937. Reorder Data in Log Files (Q781)"
            content1={
              <>
                You are given an array of logs. Each log is a space-delimited
                string of words, where the first word is the identifier.
                <br />
                There are two types of logs:
                <br />
                Letter-logs: All words (except the identifier) consist of
                lowercase English letters.
                <br />
                Digit-logs: All words (except the identifier) consist of digits.
                <br />
                Reorder these logs so that:
                <br />
                The letter-logs come before all digit-logs.
                <br />
                The letter-logs are sorted lexicographically by their contents.
                If their contents are the same, then sort them lexicographically
                by their identifiers.
                <br />
                The digit-logs maintain their relative ordering.
                <br />
                Return the final order of the logs.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: logs = ["dig1 8 1 5 1","let1 art can","dig2 3
                    6","let2 own kit dig","let3 art zero"]
                    <br /> Output: ["let1 art can","let3 art zero","let2 own kit
                    dig","dig1 8 1 5 1","dig2 3 6"]
                    <br /> Explanation:
                    <br /> The letter-log contents are all different, so their
                    ordering is "art can", "art zero", "own kit dig".
                    <br /> The digit-logs have a relative order of "dig1 8 1 5
                    1", "dig2 3 6".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: logs = ["a1 9 2 3 1","g1 act car","zo4 4 7","ab1 off
                    key dog","a8 act zoo"]
                    <br /> Output: ["g1 act car","a8 act zoo","ab1 off key
                    dog","a1 9 2 3 1","zo4 4 7"]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= logs.length &lt;= 100 <br />
                3 &lt;= logs[i].length &lt;= 100 <br />
                All the tokens of logs[i] are separated by a single space.{" "}
                <br />
                logs[i] is guaranteed to have an identifier and at least one
                word after the identifier.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: logs = ["dig1 8 1 5 1","let1 art can","dig2 3 6","let2 own kit dig","let3 art zero"]
                class Solution {
                  public String[] reorderLogFiles(String[] logs) {
                    List<String> ans = new ArrayList<>();
                    List<String> digitLogs = new ArrayList<>();
                    List<String[]> letterLogs = new ArrayList<>();
                
                    for (final String log : logs) {
                      final int i = log.indexOf(' ');
                      if (Character.isDigit(log.charAt(i + 1)))
                        digitLogs.add(log);
                      else
                        letterLogs.add(new String[] {log.substring(0, i), log.substring(i + 1)});
                    }
                
                    Collections.sort(letterLogs, new Comparator<String[]>() {
                      @Override
                      public int compare(String[] l1, String[] l2) {
                        return l1[1].compareTo(l2[1]) == 0 ? l1[0].compareTo(l2[0]) : l1[1].compareTo(l2[1]);
                      }
                    });
                
                    for (String[] letterLog : letterLogs)
                      ans.add(letterLog[0] + " " + letterLog[1]);
                
                    for (final String digitLog : digitLogs)
                      ans.add(digitLog);
                
                    return ans.toArray(new String[0]);
                  }
                }`,
                output: `["let1 art can","let3 art zero","let2 own kit dig","dig1 8 1 5 1","dig2 3 6"]`,
              },
            }}
          />
        ),
      },
      q782: {
        title: "Q938. Range Sum of BST (Q782)",
        content: (
          <Comp
            title="Q938. Range Sum of BST (Q782)"
            content1={
              <>
                Given the root node of a binary search tree and two integers low
                and high, return the sum of values of all nodes with a value in
                the inclusive range [low, high].
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode938,
                content: (
                  <>
                    Input: root = [10,5,15,3,7,null,18], low = 7, high = 15
                    <br /> Output: 32 <br />
                    Explanation: Nodes 7, 10, and 15 are in the range [7, 15]. 7
                    + 10 + 15 = 32.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [10,5,15,3,7,13,18,1,null,6], low = 6, high =
                    10
                    <br />
                    Output: 23
                    <br />
                    Explanation: Nodes 6, 7, and 10 are in the range [6, 10]. 6
                    + 7 + 10 = 23.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 2 * 104].
                <br /> 1 &lt;= Node.val &lt;= 10^5
                <br /> 1 &lt;= low &lt;= high &lt;= 10^5
                <br /> All Node.val are unique.
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
              }
             
             /**
              * @param {TreeNode} root
              * @param {number} low
              * @param {number} high
              * @return {number}
              */
             var rangeSumBST = function(root, low, high) {
               if (!root) return 0;
               if (root.val < low)
                 return rangeSumBST(root.right, low, high);
               if (root.val > high)
                 return rangeSumBST(root.left, low, high);
               return root.val + rangeSumBST(root.left, low, high) + rangeSumBST(root.right, low, high);   
             };
             
             const t = new TreeNode(10)
             t.left = new TreeNode(5)
             t.left.left = new TreeNode(3)
             t.left.right = new TreeNode(7)
             t.right = new TreeNode(15)
             t.right.right = new TreeNode(18)
             console.log(rangeSumBST(t,7,15))`,
                output: `32`,
              },
            }}
          />
        ),
      },
      q783: {
        title: "Q939. Minimum Area Rectangle (Q783)",
        content: (
          <Comp
            title="Q939. Minimum Area Rectangle (Q783)"
            content1={
              <>
                You are given an array of points in the X-Y plane points where
                points[i] = [xi, yi].
                <br />
                Return the minimum area of a rectangle formed from these points,
                with sides parallel to the X and Y axes. If there is not any
                such rectangle, return 0.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode939,
                content: (
                  <>
                    Input: points = [[1,1],[1,3],[3,1],[3,3],[2,2]]
                    <br /> Output: 4
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: points = [[1,1],[1,3],[3,1],[3,3],[4,1],[4,3]]
                    <br /> Output: 2
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= points.length &lt;= 500 <br />
                points[i].length == 2 <br />
                0 &lt;= xi, yi &lt;= 4 * 10^4 <br />
                All the given points are unique.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `// Input: points = [[1,1],[1,3],[3,1],[3,3],[2,2]]
                class Solution {
                  public int minAreaRect(int[][] points) {
                    int ans = Integer.MAX_VALUE;
                    Map<Integer, Set<Integer>> xToYs = new HashMap<>();
                    for (int[] p : points) {
                      xToYs.putIfAbsent(p[0], new HashSet<>());
                      xToYs.get(p[0]).add(p[1]);
                    }
                    for (int i = 1; i < points.length; ++i)
                      for (int j = 0; j < i; ++j) {
                        int[] p = points[i];
                        int[] q = points[j];
                        if (p[0] == q[0] || p[1] == q[1])
                          continue;
                        if (xToYs.get(p[0]).contains(q[1]) && xToYs.get(q[0]).contains(p[1]))
                          ans = Math.min(ans, Math.abs(p[0] - q[0]) * Math.abs(p[1] - q[1]));
                      }
                    return ans == Integer.MAX_VALUE ? 0 : ans;
                  }
                }
                `,
                output: `4`,
              },
            }}
          />
        ),
      },
      q784: {
        title: "Q940. Distinct Subsequences II (Q784)",
        content: (
          <Comp
            title="Q940. Distinct Subsequences II (Q784)"
            content1={
              <>
                Given a string s, return the number of distinct non-empty
                subsequences of s. Since the answer may be very large, return it
                modulo 10^9 + 7.
                <br />A subsequence of a string is a new string that is formed
                from the original string by deleting some (can be none) of the
                characters without disturbing the relative positions of the
                remaining characters. (i.e., "ace" is a subsequence of "abcde"
                while "aec" is not.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "abc" <br />
                    Output: 7 <br />
                    Explanation: The 7 distinct subsequences are "a", "b", "c",
                    "ab", "ac", "bc", and "abc".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "aba" <br />
                    Output: 6 <br />
                    Explanation: The 6 distinct subsequences are "a", "b", "ab",
                    "aa", "ba", and "aba".
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "aaa" <br />
                    Output: 3 <br />
                    Explanation: The 3 distinct subsequences are "a", "aa" and
                    "aaa".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;== s.length &lt;= 2000 <br />s consists of lowercase
                English letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number}
                */
               var distinctSubseqII = function(s) {
                 const kMod = 1e9 + 7;
                 const endsWith= Array(26).fill(0);
                 for (let c of s)
                     endsWith[c.charCodeAt(0) - 97] = (endsWith.reduce((a,b)=>a+b,0) + 1) % kMod;
                   return endsWith.reduce((a,b)=>a+b,0) % kMod;   
               };
               
               console.log(distinctSubseqII("abc"))`,
                output: `7`,
              },
            }}
          />
        ),
      },
      q785: {
        title: "Q941. Valid Mountain Array (Q785)",
        content: (
          <Comp
            title="Q941. Valid Mountain Array (Q785)"
            content1={
              <>
                Given an array of integers arr, return true if and only if it is
                a valid mountain array.
                <br />
                Recall that arr is a mountain array if and only if:
                <br />
                arr.length &gt;= 3<br />
                There exists some i with 0 &lt; i &lt; arr.length - 1 such that:
                <br />
                <br />
                arr[0] &lt; arr[1] &lt; ... &lt; arr[i - 1] &lt; arr[i]
                <br />
                arr[i] &gt; arr[i + 1] &gt; ... &gt; arr[arr.length - 1]
              </>
            }
            img={Leetcode941}
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [2,1]
                    <br />
                    Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [3,5,5]
                    <br />
                    Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [0,3,2,1]
                    <br />
                    Output: true
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= arr.length &lt;= 10^4
                <br />0 &lt;= arr[i] &lt;= 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} arr
                * @return {boolean}
                */
               var validMountainArray = function(arr) {
                 if (arr.length < 3) return false;
                 let l = 0;
                 let r = arr.length - 1;
                 while (l + 1 < arr.length && arr[l] < arr[l + 1])
                   ++l;
                 while (r > 0 && arr[r] < arr[r - 1])
                   --r;
                 return l > 0 && r < arr.length - 1 && l == r;    
               };
               
               console.log(validMountainArray([2,1]))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q786: {
        title: "Q942. DI String Match (Q786)",
        content: (
          <Comp
            title="Q942. DI String Match (Q786)"
            content1={
              <>
                A permutation perm of n + 1 integers of all the integers in the
                range [0, n] can be represented as a string s of length n where:
                <br />
                s[i] == 'I' if perm[i] &lt; perm[i + 1], and <br />
                s[i] == 'D' if perm[i] &gt; perm[i + 1]. <br />
                Given a string s, reconstruct the permutation perm and return
                it. If there are multiple valid permutations perm, return any of
                them.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "IDID"
                    <br />
                    Output: [0,4,1,3,2]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "III"
                    <br />
                    Output: [0,1,2,3]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "DDI"
                    <br />
                    Output: [3,2,0,1]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 10^5
                <br />
                s[i] is either 'I' or 'D'.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {number[]}
                */
               var diStringMatch = function(s) {
                 const n = s.length;
                 const ans = Array(n + 1).fill(0);
                 let min = 0;
                 let max = n;
                 for (let i = 0; i < n; ++i)
                   ans[i] = s[i] == 'I' ? min++ : max--;
                 ans[n] = min;
                 return ans;    
               };
               
               console.log(diStringMatch("IDID"))`,
                output: `[0,4,1,3,2]`,
              },
            }}
          />
        ),
      },
      q787: {
        title: "Q943. Find the Shortest Superstring (Q787)",
        content: (
          <Comp
            title="Q943. Find the Shortest Superstring (Q787)"
            content1={
              <>
                Given an array of strings words, return the smallest string that
                contains each string in words as a substring. If there are
                multiple valid strings of the smallest length, return any of
                them.
                <br />
                You may assume that no string in words is a substring of another
                string in words.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: words = ["alex","loves","leetcode"]
                    <br />
                    Output: "alexlovesleetcode"
                    <br />
                    Explanation: All permutations of "alex","loves","leetcode"
                    would also be accepted.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words = ["catg","ctaagt","gcta","ttca","atgcatc"]
                    <br />
                    Output: "gctaagttcatgcatc"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= words.length &lt;= 12
                <br />
                1 &lt;= words[i].length &lt;= 20
                <br />
                words[i] consists of lowercase English letters.
                <br />
                All the strings of words are unique.
              </>
            }
            tc="n!"
            sc="n"
            codes={{
              Java: {
                code: `// Input: words = ["alex","loves","leetcode"]
                class Solution {
                    
                    public String shortestSuperstring(String[] words) {
                        
                        int n = words.length;
                        int[][] distance = new int[n][n];
                        for(int i=0; i<n; i++){
                            for(int j=0; j<n; j++){
                                int min = Math.min(words[i].length(), words[j].length());
                                for(int k=min; k>=0; k--){
                                    if(words[i].endsWith(words[j].substring(0,k))) {
                                        distance[i][j] = words[j].length() - k;
                                        break;
                                    }
                                }
                            }
                        }
                        
                        int dp[][] = new int[(1<<n)][n];
                        int path[][] =  new int[(1<<n)][n];
                        String ans = "";
                        int len = Integer.MAX_VALUE;
                        for(int i = 0; i<n; i++){
                           for(int j=0; j< ( 1<<n); j++){
                                Arrays.fill(dp[j], -1);
                                Arrays.fill(path[j], -1);
                            }
                            int tsp = tsp(i, 0, distance, dp, path, n);
                            String str = createPath(words, i, distance, path);
                            if(str.length() < len){
                                ans = str;
                                len = str.length();
                            }
                            
                        }
                        return ans;
                
                    }
                    
                    private int tsp(int city, int mask, int[][] distance, int[][] dp, int[][] path, int n){
                        if(mask == ((1<<n) - 1)) return 0; 
                        if(dp[mask][city] != -1){
                            return dp[mask][city];
                        }
                        int ans = Integer.MAX_VALUE;
                        int nextCity = -1;
                        
                        for(int i = 0; i<n; i++){
                            if((mask & (1<<i)) == 0 ){
                                int dis = distance[city][i] + tsp(i, (mask | (1<<i)), distance, dp, path, n);
                                if(dis < ans){
                                    ans = dis;
                                    nextCity = i;
                                }
                            }
                        }
                        path[mask][city] = nextCity;
                        dp[mask][city] = ans;
                        return ans;
                    }
                    
                    private String createPath(String[] words, int start, int[][] distance, int[][] path){
                        int c = start;
                        StringBuilder sb = new StringBuilder(words[c]);
                        int mask = (1<<start);
                        int x = path[mask][c];
                        while(x != -1){
                            sb.append(words[x].substring(words[x].length() - distance[c][x]));
                            mask |= (1<<x);
                            c = x;
                            x = path[mask][x];
                        }
                        return sb.toString();
                    }
                }`,
                output: `"alexlovesleetcode"`,
              },
            }}
          />
        ),
      },
      q788: {
        title: "Q944. Delete Columns to Make Sorted (Q788)",
        content: (
          <Comp
            title="Q944. Delete Columns to Make Sorted (Q788)"
            content1={
              <>
                You are given an array of n strings strs, all of the same
                length.
                <br />
                The strings can be arranged such that there is one on each line,
                making a grid. For example, strs = ["abc", "bce", "cae"] can be
                arranged as:
                <br />
                abc <br />
                bce <br />
                cae <br />
                You want to delete the columns that are not sorted
                lexicographically. In the above example (0-indexed), columns 0
                ('a', 'b', 'c') and 2 ('c', 'e', 'e') are sorted while column 1
                ('b', 'c', 'a') is not, so you would delete column 1.
                <br />
                Return the number of columns that you will delete.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: strs = ["cba","daf","ghi"] <br />
                    Output: 1 <br />
                    Explanation: The grid looks as follows: <br />
                    cba <br />
                    daf <br />
                    ghi <br />
                    Columns 0 and 2 are sorted, but column 1 is not, so you only
                    need to delete 1 column.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: strs = ["a","b"] <br />
                    Output: 0 <br />
                    Explanation: The grid looks as follows: <br />
                    a <br />
                    b <br />
                    Column 0 is the only column and is sorted, so you will not
                    delete any columns.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: strs = ["zyx","wvu","tsr"] Output: 3 <br />
                    Explanation: The grid looks as follows: <br />
                    zyx <br />
                    wvu <br />
                    tsr <br />
                    All 3 columns are not sorted, so you will delete all 3.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == strs.length <br />
                1 &lt;= n &lt;= 100 <br />
                1 &lt;= strs[i].length &lt;= 1000 <br />
                strs[i] consists of lowercase English letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} strs
                * @return {number}
                */
               var minDeletionSize = function(strs) {
                 const n = strs[0].length;
                 let ans = 0;
                 for (let j = 0; j < n; ++j)
                   for (let i = 0; i + 1 < strs.length; ++i)
                     if (strs[i].charAt(j) > strs[i + 1].charAt(j)) {
                       ++ans;
                       break;
                     }
                 return ans;   
               };
               
               console.log(minDeletionSize(["cba","daf","ghi"]))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q789: {
        title: "Q945. Minimum Increment to Make Array Unique (Q789)",
        content: (
          <Comp
            title="Q945. Minimum Increment to Make Array Unique (Q789)"
            content1={
              <>
                You are given an integer array nums. In one move, you can pick
                an index i where 0 &lt;= i &lt; nums.length and increment
                nums[i] by 1.
                <br />
                Return the minimum number of moves to make every value in nums
                unique.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,2]
                    <br />
                    Output: 1<br />
                    Explanation: After 1 move, the array could be [1, 2, 3].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [3,2,1,2,1,7]
                    <br />
                    Output: 6<br />
                    Explanation: After 6 moves, the array could be [3, 4, 1, 2,
                    5, 7].
                    <br /> It can be shown with 5 or less moves that it is
                    impossible for the array to have all unique values.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^5 <br />0 &lt;= nums[i] &lt;= 10^5
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var minIncrementForUnique = function(nums) {
                 let ans = 0;
                 let minAvailable = 0;
                 nums = nums.sort((a,b)=>a-b)
                 for (let a of nums) {
                   ans += Math.max(minAvailable - a, 0);
                   minAvailable = Math.max(minAvailable, a) + 1;
                 }
                 return ans;  
               };
               
               console.log(minIncrementForUnique([3,2,1,2,1,7]))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q790: {
        title: "Q946. Validate Stack Sequences (Q790)",
        content: (
          <Comp
            title="Q946. Validate Stack Sequences (Q790)"
            content1={
              <>
                Given two integer arrays pushed and popped each with distinct
                values, return true if this could have been the result of a
                sequence of push and pop operations on an initially empty stack,
                or false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: pushed = [1,2,3,4,5], popped = [4,5,3,2,1]
                    <br /> Output: true
                    <br /> Explanation: We might do the following sequence:
                    <br /> push(1), push(2), push(3), push(4),
                    <br /> pop() -&gt; 4,
                    <br /> push(5),
                    <br /> pop() -&gt; 5, pop() -&gt; 3, pop() -&gt; 2, pop()
                    -&gt; 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: pushed = [1,2,3,4,5], popped = [4,3,5,1,2]
                    <br /> Output: false
                    <br /> Explanation: 1 cannot be popped before 2.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= pushed.length &lt;= 1000
                <br /> 0 &lt;= pushed[i] &lt;= 1000
                <br /> All the elements of pushed are unique.
                <br /> popped.length == pushed.length
                <br /> popped is a permutation of pushed.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} pushed
                * @param {number[]} popped
                * @return {boolean}
                */
               var validateStackSequences = function(pushed, popped) {
                 const stack = [];
                 let i = 0; 
                 for (let x of pushed) {
                     stack.push(x);
                     while (stack.length && stack[stack.length-1] == popped[i]) {
                       stack.pop();
                       ++i;
                     }
                 }
                 return !stack.length;  
               };
               
               console.log(validateStackSequences([1,2,3,4,5],[4,5,3,2,1]))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q791: {
        title: "Q947. Most Stones Removed with Same Row or Column (Q791)",
        content: (
          <Comp
            title="Q947. Most Stones Removed with Same Row or Column (Q791)"
            content1={
              <>
                On a 2D plane, we place n stones at some integer coordinate
                points. Each coordinate point may have at most one stone.
                <br />
                A stone can be removed if it shares either the same row or the
                same column as another stone that has not been removed.
                <br />
                Given an array stones of length n where stones[i] = [xi, yi]
                represents the location of the ith stone, return the largest
                possible number of stones that can be removed.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: stones = [[0,0],[0,1],[1,0],[1,2],[2,1],[2,2]]
                    <br />
                    Output: 5<br />
                    Explanation: One way to remove 5 stones is as follows:
                    <br />
                    1. Remove stone [2,2] because it shares the same row as
                    [2,1].
                    <br />
                    2. Remove stone [2,1] because it shares the same column as
                    [0,1].
                    <br />
                    3. Remove stone [1,2] because it shares the same row as
                    [1,0].
                    <br />
                    4. Remove stone [1,0] because it shares the same column as
                    [0,0].
                    <br />
                    5. Remove stone [0,1] because it shares the same row as
                    [0,0].
                    <br />
                    Stone [0,0] cannot be removed since it does not share a
                    row/column with another stone still on the plane.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: stones = [[0,0],[0,2],[1,1],[2,0],[2,2]]
                    <br />
                    Output: 3
                    <br />
                    Explanation: One way to make 3 moves is as follows:
                    <br />
                    1. Remove stone [2,2] because it shares the same row as
                    [2,0].
                    <br />
                    2. Remove stone [2,0] because it shares the same column as
                    [0,0].
                    <br />
                    3. Remove stone [0,2] because it shares the same row as
                    [0,0].
                    <br />
                    Stones [0,0] and [1,1] cannot be removed since they do not
                    share a row/column with another stone still on the plane.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: stones = [[0,0]]
                    <br />
                    Output: 0<br />
                    Explanation: [0,0] is the only stone on the plane, so you
                    cannot remove it.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;== stones.length &lt;= 1000
                <br />
                0 &lt;== xi, yi &lt;== 10^4
                <br />
                No two stones are at the same coordinate point.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: stones = [[0,0],[0,1],[1,0],[1,2],[2,1],[2,2]]
                class Solution {
                  public int removeStones(int[][] stones) {
                    int numOfIslands = 0;
                    List<Integer>[] graph = new List[stones.length];
                    Set<Integer> seen = new HashSet<>();
                    for (int i = 0; i < graph.length; ++i)
                      graph[i] = new ArrayList<>();
                    for (int i = 0; i < stones.length; ++i)
                      for (int j = i + 1; j < stones.length; ++j)
                        if (stones[i][0] == stones[j][0] || stones[i][1] == stones[j][1]) {
                          graph[i].add(j);
                          graph[j].add(i);
                        }
                    for (int i = 0; i < stones.length; ++i)
                      if (seen.add(i)) {
                        dfs(graph, i, seen);
                        ++numOfIslands;
                      }
                    return stones.length - numOfIslands;
                  }
                  private void dfs(List<Integer>[] graph, int u, Set<Integer> seen) {
                    for (final int v : graph[u])
                      if (seen.add(v))
                        dfs(graph, v, seen);
                  }
                }
                `,
                output: `5`,
              },
            }}
          />
        ),
      },
      q792: {
        title: "Q948. Bag of Tokens (Q792)",
        content: (
          <Comp
            title="Q948. Bag of Tokens (Q792)"
            content1={
              <>
                You have an initial power of power, an initial score of 0, and a
                bag of tokens where tokens[i] is the value of the ith token
                (0-indexed).
                <br />
                Your goal is to maximize your total score by potentially playing
                each token in one of two ways:
                <br />
                If your current power is at least tokens[i], you may play the
                ith token face up, losing tokens[i] power and gaining 1 score.
                <br />
                If your current score is at least 1, you may play the ith token
                face down, gaining tokens[i] power and losing 1 score.
                <br />
                Each token may be played at most once and in any order. You do
                not have to play all the tokens.
                <br />
                Return the largest possible score you can achieve after playing
                any number of tokens.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: tokens = [100], power = 50
                    <br />
                    Output: 0<br />
                    Explanation: Playing the only token in the bag is impossible
                    because you either have too little power or too little
                    score.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: tokens = [100,200], power = 150
                    <br />
                    Output: 1<br />
                    Explanation: Play the 0th token (100) face up, your power
                    becomes 50 and score becomes 1. There is no need to play the
                    1st token since you cannot play it face up to add to your
                    score.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: tokens = [100,200,300,400], power = 200
                    <br />
                    Output: 2<br />
                    Explanation: Play the tokens in this order to get a score of
                    2:
                    <br />
                    1. Play the 0th token (100) face up, your power becomes 100
                    and score becomes 1.
                    <br />
                    2. Play the 3rd token (400) face down, your power becomes
                    500 and score becomes 0.
                    <br />
                    3. Play the 1st token (200) face up, your power becomes 300
                    and score becomes 1.
                    <br />
                    4. Play the 2nd token (300) face up, your power becomes 0
                    and score becomes 2.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= tokens.length &lt;= 1000 <br />0 &lt;= tokens[i], power
                &lt; 10^4
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} tokens
                * @param {number} power
                * @return {number}
                */
               var bagOfTokensScore = function(tokens, power) {
                 let ans = 0;
                 let score = 0;
                 let i = 0;                 
                 let j = tokens.length - 1; 
                 tokens = tokens.sort((a,b)=>a-b)
                   while (i <= j && (power >= tokens[i] || score > 0)) {
                     while (i <= j && power >= tokens[i]) {
                       power -= tokens[i++];
                       ++score;
                     }
                     ans = Math.max(ans, score);
                     if (i <= j && score > 0) {
                       power += tokens[j--];
                       --score;
                     }
                   }
                   return ans;   
               };
               
               console.log(bagOfTokensScore([100],50))`,
                output: `0`,
              },
            }}
          />
        ),
      },
      q793: {
        title: "Q949. Largest Time for Given Digits (Q793)",
        content: (
          <Comp
            title="Q949. Largest Time for Given Digits (Q793)"
            content1={
              <>
                Given an array arr of 4 digits, find the latest 24-hour time
                that can be made using each digit exactly once.
                <br />
                24-hour times are formatted as "HH:MM", where HH is between 00
                and 23, and MM is between 00 and 59. The earliest 24-hour time
                is 00:00, and the latest is 23:59.
                <br />
                Return the latest 24-hour time in "HH:MM" format. If no valid
                time can be made, return an empty string.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [1,2,3,4] <br />
                    Output: "23:41" <br />
                    Explanation: The valid 24-hour times are "12:34", "12:43",
                    "13:24", "13:42", "14:23", "14:32", "21:34", "21:43",
                    "23:14", and "23:41". Of these times, "23:41" is the latest.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [5,5,5,5] <br />
                    Output: "" <br />
                    Explanation: There are no valid 24-hour times as "55:55" is
                    not valid.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [5,5,5,5] <br />
                    Output: "" <br />
                    Explanation: There are no valid 24-hour times as "55:55" is
                    not valid.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                arr.length == 4 <br />0 &lt;= arr[i] &lt;= 9
              </>
            }
            tc="n^3"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} arr
                * @return {string}
                */
               var largestTimeFromDigits = function(arr) {
                 let ans = "";
                 for (let i = 0; i < 4; ++i)
                   for (let j = 0; j < 4; ++j)
                       for (let k = 0; k < 4; ++k) {
                         if (i == j || i == k || j == k)
                           continue;
                         let hours = "" + arr[i] + arr[j];
                         let minutes = "" + arr[k] + arr[6 - i - j - k];
                         let time = hours + ':' + minutes;
                         if (hours.localeCompare("24") < 0 && minutes.localeCompare("60") < 0 && ans.localeCompare(time) < 0)
                           ans = time;
                       }
                 return ans;    
               };
               
               console.log(largestTimeFromDigits([1,2,3,4]))`,
                output: `23:41`,
              },
            }}
          />
        ),
      },
      q794: {
        title: "Q950. Reveal Cards In Increasing Order (Q794)",
        content: (
          <Comp
            title="Q950. Reveal Cards In Increasing Order (Q794)"
            content1={
              <>
                You are given an integer array deck. There is a deck of cards
                where every card has a unique integer. The integer on the ith
                card is deck[i].
                <br />
                You can order the deck in any order you want. Initially, all the
                cards start face down (unrevealed) in one deck.
                <br />
                You will do the following steps repeatedly until all cards are
                revealed:
                <br />
                1. Take the top card of the deck, reveal it, and take it out of
                the deck.
                <br />
                2. If there are still cards in the deck then put the next top
                card of the deck at the bottom of the deck.
                <br />
                3. If there are still unrevealed cards, go back to step 1.
                Otherwise, stop.
                <br />
                Return an ordering of the deck that would reveal the cards in
                increasing order.
                <br />
                Note that the first entry in the answer is considered to be the
                top of the deck.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: deck = [17,13,11,2,3,5,7] <br />
                    Output: [2,13,3,11,5,17,7] <br />
                    Explanation: <br />
                    We get the deck in the order [17,13,11,2,3,5,7] (this order
                    does not matter), and reorder it.
                    <br /> After reordering, the deck starts as
                    [2,13,3,11,5,17,7], where 2 is the top of the deck.
                    <br /> We reveal 2, and move 13 to the bottom. The deck is
                    now [3,11,5,17,7,13].
                    <br /> We reveal 3, and move 11 to the bottom. The deck is
                    now [5,17,7,13,11].
                    <br /> We reveal 5, and move 17 to the bottom. The deck is
                    now [7,13,11,17].
                    <br /> We reveal 7, and move 13 to the bottom. The deck is
                    now [11,17,13].
                    <br /> We reveal 11, and move 17 to the bottom. The deck is
                    now [13,17].
                    <br /> We reveal 13, and move 17 to the bottom. The deck is
                    now [17].
                    <br /> We reveal 17.
                    <br /> Since all the cards revealed are in increasing order,
                    the answer is correct.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: deck = [1,1000] <br />
                    Output: [1,1000]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= deck.length &lt;= 1000 <br />
                1 &lt;= deck[i] &lt;= 10^6 <br />
                All the values of deck are unique.
              </>
            }
            tc="n^3"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} deck
                * @return {number[]}
                */
               var deckRevealedIncreasing = function(deck) {
                 const n = deck.length;
                 deck = deck.sort((a,b)=>a-b)
                 const q = [];
                 q.push(deck[n - 1]);
                 for (let i = n - 2; i >= 0; --i) {
                     q.unshift(q[q.length-1]);
                     q.pop();
                     q.unshift(deck[i]);
                   }
                   for (let i = 0; i < n; ++i)
                     deck[i] = q.shift();
                   return deck; 
               };
               
               console.log(deckRevealedIncreasing([17,13,11,2,3,5,7]))`,
                output: `[
                  2, 13, 3, 11,
                  5, 17, 7
                ]`,
              },
            }}
          />
        ),
      },
      q795: {
        title: "Q951. Flip Equivalent Binary Trees (Q795)",
        content: (
          <Comp
            title="Q951. Flip Equivalent Binary Trees (Q795)"
            content1={
              <>
                For a binary tree T, we can define a flip operation as follows:
                choose any node, and swap the left and right child subtrees.
                <br />
                A binary tree X is flip equivalent to a binary tree Y if and
                only if we can make X equal to Y after some number of flip
                operations.
                <br />
                Given the roots of two binary trees root1 and root2, return true
                if the two trees are flip equivalent or false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                img: Leetcode951,
                content: (
                  <>
                    Input: root1 = [1,2,3,4,5,6,null,null,null,7,8], root2 =
                    [1,3,2,null,6,4,5,null,null,null,null,8,7]
                    <br /> Output: true
                    <br /> Explanation: We flipped at nodes with values 1, 3,
                    and 5.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root1 = [], root2 = [] <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root1 = [], root2 = [1] <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in each tree is in the range [0, 100]. Each
                tree will have unique node values in the range [0, 99].
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right){
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root1
                 * @param {TreeNode} root2
                 * @return {boolean}
                 */
                var flipEquiv = function(root1, root2) {
                     if (!root1)
                      return !root2;
                    if (!root2)
                      return !root1;
                    if (root1.val != root2.val)
                      return false;
                    return flipEquiv(root1.left, root2.left) &&
                           flipEquiv(root1.right, root2.right) ||
                           flipEquiv(root1.left, root2.right) &&
                           flipEquiv(root1.right, root2.left); 
                };
                
                const t1 = new TreeNode()
                const t2 = new TreeNode(1)
                console.log(flipEquiv(t1,t2))`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q796: {
        title: "Q952. Largest Component Size by Common Factor (Q796)",
        content: (
          <Comp
            title="Q952. Largest Component Size by Common Factor (Q796)"
            content1={
              <>
                You are given an integer array of unique positive integers nums.
                Consider the following graph:
                <br />
                There are nums.length nodes, labeled nums[0] to nums[nums.length
                - 1],
                <br />
                There is an undirected edge between nums[i] and nums[j] if
                nums[i] and nums[j] share a common factor greater than 1.
                <br />
                Return the size of the largest connected component in the graph.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2018/12/01/ex1.png",
                content: (
                  <>
                    Input: nums = [4,6,15,35] <br />
                    Output: 4
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2018/12/01/ex2.png",
                content: (
                  <>
                    Input: nums = [20,50,9,63]
                    <br />
                    Output: 2
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2018/12/01/ex3.png",
                content: (
                  <>
                    Input: nums = [2,3,6,7,4,12,21,39]
                    <br />
                    Output: 8
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;== 2 * 10^4
                <br />
                1 &lt;== nums[i] &lt;== 10^5
                <br />
                All the values of nums are unique.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: nums = [4,6,15,35]
                class UF {
                  public UF(int n) {
                    id = new int[n + 1];
                    for (int i = 0; i < id.length; ++i)
                      id[i] = i;
                  }
                  public void union(int u, int v) {
                    id[find(u)] = find(v);
                  }
                  public int find(int u) {
                    return id[u] == u ? u : (id[u] = find(id[u]));
                  }
                  private int[] id;
                }
                
                class Solution {
                  public int largestComponentSize(int[] A) {
                    final int n = Arrays.stream(A).max().getAsInt();
                    int ans = 0;
                    UF uf = new UF(n);
                    Map<Integer, Integer> count = new HashMap<>();
                    for (int a : A)
                      for (int num = 2; num <= (int) Math.sqrt(a); ++num)
                        if (a % num == 0) {
                          uf.union(a, num);
                          uf.union(a, a / num);
                        }
                    for (int a : A) {
                      int pa = uf.find(a);
                      count.put(pa, count.getOrDefault(pa, 0) + 1);
                      ans = Math.max(ans, count.get(pa));
                    }
                    return ans;
                  }
                }`,
                output: `4`,
              },
            }}
          />
        ),
      },
      q797: {
        title: "Q953. Verifying an Alien Dictionary (Q797)",
        content: (
          <Comp
            title="Q953. Verifying an Alien Dictionary (Q797)"
            content1={
              <>
                In an alien language, surprisingly, they also use English
                lowercase letters, but possibly in a different order. The order
                of the alphabet is some permutation of lowercase letters.
                <br />
                Given a sequence of words written in the alien language, and the
                order of the alphabet, return true if and only if the given
                words are sorted lexicographically in this alien language.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: words = ["hello","leetcode"], order =
                    "hlabcdefgijkmnopqrstuvwxyz"
                    <br /> Output: true
                    <br /> Explanation: As 'h' comes before 'l' in this
                    language, then the sequence is sorted.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words = ["word","world","row"], order =
                    "worldabcefghijkmnpqstuvxyz"
                    <br /> Output: false
                    <br /> Explanation: As 'd' comes after 'l' in this language,
                    then words[0] &gt; words[1], hence the sequence is unsorted.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words = ["apple","app"], order =
                    "abcdefghijklmnopqrstuvwxyz"
                    <br /> Output: false
                    <br /> Explanation: The first three characters "app" match,
                    and the second string is shorter (in size.) According to
                    lexicographical rules "apple" > "app", because 'l' > '∅',
                    where '∅' is defined as the blank character which is less
                    than any other character (More info).
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= words.length &lt;= 100
                <br /> 1 &lt;= words[i].length &lt;= 20
                <br /> order.length == 26
                <br /> All characters in words[i] and order are English
                lowercase letters.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} words
                * @param {string} order
                * @return {boolean}
                */
               var isAlienSorted = function(words, order) {
                 const map = Array(26).fill(0); 
                 for (let i = 0; i < 26; ++i)
                     map[order[i].charCodeAt(0) - 97] =  String.fromCharCode(i + 97);
                   for (let i = 0; i + 1 < words.length; ++i)
                     if (bigger(words[i], words[i + 1], map))
                       return false;
                   return true; 
               };
               
               function bigger(s1, s2, map) {
                   for (let i = 0; i < s1.length && i < s2.length; ++i)
                     if (s1[i] != s2[i])
                       return map[s1[i].charCodeAt(0) - 97] > map[s2[i].charCodeAt(0) - 97];
                   return s1.length > s2.length;
                 }
               
               console.log(isAlienSorted(["hello","leetcode"],"hlabcdefgijkmnopqrstuvwxyz"))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q798: {
        title: "Q954. Array of Doubled Pairs (Q798)",
        content: (
          <Comp
            title="Q954. Array of Doubled Pairs (Q798)"
            content1={
              <>
                Given an integer array of even length arr, return true if it is
                possible to reorder arr such that arr[2 * i + 1] = 2 * arr[2 *
                i] for every 0 &lt;= i &lt; len(arr) / 2, or false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [3,1,3,6] <br />
                    Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [2,1,2,6] <br />
                    Output: false
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [4,-2,2,-4] <br />
                    Output: true <br />
                    Explanation: We can take two groups, [-2,-4] and [2,4] to
                    form [-2,-4,2,4] or [2,4,-2,-4].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= arr.length &lt;= 3 * 10^4
                <br />
                arr.length is even.
                <br />
                -10^5 &lt;= arr[i] &lt;= 10^5
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: arr = [3,1,3,6]
                class Solution {
                  public boolean canReorderDoubled(int[] A) {
                    Map<Integer, Integer> count = new HashMap<>();
                
                    for (final int a : A)
                      count.merge(a, 1, Integer::sum);
                
                    A = Arrays.stream(A)
                            .boxed()
                            .sorted((a, b) -> Math.abs(a) - Math.abs(b))
                            .mapToInt(i -> i)
                            .toArray();
                
                    for (final int a : A) {
                      if (count.get(a) == 0)
                        continue;
                      if (count.getOrDefault(2 * a, 0) == 0)
                        return false;
                      count.merge(a, -1, Integer::sum);
                      count.merge(2 * a, -1, Integer::sum);
                    }
                
                    return true;
                  }
                }
                `,
                output: `false`,
              },
            }}
          />
        ),
      },
      q799: {
        title: "Q955. Delete Columns to Make Sorted II (Q799)",
        content: (
          <Comp
            title="Q955. Delete Columns to Make Sorted II (Q799)"
            content1={
              <>
                You are given an array of n strings strs, all of the same
                length.
                <br />
                We may choose any deletion indices, and we delete all the
                characters in those indices for each string.
                <br />
                For example, if we have strs = ["abcdef","uvwxyz"] and deletion
                indices {(0, 2, 3)}, then the final array after deletions is
                ["bef", "vyz"].
                <br />
                Suppose we chose a set of deletion indices answer such that
                after deletions, the final array has its elements in
                lexicographic order (i.e., strs[0] &lt;= strs[1] &lt;= strs[2]
                &lt;= ... &lt;= strs[n - 1]). Return the minimum possible value
                of answer.length.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: strs = ["ca","bb","ac"] <br />
                    Output: 1 <br />
                    Explanation: <br />
                    After deleting the first column, strs = ["a", "b", "c"]. Now
                    strs is in lexicographic order (ie. strs[0] &lt;= strs[1]
                    &lt;= strs[2]). We require at least 1 deletion since
                    initially strs was not in lexicographic order, so the answer
                    is 1.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: strs = ["xc","yb","za"] <br />
                    Output: 0 <br />
                    Explanation: <br />
                    strs is already in lexicographic order, so we do not need to
                    delete anything. Note that the rows of strs are not
                    necessarily in lexicographic order: i.e., it is NOT
                    necessarily true that (strs[0][0] &lt;= strs[0][1] &lt;=
                    ...)
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: strs = ["zyx","wvu","tsr"] <br />
                    Output: 3 <br />
                    Explanation: We have to delete every column.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == strs.length <br />
                1 &lt;= n &lt;= 100 <br />
                1 &lt;= strs[i].length &lt;= 100 <br />
                strs[i] consists of lowercase English letters.
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} strs
                * @return {number}
                */
               var minDeletionSize = function(strs) {
                 const n = strs[0].length;
                 let ans = 0;
                 const sorted = Array(strs.length - 1).fill(0);
                 for (let j = 0; j < n; ++j) {
                   let i;
                   for (i = 0; i + 1 < strs.length; ++i)
                     if (!sorted[i] && strs[i].charAt(j) > strs[i + 1].charAt(j)) {
                         ++ans;
                         break;
                       }
                     if (i + 1 == strs.length)
                       for (i = 0; i + 1 < strs.length; ++i)
                         sorted[i] = sorted[i] || strs[i].charAt(j) < strs[i + 1].charAt(j);
                   }
                   return ans;    
               };
               
               console.log(minDeletionSize(["ca","bb","ac"]))`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q800: {
        title: "Q956. Tallest Billboard (Q800)",
        content: (
          <Comp
            title="Q956. Tallest Billboard (Q800)"
            content1={
              <>
                You are installing a billboard and want it to have the largest
                height. The billboard will have two steel supports, one on each
                side. Each steel support must be an equal height.
                <br />
                You are given a collection of rods that can be welded together.
                For example, if you have rods of lengths 1, 2, and 3, you can
                weld them together to make a support of length 6.
                <br />
                Return the largest possible height of your billboard
                installation. If you cannot support the billboard, return 0.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: rods = [1,2,3,6] Output: 6 Explanation: We have two
                    disjoint subsets {"{1,2,3} and {6}"}, which have the same
                    sum = 6.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: rods = [1,2,3,4,5,6] Output: 10 Explanation: We have
                    two disjoint subsets {"{2,3,5} and {4,6}"}, which have the
                    same sum = 10.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: rods = [1,2] Output: 0 Explanation: The billboard
                    cannot be supported, so we return 0.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= rods.length &lt;= 20 <br />
                1 &lt;= rods[i] &lt;= 1000 <br />
                sum(rods[i]) &lt;= 5000
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} rods
                * @return {number}
                */
               var tallestBillboard = function(rods) {
                 const sum = rods.reduce((a,b)=>a+b,0);
                 const dp = Array(sum + 1).fill(-1);
                 dp[0] = 0;
                 for (let h of rods) {
                     const prev = dp.slice();
                     for (let i = 0; i <= sum - h; ++i) {
                       if (prev[i] < 0)
                         continue;
                       dp[i] = Math.max(dp[i], prev[i]);
                       dp[i + h] = Math.max(dp[i + h], prev[i]);
                       dp[Math.abs(i - h)] = Math.max(dp[Math.abs(i - h)], prev[i] + Math.min(i, h));
                     }
                   }
                   return dp[0];
               };
               
               console.log(tallestBillboard([1,2,3,6]))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q801: {
        title: "Q957. Prison Cells After N Days (Q801)",
        content: (
          <Comp
            title="Q957. Prison Cells After N Days (Q801)"
            content1={
              <>
                There are 8 prison cells in a row and each cell is either
                occupied or vacant.
                <br />
                Each day, whether the cell is occupied or vacant changes
                according to the following rules:
                <br />
                If a cell has two adjacent neighbors that are both occupied or
                both vacant, then the cell becomes occupied.
                <br />
                Otherwise, it becomes vacant.
                <br />
                Note that because the prison is a row, the first and the last
                cells in the row can't have two adjacent neighbors.
                <br />
                You are given an integer array cells where cells[i] == 1 if the
                ith cell is occupied and cells[i] == 0 if the ith cell is
                vacant, and you are given an integer n.
                <br />
                Return the state of the prison after n days (i.e., n such
                changes described above).
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: cells = [0,1,0,1,1,0,0,1], n = 7 <br />
                    Output: [0,0,1,1,0,0,0,0] <br />
                    Explanation: The following table summarizes the state of the
                    prison on each day: Day 0: [0, 1, 0, 1, 1, 0, 0, 1] <br />
                    Day 1: [0, 1, 1, 0, 0, 0, 0, 0] <br />
                    Day 2: [0, 0, 0, 0, 1, 1, 1, 0] <br />
                    Day 3: [0, 1, 1, 0, 0, 1, 0, 0] <br />
                    Day 4: [0, 0, 0, 0, 0, 1, 0, 0] <br />
                    Day 5: [0, 1, 1, 1, 0, 1, 0, 0] <br />
                    Day 6: [0, 0, 1, 0, 1, 1, 0, 0] <br />
                    Day 7: [0, 0, 1, 1, 0, 0, 0, 0]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: cells = [1,0,0,1,0,0,1,0], n = 1000000000
                    <br /> Output: [0,0,1,1,1,1,1,0]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                cells.length == 8 <br />
                cells[i] is either 0 or 1. <br />1 &lt;= n &lt;= 10^9
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `
                // Input: cells = [0,1,0,1,1,0,0,1], n = 7
                class Solution {
                  public int[] prisonAfterNDays(int[] cells, int N) {
                    int[] firstDayCells = new int[cells.length];
                    int[] nextDayCells = new int[cells.length];
                    for (int day = 0; N-- > 0; cells = nextDayCells.clone(), ++day) {
                      for (int i = 1; i + 1 < cells.length; ++i)
                        nextDayCells[i] = cells[i - 1] == cells[i + 1] ? 1 : 0;
                      if (day == 0)
                        firstDayCells = nextDayCells.clone();
                      else if (Arrays.equals(nextDayCells, firstDayCells))
                        N %= day;
                    }
                    return cells;
                  }
                }`,
                output: `[0,0,1,1,0,0,0,0]`,
              },
            }}
          />
        ),
      },
      q802: {
        title: "Q958. Check Completeness of a Binary Tree (Q802)",
        content: (
          <Comp
            title="Q958. Check Completeness of a Binary Tree (Q802)"
            content1={
              <>
                Given the root of a binary tree, determine if it is a complete
                binary tree.
                <br />
                In a complete binary tree, every level, except possibly the
                last, is completely filled, and all nodes in the last level are
                as far left as possible. It can have between 1 and 2h nodes
                inclusive at the last level h.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2018/12/15/complete-binary-tree-1.png",
                content: (
                  <>
                    Input: root = [1,2,3,4,5,6] <br />
                    Output: true <br />
                    Explanation: Every level before the last is full (ie. levels
                    with node-values {"{1} and {2, 3}"}), and all nodes in the
                    last level ({"{(4, 5, 6)}"}) are as far left as possible.
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2018/12/15/complete-binary-tree-2.png",
                content: (
                  <>
                    Input: root = [1,2,3,4,5,null,7] <br />
                    Output: false <br />
                    Explanation: The node with value 7 isn't as far left as
                    possible.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 100].
                <br /> 1 &lt;= Node.val &lt;= 1000
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Java: {
                code: `/**
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode() {}
                *     TreeNode(int val) { this.val = val; }
                *     TreeNode(int val, TreeNode left, TreeNode right) {
                *         this.val = val;
                *         this.left = left;
                *         this.right = right;
                *     }
                * }
                */
               // Input: root = [1,2,3,4,5,6]
               class Solution {
                 public boolean isCompleteTree(TreeNode root) {
                   final int nNodes = count(root);
                   return isValidIndex(root, 1, nNodes);
                 }
                 private int count(TreeNode root) {
                   if (root == null)
                     return 0;
                   return 1 + count(root.left) + count(root.right);
                 }
                 private boolean isValidIndex(TreeNode root, int i, int nNodes) {
                   if (root == null)
                     return true;
                   if (i > nNodes)
                     return false;
                   return isValidIndex(root.left, i * 2, nNodes) &&
                          isValidIndex(root.right, i * 2 + 1, nNodes);
                 }
               }`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q803: {
        title: "Q959. Regions Cut By Slashes (Q803)",
        content: (
          <Comp
            title="Q959. Regions Cut By Slashes (Q803)"
            content1={
              <>
                An n x n grid is composed of 1 x 1 squares where each 1 x 1
                square consists of a '/', '\', or blank space ' '. These
                characters divide the square into contiguous regions.
                <br />
                Given the grid grid represented as a string array, return the
                number of regions.
                <br />
                Note that backslash characters are escaped, so a '\' is
                represented as '\\'.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2018/12/15/1.png",
                content: (
                  <>
                    Input: grid = [" /","/ "] <br />
                    Output: 2
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2018/12/15/2.png",
                content: (
                  <>
                    Input: grid = [" /"," "]] <br />
                    Output: 1
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2018/12/15/4.png",
                content: (
                  <>
                    Input: grid = ["/\\","\\/"] <br />
                    Output: 5 <br />
                    Explanation: Recall that because \ characters are escaped,
                    "\\/" refers to \/, and "/\\" refers to /\.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == grid.length == grid[i].length
                <br />
                1 &lt;= n &lt;= 30
                <br />
                grid[i][j] is either '/', '\', or '
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} grid
                * @return {number}
                */
               var regionsBySlashes = function(grid) {
                 const n = grid.length;
                 const g = Array.from(Array(n * 3),()=>Array(n * 3).fill(0));
                   for (let i = 0; i < n; ++i)
                     for (let j = 0; j < n; ++j)
                       if (grid[i].charAt(j) == '/') {
                         g[i * 3][j * 3 + 2] = 1;
                         g[i * 3 + 1][j * 3 + 1] = 1;
                         g[i * 3 + 2][j * 3] = 1;
                       } else if (grid[i].charAt(j) == '\\') {
                         g[i * 3][j * 3] = 1;
                         g[i * 3 + 1][j * 3 + 1] = 1;
                         g[i * 3 + 2][j * 3 + 2] = 1;
                       }
                   let ans = 0;
                   for (let i = 0; i < n * 3; ++i)
                     for (let j = 0; j < n * 3; ++j)
                       if (g[i][j] == 0) {
                         dfs(g, i, j);
                         ++ans;
                       }
                   return ans;    
               };
               
               function dfs(g, i, j) {
                   if (i < 0 || i == g.length || j < 0 || j == g[0].length)
                     return;
                   if (g[i][j] != 0)
                     return;
                   g[i][j] = 2; 
                   dfs(g, i + 1, j);
                   dfs(g, i - 1, j);
                   dfs(g, i, j + 1);
                   dfs(g, i, j - 1);
               }
               
               console.log(regionsBySlashes([" /","/ "]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q804: {
        title: "Q960. Delete Columns to Make Sorted III (Q804)",
        content: (
          <Comp
            title="Q960. Delete Columns to Make Sorted III (Q804)"
            content1={
              <>
                You are given an array of n strings strs, all of the same
                length.
                <br />
                We may choose any deletion indices, and we delete all the
                characters in those indices for each string.
                <br />
                For example, if we have strs = ["abcdef","uvwxyz"] and deletion
                indices {"{0, 2, 3}"}, then the final array after deletions is
                ["bef", "vyz"].
                <br />
                Suppose we chose a set of deletion indices answer such that
                after deletions, the final array has every string (row) in
                lexicographic order. (i.e., (strs[0][0] &lt;= strs[0][1] &lt;=
                ... &lt;= strs[0][strs[0].length - 1]), and (strs[1][0] &lt;=
                strs[1][1] &lt;= ... &lt;= strs[1][strs[1].length - 1]), and so
                on). Return the minimum possible value of answer.length.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: strs = ["babca","bbazb"] <br />
                    Output: 3 <br />
                    Explanation: After deleting columns 0, 1, and 4, the final
                    array is strs = ["bc", "az"]. Both these rows are
                    individually in lexicographic order (ie. strs[0][0] &lt;=
                    strs[0][1] and strs[1][0] &lt;= strs[1][1]). Note that
                    strs[0] &gt; strs[1] - the array strs is not necessarily in
                    lexicographic order.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: strs = ["edcba"] <br />
                    Output: 4 <br />
                    Explanation: If we delete less than 4 columns, the only row
                    will not be lexicographically sorted.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: strs = ["ghi","def","abc"] <br />
                    Output: 0 <br />
                    Explanation: All rows are already lexicographically sorted.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == strs.length <br />
                1 &lt;= n &lt;= 100 <br />
                1 &lt;= strs[i].length &lt;= 100 <br />
                strs[i] consists of lowercase English letters.
              </>
            }
            tc="n^3"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string[]} strs
                * @return {number}
                */
               var minDeletionSize = function(strs) {
                 const n = strs[0].length;
                 const dp = Array(n).fill(1);
                 for (let i = 1; i < n; ++i)
                 for (let j = 0; j < i; ++j)
                   if (isSorted(strs, j, i))
                     dp[i] = Math.max(dp[i], dp[j] + 1);
                   return n - Math.max(...dp)  
               };
               
               function isSorted(A, j, i) {
                   for (let a of A)
                     if (a.charAt(j) > a.charAt(i))
                       return false;
                   return true;
               }
               
               console.log(minDeletionSize(["babca","bbazb"]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q805: {
        title: "Q961. N-Repeated Element in Size 2N Array (Q805)",
        content: (
          <Comp
            title="Q961. N-Repeated Element in Size 2N Array (Q805)"
            content1={
              <>
                You are given an integer array nums with the following
                properties:
                <br />
                nums.length == 2 * n.
                <br />
                nums contains n + 1 unique elements.
                <br />
                Exactly one element of nums is repeated n times.
                <br />
                Return the element that is repeated n times.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,3,3]
                    <br />
                    Output: 3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2,1,2,5,3,2]
                    <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [5,1,5,2,5,3,5,4]
                    <br />
                    Output: 5
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= n &lt;= 5000
                <br />
                nums.length == 2 * n<br />
                0 &lt;= nums[i] &lt;= 10^4
                <br />
                nums contains n + 1 unique elements and one of them is repeated
                exactly n times.
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var repeatedNTimes = function(nums) {
                for (let i = 0; i + 2 < nums.length; ++i)
                     if (nums[i] == nums[i + 1] || nums[i] == nums[i + 2])
                   return nums[i];
                 return nums[nums.length - 1];   
               };
               
               console.log(repeatedNTimes([1,2,3,3]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q806: {
        title: "Q962. Maximum Width Ramp (Q806)",
        content: (
          <Comp
            title="Q962. Maximum Width Ramp (Q806)"
            content1={
              <>
                A ramp in an integer array nums is a pair (i, j) for which i
                &lt; j and nums[i] &lt;= nums[j]. The width of such a ramp is j
                - i.
                <br />
                Given an integer array nums, return the maximum width of a ramp
                in nums. If there is no ramp in nums, return 0.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [6,0,8,2,1,5] <br />
                    Output: 4 <br />
                    Explanation: The maximum width ramp is achieved at (i, j) =
                    (1, 5): nums[1] = 0 and nums[5] = 5.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [9,8,1,0,1,9,4,0,4,1] <br />
                    Output: 7 <br />
                    Explanation: The maximum width ramp is achieved at (i, j) =
                    (2, 9): nums[2] = 1 and nums[9] = 1.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= nums.length &lt;= 5 * 10^4 <br />0 &lt;= nums[i] &lt;= 5
                * 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `
                // Input: nums = [6,0,8,2,1,5]
                class Solution {
                  public int maxWidthRamp(int[] nums) {
                    int ans = 0;
                    Deque<Integer> stack = new ArrayDeque<>();
                    for (int i = 0; i < nums.length; ++i)
                      if (stack.isEmpty() || nums[i] < nums[stack.peek()])
                        stack.push(i);
                    for (int i = nums.length - 1; i > ans; --i)
                      while (!stack.isEmpty() && nums[i] >= nums[stack.peek()])
                        ans = Math.max(ans, i - stack.pop());
                    return ans;
                  }
                }
                `,
                output: `4`,
              },
            }}
          />
        ),
      },
      q807: {
        title: "Q963. Minimum Area Rectangle II (Q807)",
        content: (
          <Comp
            title="Q963. Minimum Area Rectangle II (Q807)"
            content1={
              <>
                You are given an array of points in the X-Y plane points where
                points[i] = [xi, yi].
                <br />
                Return the minimum area of any rectangle formed from these
                points, with sides not necessarily parallel to the X and Y axes.
                If there is not any such rectangle, return 0.
                <br />
                Answers within 10-5 of the actual answer will be accepted.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2018/12/21/1a.png",
                content: (
                  <>
                    Input: points = [[1,2],[2,1],[1,0],[0,1]] <br />
                    Output: 2.00000
                    <br />
                    Explanation: The minimum area rectangle occurs at
                    [1,2],[2,1],[1,0],[0,1], with an area of 2.
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2018/12/22/2.png",
                content: (
                  <>
                    Input: points = [[0,1],[2,1],[1,1],[1,0],[2,0]]
                    <br />
                    Output: 1.00000
                    <br />
                    Explanation: The minimum area rectangle occurs at
                    [1,0],[1,1],[2,1],[2,0], with an area of 1.
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2018/12/22/3.png",
                content: (
                  <>
                    Input: points = [[0,3],[1,2],[3,1],[1,3],[2,1]]
                    <br />
                    Output: 0<br />
                    Explanation: There is no possible rectangle to form from
                    these points.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= points.length &lt;= 50
                <br />
                points[i].length == 2<br />
                0 &lt;= xi, yi &lt;= 4 * 10^4
                <br />
                All the given points are unique.
              </>
            }
            tc="n^2"
            sc="n^2"
            codes={{
              Java: {
                code: `// Input: points = [[1,2],[2,1],[1,0],[0,1]]
                class Solution {
                  public double minAreaFreeRect(int[][] points) {
                    Long ans = Long.MAX_VALUE;
                    Map<Integer, List<int[]>> centerToPoints = new HashMap<>();
                
                    for (int[] A : points)
                      for (int[] B : points) {
                        int center = hash(A, B);
                        if (centerToPoints.get(center) == null)
                          centerToPoints.put(center, new ArrayList<>());
                        centerToPoints.get(center).add(new int[] {A[0], A[1], B[0], B[1]});
                      }
                
                    for (List<int[]> pointPairs : centerToPoints.values())
                      for (int[] ab : pointPairs)
                        for (int[] cd : pointPairs) {
                          final int ax = ab[0], ay = ab[1];
                          final int cx = cd[0], cy = cd[1];
                          final int dx = cd[2], dy = cd[3];
                          if ((cx - ax) * (dx - ax) + (cy - ay) * (dy - ay) == 0) {
                            Long squaredArea = dist(ax, ay, cx, cy) * dist(ax, ay, dx, dy);
                            if (squaredArea > 0)
                              ans = Math.min(ans, squaredArea);
                          }
                        }
                    return ans == Long.MAX_VALUE ? 0 : Math.sqrt(ans);
                  }
                
                  private int hash(int[] p, int[] q) {
                    return ((p[0] + q[0]) << 16) + (p[1] + q[1]);
                  }
                
                  private Long dist(long px, long py, long qx, long qy) {
                    return (px - qx) * (px - qx) + (py - qy) * (py - qy);
                  }
                }`,
                output: `2.00000`,
              },
            }}
          />
        ),
      },
      q808: {
        title: "Q964. Least Operators to Express Number (Q808)",
        content: (
          <Comp
            title="Q964. Least Operators to Express Number (Q808)"
            content1={
              <>
                Given a single positive integer x, we will write an expression
                of the form x (op1) x (op2) x (op3) x ... where each operator
                op1, op2, etc. is either addition, subtraction, multiplication,
                or division (+, -, *, or /). For example, with x = 3, we might
                write 3 * 3 / 3 + 3 - 3 which is a value of 3.
                <br />
                When writing such an expression, we adhere to the following
                conventions:
                <br />
                The division operator (/) returns rational numbers.
                <br />
                There are no parentheses placed anywhere.
                <br />
                We use the usual order of operations: multiplication and
                division happen before addition and subtraction.
                <br />
                It is not allowed to use the unary negation operator (-). For
                example, "x - x" is a valid expression as it only uses
                subtraction, but "-x + x" is not because it uses negation.
                <br />
                We would like to write an expression with the least number of
                operators such that the expression equals the given target.
                Return the least number of operators used.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: x = 3, target = 19 <br />
                    Output: 5 <br />
                    Explanation: 3 * 3 + 3 * 3 + 3 / 3. <br />
                    The expression contains 5 operations.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: x = 5, target = 501 <br />
                    Output: 8 <br />
                    Explanation: 5 * 5 * 5 * 5 - 5 * 5 * 5 + 5 / 5. <br />
                    The expression contains 8 operations.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: x = 100, target = 100000000 <br />
                    Output: 3 <br />
                    Explanation: 100 * 100 * 100 * 100. <br />
                    The expression contains 3 operations.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= x &lt;= 10^0 <br />1 &lt;= target &lt;= 2 * 10^8
              </>
            }
            tc="log n"
            sc="log n"
            codes={{
              Java: {
                code: `// Input: x = 3, target = 19
                class Solution {
                  public int leastOpsExpressTarget(int x, int target) {
                    return dfs(x, target);
                  }
                  private Map<Integer, Integer> memo = new HashMap<>();
                  private int dfs(int x, int target) {
                    if (memo.containsKey(target))
                      return memo.get(target);
                    if (x > target)
                      return Math.min(2 * target - 1, 2 * (x - target));
                    if (x == target)
                      return 0;
                    long prod = x;
                    int n = 0;
                    while (prod < target) {
                      prod *= x;
                      ++n;
                    }
                    if (prod == target) {
                      memo.put(target, n);
                      return memo.get(target);
                    }
                    int ans = dfs(x, target - (int) (prod / (long) x)) + n;
                    if (prod < 2 * target)
                      ans = Math.min(ans, dfs(x, (int) (prod - (long) target)) + n + 1);
                    memo.put(target, ans);
                    return ans;
                  }
                }
                `,
                output: `5`,
              },
            }}
          />
        ),
      },
      q809: {
        title: "Q965. Univalued Binary Tree (Q809)",
        content: (
          <Comp
            title="Q965. Univalued Binary Tree (Q809)"
            content1={
              <>
                A binary tree is uni-valued if every node in the tree has the
                same value.
                <br />
                Given the root of a binary tree, return true if the given tree
                is uni-valued, or false otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2018/12/28/unival_bst_1.png",
                content: (
                  <>
                    Input: root = [1,1,1,1,1,null,1]
                    <br />
                    Output: true
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2018/12/28/unival_bst_2.png",
                content: (
                  <>
                    Input: root = [2,2,2,5,2] <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 100].
                <br /> 0 &lt;= Node.val &lt; 100
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `function TreeNode(val, left, right) {
                  this.val = (val=== undefined ? 0 : val)
                  this.left = (left=== undefined ? null : left)
                  this.right = (right=== undefined ? null : right)
                }
                
                /**
                 * @param {TreeNode} root
                 * @return {boolean}
                 */
                var isUnivalTree = function(root) {
                  let val = root.val;
                  return treeTraverse(root, val);   
                };
                
                function treeTraverse(root, val){
                    if(root == null){
                        return true;
                    }
                
                    let left = treeTraverse(root.left, val);
                    let right = treeTraverse(root.right, val);
                    return root.val == val && left && right;
                }
                
                const t = new TreeNode(1)
                t.left = new TreeNode(1)
                t.left.left = new TreeNode(1)
                t.left.right = new TreeNode(1)
                t.right = new TreeNode(1)
                t.right.right = new TreeNode(1)
                console.log(isUnivalTree(t))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q810: {
        title: "Q966. Vowel Spellchecker (Q810)",
        content: (
          <Comp
            title="Q966. Vowel Spellchecker (Q810)"
            content1={
              <>
                Given a wordlist, we want to implement a spellchecker that
                converts a query word into a correct word.
                <br />
                For a given query word, the spell checker handles two categories
                of spelling mistakes:
                <br />
                Capitalization: If the query matches a word in the wordlist
                (case-insensitive), then the query word is returned with the
                same case as the case in the wordlist.
                <br />
                Example: wordlist = ["yellow"], query = "YellOw": correct =
                "yellow"
                <br />
                Example: wordlist = ["Yellow"], query = "yellow": correct =
                "Yellow"
                <br />
                Example: wordlist = ["yellow"], query = "yellow": correct =
                "yellow"
                <br />
                Vowel Errors: If after replacing the vowels ('a', 'e', 'i', 'o',
                'u') of the query word with any vowel individually, it matches a
                word in the wordlist (case-insensitive), then the query word is
                returned with the same case as the match in the wordlist.
                <br />
                Example: wordlist = ["YellOw"], query = "yollow": correct =
                "YellOw"
                <br />
                Example: wordlist = ["YellOw"], query = "yeellow": correct = ""
                (no match)
                <br />
                Example: wordlist = ["YellOw"], query = "yllw": correct = "" (no
                match) In addition, the spell checker operates under the
                following precedence rules:
                <br />
                When the query exactly matches a word in the wordlist
                (case-sensitive), you should return the same word back.
                <br />
                When the query matches a word up to capitlization, you should
                return the first such match in the wordlist.
                <br />
                When the query matches a word up to vowel errors, you should
                return the first such match in the wordlist.
                <br />
                If the query has no matches in the wordlist, you should return
                the empty string.
                <br />
                Given some queries, return a list of words answer, where
                answer[i] is the correct word for query = queries[i].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: wordlist = ["KiTe","kite","hare","Hare"], queries =
                    ["kite","Kite","KiTe","Hare","HARE","Hear","hear","keti","keet","keto"]
                    <br /> Output:
                    ["kite","KiTe","KiTe","Hare","hare","","","KiTe","","KiTe"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: wordlist = ["yellow"], queries = ["YellOw"] <br />
                    Output: ["yellow"]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= wordlist.length, queries.length &lt;= 5000 <br />
                1 &lt;= wordlist[i].length, queries[i].length &lt;= 7 <br />
                wordlist[i] and queries[i] consist only of only English letters.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: wordlist = ["KiTe","kite","hare","Hare"], queries = ["kite","Kite","KiTe","Hare","HARE","Hear","hear","keti","keet","keto"]
                class Solution {
                  public String[] spellchecker(String[] wordlist, String[] queries) {
                    List<String> ans = new ArrayList<>();
                    Map<String, String> dict = new HashMap<>();
                
                    for (final String word : wordlist) {
                      dict.putIfAbsent(word, word);
                      dict.putIfAbsent(lowerKey(word), word);
                      dict.putIfAbsent(vowelKey(word), word);
                    }
                
                    for (final String q : queries)
                      if (dict.containsKey(q))
                        ans.add(dict.get(q));
                      else if (dict.containsKey(lowerKey(q)))
                        ans.add(dict.get(lowerKey(q)));
                      else if (dict.containsKey(vowelKey(q)))
                        ans.add(dict.get(vowelKey(q)));
                      else
                        ans.add("");
                
                    return ans.toArray(new String[0]);
                  }
                
                  private String lowerKey(final String word) {
                    return "$" + word.toLowerCase();
                  }
                
                  private String vowelKey(final String word) {
                    String s = "";
                    for (char c : word.toCharArray())
                      s += isVowel(c) ? '*' : Character.toLowerCase(c);
                    return s;
                  }
                
                  private boolean isVowel(char c) {
                    c = Character.toLowerCase(c);
                    return c == 'a' || c == 'e' || c == 'i' || c == 'o' || c == 'u';
                  }
                }`,
                output: `["kite","KiTe","KiTe","Hare","hare","","","KiTe","","KiTe"]`,
              },
            }}
          />
        ),
      },
      q811: {
        title: "Q967. Numbers With Same Consecutive Differences (Q811)",
        content: (
          <Comp
            title="Q967. Numbers With Same Consecutive Differences (Q811)"
            content1={
              <>
                Return all non-negative integers of length n such that the
                absolute difference between every two consecutive digits is k.
                <br />
                Note that every number in the answer must not have leading
                zeros. For example, 01 has one leading zero and is invalid.
                <br />
                You may return the answer in any order.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 3, k = 7 <br />
                    Output: [181,292,707,818,929] <br />
                    Explanation: Note that 070 is not a valid number, because it
                    has leading zeroes.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 2, k = 1 <br />
                    Output: [10,12,21,23,32,34,43,45,54,56,65,67,76,78,87,89,98]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= n &lt;= 9<br />0 &lt;= k &lt;= 9
              </>
            }
            tc="n.2^n"
            sc="2^n"
            codes={{
              Java: {
                code: `// Input: n = 3, k = 7
                class Solution {
                  public int[] numsSameConsecDiff(int n, int k) {
                    if (n == 1)
                      return new int[] {0, 1, 2, 3, 4, 5, 6, 7, 8, 9};
                    List<Integer> ans = new ArrayList<>();
                    if (k == 0) {
                      for (char c = '1'; c <= '9'; ++c) {
                        final String s = String.valueOf(c).repeat(n);
                        ans.add(Integer.parseInt(s));
                      }
                      return ans.stream().mapToInt(i -> i).toArray();
                    }
                    for (int num = 1; num <= 9; ++num)
                      dfs(n - 1, k, num, ans);
                
                    return ans.stream().mapToInt(i -> i).toArray();
                  }
                  private void dfs(int n, int k, int num, List<Integer> ans) {
                    if (n == 0) {
                      ans.add(num);
                      return;
                    }
                    final int lastDigit = num % 10;
                    for (final int nextDigit : new int[] {lastDigit - k, lastDigit + k})
                      if (0 <= nextDigit && nextDigit <= 9)
                        dfs(n - 1, k, num * 10 + nextDigit, ans);
                  }
                }`,
                output: `[181,292,707,818,929]`,
              },
            }}
          />
        ),
      },
      q812: {
        title: "Q968. Binary Tree Cameras (Q812)",
        content: (
          <Comp
            title="Q968. Binary Tree Cameras (Q812)"
            content1={
              <>
                You are given the root of a binary tree. We install cameras on
                the tree nodes where each camera at a node can monitor its
                parent, itself, and its immediate children.
                <br />
                Return the minimum number of cameras needed to monitor all nodes
                of the tree.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2018/12/29/bst_cameras_01.png",
                content: (
                  <>
                    Input: root = [0,0,null,0,0]
                    <br />
                    Output: 1<br />
                    Explanation: One camera is enough to monitor all nodes if
                    placed as shown.
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2018/12/29/bst_cameras_02.png",
                content: (
                  <>
                    Input: root = [0,0,null,0,null,0,null,null,0]
                    <br />
                    Output: 2<br />
                    Explanation: At least two cameras are needed to monitor all
                    nodes of the tree. The above image shows one of the valid
                    configurations of camera placement.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 1000].
                <br />
                Node.val == 0
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Java: {
                code: `// Input: root = [0,0,null,0,null,0,null,null,0]
                /**
                 * Definition for a binary tree node.
                 * public class TreeNode {
                 *     int val;
                 *     TreeNode left;
                 *     TreeNode right;
                 *     TreeNode() {}
                 *     TreeNode(int val) { this.val = val; }
                 *     TreeNode(int val, TreeNode left, TreeNode right) {
                 *         this.val = val;
                 *         this.left = left;
                 *         this.right = right;
                 *     }
                 * }
                 */
                class Solution {
                  public int minCameraCover(TreeNode root) {
                    int[] ans = dfs(root);
                    return Math.min(ans[1], ans[2]);
                  }
                  private int[] dfs(TreeNode root) {
                    if (root == null)
                      return new int[] {0, 0, 1000};
                    int[] l = dfs(root.left);
                    int[] r = dfs(root.right);
                    final int s0 = l[1] + r[1];
                    final int s1 = Math.min(l[2] + Math.min(r[1], r[2]),
                                            r[2] + Math.min(l[1], l[2]));
                    final int s2 = 1 + Math.min(l[0], Math.min(l[1], l[2])) +
                                       Math.min(r[0], Math.min(r[1], r[2]));
                    return new int[] { s0, s1, s2 };
                  }
                }`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q813: {
        title: "Q969. Pancake Sorting (Q813)",
        content: (
          <Comp
            title="Q969. Pancake Sorting (Q813)"
            content1={
              <>
                Given an array of integers arr, sort the array by performing a
                series of pancake flips.
                <br />
                In one pancake flip we do the following steps:
                <br />
                Choose an integer k where 1 &lt;= k &lt;= arr.length.
                <br />
                Reverse the sub-array arr[0...k-1] (0-indexed).
                <br />
                For example, if arr = [3,2,1,4] and we performed a pancake flip
                choosing k = 3, we reverse the sub-array [3,2,1], so arr =
                [1,2,3,4] after the pancake flip at k = 3.
                <br />
                Return an array of the k-values corresponding to a sequence of
                pancake flips that sort arr. Any valid answer that sorts the
                array within 10 * arr.length flips will be judged as correct.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [3,2,4,1] <br />
                    Output: [4,2,4,3] <br />
                    Explanation: <br />
                    We perform 4 pancake flips, with k values 4, 2, 4, and 3.{" "}
                    <br />
                    Starting state: arr = [3, 2, 4, 1] <br />
                    After 1st flip (k = 4): arr = [1, 4, 2, 3] <br />
                    After 2nd flip (k = 2): arr = [4, 1, 2, 3] <br />
                    After 3rd flip (k = 4): arr = [3, 2, 1, 4] <br />
                    After 4th flip (k = 3): arr = [1, 2, 3, 4], which is sorted.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [1,2,3] <br />
                    Output: [] <br />
                    Explanation: The input is already sorted, so there is no
                    need to flip anything.
                    <br /> Note that other answers, such as [3, 3], would also
                    be accepted.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= arr.length &lt;= 100 <br />
                1 &lt;= arr[i] &lt;= arr.length <br />
                All integers in arr are unique (i.e. arr is a permutation of the
                integers from 1 to arr.length).
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: arr = [3,2,4,1]
                class Solution {
                  public List<Integer> pancakeSort(int[] A) {
                    List<Integer> ans = new ArrayList<>();
                    for (int target = A.length; target >= 1; --target) {
                      int index = find(A, target);
                      reverse(A, 0, index);
                      reverse(A, 0, target - 1);
                      ans.add(index + 1);
                      ans.add(target);
                    }
                    return ans;
                  }
                  private int find(int[] A, int target) {
                    for (int i = 0; i < A.length; ++i)
                      if (A[i] == target)
                        return i;
                    throw new IllegalArgumentException();
                  }
                  private void reverse(int[] A, int l, int r) {
                    while (l < r)
                      swap(A, l++, r--);
                  }
                  private void swap(int[] A, int l, int r) {
                    int temp = A[l];
                    A[l] = A[r];
                    A[r] = temp;
                  }
                }
                `,
                output: `[4,2,4,3]`,
              },
            }}
          />
        ),
      },
      q814: {
        title: "Q970. Powerful Integers (Q814)",
        content: (
          <Comp
            title="Q970. Powerful Integers (Q814)"
            content1={
              <>
                Given three integers x, y, and bound, return a list of all the
                powerful integers that have a value less than or equal to bound.
                <br />
                An integer is powerful if it can be represented as xi + yj for
                some integers i &gt;= 0 and j &gt;= 0.
                <br />
                You may return the answer in any order. In your answer, each
                value should occur at most once.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: x = 2, y = 3, bound = 10 <br />
                    Output: [2,3,4,5,7,9,10] <br />
                    Explanation: <br />
                    2 = 20 + 3^0 <br />
                    3 = 21 + 3^0 <br />
                    4 = 20 + 3^1 <br />
                    5 = 21 + 3^1 <br />
                    7 = 22 + 3^1 <br />
                    9 = 23 + 3^0 <br />
                    10 = 20 + 3^2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: x = 3, y = 5, bound = 15 <br />
                    Output: [2,4,6,8,10,14]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= x, y &lt;= 100 <br />0 &lt;= bound &lt;= 10^6
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} x
                * @param {number} y
                * @param {number} bound
                * @return {number[]}
                */
               var powerfulIntegers = function(x, y, bound) {
                 const ans = new Set();
                 for (let i = 1; i < bound; i *= x) {
                   for (let j = 1; i + j <= bound; j *= y) {
                       ans.add(i + j);
                       if (y == 1)
                         break;
                     }
                     if (x == 1)
                       break;
                   }
                   return Array.from(ans);   
               };
               
               console.log(powerfulIntegers(3,5,15))`,
                output: `[ 2, 6, 4, 8, 10, 14 ]`,
              },
            }}
          />
        ),
      },
      q815: {
        title: "Q971. Flip Binary Tree To Match Preorder Traversal (Q815)",
        content: (
          <Comp
            title="Q971. Flip Binary Tree To Match Preorder Traversal (Q815)"
            content1={
              <>
                You are given the root of a binary tree with n nodes, where each
                node is uniquely assigned a value from 1 to n. You are also
                given a sequence of n values voyage, which is the desired
                pre-order traversal of the binary tree.
                <br />
                Any node in the binary tree can be flipped by swapping its left
                and right subtrees. For example, flipping node 1 will have the
                following effect:
              </>
            }
            img="https://assets.leetcode.com/uploads/2021/02/15/fliptree.jpg"
            content2={
              <>
                Flip the smallest number of nodes so that the pre-order
                traversal of the tree matches voyage.
                <br />
                Return a list of the values of all flipped nodes. You may return
                the answer in any order. If it is impossible to flip the nodes
                in the tree to make the pre-order traversal match voyage, return
                the list [-1].
              </>
            }
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2019/01/02/1219-01.png",
                content: (
                  <>
                    Input: root = [1,2], voyage = [2,1] <br />
                    Output: [-1] <br />
                    Explanation: It is impossible to flip the nodes such that
                    the pre-order traversal matches voyage.
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2019/01/02/1219-02.png",
                content: (
                  <>
                    Input: root = [1,2,3], voyage = [1,3,2]
                    <br />
                    Output: [1]
                    <br />
                    Explanation: Flipping node 1 swaps nodes 2 and 3, so the
                    pre-order traversal matches voyage.
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2019/01/02/1219-02.png",
                content: (
                  <>
                    Input: root = [1,2,3], voyage = [1,2,3] <br />
                    Output: [] <br />
                    Explanation: The tree's pre-order traversal already matches
                    voyage, so no nodes need to be flipped.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is n.
                <br />
                n == voyage.length
                <br />
                1 &lt;= n &lt;= 100
                <br />
                1 &lt;= Node.val, voyage[i] &lt;= n<br />
                All the values in the tree are unique.
                <br />
                All the values in voyage are unique.
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Java: {
                code: `
                // Input: root = [1,2], voyage = [2,1]
                /**
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode() {}
                *     TreeNode(int val) { this.val = val; }
                *     TreeNode(int val, TreeNode left, TreeNode right) {
                *         this.val = val;
                *         this.left = left;
                *         this.right = right;
                *     }
                * }
                */
               
               class Solution {
                   public List<Integer> flipMatchVoyage(TreeNode root, int[] voyage) {
                       List<Integer> ans = new ArrayList<>();
                       Queue<Integer> queue = new ArrayDeque<>();
                       for (int v : voyage) queue.offer(v);
                       dfs(root, queue, ans);
                       return queue.isEmpty()? ans : List.of(-1);
                   }
               
                   private boolean dfs(TreeNode root, Queue<Integer> queue, List<Integer> ans){
                       if (root == null) return true;
                       if (root.val != queue.peek()) return false;
                       queue.poll();
               
                       if (!dfs(root.left, queue, ans)){
                           ans.add(root.val);
                           flip(root); 
                           if (!dfs(root.left, queue, ans)) return false; 
                       }
                       if (!dfs(root.right, queue, ans)){ 
                           ans.add(root.val);
                           flip(root);
                           if (dfs(root.right, queue, ans)) return false;
                       }
                       return true;
                   }
               
                   private void flip(TreeNode root){
                       TreeNode tmp = root.right;
                       root.right = root.left;
                       root.left  = tmp;
                   }
               }
               `,
                output: `[-1]`,
              },
            }}
          />
        ),
      },
      q816: {
        title: "Q972. Equal Rational Numbers (Q816)",
        content: (
          <Comp
            title="Q972. Equal Rational Numbers (Q816)"
            content1={
              <>
                Given two strings s and t, each of which represents a
                non-negative rational number, return true if and only if they
                represent the same number. The strings may use parentheses to
                denote the repeating part of the rational number.
                <br />A rational number can be represented using up to three
                parts:{" "}
                {"<IntegerPart>, <NonRepeatingPart>, and a <RepeatingPart>"}.
                The number will be represented in one of the following three
                ways:
                <br />
                {"<IntegerPart>"}
                <br />
                For example, 12, 0, and 123.
                <br />
                {"<IntegerPart><.><NonRepeatingPart>"}
                <br />
                For example, 0.5, 1., 2.12, and 123.0001.
                <br />
                {"<IntegerPart><.><NonRepeatingPart><(><RepeatingPart><)>"}
                <br />
                For example, 0.1(6), 1.(9), 123.00(1212).
                <br />
                The repeating portion of a decimal expansion is conventionally
                denoted within a pair of round brackets. For example:
                <br />
                1/6 = 0.16666666... = 0.1(6) = 0.1666(6) = 0.166(66)
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "0.(52)", t = "0.5(25)" <br />
                    Output: true <br />
                    Explanation: Because "0.(52)" represents 0.52525252..., and
                    "0.5(25)" represents 0.52525252525..... , the strings
                    represent the same number.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "0.1666(6)", t = "0.166(66)" <br />
                    Output: true
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "0.9(9)", t = "1." <br />
                    Output: true <br />
                    Explanation: "0.9(9)" represents 0.999999999... repeated
                    forever, which equals 1. [See this link for an explanation.]
                    <br /> "1." represents the number 1, which is formed
                    correctly: (IntegerPart) = "1" and (NonRepeatingPart) = "".
                  </>
                ),
              },
            ]}
            constraints={
              <>
                Each part consists only of digits. <br />
                The {"<IntegerPart>"} does not have leading zeros (except for
                the zero itself).
                <br /> 1 &lt;= {"<IntegerPart>"}.length &lt;= 4 <br />0 &lt;={" "}
                {"<NonRepeatingPart>"}.length &lt;= 4 <br />1 &lt;={" "}
                {"<RepeatingPart>"}.length &lt;= 4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: s = "0.(52)", t = "0.5(25)"
                class Solution {
                  public boolean isRationalEqual(String S, String T) {
                    return Math.abs(valueOf(S) - valueOf(T)) < 1e-9;
                  }
                
                  private double[] ratios = new double[] {1.0, 1.0 / 9, 1.0 / 99, 1.0 / 999, 1.0 / 9999};
                
                  private double valueOf(final String s) {
                    if (!s.contains("("))
                      return Double.valueOf(s);
                    double integer_nonRepeating = Double.valueOf(s.substring(0, s.indexOf('(')));
                    int nonRepeatingLength = s.indexOf('(') - s.indexOf('.') - 1;
                    int repeating = Integer.parseInt(s.substring(s.indexOf('(') + 1, s.indexOf(')')));
                    int repeatingLength = s.indexOf(')') - s.indexOf('(') - 1;
                    return integer_nonRepeating +
                        repeating * Math.pow(0.1, nonRepeatingLength) * ratios[repeatingLength];
                  }
                }
                `,
                output: `true`,
              },
            }}
          />
        ),
      },
      q817: {
        title: "Q973. K Closest Points to Origin (Q817)",
        content: (
          <Comp
            title="Q973. K Closest Points to Origin (Q817)"
            content1={
              <>
                Given an array of points where points[i] = [xi, yi] represents a
                point on the X-Y plane and an integer k, return the k closest
                points to the origin (0, 0).
                <br />
                The distance between two points on the X-Y plane is the
                Euclidean distance (i.e., √(x1 - x2)2 + (y1 - y2)2).
                <br />
                You may return the answer in any order. The answer is guaranteed
                to be unique (except for the order that it is in)
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2021/03/03/closestplane1.jpg",
                content: (
                  <>
                    Input: points = [[1,3],[-2,2]], k = 1 <br />
                    Output: [[-2,2]] <br />
                    Explanation: <br />
                    The distance between (1, 3) and the origin i <br />s
                    sqrt(10). The distance between (-2, 2) and the origin is
                    sqrt(8). <br />
                    Since sqrt(8) &lt; sqrt(10), (-2, 2) is closer to the
                    origin. <br />
                    We only want the closest k = 1 points from the origin, so
                    the answer is just [[-2,2]].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: points = [[3,3],[5,-1],[-2,4]], k = 2 <br />
                    Output: [[3,3],[-2,4]] <br />
                    Explanation: The answer [[-2,4],[3,3]] would also be
                    accepted.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= k &lt;= points.length &lt;= 10^4
                <br />
                -10^4 &lt; xi, yi &lt; 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: points = [[1,3],[-2,2]], k = 1
                class Solution {
                  public int[][] kClosest(int[][] points, int K) {
                    quickSelect(points, 0, points.length - 1, K);
                    return Arrays.copyOfRange(points, 0, K);
                  }
                  private void quickSelect(int[][] points, int l, int r, int K) {
                    final int randIndex = new Random().nextInt(r - l + 1) + l;
                    swap(points, randIndex, r);
                    final int[] pivot = points[r];
                    int nextSwapped = l;
                    for (int i = l; i < r; ++i)
                      if (squareDist(points[i]) <= squareDist(pivot))
                        swap(points, nextSwapped++, i);
                    swap(points, nextSwapped, r);
                    final int count = nextSwapped - l + 1; // # of points <= pivot
                    if (count == K)
                      return;
                    if (count > K)
                      quickSelect(points, l, nextSwapped - 1, K);
                    else
                      quickSelect(points, nextSwapped + 1, r, K - count);
                  }
                  private int squareDist(int[] p) {
                    return p[0] * p[0] + p[1] * p[1];
                  }
                  private void swap(int[][] points, int i, int j) {
                    final int[] temp = points[i];
                    points[i] = points[j];
                    points[j] = temp;
                  }
                }`,
                output: `[[-2,2]]`,
              },
            }}
          />
        ),
      },
      q818: {
        title: "Q974. Subarray Sums Divisible by K (Q818)",
        content: (
          <Comp
            title="Q974. Subarray Sums Divisible by K (Q818)"
            content1={
              <>
                Given an integer array nums and an integer k, return the number
                of non-empty subarrays that have a sum divisible by k.
                <br />A subarray is a contiguous part of an array.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [4,5,0,-2,-3,1], k = 5 <br />
                    Output: 7 <br />
                    Explanation: There are 7 subarrays with a sum divisible by k
                    = 5: [4, 5, 0, -2, -3, 1], [5], [5, 0], [5, 0, -2, -3], [0],
                    [0, -2, -3], [-2, -3]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [5], k = 9 <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 3 * 10^4 <br />
                -10^4 &lt;= nums[i] &lt;= 10^4 2 &lt;= k &lt;= 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var subarraysDivByK = function(nums, k) {
                 let ans = 0;
                 let prefix = 0;
                 const count = Array(k).fill(0);
                 count[0] = 1;
                   for (let a of nums) {
                     prefix = (prefix + a % k + k) % k;
                     ans += count[prefix];
                     ++count[prefix];
                   }
                 return ans;    
               };
               
               console.log(subarraysDivByK([4,5,0,-2,-3,1],5))`,
                output: `7`,
              },
            }}
          />
        ),
      },
      q819: {
        title: "Q975. Odd Even Jump (Q819)",
        content: (
          <Comp
            title="Q975. Odd Even Jump (Q819)"
            content1={
              <>
                You are given an integer array arr. From some starting index,
                you can make a series of jumps. The (1st, 3rd, 5th, ...) jumps
                in the series are called odd-numbered jumps, and the (2nd, 4th,
                6th, ...) jumps in the series are called even-numbered jumps.
                Note that the jumps are numbered, not the indices.
                <br />
                You may jump forward from index i to index j (with i &lt; j) in
                the following way:
                <br />
                During odd-numbered jumps (i.e., jumps 1, 3, 5, ...), you jump
                to the index j such that arr[i] &lt;= arr[j] and arr[j] is the
                smallest possible value. If there are multiple such indices j,
                you can only jump to the smallest such index j.
                <br />
                During even-numbered jumps (i.e., jumps 2, 4, 6, ...), you jump
                to the index j such that arr[i] &gt;= arr[j] and arr[j] is the
                largest possible value. If there are multiple such indices j,
                you can only jump to the smallest such index j.
                <br />
                It may be the case that for some index i, there are no legal
                jumps.
                <br />
                A starting index is good if, starting from that index, you can
                reach the end of the array (index arr.length - 1) by jumping
                some number of times (possibly 0 or more than once).
                <br />
                Return the number of good starting indices.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [10,13,12,14,15]
                    <br /> Output: 2
                    <br /> Explanation:
                    <br /> From starting index i = 0, we can make our 1st jump
                    to i = 2 (since arr[2] is the smallest among arr[1], arr[2],
                    arr[3], arr[4] that is greater or equal to arr[0]), then we
                    cannot jump any more.
                    <br /> From starting index i = 1 and i = 2, we can make our
                    1st jump to i = 3, then we cannot jump any more.
                    <br /> From starting index i = 3, we can make our 1st jump
                    to i = 4, so we have reached the end.
                    <br /> From starting index i = 4, we have reached the end
                    already.
                    <br /> In total, there are 2 different starting indices i =
                    3 and i = 4, where we can reach the end with some number of
                    <br /> jumps.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [2,3,1,1,4]
                    <br /> Output: 3
                    <br /> Explanation:
                    <br /> From starting index i = 0, we make jumps to i = 1, i
                    = 2, i = 3:
                    <br /> During our 1st jump (odd-numbered), we first jump to
                    i = 1 because arr[1] is the smallest value in [arr[1],
                    arr[2], arr[3], arr[4]] that is greater than or equal to
                    arr[0].
                    <br /> During our 2nd jump (even-numbered), we jump from i =
                    1 to i = 2 because arr[2] is the largest value in [arr[2],
                    arr[3], arr[4]] that is less than or equal to arr[1]. arr[3]
                    is also the largest value, but 2 is a smaller index, so we
                    can only jump to i = 2 and not i = 3
                    <br /> During our 3rd jump (odd-numbered), we jump from i =
                    2 to i = 3 because arr[3] is the smallest value in [arr[3],
                    arr[4]] that is greater than or equal to arr[2].
                    <br /> We can't jump from i = 3 to i = 4, so the starting
                    index i = 0 is not good.
                    <br /> In a similar manner, we can deduce that:
                    <br /> From starting index i = 1, we jump to i = 4, so we
                    reach the end.
                    <br /> From starting index i = 2, we jump to i = 3, and then
                    we can't jump anymore.
                    <br /> From starting index i = 3, we jump to i = 4, so we
                    reach the end.
                    <br /> From starting index i = 4, we are already at the end.
                    <br /> In total, there are 3 different starting indices i =
                    1, i = 3, and i = 4, where we can reach the end with some
                    <br /> number of jumps.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [5,1,3,4,2]
                    <br /> Output: 3
                    <br /> Explanation: We can reach the end from starting
                    indices 1, 2, and 4.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= arr.length &lt;= 2 * 10^4 <br />0 &lt;= arr[i] &lt; 10^5
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: arr = [10,13,12,14,15]
                class Solution {
                  public int oddEvenJumps(int[] A) {
                    final int n = A.length;
                    TreeMap<Integer, Integer> map = new TreeMap<>(); 
                    int[] inc = new int[n];
                    int[] dec = new int[n]; 
                    map.put(A[n - 1], n - 1);
                    inc[n - 1] = 1;
                    dec[n - 1] = 1;
                    for (int i = n - 2; i >= 0; --i) {
                      Map.Entry<Integer, Integer> lo = map.ceilingEntry(A[i]);
                      Map.Entry<Integer, Integer> hi = map.floorEntry(A[i]); 
                      if (lo != null)
                        inc[i] = dec[(int) lo.getValue()];
                      if (hi != null)
                        dec[i] = inc[(int) hi.getValue()];
                      map.put(A[i], i);
                    }
                    return Arrays.stream(inc).sum();
                  }
                }
                `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q820: {
        title: "Q976. Largest Perimeter Triangle (Q820)",
        content: (
          <Comp
            title="Q976. Largest Perimeter Triangle (Q820)"
            content1={
              <>
                Given an integer array nums, return the largest perimeter of a
                triangle with a non-zero area, formed from three of these
                lengths. If it is impossible to form any triangle of a non-zero
                area, return 0.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [2,1,2] <br />
                    Output: 5
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,1] <br />
                    Output: 0
                  </>
                ),
              },
            ]}
            constraints={
              <>
                3 &lt;= nums.length &lt;= 10^4 <br />1 &lt;= nums[i] &lt;= 10^6
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var largestPerimeter = function(nums) {
                 nums = nums.sort((a,b)=>a-b)
                 for (let i = nums.length - 1; i > 1; --i)
                     if (nums[i - 2] + nums[i - 1] > nums[i])
                       return nums[i - 2] + nums[i - 1] + nums[i];
                 return 0;    
               };
               console.log(largestPerimeter([2,1,2]))`,
                output: `5`,
              },
            }}
          />
        ),
      },
      q821: {
        title: "Q977. Squares of a Sorted Array (Q821)",
        content: (
          <Comp
            title="Q977. Squares of a Sorted Array (Q821)"
            content1={
              <>
                Given an integer array nums sorted in non-decreasing order,
                return an array of the squares of each number sorted in
                non-decreasing order.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [-4,-1,0,3,10] <br />
                    Output: [0,1,9,16,100]
                    <br />
                    Explanation: After squaring, the array becomes
                    [16,1,0,9,100].
                    <br />
                    After sorting, it becomes [0,1,9,16,100].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [-7,-3,2,3,11]
                    <br />
                    Output: [4,9,9,49,121]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4
                <br />
                -10^4 &lt;= nums[i] &lt;= 10^4
                <br />
                nums is sorted in non-decreasing order.
              </>
            }
            fp={
              <>
                <b>Follow up: </b>Squaring each element and sorting the new
                array is very trivial, could you find an O(n) solution using a
                different approach?
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number[]}
                */
               var sortedSquares = function(nums) {
                 const n = nums.length;
                 const ans = Array(n).fill(0);
                 let i = n - 1;
                 for (let l = 0, r = n - 1; l <= r;)
                   if (Math.abs(nums[l]) > Math.abs(nums[r]))
                     ans[i--] = nums[l] * nums[l++];
                   else
                     ans[i--] = nums[r] * nums[r--];
                 return ans;    
               };
               
               console.log(sortedSquares([-4,-1,0,3,10]))`,
                output: `[ 0, 1, 9, 16, 100 ]`,
              },
            }}
          />
        ),
      },
      q822: {
        title: "Q978. Longest Turbulent Subarray (Q822)",
        content: (
          <Comp
            title="Q978. Longest Turbulent Subarray (Q822)"
            content1={
              <>
                Given an integer array arr, return the length of a maximum size
                turbulent subarray of arr.
                <br />
                A subarray is turbulent if the comparison sign flips between
                each adjacent pair of elements in the subarray.
                <br />
                More formally, a subarray [arr[i], arr[i + 1], ..., arr[j]] of
                arr is said to be turbulent if and only if:
                <br />
                For i &lt;= k &lt; j:
                <br />
                arr[k] &gt; arr[k + 1] when k is odd, and
                <br />
                arr[k] &lt; arr[k + 1] when k is even.
                <br />
                Or, for i &lt;= k &lt; j:
                <br />
                arr[k] &gt; arr[k + 1] when k is even, and
                <br />
                arr[k] &lt; arr[k + 1] when k is odd.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: arr = [9,4,2,10,7,8,8,1,9] <br />
                    Output: 5 <br />
                    Explanation: arr[1] &gt; arr[2] &lt; arr[3] &gt; arr[4] &lt;
                    arr[5]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [4,8,12,16] <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: arr = [100] <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= arr.length &lt;= 4 * 10^4
                <br />0 &lt;= arr[i] &lt;= 10^9
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} arr
                * @return {number}
                */
               var maxTurbulenceSize = function(arr) {
                 let ans = 1;
                 let increasing = 1;
                 let decreasing = 1;
                   for (let i = 1; i < arr.length; ++i) {
                     if (arr[i] > arr[i - 1]) {
                       increasing = decreasing + 1;
                       decreasing = 1;
                     } else if (arr[i] < arr[i - 1]) {
                       decreasing = increasing + 1;
                       increasing = 1;
                     } else {
                       increasing = 1;
                       decreasing = 1;
                     }
                     ans = Math.max(ans, Math.max(increasing, decreasing));
                   }
                   return ans;    
               };
               
               console.log(maxTurbulenceSize([9,4,2,10,7,8,8,1,9]
               ))`,
                output: `5`,
              },
            }}
          />
        ),
      },
      q823: {
        title: "Q979. Distribute Coins in Binary Tree (Q823)",
        content: (
          <Comp
            title="Q979. Distribute Coins in Binary Tree (Q823)"
            content1={
              <>
                You are given the root of a binary tree with n nodes where each
                node in the tree has node.val coins. There are n coins in total
                throughout the whole tree.
                <br />
                In one move, we may choose two adjacent nodes and move one coin
                from one node to another. A move may be from parent to child, or
                from child to parent.
                <br />
                Return the minimum number of moves required to make every node
                have exactly one coin.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2019/01/18/tree1.png",
                content: (
                  <>
                    Input: root = [3,0,0] <br />
                    Output: 2 <br />
                    Explanation: From the root of the tree, we move one coin to
                    its left child, and one coin to its right child.
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2019/01/18/tree2.png",
                content: (
                  <>
                    Input: root = [0,3,0] <br />
                    Output: 3 <br />
                    Explanation: From the left child of the root, we move two
                    coins to the root [taking two moves]. Then, we move one coin
                    from the root of the tree to the right child.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is n. <br />
                1 &lt;= n &lt;= 100 <br />
                0 &lt;= Node.val &lt;= n <br />
                The sum of all Node.val is n.
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Java: {
                code: `/**
                // Input: root = [3,0,0]
                * Definition for a binary tree node.
                * public class TreeNode {
                *     int val;
                *     TreeNode left;
                *     TreeNode right;
                *     TreeNode() {}
                *     TreeNode(int val) { this.val = val; }
                *     TreeNode(int val, TreeNode left, TreeNode right) {
                *         this.val = val;
                *         this.left = left;
                *         this.right = right;
                *     }
                * }
                */
               class Solution {
                 public int distributeCoins(TreeNode root) {
                   dfs(root);
                   return ans;
                 }
                 private int ans = 0;
                 private int dfs(TreeNode root) {
                   if (root == null)
                     return 0;
                   final int l = dfs(root.left);
                   final int r = dfs(root.right);
                   ans += Math.abs(l) + Math.abs(r);
                   return (root.val - 1) + l + r;
                 }
               }`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q824: {
        title: "Q980. Unique Paths III (Q824)",
        content: (
          <Comp
            title="Q980. Unique Paths III (Q824)"
            content1={
              <>
                You are given an m x n integer array grid where grid[i][j] could
                be:
                <br />
                1 representing the starting square. There is exactly one
                starting square.
                <br />
                2 representing the ending square. There is exactly one ending
                square.
                <br />
                0 representing empty squares we can walk over.
                <br />
                -1 representing obstacles that we cannot walk over.
                <br />
                Return the number of 4-directional walks from the starting
                square to the ending square, that walk over every non-obstacle
                square exactly once.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2021/08/02/lc-unique1.jpg",
                content: (
                  <>
                    Input: grid = [[1,0,0,0],[0,0,0,0],[0,0,2,-1]]
                    <br /> Output: 2 <br />
                    Explanation: We have the following two paths: <br />
                    1.
                    (0,0),(0,1),(0,2),(0,3),(1,3),(1,2),(1,1),(1,0),(2,0),(2,1),(2,2)
                    <br /> 2.
                    (0,0),(1,0),(2,0),(2,1),(1,1),(0,1),(0,2),(0,3),(1,3),(1,2),(2,2)
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2021/08/02/lc-unique2.jpg",
                content: (
                  <>
                    Input: grid = [[1,0,0,0],[0,0,0,0],[0,0,0,2]]
                    <br />
                    Output: 4<br />
                    Explanation: We have the following four paths: <br />
                    <br /> 1.
                    (0,0),(0,1),(0,2),(0,3),(1,3),(1,2),(1,1),(1,0),(2,0),(2,1),(2,2),(2,3)
                    <br /> 2.
                    (0,0),(0,1),(1,1),(1,0),(2,0),(2,1),(2,2),(1,2),(0,2),(0,3),(1,3),(2,3)
                    <br /> 3.
                    (0,0),(1,0),(2,0),(2,1),(2,2),(1,2),(1,1),(0,1),(0,2),(0,3),(1,3),(2,3)
                    <br /> 4.
                    (0,0),(1,0),(2,0),(2,1),(1,1),(0,1),(0,2),(0,3),(1,3),(1,2),(2,2),(2,3)
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2021/08/02/lc-unique3-.jpg",
                content: (
                  <>
                    Input: grid = [[0,1],[2,0]]
                    <br /> Output: 0
                    <br /> Explanation: There is no path that walks over every
                    empty square exactly once.
                    <br /> Note that the starting and ending square can be
                    anywhere in the grid.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == grid.length <br />
                n == grid[i].length <br />
                1 &lt;= m, n &lt;= 20 <br />
                1 &lt;= m * n &lt;= 20 <br />
                -1 &lt;= grid[i][j] &lt;= 2 <br />
                There is exactly one starting cell and one ending cell.
              </>
            }
            tc="3^m.n"
            sc="m.n"
            codes={{
              Java: {
                code: `// Input: grid = [[1,0,0,0],[0,0,0,0],[0,0,2,-1]]
                class Solution {
                  public int uniquePathsIII(int[][] grid) {
                    int empty = 1;
                    int sx = -1;
                    int sy = -1;
                    int ex = -1;
                    int ey = -1;
                    for (int i = 0; i < grid.length; ++i)
                      for (int j = 0; j < grid[0].length; ++j)
                        if (grid[i][j] == 0) {
                          ++empty;
                        } else if (grid[i][j] == 1) {
                          sx = i;
                          sy = j;
                        } else if (grid[i][j] == 2) {
                          ex = i;
                          ey = j;
                        }
                    dfs(grid, empty, sx, sy, ex, ey);
                    return ans;
                  }
                
                  private int ans = 0;
                
                  private void dfs(int[][] grid, int empty, int i, int j, int ex, int ey) {
                    if (i < 0 || i == grid.length || j < 0 || j == grid[0].length)
                      return;
                    if (grid[i][j] < 0)
                      return;
                    if (i == ex && j == ey) {
                      if (empty == 0)
                        ++ans;
                      return;
                    }
                    grid[i][j] = -2;
                    dfs(grid, empty - 1, i + 1, j, ex, ey);
                    dfs(grid, empty - 1, i - 1, j, ex, ey);
                    dfs(grid, empty - 1, i, j + 1, ex, ey);
                    dfs(grid, empty - 1, i, j - 1, ex, ey);
                    grid[i][j] = 0;
                  }
                }`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q825: {
        title: "Q981. Time Based Key-Value Store (Q825)",
        content: (
          <Comp
            title="Q981. Time Based Key-Value Store (Q825)"
            content1={
              <>
                Design a time-based key-value data structure that can store
                multiple values for the same key at different time stamps and
                retrieve the key's value at a certain timestamp.
                <br />
                Implement the TimeMap class:
                <br />
                TimeMap() Initializes the object of the data structure.
                <br />
                void set(String key, String value, int timestamp) Stores the key
                key with the value value at the given time timestamp.
                <br />
                String get(String key, int timestamp) Returns a value such that
                set was called previously, with timestamp_prev &lt;= timestamp.
                If there are multiple such values, it returns the value
                associated with the largest timestamp_prev. If there are no
                values, it returns "".
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input <br />
                    ["TimeMap", "set", "get", "get", "set", "get", "get"]
                    <br /> [[], ["foo", "bar", 1], ["foo", 1], ["foo", 3],
                    ["foo", "bar2", 4], ["foo", 4], ["foo", 5]] Output <br />
                    [null, null, "bar", "bar", null, "bar2", "bar2"]
                    <br />
                    Explanation <br />
                    TimeMap timeMap = new TimeMap(); <br />
                    timeMap.set("foo", "bar", 1); // store the key "foo" and
                    value "bar" along with timestamp = 1.
                    <br /> timeMap.get("foo", 1); // return "bar"
                    <br /> timeMap.get("foo", 3); // return "bar", since there
                    is no value corresponding to foo at timestamp 3 and
                    timestamp 2, then the only value is at timestamp 1 is "bar".
                    <br /> timeMap.set("foo", "bar2", 4); // store the key "foo"
                    and value "bar2" along with timestamp = 4.
                    <br /> timeMap.get("foo", 4); // return "bar2"
                    <br /> timeMap.get("foo", 5); // return "bar2"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= key.length, value.length &lt;= 100
                <br /> key and value consist of lowercase English letters and
                digits.
                <br /> 1 &lt;= timestamp &lt;= 10^7
                <br /> All the timestamps timestamp of set are strictly
                increasing.
                <br /> At most 2 * 105 calls will be made to set and get.
              </>
            }
            tc="1"
            sc="n"
            codes={{
              Java: {
                code: `// Input
                // ["TimeMap", "set", "get", "get", "set", "get", "get"]
                // [[], ["foo", "bar", 1], ["foo", 1], ["foo", 3], ["foo", "bar2", 4], ["foo", 4], ["foo", 5]]
                class T {
                  public String value;
                  public int timestamp;
                  public T(String value, int timestamp) {
                    this.value = value;
                    this.timestamp = timestamp;
                  }
                }
                
                class TimeMap {
                  public void set(String key, String value, int timestamp) {
                    map.putIfAbsent(key, new ArrayList<>());
                    map.get(key).add(new T(value, timestamp));
                  }
                
                  public String get(String key, int timestamp) {
                    List<T> A = map.get(key);
                    if (A == null)
                      return "";
                
                    int l = 0;
                    int r = A.size();
                
                    while (l < r) {
                      final int m = l + (r - l) / 2;
                      if (A.get(m).timestamp > timestamp)
                        r = m;
                      else
                        l = m + 1;
                    }
                
                    return l == 0 ? "" : A.get(l - 1).value;
                  }
                
                  private Map<String, List<T>> map = new HashMap<>();
                }`,
                output: `[null, null, "bar", "bar", null, "bar2", "bar2"]`,
              },
            }}
          />
        ),
      },
      q826: {
        title: "Q982. Triples with Bitwise AND Equal To Zero (Q826)",
        content: (
          <Comp
            title="Q982. Triples with Bitwise AND Equal To Zero (Q826)"
            content1={
              <>
                Given an integer array nums, return the number of AND triples.
                <br />
                An AND triple is a triple of indices (i, j, k) such that:
                <br />
                0 &lt;= i &lt; nums.length
                <br />
                0 &lt;= j &lt; nums.length
                <br />
                0 &lt;= k &lt; nums.length
                <br />
                nums[i] & nums[j] & nums[k] == 0, where & represents the
                bitwise-AND operator.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [2,1,3]
                    <br />
                    Output: 12
                    <br />
                    Explanation: We could choose the following i, j, k triples:
                    <br /> (i=0, j=0, k=1) : 2 & 2 & 1
                    <br /> (i=0, j=1, k=0) : 2 & 1 & 2
                    <br /> (i=0, j=1, k=1) : 2 & 1 & 1
                    <br /> (i=0, j=1, k=2) : 2 & 1 & 3
                    <br /> (i=0, j=2, k=1) : 2 & 3 & 1
                    <br /> (i=1, j=0, k=0) : 1 & 2 & 2
                    <br /> (i=1, j=0, k=1) : 1 & 2 & 1
                    <br /> (i=1, j=0, k=2) : 1 & 2 & 3
                    <br /> (i=1, j=1, k=0) : 1 & 1 & 2
                    <br /> (i=1, j=2, k=0) : 1 & 3 & 2
                    <br /> (i=2, j=0, k=1) : 3 & 2 & 1
                    <br /> (i=2, j=1, k=0) : 3 & 1 & 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [0,0,0] <br />
                    Output: 27
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 1000
                <br />0 &lt;= nums[i] &lt; 2^16
              </>
            }
            tc="n^2"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @return {number}
                */
               var countTriplets = function(nums) {
                 const kMax = 1 << 16;
                 let ans = 0;
                 const count = Array(kMax).fill(0); 
                 for (let a of nums)
                 for (let b of nums)
                   ++count[a & b];
                 for (let a of nums)
                   for (let i = 0; i < kMax; ++i)
                     if ((a & i) == 0)
                       ans += count[i];
                 return ans; 
               };
               console.log(countTriplets([2,1,3]))`,
                output: `12`,
              },
            }}
          />
        ),
      },
      q827: {
        title: "Q983. Minimum Cost For Tickets (Q827)",
        content: (
          <Comp
            title="Q983. Minimum Cost For Tickets (Q827)"
            content1={
              <>
                You have planned some train traveling one year in advance. The
                days of the year in which you will travel are given as an
                integer array days. Each day is an integer from 1 to 365.
                <br />
                Train tickets are sold in three different ways:
                <br />
                a 1-day pass is sold for costs[0] dollars,
                <br />
                a 7-day pass is sold for costs[1] dollars, and
                <br />
                a 30-day pass is sold for costs[2] dollars. The passes allow
                that many days of consecutive travel.
                <br />
                For example, if we get a 7-day pass on day 2, then we can travel
                for 7 days: 2, 3, 4, 5, 6, 7, and 8. Return the minimum number
                of dollars you need to travel every day in the given list of
                days.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: days = [1,4,6,7,8,20], costs = [2,7,15]
                    <br />
                    Output: 11
                    <br />
                    Explanation: For example, here is one way to buy passes that
                    lets you travel your travel plan:
                    <br />
                    On day 1, you bought a 1-day pass for costs[0] = $2, which
                    covered day 1.
                    <br />
                    On day 3, you bought a 7-day pass for costs[1] = $7, which
                    covered days 3, 4, ..., 9.
                    <br />
                    On day 20, you bought a 1-day pass for costs[0] = $2, which
                    covered day 20.
                    <br />
                    In total, you spent $11 and covered all the days of your
                    travel.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: days = [1,2,3,4,5,6,7,8,9,10,30,31], costs = [2,7,15]
                    <br />
                    Output: 17
                    <br />
                    Explanation: For example, here is one way to buy passes that
                    lets you travel your travel plan:
                    <br />
                    On day 1, you bought a 30-day pass for costs[2] = $15 which
                    covered days 1, 2, ..., 30.
                    <br />
                    On day 31, you bought a 1-day pass for costs[0] = $2 which
                    covered day 31.
                    <br />
                    In total, you spent $17 and covered all the days of your
                    travel.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= days.length &lt;= 365
                <br />
                1 &lt;= days[i] &lt;= 365
                <br />
                days is in strictly increasing order.
                <br />
                costs.length == 3<br />1 &lt;= costs[i] &lt;= 1000
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: days = [1,4,6,7,8,20], costs = [2,7,15]

                class Solution {
                  public int mincostTickets(int[] days, int[] costs) {
                    int ans = 0;
                    Queue<int[]> last7 = new ArrayDeque<>(); 
                    Queue<int[]> last30 = new ArrayDeque<>();
                    for (int day : days) {
                      while (!last7.isEmpty() && last7.peek()[0] + 7 <= day)
                        last7.poll();
                      while (!last30.isEmpty() && last30.peek()[0] + 30 <= day)
                        last30.poll();
                      last7.offer(new int[] {day, ans + costs[1]});
                      last30.offer(new int[] {day, ans + costs[2]});
                      ans = Math.min(ans + costs[0], Math.min(last7.peek()[1], last30.peek()[1]));
                    }
                    return ans;
                  }
                }
                `,
                output: `11`,
              },
            }}
          />
        ),
      },
      q828: {
        title: "Q984. String Without AAA or BBB (Q828)",
        content: (
          <Comp
            title="Q984. String Without AAA or BBB (Q828)"
            content1={
              <>
                Given two integers a and b, return any string s such that:
                <br />
                s has length a + b and contains exactly a 'a' letters, and
                exactly b 'b' letters, The substring 'aaa' does not occur in s,
                and
                <br />
                <br /> The substring 'bbb' does not occur in s.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: a = 1, b = 2<br />
                    Output: "abb"
                    <br />
                    Explanation: "abb", "bab" and "bba" are all correct answers.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: a = 4, b = 1<br />
                    Output: "aabaa"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= a, b &lt;= 100 <br />
                It is guaranteed such an s exists for the given a and b.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              "C++": {
                code: `// Input: a = 1, b = 2
                class Solution {
                 public:
                  string strWithout3a3b(int A, int B, char a = 'a', char b = 'b') {
                    if (A < B)
                      return strWithout3a3b(B, A, b, a);
                    if (B == 0)
                      return string(min(A, 2), a);
                
                    const int useA = min(A, 2);
                    const int useB = (A - useA >= B) ? 1 : 0;
                    return string(useA, a) + string(useB, b) +
                           strWithout3a3b(A - useA, B - useB, a, b);
                  }
                };
                `,
                output: `abb`,
              },
            }}
          />
        ),
      },
      q829: {
        title: "Q985. Sum of Even Numbers After Queries (Q829)",
        content: (
          <Comp
            title="Q985. Sum of Even Numbers After Queries (Q829)"
            content1={
              <>
                You are given an integer array nums and an array queries where
                queries[i] = [vali, indexi].
                <br />
                For each query i, first, apply nums[indexi] = nums[indexi] +
                vali, then print the sum of the even values of nums.
                <br />
                Return an integer array answer where answer[i] is the answer to
                the ith query.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,3,4], queries =
                    [[1,0],[-3,1],[-4,0],[2,3]]
                    <br /> Output: [8,6,2,4]
                    <br /> Explanation: At the beginning, the array is
                    [1,2,3,4].
                    <br /> After adding 1 to nums[0], the array is [2,2,3,4],
                    and the sum of even values is 2 + 2 + 4 = 8.
                    <br /> After adding -3 to nums[1], the array is [2,-1,3,4],
                    and the sum of even values is 2 + 4 = 6.
                    <br /> After adding -4 to nums[0], the array is [-2,-1,3,4],
                    and the sum of even values is -2 + 4 = 2.
                    <br /> After adding 2 to nums[3], the array is [-2,-1,3,6],
                    and the sum of even values is -2 + 6 = 4.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1], queries = [[4,0]]
                    <br /> Output: [0]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4
                <br />
                -10^4 &lt;= nums[i] &lt;= 10^4
                <br />
                1 &lt;= queries.length &lt;= 10^4
                <br />
                -10^4 &lt;= vali &lt;= 10^4
                <br />0 &lt;= indexi &lt; nums.length
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number[][]} queries
                * @return {number[]}
                */
               var sumEvenAfterQueries = function(nums, queries) {
                 let ans = Array(queries.length).fill(0);
                 let sum = 0;
                 for (let a of nums)
                   sum += a % 2 == 0 ? a : 0;
                 for (let i = 0; i < queries.length; ++i) {
                   if (nums[queries[i][1]] % 2 == 0)
                     sum -= nums[queries[i][1]];
                   nums[queries[i][1]] += queries[i][0];
                   if (nums[queries[i][1]] % 2 == 0)
                     sum += nums[queries[i][1]];
                   ans[i] = sum;
                 }
                 return ans;  
               };
               
               console.log(sumEvenAfterQueries([1,2,3,4],[[1,0],[-3,1],[-4,0],[2,3]]))`,
                output: `[ 8, 6, 2, 4 ]`,
              },
            }}
          />
        ),
      },
      q830: {
        title: "Q986. Interval List Intersections (Q830)",
        content: (
          <Comp
            title="Q986. Interval List Intersections (Q830)"
            content1={
              <>
                You are given two lists of closed intervals, firstList and
                secondList, where firstList[i] = [starti, endi] and
                secondList[j] = [startj, endj]. Each list of intervals is
                pairwise disjoint and in sorted order.
                <br />
                Return the intersection of these two interval lists.
                <br />
                A closed interval [a, b] (with a &lt;= b) denotes the set of
                real numbers x with a &lt;= x &lt;= b.
                <br />
                The intersection of two closed intervals is a set of real
                numbers that are either empty or represented as a closed
                interval. For example, the intersection of [1, 3] and [2, 4] is
                [2, 3].
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2019/01/30/interval1.png",
                content: (
                  <>
                    Input: firstList = [[0,2],[5,10],[13,23],[24,25]],
                    secondList = [[1,5],[8,12],[15,24],[25,26]]
                    <br /> Output: [[1,2],[5,5],[8,10],[15,23],[24,24],[25,25]]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: firstList = [[1,3],[5,9]], secondList = []
                    <br /> Output: []
                  </>
                ),
              },
            ]}
            constraints={
              <>
                0 &lt;= firstList.length, secondList.length &lt;= 1000
                firstList.length + secondList.length &gt;= 1 <br />
                0 &lt;= starti &lt; endi &lt;= 10^9 <br />
                endi &lt; starti+1 <br />
                0 &lt;= startj &lt; endj &lt;= 10^9 <br />
                endj &lt; startj+1
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[][]} firstList
                * @param {number[][]} secondList
                * @return {number[][]}
                */
               var intervalIntersection = function(firstList, secondList) {
                 const ans = [];
                 let i = 0;
                 let j = 0;
                 while (i < firstList.length && j < secondList.length) {
                   const maxL = Math.max(firstList[i][0], secondList[j][0]);
                   const minR = Math.min(firstList[i][1], secondList[j][1]);
                   if (maxL <= minR)
                     ans.push([maxL, minR]);
                   if (firstList[i][1] < secondList[j][1])
                     ++i;
                   else
                     ++j;
                 }
                 return ans
               };
               
               console.log(intervalIntersection([[0,2],[5,10],[13,23],[24,25]],[[1,5],[8,12],[15,24],[25,26]]))`,
                output: `[ [ 1, 2 ], [ 5, 5 ], [ 8, 10 ], [ 15, 23 ], [ 24, 24 ], [ 25, 25 ] ]`,
              },
            }}
          />
        ),
      },
      q831: {
        title: "Q987. Vertical Order Traversal of a Binary Tree (Q831)",
        content: (
          <Comp
            title="Q987. Vertical Order Traversal of a Binary Tree (Q831)"
            content1={
              <>
                Given the root of a binary tree, calculate the vertical order
                traversal of the binary tree.
                <br />
                For each node at position (row, col), its left and right
                children will be at positions (row + 1, col - 1) and (row + 1,
                col + 1) respectively. The root of the tree is at (0, 0).
                <br />
                The vertical order traversal of a binary tree is a list of
                top-to-bottom orderings for each column index starting from the
                leftmost column and ending on the rightmost column. There may be
                multiple nodes in the same row and same column. In such a case,
                sort these nodes by their values.
                <br />
                Return the vertical order traversal of the binary tree.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2021/01/29/vtree1.jpg",
                content: (
                  <>
                    Input: root = [3,9,20,null,null,15,7]
                    <br /> Output: [[9],[3,15],[20],[7]]
                    <br /> Explanation:
                    <br /> Column -1: Only node 9 is in this column.
                    <br /> Column 0: Nodes 3 and 15 are in this column in that
                    order from top to bottom.
                    <br /> Column 1: Only node 20 is in this column.
                    <br /> Column 2: Only node 7 is in this column.
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2021/01/29/vtree2.jpg",
                content: (
                  <>
                    Input: root = [1,2,3,4,5,6,7]
                    <br /> Output: [[4],[2],[1,5,6],[3],[7]]
                    <br /> Explanation:
                    <br /> Column -2: Only node 4 is in this column.
                    <br /> Column -1: Only node 2 is in this column.
                    <br /> Column 0: Nodes 1, 5, and 6 are in this column.
                    <br /> 1 is at the top, so it comes first.
                    <br /> 5 and 6 are at the same position (2, 0), so we order
                    them by their value, 5 before 6.
                    <br /> Column 1: Only node 3 is in this column.
                    <br /> Column 2: Only node 7 is in this column.
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2021/01/29/vtree3.jpg",
                content: (
                  <>
                    Input: root = [1,2,3,4,6,5,7]
                    <br /> Output: [[4],[2],[1,5,6],[3],[7]]
                    <br /> Explanation:
                    <br /> This case is the exact same as example 2, but with
                    nodes 5 and 6 swapped.
                    <br /> Note that the solution remains the same since 5 and 6
                    are in the same location and should be ordered by their
                    values.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 1000].
                <br /> 0 &lt;= Node.val &lt;= 1000
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: root = [3,9,20,null,null,15,7]
                /**
                 * Definition for a binary tree node.
                 * public class TreeNode {
                 *     int val;
                 *     TreeNode left;
                 *     TreeNode right;
                 *     TreeNode() {}
                 *     TreeNode(int val) { this.val = val; }
                 *     TreeNode(int val, TreeNode left, TreeNode right) {
                 *         this.val = val;
                 *         this.left = left;
                 *         this.right = right;
                 *     }
                 * }
                 */
                class Solution {
                  public List<List<Integer>> verticalTraversal(TreeNode root) {
                    List<List<Integer>> ans = new ArrayList<>();
                    TreeMap<Integer, List<int[]>> xToSortedPairs = new TreeMap<>(); 
                    dfs(root, 0, 0, xToSortedPairs);
                    for (List<int[]> pairs : xToSortedPairs.values()) {
                      Collections.sort(pairs, (a, b) -> a[0] == b[0] ? a[1] - b[1] : a[0] - b[0]);
                      List<Integer> vals = new ArrayList<>();
                      for (int[] pair : pairs)
                        vals.add(pair[1]);
                      ans.add(vals);
                    }
                    return ans;
                  }
                
                  private void dfs(TreeNode root, int x, int y, TreeMap<Integer, List<int[]>> xToSortedPairs) {
                    if (root == null)
                      return;
                    xToSortedPairs.putIfAbsent(x, new ArrayList<>());
                    xToSortedPairs.get(x).add(new int[] {y, root.val});
                    dfs(root.left, x - 1, y + 1, xToSortedPairs);
                    dfs(root.right, x + 1, y + 1, xToSortedPairs);
                  }
                }`,
                output: `[[9],[3,15],[20],[7]]`,
              },
            }}
          />
        ),
      },
      q832: {
        title: "Q988. Smallest String Starting From Leaf (Q832)",
        content: (
          <Comp
            title="Q988. Smallest String Starting From Leaf (Q832)"
            content1={
              <>
                You are given the root of a binary tree where each node has a
                value in the range [0, 25] representing the letters 'a' to 'z'.
                <br />
                Return the lexicographically smallest string that starts at a
                leaf of this tree and ends at the root.
                <br />
                As a reminder, any shorter prefix of a string is
                lexicographically smaller.
                <br />
                For example, "ab" is lexicographically smaller than "aba".
                <br /> A leaf of a node is a node that has no children.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: root = [0,1,2,3,4,3,4] <br />
                    Output: "dba"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [25,1,3,1,3,0,2] <br />
                    Output: "adz"
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: root = [2,2,1,null,1,0,null,0] <br />
                    Output: "abc"
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 8500].
                <br /> 0 &lt;= Node.val &lt;= 25
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: root = [0,1,2,3,4,3,4]
                /**
                 * Definition for a binary tree node.
                 * public class TreeNode {
                 *     int val;
                 *     TreeNode left;
                 *     TreeNode right;
                 *     TreeNode() {}
                 *     TreeNode(int val) { this.val = val; }
                 *     TreeNode(int val, TreeNode left, TreeNode right) {
                 *         this.val = val;
                 *         this.left = left;
                 *         this.right = right;
                 *     }
                 * }
                 */
                class Solution {
                  public String smallestFromLeaf(TreeNode root) {
                    dfs(root, new StringBuilder());
                    return ans;
                  }
                
                  private String ans = null;
                
                  private void dfs(TreeNode root, StringBuilder sb) {
                    if (root == null)
                      return;
                    sb.append((char) (root.val + 'a'));
                    if (root.left == null && root.right == null) {
                      final String path = sb.reverse().toString();
                      sb.reverse(); // roll back
                      if (ans == null || ans.compareTo(path) > 0)
                        ans = path;
                    }
                    dfs(root.left, sb);
                    dfs(root.right, sb);
                    sb.deleteCharAt(sb.length() - 1);
                  }
                }`,
                output: `dba`,
              },
            }}
          />
        ),
      },
      q833: {
        title: "Q989. Add to Array-Form of Integer (Q833)",
        content: (
          <Comp
            title="Q989. Add to Array-Form of Integer (Q833)"
            content1={
              <>
                The array-form of an integer num is an array representing its
                digits in left to right order.
                <br />
                For example, for num = 1321, the array form is [1,3,2,1]. Given
                num, the array-form of an integer, and an integer k, return the
                array-form of the integer num + k.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: num = [1,2,0,0], k = 34
                    <br />
                    Output: [1,2,3,4]
                    <br />
                    Explanation: 1200 + 34 = 1234
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num = [2,7,4], k = 181
                    <br />
                    Output: [4,5,5]
                    <br />
                    Explanation: 274 + 181 = 455
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: num = [2,1,5], k = 806
                    <br />
                    Output: [1,0,2,1]
                    <br />
                    Explanation: 215 + 806 = 1021
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= num.length &lt;= 10^4
                <br />
                0 &lt;= num[i] &lt;= 9<br />
                num does not contain any leading zeros except for the zero
                itself.
                <br />1 &lt;= k &lt;= 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} num
                * @param {number} k
                * @return {number[]}
                */
               var addToArrayForm = function(num, k) {
                const ans = [];
                   for (let i = num.length - 1; i >= 0; --i) {
                   ans.unshift((num[i] + k) % 10);
                   k = parseInt((num[i] + k) / 10);
                   }
                 while (k > 0) {
                   ans.unshift(k % 10);
                   k = parseInt(k/10);
                 }
                 return ans;   
               };
               
               console.log(addToArrayForm([1,2,0,0],34))`,
                output: `[ 1, 2, 3, 4 `,
              },
            }}
          />
        ),
      },
      q834: {
        title: "Q990. Satisfiability of Equality Equations (Q834)",
        content: (
          <Comp
            title="Q990. Satisfiability of Equality Equations (Q834)"
            content1={
              <>
                You are given an array of strings equations that represent
                relationships between variables where each string equations[i]
                is of length 4 and takes one of two different forms: "xi==yi" or
                "xi!=yi".Here, xi and yi are lowercase letters (not necessarily
                different) that represent one-letter variable names.
                <br />
                Return true if it is possible to assign integers to variable
                names so as to satisfy all the given equations, or false
                otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: equations = ["a==b","b!=a"]
                    <br />
                    Output: false
                    <br />
                    Explanation: If we assign say, a = 1 and b = 1, then the
                    first equation is satisfied, but not the second. There is no
                    way to assign the variables to satisfy both equations.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: equations = ["b==a","a==b"]
                    <br />
                    Output: true
                    <br />
                    Explanation: We could assign a = 1 and b = 1 to satisfy both
                    equations.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= equations.length &lt;= 500
                <br />
                equations[i].length == 4
                <br />
                equations[i][0] is a lowercase letter.
                <br />
                equations[i][1] is either '=' or '!'.
                <br />
                equations[i][2] is '='.
                <br />
                equations[i][3] is a lowercase letter.
              </>
            }
            tc="n.log n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: equations = ["a==b","b!=a"]
                class UF {
                  public int[] id;
                
                  public UF(int n) {
                    id = new int[n];
                    for (int i = 0; i < n; ++i)
                      id[i] = i;
                  }
                
                  public void union(int u, int v) {
                    id[find(u)] = find(v);
                  }
                
                  public int find(int u) {
                    return id[u] == u ? u : (id[u] = find(id[u]));
                  }
                }
                class Solution {
                  public boolean equationsPossible(String[] equations) {
                    UF uf = new UF(26);
                    for (final String e : equations)
                      if (e.charAt(1) == '=') {
                        final int x = e.charAt(0) - 'a';
                        final int y = e.charAt(3) - 'a';
                        uf.union(x, y);
                      }
                    for (final String e : equations)
                      if (e.charAt(1) == '!') {
                        final int x = e.charAt(0) - 'a';
                        final int y = e.charAt(3) - 'a';
                        if (uf.find(x) == uf.find(y))
                          return false;
                      }
                    return true;
                  }
                }`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q835: {
        title: "Q991. Broken Calculator (Q835)",
        content: (
          <Comp
            title="Q991. Broken Calculator (Q835)"
            content1={
              <>
                There is a broken calculator that has the integer startValue on
                its display initially. In one operation, you can:
                <br />
                multiply the number on display by 2, or
                <br />
                subtract 1 from the number on display.
                <br />
                Given two integers startValue and target, return the minimum
                number of operations needed to display target on the calculator.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: startValue = 2, target = 3<br />
                    Output: 2<br />
                    Explanation: Use double operation and then decrement
                    operation {"{2 -> 4 -> 3}"}.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: startValue = 5, target = 8<br />
                    Output: 2<br />
                    Explanation: Use decrement and then double {"{5 -> 4 -> 8}"}
                    .
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: startValue = 3, target = 10
                    <br />
                    Output: 3<br />
                    Explanation: Use double, decrement and double{" "}
                    {"{3 -> 6 -> 5 -> 10}"}.
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= x, y &lt;= 109</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} startValue
                * @param {number} target
                * @return {number}
                */
               var brokenCalc = function(startValue, target){
                 let ops = 0;
                 while (startValue < target) {
                   if (target % 2 == 0)
                     target = parseInt(target/2);
                   else
                     target += 1;
                   ++ops;
                 }
                 return ops + startValue - target;   
               };
               
               console.log(brokenCalc(2,3))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q836: {
        title: "Q992. Subarrays with K Different Integers  (Q836)",
        content: (
          <Comp
            title="Q992. Subarrays with K Different Integers  (Q836)"
            content1={
              <>
                Given an integer array nums and an integer k, return the number
                of good subarrays of nums.
                <br />
                A good array is an array where the number of different integers
                in that array is exactly k.
                <br />
                For example, [1,2,3,1,2] has 3 different integers: 1, 2, and 3.
                A subarray is a contiguous part of an array.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,2,1,2,3], k = 2<br />
                    Output: 7<br />
                    Explanation: Subarrays formed with exactly 2 different
                    integers: [1,2], [2,1], [1,2], [2,3], [1,2,1], [2,1,2],
                    [1,2,1,2]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,2,1,3,4], k = 3<br />
                    Output: 3<br />
                    Explanation: Subarrays formed with exactly 3 different
                    integers: [1,2,1,3], [2,1,3], [1,3,4].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 2 * 10^4
                <br />1 &lt;= nums[i], k &lt;= nums.length
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var subarraysWithKDistinct = function(nums, k) {
                 return subarraysWithAtMostKDistinct(nums, k) - subarraysWithAtMostKDistinct(nums, k - 1);   
               };
               function subarraysWithAtMostKDistinct(A, K) {
                   let ans = 0;
                   const count = Array(A.length + 1).fill(0);
                   for (let l = 0, r = 0; r < A.length; ++r) {
                     if (++count[A[r]] == 1)
                       --K;
                     while (K == -1)
                       if (--count[A[l++]] == 0)
                         ++K;
                     ans += r - l + 1; 
                   }
                   return ans;
               }
               console.log(subarraysWithKDistinct([1,2,1,2,3],2))`,
                output: `7`,
              },
            }}
          />
        ),
      },
      q837: {
        title: "Q993. Cousins in Binary Tree (Q837)",
        content: (
          <Comp
            title="Q993. Cousins in Binary Tree (Q837)"
            content1={
              <>
                Given the root of a binary tree with unique values and the
                values of two different nodes of the tree x and y, return true
                if the nodes corresponding to the values x and y in the tree are
                cousins, or false otherwise.
                <br />
                Two nodes of a binary tree are cousins if they have the same
                depth with different parents.
                <br />
                Note that in a binary tree, the root node is at the depth 0, and
                children of each depth k node are at the depth k + 1.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2019/02/12/q1248-01.png",
                content: (
                  <>
                    Input: root = [1,2,3,4], x = 4, y = 3
                    <br />
                    Output: false
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2019/02/12/q1248-01.png",
                content: (
                  <>
                    Input: root = [1,2,3,null,4,null,5], x = 5, y = 4
                    <br />
                    Output: true
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2019/02/12/q1248-02.png",
                content: (
                  <>
                    Input: root = [1,2,3,null,4], x = 2, y = 3
                    <br />
                    Output: false
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [2, 100].
                <br />
                1 &lt;= Node.val &lt;= 100
                <br />
                Each node has a unique value.
                <br />
                x != y<br />x and y are exist in the tree.
              </>
            }
            tc="n"
            sc="n"
            codes={{
              "C++": {
                code: `// Input: root = [1,2,3,4], x = 4, y = 3
                /**
                 * Definition for a binary tree node.
                 * struct TreeNode {
                 *     int val;
                 *     TreeNode *left;
                 *     TreeNode *right;
                 *     TreeNode() : val(0), left(nullptr), right(nullptr) {}
                 *     TreeNode(int x) : val(x), left(nullptr), right(nullptr) {}
                 *     TreeNode(int x, TreeNode *left, TreeNode *right) : val(x), left(left), right(right) {}
                 * };
                 */
                class Solution {
                 public:
                  bool isCousins(TreeNode* root, int x, int y) {
                    if (!root)
                      return false;
                    queue<TreeNode*> queue{{root}};
                    while (!queue.empty()) {
                      bool isFindX = false;
                      bool isFindY = false;
                      for (int i = queue.size(); i > 0; --i) {
                        root = queue.front(), queue.pop();
                        if (root->val == x)
                          isFindX = true;
                        else if (root->val == y)
                          isFindY = true;
                        else if (root->left && root->right) {
                          if (root->left->val == x && root->right->val == y)
                            return false;
                          if (root->left->val == y && root->right->val == x)
                            return false;
                        }
                        if (root->left)
                          queue.push(root->left);
                        if (root->right)
                          queue.push(root->right);
                      }
                      if (isFindX && isFindY)
                        return true;
                      else if (isFindX || isFindY)
                        return false;
                    }
                    return false;
                  }
                };`,
                output: `false`,
              },
            }}
          />
        ),
      },
      q838: {
        title: "Q994. Rotting Oranges (Q838)",
        content: (
          <Comp
            title="Q994. Rotting Oranges (Q838)"
            content1={
              <>
                You are given an m x n grid where each cell can have one of
                three values: ,<br />
                0 representing an empty cell,
                <br />
                1 representing a fresh orange, or,
                <br />
                2 representing a rotten orange.,
                <br />
                Every minute, any fresh orange that is 4-directionally adjacent
                to a rotten orange becomes rotten. ,<br />
                Return the minimum number of minutes that must elapse until no
                cell has a fresh orange. If this is impossible, return -1.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2019/02/16/oranges.png",
                content: (
                  <>
                    Input: grid = [[2,1,1],[1,1,0],[0,1,1]]
                    <br />
                    Output: 4
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[2,1,1],[0,1,1],[1,0,1]]
                    <br />
                    Output: -1
                    <br />
                    Explanation: The orange in the bottom left corner (row 2,
                    column 0) is never rotten, because rotting only happens
                    4-directionally.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: grid = [[0,2]]
                    <br />
                    Output: 0<br />0 Explanation: Since there are already no
                    fresh oranges at minute 0, the answer is just 0.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                m == grid.length
                <br />
                n == grid[i].length
                <br />
                1 &lt;= m, n &lt;= 10
                <br />
                grid[i][j] is 0, 1, or 2.
              </>
            }
            tc="m.n"
            sc="m.n"
            codes={{
              "C++": {
                code: `// Input: grid = [[2,1,1],[1,1,0],[0,1,1]]
                class Solution {
                 public:
                  int orangesRotting(vector<vector<int>>& grid) {
                    const int m = grid.size();
                    const int n = grid[0].size();
                    const vector<int> dirs{0, 1, 0, -1, 0};
                    int ans = 0;
                    int countFresh = 0;
                    queue<pair<int, int>> q;
                    for (int i = 0; i < m; ++i)
                      for (int j = 0; j < n; ++j)
                        if (grid[i][j] == 1)
                          ++countFresh;
                        else if (grid[i][j] == 2)
                          q.emplace(i, j);
                    if (countFresh == 0)
                      return 0;
                    while (!q.empty()) {
                      ++ans;
                      for (int sz = q.size(); sz > 0; --sz) {
                        const auto [i, j] = q.front();
                        q.pop();
                        for (int k = 0; k < 4; ++k) {
                          const int x = i + dirs[k];
                          const int y = j + dirs[k + 1];
                          if (x < 0 || x == m || y < 0 || y == n)
                            continue;
                          if (grid[x][y] != 1)
                            continue;
                          grid[x][y] = 2;   
                          q.emplace(x, y);  
                          --countFresh;    
                        }
                      }
                    }
                
                    return countFresh == 0 ? ans - 1 : -1;
                  }
                };`,
                output: `1`,
              },
            }}
          />
        ),
      },
      q839: {
        title: "Q995. Minimum Number of K Consecutive Bit Flips (Q839)",
        content: (
          <Comp
            title="Q995. Minimum Number of K Consecutive Bit Flips (Q839)"
            content1={
              <>
                You are given a binary array nums and an integer k.
                <br />
                A k-bit flip is choosing a subarray of length k from nums and
                simultaneously changing every 0 in the subarray to 1, and every
                1 in the subarray to 0.
                <br />
                Return the minimum number of k-bit flips required so that there
                is no 0 in the array. If it is not possible, return -1.
                <br />A subarray is a contiguous part of an array.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [0,1,0], k = 1 <br />
                    Output: 2 <br />
                    Explanation: Flip nums[0], then flip nums[2].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [1,1,0], k = 2 <br />
                    Output: -1 <br />
                    Explanation: No matter how we flip subarrays of size 2, we
                    cannot make the array become [1,1,1].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [0,0,0,1,0,1,1,0], k = 3 Output: 3 <br />
                    Explanation: <br />
                    Flip nums[0],nums[1],nums[2]: nums becomes [1,1,1,1,0,1,1,0]
                    <br /> Flip nums[4],nums[5],nums[6]: nums becomes
                    [1,1,1,1,1,0,0,0]
                    <br /> Flip nums[5],nums[6],nums[7]: nums becomes
                    [1,1,1,1,1,1,1,1]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^5 <br />1 &lt;= k &lt;= nums.length
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var minKBitFlips = function(nums, k) {
                 let ans = 0;
                 let flippedTime = 0;
                 for (let r = 0; r < nums.length; ++r) {
                   if (r >= k && nums[r - k] == 2)
                     --flippedTime;
                   if (flippedTime % 2 == nums[r]) {
                     if (r + k > nums.length)
                       return -1;
                     ++ans;
                     ++flippedTime;
                     nums[r] = 2;
                   }
                 }
                 return ans;    
               };
               
               console.log(minKBitFlips([0,1,0],1))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q840: {
        title: "Q996. Number of Squareful Arrays (Q840)",
        content: (
          <Comp
            title="Q996. Number of Squareful Arrays (Q840)"
            content1={
              <>
                An array is squareful if the sum of every pair of adjacent
                elements is a perfect square.
                <br />
                Given an integer array nums, return the number of permutations
                of nums that are squareful.
                <br />
                Two permutations perm1 and perm2 are different if there is some
                index i such that perm1[i] != perm2[i].
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,17,8]
                    <br />
                    Output: 2<br />
                    Explanation: [1,8,17] and [17,8,1] are the valid
                    permutations.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2,2,2]
                    <br />
                    Output: 1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 12
                <br />0 &lt;= nums[i] &lt;= 10^9
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Java: {
                code: `// Input: nums = [1,17,8]
                class Solution {
                  public int numSquarefulPerms(int[] A) {
                    boolean[] used = new boolean[A.length];
                    Arrays.sort(A);
                    dfs(A, used, new ArrayList<>());
                    return ans;
                  }
                  private int ans = 0;
                  private void dfs(int[] A, boolean[] used, List<Integer> path) {
                    if (path.size() > 1 && !isSquare(path.get(path.size() - 1) + path.get(path.size() - 2)))
                      return;
                    if (path.size() == A.length) {
                      ++ans;
                      return;
                    }
                    for (int i = 0; i < A.length; ++i) {
                      if (used[i])
                        continue;
                      if (i > 0 && A[i] == A[i - 1] && !used[i - 1])
                        continue;
                      used[i] = true;
                      path.add(A[i]);
                      dfs(A, used, path);
                      path.remove(path.size() - 1);
                      used[i] = false;
                    }
                  }
                  private boolean isSquare(int num) {
                    int root = (int) Math.sqrt(num);
                    return root * root == num;
                  }
                }
                `,
                output: `2`,
              },
            }}
          />
        ),
      },
      q841: {
        title: "Q997. Find the Town Judge (Q841)",
        content: (
          <Comp
            title="Q997. Find the Town Judge (Q841)"
            content1={
              <>
                In a town, there are n people labeled from 1 to n. There is a
                rumor that one of these people is secretly the town judge.
                <br />
                If the town judge exists, then:
                <br />
                The town judge trusts nobody.
                <br />
                Everybody (except for the town judge) trusts the town judge.
                <br />
                There is exactly one person that satisfies properties 1 and 2.
                <br />
                You are given an array trust where trust[i] = [ai, bi]
                representing that the person labeled ai trusts the person
                labeled bi.
                <br />
                Return the label of the town judge if the town judge exists and
                can be identified, or return -1 otherwise.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 2, trust = [[1,2]]
                    <br />
                    Output: 2
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 3, trust = [[1,3],[2,3]]
                    <br />
                    Output: 3
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 3, trust = [[1,3],[2,3],[3,1]]
                    <br />
                    Output: -1
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 1000
                <br />
                0 &lt;= trust.length &lt;= 10^4
                <br />
                trust[i].length == 2<br />
                All the pairs of trust are unique.
                <br />
                ai != bi
                <br />1 &lt;= ai, bi &lt;= n
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @param {number[][]} trust
                * @return {number}
                */
               var findJudge = function(n, trust) {
                 const count = Array(n + 1).fill(0);
                 for (let t of trust) {
                   --count[t[0]];
                   ++count[t[1]];
                 }
                 for (let i = 1; i < n + 1; ++i)
                   if (count[i] == n - 1)
                     return i;
                 return -1;    
               };
               
               console.log(findJudge(2,[[1,2]]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q842: {
        title: "Q998. Maximum Binary Tree II (Q842)",
        content: (
          <Comp
            title="Q998. Maximum Binary Tree II (Q842)"
            content1={
              <>
                A maximum tree is a tree where every node has a value greater
                than any other value in its subtree.
                <br />
                You are given the root of a maximum binary tree and an integer
                val.
                <br />
                Just as in the previous problem, the given tree was constructed
                from a list a (root = Construct(a)) recursively with the
                following Construct(a) routine:
                <br />
                If a is empty, return null.
                <br />
                Otherwise, let a[i] be the largest element of a. Create a root
                node with the value a[i].
                <br />
                The left child of root will be Construct([a[0], a[1], ..., a[i -
                1]]).
                <br />
                The right child of root will be Construct([a[i + 1], a[i + 2],
                ..., a[a.length - 1]]).
                <br />
                Return root. Note that we were not given a directly, only a root
                node root = Construct(a).
                <br />
                Suppose b is a copy of a with the value val appended to it. It
                is guaranteed that b has unique values.
                <br />
                Return Construct(b).
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2021/08/09/maxtree1.JPG",
                content: (
                  <>
                    Input: root = [4,1,3,null,null,2], val = 5
                    <br /> Output: [5,4,null,1,3,null,null,2] <br />
                    Explanation: a = [1,4,2,3], b = [1,4,2,3,5]
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2021/08/09/maxtree21.JPG",
                content: (
                  <>
                    Input: root = [5,2,4,null,1], val = 3
                    <br /> Output: [5,2,4,null,1,null,3] <br />
                    Explanation: a = [2,1,5,4], b = [2,1,5,4,3]
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2021/08/09/maxtree3.JPG",
                content: (
                  <>
                    Input: root = [5,2,3,null,1], val = 4
                    <br /> Output: [5,2,4,null,1,3] <br />
                    Explanation: a = [2,1,5,3], b = [2,1,5,3,4]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                The number of nodes in the tree is in the range [1, 100].
                <br /> 1 &lt;= Node.val &lt;= 100
                <br />
                All the values of the tree are unique.
                <br />1 &lt;= val &lt;= 100
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Java: {
                code: `/**
                // Input: root = [5,2,3,null,1], val = 4
                * Definition for a binary tree node.
                 * public class TreeNode {
                 *     int val;
                 *     TreeNode left;
                 *     TreeNode right;
                 *     TreeNode() {}
                 *     TreeNode(int val) { this.val = val; }
                 *     TreeNode(int val, TreeNode left, TreeNode right) {
                 *         this.val = val;
                 *         this.left = left;
                 *         this.right = right;
                 *     }
                 * }
                 */
                class Solution {
                  public TreeNode insertIntoMaxTree(TreeNode root, int val) {
                    if (root.val < val)
                      return new TreeNode(val, root, null);
                    TreeNode curr = root;
                    while (curr.right != null && curr.right.val > val)
                      curr = curr.right;
                    TreeNode inserted = new TreeNode(val, curr.right, null);
                    curr.right = inserted;
                    return root;
                  }
                }
                `,
                output: `[5,2,4,null,1,3]`,
              },
            }}
          />
        ),
      },
      q843: {
        title: "Q999. Available Captures for Rook (Q843)",
        content: (
          <Comp
            title="Q999. Available Captures for Rook (Q843)"
            content1={
              <>
                On an 8 x 8 chessboard, there is exactly one white rook 'R' and
                some number of white bishops 'B', black pawns 'p', and empty
                squares '.'.
                <br />
                When the rook moves, it chooses one of four cardinal directions
                (north, east, south, or west), then moves in that direction
                until it chooses to stop, reaches the edge of the board,
                captures a black pawn, or is blocked by a white bishop. A rook
                is considered attacking a pawn if the rook can capture the pawn
                on the rook's turn. The number of available captures for the
                white rook is the number of pawns that the rook is attacking.
                <br />
                Return the number of available captures for the white rook.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2019/02/20/1253_example_1_improved.PNG",
                content: (
                  <>
                    Input: board =
                    [[".",".",".",".",".",".",".","."],[".",".",".","p",".",".",".","."],[".",".",".","R",".",".",".","p"],[".",".",".",".",".",".",".","."],[".",".",".",".",".",".",".","."],[".",".",".","p",".",".",".","."],[".",".",".",".",".",".",".","."],[".",".",".",".",".",".",".","."]]
                    <br /> Output: 3 <br />
                    Explanation: In this example, the rook is attacking all the
                    pawns.
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2019/02/19/1253_example_2_improved.PNG",
                content: (
                  <>
                    Input: board = [[".",".",".",".",".",".",".","."],
                    <br />
                    [".","p","p","p","p","p",".","."],[".","p","p","B","p","p",".","."],
                    <br />
                    [".","p","B","R","B","p",".","."],
                    <br />
                    [".","p","p","B","p","p",".","."],[".","p","p","p","p","p",".","."],[".",".",".",".",".",".",".","."],[".",".",".",".",".",".",".","."]]
                    <br /> Output: 0 <br />
                    Explanation: The bishops are blocking the rook from
                    attacking any of the pawns.
                  </>
                ),
              },
              {
                img: "https://assets.leetcode.com/uploads/2019/02/20/1253_example_3_improved.PNG",
                content: (
                  <>
                    Input: board = [[".",".",".",".",".",".",".","."],
                    <br />
                    [".",".",".","p",".",".",".","."],[".",".",".","p",".",".",".","."],
                    <br />
                    ["p","p",".","R",".","p","B","."],
                    <br />
                    [".",".",".",".",".",".",".","."],[".",".",".","B",".",".",".","."],[".",".",".","p",".",".",".","."],[".",".",".",".",".",".",".","."]]
                    <br /> Output: 3 <br />
                    Explanation: The rook is attacking the pawns at positions
                    b5, d6, and f5.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                board.length == 8 <br />
                board[i].length == 8 <br />
                board[i][j] is either 'R', '.', 'B', or 'p' <br />
                There is exactly one cell with board[i][j] == 'R'
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {character[][]} board
                * @return {number}
                */
               var numRookCaptures = function(board) {
                 let ans = 0;
                 let i0 = 0;
                 let j0 = 0;
               
                   for (let i = 0; i < 8; ++i)
                     for (let j = 0; j < 8; ++j)
                       if (board[i][j] == 'R') {
                         i0 = i;
                         j0 = j;
                       }
               
                   for (let d of [[1, 0], [0, 1], [-1, 0], [0, -1]])
                     for (let i = i0 + d[0], j = j0 + d[1]; 0 <= i && i < 8 && 0 <= j && j < 8;
                          i += d[0], j += d[1]) {
                       if (board[i][j] == 'p')
                         ++ans;
                       if (board[i][j] != '.')
                         break;
                     }
               
                   return ans;  
               };
               
               console.log(numRookCaptures([[".",".",".",".",".",".",".","."],
               [".",".",".","p",".",".",".","."],
               [".",".",".","R",".",".",".","p"],
               [".",".",".",".",".",".",".","."],
               [".",".",".",".",".",".",".","."],
               [".",".",".","p",".",".",".","."],
               [".",".",".",".",".",".",".","."],
               [".",".",".",".",".",".",".","."]]))`,
                output: `3`,
              },
            }}
          />
        ),
      },
      q844: {
        title: "Q1000. Minimum Cost to Merge Stones (Q844)",
        content: (
          <Comp
            title="Q1000. Minimum Cost to Merge Stones (Q844)"
            content1={
              <>
                There are n piles of stones arranged in a row. The ith pile has
                stones[i] stones.
                <br />
                A move consists of merging exactly k consecutive piles into one
                pile, and the cost of this move is equal to the total number of
                stones in these k piles.
                <br />
                Return the minimum cost to merge all piles of stones into one
                pile. If it is impossible, return -1.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: stones = [3,2,4,1], k = 2 Output: 20
                    <br />
                    Explanation: We start with [3, 2, 4, 1].
                    <br />
                    We merge [3, 2] for a cost of 5, and we are left with [5, 4,
                    1].
                    <br />
                    We merge [4, 1] for a cost of 5, and we are left with [5,
                    5].
                    <br />
                    We merge [5, 5] for a cost of 10, and we are left with [10].
                    <br />
                    The total cost was 20, and this is the minimum possible.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: stones = [3,2,4,1], k = 3<br />
                    Output: -1
                    <br />
                    Explanation: After any merge operation, there are 2 piles
                    left, and we can't merge anymore. So the task is impossible.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: stones = [3,5,1,2,6], k = 3<br />
                    Output: 25
                    <br />
                    Explanation: We start with [3, 5, 1, 2, 6].
                    <br />
                    We merge [5, 1, 2] for a cost of 8, and we are left with [3,
                    8, 6].
                    <br />
                    We merge [3, 8, 6] for a cost of 17, and we are left with
                    [17].
                    <br />
                    The total cost was 25, and this is the minimum possible.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                n == stones.length
                <br />
                1 &lt;= n &lt;= 30
                <br />
                1 &lt;= stones[i] &lt;= 100
                <br />2 &lt;= k &lt;= 30
              </>
            }
            tc="n^3"
            sc="n^2"
            codes={{
              Javascript: {
                code: `/**
                * @param {character[][]} board
                * @return {number}
                */
               /**
                * @param {number[]} stones
                * @param {number} k
                * @return {number}
                */
               var mergeStones = function(stones, k) {
                 const n =stones.length;
                 if ((n - 1) % (k - 1) != 0)
                   return -1;
                 const kMax = 1e9;
                 const dp = Array.from(Array(n),()=>Array(n).fill(kMax));
                 const prefix = Array(n + 1).fill(0);
                   for (let i = 0; i < n; ++i)
                     dp[i][i] = 0;
                   for (let i = 0; i < n; ++i)
                     prefix[i + 1] = prefix[i] + stones[i];
                   for (let d = 1; d < n; ++d)
                     for (let i = 0; i + d < n; ++i) {
                       const j = i + d;
                       for (let m = i; m < j; m += k - 1)
                         dp[i][j] = Math.min(dp[i][j], dp[i][m] + dp[m + 1][j]);
                       if (d % (k - 1) == 0)
                         dp[i][j] += prefix[j + 1] - prefix[i];
                     }
                   return dp[0][n - 1];   
               };
               
               console.log(mergeStones([3,2,4,1],2))`,
                output: `20`,
              },
            }}
          />
        ),
      },
      q845: {
        title: "Q1001. Grid Illumination (Q845)",
        content: (
          <Comp
            title="Q1001. Grid Illumination (Q845)"
            content1={
              <>
                There is a 2D grid of size n x n where each cell of this grid
                has a lamp that is initially turned off.
                <br />
                You are given a 2D array of lamp positions lamps, where lamps[i]
                = [rowi, coli] indicates that the lamp at grid[rowi][coli] is
                turned on. Even if the same lamp is listed more than once, it is
                turned on.
                <br />
                When a lamp is turned on, it illuminates its cell and all other
                cells in the same row, column, or diagonal.
                <br />
                You are also given another 2D array queries, where queries[j] =
                [rowj, colj]. For the jth query, determine whether
                grid[rowj][colj] is illuminated or not. After answering the jth
                query, turn off the lamp at grid[rowj][colj] and its 8 adjacent
                lamps if they exist. A lamp is adjacent if its cell shares
                either a side or corner with grid[rowj][colj].
                <br />
                Return an array of integers ans, where ans[j] should be 1 if the
                cell in the jth query was illuminated, or 0 if the lamp was not.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2020/08/19/illu_1.jpg",
                content: (
                  <>
                    Input: n = 5, lamps = [[0,0],[4,4]], queries = [[1,1],[1,0]]
                    <br /> Output: [1,0] <br />
                    Explanation: We have the initial grid with all lamps turned
                    off. In the above picture we see the grid after turning on
                    the lamp at grid[0][0] then turning on the lamp at
                    grid[4][4].
                    <br /> The 0th query asks if the lamp at grid[1][1] is
                    illuminated or not (the blue square). It is illuminated, so
                    set ans[0] = 1. Then, we turn off all lamps in the red
                    square.
                    <br /> The 1st query asks if the lamp at grid[1][0] is
                    illuminated or not (the blue square). It is not illuminated,
                    so set ans[1] = 0. Then, we turn off all lamps in the red
                    rectangle.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 5, lamps = [[0,0],[4,4]], queries = [[1,1],[1,1]]
                    <br /> Output: [1,1]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 5, lamps = [[0,0],[0,4]], queries =
                    [[0,4],[0,1],[1,4]]
                    <br /> Output: [1,1,0]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= n &lt;= 109
                <br />
                0 &lt;= lamps.length &lt;= 20000
                <br />
                0 &lt;= queries.length &lt;= 20000
                <br />
                lamps[i].length == 2<br />
                0 &lt;= rowi, coli &lt; n<br />
                queries[j].length == 2<br />0 &lt;= rowj, colj &lt; n
              </>
            }
            tc="n^3"
            sc="n"
            codes={{
              Java: {
                code: `// Input: n = 5, lamps = [[0,0],[4,4]], queries = [[1,1],[1,0]]
                class Solution {
                  public int[] gridIllumination(int N, int[][] lamps, int[][] queries) {
                    List<Integer> ans = new ArrayList<>();
                    Map<Integer, Integer> rows = new HashMap<>();
                    Map<Integer, Integer> cols = new HashMap<>();
                    Map<Integer, Integer> diag1 = new HashMap<>();
                    Map<Integer, Integer> diag2 = new HashMap<>();
                    Set<Long> lampsSet = new HashSet<>();
                    for (int[] lamp : lamps) {
                      int i = lamp[0];
                      int j = lamp[1];
                      if (lampsSet.add(hash(i, j))) {
                        rows.put(i, rows.getOrDefault(i, 0) + 1);
                        cols.put(j, cols.getOrDefault(j, 0) + 1);
                        diag1.put(i + j, diag1.getOrDefault(i + j, 0) + 1);
                        diag2.put(i - j, diag2.getOrDefault(i - j, 0) + 1);
                      }
                    }
                    for (int[] q : queries) {
                      int i = q[0];
                      int j = q[1];
                      if (rows.getOrDefault(i, 0) > 0 || cols.getOrDefault(j, 0) > 0 ||
                          diag1.getOrDefault(i + j, 0) > 0 || diag2.getOrDefault(i - j, 0) > 0) {
                        ans.add(1);
                        for (int y = Math.max(0, i - 1); y < Math.min(N, i + 2); ++y)
                          for (int x = Math.max(0, j - 1); x < Math.min(N, j + 2); ++x)
                            if (lampsSet.remove(hash(y, x))) {
                              rows.put(y, rows.getOrDefault(y, 0) - 1);
                              cols.put(x, cols.getOrDefault(x, 0) - 1);
                              diag1.put(y + x, diag1.getOrDefault(y + x, 0) - 1);
                              diag2.put(y - x, diag2.getOrDefault(y - x, 0) - 1);
                            }
                      } else
                        ans.add(0);
                    }
                    return ans.stream().mapToInt(i -> i).toArray();
                  }
                
                  private long hash(int i, int j) {
                    return ((long) i << 32) + j;
                  }
                }`,
                output: `[1,0]`,
              },
            }}
          />
        ),
      },
      q846: {
        title: "Q1002. Find Common Characters (Q846)",
        content: (
          <Comp
            title="Q1002. Find Common Characters (Q846)"
            content1={
              <>
                Given a string array words, return an array of all characters
                that show up in all strings within the words (including
                duplicates). You may return the answer in any order.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: words = ["bella","label","roller"]
                    <br /> Output: ["e","l","l"]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: words = ["cool","lock","cook"]
                    <br /> Output: ["c","o"]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= words.length &lt;= 100 <br />
                1 &lt;= words[i].length &lt;= 100
                <br />
                words[i] consists of lowercase English letter
              </>
            }
            tc="n^2"
            sc="n"
            codes={{
              Java: {
                code: `// Input: words = ["bella","label","roller"]
                class Solution {
                  public List<String> commonChars(String[] A) {
                    List<String> ans = new ArrayList<>();
                    int[] commonCount = new int[26];
                    Arrays.fill(commonCount, Integer.MAX_VALUE);
                    for (String a : A) {
                      int[] count = new int[26];
                      for (char c : a.toCharArray())
                        ++count[c - 'a'];
                      for (int i = 0; i < 26; ++i)
                        commonCount[i] = Math.min(commonCount[i], count[i]);
                    }
                    for (char c = 'a'; c <= 'z'; ++c)
                      for (int i = 0; i < commonCount[c - 'a']; ++i)
                        ans.add(String.valueOf(c));
                    return ans;
                  }
                }`,
                output: `["e","l","l"]`,
              },
            }}
          />
        ),
      },
      q847: {
        title: "Q1003. Check If Word Is Valid After Substitutions (Q847)",
        content: (
          <Comp
            title="Q1003. Check If Word Is Valid After Substitutions (Q847)"
            content1={
              <>
                Given a string s, determine if it is valid.
                <br />
                A string s is valid if, starting with an empty string t = "",
                you can transform t into s after performing the following
                operation any number of times:
                <br />
                Insert string "abc" into any position in t. More formally, t
                becomes tleft + "abc" + tright, where t == tleft + tright. Note
                that tleft and tright may be empty. Return true if s is a valid
                string, otherwise, return false.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: s = "aabcbc" Output: true
                    <br />
                    Explanation:
                    <br />
                    "" -&gt; "abc" -&gt; "aabcbc"
                    <br />
                    Thus, "aabcbc" is valid.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "abcabcababcc"
                    <br />
                    Output: true
                    <br />
                    Explanation:
                    <br />
                    "" -&gt; "abc" -&gt; "abcabc" -&gt; "abcabcabc" -&gt;
                    "abcabcababcc" Thus, "abcabcababcc" is valid.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: s = "abccba"
                    <br />
                    Output: false
                    <br />
                    Explanation: It is impossible to get "abccba" using the
                    operation.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= s.length &lt;= 2 * 10^4
                <br />s consists of letters 'a', 'b', and 'c'
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {string} s
                * @return {boolean}
                */
               var isValid = function(s) {
                 const stack = [];
                   for (let c of s)
                     if (c == 'c') {
                       if (stack.length < 2)
                         return false;
                       if (stack[stack.length-1] != 'b')
                         return false;
                       stack.pop();
                       if (stack[stack.length-1] != 'a')
                         return false;
                       stack.pop();
                     } else {
                       stack.push(c);
                     }
                   return !stack.length;    
               };
               
               console.log(isValid("aabcbc"))`,
                output: `true`,
              },
            }}
          />
        ),
      },
      q848: {
        title: "Q1004. Max Consecutive Ones III (Q848)",
        content: (
          <Comp
            title="Q1004. Max Consecutive Ones III (Q848)"
            content1={
              <>
                Given a binary array nums and an integer k, return the maximum
                number of consecutive 1's in the array if you can flip at most k
                0's.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [1,1,1,0,0,0,1,1,1,1,0], k = 2
                    <br /> Output: 6<br />
                    Explanation: [1,1,1,0,0,1,1,1,1,1,1]
                    <br />
                    Bolded numbers were flipped from 0 to 1. The longest
                    subarray is underlined.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [0,0,1,1,0,0,1,1,1,0,1,1,0,0,0,1,1,1,1], k = 3
                    <br /> Output: 10
                    <br />
                    Explanation: [0,0,1,1,1,1,1,1,1,1,1,1,0,0,0,1,1,1,1] Bolded
                    numbers were flipped from 0 to 1. The longest subarray is
                    underlined.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^5
                <br />
                nums[i] is either 0 or 1.
                <br />0 &lt;= k &lt;= nums.length
              </>
            }
            tc="n"
            sc="1"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var longestOnes = function(nums, k) {
                 let ans = 0;
                 for (let l = 0, r = 0; r < nums.length; ++r) {
                     if (nums[r] == 0)
                       --k;
                     while (k < 0)
                       if (nums[l++] == 0)
                         ++k;
                     ans = Math.max(ans, r - l + 1);
                   }
                   return ans;    
               };
               console.log(longestOnes([1,1,1,0,0,0,1,1,1,1,0],2))`,
                output: `6`,
              },
            }}
          />
        ),
      },
      q849: {
        title: "Q1005. Maximize Sum Of Array After K Negations (Q849)",
        content: (
          <Comp
            title="Q1005. Maximize Sum Of Array After K Negations (Q849)"
            content1={
              <>
                Given an integer array nums and an integer k, modify the array
                in the following way:
                <br />
                choose an index i and replace nums[i] with -nums[i].
                <br />
                You should apply this process exactly k times. You may choose
                the same index i multiple times.
                <br />
                Return the largest possible sum of the array after modifying it
                in this way.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: nums = [4,2,3], k = 1<br />
                    Output: 5<br />
                    Explanation: Choose index 1 and nums becomes [4,-2,3].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [3,-1,0,2], k = 3<br />
                    Output: 6<br />
                    Explanation: Choose indices (1, 2, 2) and nums becomes
                    [3,1,0,2].
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: nums = [2,-3,-1,5,-4], k = 2<br />
                    Output: 13
                    <br />
                    Explanation: Choose indices (1, 4) and nums becomes
                    [2,3,-1,5,4].
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= nums.length &lt;= 10^4
                <br />
                -100 &lt;= nums[i] &lt;= 100
                <br />1 &lt;= k &lt;= 10^4
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} nums
                * @param {number} k
                * @return {number}
                */
               var largestSumAfterKNegations = function(nums, k) {
                 nums = nums.sort((a,b)=>a-b)
                 for (let i = 0; i < nums.length; ++i) {
                     if (nums[i] > 0 || k == 0)
                       break;
                     nums[i] = -nums[i];
                     --k;
                   }
                   return nums.reduce((a,b)=>a+b,0) -
                          (k % 2) * Math.min(...nums) * 2;  
               };
               
               console.log(largestSumAfterKNegations([4,2,3],1))`,
                output: `5`,
              },
            }}
          />
        ),
      },
      q850: {
        title: "Q1006. Clumsy Factorial (Q850)",
        content: (
          <Comp
            title="Q1006. Clumsy Factorial (Q850)"
            content1={
              <>
                The factorial of a positive integer n is the product of all
                positive integers less than or equal to n.
                <br />
                For example, factorial(10) = 10 * 9 * 8 * 7 * 6 * 5 * 4 * 3 * 2
                * 1. We make a clumsy factorial using the integers in decreasing
                order by swapping out the multiply operations for a fixed
                rotation of operations with multiply '*', divide '/', add '+',
                and subtract '-' in this order.
                <br />
                For example, clumsy(10) = 10 * 9 / 8 + 7 - 6 * 5 / 4 + 3 - 2 *
                1. However, these operations are still applied using the usual
                order of operations of arithmetic. We do all multiplication and
                division steps before any addition or subtraction steps, and
                multiplication and division steps are processed left to right.
                <br />
                Additionally, the division that we use is floor division such
                that 10 * 9 / 8 = 90 / 8 = 11.
                <br />
                Given an integer n, return the clumsy factorial of n.
              </>
            }
            content2={null}
            examples={[
              {
                content: (
                  <>
                    Input: n = 4 <br />
                    Output: 7 <br />
                    Explanation: 7 = 4 * 3 / 2 + 1
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: n = 10 <br />
                    Output: 12 <br />
                    Explanation: 12 = 10 * 9 / 8 + 7 - 6 * 5 / 4 + 3 - 2 * 1
                  </>
                ),
              },
            ]}
            constraints={<>1 &lt;= n &lt;= 10^4</>}
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number} n
                * @return {number}
                */
               var clumsy = function(n) {
                   if (n == 1)
                     return 1;
                   if (n == 2)
                     return 2;
                   if (n == 3)
                     return 6;
                   if (n == 4)
                     return 7;
                   if (n % 4 == 1)
                     return n + 2;
                   if (n % 4 == 2)
                     return n + 2;
                   if (n % 4 == 3)
                     return n - 1;
                   return n + 1;  
               };
               
               console.log(clumsy(4))`,
                output: `7`,
              },
            }}
          />
        ),
      },
      q851: {
        title: "Q1007. Minimum Domino Rotations For Equal Row (Q851)",
        content: (
          <Comp
            title="Q1007. Minimum Domino Rotations For Equal Row (Q851)"
            content1={
              <>
                In a row of dominoes, tops[i] and bottoms[i] represent the top
                and bottom halves of the ith domino. (A domino is a tile with
                two numbers from 1 to 6 - one on each half of the tile.)
                <br />
                We may rotate the ith domino, so that tops[i] and bottoms[i]
                swap values.
                <br />
                Return the minimum number of rotations so that all the values in
                tops are the same, or all the values in bottoms are the same.
                <br />
                If it cannot be done, return -1.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2021/05/14/domino.png",
                content: (
                  <>
                    Input: tops = [2,1,2,4,2,2], bottoms = [5,2,6,2,3,2]
                    <br />
                    Output: 2<br />
                    Explanation: <br />
                    The first figure represents the dominoes as given by tops
                    and bottoms: before we do any rotations. If we rotate the
                    second and fourth dominoes, we can make every value in the
                    top row equal to 2, as indicated by the second figure.
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: tops = [3,5,1,2,3], bottoms = [3,6,3,3,4]
                    <br />
                    Output: -1
                    <br />
                    Explanation: <br />
                    In this case, it is not possible to rotate the dominoes to
                    make one row of values equal.
                  </>
                ),
              },
            ]}
            constraints={
              <>
                2 &lt;= tops.length &lt;= 2 * 10^4
                <br />
                bottoms.length == tops.length
                <br />1 &lt;= tops[i], bottoms[i] &lt;= 6
              </>
            }
            tc="n"
            sc="n"
            codes={{
              Javascript: {
                code: `/**
                * @param {number[]} tops
                * @param {number[]} bottoms
                * @return {number}
                */
               var minDominoRotations = function(tops, bottoms){
                 const n = tops.length;
                 const countA = Array(7).fill(0);
                 const countB = Array(7).fill(0);
                 const countBoth = Array(7).fill(0);
                 for (let i = 0; i < n; ++i) {
                   ++countA[tops[i]];
                   ++countB[bottoms[i]];
                   if (tops[i] == bottoms[i])
                     ++countBoth[tops[i]];
                 }
                 for (let i = 1; i <= 6; ++i)
                   if (countA[i] + countB[i] - countBoth[i] == n)
                     return n - Math.max(countA[i], countB[i]);
                 return -1;  
               };
               
               console.log(minDominoRotations([2,1,2,4,2,2],[5,2,6,2,3,2]))`,
                output: `2`,
              },
            }}
          />
        ),
      },
      q852: {
        title:
          "Q1008. Construct Binary Search Tree from Preorder Traversal (Q852)",
        content: (
          <Comp
            title="Q1008. Construct Binary Search Tree from Preorder Traversal (Q852)"
            content1={
              <>
                Given an array of integers preorder, which represents the
                preorder traversal of a BST (i.e., binary search tree),
                construct the tree and return its root.
                <br />
                It is guaranteed that there is always possible to find a binary
                search tree with the given requirements for the given test
                cases.
                <br />
                A binary search tree is a binary tree where for every node, any
                descendant of Node.left has a value strictly less than Node.val,
                and any descendant of Node.right has a value strictly greater
                than Node.val.
                <br />A preorder traversal of a binary tree displays the value
                of the node first, then traverses Node.left, then traverses
                Node.right.
              </>
            }
            content2={null}
            examples={[
              {
                img: "https://assets.leetcode.com/uploads/2019/03/06/1266.png",
                content: (
                  <>
                    Input: preorder = [8,5,1,7,10,12]
                    <br />
                    Output: [8,5,10,1,7,null,12]
                  </>
                ),
              },
              {
                content: (
                  <>
                    Input: preorder = [8,5,1,7,10,12]
                    <br />
                    Output: [8,5,10,1,7,null,12]
                  </>
                ),
              },
            ]}
            constraints={
              <>
                1 &lt;= preorder.length &lt;= 100
                <br />
                1 &lt;= preorder[i] &lt;= 1000
                <br />
                All the values of preorder are unique.
              </>
            }
            tc="n"
            sc="h"
            codes={{
              Javascript: {
                code: ` function TreeNode(val, left, right) {
                  this.val = (val===undefined ? 0 : val)
                  this.left = (left===undefined ? null : left)
                  this.right = (right===undefined ? null : right)
             }
             
             /**
              * @param {number[]} preorder
              * @return {TreeNode}
              */
             var bstFromPreorder = function(preorder) {
               const root = new TreeNode(preorder[0]);
               const stack = [root];
               for (let i = 1; i < preorder.length; ++i) {
                 let parent = stack[stack.length-1];
                 let child = new TreeNode(preorder[i]);
                   while (stack.length && stack[stack.length-1].val < child.val)
                     parent = stack.pop();
                   if (parent.val > child.val)
                     parent.left = child;
                   else
                     parent.right = child;
                   stack.push(child);
                 }
                 return root;    
             };
             
             console.log(bstFromPreorder([8,5,1,7,10,12]))`,
                output: `TreeNode {
                  val: 8,
                  left: TreeNode {
                    val: 5,
                    left: TreeNode { val: 1, left: null, right: null },
                    right: TreeNode { val: 7, left: null, right: null }
                  },
                  right: TreeNode {
                    val: 10,
                    left: null,
                    right: TreeNode { val: 12, left: null, right: null }
                  }
                }`,
              },
            }}
          />
        ),
      },
    },
  },
  systemDesign: {
    title: "System Design",
    types: {},
    content: (
      <>
        <b>System Design </b>
        is the process of designing the architecture, components, and interfaces
        for a system so that it meets the end-user requirements. <br />
        <Span>
          The HLD changes the client or business requirement into a high-level
          solution. The LLD changes the high-level solution to a comprehensive
          solution.
        </Span>
        <b>High Level Design</b>
        <Span>
          The high‐level design includes such things as decisions about what
          platform to use (such as desktop, laptop, tablet, or phone), what data
          design to use (such as direct access, 2‐tier, or 3‐tier), and
          interfaces with other systems (such as external purchasing systems).
        </Span>
        <Span>
          High Level Design in short HLD is the general system design means it
          refers to the overall system design. It describes the overall
          description/architecture of the application. It includes the
          description of system architecture, data base design, brief
          description on systems, services, platforms and relationship among
          modules. It is also known as macro level/system design. It is created
          by solution architect. It converts the Business/client requirement
          into High Level Solution. It is created first means before Low Level
          Design.
        </Span>
        <b>Low Level Design</b>
        <Span>
          Low-level design (LLD) is a component-level design process that
          follows a step-by-step refinement process. This process can be used
          for designing data structures, required software architecture, source
          code and ultimately, performance algorithms.
        </Span>
        <Span>
          Low Level Design in short LLD is like detailing HLD means it refers to
          component-level design process. It describes detailed description of
          each and every module means it includes actual logic for every system
          component and it goes deep into each modules specification. It is also
          known as micro level/detailed design. It is created by designers and
          developers. It converts the High Level Solution into Detailed
          solution. It is created second means after High Level Design.
        </Span>
        <br />
        <table>
          <thead>
            <th>S.No.</th>
            <th>HIGH LEVEL DESIGN</th>
            <th>LOW LEVEL DESIGN</th>
          </thead>
          <tbody>
            <tr>
              <td>01.</td>
              <td>
                High Level Design is the general system design means it refers
                to the overall system design.
              </td>
              <td>
                Low Level Design is like detailing HLD means it refers to
                component-level design process.
              </td>
            </tr>
            <tr>
              <td>02.</td>
              <td>High Level Design in short called as HLD. </td>
              <td>Low Level Design in short called as LLD.</td>
            </tr>
            <tr>
              <td>03.</td>
              <td>It is also known as macro level/system design.</td>
              <td> It is also known as micro level/detailed design.</td>
            </tr>
            <tr>
              <td>04.</td>
              <td>
                It describes the overall description/architecture of the
                application.
              </td>
              <td>
                It describes detailed description of each and every module.
              </td>
            </tr>
            <tr>
              <td>05.</td>
              <td>
                High Level Design expresses the brief functionality of each
                module.
              </td>
              <td>
                Low Level Design expresses details functional logic of the
                module.
              </td>
            </tr>
            <tr>
              <td>06.</td>
              <td>It is created by solution architect.</td>
              <td>It is created by designers and developers.</td>
            </tr>
            <tr>
              <td>07.</td>
              <td>
                Here in High Level Design the participants are design team,
                review team, and client team.
              </td>
              <td>
                Here in Low Level Design participants are design team, Operation
                Teams, and Implementers.
              </td>
            </tr>
            <tr>
              <td>08.</td>
              <td>It is created first means before Low Level Design.</td>
              <td> It is created second means after High Level Design.</td>
            </tr>
            <tr>
              <td>09.</td>
              <td>
                In HLD the input criteria is Software Requirement Specification
                (SRS).
              </td>
              <td>
                In LLD the input criteria is reviewed High Level Design (HLD).
              </td>
            </tr>
            <tr>
              <td>10.</td>
              <td>
                High Level Solution converts the Business/client requirement
                into High Level Solution.
              </td>
              <td>
                Low Level Design converts the High Level Solution into Detailed
                solution.
              </td>
            </tr>
            <tr>
              <td>11.</td>
              <td>
                In HLD the output criteria is data base design, functional
                design and review record.
              </td>
              <td>
                n LLD the output criteria is program specification and unit test
                plan.
              </td>
            </tr>
          </tbody>
        </table>
      </>
    ),
  },
  designPatterns: {
    title: "Design Patterns",
    types: {},
    content: (
      <>
        <b>Design patterns </b>
        are used to represent some of the best practices adapted by experienced
        object-oriented software developers. A design pattern systematically
        names, motivates, and explains a general design that addresses a
        recurring design problem in object-oriented systems. It describes the
        problem, the solution, when to apply the solution, and its consequences.
        It also gives implementation hints and examples.
        <br />
        <br />
        <Span>
          A design pattern provides a general reusable solution for the common
          problems that occur in software design. The pattern typically shows
          relationships and interactions between classes or objects. The idea is
          to speed up the development process by providing well-tested, proven
          development/design paradigms. Design patterns are programming language
          independent strategies for solving a common problem. That means a
          design pattern represents an idea, not a particular implementation. By
          using design patterns, you can make your code more flexible, reusable,
          and maintainable.
        </Span>
        <Span>
          It’s not mandatory to always implement design patterns in your
          project. Design patterns are not meant for project development. Design
          patterns are meant for common problem-solving. Whenever there is a
          need, you have to implement a suitable pattern to avoid such problems
          in the future. To find out which pattern to use, you just have to try
          to understand the design patterns and their purposes. Only by doing
          that, you will be able to pick the right one.
        </Span>
        <b>Goal: </b>
        <br />
        <br />
        <Span>
          Understand the purpose and usage of each design pattern in order to
          pick and implement the correct pattern as needed.
        </Span>
        <b>Example: </b>
        <br />
        <br />
        <Span>
          In many real-world situations, we want to create only one instance of
          a class. For example, there can be only one active president of a
          country at any given time. This pattern is called a Singleton pattern.
          Other software examples could be a single DB connection shared by
          multiple objects as creating a separate DB connection for every object
          is costly. Similarly, there can be a single configuration manager or
          error manager in an application that handles all problems instead of
          creating multiple managers.
        </Span>
        <Span>
          <b>Types of Design Patterns</b>
        </Span>
        <Span>There are mainly three types of design patterns: </Span>
        <Span>
          <b>1. Creational</b>
        </Span>
        <Span>
          These design patterns are all about class instantiation or object
          creation. These patterns can be further categorized into
          Class-creational patterns and object-creational patterns. While
          class-creation patterns use inheritance effectively in the
          instantiation process, object-creation patterns use delegation
          effectively to get the job done. Creational design patterns are the
          Factory Method, Abstract Factory, Builder, Singleton, Object Pool, and
          Prototype.
        </Span>
        <Span>Use case of creational design pattern-</Span>
        <Span>
          1. Suppose a developer wants to create a simple DBConnection class to
          connect to a database and wants to access the database at multiple
          locations from code, generally what the developer will do is create an
          instance of DBConnection class and use it for doing database
          operations wherever required. This results in creating multiple
          connections from the database as each instance of DBConnection class
          will have a separate connection to the database. In order to deal with
          it, we create DBConnection class as a singleton class, so that only
          one instance of DBConnection is created and a single connection is
          established. Because we can manage DB Connection via one instance, we
          can control load balance, unnecessary connections, etc.
        </Span>
        <Span>
          2. Suppose you want to create multiple instances of a similar kind and
          want to achieve loose coupling then you can go for Factory pattern. A
          class implementing factory design pattern works as a bridge between
          multiple classes. Consider an example of using multiple database
          servers like SQL Server and Oracle. If you are developing an
          application using SQL Server database as back end, but in the future
          need to change the database to the oracle, you will need to modify all
          your code, so as factory design patterns maintain loose coupling and
          easy implementation, we should go for the factory design pattern in
          order to achieve loose coupling and the creation of a similar kind of
          object.
        </Span>
        <Span>
          <b>2. Structural</b>
        </Span>
        <Span>
          These design patterns are about organizing different classes and
          objects to form larger structures and provide new functionality.
          Structural design patterns are Adapter, Bridge, Composite, Decorator,
          Facade, Flyweight, Private Class Data, and Proxy.
        </Span>
        <Span>
          <b>Use Case Of Structural Design Pattern-</b>
        </Span>
        <Span>
          1. When 2 interfaces are not compatible with each other and want to
          establish a relationship between them through an adapter it’s called
          an adapter design pattern. The adapter pattern converts the interface
          of a class into another interface or class that the client expects,
          i.e adapter lets classes work together that could not otherwise
          because of incompatibility. so in these types of incompatible
          scenarios, we can go for the adapter pattern.
        </Span>
        <Span>
          <b>3. Behavioral</b>
        </Span>
        <Span>
          Behavioral patterns are about identifying common communication
          patterns between objects and realizing these patterns. Behavioral
          patterns are Chain of responsibility, Command, Interpreter, Iterator,
          Mediator, Memento, Null Object, Observer, State, Strategy, Template
          method, Visitor
        </Span>
        <Span>
          <b>Use Case Of Behavioral Design Pattern-</b>
        </Span>
        <Span>
          1. The template pattern defines the skeleton of an algorithm in an
          operation deferring some steps to sub-classes. The template method
          lets subclasses redefine certain steps of an algorithm without
          changing the algorithm structure. For example, in your project, you
          want the behavior of the module to be able to extend, such that we can
          make the module behave in new and different ways as the requirements
          of the application change, or to meet the needs of new applications.
          However, no one is allowed to make source code changes to it, i.e you
          can add but can’t modify the structure in those scenarios a developer
          can approach template design pattern.
        </Span>
      </>
    ),
  },
  notes: {
    title: "Notes",
    types: {
      "Obj-C": {
        title: "Objective C",
        content: (
          <>
            <Span>
              Windows Users can make use of{" "}
              <a
                href="https://www.mingw.org/"
                target="blank"
                rel="noreferrer noopener"
              >
                www.mingw.org
              </a>{" "}
              or{" "}
              <a
                href="https://www.cygwin.com/"
                target="blank"
                rel="noreferrer noopener"
              >
                www.cygwin.com
              </a>{" "}
              for comipiling objective c
            </Span>
            <Span>
              <b>Example</b>
            </Span>
            <Span>Animal.m</Span>
            <pre>
              {`#import "Animal.h"
#import "Animal+Vet.h"

@implementation Animal

- (instancetype)init
{
    self = [super init];
    if (self) {
        self.name = @"Sai";
    }
    return self;
}

- (instancetype)initWithName:(NSString *)defaultName
{
    self = [super init];
    if (self) {
        self.name = defaultName;
    }
    return self;
}

-(void) getInfo{
NSLog(@"Random");
[self getExamResults];
}

-(float) weightInKg:(float)weightInLbs {
    return weightInLbs * 0.4535;
}

-(int) getSum:(int)num1 nextNumber:(int)num2 {
    return num1 + num2;
}

-(NSString *)talkToMe:(NSString *)myName{
    NSString *response = [NSString stringWithFormat:@"Hello %@", myName];
    return response;
}

-(void) makeSound{
    NSLog(@"Grrrr");
}

@end

`}
            </pre>
            <Span>Animal.h</Span>
            <pre>
              {`#import <Foundation/Foundation.h>

NS_ASSUME_NONNULL_BEGIN

@interface Animal : NSObject

//@property (readonly) NSString *name;
@property NSString *name;
@property NSString *favFood;
@property NSString *sound;
@property float weight;

-(instancetype) initWithName:(NSString*) defaultName;
-(void) getInfo;
-(float) weightInKg : (float) weightInLbs;
-(NSString *) talkToMe: (NSString *) myName;
-(int) getSum: (int) num1
   nextNumber: (int) num2;

-(void) makeSound;

@end

NS_ASSUME_NONNULL_END

`}
            </pre>
            <Span>Animal+Exam.h</Span>
            <pre>
              {`#import "Animal.h"

NS_ASSUME_NONNULL_BEGIN

@interface Animal (Exam)

- (BOOL) checkedByVet;
- (void) getShots;

@end

NS_ASSUME_NONNULL_END

`}
            </pre>
            <Span>Animal+Exam.m</Span>
            <pre>
              {`#import "Animal+Exam.h"

@implementation Animal (Exam)

-(BOOL) checkedByVet{
    return 1;
}

-(void) getShots{
    NSLog(@"%@ got its shots",self.name);
}

@end

`}
            </pre>
            <Span>Animal+Vet.m</Span>
            <pre>
              {`#import "Animal+Vet.h"

@implementation Animal (Vet)

-(void) getExamResults{
  NSLog(@"Exam is fine");
}

@end`}
            </pre>
            <Span>Animal+Vet.h</Span>
            <pre>
              {`#import "Animal.h"

NS_ASSUME_NONNULL_BEGIN

@interface Animal (Protected)

-(void) getExamResults;

@end

NS_ASSUME_NONNULL_END

`}
            </pre>
            <Span>Dog.m</Span>
            <pre>
              {`#import "Dog.h"

@implementation Dog

-(void) makeSound{
    NSLog(@"%@ says woof",self.name);
}

@end

`}
            </pre>
            <Span>Dog.h</Span>
            <pre>
              {`#import "Animal.h"

NS_ASSUME_NONNULL_BEGIN

@interface Dog : Animal

@end

NS_ASSUME_NONNULL_END

`}
            </pre>
            <Span>Koala.m</Span>
            <pre>
              {`#import "Koala.h"
@implementation Koala
-(NSString *)talkToMe:(NSString *)myName{
    NSString *response = [NSString stringWithFormat:@"Hello %@ says %@", myName, self.name];
    return response;
}
-(void) performTrick{
    NSLog(@"%@ perfoms", self.name);
}
-(void) lookCute{
    NSLog(@")%@ acts super cute", self.name);
}
- (void)performCute {
    
}
-(void) makeSound{
    NSLog(@"%@ sounds", self.name);
}
@end

`}
            </pre>
            <Span>Koala.h</Span>
            <pre>
              {`#import "Animal.h"
#import "BeautyContest.h"

NS_ASSUME_NONNULL_BEGIN

@interface Koala : Animal <BeautyContest>

-(NSString *) talkToMe:(NSString *)myName;

-(void) performTrick;
-(void) makeSound;

@end

NS_ASSUME_NONNULL_END

`}
            </pre>
            <Span>BeautyContest.h</Span>
            <pre>
              {`#import <Foundation/Foundation.h>

NS_ASSUME_NONNULL_BEGIN

@protocol BeautyContest <NSObject>

- (void) lookCute;
- (void) performCute;

@end

NS_ASSUME_NONNULL_END`}
            </pre>
            <Span>Main.m</Span>
            <pre>{`#import <Foundation/Foundation.h>
#import "Animal.h"
#import "Koala.h"
#import "Animal+Exam.h"
#import "Dog.h"

int main(int argc, const char * argv[]) {
    @autoreleasepool {
//        It is used to remove unwanted objects and signals us when objects are no longer
//        needed
//        int char BOOL NSString NSMutableString NSLog NSRange NSMakeRange NSArray

        NSLog(@"Hello, World!");
        NSString *nothing = nil;
        NSLog(@"Location of nil : %p, ",nothing);
        NSLog(@"%@",nothing);
        NSString *quote = @"abcdsjskladhkjsadhkjhsad";
        NSLog(@"size %d",(int)[quote length]);
        NSLog(@"%c",[quote characterAtIndex:5]);
        char *name = "Sai";
        NSString *myName = [NSString stringWithFormat:@"%s",name];
//        immutable object
        BOOL isStringEqual = [quote isEqualToString:myName];
        printf("equal: %d\\n",isStringEqual);
        const char *uCString = [[myName uppercaseString]UTF8String];
        printf("%s\\n",uCString);
        NSString *wholeQuote = [quote stringByAppendingString:myName];
        NSRange searchResult = [wholeQuote rangeOfString:@"Sai"];
        if(searchResult.location == NSNotFound){
            NSLog(@"String not found");
        }else{
            printf("Sai is at index %lu and is %lu long\\n",searchResult.location,searchResult.length);
        }
//        NSRange range = NSMakeRange(42, 1);
//        const char *newQuote = [[wholeQuote stringByReplacingCharactersInRange:range withString:@"Sai9" ]UTF8String];
//        printf("%s",newQuote);

        NSMutableString *groceryList = [NSMutableString stringWithCapacity:50];
        [groceryList appendFormat:@"%s","ABCDEF"];
        NSLog(@"%@",groceryList);
        [groceryList deleteCharactersInRange:NSMakeRange(0, 2)];
        NSLog(@"%@",groceryList);
        [groceryList insertString:@" , Apple" atIndex:1];
        NSLog(@"%@",groceryList);
        [groceryList replaceCharactersInRange:NSMakeRange(1, 1) withString:@"Orange"];
        NSLog(@"%@",groceryList);
        NSArray *officeSupplies = @[@"A",@"B",@"C"];
        NSLog(@"First: %@",officeSupplies[0]);
        NSLog(@"Office Supplies: %@",officeSupplies);
        BOOL containsItem = [officeSupplies containsObject:@"A"];
        NSLog(@"Need Pencils : %d",containsItem);
        NSLog(@"Total : %d",(int)[officeSupplies count]);
        NSLog(@"Index of A is at %lu",(unsigned long)[officeSupplies indexOfObject:@"A"]);

       NSMutableArray *heroes = [NSMutableArray arrayWithCapacity:5];
       [heroes addObject:@"A"];
       [heroes addObject:@"B"];
       [heroes addObject:@"C"];
       [heroes addObject:@"D"];
       [heroes insertObject:@"E" atIndex:2];
       NSLog(@"%@", heroes);
       [heroes removeObject:@"D"];
       [heroes removeObjectAtIndex:0];
       [heroes removeObjectIdenticalTo:@"A" inRange:NSMakeRange(0,1)];
       for(int i=0; i < [heroes count]; i++){
           NSLog(@"%@",heroes[i]);
       }
        
       Animal *dog = [[Animal alloc] init];
       [dog getInfo];
       NSLog(@"The dogs name is %@", [dog name]);
       [dog setName:@"Spot"];
        
       Animal *cat = [[Animal alloc]initWithName:@"Meow"];
        
       NSLog(@"%@",cat.name);
       NSLog(@"180 lbs = %.2f kg", [dog weightInKg:180]);
       NSLog(@"3 + 5 = %d", [dog getSum:3 nextNumber:5 ]);
       NSLog(@"%@", [dog talkToMe:@"Derek"]);
        
       Koala *herbie = [[Koala alloc]initWithName:@"Herbie"];
       NSLog(@"%@",[herbie talkToMe:@"Derek"]);
       NSLog(@"Did %@ receive shots: %d",herbie.name,[herbie checkedByVet]);
       [herbie getShots];
       [dog getInfo];
       [herbie lookCute];
       [herbie performTrick];
        
       float (^getArea) (float height, float width);
        
       getArea = ^float(float width, float height){
           return width * height;
       };
    
       NSLog(@"Area of 3 width and 50 height: %.1f", getArea(3,50));
       
        enum Ratings{
            Poor = 1,
            Ok = 2,
            great = 5
        };
        
        enum Ratings matrixRating = great;
        
        NSLog(@"Matrix : %u",matrixRating);
        
        Dog *grover = [[Dog alloc]initWithName:@"Grover"];
        NSArray *animals = [[NSArray alloc]initWithObjects:herbie,grover, nil];
        
        id object1 = [animals objectAtIndex:0];
        id object2 = [animals objectAtIndex:1];
        
        [object1 makeSound];
        [object2 makeSound];

    }
    return 0;
}`}</pre>
            <Span>
              <pre>
                {`
Categories, Objects , Protocols, Inheriatnce, Anonymous Functions And Garbage Collection

#import <Foundation/Foundation.h>

@protocol PrintProtocolDelegate
- (void)processCompleted;

@end

@interface PrintClass :NSObject {
   id delegate;
}

- (void) printDetails;
- (void) setDelegate:(id)newDelegate;
@end

@implementation PrintClass
- (void)printDetails {
   NSLog(@"Printing Details");
   [delegate processCompleted];
}

- (void) setDelegate:(id)newDelegate {
   delegate = newDelegate;
}

@end

@interface SampleClass:NSObject<PrintProtocolDelegate>
- (void)startAction;

@end

@implementation SampleClass
- (void)startAction {
   PrintClass *printClass = [[PrintClass alloc]init];
   [printClass setDelegate:self];
   [printClass printDetails];
}

-(void)processCompleted {
   NSLog(@"Printing Process Completed");
}

@end

int main(int argc, const char * argv[]) {
   NSAutoreleasePool * pool = [[NSAutoreleasePool alloc] init];
   SampleClass *sampleClass = [[SampleClass alloc]init];
   [sampleClass startAction];
   [pool drain];
   return 0;
}
Categories
A category is a group of related methods for a class, and all of the methods defined in a category are available through the class as if they were defined in the main interface file. As an example, take the Person class that we've been working with throughout this book. If this were a large project, Person may have dozens of methods ranging from basic behaviors to interactions with other people to identity checking. The API might call for all of these methods to be available through a single class, but it's much easier for developers to maintain if each group is stored in a separate file. In addition, categories eliminate the need to recompile the entire class every time you change a single method, which can be a time-saver for very large projects.
#import "Person+Relations.h"
@implementation Person (Relations)
@interface Person (Relations)
SubClass
@implementation A {}
@interface A : B

Delegates And Protocols

A delegate is just a class that does some work for another class. 
A protocol is just a list of methods (and/or properties) 
that must be used by any class that adopts the protocol.

Extensions
Extensions (also called class extensions) are a special type of category that requires their methods to be defined in the main implementation block for the associated class, as opposed to an implementation defined in a category. This can be used to override publicly declared property attributes. For example, it is sometimes convenient to change a read-only property to a read-write property within a class' implementation.
ship.h

#import <Foundation/Foundation.h>
#import "Person.h"
@interface Ship : NSObject
@property (strong, readonly) Person *captain;
- (id)initWithCaptain:(Person *)captain;
@end
// Ship.m
#import "Ship.h"
 
 
// The class extension.
@interface Ship()
@property (strong, readwrite) Person *captain;
@end
 
@implementation Ship
@synthesize captain = _captain;
- (id)initWithCaptain:(Person *)captain {
    self = [super init];
    if (self) {
        [self setCaptain:captain];
    }
    return self;
}
@end
https://code.tutsplus.com/tutorials/objective-c-succinctly-categories-and-extensions--mobile-22016  
  `}
              </pre>
            </Span>
          </>
        ),
        types: {},
      },
      Swift: {
        title: "Swift",
        content: (
          <>
            <Span>
              Apple Developer Program offers a complete set of technical
              resources, support and access to pre-release software with
              everything they need to create innovative apps for IOS and OS X,
              extension for Safari and accessories for iPod , iPhone and iPad.
            </Span>
            <Span>IOS Developer Program: $99k/year</Span>
            <Span>Mac Developer Program: $99k/year</Span>
            <Span>
              When the event is triggered on the surface of ios device, average
              pressure and desired coordinates will be sent to the ios manager
              (os) with the help of sensor.
            </Span>
            <Span>
              IOS Manager (OS) will crash automatically when the device is
              switched off.
            </Span>
            <Span>Steps to make an app :</Span>
            <Span>
              1. Idea <br />
              2. Design <br />
              3. Development <br />
              4. Test <br />
              5. Publish <br />
              6. Market <br />
              7. Update
            </Span>
            <Span>
              Xcode 12.4 === Minimum Catelina MacOS 10.15.4 <br />
              Xcode Beta contains tetsing and is not to download.
            </Span>
            <Span>IOS =&gt; App =&gt; Storyboard</Span>
            <Span>
              Bundle Identifier : Organisation Identifier + '.' + Product Name
            </Span>
            <Span>
              Storyboard is used in modulud 2.15 and at Swift UI activity is
              placed at right side.
            </Span>
            <Span>
              At General Settings Tab, we can find following options:{" "}
            </Span>
            <Span>1. Device Orientation </Span>
            <Span>2. Signing And Capabilities </Span>
            <Span>3. Deployment Info</Span>
            <Span>
              4. Status BarStyle ( Light Content, Dark Content, Default And Hide
              )
            </Span>
            <Span>Project Structure: </Span>
            <Span>
              1. AppDelegate.swift <br />
              2. SceneDelegate.swift <br />
              3. Main.storyboard <br />
              4. LaunchScreen.storyboard <br />
              5. ViewController.swift <br />
              6. Assets.xcassets <br />
              7. Info.plist
            </Span>
            <Span>
              <b>View Controller Scene:</b>
            </Span>
            <Span>
              We can place images from UIImageView into the view controller
              scene.
            </Span>
            <Span>We can place app icon at Assets.xcassets</Span>
            <Span>
              Inspector window inspectors =&gt; <br />
              Attribute Inspector ( Text, Color, Bg, Tint, etc. ) <br />
              Size Inspector ( Location at the screen )
            </Span>
            <Span>Bottom Pane is called the debug area.</Span>
            <Span>
              <b>Note:</b>
              We can generate app icons using the site: <b>appicon.co</b>
            </Span>
            <Span>
              Apple wants us to upload icons of appropriate dimensions so as to
              display them at notification messages, app icon, spotlight, etc.
              so as to keep the app as fast as possible.
            </Span>
            <Span>
              <b>Running on real device:</b>
            </Span>
            <Span>
              compare xcode ( using about tab at the top left corner) with ios
              version
            </Span>
            <Span>XCode 11 corresponds to IOS 13.</Span>
            <Span>
              11.0 === 13.0 <br />
              11.1 === 13.1
            </Span>
            <Span>1. Add apple developer account</Span>
            <Span>2. Sign in to the app.</Span>
            <Span>3. Connect physical device</Span>
            <Span>
              4. Add Apple ID: Xcode =&lt; Preferences =&lt; Accounts =&lt;
              Bottom Left =&lt; Apple ID
            </Span>
            <Span>5. Sign-in to the the app</Span>
            <Span>6. Check automatically managed signing</Span>
            <Span>7. Team =&gt; Account ( Developer Account )</Span>
            <Span>8. Trust and complete the process.</Span>
            <Span>
              Auto Layout allows us to adjust positioning on all devices.
            </Span>
            <Span>
              In order to run the app wirelessly using same wifi connection,
              choose connect via network option at devices and simulators part
              of windows tab.
            </Span>
            <Span>
              <b>Image View</b>
            </Span>
            <Span>Cmd + Option command allows us to replicate elements.</Span>
            <Span>
              Types: Aspect fit, Scale to fill (default), and Aspect Fill.
            </Span>
            <Span>
              <b>Color Picker</b>
            </Span>
            <Span>Background =&gt; Custom =&gt; Color Dropper Tool</Span>
            <Span>Make use of 2 fingers to move your storyboard.</Span>
            <Span>
              Main.storyboard is also known as interface builder as we're making
              use of it to build a interface.
            </Span>
            <Span>Tab is used for autocompletion.</Span>
            <Span>Drag components into the Interface Builder Outlet.</Span>
            <Span>Example:</Span>
            <pre>{`
import UIKit
class ViewController: UIViewController {

@IBOutlet weak var diceImageView1: UIImageView1;
@IBOutlet weak var diceImageView2: UIImageView2;

override func viewDidLoad(){
  super.viewDidLoad()
  diceImageView1.image = _
}

@IBAction func <>(_ sender: Any){ print("A") }

}

`}</pre>
            <Span>
              <b>Swift</b>
            </Span>
            <Span>
              <b>Naming Conventions:</b>
            </Span>
            <Span>Camel Case: aA</Span>
            <Span>Kebab Case: a-A</Span>
            <Span>Snake Case: a_A</Span>
            <Span>// is used for comments.</Span>
            <Span>{"File -> New -> Playground -> IOS -> Bundle"}</Span>
            <Span>{"Bottom Left -> Play Button -> Automatically Save"}</Span>
            <Span>{"Int Float Bool Double Array String Dictionary"}</Span>
            <Span>Int.random(in: 0...5)</Span>
            <Span>Float.random(in: 0...5)</Span>
            <p>ViewController.swift</p>
            <pre>
              {`
import UIKit
class ViewController: UIViewController {
    @IBOutlet weak var diceImageView1: UIImageView!
    @IBOutlet weak var diceImageView2: UIImageView!
    var leftDiceNumber = 1
    
    override func viewDidLoad() {
        super.viewDidLoad()
        // Do any additional setup after loading the view.
    }
   
    @IBAction func rollButtonPressed(_ sender: Any) {
        let diceArray =  [#imageLiteral(resourceName: "DiceOne"),#imageLiteral(resourceName: "DiceTwo"),#imageLiteral(resourceName: "DiceThree"),#imageLiteral(resourceName: "DiceFour"),#imageLiteral(resourceName: "DiceFive"),#imageLiteral(resourceName: "DiceSix")]
        diceImageView1.image = diceArray[Int.random(in: 0...5)]
        diceImageView2.image = diceArray[Int.random(in: 0...5)]
    } 
}
              `}
            </pre>
            <Span>
              <b>Cocoapods:</b>
            </Span>
            <Span>
              Cocoapods is built with ruby, all xcode dependencies for a project
              are specified in the podfile. Cocoapods will resolve dependencies
              between libraries, fetch resulting source code then link it
              together in xocde workspace to build the project.
            </Span>
            <Span>
              Xcode =&gt; Preferences =&gt; Location Name =&gt; Install Command
              Line Tools Line Tools
            </Span>
            <Span>Xcode =&gt; Prefernces =&gt; Components</Span>
            <Span>
              {`
sudo gem install cocoapods
sudo arch -x86_64 gem install fi
arch -x86_64 pod insatll              
              `}
            </Span>
            <Span>
              At React Native. we can install dependencies using following
              commands:
            </Span>
            <pre>
              {`
cd ios
pod init
pod install
rm -rf pods podfile podfile.txt
              `}
            </pre>
            <Span>Github File Structure:</Span>
            <Span>
              {`
.xcodeproj
<> => Base.lproj => LaunchScreen.storyboard And Main.storyboard
   => Assets.xcassets (AppIcon.co)
   => AppDelegate.swift
   => SwiftDelegate.swif=> ViewController.swift              
              `}
            </Span>
            <Span>
              <b>Constraints</b>
            </Span>
            <Span>
              Click at the top and turn dashed lines into solid lines.
            </Span>
            <Span>
              Types: Leading, Trailing, Bottom, Top, SuperView.CenterX,
              SuperView.CenterY, SuperView.Bottom, SuperView.Top,
              First.trailing, Second.trailing, Relative to the margin
            </Span>
            <Span>Alignment And Pinning:</Span>
            <Span>
              Alignment: Used to align center vertically or horizontally
            </Span>
            <Span>
              Pin: Distance which our elements should be placed from the edge of
              container view.
            </Span>
            <Span>
              Container and subviews. We can place multiple containers inside
              the main uiview and several subviews inside each container
            </Span>
            <Span>
              In order to create a new view, click at + icon. Then select view
              followed by uiview and drag container inside view.
            </Span>
            <Span>Stacks And Views</Span>
            <Span>
              We can stack views together either vertically or horizontally
            </Span>
            <Span>
              <b>Stacks And Views</b>
            </Span>
            <Span>StackView ==&gt; Distrinution ==&gt; Fill Equally</Span>
            <Span>Background ==&gt; System Background ==&gt; Default</Span>
            <Span>
              <b>AVFoundation Class</b>
            </Span>
            <Span>
              Doucmentation can be found at
              developer.apple.com/documentation/Audio/AudioPlayer
            </Span>
            <Span>Code:</Span>
            <pre>
              {`
import UIKit
import AVFoundation

class ViewController: UIViewController {

    override func viewDidLoad() {
        super.viewDidLoad()
    }
    
    var player: AVAudioPlayer?

    @IBAction func keyPressed(_ sender: UIButton) {
//        guard let url = Bundle.main.url(forResource: "C", withExtension: "wav") else {return}
//
//        do{
//            try AVAudioSession.sharedInstance().setCategory(.playback,mode: .default)
//            try AVAudioSession.sharedInstance().setActive(true)
//            player = try AVAudioPlayer(contentsOf:url,fileTypeHint: AVFileType.mp3.rawValue)
//            guard let player = player else { return }
//            player.play()
//        }catch let error{
//            print(error.localizedDescription)
//        }
        playSound(soundName:sender.currentTitle!);
    }
    
    func playSound(soundName:String){
        let url = Bundle.main.url(forResource: soundName, withExtension: "wav")
        player = try! AVAudioPlayer(contentsOf: url!)
        player?.play()
    }
    
}
             
             `}
            </pre>
            <Span>
              <b>Swift Fundamentsals:</b>
            </Span>
            <Span>Dictionary:</Span>
            <Span>let x = ["a" : 3, "b": 4]</Span>
            <Span>Array</Span>
            <Span>["a","b","c"]</Span>
            <Span>
              if a =3 {"{}"} else {"{}"}
            </Span>
            <Span>
              switch a {"{ case '3' : print(5) case '4' : print(9) }"}
            </Span>
            <Span>case 3 to 5</Span>
            <Span>for a in 1...5 {"{}"}</Span>
            <Span>
              Creating a new file <br />
              File -&lt; New -&lt; Playground -&lt; ios -&lt; blank <br />
              play -&lt; automatically save
            </Span>
            <Span>
              <b>Ranges:</b>
            </Span>
            <Span>
              <b>Closed Range:</b>
            </Span>
            <Span>
              a...b <br />
              a&lt;= &lt;=b <br /> a to b
            </Span>
            <Span>
              <b>Left Open Range:</b>
            </Span>
            <Span>
              a..&lt;b <br />
              a&lt;= &lt;b <br />
            </Span>
            <Span>
              <b>One Sided Range:</b>
            </Span>
            <Span>
              ...b &lt;b <br />
            </Span>
            <Span>Defining and unwarpping optionals:</Span>
            <Span>var pu1: String = nil is not correct.</Span>
            <Span>var pu1: String = nil is correct.</Span>
            <Span>a = nil print(a!) is not correct we can print(a)</Span>
            <Span>if a != nil {"{ print(a!) }"}</Span>
            <Span>5/2 = 2 , Float(5)/Float(2) = 2.5</Span>
            <Span>
              <b>Timer & Progress View</b>
            </Span>
            <pre>
              {`
import UIKit
import AVFoundation

class ViewController: UIViewController {
    
    @IBOutlet weak var progressBar: UIProgressView!
    @IBOutlet weak var titleLabel: UILabel!
    let eggTimes = ["Soft": 3, "Medium": 4, "Hard": 7]
    var timer = Timer()
    var player: AVAudioPlayer!
    var totalTime = 0
    var secondsPassed = 0
    
    @IBAction func hardnessSelected(_ sender: UIButton) {
        
        timer.invalidate()
        let hardness = sender.currentTitle!
        totalTime = eggTimes[hardness]!

        progressBar.progress = 0.0
        secondsPassed = 0
        titleLabel.text = hardness

        timer = Timer.scheduledTimer(timeInterval: 1.0, target:self, selector: #selector(updateTimer), userInfo:nil, repeats: true)
    }
    
    @objc func updateTimer() {
        if secondsPassed < totalTime {
            secondsPassed += 1
            progressBar.progress = Float(secondsPassed) / Float(totalTime)
            print(Float(secondsPassed) / Float(totalTime))
        } else {
            timer.invalidate()
            titleLabel.text = "DONE!"
            
            let url = Bundle.main.url(forResource: "alarm_sound", withExtension: "mp3")
            player = try! AVAudioPlayer(contentsOf: url!)
            player.play()
        }
    }
    
} 
  `}
            </pre>
            <Span>
              Design pattern allows us to organise and structure our code. (
              MVC, MVP, MVVM, VIPER )
            </Span>
            <Span>
              Apple, Spring =&gt; MVC ( Model View Controller ( it modifies
              model ad passed that to view and acts as a mediator ) )
            </Span>
            <Span>
              Android MVP =&gt; MVP ( Model View Presenter ) ( presenter listens
              b/w view and model )
            </Span>
            <Span>
              MVVM : View Model only passed certain part of model to the view.
            </Span>
            <Span>Immutability</Span>
            <Span>
              By default, struct is immutable as every variable is provided the
              keyword let. That's why we must use of a special keyword mutating.
            </Span>
            <Span>
              We can't call a mutating function using a instance which was
              declared with let declared. All its properties will become
              immutable.
            </Span>
            <pre>
              {`
String(format: "%.2f", sender.value)
Int(sender.value)
heightSliderChanged(_ sender: UISlider)
weightSliderChanged(_ sender: UISlider)
`}
            </pre>
            <Span>
              Project =&gt; MacOS =&gt;CommandLineTool =&gt; ProductName
            </Span>
            <pre>
              {`
let Skeleton = Empty()
let dragon = Dragon()              
dragon.move()
class Enemy{
var health = 0 
func move(){

}
}
class Dragon{
var a = 12
override func move(){
  super.attack()
  print();
}
}
`}
            </pre>
            UIButton &gt; UIControl &gt; UIView &gt; UIResponder &gt; NSObject
            <Span>Struct Vs Classes</Span>
            <Span>struct does not inheritance and are immutable.</Span>
            <pre>
              {`
struct {
  var health: Int // let cannot be used
  mutable func x(){
    self.health = 10
  }
}              
              `}
            </pre>
            <Span>Structs are passed by value</Span>
            <Span>Classes are passed by reference</Span>
            <pre>
              {`
import UIKit
                         
{
  performSegue(withIdentifier: "goToResult", sender.self)
}

override func prepare(for segue: UIStoryboardSegue, sender: Any?){
if segue.identifier == "goToResult" {
  let destination = segue.destination as !ResulttViewController
  destination.color = calc.getColor()
}
}
              `}
            </pre>
            <Span>Main.storyboard =&gt; new &gt; view controller</Span>
            <pre>
              {`
  import UIKit

  class secondVC: UIViewController {
    super.viewDidLoad()
    let label = UILabel
    label.text= "Sai"
    label.frame = CGRect(x:0,y:0,x:100,y:100)
    view.addSubView(label)
  }

  ViewController.swift

  let secondVC = SecondViewController()
  secondVC.bmi = "10"
  self.presnt(secondVC,connected: true,completion: null)
              `}
            </pre>
            <Span>
              In order to link few ui controllers, connect them via cmd + click
            </Span>
            <Span>
              <b>Swift Fundamentsals:</b>
            </Span>
            <Span>
              <b>1. Force Unwrapping:</b>
            </Span>
            <Span>
              let a = nil <br />
              let b = a! <br />
              Project will build successfully but during runtime it will crash
            </Span>
            <Span>
              <b>2. Check for nil value:</b>
            </Span>
            <Span>if a!= nil {"{ a = b! }"}</Span>
            <Span>
              <b>3. Optional Binding:</b>
            </Span>
            <Span>if let a =b {"{ a }"}</Span>
            <Span>
              <b>4. Nil Coalescing Operator:</b>
            </Span>
            <Span>a ?? b</Span>
            <Span>
              <b>5. Optional Chaining:</b>
            </Span>
            <Span>op?.a()</Span>
            <Span>
              Alamofire (can be installed using cocoapods or swiftUIPackages)
            </Span>
            <Span>
              portable network graphics gets pixelated when zoomed in
              <br />
              vector images doesn't get pixalated <br />
              e.g. -&gt; .pdf
              <br />
              check preserve vector data at inspector window of Assests.xcassets
            </Span>
            <Span>
              Useful textfield properties
              <br />
              ReturnKey -&gt; Go <br />
              autoCapitalize : true (london -&gt; London)
              <br />
              secureKey : true *******
              <br />
              textFieldShouldReturn
              <br />
              textFieldDidBeginEditing
              <br />
              textFieldShouldbeginEditing
              <br />
              textFieldDidEndEditing
              <br />
              textFieldShouldEndEditing
            </Span>
            <Span>
              <b>Swift Protocols</b>
            </Span>
            <Span>
              <pre>{`
protocol config(){
  canFly()
}

class A:config{ func canFly(){} }
class B:A,config{  override func canFly(){}  }

struct C:canFly{}
`}</pre>
              <Span>
                Protocols can be inherited via classes and structures.
              </Span>
              <Span>
                String Interpolation <br />
                print("ans:\(9+0)")
              </Span>
              <Span>Delegate design pattern</Span>
              <pre>
                {`
protocol AdvancedLifeSupport(){
   func performCPR()
}

class EmergencyCallHandler{
  var delegate:AdvancedLifeSupport?
  func assessSituation(){printf("Can you tell me what happened?")}
  func medicalEmergency(){ delegate?.performCPR() }  
}

struct Paramedic:AdvancedLifeSupport{

init(handler: EmergencyCallHandler){
   handler.delegate = self
}

func performCPR(){
  print("T")
}
}
let emilio = EmergencyCallback()
let p = Paramedic(handler : emilio)
emilio.assessSituation()

class Doctor: AdvancedLifeSupport{
   init(handler: EmergencyCallHandler){
     handler.delegate = self
   }
   func performCPR(){
     print("")
   }
}
class Surgeon:Doctor {
   override performCPR(){
     super.performCPR()
     print("")
   }
}
}
                `}
              </pre>
            </Span>
            <Span>
              EmergencyCallHandler doesn't care about what delegate is
              associated with . It can be class , struct or subclass.
            </Span>
            <Span>UTF-8 is the standardized protocol to encode te xt</Span>
            <Span>
              Define a protocol , make use of it in class, subclass or the
              struct. Attach a handler with its delegate property and init
              constructor ( handler.delegate = self ).Then, make us of this
              handler at some other class.
            </Span>
            <Span>
              <b>Closures</b>
            </Span>
            <pre>
              {`
import UIKit
func calculator(n1:Int,n2:Int)->Int{
  return n1 * n2
}
calculator(n1:2,n2:3) =>
func calculator(n1:Int,n2:Int,operation:(Int,Int)->Int) -> Int {
   return operator(n1,n2)
}
func add(no1:int,no2:Int)->Int {
   return no1 + no2
}
calculator(n1:2,n2:3,operator:add) =>
let result = calculator(n1:2,n2:3){$0*$1}
import UI
let array= [6,2,3,9,4,1]
func addOne(n1:Int)->Int{ return n1+1 }
array.map(addOne)
array.map({(n1,n2)->Int in return n!+1})
array.map{$0+1}
array.map{"\($0)"}  
  `}
            </pre>
            <Span>
              <b>Conversion to closure</b>
            </Span>
            <Span>highlight and press enter</Span>
            <Span>
              <b>Code formatting</b>
            </Span>
            <Span>cmd + I</Span>
            <Span>Type alias & protocols (JSON encoding & decoding)</Span>
            <Span>:Decodable :Encodable :Codable</Span>
            <Span>External parameter names</Span>
            <Span>
              func a(x y :Int){"{print(y)}"}{" "}
              {"func a(x:2){} func a(_ y:Int){print(y)}{}"}
              func a(2){}
            </Span>
            <Span>Markers</Span>
            <Span>//MARK:</Span>
            <b>DispatchQueue</b>
            <pre>
              {`
let task = URLSession.shared.dataTask(with:url){
  (data,response,error) if let data = data {
   self.label.text = "\(data.count)"
  }
  }
task.resume()  
              `}
            </pre>
            <b>Extensions</b>
            <pre>
              {`
var x=3.1234
x.round()
extension Double{
  func round(to places: Int){
  let p = pow(10,places)
  var n = self
  n= n * p
  n.round()
  n/=p
  return n
  }
}
extension WeatherViewController: WeatherManagerDelegate
extension WeatherViewController: CLLocationManagerDelegate
OR class WeatherViewController: UIViewController, WeatherManagerDelegate,CLLocationManagerDelegate             
              `}
            </pre>
            <Span>Model Directory:</Span>
            <b>WeatherData.swift</b>
            <pre>{`
import Foundation
struct WeatherData: Codable {
    let name: String
    let main: Main
    let weather: [Weather]
}
struct Main: Codable {
    let temp: Double
}
struct Weather: Codable {
    let description: String
    let id: Int
}
            `}</pre>
            <b>WeatherModel.swift</b>
            <pre>
              {`
import Foundation

struct WeatherModel {
    let conditionId: Int
    let cityName: String
    let temperature: Double
    
    var temperatureString: String {
        return String(format: "%.1f", temperature)
    }
    
    var conditionName: String {
        switch conditionId {
        case 200...232:
            return "cloud.bolt"
        case 300...321:
            return "cloud.drizzle"
        case 500...531:
            return "cloud.rain"
        case 600...622:
            return "cloud.snow"
        case 701...781:
            return "cloud.fog"
        case 800:
            return "sun.max"
        case 801...804:
            return "cloud.bolt"
        default:
            return "cloud"
        }
    }
    
}  
  `}
            </pre>
            <Span>Controllers</Span>
            <b>WeatherManager.swift</b>
            <pre>
              {`
import Foundation
import CoreLocation

protocol WeatherManagerDelegate {
    func didUpdateWeather(_ weatherManager: WeatherManager, weather: WeatherModel)
    func didFailWithError(error: Error)
}

struct WeatherManager {
    let weatherURL = "https://api.openweathermap.org/data/2.5/weather?appid=e72ca729af228beabd5d20e3b7749713&units=metric" 
    var delegate: WeatherManagerDelegate?
    func fetchWeather(cityName: String) {
        let urlString = "\(weatherURL)&q=\(cityName)"
        performRequest(with: urlString)
    }
    func fetchWeather(latitude: CLLocationDegrees, longitude: CLLocationDegrees) {
        let urlString = "\(weatherURL)&lat=\(latitude)&lon=\(longitude)"
        performRequest(with: urlString)
    }
    func performRequest(with urlString: String) {
        if let url = URL(string: urlString) {
            let session = URLSession(configuration: .default)
            let task = session.dataTask(with: url) { (data, response, error) in
                if error != nil {
                    self.delegate?.didFailWithError(error: error!)
                    return
                }
                if let safeData = data {
                    if let weather = self.parseJSON(safeData) {
                        self.delegate?.didUpdateWeather(self, weather: weather)
                    }
                }
            }
            task.resume()
        }
    }
    func parseJSON(_ weatherData: Data) -> WeatherModel? {
        let decoder = JSONDecoder()
        do {
            let decodedData = try decoder.decode(WeatherData.self, from: weatherData)
            let id = decodedData.weather[0].id
            let temp = decodedData.main.temp
            let name = decodedData.name   
            let weather = WeatherModel(conditionId: id, cityName: name, temperature: temp)
            return weather   
        } catch {
            delegate?.didFailWithError(error: error)
            return nil
        }
    }   
}              
              `}
            </pre>
            <b>WeatherViewController.swift</b>
            <pre>
              {`
import UIKit
import CoreLocation

class WeatherViewController: UIViewController {
    @IBOutlet weak var conditionImageView: UIImageView!
    @IBOutlet weak var temperatureLabel: UILabel!
    @IBOutlet weak var cityLabel: UILabel!
    @IBOutlet weak var searchTextField: UITextField!
    
    var weatherManager = WeatherManager()
    let locationManager = CLLocationManager()
    
    override func viewDidLoad() {
        super.viewDidLoad()
        
        locationManager.delegate = self
        locationManager.requestWhenInUseAuthorization()
        locationManager.requestLocation()
        
        weatherManager.delegate = self
        searchTextField.delegate = self
    }

}
//MARK: - UITextFieldDelegate
extension WeatherViewController: UITextFieldDelegate {
    
    @IBAction func searchPressed(_ sender: UIButton) {
        searchTextField.endEditing(true)
    }
    
    func textFieldShouldReturn(_ textField: UITextField) -> Bool {
        searchTextField.endEditing(true)
        return true
    }
    
    func textFieldShouldEndEditing(_ textField: UITextField) -> Bool {
        if textField.text != "" {
            return true
        } else {
            textField.placeholder = "Type something"
            return false
        }
    }
    
    func textFieldDidEndEditing(_ textField: UITextField) {
        
        if let city = searchTextField.text {
            weatherManager.fetchWeather(cityName: city)
        }
        
        searchTextField.text = ""
        
    }
}
//MARK: - WeatherManagerDelegate

extension WeatherViewController: WeatherManagerDelegate {
    
    func didUpdateWeather(_ weatherManager: WeatherManager, weather: WeatherModel) {
        DispatchQueue.main.async {
            self.temperatureLabel.text = weather.temperatureString
            self.conditionImageView.image = UIImage(systemName: weather.conditionName)
            self.cityLabel.text = weather.cityName
        }
    }
    
    func didFailWithError(error: Error) {
        print(error)
    }
}
//MARK: - CLLocationManagerDelegate

extension WeatherViewController: CLLocationManagerDelegate {
    
    @IBAction func locationPressed(_ sender: UIButton) {
        locationManager.requestLocation()
    }
    
    func locationManager(_ manager: CLLocationManager, didUpdateLocations locations: [CLLocation]) {
        if let location = locations.last {
            locationManager.stopUpdatingLocation()
            let lat = location.coordinate.latitude
            let lon = location.coordinate.longitude
            weatherManager.fetchWeather(latitude: lat, longitude: lon)
        }
    }
    
    func locationManager(_ manager: CLLocationManager, didFailWithError error: Error) {
        print(error)
    }
}
`}
            </pre>
            <Span>
              <b>Linking two files at storyboard file</b>
            </Span>
            <Span>
              select the left most icon of one screen at storyboard and drag it
              to other screen present in the same file.
            </Span>
            <Span>
              <b>Rebuild the app</b>
            </Span>
            <Span>
              <b>cmd + B</b>
            </Span>
            <Span>
              <b>Triggered segue and navigation controller</b>
            </Span>
            <Span>
              select left most icon of the screen and pick embed in navigation
              controller option.
            </Span>
            <Span>
              <b>Swift loops</b>
            </Span>
            <pre>
              {`
for _ in 1...5{print("s")}
override func viewDidLoad(){
   super.viewDidLoad()
   Timer.scheduledTime(withTimeInterval:0.1*charIndex,repeats:false){
     (timer) in self.titleLabel.text?.append(letter)
   }
}

override func viewWillAppear(_ animated:Bool){
   super.viewWillAppear(animated)
   navigationController?.isNavigationBarHidden = true
}

override func viewDidAppear(_ animated:Bool){
   super.viewDidAppear(animated)
   navigationController?.isNavigationBarHidden = false
}

while now < s{
 now = Date().timeIntervalsince1970
 print("...")
}
           `}
            </pre>
            <Span>
              <b>Third Party Libraries</b>
            </Span>
            <pre>
              {`
cocoapods.org
package dependency manager

Interface for ruby gems is a command line tool called gem which can install & manage libraries

Cocoapods manages library dependencies for xcode projects. Dependencies for project are specified in a single text file called a podfile
required ios version of a dependency can be checked at .podspecfile

following statements will be present at such files:
ios,'10.0'
              `}
            </pre>
            <Span>pod commands</Span>
            <Span>
              pod init <br />
              pod install
              <br />
              pod update
              <br />
              sudo arch -86_64x gem install fi
              <br />
              arch -86_64x pod install
              <br />
              when a line of code which defines a package is removed from
              podfile , close the xcode , execute pod install and reopen it
            </Span>
            <Span>
              <b>Package Manager Options</b>
            </Span>
            <Span>
              Cocoapods , carthage and swift package manager.
              <br />
              as of now very few supports swift package manager
              <br />
              file -&lt; swift packages -&lt; add package dependency -&lt;
              select project -&lt; github url
              <br />
              .podspec file -&lt; cocoapods <br />
              Package.Swift -&lt; SPM
            </Span>
            <Span>
              <b>Firebase Configuration</b>
            </Span>
            <Span>
              add project -&gt;
              <br />
              request ios app bundle app id-&gt;
              <br />
              download GoogleService-Info.plist configuration file
              <br />
              Update AppDelegate.swift
              <br />
              pod 'Firebase/Auth'
              <br />
              pod 'Firebase/Firestore'
              <br />
              pod install
            </Span>
            <b>Global Constants</b>
            <pre>
              {`
struct K {
  static let a = "A"
  static A {
    static let b = "B"
  }
}  
  `}
            </pre>
            <b>UITableView</b>
            <pre>
              {`
func tableView(.... numOfRowsInSelection
func tableView(.... cellForRowAtIndexPath
func tableView(.... didSelectRowAtIndexPath
              `}
            </pre>
            <b>Custom TableViewCell</b>
            <pre>{`
Views -> New Cocoa Touch Class -> Message Cell (Subclass of UITableViewCell)
check allow create xib file
            `}</pre>
            <b>hackiftakhar/IQKeyboardManager</b>
            <pre>
              {`
File ->Swift Packages -> Add Package Dependency
Edit AppDelegate.swiift
TableViewCell xib file -> interactions -> disable
              `}
            </pre>
            <b>ViewControllerLifeCycle</b>
            <pre>
              viewDidLoad() <br />
              viewWillAppear()
              <br />
              viewDidAppear()
              <br />
              viewWillUnappear()
              <br />
              viewDidDisappear()
            </pre>
            <br />
            <Span>
              <b>App Life Cycle Methods</b>
            </Span>
            <pre>
              Upto ios 12 , all life cycle methods used to be at
              AppDelegate.swift but as of ios13 , especially ipadOS there may be
              multiple instances of the app. If one of the window goes in
              background then scene delegate gets notified.
              <br />
              It treats each window as a separate screen.
              <br />
              N/W changes are handled by sceneDelegate.swift
              <br />
              Mutiple view controllers in one screne, multiple scenes in a app.
              -&gt; SceneDelegate
              <br />
              func sceneWillResignActive( sceneDidEnterBackground(
            </pre>
            <Span>constants.swift</Span>
            <pre>
              {`
struct K {
  static let appName = "⚡️FlashChat"
  static let cellIdentifier = "ReusableCell"
  static let cellNibName = "MessageCell"
  static let registerSegue = "RegisterToChat"
  static let loginSegue = "LoginToChat"
  struct BrandColors {
      static let purple = "BrandPurple"
      static let lightPurple = "BrandLightPurple"
      static let blue = "BrandBlue"
      static let lighBlue = "BrandLightBlue"
  }
  struct FStore {
      static let collectionName = "messages"
      static let senderField = "sender"
      static let bodyField = "body"
      static let dateField = "date"
  }
}              
              `}
            </pre>
            <Span>
              <b>Views</b>
            </Span>
            <p>MessageCell.swift</p>
            <pre>{`
       import UIKit
       class MessageCell: UITableViewCell {
           @IBOutlet weak var messageBubble: UIView!
           @IBOutlet weak var label: UILabel!
           @IBOutlet weak var rightImageView: UIImageView!
           @IBOutlet weak var leftImageView: UIImageView!
           override func awakeFromNib() {
               super.awakeFromNib()
               messageBubble.layer.cornerRadius = messageBubble.frame.size.height / 5
           }      
           override func setSelected(_ selected: Bool, animated: Bool) {
               super.setSelected(selected, animated: animated)
           }
       }     
            `}</pre>
            <p>We do have MessageCell.xlb as well</p>
            <Span>
              <b>Models</b>
            </Span>
            <b>Message.swift</b>
            <pre>{`
import Foundation
struct Message {
    let sender: String
    let body: String
}           
            `}</pre>
            <Span>
              <b>Controllers</b>
            </Span>
            <b>ChatViewController.swift</b>
            <pre>{`
import UIKit
import Firebase
class ChatViewController: UIViewController {
    @IBOutlet weak var tableView: UITableView!
    @IBOutlet weak var messageTextfield: UITextField!
    
    let db = Firestore.firestore()
    
    var messages: [Message] = []
    
    override func viewDidLoad() {
        super.viewDidLoad()
        tableView.dataSource = self
        title = K.appName
        navigationItem.hidesBackButton = true
        tableView.register(UINib(nibName: K.cellNibName, bundle: nil), forCellReuseIdentifier: K.cellIdentifier)
        loadMessages()
    }
    func loadMessages() {                    
        db.collection(K.FStore.collectionName)
            .order(by: K.FStore.dateField)
            .addSnapshotListener { (querySnapshot, error) in
            self.messages = []
            if let e = error {
                print("There was an issue retrieving data from Firestore. \(e)")
            } else {
                if let snapshotDocuments = querySnapshot?.documents {
                    for doc in snapshotDocuments {
                        let data = doc.data()
                        if let messageSender = data[K.FStore.senderField] as? String, let messageBody = data[K.FStore.bodyField] as? String {
                            let newMessage = Message(sender: messageSender, body: messageBody)
                            self.messages.append(newMessage)
                            
                            DispatchQueue.main.async {
                                   self.tableView.reloadData()
                                let indexPath = IndexPath(row: self.messages.count - 1, section: 0)
                                self.tableView.scrollToRow(at: indexPath, at: .top, animated: false)
                            }
                        }
                    }
                }
            }
        }
    }
    @IBAction func sendPressed(_ sender: UIButton) { 
        if let messageBody = messageTextfield.text, let messageSender = Auth.auth().currentUser?.email {
            db.collection(K.FStore.collectionName).addDocument(data: [
                K.FStore.senderField: messageSender,
                K.FStore.bodyField: messageBody,
                K.FStore.dateField: Date().timeIntervalSince1970
            ]) { (error) in
                if let e = error {
                    print("There was an issue saving data to firestore, \(e)")
                } else {
                    print("Successfully saved data.")
                    
                    DispatchQueue.main.async {
                         self.messageTextfield.text = ""
                    }
                }
            }
        }
    }
    @IBAction func logOutPressed(_ sender: UIBarButtonItem) {    
        do {
            try Auth.auth().signOut()
            navigationController?.popToRootViewController(animated: true)
        } catch let signOutError as NSError {
          print ("Error signing out: %@", signOutError)
        }
    }
}
extension ChatViewController: UITableViewDataSource {
    func tableView(_ tableView: UITableView, numberOfRowsInSection section: Int) -> Int {
        return messages.count
    }
    func tableView(_ tableView: UITableView, cellForRowAt indexPath: IndexPath) -> UITableViewCell {
        let message = messages[indexPath.row]
        let cell = tableView.dequeueReusableCell(withIdentifier: K.cellIdentifier, for: indexPath) as! MessageCell
        cell.label.text = message.body
        //This is a message from the current user.
        if message.sender == Auth.auth().currentUser?.email {
            cell.leftImageView.isHidden = true
            cell.rightImageView.isHidden = false
            cell.messageBubble.backgroundColor = UIColor(named: K.BrandColors.lightPurple)
            cell.label.textColor = UIColor(named: K.BrandColors.purple)
        }
        //This is a message from another sender.
        else {
            cell.leftImageView.isHidden = false
            cell.rightImageView.isHidden = true
            cell.messageBubble.backgroundColor = UIColor(named: K.BrandColors.purple)
            cell.label.textColor = UIColor(named: K.BrandColors.lightPurple)
        }        
     return cell
    }
}
            `}</pre>
            <b>LoginViewController.swift</b>
            <pre>{`
import UIKit
import Firebase
class LoginViewController: UIViewController {
    @IBOutlet weak var emailTextfield: UITextField!
    @IBOutlet weak var passwordTextfield: UITextField!
    @IBAction func loginPressed(_ sender: UIButton) {
        if let email = emailTextfield.text, let password = passwordTextfield.text {
            Auth.auth().signIn(withEmail: email, password: password) { authResult, error in
                if let e = error {
                    print(e)
                } else {
                    self.performSegue(withIdentifier: K.loginSegue, sender: self)
                }
            }
        }
    }
}

           `}</pre>
            <b>RegisterViewController.swift</b>
            <pre>{`
import UIKit
import Firebase
class RegisterViewController: UIViewController {
    @IBOutlet weak var emailTextfield: UITextField!
    @IBOutlet weak var passwordTextfield: UITextField!
    @IBAction func registerPressed(_ sender: UIButton) {
        if let email = emailTextfield.text, let password = passwordTextfield.text {
            Auth.auth().createUser(withEmail: email, password: password) { authResult, error in
                if let e = error {
                    print(e)
                } else {
                    //Navigate to the ChatViewController
                    self.performSegue(withIdentifier: K.registerSegue, sender: self)
                }
            }
        }
    }    
}

`}</pre>
            <b>WelcomeViewController.swift</b>
            <pre>{`
import UIKit
class WelcomeViewController: UIViewController {
    @IBOutlet weak var titleLabel: UILabel!
    override func viewWillAppear(_ animated: Bool) {
        super.viewWillAppear(animated)
        navigationController?.isNavigationBarHidden = true
    }
    override func viewWillDisappear(_ animated: Bool) {
        super.viewWillDisappear(animated)
        navigationController?.isNavigationBarHidden = false
    }
    override func viewDidLoad() {
        super.viewDidLoad()                    
        titleLabel.text = ""
        var charIndex = 0.0
        let titleText = K.appName
        for letter in titleText {
            Timer.scheduledTimer(withTimeInterval: 0.1 * charIndex, repeats: false) { (timer) in
                self.titleLabel.text?.append(letter)
            }
            charIndex += 1
        }  
    }
}`}</pre>
            <br />
            <Span>
              Swift UI is apple's brand new UI framework built with declarative
              swift code.
            </Span>
            <Span>
              Since SwiftUI uses UIkit and AppKit behind the scenes, this means
              that rendering isn't any faster. However, in terms of development
              build time, SwiftUI usually performs better than UIkit. That's
              because the hierarchy of view resides in value-type structs stored
              on the stack, which means no costly memory allocations.
            </Span>
            <Span>
              <b>Imperative Vs Declarative Programming</b>
            </Span>
            <Span>
              Declarative programming is a paradigm in which programmer defines
              what needs to be accomplished w/o defining how it needs to be
              implemented.
              <br />
              Types: Functional , Logic
              <br />
              Imperative programming is a programming paradigm that uses
              statements that change a program’s state.
              <br />
              Types : Structural, Modular , Procedural
            </Span>
            <Span>
              <b>SwiftUI or UIKit</b>
            </Span>
            <Span>
              SwiftUI, Apple’s new declarative programming framework, was
              introduced along with iOS 13 in September 2019.
            </Span>
            <Span>
              <b>UIKit</b>
            </Span>
            <Span>
              UIKit provides a variety of objects which you can use to develop
              apps for iOS. These objects, such as UIView and its subclasses,
              allow for the display and interaction of content within your app.
              UIKit apps generally make use of the Model-View-Controller (MVC)
              design pattern.
              <br />
              UIKit has been the backbone of UI development on iOS for over a
              decade. It is a mature platform that sees use in just about every
              iOS application in existence. Since it is well established, there
              is an abundance of resources available in case you get stuck or
              have questions.
              <br />
              UIKit apps can be built in a few different ways:
              <br />
              1. Leveraging Interface Builder in order to design a UI without
              writing any code. Interface Builder is integrated into Xcode and
              allows for editing of .storyboard and .xib files, both of which
              describe layouts using XML.
              <br />
              2. A code focused approach where views and layout constraints are
              defined in Swift (or Objective-C).
              <br />
              3. A mix of the above two approaches
            </Span>
            <Span>
              <b>SwiftUI</b>
            </Span>
            <Span>
              SwiftUI is Apple’s new declarative programming framework used to
              develop apps for iOS and Mac using Swift. The declarative approach
              is the key differentiator when comparing SwiftUI to UIKit. In
              UIKit you mediate the relationship between events and changes to
              presentation. With SwiftUI the need to mediate that relationship
              disappears since that is handled by the framework itself.
            </Span>
            <Span>
              As far as building apps with SwiftUI, things are a bit more
              streamlined when compared to UIKit:
              <br />
              1. Xcode displays the visual editor alongside any file that
              contains a SwiftUI view, displaying a live representation of the
              view you are building. You can still interactively design on the
              canvas, just like in Interface Builder.
              <br />
              2. .storyboard and .xib files are not used in SwiftUI. The Swift
              code itself describes the layout rather than these opaque XML
              files.
            </Span>
            <Span>
              Here are some additional notes and caveats:
              <br />
              1. Widgets built using WidgetKit are required to use SwiftUI. This
              is the only case as of now (iOS 14), but given Apple’s technology
              and API history, we anticipate a time will come when you will need
              to use SwiftUI in order to leverage the latest Apple features.
              <br />
              2. Swift developers can take advantage of Apple’s native libraries
              and design elements across all of its platforms. For example, you
              can’t do a native UI on watchOS if you don’t use SwiftUI.
              <br />
              3. SwiftUI requires iOS 13 or later. It might not be right for
              apps where backwards compatibility is important.
              <br />
              4. It wasn’t until iOS 14 and the introduction of the App and
              Scene protocols that SwiftUI became viable for building whole
              apps.
              {/* https://bignerdranch.com/blog/learning-apples-swiftui-or-uikit-which-one-is-right-for-you-right-now/#:~:text=SwiftUI%20is%20Apple's%20new%20declarative,events%20and%20changes%20to%20presentation.&text=xib%20files%20are%20not%20used%20in%20SwiftUI. */}
            </Span>
            <Span>
              <b>Using SwiftUI and UIKit Together</b>
            </Span>
            <Span>
              UIViewRepresentable is a protocol provided by the SwiftUI
              framework. Using this protocol it is possible to wrap an instance
              of a UIKit view so that it can be displayed with SwiftUI
            </Span>
            <Span>Defining a UIKit view wrapper with UIViewRepresentable:</Span>
            <pre>{`
@available(iOS 13, *)
struct MyUIKitView: UIViewRepresentable {
    func makeUIView(context: Context) -> UITextView {
        let view = UIView()
        view.backgroundColor = .blue
        return view
    }            
    func updateUIView(_ uiView: UITextView, context: Context) {
    }
}
            `}</pre>
            <Span>Using the wrapped UIKit view in SwiftUI:</Span>
            <pre>{`
struct ContentView: View {
  var body: some View {
     VStack {
        Text("Hello from UIKit!")
        MyUIKitView()
     }
  }
  
            `}</pre>
            <Span>
              <b>Using SwiftUI in UIKit</b>
            </Span>
            <Span>
              UIHostingController is a view controller which allows for the
              display of SwiftUI views into a UIKit view hierarchy. It can be
              used just like any other view controller in UIKit:
            </Span>
            <Span>
              let hostingController = UIHostingController(rootView: Text("Hello
              from SwiftUI!")) <br />
              present(hostingController, animated: true)
            </Span>
            <Span>
              <b>Overcome Immutability of struct in SwiftUI</b>
            </Span>
            <Span>
              swift provides special wrapper @state to overcome immutability of
              structs. <br />
              struct ContentView:View {"{ @state var left = 1 }"}
            </Span>
            <Span>
              <b>Running app on mac using product catalyst</b>
            </Span>
            <Span>
              project -&lt; target -&lt; mac <br />
              signing & capabilities -&lt; apple ID
            </Span>
            <Span>
              <b>Update Preview</b>
            </Span>
            <Span>Command + Option + P</Span>
            <Span>
              <b>Network Handling</b>
            </Span>
            <Span>
              @ObservedObject and @Published can be used along with
              NetworkManager
            </Span>
            <Span>
              <b>Custom Fonts</b>
            </Span>
            <Span>
              At info.plist , update Fons provided by the application key (for
              e.g. Pacifico-Regular.ttf)
            </Span>
            <Span>ContentView.swift</Span>
            <Span>Example 1:</Span>
            <pre>
              {`
import SwiftUI
struct ContentView: View {
    var body: some View {
        ZStack {
            Color(.systemTeal)
                .edgesIgnoringSafeArea(.all)
            VStack {
                Text("I Am Rich")
                    .font(.system(size: 40))
                    .fontWeight(.bold)
                    .foregroundColor(Color.white)
                Image("diamond")
                    .resizable()
                    .aspectRatio(contentMode: .fit)
                    .frame(width: 200, height: 200, alignment: .center)
            }
        }
    }
}
struct ContentView_Previews: PreviewProvider {
    static var previews: some View {
        ContentView().previewDevice(PreviewDevice(rawValue:"iPhone SE"))
    }
}
`}
            </pre>
            <Span>Example 2:</Span>
            <pre>{`
import SwiftUI
struct ContentView: View { 
    @State var leftDiceNumber = 1
    @State var rightDiceNumber = 1
    var body: some View {
        ZStack {
            Image("background")
                .resizable()
                .edgesIgnoringSafeArea(.all)
            VStack {
                Image("diceeLogo")
                Spacer()
                HStack {
                    DiceView(n: leftDiceNumber)
                    DiceView(n: rightDiceNumber)
                }
                .padding(.horizontal)
                Spacer()
                Button(action: {
                    self.leftDiceNumber = Int.random(in: 1...6)
                    self.rightDiceNumber = Int.random(in: 1...6)
                }) {
                    Text("Roll")
                        .font(.system(size: 50))
                        .fontWeight(.heavy)
                        .foregroundColor(.white)
                        .padding(.horizontal)
                }
                .background(Color.red)
            }
        }
    }
}
struct DiceView: View {
    let n: Int   
    var body: some View {
        Image("dice\(n)")
            .resizable()
            .aspectRatio(1, contentMode: .fit)
            .padding()
    }
}
struct ContentView_Previews: PreviewProvider {
    static var previews: some View {
        ContentView()
    }
}            
            `}</pre>
            <Span>Example 3:</Span>
            <Span>Models:</Span>
            <Span>
              <b>NetworkManager.swift</b>
            </Span>
            <pre>{`import Foundation
class NetworkManager: ObservableObject {
    @Published var posts = [Post]()
    func fetchData() {
        if let url = URL(string: "https://hn.algolia.com/api/v1/search?tags=front_page") {
            let session = URLSession(configuration: .default)
            let task = session.dataTask(with: url) { (data, response, error) in
                if error == nil {
                    let decoder = JSONDecoder()
                    if let safeData = data {
                        do {
                            let results = try decoder.decode(Results.self, from: safeData)
                            DispatchQueue.main.async {
                                self.posts = results.hits
                            }
                        } catch {
                            print(error)
                        }
                    }
                }
            }
            task.resume()
        }
    }
}`}</pre>
            <Span>
              <b>PostData.swift</b>
            </Span>
            <pre>{`import Foundation
struct Results: Decodable {
    let hits: [Post]
}
struct Post: Decodable, Identifiable {
    var id: String {
        return objectID
    }
    let objectID: String
    let points: Int
    let title: String
    let url: String?
}`}</pre>
            <Span>Models:</Span>
            <Span>ContentView.swift</Span>
            <pre>
              {`
import SwiftUI
struct ContentView: View {
    @ObservedObject var networkManager = NetworkManager()
    var body: some View {
        NavigationView {
            List(networkManager.posts) { post in
                NavigationLink(destination: DetailView(url: post.url)) {
                    HStack {
                        Text(String(post.points))
                        Text(post.title)
                    }
                }
            }
            .navigationBarTitle("H4X0R NEWS")
        }
        .onAppear {
            self.networkManager.fetchData()
        }
    }
}
struct ContentView_Previews: PreviewProvider {
    static var previews: some View {
        ContentView()
    }
}              
//
//let posts = [
//    Post(id: "1", title: "Hello"),
//    Post(id: "2", title: "Bonjour"),
//    Post(id: "3", title: "Hola")
//]
              `}
            </pre>
            <Span>DetailView.swift</Span>
            <pre>{`
import SwiftUI
struct DetailView: View {
    let url: String?   
    var body: some View {
        WebView(urlString: url)
    }
}
struct DetailView_Previews: PreviewProvider {
    static var previews: some View {
        DetailView(url: "https://www.google.com")
    }
}

              `}</pre>
            <Span>WebView View.swift</Span>
            <pre>{`
import Foundation
import WebKit
import SwiftUI
struct WebView: UIViewRepresentable {
    let urlString: String?
    func makeUIView(context: Context) -> WKWebView {
        return WKWebView()
    }
    func updateUIView(_ uiView: WKWebView, context: Context) {
        if let safeString = urlString {
            if let url = URL(string: safeString) {
                let request = URLRequest(url: url)
                uiView.load(request)
            }
        }
    }
}            
            `}</pre>
            <Span>UITableView</Span>
            <Span>
              numberOfRowsInSelection <br />
              cellForRowAt
              <br />
              didSelectRowAt
              <br />
              deselectRow
              <br />
              dequeueReusableCell
              <br />
              cellForRow
            </Span>
            <Span>Reload TableView Data</Span>
            <Span>
              self.tableView.reloadData()
              <br />
              App Launched -&gt; App Visible -&gt; App Receeds Into Background
              -&gt; Resources Reclaimed
            </Span>
            <Span>Various Ways Of Storing Data</Span>
            <Span>
              UserDefaults -&gt; small piece of data <br />
              Codable -&gt; plist <br />
              KeyChain -&gt; passwords <br />
              SQLite -&gt; large amount of data <br />
              CoreData -&gt; object oriented database <br />
              Realm -&gt;faster and easier to use
            </Span>
            <Span>CoreData Terminology</Span>
            <Span>
              Entity -&lt; Class -&lt; Taale
              <br />
              Property -&lt; Attribute -&lt; Field
            </Span>
            <Span>Colors : Chamelon</Span>
            <Span>Execute code under main thread from separate thread</Span>
            <pre>{`
func searchBar(_ searchBar:UISearchBar,textDidChange searchText:String){
  DispatchQueue.main.async{
    searchBar.reassignFirstResponder()
  }
}

            `}</pre>
            <p>Packages</p>
            <pre>
              {`
PropertyListEncoder
PropertyListDecoder
UserDefaults.standard
NSSearchPathForDirectoriesInDomain
NSPersistentContainer
NSFetchRequest
NSPredicate
Item()

  `}
            </pre>
            <p>Advanced Swift Operations:</p>
            <pre>
              {`

var b:Int {
  get { a - 4 }
  set { print(newValue) }
}            

              `}
            </pre>
            <pre>
              {`
              
var a:Int = 10 {
  willSet {
    print(newValue) 
  }
  didSet {
    print(newValue) 
  }
}                     

              `}
            </pre>
            <pre>{`
            
            if let a = b {
              if c == true {
                  
              }
              x = y
            }
            
            `}</pre>
            <Span>
              <b>Swift Access Levels</b>
            </Span>
            <Span>1. private</Span>
            <Span>2. filepointer</Span>
            <Span>3. internal</Span>
            <Span>4. public</Span>
            <Span>Swift Optionals</Span>
            <Span>Guard</Span>
            <Span>
              guard let number = Double(x.txt) else {"{ fatalError('')}"}
            </Span>
            <Span>Struct live on stack whereas classes live on heap.</Span>
            <Span>tuples</Span>
            <Span>
              x: ("a",3) <br />
              x: (a:"a",b:3) <br />
              print("\(x.a)\(x.b)")
            </Span>
            <Span>
              if let n! = x?.n1, let 0 = y1?.n2 {"{ print('\\(n1) \\(n2)') }"}
            </Span>
            <Span>
              TestFlight makes it easy to invite users to test your apps and App
              Clips and collect valuable feedback before releasing your apps on
              the App Store. You can invite up to 10,000 testers using just
              their email address or by sharing a public link.
            </Span>
          </>
        ),
        types: {},
      },
      Go: {
        title: "Go",
        content: (
          <>
            <Span>
              <b>Installation Guide:</b>
            </Span>
            <Span>
              Go to golang.org and choose download option. Then at the download
              page, select appropriate arm based verison for m1 mac.
            </Span>
            <Span>After installatin, setup can be deleted</Span>
            <Span>Go to /usr/local/go</Span>
            <Span>Execute following command:</Span>
            <Span>export GOROOT=/usr/local/go</Span>
            <Span>
              export GOPATH=$HOME/Desktop/folders/projects/go-workspace
            </Span>
            <Span>export PATH=$GOPATH/bin:$GOROOT/bin:$PATH</Span>
            <Span>
              Go is very useful for multi-threading. It has lot of features that
              are very simple.
            </Span>
            <Span>
              <b>Goroutines</b>
            </Span>
            <Span>
              They are light weight threads of execution managed by the go
              runtime.
            </Span>
            <Span>They are just functions that can be run concurrently</Span>
            <Span>
              It's simple to start goroutine . That's it's used widely for
              backend app that needs concurrency.
            </Span>
            <pre>
              {`package main

import (
	"fmt"
	"time"
)

func spinner(delay time.Duration) {
	for {
		for _, x := range \`-\|/\` {
			fmt.Printf("\r%c", x)
			time.Sleep(delay)
		}
	}
}

func waitAndPrint(delay time.Duration) {
	fmt.Println("Working")
	time.Sleep(delay)
	fmt.Println("Fn")
}

func main() {
	go spinner(100 * time.Millisecond)
	waitAndPrint(10 * time.Second)
}`}
            </pre>
            <Span>
              <b>Channels</b>
            </Span>
            <Span>
              Imagine we have lot of go routines, Channels are used to
              communicate between various goroutines. Each channel is a condult
              for values of a particular type called the channel element type.
            </Span>
            <pre>{`
package main
import (
  "fmt"
  "time"
)
func other(c chan string) {
  time.Sleep(5 * time.Second)
  c <- " i am a goroutine"
}
func main() {
  done := make(chan string)
  defer close(done)
  go other(done)
  // wait for data to come
  data := <-done
  fmt.Println(data)
}            
            `}</pre>
            <Span>
              <b>Mutex</b>
            </Span>
            <Span>
              A Mutex is a method used as a locking mechanism to ensure that
              only one Goroutine is accessing the critical section of code at
              any point of time. This is done to prevent race conditions from
              happening. Sync package contains the Mutex
            </Span>
            <Span>
              In 2006, Intel released the first dual core processor. In 2007,
              Google started creating the go programming language. They wanted
              to advantage of multiple cores. Hence, Go is the first viable
              language which was wriiten to take advantage of multiple cores.
              It's statically typed, garbage compiled, to run on distributed
              environments across multiple machines and easily take advantage of
              that.It's built time is super fast. Google wanted fast build, fast
              efficient compilation, efficient execution and easy programming
              such as python but it has advantage of compiled time, GC ans
              static type checking. People who helped in creating c, UTF-8 (
              world's most popular encoding scheme ) and UNIX OS contributed
              towards creation of go. Youtube is about all re-written in go.
              2009 =&gt; open sourced and first version was released at 2012. Go
              is really popular in China because of its large population.
            </Span>
            <Span>
              Server Side : Go &gt; Node.js &gt; Python &gt; Ruby &gt; PHP
            </Span>
            <Span>
              People such as Rob Pike ( UTF-8 ), Ken Thompson ( Unix OS, He
              re-wrote the Unix kernel in C ), and Robert Griesemer ( V8 JS
              Engine ) led the project.
            </Span>
            <Span>
              Go was built to do what google does. Google is rewriting Google
              with Go. Youtube is about all re-written in go
            </Span>
            <Span>Commands :</Span>
            <Span>go mod init _____</Span>
            <Span>go build ___.go</Span>
            <Span>go run ___.go</Span>
            <Span>go build github.com/saiashish9/..... ./_____</Span>
            <Span>
              <b>Variables:</b>
            </Span>
            <Span>Short Variable Declarator Operator:</Span>
            <Span>x:= 7 fmt.Println(x)</Span>
            <Span>fmt.Printf("%T",x)</Span>
            <Span>Block Level Scope</Span>
            <pre>
              {`
func main(){
  x := 10
  fmt.Println(x)
}              
              `}
            </pre>
            <Span>Package Level Scope</Span>
            <pre>
              {`
package main

import "fmt"

var x = 10

var y int

func main(){
  fmt.Println(x)
  fmt.Println(y) // 0
}
              `}
            </pre>
            <Span>
              <b>Composite Literal</b>
            </Span>
            <Span>
              <b>Slice</b>
            </Span>
            <p>It is a datastructure used to hold a list of things.</p>
            <pre>{`
a := []int{1, 2, 3}
fmt.Println(a, a[:2])
            `}</pre>
            <Span>
              <b>Map</b>
            </Span>
            <pre>{`
m := map[string]int{ "a" : 5, "b" : 7}
            `}</pre>
            <Span>
              <b>Struct</b>
            </Span>
            <pre>
              {`
type ht int
type person struct {
  fn string
  ln string
}         
func main(){
var t ht
t = 7
}
p := person{"a","b"}
         `}
            </pre>
            <Span>
              <b>Functions</b>
            </Span>
            <Span>{`func (receiver) identifier(parameters)`}</Span>
            <pre>{`
func (p person) speak(){
  fmt.Println(p.fname,\`"1235"\`)
}            
p := person{"a","b"}
p.speak()
            `}</pre>
            <Span>Nested Struct</Span>
            <pre>
              {`
type struct secretAgent {
  person
  license bool
}         
s1:= secretAgent {
  person {
    "a",
    "b"
  },
  true
}
s1.person.speak()
              `}
            </pre>
            <Span>Interfaces And Polymorphism</Span>
            <pre>{`
type human innterface {
  speak()
}       

func saySomething(h human){
  h.speak()
}

saySonething(p1)
saySonething(s1)

            `}</pre>
            <Span>Fundamentals</Span>
            <pre>{`
// i:=42 is not valid here
var i = 42 
// i => local level access
// I => global level access
func main(){
  var i int = 42
  var j float32 = 27.6
  fmt.Printf("%v ,%T", j, j)
  var q string
  q = string(i)
  q = strconv.Itoa(p)
  // variable blocks
  var (
	  i = 32
  )
  var (
    x string = "A"
    y string = "B"
  )
  fmt.Println(x, y)
  var n bool = true
  n = 1==1
  var i int8 = 6
  // 6 == 6 false
  // int(6) == 6 true
  // int8 +128 - 127
  // int16 -32768 - 32767
  // int32 int64
  // var n uint16 = 42
  // uint32 uint64 X
  // uint8 -> byte common
  var i complex64 = 1 + 2i
  i = complex(5, 6)
  fmt.Println(i)
  fmt.Println(real(i), imag(i))
  s := "abcd"
  println("abcd"[2], s[2])
  // s[2] = "j" is not valid
  // & | ^ &^ xnor << >>
  rune '' int32
  r := 'a'
  fmt.Println(r)
  var r rune = 'i'
  fmt.Println(r)
  const myConst int = 42
  const c float32 = math.Sin(1.57) X
  const (
    a = iota
    b = iota
    c = iota
  )
  const (
    a2 = iota
    b2
  )
  var c2 int = a2
  fmt.Println(a2 == c2)
  const (
    _ = 5
    a
    b
    c
  )
  // 5 5 5
  println(a, b, c)
  const (
    _ = iota + 5
    a
    b
    c
  )
  println(a, b, c)
  // 6 7 8
  const (
    _  = iota
    KB = 1 << (10 * iota)
    MB
    GB
    TB
    PB
    EB
    ZB
    YB
  )
  fileSize := 4000000000.00
  fmt.Printf("%.2fGB", fileSize/GB)
  const (
    a = 1 << iota
    b
    c
    e
  )
  1 2 4 16
  var d byte = a | b | c
  println(d)
  fmt.Printf("%b\\n", d)
  fmt.Printf("%v\\n", a&d == a)
  fmt.Printf("%v\\n", a&e == a)
  _ = iota // write only value
  grades := [3]int{97, 85}
  g := [...]int{1, 2}
  var students [3]string
  fmt.Println(students)
  students[0], students[1] = "a", "b"
  fmt.Println(students, len(students))
  fmt.Printf("%v \\n%v \\n", grades, g)
  var a [3][3]int = [3][3]int{
  	[3]int{1, 0, 1},
  	[3]int{0, 0, 1},
  	[3]int{1, 0, 0}}
  fmt.Println(a)
  a := [...]int{1, 2, 3}
  b := a
  c := &a
  c[1] = 5
  b[1] = 5
  fmt.Println(a, b, c)
  // slice
  a := []int{1, 2, 3}
  fmt.Println(a, a[:2])
  a := make([]int, 3, 1000)
  fmt.Println(len(a), cap(a))
  a = append(a, 1, 2)
  b := []int{}
  b = append(b, []int{2, 3, 4}...)
  fmt.Println(b)
  fmt.Println(a)
  a := []int{1, 2, 3, 4, 5}
  fmt.Println(a)
  b := a[:len(a)]
  fmt.Println(b)
  }            
            `}</pre>
            <Span>Constructs</Span>
            <pre>{`
for i := 0; i < 10; i++ {
	if i%2 == 0 {
		continue
	}
	fmt.Println(i)
}
s := []int{1, 2, 3}
fmt.Println(s)
for k, v := range s {
	fmt.Println(k, v)
}
x := map[string]int{
	"a": 1,
}
for _, p := range x {
	fmt.Println(p)
}
for j := 7; j <= 9; j++ {
	fmt.Println(j)
}
for {
	fmt.Println("loop")
	break
}           
s := []int{1, 2, 3}
fmt.Println(s)
for k, v := range s {
  fmt.Println(k, v)
}   
for i, j := 0; i < 5; i, j = i+1, j+1 {
  fmt.Println(i, j)
}
for i := 0; i < 10; i++ {
  if i%2 == 0 {
    continue
  }
  fmt.Println(i)
}
s := map[string]int{
  "a": 1,
  "b": 2,
}
if c, d := s["a"]; d {
  fmt.Println(c)
}
myNum := 0.123
if math.Abs(myNum/math.Pow(math.Sqrt(myNum), 2)-1) < 0.001 {
  fmt.Println("same")
} else if 0 == 0 {
  fmt.Println("diff")
}
switch i := 2 + 3; i {
case 1, 2:
  fmt.Println("one")
case 3:
  fmt.Println("two")
default:
  fmt.Println("neither one nor two")
}
i := 2
switch {
case i <= 10:
  fmt.Println("<=0")
  fallthrough
case i >= 20:
  fmt.Println(">=0")
}
var i interface{} = 1
i = 2.2
i = true
switch i.(type) {
case int:
  fmt.Println("i is an int")
case string:
  fmt.Println("string")
case bool:
  fmt.Println("bool")
case float32:
  fmt.Println("float32")
  fmt.Println(i)
}
for i := 0; i < 5; i += 2 {
  fmt.Println(i)
}
// i++ X
// i=0 , j=0 X
for i, j := 0; i < 5; i, j = i+1, j+1 {
  fmt.Println(i, j)
}
for i := 0; i < 10; i++ {
  if i%2 == 0 {
    continue
  }
  fmt.Println(i)
}
            `}</pre>
            <Span>
              <b>Functions:</b>
            </Span>
            <pre>{`
package main
import "fmt"
func main() {
	sayMsg("Go")
	greeting := "Hello"
	name := "Sai"
	sayGreeting(&greeting, &name)
	fmt.Println(name)
	sum(1, 2, 3, 4)
	g := greeter{
		"hi", "Go",
	}
	g.greet()
	fmt.Println(g.name)
}
type greeter struct {
	greeting string
	name     string
}

func (g *greeter) greet() {
	fmt.Println(g)
	g.name = ""
}
func sum(values ...int) {
	fmt.Println(values)
	result := 0
	for _, v := range values {
		result += v
	}
	fmt.Println(result)
}
func sayGreeting(greeting, name *string) {
	fmt.Println(*greeting, *name)
	*name = "Sai9"
	fmt.Println(*name)
}
func sayMsg(msg string) {
	fmt.Println(msg)
}            
            `}</pre>
            <Span>
              <b>Interfaces:</b>
            </Span>
            <pre>{`
package main
import "fmt"
func main() {
	var w Writer = ConsoleWriter{}
	w.Write([]byte("Hello Go!"))
	myInt := IntCounter(0)
	var inc Incrementer = &myInt
	for i := 0; i < 10; i++ {
		fmt.Println(inc.Increment())
	}
}
func (ic *IntCounter) Increment() int {
	*ic++
	return int(*ic)
}
type IntCounter int
type Incrementer interface {
	Increment() int
}
type Writer interface {
	Write([]byte) (int, error)
}
type Closer interface {
	Close() error
}
type WriterCloser interface {
	Writer
	Closer
}
type ConsoleWriter struct{}
func (cw ConsoleWriter) Write(data []byte) (int, error) {
	n, err := fmt.Println(string(data))
	return n, err
}            
            `}</pre>
            <Span>
              <b>Tags</b>
            </Span>
            <pre>{`
package main
import (
	"fmt"
	"reflect"
)
type Animal struct {
	Name   string \`required max:"100"\`
	Origin string
}
func main() {
	t := reflect.TypeOf(Animal{})
	field, _ := t.FieldByName("Name")
	fmt.Println(field.Tag)
}
            
            `}</pre>
            <Span>
              <b>Pointers</b>
            </Span>
            <pre>{`
package main
import "fmt"
func main() {
	var a int = 42
	b := a
	fmt.Println(a, b)
	a = 27
	fmt.Println(a, b)
	var a int = 42
	var b *int = &a
	fmt.Println(a, b, *b)
	a = 27
	fmt.Println(a, *b)
	a := [3]int{1, 2, 3}
	b := &a[0]
	c := &a[1] - 4
	fmt.Println("%v %p %p\\n", a, b, c)
	var ms *myStruct
	ms = new(myStruct)
	ms = myStruct{foo: 42}
	(*ms).foo = 42
	// compiling helping us out
	ms.foo = 42
	fmt.Println(ms, ms.foo, (*ms).foo)
}
type myStruct struct {
	foo int
}
`}</pre>
            <Span>
              <b>Structs</b>
            </Span>
            <pre>{`
package main
import "fmt"
type Doctor struct {
	number     int
	actorName  string
	companions []string
}
type A struct {
	name   string
	origin string
}
type B struct {
	A
	Canfly bool
}
func main() {
	s := map[string]int{
		"a": 1,
		"b": 2,
	}
	s["a"] = 3
	delete(s, "a")
	_, b := s["a"]
	// no need to make use of _ again
	fmt.Println(s, b)
	a := Doctor{
		number:    3,
		actorName: "Sai",
		companions: []string{
			"a",
			"b",
		},
	}
	b := Doctor{
		3,
		"Sai",
		[]string{
			"a",
			"b",
		},
	}
	a := struct{ name string }{name: "Sai"}
	b := &a
	b.name = "Ashish"
	fmt.Println(a, b)
	// go doesn't supports traditional oop features
	// go doesn't have inheritance
	// fmt.Println(b.actorName)
	b := B{}
	b.Canfly = true
	b.name = "Sai"
	fmt.Println(b, b.name)
	type A struct {
		name   string
		origin string
	}
	type B struct {
		A
		destination string
	}
	b := B{
		A: A{"sai", "123"},
		destination: "delhi",
	}
	fmt.Println(b)
}            
            `}</pre>
            <Span>
              <b>Routines</b>
            </Span>
            <Span>
              A goroutine is a lightweight thread of execution used for
              concurrency.
            </Span>
            <Span>Example 1</Span>
            <pre>
              {`
package main
import (
  "fmt"
  "time"
)
func f(from string) {
  for i := 0; i < 3; i++ {
      fmt.Println(from, ":", i)
  }
}
func main() {  
  f("direct")
  go f("goroutine")
  go func(msg string) {
      fmt.Println(msg)
  }("going")  
  time.Sleep(time.Second)
  fmt.Println("done")
}
go run goroutines.go
direct : 0
direct : 1
direct : 2
goroutine : 0
going
goroutine : 1
goroutine : 2
done  
  `}
            </pre>
            <Span>Example 2</Span>
            <pre>{`
package main
import (
	"fmt"
	"sync"
)
var wg = sync.WaitGroup{}
var counter = 0
var a = sync.RWMutex{}
func main() {
	// runtime.GOMAXPROCS(100)
	for i := 0; i < 10; i++ {
		wg.Add(2)
		go sayHello()
		go increment()
	}
	wg.Wait()
}
func sayHello() {
	a.RLock()
	fmt.Println(counter)
	a.RUnlock()
	wg.Done()
}
func increment() {
	a.Lock()
	counter++
	a.Unlock()
	wg.Done()
}
go run main.go
1
2
2
2
2
2
2
2
2
3
w/o mutexes
0
2
3
4
4
5
6
7
8
10
`}</pre>
            <pre>
              {`
package main
import (
	"fmt"
	"sync"
	"time"
)
var wg = sync.WaitGroup{}
func main() {
	var msg = "hello"
	wg.Add(1)
	go func(msg string) {
		fmt.Println(msg)
		wg.Done()
	}(msg)
	msg = "Good Bye"
	wg.Wait()
	// closure's anonymous fns do have access to outer scope
	time.Sleep(100 * time.Millisecond)
}
func sayHello() {
	fmt.Println("hello")
}  
`}
            </pre>
            <Span>WaitGroups</Span>
            <Span>
              To wait for multiple goroutines to finish, we can use a wait
              group.Sleep to simulate an expensive task. This WaitGroup is used
              to wait for all the goroutines launched here to finish. Note: if a
              WaitGroup is explicitly passed into functions, it should be done
              by pointer.
              <br />
              Wrap the worker call in a closure that makes sure to tell the
              WaitGroup that this worker is done. This way the worker itself
              does not have to be aware of the concurrency primitives involved
              in its execution.
            </Span>
            <pre>
              {`
package main
import (
	"fmt"
	"sync"
	"time"
)
func worker(id int) {
	fmt.Printf("Worker %d starting\\n", id)
	time.Sleep(time.Second)
	fmt.Printf("Worker %d done\\n", id)
}
func main() {
	var wg sync.WaitGroup
	for i := 1; i <= 5; i++ {
		wg.Add(1) // increases counter by 1 as we've 1 go routine
		i := i
		go func() {
			defer wg.Done() // decreases counter by 1
			worker(i)
		}()
	}
	// Block until the WaitGroup counter goes back to 0;
	// all the workers notified they're done.
	wg.Wait()
}
o/p
Worker 4 starting
Worker 1 starting
Worker 5 starting
Worker 3 starting
Worker 2 starting
Worker 2 done
Worker 5 done
Worker 4 done
Worker 3 done
Worker 1 done 

// w/o defer
Worker 5 starting
Worker 2 starting
Worker 1 starting
Worker 4 starting
Worker 3 starting
              `}
            </pre>
            <Span>Defer</Span>
            <Span>
              A defer statement defers the execution of a function until the
              surrounding function returns. The deferred call's arguments are
              evaluated immediately, but the function call is not executed until
              the surrounding function returns. Defer is used to delay execution
              of a statement until function exits and is useful to group "open"
              and "close" functions together Arguments evaluated at time defer
              is executed, not at time of called function execution
            </Span>
            <pre>
              {`
package main
import "fmt"
func main() {
  fmt.Println("1")
  defer fmt.Println("2")
  fmt.Println("3")
  defer fmt.Println("5")
  // defer takes the value at the time defer is called
  a := "5"
  defer fmt.Println(a)
  a = "6"
}              
// LIFO Stack
// Example 2:

package main
import "fmt"
func main() {
	defer fmt.Println("world")
	fmt.Println("hello")
}
hello
world
`}
            </pre>
            <Span>Channel</Span>
            <Span>
              Channels are the pipes that connect concurrent goroutines. You can
              send values into channels from one goroutine and receive those
              values into another goroutine.
            </Span>
            <pre>{`
package main
import "fmt"
func main() {
  // Create a new channel with \`make(chan val-type)\`.
  messages := make(chan string)
  go func() { messages <- "ping" }()
  msg := <-messages
  fmt.Println(msg, messages)
}
            `}</pre>
            <Span>Worker Pools</Span>
            <Span>
              Here’s the worker, of which we’ll run several concurrent
              instances. These workers will receive work on the jobs channel and
              send the corresponding results on results. We’ll sleep a second
              per job to simulate an expensive taskHere’s the worker, of which
              we’ll run several concurrent instances. These workers will receive
              work on the jobs channel and send the corresponding results on
              results. We’ll sleep a second per job to simulate an expensive
              task.In order to use our pool of workers we need to send them work
              and collect their results. We make 2 channels for this. Here’s the
              worker, of which we’ll run several concurrent instances. These
              workers will receive work on the jobs channel and send the
              corresponding results on results. We’ll sleep a second per job to
              simulate an expensive task
            </Span>
            <pre>{`
package main
import (
	"fmt"
	"time"
)
func worker(id int, jobs <-chan int, results chan<- int) {
	for j := range jobs {
		fmt.Println("worker", id, "started  job", j)
		time.Sleep(time.Second)
		fmt.Println("worker", id, "finished job", j)
		results <- j * 2
	}
}
func main() {
	const numJobs = 5
	jobs := make(chan int, numJobs)
	results := make(chan int, numJobs)
	for w := 1; w <= 3; w++ {
		go worker(w, jobs, results)
	}
	for j := 1; j <= numJobs; j++ {
		jobs <- j
	}
	close(jobs)
	for a := 1; a <= numJobs; a++ {
		<-results
	}
}
O/P:
worker 3 started  job 1
worker 1 started  job 2
worker 2 started  job 3
worker 3 finished job 1
worker 1 finished job 2
worker 1 started  job 5
worker 3 started  job 4
worker 2 finished job 3
worker 3 finished job 4
worker 1 finished job 5            
            `}</pre>
            <Span>Atomic Counters</Span>
            <Span>
              The primary mechanism for managing state in Go is communication
              over channels. We saw this for example with worker pools. There
              are a few other options for managing state though. Here we’ll look
              at using the sync/atomic package for atomic counters accessed by
              multiple goroutines.
            </Span>
            <pre>{`
package main
import (
	"fmt"
	"sync"
	"sync/atomic"
)
func main() {
	var ops uint64
	var wg sync.WaitGroup
	for i := 0; i < 50; i++ {
		wg.Add(1)
		go func() {
			for c := 0; c < 1000; c++ {
				atomic.AddUint64(&ops, 1)
			}
			wg.Done()
		}()
	}
	wg.Wait()
	fmt.Println("ops:", ops)
}
ops: 50000
`}</pre>
            <Span>Mutexes</Span>
            <Span>
              In computer science, mutual exclusion is a property of concurrency
              control, which is instituted for the purpose of preventing race
              conditions. It is the requirement that one thread of execution
              never enters a critical section while a concurrent thread of
              execution is already accessing critical section, which refers to
              an interval of time during which a thread of execution accesses a
              shared resource, such as [Shared data objects, shared resources,
              shared memory].
            </Span>
            <Span>
              {" "}
              For more complex state we can use a mutex to safely access data
              across multiple goroutines. Container holds a map of counters;
              since we want to update it concurrently from multiple goroutines,
              we add a Mutex to synchronize access. Note that mutexes must not
              be copied, so if this struct is passed around, it should be done
              by pointer.Lock the mutex before accessing counters; unlock it at
              the end of the function using a defer statement.
            </Span>
            <pre>
              {`
package main
import (
	"fmt"
	"sync"
)
type Container struct {
	mu       sync.Mutex
	counters map[string]int
}
func (c *Container) inc(name string) {
	c.mu.Lock()
	defer c.mu.Unlock()
	c.counters[name]++
}
func main() {
	c := Container{
		counters: map[string]int{"a": 0, "b": 0},
	}

	var wg sync.WaitGroup
	doIncrement := func(name string, n int) {
		for i := 0; i < n; i++ {
			c.inc(name)
		}
		wg.Done()
	}
	wg.Add(3)
	go doIncrement("a", 10000)
	go doIncrement("a", 10000)
	go doIncrement("b", 10000)
	wg.Wait()
	fmt.Println(c.counters)
  // O/P:
  // map[a:20000 b:10000]
}`}
            </pre>
            <Span>Closure</Span>
            <Span>
              Go supports anonymous functions, which can form closures.
              Anonymous functions are useful when you want to define a function
              inline without having to name it.This function intSeq returns
              another function, which we define anonymously in the body of
              intSeq. The returned function closes over the variable i to form a
              closure.
            </Span>
            <pre>{`
package main
import "fmt"
func intSeq() func() int {
	i := 0
	return func() int {
		i++
		return i
	}
}
func main() {
	nextInt := intSeq()
	fmt.Println(nextInt())
	fmt.Println(nextInt())
	fmt.Println(nextInt())
	newInts := intSeq()
	fmt.Println(newInts())
}
O/P:
1
2
3
1            
            `}</pre>
            <Span>Panic</Span>
            <Span>
              Occurs when program cannot continue at all cannot obtain TCP port
              for web server if nothing handles panic, program will exit. A
              panic typically means something went unexpectedly wrong. Mostly we
              use it to fail fast on errors that shouldn’t occur during normal
              operation, or that we aren’t prepared to handle gracefully.
            </Span>
            <pre>
              {`
// Example 1
err := http.ListenAndServe(":8000",nill)
if err != nill {
panic(err.Error())
}
// Example 2
package main
import "os"
func main() {
    panic("a problem")
    _, err := os.Create("/tmp/file")
    if err != nil {
        panic(err)
    }
}
// O/P
go run panic.go
panic: a problem
goroutine 1 [running]:
main.main()
    /.../panic.go:12 +0x47
...
exit status 2
              `}
            </pre>
            <Span>Recover</Span>
            <Span>
              Used to recover from panics Only useful in deferred functions
              Current function will not attempt to continue , but higher
              functions in call stack will
            </Span>
            <Span>
              Go makes it possible to recover from a panic, by using the recover
              built-in function. A recover can stop a panic from aborting the
              program and let it continue with execution instead. An example of
              where this can be useful: a server wouldn’t want to crash if one
              of the client connections exhibits a critical error. Instead, the
              server would want to close that connection and continue serving
              other clients. In fact, this is what Go’s net/http does by default
              for HTTP servers.
            </Span>
            <pre>
              {`package main
import "fmt"
func mayPanic() {
    panic("a problem")
}
func main() {
    defer func() {
        if r := recover(); r != nil {
            fmt.Println("Recovered. Error:\\n", r)
        }
    }()
    mayPanic()
    fmt.Println("After mayPanic()")
}
O/P:
Recovered. Error:
 a problem
`}
            </pre>
            <Span>
              <b>Templates:</b>
            </Span>
            <Span>Example 1:</Span>
            <pre>
              {`
package main
import "fmt"
func main() {
	name := "Sai
	tpl := \`
	<!DOCTYPE html>
	<html lang="en">
	<head>
	<meta charset="UTF-8">
	<title>Hello World!</title>
	</head>
	<body>
	<h1>\` + name + \`</h1>
	</body>
	</html>
	\`
	fmt.Println(tpl)
}              
go run main.go > index.html
              `}
            </pre>
            <Span>Example 2:</Span>
            <pre>
              {`
package main
import (
	"fmt"
	"io"
	"log"
	"os"
	"strings"
)
func main() {
	name := os.Args[1]
	fmt.Println(os.Args[0])
	fmt.Println(os.Args[1])
	str := fmt.Sprint(\`
	<!DOCTYPE html>
	<html lang="en">
	<head>
	<meta charset="UTF-8" >
	<title>Conatenation</title>
	</head>
	<body>
	<h1>\` +
		name +
		\`</h1>
	</body>
	</html>
	\`)
	nf, err := os.Create("index.html")
	if err != nil {
		log.Fatal("error")
	}
	defer nf.Close()
	io.Copy(nf, strings.NewReader(str))
}              
              `}
            </pre>
            <Span>Package Text Template ( text/template )</Span>
            <pre>{`temp.go
package main
import (
	"log"
	"os"
	"text/template"
)
var tpl *template.Template
type a1 struct {
	A string
	B int
}
type b1 struct {
	C []a1
}
func init() {
	tpl = template.Must(template.ParseGlob("*"))
	// templates/*.txt
	// templates/*
}
func main() {
	// every type implements the empty interface
	err := tpl.Execute(os.Stdout, nil)
	var data interface{}
	data = []string{"a", "b", "c"}
	data = map[string]string{
		"a": "1"}
	data = a1{A: "a", B: 1}
	data = b1{
		C []{data,data}
	}
	_ = data
	var fm = template.FuncMap(
		"us": strings.ToUpper,
		"ft": firstThree
	)
	// err = tpl.ExecuteTemplate(os.Stdout, "a.txt", nil)
	// err = tpl.ExecuteTemplate(os.Stdout, "tpl.gohtml", 42)
	// err = tpl.ExecuteTemplate(os.Stdout, "tpl1.gohtml", \`test\`)
	err = tpl.ExecuteTemplate(os.Stdout, "tpl1.gohtml", data)
	if err != nil {
		log.Fatalln(err)
	}
}
tp1.gohtml
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Hello World!</title>
</head>
<body>
<h1>The meaning of life: {{.}}</h1>
</body>
</html>
tp2.gohtml
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Hello World!</title>
</head>
<body>
{{ range .C }}
  {{ .A }} - {{ .B }}
{{ end }}
</body>
</html>
{{/* {{$t := .}}
<h1> {{$t}}</h1> */}}
{{/* {{ range . }}
   {{ . }}
{{ end }}*/}}
{{/* {{ range $index , $e := .  }}
    {{ $index }} - {{ $e }}
{{ end }}  */}}
  {{/* {{ $x := .A }}
  {{ $x }}
  {{ .A }} - {{ .B }} */}}
a.txt
########
a
#######
a.gohtml
#########
abcd
########
abc.txt
b
`}</pre>
            <Span>Pipelines in templates</Span>
            <pre>{`{{ . | fdbl | fsq | fsqrt }}`}</pre>
            <Span>double , square, square root</Span>
            <pre>{`
package main
import (
  "log"
  "os"
  "text/template"
)
var tpl *template.Template
func init() {
  tpl = template.Must(template.ParseFiles("tpl.gohtml"))
}
func main() {
  xs := []string{"zero", "one", "two", "three", "four", "five"}
  err := tpl.Execute(os.Stdout, xs)
  if err != nil {
    log.Fatalln(err)
  }
}
{{index . 2}}
{{index . 0}}
{{index . 1}}              
              `}</pre>
            <Span>Nested Template</Span>
            <pre>{`
index.gohtml
<p>{{template "polarbear"}}</p>
file 2:
{{define "polarbear"}}
Here is my polar bear template
{{end}}
              `}</pre>
            <Span>
              <b>Html Templates</b>
            </Span>
            <pre>{`
package main
import (
	"html/template"
	"log"
	"net/http"
)
// html templates escapes un-safe characters avoid cross site scripting
// \`<script>alert('hi')</script>\`
//  in text templates, fn will be exceuted
//  in html templates , additional characters will be added in b/w
var tpl *template.Template
func init() {
	tpl = template.Must(template.ParseGlob("templates/*.gohtml"))
}
func main() {
	http.HandleFunc("/", index)
	http.Handle("/public/", http.StripPrefix("/public", http.FileServer(http.Dir("public"))))
	http.ListenAndServe(":8080", nil)
}
func index(res http.ResponseWriter, req *http.Request) {
	err := tpl.ExecuteTemplate(res, "index.gohtml", nil)
	if err != nil {
		log.Fatalln("template didn't execute: ", err)
	}
}  

index.gohtml
{{template "btf"}}
{{template "footer"}}
            `}</pre>
            <Span>Refer to https://github.dev/SaiAshish9/go-templates</Span>
            <Span>
              <b>TCP Servers</b>
            </Span>
            <Span>Read from connection</Span>
            <pre>
              {`
package main
import (
	"fmt"
	"io"
	"log"
	"net"
)
func main() {
	li, err := net.Listen("tcp", ":8080")
	if err != nil {
		log.Fatalln(err)
	}
	defer li.Close()
	for {
		conn, err := li.Accept()
		if err != nil {
			log.Println(err)
			continue
		}
		io.WriteString(conn, "\\nHello from TCP server\\n")
		fmt.Fprintln(conn, "How is your day?")
		fmt.Fprintf(conn, "%v", "Well, I hope!")
		conn.Close()
	}
}
go run main.go
telnet http 8080
telnet is a program which works on tcp               
              `}
            </pre>
            <Span>Write to connection with deadline</Span>
            <pre>{`
package main
import (
	"bufio"
	"fmt"
	"log"
	"net"
)
func main() {
	li, err := net.Listen("tcp", ":8080")
	if err != nil {
		log.Fatalln(err)
	}
	defer li.Close()
	for {
		conn, err := li.Accept()
		if err != nil {
			log.Println(err)
			continue
		}
		go handle(conn)
	}
}
func handle(conn net.Conn) {
  err := conn.SetDeadline(time.Now().Add(10 * time.Second))
	if err != nil {
		log.Fatalln("CONN TIMEOUT")
	}
	scanner := bufio.NewScanner(conn)
	for scanner.Scan() {
		ln := scanner.Text()
		fmt.Println(ln)
    fmt.Fprintf(conn, "I heard you say: %s\\n", ln)
	}
	defer conn.Close()
	fmt.Println("Code got here.")
}            
            `}</pre>
            <Span>Dial</Span>
            <pre>{`
package main
import (
	"fmt"
	"log"
	"net"
)
func main() {
	conn, err := net.Dial("tcp", "localhost:8080")
	if err != nil {
		log.Fatalln(err)
	}
	defer conn.Close()
	fmt.Fprintln(conn, "I dialed you.")
}
            `}</pre>
            <Span>TCP Server For HTTP</Span>
            <pre>{`
package main
import (
	"bufio"
	"fmt"
	"log"
	"net"
	"strings"
)
func main() {
	li, err := net.Listen("tcp", ":8080")
	if err != nil {
		log.Fatalln(err.Error())
	}
	defer li.Close()
	for {
		conn, err := li.Accept()
		if err != nil {
			log.Println(err.Error())
			continue
		}
		go handle(conn)
	}
}
func handle(conn net.Conn) {
	defer conn.Close()
	request(conn)
}
func request(conn net.Conn) {
	i := 0
	scanner := bufio.NewScanner(conn)
	for scanner.Scan() {
		ln := scanner.Text()
		fmt.Println(ln)
		if i == 0 {
			mux(conn, ln)
		}
		if ln == "" {
			// headers are done
			break
		}
		i++
	}
}
func mux(conn net.Conn, ln string) {
	m := strings.Fields(ln)[0] 
	u := strings.Fields(ln)[1] 
	fmt.Println("***METHOD", m)
  fmt.Println("***URI", u)
	// multiplexer
	if m == "GET" && u == "/" {
		index(conn)
	}
	if m == "GET" && u == "/about" {
		about(conn)
	}
	if m == "GET" && u == "/contact" {
		contact(conn)
	}
	if m == "GET" && u == "/apply" {
		apply(conn)
	}
	if m == "POST" && u == "/apply" {
		applyProcess(conn)
	}
}
func index(conn net.Conn) {
	body := \`<!DOCTYPE html><html lang="en"><head><meta charet="UTF-8"><title></title></head><body>
	<strong>INDEX</strong><br>
	<a href="/">index</a><br>
	<a href="/about">about</a><br>
	<a href="/contact">contact</a><br>
	<a href="/apply">apply</a><br>
	</body></html>\`
	fmt.Fprint(conn, "HTTP/1.1 200 OK\\r\\n")
	fmt.Fprintf(conn, "Content-Length: %d\\r\\n", len(body))
	fmt.Fprint(conn, "Content-Type: text/html\\r\\n")
	fmt.Fprint(conn, "\\r\\n")
	fmt.Fprint(conn, body)
}
func about(conn net.Conn) {
	body := \`<!DOCTYPE html><html lang="en"><head><meta charet="UTF-8"><title></title></head><body>
	<strong>ABOUT</strong><br>
	<a href="/">index</a><br>
	<a href="/about">about</a><br>
	<a href="/contact">contact</a><br>
	<a href="/apply">apply</a><br>
	</body></html>\`
	fmt.Fprint(conn, "HTTP/1.1 200 OK\\r\\n")
	fmt.Fprintf(conn, "Content-Length: %d\\r\\n", len(body))
	fmt.Fprint(conn, "Content-Type: text/html\\r\\n")
	fmt.Fprint(conn, "\\r\\n")
	fmt.Fprint(conn, body)
}
func contact(conn net.Conn) {
	body := \`<!DOCTYPE html><html lang="en"><head><meta charet="UTF-8"><title></title></head><body>
	<strong>CONTACT</strong><br>
	<a href="/">index</a><br>
	<a href="/about">about</a><br>
	<a href="/contact">contact</a><br>
	<a href="/apply">apply</a><br>
	</body></html>\`
	fmt.Fprint(conn, "HTTP/1.1 200 OK\\r\\n")
	fmt.Fprintf(conn, "Content-Length: %d\\r\\n", len(body))
	fmt.Fprint(conn, "Content-Type: text/html\\r\\n")
	fmt.Fprint(conn, "\\r\\n")
	fmt.Fprint(conn, body)
}
func apply(conn net.Conn) {
	body := \`<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><title></title></head><body>
	<strong>APPLY</strong><br>
	<a href="/">index</a><br>
	<a href="/about">about</a><br>
	<a href="/contact">contact</a><br>
	<a href="/apply">apply</a><br>
	<form method="POST" action="/apply">
	<input type="submit" value="apply">
	</form>
	</body></html>\`
	fmt.Fprint(conn, "HTTP/1.1 200 OK\\r\\n")
	fmt.Fprintf(conn, "Content-Length: %d\\r\\n", len(body))
	fmt.Fprint(conn, "Content-Type: text/html\\r\\n")
	fmt.Fprint(conn, "\\r\\n")
	fmt.Fprint(conn, body)
}
func applyProcess(conn net.Conn) {
	body := \`<!DOCTYPE html><html lang="en"><head><meta charet="UTF-8"><title></title></head><body>
	<strong>APPLY PROCESS</strong><br>
	<a href="/">index</a><br>
	<a href="/about">about</a><br>
	<a href="/contact">contact</a><br>
	<a href="/apply">apply</a><br>
	</body></html>\`
	fmt.Fprint(conn, "HTTP/1.1 200 OK\\r\\n")
	fmt.Fprintf(conn, "Content-Length: %d\\r\\n", len(body))
	fmt.Fprint(conn, "Content-Type: text/html\\r\\n")
	fmt.Fprint(conn, "\\r\\n")
	fmt.Fprint(conn, body)
}
            `}</pre>
            <Span>The Dial function connects to a server:</Span>
            <Span>The Listen function creates servers:</Span>
            <Span>Net Http</Span>
            <pre>{`
package main
import (
	"html/template"
	"log"
	"net/http"
	"net/url"
)
type hotdog int
func (m hotdog) ServeHTTP(w http.ResponseWriter, req *http.Request) {
	err := req.ParseForm()
	if err != nil {
		log.Fatalln(err)
	}
	data := struct {
		Method        string
		URL           *url.URL
		Submissions   map[string][]string
		Header        http.Header
		Host          string
		ContentLength int64
	}{
		req.Method,
		req.URL,
		req.Form,
		req.Header,
		req.Host,
		req.ContentLength,
	}
	tpl.ExecuteTemplate(w, "index.gohtml", data)
}
var tpl *template.Template
func init() {
	tpl = template.Must(template.ParseFiles("index.gohtml"))
}
func main() {
	var d hotdog
	http.ListenAndServe(":8080", d)
}
package main
import (
	"fmt"
	"net/http"
)
type hotdog int
func (m hotdog) ServeHTTP(w http.ResponseWriter, req *http.Request) {
	w.Header().Set("Mcleod-Key", "this is from mcleod")
	w.Header().Set("Content-Type", "text/html; charset=utf-8")
	fmt.Fprintln(w, "<h1>Any code you want in this func</h1>")
}
func main() {
	var d hotdog
	http.ListenAndServe(":8080", d)
}            
            `}</pre>
            <Span>Net Http ServerMux</Span>
            <pre>{`
package main
import (
  "io"
  "net/http"
)
type hotdog int
func (m hotdog) ServeHTTP(w http.ResponseWriter, req *http.Request) {
  switch req.URL.Path {
  case "/dog":
    io.WriteString(w, "doggy doggy doggy")
  case "/cat":
    io.WriteString(w, "kitty kitty kitty")
  }
}
func main() {
  var d hotdog
  http.ListenAndServe(":8080", d)
}            
// -------------------------
type hotdog int
func (d hotdog) ServeHTTP(res http.ResponseWriter, req *http.Request) {
	io.WriteString(res, "dog dog dog")
}
type hotcat int
func (c hotcat) ServeHTTP(res http.ResponseWriter, req *http.Request) {
	io.WriteString(res, "cat cat cat")
}
func main() {
	var d hotdog
	var c hotcat
	mux := http.NewServeMux()
	mux.Handle("/dog/", d)
	mux.Handle("/cat", c)
	http.ListenAndServe(":8080", mux)
}
// -------------------------
type hotdog int
func (d hotdog) ServeHTTP(res http.ResponseWriter, req *http.Request) {
	io.WriteString(res, "dog dog dog")
}
type hotcat int
func (c hotcat) ServeHTTP(res http.ResponseWriter, req *http.Request) {
	io.WriteString(res, "cat cat cat")
}
func main() {
	var d hotdog
	var c hotcat
	http.Handle("/dog", d)
	http.Handle("/cat", c)
	http.ListenAndServe(":8080", nil)
}
// -------------------------
package main
import (
	"io"
	"net/http"
)
func d(res http.ResponseWriter, req *http.Request) {
	io.WriteString(res, "dog dog dog")
}
func c(res http.ResponseWriter, req *http.Request) {
	io.WriteString(res, "cat cat cat")
}
func main() {
	http.HandleFunc("/dog", d)
	http.HandleFunc("/cat", c)
	// http.Handle("/dog", http.HandlerFunc(d))
	// http.Handle("/cat", http.HandlerFunc(c))
	http.ListenAndServe(":8080", nil)
}
            `}</pre>
            <Span>Third Party Server Mux:</Span>
            <pre>{`
package main
import (
	"fmt"
	"github.com/julienschmidt/httprouter"
	"html/template"
	"log"
	"net/http"
)
var tpl *template.Template
func init() {
	tpl = template.Must(template.ParseGlob("templates/*"))
}
func main() {
	mux := httprouter.New()
	mux.GET("/", index)
	mux.GET("/about", about)
	mux.GET("/contact", contact)
	mux.GET("/apply", apply)
	mux.POST("/apply", applyProcess)
	mux.GET("/user/:name", user)
	mux.GET("/blog/:category/:article", blogRead)
	mux.POST("/blog/:category/:article", blogWrite)
	http.ListenAndServe(":8080", mux)
}
func user(w http.ResponseWriter, req *http.Request, ps httprouter.Params) {
	fmt.Fprintf(w, "USER, %s!\\n", ps.ByName("name"))
}
func blogRead(w http.ResponseWriter, req *http.Request, ps httprouter.Params) {
	fmt.Fprintf(w, "READ CATEGORY, %s!\\n", ps.ByName("category"))
	fmt.Fprintf(w, "READ ARTICLE, %s!\\n", ps.ByName("article"))
}
func blogWrite(w http.ResponseWriter, req *http.Request, ps httprouter.Params) {
	fmt.Fprintf(w, "WRITE CATEGORY, %s!\\n", ps.ByName("category"))
	fmt.Fprintf(w, "WRITE ARTICLE, %s!\\n", ps.ByName("article"))
}
func index(w http.ResponseWriter, req *http.Request, _ httprouter.Params) {
	err := tpl.ExecuteTemplate(w, "index.gohtml", nil)
	HandleError(w, err)
}
func about(w http.ResponseWriter, req *http.Request, _ httprouter.Params) {
	err := tpl.ExecuteTemplate(w, "about.gohtml", nil)
	HandleError(w, err)
}
func contact(w http.ResponseWriter, req *http.Request, _ httprouter.Params) {
	err := tpl.ExecuteTemplate(w, "contact.gohtml", nil)
	HandleError(w, err)
}
func apply(w http.ResponseWriter, req *http.Request, _ httprouter.Params) {
	err := tpl.ExecuteTemplate(w, "apply.gohtml", nil)
	HandleError(w, err)
}
func applyProcess(w http.ResponseWriter, req *http.Request, _ httprouter.Params) {
	err := tpl.ExecuteTemplate(w, "applyProcess.gohtml", nil)
	HandleError(w, err)
}
func HandleError(w http.ResponseWriter, err error) {
	if err != nil {
		http.Error(w, err.Error(), http.StatusInternalServerError)
		log.Fatalln(err)
	}
}            
            `}</pre>
            <Span>
              <b>Serving Files:</b>
            </Span>
            <pre>{`
package main
import (
	"io"
	"net/http"
	"os"
)
func main() {
	http.HandleFunc("/", dog)
	http.HandleFunc("/toby.jpg", dogPic)
	http.ListenAndServe(":8080", nil)
}
func dog(w http.ResponseWriter, req *http.Request) {
	w.Header().Set("Content-Type", "text/html; charset=utf-8")
	io.WriteString(w, \`
	<img src="/toby.jpg">
	\`)
}
func dogPic(w http.ResponseWriter, req *http.Request) {
	f, err := os.Open("toby.jpg")
	if err != nil {
		http.Error(w, "file not found", 404)
		return
	}
	defer f.Close()
	// io.Copy(w, f)
  // fi, err := f.Stat()
	// if err != nil {
	// 	http.Error(w, "file not found", 404)
	// 	return
	// }
	// http.ServeContent(w, req, f.Name(), fi.ModTime(), f)
}
func main() {
	http.HandleFunc("/", dog)
	http.HandleFunc("/toby.jpg", dogPic)
  http.Handle("/assets/", http.StripPrefix("/assets", http.FileServer(http.Dir("./assets"))))
	io.WriteString(w, \`<img src="/assets/toby.jpg">\`)
	http.ListenAndServe(":8080", nil)
}
func dog(w http.ResponseWriter, req *http.Request) {
	w.Header().Set("Content-Type", "text/html; charset=utf-8")
	io.WriteString(w, \`<img src="toby.jpg">\`)
}
func dogPic(w http.ResponseWriter, req *http.Request) {
	http.ServeFile(w, req, "toby.jpg")
	log.Fatal(http.ListenAndServe(":8080", http.FileServer(http.Dir("."))))
}
// serveFile serveContent 
            `}</pre>
            <Span>
              <b>Not Found Handler:</b>
            </Span>
            <Span>{`
package main
import (
	"fmt"
	"net/http"
)
func main() {
	http.HandleFunc("/", foo)
	http.Handle("/favicon.ico", http.NotFoundHandler())
	http.ListenAndServe(":8080", nil)
}
func foo(w http.ResponseWriter, req *http.Request) {
	fmt.Println(req.URL.Path)
	fmt.Fprintln(w, "go look at your terminal")
}            
            `}</Span>
            <Span>
              <b>AppEngine Deploy</b>
            </Span>
            <pre>{`
index.html + .css +.img

app.yaml

runtime: go113

handlers:
- url: /.*
  script: auto
  secure: always

main.go

package main
import "net/http"
func main(){
	http.Handle("/", http.FileServer(http.Dir(".")))
	http.ListenAndServe(":8080", nil)
}

            `}</pre>
            <Span>
              <pre>{`
package main
import (
	"fmt"
	"net/http"
)
func main() {
	http.HandleFunc("/", foo)
	http.Handle("/favicon.ico", http.NotFoundHandler())
	http.ListenAndServe(":8080", nil)
}
func foo(w http.ResponseWriter, req *http.Request) {
	v := req.FormValue("q")
	fmt.Fprintln(w, "Do my search: "+v)
}              
              `}</pre>
            </Span>
            <Span>
              {`
package main
import (
	"html/template"
	"log"
	"net/http"
)
var tpl *template.Template
func init() {
	tpl = template.Must(template.ParseGlob("templates/*"))
}
type person struct {
	FirstName  string
	LastName   string
	Subscribed bool
}
func main() {
	http.HandleFunc("/", foo)
	http.Handle("/favicon.ico", http.NotFoundHandler())
	http.ListenAndServe(":8080", nil)
}
func foo(w http.ResponseWriter, req *http.Request) {
	f := req.FormValue("first")
	l := req.FormValue("last")
	s := req.FormValue("subscribe") == "on"
	err := tpl.ExecuteTemplate(w, "index.gohtml", person{f, l, s})
	if err != nil {
		http.Error(w, err.Error(), 500)
		log.Fatalln(err)
	}
}

templates/index.gohtml

index.gohtml
{{template "header"}}
<form method="POST">
    <label for="firstName">First Name</label>
    <input type="text" id="firstName" name="first">
    <br>
    <label for="lastName">Last Name</label>
    <input type="text" id="lastName" name="last">
    <br>
    <label for="sub">Subscribe</label>
    <input type="checkbox" id="sub" name="subscribe">
    <br>
    <input type="submit">
</form>
<br>
<h1>First: {{.FirstName}}</h1>
<h1>Last: {{.LastName}}</h1>
<h1>Subscribed: {{.Subscribed}}</h1>
{{template "footer"}}

include-footer.gohtml

{{define "header"}}
<!doctype html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Document</title>
</head>
<body>
{{end}}

include-header.gohtml

{{define "footer"}}
<h1>copyright McLeod</h1>
</body>
</html>
{{end}}
              `}
            </Span>
            <Span>Form File</Span>
            <pre>
              {`
package main
import (
	"fmt"
	"html/template"
	"io/ioutil"
	"net/http"
	"os"
	"path/filepath"
)
var tpl *template.Template
func init() {
	tpl = template.Must(template.ParseGlob("templates/*"))
}
func main() {
	http.HandleFunc("/", foo)
	http.Handle("/favicon.ico", http.NotFoundHandler())
	http.ListenAndServe(":8080", nil)
}
func foo(w http.ResponseWriter, req *http.Request) {
	var s string
	if req.Method == http.MethodPost {
		f, h, err := req.FormFile("q")
		if err != nil {
			http.Error(w, err.Error(), http.StatusInternalServerError)
			return
		}
		defer f.Close()
		fmt.Println("\\nfile:", f, "\\nheader:", h, "\\nerr", err)
		bs, err := ioutil.ReadAll(f)
		if err != nil {
			http.Error(w, err.Error(), http.StatusInternalServerError)
			return
		}
		s = string(bs)
		dst, err := os.Create(filepath.Join("./user/", h.Filename))
		if err != nil {
			http.Error(w, err.Error(), http.StatusInternalServerError)
			return
		}
		defer dst.Close()
		_, err = dst.Write(bs)
		if err != nil {
			http.Error(w, err.Error(), http.StatusInternalServerError)
			return
		}
	}
	w.Header().Set("Content-Type", "text/html; charset=utf-8")
	tpl.ExecuteTemplate(w, "index.gohtml", s)
}              
              `}
            </pre>
            <Span>Enctype (type="text/plain")</Span>
            <pre>{`
	http.HandleFunc("/", foo)
  func foo(w http.ResponseWriter, req *http.Request) {
    bs := make([]byte, req.ContentLength)
    req.Body.Read(bs)
    body := string(bs)
    err := tpl.ExecuteTemplate(w, "index.gohtml", body)
    if err != nil {
      http.Error(w, err.Error(), 500)
      log.Fatalln(err)
    }
  }            
            `}</pre>
            <Span>Enctype (type="application/x-www-form-urlencoded")</Span>
            <pre>
              {`
<form method="POST" enctype="application/x-www-form-urlencoded">
<label for="firstName">First Name</label>
<input type="text" id="firstName" name="first">
<br>
<label for="lastName">Last Name</label>
<input type="text" id="lastName" name="last">
<br>
<label for="sub">Subscribed</label>
<input type="checkbox" id="sub" name="subscribe">
<br>
<input type="submit">
</form>
func main() {
	http.HandleFunc("/", foo)
	http.Handle("/favicon.ico", http.NotFoundHandler())
	http.ListenAndServe(":8080", nil)
}
func foo(w http.ResponseWriter, req *http.Request) {
	bs := make([]byte, req.ContentLength)
	req.Body.Read(bs)
	body := string(bs)
	err := tpl.ExecuteTemplate(w, "index.gohtml", body)
	if err != nil {
		http.Error(w, err.Error(), 500)
		log.Fatalln(err)
	}
}
`}
            </pre>
            <Span>Enctype (type="multipart/form-data")</Span>
            <pre>{`
<form method="POST" enctype="multipart/form-data">
<label for="firstName">First Name</label>
<input type="text" id="firstName" name="first">
<br>
<label for="lastName">Last Name</label>
<input type="text" id="lastName" name="last">
<br>
<label for="sub">Subscribed</label>
<input type="checkbox" id="sub" name="subscribe">
<br>
<input type="submit">
</form>
func main() {
	http.HandleFunc("/", foo)
	http.Handle("/favicon.ico", http.NotFoundHandler())
	http.ListenAndServe(":8080", nil)
}
func foo(w http.ResponseWriter, req *http.Request) {
	bs := make([]byte, req.ContentLength)
	req.Body.Read(bs)
	body := string(bs)
	err := tpl.ExecuteTemplate(w, "index.gohtml", body)
	if err != nil {
		http.Error(w, err.Error(), 500)
		log.Fatalln(err)
	}
}
`}</pre>
            <Span>
              <b>Redirect</b>
            </Span>
            <pre>{`
	http.Redirect(w, req, "/", http.StatusSeeOther)
	http.Redirect(w, req, "/", http.StatusTemporaryRedirect)
	http.Redirect(w, req, "/", http.StatusMovedPermanently)
  // --------
  <form method="POST" action="/bar">
    <input type="text" name="fname" title="fname">
    <input type="submit">
  </form>
	w.Header().Set("Location", "/")
	w.WriteHeader(http.StatusSeeOther)
`}</pre>
            <Span>
              <b>Cookies</b>
            </Span>{" "}
            <pre>{`
package main
import (
	"fmt"
	"net/http"
)
func main() {
	http.HandleFunc("/", set)
	http.HandleFunc("/read", read)
	http.Handle("/favicon.ico", http.NotFoundHandler())
	http.ListenAndServe(":8080", nil)
}
func set(w http.ResponseWriter, req *http.Request) {
	http.SetCookie(w, &http.Cookie{
		Name:  "my-cookie",
		Value: "some value",
		Path: "/",
	})
	fmt.Fprintln(w, "COOKIE WRITTEN - CHECK YOUR BROWSER")
	fmt.Fprintln(w, "in chrome go to: dev tools / application / cookies")
}
func read(w http.ResponseWriter, req *http.Request) {
	c1, err := req.Cookie("my-cookie")
	if err != nil {
		log.Println(err)
	} else {
		fmt.Fprintln(w, "YOUR COOKIE #1:", c1)
	}
	c2, err := req.Cookie("general")
	if err != nil {
		log.Println(err)
	} else {
		fmt.Fprintln(w, "YOUR COOKIE #2:", c2)
	}
	c3, err := req.Cookie("specific")
	if err != nil {
		log.Println(err)
	} else {
		fmt.Fprintln(w, "YOUR COOKIE #3:", c3)
	}
}
func expire(w http.ResponseWriter, req *http.Request) {
	c, err := req.Cookie("session")
	if err != nil {
		http.Redirect(w, req, "/set", http.StatusSeeOther)
		return
	}
	c.MaxAge = -1 // delete cookie
	http.SetCookie(w, c)
	http.Redirect(w, req, "/", http.StatusSeeOther)
}
            `}</pre>
            <Span>
              <b>Sessions, UUID, Middlewares And Bycrypt</b>
            </Span>
            <pre>
              {`
main.go

package main

import (
	"github.com/satori/go.uuid"
	"golang.org/x/crypto/bcrypt"
	"html/template"
	"net/http"
	"time"
)

type user struct {
	UserName string
	Password []byte
	First    string
	Last     string
	Role     string
}

type session struct {
	un           string
	lastActivity time.Time
}

var tpl *template.Template
var dbUsers = map[string]user{}      
var dbSessions = map[string]session{} 
var dbSessionsCleaned time.Time

const sessionLength int = 30

func init() {
	tpl = template.Must(template.ParseGlob("templates/*"))
	dbSessionsCleaned = time.Now()
}

func main() {
	http.HandleFunc("/", index)
	http.HandleFunc("/bar", bar)
	http.HandleFunc("/signup", signup)
	http.HandleFunc("/login", login)
	http.HandleFunc("/logout", authorized(logout))
	http.Handle("/favicon.ico", http.NotFoundHandler())
	http.ListenAndServe(":8080", nil)
}

func index(w http.ResponseWriter, req *http.Request) {
	u := getUser(w, req)
	showSessions()
	tpl.ExecuteTemplate(w, "index.gohtml", u)
}

func bar(w http.ResponseWriter, req *http.Request) {
	u := getUser(w, req)
	if !alreadyLoggedIn(w, req) {
		http.Redirect(w, req, "/", http.StatusSeeOther)
		return
	}
	if u.Role != "007" {
		http.Error(w, "You must be 007 to enter the bar", http.StatusForbidden)
		return
	}
	showSessions() // for demonstration purposes
	tpl.ExecuteTemplate(w, "bar.gohtml", u)
}

func signup(w http.ResponseWriter, req *http.Request) {
	if alreadyLoggedIn(w, req) {
		http.Redirect(w, req, "/", http.StatusSeeOther)
		return
	}
	var u user
	if req.Method == http.MethodPost {
		un := req.FormValue("username")
		p := req.FormValue("password")
		f := req.FormValue("firstname")
		l := req.FormValue("lastname")
		r := req.FormValue("role")
		if _, ok := dbUsers[un]; ok {
			http.Error(w, "Username already taken", http.StatusForbidden)
			return
		}
		sID, _ := uuid.NewV4()
		c := &http.Cookie{
			Name:  "session",
			Value: sID.String(),
		}
		c.MaxAge = sessionLength
		http.SetCookie(w, c)
		dbSessions[c.Value] = session{un, time.Now()}
		bs, err := bcrypt.GenerateFromPassword([]byte(p), bcrypt.MinCost)
		if err != nil {
			http.Error(w, "Internal server error", http.StatusInternalServerError)
			return
		}
		u = user{un, bs, f, l, r}
		dbUsers[un] = u
		http.Redirect(w, req, "/", http.StatusSeeOther)
		return
	}
	showSessions() // for demonstration purposes
	tpl.ExecuteTemplate(w, "signup.gohtml", u)
}

func login(w http.ResponseWriter, req *http.Request) {
	if alreadyLoggedIn(w, req) {
		http.Redirect(w, req, "/", http.StatusSeeOther)
		return
	}
	var u user
	if req.Method == http.MethodPost {
		un := req.FormValue("username")
		p := req.FormValue("password")
		u, ok := dbUsers[un]
		if !ok {
			http.Error(w, "Username and/or password do not match", http.StatusForbidden)
			return
		}
		err := bcrypt.CompareHashAndPassword(u.Password, []byte(p))
		if err != nil {
			http.Error(w, "Username and/or password do not match", http.StatusForbidden)
			return
		}
		sID, _ := uuid.NewV4()
		c := &http.Cookie{
			Name:  "session",
			Value: sID.String(),
		}
		c.MaxAge = sessionLength
		http.SetCookie(w, c)
		dbSessions[c.Value] = session{un, time.Now()}
		http.Redirect(w, req, "/", http.StatusSeeOther)
		return
	}
	showSessions() 
	tpl.ExecuteTemplate(w, "login.gohtml", u)
}

func logout(w http.ResponseWriter, req *http.Request) {
	c, _ := req.Cookie("session")
	delete(dbSessions, c.Value)
	// remove the cookie
	c = &http.Cookie{
		Name:   "session",
		Value:  "",
		MaxAge: -1,
	}
	http.SetCookie(w, c)
	if time.Now().Sub(dbSessionsCleaned) > (time.Second * 30) {
		go cleanSessions()
	}
	http.Redirect(w, req, "/login", http.StatusSeeOther)
}

func authorized(h http.HandlerFunc) http.HandlerFunc {
	return http.HandlerFunc(func(w http.ResponseWriter, r *http.Request) {
		if !alreadyLoggedIn(w, r) {
			http.Redirect(w, r, "/", http.StatusSeeOther)
			return
		}
		h.ServeHTTP(w, r)
	})
}

session.go

package main

import (
	"fmt"
	"github.com/satori/go.uuid"
	"net/http"
	"time"
)

func getUser(w http.ResponseWriter, req *http.Request) user {
	c, err := req.Cookie("session")
	if err != nil {
		sID, _ := uuid.NewV4()
		c = &http.Cookie{
			Name:  "session",
			Value: sID.String(),
		}

	}
	c.MaxAge = sessionLength
	http.SetCookie(w, c)
	var u user
	if s, ok := dbSessions[c.Value]; ok {
		s.lastActivity = time.Now()
		dbSessions[c.Value] = s
		u = dbUsers[s.un]
	}
	return u
}
func alreadyLoggedIn(w http.ResponseWriter, req *http.Request) bool {
	c, err := req.Cookie("session")
	if err != nil {
		return false
	}
	s, ok := dbSessions[c.Value]
	if ok {
		s.lastActivity = time.Now()
		dbSessions[c.Value] = s
	}
	_, ok = dbUsers[s.un]
	c.MaxAge = sessionLength
	http.SetCookie(w, c)
	return ok
}
func cleanSessions() {
	fmt.Println("BEFORE CLEAN") 
	showSessions()              
	for k, v := range dbSessions {
		if time.Now().Sub(v.lastActivity) > (time.Second * 30) {
			delete(dbSessions, k)
		}
	}
	dbSessionsCleaned = time.Now()
	fmt.Println("AFTER CLEAN") 
	showSessions()            
}
func showSessions() {
	fmt.Println("********")
	for k, v := range dbSessions {
		fmt.Println(k, v.un)
	}
	fmt.Println("")
}
              `}
            </pre>
            <Span>
              <b>Base64</b>
            </Span>
            <pre>
              {`
package main
import (
	"encoding/base64"
	"fmt"
	"log"
)
func main() {
	s := "Love is but a song to sing Fear's the way we die You can make the mountains ring Or make the angels cry Though the bird is on the wing And you may not know why Come on people now Smile on your brother Everybody get together Try to love one another Right now"
	s64 := base64.StdEncoding.EncodeToString([]byte(s))
	fmt.Println(s64)
	bs, err := base64.StdEncoding.DecodeString(s64)
	if err != nil {
		log.Fatalln("I'm giving her all she's got Captain!", err)
	}
	fmt.Println(string(bs))
}
              `}
            </pre>
            <Span>
              <b>Context</b>
            </Span>
            <Span>
              A Context carries deadlines, cancellation signals, and other
              request-scoped values across API boundaries and goroutines.
            </Span>
            <Span>
              A context.Context is created for each request by the net/http
              machinery, and is available with the Context() method
            </Span>
            <Span>
              Wait for a few seconds before sending a reply to the client. This
              could simulate some work the server is doing. While working, keep
              an eye on the context’s Done() channel for a signal that we should
              cancel the work and return as soon as possible.
            </Span>
            <pre>
              {`
package main
import (
	"fmt"
	"net/http"
	"time"
)
func hello(w http.ResponseWriter, req *http.Request) {
	ctx := req.Context()
	fmt.Println("server: hello handler started")
	defer fmt.Println("server: hello handler ended")
	select {
	case <-time.After(10 * time.Second):
		fmt.Fprintf(w, "hello\\n")
	case <-ctx.Done():
		err := ctx.Err()
		fmt.Println("server:", err)
		internalError := http.StatusInternalServerError
		http.Error(w, err.Error(), internalError)
	}
}
func main() {
	http.HandleFunc("/hello", hello)
	http.ListenAndServe(":8090", nil)
}
server: hello handler started
server: context canceled
server: hello handler ended              

package main
import (
	"context"
	"fmt"
	"log"
	"net/http"
)
func main() {
	http.HandleFunc("/", foo)
	http.HandleFunc("/bar", bar)
	http.Handle("/favicon.ico", http.NotFoundHandler())
	http.ListenAndServe(":8080", nil)
}
func foo(w http.ResponseWriter, req *http.Request) {
	ctx := req.Context()
	ctx = context.WithValue(ctx, "userID", 777)
	ctx = context.WithValue(ctx, "fname", "Bond")
	results := dbAccess(ctx)
	fmt.Fprintln(w, results)
}
func dbAccess(ctx context.Context) int {
	uid := ctx.Value("userID").(int)
	return uid
}
func bar(w http.ResponseWriter, req *http.Request) {
	ctx := req.Context()
	log.Println(ctx)
	fmt.Fprintln(w, ctx)
}
`}
            </pre>
            <Span>HTTPS</Span>
            <pre>{`
package main
import (
	"crypto/tls"
	"fmt"
	"log"
	"net/http"
	"rsc.io/letsencrypt"
)
func main() {
	http.HandleFunc("/", foo)
	var m letsencrypt.Manager
	if err := m.CacheFile("letsencrypt.cache"); err != nil {
		log.Fatalln(err)
	}
	go http.ListenAndServe(":8080", http.HandlerFunc(letsencrypt.RedirectHTTP))
	srv := &http.Server{
		Addr: ":10443",
		TLSConfig: &tls.Config{
			GetCertificate: m.GetCertificate,
		},
	}
	log.Fatalln(srv.ListenAndServeTLS("", ""))
}
func foo(res http.ResponseWriter, req *http.Request) {
	fmt.Fprintln(res, "Hello TLS")
}
`}</pre>
            <Span>JSON</Span>
            <pre>
              {`
import (
	"encoding/json"
	"log"
	"net/http"
)
type person struct {
	Fname string
	Lname string
	Items []string
}
w.Header().Set("Content-Type", "application/json")
p1 := person{
  Fname: "James",
  Lname: "Bond",
  Items: []string{"Suit", "Gun", "Wry sense of humor"},
}
j, err := json.Marshal(p1)
if err != nil {
  log.Println(err)
}
w.Write(j)
w.Header().Set("Content-Type", "application/json")
p1 := person{
  Fname: "James",
  Lname: "Bond",
  Items: []string{"Suit", "Gun", "Wry sense of humor"},
}
err := json.NewEncoder(w).Encode(p1)
if err != nil {
  log.Println(err)
}

package main
import (
	"encoding/json"
	"fmt"
	"log"
)
type thumbnail struct {
	URL           string
	Height, Width int
}
type img struct {
	Width, Height int
	Title         string
	Thumbnail     thumbnail
	Animated      bool
	IDs           []int
}
func main() {
	var data img
	rcvd := \`{"Width":800,"Height":600,"Title":"View from 15th Floor","Thumbnail":{"Url":"http://www.example.com/image/481989943","Height":125,"Width":100},"Animated":false,"IDs":[116,943,234,38793]}\`
	err := json.Unmarshal([]byte(rcvd), &data)
	if err != nil {
		log.Fatalln("error unmarshalling", err)
	}
	fmt.Println(data)
	for i, v := range data.IDs {
		fmt.Println(i, v)
	}
	fmt.Println(data.Thumbnail.URL)
}

package main
import (
	"encoding/json"
	"fmt"
	"log"
)
type city struct {
	Bali       string  \`json:"Postal"\`
	Kauai      float64 \`json:"Latitude"\`
	Maui       float64 \`json:"Longitude"\`
	Java       string  \`json:"Address"\`
	NewZealand string  \`json:"City"\`
	Skye       string  \`json:"State"\`
	Oahu       string  \`json:"Zip"\`
	Hawaii     string  \`json:"Country"\`
}
type cities []city
func main() {
	var data cities
	rcvd := \`[{"Postal":"zip","Latitude":37.7668,"Longitude":-122.3959,"Address":"","City":"SAN FRANCISCO","State":"CA","Zip":"94107","Country":"US"},{"Postal":"zip","Latitude":37.371991,"Longitude":-122.02602,"Address":"","City":"SUNNYVALE","State":"CA","Zip":"94085","Country":"US"}]\`
	err := json.Unmarshal([]byte(rcvd), &data)
	if err != nil {
		log.Fatalln(err)
	}
	fmt.Println(data)
	fmt.Println(data[1].Kauai)
}
rcvd := \`"Todd"\`
rcvd := nil
err := json.Unmarshal([]byte(rcvd), &data)
              `}
            </pre>
            <Span>MongoDB</Span>
            <pre>{`
main.go
package main
import (
	"github.com/julienschmidt/httprouter"
	"gopkg.in/mgo.v2"
	"net/http"
	"github.com/GoesToEleven/golang-web-dev/042_mongodb/05_mongodb/05_update-user-controllers-delete/controllers"
)
func main() {
	r := httprouter.New()
	uc := controllers.NewUserController(getSession())
	r.GET("/user/:id", uc.GetUser)
	r.POST("/user", uc.CreateUser)
	r.DELETE("/user/:id", uc.DeleteUser)
	http.ListenAndServe("localhost:8080", r)
}
func getSession() *mgo.Session {
	s, err := mgo.Dial("mongodb://localhost")
	if err != nil {
		panic(err)
	}
	return s
}
models/user.go

package models
import "gopkg.in/mgo.v2/bson"
type User struct {
	Id     bson.ObjectId \`json:"id" bson:"_id"\`
	Name   string        \`json:"name" bson:"name"\`
	Gender string        \`json:"gender" bson:"gender"\`
	Age    int           \`json:"age" bson:"age"\`
}

controllers/user.go

package controllers

import (
	"encoding/json"
	"fmt"
	"github.com/GoesToEleven/golang-web-dev/042_mongodb/05_mongodb/05_update-user-controllers-delete/models"
	"github.com/julienschmidt/httprouter"
	"gopkg.in/mgo.v2"
	"gopkg.in/mgo.v2/bson"
	"net/http"
)
type UserController struct {
	session *mgo.Session
}
func NewUserController(s *mgo.Session) *UserController {
	return &UserController{s}
}
func (uc UserController) GetUser(w http.ResponseWriter, r *http.Request, p httprouter.Params) {
	id := p.ByName("id")
	if !bson.IsObjectIdHex(id) {
		w.WriteHeader(http.StatusNotFound) // 404
		return
	}
	oid := bson.ObjectIdHex(id)
	u := models.User{}
	if err := uc.session.DB("go-web-dev-db").C("users").FindId(oid).One(&u); err != nil {
		w.WriteHeader(404)
		return
	}
	uj, err := json.Marshal(u)
	if err != nil {
		fmt.Println(err)
	}
	w.Header().Set("Content-Type", "application/json")
	w.WriteHeader(http.StatusOK) // 200
	fmt.Fprintf(w, "%s\\n", uj)
}
func (uc UserController) CreateUser(w http.ResponseWriter, r *http.Request, _ httprouter.Params) {
	u := models.User{}
	json.NewDecoder(r.Body).Decode(&u)
	u.Id = bson.NewObjectId()
	uc.session.DB("go-web-dev-db").C("users").Insert(u)
	uj, err := json.Marshal(u)
	if err != nil {
		fmt.Println(err)
	}
	w.Header().Set("Content-Type", "application/json")
	w.WriteHeader(http.StatusCreated) // 201
	fmt.Fprintf(w, "%s\\n", uj)
}
func (uc UserController) DeleteUser(w http.ResponseWriter, r *http.Request, p httprouter.Params) {
	id := p.ByName("id")
	if !bson.IsObjectIdHex(id) {
		w.WriteHeader(404)
		return
	}
	oid := bson.ObjectIdHex(id)
	if err := uc.session.DB("go-web-dev-db").C("users").RemoveId(oid); err != nil {
		w.WriteHeader(404)
		return
	}
	w.WriteHeader(http.StatusOK) // 200
	fmt.Fprint(w, "Deleted user", oid, "\\n")
}
`}</pre>
            <Span>
              <b>Code Organisation</b>
            </Span>
            <pre>
              {`
package main
import (
	"database/sql"
	"fmt"
	_ "github.com/lib/pq"
	"html/template"
	"net/http"
	"strconv"
)
var db *sql.DB
var tpl *template.Template
func init() {
	var err error
	db, err = sql.Open("postgres", "postgres://bond:password@localhost/bookstore?sslmode=disable")
	if err != nil {
		panic(err)
	}
	if err = db.Ping(); err != nil {
		panic(err)
	}
	fmt.Println("You connected to your database.")
	tpl = template.Must(template.ParseGlob("templates/*.gohtml"))
}
type Book struct {
	Isbn   string
	Title  string
	Author string
	Price  float32
}
func main() {
	http.HandleFunc("/", index)
	http.HandleFunc("/books", booksIndex)
	http.HandleFunc("/books/show", booksShow)
	http.HandleFunc("/books/create", booksCreateForm)
	http.HandleFunc("/books/create/process", booksCreateProcess)
	http.HandleFunc("/books/update", booksUpdateForm)
	http.HandleFunc("/books/update/process", booksUpdateProcess)
	http.HandleFunc("/books/delete/process", booksDeleteProcess)
	http.ListenAndServe(":8080", nil)
}
func index(w http.ResponseWriter, r *http.Request) {
	http.Redirect(w, r, "/books", http.StatusSeeOther)
}
func booksIndex(w http.ResponseWriter, r *http.Request) {
	if r.Method != "GET" {
		http.Error(w, http.StatusText(405), http.StatusMethodNotAllowed)
		return
	}
	rows, err := db.Query("SELECT * FROM books")
	if err != nil {
		http.Error(w, http.StatusText(500), 500)
		return
	}
	defer rows.Close()
	bks := make([]Book, 0)
	for rows.Next() {
		bk := Book{}
		err := rows.Scan(&bk.Isbn, &bk.Title, &bk.Author, &bk.Price) // order matters
		if err != nil {
			http.Error(w, http.StatusText(500), 500)
			return
		}
		bks = append(bks, bk)
	}
	if err = rows.Err(); err != nil {
		http.Error(w, http.StatusText(500), 500)
		return
	}
	tpl.ExecuteTemplate(w, "books.gohtml", bks)
}
func booksShow(w http.ResponseWriter, r *http.Request) {
	if r.Method != "GET" {
		http.Error(w, http.StatusText(405), http.StatusMethodNotAllowed)
		return
	}
	isbn := r.FormValue("isbn")
	if isbn == "" {
		http.Error(w, http.StatusText(400), http.StatusBadRequest)
		return
	}
	row := db.QueryRow("SELECT * FROM books WHERE isbn = $1", isbn)
	bk := Book{}
	err := row.Scan(&bk.Isbn, &bk.Title, &bk.Author, &bk.Price)
	switch {
	case err == sql.ErrNoRows:
		http.NotFound(w, r)
		return
	case err != nil:
		http.Error(w, http.StatusText(500), http.StatusInternalServerError)
		return
	}
	tpl.ExecuteTemplate(w, "show.gohtml", bk)
}
func booksCreateForm(w http.ResponseWriter, r *http.Request) {
	tpl.ExecuteTemplate(w, "create.gohtml", nil)
}
func booksCreateProcess(w http.ResponseWriter, r *http.Request) {
	if r.Method != "POST" {
		http.Error(w, http.StatusText(405), http.StatusMethodNotAllowed)
		return
	}
	bk := Book{}
	bk.Isbn = r.FormValue("isbn")
	bk.Title = r.FormValue("title")
	bk.Author = r.FormValue("author")
	p := r.FormValue("price")
	if bk.Isbn == "" || bk.Title == "" || bk.Author == "" || p == "" {
		http.Error(w, http.StatusText(400), http.StatusBadRequest)
		return
	}
	f64, err := strconv.ParseFloat(p, 32)
	if err != nil {
		http.Error(w, http.StatusText(406)+"Please hit back and enter a number for the price", http.StatusNotAcceptable)
		return
	}
	bk.Price = float32(f64)
	_, err = db.Exec("INSERT INTO books (isbn, title, author, price) VALUES ($1, $2, $3, $4)", bk.Isbn, bk.Title, bk.Author, bk.Price)
	if err != nil {
		http.Error(w, http.StatusText(500), http.StatusInternalServerError)
		return
	}
	tpl.ExecuteTemplate(w, "created.gohtml", bk)
}
func booksUpdateForm(w http.ResponseWriter, r *http.Request) {
	if r.Method != "GET" {
		http.Error(w, http.StatusText(405), http.StatusMethodNotAllowed)
		return
	}
	isbn := r.FormValue("isbn")
	if isbn == "" {
		http.Error(w, http.StatusText(400), http.StatusBadRequest)
		return
	}
	row := db.QueryRow("SELECT * FROM books WHERE isbn = $1", isbn)
	bk := Book{}
	err := row.Scan(&bk.Isbn, &bk.Title, &bk.Author, &bk.Price)
	switch {
	case err == sql.ErrNoRows:
		http.NotFound(w, r)
		return
	case err != nil:
		http.Error(w, http.StatusText(500), http.StatusInternalServerError)
		return
	}
	tpl.ExecuteTemplate(w, "update.gohtml", bk)
}
func booksUpdateProcess(w http.ResponseWriter, r *http.Request) {
	if r.Method != "POST" {
		http.Error(w, http.StatusText(405), http.StatusMethodNotAllowed)
		return
	}
	bk := Book{}
	bk.Isbn = r.FormValue("isbn")
	bk.Title = r.FormValue("title")
	bk.Author = r.FormValue("author")
	p := r.FormValue("price")
	if bk.Isbn == "" || bk.Title == "" || bk.Author == "" || p == "" {
		http.Error(w, http.StatusText(400), http.StatusBadRequest)
		return
	}
	f64, err := strconv.ParseFloat(p, 32)
	if err != nil {
		http.Error(w, http.StatusText(406)+"Please hit back and enter a number for the price", http.StatusNotAcceptable)
		return
	}
	bk.Price = float32(f64)
	_, err = db.Exec("UPDATE books SET isbn = $1, title=$2, author=$3, price=$4 WHERE isbn=$1;", bk.Isbn, bk.Title, bk.Author, bk.Price)
	if err != nil {
		http.Error(w, http.StatusText(500), http.StatusInternalServerError)
		return
	}
	tpl.ExecuteTemplate(w, "updated.gohtml", bk)
}
func booksDeleteProcess(w http.ResponseWriter, r *http.Request) {
	if r.Method != "GET" {
		http.Error(w, http.StatusText(405), http.StatusMethodNotAllowed)
		return
	}
	isbn := r.FormValue("isbn")
	if isbn == "" {
		http.Error(w, http.StatusText(400), http.StatusBadRequest)
		return
	}
	_, err := db.Exec("DELETE FROM books WHERE isbn=$1;", isbn)
	if err != nil {
		http.Error(w, http.StatusText(500), http.StatusInternalServerError)
		return
	}
	http.Redirect(w, r, "/books", http.StatusSeeOther)
}
Organise the above code in various sub packages
  `}
            </pre>
            <Span>
              <b>Google Cloud</b>
            </Span>
            <pre>{`
app.yaml

application: astute-curve-100822
version: 1
runtime: go
api_version: go1

handlers:
- url: /admin/.*
  script: _go_app
  login: admin
- url: /.*
  script: _go_app
  login: required            
 
main.go
package main
import (
	"fmt"
	"net/http"
	"google.golang.org/appengine"
	"google.golang.org/appengine/user"
)
func init() {
	http.HandleFunc("/", index)
	http.HandleFunc("/admin/", admin)
}
func index(w http.ResponseWriter, r *http.Request) {
	ctx := appengine.NewContext(r)
	u := user.Current(ctx)
	url, err := user.LogoutURL(ctx, "/")
	if err != nil {
		http.Error(w, err.Error(), http.StatusInternalServerError)
		return
	}
	w.Header().Set("Content-Type", "text/html; charset=UTF-8")
	fmt.Fprintf(w, \`Welcome, %s! (<a href="%s">sign out</a>)\`, u, url)
}
func admin(w http.ResponseWriter, r *http.Request) {
	ctx := appengine.NewContext(r)
	u := user.Current(ctx)
	url, _ := user.LogoutURL(ctx, "/")
	w.Header().Set("Content-Type", "text/html; charset=UTF-8")
	fmt.Fprintf(w, \`Welcome ADMIN, %s! (<a href="%s">sign out</a>)\`, u, url)
}
 `}</pre>
            <Span>Memcache</Span>
            <pre>{`
app.yaml
application: learning-1130
version: 1
runtime: go
api_version: go1
handlers:
- url: /.*
  script: _go_app

main.go
package main
import (
  "fmt"
  "net/http"
  "google.golang.org/appengine"
  "google.golang.org/appengine/memcache"
  "time"
) 
func init() {
  http.HandleFunc("/", index)
} 
func index(w http.ResponseWriter, r *http.Request) {
  ctx := appengine.NewContext(r)
  item1 := memcache.Item{
    Key:        "foo",
    Value:      []byte("bar"),
    Expiration: 10 * time.Second,
  }
  memcache.Set(ctx, &item1)
  item, err := memcache.Get(ctx, "foo")
  if err != nil {
    http.Error(w, err.Error(), http.StatusInternalServerError)
    return
  }
  fmt.Fprintln(w, string(item.Value))
} 
 `}</pre>
            <Span>
              <b>Datastore</b>
            </Span>
            <pre>{`
 package evolved

 import (
   "fmt"
   "net/http"
 
   "google.golang.org/appengine"
   "google.golang.org/appengine/datastore"
 )
 
 func init() {
   http.HandleFunc("/", index)
   http.HandleFunc("/tools", showTools)
 }
 
 type Tool struct {
   Name        string
   Description string
 }

 func index(res http.ResponseWriter, req *http.Request) {
   if req.URL.Path == "/favicon.ico" {
     http.NotFound(res, req)
   }
   res.Header().Set("Content-Type", "text/html")
   if req.Method == "POST" {
     putTool(res, req)
   }
   fmt.Fprintln(res, \`
       <form method="POST" action="/">
         <h1>Tool</h1>
         <input type="text" name="name"><br>
         <h1>Description</h1>
         <textarea name="descrip"></textarea>
         <input type="submit">
       </form>\`)
 }
 
 func putTool(res http.ResponseWriter, req *http.Request) {
   name := req.FormValue("name")
   descrip := req.FormValue("descrip")
   ctx := appengine.NewContext(req)
   parentKey := datastore.NewKey(ctx, "House", "Garage", 0, nil)
   key := datastore.NewKey(ctx, "Tools", name, 0, parentKey)
   entity := &Tool{
     Name:        name,
     Description: descrip,
   }
   _, err := datastore.Put(ctx, key, entity)
   if err != nil {
     http.Error(res, err.Error(), 500)
     return
   }
 }
 
 func showTools(res http.ResponseWriter, req *http.Request) {
   html := ""
   ctx := appengine.NewContext(req)
   parentKey := datastore.NewKey(ctx, "House", "Garage", 0, nil)
   q := datastore.NewQuery("Tools").Ancestor(parentKey)
   iterator := q.Run(ctx)
   for {
     var entity Tool
     _, err := iterator.Next(&entity)
     if err == datastore.Done {
       break
     } else if err != nil {
       http.Error(res, err.Error(), 500)
       return
     }
     html += \`
       <dt>\` + entity.Name + \`</dt>
       <dd>\` + entity.Description + \`</dd>
     \`
   }
   res.Header().Set("Content-Type", "text/html")
   fmt.Fprintln(res, \`<dl>\`+html+\`</dl>\`)
 }
 
 `}</pre>
            <Span>
              Cloud Storage: https://github.dev/saiashish9/golang-web-dev
            </Span>
            <Span>
              <b>WebRTC</b>
            </Span>
            <Span>https://webrtc.org</Span>
            <Span>
              An open framework for a wen that enables Real Time Communication
              in the browser.
            </Span>
            <Span>Web Sockets</Span>
            <Span>
              The WebSocket API is an advanced technology that makes it possible
              to open a two-way interactive communication session between the
              user's browser and a server. With this API, you can send messages
              to a server and receive event-driven responses without having to
              poll the server for a reply.
            </Span>
            <Span>
              Unlike HTTP, where you have to constantly request updates, with
              websockets, updates are sent immediately when they are available.
              WebSockets keeps a single, persistent connection open while
              eliminating latency problems that arise with HTTP
              request/response-based methods.
            </Span>
            <Span>
              WebRTC ( Web Real Time Communication ) is a technology which
              enables web applications and sites to capture and optionaaly
              stream audio/ or video as well as to exchange arbitrary data b/w
              browsers w/o requiring an intermediary. We need js for webrtc at
              any browser.
            </Span>
            <Span>
              It makes use of peer to peer n/w to communicate and share this
              data. Earlier there was a central point to communicate and share
              this data.
            </Span>
            <Span>
              WebSockets are meant to enable bidirectional communication between
              a browser and a web server and WebRTC is meant to offer real time
              communication between browsers (predominantly voice and video
              communications).
            </Span>
            <Span>
              WebRTC apps need a service via which they can exchange network and
              media metadata, a process known as signaling. However, once
              signaling has taken place, video/audio/data is streamed directly
              between clients, avoiding the performance cost of streaming via an
              intermediary server. They're built on top of http.
              <br />
              WebSocket on the other hand is designed for bi-directional
              communication between client and server. It is possible to stream
              audio and video over WebSocket (see here for example), but the
              technology and APIs are not inherently designed for efficient,
              robust streaming in the way that WebRTC is.
            </Span>
            <Span>WebRTC Connection Cycle</Span>
            <Img left src={WebRTCImg} large />
            <Img left src={WebRTCImg1} />
            <Span>
              Local description is used to describe itself, remote description
              is used to describe the device on other end of the connection.
            </Span>
            <Span>
              We can establish connection between two devices via a intermediate
              called as signalling server.
            </Span>
            <Span>SDP and Signalling</Span>
            <Span>
              The process of signalling can be done w/o any medium. Heck we can
              even tweet the offer
            </Span>
            <Span>
              But in serious terms, we usually use a websocket server beacuse of
              its duplex communication capabilities.
            </Span>
            <Span>
              We can use regular HTTP, but we'll have to use long polling
            </Span>
            <Span>
              github.com/gorilla/websocket can use used to establish connection
              in ho
            </Span>
            <Span>Session Description Protocol</Span>
            <Span>
              The configuration of endpoint on a webRTC cction is called a
              session description.It is expressed using the SDp
            </Span>
            <Span>
              The description includes information about the kind of media being
              sent, its format, the transfer protocol being used the enpoint's
              IP address and port and other information needed to describe a
              media transfer enpoint
            </Span>
            <Span>Offers and ansers are special descriptions.</Span>
            <Span>
              Each peers keeps two descriptions on hand, the local descripton,
              describing itslef and the remote description describing the other
              end of the call
            </Span>
            <Span>
              NAT, STUN ( Session Traversal Utilities for NAT(N/W Address
              Translation) ) & TURN Servers, ICE ( Interactive Connective
              Establishment ) Candidates
            </Span>
            <Img left src={WebRTCImg3} />
            <Span>System Design</Span>
            <Img left src={WebRTCImg2} />
            <Span>
              <b>Configuration Steps:</b>
            </Span>
            <Span>1. Go to golang.org</Span>
            <Span>2. Download arm based version for m1 mac</Span>
            <Span>3. Execute following statements:</Span>
            <Span>
              export GOROOT=/usr/local/go <br />
              export GOPATH=$HOME/Desktop/folders/projects/go-workspace
              <br />
              export PATH=$GOPATH/bin:$GOROOT/bin:$PATH
            </Span>
            <Span>
              5. Afterwards go to golang workspace (i.e.,
              $HOME/Desktop/folders/projects/go-workspace)
            </Span>
            <Span>6. Execute Following Commands:</Span>
            <Span>
              mkdir video-chat <br /> cd video-chat <br /> go mod init
              video-chat-app
            </Span>
            <Span>
              7. Place server directory and main.go except client directory
            </Span>
            <Span>
              8. Then , excute go build main.go and then go run main.go
            </Span>
            <Span>
              9. execute yarn create react-app client at root level. Make use of
              existing files and execute yarn start.
            </Span>
            <Span>10. Open localhost:3000 and click at the button</Span>
            <Span>
              11. Copy and paste the link in a new tab. We'll be able to
              establish video chat
            </Span>
            <Img left src={WebRTCImg4} large />
            <Img left src={GoImg} large />
            <Img left src={WebRTCImg5} large />
            <Img left src={WebRTCImg6} large />
          </>
        ),
        types: {},
      },
      React: {
        title: "React",
        content: (
          <>
            <Span>Protected Route</Span>
            <pre>{`
      const A = ({ a : Component ,...rest }) => {
        const { state } = useStore()
        return (
            <Route
            render={
              props => !state.isAdmin ? <Redirect to="/login /> : <Component {...props} />
            }
            {...rest}
            />
        )
      }                    
            `}</pre>
            <Span>Suspense</Span>
            <pre>
              {`
<Suspense fallback={}></Suspense>              
              `}
            </pre>
            <Span>lazy</Span>
            <pre>{`
            lazy(()=>k)
            `}</pre>
            <Span>PropTypes</Span>
            <pre>
              {`
import PropTypes from "prop-types"

static PropTypes = {
  onDelete : Proptypes.func.isRequired
}

static defaultProps = { onClose(){} }
              `}
            </pre>
            <Span>
              Redux, React-Redux, Redux-Thunk, Redux-Logger, Reselect,
              Redux-Saga, Redux-Persist, Redux-Devtools-Extension
            </Span>
            <pre>
              {`
import { createStore, combineReducers, applyMiddleware } from "redux"
import { Provider } from "react-redux"
import { createLogger } from "redux-logger"
import { createSelector } from "reselect
import thunk from "redux-thunk"
import { PersistGate } from "redux-persist/integration/react
import { PersistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/integration/react"
logger = createLogger()
reducers = combineReducers({
  reducerA
})
reducerA = (state=initialState, action = {}) {
  switch(action.type){
      case : 
  }
} 
mapStateToProps
mapDispatchToProps
connect(mapStateToProps,mapDispatchToProps)
              `}
            </pre>
            <br />
            <pre>
              {`
import { composeWithDevTools } from "redux-devtools-extension"
composeWithDevTools(applyMiddleware(...middlewares))
import { compose } from "redux" 
(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(applyMiddleware(...middlewares))
              `}
            </pre>
            <Span>bind() menthod</Span>
            <pre>
              {`
class A extends Component {
constructor(props){
  super(props)
  this.state = {}
  this.onSave = this.onSave.bind(this)
}
}              

this.A = this.A.bind(this)
this.A.bind(this)
this.A.bind(this,id)
              `}
            </pre>
            <Span>
              useContext , createContext, useSelector, useDispatch, useMemo,
              useCallback , useRef, createRef,
            </Span>
            <Span>Counter</Span>
            <pre>
              {`
import React, { useState, useCallback } from 'react'

const Counter = () => {
  const [count, setCount] = useState(0)
  const [otherCounter, setOtherCounter] = useState(0)

  const increment = () => {
    setCount(count + 1)
  }
  const decrement = () => {
    setCount(count - 1)
  }
  const incrementOtherCounter = () => {
    setOtherCounter(otherCounter + 1)
  }

  return (
    <>
      Count: {count}
      <button onClick={increment}>+</button>
      <button onClick={decrement}>-</button>
      <button onClick={incrementOtherCounter}>incrementOtherCounter</button>
    </>
  )
}

ReactDOM.render(<Counter />, document.getElementById('app'))
The problem here is that any time the counter is updated, all the 3 functions are re-created again.

You can visualize this by instantiating a Set data structure, and 
adding each function to it. Why Set? because it only stores unique elements, which in our case means different (uniquely instantiated) functions.              

const { useState, useCallback } = React
const functionsCounter = new Set()

const Counter = () => {
  const [count, setCount] = useState(0)
  const [otherCounter, setOtherCounter] = useState(0)
  
  const increment = useCallback(() => {
    setCount(count + 1)
  }, [count])
  const decrement = useCallback(() => {
    setCount(count - 1)
  }, [count])
  const incrementOtherCounter = useCallback(() => {
    setOtherCounter(otherCounter + 1)
  }, [otherCounter])

  /*
  const increment = (() => {
    setCount(count + 1)
  })
  const decrement = (() => {
    setCount(count - 1)
  })
  const incrementOtherCounter = (() => {
    setOtherCounter(otherCounter + 1)
  })
  */

  functionsCounter.add(increment)
  functionsCounter.add(decrement)
  functionsCounter.add(incrementOtherCounter)

  console.log(functionsCounter.size)
  
  return (
    <>
      Count: {count}
      <button onClick={increment}>+</button>
      <button onClick={decrement}>-</button>
      <button onClick={incrementOtherCounter}>incrementOtherCounter</button>
    </>
  )
}

ReactDOM.render(<Counter />, document.getElementById('app'))
`}
            </pre>
            <Span>Countdown Timer</Span>
            <pre>
              {`
function Countdown({ seconds }) {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return <div>{timeLeft}s</div>;
}              
              `}
            </pre>
            <pre>
              {`
import React, {useReducer, createContext} from 'react';
export default (reducer, actions, initialState) => {
  const Context = createContext();
  const Provider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);              
    const boundActions = {};
    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }
    return (
      <Context.Provider value={{state, ...boundActions}}>
        {children}
      </Context.Provider>
    );
  };
  return {Context, Provider};
};
              `}
            </pre>
            <pre>
              {`
import axios from "axios";
const instance = axios.create({
  baseURL: "https://sheet.best/api/sheets/",
});
instance.interceptors.request.use(
  async (config) => {
      config.headers['Content-Type'] = 'application/json';
      return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
export default instance;              
              `}
            </pre>
            <pre>
              {`
export const { Context, Provider } = createDataContext(
  reducer,
  {
    fetchTexts,
  },
  {
    texts: null,
  }
);              

import Cookie from "js-cookie"
const generatePDF = (dispatch) => async ({ child, file, type }) => {
  try {
    const email = Cookie.get("email");
    console.log(url);
    await firebase.storage().ref().child(child).put(file, "application/pdf");
    const url = await firebase
      .storage()
      .ref(type)
      .getDownloadURL();
    let obj = {};
    obj[type] = url;
    await db.collection("dev-users").doc(email).update(obj);
  } catch (e) {
    throw new Error(e);
  }
};
              `}
            </pre>
            <Span>
              Sagas enable numerous approaches to tackling parallel execution,
              task concurrency, task racing, task cancellation, and more. Keep
              total control over the flow of your code.
            </Span>
            <Span>
              Babel is a JavaScript transpiler, meaning it converts a newer
              version of ECMAScript, such as ES9, to a standard version (ES5).
            </Span>
            <Span>
              ECMAScript is a Standard for scripting languages such as
              JavaScript, JScript, etc. It is a trademark scripting language
              specification. JavaScript is a language based on ECMAScript. A
              standard for scripting languages like JavaScript, JScript is
              ECMAScript.
            </Span>
            <Span>
              Ecma International (formally European Computer Manufacturers
              Association) is a non-profit organization that develops standards
              in computer hardware, communications, and programming languages
            </Span>
            <Span>
              Webpack is a module bundler. Its main purpose is to bundle
              JavaScript files for usage in a browser, yet it is also capable of
              transforming, bundling, or packaging just about any resource or
              asset.
            </Span>
            <Span>
              A polyfill is a piece of code (usually JavaScript on the Web) used
              to provide modern functionality on older browsers that do not
              natively support it.
            </Span>
            <Span>
              The Document Object Model (DOM) is a programming interface for web
              documents. It represents the page so that programs can change the
              document structure, style, and content. The DOM represents the
              document as nodes and objects; that way, programming languages can
              interact with the page
            </Span>
            <Span>
              Debouncing in JavaScript is a practice used to improve browser
              performance. There might be some functionality in a web page which
              requires time-consuming computations. If such a method is invoked
              frequently, it might greatly affect the performance of the
              browser, as JavaScript is a single threaded language.26-Jul-2021
            </Span>
          </>
        ),
      },
      ReactNative: {
        title: "React Native",
        content: (
          <>
            <Span>
              expo install react-native-gesture-handler react-native-reanimated
              react-native-screens react-native-safe-area-context
              @react-native-community/masked-view @react-navigation/native
              @react-navigation/stack @react-navigation/native
              @react-navigation/bottom-tabs
            </Span>
            <Span>
              CreateAppContainer <br />
              CreateStackNavigator <br />
              CreateDrawerNavigator <br />
              CreateBottomTabNavigator <br />
              CreateMaterialTopBarNavigator <br />
            </Span>
            <Span>Animated</Span>
            <Span>Animated.(Spring,Delay,Timing,Value,ValueXY)</Span>
            <Span>Pan Responder</Span>
            <Span>Flatlist</Span>
            <Span>
              data <br />
              renderItem <br />
              horizontal
              <br />
              keyExtractor <br />
              showsHorizontalIndicator
            </Span>
            <Span>react-native-config.js</Span>
            <Span>
              react-native-splashscreen <br />
              react-native-bootsplash <br />
              react-native-elements <br />
              latlng-to-zip <br />
              react-native-contacts <br />
              react-native-get-location <br />
              react-native-image-picker <br />
              react-native-sound <br />
              react-native-video <br />
              react-native-youtube <br />
              react-native-device-info <br />
              react-native-countup <br />
              react-native-svg <br />
              react-native-linear-gradient <br />
              @react-native-community/google-signin <br />
              @react-native-community/async-storage <br />
              react-native-debugger <br />
              @react-native-community/datetimepicker
              <br /> ngrok http 3000 <br />
              json-server -w db.json <br />
              nodemon src/index.json
              <br />
              react-native-modalize
            </Span>
          </>
        ),
      },
      Regex: {
        title: "Regex",
        content: (
          <>
            <Span>
              A regular expression (sometimes called a rational expression) is a
              sequence of characters that define a search pattern, mainly for
              use in pattern matching with strings, or string matching, i.e.
              “find and replace”-like operations.
            </Span>
            <Span>
              Regular expressions are a generalized way to match patterns with
              sequences of characters. It is used in every programming language
              like C++, Java and Python.
            </Span>
            <Span>
              What is a regular expression and what makes it so important? Regex
              are used in Google analytics in URL matching in supporting search
              and replace in most popular editors like Sublime, Notepad++,
              Brackets, Google Docs and Microsoft word.
            </Span>
            <Span>
              Example : Regular expression for an email address :
              <pre>
                {`
              ^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{(2, 5)})$                
                `}
              </pre>
            </Span>
            <Span>
              The above regular expression can be used for checking if a given
              set of characters is an email address or not.
            </Span>
            <Span>How to write regular expression?</Span>
            <Span>Repeaters : * , + and {} :</Span>
            <Span>
              These symbols act as repeaters and tell the computer that the
              preceding character is to be used for more than just one time.
            </Span>
            <Span>1. The asterisk symbol ( * ):</Span>
            <Span>
              It tells the computer to match the preceding character (or set of
              characters) for 0 or more times (upto infinite).
            </Span>
            <pre>
              {`
              Example : The regular expression ab*c will give ac, abc, abbc, 
              abbbc….ans so on 
              `}
            </pre>
            <Span>2. The Plus symbol ( + ):</Span>
            <Span>
              It tells the computer to repeat the preceding character (or set of
              characters) for atleast one or more times(upto infinite).
            </Span>
            <pre>
              {`
              Example : The regular expression ab+c will give abc, abbc,
              abbc, … and so on.
              `}
            </pre>
            <Span>3. The curly braces {"{…}"}</Span>
            <Span>
              It tells the computer to repeat the preceding character (or set of
              characters) for as many times as the value inside this bracket.
            </Span>
            <pre>
              {`
              Example : {2} means that the preceding character is to be repeated 2 
              times, {min,} means the preceding character is matches min or  more 
              times. {min,max} means that the preceding character is repeated at
              least min & at most max times.
              `}
            </pre>
            <Span>4. Wildcard – ( . )</Span>
            <Span>
              The dot symbol can take place of any other symbol, that is why it
              is called the wildcard character.
            </Span>
            <pre>
              {`
              Example : 
              The Regular expression .* will tell the computer that any character
              can be used any number of times.
              `}
            </pre>
            <Span>5. Optional character – ( ? )</Span>
            <Span>
              This symbol tells the computer that the preceding character may or
              may not be present in the string to be matched.
            </Span>
            <pre>
              {`
                Example:
                We may write the format for document file as – “docx?”
                The ‘?’ tells the computer that x may or may not be 
                present in the name of file format. 
                `}
            </pre>
            <Span>6. The caret ( ^ ) symbol:</Span>
            <Span>
              Setting position for match :tells the computer that the match must
              start at the beginning of the string or line.
            </Span>
            <pre>
              {`
              Example : ^\d{3} will match with patterns like "901" in "901-333-".
              `}
            </pre>
            <Span>7. The dollar ( $ ) symbol</Span>
            <Span>
              It tells the computer that the match must occur at the end of the
              string or before \n at the end of the line or string.
            </Span>
            <pre>{`
            Example : -\d{3}$  will match with patterns like "-333" in "-901-333".
            `}</pre>
            <Span>8. Character Classes</Span>
            <Span>
              A character class matches any one of a set of characters. It is
              used to match the most basic element of a language like a letter,
              a digit, space, a symbol etc.
            </Span>
            <Span>
              /s : matches any whitespace characters such as space and tab{" "}
              <br />
              /S : matches any non-whitespace characters <br />
              /d : matches any digit character <br />
              /D : matches any non-digit characters <br />
              /w : matches any word character (basically alpha-numeric) <br />
              /W : matches any non-word character <br />
              /b : matches any word boundary (this would include spaces, dashes,
              commas, semi-colons, etc)
            </Span>
            <Span>9. {"[set_of_characters]"}</Span>
            <Span>
              Matches any single character in set_of_characters. By default, the
              match is case-sensitive.
            </Span>
            <pre>
              {`
Example : [abc] will match characters a,b and c in any string.              
              `}
            </pre>
            <Span>10. {"[^set_of_characters]"}</Span>
            <pre>
              {`
Negation: Matches any single character that is not in set_of_characters. By default, the match is case sensitive.              
              `}
            </pre>
            <Span>11. {"[first-last]"}</Span>
            <Span>
              Character range: Matches any single character in the range from
              first to last.
            </Span>
            <pre>
              {`
              Example : [a-zA-z] will match any character from a to z or A to Z.
              `}
            </pre>
            <Span>12. The Escape Symbol : \</Span>
            <Span>
              If you want to match for the actual ‘+’, ‘.’ etc characters, add a
              backslash( \ ) before that character. This will tell the computer
              to treat the following character as a search character and
              consider it for matching pattern.
            </Span>
            <pre>
              {`
              Example : \d+[\+-x\*]\d+ will match patterns like "2+2"
              and "3*9" in "(2+2) * 3*9".
              `}
            </pre>
            <Span>13. Grouping Characters ( )</Span>
            <Span>
              A set of different symbols of a regular expression can be grouped
              together to act as a single unit and behave as a block, for this,
              you need to wrap the regular expression in the parenthesis( ).
            </Span>
            <pre>
              {`
              Example : ([A-Z]\w+) contains two different elements of the regular 
              expression combined together. This expression will match any pattern 
              containing uppercase letter followed by any character.
              `}
            </pre>
            <Span>14. Vertical Bar ( | )</Span>
            <Span>
              Matches any one element separated by the vertical bar (|)
              character.
            </Span>
            <pre>{`
            Example :  th(e|is|at) will match words - the, this and that.
            `}</pre>
            <Span>15. \number ( Backrefernce ) :</Span>
            <Span>
              Backreference: allows a previously matched
              sub-expression(expression captured or enclosed within circular
              brackets ) to be identified subsequently in the same regular
              expression. \n means that group enclosed within the n-th bracket
              will be repeated at current position.
            </Span>
            <pre>
              {`
              Example : ([a-z])\\1 will match “ee” in Geek because the character 
              at second position is same as character at position 1 of the match.
              `}
            </pre>
            <Span>16. Comment : (?# comment) </Span>
            <Span>
              Inline comment: The comment ends at the first closing parenthesis.
            </Span>
            <pre>{`Example : \\bA(?#This is an inline comment)\\w+\\b`}</pre>
            <Span># [to end of line]</Span>
            <Span>
              X-mode comment. The comment starts at an unescaped # and continues
              to the end of the line.
            </Span>
            <pre>{`Example :  (?x)\\bA\\w+\\b#Matches words starting with A`}</pre>
          </>
        ),
      },
      MySQL: {
        title: "MYSQL",
        content: <></>,
      },
    },
    content: (
      <>
        <Img style={{ borderRadius: "0.4rem" }} src={NotesImg} />
      </>
    ),
  },
};

// https://cheonhyangzhang.gitbooks.io/leetcode-solutions/content/56-merge-intervals.html
